<button
    #tableViewToggle
    mat-button
    [attr.data-qa]="dataQa.tableViewButton"
    color="primary"
    [matMenuTriggerFor]="changeTableViewMenu"
    class="color-primary">
    Table view
    <mat-icon svgIcon="chevron-down" iconPositionEnd style="width: 12px" />
</button>
<mat-menu #changeTableViewMenu="matMenu" class="p-3">
    <div
        tabindex="0"
        class="change-table-view-menu"
        (click)="$event.stopPropagation()"
        (keyup.enter)="$event.stopPropagation()">
        <p>Table View Options:</p>
        <mat-button-toggle-group
            name="tableView"
            aria-label="Table View"
            [value]="selected">
            <mat-button-toggle
                value="document"
                [attr.data-qa]="dataQa.documentViewToggle"
                (change)="changeTableView($event.value)">
                Document View
            </mat-button-toggle>
            <mat-button-toggle
                value="discrepancy"
                [attr.data-qa]="dataQa.discrepancyViewToggle"
                (change)="changeTableView($event.value)">
                Discrepancy View
            </mat-button-toggle>
        </mat-button-toggle-group>
        <p class="text-xs">
            {{ description }}
        </p>
        <app-flat-button class="justify-self-end" (clicked)="closeMenu()">
            Close
        </app-flat-button>
    </div>
</mat-menu>
