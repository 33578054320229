import { CommonModule } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { FileType, fileTypesMap } from 'app/utils/constants';

@Component({
    selector: 'app-dropzone',
    standalone: true,
    templateUrl: 'dropzone.component.html',
    styleUrl: 'dropzone.component.scss',
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatProgressBarModule,
    ],
})
export class DropzoneComponent implements OnInit {
    @Input() acceptedFiles: FileType[] = [];
    @Input() maxFileCount = 1;
    @Input() progress: number | null = null;
    @Output() fileReady = new EventEmitter();
    @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

    isDragging = false;
    errorMessage: string | null = null;
    multipleNotAllowedMessage =
        'Uploading multiple documents is not allowed, please drop one document at a time.';
    acceptContentTypes: string = '';
    files: FileList | null = null;

    ngOnInit(): void {
        this.acceptContentTypes = this.acceptedFiles
            .map((file) => fileTypesMap[file])
            .join(',');
    }

    onDragOver(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.isDragging = true;

        const files = event.dataTransfer?.items;
        if (files && files.length > this.maxFileCount) {
            this.errorMessage = this.multipleNotAllowedMessage;
        } else {
            this.errorMessage = null;
        }
    }

    onDragLeave(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.isDragging = false;
        this.errorMessage = null;
    }

    onDrop(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.isDragging = false;

        const files = event.dataTransfer?.files;
        this.files = files || null;

        if (files) {
            this.errorMessage = this.validate(files);
            if (!this.errorMessage) {
                this.fileReady.emit(files);
            }
        }
    }

    onClick() {
        this.fileInput.nativeElement.click();
    }

    onFileSelected(event: Event) {
        const input = event.target as HTMLInputElement;
        this.files = input.files || null;

        if (this.files) {
            this.errorMessage = this.validate(this.files);
            if (!this.errorMessage) {
                this.fileReady.emit(this.files);
            }
        }
    }

    onCloseClick() {
        this.files = null;
        this.fileReady.emit(null);
    }

    private validate(files: FileList): string | null {
        const countError = this.validateFileCount(files);
        if (countError) return countError;

        const typeError = this.validateFileTypes(files);
        if (typeError) return typeError;

        return null;
    }

    private validateFileCount(files: FileList): string | null {
        if (files.length > this.maxFileCount) {
            return this.multipleNotAllowedMessage;
        }
        return null;
    }

    private validateFileTypes(files: FileList): string | null {
        for (let i = 0; i < files.length; i++) {
            if (!this.acceptedFiles.includes(files[i].type as FileType)) {
                return `Accepted file types: ${this.acceptContentTypes}`;
            }
        }

        return null;
    }
}
