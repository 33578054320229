import { HttpStatusCode } from '@angular/common/http';

export class PagedResponse<T> {
    constructor(
        public pageIndex: number,
        public pageSize: number,
        public totalItems: number,
        public items: T[],
    ) {}
}

export class SimpleResponse<T> {
    constructor(public data: T) {}
}

export type Order = 'asc' | 'desc';

export interface DefaultParams {
    pageIndex: number;
    pageSize: number;
    order: Order;
}

export interface PostProgressResponse {
    status: PostProgressStatusType;
    progress: number;
}

export type PostProgressStatusType =
    | 'progress'
    | 'success'
    | 'unknown'
    | 'error';

export class ErrorResponse {
    constructor(
        public detail: string,
        public status: HttpStatusCode,
        public title: string,
    ) {}
}
