import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { RouterService } from '@services/router.service';
import { DocumentDetailDialogComponent } from 'app/components/documents-and-discrepancies/detail-dialog/detail-dialog.component';

@Component({
    standalone: true,
    template: '',
})
export class DocumentDetailComponent {
    documentId: number | null = null;

    constructor(
        public dialog: MatDialog,
        private routerService: RouterService,
        route: ActivatedRoute,
    ) {
        route.params.subscribe((params) => {
            this.documentId = params['id'];
        });
        this.openDialog();
    }

    openDialog() {
        const dialogRef = this.dialog.open(DocumentDetailDialogComponent, {
            data: { id: this.documentId },
            height: '100%',
            width: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
            panelClass: 'm-n4',
        });

        dialogRef.afterClosed().subscribe(() => {
            // TODO: Pass true if update happen
            this.routerService.goBack(true);
        });
    }
}
