import { Routes } from '@angular/router';

import { AuthGuard } from '@guards/auth.guard';
import { DocDetailGuard } from '@guards/document-edit.guard';
import { DocumentsAndDiscrepanciesComponent } from './pages/documents-and-discrepancies/documents-and-discrepancies';
import { LoginComponent } from './pages/login/login.component';
import { DocumentDetailComponent } from './pages/document-detail/document-detail.component';

export const routes: Routes = [
    { path: '', redirectTo: '/documents-and-discrepancies', pathMatch: 'full' },
    {
        path: 'documents-and-discrepancies',
        component: DocumentsAndDiscrepanciesComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: ':id',
                component: DocumentDetailComponent,
                canActivate: [DocDetailGuard],
            },
        ],
    },
    { path: 'login', component: LoginComponent },
    { path: '**', redirectTo: '/documents-and-discrepancies' },
];
