import { HttpResponse } from '@angular/common/http';

export class PDFDocument {
    constructor(
        public blob: Blob | null,
        public name: string,
    ) {}
}

export const createPDF = (response: HttpResponse<Blob>): PDFDocument => {
    if (response.body) {
        const contentDisposition = response.headers.get('Content-Disposition');
        let name = '';

        if (contentDisposition) {
            const matches = /filename\*=UTF-8''([^;]+)/.exec(
                contentDisposition,
            );
            if (matches != null && matches[1]) {
                name = matches[1];
            }
        }
        return new PDFDocument(createBlobPDF(response.body), name);
    }

    return new PDFDocument(null, '');
};

export const createBlobPDF = (blobPart: BlobPart) => {
    return new Blob([blobPart], {
        type: 'application/pdf',
    });
};
