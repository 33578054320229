import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import endpoints from '@endpoints';
import { ApiService } from '../api.service';
import { interpolate } from 'app/utils/helpers';
import { SimpleResponse } from 'app/models/api.model';
import { SharedMapper } from '../shared.mapper';
import { IdDisplayPair, SelectOption } from 'app/models/shared.model';

@Injectable({
    providedIn: 'root',
})
export class StationService {
    private readonly _path = 'workflow/v1';

    constructor(
        private api: ApiService,
        private sharedMapper: SharedMapper,
    ) {}

    getStationFixers(id: number): Observable<SelectOption[]> {
        return this.api
            .get(this._path + interpolate(endpoints.stationFixers, { id }))
            .pipe(
                map((response: SimpleResponse<IdDisplayPair[]>) =>
                    (response?.data ?? []).map(
                        this.sharedMapper.toSelectOption,
                    ),
                ),
                catchError(() => of([])),
            );
    }
}
