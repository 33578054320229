@if (loading) {
    <app-loader type="overlay" size="medium" />
}
<div
    class="instance-select"
    *ngIf="isAdmin && workflowInstanceOptions.length > 0">
    <div class="instance-select__wrapper">
        <app-select
            [dataQa]="dataQa.workflowInstanceSelect"
            label="Workflow Instance"
            [options]="workflowInstanceOptions"
            [value]="workflowInstanceOptions[0].value"
            (selectionChange)="onWorkflowInstanceChange($event)" />
        <app-select
            [dataQa]="dataQa.repositorySelect"
            label="Repository"
            [options]="repositoryOptions"
            [value]="selectedRepository"
            [multiple]="true"
            (selectionChange)="onRepositoryChange($event)" />
    </div>
</div>
<div class="filter-select">
    <app-status-filter
        [statusChips]="statusChipsData"
        (filterChange)="onStatusFilterChange($event)" />
    <app-toggle
        id="showMyItemsToggle"
        label="Show only my items"
        style="height: 30px"
        [initialToggled]="showMyItems"
        (toggle)="onToggleMyItems($event)" />
</div>
<ng-container>
    <div class="table-action flex">
        <div></div>
        <div class="flex items-center fs-14">
            <app-change-view-menu
                [selected]="selectedTableView"
                (tableViewChanged)="onTableViewChanged($event)" />
            <app-text-button
                [dataQa]="dataQa.resetFiltersButton"
                (clicked)="resetFilters()"
                [disabled]="isResetFiltersDisabled()">
                Reset filters
            </app-text-button>
        </div>
    </div>
    <div *ngIf="showDocuments">
        <app-document-view
            *ngIf="selectedTableView === TableView.Document"
            [workflowAndRepo]="workflowAndRepo"
            [statuses]="selectedStatuses"
            [showMyItems]="showMyItems" />
        <app-discrepancy-view
            *ngIf="selectedTableView === TableView.Discrepancy"
            [workflowAndRepo]="workflowAndRepo"
            [statuses]="selectedStatuses"
            [showMyItems]="showMyItems" />
    </div>
</ng-container>
