import { IServerSideGetRowsRequest } from 'ag-grid-community';
import { SelectOption } from 'app/models/shared.model';

// Enums

export enum TableView {
    Document = 'document',
    Discrepancy = 'discrepancy',
}

export enum DocumentColumnType {
    NAME = 'name',
    DOC_TYPE = 'documentType',
    PRIORITY = 'priority',
    REPOSITORY = 'repository',
    WF_ENTER_DATE = 'createdAt',
    STATION = 'station',
}

export enum NestedColumnType {
    ID = 'discrepancyNo',
    REASON = 'reason',
    PRIORITY = 'priority',
    STATUS = 'status',
    TAKEN = 'taken',
    ANALYST = 'analyst',
    STATION = 'station',
    RESPONSIBLE_FIXER = 'responsibleFixer',
}

// Helpers

export const setFilterQuery = (request: IServerSideGetRowsRequest) => {
    const queryParams = {};
    const { filterModel } = request;

    if (filterModel && Object.keys(filterModel).length) {
        Object.keys(filterModel).forEach((key) => {
            Object.assign(queryParams, {
                [key]: (filterModel as any)[key]?.filter?.trim(),
            });
        });
    }
    return queryParams;
};

export const setSortQuery = (request: IServerSideGetRowsRequest) => {
    const queryParams = {};
    const { sortModel } = request;

    if (sortModel) {
        sortModel.forEach((item) => {
            Object.assign(queryParams, {
                orderBy: item.colId,
                order: item.sort,
            });
        });
    }
    return queryParams;
};

export const setPageIndex = (
    request: IServerSideGetRowsRequest,
    pageSize: number,
): number => {
    const startRow = request?.startRow ?? null;
    const index = startRow ? Math.round(startRow / pageSize) + 1 : 1;
    return index;
};

export const createFilterFromDataSource = (
    data: any[],
    field: string,
): SelectOption[] => {
    const uniqueValues = [...new Set(data.map((item: any) => item[field]))];
    const newArray = uniqueValues.map((value: string) => ({
        viewValue: value,
        value,
    }));
    return newArray;
};

export const isFilterApplied = (
    request: IServerSideGetRowsRequest,
): boolean => {
    return Object.keys(request.filterModel || {}).length !== 0;
};
