import { CommonModule } from '@angular/common';
import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';

import { LoaderComponent } from 'app/components/shared/loader/loader.component';
import { TabsComponent } from 'app/components/shared/tabs/tabs.component';
import { ContentFieldComponent } from '@shared/content-field/content-field.component';
import { DocumentService } from '@services/api/document/document.service';
import { MetadataItem } from 'app/models/document/metadata';

@Component({
    selector: 'app-document-metadata-tab',
    standalone: true,
    templateUrl: './document-metadata-tab.component.html',
    styleUrl: './document-metadata-tab.component.scss',
    imports: [
        CommonModule,
        TabsComponent,
        LoaderComponent,
        ContentFieldComponent,
    ],
})
export class DocumentMetadataTabComponent implements OnInit, OnChanges {
    @Input({ required: true }) documentId: number | null = null;

    readonly columnCount = 3;
    metadata: MetadataItem[] = [];
    loading = false;

    constructor(private documentService: DocumentService) {}

    ngOnInit(): void {
        if (this.documentId) {
            this.getMetadata(this.documentId);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const documentIdChange = changes['documentId'];

        if (documentIdChange && !documentIdChange.firstChange) {
            this.getMetadata(documentIdChange.currentValue);
        }
    }

    placeholderCells(): string[] {
        const count = this.metadata.length % this.columnCount;
        const cells = [];
        for (let i = 0; i <= count; i++) {
            cells.push('');
        }
        return cells;
    }

    private getMetadata(id: number) {
        this.loading = true;
        this.documentService.getMetadata(id).subscribe({
            next: (data) => (this.metadata = data),
            complete: () => (this.loading = false),
        });
    }
}
