import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { SnackbarService } from '@services/snackbar.service';
import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { LoaderComponent } from '@shared/loader/loader.component';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { RecallDiscrepancyDialogComponent } from '../recall-discrepancy-dialog/recall-discrepancy-dialog.component';
import { CancelDiscrepancyDialogComponent } from '../cancel-discrepancy-dialog/cancel-discrepancy-dialog.component';
import { DiscardDiscrepancyDialogComponent } from '../discard-discrepancy-dialog/discard-discrepancy-dialog.component';
import { DiscrepancyEditOptions } from 'app/models/discrepancy/edit-options';
import { DiscrepancyDetail } from 'app/models/discrepancy/detail';
import { DiscrepancyActionCode } from 'app/models/discrepancy/state-action';
import { UpdateDiscrepancyRequest } from 'app/models/discrepancy/update-discrepancy-request';

@Component({
    selector: 'app-footer-state-actions',
    standalone: true,
    template: `
        <div class="flex items-center gap-10">
            <app-text-button *ngIf="canRecall()" (clicked)="onRecallClick()">
                Recall
            </app-text-button>
            <app-flat-button
                *ngIf="canCancel()"
                variant="tertiary"
                (clicked)="onCancelClick()">
                Cancel Discrepancy
            </app-flat-button>
            <app-flat-button *ngIf="discDetail.isDraft" (clicked)="onDiscard()">
                Discard
            </app-flat-button>
            <app-flat-button
                *ngIf="editOptions.canEdit"
                variant="tertiary"
                (clicked)="onSave()"
                [disabled]="!canSave">
                Save
            </app-flat-button>
            <app-flat-button
                *ngIf="canNotify()"
                [disabled]="!editForm.valid"
                variant="cta"
                (clicked)="onNotify()">
                Notify
            </app-flat-button>
            <app-flat-button
                *ngIf="canRenotify()"
                variant="cta"
                (clicked)="onRenotify()">
                Re-Notify
            </app-flat-button>
        </div>
        @if (loading) {
            <app-loader type="overlay" size="large" />
        }
    `,
    styles: ``,
    imports: [
        CommonModule,
        TextButtonComponent,
        FlatButtonComponent,
        LoaderComponent,
    ],
})
export class FooterStateActionsComponent {
    @Input({ required: true }) documentId!: number;
    @Input({ required: true }) discDetail!: DiscrepancyDetail;
    @Input({ required: true }) canSave!: boolean;
    @Input({ required: true }) editForm!: FormGroup;
    @Input({ required: true }) editOptions!: DiscrepancyEditOptions;
    @Output() getDiscrepancies = new EventEmitter<number>();
    @Output() getDiscrepancyDetails = new EventEmitter<number>();

    loading = false;

    constructor(
        private snackbarService: SnackbarService,
        private discrepancyService: DiscrepancyService,
        private dialog: MatDialog,
    ) {}

    canRecall() {
        return this.discDetail.actions.some(
            (item) => item.action === DiscrepancyActionCode.RECALL,
        );
    }

    onRecallClick() {
        const dialogRef = this.dialog.open(RecallDiscrepancyDialogComponent, {
            data: {
                id: this.discDetail?.id,
                discrepancyNo: this.discDetail.discrepancyNo,
            },
            maxWidth: '450px',
            minWidth: '200px',
            width: '450px',
        });

        dialogRef.afterClosed().subscribe((success) => {
            if (this.documentId && success) {
                this.getDiscrepancies.emit(this.documentId);
            }
        });
    }

    canCancel() {
        return (
            !this.discDetail.isDraft &&
            this.discDetail.actions.some(
                (item) => item.action === DiscrepancyActionCode.CANCEL,
            )
        );
    }

    onCancelClick() {
        const dialogRef = this.dialog.open(CancelDiscrepancyDialogComponent, {
            data: {
                id: this.discDetail?.id,
                discrepancyNo: this.discDetail.discrepancyNo,
            },
            maxWidth: '450px',
            minWidth: '200px',
            width: '450px',
        });

        dialogRef.afterClosed().subscribe((success) => {
            if (this.documentId && success) {
                this.getDiscrepancies.emit(this.documentId);
            }
        });
    }

    canNotify() {
        return this.discDetail.actions.some(
            (item) => item.action === DiscrepancyActionCode.NOTIFY,
        );
    }

    canRenotify() {
        return this.discDetail.actions.some(
            (item) => item.action === DiscrepancyActionCode.RENOTIFY,
        );
    }

    onDiscard() {
        const dialogRef = this.dialog.open(DiscardDiscrepancyDialogComponent, {
            data: {
                id: this.discDetail?.id,
                discrepancyNo: this.discDetail.discrepancyNo,
            },
            maxWidth: '450px',
            minWidth: '200px',
            width: '450px',
        });

        dialogRef.afterClosed().subscribe((success) => {
            if (this.documentId && success) {
                this.getDiscrepancies.emit(this.documentId);
            }
        });
    }

    onSave(triggerNotify = false) {
        const discId = this.discDetail?.id;
        const editData = {
            ...new UpdateDiscrepancyRequest(
                this.discDetail.priority,
                this.discDetail.reason?.backingField,
                this.discDetail.pageRange,
                this.discDetail.station?.backingField,
                this.discDetail.responsibleFixer?.backingField,
                this.discDetail.directions,
            ),
            ...this.editForm.value,
        };
        this.discrepancyService.editDiscrepancy(discId, editData).subscribe({
            next: (success) => {
                if (success) {
                    this.snackbarService.success({
                        variant: 'success',
                        header: `Discrepancy ${this.discDetail.discrepancyNo} is Saved`,
                    });
                    this.getDiscrepancyDetails.emit(discId);
                    if (triggerNotify) {
                        this.notifyDiscrepancy();
                    }
                } else {
                    this.snackbarService.error({
                        variant: 'error',
                        header: `Discrepancy ${this.discDetail.discrepancyNo} Could Not be Saved`,
                    });
                }
            },
        });
    }

    onNotify() {
        if (this.canSave) {
            this.onSave(true);
        } else {
            this.notifyDiscrepancy();
        }
    }

    notifyDiscrepancy() {
        const discId = this.discDetail?.id;
        this.loading = true;
        this.discrepancyService.notifyDiscrepancy(discId).subscribe({
            next: (success) => {
                if (success) {
                    this.snackbarService.success({
                        variant: 'success',
                        header: `Notified for Dicrepancy ${this.discDetail.discrepancyNo}!`,
                    });
                    this.getDiscrepancyDetails.emit(discId);
                }
            },
            complete: () => (this.loading = false),
        });
    }

    onRenotify() {
        const discId = this.discDetail?.id;
        this.loading = true;
        this.discrepancyService.renotifyDiscrepancy(discId).subscribe({
            next: (success) => {
                if (success) {
                    this.snackbarService.success({
                        variant: 'success',
                        header: `Re-Notified for Dicrepancy ${this.discDetail.discrepancyNo}!`,
                    });
                    this.getDiscrepancyDetails.emit(discId);
                }
            },
            complete: () => (this.loading = false),
        });
    }
}
