<mat-label
    *ngIf="label"
    class="label text-xxs color-gray14"
    [class.color-status-red4]="required && !value">
    {{ label.toLocaleUpperCase() }} {{ required ? '*' : '' }}
</mat-label>
<mat-select
    #select
    [attr.data-qa]="dataQa"
    [disabled]="disabled"
    [multiple]="multiple"
    [placeholder]="placeholder"
    [ngClass]="{
        select: true,
        'select--disabled': disabled,
        'select--secondary': variant === 'secondary',
    }"
    [(value)]="value"
    (selectionChange)="onSelectionChange($event)">
    <mat-select-trigger *ngIf="multiple">
        {{ displayText() }}
    </mat-select-trigger>
    <mat-checkbox
        *ngIf="multiple"
        class="color-gray15 fs-13"
        style="padding-left: 10px; width: 255px"
        [indeterminate]="!allSelected"
        (change)="onSelectAllClick()"
        [checked]="allSelected">
        Select all ({{ options.length }})
    </mat-checkbox>
    <mat-divider *ngIf="multiple" style="margin: 0 16px" />
    <mat-option *ngIf="noneOption" [value]="null" class="color-gray15">
        None
    </mat-option>
    <mat-option
        *ngFor="let option of options"
        [value]="option.value"
        class="color-gray15">
        {{ option.viewValue }}
    </mat-option>
</mat-select>
