@if (loading) {
    <app-loader />
} @else {
    @if (discrepancies.length <= 0) {
        No discrepancy found.
    } @else {
        <app-tabs
            [tabs]="tabsData"
            tabLabelClass="text-s flex-wrap"
            tabContentClass="ml-n4 pl-4"
            (tabSelected)="handleTabChange($event)"
            [initialSelectedTabIndex]="selectedTabIndex"
            (newClicked)="openAddDiscModal()">
            @if (detailsLoading) {
                <app-loader />
            } @else {
                @if (discDetail.id !== -1) {
                    <form class="pr-2" [formGroup]="editForm">
                        <div class="info-section bordered" *ngIf="discDetail">
                            <app-content-field label="ANALYST">
                                {{ discDetail.analyst?.displayName ?? '-' }}
                            </app-content-field>
                            <app-content-field label="STATUS">
                                <span appStatusBox>
                                    {{ discDetail.status }}
                                </span>
                            </app-content-field>

                            @if (readOnly || !isAdmin) {
                                <app-content-field label="PRIORITY">
                                    <span
                                        [appPriorityColor]="
                                            discDetail.priority
                                        "></span>
                                </app-content-field>
                            } @else {
                                <app-select
                                    label="PRIORITY"
                                    variant="secondary"
                                    style="min-width: 100px"
                                    formControlName="priority"
                                    [value]="discDetail.priority"
                                    [options]="editOptions.priority" />
                            }
                            <app-content-field label="CREATE DATE">
                                {{ discDetail.createdAt }}
                            </app-content-field>
                        </div>
                        <mat-divider />
                        <div class="info-section">
                            @if (readOnly) {
                                <app-content-field label="REASON">
                                    {{ discDetail.reason?.displayName }}
                                </app-content-field>
                            } @else {
                                <app-select
                                    label="REASON"
                                    [options]="editOptions.reason"
                                    formControlName="reason"
                                    [value]="discDetail.reason?.backingField" />
                            }
                            @if (readOnly) {
                                <app-content-field label="PAGE RANGE">
                                    {{ discDetail.pageRange || '-' }}
                                </app-content-field>
                            } @else {
                                <app-input
                                    label="PAGE RANGE"
                                    [required]="true"
                                    formControlName="pageRange"
                                    [control]="
                                        $any(editForm.get('pageRange'))
                                    " />
                            }
                        </div>
                        <div class="info-section">
                            @if (readOnly) {
                                <app-content-field label="RESPONSIBLE STATION">
                                    {{ discDetail.station?.displayName }}
                                </app-content-field>
                            } @else {
                                <app-select
                                    label="RESPONSIBLE STATION"
                                    formControlName="station"
                                    [value]="discDetail.station?.backingField"
                                    [options]="editOptions.station" />
                            }
                            @if (readOnly) {
                                <app-content-field label="RESPONSIBLE FIXER">
                                    {{
                                        discDetail.responsibleFixer
                                            ?.displayName ?? '-'
                                    }}
                                </app-content-field>
                            } @else {
                                <app-select
                                    label="RESPONSIBLE FIXER"
                                    formControlName="responsibleFixer"
                                    [value]="
                                        discDetail.responsibleFixer
                                            ?.backingField
                                    "
                                    [options]="editOptions.fixer" />
                            }
                        </div>
                        <div class="info-section">
                            @if (readOnly) {
                                <app-content-field label="DIRECTIONS">
                                    {{ discDetail.directions }}
                                </app-content-field>
                            } @else {
                                <app-input
                                    label="DIRECTIONS"
                                    [max]="maxDirectionsLength"
                                    formControlName="directions" />
                            }
                        </div>
                        @if (mode === 'detail' && !readOnly) {
                            <div class="info-section">
                                <app-select
                                    label="SELECT A VERSION TO SEND FOR CORRECTION:"
                                    formControlName="revision"
                                    [options]="editOptions.revision" />
                            </div>
                        }
                    </form>
                    <app-activity-log [discrepancyId]="discDetail.id" />
                    <ng-container *ngIf="mode === 'detail'">
                        <div class="actions ml-n4 pl-2 pr-4">
                            <div>
                                <app-text-button
                                    (clicked)="takeDiscrepancy()"
                                    [disabled]="!canTake">
                                    Take
                                </app-text-button>
                                <app-text-button
                                    (clicked)="putbackDiscrepancy()"
                                    [disabled]="!isAssignedToMe">
                                    Put back
                                </app-text-button>
                                <app-text-button
                                    *ngIf="isAdmin"
                                    [disabled]="!isAdmin"
                                    (clicked)="assignDiscrepancy()">
                                    Assign
                                </app-text-button>
                                <app-text-button
                                    *ngIf="isAdmin"
                                    [disabled]="!canUnassign"
                                    (clicked)="unassignDiscrepancy()">
                                    Unassign
                                </app-text-button>
                            </div>
                            <div class="flex items-center gap-10">
                                <app-text-button
                                    *ngIf="canRecall()"
                                    (clicked)="onRecallClick()">
                                    Recall
                                </app-text-button>
                                <app-flat-button
                                    *ngIf="canCancel()"
                                    variant="tertiary"
                                    (clicked)="onCancelClick()">
                                    Cancel Discrepancy
                                </app-flat-button>
                                <app-flat-button
                                    *ngIf="editOptions.canEdit"
                                    variant="tertiary"
                                    (clicked)="onSave()"
                                    [disabled]="!canSave">
                                    Save
                                </app-flat-button>
                                <app-flat-button
                                    *ngIf="canNotify()"
                                    variant="cta"
                                    (clicked)="onNotify()">
                                    Notify
                                </app-flat-button>
                                <app-flat-button
                                    *ngIf="canRenotify()"
                                    variant="cta"
                                    (clicked)="onRenotify()">
                                    Re-Notify
                                </app-flat-button>
                            </div>
                        </div>
                    </ng-container>
                } @else {
                    Something went wrong.
                }
            }
            @if (mode === 'detail' && documentDetails) {
                <app-document-upload
                    [discrepancies]="discrepancies"
                    [documentDetails]="documentDetails" />
            }
        </app-tabs>
        @if (mode === 'detail') {
            @if (discrepancies.length >= maxDiscrepancyCount) {
                <app-tooltip class="max-disc-tooltip pos-abs">
                    <app-flat-button
                        trigger
                        variant="tertiary"
                        [disabled]="true">
                        <span class="flex items-center text-sm">
                            <mat-icon svgIcon="add" />New
                        </span>
                    </app-flat-button>
                    <div content>
                        You can create only
                        <strong>{{ maxDiscrepancyCount }}</strong>
                        discrepancies.
                    </div>
                </app-tooltip>
            } @else {
                <app-flat-button
                    class="pos-abs"
                    style="top: -7px; right: 3px"
                    variant="tertiary"
                    (clicked)="openAddDiscModal()">
                    <span class="flex items-center text-sm">
                        <mat-icon svgIcon="add" />New</span
                    >
                </app-flat-button>
            }
        }
    }
}

@if (notifyLoading) {
    <app-loader type="overlay" size="xlarge" />
}
