<div class="snackbar">
    <div class="snackbar__header">
        <mat-icon
            *ngIf="data.variant === 'success'"
            svgIcon="check2"
            style="height: 20px"
            class="color-yellow-green7" />
        <mat-icon
            *ngIf="data.variant === 'error'"
            svgIcon="status-cancel"
            class="color-status-red4" />
        <span class="header text-sm text-bold color-gray15">
            {{ data.header }}
        </span>
        <mat-icon
            svgIcon="close"
            (click)="closeSnackbar()"
            class="cursor-pointer" />
    </div>
    <div *ngIf="data.content" class="snackbar__content text-xs">
        <div></div>
        <div>{{ data.content }}</div>
        <div></div>
    </div>
</div>
