import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class RouterService {
    constructor(private router: Router) {}

    goBack(refresh: boolean = false) {
        this.router.navigate(['..'], { state: { refresh } });
    }

    goLogin() {
        this.router.navigate(['/login']);
    }

    goHome() {
        this.router.navigate(['/']);
    }

    goDocumentDetails(id: number) {
        this.router.navigate(['documents-and-discrepancies', id]);
    }
}
