import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DocumentState } from '../state/document.state';

export const selectDocumentState =
    createFeatureSelector<DocumentState>('document');

export const selectId = createSelector(
    selectDocumentState,
    (state: DocumentState) => state.id,
);

export const selectSelectedRevision = createSelector(
    selectDocumentState,
    (state: DocumentState) => state.selectedRevision,
);

export const selectRevisionList = createSelector(
    selectDocumentState,
    (state: DocumentState) => state.revisionList,
);

export const selectDiscrepancyCount = createSelector(
    selectDocumentState,
    (state: DocumentState) => state.discrepancyCount,
);

export const selectOriginalDocumentId = createSelector(
    selectDocumentState,
    (state: DocumentState) => state.originalDocumentId,
);
