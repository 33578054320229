import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatAutocompleteModule,
    MatOption,
} from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SelectOption } from 'app/models/shared.model';

@Component({
    selector: 'app-autocomplete',
    templateUrl: 'autocomplete.component.html',
    styleUrl: 'autocomplete.component.scss',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        MatAutocompleteModule,
        MatIconModule,
        ReactiveFormsModule,
        AsyncPipe,
    ],
})
export class AutocompleteComponent implements OnInit {
    @Input() variant: 'primary' | 'secondary' = 'primary';
    @Input() placeholder: string = 'Filter';
    @Input() options: SelectOption[] = [];
    @Input() inputControl = new FormControl<string | SelectOption>('');

    @Output() selectionChange = new EventEmitter<{ value: string | number }>();

    filteredOptions: Observable<SelectOption[]> | undefined;
    disabled = false;

    ngOnInit() {
        this.disabled = this.options.length === 0;

        this.filteredOptions = this.inputControl.valueChanges.pipe(
            startWith(''),
            map((value) => {
                const name =
                    typeof value === 'string' ? value : value?.viewValue;
                return name
                    ? this._filter(name as string)
                    : this.options.slice();
            }),
        );
    }

    onSelectionChange(option: MatOption) {
        const selection: SelectOption = option.value;
        this.selectionChange.emit({ value: selection.value });
    }

    displayFn(option: SelectOption): string {
        return option && option.viewValue ? option.viewValue : '';
    }

    private _filter(name: string): SelectOption[] {
        const filterValue = name.toLowerCase();

        return this.options.filter((option) =>
            option.viewValue.toLowerCase().includes(filterValue),
        );
    }
}
