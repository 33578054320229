<app-panel
    [headerTitle]="headerTitle"
    headerPrimaryButtonLabel="Go to Details & Edit"
    [headerPrimaryButtonDisabled]="!canEditDocument"
    (primaryButtonClicked)="goToDetails()"
    (closeClicked)="closePanel()">
    <div class="summary-panel h-full">
        <div class="summary pt-4">
            <app-tabs
                [tabs]="tabsData"
                (tabSelected)="activeTab = $event"
                tabLabelClass="ml-5"
                tabContentClass="pl-5"
                [initialSelectedTabIndex]="initialSelectedTabIndex">
                <app-document-metadata-tab
                    *ngIf="activeTab === tabs.METADATA"
                    [documentId]="documentId" />
                <app-discrepancies-tab
                    *ngIf="activeTab === tabs.DISCREPANCIES"
                    [documentId]="documentId"
                    [selectedDiscrepancy]="selectedDiscrepancy" />
                <app-internals-tab
                    *ngIf="activeTab === tabs.WF_INTERNALS"
                    [documentId]="documentId" />
            </app-tabs>
        </div>
        <div class="drag-handle" wfeResizable></div>
        <div class="documents">
            <p class="documents__title flex items-center pl-5">Documents</p>
            <div></div>
        </div>
    </div>
</app-panel>
