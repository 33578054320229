export class Formats {
    static readonly DATE: string = 'dd MMM, yyyy, HH:mm';
}

export class Patterns {
    static readonly PLACEHOLDER = /{(.*?)}/g;
    static readonly PAGE_RANGE = /^(?!0$)([1-9]\d*(-[1-9]\d*)?)$/;
}

export class Cookies {
    static readonly UAA_ACCESS_TOKEN: string = 'uaa_access_token';
    static readonly OIDC_ACCESS_TOKEN: string = 'oidc_access_token';
}

export enum FileType {
    PDF = 'application/pdf',
    PNG = 'image/png',
    JPEG = 'image/jpeg',
}

export const fileTypesMap = {
    [FileType.PDF]: '.pdf',
    [FileType.PNG]: '.png',
    [FileType.JPEG]: '.jpg,.jpeg',
};
