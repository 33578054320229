export class DocumentRevisionsResponse {
    constructor(
        public canUpload: boolean,
        public interactionsStation: string | null,
        public discrepancyNos: number[],
        public versions: DocumentRevisionVersion[],
        public originalVersion: DocumentRevisionVersion,
    ) {}
}

export class DocumentRevisionVersion {
    constructor(
        public isOriginal: boolean,
        public revisionName: string,
        public relationKind: DocumentRevisionStatus[],
        public createdBy: string,
        public createdAt: string,
        public documentServiceId: string,
    ) {}
}

export class DocumentRevisionTableItem {
    constructor(
        public id: number,
        public isHeader: boolean,
        public isOriginal?: boolean,
        public revisionName?: string,
        public relationKind?: DocumentRevisionStatus[],
        public createdBy?: string,
        public createdAt?: string,
        public documentServiceId?: string,
        public sentForConnection?: boolean,
    ) {}
}

export enum DocumentRevisionStatus {
    ORIGIN,
    NO_ACTION,
    REJECTED,
    ACCEPTED,
    ACCEPTED_AND_APPLIED,
}
