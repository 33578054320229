<div>
    <div class="header text-sm flex items-center ml-n4 pl-4 bg-gray0">
        Documents
    </div>
    <div class="ml-n4 pl-4 pr-3 pt-3 bg-white">
        <div
            class="pos-rel"
            wfeDragDrop
            [maxFileCount]="1"
            [externalError]="!canUpload"
            (fileDropped)="onFileDrop($event)"
            [overlayMessage]="getOverlayMessage()">
            <div class="flex justify-space-between items-center mb-4 gap-4">
                <span class="text-sm text-med">
                    <b>
                        {{ documentDetails.name }} Versions ({{ versionCount }})
                    </b>
                </span>
                <app-flat-button
                    variant="tertiary"
                    [disabled]="!canUpload"
                    (clicked)="onUploadClick()"
                    class="upload-button">
                    <mat-icon svgIcon="upload" />
                    Upload Correction
                </app-flat-button>
            </div>
            <app-table
                variant="secondary"
                class="flex mb-4"
                [columnDefs]="columnDefs"
                [suppressMenu]="true"
                [suppressMovableColumns]="true"
                [suppressRowHoverHighlight]="true"
                [suppressCellFocus]="true"
                [pagination]="false"
                [pageSize]="-1"
                [getRowStyle]="getRowStyle"
                [gridOptions]="gridOptions"
                (gridReady)="onGridReady($event)" />
        </div>
        <app-document-status-legend />
    </div>
</div>
