import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DocumentService } from '@services/api/document/document.service';
import { UserService } from '@services/api/user/user.service';
import { ResizableDirective } from '@directives/resizable.directive';
import { TabsComponent } from '@shared/tabs/tabs.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { WebViewerComponent } from '@shared/webviewer/webviewer.component';
import { BrandingBarComponent } from '@shared/branding-bar/branding-bar.component';
import { InternalsTabComponent } from '../summary-panel/internals-tab/internals-tab.component';
import { DiscrepanciesTabComponent } from '../summary-panel/discrepancies-tab/discrepancies-tab.component';
import { createPDF, PDFDocument } from 'app/utils/helpers/file';
import { WorkflowDocument } from 'app/models/workflow.model';
import { DocumentMetadataEditComponent } from './document-metadata-edit/document-metadata-edit.component';

@Component({
    standalone: true,
    templateUrl: 'detail-dialog.component.html',
    styleUrl: 'detail-dialog.component.scss',
    imports: [
        CommonModule,
        BrandingBarComponent,
        MatIconModule,
        MatButtonModule,
        TabsComponent,
        InternalsTabComponent,
        DiscrepanciesTabComponent,
        ResizableDirective,
        WebViewerComponent,
        LoaderComponent,
        DocumentMetadataEditComponent,
    ],
    encapsulation: ViewEncapsulation.None,
})
export class DocumentDetailDialogComponent implements OnInit, OnDestroy {
    readonly tabs: typeof DetailDialogTab = DetailDialogTab;
    activeTab: DetailDialogTab | undefined;
    tabsData = [
        { id: DetailDialogTab.METADATA, label: 'Document Metadata' },
        { id: DetailDialogTab.DISCREPANCIES, label: 'Discrepancies' },
        { id: DetailDialogTab.WF_INTERNALS, label: 'WF Internals' },
    ];
    isAdminUser = false;
    documentDetails: WorkflowDocument | null = null;
    pdfDocument: PDFDocument | undefined;
    pdfLoading = false;

    constructor(
        private documentService: DocumentService,
        private userService: UserService,
        public dialogRef: MatDialogRef<DocumentDetailDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { id: number },
    ) {
        this.documentService.getDocumentDetails(this.data.id).subscribe({
            next: (data) => {
                this.documentDetails = data;
                this.getDocumentFile();
            },
        });
    }

    ngOnInit(): void {
        this.activeTab = this.tabsData[0].id;
        document.body.style.overflow = 'hidden';

        this.userService
            .isAdminUser()
            .subscribe((isAdmin) => (this.isAdminUser = isAdmin));
    }

    private getDocumentFile() {
        if (this.documentDetails?.documentServiceId) {
            this.pdfLoading = true;
            this.documentService
                .getDocumentFile(
                    this.data.id,
                    this.documentDetails.documentServiceId,
                )
                .subscribe({
                    next: (response) =>
                        (this.pdfDocument = createPDF(response)),
                    complete: () => (this.pdfLoading = false),
                });
        }
    }

    ngOnDestroy(): void {
        document.body.style.overflow = 'auto';
    }

    onCloseClick() {
        this.dialogRef.close();
    }
}

enum DetailDialogTab {
    METADATA,
    DISCREPANCIES,
    WF_INTERNALS,
}
