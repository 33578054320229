<app-web-viewer *ngIf="document.blob" [document]="document">
    <div
        header
        class="header text-sm text-semi flex gap-10 items-center justify-space-between">
        <div class="flex items-center">
            <mat-icon
                svgIcon="arrow-right"
                class="color-primary-default mr-1" />
            {{ document.name }}
            <app-tooltip *ngIf="revisionDetails && statuses" class="ml-1">
                <mat-icon trigger svgIcon="info" />
                <div content class="grid gap-5 text-s">
                    @for (item of getKeys(statuses); track $index) {
                        @if (statuses[item].show) {
                            <p
                                class="flex items-end"
                                [ngClass]="statuses[item].textClass">
                                <mat-icon [svgIcon]="statuses[item].icon" />
                                <span class="ml-2">
                                    {{ statuses[item].text }}
                                </span>
                            </p>
                        }
                    }
                </div>
            </app-tooltip>
        </div>
        <div class="flex gap-6">
            <app-flat-button
                *ngIf="canReject()"
                (clicked)="onRejectClick()"
                variant="danger">
                Reject
            </app-flat-button>
            <app-flat-button
                *ngIf="canAccept()"
                (clicked)="onAcceptClick()"
                variant="success">
                Accept
            </app-flat-button>
        </div>
    </div>
</app-web-viewer>
