import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-checkbox',
    standalone: true,
    templateUrl: 'checkbox.component.html',
    styleUrl: 'checkbox.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
    ],
})
export class CheckboxComponent {
    @Input() label: string = '';
    @Input() disabled: boolean = false;
    @Input() checked: boolean = false;
    @Output() checkedChange = new EventEmitter<boolean>();

    private onChange = (_value: boolean) => {};
    private onTouched = () => {};

    writeValue(value: boolean): void {
        this.checked = value;
    }

    registerOnChange(fn: (value: boolean) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    toggle(): void {
        if (!this.disabled) {
            this.checked = !this.checked;
            this.onChange(this.checked);
            this.checkedChange.emit(this.checked);
            this.onTouched();
        }
    }
}
