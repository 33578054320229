import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import endpoints from '@endpoints';
import { interpolate } from 'app/utils/helpers';
import { ApiService } from './api.service';
import { PagedResponse, SimpleResponse } from '../../models/api.model';
import { WorkflowMapper } from './workflow.mapper';
import { SharedMapper } from '@services/api/shared.mapper';
import {
    DiscrepancyFilterOptionsResponse,
    DiscrepancyFilterOptions,
} from 'app/models/discrepancy/filter-options';
import {
    DiscrepancyEditOptions,
    DiscrepancyEditOptionsResponse,
} from 'app/models/discrepancy/edit-options';
import { DropdownItem } from 'app/models/shared.model';
import {
    DiscrepanciesWithTotal,
    DiscrepanciesWithTotalResponse,
} from 'app/models/discrepancy/with-total';
import { DiscrepancyDetail } from 'app/models/discrepancy/detail';
import { ActivityLogItem } from 'app/models/discrepancy/activity-log';
import { StatusChipItem } from 'app/models/discrepancy/status-chip';
import { Discrepancy, DiscrepancyResponse } from 'app/models/discrepancy/item';
import { UpdateDiscrepancyRequest } from 'app/models/discrepancy/update-discrepancy-request';

@Injectable({
    providedIn: 'root',
})
export class WorkflowService {
    private readonly _path = 'workflow/v1';

    constructor(
        private api: ApiService,
        private mapper: WorkflowMapper,
        private sharedMapper: SharedMapper,
    ) {}

    getDiscrepanciesByDocumentId(
        id: number,
        params = {},
    ): Observable<DiscrepanciesWithTotal> {
        const url =
            this._path + interpolate(endpoints.documentsDiscrepancies, { id });

        return this.api.get<Discrepancy[]>(url, params).pipe(
            map((response: SimpleResponse<DiscrepanciesWithTotalResponse>) => {
                return this.mapper.toDiscrepanciesWithTotal(response.data);
            }),
            catchError(() => of(new DiscrepanciesWithTotal([], 0))),
        );
    }

    getAllDiscrepancies(params = {}) {
        return this.api
            .get<
                Discrepancy[]
            >(this._path + endpoints.discrepancies, params, true)
            .pipe(
                map((response) => {
                    const pagedResponse =
                        response as PagedResponse<DiscrepancyResponse>;
                    return new PagedResponse<Discrepancy>(
                        pagedResponse.pageIndex,
                        pagedResponse.pageSize,
                        pagedResponse.totalItems,
                        pagedResponse.items.map(this.mapper.toDiscrepancy),
                    );
                }),
            );
    }

    getWorkflows(): Observable<DropdownItem[]> {
        return this.api
            .get<DropdownItem[]>(this._path + endpoints.workflows)
            .pipe(
                map((response: SimpleResponse<DropdownItem[]>) =>
                    response.data.map(this.sharedMapper.toDropdownItem),
                ),
                catchError(() => of([])),
            );
    }

    getDiscrepancyDetails(id: number): Observable<DiscrepancyDetail> {
        return this.api
            .get(this._path + interpolate(endpoints.discrepancyDetails, { id }))
            .pipe(
                map((response: SimpleResponse<DiscrepancyDetail>) =>
                    this.mapper.toDiscrepancyDetail(response.data),
                ),
                catchError(() => of(DiscrepancyDetail.initial())),
            );
    }

    getDiscrepanciesFilterOptions(
        params:
            | {
                  workflow: number;
                  repository: number[];
              }
            | undefined,
    ) {
        return this.api
            .get(this._path + endpoints.discrepanciesFilterOptions, params)
            .pipe(
                map(
                    (
                        response: SimpleResponse<DiscrepancyFilterOptionsResponse>,
                    ) => this.mapper.toDiscrepancyFilterOptions(response.data),
                ),
                catchError(() => of(DiscrepancyFilterOptions.initial())),
            );
    }

    getDiscrepancyEditOptions(id: number): Observable<DiscrepancyEditOptions> {
        return this.api
            .get(
                this._path +
                    interpolate(endpoints.discrepancyEditOptions, { id }),
            )
            .pipe(
                map(
                    (
                        response: SimpleResponse<DiscrepancyEditOptionsResponse>,
                    ) => this.mapper.toDiscrepancyEditOptions(response.data),
                ),
                catchError(() => of(DiscrepancyEditOptions.initial())),
            );
    }

    getDiscrepanciesStatusChips(
        params: { workflow: number; repository: any } | undefined,
    ): Observable<StatusChipItem[]> {
        return this.api
            .get(this._path + endpoints.discrepancyStatusChips, params)
            .pipe(
                map((response: SimpleResponse<StatusChipItem[]>) =>
                    (response.data ?? []).map(this.mapper.toStatusChipItem),
                ),
                catchError(() => of([])),
            );
    }

    getDiscrepancyActivityLogs(
        id: number,
        params = {},
    ): Observable<ActivityLogItem[]> {
        return this.api
            .get(
                this._path +
                    interpolate(endpoints.discrepancyActivityLogs, { id }),
                params,
            )
            .pipe(
                map((response: SimpleResponse<ActivityLogItem[]>) =>
                    (response.data ?? []).map(this.mapper.toActivityLogItem),
                ),
                catchError(() => of([])),
            );
    }

    addDiscrepancyToDocument(
        id: number,
        data: UpdateDiscrepancyRequest,
    ): Observable<DiscrepancyDetail | null> {
        return this.api
            .post(
                this._path +
                    interpolate(endpoints.documentsDiscrepancies, { id }),
                data,
            )

            .pipe(
                map((response: SimpleResponse<DiscrepancyDetail>) =>
                    this.mapper.toDiscrepancyDetail(response.data),
                ),
                catchError(() => of(null)),
            );
    }
}
