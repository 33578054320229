import { QuestionFieldType } from '@shared/question/question-base';
import { SelectOption } from 'app/components/shared/select/select.component';

export enum Priority {
    Low = '0',
    Normal = '1',
    High = '2',
    Critical = '3',
}

export class WorkflowDocument {
    constructor(
        public id: number,
        public name: string,
        public documentType: string,
        public repository: string,
        public priority: Priority,
        public createdAt: string,
        public station: string,
        public documentServiceId: string | null,
    ) {}
}

export class MetadataItem {
    constructor(
        public fieldLabel: string,
        public fieldValue: string,
        public ndx: number,
        public isRequired: boolean,
    ) {}
}

export class MetadataOption {
    constructor(
        public value: string,
        public fieldName: string,
        public label: string,
        public isRequired: boolean,
        public order: number,
        public fieldType: QuestionFieldType,
        public dataType: string,
        public options: string[],
        public maxLength: number,
        public readonly: boolean,
        public section: string,
        public size: number,
    ) {}
}

export class DocumentQueryParam {
    constructor(
        public workflow: number,
        public repository: number[],
    ) {}
}

export class DocumentInternals {
    constructor(
        public tenantId: number,
        public repositoryId: number,
        public workflowId: number,
        public workflowDocumentId: number,
        public createdAt: string,
    ) {}
}

export class DocumentFilterOptions {
    constructor(
        public documentType: IdDisplayPair[],
        public priority: IdDisplayPair[],
        public repository: IdDisplayPair[],
        public station: IdDisplayPair[],
    ) {}

    static initial(): DocumentFilterOptions {
        return new DocumentFilterOptions([], [], [], []);
    }
}

export class DocDiscrepancyCreateOptionsResponse {
    constructor(
        public reason: IdDisplayPair[],
        public station: IdDisplayPair[],
        public fixer: IdDisplayPair[],
    ) {}
}

export class DocDiscrepancyCreateOptions {
    constructor(
        public reason: SelectOption[],
        public station: SelectOption[],
        public fixer: SelectOption[],
    ) {}

    static initial(): DocDiscrepancyCreateOptions {
        return new DocDiscrepancyCreateOptions([], [], []);
    }
}

export class IdDisplayPair {
    constructor(
        public backingField: number,
        public displayName: string,
    ) {}
}

export class DiscrepancyResponse {
    constructor(
        public id: number,
        public reason: IdDisplayPair | null,
        public priority: Priority,
        public status: string,
        public createdAt: string,
        public updatedAt: string,
        public taken: string,
        public analyst: IdDisplayPair | null,
        public station: IdDisplayPair | null,
        public responsibleFixer: IdDisplayPair | null,
        public documentName: string,
        public documentId: number,
        public discrepancyNo: number,
    ) {}
}

export class Discrepancy {
    constructor(
        public id: number,
        public reason: string,
        public priority: Priority,
        public status: string,
        public createdAt: string,
        public updatedAt: string,
        public taken: string,
        public analyst: string,
        public station: string,
        public responsibleFixer: string,
        public documentName: string,
        public documentId: number,
        public discrepancyNo: number,
    ) {}
}

export enum DiscrepancyActionCode {
    NOTIFY,
    RENOTIFY,
    CANCEL,
    RECALL,
}

export class DiscrepancyAction {
    constructor(
        public id: number,
        public action: DiscrepancyActionCode,
        public label: string,
    ) {}
}

export class DiscrepancyDetail {
    constructor(
        public id: number,
        public pageRange: string,
        public directions: string,
        public createdAt: string,
        public reason: IdDisplayPair | null,
        public priority: Priority,
        public status: string,
        public analyst: IdDisplayPair | null,
        public station: IdDisplayPair | null,
        public responsibleFixer: IdDisplayPair | null,
        public documentServiceId: string,
        public canCancel: boolean,
        public actions: DiscrepancyAction[],
        public revision: IdDisplayPair | null,
    ) {}

    static initial(): DiscrepancyDetail {
        return new DiscrepancyDetail(
            -1,
            '',
            '',
            '',
            null,
            Priority.Normal,
            '',
            null,
            null,
            null,
            '',
            false,
            [],
            null,
        );
    }
}

export class DiscrepancyFilterOptions {
    constructor(
        public priority: IdDisplayPair[] = [],
        public reason: IdDisplayPair[] = [],
        public status: IdDisplayPair[] = [],
        public station: IdDisplayPair[] = [],
        public responsibleFixer: IdDisplayPair[] = [],
    ) {}

    static initial(): DiscrepancyFilterOptions {
        return new DiscrepancyFilterOptions();
    }
}

export class DiscrepancyEditOptionsResponse {
    constructor(
        public priority: IdDisplayPair[],
        public reason: IdDisplayPair[],
        public station: IdDisplayPair[],
        public fixer: IdDisplayPair[],
        public canEdit: boolean,
        public analyst: IdDisplayPair[] | null,
        public revision: IdDisplayPair[],
    ) {}
}

export class DiscrepancyEditOptions {
    constructor(
        public priority: SelectOption[],
        public reason: SelectOption[],
        public station: SelectOption[],
        public fixer: SelectOption[],
        public canEdit: boolean,
        public analyst: SelectOption[],
        public revision: SelectOption[],
    ) {
        this.priority = priority;
        this.reason = reason;
        this.station = station;
        this.fixer = fixer;
        this.canEdit = canEdit;
        this.analyst = analyst;
        this.revision = revision;
    }

    static initial(): DiscrepancyEditOptions {
        return new DiscrepancyEditOptions([], [], [], [], false, [], []);
    }
}

export class UpdateDiscrepancyRequest {
    constructor(
        public priority?: Priority,
        public reason?: number,
        public pageRange?: string,
        public station?: number,
        public fixer?: number,
        public directions?: string,
    ) {}
}

export class StatusChipItem {
    constructor(
        public assigned: number,
        public backingField: number,
        public name: string,
        public total: number,
        public unassigned: number,
    ) {}
}

export class DropdownItem {
    constructor(
        public id: number,
        public name: string,
    ) {}
}

export class ActivityLogItem {
    constructor(
        public id: number,
        public user: string,
        public logMessage: string,
        public createdAt: string,
    ) {}
}

export class WorkflowDocumentPermissionResponse {
    constructor(
        public id: number,
        public name: string,
        public isMine: boolean,
        public createdAt: string,
    ) {}
}
