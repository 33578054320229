<label class="flex items-center">
    <input
        type="checkbox"
        (change)="toggle()"
        [checked]="checked"
        [disabled]="disabled"
        [ngClass]="{
            medium: size === 'medium',
            small: size === 'small',
        }" />
    <span
        [ngClass]="{
            'ml-2 text-sm': size === 'medium',
            'ml-1 text-xs': size === 'small',
        }">
        {{ label }}
    </span>
</label>
