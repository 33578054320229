import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import {
    DateRange,
    MatCalendar,
    MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import moment, { Moment } from 'moment';
import { IFloatingFilterAngularComp } from 'ag-grid-angular';
import { IFloatingFilterParams, ISimpleFilter } from 'ag-grid-community';

import { SharedMapper } from '@services/api/shared.mapper';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { InputComponent } from '@shared/input/input.component';
import { ContentFieldComponent } from '@shared/content-field/content-field.component';
import { DATE_FORMAT } from 'app/utils/constants/date';

@Component({
    standalone: true,
    templateUrl: './date-filter.component.html',
    styleUrl: './date-filter.component.scss',
    imports: [
        CommonModule,
        MatIconModule,
        MatMenuModule,
        MatDividerModule,
        MatDatepickerModule,
        TextButtonComponent,
        FlatButtonComponent,
        InputComponent,
        ContentFieldComponent,
    ],
    providers: [provideMomentDateAdapter(DATE_FORMAT)],
})
export class DateFilterComponent implements IFloatingFilterAngularComp {
    params!: IFloatingFilterParams<ISimpleFilter>;
    selectedRangeValue: DateRange<any> | undefined;
    selectedOption: RangeOption | null = null;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;
    @ViewChild('calendar', { static: false }) calendar!: MatCalendar<Date>;

    constructor(private sharedMapper: SharedMapper) {}

    get startDate() {
        return this.selectedRangeValue?.start
            ? this.sharedMapper.toDate(this.selectedRangeValue!.start)
            : '-';
    }

    get endDate() {
        return this.selectedRangeValue?.end
            ? this.sharedMapper.toDate(this.selectedRangeValue!.end)
            : '-';
    }

    agInit(params: IFloatingFilterParams<ISimpleFilter>): void {
        this.params = params;
        this.selectedRangeValue = new DateRange<Moment>(null, null);
    }

    onParentModelChanged(parentModel: any) {
        if (parentModel) {
            // console.log(parentModel);
        }
    }

    refresh(): boolean {
        return false;
    }

    selectedChange(m: any) {
        if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
            this.selectedRangeValue = new DateRange<Moment>(m, null);
        } else {
            const start = this.selectedRangeValue.start;
            const end = m;
            if (end < start) {
                this.selectedRangeValue = new DateRange<Moment>(end, start);
            } else {
                this.selectedRangeValue = new DateRange<Moment>(start, end);
            }
        }
        this.selectedOption = 'custom';
    }

    apply() {
        this.onValueChange();
        if (this.trigger) this.trigger.closeMenu();
    }

    close() {
        this.selectedOption = null;
        this.selectedRangeValue = new DateRange<Moment>(null, null);
        this.onValueChange();
        if (this.trigger) this.trigger.closeMenu();
    }

    selectToday() {
        const today = moment();
        this.selectedRangeValue = new DateRange(today, today);
        this.selectedOption = 'today';
        this.goToDate(today);
    }

    selectLastWeekend() {
        const today = moment();
        const lastSaturday = today.clone().day(-1);
        const lastSunday = today.clone().day(0);
        const start = lastSaturday;
        const end = lastSunday;

        this.selectedRangeValue = new DateRange(start, end);
        this.selectedOption = 'lastWeekend';
        this.goToDate(today);
    }

    selectWeekToDate() {
        const startOfWeek = moment().startOf('isoWeek');
        const today = moment();

        this.selectedRangeValue = new DateRange(startOfWeek, today);
        this.selectedOption = 'weekToDate';
        this.goToDate(startOfWeek);
    }

    selectMonthToDate() {
        const startOfMonth = moment().startOf('month');
        const today = moment();

        this.selectedRangeValue = new DateRange(startOfMonth, today);
        this.selectedOption = 'monthToDate';
        this.goToDate(startOfMonth);
    }

    selectLastMonth() {
        const startOfLastMonth = moment()
            .subtract(1, 'months')
            .startOf('month');
        const endOfLastMonth = moment().subtract(1, 'months').endOf('month');

        this.selectedRangeValue = new DateRange(
            startOfLastMonth,
            endOfLastMonth,
        );
        this.selectedOption = 'lastMonth';
        this.goToDate(startOfLastMonth);
    }

    selectLastYear() {
        const startOfLastYear = moment().subtract(1, 'years').startOf('year');
        const endOfLastYear = moment().subtract(1, 'years').endOf('year');

        this.selectedRangeValue = new DateRange(startOfLastYear, endOfLastYear);
        this.selectedOption = 'lastYear';
        this.goToDate(startOfLastYear);
    }

    goToDate(date: any) {
        this.calendar._goToDateInView(date, 'month');
    }

    onValueChange() {
        this.params.parentFilterInstance((instance: ISimpleFilter) => {
            const startAndEndDate = this.selectedRangeValue?.start
                ? JSON.stringify({
                      start: this.selectedRangeValue?.start,
                      end: this.selectedRangeValue?.end,
                  })
                : undefined;
            instance.onFloatingFilterChanged('contains', startAndEndDate);
        });
    }
}

type RangeOption =
    | 'custom'
    | 'today'
    | 'lastWeekend'
    | 'weekToDate'
    | 'monthToDate'
    | 'lastMonth'
    | 'lastYear';
