import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import endpoints from '@endpoints';
import { ApiService } from '../api.service';
import { interpolate } from 'app/utils/helpers';
import { UpdateDiscrepancyRequest } from 'app/models/discrepancy/update-discrepancy-request';

@Injectable({
    providedIn: 'root',
})
export class DiscrepancyService {
    private readonly _path = 'workflow/v1';

    constructor(private api: ApiService) {}

    editDiscrepancy(
        id: number,
        data: UpdateDiscrepancyRequest,
    ): Observable<boolean> {
        return this.api
            .put(
                this._path + interpolate(endpoints.discrepancyById, { id }),
                data,
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    notifyDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(this._path + interpolate(endpoints.notifyDiscrepancy, { id }))
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    renotifyDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(
                this._path + interpolate(endpoints.renotifyDiscrepancy, { id }),
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    cancelDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(this._path + interpolate(endpoints.cancelDiscrepancy, { id }))
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    recallDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(this._path + interpolate(endpoints.recallDiscrepancy, { id }))
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    discardDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(
                this._path + interpolate(endpoints.discardDiscrepancy, { id }),
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    takeDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(this._path + interpolate(endpoints.takeDiscrepancy, { id }))
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    putbackDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(
                this._path + interpolate(endpoints.putbackDiscrepancy, { id }),
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    assignDiscrepancy(
        id: number,
        data: { userId: number },
    ): Observable<boolean> {
        return this.api
            .post(
                this._path + interpolate(endpoints.assignDiscrepancy, { id }),
                data,
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    unassignDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(
                this._path + interpolate(endpoints.unassignDiscrepancy, { id }),
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }
}
