<div>
    <label>
        <span
            [ngClass]="{
                'text-xxs color-gray14': true,
                'color-status-red4':
                    (control && control.invalid) || (required && !value),
            }">
            {{ label }} {{ required ? '*' : '' }}
        </span>
        <input
            [type]="type"
            [value]="value"
            (input)="onInput($event)"
            class="input block w-full"
            [attr.placeholder]="placeholder"
            [attr.maxlength]="max" />

        <div *ngIf="max" class="text-xs text-end">
            {{ value.length }}/{{ max }}
        </div>
    </label>
    <app-validation-error *ngIf="control" [control]="control" />
</div>
