import { Injectable } from '@angular/core';
import { Observable, map, catchError, of } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

import endpoints from '@endpoints';
import { ApiService } from '@services/api/api.service';
import { interpolate } from 'app/utils/helpers';
import { RejectRevisionRequest } from 'app/models/revision/reject-revision-request';
import { RevisionDetails } from 'app/models/revision/revision-detail';
import { SimpleResponse } from 'app/models/api.model';

@Injectable({
    providedIn: 'root',
})
export class RevisionService {
    private readonly _path = 'workflow/v1';

    constructor(private api: ApiService) {}

    rejectRevision(
        id: number,
        data: RejectRevisionRequest,
    ): Observable<boolean> {
        return this.api
            .post<RejectRevisionRequest>(
                this._path + interpolate(endpoints.rejectRevision, { id }),
                data,
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    acceptRevision(id: number): Observable<boolean> {
        return this.api
            .post(this._path + interpolate(endpoints.acceptRevision, { id }))
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    getRevisionDetails(id: number): Observable<RevisionDetails | null> {
        return this.api
            .get(this._path + interpolate(endpoints.revisionById, { id }))
            .pipe(
                map(
                    (response: SimpleResponse<RevisionDetails>) =>
                        response.data,
                ),
                catchError(() => of(null)),
            );
    }

    getRevisionFile(id: number): Observable<HttpResponse<Blob> | null> {
        return this.api
            .getBlob(this._path + interpolate(endpoints.revisionFile, { id }))
            .pipe(catchError(() => of(null)));
    }
}
