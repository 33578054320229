import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import {
    ColDef,
    GridApi,
    GridOptions,
    GridReadyEvent,
    IServerSideDatasource,
} from 'ag-grid-community';

import { SnackbarService } from '@services/snackbar.service';
import { SharedMapper } from '@services/api/shared.mapper';
import { DocumentService } from '@services/api/document/document.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { UploadCorrectionDialogComponent } from '../../upload-correction-dialog/upload-correction-dialog.component';
import { TableComponent } from '@shared/table/table.component';
import { TextFilterComponent } from '@shared/table/filter/text-filter/text-filter.component';
import { DiscrepancyHeaderComponent } from './discrepancy-header.component';
import { DocStatusLegendComponent } from './status-legend.component';
import { Discrepancy, WorkflowDocument } from 'app/models/workflow.model';
import { VersionNameCellComponent } from './version-name-cell.component';
import { DiscrepancyCellComponent } from './discrepancy-cell.component';
import {
    DocumentRevisionsResponse,
    DocumentRevisionTableItem,
} from 'app/models/document-revision';

@Component({
    standalone: true,
    selector: 'app-document-upload',
    imports: [
        CommonModule,
        MatIconModule,
        MatDividerModule,
        FlatButtonComponent,
        TableComponent,
        DocStatusLegendComponent,
    ],
    templateUrl: 'document-upload.component.html',
    styleUrl: 'document-upload.component.scss',
})
export class DocumentUploadComponent {
    @Input({ required: true }) documentDetails!: WorkflowDocument;
    @Input() discrepancies: Discrepancy[] = [];

    versionCount: number | null = null;
    columnDefs: ColDef[] = [];
    gridOptions: GridOptions = {
        autoSizeStrategy: {
            type: 'fitCellContents',
        },
    };
    private _gridApi!: GridApi;

    constructor(
        public dialog: MatDialog,
        private sharedMapper: SharedMapper,
        private documentService: DocumentService,
        private snackbarService: SnackbarService,
    ) {}

    onGridReady(event: GridReadyEvent) {
        this._gridApi = event.api;

        const datasource = this.getDocumentVersions();
        this._gridApi.setGridOption('serverSideDatasource', datasource);
    }

    onUploadClick() {
        const dialogRef = this.dialog.open(UploadCorrectionDialogComponent, {
            data: {
                id: this.documentDetails.id,
                discrepancies: this.discrepancies,
            },
            maxWidth: '600px',
            minWidth: '200px',
            width: '600px',
        });

        dialogRef.afterClosed().subscribe({
            next: (success) => {
                if (success) {
                    this.snackbarService.success({
                        header: `Document revision uploaded successfully.`,
                    });

                    this.refreshData();
                }
            },
        });
    }

    getRowStyle = (params: any) => {
        if (params?.data?.isHeader) {
            return { backgroundColor: '#f2f5f7' };
        }
        return undefined;
    };

    private getDocumentVersions(): IServerSideDatasource {
        return {
            getRows: (params) => {
                this.documentService
                    .getDocumentRevisions(this.documentDetails.id)
                    .subscribe((data) => {
                        if (data) {
                            this.versionCount = data.versions.length + 1;
                            const rowData = this.getDocRevisionTableDate(data);
                            params.success({
                                rowData: rowData,
                                rowCount: rowData.length,
                            });
                            this._gridApi!.setGridOption(
                                'columnDefs',
                                this.getColumnDefs(data.discrepancyNos),
                            );
                        } else {
                            params.fail();
                        }
                    });
            },
        };
    }

    private getDocRevisionTableDate(
        data: DocumentRevisionsResponse,
    ): DocumentRevisionTableItem[] {
        const tableData: DocumentRevisionTableItem[] = [];
        tableData.push(
            new DocumentRevisionTableItem(0, true, false, 'Interaction'),
        );
        data.versions.forEach((item, index) => {
            tableData.push(
                new DocumentRevisionTableItem(
                    index + 1,
                    false,
                    item.isOriginal,
                    item.revisionName,
                    item.relationKind,
                    item.createdBy,
                    item.createdAt,
                    item.documentServiceId,
                    item.isOriginal ? true : false,
                ),
            );
        });
        tableData.push(
            new DocumentRevisionTableItem(
                data.versions.length + 1,
                true,
                false,
                'Original Document',
            ),
        );
        tableData.push(
            new DocumentRevisionTableItem(
                data.versions.length + 2,
                false,
                true,
                data.originalVersion.revisionName,
                data.originalVersion.relationKind,
                data.originalVersion.createdBy,
                data.originalVersion.createdAt,
                data.originalVersion.documentServiceId,
            ),
        );

        return tableData;
    }

    private getColumnDefs(discrepancyNos: number[] = []) {
        return [
            {
                field: 'revisionName',
                headerName: 'Version Name',
                filter: 'agTextColumnFilter',
                floatingFilterComponent: TextFilterComponent,
                cellRenderer: VersionNameCellComponent,
                flex: 1,
                cellRendererParams: {},
                cellStyle: (params: { data: { isHeader: any } }) => {
                    if (params?.data?.isHeader) {
                        return {
                            backgroundColor: '#F2F5F7',
                            overflow: 'visible',
                        };
                    }
                    return null;
                },
            },
            {
                field: 'relationKind',
                floatingFilter: false,
                flex: 2,
                headerComponent: DiscrepancyHeaderComponent,
                headerComponentParams: {
                    relationKind: discrepancyNos,
                },
                cellRenderer: DiscrepancyCellComponent,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            {
                field: 'createdBy',
                headerName: 'By',
                filter: 'agTextColumnFilter',
                floatingFilterComponent: TextFilterComponent,
            },
            {
                field: 'createdAt',
                headerName: 'Date',
                filter: 'agTextColumnFilter',
                valueGetter: (p: { data: DocumentRevisionTableItem }) => {
                    try {
                        const dateValue = p.data.createdAt
                            ? this.sharedMapper.toDate(p.data.createdAt)
                            : p.data.isHeader
                              ? ''
                              : '-';
                        return dateValue;
                    } catch {
                        return '';
                    }
                },

                floatingFilterComponent: TextFilterComponent,
                resizable: false,
            },
        ];
    }

    refreshData() {
        this._gridApi.refreshServerSide({ purge: true });
    }
}
