import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { DocumentService } from '@services/api/document/document.service';
import { UserService } from '@services/api/user/user.service';
import { ResizableDirective } from '@directives/resizable.directive';
import { TabsComponent } from '@shared/tabs/tabs.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { WebViewerComponent } from '@shared/webviewer/webviewer.component';
import { BrandingBarComponent } from '@shared/branding-bar/branding-bar.component';
import { InternalsTabComponent } from '../internals-tab/internals-tab.component';
import { DiscrepanciesTabComponent } from '../discrepancies-tab/discrepancies-tab.component';
import { createPDFDocument, PDFDocument } from 'app/utils/helpers/file';
import { DocumentMetadataEditComponent } from './document-metadata-edit/document-metadata-edit.component';
import {
    SubscriptionList,
    SubscriptionListType,
} from 'app/utils/helpers/subscription';
import { WorkflowDocument } from 'app/models/document/item';
import { RevisionService } from '@services/api/revision/revision.service';
import { DocumentRevisionItem } from 'app/models/document';
import { selectSelectedRevision } from 'app/store/selectors/document.selectors';
import { updateSelectedRevision } from 'app/store/actions/document.actions';
import { RevisionDetails } from 'app/models/revision/revision-detail';
import { DocumentPreviewComponent } from './document-preview/document-preview.component';
import { Actions, ofType } from '@ngrx/effects';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';

@Component({
    standalone: true,
    templateUrl: 'detail-dialog.component.html',
    styleUrl: 'detail-dialog.component.scss',
    imports: [
        CommonModule,
        BrandingBarComponent,
        MatIconModule,
        MatButtonModule,
        TabsComponent,
        InternalsTabComponent,
        DiscrepanciesTabComponent,
        ResizableDirective,
        WebViewerComponent,
        LoaderComponent,
        DocumentMetadataEditComponent,
        DocumentPreviewComponent,
    ],
    encapsulation: ViewEncapsulation.None,
})
export class DocumentDetailDialogComponent implements OnInit, OnDestroy {
    readonly tabs: typeof DetailDialogTab = DetailDialogTab;
    documentRevision$: Observable<DocumentRevisionItem | null> | undefined;
    documentRevision: DocumentRevisionItem | null = null;
    activeTab: DetailDialogTab | undefined;
    tabsData = [
        { id: DetailDialogTab.METADATA, label: 'Document Metadata' },
        { id: DetailDialogTab.DISCREPANCIES, label: 'Discrepancies' },
        { id: DetailDialogTab.WF_INTERNALS, label: 'WF Internals' },
    ];
    isAdminUser = false;
    documentDetails: WorkflowDocument | null = null;
    documentFile: PDFDocument | null = null;
    documentLoading = false;
    revisionDetails: RevisionDetails | null = null;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    constructor(
        private store: Store,
        private documentService: DocumentService,
        private userService: UserService,
        private revisionService: RevisionService,
        public dialogRef: MatDialogRef<DocumentDetailDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { id: number },
        private actions$: Actions,
    ) {
        this.getDocumentDetails(this.data.id);
    }

    ngOnInit(): void {
        this.activeTab = this.tabsData[0].id;
        document.body.style.overflow = 'hidden';

        this._subscriptions['is-admin-user'] = this.userService
            .isAdminUser()
            .subscribe((isAdmin) => (this.isAdminUser = isAdmin));

        this.onDocumentRevisionChange();
        this.handleComponentRefresh();
    }

    ngOnDestroy(): void {
        document.body.style.overflow = 'auto';
        this.store.dispatch(updateSelectedRevision({ selectedRevision: null }));
        this._subscriptions.unsubscribeAllSafe();
    }

    onCloseClick() {
        this.dialogRef.close();
    }

    private onDocumentRevisionChange() {
        this.documentRevision$ = this.store.select(selectSelectedRevision);
        this._subscriptions['document-revision'] =
            this.documentRevision$.subscribe({
                next: (revision) => {
                    if (revision) {
                        this.documentRevision = revision;
                        this.getDocumentFile(revision.documentServiceId!);
                        this.getRevisionDetails();
                    }
                },
            });
    }

    private getDocumentDetails(id: number) {
        this.documentService.getDocumentDetails(id).subscribe({
            next: (data) => {
                if (data) {
                    this.documentDetails = data;
                    if (this.documentDetails) {
                        this.getDocumentFile(
                            this.documentDetails?.documentServiceId,
                        );
                    }
                }
            },
        });
    }

    private getDocumentFile(documentServiceId: string | null) {
        if (documentServiceId) {
            this.documentLoading = true;
            this.documentFile = null;
            this._subscriptions['get-document-file'] = this.documentService
                .getDocumentFile(this.data.id, documentServiceId)
                .subscribe({
                    next: (response) =>
                        (this.documentFile = createPDFDocument(response)),
                    complete: () => (this.documentLoading = false),
                });
        }
    }

    private getRevisionDetails() {
        if (this.documentRevision) {
            this._subscriptions['get-revision-details'] = this.revisionService
                .getRevisionDetails(this.documentRevision.revisionId!)
                .subscribe({
                    next: (response) => (this.revisionDetails = response),
                });
        }
    }

    private handleComponentRefresh() {
        this.actions$.pipe(ofType(refreshActionRelatedData)).subscribe({
            next: (refresh) => {
                if (refresh) this.getRevisionDetails();
            },
        });
    }
}

enum DetailDialogTab {
    METADATA,
    DISCREPANCIES,
    WF_INTERNALS,
}
