import { CommonModule, NgClass } from '@angular/common';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormsModule,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
} from '@angular/forms';

import { ValidationErrorComponent } from '@shared/validation-error/validation-error.component';

@Component({
    selector: 'app-input',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ValidationErrorComponent,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
    templateUrl: './input.component.html',
    styleUrl: './input.component.scss',
})
export class InputComponent implements ControlValueAccessor {
    value: string = '';

    onChange = (_value: string) => {};
    onTouched = () => {};

    @Input() label: string = '';
    @Input() type: string = '';
    @Input() placeholder = '';
    @Input() max: number | null = null;
    @Input() initialValue: string = '';
    @Input() required: boolean = false;
    @Input() control: FormControl | null = null;

    onInput(event: Event) {
        const input = event.target as HTMLInputElement;
        this.value = input.value;
        this.onChange(this.value);
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
