<div
    class="text-s text-med flex items-center gap-5"
    [matMenuTriggerFor]="dateFilterMenu">
    <mat-icon svgIcon="calendar" /> {{ selectedOption ? startDate : 'Filter' }}
</div>

<mat-menu
    #dateFilterMenu="matMenu"
    xPosition="after"
    yPosition="below"
    [overlapTrigger]="false">
    <div
        tabindex="0"
        style="width: 440px"
        class="overflow-hidden"
        (click)="$event.stopPropagation()"
        (keyup.enter)="$event.stopPropagation()">
        <div class="text-sm p-2 pl-4 pb-3">
            Select Date Range or a Specific Date
        </div>
        <div class="date-filter">
            <div
                class="flex flex-column whitespace-nowrap date-selection text-sm">
                <div
                    class="custom"
                    [class.selected]="selectedOption === 'custom'">
                    Custom
                </div>
                <div
                    tabindex="0"
                    (click)="selectToday()"
                    (keyup.enter)="selectToday()"
                    [class.selected]="selectedOption === 'today'">
                    Today
                </div>
                <div
                    tabindex="0"
                    (click)="selectLastWeekend()"
                    (keyup.enter)="selectLastWeekend()"
                    [class.selected]="selectedOption === 'lastWeekend'">
                    Last weekend
                </div>
                <div
                    tabindex="0"
                    (click)="selectWeekToDate()"
                    (keyup.enter)="selectWeekToDate()"
                    [class.selected]="selectedOption === 'weekToDate'">
                    Week to date
                </div>
                <div
                    tabindex="0"
                    (click)="selectMonthToDate()"
                    (keyup.enter)="selectMonthToDate()"
                    [class.selected]="selectedOption === 'monthToDate'">
                    Month to date
                </div>
                <div
                    tabindex="0"
                    (click)="selectLastMonth()"
                    (keyup.enter)="selectLastMonth()"
                    [class.selected]="selectedOption === 'lastMonth'">
                    Last month
                </div>
                <div
                    tabindex="0"
                    (click)="selectLastYear()"
                    (keyup.enter)="selectLastYear()"
                    [class.selected]="selectedOption === 'lastYear'">
                    Last year
                </div>
            </div>
            <div>
                <div class="flex gap-10 pl-5 pr-5 mt-2">
                    <app-content-field
                        label="START DATE *"
                        class="flex-1 selected-date">
                        {{ startDate }}
                    </app-content-field>
                    <app-content-field
                        label="END DATE (OPTIONAL)"
                        class="selected-date">
                        {{ endDate }}
                    </app-content-field>
                </div>
                <mat-calendar
                    #calendar
                    class="w-full"
                    [selected]="selectedRangeValue"
                    (selectedChange)="selectedChange($event)" />
            </div>
        </div>
        <div class="footer flex gap-10 justify-end pt-2 pr-6">
            <app-flat-button (clicked)="close()">Cancel</app-flat-button>
            <app-flat-button
                variant="cta"
                (clicked)="apply()"
                [disabled]="!selectedRangeValue?.start">
                Apply
            </app-flat-button>
        </div>
    </div>
</mat-menu>
