import { CommonModule } from '@angular/common';
import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { forkJoin, of, Subscription } from 'rxjs';

import { WorkflowService } from '@services/api/workflow.service';
import { SnackbarService } from '@services/snackbar.service';
import { UserService } from '@services/api/user/user.service';
import { TabsComponent } from '@shared/tabs/tabs.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { InputComponent } from '@shared/input/input.component';
import { SelectComponent } from '@shared/select/select.component';
import { TooltipComponent } from '@shared/tooltip/tooltip.component';
import { PriorityColorDirective } from '@directives/priority-color.directive';
import { StatusBoxDirective } from '@directives/status-box.directive';
import { pageRangeValidator } from 'app/utils/helpers/validators';
import {
    SubscriptionList,
    SubscriptionListType,
} from 'app/utils/helpers/subscription';
import {
    Discrepancy,
    DiscrepancyActionCode,
    DiscrepancyDetail,
    DiscrepancyEditOptions,
    IdDisplayPair,
    UpdateDiscrepancyRequest,
    WorkflowDocument,
} from 'app/models/workflow.model';
import { ActivityLogComponent } from '../../activity-log/activity-log.component';
import { DocumentUploadComponent } from '../../detail-dialog/document-upload/document-upload.component';
import { ContentFieldComponent } from '@shared/content-field/content-field.component';
import { AssignDiscrepancyDialogComponent } from '../../assign-discrepancy-dialog/assign-discrepancy-dialog.component';
import { AddDiscrepancyDialogComponent } from '../../add-discrepancy-dialog/add-discrepancy-dialog.component';
import { CancelDiscrepancyDialogComponent } from '../../cancel-discrepancy-dialog/cancel-discrepancy-dialog.component';
import { RecallDiscrepancyDialogComponent } from '../../recall-discrepancy-dialog/recall-discrepancy-dialog.component';

@Component({
    selector: 'app-discrepancies-tab',
    standalone: true,
    templateUrl: './discrepancies-tab.component.html',
    styleUrl: './discrepancies-tab.component.scss',
    imports: [
        CommonModule,
        MatDividerModule,
        MatIconModule,
        ReactiveFormsModule,
        PriorityColorDirective,
        StatusBoxDirective,
        TabsComponent,
        LoaderComponent,
        ContentFieldComponent,
        ActivityLogComponent,
        SelectComponent,
        InputComponent,
        DocumentUploadComponent,
        TextButtonComponent,
        FlatButtonComponent,
        TooltipComponent,
    ],
})
export class DiscrepanciesTabComponent implements OnInit, OnChanges, OnDestroy {
    @Input({ required: true }) documentId!: number;
    @Input() documentDetails: WorkflowDocument | null = null;
    @Input() selectedDiscrepancy: number | null = null;
    @Input() mode: 'summary' | 'detail' = 'summary';

    readonly maxDirectionsLength = 300;
    readonly maxDiscrepancyCount = 3;
    loading = false;
    detailsLoading = false;
    notifyLoading = false;
    tabsData: { label: string }[] = [];
    selectedTabIndex: number = 0;
    discrepancies: Discrepancy[] = [];
    discDetail = DiscrepancyDetail.initial();
    editOptions = DiscrepancyEditOptions.initial();
    editForm!: FormGroup;
    editFormChanged = false;
    formChanged = false;
    isAdmin = false;
    userId: number | null = null;
    isAssignedToMe = false;
    canTake = false;
    canUnassign = false;
    readOnly = true;
    private editFormSubscription: Subscription | undefined;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    constructor(
        public workflowService: WorkflowService,
        private snackbarService: SnackbarService,
        private userService: UserService,
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        private router: Router,
    ) {
        this.editForm = this.formBuilder.group({
            priority: new FormControl(null),
            reason: new FormControl(null),
            station: new FormControl(null),
            responsibleFixer: new FormControl(null),
            pageRange: new FormControl('', [
                pageRangeValidator(),
                Validators.required,
            ]),
            directions: new FormControl(
                '',
                Validators.maxLength(this.maxDirectionsLength),
            ),
            revision: new FormControl(null),
        });
        this.handleDiscDataRefresh();
    }

    get canSave() {
        return !this.editForm.invalid && this.editFormChanged;
    }

    ngOnInit(): void {
        this._subscriptions['is-admin-user'] = this.userService
            .isAdminUser()
            .subscribe({
                next: (isAdmin) => (this.isAdmin = isAdmin),
            });

        this._subscriptions['get-user-id'] = this.userService
            .getUserId()
            .subscribe({
                next: (userId) => (this.userId = userId),
            });

        if (this.documentId) {
            this.getDiscrepancies(this.documentId);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const documentIdChange = changes['documentId'];
        const selectedDiscrepancyChange = changes['selectedDiscrepancy'];

        if (documentIdChange && !documentIdChange.firstChange) {
            this.selectedDiscrepancy = selectedDiscrepancyChange?.currentValue;
            this.getDiscrepancies(documentIdChange.currentValue);
        } else if (
            selectedDiscrepancyChange &&
            !selectedDiscrepancyChange.firstChange
        ) {
            this.getDiscrepancies(this.documentId);
        }
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    handleDiscDataRefresh() {
        this.router.events.subscribe((val) => {
            if (
                val instanceof NavigationEnd &&
                val.urlAfterRedirects === '/documents-and-discrepancies'
            ) {
                if (
                    this.router.getCurrentNavigation()?.extras.state?.[
                        'refresh'
                    ]
                ) {
                    if (this.documentId) {
                        this.getDiscrepancies(this.documentId);
                    }
                }
            }
        });
    }

    handleTabChange(index: number) {
        this.getDiscrepancyDetails(this.discrepancies[index].id);
    }

    takeDiscrepancy() {
        const discId = this.discDetail?.id;
        if (discId) {
            this.workflowService.takeDiscrepancy(discId).subscribe({
                next: (success) => {
                    if (success) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `Discrepancy ${discId} Has Been Taken`,
                        });
                        this.getDiscrepancyDetails(discId);
                    }
                },
            });
        }
    }

    putbackDiscrepancy() {
        const discId = this.discDetail?.id;
        if (discId && this.isAssignedToMe) {
            this.workflowService.putbackDiscrepancy(discId).subscribe({
                next: (success) => {
                    if (success) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `Discrepancy ${discId} Has Been Put Back`,
                        });
                        this.getDiscrepancyDetails(discId);
                    }
                },
            });
        }
    }

    assignDiscrepancy() {
        const discId = this.discDetail?.id;
        const dialogRef = this.dialog.open(AssignDiscrepancyDialogComponent, {
            data: { id: discId, options: this.editOptions.analyst },
            maxWidth: '450px',
            minWidth: '200px',
            width: '450px',
        });

        dialogRef.afterClosed().subscribe({
            next: (success) => {
                if (success) this.getDiscrepancyDetails(discId);
            },
        });
    }

    unassignDiscrepancy() {
        const discId = this.discDetail?.id;
        if (discId) {
            this.workflowService.unassignDiscrepancy(discId).subscribe({
                next: (success) => {
                    if (success) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `Discrepancy ${discId} Has Been Unassigned`,
                        });
                        this.getDiscrepancyDetails(discId);
                    }
                },
            });
        }
    }

    canCancel() {
        return this.discDetail.actions.some(
            (item) => item.action === DiscrepancyActionCode.CANCEL,
        );
    }

    onCancelClick() {
        const dialogRef = this.dialog.open(CancelDiscrepancyDialogComponent, {
            data: { id: this.discDetail?.id },
            maxWidth: '450px',
            minWidth: '200px',
            width: '450px',
        });

        dialogRef.afterClosed().subscribe((success) => {
            if (this.documentId && success) {
                this.getDiscrepancies(this.documentId);
            }
        });
    }

    canRecall() {
        return this.discDetail.actions.some(
            (item) => item.action === DiscrepancyActionCode.RECALL,
        );
    }

    onRecallClick() {
        const dialogRef = this.dialog.open(RecallDiscrepancyDialogComponent, {
            data: { id: this.discDetail?.id },
            maxWidth: '450px',
            minWidth: '200px',
            width: '450px',
        });

        dialogRef.afterClosed().subscribe((success) => {
            if (this.documentId && success) {
                this.getDiscrepancies(this.documentId);
            }
        });
    }

    canNotify() {
        return this.discDetail.actions.some(
            (item) => item.action === DiscrepancyActionCode.NOTIFY,
        );
    }

    canRenotify() {
        return this.discDetail.actions.some(
            (item) => item.action === DiscrepancyActionCode.RENOTIFY,
        );
    }

    onSave(triggerNotify = false) {
        const discId = this.discDetail?.id;
        const editData = {
            ...new UpdateDiscrepancyRequest(
                this.discDetail.priority,
                this.discDetail.reason?.backingField,
                this.discDetail.pageRange,
                this.discDetail.station?.backingField,
                this.discDetail.responsibleFixer?.backingField,
                this.discDetail.directions,
            ),
            ...this.editForm.value,
        };
        this.workflowService.editDiscrepancy(discId, editData).subscribe({
            next: (success) => {
                if (success) {
                    this.snackbarService.success({
                        variant: 'success',
                        header: `Discrepancy ${discId} is Saved`,
                    });
                    this.getDiscrepancyDetails(discId);

                    if (triggerNotify) {
                        this.notifyDiscrepancy();
                    }
                } else {
                    this.snackbarService.error({
                        variant: 'error',
                        header: `Discrepancy ${discId} Could Not be Saved`,
                    });
                }
            },
        });
    }

    onNotify() {
        if (this.canSave) {
            this.onSave(true);
        }
    }

    notifyDiscrepancy() {
        const discId = this.discDetail?.id;
        this.notifyLoading = true;
        this.workflowService.notifyDiscrepancy(discId).subscribe({
            next: (success) => {
                if (success) {
                    this.snackbarService.success({
                        variant: 'success',
                        header: `Notified for Dicrepancy ${discId}!`,
                    });
                    this.getDiscrepancyDetails(discId);
                }
            },
            complete: () => (this.notifyLoading = false),
        });
    }

    onRenotify() {
        const discId = this.discDetail?.id;
        this.notifyLoading = true;
        this.workflowService.renotifyDiscrepancy(discId).subscribe({
            next: (success) => {
                if (success) {
                    this.snackbarService.success({
                        variant: 'success',
                        header: `Re-Notified for Dicrepancy ${discId}!`,
                    });
                    this.getDiscrepancyDetails(discId);
                }
            },
            complete: () => (this.notifyLoading = false),
        });
    }

    openAddDiscModal() {
        const dialogRef = this.dialog.open(AddDiscrepancyDialogComponent, {
            data: {
                documentId: this.documentId,
                station: this.discDetail.station?.backingField,
                fixer: this.discDetail.responsibleFixer?.backingField,
            },
            maxWidth: '545px',
            minWidth: '200px',
            width: '545px',
        });

        dialogRef
            .afterClosed()
            .subscribe((res: { success: boolean; discId: number }) => {
                if (this.documentId && res.success) {
                    this.selectedDiscrepancy = res.discId;
                    this.getDiscrepancies(this.documentId);
                }
            });
    }

    protected getDiscrepancyDetails(id: number) {
        this.editFormSubscription?.unsubscribe();

        if (this.discrepancies.length > 0) {
            this.detailsLoading = true;

            forkJoin([
                this.getEditOptions(id),
                this.workflowService.getDiscrepancyDetails(id),
            ]).subscribe({
                next: ([editOptionsData, discDetailsData]) => {
                    this.editOptions = editOptionsData;
                    this.discDetail = discDetailsData;
                    this.readOnly = !editOptionsData.canEdit;

                    this.setActionPermissions(discDetailsData.analyst);
                    this.fillEditForm(discDetailsData);
                    this.onEditFormValueChange();
                },
                complete: () => (this.detailsLoading = false),
            });
        }
    }

    private onEditFormValueChange() {
        const initialValue = this.editForm.value;
        this.editFormSubscription = this.editForm.valueChanges.subscribe(() => {
            this.editFormChanged = Object.keys(initialValue).some(
                (key) => this.editForm.value[key] != initialValue[key],
            );
        });
    }

    private fillEditForm({
        priority,
        pageRange,
        reason,
        station,
        directions,
        responsibleFixer,
        revision,
    }: DiscrepancyDetail) {
        this.editForm.patchValue({
            priority,
            pageRange,
            reason: reason?.backingField,
            station: station?.backingField,
            responsibleFixer: responsibleFixer?.backingField,
            directions,
            revision: revision?.backingField,
        });
    }

    private setActionPermissions(analyst: IdDisplayPair | null) {
        this.isAssignedToMe = analyst?.backingField == this.userId;
        this.canTake = this.canTakeDiscrepancy();
        this.canUnassign = this.canUassignDiscrepancy();
    }

    private canTakeDiscrepancy() {
        if (this.isAdmin) return !this.isAssignedToMe;
        const noAnalyst = this.discDetail.analyst === null;
        if (noAnalyst || !this.isAssignedToMe) return true;
        return false;
    }

    private canUassignDiscrepancy() {
        const noAnalyst = this.discDetail.analyst === null;
        if (this.isAdmin) return !this.isAssignedToMe && !noAnalyst;
        return false;
    }

    private getDiscrepancies(id: number) {
        this.loading = true;
        this._subscriptions['get-disc-by-doc-id'] = this.workflowService
            .getDiscrepanciesByDocumentId(id)
            .subscribe({
                next: (data) => {
                    const discrepancyIds = data.map((item) => item.id);
                    this.discrepancies = data;
                    this.setsTabData();
                    if (this.selectedDiscrepancy) {
                        this.selectedTabIndex = discrepancyIds.findIndex(
                            (item) => item === this.selectedDiscrepancy,
                        );
                    }
                    const discrepancyId = discrepancyIds[this.selectedTabIndex];
                    this.getDiscrepancyDetails(discrepancyId);
                },
                complete: () => (this.loading = false),
            });
    }

    private getEditOptions(id: number) {
        if (this.mode === 'detail') {
            return this.workflowService.getDiscrepancyEditOptions(id);
        }
        return of(DiscrepancyEditOptions.initial());
    }

    private setsTabData() {
        this.tabsData = [
            ...this.discrepancies.map((item) => ({
                label: `Discrepancy ${item.discrepancyNo}`,
            })),
        ];
    }
}
