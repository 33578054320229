var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __reExport = (target, mod, secondTarget) => (__copyProps(target, mod, "default"), secondTarget && __copyProps(secondTarget, mod, "default"));
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// packages/ag-grid-enterprise/src/main.ts
var main_exports = {};
__export(main_exports, {
  ALWAYS_SYNC_GLOBAL_EVENTS: () => ALWAYS_SYNC_GLOBAL_EVENTS,
  AbstractHeaderCellCtrl: () => AbstractHeaderCellCtrl,
  AdvancedFilterModule: () => AdvancedFilterModule,
  AgAbstractField: () => AgAbstractField,
  AgAbstractLabel: () => AgAbstractLabel2,
  AgAutocomplete: () => AgAutocomplete2,
  AgCheckbox: () => AgCheckbox,
  AgDialog: () => AgDialog5,
  AgGroupComponent: () => AgGroupComponent6,
  AgInputDateField: () => AgInputDateField2,
  AgInputNumberField: () => AgInputNumberField2,
  AgInputRange: () => AgInputRange,
  AgInputTextArea: () => AgInputTextArea,
  AgInputTextField: () => AgInputTextField3,
  AgMenuItemComponent: () => AgMenuItemComponent5,
  AgMenuItemRenderer: () => AgMenuItemRenderer2,
  AgMenuList: () => AgMenuList4,
  AgMenuPanel: () => AgMenuPanel,
  AgPanel: () => AgPanel2,
  AgPickerField: () => AgPickerField2,
  AgPromise: () => AgPromise8,
  AgPromiseStatus: () => AgPromiseStatus,
  AgRadioButton: () => AgRadioButton,
  AgRichSelect: () => AgRichSelect4,
  AgSelect: () => AgSelect9,
  AgSlider: () => AgSlider3,
  AgStackComponentsRegistry: () => AgStackComponentsRegistry,
  AgToggleButton: () => AgToggleButton5,
  AlignedGridsService: () => AlignedGridsService,
  AnimateShowChangeCellRenderer: () => AnimateShowChangeCellRenderer,
  AnimateSlideCellRenderer: () => AnimateSlideCellRenderer,
  AnimationFrameService: () => AnimationFrameService,
  AutoScrollService: () => AutoScrollService2,
  AutoWidthCalculator: () => AutoWidthCalculator,
  Autowired: () => Autowired81,
  BarColumnLabelPlacement: () => BarColumnLabelPlacement,
  BaseComponentWrapper: () => BaseComponentWrapper,
  Bean: () => Bean18,
  BeanStub: () => BeanStub36,
  Beans: () => Beans,
  BodyDropPivotTarget: () => BodyDropPivotTarget,
  BodyDropTarget: () => BodyDropTarget,
  CellComp: () => CellComp,
  CellCtrl: () => CellCtrl2,
  CellNavigationService: () => CellNavigationService2,
  CellPositionUtils: () => CellPositionUtils2,
  CellRangeType: () => CellRangeType3,
  ChangedPath: () => ChangedPath2,
  ChartMappings: () => ChartMappings3,
  CheckboxCellEditor: () => CheckboxCellEditor,
  CheckboxCellRenderer: () => CheckboxCellRenderer,
  CheckboxSelectionComponent: () => CheckboxSelectionComponent,
  ClientSideRowModelSteps: () => ClientSideRowModelSteps,
  ClipboardModule: () => ClipboardModule,
  Column: () => Column,
  ColumnApi: () => ColumnApi,
  ColumnFactory: () => ColumnFactory,
  ColumnGroup: () => ColumnGroup,
  ColumnKeyCreator: () => ColumnKeyCreator,
  ColumnModel: () => ColumnModel,
  ColumnsToolPanelModule: () => ColumnsToolPanelModule,
  Component: () => Component64,
  ComponentUtil: () => ComponentUtil,
  Context: () => Context,
  CssClassApplier: () => CssClassApplier4,
  CssClassManager: () => CssClassManager,
  CtrlsService: () => CtrlsService,
  DataTypeService: () => DataTypeService,
  DateCellEditor: () => DateCellEditor,
  DateFilter: () => DateFilter,
  DateStringCellEditor: () => DateStringCellEditor,
  DisplayedGroupCreator: () => DisplayedGroupCreator,
  DragAndDropService: () => DragAndDropService7,
  DragService: () => DragService,
  DragSourceType: () => DragSourceType6,
  EnterpriseCoreModule: () => EnterpriseCoreModule,
  Environment: () => Environment,
  EventService: () => EventService3,
  Events: () => Events41,
  ExcelExportModule: () => ExcelExportModule,
  ExcelFactoryMode: () => ExcelFactoryMode2,
  ExpansionService: () => ExpansionService2,
  ExpressionService: () => ExpressionService,
  FilterManager: () => FilterManager,
  FilterWrapperComp: () => FilterWrapperComp4,
  FiltersToolPanelModule: () => FiltersToolPanelModule,
  FloatingFilterMapper: () => FloatingFilterMapper,
  FocusService: () => FocusService,
  GROUP_AUTO_COLUMN_ID: () => GROUP_AUTO_COLUMN_ID3,
  Grid: () => Grid,
  GridApi: () => GridApi2,
  GridBodyComp: () => GridBodyComp,
  GridBodyCtrl: () => GridBodyCtrl,
  GridChartsModule: () => GridChartsModule,
  GridComp: () => GridComp,
  GridCoreCreator: () => GridCoreCreator,
  GridCtrl: () => GridCtrl,
  GridHeaderComp: () => GridHeaderComp,
  GridHeaderCtrl: () => GridHeaderCtrl,
  GridOptionsService: () => GridOptionsService,
  GroupCellRenderer: () => GroupCellRenderer,
  GroupCellRendererCtrl: () => GroupCellRendererCtrl,
  GroupInstanceIdCreator: () => GroupInstanceIdCreator,
  HeaderCellCtrl: () => HeaderCellCtrl,
  HeaderFilterCellComp: () => HeaderFilterCellComp,
  HeaderFilterCellCtrl: () => HeaderFilterCellCtrl,
  HeaderGroupCellCtrl: () => HeaderGroupCellCtrl,
  HeaderNavigationDirection: () => HeaderNavigationDirection,
  HeaderNavigationService: () => HeaderNavigationService,
  HeaderPositionUtils: () => HeaderPositionUtils,
  HeaderRowComp: () => HeaderRowComp,
  HeaderRowContainerComp: () => HeaderRowContainerComp,
  HeaderRowContainerCtrl: () => HeaderRowContainerCtrl,
  HeaderRowCtrl: () => HeaderRowCtrl,
  HeaderRowType: () => HeaderRowType,
  HorizontalDirection: () => HorizontalDirection,
  HorizontalResizeService: () => HorizontalResizeService,
  KeyCode: () => KeyCode12,
  LargeTextCellEditor: () => LargeTextCellEditor,
  LayoutCssClasses: () => LayoutCssClasses,
  LicenseManager: () => GridLicenseManager,
  LocaleService: () => LocaleService,
  Logger: () => Logger,
  LoggerFactory: () => LoggerFactory,
  ManagedFocusFeature: () => ManagedFocusFeature2,
  MasterDetailModule: () => MasterDetailModule,
  MenuModule: () => MenuModule,
  MenuService: () => MenuService,
  ModuleNames: () => ModuleNames20,
  ModuleRegistry: () => ModuleRegistry8,
  MouseEventService: () => MouseEventService,
  MoveColumnFeature: () => MoveColumnFeature,
  MultiFilter: () => MultiFilter,
  MultiFilterModule: () => MultiFilterModule,
  NavigationService: () => NavigationService,
  NumberCellEditor: () => NumberCellEditor,
  NumberFilter: () => NumberFilter,
  NumberSequence: () => NumberSequence2,
  Optional: () => Optional8,
  PaginationProxy: () => PaginationProxy,
  PillDragComp: () => PillDragComp3,
  PillDropZonePanel: () => PillDropZonePanel3,
  PinnedRowModel: () => PinnedRowModel,
  PivotDropZonePanel: () => PivotDropZonePanel,
  PopupComponent: () => PopupComponent2,
  PopupEditorWrapper: () => PopupEditorWrapper,
  PopupService: () => PopupService,
  PositionableFeature: () => PositionableFeature2,
  PostConstruct: () => PostConstruct77,
  PreConstruct: () => PreConstruct4,
  PreDestroy: () => PreDestroy9,
  PrimaryColsPanel: () => PrimaryColsPanel,
  PropertyKeys: () => PropertyKeys,
  ProvidedColumnGroup: () => ProvidedColumnGroup6,
  ProvidedFilter: () => ProvidedFilter3,
  Qualifier: () => Qualifier,
  QuerySelector: () => QuerySelector,
  RangeSelectionModule: () => RangeSelectionModule,
  RefSelector: () => RefSelector38,
  ResizeObserverService: () => ResizeObserverService,
  RichSelectModule: () => RichSelectModule,
  RowAnimationCssClasses: () => RowAnimationCssClasses,
  RowContainerComp: () => RowContainerComp,
  RowContainerCtrl: () => RowContainerCtrl,
  RowContainerName: () => RowContainerName,
  RowContainerType: () => RowContainerType,
  RowCtrl: () => RowCtrl,
  RowGroupDropZonePanel: () => RowGroupDropZonePanel,
  RowGroupingModule: () => RowGroupingModule,
  RowHighlightPosition: () => RowHighlightPosition,
  RowNode: () => RowNode3,
  RowNodeBlock: () => RowNodeBlock2,
  RowNodeBlockLoader: () => RowNodeBlockLoader,
  RowNodeSorter: () => RowNodeSorter,
  RowPositionUtils: () => RowPositionUtils2,
  RowRenderer: () => RowRenderer,
  ScalarFilter: () => ScalarFilter,
  ScrollVisibleService: () => ScrollVisibleService,
  SelectCellEditor: () => SelectCellEditor,
  SelectableService: () => SelectableService,
  SelectionHandleType: () => SelectionHandleType5,
  ServerSideRowModelModule: () => ServerSideRowModelModule,
  ServerSideTransactionResultStatus: () => ServerSideTransactionResultStatus4,
  SetFilter: () => SetFilter,
  SetFilterModule: () => SetFilterModule,
  SetLeftFeature: () => SetLeftFeature,
  SideBarModule: () => SideBarModule,
  SimpleFilter: () => SimpleFilter,
  SortController: () => SortController,
  SortIndicatorComp: () => SortIndicatorComp,
  SparklinesModule: () => SparklinesModule,
  StandardMenuFactory: () => StandardMenuFactory,
  StatusBarModule: () => StatusBarModule,
  StylingService: () => StylingService,
  TabGuardClassNames: () => TabGuardClassNames,
  TabGuardComp: () => TabGuardComp5,
  TabGuardCtrl: () => TabGuardCtrl,
  TabbedLayout: () => TabbedLayout3,
  TextCellEditor: () => TextCellEditor,
  TextFilter: () => TextFilter2,
  TextFloatingFilter: () => TextFloatingFilter,
  Timer: () => Timer,
  ToolPanelColDefService: () => ToolPanelColDefService,
  TooltipFeature: () => TooltipFeature4,
  TooltipStateManager: () => TooltipStateManager,
  TouchListener: () => TouchListener2,
  UserComponentFactory: () => UserComponentFactory2,
  UserComponentRegistry: () => UserComponentRegistry,
  ValueCache: () => ValueCache,
  ValueService: () => ValueService2,
  ValuesDropZonePanel: () => ValuesDropZonePanel,
  VanillaFrameworkOverrides: () => VanillaFrameworkOverrides,
  VerticalDirection: () => VerticalDirection,
  ViewportRowModelModule: () => ViewportRowModelModule,
  VirtualList: () => VirtualList5,
  VirtualListDragFeature: () => VirtualListDragFeature3,
  _: () => _76,
  __FORCE_MODULE_DETECTION_AG_GRID_CORE_EXT: () => __FORCE_MODULE_DETECTION_AG_GRID_CORE_EXT,
  agCharts: () => agCharts,
  createGrid: () => createGrid2,
  exportMultipleSheetsAsExcel: () => exportMultipleSheetsAsExcel,
  getMultipleSheetsAsExcel: () => getMultipleSheetsAsExcel,
  getRowContainerTypeForName: () => getRowContainerTypeForName,
  provideGlobalGridOptions: () => provideGlobalGridOptions
});
import { ModuleRegistry as ModuleRegistry7 } from "ag-grid-community";
import { ClientSideRowModelModule } from "ag-grid-community";
import { InfiniteRowModelModule } from "ag-grid-community";
import { CsvExportModule as CsvExportModule3 } from "ag-grid-community";

// enterprise-modules/advanced-filter/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames2 } from "ag-grid-community";

// enterprise-modules/core/src/agGridEnterpriseModule.ts
import { ModuleNames } from "ag-grid-community";

// enterprise-modules/core/src/license/gridLicenseManager.ts
import { Bean, BeanStub, PreConstruct } from "ag-grid-community";

// enterprise-modules/core/src/license/shared/md5.ts
var MD5 = class {
  constructor() {
    this.ieCompatibility = false;
  }
  init() {
    this.ieCompatibility = this.md5("hello") != "5d41402abc4b2a76b9719d911017c592";
  }
  md5cycle(x, k) {
    let a = x[0], b = x[1], c = x[2], d = x[3];
    a = this.ff(a, b, c, d, k[0], 7, -680876936);
    d = this.ff(d, a, b, c, k[1], 12, -389564586);
    c = this.ff(c, d, a, b, k[2], 17, 606105819);
    b = this.ff(b, c, d, a, k[3], 22, -1044525330);
    a = this.ff(a, b, c, d, k[4], 7, -176418897);
    d = this.ff(d, a, b, c, k[5], 12, 1200080426);
    c = this.ff(c, d, a, b, k[6], 17, -1473231341);
    b = this.ff(b, c, d, a, k[7], 22, -45705983);
    a = this.ff(a, b, c, d, k[8], 7, 1770035416);
    d = this.ff(d, a, b, c, k[9], 12, -1958414417);
    c = this.ff(c, d, a, b, k[10], 17, -42063);
    b = this.ff(b, c, d, a, k[11], 22, -1990404162);
    a = this.ff(a, b, c, d, k[12], 7, 1804603682);
    d = this.ff(d, a, b, c, k[13], 12, -40341101);
    c = this.ff(c, d, a, b, k[14], 17, -1502002290);
    b = this.ff(b, c, d, a, k[15], 22, 1236535329);
    a = this.gg(a, b, c, d, k[1], 5, -165796510);
    d = this.gg(d, a, b, c, k[6], 9, -1069501632);
    c = this.gg(c, d, a, b, k[11], 14, 643717713);
    b = this.gg(b, c, d, a, k[0], 20, -373897302);
    a = this.gg(a, b, c, d, k[5], 5, -701558691);
    d = this.gg(d, a, b, c, k[10], 9, 38016083);
    c = this.gg(c, d, a, b, k[15], 14, -660478335);
    b = this.gg(b, c, d, a, k[4], 20, -405537848);
    a = this.gg(a, b, c, d, k[9], 5, 568446438);
    d = this.gg(d, a, b, c, k[14], 9, -1019803690);
    c = this.gg(c, d, a, b, k[3], 14, -187363961);
    b = this.gg(b, c, d, a, k[8], 20, 1163531501);
    a = this.gg(a, b, c, d, k[13], 5, -1444681467);
    d = this.gg(d, a, b, c, k[2], 9, -51403784);
    c = this.gg(c, d, a, b, k[7], 14, 1735328473);
    b = this.gg(b, c, d, a, k[12], 20, -1926607734);
    a = this.hh(a, b, c, d, k[5], 4, -378558);
    d = this.hh(d, a, b, c, k[8], 11, -2022574463);
    c = this.hh(c, d, a, b, k[11], 16, 1839030562);
    b = this.hh(b, c, d, a, k[14], 23, -35309556);
    a = this.hh(a, b, c, d, k[1], 4, -1530992060);
    d = this.hh(d, a, b, c, k[4], 11, 1272893353);
    c = this.hh(c, d, a, b, k[7], 16, -155497632);
    b = this.hh(b, c, d, a, k[10], 23, -1094730640);
    a = this.hh(a, b, c, d, k[13], 4, 681279174);
    d = this.hh(d, a, b, c, k[0], 11, -358537222);
    c = this.hh(c, d, a, b, k[3], 16, -722521979);
    b = this.hh(b, c, d, a, k[6], 23, 76029189);
    a = this.hh(a, b, c, d, k[9], 4, -640364487);
    d = this.hh(d, a, b, c, k[12], 11, -421815835);
    c = this.hh(c, d, a, b, k[15], 16, 530742520);
    b = this.hh(b, c, d, a, k[2], 23, -995338651);
    a = this.ii(a, b, c, d, k[0], 6, -198630844);
    d = this.ii(d, a, b, c, k[7], 10, 1126891415);
    c = this.ii(c, d, a, b, k[14], 15, -1416354905);
    b = this.ii(b, c, d, a, k[5], 21, -57434055);
    a = this.ii(a, b, c, d, k[12], 6, 1700485571);
    d = this.ii(d, a, b, c, k[3], 10, -1894986606);
    c = this.ii(c, d, a, b, k[10], 15, -1051523);
    b = this.ii(b, c, d, a, k[1], 21, -2054922799);
    a = this.ii(a, b, c, d, k[8], 6, 1873313359);
    d = this.ii(d, a, b, c, k[15], 10, -30611744);
    c = this.ii(c, d, a, b, k[6], 15, -1560198380);
    b = this.ii(b, c, d, a, k[13], 21, 1309151649);
    a = this.ii(a, b, c, d, k[4], 6, -145523070);
    d = this.ii(d, a, b, c, k[11], 10, -1120210379);
    c = this.ii(c, d, a, b, k[2], 15, 718787259);
    b = this.ii(b, c, d, a, k[9], 21, -343485551);
    x[0] = this.add32(a, x[0]);
    x[1] = this.add32(b, x[1]);
    x[2] = this.add32(c, x[2]);
    x[3] = this.add32(d, x[3]);
  }
  cmn(q, a, b, x, s, t) {
    a = this.add32(this.add32(a, q), this.add32(x, t));
    return this.add32(a << s | a >>> 32 - s, b);
  }
  ff(a, b, c, d, x, s, t) {
    return this.cmn(b & c | ~b & d, a, b, x, s, t);
  }
  gg(a, b, c, d, x, s, t) {
    return this.cmn(b & d | c & ~d, a, b, x, s, t);
  }
  hh(a, b, c, d, x, s, t) {
    return this.cmn(b ^ c ^ d, a, b, x, s, t);
  }
  ii(a, b, c, d, x, s, t) {
    return this.cmn(c ^ (b | ~d), a, b, x, s, t);
  }
  md51(s) {
    const n = s.length;
    const state = [1732584193, -271733879, -1732584194, 271733878];
    let i;
    for (i = 64; i <= s.length; i += 64) {
      this.md5cycle(state, this.md5blk(s.substring(i - 64, i)));
    }
    s = s.substring(i - 64);
    const tail = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (i = 0; i < s.length; i++) {
      tail[i >> 2] |= s.charCodeAt(i) << (i % 4 << 3);
    }
    tail[i >> 2] |= 128 << (i % 4 << 3);
    if (i > 55) {
      this.md5cycle(state, tail);
      for (i = 0; i < 16; i++) {
        tail[i] = 0;
      }
    }
    tail[14] = n * 8;
    this.md5cycle(state, tail);
    return state;
  }
  /* there needs to be support for Unicode here, * unless we pretend that we can redefine the MD-5
   * algorithm for multi-byte characters (perhaps by adding every four 16-bit characters and
   * shortening the sum to 32 bits). Otherwise I suthis.ggest performing MD-5 as if every character
   * was two bytes--e.g., 0040 0025 = @%--but then how will an ordinary MD-5 sum be matched?
   * There is no way to standardize text to something like UTF-8 before transformation; speed cost is
   * utterly prohibitive. The JavaScript standard itself needs to look at this: it should start
   * providing access to strings as preformed UTF-8 8-bit unsigned value arrays.
   */
  md5blk(s) {
    const md5blks = [];
    for (let i = 0; i < 64; i += 4) {
      md5blks[i >> 2] = s.charCodeAt(i) + (s.charCodeAt(i + 1) << 8) + (s.charCodeAt(i + 2) << 16) + (s.charCodeAt(i + 3) << 24);
    }
    return md5blks;
  }
  rhex(n) {
    const hex_chr = "0123456789abcdef".split("");
    let s = "", j = 0;
    for (; j < 4; j++) {
      s += hex_chr[n >> j * 8 + 4 & 15] + hex_chr[n >> j * 8 & 15];
    }
    return s;
  }
  hex(x) {
    for (let i = 0; i < x.length; i++) {
      x[i] = this.rhex(x[i]);
    }
    return x.join("");
  }
  md5(s) {
    return this.hex(this.md51(s));
  }
  add32(a, b) {
    return this.ieCompatibility ? this.add32Compat(a, b) : this.add32Std(a, b);
  }
  /* this function is much faster, so if possible we use it. Some IEs are the only ones I know of that
   need the idiotic second function, generated by an if clause.  */
  add32Std(a, b) {
    return a + b & 4294967295;
  }
  add32Compat(x, y) {
    const lsw = (x & 65535) + (y & 65535), msw = (x >> 16) + (y >> 16) + (lsw >> 16);
    return msw << 16 | lsw & 65535;
  }
};

// enterprise-modules/core/src/license/shared/licenseManager.ts
function missingOrEmpty(value) {
  return value == null || value.length === 0;
}
var LICENSE_TYPES = {
  "01": "GRID",
  "02": "CHARTS",
  "0102": "BOTH"
};
var _LicenseManager = class _LicenseManager {
  constructor(document2) {
    this.watermarkMessage = void 0;
    this.totalMessageLength = 124;
    this.document = document2;
    this.md5 = new MD5();
    this.md5.init();
  }
  validateLicense() {
    const licenseDetails = this.getLicenseDetails(_LicenseManager.licenseKey);
    const currentLicenseName = `AG Grid ${licenseDetails.currentLicenseType === "BOTH" ? "and AG Charts " : ""}Enterprise`;
    const suppliedLicenseName = licenseDetails.suppliedLicenseType === void 0 ? "" : `AG ${licenseDetails.suppliedLicenseType === "BOTH" ? "Grid and AG Charts" : licenseDetails.suppliedLicenseType === "GRID" ? "Grid" : "Charts"} Enterprise`;
    if (licenseDetails.missing) {
      if (!this.isWebsiteUrl() || this.isForceWatermark()) {
        this.outputMissingLicenseKey(currentLicenseName);
      }
    } else if (licenseDetails.expired) {
      const gridReleaseDate = _LicenseManager.getGridReleaseDate();
      const formattedReleaseDate = _LicenseManager.formatDate(gridReleaseDate);
      this.outputExpiredKey(licenseDetails.expiry, formattedReleaseDate, currentLicenseName, suppliedLicenseName);
    } else if (!licenseDetails.valid) {
      this.outputInvalidLicenseKey(!!licenseDetails.incorrectLicenseType, currentLicenseName, suppliedLicenseName);
    } else if (licenseDetails.isTrial && licenseDetails.trialExpired) {
      this.outputExpiredTrialKey(licenseDetails.expiry, currentLicenseName, suppliedLicenseName);
    }
  }
  static extractExpiry(license) {
    const restrictionHashed = license.substring(license.lastIndexOf("_") + 1, license.length);
    return new Date(parseInt(_LicenseManager.decode(restrictionHashed), 10));
  }
  static extractLicenseComponents(licenseKey) {
    let cleanedLicenseKey = licenseKey.replace(/[\u200B-\u200D\uFEFF]/g, "");
    cleanedLicenseKey = cleanedLicenseKey.replace(/\r?\n|\r/g, "");
    if (licenseKey.length <= 32) {
      return { md5: null, license: licenseKey, version: null, isTrial: null };
    }
    const hashStart = cleanedLicenseKey.length - 32;
    const md5 = cleanedLicenseKey.substring(hashStart);
    const license = cleanedLicenseKey.substring(0, hashStart);
    const [version, isTrial, type] = _LicenseManager.extractBracketedInformation(cleanedLicenseKey);
    return { md5, license, version, isTrial, type };
  }
  getLicenseDetails(licenseKey) {
    const currentLicenseType = _LicenseManager.chartsLicenseManager ? "BOTH" : "GRID";
    if (missingOrEmpty(licenseKey)) {
      return {
        licenseKey,
        valid: false,
        missing: true,
        currentLicenseType
      };
    }
    const gridReleaseDate = _LicenseManager.getGridReleaseDate();
    const { md5, license, version, isTrial, type } = _LicenseManager.extractLicenseComponents(licenseKey);
    let valid = md5 === this.md5.md5(license) && licenseKey.indexOf("For_Trialing_ag-Grid_Only") === -1;
    let trialExpired = void 0;
    let expired = void 0;
    let expiry = null;
    let incorrectLicenseType = false;
    let suppliedLicenseType = void 0;
    function handleTrial() {
      const now = /* @__PURE__ */ new Date();
      trialExpired = expiry < now;
      expired = void 0;
    }
    if (valid) {
      expiry = _LicenseManager.extractExpiry(license);
      valid = !isNaN(expiry.getTime());
      if (valid) {
        expired = gridReleaseDate > expiry;
        switch (version) {
          case "legacy":
          case "2": {
            if (isTrial) {
              handleTrial();
            }
            break;
          }
          case "3": {
            if (missingOrEmpty(type)) {
              valid = false;
            } else {
              suppliedLicenseType = type;
              if (type !== LICENSE_TYPES["01"] && type !== LICENSE_TYPES["0102"] || currentLicenseType === "BOTH" && suppliedLicenseType !== "BOTH") {
                valid = false;
                incorrectLicenseType = true;
              } else if (isTrial) {
                handleTrial();
              }
            }
          }
        }
      }
    }
    if (!valid) {
      return {
        licenseKey,
        valid,
        incorrectLicenseType,
        currentLicenseType,
        suppliedLicenseType
      };
    }
    return {
      licenseKey,
      valid,
      expiry: _LicenseManager.formatDate(expiry),
      expired,
      version,
      isTrial,
      trialExpired,
      incorrectLicenseType,
      currentLicenseType,
      suppliedLicenseType
    };
  }
  isDisplayWatermark() {
    return this.isForceWatermark() || !this.isLocalhost() && !this.isWebsiteUrl() && !missingOrEmpty(this.watermarkMessage);
  }
  getWatermarkMessage() {
    return this.watermarkMessage || "";
  }
  getHostname() {
    const win = this.document.defaultView || window;
    const loc = win.location;
    const { hostname = "" } = loc;
    return hostname;
  }
  isForceWatermark() {
    const win = this.document.defaultView || window;
    const loc = win.location;
    const { pathname } = loc;
    return pathname ? pathname.indexOf("forceWatermark") !== -1 : false;
  }
  isWebsiteUrl() {
    const hostname = this.getHostname();
    return hostname.match(/^((?:[\w-]+\.)?ag-grid\.com)$/) !== null;
  }
  isLocalhost() {
    const hostname = this.getHostname();
    return hostname.match(/^(?:127\.0\.0\.1|localhost)$/) !== null;
  }
  static formatDate(date) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return day + " " + monthNames[monthIndex] + " " + year;
  }
  static getGridReleaseDate() {
    return new Date(parseInt(_LicenseManager.decode(_LicenseManager.RELEASE_INFORMATION), 10));
  }
  static decode(input) {
    const keystr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    let t = "";
    let n, r, i;
    let s, o, u, a;
    let f = 0;
    const e = input.replace(/[^A-Za-z0-9+/=]/g, "");
    while (f < e.length) {
      s = keystr.indexOf(e.charAt(f++));
      o = keystr.indexOf(e.charAt(f++));
      u = keystr.indexOf(e.charAt(f++));
      a = keystr.indexOf(e.charAt(f++));
      n = s << 2 | o >> 4;
      r = (o & 15) << 4 | u >> 2;
      i = (u & 3) << 6 | a;
      t = t + String.fromCharCode(n);
      if (u != 64) {
        t = t + String.fromCharCode(r);
      }
      if (a != 64) {
        t = t + String.fromCharCode(i);
      }
    }
    t = _LicenseManager.utf8_decode(t);
    return t;
  }
  static utf8_decode(input) {
    input = input.replace(/rn/g, "n");
    let t = "";
    for (let n = 0; n < input.length; n++) {
      const r = input.charCodeAt(n);
      if (r < 128) {
        t += String.fromCharCode(r);
      } else if (r > 127 && r < 2048) {
        t += String.fromCharCode(r >> 6 | 192);
        t += String.fromCharCode(r & 63 | 128);
      } else {
        t += String.fromCharCode(r >> 12 | 224);
        t += String.fromCharCode(r >> 6 & 63 | 128);
        t += String.fromCharCode(r & 63 | 128);
      }
    }
    return t;
  }
  static setChartsLicenseManager(dependantLicenseManager) {
    this.chartsLicenseManager = dependantLicenseManager;
  }
  static setLicenseKey(licenseKey) {
    this.licenseKey = licenseKey;
    if (this.chartsLicenseManager) {
      this.chartsLicenseManager.setLicenseKey(licenseKey, true);
    }
  }
  static extractBracketedInformation(licenseKey) {
    if (!licenseKey.includes("[")) {
      return ["legacy", false, void 0];
    }
    const matches = licenseKey.match(/\[(.*?)\]/g).map((match) => match.replace("[", "").replace("]", ""));
    if (!matches || matches.length === 0) {
      return ["legacy", false, void 0];
    }
    const isTrial = matches.filter((match) => match === "TRIAL").length === 1;
    const rawVersion = matches.filter((match) => match.indexOf("v") === 0)[0];
    const version = rawVersion ? rawVersion.replace("v", "") : "legacy";
    const type = LICENSE_TYPES[matches.filter((match) => LICENSE_TYPES[match])[0]];
    return [version, isTrial, type];
  }
  centerPadAndOutput(input) {
    const paddingRequired = this.totalMessageLength - input.length;
    console.error(input.padStart(paddingRequired / 2 + input.length, "*").padEnd(this.totalMessageLength, "*"));
  }
  padAndOutput(input, padding = "*", terminateWithPadding = "") {
    console.error(input.padEnd(this.totalMessageLength - terminateWithPadding.length, padding) + terminateWithPadding);
  }
  outputInvalidLicenseKey(incorrectLicenseType, currentLicenseName, suppliedLicenseName) {
    if (incorrectLicenseType) {
      this.centerPadAndOutput("");
      this.centerPadAndOutput(` ${currentLicenseName} License `);
      this.centerPadAndOutput(" Incompatible License Key ");
      this.padAndOutput(`* Your license key is for ${suppliedLicenseName} only and does not cover you for ${currentLicenseName}.`, " ", "*");
      this.padAndOutput("* Please contact info@ag-grid.com to obtain a combined license key.", " ", "*");
      this.centerPadAndOutput("");
      this.centerPadAndOutput("");
    } else {
      this.centerPadAndOutput("");
      this.centerPadAndOutput(` ${currentLicenseName} License `);
      this.centerPadAndOutput(" Invalid License Key ");
      this.padAndOutput(`* Your license key is not valid - please contact info@ag-grid.com to obtain a valid license.`, " ", "*");
      this.centerPadAndOutput("");
      this.centerPadAndOutput("");
    }
    this.watermarkMessage = "Invalid License";
  }
  outputExpiredTrialKey(formattedExpiryDate, currentLicenseName, suppliedLicenseName) {
    this.centerPadAndOutput("");
    this.centerPadAndOutput(` ${currentLicenseName} License `);
    this.centerPadAndOutput(" Trial Period Expired. ");
    this.padAndOutput(`* Your trial only license for ${suppliedLicenseName} expired on ${formattedExpiryDate}.`, " ", "*");
    this.padAndOutput("* Please email info@ag-grid.com to purchase a license.", " ", "*");
    this.centerPadAndOutput("");
    this.centerPadAndOutput("");
    this.watermarkMessage = "Trial Period Expired";
  }
  outputMissingLicenseKey(currentLicenseName) {
    this.centerPadAndOutput("");
    this.centerPadAndOutput(` ${currentLicenseName} License `);
    this.centerPadAndOutput(" License Key Not Found ");
    this.padAndOutput(`* All ${currentLicenseName} features are unlocked for trial.`, " ", "*");
    this.padAndOutput("* If you want to hide the watermark please email info@ag-grid.com for a trial license key.", " ", "*");
    this.centerPadAndOutput("");
    this.centerPadAndOutput("");
    this.watermarkMessage = "For Trial Use Only";
  }
  outputExpiredKey(formattedExpiryDate, formattedReleaseDate, currentLicenseName, suppliedLicenseName) {
    this.centerPadAndOutput("");
    this.centerPadAndOutput(` ${currentLicenseName} License `);
    this.centerPadAndOutput(" Incompatible Software Version ");
    this.padAndOutput(`* Your license key works with versions of ${suppliedLicenseName} released before ${formattedExpiryDate}.`, " ", "*");
    this.padAndOutput(`* The version you are trying to use was released on ${formattedReleaseDate}.`, " ", "*");
    this.padAndOutput("* Please contact info@ag-grid.com to renew your license key.", " ", "*");
    this.centerPadAndOutput("");
    this.centerPadAndOutput("");
    this.watermarkMessage = "License Expired";
  }
};
_LicenseManager.RELEASE_INFORMATION = "MTcxNTc3NTcyODYxNg==";
var LicenseManager = _LicenseManager;

// enterprise-modules/core/src/license/gridLicenseManager.ts
var GridLicenseManager = class extends BeanStub {
  validateLicense() {
    this.licenseManager = new LicenseManager(this.gos.getDocument());
    this.licenseManager.validateLicense();
  }
  static getLicenseDetails(licenseKey) {
    return new LicenseManager(null).getLicenseDetails(licenseKey);
  }
  isDisplayWatermark() {
    return this.licenseManager.isDisplayWatermark();
  }
  getWatermarkMessage() {
    return this.licenseManager.getWatermarkMessage();
  }
  static setLicenseKey(licenseKey) {
    LicenseManager.setLicenseKey(licenseKey);
  }
  static setChartsLicenseManager(chartsLicenseManager) {
    LicenseManager.setChartsLicenseManager(chartsLicenseManager);
  }
};
__decorateClass([
  PreConstruct
], GridLicenseManager.prototype, "validateLicense", 1);
GridLicenseManager = __decorateClass([
  Bean("licenseManager")
], GridLicenseManager);

// enterprise-modules/core/src/license/watermark.ts
import { Autowired as Autowired2, Component, PostConstruct, RefSelector } from "ag-grid-community";
var WatermarkComp = class extends Component {
  constructor() {
    super(
      /* html*/
      `<div class="ag-watermark">
                <div ref="eLicenseTextRef" class="ag-watermark-text"></div>
            </div>`
    );
  }
  postConstruct() {
    const show = this.shouldDisplayWatermark();
    this.setDisplayed(show);
    if (show) {
      this.eLicenseTextRef.innerText = this.licenseManager.getWatermarkMessage();
      window.setTimeout(() => this.addCssClass("ag-opacity-zero"), 0);
      window.setTimeout(() => this.setDisplayed(false), 5e3);
    }
  }
  shouldDisplayWatermark() {
    return this.licenseManager.isDisplayWatermark();
  }
};
__decorateClass([
  Autowired2("licenseManager")
], WatermarkComp.prototype, "licenseManager", 2);
__decorateClass([
  RefSelector("eLicenseTextRef")
], WatermarkComp.prototype, "eLicenseTextRef", 2);
__decorateClass([
  PostConstruct
], WatermarkComp.prototype, "postConstruct", 1);

// enterprise-modules/core/src/version.ts
var VERSION = "31.3.2";

// enterprise-modules/core/src/agGridEnterpriseModule.ts
var EnterpriseCoreModule = {
  version: VERSION,
  moduleName: ModuleNames.EnterpriseCoreModule,
  beans: [GridLicenseManager],
  agStackComponents: [
    { componentName: "AgWatermark", componentClass: WatermarkComp }
  ]
};

// enterprise-modules/advanced-filter/dist/package/main.esm.mjs
import {
  AgAutocomplete,
  Autowired as Autowired9,
  Component as Component8,
  PostConstruct as PostConstruct9,
  RefSelector as RefSelector5,
  _ as _12
} from "ag-grid-community";
import {
  AgDialog,
  Autowired as Autowired8,
  BeanStub as BeanStub3,
  Events as Events5,
  PostConstruct as PostConstruct8,
  _ as _11
} from "ag-grid-community";
import {
  Autowired as Autowired3,
  Component as Component2,
  Events,
  KeyCode,
  PostConstruct as PostConstruct2,
  _ as _2
} from "ag-grid-community";
import {
  Autowired as Autowired7,
  Component as Component7,
  PostConstruct as PostConstruct7,
  RefSelector as RefSelector4,
  TooltipFeature as TooltipFeature3,
  VirtualList as VirtualList3,
  _ as _10
} from "ag-grid-community";
import {
  Autowired as Autowired5,
  DragAndDropService,
  DragSourceType as DragSourceType2,
  Events as Events3,
  KeyCode as KeyCode4,
  PostConstruct as PostConstruct5,
  RefSelector as RefSelector2,
  TabGuardComp,
  TooltipFeature,
  _ as _8
} from "ag-grid-community";
import { AgRichSelect, _ as _22 } from "ag-grid-community";
import {
  BeanStub as BeanStub2,
  DragSourceType,
  PostConstruct as PostConstruct22,
  VirtualListDragFeature
} from "ag-grid-community";
import { BeanStub as BeanStub22, KeyCode as KeyCode2, PostConstruct as PostConstruct3, _ as _3 } from "ag-grid-community";
import {
  Autowired as Autowired22,
  Component as Component3,
  _ as _4
} from "ag-grid-community";
import {
  AgInputDateField,
  AgInputNumberField,
  AgInputTextField,
  Autowired as Autowired32,
  Component as Component4,
  Events as Events2,
  KeyCode as KeyCode3,
  PostConstruct as PostConstruct4,
  RefSelector as RefSelector3,
  _ as _5
} from "ag-grid-community";
import { Autowired as Autowired4, Component as Component5 } from "ag-grid-community";
import { AgRichSelect as AgRichSelect2, _ as _7 } from "ag-grid-community";
import {
  Autowired as Autowired6,
  Component as Component6,
  Events as Events4,
  PostConstruct as PostConstruct6,
  RefSelector as RefSelector32,
  TooltipFeature as TooltipFeature2,
  _ as _9
} from "ag-grid-community";
import {
  Autowired as Autowired10,
  Bean as Bean2,
  BeanStub as BeanStub4,
  PostConstruct as PostConstruct10,
  _ as _13
} from "ag-grid-community";
import {
  Autowired as Autowired11,
  Bean as Bean22,
  BeanStub as BeanStub5,
  Events as Events6,
  PostConstruct as PostConstruct11,
  _ as _14
} from "ag-grid-community";
var __defProp2 = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc2 = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp2 = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp2(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp2.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __decorateClass2 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc2(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp2(target, key, result);
  return result;
};
var AdvancedFilterHeaderComp = class extends Component2 {
  constructor(enabled) {
    super(
      /* html */
      `
            <div class="ag-advanced-filter-header" role="row">
            </div>`
    );
    this.enabled = enabled;
  }
  postConstruct() {
    this.setupAdvancedFilter(this.enabled);
    this.addDestroyFunc(() => this.destroyBean(this.eAdvancedFilter));
    this.addManagedListener(this.eventService, Events.EVENT_GRID_COLUMNS_CHANGED, () => this.onGridColumnsChanged());
    this.addGuiEventListener("keydown", (event) => this.onKeyDown(event));
    this.addGuiEventListener("focusout", (event) => {
      if (!this.getFocusableElement().contains(event.relatedTarget)) {
        this.focusService.clearAdvancedFilterColumn();
      }
    });
  }
  getFocusableElement() {
    var _a, _b;
    return (_b = (_a = this.eAdvancedFilter) == null ? void 0 : _a.getGui()) != null ? _b : this.getGui();
  }
  setEnabled(enabled) {
    if (enabled === this.enabled) {
      return;
    }
    this.setupAdvancedFilter(enabled);
  }
  refresh() {
    var _a;
    (_a = this.eAdvancedFilter) == null ? void 0 : _a.refresh();
  }
  getHeight() {
    return this.height;
  }
  setInputDisabled(disabled) {
    var _a;
    (_a = this.eAdvancedFilter) == null ? void 0 : _a.setInputDisabled(disabled);
  }
  setupAdvancedFilter(enabled) {
    const eGui = this.getGui();
    if (enabled) {
      this.eAdvancedFilter = this.createBean(new AdvancedFilterComp());
      const eAdvancedFilterGui = this.eAdvancedFilter.getGui();
      this.eAdvancedFilter.addCssClass("ag-advanced-filter-header-cell");
      this.height = this.columnModel.getFloatingFiltersHeight();
      const height = `${this.height}px`;
      eGui.style.height = height;
      eGui.style.minHeight = height;
      this.setAriaRowIndex();
      _2.setAriaRole(eAdvancedFilterGui, "gridcell");
      _2.setAriaColIndex(eAdvancedFilterGui, 1);
      this.setAriaColumnCount(eAdvancedFilterGui);
      eGui.appendChild(eAdvancedFilterGui);
    } else {
      _2.clearElement(eGui);
      this.destroyBean(this.eAdvancedFilter);
      this.height = 0;
    }
    _2.setDisplayed(eGui, enabled);
    this.enabled = enabled;
  }
  setAriaColumnCount(eAdvancedFilterGui) {
    _2.setAriaColSpan(eAdvancedFilterGui, this.columnModel.getAllGridColumns().length);
  }
  setAriaRowIndex() {
    _2.setAriaRowIndex(this.getGui(), this.headerNavigationService.getHeaderRowCount());
  }
  onGridColumnsChanged() {
    if (!this.eAdvancedFilter) {
      return;
    }
    this.setAriaColumnCount(this.eAdvancedFilter.getGui());
    this.setAriaRowIndex();
  }
  onKeyDown(event) {
    switch (event.key) {
      case KeyCode.ENTER: {
        if (this.hasFocus()) {
          if (this.focusService.focusInto(this.getFocusableElement())) {
            event.preventDefault();
          }
        }
        break;
      }
      case KeyCode.ESCAPE:
        if (!this.hasFocus()) {
          this.getFocusableElement().focus();
        }
        break;
      case KeyCode.UP:
        this.navigateUpDown(true, event);
        break;
      case KeyCode.DOWN:
        this.navigateUpDown(false, event);
        break;
      case KeyCode.TAB:
        if (this.hasFocus()) {
          this.navigateLeftRight(event);
        } else {
          const nextFocusableEl = this.focusService.findNextFocusableElement(this.getFocusableElement(), null, event.shiftKey);
          if (nextFocusableEl) {
            event.preventDefault();
            nextFocusableEl.focus();
          } else {
            this.navigateLeftRight(event);
          }
        }
        break;
    }
  }
  navigateUpDown(backwards, event) {
    if (this.hasFocus()) {
      if (this.focusService.focusNextFromAdvancedFilter(backwards)) {
        event.preventDefault();
      }
      ;
    }
  }
  navigateLeftRight(event) {
    if (event.shiftKey ? this.focusService.focusLastHeader() : this.focusService.focusNextFromAdvancedFilter(false, true)) {
      event.preventDefault();
    }
  }
  hasFocus() {
    return this.gos.getActiveDomElement() === this.getFocusableElement();
  }
};
__decorateClass2([
  Autowired3("columnModel")
], AdvancedFilterHeaderComp.prototype, "columnModel", 2);
__decorateClass2([
  Autowired3("focusService")
], AdvancedFilterHeaderComp.prototype, "focusService", 2);
__decorateClass2([
  Autowired3("headerNavigationService")
], AdvancedFilterHeaderComp.prototype, "headerNavigationService", 2);
__decorateClass2([
  PostConstruct2
], AdvancedFilterHeaderComp.prototype, "postConstruct", 1);
var AddDropdownComp = class extends AgRichSelect {
  constructor(params) {
    super(__spreadProps(__spreadValues({}, params), {
      template: (
        /* html */
        `
                <div class="ag-picker-field" role="presentation">
                    <div ref="eLabel"></div>
                    <div ref="eWrapper" class="ag-wrapper ag-picker-collapsed">
                        <div ref="eDisplayField" class="ag-picker-field-display"></div>
                        <ag-input-text-field ref="eInput" class="ag-rich-select-field-input"></ag-input-text-field>
                        <div ref="eIcon" class="ag-picker-field-icon" aria-hidden="true"></div>
                    </div>
                </div>`
      )
    }));
    this.params = params;
  }
  showPicker() {
    setTimeout(() => super.showPicker());
  }
  hidePicker() {
    setTimeout(() => super.hidePicker());
  }
  postConstruct() {
    super.postConstruct();
    const { wrapperClassName, ariaLabel } = this.params;
    _22.setDisplayed(this.eDisplayField, false);
    if (wrapperClassName) {
      this.eWrapper.classList.add(wrapperClassName);
    }
    _22.setAriaLabelledBy(this.eWrapper, "");
    _22.setAriaLabel(this.eWrapper, ariaLabel);
  }
  onEnterKeyDown(event) {
    _22.stopPropagationForAgGrid(event);
    if (this.isPickerDisplayed) {
      super.onEnterKeyDown(event);
    } else {
      event.preventDefault();
      this.showPicker();
    }
  }
};
var _AdvancedFilterBuilderDragFeature = class _AdvancedFilterBuilderDragFeature2 extends BeanStub2 {
  constructor(comp, virtualList) {
    super();
    this.comp = comp;
    this.virtualList = virtualList;
  }
  postConstruct() {
    this.createManagedBean(new VirtualListDragFeature(
      this.comp,
      this.virtualList,
      {
        dragSourceType: DragSourceType.AdvancedFilterBuilder,
        listItemDragStartEvent: _AdvancedFilterBuilderDragFeature2.EVENT_DRAG_STARTED,
        listItemDragEndEvent: _AdvancedFilterBuilderDragFeature2.EVENT_DRAG_ENDED,
        eventSource: this,
        getCurrentDragValue: (listItemDragStartEvent) => this.getCurrentDragValue(listItemDragStartEvent),
        isMoveBlocked: () => false,
        getNumRows: (comp) => comp.getNumItems(),
        moveItem: (currentDragValue, lastHoveredListItem) => this.moveItem(currentDragValue, lastHoveredListItem)
      }
    ));
  }
  getCurrentDragValue(listItemDragStartEvent) {
    return listItemDragStartEvent.item;
  }
  moveItem(currentDragValue, lastHoveredListItem) {
    this.comp.moveItem(currentDragValue, lastHoveredListItem);
  }
};
_AdvancedFilterBuilderDragFeature.EVENT_DRAG_STARTED = "advancedFilterBuilderDragStarted";
_AdvancedFilterBuilderDragFeature.EVENT_DRAG_ENDED = "advancedFilterBuilderDragEnded";
__decorateClass2([
  PostConstruct22
], _AdvancedFilterBuilderDragFeature.prototype, "postConstruct", 1);
var AdvancedFilterBuilderDragFeature = _AdvancedFilterBuilderDragFeature;
var AdvancedFilterBuilderItemNavigationFeature = class extends BeanStub22 {
  constructor(eGui, focusWrapper, eFocusableComp) {
    super();
    this.eGui = eGui;
    this.focusWrapper = focusWrapper;
    this.eFocusableComp = eFocusableComp;
  }
  postConstruct() {
    this.addManagedListener(this.eGui, "keydown", (event) => {
      switch (event.key) {
        case KeyCode2.TAB:
          if (!event.defaultPrevented) {
            _3.stopPropagationForAgGrid(event);
          }
          break;
        case KeyCode2.UP:
        case KeyCode2.DOWN:
          _3.stopPropagationForAgGrid(event);
          break;
        case KeyCode2.ESCAPE:
          if (_3.isStopPropagationForAgGrid(event)) {
            return;
          }
          if (this.eGui.contains(this.gos.getActiveDomElement())) {
            event.preventDefault();
            _3.stopPropagationForAgGrid(event);
            this.focusWrapper.focus();
          }
          break;
      }
    });
    this.addManagedListener(this.focusWrapper, "keydown", (event) => {
      switch (event.key) {
        case KeyCode2.ENTER:
          if (_3.isStopPropagationForAgGrid(event)) {
            return;
          }
          if (this.gos.getActiveDomElement() === this.focusWrapper) {
            event.preventDefault();
            _3.stopPropagationForAgGrid(event);
            this.eFocusableComp.getFocusableElement().focus();
          }
          break;
      }
    });
    this.addManagedListener(this.focusWrapper, "focusin", () => {
      this.focusWrapper.classList.add("ag-advanced-filter-builder-virtual-list-item-highlight");
    });
    this.addManagedListener(this.focusWrapper, "focusout", (event) => {
      if (!this.focusWrapper.contains(event.relatedTarget)) {
        this.focusWrapper.classList.remove("ag-advanced-filter-builder-virtual-list-item-highlight");
      }
    });
  }
};
__decorateClass2([
  PostConstruct3
], AdvancedFilterBuilderItemNavigationFeature.prototype, "postConstruct", 1);
function getAdvancedFilterBuilderAddButtonParams(translate, maxPickerWidth) {
  return {
    pickerAriaLabelKey: "ariaLabelAdvancedFilterBuilderAddField",
    pickerAriaLabelValue: "Advanced Filter Builder Add Field",
    pickerType: "ag-list",
    valueList: [{
      key: "condition",
      displayValue: translate("advancedFilterBuilderAddCondition")
    }, {
      key: "join",
      displayValue: translate("advancedFilterBuilderAddJoin")
    }],
    valueFormatter: (value) => {
      var _a;
      return value == null ? null : (_a = value.displayValue) != null ? _a : value.key;
    },
    pickerIcon: "advancedFilterBuilderAdd",
    maxPickerWidth: `${maxPickerWidth != null ? maxPickerWidth : 120}px`,
    wrapperClassName: "ag-advanced-filter-builder-item-button",
    ariaLabel: translate("advancedFilterBuilderAddButtonTooltip")
  };
}
var AdvancedFilterBuilderEvents = class {
};
AdvancedFilterBuilderEvents.EVENT_ADDED = "advancedFilterBuilderAdded";
AdvancedFilterBuilderEvents.EVENT_MOVED = "advancedFilterBuilderMoved";
AdvancedFilterBuilderEvents.EVENT_REMOVED = "advancedFilterBuilderRemoved";
AdvancedFilterBuilderEvents.EVENT_VALUE_CHANGED = "advancedFilterBuilderValueChanged";
AdvancedFilterBuilderEvents.EVENT_VALID_CHANGED = "advancedFilterBuilderValidChanged";
var ConditionPillWrapperComp = class extends Component3 {
  constructor() {
    super(
      /* html */
      `
            <div class="ag-advanced-filter-builder-item-condition" role="presentation"></div>
        `
    );
    this.validationMessage = null;
  }
  init(params) {
    const { item, createPill } = params;
    this.item = item;
    this.createPill = createPill;
    this.filterModel = item.filterModel;
    this.setupColumnCondition(this.filterModel);
    this.validate();
    this.addDestroyFunc(() => this.destroyBeans([this.eColumnPill, this.eOperatorPill, this.eOperandPill]));
  }
  getDragName() {
    return this.filterModel.colId ? this.advancedFilterExpressionService.parseColumnFilterModel(this.filterModel) : this.getDefaultColumnDisplayValue();
  }
  getAriaLabel() {
    return `${this.advancedFilterExpressionService.translate("ariaAdvancedFilterBuilderFilterItem")} ${this.getDragName()}`;
  }
  getValidationMessage() {
    return this.validationMessage;
  }
  getFocusableElement() {
    return this.eColumnPill.getFocusableElement();
  }
  setupColumnCondition(filterModel) {
    var _a;
    const columnDetails = this.advancedFilterExpressionService.getColumnDetails(filterModel.colId);
    this.baseCellDataType = columnDetails.baseCellDataType;
    this.column = columnDetails.column;
    this.numOperands = this.getNumOperands(this.getOperatorKey());
    this.eColumnPill = this.createPill({
      key: this.getColumnKey(),
      displayValue: (_a = this.getColumnDisplayValue()) != null ? _a : this.getDefaultColumnDisplayValue(),
      cssClass: "ag-advanced-filter-builder-column-pill",
      isSelect: true,
      getEditorParams: () => ({ values: this.advancedFilterExpressionService.getColumnAutocompleteEntries() }),
      update: (key) => this.setColumnKey(key),
      pickerAriaLabelKey: "ariaLabelAdvancedFilterBuilderColumnSelectField",
      pickerAriaLabelValue: "Advanced Filter Builder Column Select Field",
      ariaLabel: this.advancedFilterExpressionService.translate("ariaAdvancedFilterBuilderColumn")
    });
    this.getGui().appendChild(this.eColumnPill.getGui());
    if (_4.exists(this.getColumnKey())) {
      this.createOperatorPill();
      if (this.hasOperand()) {
        this.createOperandPill();
      }
    }
  }
  createOperatorPill() {
    var _a;
    this.eOperatorPill = this.createPill({
      key: this.getOperatorKey(),
      displayValue: (_a = this.getOperatorDisplayValue()) != null ? _a : this.getDefaultOptionSelectValue(),
      cssClass: "ag-advanced-filter-builder-option-pill",
      isSelect: true,
      getEditorParams: () => ({ values: this.getOperatorAutocompleteEntries() }),
      update: (key) => this.setOperatorKey(key),
      pickerAriaLabelKey: "ariaLabelAdvancedFilterBuilderOptionSelectField",
      pickerAriaLabelValue: "Advanced Filter Builder Option Select Field",
      ariaLabel: this.advancedFilterExpressionService.translate("ariaAdvancedFilterBuilderOption")
    });
    this.eColumnPill.getGui().insertAdjacentElement("afterend", this.eOperatorPill.getGui());
  }
  createOperandPill() {
    var _a;
    const key = (_a = this.getOperandDisplayValue()) != null ? _a : "";
    this.eOperandPill = this.createPill({
      key,
      displayValue: key,
      baseCellDataType: this.baseCellDataType,
      cssClass: "ag-advanced-filter-builder-value-pill",
      isSelect: false,
      update: (key2) => this.setOperand(key2),
      ariaLabel: this.advancedFilterExpressionService.translate("ariaAdvancedFilterBuilderValue")
    });
    this.getGui().appendChild(this.eOperandPill.getGui());
  }
  getColumnKey() {
    return this.filterModel.colId;
  }
  getColumnDisplayValue() {
    return this.advancedFilterExpressionService.getColumnDisplayValue(this.filterModel);
  }
  getOperatorKey() {
    return this.filterModel.type;
  }
  getOperatorDisplayValue() {
    return this.advancedFilterExpressionService.getOperatorDisplayValue(this.filterModel);
  }
  getOperandDisplayValue() {
    return this.advancedFilterExpressionService.getOperandDisplayValue(this.filterModel, true);
  }
  hasOperand() {
    return this.numOperands > 0;
  }
  getOperatorAutocompleteEntries() {
    return this.column ? this.advancedFilterExpressionService.getOperatorAutocompleteEntries(
      this.column,
      this.baseCellDataType
    ) : [];
  }
  setColumnKey(colId) {
    if (!this.eOperatorPill) {
      this.createOperatorPill();
    }
    const newColumnDetails = this.advancedFilterExpressionService.getColumnDetails(colId);
    this.column = newColumnDetails.column;
    const newBaseCellDataType = newColumnDetails.baseCellDataType;
    if (this.baseCellDataType !== newBaseCellDataType) {
      this.baseCellDataType = newBaseCellDataType;
      this.setOperatorKey(void 0);
      if (this.eOperatorPill) {
        _4.removeFromParent(this.eOperatorPill.getGui());
        this.destroyBean(this.eOperatorPill);
        this.createOperatorPill();
      }
      this.validate();
    }
    this.filterModel.colId = colId;
    this.filterModel.filterType = this.baseCellDataType;
  }
  setOperatorKey(operator) {
    const newNumOperands = this.getNumOperands(operator);
    if (newNumOperands !== this.numOperands) {
      this.numOperands = newNumOperands;
      if (newNumOperands === 0) {
        this.destroyOperandPill();
      } else {
        this.createOperandPill();
        if (this.baseCellDataType !== "number") {
          this.setOperand("");
        }
      }
    }
    this.filterModel.type = operator;
    this.validate();
  }
  setOperand(operand) {
    var _a;
    let parsedOperand = operand;
    if (this.column) {
      parsedOperand = (_a = this.advancedFilterExpressionService.getOperandModelValue(operand, this.baseCellDataType, this.column)) != null ? _a : "";
    }
    this.filterModel.filter = parsedOperand;
    this.validate();
  }
  getNumOperands(operator) {
    var _a, _b;
    return (_b = (_a = this.advancedFilterExpressionService.getExpressionOperator(this.baseCellDataType, operator)) == null ? void 0 : _a.numOperands) != null ? _b : 0;
  }
  destroyOperandPill() {
    delete this.filterModel.filter;
    this.getGui().removeChild(this.eOperandPill.getGui());
    this.destroyBean(this.eOperandPill);
    this.eOperandPill = void 0;
  }
  validate() {
    let validationMessage = null;
    if (!_4.exists(this.getColumnKey())) {
      validationMessage = this.advancedFilterExpressionService.translate("advancedFilterBuilderValidationSelectColumn");
    } else if (!_4.exists(this.getOperatorKey())) {
      validationMessage = this.advancedFilterExpressionService.translate("advancedFilterBuilderValidationSelectOption");
    } else if (this.numOperands > 0 && !_4.exists(this.getOperandDisplayValue())) {
      validationMessage = this.advancedFilterExpressionService.translate("advancedFilterBuilderValidationEnterValue");
    }
    this.item.valid = !validationMessage;
    if (validationMessage !== this.validationMessage) {
      this.validationMessage = validationMessage;
      this.dispatchEvent({
        type: AdvancedFilterBuilderEvents.EVENT_VALID_CHANGED
      });
    }
  }
  getDefaultColumnDisplayValue() {
    return this.advancedFilterExpressionService.translate("advancedFilterBuilderSelectColumn");
  }
  getDefaultOptionSelectValue() {
    return this.advancedFilterExpressionService.translate("advancedFilterBuilderSelectOption");
  }
};
__decorateClass2([
  Autowired22("advancedFilterExpressionService")
], ConditionPillWrapperComp.prototype, "advancedFilterExpressionService", 2);
__decorateClass2([
  Autowired22("valueService")
], ConditionPillWrapperComp.prototype, "valueService", 2);
var InputPillComp = class extends Component4 {
  constructor(params) {
    super(
      /* html */
      `
            <div class="ag-advanced-filter-builder-pill-wrapper" role="presentation">
                <div ref="ePill" class="ag-advanced-filter-builder-pill" role="button">
                    <span ref="eLabel" class="ag-advanced-filter-builder-pill-display"></span>
                </div>
            </div>
        `
    );
    this.params = params;
    this.value = params.value;
  }
  postConstruct() {
    const { cssClass, ariaLabel } = this.params;
    this.ePill.classList.add(cssClass);
    this.activateTabIndex([this.ePill]);
    this.eLabel.id = `${this.getCompId()}`;
    _5.setAriaDescribedBy(this.ePill, this.eLabel.id);
    _5.setAriaLabel(this.ePill, ariaLabel);
    this.renderValue();
    this.addManagedListener(this.ePill, "click", (event) => {
      event.preventDefault();
      this.showEditor();
    });
    this.addManagedListener(this.ePill, "keydown", (event) => {
      switch (event.key) {
        case KeyCode3.ENTER:
          event.preventDefault();
          _5.stopPropagationForAgGrid(event);
          this.showEditor();
          break;
      }
    });
    this.addDestroyFunc(() => this.destroyBean(this.eEditor));
  }
  getFocusableElement() {
    return this.ePill;
  }
  showEditor() {
    if (this.eEditor) {
      return;
    }
    _5.setDisplayed(this.ePill, false);
    this.eEditor = this.createEditorComp(this.params.type);
    this.eEditor.setValue(this.value);
    const eEditorGui = this.eEditor.getGui();
    this.eEditor.addManagedListener(eEditorGui, "keydown", (event) => {
      switch (event.key) {
        case KeyCode3.ENTER:
          event.preventDefault();
          _5.stopPropagationForAgGrid(event);
          this.updateValue(true);
          break;
        case KeyCode3.ESCAPE:
          event.preventDefault();
          _5.stopPropagationForAgGrid(event);
          this.hideEditor(true);
          break;
      }
    });
    this.eEditor.addManagedListener(eEditorGui, "focusout", () => {
      this.updateValue(false);
    });
    this.getGui().appendChild(eEditorGui);
    this.eEditor.getFocusableElement().focus();
  }
  createEditorComp(type) {
    let comp;
    switch (type) {
      case "text":
        comp = new AgInputTextField();
        break;
      case "number":
        comp = new AgInputNumberField();
        break;
      case "date":
        comp = new AgInputDateField();
        break;
    }
    return this.createBean(comp);
  }
  hideEditor(keepFocus) {
    const { eEditor } = this;
    if (!eEditor) {
      return;
    }
    this.eEditor = void 0;
    this.getGui().removeChild(eEditor.getGui());
    this.destroyBean(eEditor);
    _5.setDisplayed(this.ePill, true);
    if (keepFocus) {
      this.ePill.focus();
    }
  }
  renderValue() {
    let value;
    this.eLabel.classList.remove(
      "ag-advanced-filter-builder-value-empty",
      "ag-advanced-filter-builder-value-number",
      "ag-advanced-filter-builder-value-text"
    );
    if (!_5.exists(this.value)) {
      value = this.advancedFilterExpressionService.translate("advancedFilterBuilderEnterValue");
      this.eLabel.classList.add("ag-advanced-filter-builder-value-empty");
    } else if (this.params.type === "number") {
      value = this.value;
      this.eLabel.classList.add("ag-advanced-filter-builder-value-number");
    } else {
      value = `"${this.value}"`;
      this.eLabel.classList.add("ag-advanced-filter-builder-value-text");
    }
    this.eLabel.innerText = value;
  }
  updateValue(keepFocus) {
    var _a;
    if (!this.eEditor) {
      return;
    }
    const value = (_a = this.eEditor.getValue()) != null ? _a : "";
    this.dispatchEvent({
      type: Events2.EVENT_FIELD_VALUE_CHANGED,
      value
    });
    this.value = value;
    this.renderValue();
    this.hideEditor(keepFocus);
  }
};
__decorateClass2([
  RefSelector3("ePill")
], InputPillComp.prototype, "ePill", 2);
__decorateClass2([
  RefSelector3("eLabel")
], InputPillComp.prototype, "eLabel", 2);
__decorateClass2([
  Autowired32("advancedFilterExpressionService")
], InputPillComp.prototype, "advancedFilterExpressionService", 2);
__decorateClass2([
  PostConstruct4
], InputPillComp.prototype, "postConstruct", 1);
var JoinPillWrapperComp = class extends Component5 {
  constructor() {
    super(
      /* html */
      `
            <div class="ag-advanced-filter-builder-item-condition" role="presentation"></div>
        `
    );
  }
  init(params) {
    const { item, createPill } = params;
    const filterModel = item.filterModel;
    this.filterModel = filterModel;
    this.ePill = createPill({
      key: filterModel.type,
      displayValue: this.advancedFilterExpressionService.parseJoinOperator(filterModel),
      cssClass: "ag-advanced-filter-builder-join-pill",
      isSelect: true,
      getEditorParams: () => ({ values: this.advancedFilterExpressionService.getJoinOperatorAutocompleteEntries() }),
      update: (key) => filterModel.type = key,
      pickerAriaLabelKey: "ariaLabelAdvancedFilterBuilderJoinSelectField",
      pickerAriaLabelValue: "Advanced Filter Builder Join Operator Select Field",
      ariaLabel: this.advancedFilterExpressionService.translate("ariaAdvancedFilterBuilderJoinOperator")
    });
    this.getGui().appendChild(this.ePill.getGui());
    this.addDestroyFunc(() => this.destroyBean(this.ePill));
  }
  getDragName() {
    return this.advancedFilterExpressionService.parseJoinOperator(this.filterModel);
  }
  getAriaLabel() {
    return `${this.advancedFilterExpressionService.translate("ariaAdvancedFilterBuilderGroupItem")} ${this.getDragName()}`;
  }
  getValidationMessage() {
    return null;
  }
  getFocusableElement() {
    return this.ePill.getFocusableElement();
  }
};
__decorateClass2([
  Autowired4("advancedFilterExpressionService")
], JoinPillWrapperComp.prototype, "advancedFilterExpressionService", 2);
var SelectPillComp = class extends AgRichSelect2 {
  constructor(params) {
    super(__spreadProps(__spreadValues({}, params), {
      template: (
        /* html */
        `
                <div class="ag-picker-field ag-advanced-filter-builder-pill-wrapper" role="presentation">
                    <div ref="eLabel"></div>
                    <div ref="eWrapper" class="ag-wrapper ag-advanced-filter-builder-pill ag-picker-collapsed">
                        <div ref="eDisplayField" class="ag-picker-field-display ag-advanced-filter-builder-pill-display"></div>
                        <ag-input-text-field ref="eInput" class="ag-rich-select-field-input"></ag-input-text-field>
                        <div ref="eIcon" class="ag-picker-field-icon" aria-hidden="true"></div>
                    </div>
                </div>`
      )
    }));
    this.params = params;
  }
  getFocusableElement() {
    return this.eWrapper;
  }
  showPicker() {
    setTimeout(() => super.showPicker());
  }
  hidePicker() {
    setTimeout(() => super.hidePicker());
  }
  postConstruct() {
    super.postConstruct();
    const { wrapperClassName, ariaLabel } = this.params;
    this.eWrapper.classList.add(wrapperClassName);
    _7.setAriaLabelledBy(this.eWrapper, "");
    _7.setAriaLabel(this.eWrapper, ariaLabel);
  }
  createPickerComponent() {
    var _a;
    if (!this.values) {
      const { values } = this.params.getEditorParams();
      this.values = values;
      const key = this.value.key;
      const value = (_a = values.find((value2) => value2.key === key)) != null ? _a : {
        key,
        displayValue: this.value.displayValue
      };
      this.value = value;
    }
    return super.createPickerComponent();
  }
  onEnterKeyDown(event) {
    _7.stopPropagationForAgGrid(event);
    if (this.isPickerDisplayed) {
      super.onEnterKeyDown(event);
    } else {
      event.preventDefault();
      this.showPicker();
    }
  }
};
var AdvancedFilterBuilderItemComp = class extends TabGuardComp {
  constructor(item, dragFeature, focusWrapper) {
    super(
      /* html */
      `
            <div class="ag-advanced-filter-builder-item-wrapper" role="presentation">
                <div ref="eItem" class="ag-advanced-filter-builder-item" role="presentation">
                    <div ref="eTreeLines" class="ag-advanced-filter-builder-item-tree-lines" aria-hidden="true"></div>
                    <span ref="eDragHandle" class="ag-drag-handle" aria-hidden="true"></span>
                    <span ref="eValidation" class="ag-advanced-filter-builder-item-button ag-advanced-filter-builder-invalid" aria-hidden="true"></span>
                </div>
                <div ref="eButtons" class="ag-advanced-filter-builder-item-buttons">
                    <span ref="eMoveUpButton" class="ag-advanced-filter-builder-item-button" role="button"></span>
                    <span ref="eMoveDownButton" class="ag-advanced-filter-builder-item-button" role="button"></span>
                    <div ref="eAddButton" role="presentation"></div>
                    <span ref="eRemoveButton" class="ag-advanced-filter-builder-item-button" role="button"></span>
                </div>
            </div>
        `
    );
    this.item = item;
    this.dragFeature = dragFeature;
    this.focusWrapper = focusWrapper;
    this.moveUpDisabled = false;
    this.moveDownDisabled = false;
  }
  postConstruct() {
    const { filterModel, level, showMove } = this.item;
    const isJoin = filterModel.filterType === "join";
    this.ePillWrapper = this.createManagedBean(isJoin ? new JoinPillWrapperComp() : new ConditionPillWrapperComp());
    this.ePillWrapper.init({ item: this.item, createPill: (params) => this.createPill(params) });
    this.eDragHandle.insertAdjacentElement("afterend", this.ePillWrapper.getGui());
    if (level === 0) {
      const eTreeLine = document.createElement("div");
      eTreeLine.classList.add("ag-advanced-filter-builder-item-tree-line-vertical-bottom");
      eTreeLine.classList.add("ag-advanced-filter-builder-item-tree-line-root");
      this.eTreeLines.appendChild(eTreeLine);
      _8.setDisplayed(this.eDragHandle, false);
      _8.setDisplayed(this.eButtons, false);
      _8.setAriaExpanded(this.focusWrapper, true);
    } else {
      this.setupTreeLines(level);
      this.eDragHandle.appendChild(_8.createIconNoSpan("advancedFilterBuilderDrag", this.gos));
      this.setupValidation();
      this.setupMoveButtons(showMove);
      this.setupAddButton();
      this.setupRemoveButton();
      this.setupDragging();
      this.updateAriaExpanded();
    }
    _8.setAriaLevel(this.focusWrapper, level + 1);
    this.initialiseTabGuard({});
    this.createManagedBean(new AdvancedFilterBuilderItemNavigationFeature(
      this.getGui(),
      this.focusWrapper,
      this.ePillWrapper
    ));
    this.updateAriaLabel();
    this.addManagedListener(this.ePillWrapper, AdvancedFilterBuilderEvents.EVENT_VALUE_CHANGED, () => this.dispatchEvent({
      type: AdvancedFilterBuilderEvents.EVENT_VALUE_CHANGED
    }));
    this.addManagedListener(this.ePillWrapper, AdvancedFilterBuilderEvents.EVENT_VALID_CHANGED, () => this.updateValidity());
  }
  setState(params) {
    const { level } = this.item;
    if (level === 0) {
      return;
    }
    const { showMove } = this.item;
    const { disableMoveUp, disableMoveDown, treeLines, showStartTreeLine } = params;
    this.updateTreeLines(treeLines, showStartTreeLine);
    this.updateAriaExpanded();
    if (showMove) {
      this.moveUpDisabled = !!disableMoveUp;
      this.moveDownDisabled = !!disableMoveDown;
      this.eMoveUpButton.classList.toggle("ag-advanced-filter-builder-item-button-disabled", disableMoveUp);
      this.eMoveDownButton.classList.toggle("ag-advanced-filter-builder-item-button-disabled", disableMoveDown);
      _8.setAriaDisabled(this.eMoveUpButton, !!disableMoveUp);
      _8.setAriaDisabled(this.eMoveDownButton, !!disableMoveDown);
      this.moveUpTooltipFeature.refreshToolTip();
      this.moveDownTooltipFeature.refreshToolTip();
    }
  }
  focusMoveButton(backwards) {
    (backwards ? this.eMoveUpButton : this.eMoveDownButton).focus();
  }
  afterAdd() {
    this.ePillWrapper.getFocusableElement().focus();
  }
  setupTreeLines(level) {
    for (let i = 0; i < level; i++) {
      const eTreeLine = document.createElement("div");
      this.eTreeLines.appendChild(eTreeLine);
    }
  }
  updateTreeLines(treeLines, showStartTreeLine) {
    const lastTreeLineIndex = treeLines.length - 1;
    const { children } = this.eTreeLines;
    for (let i = 0; i < lastTreeLineIndex; i++) {
      const eTreeLine2 = children.item(i);
      if (eTreeLine2) {
        eTreeLine2.classList.toggle("ag-advanced-filter-builder-item-tree-line-vertical", !treeLines[i]);
      }
    }
    const eTreeLine = children.item(lastTreeLineIndex);
    if (eTreeLine) {
      eTreeLine.classList.add("ag-advanced-filter-builder-item-tree-line-horizontal");
      const isLastChild = treeLines[lastTreeLineIndex];
      eTreeLine.classList.toggle("ag-advanced-filter-builder-item-tree-line-vertical-top", isLastChild);
      eTreeLine.classList.toggle("ag-advanced-filter-builder-item-tree-line-vertical", !isLastChild);
    }
    this.eDragHandle.classList.toggle("ag-advanced-filter-builder-item-tree-line-vertical-bottom", showStartTreeLine);
  }
  setupValidation() {
    this.eValidation.appendChild(_8.createIconNoSpan("advancedFilterBuilderInvalid", this.gos));
    this.validationTooltipFeature = this.createManagedBean(new TooltipFeature({
      getGui: () => this.eValidation,
      getLocation: () => "advancedFilter",
      getTooltipValue: () => this.ePillWrapper.getValidationMessage(),
      getTooltipShowDelayOverride: () => 1e3
    }));
    this.updateValidity();
  }
  setupAddButton() {
    var _a;
    const addButtonParams = getAdvancedFilterBuilderAddButtonParams(
      (key) => this.advancedFilterExpressionService.translate(key),
      (_a = this.gos.get("advancedFilterBuilderParams")) == null ? void 0 : _a.addSelectWidth
    );
    const eAddButton = this.createManagedBean(new AddDropdownComp(addButtonParams));
    this.addManagedListener(
      eAddButton,
      Events3.EVENT_FIELD_PICKER_VALUE_SELECTED,
      ({ value }) => this.dispatchEvent({
        type: AdvancedFilterBuilderEvents.EVENT_ADDED,
        item: this.item,
        isJoin: value.key === "join"
      })
    );
    this.eAddButton.appendChild(eAddButton.getGui());
    this.createManagedBean(new TooltipFeature({
      getGui: () => this.eAddButton,
      getLocation: () => "advancedFilter",
      getTooltipValue: () => this.advancedFilterExpressionService.translate("advancedFilterBuilderAddButtonTooltip")
    }));
  }
  setupRemoveButton() {
    this.eRemoveButton.appendChild(_8.createIconNoSpan("advancedFilterBuilderRemove", this.gos));
    this.addManagedListener(this.eRemoveButton, "click", () => this.removeItem());
    this.addManagedListener(this.eRemoveButton, "keydown", (event) => {
      switch (event.key) {
        case KeyCode4.ENTER:
          event.preventDefault();
          _8.stopPropagationForAgGrid(event);
          this.removeItem();
          break;
      }
    });
    this.createManagedBean(new TooltipFeature({
      getGui: () => this.eRemoveButton,
      getLocation: () => "advancedFilter",
      getTooltipValue: () => this.advancedFilterExpressionService.translate("advancedFilterBuilderRemoveButtonTooltip")
    }));
    _8.setAriaLabel(this.eRemoveButton, this.advancedFilterExpressionService.translate("advancedFilterBuilderRemoveButtonTooltip"));
    this.activateTabIndex([this.eRemoveButton]);
  }
  setupMoveButtons(showMove) {
    if (showMove) {
      this.eMoveUpButton.appendChild(_8.createIconNoSpan("advancedFilterBuilderMoveUp", this.gos));
      this.addManagedListener(this.eMoveUpButton, "click", () => this.moveItem(true));
      this.addManagedListener(this.eMoveUpButton, "keydown", (event) => {
        switch (event.key) {
          case KeyCode4.ENTER:
            event.preventDefault();
            _8.stopPropagationForAgGrid(event);
            this.moveItem(true);
            break;
        }
      });
      this.moveUpTooltipFeature = this.createManagedBean(new TooltipFeature({
        getGui: () => this.eMoveUpButton,
        getLocation: () => "advancedFilter",
        getTooltipValue: () => this.moveUpDisabled ? null : this.advancedFilterExpressionService.translate("advancedFilterBuilderMoveUpButtonTooltip")
      }));
      _8.setAriaLabel(this.eMoveUpButton, this.advancedFilterExpressionService.translate("advancedFilterBuilderMoveUpButtonTooltip"));
      this.eMoveDownButton.appendChild(_8.createIconNoSpan("advancedFilterBuilderMoveDown", this.gos));
      this.addManagedListener(this.eMoveDownButton, "click", () => this.moveItem(false));
      this.addManagedListener(this.eMoveDownButton, "keydown", (event) => {
        switch (event.key) {
          case KeyCode4.ENTER:
            event.preventDefault();
            _8.stopPropagationForAgGrid(event);
            this.moveItem(false);
            break;
        }
      });
      this.moveDownTooltipFeature = this.createManagedBean(new TooltipFeature({
        getGui: () => this.eMoveDownButton,
        getLocation: () => "advancedFilter",
        getTooltipValue: () => this.moveDownDisabled ? null : this.advancedFilterExpressionService.translate("advancedFilterBuilderMoveDownButtonTooltip")
      }));
      _8.setAriaLabel(this.eMoveDownButton, this.advancedFilterExpressionService.translate("advancedFilterBuilderMoveDownButtonTooltip"));
      this.activateTabIndex([this.eMoveUpButton, this.eMoveDownButton]);
    } else {
      _8.setDisplayed(this.eMoveUpButton, false);
      _8.setDisplayed(this.eMoveDownButton, false);
    }
  }
  updateValidity() {
    _8.setVisible(this.eValidation, !this.item.valid);
    this.validationTooltipFeature.refreshToolTip();
    this.updateAriaLabel();
  }
  createPill(params) {
    var _a, _b;
    const { key, displayValue, cssClass, update, ariaLabel } = params;
    const onUpdated = (key2) => {
      if (key2 == null) {
        return;
      }
      update(key2);
      this.dispatchEvent({
        type: AdvancedFilterBuilderEvents.EVENT_VALUE_CHANGED
      });
    };
    if (params.isSelect) {
      const { getEditorParams, pickerAriaLabelKey, pickerAriaLabelValue } = params;
      const advancedFilterBuilderParams = this.gos.get("advancedFilterBuilderParams");
      const minPickerWidth = `${(_a = advancedFilterBuilderParams == null ? void 0 : advancedFilterBuilderParams.pillSelectMinWidth) != null ? _a : 140}px`;
      const maxPickerWidth = `${(_b = advancedFilterBuilderParams == null ? void 0 : advancedFilterBuilderParams.pillSelectMaxWidth) != null ? _b : 200}px`;
      const comp = this.createBean(new SelectPillComp({
        pickerAriaLabelKey,
        pickerAriaLabelValue,
        pickerType: "ag-list",
        value: {
          key,
          displayValue
        },
        valueFormatter: (value) => {
          var _a2;
          return value == null ? null : (_a2 = value.displayValue) != null ? _a2 : value.key;
        },
        variableWidth: true,
        minPickerWidth,
        maxPickerWidth,
        getEditorParams,
        wrapperClassName: cssClass,
        ariaLabel
      }));
      this.addManagedListener(
        comp,
        Events3.EVENT_FIELD_PICKER_VALUE_SELECTED,
        ({ value }) => onUpdated(value == null ? void 0 : value.key)
      );
      return comp;
    } else {
      const comp = this.createBean(new InputPillComp({
        value: displayValue,
        cssClass,
        type: this.getInputType(params.baseCellDataType),
        ariaLabel
      }));
      this.addManagedListener(
        comp,
        Events3.EVENT_FIELD_VALUE_CHANGED,
        ({ value }) => onUpdated(value)
      );
      return comp;
    }
  }
  getInputType(baseCellDataType) {
    switch (baseCellDataType) {
      case "text":
      case "object":
      case "boolean":
        return "text";
      case "number":
        return "number";
      case "date":
      case "dateString":
        return "date";
    }
  }
  setupDragging() {
    const dragSource = {
      type: DragSourceType2.AdvancedFilterBuilder,
      eElement: this.eDragHandle,
      dragItemName: () => this.ePillWrapper.getDragName(),
      getDefaultIconName: () => DragAndDropService.ICON_NOT_ALLOWED,
      getDragItem: () => ({}),
      onDragStarted: () => this.dragFeature.dispatchEvent({
        type: AdvancedFilterBuilderDragFeature.EVENT_DRAG_STARTED,
        item: this.item
      }),
      onDragStopped: () => this.dragFeature.dispatchEvent({
        type: AdvancedFilterBuilderDragFeature.EVENT_DRAG_ENDED
      })
    };
    this.dragAndDropService.addDragSource(dragSource, true);
    this.addDestroyFunc(() => this.dragAndDropService.removeDragSource(dragSource));
  }
  updateAriaLabel() {
    const wrapperLabel = this.ePillWrapper.getAriaLabel();
    const level = `${this.item.level + 1}`;
    const validationMessage = this.ePillWrapper.getValidationMessage();
    let ariaLabel;
    if (validationMessage) {
      ariaLabel = this.advancedFilterExpressionService.translate(
        "ariaAdvancedFilterBuilderItemValidation",
        [wrapperLabel, level, validationMessage]
      );
    } else {
      ariaLabel = this.advancedFilterExpressionService.translate(
        "ariaAdvancedFilterBuilderItem",
        [wrapperLabel, level]
      );
    }
    _8.setAriaLabel(this.focusWrapper, ariaLabel);
  }
  updateAriaExpanded() {
    _8.removeAriaExpanded(this.focusWrapper);
    const { filterModel } = this.item;
    if ((filterModel == null ? void 0 : filterModel.filterType) === "join" && filterModel.conditions.length) {
      _8.setAriaExpanded(this.focusWrapper, true);
    }
  }
  removeItem() {
    this.dispatchEvent({
      type: AdvancedFilterBuilderEvents.EVENT_REMOVED,
      item: this.item
    });
  }
  moveItem(backwards) {
    this.dispatchEvent({
      type: AdvancedFilterBuilderEvents.EVENT_MOVED,
      item: this.item,
      backwards
    });
  }
};
__decorateClass2([
  RefSelector2("eTreeLines")
], AdvancedFilterBuilderItemComp.prototype, "eTreeLines", 2);
__decorateClass2([
  RefSelector2("eDragHandle")
], AdvancedFilterBuilderItemComp.prototype, "eDragHandle", 2);
__decorateClass2([
  RefSelector2("eItem")
], AdvancedFilterBuilderItemComp.prototype, "eItem", 2);
__decorateClass2([
  RefSelector2("eButtons")
], AdvancedFilterBuilderItemComp.prototype, "eButtons", 2);
__decorateClass2([
  RefSelector2("eValidation")
], AdvancedFilterBuilderItemComp.prototype, "eValidation", 2);
__decorateClass2([
  RefSelector2("eMoveUpButton")
], AdvancedFilterBuilderItemComp.prototype, "eMoveUpButton", 2);
__decorateClass2([
  RefSelector2("eMoveDownButton")
], AdvancedFilterBuilderItemComp.prototype, "eMoveDownButton", 2);
__decorateClass2([
  RefSelector2("eAddButton")
], AdvancedFilterBuilderItemComp.prototype, "eAddButton", 2);
__decorateClass2([
  RefSelector2("eRemoveButton")
], AdvancedFilterBuilderItemComp.prototype, "eRemoveButton", 2);
__decorateClass2([
  Autowired5("beans")
], AdvancedFilterBuilderItemComp.prototype, "beans", 2);
__decorateClass2([
  Autowired5("dragAndDropService")
], AdvancedFilterBuilderItemComp.prototype, "dragAndDropService", 2);
__decorateClass2([
  Autowired5("advancedFilterExpressionService")
], AdvancedFilterBuilderItemComp.prototype, "advancedFilterExpressionService", 2);
__decorateClass2([
  PostConstruct5
], AdvancedFilterBuilderItemComp.prototype, "postConstruct", 1);
var AdvancedFilterBuilderItemAddComp = class extends Component6 {
  constructor(item, focusWrapper) {
    super(
      /* html */
      `
            <div class="ag-advanced-filter-builder-item-wrapper" role="presentation">
                <div ref="eItem" class="ag-advanced-filter-builder-item" role="presentation">
                    <div class="ag-advanced-filter-builder-item-tree-lines" aria-hidden="true">
                        <div class="ag-advanced-filter-builder-item-tree-line-vertical-top ag-advanced-filter-builder-item-tree-line-horizontal"></div>
                    </div>
                </div>
            </div>
        `
    );
    this.item = item;
    this.focusWrapper = focusWrapper;
  }
  postConstruct() {
    var _a;
    _9.setAriaLevel(this.focusWrapper, 2);
    const addButtonParams = getAdvancedFilterBuilderAddButtonParams(
      (key) => this.advancedFilterExpressionService.translate(key),
      (_a = this.gos.get("advancedFilterBuilderParams")) == null ? void 0 : _a.addSelectWidth
    );
    const eAddButton = this.createManagedBean(new AddDropdownComp(addButtonParams));
    this.addManagedListener(eAddButton, Events4.EVENT_FIELD_PICKER_VALUE_SELECTED, ({ value }) => {
      this.dispatchEvent({
        type: AdvancedFilterBuilderEvents.EVENT_ADDED,
        item: this.item,
        isJoin: value.key === "join"
      });
    });
    this.eItem.appendChild(eAddButton.getGui());
    this.createManagedBean(new TooltipFeature2({
      getGui: () => eAddButton.getGui(),
      getLocation: () => "advancedFilter",
      getTooltipValue: () => this.advancedFilterExpressionService.translate("advancedFilterBuilderAddButtonTooltip")
    }));
    this.createManagedBean(new AdvancedFilterBuilderItemNavigationFeature(
      this.getGui(),
      this.focusWrapper,
      eAddButton
    ));
    _9.setAriaLabel(
      this.focusWrapper,
      this.advancedFilterExpressionService.translate("ariaAdvancedFilterBuilderItem", [
        this.advancedFilterExpressionService.translate("advancedFilterBuilderAddButtonTooltip"),
        `${this.item.level + 1}`
      ])
    );
  }
  afterAdd() {
  }
};
__decorateClass2([
  Autowired6("beans")
], AdvancedFilterBuilderItemAddComp.prototype, "beans", 2);
__decorateClass2([
  Autowired6("advancedFilterExpressionService")
], AdvancedFilterBuilderItemAddComp.prototype, "advancedFilterExpressionService", 2);
__decorateClass2([
  RefSelector32("eItem")
], AdvancedFilterBuilderItemAddComp.prototype, "eItem", 2);
__decorateClass2([
  PostConstruct6
], AdvancedFilterBuilderItemAddComp.prototype, "postConstruct", 1);
var AdvancedFilterBuilderComp = class extends Component7 {
  constructor() {
    super(
      /* html */
      `
            <div role="presentation" class="ag-advanced-filter-builder" tabindex="-1">
                <div role="presentation" class="ag-advanced-filter-builder-list" ref="eList"></div>
                <div role="presentation" class="ag-advanced-filter-builder-button-panel">
                    <button class="ag-button ag-standard-button ag-advanced-filter-builder-apply-button" ref="eApplyFilterButton"></button>
                    <button class="ag-button ag-standard-button ag-advanced-filter-builder-cancel-button" ref="eCancelFilterButton"></button>
                </div>
            </div>`
    );
    this.validationMessage = null;
  }
  postConstruct() {
    var _a;
    const { showMoveButtons } = (_a = this.gos.get("advancedFilterBuilderParams")) != null ? _a : {};
    this.showMove = !!showMoveButtons;
    this.addManagedPropertyListener("advancedFilterBuilderParams", ({ currentValue }) => {
      this.showMove = !!(currentValue == null ? void 0 : currentValue.showMoveButtons);
      this.refreshList(false);
    });
    this.filterModel = this.setupFilterModel();
    this.setupVirtualList();
    this.dragFeature = this.createManagedBean(new AdvancedFilterBuilderDragFeature(this, this.virtualList));
    this.setupButtons();
  }
  refresh() {
    let indexToFocus = this.virtualList.getLastFocusedRow();
    this.setupFilterModel();
    this.validateItems();
    this.refreshList(false);
    if (indexToFocus != null) {
      if (!this.virtualList.getComponentAt(indexToFocus)) {
        indexToFocus = 0;
      }
      this.virtualList.focusRow(indexToFocus);
    }
  }
  getNumItems() {
    return this.items.length;
  }
  moveItem(item, destination) {
    if (!destination || !item) {
      return;
    }
    this.moveItemToIndex(item, destination.rowIndex, destination.position);
  }
  afterGuiAttached() {
    this.virtualList.focusRow(0);
  }
  setupVirtualList() {
    this.virtualList = this.createManagedBean(new VirtualList3({
      cssIdentifier: "advanced-filter-builder",
      ariaRole: "tree",
      listName: this.advancedFilterExpressionService.translate("ariaAdvancedFilterBuilderList")
    }));
    this.virtualList.setComponentCreator(this.createItemComponent.bind(this));
    this.virtualList.setComponentUpdater(this.updateItemComponent.bind(this));
    this.virtualList.setRowHeight(40);
    this.eList.appendChild(this.virtualList.getGui());
    this.virtualList.setModel({
      getRowCount: () => this.items.length,
      getRow: (index) => this.items[index],
      areRowsEqual: (oldRow, newRow) => oldRow === newRow
    });
    this.buildList();
    this.virtualList.refresh();
  }
  setupButtons() {
    this.eApplyFilterButton.innerText = this.advancedFilterExpressionService.translate("advancedFilterBuilderApply");
    this.activateTabIndex([this.eApplyFilterButton]);
    this.addManagedListener(this.eApplyFilterButton, "click", () => {
      this.advancedFilterService.setModel(this.filterModel);
      this.filterManager.onFilterChanged({ source: "advancedFilter" });
      this.close();
    });
    this.validationTooltipFeature = this.createManagedBean(new TooltipFeature3({
      getGui: () => this.eApplyFilterButton,
      getLocation: () => "advancedFilter",
      getTooltipValue: () => this.validationMessage,
      getTooltipShowDelayOverride: () => 1e3
    }));
    this.validate();
    this.addManagedListener(
      this.eApplyFilterButton,
      "mouseenter",
      () => this.addOrRemoveCssClass("ag-advanced-filter-builder-validation", true)
    );
    this.addManagedListener(
      this.eApplyFilterButton,
      "mouseleave",
      () => this.addOrRemoveCssClass("ag-advanced-filter-builder-validation", false)
    );
    this.eCancelFilterButton.innerText = this.advancedFilterExpressionService.translate("advancedFilterBuilderCancel");
    this.activateTabIndex([this.eCancelFilterButton]);
    this.addManagedListener(this.eCancelFilterButton, "click", () => this.close());
  }
  removeItemFromParent(item) {
    const sourceParentIndex = item.parent.conditions.indexOf(item.filterModel);
    item.parent.conditions.splice(sourceParentIndex, 1);
    return sourceParentIndex;
  }
  moveItemToIndex(item, destinationRowIndex, destinationPosition) {
    var _a;
    const destinationItem = this.items[destinationRowIndex];
    const destinationIsParent = ((_a = destinationItem.filterModel) == null ? void 0 : _a.filterType) === "join" && destinationPosition === "bottom";
    const destinationParent = destinationIsParent ? destinationItem.filterModel : destinationItem.parent;
    if (!destinationParent) {
      return;
    }
    if (this.isChildOrSelf(destinationParent, item.filterModel) || destinationItem === item) {
      return;
    }
    this.removeItemFromParent(item);
    let destinationParentIndex;
    if (destinationIsParent) {
      destinationParentIndex = 0;
    } else {
      destinationParentIndex = destinationParent.conditions.indexOf(destinationItem.filterModel);
      if (destinationParentIndex === -1) {
        destinationParentIndex = destinationParent.conditions.length;
      } else if (destinationPosition === "bottom") {
        destinationParentIndex += 1;
      }
    }
    destinationParent.conditions.splice(destinationParentIndex, 0, item.filterModel);
    this.refreshList(false);
  }
  isChildOrSelf(modelToCheck, potentialParentModel) {
    return modelToCheck === potentialParentModel || potentialParentModel.filterType === "join" && potentialParentModel.conditions.some((condition) => this.isChildOrSelf(modelToCheck, condition));
  }
  setupFilterModel() {
    const filterModel = this.formatFilterModel(this.advancedFilterService.getModel());
    this.stringifiedModel = JSON.stringify(filterModel);
    return filterModel;
  }
  formatFilterModel(filterModel) {
    filterModel = filterModel != null ? filterModel : {
      filterType: "join",
      type: "AND",
      conditions: []
    };
    if (filterModel.filterType !== "join") {
      filterModel = {
        filterType: "join",
        type: "AND",
        conditions: [filterModel]
      };
    }
    return filterModel;
  }
  buildList() {
    const parseFilterModel = (filterModel, items, level, parent) => {
      items.push({ filterModel, level, parent, valid: true, showMove: this.showMove });
      if (filterModel.filterType === "join") {
        filterModel.conditions.forEach((childFilterModel) => parseFilterModel(childFilterModel, items, level + 1, filterModel));
        if (level === 0) {
          items.push({ filterModel: null, level: level + 1, parent: filterModel, valid: true });
        }
      }
    };
    this.items = [];
    parseFilterModel(this.filterModel, this.items, 0);
  }
  refreshList(softRefresh) {
    if (!softRefresh) {
      const invalidModels = [];
      this.items.forEach((item) => {
        if (!item.valid) {
          invalidModels.push(item.filterModel);
        }
      });
      this.buildList();
      if (invalidModels.length) {
        this.items.forEach((item) => {
          if (item.filterModel && invalidModels.includes(item.filterModel)) {
            item.valid = false;
          }
        });
      }
    }
    this.virtualList.refresh(softRefresh);
    this.validate();
  }
  updateItemComponent(item, comp) {
    const index = this.items.indexOf(item);
    const populateTreeLines = (filterModel2, treeLines2) => {
      const parentItem = this.items.find((itemToCheck) => itemToCheck.filterModel === filterModel2);
      const parentFilterModel = parentItem == null ? void 0 : parentItem.parent;
      if (parentFilterModel) {
        const { conditions } = parentFilterModel;
        populateTreeLines(parentFilterModel, treeLines2);
        treeLines2.push(conditions[conditions.length - 1] === filterModel2);
      }
    };
    const treeLines = [];
    const { filterModel } = item;
    if (filterModel) {
      populateTreeLines(filterModel, treeLines);
      treeLines[0] = false;
    }
    const showStartTreeLine = (filterModel == null ? void 0 : filterModel.filterType) === "join" && !!filterModel.conditions.length;
    comp.setState({
      disableMoveUp: index === 1,
      disableMoveDown: !this.canMoveDown(item, index),
      treeLines,
      showStartTreeLine
    });
  }
  createItemComponent(item, focusWrapper) {
    const itemComp = this.createBean(item.filterModel ? new AdvancedFilterBuilderItemComp(item, this.dragFeature, focusWrapper) : new AdvancedFilterBuilderItemAddComp(item, focusWrapper));
    itemComp.addManagedListener(
      itemComp,
      AdvancedFilterBuilderEvents.EVENT_REMOVED,
      ({ item: item2 }) => this.removeItem(item2)
    );
    itemComp.addManagedListener(
      itemComp,
      AdvancedFilterBuilderEvents.EVENT_VALUE_CHANGED,
      () => this.validate()
    );
    itemComp.addManagedListener(
      itemComp,
      AdvancedFilterBuilderEvents.EVENT_ADDED,
      ({ item: item2, isJoin }) => this.addItem(item2, isJoin)
    );
    itemComp.addManagedListener(
      itemComp,
      AdvancedFilterBuilderEvents.EVENT_MOVED,
      ({ item: item2, backwards }) => this.moveItemUpDown(item2, backwards)
    );
    if (itemComp instanceof AdvancedFilterBuilderItemComp) {
      this.updateItemComponent(item, itemComp);
    }
    return itemComp;
  }
  addItem(item, isJoin) {
    var _a;
    const { parent: itemParent, level, filterModel: itemFilterModel } = item;
    const itemIsJoin = (itemFilterModel == null ? void 0 : itemFilterModel.filterType) === "join";
    const filterModel = isJoin ? {
      filterType: "join",
      type: "AND",
      conditions: []
    } : {};
    const parent = itemIsJoin ? itemFilterModel : itemParent;
    let insertIndex = itemIsJoin ? 0 : parent.conditions.indexOf(itemFilterModel);
    if (insertIndex >= 0) {
      if (!itemIsJoin) {
        insertIndex += 1;
      }
      parent.conditions.splice(insertIndex, 0, filterModel);
    } else {
      parent.conditions.push(filterModel);
    }
    let index = this.items.indexOf(item);
    const softRefresh = index >= 0;
    if (softRefresh) {
      if (item.filterModel) {
        index++;
      }
      const newItems = [{
        filterModel,
        level: itemIsJoin ? level + 1 : level,
        parent,
        valid: isJoin,
        showMove: this.showMove
      }];
      this.items.splice(index, 0, ...newItems);
    }
    this.refreshList(softRefresh);
    if (softRefresh) {
      (_a = this.virtualList.getComponentAt(index)) == null ? void 0 : _a.afterAdd();
    }
  }
  removeItem(item) {
    var _a;
    const parent = item.parent;
    const { filterModel } = item;
    const parentIndex = parent.conditions.indexOf(filterModel);
    parent.conditions.splice(parentIndex, 1);
    const isJoin = ((_a = item.filterModel) == null ? void 0 : _a.filterType) === "join";
    const index = this.items.indexOf(item);
    const softRefresh = !isJoin && index >= 0;
    if (softRefresh) {
      this.items.splice(index, 1);
    }
    this.refreshList(softRefresh);
    if (index >= 0) {
      this.virtualList.focusRow(index);
    }
  }
  moveItemUpDown(item, backwards) {
    const itemIndex = this.items.indexOf(item);
    const destinationIndex = backwards ? itemIndex - 1 : itemIndex + 1;
    if (destinationIndex === 0 || !backwards && !this.canMoveDown(item, itemIndex)) {
      return;
    }
    const destinationItem = this.items[destinationIndex];
    const indexInParent = this.removeItemFromParent(item);
    const { level, filterModel, parent } = item;
    const { level: destinationLevel, filterModel: destinationFilterModel, parent: destinationParent } = destinationItem;
    if (backwards) {
      if (destinationLevel === level && destinationFilterModel.filterType === "join") {
        destinationFilterModel.conditions.push(filterModel);
      } else if (destinationLevel <= level) {
        const destinationIndex2 = destinationParent.conditions.indexOf(destinationFilterModel);
        destinationParent.conditions.splice(destinationIndex2, 0, filterModel);
      } else {
        const newParentItem = parent.conditions[indexInParent - 1];
        newParentItem.conditions.push(filterModel);
      }
    } else {
      if (destinationLevel === level) {
        if (destinationFilterModel.filterType === "join") {
          destinationFilterModel.conditions.splice(0, 0, filterModel);
        } else {
          const destinationIndex2 = destinationParent.conditions.indexOf(destinationFilterModel);
          destinationParent.conditions.splice(destinationIndex2 + 1, 0, filterModel);
        }
      } else {
        if (indexInParent < parent.conditions.length) {
          parent.conditions.splice(indexInParent + 1, 0, filterModel);
        } else {
          const parentItem = this.items.find((itemToCheck) => itemToCheck.filterModel === parent);
          const destinationIndex2 = parentItem.parent.conditions.indexOf(parentItem.filterModel) + 1;
          parentItem.parent.conditions.splice(destinationIndex2, 0, filterModel);
        }
      }
    }
    this.refreshList(false);
    const newIndex = this.items.findIndex(({ filterModel: filterModelToCheck }) => filterModelToCheck === filterModel);
    if (newIndex >= 0) {
      const comp = this.virtualList.getComponentAt(newIndex);
      if (comp instanceof AdvancedFilterBuilderItemComp) {
        comp.focusMoveButton(backwards);
      }
    }
  }
  canMoveDown(item, index) {
    return !(item.level === 1 && index === this.items.length - 2 || item.level === 1 && item.parent.conditions[item.parent.conditions.length - 1] === item.filterModel);
  }
  close() {
    this.advancedFilterService.getCtrl().toggleFilterBuilder("ui");
  }
  validate() {
    let disableApply = !this.items.every(({ valid }) => valid);
    if (!disableApply) {
      disableApply = JSON.stringify(this.filterModel) === this.stringifiedModel;
      if (disableApply) {
        this.validationMessage = this.advancedFilterExpressionService.translate("advancedFilterBuilderValidationAlreadyApplied");
      } else {
        this.validationMessage = null;
      }
    } else {
      this.validationMessage = this.advancedFilterExpressionService.translate("advancedFilterBuilderValidationIncomplete");
    }
    _10.setDisabled(this.eApplyFilterButton, disableApply);
    this.validationTooltipFeature.refreshToolTip();
  }
  validateItems() {
    const clearOperator = (filterModel) => {
      filterModel.type = void 0;
    };
    const clearOperand = (filterModel) => {
      delete filterModel.filter;
    };
    this.items.forEach((item) => {
      if (!item.valid || !item.filterModel || item.filterModel.filterType === "join") {
        return;
      }
      const { filterModel } = item;
      const { colId } = filterModel;
      const hasColumn = this.advancedFilterExpressionService.getColumnAutocompleteEntries().find(({ key }) => key === colId);
      const columnDetails = this.advancedFilterExpressionService.getColumnDetails(filterModel.colId);
      if (!hasColumn || !columnDetails.column) {
        item.valid = false;
        filterModel.colId = void 0;
        clearOperator(filterModel);
        clearOperand(filterModel);
        return;
      }
      const operatorForType = this.advancedFilterExpressionService.getDataTypeExpressionOperator(columnDetails.baseCellDataType);
      const operator = operatorForType.operators[filterModel.type];
      if (!operator) {
        item.valid = false;
        clearOperator(filterModel);
        clearOperand(filterModel);
        return;
      }
      if (operator.numOperands > 0 && !_10.exists(filterModel.filter)) {
        item.valid = false;
        return;
      }
    });
  }
};
__decorateClass2([
  RefSelector4("eList")
], AdvancedFilterBuilderComp.prototype, "eList", 2);
__decorateClass2([
  RefSelector4("eApplyFilterButton")
], AdvancedFilterBuilderComp.prototype, "eApplyFilterButton", 2);
__decorateClass2([
  RefSelector4("eCancelFilterButton")
], AdvancedFilterBuilderComp.prototype, "eCancelFilterButton", 2);
__decorateClass2([
  Autowired7("filterManager")
], AdvancedFilterBuilderComp.prototype, "filterManager", 2);
__decorateClass2([
  Autowired7("advancedFilterService")
], AdvancedFilterBuilderComp.prototype, "advancedFilterService", 2);
__decorateClass2([
  Autowired7("advancedFilterExpressionService")
], AdvancedFilterBuilderComp.prototype, "advancedFilterExpressionService", 2);
__decorateClass2([
  Autowired7("beans")
], AdvancedFilterBuilderComp.prototype, "beans", 2);
__decorateClass2([
  PostConstruct7
], AdvancedFilterBuilderComp.prototype, "postConstruct", 1);
var _AdvancedFilterCtrl = class _AdvancedFilterCtrl2 extends BeanStub3 {
  constructor(enabled) {
    super();
    this.enabled = enabled;
  }
  postConstruct() {
    this.hasAdvancedFilterParent = !!this.gos.get("advancedFilterParent");
    this.ctrlsService.whenReady(() => this.setAdvancedFilterComp());
    this.addManagedListener(
      this.eventService,
      Events5.EVENT_ADVANCED_FILTER_ENABLED_CHANGED,
      ({ enabled }) => this.onEnabledChanged(enabled)
    );
    this.addManagedPropertyListener("advancedFilterParent", () => this.updateComps());
    this.addDestroyFunc(() => {
      this.destroyAdvancedFilterComp();
      this.destroyBean(this.eBuilderComp);
      if (this.eBuilderDialog && this.eBuilderDialog.isAlive()) {
        this.destroyBean(this.eBuilderDialog);
      }
    });
  }
  setupHeaderComp(eCompToInsertBefore) {
    this.eHeaderComp = this.createManagedBean(new AdvancedFilterHeaderComp(this.enabled && !this.hasAdvancedFilterParent));
    eCompToInsertBefore.insertAdjacentElement("beforebegin", this.eHeaderComp.getGui());
  }
  focusHeaderComp() {
    if (this.eHeaderComp) {
      this.eHeaderComp.getFocusableElement().focus();
      return true;
    }
    return false;
  }
  refreshComp() {
    var _a, _b;
    (_a = this.eFilterComp) == null ? void 0 : _a.refresh();
    (_b = this.eHeaderComp) == null ? void 0 : _b.refresh();
  }
  refreshBuilderComp() {
    var _a;
    (_a = this.eBuilderComp) == null ? void 0 : _a.refresh();
  }
  getHeaderHeight() {
    var _a, _b;
    return (_b = (_a = this.eHeaderComp) == null ? void 0 : _a.getHeight()) != null ? _b : 0;
  }
  setInputDisabled(disabled) {
    var _a, _b;
    (_a = this.eFilterComp) == null ? void 0 : _a.setInputDisabled(disabled);
    (_b = this.eHeaderComp) == null ? void 0 : _b.setInputDisabled(disabled);
  }
  toggleFilterBuilder(source, force) {
    if (force && this.eBuilderDialog || force === false && !this.eBuilderDialog) {
      return;
    }
    if (this.eBuilderDialog) {
      this.builderDestroySource = source;
      this.destroyBean(this.eBuilderDialog);
      return;
    }
    this.setInputDisabled(true);
    const { width, height, minWidth } = this.getBuilderDialogSize();
    this.eBuilderComp = this.createBean(new AdvancedFilterBuilderComp());
    this.eBuilderDialog = this.createBean(new AgDialog({
      title: this.advancedFilterExpressionService.translate("advancedFilterBuilderTitle"),
      component: this.eBuilderComp,
      width,
      height,
      resizable: true,
      movable: true,
      maximizable: true,
      centered: true,
      closable: true,
      minWidth,
      afterGuiAttached: () => {
        var _a;
        return (_a = this.eBuilderComp) == null ? void 0 : _a.afterGuiAttached();
      }
    }));
    this.dispatchFilterBuilderVisibleChangedEvent(source, true);
    this.eBuilderDialog.addEventListener(AgDialog.EVENT_DESTROYED, () => {
      var _a;
      this.destroyBean(this.eBuilderComp);
      this.eBuilderComp = void 0;
      this.eBuilderDialog = void 0;
      this.setInputDisabled(false);
      this.dispatchEvent({
        type: _AdvancedFilterCtrl2.EVENT_BUILDER_CLOSED
      });
      this.dispatchFilterBuilderVisibleChangedEvent((_a = this.builderDestroySource) != null ? _a : "ui", false);
      this.builderDestroySource = void 0;
    });
  }
  dispatchFilterBuilderVisibleChangedEvent(source, visible) {
    const event = {
      type: Events5.EVENT_ADVANCED_FILTER_BUILDER_VISIBLE_CHANGED,
      source,
      visible
    };
    this.eventService.dispatchEvent(event);
  }
  getBuilderDialogSize() {
    var _a, _b;
    const minWidth = (_b = (_a = this.gos.get("advancedFilterBuilderParams")) == null ? void 0 : _a.minWidth) != null ? _b : 500;
    const popupParent = this.popupService.getPopupParent();
    const maxWidth = Math.round(_11.getAbsoluteWidth(popupParent)) - 2;
    const maxHeight = Math.round(_11.getAbsoluteHeight(popupParent) * 0.75) - 2;
    const width = Math.min(Math.max(600, minWidth), maxWidth);
    const height = Math.min(600, maxHeight);
    return { width, height, minWidth };
  }
  onEnabledChanged(enabled) {
    this.enabled = enabled;
    this.updateComps();
  }
  updateComps() {
    this.setAdvancedFilterComp();
    this.setHeaderCompEnabled();
    this.eventService.dispatchEvent({
      type: Events5.EVENT_HEADER_HEIGHT_CHANGED
    });
  }
  setAdvancedFilterComp() {
    this.destroyAdvancedFilterComp();
    if (!this.enabled) {
      return;
    }
    const advancedFilterParent = this.gos.get("advancedFilterParent");
    this.hasAdvancedFilterParent = !!advancedFilterParent;
    if (advancedFilterParent) {
      const eAdvancedFilterComp = this.createBean(new AdvancedFilterComp());
      const eAdvancedFilterCompGui = eAdvancedFilterComp.getGui();
      const { allThemes } = this.environment.getTheme();
      if (allThemes.length) {
        eAdvancedFilterCompGui.classList.add(...allThemes);
      }
      eAdvancedFilterCompGui.classList.add(this.gos.get("enableRtl") ? "ag-rtl" : "ag-ltr");
      advancedFilterParent.appendChild(eAdvancedFilterCompGui);
      this.eFilterComp = eAdvancedFilterComp;
    }
  }
  setHeaderCompEnabled() {
    var _a;
    (_a = this.eHeaderComp) == null ? void 0 : _a.setEnabled(this.enabled && !this.hasAdvancedFilterParent);
  }
  destroyAdvancedFilterComp() {
    if (this.eFilterComp) {
      _11.removeFromParent(this.eFilterComp.getGui());
      this.destroyBean(this.eFilterComp);
    }
  }
};
_AdvancedFilterCtrl.EVENT_BUILDER_CLOSED = "advancedFilterBuilderClosed";
__decorateClass2([
  Autowired8("focusService")
], _AdvancedFilterCtrl.prototype, "focusService", 2);
__decorateClass2([
  Autowired8("ctrlsService")
], _AdvancedFilterCtrl.prototype, "ctrlsService", 2);
__decorateClass2([
  Autowired8("popupService")
], _AdvancedFilterCtrl.prototype, "popupService", 2);
__decorateClass2([
  Autowired8("advancedFilterExpressionService")
], _AdvancedFilterCtrl.prototype, "advancedFilterExpressionService", 2);
__decorateClass2([
  PostConstruct8
], _AdvancedFilterCtrl.prototype, "postConstruct", 1);
var AdvancedFilterCtrl = _AdvancedFilterCtrl;
var AdvancedFilterComp = class extends Component8 {
  constructor() {
    super(
      /* html */
      `
            <div class="ag-advanced-filter" role="presentation" tabindex="-1">
                <ag-autocomplete ref="eAutocomplete"></ag-autocomplete>
                <button class="ag-button ag-standard-button ag-advanced-filter-apply-button" ref="eApplyFilterButton"></button>
                <button class="ag-advanced-filter-builder-button" ref="eBuilderFilterButton">
                    <span ref="eBuilderFilterButtonIcon" aria-hidden="true"></span>
                    <span class="ag-advanced-filter-builder-button-label" ref="eBuilderFilterButtonLabel"></span>
                </button>
            </div>`
    );
    this.expressionParser = null;
    this.isApplyDisabled = true;
    this.builderOpen = false;
  }
  postConstruct() {
    this.eAutocomplete.setListGenerator((_value, position) => this.generateAutocompleteListParams(position)).setValidator(() => this.validateValue()).setForceLastSelection((lastSelection, searchString) => this.forceLastSelection(lastSelection, searchString)).setInputAriaLabel(this.advancedFilterExpressionService.translate("ariaAdvancedFilterInput")).setListAriaLabel(this.advancedFilterExpressionService.translate("ariaLabelAdvancedFilterAutocomplete"));
    this.refresh();
    this.addManagedListener(
      this.eAutocomplete,
      AgAutocomplete.EVENT_VALUE_CHANGED,
      ({ value }) => this.onValueChanged(value)
    );
    this.addManagedListener(
      this.eAutocomplete,
      AgAutocomplete.EVENT_VALUE_CONFIRMED,
      ({ isValid }) => this.onValueConfirmed(isValid)
    );
    this.addManagedListener(
      this.eAutocomplete,
      AgAutocomplete.EVENT_OPTION_SELECTED,
      ({ position, updateEntry, autocompleteType }) => this.onOptionSelected(position, updateEntry, autocompleteType)
    );
    this.addManagedListener(
      this.eAutocomplete,
      AgAutocomplete.EVENT_VALID_CHANGED,
      ({ isValid, validationMessage }) => this.onValidChanged(isValid, validationMessage)
    );
    this.setupApplyButton();
    this.setupBuilderButton();
  }
  refresh() {
    const expression = this.advancedFilterService.getExpressionDisplayValue();
    this.eAutocomplete.setValue({ value: expression != null ? expression : "", position: expression == null ? void 0 : expression.length, updateListOnlyIfOpen: true });
  }
  setInputDisabled(disabled) {
    this.eAutocomplete.setInputDisabled(disabled);
    _12.setDisabled(this.eApplyFilterButton, disabled || this.isApplyDisabled);
  }
  getTooltipParams() {
    const res = super.getTooltipParams();
    res.location = "advancedFilter";
    return res;
  }
  setupApplyButton() {
    this.eApplyFilterButton.innerText = this.advancedFilterExpressionService.translate("advancedFilterApply");
    this.activateTabIndex([this.eApplyFilterButton]);
    this.addManagedListener(this.eApplyFilterButton, "click", () => this.onValueConfirmed(this.eAutocomplete.isValid()));
    _12.setDisabled(this.eApplyFilterButton, this.isApplyDisabled);
  }
  setupBuilderButton() {
    this.eBuilderFilterButtonIcon.appendChild(_12.createIconNoSpan("advancedFilterBuilder", this.gos));
    this.eBuilderFilterButtonLabel.innerText = this.advancedFilterExpressionService.translate("advancedFilterBuilder");
    this.activateTabIndex([this.eBuilderFilterButton]);
    this.addManagedListener(this.eBuilderFilterButton, "click", () => this.openBuilder());
    this.addManagedListener(this.advancedFilterService.getCtrl(), AdvancedFilterCtrl.EVENT_BUILDER_CLOSED, () => this.closeBuilder());
  }
  onValueChanged(value) {
    var _a;
    value = _12.makeNull(value);
    this.advancedFilterService.setExpressionDisplayValue(value);
    this.expressionParser = this.advancedFilterService.createExpressionParser(value);
    const updatedExpression = (_a = this.expressionParser) == null ? void 0 : _a.parseExpression();
    if (updatedExpression && updatedExpression !== value) {
      this.eAutocomplete.setValue({ value: updatedExpression, silent: true, restoreFocus: true });
    }
  }
  onValueConfirmed(isValid) {
    if (!isValid || this.isApplyDisabled) {
      return;
    }
    _12.setDisabled(this.eApplyFilterButton, true);
    this.advancedFilterService.applyExpression();
    this.filterManager.onFilterChanged({ source: "advancedFilter" });
  }
  onOptionSelected(position, updateEntry, type) {
    const { updatedValue, updatedPosition, hideAutocomplete } = this.updateExpression(position, updateEntry, type);
    this.eAutocomplete.setValue({
      value: updatedValue,
      position: updatedPosition,
      updateListOnlyIfOpen: hideAutocomplete,
      restoreFocus: true
    });
  }
  validateValue() {
    var _a, _b, _c;
    return ((_a = this.expressionParser) == null ? void 0 : _a.isValid()) ? null : (_c = (_b = this.expressionParser) == null ? void 0 : _b.getValidationMessage()) != null ? _c : null;
  }
  onValidChanged(isValid, validationMessage) {
    this.isApplyDisabled = !isValid || this.advancedFilterService.isCurrentExpressionApplied();
    _12.setDisabled(this.eApplyFilterButton, this.isApplyDisabled);
    this.setTooltip({
      newTooltipText: validationMessage,
      showDelayOverride: 1e3
    });
  }
  generateAutocompleteListParams(position) {
    return this.expressionParser ? this.expressionParser.getAutocompleteListParams(position) : this.advancedFilterExpressionService.getDefaultAutocompleteListParams("");
  }
  updateExpression(position, updateEntry, type) {
    var _a, _b;
    this.advancedFilterExpressionService.updateAutocompleteCache(updateEntry, type);
    return (_b = (_a = this.expressionParser) == null ? void 0 : _a.updateExpression(position, updateEntry, type)) != null ? _b : this.advancedFilterService.getDefaultExpression(updateEntry);
  }
  forceLastSelection({ key, displayValue }, searchString) {
    return !!searchString.toLocaleLowerCase().match(`^${(displayValue != null ? displayValue : key).toLocaleLowerCase()}\\s*$`);
  }
  openBuilder() {
    if (this.builderOpen) {
      return;
    }
    this.builderOpen = true;
    _12.setDisabled(this.eBuilderFilterButton, true);
    this.advancedFilterService.getCtrl().toggleFilterBuilder("ui");
  }
  closeBuilder() {
    if (!this.builderOpen) {
      return;
    }
    this.builderOpen = false;
    _12.setDisabled(this.eBuilderFilterButton, false);
    this.eBuilderFilterButton.focus();
  }
};
__decorateClass2([
  RefSelector5("eAutocomplete")
], AdvancedFilterComp.prototype, "eAutocomplete", 2);
__decorateClass2([
  RefSelector5("eApplyFilterButton")
], AdvancedFilterComp.prototype, "eApplyFilterButton", 2);
__decorateClass2([
  RefSelector5("eBuilderFilterButton")
], AdvancedFilterComp.prototype, "eBuilderFilterButton", 2);
__decorateClass2([
  RefSelector5("eBuilderFilterButtonIcon")
], AdvancedFilterComp.prototype, "eBuilderFilterButtonIcon", 2);
__decorateClass2([
  RefSelector5("eBuilderFilterButtonLabel")
], AdvancedFilterComp.prototype, "eBuilderFilterButtonLabel", 2);
__decorateClass2([
  Autowired9("advancedFilterService")
], AdvancedFilterComp.prototype, "advancedFilterService", 2);
__decorateClass2([
  Autowired9("advancedFilterExpressionService")
], AdvancedFilterComp.prototype, "advancedFilterExpressionService", 2);
__decorateClass2([
  Autowired9("filterManager")
], AdvancedFilterComp.prototype, "filterManager", 2);
__decorateClass2([
  PostConstruct9
], AdvancedFilterComp.prototype, "postConstruct", 1);
var ADVANCED_FILTER_LOCALE_TEXT = {
  ariaAdvancedFilterBuilderItem: (variableValues) => `${variableValues[0]}. Level ${variableValues[1]}. Press ENTER to edit.`,
  ariaAdvancedFilterBuilderItemValidation: (variableValues) => `${variableValues[0]}. Level ${variableValues[1]}. ${variableValues[2]} Press ENTER to edit.`,
  ariaAdvancedFilterBuilderList: "Advanced Filter Builder List",
  ariaAdvancedFilterBuilderFilterItem: "Filter Condition",
  ariaAdvancedFilterBuilderGroupItem: "Filter Group",
  ariaAdvancedFilterBuilderColumn: "Column",
  ariaAdvancedFilterBuilderOption: "Option",
  ariaAdvancedFilterBuilderValue: "Value",
  ariaAdvancedFilterBuilderJoinOperator: "Join Operator",
  ariaAdvancedFilterInput: "Advanced Filter Input",
  ariaLabelAdvancedFilterAutocomplete: "Advanced Filter Autocomplete",
  advancedFilterContains: "contains",
  advancedFilterNotContains: "does not contain",
  advancedFilterTextEquals: "equals",
  advancedFilterTextNotEqual: "does not equal",
  advancedFilterStartsWith: "begins with",
  advancedFilterEndsWith: "ends with",
  advancedFilterBlank: "is blank",
  advancedFilterNotBlank: "is not blank",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "is true",
  advancedFilterFalse: "is false",
  advancedFilterAnd: "AND",
  advancedFilterOr: "OR",
  advancedFilterApply: "Apply",
  advancedFilterBuilder: "Builder",
  advancedFilterValidationMissingColumn: "Column is missing",
  advancedFilterValidationMissingOption: "Option is missing",
  advancedFilterValidationMissingValue: "Value is missing",
  advancedFilterValidationInvalidColumn: "Column not found",
  advancedFilterValidationInvalidOption: "Option not found",
  advancedFilterValidationMissingQuote: "Value is missing an end quote",
  advancedFilterValidationNotANumber: "Value is not a number",
  advancedFilterValidationInvalidDate: "Value is not a valid date",
  advancedFilterValidationMissingCondition: "Condition is missing",
  advancedFilterValidationJoinOperatorMismatch: "Join operators within a condition must be the same",
  advancedFilterValidationInvalidJoinOperator: "Join operator not found",
  advancedFilterValidationMissingEndBracket: "Missing end bracket",
  advancedFilterValidationExtraEndBracket: "Too many end brackets",
  advancedFilterValidationMessage: (variableValues) => `Expression has an error. ${variableValues[0]} - ${variableValues[1]}.`,
  advancedFilterValidationMessageAtEnd: (variableValues) => `Expression has an error. ${variableValues[0]} at end of expression.`,
  advancedFilterBuilderTitle: "Advanced Filter",
  advancedFilterBuilderApply: "Apply",
  advancedFilterBuilderCancel: "Cancel",
  advancedFilterBuilderAddButtonTooltip: "Add Filter or Group",
  advancedFilterBuilderRemoveButtonTooltip: "Remove",
  advancedFilterBuilderMoveUpButtonTooltip: "Move Up",
  advancedFilterBuilderMoveDownButtonTooltip: "Move Down",
  advancedFilterBuilderAddJoin: "Add Group",
  advancedFilterBuilderAddCondition: "Add Filter",
  advancedFilterBuilderSelectColumn: "Select a column",
  advancedFilterBuilderSelectOption: "Select an option",
  advancedFilterBuilderEnterValue: "Enter a value...",
  advancedFilterBuilderValidationAlreadyApplied: "Current filter already applied.",
  advancedFilterBuilderValidationIncomplete: "Not all conditions are complete.",
  advancedFilterBuilderValidationSelectColumn: "Must select a column.",
  advancedFilterBuilderValidationSelectOption: "Must select an option.",
  advancedFilterBuilderValidationEnterValue: "Must enter a value."
};
function getSearchString(value, position, endPosition) {
  if (!value) {
    return "";
  }
  const numChars = endPosition - position;
  return numChars ? value.slice(0, value.length - numChars) : value;
}
function updateExpression(expression, startPosition, endPosition, updatedValuePart, appendSpace, appendQuote, empty) {
  const secondPartStartPosition = endPosition + (!expression.length || empty ? 0 : 1);
  let positionOffset = 0;
  if (appendSpace) {
    if (expression[secondPartStartPosition] === " ") {
      positionOffset = 1;
    } else {
      updatedValuePart += " ";
      if (appendQuote) {
        updatedValuePart += `"`;
      }
    }
  }
  const updatedValue = expression.slice(0, startPosition) + updatedValuePart + expression.slice(secondPartStartPosition);
  return { updatedValue, updatedPosition: startPosition + updatedValuePart.length + positionOffset };
}
function findStartPosition(expression, position, endPosition) {
  let startPosition = position;
  while (startPosition < endPosition) {
    const char = expression[startPosition];
    if (char !== " ") {
      break;
    }
    startPosition++;
  }
  return startPosition;
}
function findEndPosition(expression, position, includeCloseBracket, isStartPositionUnknown) {
  let endPosition = position;
  let isEmpty = false;
  while (endPosition < expression.length) {
    const char = expression[endPosition];
    if (char === "(") {
      if (isStartPositionUnknown && expression[endPosition - 1] === " ") {
        isEmpty = true;
      } else {
        endPosition = endPosition - 1;
      }
      break;
    } else if (char === " " || includeCloseBracket && char === ")") {
      endPosition = endPosition - 1;
      break;
    }
    endPosition++;
  }
  return { endPosition, isEmpty };
}
function checkAndUpdateExpression(params, userValue, displayValue, endPosition) {
  if (displayValue !== userValue) {
    params.expression = updateExpression(
      params.expression,
      endPosition - userValue.length + 1,
      endPosition,
      displayValue
    ).updatedValue;
  }
}
function escapeQuotes(value) {
  return value.replace(/(['"])/, "\\$1");
}
var ColumnParser = class {
  constructor(params, startPosition) {
    this.params = params;
    this.startPosition = startPosition;
    this.type = "column";
    this.valid = true;
    this.hasStartChar = false;
    this.hasEndChar = false;
    this.colName = "";
  }
  parse(char, position) {
    if (char === ColFilterExpressionParser.COL_START_CHAR && !this.colName) {
      this.hasStartChar = true;
    } else if (char === ColFilterExpressionParser.COL_END_CHAR && this.hasStartChar) {
      const isMatch = this.parseColumn(false, position);
      if (isMatch) {
        this.hasEndChar = true;
        return false;
      } else {
        this.colName += char;
      }
    } else {
      this.colName += char;
    }
    return void 0;
  }
  getDisplayValue() {
    return (this.hasStartChar ? ColFilterExpressionParser.COL_START_CHAR : "") + this.colName + (this.hasEndChar ? ColFilterExpressionParser.COL_END_CHAR : "");
  }
  getColId() {
    return this.colId;
  }
  complete(position) {
    this.parseColumn(true, position);
  }
  getValidationError() {
    var _a;
    return this.valid ? null : {
      message: this.params.advancedFilterExpressionService.translate("advancedFilterValidationInvalidColumn"),
      startPosition: this.startPosition,
      endPosition: (_a = this.endPosition) != null ? _a : this.params.expression.length - 1
    };
  }
  parseColumn(fromComplete, endPosition) {
    var _a;
    this.endPosition = endPosition;
    const colValue = this.params.advancedFilterExpressionService.getColId(this.colName);
    if (colValue && this.hasStartChar) {
      this.colId = colValue.colId;
      checkAndUpdateExpression(this.params, this.colName, colValue.columnName, endPosition - 1);
      this.colName = colValue.columnName;
      this.column = this.params.columnModel.getPrimaryColumn(this.colId);
      if (this.column) {
        this.baseCellDataType = (_a = this.params.dataTypeService.getBaseDataType(this.column)) != null ? _a : "text";
        return true;
      }
    }
    if (fromComplete) {
      this.valid = false;
    }
    this.baseCellDataType = "text";
    return false;
  }
};
var OperatorParser = class {
  constructor(params, startPosition, baseCellDataType) {
    this.params = params;
    this.startPosition = startPosition;
    this.baseCellDataType = baseCellDataType;
    this.type = "operator";
    this.valid = true;
    this.expectedNumOperands = 0;
    this.operator = "";
  }
  parse(char, position) {
    if (char === " " || char === ")") {
      const isMatch = this.parseOperator(false, position - 1);
      if (isMatch) {
        return true;
      } else {
        this.operator += char;
      }
    } else {
      this.operator += char;
    }
    return void 0;
  }
  complete(position) {
    this.parseOperator(true, position);
  }
  getValidationError() {
    var _a;
    return this.valid ? null : {
      message: this.params.advancedFilterExpressionService.translate("advancedFilterValidationInvalidOption"),
      startPosition: this.startPosition,
      endPosition: (_a = this.endPosition) != null ? _a : this.params.expression.length - 1
    };
  }
  getDisplayValue() {
    return this.operator;
  }
  getOperatorKey() {
    return this.parsedOperator;
  }
  parseOperator(fromComplete, endPosition) {
    const operatorForType = this.params.advancedFilterExpressionService.getDataTypeExpressionOperator(this.baseCellDataType);
    const parsedOperator = operatorForType.findOperator(this.operator);
    this.endPosition = endPosition;
    if (parsedOperator) {
      this.parsedOperator = parsedOperator;
      const operator = operatorForType.operators[parsedOperator];
      this.expectedNumOperands = operator.numOperands;
      const operatorDisplayValue = operator.displayValue;
      checkAndUpdateExpression(this.params, this.operator, operatorDisplayValue, endPosition);
      this.operator = operatorDisplayValue;
      return true;
    }
    const isPartialMatch = parsedOperator === null;
    if (fromComplete || !isPartialMatch) {
      this.valid = false;
    }
    return false;
  }
};
var OperandParser = class {
  constructor(params, startPosition, baseCellDataType, column) {
    this.params = params;
    this.startPosition = startPosition;
    this.baseCellDataType = baseCellDataType;
    this.column = column;
    this.type = "operand";
    this.valid = true;
    this.operand = "";
    this.validationMessage = null;
  }
  parse(char, position) {
    if (char === " ") {
      if (this.quotes) {
        this.operand += char;
      } else {
        this.parseOperand(false, position);
        return true;
      }
    } else if (char === ")") {
      if (this.baseCellDataType === "number" || !this.quotes) {
        this.parseOperand(false, position - 1);
        return true;
      } else {
        this.operand += char;
      }
    } else if (!this.operand && !this.quotes && (char === `'` || char === `"`)) {
      this.quotes = char;
    } else if (this.quotes && char === this.quotes) {
      this.parseOperand(false, position);
      return false;
    } else {
      this.operand += char;
    }
    return void 0;
  }
  complete(position) {
    this.parseOperand(true, position);
  }
  getValidationError() {
    var _a;
    return this.validationMessage ? {
      message: this.validationMessage,
      startPosition: this.startPosition,
      endPosition: (_a = this.endPosition) != null ? _a : this.params.expression.length - 1
    } : null;
  }
  getRawValue() {
    return this.operand;
  }
  getModelValue() {
    return this.modelValue;
  }
  parseOperand(fromComplete, position) {
    const { advancedFilterExpressionService } = this.params;
    this.endPosition = position;
    this.modelValue = this.operand;
    if (fromComplete && this.quotes) {
      this.valid = false;
      this.validationMessage = advancedFilterExpressionService.translate("advancedFilterValidationMissingQuote");
    } else if (this.modelValue === "") {
      this.valid = false;
      this.validationMessage = advancedFilterExpressionService.translate("advancedFilterValidationMissingValue");
    } else {
      const modelValue = advancedFilterExpressionService.getOperandModelValue(this.operand, this.baseCellDataType, this.column);
      if (modelValue != null) {
        this.modelValue = modelValue;
      }
      switch (this.baseCellDataType) {
        case "number":
          if (this.quotes || isNaN(this.modelValue)) {
            this.valid = false;
            this.validationMessage = advancedFilterExpressionService.translate("advancedFilterValidationNotANumber");
          }
          break;
        case "date":
        case "dateString":
          if (modelValue == null) {
            this.valid = false;
            this.validationMessage = advancedFilterExpressionService.translate("advancedFilterValidationInvalidDate");
          }
          break;
      }
    }
  }
};
var ColFilterExpressionParser = class {
  constructor(params, startPosition) {
    this.params = params;
    this.startPosition = startPosition;
    this.isAwaiting = true;
  }
  parseExpression() {
    var _a, _b;
    let i = this.startPosition;
    const { expression } = this.params;
    while (i < expression.length) {
      const char = expression[i];
      if (char === " " && this.isAwaiting) {
      } else {
        this.isAwaiting = false;
        if (!this.parser) {
          let parser;
          if (!this.columnParser) {
            this.columnParser = new ColumnParser(this.params, i);
            parser = this.columnParser;
          } else if (!this.operatorParser) {
            this.operatorParser = new OperatorParser(this.params, i, this.columnParser.baseCellDataType);
            parser = this.operatorParser;
          } else {
            this.operandParser = new OperandParser(this.params, i, this.columnParser.baseCellDataType, this.columnParser.column);
            parser = this.operandParser;
          }
          this.parser = parser;
        }
        const hasCompletedOnPrevChar = this.parser.parse(char, i);
        if (hasCompletedOnPrevChar != null) {
          if (this.isComplete()) {
            return this.returnEndPosition(hasCompletedOnPrevChar ? i - 1 : i, true);
          }
          this.parser = void 0;
          this.isAwaiting = true;
        }
      }
      i++;
    }
    (_b = (_a = this.parser) == null ? void 0 : _a.complete) == null ? void 0 : _b.call(_a, i - 1);
    return this.returnEndPosition(i);
  }
  isValid() {
    return this.isComplete() && this.columnParser.valid && this.operatorParser.valid && (!this.operandParser || this.operandParser.valid);
  }
  getValidationError() {
    var _a, _b, _c, _d, _e;
    const validationError = (_e = (_c = (_a = this.columnParser) == null ? void 0 : _a.getValidationError()) != null ? _c : (_b = this.operatorParser) == null ? void 0 : _b.getValidationError()) != null ? _e : (_d = this.operandParser) == null ? void 0 : _d.getValidationError();
    if (validationError) {
      return validationError;
    }
    const endPosition = this.params.expression.length;
    let translateKey;
    if (!this.columnParser) {
      translateKey = "advancedFilterValidationMissingColumn";
    } else if (!this.operatorParser) {
      translateKey = "advancedFilterValidationMissingOption";
    } else if (this.operatorParser.expectedNumOperands && !this.operandParser) {
      translateKey = "advancedFilterValidationMissingValue";
    }
    if (translateKey) {
      return {
        message: this.params.advancedFilterExpressionService.translate(translateKey),
        startPosition: endPosition,
        endPosition
      };
    }
    return null;
  }
  getFunctionString(params) {
    return this.getFunctionCommon(params, (operandIndex, operatorIndex, colId, evaluatorParamsIndex) => {
      const escapedColId = escapeQuotes(colId);
      const operand = operandIndex == null ? "" : `, params.operands[${operandIndex}]`;
      return `params.operators[${operatorIndex}].evaluator(expressionProxy.getValue('${escapedColId}', node), node, params.evaluatorParams[${evaluatorParamsIndex}]${operand})`;
    });
  }
  getFunctionParsed(params) {
    return this.getFunctionCommon(params, (operandIndex, operatorIndex, colId, evaluatorParamsIndex) => {
      return (expressionProxy, node, p) => p.operators[operatorIndex].evaluator(
        expressionProxy.getValue(colId, node),
        node,
        p.evaluatorParams[evaluatorParamsIndex],
        operandIndex == null ? void 0 : p.operands[operandIndex]
      );
    });
  }
  getAutocompleteListParams(position) {
    if (this.isColumnPosition(position)) {
      return this.getColumnAutocompleteListParams(position);
    }
    if (this.isOperatorPosition(position)) {
      return this.getOperatorAutocompleteListParams(position);
    }
    if (this.isBeyondEndPosition(position)) {
      return void 0;
    }
    return { enabled: false };
  }
  updateExpression(position, updateEntry, type) {
    var _a, _b, _c, _d, _e;
    const { expression } = this.params;
    if (this.isColumnPosition(position)) {
      return updateExpression(
        this.params.expression,
        this.startPosition,
        ((_a = this.columnParser) == null ? void 0 : _a.getColId()) ? this.columnParser.endPosition : findEndPosition(expression, position).endPosition,
        this.params.advancedFilterExpressionService.getColumnValue(updateEntry),
        true
      );
    } else if (this.isOperatorPosition(position)) {
      const baseCellDataType = this.getBaseCellDataTypeFromOperatorAutocompleteType(type);
      const hasOperand = this.hasOperand(baseCellDataType, updateEntry.key);
      const doesOperandNeedQuotes = hasOperand && this.doesOperandNeedQuotes(baseCellDataType);
      let update;
      if (((_b = this.operatorParser) == null ? void 0 : _b.startPosition) != null && position < this.operatorParser.startPosition) {
        update = updateExpression(
          expression,
          position,
          position,
          (_c = updateEntry.displayValue) != null ? _c : updateEntry.key,
          hasOperand,
          doesOperandNeedQuotes
        );
      } else {
        let endPosition;
        let empty = false;
        if ((_d = this.operatorParser) == null ? void 0 : _d.getOperatorKey()) {
          endPosition = this.operatorParser.endPosition;
        } else {
          const { endPosition: calculatedEndPosition, isEmpty } = findEndPosition(expression, position, true, true);
          endPosition = calculatedEndPosition;
          empty = isEmpty;
        }
        update = updateExpression(
          expression,
          findStartPosition(expression, this.columnParser.endPosition + 1, endPosition),
          endPosition,
          (_e = updateEntry.displayValue) != null ? _e : updateEntry.key,
          hasOperand,
          doesOperandNeedQuotes,
          empty
        );
      }
      return __spreadProps(__spreadValues({}, update), { hideAutocomplete: !hasOperand });
    }
    return null;
  }
  getModel() {
    const colId = this.columnParser.getColId();
    const model = {
      filterType: this.columnParser.baseCellDataType,
      colId,
      type: this.operatorParser.getOperatorKey()
    };
    if (this.operatorParser.expectedNumOperands) {
      model.filter = this.operandParser.getModelValue();
    }
    return model;
  }
  getFunctionCommon(params, processFunc) {
    var _a, _b;
    const colId = this.columnParser.getColId();
    const operator = (_a = this.operatorParser) == null ? void 0 : _a.getOperatorKey();
    const { operators, evaluatorParams, operands } = params;
    const operatorForColumn = this.params.advancedFilterExpressionService.getExpressionOperator(this.columnParser.baseCellDataType, operator);
    const operatorIndex = this.addToListAndGetIndex(operators, operatorForColumn);
    const evaluatorParamsForColumn = this.params.advancedFilterExpressionService.getExpressionEvaluatorParams(colId);
    const evaluatorParamsIndex = this.addToListAndGetIndex(evaluatorParams, evaluatorParamsForColumn);
    const operandIndex = ((_b = this.operatorParser) == null ? void 0 : _b.expectedNumOperands) === 0 ? void 0 : this.addToListAndGetIndex(operands, this.getOperandValue());
    return processFunc(operandIndex, operatorIndex, colId, evaluatorParamsIndex);
  }
  getOperandValue() {
    let operand = this.operandParser.getRawValue();
    const { baseCellDataType, column } = this.columnParser;
    switch (baseCellDataType) {
      case "number":
        operand = Number(operand);
        break;
      case "date":
      case "dateString":
        operand = this.params.valueService.parseValue(column, null, operand, void 0);
        break;
    }
    if (baseCellDataType === "dateString") {
      return this.params.dataTypeService.getDateParserFunction(column)(operand);
    }
    return operand;
  }
  isComplete() {
    return !!(this.operatorParser && (!this.operatorParser.expectedNumOperands || this.operatorParser.expectedNumOperands && this.operandParser));
  }
  isColumnPosition(position) {
    return !this.columnParser || this.columnParser.endPosition == null || position <= this.columnParser.endPosition + 1;
  }
  isOperatorPosition(position) {
    return !this.operatorParser || this.operatorParser.endPosition == null || position <= this.operatorParser.endPosition + 1;
  }
  isBeyondEndPosition(position) {
    return this.isComplete() && this.endPosition != null && position > this.endPosition + 1 && this.endPosition + 1 < this.params.expression.length;
  }
  returnEndPosition(returnPosition, treatAsEnd) {
    this.endPosition = treatAsEnd ? returnPosition : returnPosition - 1;
    return returnPosition;
  }
  getColumnAutocompleteListParams(position) {
    return this.params.advancedFilterExpressionService.generateAutocompleteListParams(
      this.params.advancedFilterExpressionService.getColumnAutocompleteEntries(),
      "column",
      this.getColumnSearchString(position)
    );
  }
  getColumnSearchString(position) {
    var _a, _b, _c, _d, _e;
    const columnName = (_b = (_a = this.columnParser) == null ? void 0 : _a.getDisplayValue()) != null ? _b : "";
    const searchString = getSearchString(
      columnName,
      position,
      ((_c = this.columnParser) == null ? void 0 : _c.endPosition) == null ? this.params.expression.length : this.columnParser.endPosition + 1
    );
    const containsStartChar = ((_d = this.columnParser) == null ? void 0 : _d.hasStartChar) && searchString.length > 0;
    const containsEndChar = ((_e = this.columnParser) == null ? void 0 : _e.hasEndChar) && searchString.length === columnName.length + 2;
    if (containsStartChar) {
      return searchString.slice(1, containsEndChar ? -1 : void 0);
    }
    return searchString;
  }
  getOperatorAutocompleteListParams(position) {
    var _a, _b, _c, _d, _e;
    const column = (_a = this.columnParser) == null ? void 0 : _a.column;
    if (!column) {
      return { enabled: false };
    }
    const baseCellDataType = this.columnParser.baseCellDataType;
    const searchString = ((_b = this.operatorParser) == null ? void 0 : _b.startPosition) != null && position < this.operatorParser.startPosition ? "" : getSearchString(
      (_d = (_c = this.operatorParser) == null ? void 0 : _c.getDisplayValue()) != null ? _d : "",
      position,
      ((_e = this.operatorParser) == null ? void 0 : _e.endPosition) == null ? this.params.expression.length : this.operatorParser.endPosition + 1
    );
    return this.params.advancedFilterExpressionService.generateAutocompleteListParams(
      this.params.advancedFilterExpressionService.getOperatorAutocompleteEntries(column, baseCellDataType),
      `operator-${baseCellDataType}`,
      searchString
    );
  }
  getBaseCellDataTypeFromOperatorAutocompleteType(type) {
    return type == null ? void 0 : type.replace("operator-", "");
  }
  hasOperand(baseCellDataType, operator) {
    var _a, _b;
    return !baseCellDataType || !operator || ((_b = (_a = this.params.advancedFilterExpressionService.getExpressionOperator(baseCellDataType, operator)) == null ? void 0 : _a.numOperands) != null ? _b : 0) > 0;
  }
  doesOperandNeedQuotes(baseCellDataType) {
    return baseCellDataType !== "number";
  }
  addToListAndGetIndex(list, value) {
    const index = list.length;
    list.push(value);
    return index;
  }
};
ColFilterExpressionParser.COL_START_CHAR = "[";
ColFilterExpressionParser.COL_END_CHAR = "]";
function findMatch(searchValue, values, getDisplayValue) {
  let partialMatch = false;
  const searchValueLowerCase = searchValue.toLocaleLowerCase();
  const partialSearchValue = searchValueLowerCase + " ";
  const parsedValue = Object.entries(values).find(([_key, value]) => {
    const displayValueLowerCase = getDisplayValue(value).toLocaleLowerCase();
    if (displayValueLowerCase.startsWith(partialSearchValue)) {
      partialMatch = true;
    }
    return displayValueLowerCase === searchValueLowerCase;
  });
  if (parsedValue) {
    return parsedValue[0];
  } else if (partialMatch) {
    return null;
  } else {
    return void 0;
  }
}
function getEntries(operators, activeOperatorKeys) {
  const keys = activeOperatorKeys != null ? activeOperatorKeys : Object.keys(operators);
  return keys.map((key) => ({
    key,
    displayValue: operators[key].displayValue
  }));
}
var TextFilterExpressionOperators = class {
  constructor(params) {
    this.params = params;
    this.initOperators();
  }
  getEntries(activeOperators) {
    return getEntries(this.operators, activeOperators);
  }
  findOperator(displayValue) {
    return findMatch(displayValue, this.operators, ({ displayValue: displayValue2 }) => displayValue2);
  }
  initOperators() {
    const { translate } = this.params;
    this.operators = {
      contains: {
        displayValue: translate("advancedFilterContains"),
        evaluator: (value, node, params, operand1) => this.evaluateExpression(value, node, params, operand1, false, (v, o) => v.includes(o)),
        numOperands: 1
      },
      notContains: {
        displayValue: translate("advancedFilterNotContains"),
        evaluator: (value, node, params, operand1) => this.evaluateExpression(value, node, params, operand1, true, (v, o) => !v.includes(o)),
        numOperands: 1
      },
      equals: {
        displayValue: translate("advancedFilterTextEquals"),
        evaluator: (value, node, params, operand1) => this.evaluateExpression(value, node, params, operand1, false, (v, o) => v === o),
        numOperands: 1
      },
      notEqual: {
        displayValue: translate("advancedFilterTextNotEqual"),
        evaluator: (value, node, params, operand1) => this.evaluateExpression(value, node, params, operand1, true, (v, o) => v != o),
        numOperands: 1
      },
      startsWith: {
        displayValue: translate("advancedFilterStartsWith"),
        evaluator: (value, node, params, operand1) => this.evaluateExpression(value, node, params, operand1, false, (v, o) => v.startsWith(o)),
        numOperands: 1
      },
      endsWith: {
        displayValue: translate("advancedFilterEndsWith"),
        evaluator: (value, node, params, operand1) => this.evaluateExpression(value, node, params, operand1, false, (v, o) => v.endsWith(o)),
        numOperands: 1
      },
      blank: {
        displayValue: translate("advancedFilterBlank"),
        evaluator: (value) => value == null || typeof value === "string" && value.trim().length === 0,
        numOperands: 0
      },
      notBlank: {
        displayValue: translate("advancedFilterNotBlank"),
        evaluator: (value) => value != null && (typeof value !== "string" || value.trim().length > 0),
        numOperands: 0
      }
    };
  }
  evaluateExpression(value, node, params, operand, nullsMatch, expression) {
    if (value == null) {
      return nullsMatch;
    }
    return params.caseSensitive ? expression(params.valueConverter(value, node), operand) : expression(params.valueConverter(value, node).toLocaleLowerCase(), operand.toLocaleLowerCase());
  }
};
var ScalarFilterExpressionOperators = class {
  constructor(params) {
    this.params = params;
    this.initOperators();
  }
  getEntries(activeOperators) {
    return getEntries(this.operators, activeOperators);
  }
  findOperator(displayValue) {
    return findMatch(displayValue, this.operators, ({ displayValue: displayValue2 }) => displayValue2);
  }
  initOperators() {
    const { translate, equals } = this.params;
    this.operators = {
      equals: {
        displayValue: translate("advancedFilterEquals"),
        evaluator: (value, node, params, operand1) => this.evaluateSingleOperandExpression(value, node, params, operand1, !!params.includeBlanksInEquals, equals),
        numOperands: 1
      },
      notEqual: {
        displayValue: translate("advancedFilterNotEqual"),
        evaluator: (value, node, params, operand1) => this.evaluateSingleOperandExpression(value, node, params, operand1, !!params.includeBlanksInEquals, (v, o) => !equals(v, o)),
        numOperands: 1
      },
      greaterThan: {
        displayValue: translate("advancedFilterGreaterThan"),
        evaluator: (value, node, params, operand1) => this.evaluateSingleOperandExpression(value, node, params, operand1, !!params.includeBlanksInGreaterThan, (v, o) => v > o),
        numOperands: 1
      },
      greaterThanOrEqual: {
        displayValue: translate("advancedFilterGreaterThanOrEqual"),
        evaluator: (value, node, params, operand1) => this.evaluateSingleOperandExpression(value, node, params, operand1, !!params.includeBlanksInGreaterThan, (v, o) => v >= o),
        numOperands: 1
      },
      lessThan: {
        displayValue: translate("advancedFilterLessThan"),
        evaluator: (value, node, params, operand1) => this.evaluateSingleOperandExpression(value, node, params, operand1, !!params.includeBlanksInLessThan, (v, o) => v < o),
        numOperands: 1
      },
      lessThanOrEqual: {
        displayValue: translate("advancedFilterLessThanOrEqual"),
        evaluator: (value, node, params, operand1) => this.evaluateSingleOperandExpression(value, node, params, operand1, !!params.includeBlanksInLessThan, (v, o) => v <= o),
        numOperands: 1
      },
      blank: {
        displayValue: translate("advancedFilterBlank"),
        evaluator: (value) => value == null,
        numOperands: 0
      },
      notBlank: {
        displayValue: translate("advancedFilterNotBlank"),
        evaluator: (value) => value != null,
        numOperands: 0
      }
    };
  }
  evaluateSingleOperandExpression(value, node, params, operand, nullsMatch, expression) {
    if (value == null) {
      return nullsMatch;
    }
    return expression(params.valueConverter(value, node), operand);
  }
};
var BooleanFilterExpressionOperators = class {
  constructor(params) {
    this.params = params;
    this.initOperators();
  }
  getEntries(activeOperators) {
    return getEntries(this.operators, activeOperators);
  }
  findOperator(displayValue) {
    return findMatch(displayValue, this.operators, ({ displayValue: displayValue2 }) => displayValue2);
  }
  initOperators() {
    const { translate } = this.params;
    this.operators = {
      true: {
        displayValue: translate("advancedFilterTrue"),
        evaluator: (value) => !!value,
        numOperands: 0
      },
      false: {
        displayValue: translate("advancedFilterFalse"),
        evaluator: (value) => value === false,
        numOperands: 0
      },
      blank: {
        displayValue: translate("advancedFilterBlank"),
        evaluator: (value) => value == null,
        numOperands: 0
      },
      notBlank: {
        displayValue: translate("advancedFilterNotBlank"),
        evaluator: (value) => value != null,
        numOperands: 0
      }
    };
  }
};
var AdvancedFilterExpressionService = class extends BeanStub4 {
  constructor() {
    super(...arguments);
    this.columnNameToIdMap = {};
    this.columnAutocompleteEntries = null;
    this.expressionEvaluatorParams = {};
  }
  postConstruct() {
    this.expressionJoinOperators = this.generateExpressionJoinOperators();
    this.expressionOperators = this.generateExpressionOperators();
  }
  parseJoinOperator(model) {
    var _a;
    const { type } = model;
    return (_a = this.expressionJoinOperators[type]) != null ? _a : type;
  }
  getColumnDisplayValue(model) {
    const { colId } = model;
    const columnEntries = this.getColumnAutocompleteEntries();
    const columnEntry = columnEntries.find(({ key }) => key === colId);
    let columnName;
    if (columnEntry) {
      columnName = columnEntry.displayValue;
      this.columnNameToIdMap[columnName.toLocaleUpperCase()] = { colId, columnName };
    } else {
      columnName = colId;
    }
    return columnName;
  }
  getOperatorDisplayValue(model) {
    var _a, _b;
    return (_b = (_a = this.getExpressionOperator(model.filterType, model.type)) == null ? void 0 : _a.displayValue) != null ? _b : model.type;
  }
  getOperandModelValue(operand, baseCellDataType, column) {
    var _a;
    switch (baseCellDataType) {
      case "number":
        return _13.exists(operand) ? Number(operand) : null;
      case "date":
        return _13.serialiseDate(this.valueService.parseValue(column, null, operand, void 0), false);
      case "dateString":
        const parsedDateString = this.valueService.parseValue(column, null, operand, void 0);
        return _13.serialiseDate((_a = this.dataTypeService.getDateParserFunction(column)(parsedDateString)) != null ? _a : null, false);
    }
    return operand;
  }
  getOperandDisplayValue(model, skipFormatting) {
    var _a, _b;
    const { colId, filter } = model;
    const column = this.columnModel.getPrimaryColumn(colId);
    let operand = "";
    if (filter != null) {
      let operand1;
      switch (model.filterType) {
        case "number":
          operand1 = (_a = _13.toStringOrNull(filter)) != null ? _a : "";
          break;
        case "date":
          const dateValue = _13.parseDateTimeFromString(filter);
          operand1 = column ? this.valueService.formatValue(column, null, dateValue) : null;
          break;
        case "dateString":
          const dateStringDateValue = _13.parseDateTimeFromString(filter);
          const dateStringStringValue = column ? this.dataTypeService.getDateFormatterFunction(column)(dateStringDateValue != null ? dateStringDateValue : void 0) : null;
          operand1 = column ? this.valueService.formatValue(column, null, dateStringStringValue) : null;
          break;
      }
      if (model.filterType !== "number") {
        operand1 = (_b = operand1 != null ? operand1 : _13.toStringOrNull(filter)) != null ? _b : "";
        if (!skipFormatting) {
          operand1 = `"${operand1}"`;
        }
      }
      operand = skipFormatting ? operand1 : ` ${operand1}`;
    }
    return operand;
  }
  parseColumnFilterModel(model) {
    var _a, _b;
    const columnName = (_a = this.getColumnDisplayValue(model)) != null ? _a : "";
    const operator = (_b = this.getOperatorDisplayValue(model)) != null ? _b : "";
    const operands = this.getOperandDisplayValue(model);
    return `[${columnName}] ${operator}${operands}`;
  }
  updateAutocompleteCache(updateEntry, type) {
    if (type === "column") {
      const { key: colId, displayValue } = updateEntry;
      this.columnNameToIdMap[updateEntry.displayValue.toLocaleUpperCase()] = { colId, columnName: displayValue };
    }
  }
  translate(key, variableValues) {
    let defaultValue = ADVANCED_FILTER_LOCALE_TEXT[key];
    if (typeof defaultValue === "function") {
      defaultValue = defaultValue(variableValues);
    }
    return this.localeService.getLocaleTextFunc()(key, defaultValue, variableValues);
  }
  generateAutocompleteListParams(entries, type, searchString) {
    return {
      enabled: true,
      type,
      searchString,
      entries
    };
  }
  getColumnAutocompleteEntries() {
    var _a;
    if (this.columnAutocompleteEntries) {
      return this.columnAutocompleteEntries;
    }
    const columns = (_a = this.columnModel.getAllPrimaryColumns()) != null ? _a : [];
    const entries = [];
    const includeHiddenColumns = this.gos.get("includeHiddenColumnsInAdvancedFilter");
    columns.forEach((column) => {
      if (column.getColDef().filter && (includeHiddenColumns || column.isVisible() || column.isRowGroupActive())) {
        entries.push({
          key: column.getColId(),
          displayValue: this.columnModel.getDisplayNameForColumn(column, "advancedFilter")
        });
      }
    });
    entries.sort((a, b) => {
      var _a2, _b;
      const aValue = (_a2 = a.displayValue) != null ? _a2 : "";
      const bValue = (_b = b.displayValue) != null ? _b : "";
      if (aValue < bValue) {
        return -1;
      } else if (bValue > aValue) {
        return 1;
      }
      return 0;
    });
    return entries;
  }
  getOperatorAutocompleteEntries(column, baseCellDataType) {
    const activeOperators = this.getActiveOperators(column);
    return this.getDataTypeExpressionOperator(baseCellDataType).getEntries(activeOperators);
  }
  getJoinOperatorAutocompleteEntries() {
    return Object.entries(this.expressionJoinOperators).map(([key, displayValue]) => ({ key, displayValue }));
  }
  getDefaultAutocompleteListParams(searchString) {
    return this.generateAutocompleteListParams(this.getColumnAutocompleteEntries(), "column", searchString);
  }
  getDataTypeExpressionOperator(baseCellDataType) {
    return this.expressionOperators[baseCellDataType];
  }
  getExpressionOperator(baseCellDataType, operator) {
    var _a, _b;
    return (_b = (_a = this.getDataTypeExpressionOperator(baseCellDataType)) == null ? void 0 : _a.operators) == null ? void 0 : _b[operator];
  }
  getExpressionJoinOperators() {
    return this.expressionJoinOperators;
  }
  getColId(columnName) {
    const upperCaseColumnName = columnName.toLocaleUpperCase();
    const cachedColId = this.columnNameToIdMap[upperCaseColumnName];
    if (cachedColId) {
      return cachedColId;
    }
    const columnAutocompleteEntries = this.getColumnAutocompleteEntries();
    const colEntry = columnAutocompleteEntries.find(({ displayValue }) => displayValue.toLocaleUpperCase() === upperCaseColumnName);
    if (colEntry) {
      const { key: colId, displayValue } = colEntry;
      const colValue = { colId, columnName: displayValue };
      this.columnNameToIdMap[upperCaseColumnName] = colValue;
      return colValue;
    }
    return null;
  }
  getExpressionEvaluatorParams(colId) {
    let params = this.expressionEvaluatorParams[colId];
    if (params) {
      return params;
    }
    const column = this.columnModel.getPrimaryColumn(colId);
    if (!column) {
      return { valueConverter: (v) => v };
    }
    const baseCellDataType = this.dataTypeService.getBaseDataType(column);
    switch (baseCellDataType) {
      case "dateString":
        params = {
          valueConverter: this.dataTypeService.getDateParserFunction(column)
        };
        break;
      case "object":
        if (column.getColDef().filterValueGetter) {
          params = { valueConverter: (v) => v };
        } else {
          params = {
            valueConverter: (value, node) => {
              var _a;
              return (_a = this.valueService.formatValue(column, node, value)) != null ? _a : typeof value.toString === "function" ? value.toString() : "";
            }
          };
        }
        break;
      case "text":
      case void 0:
        params = { valueConverter: (v) => _13.toStringOrNull(v) };
        break;
      default:
        params = { valueConverter: (v) => v };
        break;
    }
    const { filterParams } = column.getColDef();
    if (filterParams) {
      [
        "caseSensitive",
        "includeBlanksInEquals",
        "includeBlanksInLessThan",
        "includeBlanksInGreaterThan"
      ].forEach((param) => {
        const paramValue = filterParams[param];
        if (paramValue) {
          params[param] = paramValue;
        }
      });
    }
    this.expressionEvaluatorParams[colId] = params;
    return params;
  }
  getColumnDetails(colId) {
    var _a, _b;
    const column = (_a = this.columnModel.getPrimaryColumn(colId)) != null ? _a : void 0;
    const baseCellDataType = (_b = column ? this.dataTypeService.getBaseDataType(column) : void 0) != null ? _b : "text";
    return { column, baseCellDataType };
  }
  generateExpressionOperators() {
    const translate = (key, variableValues) => this.translate(key, variableValues);
    return {
      text: new TextFilterExpressionOperators({ translate }),
      boolean: new BooleanFilterExpressionOperators({ translate }),
      object: new TextFilterExpressionOperators({ translate }),
      number: new ScalarFilterExpressionOperators({ translate, equals: (v, o) => v === o }),
      date: new ScalarFilterExpressionOperators({ translate, equals: (v, o) => v.getTime() === o.getTime() }),
      dateString: new ScalarFilterExpressionOperators({ translate, equals: (v, o) => v.getTime() === o.getTime() })
    };
  }
  getColumnValue({ displayValue }) {
    return `${ColFilterExpressionParser.COL_START_CHAR}${displayValue}${ColFilterExpressionParser.COL_END_CHAR}`;
  }
  generateExpressionJoinOperators() {
    return {
      AND: this.translate("advancedFilterAnd"),
      OR: this.translate("advancedFilterOr")
    };
  }
  getActiveOperators(column) {
    var _a;
    const filterOptions = (_a = column.getColDef().filterParams) == null ? void 0 : _a.filterOptions;
    if (!filterOptions) {
      return void 0;
    }
    const isValid = filterOptions.every((filterOption) => typeof filterOption === "string");
    return isValid ? filterOptions : void 0;
  }
  resetColumnCaches() {
    this.columnAutocompleteEntries = null;
    this.columnNameToIdMap = {};
    this.expressionEvaluatorParams = {};
  }
};
__decorateClass2([
  Autowired10("valueService")
], AdvancedFilterExpressionService.prototype, "valueService", 2);
__decorateClass2([
  Autowired10("columnModel")
], AdvancedFilterExpressionService.prototype, "columnModel", 2);
__decorateClass2([
  Autowired10("dataTypeService")
], AdvancedFilterExpressionService.prototype, "dataTypeService", 2);
__decorateClass2([
  PostConstruct10
], AdvancedFilterExpressionService.prototype, "postConstruct", 1);
AdvancedFilterExpressionService = __decorateClass2([
  Bean2("advancedFilterExpressionService")
], AdvancedFilterExpressionService);
var OperatorParser2 = class {
  constructor(params) {
    this.params = params;
    this.operators = [];
    this.operatorStartPositions = [];
    this.operatorEndPositions = [];
    this.activeOperator = 0;
    this.validationError = null;
  }
  parseExpression(i) {
    this.operators.push("");
    this.operatorStartPositions.push(i);
    this.operatorEndPositions.push(void 0);
    const { expression } = this.params;
    while (i < expression.length) {
      const char = expression[i];
      if (char === " ") {
        const isComplete = this.parseOperator(i - 1);
        if (isComplete) {
          this.activeOperator++;
          return i - 1;
        } else {
          this.operators[this.activeOperator] += char;
        }
      } else {
        this.operators[this.activeOperator] += char;
      }
      i++;
    }
    this.parseOperator(i - 1);
    return i;
  }
  isValid() {
    return !this.validationError && (!this.operators.length || !!this.parsedOperator);
  }
  getValidationError() {
    return this.validationError;
  }
  getFunction() {
    return this.parsedOperator === "OR" ? "||" : "&&";
  }
  getModel() {
    return this.parsedOperator === "OR" ? "OR" : "AND";
  }
  getAutocompleteListParams(position, operatorIndex) {
    let searchString;
    if (operatorIndex == null) {
      searchString = "";
    } else {
      const operator = this.operators[operatorIndex];
      const operatorEndPosition = this.operatorEndPositions[operatorIndex];
      searchString = getSearchString(
        operator,
        position,
        operatorEndPosition == null ? this.params.expression.length : operatorEndPosition + 1
      );
    }
    let entries = this.params.advancedFilterExpressionService.getJoinOperatorAutocompleteEntries();
    if (operatorIndex || operatorIndex == null && this.activeOperator) {
      entries = entries.filter(({ key }) => key === this.parsedOperator);
    }
    return this.params.advancedFilterExpressionService.generateAutocompleteListParams(entries, "join", searchString);
  }
  updateExpression(position, updateEntry, operatorIndex) {
    var _a, _b;
    let { expression } = this.params;
    const updatedValuePart = (_a = updateEntry.displayValue) != null ? _a : updateEntry.key;
    if (operatorIndex === 0) {
      for (let i = this.operatorEndPositions.length - 1; i > 0; i--) {
        const operatorEndPosition = this.operatorEndPositions[i];
        if (operatorEndPosition == null) {
          continue;
        }
        expression = updateExpression(
          expression,
          this.operatorStartPositions[i],
          operatorEndPosition,
          updatedValuePart
        ).updatedValue;
      }
    }
    const startPosition = this.operatorStartPositions.length > operatorIndex ? this.operatorStartPositions[operatorIndex] : position;
    const endPosition = (_b = this.operatorEndPositions.length > operatorIndex ? this.operatorEndPositions[operatorIndex] : void 0) != null ? _b : findEndPosition(expression, position, true).endPosition;
    return updateExpression(
      expression,
      startPosition,
      endPosition,
      updatedValuePart,
      true
    );
  }
  getNumOperators() {
    return this.operators.length;
  }
  getLastOperatorEndPosition() {
    return this.operatorEndPositions[this.operatorEndPositions.length - 1];
  }
  parseOperator(endPosition) {
    const operator = this.operators.length > this.activeOperator ? this.operators[this.activeOperator] : "";
    const joinOperators = this.params.advancedFilterExpressionService.getExpressionJoinOperators();
    const parsedValue = findMatch(operator, joinOperators, (v) => v);
    if (parsedValue) {
      this.operatorEndPositions[this.activeOperator] = endPosition;
      const displayValue = joinOperators[parsedValue];
      if (this.activeOperator) {
        if (parsedValue !== this.parsedOperator) {
          if (!this.validationError) {
            this.validationError = {
              message: this.params.advancedFilterExpressionService.translate("advancedFilterValidationJoinOperatorMismatch"),
              startPosition: endPosition - operator.length + 1,
              endPosition
            };
          }
          return false;
        }
      } else {
        this.parsedOperator = parsedValue;
      }
      if (operator !== displayValue) {
        checkAndUpdateExpression(this.params, operator, displayValue, endPosition);
        this.operators[this.activeOperator] = displayValue;
      }
      return true;
    } else if (parsedValue === null) {
      return false;
    } else {
      if (!this.validationError) {
        this.validationError = {
          message: this.params.advancedFilterExpressionService.translate("advancedFilterValidationInvalidJoinOperator"),
          startPosition: endPosition - operator.length + 1,
          endPosition
        };
      }
      return true;
    }
  }
};
var JoinFilterExpressionParser = class _JoinFilterExpressionParser {
  constructor(params, startPosition) {
    this.params = params;
    this.startPosition = startPosition;
    this.expectingExpression = true;
    this.expectingOperator = false;
    this.expressionParsers = [];
    this.operatorParser = new OperatorParser2(this.params);
    this.missingEndBracket = false;
    this.extraEndBracket = false;
  }
  parseExpression() {
    let i = this.startPosition;
    const { expression } = this.params;
    while (i < expression.length) {
      const char = expression[i];
      if (char === "(" && !this.expectingOperator) {
        const nestedParser = new _JoinFilterExpressionParser(this.params, i + 1);
        i = nestedParser.parseExpression();
        this.expressionParsers.push(nestedParser);
        this.expectingExpression = false;
        this.expectingOperator = true;
      } else if (char === ")") {
        this.endPosition = i - 1;
        if (this.startPosition === 0) {
          this.extraEndBracket = true;
        }
        return i;
      } else if (char === " ") {
      } else if (this.expectingExpression) {
        const nestedParser = new ColFilterExpressionParser(this.params, i);
        i = nestedParser.parseExpression();
        this.expressionParsers.push(nestedParser);
        this.expectingExpression = false;
        this.expectingOperator = true;
      } else if (this.expectingOperator) {
        i = this.operatorParser.parseExpression(i);
        this.expectingOperator = false;
        this.expectingExpression = true;
      }
      i++;
    }
    if (this.startPosition > 0) {
      this.missingEndBracket = true;
    }
    return i;
  }
  isValid() {
    return !this.missingEndBracket && !this.extraEndBracket && this.expressionParsers.length === this.operatorParser.getNumOperators() + 1 && this.operatorParser.isValid() && this.expressionParsers.every((expressionParser) => expressionParser.isValid());
  }
  getValidationError() {
    const operatorError = this.operatorParser.getValidationError();
    for (let i = 0; i < this.expressionParsers.length; i++) {
      const expressionError = this.expressionParsers[i].getValidationError();
      if (expressionError) {
        return operatorError && operatorError.startPosition < expressionError.startPosition ? operatorError : expressionError;
      }
    }
    ;
    if (operatorError) {
      return operatorError;
    }
    if (this.extraEndBracket) {
      return {
        message: this.params.advancedFilterExpressionService.translate("advancedFilterValidationExtraEndBracket"),
        startPosition: this.endPosition + 1,
        endPosition: this.endPosition + 1
      };
    }
    let translateKey;
    if (this.expressionParsers.length === this.operatorParser.getNumOperators()) {
      translateKey = "advancedFilterValidationMissingCondition";
    } else if (this.missingEndBracket) {
      translateKey = "advancedFilterValidationMissingEndBracket";
    }
    if (translateKey) {
      return {
        message: this.params.advancedFilterExpressionService.translate(translateKey),
        startPosition: this.params.expression.length,
        endPosition: this.params.expression.length
      };
    }
    return null;
  }
  getFunctionString(params) {
    const hasMultipleExpressions = this.expressionParsers.length > 1;
    const expression = this.expressionParsers.map(
      (expressionParser) => expressionParser.getFunctionString(params)
    ).join(
      ` ${this.operatorParser.getFunction()} `
    );
    return hasMultipleExpressions ? `(${expression})` : expression;
  }
  getFunctionParsed(params) {
    const operator = this.operatorParser.getFunction();
    const funcs = this.expressionParsers.map((expressionParser) => expressionParser.getFunctionParsed(params));
    const arrayFunc = operator === "&&" ? "every" : "some";
    return (expressionProxy, node, p) => funcs[arrayFunc]((func) => func(expressionProxy, node, p));
  }
  getAutocompleteListParams(position) {
    if (this.endPosition != null && position > this.endPosition + 1) {
      return void 0;
    }
    if (!this.expressionParsers.length) {
      return this.getColumnAutocompleteListParams();
    }
    const expressionParserIndex = this.getExpressionParserIndex(position);
    if (expressionParserIndex == null) {
      if (this.params.expression[position] === "(") {
        return { enabled: false };
      }
      return this.getColumnAutocompleteListParams();
    }
    const expressionParser = this.expressionParsers[expressionParserIndex];
    const autocompleteType = expressionParser.getAutocompleteListParams(position);
    if (!autocompleteType) {
      if (expressionParserIndex < this.expressionParsers.length - 1) {
        return this.operatorParser.getAutocompleteListParams(position, expressionParserIndex);
      }
      if (this.expressionParsers.length === this.operatorParser.getNumOperators()) {
        const operatorEndPosition = this.operatorParser.getLastOperatorEndPosition();
        return operatorEndPosition == null || position <= operatorEndPosition + 1 ? this.operatorParser.getAutocompleteListParams(position, this.operatorParser.getNumOperators() - 1) : this.getColumnAutocompleteListParams();
      }
      if (this.params.expression[position - 1] === ")") {
        return { enabled: false };
      }
      return this.operatorParser.getAutocompleteListParams(position);
    }
    return autocompleteType;
  }
  updateExpression(position, updateEntry, type) {
    var _a;
    const expression = this.params.expression;
    const expressionParserIndex = this.getExpressionParserIndex(position);
    if (expressionParserIndex == null) {
      const updatedValuePart = type === "column" ? this.params.advancedFilterExpressionService.getColumnValue(updateEntry) : (_a = updateEntry.displayValue) != null ? _a : updateEntry.key;
      return updateExpression(expression, this.startPosition, this.startPosition, updatedValuePart, true);
    }
    const expressionParser = this.expressionParsers[expressionParserIndex];
    const updatedExpression = expressionParser.updateExpression(position, updateEntry, type);
    if (updatedExpression == null) {
      if (type === "column") {
        return updateExpression(
          expression,
          position,
          expression.length - 1,
          this.params.advancedFilterExpressionService.getColumnValue(updateEntry),
          true
        );
      } else if (this.endPosition != null && position > this.endPosition + 1) {
        return null;
      } else {
        return this.operatorParser.updateExpression(position, updateEntry, expressionParserIndex);
      }
    }
    return updatedExpression;
  }
  getModel() {
    if (this.expressionParsers.length > 1) {
      return {
        filterType: "join",
        type: this.operatorParser.getModel(),
        conditions: this.expressionParsers.map((parser) => parser.getModel())
      };
    } else {
      return this.expressionParsers[0].getModel();
    }
  }
  getColumnAutocompleteListParams() {
    return this.params.advancedFilterExpressionService.generateAutocompleteListParams(
      this.params.advancedFilterExpressionService.getColumnAutocompleteEntries(),
      "column",
      ""
    );
  }
  getExpressionParserIndex(position) {
    let expressionParserIndex;
    for (let i = 0; i < this.expressionParsers.length; i++) {
      const expressionParserToCheck = this.expressionParsers[i];
      if (expressionParserToCheck.startPosition > position) {
        break;
      }
      expressionParserIndex = i;
    }
    return expressionParserIndex;
  }
};
var FilterExpressionParser = class {
  constructor(params) {
    this.params = params;
    this.valid = false;
  }
  parseExpression() {
    this.joinExpressionParser = new JoinFilterExpressionParser(this.params, 0);
    const i = this.joinExpressionParser.parseExpression();
    this.valid = i >= this.params.expression.length - 1 && this.joinExpressionParser.isValid();
    return this.params.expression;
  }
  isValid() {
    return this.valid;
  }
  getValidationMessage() {
    const error = this.joinExpressionParser.getValidationError();
    if (!error) {
      return null;
    }
    const { message, startPosition, endPosition } = error;
    return startPosition < this.params.expression.length ? this.params.advancedFilterExpressionService.translate("advancedFilterValidationMessage", [
      message,
      this.params.expression.slice(startPosition, endPosition + 1).trim()
    ]) : this.params.advancedFilterExpressionService.translate("advancedFilterValidationMessageAtEnd", [message]);
  }
  getFunctionString() {
    const params = this.createFunctionParams();
    return {
      functionString: `return ${this.joinExpressionParser.getFunctionString(params)};`,
      params
    };
  }
  getFunctionParsed() {
    const params = this.createFunctionParams();
    return {
      expressionFunction: this.joinExpressionParser.getFunctionParsed(params),
      params
    };
  }
  getAutocompleteListParams(position) {
    var _a;
    return (_a = this.joinExpressionParser.getAutocompleteListParams(position)) != null ? _a : { enabled: false };
  }
  updateExpression(position, updateEntry, type) {
    return this.joinExpressionParser.updateExpression(position, updateEntry, type);
  }
  getModel() {
    return this.isValid() ? this.joinExpressionParser.getModel() : null;
  }
  createFunctionParams() {
    return {
      operands: [],
      operators: [],
      evaluatorParams: []
    };
  }
};
var AdvancedFilterService = class extends BeanStub5 {
  constructor() {
    super(...arguments);
    this.appliedExpression = null;
    this.expression = null;
    this.isValid = true;
  }
  postConstruct() {
    this.setEnabled(this.gos.get("enableAdvancedFilter"), true);
    this.ctrl = this.createManagedBean(new AdvancedFilterCtrl(this.enabled));
    this.expressionProxy = {
      getValue: (colId, node) => {
        const column = this.columnModel.getPrimaryColumn(colId);
        return column ? this.valueService.getValue(column, node, true) : void 0;
      }
    };
    this.addManagedPropertyListener("enableAdvancedFilter", (event) => this.setEnabled(!!event.currentValue));
    this.addManagedListener(
      this.eventService,
      Events6.EVENT_NEW_COLUMNS_LOADED,
      (event) => this.onNewColumnsLoaded(event)
    );
    this.addManagedPropertyListener("includeHiddenColumnsInAdvancedFilter", () => this.updateValidity());
  }
  isEnabled() {
    return this.enabled;
  }
  isFilterPresent() {
    return !!this.expressionFunction;
  }
  doesFilterPass(node) {
    return this.expressionFunction(this.expressionProxy, node, this.expressionParams);
  }
  getModel() {
    var _a;
    const expressionParser = this.createExpressionParser(this.appliedExpression);
    expressionParser == null ? void 0 : expressionParser.parseExpression();
    return (_a = expressionParser == null ? void 0 : expressionParser.getModel()) != null ? _a : null;
  }
  setModel(model) {
    const parseModel = (model2, isFirstParent) => {
      if (model2.filterType === "join") {
        const operator = this.advancedFilterExpressionService.parseJoinOperator(model2);
        const expression2 = model2.conditions.map((condition) => parseModel(condition)).filter((condition) => _14.exists(condition)).join(` ${operator} `);
        return isFirstParent || model2.conditions.length <= 1 ? expression2 : `(${expression2})`;
      } else {
        return this.advancedFilterExpressionService.parseColumnFilterModel(model2);
      }
    };
    const expression = model ? parseModel(model, true) : null;
    this.setExpressionDisplayValue(expression);
    this.applyExpression();
    this.ctrl.refreshComp();
    this.ctrl.refreshBuilderComp();
  }
  getExpressionDisplayValue() {
    return this.expression;
  }
  setExpressionDisplayValue(expression) {
    this.expression = expression;
  }
  isCurrentExpressionApplied() {
    return this.appliedExpression === this.expression;
  }
  createExpressionParser(expression) {
    if (!expression) {
      return null;
    }
    return new FilterExpressionParser({
      expression,
      columnModel: this.columnModel,
      dataTypeService: this.dataTypeService,
      valueService: this.valueService,
      advancedFilterExpressionService: this.advancedFilterExpressionService
    });
  }
  getDefaultExpression(updateEntry) {
    const updatedValue = this.advancedFilterExpressionService.getColumnValue(updateEntry) + " ";
    return {
      updatedValue,
      updatedPosition: updatedValue.length
    };
  }
  isHeaderActive() {
    return !this.gos.get("advancedFilterParent");
  }
  getCtrl() {
    return this.ctrl;
  }
  setEnabled(enabled, silent) {
    const previousValue = this.enabled;
    const rowModelType = this.rowModel.getType();
    const isValidRowModel = rowModelType === "clientSide" || rowModelType === "serverSide";
    if (enabled && !rowModelType) {
      _14.warnOnce("Advanced Filter is only supported with the Client-Side Row Model or Server-Side Row Model.");
    }
    this.enabled = enabled && isValidRowModel;
    if (!silent && this.enabled !== previousValue) {
      const event = {
        type: Events6.EVENT_ADVANCED_FILTER_ENABLED_CHANGED,
        enabled: this.enabled
      };
      this.eventService.dispatchEvent(event);
    }
  }
  applyExpression() {
    const expressionParser = this.createExpressionParser(this.expression);
    expressionParser == null ? void 0 : expressionParser.parseExpression();
    this.applyExpressionFromParser(expressionParser);
  }
  applyExpressionFromParser(expressionParser) {
    this.isValid = !expressionParser || expressionParser.isValid();
    if (!expressionParser || !this.isValid) {
      this.expressionFunction = null;
      this.expressionParams = null;
      this.appliedExpression = null;
      return;
    }
    const { expressionFunction, params } = this.getFunction(expressionParser);
    this.expressionFunction = expressionFunction;
    this.expressionParams = params;
    this.appliedExpression = this.expression;
  }
  getFunction(expressionParser) {
    if (this.gos.get("suppressAdvancedFilterEval")) {
      return expressionParser.getFunctionParsed();
    } else {
      const { functionString, params } = expressionParser.getFunctionString();
      return {
        expressionFunction: new Function("expressionProxy", "node", "params", functionString),
        params
      };
    }
  }
  updateValidity() {
    this.advancedFilterExpressionService.resetColumnCaches();
    const expressionParser = this.createExpressionParser(this.expression);
    expressionParser == null ? void 0 : expressionParser.parseExpression();
    const isValid = !expressionParser || expressionParser.isValid();
    const updatedValidity = isValid !== this.isValid;
    this.applyExpressionFromParser(expressionParser);
    this.ctrl.refreshComp();
    this.ctrl.refreshBuilderComp();
    return updatedValidity;
  }
  onNewColumnsLoaded(event) {
    if (event.source !== "gridInitializing" || !this.dataTypeService.isPendingInference()) {
      return;
    }
    this.ctrl.setInputDisabled(true);
    const destroyFunc = this.addManagedListener(this.eventService, Events6.EVENT_DATA_TYPES_INFERRED, () => {
      destroyFunc == null ? void 0 : destroyFunc();
      this.ctrl.setInputDisabled(false);
    });
  }
};
__decorateClass2([
  Autowired11("valueService")
], AdvancedFilterService.prototype, "valueService", 2);
__decorateClass2([
  Autowired11("columnModel")
], AdvancedFilterService.prototype, "columnModel", 2);
__decorateClass2([
  Autowired11("dataTypeService")
], AdvancedFilterService.prototype, "dataTypeService", 2);
__decorateClass2([
  Autowired11("rowModel")
], AdvancedFilterService.prototype, "rowModel", 2);
__decorateClass2([
  Autowired11("advancedFilterExpressionService")
], AdvancedFilterService.prototype, "advancedFilterExpressionService", 2);
__decorateClass2([
  PostConstruct11
], AdvancedFilterService.prototype, "postConstruct", 1);
AdvancedFilterService = __decorateClass2([
  Bean22("advancedFilterService")
], AdvancedFilterService);
var VERSION2 = "31.3.2";
var AdvancedFilterModule = {
  version: VERSION2,
  moduleName: ModuleNames2.AdvancedFilterModule,
  beans: [AdvancedFilterService, AdvancedFilterExpressionService],
  agStackComponents: [
    { componentName: "agAdvancedFilter", componentClass: AdvancedFilterComp }
  ],
  dependantModules: [
    EnterpriseCoreModule
  ]
};

// enterprise-modules/charts/dist/package/main.esm.mjs
var main_esm_exports = {};
__export(main_esm_exports, {
  GridChartsModule: () => GridChartsModule,
  __FORCE_MODULE_DETECTION_AG_GRID_CORE_EXT: () => __FORCE_MODULE_DETECTION_AG_GRID_CORE_EXT,
  agCharts: () => agCharts
});
import { ModuleNames as ModuleNames22 } from "ag-grid-community";

// enterprise-modules/range-selection/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames3 } from "ag-grid-community";
import {
  Autowired as Autowired12,
  Bean as Bean3,
  Events as Events7,
  PostConstruct as PostConstruct12,
  BeanStub as BeanStub6,
  AutoScrollService,
  CellCtrl,
  _ as _6
} from "ag-grid-community";
import {
  Autowired as Autowired33,
  Events as Events22,
  SelectionHandleType as SelectionHandleType2,
  _ as _32
} from "ag-grid-community";
import {
  Autowired as Autowired23,
  Component as Component9,
  PostConstruct as PostConstruct23,
  _ as _23,
  SelectionHandleType
} from "ag-grid-community";
import {
  CellRangeType,
  SelectionHandleType as SelectionHandleType3,
  _ as _42
} from "ag-grid-community";
import { Bean as Bean23, BeanStub as BeanStub23, SelectionHandleType as SelectionHandleType4 } from "ag-grid-community";
var __defProp3 = Object.defineProperty;
var __defProps2 = Object.defineProperties;
var __getOwnPropDesc3 = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs2 = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols2 = Object.getOwnPropertySymbols;
var __hasOwnProp3 = Object.prototype.hasOwnProperty;
var __propIsEnum2 = Object.prototype.propertyIsEnumerable;
var __defNormalProp2 = (obj, key, value) => key in obj ? __defProp3(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues2 = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp3.call(b, prop))
      __defNormalProp2(a, prop, b[prop]);
  if (__getOwnPropSymbols2)
    for (var prop of __getOwnPropSymbols2(b)) {
      if (__propIsEnum2.call(b, prop))
        __defNormalProp2(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps2 = (a, b) => __defProps2(a, __getOwnPropDescs2(b));
var __decorateClass3 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc3(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp3(target, key, result);
  return result;
};
var RangeService = class extends BeanStub6 {
  constructor() {
    super(...arguments);
    this.cellRanges = [];
    this.bodyScrollListener = this.onBodyScroll.bind(this);
    this.dragging = false;
    this.intersectionRange = false;
  }
  init() {
    this.addManagedListener(this.eventService, Events7.EVENT_NEW_COLUMNS_LOADED, () => this.onColumnsChanged());
    this.addManagedListener(this.eventService, Events7.EVENT_COLUMN_VISIBLE, this.onColumnsChanged.bind(this));
    this.addManagedListener(this.eventService, Events7.EVENT_COLUMN_VALUE_CHANGED, this.onColumnsChanged.bind(this));
    this.addManagedListener(this.eventService, Events7.EVENT_COLUMN_PIVOT_MODE_CHANGED, () => this.removeAllCellRanges());
    this.addManagedListener(this.eventService, Events7.EVENT_COLUMN_ROW_GROUP_CHANGED, () => this.removeAllCellRanges());
    this.addManagedListener(this.eventService, Events7.EVENT_COLUMN_PIVOT_CHANGED, () => this.removeAllCellRanges());
    this.addManagedListener(this.eventService, Events7.EVENT_COLUMN_GROUP_OPENED, this.refreshLastRangeStart.bind(this));
    this.addManagedListener(this.eventService, Events7.EVENT_COLUMN_MOVED, this.refreshLastRangeStart.bind(this));
    this.addManagedListener(this.eventService, Events7.EVENT_COLUMN_PINNED, this.refreshLastRangeStart.bind(this));
    this.ctrlsService.whenReady((p) => {
      const gridBodyCtrl = p.gridBodyCtrl;
      this.autoScrollService = new AutoScrollService({
        scrollContainer: gridBodyCtrl.getBodyViewportElement(),
        scrollAxis: "xy",
        getVerticalPosition: () => gridBodyCtrl.getScrollFeature().getVScrollPosition().top,
        setVerticalPosition: (position) => gridBodyCtrl.getScrollFeature().setVerticalScrollPosition(position),
        getHorizontalPosition: () => gridBodyCtrl.getScrollFeature().getHScrollPosition().left,
        setHorizontalPosition: (position) => gridBodyCtrl.getScrollFeature().setHorizontalScrollPosition(position),
        shouldSkipVerticalScroll: () => !this.gos.isDomLayout("normal"),
        shouldSkipHorizontalScroll: () => !gridBodyCtrl.getScrollFeature().isHorizontalScrollShowing()
      });
    });
  }
  // Called for both columns loaded & column visibility events
  onColumnsChanged() {
    this.refreshLastRangeStart();
    const allColumns = this.columnModel.getAllDisplayedColumns();
    this.cellRanges.forEach((cellRange) => {
      const beforeCols = cellRange.columns;
      cellRange.columns = cellRange.columns.filter(
        (col) => col.isVisible() && allColumns.indexOf(col) !== -1
      );
      const colsInRangeChanged = !_6.areEqual(beforeCols, cellRange.columns);
      if (colsInRangeChanged) {
        this.dispatchChangedEvent(false, true, cellRange.id);
      }
    });
    const countBefore = this.cellRanges.length;
    this.cellRanges = this.cellRanges.filter((range) => range.columns.length > 0);
    if (countBefore > this.cellRanges.length) {
      this.dispatchChangedEvent(false, true);
    }
  }
  refreshLastRangeStart() {
    const lastRange = _6.last(this.cellRanges);
    if (!lastRange) {
      return;
    }
    this.refreshRangeStart(lastRange);
  }
  isContiguousRange(cellRange) {
    const rangeColumns = cellRange.columns;
    if (!rangeColumns.length) {
      return false;
    }
    const allColumns = this.columnModel.getAllDisplayedColumns();
    const allPositions = rangeColumns.map((c) => allColumns.indexOf(c)).sort((a, b) => a - b);
    return _6.last(allPositions) - allPositions[0] + 1 === rangeColumns.length;
  }
  getRangeStartRow(cellRange) {
    if (cellRange.startRow && cellRange.endRow) {
      return this.rowPositionUtils.before(cellRange.startRow, cellRange.endRow) ? cellRange.startRow : cellRange.endRow;
    }
    const rowPinned = this.pinnedRowModel.getPinnedTopRowCount() > 0 ? "top" : null;
    return { rowIndex: 0, rowPinned };
  }
  getRangeEndRow(cellRange) {
    if (cellRange.startRow && cellRange.endRow) {
      return this.rowPositionUtils.before(cellRange.startRow, cellRange.endRow) ? cellRange.endRow : cellRange.startRow;
    }
    const pinnedBottomRowCount = this.pinnedRowModel.getPinnedBottomRowCount();
    const pinnedBottom = pinnedBottomRowCount > 0;
    if (pinnedBottom) {
      return {
        rowIndex: pinnedBottomRowCount - 1,
        rowPinned: "bottom"
      };
    }
    return {
      rowIndex: this.rowModel.getRowCount() - 1,
      rowPinned: null
    };
  }
  setRangeToCell(cell, appendRange = false) {
    if (!this.gos.get("enableRangeSelection")) {
      return;
    }
    const columns = this.calculateColumnsBetween(cell.column, cell.column);
    if (!columns) {
      return;
    }
    const suppressMultiRangeSelections = this.gos.get("suppressMultiRangeSelection");
    if (suppressMultiRangeSelections || !appendRange || _6.missing(this.cellRanges)) {
      this.removeAllCellRanges(true);
    }
    const rowForCell = {
      rowIndex: cell.rowIndex,
      rowPinned: cell.rowPinned
    };
    const cellRange = {
      startRow: rowForCell,
      endRow: rowForCell,
      columns,
      startColumn: cell.column
    };
    this.cellRanges.push(cellRange);
    this.setNewestRangeStartCell(cell);
    this.onDragStop();
    this.dispatchChangedEvent(true, true);
  }
  extendLatestRangeToCell(cellPosition) {
    if (this.isEmpty() || !this.newestRangeStartCell) {
      return;
    }
    const cellRange = _6.last(this.cellRanges);
    this.updateRangeEnd(cellRange, cellPosition);
  }
  updateRangeEnd(cellRange, cellPosition, silent = false) {
    const endColumn = cellPosition.column;
    const colsToAdd = this.calculateColumnsBetween(cellRange.startColumn, endColumn);
    if (!colsToAdd || this.isLastCellOfRange(cellRange, cellPosition)) {
      return;
    }
    cellRange.columns = colsToAdd;
    cellRange.endRow = { rowIndex: cellPosition.rowIndex, rowPinned: cellPosition.rowPinned };
    if (!silent) {
      this.dispatchChangedEvent(true, true, cellRange.id);
    }
  }
  refreshRangeStart(cellRange) {
    const { startColumn, columns } = cellRange;
    const moveColInCellRange = (colToMove, moveToFront) => {
      const otherCols = cellRange.columns.filter((col) => col !== colToMove);
      if (colToMove) {
        cellRange.startColumn = colToMove;
        cellRange.columns = moveToFront ? [colToMove, ...otherCols] : [...otherCols, colToMove];
      } else {
        cellRange.columns = otherCols;
      }
    };
    const { left, right } = this.getRangeEdgeColumns(cellRange);
    const shouldMoveLeftCol = startColumn === columns[0] && startColumn !== left;
    if (shouldMoveLeftCol) {
      moveColInCellRange(left, true);
      return;
    }
    const shouldMoveRightCol = startColumn === _6.last(columns) && startColumn === right;
    if (shouldMoveRightCol) {
      moveColInCellRange(right, false);
      return;
    }
  }
  getRangeEdgeColumns(cellRange) {
    const allColumns = this.columnModel.getAllDisplayedColumns();
    const allIndices = cellRange.columns.map((c) => allColumns.indexOf(c)).filter((i) => i > -1).sort((a, b) => a - b);
    return {
      left: allColumns[allIndices[0]],
      right: allColumns[_6.last(allIndices)]
    };
  }
  // returns true if successful, false if not successful
  extendLatestRangeInDirection(event) {
    if (this.isEmpty() || !this.newestRangeStartCell) {
      return;
    }
    const key = event.key;
    const ctrlKey = event.ctrlKey || event.metaKey;
    const lastRange = _6.last(this.cellRanges);
    const startCell = this.newestRangeStartCell;
    const firstCol = lastRange.columns[0];
    const lastCol = _6.last(lastRange.columns);
    const endCellIndex = lastRange.endRow.rowIndex;
    const endCellFloating = lastRange.endRow.rowPinned;
    const endCellColumn = startCell.column === firstCol ? lastCol : firstCol;
    const endCell = { column: endCellColumn, rowIndex: endCellIndex, rowPinned: endCellFloating };
    const newEndCell = this.cellNavigationService.getNextCellToFocus(key, endCell, ctrlKey);
    if (!newEndCell) {
      return;
    }
    this.setCellRange({
      rowStartIndex: startCell.rowIndex,
      rowStartPinned: startCell.rowPinned,
      rowEndIndex: newEndCell.rowIndex,
      rowEndPinned: newEndCell.rowPinned,
      columnStart: startCell.column,
      columnEnd: newEndCell.column
    });
    return newEndCell;
  }
  setCellRange(params) {
    if (!this.gos.get("enableRangeSelection")) {
      return;
    }
    this.removeAllCellRanges(true);
    this.addCellRange(params);
  }
  setCellRanges(cellRanges) {
    if (_6.shallowCompare(this.cellRanges, cellRanges)) {
      return;
    }
    this.removeAllCellRanges(true);
    cellRanges.forEach((newRange) => {
      if (newRange.columns && newRange.startRow) {
        this.setNewestRangeStartCell({
          rowIndex: newRange.startRow.rowIndex,
          rowPinned: newRange.startRow.rowPinned,
          column: newRange.columns[0]
        });
      }
      this.cellRanges.push(newRange);
    });
    this.dispatchChangedEvent(false, true);
  }
  setNewestRangeStartCell(position) {
    this.newestRangeStartCell = position;
  }
  clearCellRangeCellValues(params) {
    let { cellRanges } = params;
    const {
      cellEventSource = "rangeService",
      dispatchWrapperEvents,
      wrapperEventSource = "deleteKey"
    } = params;
    if (dispatchWrapperEvents) {
      const startEvent = {
        type: Events7.EVENT_RANGE_DELETE_START,
        source: wrapperEventSource
      };
      this.eventService.dispatchEvent(startEvent);
    }
    if (!cellRanges) {
      cellRanges = this.cellRanges;
    }
    cellRanges.forEach((cellRange) => {
      this.forEachRowInRange(cellRange, (rowPosition) => {
        var _a;
        const rowNode = this.rowPositionUtils.getRowNode(rowPosition);
        if (!rowNode) {
          return;
        }
        for (let i = 0; i < cellRange.columns.length; i++) {
          const column = this.columnModel.getGridColumn(cellRange.columns[i]);
          if (!column || !column.isCellEditable(rowNode)) {
            continue;
          }
          const emptyValue = (_a = this.valueService.parseValue(column, rowNode, "", rowNode.getValueFromValueService(column))) != null ? _a : null;
          rowNode.setDataValue(column, emptyValue, cellEventSource);
        }
      });
    });
    if (dispatchWrapperEvents) {
      const endEvent = {
        type: Events7.EVENT_RANGE_DELETE_END,
        source: wrapperEventSource
      };
      this.eventService.dispatchEvent(endEvent);
    }
  }
  createCellRangeFromCellRangeParams(params) {
    return this.createPartialCellRangeFromRangeParams(params, false);
  }
  // Range service can't normally support a range without columns, but charts can
  createPartialCellRangeFromRangeParams(params, allowEmptyColumns) {
    let columns;
    let startsOnTheRight = false;
    if (params.columns) {
      columns = params.columns.map((c) => this.columnModel.getColumnWithValidation(c)).filter((c) => c);
    } else {
      const columnStart = this.columnModel.getColumnWithValidation(params.columnStart);
      const columnEnd = this.columnModel.getColumnWithValidation(params.columnEnd);
      if (!columnStart || !columnEnd) {
        return;
      }
      columns = this.calculateColumnsBetween(columnStart, columnEnd);
      if (columns && columns.length) {
        startsOnTheRight = columns[0] !== columnStart;
      }
    }
    if (!columns || !allowEmptyColumns && columns.length === 0) {
      return;
    }
    const startRow = params.rowStartIndex != null ? {
      rowIndex: params.rowStartIndex,
      rowPinned: params.rowStartPinned || null
    } : void 0;
    const endRow = params.rowEndIndex != null ? {
      rowIndex: params.rowEndIndex,
      rowPinned: params.rowEndPinned || null
    } : void 0;
    return {
      startRow,
      endRow,
      columns,
      startColumn: startsOnTheRight ? _6.last(columns) : columns[0]
    };
  }
  addCellRange(params) {
    if (!this.gos.get("enableRangeSelection")) {
      return;
    }
    const newRange = this.createCellRangeFromCellRangeParams(params);
    if (newRange) {
      if (newRange.startRow) {
        this.setNewestRangeStartCell({
          rowIndex: newRange.startRow.rowIndex,
          rowPinned: newRange.startRow.rowPinned,
          column: newRange.startColumn
        });
      }
      this.cellRanges.push(newRange);
      this.dispatchChangedEvent(false, true, newRange.id);
    }
  }
  getCellRanges() {
    return this.cellRanges;
  }
  isEmpty() {
    return this.cellRanges.length === 0;
  }
  isMoreThanOneCell() {
    const len = this.cellRanges.length;
    if (len === 0) {
      return false;
    }
    if (len > 1) {
      return true;
    }
    const range = this.cellRanges[0];
    const startRow = this.getRangeStartRow(range);
    const endRow = this.getRangeEndRow(range);
    return startRow.rowPinned !== endRow.rowPinned || startRow.rowIndex !== endRow.rowIndex || range.columns.length !== 1;
  }
  areAllRangesAbleToMerge() {
    const rowToColumnMap = /* @__PURE__ */ new Map();
    const len = this.cellRanges.length;
    if (len <= 1)
      return true;
    this.cellRanges.forEach((range) => {
      this.forEachRowInRange(range, (row) => {
        const rowName = `${row.rowPinned || "normal"}_${row.rowIndex}`;
        const columns = rowToColumnMap.get(rowName);
        const currentRangeColIds = range.columns.map((col) => col.getId());
        if (columns) {
          const filteredColumns = currentRangeColIds.filter((col) => columns.indexOf(col) === -1);
          columns.push(...filteredColumns);
        } else {
          rowToColumnMap.set(rowName, currentRangeColIds);
        }
      });
    });
    let columnsString;
    for (const val of rowToColumnMap.values()) {
      const currentValString = val.sort().join();
      if (columnsString === void 0) {
        columnsString = currentValString;
        continue;
      }
      if (columnsString !== currentValString) {
        return false;
      }
    }
    return true;
  }
  forEachRowInRange(cellRange, callback) {
    const topRow = this.getRangeStartRow(cellRange);
    const bottomRow = this.getRangeEndRow(cellRange);
    let currentRow = topRow;
    while (currentRow) {
      callback(currentRow);
      if (this.rowPositionUtils.sameRow(currentRow, bottomRow)) {
        break;
      }
      currentRow = this.cellNavigationService.getRowBelow(currentRow);
    }
  }
  removeAllCellRanges(silent) {
    if (this.isEmpty()) {
      return;
    }
    this.onDragStop();
    this.cellRanges.length = 0;
    if (!silent) {
      this.dispatchChangedEvent(false, true);
    }
  }
  // as the user is dragging outside of the panel, the div starts to scroll, which in turn
  // means we are selection more (or less) cells, but the mouse isn't moving, so we recalculate
  // the selection my mimicking a new mouse event
  onBodyScroll() {
    if (this.dragging && this.lastMouseEvent) {
      this.onDragging(this.lastMouseEvent);
    }
  }
  isCellInAnyRange(cell) {
    return this.getCellRangeCount(cell) > 0;
  }
  isCellInSpecificRange(cell, range) {
    const columnInRange = range.columns !== null && _6.includes(range.columns, cell.column);
    const rowInRange = this.isRowInRange(cell.rowIndex, cell.rowPinned, range);
    return columnInRange && rowInRange;
  }
  isLastCellOfRange(cellRange, cell) {
    const { startRow, endRow } = cellRange;
    const lastRow = this.rowPositionUtils.before(startRow, endRow) ? endRow : startRow;
    const isLastRow = cell.rowIndex === lastRow.rowIndex && cell.rowPinned === lastRow.rowPinned;
    const rangeFirstIndexColumn = cellRange.columns[0];
    const rangeLastIndexColumn = _6.last(cellRange.columns);
    const lastRangeColumn = cellRange.startColumn === rangeFirstIndexColumn ? rangeLastIndexColumn : rangeFirstIndexColumn;
    const isLastColumn = cell.column === lastRangeColumn;
    return isLastColumn && isLastRow;
  }
  isBottomRightCell(cellRange, cell) {
    const allColumns = this.columnModel.getAllDisplayedColumns();
    const allPositions = cellRange.columns.map((c) => allColumns.indexOf(c)).sort((a, b) => a - b);
    const { startRow, endRow } = cellRange;
    const lastRow = this.rowPositionUtils.before(startRow, endRow) ? endRow : startRow;
    const isRightColumn = allColumns.indexOf(cell.column) === _6.last(allPositions);
    const isLastRow = cell.rowIndex === lastRow.rowIndex && _6.makeNull(cell.rowPinned) === _6.makeNull(lastRow.rowPinned);
    return isRightColumn && isLastRow;
  }
  // returns the number of ranges this cell is in
  getCellRangeCount(cell) {
    if (this.isEmpty()) {
      return 0;
    }
    return this.cellRanges.filter((cellRange) => this.isCellInSpecificRange(cell, cellRange)).length;
  }
  isRowInRange(rowIndex, floating, cellRange) {
    const firstRow = this.getRangeStartRow(cellRange);
    const lastRow = this.getRangeEndRow(cellRange);
    const thisRow = { rowIndex, rowPinned: floating || null };
    const equalsFirstRow = thisRow.rowIndex === firstRow.rowIndex && thisRow.rowPinned == firstRow.rowPinned;
    const equalsLastRow = thisRow.rowIndex === lastRow.rowIndex && thisRow.rowPinned == lastRow.rowPinned;
    if (equalsFirstRow || equalsLastRow) {
      return true;
    }
    const afterFirstRow = !this.rowPositionUtils.before(thisRow, firstRow);
    const beforeLastRow = this.rowPositionUtils.before(thisRow, lastRow);
    return afterFirstRow && beforeLastRow;
  }
  getDraggingRange() {
    return this.draggingRange;
  }
  onDragStart(mouseEvent) {
    if (!this.gos.get("enableRangeSelection")) {
      return;
    }
    const { ctrlKey, metaKey, shiftKey } = mouseEvent;
    const isMultiKey = ctrlKey || metaKey;
    const allowMulti = !this.gos.get("suppressMultiRangeSelection");
    const isMultiSelect = allowMulti ? isMultiKey : false;
    const extendRange = shiftKey && _6.existsAndNotEmpty(this.cellRanges);
    if (!isMultiSelect && (!extendRange || _6.exists(_6.last(this.cellRanges).type))) {
      this.removeAllCellRanges(true);
    }
    const startTarget = this.dragService.getStartTarget();
    if (startTarget) {
      this.updateValuesOnMove(startTarget);
    }
    if (!this.lastCellHovered) {
      return;
    }
    this.dragging = true;
    this.lastMouseEvent = mouseEvent;
    this.intersectionRange = isMultiSelect && this.getCellRangeCount(this.lastCellHovered) > 1;
    if (!extendRange) {
      this.setNewestRangeStartCell(this.lastCellHovered);
    }
    if (this.cellRanges.length > 0) {
      this.draggingRange = _6.last(this.cellRanges);
    } else {
      const mouseRowPosition = {
        rowIndex: this.lastCellHovered.rowIndex,
        rowPinned: this.lastCellHovered.rowPinned
      };
      this.draggingRange = {
        startRow: mouseRowPosition,
        endRow: mouseRowPosition,
        columns: [this.lastCellHovered.column],
        startColumn: this.newestRangeStartCell.column
      };
      this.cellRanges.push(this.draggingRange);
    }
    this.ctrlsService.getGridBodyCtrl().addScrollEventListener(this.bodyScrollListener);
    this.dispatchChangedEvent(true, false, this.draggingRange.id);
  }
  intersectLastRange(fromMouseClick) {
    if (fromMouseClick && this.dragging) {
      return;
    }
    if (this.gos.get("suppressMultiRangeSelection")) {
      return;
    }
    if (this.isEmpty()) {
      return;
    }
    const rowPosUtils = this.rowPositionUtils;
    const lastRange = _6.last(this.cellRanges);
    const intersectionStartRow = this.getRangeStartRow(lastRange);
    const intersectionEndRow = this.getRangeEndRow(lastRange);
    const newRanges = [];
    this.cellRanges.slice(0, -1).forEach((range) => {
      const startRow = this.getRangeStartRow(range);
      const endRow = this.getRangeEndRow(range);
      const cols = range.columns;
      const intersectCols = cols.filter((col) => lastRange.columns.indexOf(col) === -1);
      if (intersectCols.length === cols.length) {
        newRanges.push(range);
        return;
      }
      if (rowPosUtils.before(intersectionEndRow, startRow) || rowPosUtils.before(endRow, intersectionStartRow)) {
        newRanges.push(range);
        return;
      }
      const rangeCountBefore = newRanges.length;
      if (rowPosUtils.before(startRow, intersectionStartRow)) {
        const top = {
          columns: [...cols],
          startColumn: lastRange.startColumn,
          startRow: __spreadValues2({}, startRow),
          endRow: this.cellNavigationService.getRowAbove(intersectionStartRow)
        };
        newRanges.push(top);
      }
      if (intersectCols.length > 0) {
        const middle = {
          columns: intersectCols,
          startColumn: _6.includes(intersectCols, lastRange.startColumn) ? lastRange.startColumn : intersectCols[0],
          startRow: this.rowMax([__spreadValues2({}, intersectionStartRow), __spreadValues2({}, startRow)]),
          endRow: this.rowMin([__spreadValues2({}, intersectionEndRow), __spreadValues2({}, endRow)])
        };
        newRanges.push(middle);
      }
      if (rowPosUtils.before(intersectionEndRow, endRow)) {
        newRanges.push({
          columns: [...cols],
          startColumn: lastRange.startColumn,
          startRow: this.cellNavigationService.getRowBelow(intersectionEndRow),
          endRow: __spreadValues2({}, endRow)
        });
      }
      if (newRanges.length - rangeCountBefore === 1) {
        newRanges[newRanges.length - 1].id = range.id;
      }
    });
    this.cellRanges = newRanges;
    if (fromMouseClick) {
      this.dispatchChangedEvent(false, true);
    }
  }
  rowMax(rows) {
    let max;
    rows.forEach((row) => {
      if (max === void 0 || this.rowPositionUtils.before(max, row)) {
        max = row;
      }
    });
    return max;
  }
  rowMin(rows) {
    let min;
    rows.forEach((row) => {
      if (min === void 0 || this.rowPositionUtils.before(row, min)) {
        min = row;
      }
    });
    return min;
  }
  updateValuesOnMove(eventTarget) {
    const cellCtrl = _6.getCtrlForEventTarget(this.gos, eventTarget, CellCtrl.DOM_DATA_KEY_CELL_CTRL);
    const cell = cellCtrl == null ? void 0 : cellCtrl.getCellPosition();
    this.cellHasChanged = false;
    if (!cell || this.lastCellHovered && this.cellPositionUtils.equals(cell, this.lastCellHovered)) {
      return;
    }
    if (this.lastCellHovered) {
      this.cellHasChanged = true;
    }
    this.lastCellHovered = cell;
  }
  onDragging(mouseEvent) {
    if (!this.dragging || !mouseEvent) {
      return;
    }
    this.updateValuesOnMove(mouseEvent.target);
    this.lastMouseEvent = mouseEvent;
    const cellPosition = this.lastCellHovered;
    const isMouseAndStartInPinned = (position) => cellPosition && cellPosition.rowPinned === position && this.newestRangeStartCell.rowPinned === position;
    const skipVerticalScroll = isMouseAndStartInPinned("top") || isMouseAndStartInPinned("bottom");
    this.autoScrollService.check(mouseEvent, skipVerticalScroll);
    if (!this.cellHasChanged) {
      return;
    }
    const columns = this.calculateColumnsBetween(this.newestRangeStartCell.column, cellPosition.column);
    if (!columns) {
      return;
    }
    this.draggingRange.endRow = {
      rowIndex: cellPosition.rowIndex,
      rowPinned: cellPosition.rowPinned
    };
    this.draggingRange.columns = columns;
    this.dispatchChangedEvent(false, false, this.draggingRange.id);
  }
  onDragStop() {
    if (!this.dragging) {
      return;
    }
    const { id } = this.draggingRange;
    this.autoScrollService.ensureCleared();
    this.ctrlsService.getGridBodyCtrl().removeScrollEventListener(this.bodyScrollListener);
    this.lastMouseEvent = null;
    this.dragging = false;
    this.draggingRange = void 0;
    this.lastCellHovered = void 0;
    if (this.intersectionRange) {
      this.intersectionRange = false;
      this.intersectLastRange();
    }
    this.dispatchChangedEvent(false, true, id);
  }
  dispatchChangedEvent(started, finished, id) {
    const event = {
      type: Events7.EVENT_RANGE_SELECTION_CHANGED,
      started,
      finished,
      id
    };
    this.eventService.dispatchEvent(event);
  }
  calculateColumnsBetween(columnFrom, columnTo) {
    const allColumns = this.columnModel.getAllDisplayedColumns();
    const isSameColumn = columnFrom === columnTo;
    const fromIndex = allColumns.indexOf(columnFrom);
    if (fromIndex < 0) {
      console.warn(`AG Grid: column ${columnFrom.getId()} is not visible`);
      return;
    }
    const toIndex = isSameColumn ? fromIndex : allColumns.indexOf(columnTo);
    if (toIndex < 0) {
      console.warn(`AG Grid: column ${columnTo.getId()} is not visible`);
      return;
    }
    if (isSameColumn) {
      return [columnFrom];
    }
    const firstIndex = Math.min(fromIndex, toIndex);
    const lastIndex = firstIndex === fromIndex ? toIndex : fromIndex;
    const columns = [];
    for (let i = firstIndex; i <= lastIndex; i++) {
      columns.push(allColumns[i]);
    }
    return columns;
  }
};
__decorateClass3([
  Autowired12("rowModel")
], RangeService.prototype, "rowModel", 2);
__decorateClass3([
  Autowired12("dragService")
], RangeService.prototype, "dragService", 2);
__decorateClass3([
  Autowired12("columnModel")
], RangeService.prototype, "columnModel", 2);
__decorateClass3([
  Autowired12("cellNavigationService")
], RangeService.prototype, "cellNavigationService", 2);
__decorateClass3([
  Autowired12("pinnedRowModel")
], RangeService.prototype, "pinnedRowModel", 2);
__decorateClass3([
  Autowired12("rowPositionUtils")
], RangeService.prototype, "rowPositionUtils", 2);
__decorateClass3([
  Autowired12("cellPositionUtils")
], RangeService.prototype, "cellPositionUtils", 2);
__decorateClass3([
  Autowired12("ctrlsService")
], RangeService.prototype, "ctrlsService", 2);
__decorateClass3([
  Autowired12("valueService")
], RangeService.prototype, "valueService", 2);
__decorateClass3([
  PostConstruct12
], RangeService.prototype, "init", 1);
RangeService = __decorateClass3([
  Bean3("rangeService")
], RangeService);
var AbstractSelectionHandle = class extends Component9 {
  constructor() {
    super(...arguments);
    this.changedCalculatedValues = false;
    this.dragging = false;
    this.shouldDestroyOnEndDragging = false;
  }
  init() {
    this.dragService.addDragSource({
      dragStartPixels: 0,
      eElement: this.getGui(),
      onDragStart: this.onDragStart.bind(this),
      onDragging: (e) => {
        this.dragging = true;
        this.rangeService.autoScrollService.check(e);
        if (this.changedCalculatedValues) {
          this.onDrag(e);
          this.changedCalculatedValues = false;
        }
      },
      onDragStop: (e) => {
        this.dragging = false;
        this.onDragEnd(e);
        this.clearValues();
        this.rangeService.autoScrollService.ensureCleared();
        document.body.classList.remove(this.getDraggingCssClass());
        if (this.shouldDestroyOnEndDragging) {
          this.destroy();
        }
      }
    });
    this.addManagedListener(
      this.getGui(),
      "mousedown",
      this.preventRangeExtension.bind(this)
    );
  }
  isDragging() {
    return this.dragging;
  }
  getCellCtrl() {
    return this.cellCtrl;
  }
  setCellCtrl(cellComp) {
    this.cellCtrl = cellComp;
  }
  getCellRange() {
    return this.cellRange;
  }
  setCellRange(range) {
    this.cellRange = range;
  }
  getRangeStartRow() {
    return this.rangeStartRow;
  }
  setRangeStartRow(row) {
    this.rangeStartRow = row;
  }
  getRangeEndRow() {
    return this.rangeEndRow;
  }
  setRangeEndRow(row) {
    this.rangeEndRow = row;
  }
  getLastCellHovered() {
    return this.lastCellHovered;
  }
  preventRangeExtension(e) {
    e.stopPropagation();
  }
  onDragStart(e) {
    this.cellHoverListener = this.addManagedListener(
      this.ctrlsService.get("gridCtrl").getGui(),
      "mousemove",
      this.updateValuesOnMove.bind(this)
    );
    document.body.classList.add(this.getDraggingCssClass());
  }
  getDraggingCssClass() {
    return `ag-dragging-${this.type === SelectionHandleType.FILL ? "fill" : "range"}-handle`;
  }
  updateValuesOnMove(e) {
    const cell = this.mouseEventService.getCellPositionForEvent(e);
    if (!cell || this.lastCellHovered && this.cellPositionUtils.equals(cell, this.lastCellHovered)) {
      return;
    }
    this.lastCellHovered = cell;
    this.changedCalculatedValues = true;
  }
  getType() {
    return this.type;
  }
  refresh(cellCtrl) {
    const oldCellComp = this.getCellCtrl();
    const eGui = this.getGui();
    const cellRange = _23.last(this.rangeService.getCellRanges());
    const start = cellRange.startRow;
    const end = cellRange.endRow;
    if (start && end) {
      const isBefore = this.rowPositionUtils.before(end, start);
      if (isBefore) {
        this.setRangeStartRow(end);
        this.setRangeEndRow(start);
      } else {
        this.setRangeStartRow(start);
        this.setRangeEndRow(end);
      }
    }
    if (oldCellComp !== cellCtrl || !_23.isVisible(eGui)) {
      this.setCellCtrl(cellCtrl);
      const eParentOfValue = cellCtrl.getComp().getParentOfValue();
      if (eParentOfValue) {
        eParentOfValue.appendChild(eGui);
      }
    }
    this.setCellRange(cellRange);
  }
  clearValues() {
    this.lastCellHovered = void 0;
    this.removeListeners();
  }
  removeListeners() {
    if (this.cellHoverListener) {
      this.cellHoverListener();
      this.cellHoverListener = void 0;
    }
  }
  destroy() {
    if (!this.shouldDestroyOnEndDragging && this.isDragging()) {
      _23.setDisplayed(this.getGui(), false);
      this.shouldDestroyOnEndDragging = true;
      return;
    }
    this.shouldDestroyOnEndDragging = false;
    super.destroy();
    this.removeListeners();
    const eGui = this.getGui();
    if (eGui.parentElement) {
      eGui.parentElement.removeChild(eGui);
    }
  }
};
__decorateClass3([
  Autowired23("rowRenderer")
], AbstractSelectionHandle.prototype, "rowRenderer", 2);
__decorateClass3([
  Autowired23("dragService")
], AbstractSelectionHandle.prototype, "dragService", 2);
__decorateClass3([
  Autowired23("rangeService")
], AbstractSelectionHandle.prototype, "rangeService", 2);
__decorateClass3([
  Autowired23("mouseEventService")
], AbstractSelectionHandle.prototype, "mouseEventService", 2);
__decorateClass3([
  Autowired23("columnModel")
], AbstractSelectionHandle.prototype, "columnModel", 2);
__decorateClass3([
  Autowired23("cellNavigationService")
], AbstractSelectionHandle.prototype, "cellNavigationService", 2);
__decorateClass3([
  Autowired23("navigationService")
], AbstractSelectionHandle.prototype, "navigationService", 2);
__decorateClass3([
  Autowired23("rowPositionUtils")
], AbstractSelectionHandle.prototype, "rowPositionUtils", 2);
__decorateClass3([
  Autowired23("cellPositionUtils")
], AbstractSelectionHandle.prototype, "cellPositionUtils", 2);
__decorateClass3([
  Autowired23("ctrlsService")
], AbstractSelectionHandle.prototype, "ctrlsService", 2);
__decorateClass3([
  PostConstruct23
], AbstractSelectionHandle.prototype, "init", 1);
function findLineByLeastSquares(values) {
  const len = values.length;
  let maxDecimals = 0;
  if (len <= 1) {
    return values;
  }
  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    const splitExponent = value.toString().split("e-");
    if (splitExponent.length > 1) {
      maxDecimals = Math.max(maxDecimals, parseInt(splitExponent[1], 10));
      continue;
    }
    if (Math.floor(value) === value) {
      continue;
    }
    maxDecimals = Math.max(maxDecimals, value.toString().split(".")[1].length);
  }
  let sum_x = 0;
  let sum_y = 0;
  let sum_xy = 0;
  let sum_xx = 0;
  let y = 0;
  for (let x = 0; x < len; x++) {
    y = values[x];
    sum_x += x;
    sum_y += y;
    sum_xx += x * x;
    sum_xy += x * y;
  }
  const m = (len * sum_xy - sum_x * sum_y) / (len * sum_xx - sum_x * sum_x);
  const b = sum_y / len - m * sum_x / len;
  const result = [];
  for (let x = 0; x <= len; x++) {
    result.push(parseFloat((x * m + b).toFixed(maxDecimals)));
  }
  return result;
}
var _FillHandle = class _FillHandle2 extends AbstractSelectionHandle {
  constructor() {
    super(_FillHandle2.TEMPLATE);
    this.markedCells = [];
    this.cellValues = [];
    this.isUp = false;
    this.isLeft = false;
    this.isReduce = false;
    this.type = SelectionHandleType2.FILL;
  }
  updateValuesOnMove(e) {
    super.updateValuesOnMove(e);
    if (!this.initialXY) {
      this.initialXY = this.mouseEventService.getNormalisedPosition(e);
    }
    const { x, y } = this.initialXY;
    const { x: newX, y: newY } = this.mouseEventService.getNormalisedPosition(e);
    const diffX = Math.abs(x - newX);
    const diffY = Math.abs(y - newY);
    const allowedDirection = this.getFillHandleDirection();
    let direction;
    if (allowedDirection === "xy") {
      direction = diffX > diffY ? "x" : "y";
    } else {
      direction = allowedDirection;
    }
    if (direction !== this.dragAxis) {
      this.dragAxis = direction;
      this.changedCalculatedValues = true;
    }
  }
  onDrag(e) {
    if (!this.initialPosition) {
      const cellCtrl = this.getCellCtrl();
      if (!cellCtrl) {
        return;
      }
      this.initialPosition = cellCtrl.getCellPosition();
    }
    const lastCellHovered = this.getLastCellHovered();
    if (lastCellHovered) {
      this.markPathFrom(this.initialPosition, lastCellHovered);
    }
  }
  onDragEnd(e) {
    this.initialXY = null;
    if (!this.markedCells.length) {
      return;
    }
    const isX = this.dragAxis === "x";
    const initialRange = this.getCellRange();
    const colLen = initialRange.columns.length;
    const rangeStartRow = this.getRangeStartRow();
    const rangeEndRow = this.getRangeEndRow();
    let finalRange;
    if (!this.isUp && !this.isLeft) {
      finalRange = this.rangeService.createCellRangeFromCellRangeParams({
        rowStartIndex: rangeStartRow.rowIndex,
        rowStartPinned: rangeStartRow.rowPinned,
        columnStart: initialRange.columns[0],
        rowEndIndex: isX ? rangeEndRow.rowIndex : this.lastCellMarked.rowIndex,
        rowEndPinned: isX ? rangeEndRow.rowPinned : this.lastCellMarked.rowPinned,
        columnEnd: isX ? this.lastCellMarked.column : initialRange.columns[colLen - 1]
      });
    } else {
      const startRow = isX ? rangeStartRow : this.lastCellMarked;
      finalRange = this.rangeService.createCellRangeFromCellRangeParams({
        rowStartIndex: startRow.rowIndex,
        rowStartPinned: startRow.rowPinned,
        columnStart: isX ? this.lastCellMarked.column : initialRange.columns[0],
        rowEndIndex: rangeEndRow.rowIndex,
        rowEndPinned: rangeEndRow.rowPinned,
        columnEnd: initialRange.columns[colLen - 1]
      });
    }
    if (finalRange) {
      this.raiseFillStartEvent();
      this.handleValueChanged(initialRange, finalRange, e);
      this.rangeService.setCellRanges([finalRange]);
      this.raiseFillEndEvent(initialRange, finalRange);
    }
  }
  getFillHandleDirection() {
    const direction = this.gos.get("fillHandleDirection");
    if (!direction) {
      return "xy";
    }
    if (direction !== "x" && direction !== "y" && direction !== "xy") {
      _32.warnOnce(`valid values for fillHandleDirection are 'x', 'y' and 'xy'. Default to 'xy'.`);
      return "xy";
    }
    return direction;
  }
  raiseFillStartEvent() {
    const fillStartEvent = {
      type: Events22.EVENT_FILL_START
    };
    this.eventService.dispatchEvent(fillStartEvent);
  }
  raiseFillEndEvent(initialRange, finalRange) {
    const fillEndEvent = {
      type: Events22.EVENT_FILL_END,
      initialRange,
      finalRange
    };
    this.eventService.dispatchEvent(fillEndEvent);
  }
  handleValueChanged(initialRange, finalRange, e) {
    const initialRangeEndRow = this.rangeService.getRangeEndRow(initialRange);
    const initialRangeStartRow = this.rangeService.getRangeStartRow(initialRange);
    const finalRangeEndRow = this.rangeService.getRangeEndRow(finalRange);
    const finalRangeStartRow = this.rangeService.getRangeStartRow(finalRange);
    const isVertical = this.dragAxis === "y";
    if (this.isReduce && !this.gos.get("suppressClearOnFillReduction")) {
      const columns = isVertical ? initialRange.columns : initialRange.columns.filter((col) => finalRange.columns.indexOf(col) < 0);
      const startRow = isVertical ? this.cellNavigationService.getRowBelow(finalRangeEndRow) : finalRangeStartRow;
      if (startRow) {
        this.clearCellsInRange(startRow, initialRangeEndRow, columns);
      }
      return;
    }
    const values = [];
    const initialValues = [];
    const initialNonAggregatedValues = [];
    const initialFormattedValues = [];
    let withinInitialRange = true;
    let idx = 0;
    const resetValues = () => {
      values.length = 0;
      initialValues.length = 0;
      initialNonAggregatedValues.length = 0;
      initialFormattedValues.length = 0;
      idx = 0;
    };
    const iterateAcrossCells = (column, columns) => {
      let currentRow = this.isUp ? initialRangeEndRow : initialRangeStartRow;
      let finished = false;
      if (isVertical) {
        withinInitialRange = true;
        resetValues();
      }
      while (!finished && currentRow) {
        const rowNode = this.rowPositionUtils.getRowNode(currentRow);
        if (!rowNode) {
          break;
        }
        if (isVertical && column) {
          fillValues(values, column, rowNode, () => {
            return !this.rowPositionUtils.sameRow(currentRow, this.isUp ? initialRangeStartRow : initialRangeEndRow);
          });
        } else if (columns) {
          withinInitialRange = true;
          resetValues();
          columns.forEach((col) => fillValues(
            values,
            col,
            rowNode,
            () => col !== (this.isLeft ? initialRange.columns[0] : _32.last(initialRange.columns))
          ));
        }
        finished = this.rowPositionUtils.sameRow(currentRow, this.isUp ? finalRangeStartRow : finalRangeEndRow);
        currentRow = this.isUp ? this.cellNavigationService.getRowAbove(currentRow) : this.cellNavigationService.getRowBelow(currentRow);
      }
    };
    const fillValues = (currentValues, col, rowNode, updateInitialSet) => {
      var _a, _b;
      let currentValue;
      let skipValue = false;
      if (withinInitialRange) {
        currentValue = this.valueService.getValue(col, rowNode);
        initialValues.push(currentValue);
        initialNonAggregatedValues.push(this.valueService.getValue(col, rowNode, void 0, true));
        initialFormattedValues.push(this.valueService.formatValue(col, rowNode, currentValue));
        withinInitialRange = updateInitialSet();
      } else {
        const { value, fromUserFunction, sourceCol, sourceRowNode } = this.processValues({
          event: e,
          values: currentValues,
          initialValues,
          initialNonAggregatedValues,
          initialFormattedValues,
          col,
          rowNode,
          idx: idx++
        });
        currentValue = value;
        if (col.isCellEditable(rowNode)) {
          const cellValue = this.valueService.getValue(col, rowNode);
          if (!fromUserFunction) {
            if (sourceCol && ((_a = sourceCol.getColDef()) == null ? void 0 : _a.useValueFormatterForExport) !== false) {
              currentValue = (_b = this.valueService.formatValue(sourceCol, sourceRowNode, currentValue)) != null ? _b : currentValue;
            }
            if (col.getColDef().useValueParserForImport !== false) {
              currentValue = this.valueService.parseValue(
                col,
                rowNode,
                // if no sourceCol, then currentValue is a number
                sourceCol ? currentValue : _32.toStringOrNull(currentValue),
                cellValue
              );
            }
          }
          if (!fromUserFunction || cellValue !== currentValue) {
            rowNode.setDataValue(col, currentValue, "rangeService");
          } else {
            skipValue = true;
          }
        }
      }
      if (!skipValue) {
        currentValues.push({
          value: currentValue,
          column: col,
          rowNode
        });
      }
    };
    if (isVertical) {
      initialRange.columns.forEach((col) => {
        iterateAcrossCells(col);
      });
    } else {
      const columns = this.isLeft ? [...finalRange.columns].reverse() : finalRange.columns;
      iterateAcrossCells(void 0, columns);
    }
  }
  clearCellsInRange(startRow, endRow, columns) {
    const cellRange = {
      startRow,
      endRow,
      columns,
      startColumn: columns[0]
    };
    this.rangeService.clearCellRangeCellValues({ cellRanges: [cellRange] });
  }
  processValues(params) {
    const { event, values, initialValues, initialNonAggregatedValues, initialFormattedValues, col, rowNode, idx } = params;
    const userFillOperation = this.gos.getCallback("fillOperation");
    const isVertical = this.dragAxis === "y";
    let direction;
    if (isVertical) {
      direction = this.isUp ? "up" : "down";
    } else {
      direction = this.isLeft ? "left" : "right";
    }
    if (userFillOperation) {
      const params2 = {
        event,
        values: values.map(({ value }) => value),
        initialValues,
        initialNonAggregatedValues,
        initialFormattedValues,
        currentIndex: idx,
        currentCellValue: this.valueService.getValue(col, rowNode),
        direction,
        column: col,
        rowNode
      };
      const userResult = userFillOperation(params2);
      if (userResult !== false) {
        return { value: userResult, fromUserFunction: true };
      }
    }
    const allNumbers = !values.some(({ value }) => {
      const asFloat = parseFloat(value);
      return isNaN(asFloat) || asFloat.toString() !== value.toString();
    });
    if (event.altKey || !allNumbers) {
      if (allNumbers && initialValues.length === 1) {
        const multiplier = this.isUp || this.isLeft ? -1 : 1;
        return { value: parseFloat(_32.last(values).value) + 1 * multiplier, fromUserFunction: false };
      }
      const { value, column: sourceCol, rowNode: sourceRowNode } = values[idx % values.length];
      return { value, fromUserFunction: false, sourceCol, sourceRowNode };
    }
    return { value: _32.last(findLineByLeastSquares(values.map(({ value }) => Number(value)))), fromUserFunction: false };
  }
  clearValues() {
    this.clearMarkedPath();
    this.clearCellValues();
    this.lastCellMarked = void 0;
    super.clearValues();
  }
  clearMarkedPath() {
    this.markedCells.forEach((cell) => {
      if (!cell.isAlive()) {
        return;
      }
      const comp = cell.getComp();
      comp.addOrRemoveCssClass("ag-selection-fill-top", false);
      comp.addOrRemoveCssClass("ag-selection-fill-right", false);
      comp.addOrRemoveCssClass("ag-selection-fill-bottom", false);
      comp.addOrRemoveCssClass("ag-selection-fill-left", false);
    });
    this.markedCells.length = 0;
    this.isUp = false;
    this.isLeft = false;
    this.isReduce = false;
  }
  clearCellValues() {
    this.cellValues.length = 0;
  }
  markPathFrom(initialPosition, currentPosition) {
    this.clearMarkedPath();
    this.clearCellValues();
    if (this.dragAxis === "y") {
      if (this.rowPositionUtils.sameRow(currentPosition, initialPosition)) {
        return;
      }
      const isBefore = this.rowPositionUtils.before(currentPosition, initialPosition);
      const rangeStartRow = this.getRangeStartRow();
      const rangeEndRow = this.getRangeEndRow();
      if (isBefore && (currentPosition.rowPinned == rangeStartRow.rowPinned && currentPosition.rowIndex >= rangeStartRow.rowIndex || rangeStartRow.rowPinned != rangeEndRow.rowPinned && currentPosition.rowPinned == rangeEndRow.rowPinned && currentPosition.rowIndex <= rangeEndRow.rowIndex)) {
        this.reduceVertical(initialPosition, currentPosition);
        this.isReduce = true;
      } else {
        this.extendVertical(initialPosition, currentPosition, isBefore);
        this.isReduce = false;
      }
    } else {
      const initialColumn = initialPosition.column;
      const currentColumn = currentPosition.column;
      if (initialColumn === currentColumn) {
        return;
      }
      const displayedColumns = this.columnModel.getAllDisplayedColumns();
      const initialIndex = displayedColumns.indexOf(initialColumn);
      const currentIndex = displayedColumns.indexOf(currentColumn);
      if (currentIndex <= initialIndex && currentIndex >= displayedColumns.indexOf(this.getCellRange().columns[0])) {
        this.reduceHorizontal(initialPosition, currentPosition);
        this.isReduce = true;
      } else {
        this.extendHorizontal(initialPosition, currentPosition, currentIndex < initialIndex);
        this.isReduce = false;
      }
    }
    this.lastCellMarked = currentPosition;
  }
  extendVertical(initialPosition, endPosition, isMovingUp) {
    const { navigationService, rangeService } = this;
    let row = initialPosition;
    do {
      const cellRange = this.getCellRange();
      const colLen = cellRange.columns.length;
      for (let i = 0; i < colLen; i++) {
        const column = cellRange.columns[i];
        const rowPos = { rowIndex: row.rowIndex, rowPinned: row.rowPinned };
        const cellPos = __spreadProps2(__spreadValues2({}, rowPos), { column });
        const cellInRange = rangeService.isCellInSpecificRange(cellPos, cellRange);
        const isInitialRow = this.rowPositionUtils.sameRow(row, initialPosition);
        if (isMovingUp) {
          this.isUp = true;
        }
        if (!isInitialRow) {
          const cell = navigationService.getCellByPosition(cellPos);
          if (cell) {
            this.markedCells.push(cell);
            const cellCtrl = cell.getComp();
            if (!cellInRange) {
              cellCtrl.addOrRemoveCssClass("ag-selection-fill-left", i === 0);
              cellCtrl.addOrRemoveCssClass("ag-selection-fill-right", i === colLen - 1);
            }
            cellCtrl.addOrRemoveCssClass(
              isMovingUp ? "ag-selection-fill-top" : "ag-selection-fill-bottom",
              this.rowPositionUtils.sameRow(row, endPosition)
            );
          }
        }
      }
      if (this.rowPositionUtils.sameRow(row, endPosition)) {
        break;
      }
    } while (
      // tslint:disable-next-line
      row = isMovingUp ? this.cellNavigationService.getRowAbove(row) : this.cellNavigationService.getRowBelow(row)
    );
  }
  reduceVertical(initialPosition, endPosition) {
    let row = initialPosition;
    do {
      const cellRange = this.getCellRange();
      const colLen = cellRange.columns.length;
      const isLastRow = this.rowPositionUtils.sameRow(row, endPosition);
      for (let i = 0; i < colLen; i++) {
        const rowPos = { rowIndex: row.rowIndex, rowPinned: row.rowPinned };
        const celPos = __spreadProps2(__spreadValues2({}, rowPos), { column: cellRange.columns[i] });
        const cell = this.navigationService.getCellByPosition(celPos);
        if (cell) {
          this.markedCells.push(cell);
          const cellComp = cell.getComp();
          cellComp.addOrRemoveCssClass(
            "ag-selection-fill-bottom",
            this.rowPositionUtils.sameRow(row, endPosition)
          );
        }
      }
      if (isLastRow) {
        break;
      }
    } while (row = this.cellNavigationService.getRowAbove(row));
  }
  extendHorizontal(initialPosition, endPosition, isMovingLeft) {
    const allCols = this.columnModel.getAllDisplayedColumns();
    const startCol = allCols.indexOf(isMovingLeft ? endPosition.column : initialPosition.column);
    const endCol = allCols.indexOf(isMovingLeft ? this.getCellRange().columns[0] : endPosition.column);
    const offset = isMovingLeft ? 0 : 1;
    const colsToMark = allCols.slice(startCol + offset, endCol + offset);
    const rangeStartRow = this.getRangeStartRow();
    const rangeEndRow = this.getRangeEndRow();
    colsToMark.forEach((column) => {
      let row = rangeStartRow;
      let isLastRow = false;
      do {
        isLastRow = this.rowPositionUtils.sameRow(row, rangeEndRow);
        const cell = this.navigationService.getCellByPosition({
          rowIndex: row.rowIndex,
          rowPinned: row.rowPinned,
          column
        });
        if (cell) {
          this.markedCells.push(cell);
          const cellComp = cell.getComp();
          cellComp.addOrRemoveCssClass("ag-selection-fill-top", this.rowPositionUtils.sameRow(row, rangeStartRow));
          cellComp.addOrRemoveCssClass("ag-selection-fill-bottom", this.rowPositionUtils.sameRow(row, rangeEndRow));
          if (isMovingLeft) {
            this.isLeft = true;
            cellComp.addOrRemoveCssClass("ag-selection-fill-left", column === colsToMark[0]);
          } else {
            cellComp.addOrRemoveCssClass("ag-selection-fill-right", column === _32.last(colsToMark));
          }
        }
        row = this.cellNavigationService.getRowBelow(row);
      } while (!isLastRow);
    });
  }
  reduceHorizontal(initialPosition, endPosition) {
    const allCols = this.columnModel.getAllDisplayedColumns();
    const startCol = allCols.indexOf(endPosition.column);
    const endCol = allCols.indexOf(initialPosition.column);
    const colsToMark = allCols.slice(startCol, endCol);
    const rangeStartRow = this.getRangeStartRow();
    const rangeEndRow = this.getRangeEndRow();
    colsToMark.forEach((column) => {
      let row = rangeStartRow;
      let isLastRow = false;
      do {
        isLastRow = this.rowPositionUtils.sameRow(row, rangeEndRow);
        const cell = this.navigationService.getCellByPosition({
          rowIndex: row.rowIndex,
          rowPinned: row.rowPinned,
          column
        });
        if (cell) {
          this.markedCells.push(cell);
          const cellComp = cell.getComp();
          cellComp.addOrRemoveCssClass("ag-selection-fill-right", column === colsToMark[0]);
        }
        row = this.cellNavigationService.getRowBelow(row);
      } while (!isLastRow);
    });
  }
  refresh(cellCtrl) {
    const cellRange = this.rangeService.getCellRanges()[0];
    const isColumnRange = !cellRange.startRow || !cellRange.endRow;
    if (isColumnRange) {
      this.destroy();
      return;
    }
    super.refresh(cellCtrl);
  }
};
_FillHandle.TEMPLATE = /* html */
`<div class="ag-fill-handle"></div>`;
__decorateClass3([
  Autowired33("valueService")
], _FillHandle.prototype, "valueService", 2);
var FillHandle = _FillHandle;
var _RangeHandle = class _RangeHandle2 extends AbstractSelectionHandle {
  constructor() {
    super(_RangeHandle2.TEMPLATE);
    this.type = SelectionHandleType3.RANGE;
    this.rangeFixed = false;
  }
  onDrag(e) {
    const lastCellHovered = this.getLastCellHovered();
    if (!lastCellHovered) {
      return;
    }
    const cellRanges = this.rangeService.getCellRanges();
    const lastRange = _42.last(cellRanges);
    if (!this.rangeFixed) {
      this.fixRangeStartEnd(lastRange);
      this.rangeFixed = true;
    }
    this.endPosition = {
      rowIndex: lastCellHovered.rowIndex,
      rowPinned: lastCellHovered.rowPinned,
      column: lastCellHovered.column
    };
    if (cellRanges.length === 2 && cellRanges[0].type === CellRangeType.DIMENSION && lastRange.type === CellRangeType.VALUE) {
      const rowChanged = !this.rowPositionUtils.sameRow(this.endPosition, this.rangeService.getRangeEndRow(lastRange));
      if (rowChanged) {
        this.rangeService.updateRangeEnd(
          cellRanges[0],
          __spreadProps2(__spreadValues2({}, this.endPosition), {
            column: cellRanges[0].columns[0]
          }),
          true
        );
      }
    }
    this.rangeService.extendLatestRangeToCell(this.endPosition);
  }
  onDragEnd(e) {
    const cellRange = _42.last(this.rangeService.getCellRanges());
    this.fixRangeStartEnd(cellRange);
    this.rangeFixed = false;
  }
  fixRangeStartEnd(cellRange) {
    const startRow = this.rangeService.getRangeStartRow(cellRange);
    const endRow = this.rangeService.getRangeEndRow(cellRange);
    const column = cellRange.columns[0];
    cellRange.startRow = startRow;
    cellRange.endRow = endRow;
    cellRange.startColumn = column;
  }
};
_RangeHandle.TEMPLATE = /* html */
`<div class="ag-range-handle"></div>`;
var RangeHandle = _RangeHandle;
var SelectionHandleFactory = class extends BeanStub23 {
  createSelectionHandle(type) {
    return this.createBean(type === SelectionHandleType4.RANGE ? new RangeHandle() : new FillHandle());
  }
};
SelectionHandleFactory = __decorateClass3([
  Bean23("selectionHandleFactory")
], SelectionHandleFactory);
var VERSION3 = "31.3.2";
var RangeSelectionModule = {
  version: VERSION3,
  moduleName: ModuleNames3.RangeSelectionModule,
  beans: [RangeService, SelectionHandleFactory],
  agStackComponents: [
    { componentName: "AgFillHandle", componentClass: FillHandle },
    { componentName: "AgRangeHandle", componentClass: RangeHandle }
  ],
  dependantModules: [
    EnterpriseCoreModule
  ]
};

// enterprise-modules/charts/dist/package/main.esm.mjs
__reExport(main_esm_exports, ag_charts_community_star);
import {
  Autowired as Autowired41,
  Bean as Bean32,
  BeanStub as BeanStub10,
  Optional as Optional2,
  PreDestroy
} from "ag-grid-community";
import { VERSION as CHARTS_VERSION, _ModuleSupport as _ModuleSupport5 } from "ag-charts-community";
import {
  _ as _33,
  AgDialog as AgDialog2,
  Autowired as Autowired40,
  Component as Component36,
  Events as Events10,
  PostConstruct as PostConstruct42,
  RefSelector as RefSelector12
} from "ag-grid-community";
import {
  AgPanel,
  AgPromise as AgPromise2,
  Autowired as Autowired37,
  Component as Component34,
  Events as Events8,
  PostConstruct as PostConstruct40
} from "ag-grid-community";
import {
  AgPromise,
  Autowired as Autowired35,
  Component as Component32,
  PostConstruct as PostConstruct39,
  TabbedLayout
} from "ag-grid-community";
import {
  _ as _112,
  AutoScrollService as AutoScrollService4,
  Autowired as Autowired102,
  Component as Component52,
  PostConstruct as PostConstruct102
} from "ag-grid-community";
import {
  _ as _52,
  Autowired as Autowired42,
  BeanStub as BeanStub52,
  Events as Events23,
  PostConstruct as PostConstruct43
} from "ag-grid-community";
import {
  _ as _24,
  Autowired as Autowired34,
  BeanStub as BeanStub42,
  CellRangeType as CellRangeType2,
  PostConstruct as PostConstruct32
} from "ag-grid-community";
import {
  _ as _15,
  Autowired as Autowired13,
  BeanStub as BeanStub7,
  ModuleNames as ModuleNames4,
  ModuleRegistry,
  Optional
} from "ag-grid-community";
import {
  Autowired as Autowired24,
  Bean as Bean4,
  BeanStub as BeanStub24,
  Events as Events9,
  PostConstruct as PostConstruct13
} from "ag-grid-community";
import { BeanStub as BeanStub32, PostConstruct as PostConstruct24 } from "ag-grid-community";
import { ChartMappings } from "ag-grid-community";
import { _Theme as _Theme2, _ModuleSupport as _ModuleSupport2 } from "ag-charts-community";
import { _ as _34 } from "ag-grid-community";
import {
  _Theme
} from "ag-charts-community";
import {
  _ as _43
} from "ag-grid-community";
import { _ModuleSupport } from "ag-charts-community";
import {
  AgCheckbox as AgCheckbox2,
  AgGroupComponent as AgGroupComponent2,
  AgRadioButton as AgRadioButton2,
  AgSelect as AgSelect3,
  AgToggleButton,
  Autowired as Autowired62,
  DragAndDropService as DragAndDropService3,
  PostConstruct as PostConstruct62,
  _ as _82
} from "ag-grid-community";
import {
  AgSelect as AgSelect2,
  Autowired as Autowired52,
  Component as Component22,
  DragSourceType as DragSourceType22,
  Events as Events32,
  _ as _72
} from "ag-grid-community";
import {
  AgSelect,
  Component as Component10,
  DragAndDropService as DragAndDropService2,
  DragSourceType as DragSourceType3,
  PillDragComp,
  PillDropZonePanel,
  PostConstruct as PostConstruct52,
  _ as _62
} from "ag-grid-community";
import {
  AgCheckbox as AgCheckbox3,
  AgGroupComponent as AgGroupComponent3,
  AgToggleButton as AgToggleButton2,
  Autowired as Autowired72,
  DragAndDropService as DragAndDropService4,
  PostConstruct as PostConstruct72,
  _ as _92
} from "ag-grid-community";
import {
  AgCheckbox as AgCheckbox4,
  AgGroupComponent as AgGroupComponent4,
  AgSelect as AgSelect4,
  Autowired as Autowired82,
  Component as Component33,
  PostConstruct as PostConstruct82,
  _ as _102
} from "ag-grid-community";
import {
  AgToggleButton as AgToggleButton3,
  Autowired as Autowired92,
  Component as Component42,
  PostConstruct as PostConstruct92
} from "ag-grid-community";
import {
  _ as _25,
  Component as Component28,
  PostConstruct as PostConstruct33
} from "ag-grid-community";
import {
  Autowired as Autowired122,
  Component as Component72,
  PostConstruct as PostConstruct122
} from "ag-grid-community";
import {
  _ as _122,
  Autowired as Autowired112,
  Component as Component62,
  PostConstruct as PostConstruct112,
  RefSelector as RefSelector6
} from "ag-grid-community";
import {
  _ as _152,
  AgCheckbox as AgCheckbox5,
  AgSlider,
  Autowired as Autowired17,
  Component as Component102,
  Events as Events52,
  PostConstruct as PostConstruct15,
  RefSelector as RefSelector33
} from "ag-grid-community";
import {
  Autowired as Autowired132,
  Component as Component82,
  PostConstruct as PostConstruct132
} from "ag-grid-community";
import { Autowired as Autowired14, Component as Component92, PostConstruct as PostConstruct14 } from "ag-grid-community";
import { Autowired as Autowired15, Events as Events42, AgAbstractLabel, RefSelector as RefSelector22, _ as _142 } from "ag-grid-community";
import { Autowired as Autowired16, BeanStub as BeanStub62 } from "ag-grid-community";
import {
  AgGroupComponent as AgGroupComponent7,
  AgSelect as AgSelect6,
  AgSlider as AgSlider2,
  Autowired as Autowired18,
  Component as Component11,
  PostConstruct as PostConstruct16,
  RefSelector as RefSelector42
} from "ag-grid-community";
import {
  AgSelect as AgSelect7,
  Autowired as Autowired222,
  Component as Component15,
  Events as Events72,
  PostConstruct as PostConstruct20
} from "ag-grid-community";
import {
  Autowired as Autowired19,
  Component as Component12,
  Events as Events62,
  PostConstruct as PostConstruct17,
  RefSelector as RefSelector52
} from "ag-grid-community";
import {
  Autowired as Autowired20,
  Component as Component13,
  PostConstruct as PostConstruct18
} from "ag-grid-community";
import {
  _ as _17,
  AgSlider as AgSlider4,
  Autowired as Autowired21,
  Component as Component14,
  PostConstruct as PostConstruct19
} from "ag-grid-community";
import {
  _ as _222,
  AgSelect as AgSelect8,
  AgSlider as AgSlider7,
  AgToggleButton as AgToggleButton4,
  Autowired as Autowired31,
  Component as Component25,
  PostConstruct as PostConstruct30,
  RefSelector as RefSelector9,
  ChartMappings as ChartMappings2
} from "ag-grid-community";
import {
  Autowired as Autowired232,
  Component as Component16,
  PostConstruct as PostConstruct21
} from "ag-grid-community";
import {
  _ as _18,
  Autowired as Autowired242,
  Component as Component17,
  PostConstruct as PostConstruct222,
  RefSelector as RefSelector62
} from "ag-grid-community";
import { Component as Component18, PostConstruct as PostConstruct232, RefSelector as RefSelector7, _ as _19 } from "ag-grid-community";
import { _Util } from "ag-charts-community";
import { KeyCode as KeyCode5 } from "ag-grid-community";
import { AgPickerField, AgDialog as AgDialog3 } from "ag-grid-community";
import {
  Autowired as Autowired25,
  Component as Component19,
  PostConstruct as PostConstruct242
} from "ag-grid-community";
import {
  Autowired as Autowired26,
  Component as Component20,
  PostConstruct as PostConstruct25
} from "ag-grid-community";
import {
  Autowired as Autowired27,
  Component as Component21,
  PostConstruct as PostConstruct26
} from "ag-grid-community";
import {
  Autowired as Autowired28,
  Component as Component222,
  PostConstruct as PostConstruct27
} from "ag-grid-community";
import {
  _ as _21,
  AgSlider as AgSlider6,
  Autowired as Autowired29,
  Component as Component23,
  PostConstruct as PostConstruct28,
  RefSelector as RefSelector8
} from "ag-grid-community";
import {
  Autowired as Autowired30,
  Component as Component24,
  PostConstruct as PostConstruct29
} from "ag-grid-community";
import {
  Autowired as Autowired322,
  Component as Component26,
  PostConstruct as PostConstruct31
} from "ag-grid-community";
import {
  BeanStub as BeanStub72,
  PostConstruct as PostConstruct322,
  _ as _242
} from "ag-grid-community";
import { _ as _27, Component as Component31, PostConstruct as PostConstruct38, RefSelector as RefSelector10 } from "ag-grid-community";
import {
  _ as _26,
  AgGroupComponent as AgGroupComponent10,
  Autowired as Autowired342,
  Component as Component30,
  PostConstruct as PostConstruct37
} from "ag-grid-community";
import { PostConstruct as PostConstruct35 } from "ag-grid-community";
import { Autowired as Autowired332, Component as Component29, PostConstruct as PostConstruct34 } from "ag-grid-community";
import { _Scene } from "ag-charts-community";
import { _Scene as _Scene2 } from "ag-charts-community";
import { _Scene as _Scene3 } from "ag-charts-community";
import { _Scene as _Scene4 } from "ag-charts-community";
import { _Scene as _Scene5 } from "ag-charts-community";
import { _Scene as _Scene6 } from "ag-charts-community";
import { _Scene as _Scene7 } from "ag-charts-community";
import { _Scene as _Scene8 } from "ag-charts-community";
import { _Scene as _Scene9 } from "ag-charts-community";
import { _Scene as _Scene10 } from "ag-charts-community";
import { _Scene as _Scene11 } from "ag-charts-community";
import { _Scene as _Scene13 } from "ag-charts-community";
import { PostConstruct as PostConstruct36 } from "ag-grid-community";
import { _Scene as _Scene12 } from "ag-charts-community";
import { _Scene as _Scene14 } from "ag-charts-community";
import { _Scene as _Scene15 } from "ag-charts-community";
import { _Scene as _Scene16 } from "ag-charts-community";
import { _Scene as _Scene17 } from "ag-charts-community";
import { _Scene as _Scene18, _Theme as _Theme4, _Util as _Util3 } from "ag-charts-community";
import { _Scene as _Scene19, _Theme as _Theme5 } from "ag-charts-community";
import { _Scene as _Scene20 } from "ag-charts-community";
import { _Scene as _Scene21, _Theme as _Theme6, _Util as _Util5 } from "ag-charts-community";
import { _Scene as _Scene22, _Theme as _Theme7 } from "ag-charts-community";
import { _Scene as _Scene23 } from "ag-charts-community";
import { _Scene as _Scene24 } from "ag-charts-community";
import {
  Autowired as Autowired36,
  Component as Component332,
  RefSelector as RefSelector11,
  _ as _28
} from "ag-grid-community";
import { Autowired as Autowired38, Component as Component35, PostConstruct as PostConstruct41 } from "ag-grid-community";
import { _ as _30 } from "ag-grid-community";
import {
  _Theme as _Theme8,
  _ModuleSupport as _ModuleSupport4,
  AgCharts
} from "ag-charts-community";
import { _Util as _Util6 } from "ag-charts-community";
import {
  BeanStub as BeanStub8,
  Events as Events92
} from "ag-grid-community";
import {
  AgCharts as AgCharts2
} from "ag-charts-community";
import {
  Autowired as Autowired39,
  Bean as Bean24,
  BeanStub as BeanStub9,
  _ as _322
} from "ag-grid-community";
import { GROUP_AUTO_COLUMN_ID } from "ag-grid-community";
import { Bean as Bean42, BeanStub as BeanStub11 } from "ag-grid-community";
import {
  _ as _35,
  Autowired as Autowired422,
  Bean as Bean5,
  BeanStub as BeanStub12
} from "ag-grid-community";
import {
  AgMenuItemComponent,
  AgMenuList,
  Autowired as Autowired43,
  Bean as Bean6,
  BeanStub as BeanStub13,
  Component as Component37,
  PostConstruct as PostConstruct432,
  RefSelector as RefSelector13,
  _ as _36
} from "ag-grid-community";
import {
  AgDialog as AgDialog32,
  Autowired as Autowired48,
  Bean as Bean7,
  BeanStub as BeanStub14,
  PostConstruct as PostConstruct49,
  TabGuardComp as TabGuardComp2
} from "ag-grid-community";
import {
  Component as Component422,
  PostConstruct as PostConstruct48
} from "ag-grid-community";
import {
  Autowired as Autowired44,
  Component as Component38,
  PostConstruct as PostConstruct44
} from "ag-grid-community";
import {
  Autowired as Autowired45,
  Component as Component39,
  PostConstruct as PostConstruct45
} from "ag-grid-community";
import {
  Autowired as Autowired46,
  Component as Component40,
  PostConstruct as PostConstruct46
} from "ag-grid-community";
import {
  Autowired as Autowired47,
  Component as Component41,
  PostConstruct as PostConstruct47,
  RefSelector as RefSelector14
} from "ag-grid-community";
import { time, AgChart } from "ag-charts-community";
import * as ag_charts_community_star from "ag-charts-community";
var __defProp4 = Object.defineProperty;
var __defProps3 = Object.defineProperties;
var __getOwnPropDesc4 = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs3 = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols3 = Object.getOwnPropertySymbols;
var __hasOwnProp4 = Object.prototype.hasOwnProperty;
var __propIsEnum3 = Object.prototype.propertyIsEnumerable;
var __defNormalProp3 = (obj, key, value) => key in obj ? __defProp4(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues3 = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp4.call(b, prop))
      __defNormalProp3(a, prop, b[prop]);
  if (__getOwnPropSymbols3)
    for (var prop of __getOwnPropSymbols3(b)) {
      if (__propIsEnum3.call(b, prop))
        __defNormalProp3(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps3 = (a, b) => __defProps3(a, __getOwnPropDescs3(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp4.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols3)
    for (var prop of __getOwnPropSymbols3(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum3.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __decorateClass4 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc4(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp4(target, key, result);
  return result;
};
var _ChartDatasource = class _ChartDatasource2 extends BeanStub7 {
  getData(params) {
    if (params.crossFiltering) {
      if (params.grouping) {
        console.warn("AG Grid: crossing filtering with row grouping is not supported.");
        return { chartData: [], columnNames: {} };
      }
      if (!this.gos.isRowModelType("clientSide")) {
        console.warn("AG Grid: crossing filtering is only supported in the client side row model.");
        return { chartData: [], columnNames: {} };
      }
    }
    const isServerSide = this.gos.isRowModelType("serverSide");
    if (isServerSide && params.pivoting) {
      this.updatePivotKeysForSSRM();
    }
    const result = this.extractRowsFromGridRowModel(params);
    result.chartData = this.aggregateRowsByDimension(params, result.chartData);
    return result;
  }
  extractRowsFromGridRowModel(params) {
    const { crossFiltering, startRow, endRow, valueCols, dimensionCols, grouping } = params;
    let extractedRowData = [];
    const columnNames = {};
    const groupNodeIndexes = {};
    const groupsToRemove = {};
    let filteredNodes = {};
    let allRowNodes = [];
    let numRows;
    if (crossFiltering) {
      filteredNodes = this.getFilteredRowNodes();
      allRowNodes = this.getAllRowNodes();
      numRows = allRowNodes.length;
    } else {
      const modelLastRow = this.gridRowModel.getRowCount() - 1;
      const hasNoRange = startRow === endRow && startRow === 0 && dimensionCols.length === 0 && valueCols.length === 0;
      if (hasNoRange) {
        numRows = 0;
      } else {
        const rangeLastRow = endRow >= 0 ? Math.min(endRow, modelLastRow) : modelLastRow;
        numRows = rangeLastRow - startRow + 1;
      }
    }
    if (numRows > 0) {
      valueCols.forEach((col) => {
        let columnNamesArr = [];
        const pivotKeys = col.getColDef().pivotKeys;
        if (pivotKeys) {
          columnNamesArr = pivotKeys.slice();
        }
        const headerName = col.getColDef().headerName;
        if (headerName) {
          columnNamesArr.push(headerName);
        }
        if (columnNamesArr.length > 0) {
          columnNames[col.getId()] = columnNamesArr;
        }
      });
    }
    let numRemovedNodes = 0;
    for (let i = 0; i < numRows; i++) {
      const rowNode = crossFiltering ? allRowNodes[i] : this.gridRowModel.getRow(i + startRow);
      if (rowNode.footer) {
        numRemovedNodes++;
        continue;
      }
      const data = {};
      dimensionCols.forEach((col) => {
        const colId = col.colId;
        const column = this.columnModel.getGridColumn(colId);
        if (column) {
          const valueObject = this.valueService.getValue(column, rowNode);
          if (grouping) {
            const valueString = valueObject && valueObject.toString ? String(valueObject.toString()) : "";
            const labels = _ChartDatasource2.getGroupLabels(rowNode, valueString);
            data[colId] = {
              labels,
              toString: function() {
                return this.labels.filter((l) => !!l).reverse().join(" - ");
              }
            };
            if (rowNode.group) {
              groupNodeIndexes[labels.toString()] = i - numRemovedNodes;
            }
            const groupKey = labels.slice(1, labels.length).toString();
            if (groupKey) {
              groupsToRemove[groupKey] = groupNodeIndexes[groupKey];
            }
          } else {
            data[colId] = valueObject;
          }
        } else {
          data[ChartDataModel.DEFAULT_CATEGORY] = i + 1;
        }
      });
      valueCols.forEach((col) => {
        const colId = col.getColId();
        if (crossFiltering) {
          const filteredOutColId = colId + "-filtered-out";
          const value = this.valueService.getValue(col, rowNode);
          const actualValue = value != null && typeof value.toNumber === "function" ? value.toNumber() : value;
          if (filteredNodes[rowNode.id]) {
            data[colId] = actualValue;
            data[filteredOutColId] = params.aggFunc || params.isScatter ? void 0 : 0;
          } else {
            data[colId] = params.aggFunc || params.isScatter ? void 0 : 0;
            data[filteredOutColId] = actualValue;
          }
        } else {
          let value = this.valueService.getValue(col, rowNode);
          if (value && value.hasOwnProperty("toString")) {
            value = parseFloat(value.toString());
          }
          data[colId] = value != null && typeof value.toNumber === "function" ? value.toNumber() : value;
        }
      });
      extractedRowData.push(data);
    }
    let groupChartData;
    if (grouping) {
      const groupIndexesToRemove = _15.values(groupsToRemove);
      const allData = extractedRowData;
      extractedRowData = [];
      groupChartData = [];
      for (let i = 0; i < allData.length; i++) {
        (_15.includes(groupIndexesToRemove, i) ? groupChartData : extractedRowData).push(allData[i]);
      }
    }
    return { chartData: extractedRowData, columnNames, groupChartData };
  }
  aggregateRowsByDimension(params, dataFromGrid) {
    const dimensionCols = params.dimensionCols;
    if (!params.aggFunc || dimensionCols.length === 0) {
      return dataFromGrid;
    }
    const lastCol = _15.last(dimensionCols);
    const lastColId = lastCol && lastCol.colId;
    const map = {};
    const dataAggregated = [];
    dataFromGrid.forEach((data) => {
      let currentMap = map;
      dimensionCols.forEach((col) => {
        const colId = col.colId;
        const key = data[colId];
        if (colId === lastColId) {
          let groupItem = currentMap[key];
          if (!groupItem) {
            groupItem = { __children: [] };
            dimensionCols.forEach((dimCol) => {
              const dimColId = dimCol.colId;
              groupItem[dimColId] = data[dimColId];
            });
            currentMap[key] = groupItem;
            dataAggregated.push(groupItem);
          }
          groupItem.__children.push(data);
        } else {
          if (!currentMap[key]) {
            currentMap[key] = {};
          }
          currentMap = currentMap[key];
        }
      });
    });
    if (ModuleRegistry.__assertRegistered(ModuleNames4.RowGroupingModule, "Charting Aggregation", this.context.getGridId())) {
      const aggStage = this.aggregationStage;
      dataAggregated.forEach((groupItem) => params.valueCols.forEach((col) => {
        if (params.crossFiltering) {
          params.valueCols.forEach((valueCol) => {
            const colId = valueCol.getColId();
            const dataToAgg = groupItem.__children.filter((child) => typeof child[colId] !== "undefined").map((child) => child[colId]);
            let aggResult = aggStage.aggregateValues(dataToAgg, params.aggFunc);
            groupItem[valueCol.getId()] = aggResult && typeof aggResult.value !== "undefined" ? aggResult.value : aggResult;
            const filteredOutColId = `${colId}-filtered-out`;
            const dataToAggFiltered = groupItem.__children.filter((child) => typeof child[filteredOutColId] !== "undefined").map((child) => child[filteredOutColId]);
            let aggResultFiltered = aggStage.aggregateValues(dataToAggFiltered, params.aggFunc);
            groupItem[filteredOutColId] = aggResultFiltered && typeof aggResultFiltered.value !== "undefined" ? aggResultFiltered.value : aggResultFiltered;
          });
        } else {
          const dataToAgg = groupItem.__children.map((child) => child[col.getId()]);
          let aggResult = aggStage.aggregateValues(dataToAgg, params.aggFunc);
          groupItem[col.getId()] = aggResult && typeof aggResult.value !== "undefined" ? aggResult.value : aggResult;
        }
      }));
    }
    return dataAggregated;
  }
  updatePivotKeysForSSRM() {
    const secondaryColumns = this.columnModel.getSecondaryColumns();
    if (!secondaryColumns) {
      return;
    }
    const pivotKeySeparator = this.extractPivotKeySeparator(secondaryColumns);
    secondaryColumns.forEach((col) => {
      if (pivotKeySeparator === "") {
        col.getColDef().pivotKeys = [];
      } else {
        const keys = col.getColId().split(pivotKeySeparator);
        col.getColDef().pivotKeys = keys.slice(0, keys.length - 1);
      }
    });
  }
  extractPivotKeySeparator(secondaryColumns) {
    if (secondaryColumns.length === 0) {
      return "";
    }
    const extractSeparator = (columnGroup, childId) => {
      const groupId = columnGroup.getGroupId();
      if (!columnGroup.getParent()) {
        return childId.split(groupId)[1][0];
      }
      return extractSeparator(columnGroup.getParent(), groupId);
    };
    const firstSecondaryCol = secondaryColumns[0];
    if (firstSecondaryCol.getParent() == null) {
      return "";
    }
    return extractSeparator(firstSecondaryCol.getParent(), firstSecondaryCol.getColId());
  }
  static getGroupLabels(rowNode, initialLabel) {
    const labels = [initialLabel];
    while (rowNode && rowNode.level !== 0) {
      rowNode = rowNode.parent;
      if (rowNode) {
        labels.push(rowNode.key);
      }
    }
    return labels;
  }
  getFilteredRowNodes() {
    const filteredNodes = {};
    this.gridRowModel.forEachNodeAfterFilterAndSort((rowNode) => {
      filteredNodes[rowNode.id] = rowNode;
    });
    return filteredNodes;
  }
  getAllRowNodes() {
    let allRowNodes = [];
    this.gridRowModel.forEachNode((rowNode) => {
      allRowNodes.push(rowNode);
    });
    return this.sortRowNodes(allRowNodes);
  }
  sortRowNodes(rowNodes) {
    const sortOptions = this.sortController.getSortOptions();
    const noSort = !sortOptions || sortOptions.length == 0;
    if (noSort)
      return rowNodes;
    return this.rowNodeSorter.doFullSort(rowNodes, sortOptions);
  }
};
__decorateClass4([
  Autowired13("rowModel")
], _ChartDatasource.prototype, "gridRowModel", 2);
__decorateClass4([
  Autowired13("valueService")
], _ChartDatasource.prototype, "valueService", 2);
__decorateClass4([
  Autowired13("columnModel")
], _ChartDatasource.prototype, "columnModel", 2);
__decorateClass4([
  Autowired13("rowNodeSorter")
], _ChartDatasource.prototype, "rowNodeSorter", 2);
__decorateClass4([
  Autowired13("sortController")
], _ChartDatasource.prototype, "sortController", 2);
__decorateClass4([
  Optional("aggregationStage")
], _ChartDatasource.prototype, "aggregationStage", 2);
var ChartDatasource = _ChartDatasource;
var ChartColumnService = class extends BeanStub24 {
  constructor() {
    super(...arguments);
    this.valueColsWithoutSeriesType = /* @__PURE__ */ new Set();
  }
  postConstruct() {
    const clearValueCols = () => this.valueColsWithoutSeriesType.clear();
    this.addManagedListener(this.eventService, Events9.EVENT_NEW_COLUMNS_LOADED, clearValueCols);
    this.addManagedListener(this.eventService, Events9.EVENT_ROW_DATA_UPDATED, clearValueCols);
  }
  getColumn(colId) {
    return this.columnModel.getPrimaryColumn(colId);
  }
  getAllDisplayedColumns() {
    return this.columnModel.getAllDisplayedColumns();
  }
  getColDisplayName(col) {
    return this.columnModel.getDisplayNameForColumn(col, "chart");
  }
  getRowGroupColumns() {
    return this.columnModel.getRowGroupColumns();
  }
  getGroupDisplayColumns() {
    return this.columnModel.getGroupDisplayColumns();
  }
  isPivotMode() {
    return this.columnModel.isPivotMode();
  }
  isPivotActive() {
    return this.columnModel.isPivotActive();
  }
  getChartColumns() {
    const gridCols = this.columnModel.getAllGridColumns();
    const dimensionCols = /* @__PURE__ */ new Set();
    const valueCols = /* @__PURE__ */ new Set();
    gridCols.forEach((col) => {
      const colDef = col.getColDef();
      const chartDataType = colDef.chartDataType;
      if (chartDataType) {
        switch (chartDataType) {
          case "category":
          case "time":
            dimensionCols.add(col);
            return;
          case "series":
            valueCols.add(col);
            return;
          case "excluded":
            return;
          default:
            console.warn(`AG Grid: unexpected chartDataType value '${chartDataType}' supplied, instead use 'category', 'series' or 'excluded'`);
            break;
        }
      }
      if (colDef.colId === "ag-Grid-AutoColumn") {
        dimensionCols.add(col);
        return;
      }
      if (!col.isPrimary()) {
        valueCols.add(col);
        return;
      }
      (this.isInferredValueCol(col) ? valueCols : dimensionCols).add(col);
    });
    return { dimensionCols, valueCols };
  }
  isInferredValueCol(col) {
    const colId = col.getColId();
    if (colId === "ag-Grid-AutoColumn") {
      return false;
    }
    const row = this.rowPositionUtils.getRowNode({ rowIndex: 0, rowPinned: null });
    if (!row) {
      return this.valueColsWithoutSeriesType.has(colId);
    }
    let cellValue = this.valueService.getValue(col, row);
    if (cellValue == null) {
      cellValue = this.extractLeafData(row, col);
    }
    if (cellValue != null && typeof cellValue.toNumber === "function") {
      cellValue = cellValue.toNumber();
    }
    const isNumber5 = typeof cellValue === "number";
    if (isNumber5) {
      this.valueColsWithoutSeriesType.add(colId);
    }
    return isNumber5;
  }
  extractLeafData(row, col) {
    if (!row.allLeafChildren) {
      return null;
    }
    for (let i = 0; i < row.allLeafChildren.length; i++) {
      const childRow = row.allLeafChildren[i];
      const value = this.valueService.getValue(col, childRow);
      if (value != null) {
        return value;
      }
    }
    return null;
  }
  destroy() {
    this.valueColsWithoutSeriesType.clear();
    super.destroy();
  }
};
__decorateClass4([
  Autowired24("columnModel")
], ChartColumnService.prototype, "columnModel", 2);
__decorateClass4([
  Autowired24("valueService")
], ChartColumnService.prototype, "valueService", 2);
__decorateClass4([
  Autowired24("rowPositionUtils")
], ChartColumnService.prototype, "rowPositionUtils", 2);
__decorateClass4([
  PostConstruct13
], ChartColumnService.prototype, "postConstruct", 1);
ChartColumnService = __decorateClass4([
  Bean4("chartColumnService")
], ChartColumnService);
var _ComboChartModel = class _ComboChartModel2 extends BeanStub32 {
  constructor(chartDataModel) {
    var _a;
    super();
    this.suppressComboChartWarnings = false;
    this.chartDataModel = chartDataModel;
    this.seriesChartTypes = (_a = chartDataModel.params.seriesChartTypes) != null ? _a : [];
  }
  init() {
    this.initComboCharts();
  }
  update(seriesChartTypes) {
    this.seriesChartTypes = seriesChartTypes != null ? seriesChartTypes : this.seriesChartTypes;
    this.initComboCharts();
    this.updateSeriesChartTypes();
  }
  initComboCharts() {
    const seriesChartTypesExist = this.seriesChartTypes && this.seriesChartTypes.length > 0;
    const customCombo = this.chartDataModel.chartType === "customCombo" || seriesChartTypesExist;
    if (customCombo) {
      this.chartDataModel.chartType = "customCombo";
      this.savedCustomSeriesChartTypes = this.seriesChartTypes || [];
    }
  }
  updateSeriesChartTypes() {
    if (!this.chartDataModel.isComboChart()) {
      return;
    }
    this.seriesChartTypes = this.seriesChartTypes.map((seriesChartType) => {
      const primaryOnly = ["groupedColumn", "stackedColumn", "stackedArea"].includes(seriesChartType.chartType);
      seriesChartType.secondaryAxis = primaryOnly ? false : seriesChartType.secondaryAxis;
      return seriesChartType;
    });
    if (this.chartDataModel.chartType === "customCombo") {
      this.updateSeriesChartTypesForCustomCombo();
      return;
    }
    this.updateChartSeriesTypesForBuiltInCombos();
  }
  updateSeriesChartTypesForCustomCombo() {
    const seriesChartTypesSupplied = this.seriesChartTypes && this.seriesChartTypes.length > 0;
    if (!seriesChartTypesSupplied && !this.suppressComboChartWarnings) {
      console.warn(`AG Grid: 'seriesChartTypes' are required when the 'customCombo' chart type is specified.`);
    }
    this.seriesChartTypes = this.seriesChartTypes.map((s) => {
      if (!_ComboChartModel2.SUPPORTED_COMBO_CHART_TYPES.includes(s.chartType)) {
        console.warn(`AG Grid: invalid chartType '${s.chartType}' supplied in 'seriesChartTypes', converting to 'line' instead.`);
        s.chartType = "line";
      }
      return s;
    });
    const getSeriesChartType = (valueCol) => {
      if (!this.savedCustomSeriesChartTypes || this.savedCustomSeriesChartTypes.length === 0) {
        this.savedCustomSeriesChartTypes = this.seriesChartTypes;
      }
      const providedSeriesChartType = this.savedCustomSeriesChartTypes.find((s) => s.colId === valueCol.colId);
      if (!providedSeriesChartType) {
        if (valueCol.selected && !this.suppressComboChartWarnings) {
          console.warn(`AG Grid: no 'seriesChartType' found for colId = '${valueCol.colId}', defaulting to 'line'.`);
        }
        return {
          colId: valueCol.colId,
          chartType: "line",
          secondaryAxis: false
        };
      }
      return providedSeriesChartType;
    };
    const updatedSeriesChartTypes = this.chartDataModel.valueColState.map(getSeriesChartType);
    this.seriesChartTypes = updatedSeriesChartTypes;
    this.savedCustomSeriesChartTypes = updatedSeriesChartTypes;
    this.suppressComboChartWarnings = true;
  }
  updateChartSeriesTypesForBuiltInCombos() {
    const { chartType, valueColState } = this.chartDataModel;
    let primaryChartType = chartType === "columnLineCombo" ? "groupedColumn" : "stackedArea";
    let secondaryChartType = chartType === "columnLineCombo" ? "line" : "groupedColumn";
    const selectedCols = valueColState.filter((cs) => cs.selected);
    const lineIndex = Math.ceil(selectedCols.length / 2);
    this.seriesChartTypes = selectedCols.map((valueCol, i) => {
      const seriesType = i >= lineIndex ? secondaryChartType : primaryChartType;
      return { colId: valueCol.colId, chartType: seriesType, secondaryAxis: false };
    });
  }
};
_ComboChartModel.SUPPORTED_COMBO_CHART_TYPES = ["line", "groupedColumn", "stackedColumn", "area", "stackedArea"];
__decorateClass4([
  PostConstruct24
], _ComboChartModel.prototype, "init", 1);
var ComboChartModel = _ComboChartModel;
var SERIES_TYPES = {
  "area": {
    isCartesian: true,
    canInvert: true
  },
  "bar": {
    isCartesian: true,
    canInvert: true
  },
  "histogram": {
    isCartesian: true
  },
  "line": {
    isCartesian: true,
    canInvert: true
  },
  "pie": {
    isPie: true
  },
  "donut": {
    isPie: true,
    canInvert: true
  },
  "scatter": {
    isCartesian: true
  },
  "bubble": {
    isCartesian: true
  },
  "radial-column": {
    isPolar: true,
    isEnterprise: true,
    isRadial: true,
    canInvert: true
  },
  "radial-bar": {
    isPolar: true,
    isEnterprise: true,
    isRadial: true,
    canInvert: true
  },
  "radar-line": {
    isPolar: true,
    isEnterprise: true,
    canInvert: true
  },
  "radar-area": {
    isPolar: true,
    isEnterprise: true,
    canInvert: true
  },
  "nightingale": {
    isPolar: true,
    isEnterprise: true,
    canInvert: true
  },
  "range-bar": {
    isCartesian: true,
    isEnterprise: true,
    canSwitchDirection: true
  },
  "range-area": {
    isCartesian: true,
    isEnterprise: true
  },
  "box-plot": {
    isCartesian: true,
    isEnterprise: true,
    canSwitchDirection: true
  },
  "treemap": {
    isEnterprise: true,
    isHierarchical: true
  },
  "sunburst": {
    isEnterprise: true,
    isHierarchical: true
  },
  "heatmap": {
    isCartesian: true,
    isEnterprise: true
  },
  "waterfall": {
    isCartesian: true,
    isEnterprise: true,
    canSwitchDirection: true
  }
};
function isSeriesType(seriesType) {
  return !!SERIES_TYPES[seriesType];
}
function isComboChart(chartType) {
  return ChartMappings.COMBO_CHART_TYPES.includes(chartType);
}
function doesSeriesHaveProperty(seriesType, prop) {
  var _a;
  return !!((_a = SERIES_TYPES[seriesType]) == null ? void 0 : _a[prop]);
}
function isEnterpriseChartType(chartType) {
  return doesSeriesHaveProperty(getSeriesType(chartType), "isEnterprise");
}
var stackedChartTypes = /* @__PURE__ */ new Set(["stackedColumn", "normalizedColumn", "stackedBar", "normalizedBar"]);
function isStacked(chartType) {
  return stackedChartTypes.has(chartType);
}
function isCartesian(seriesType) {
  return doesSeriesHaveProperty(seriesType, "isCartesian");
}
function isPolar(seriesType) {
  return doesSeriesHaveProperty(seriesType, "isPolar");
}
function isRadial(seriesType) {
  return doesSeriesHaveProperty(seriesType, "isRadial");
}
function isHierarchical(seriesType) {
  return doesSeriesHaveProperty(seriesType, "isHierarchical");
}
function getCanonicalChartType(chartType) {
  return chartType === "doughnut" ? "donut" : chartType;
}
function getSeriesTypeIfExists(chartType) {
  return ChartMappings.CHART_TYPE_TO_SERIES_TYPE[chartType];
}
function getSeriesType(chartType) {
  var _a;
  return (_a = getSeriesTypeIfExists(chartType)) != null ? _a : "line";
}
function isPieChartSeries(seriesType) {
  return doesSeriesHaveProperty(seriesType, "isPie");
}
function canOnlyHaveSingleSeries(chartType) {
  return chartType === "pie" || chartType === "waterfall" || chartType === "histogram";
}
function getMaxNumCategories(chartType) {
  return isHierarchical(getSeriesType(chartType)) ? void 0 : 1;
}
function getMaxNumSeries(chartType) {
  if (isHierarchical(getSeriesType(chartType))) {
    return 2;
  } else if (canOnlyHaveSingleSeries(chartType)) {
    return 1;
  } else {
    return void 0;
  }
}
function supportsInvertedCategorySeries(chartType) {
  return doesSeriesHaveProperty(getSeriesType(chartType), "canInvert");
}
function canSwitchDirection(chartType) {
  return doesSeriesHaveProperty(getSeriesType(chartType), "canSwitchDirection");
}
var _ChartDataModel = class _ChartDataModel2 extends BeanStub42 {
  constructor(params) {
    super();
    this.unlinked = false;
    this.chartData = [];
    this.valueColState = [];
    this.dimensionColState = [];
    this.columnNames = {};
    this.crossFiltering = false;
    this.grouping = false;
    this.params = params;
    this.chartId = params.chartId;
    this.setParams(params);
  }
  setParams(params) {
    const {
      chartType,
      pivotChart,
      chartThemeName,
      switchCategorySeries,
      aggFunc,
      cellRange,
      suppressChartRanges,
      unlinkChart,
      crossFiltering,
      seriesGroupType
    } = params;
    this.chartType = chartType;
    this.pivotChart = pivotChart != null ? pivotChart : false;
    this.chartThemeName = chartThemeName;
    this.switchCategorySeries = !!switchCategorySeries;
    this.aggFunc = aggFunc;
    this.referenceCellRange = cellRange;
    this.suppliedCellRange = cellRange;
    this.suppressChartRanges = suppressChartRanges != null ? suppressChartRanges : false;
    this.unlinked = !!unlinkChart;
    this.crossFiltering = !!crossFiltering;
    this.seriesGroupType = seriesGroupType;
  }
  init() {
    this.datasource = this.createManagedBean(new ChartDatasource());
    this.chartColumnService = this.createManagedBean(new ChartColumnService());
    this.comboChartModel = this.createManagedBean(new ComboChartModel(this));
    this.updateCellRanges({ setColsFromRange: true });
    this.updateData();
  }
  updateModel(params) {
    const { cellRange, seriesChartTypes } = params;
    if (cellRange !== this.suppliedCellRange) {
      this.dimensionCellRange = void 0;
      this.valueCellRange = void 0;
    }
    this.setParams(params);
    this.updateSelectedDimensions(cellRange == null ? void 0 : cellRange.columns);
    this.updateCellRanges({ setColsFromRange: true });
    const shouldUpdateComboModel = this.isComboChart() || seriesChartTypes;
    if (shouldUpdateComboModel) {
      this.comboChartModel.update(seriesChartTypes);
    }
    if (!this.unlinked) {
      this.updateData();
    }
  }
  updateCellRanges(params) {
    const { updatedColState, resetOrder, maintainColState, setColsFromRange } = params != null ? params : {};
    if (this.valueCellRange) {
      this.referenceCellRange = this.valueCellRange;
    }
    const { dimensionCols, valueCols } = this.chartColumnService.getChartColumns();
    const allColsFromRanges = this.getAllColumnsFromRanges();
    if (updatedColState) {
      this.updateColumnState(updatedColState, resetOrder);
    }
    this.setDimensionCellRange(dimensionCols, allColsFromRanges, updatedColState);
    this.setValueCellRange(valueCols, allColsFromRanges, setColsFromRange);
    if (!updatedColState && !maintainColState) {
      this.resetColumnState();
      this.syncDimensionCellRange();
    }
    this.comboChartModel.updateSeriesChartTypes();
  }
  updateData() {
    const { startRow, endRow } = this.getRowIndexes();
    if (this.pivotChart) {
      this.resetColumnState();
    }
    this.grouping = this.isGrouping();
    const params = {
      aggFunc: this.aggFunc,
      dimensionCols: this.getSelectedDimensions(),
      grouping: this.grouping,
      pivoting: this.isPivotActive(),
      crossFiltering: this.crossFiltering,
      valueCols: this.getSelectedValueCols(),
      startRow,
      endRow,
      isScatter: _24.includes(["scatter", "bubble"], this.chartType)
    };
    const { chartData, columnNames, groupChartData } = this.datasource.getData(params);
    this.chartData = chartData;
    this.groupChartData = groupChartData;
    this.columnNames = columnNames;
    this.categoryAxisType = void 0;
  }
  isGrouping() {
    const usingTreeData = this.gos.get("treeData");
    const groupedCols = usingTreeData ? null : this.chartColumnService.getRowGroupColumns();
    const isGroupActive = usingTreeData || groupedCols && groupedCols.length > 0;
    const colIds = this.getSelectedDimensions().map(({ colId }) => colId);
    const displayedGroupCols = this.chartColumnService.getGroupDisplayColumns();
    const groupDimensionSelected = displayedGroupCols.map((col) => col.getColId()).some((id) => colIds.includes(id));
    return !!isGroupActive && groupDimensionSelected;
  }
  getSelectedValueCols() {
    return this.valueColState.filter((cs) => cs.selected).map((cs) => cs.column);
  }
  getSelectedDimensions() {
    return this.dimensionColState.filter((cs) => cs.selected);
  }
  getColDisplayName(col) {
    return this.chartColumnService.getColDisplayName(col);
  }
  isPivotMode() {
    return this.chartColumnService.isPivotMode();
  }
  getChartDataType(colId) {
    const column = this.chartColumnService.getColumn(colId);
    return column ? column.getColDef().chartDataType : void 0;
  }
  isPivotActive() {
    return this.chartColumnService.isPivotActive();
  }
  createCellRange(type, ...columns) {
    return {
      id: this.chartId,
      // set range ID to match chart ID so we can identify changes to the ranges for this chart
      startRow: this.referenceCellRange.startRow,
      endRow: this.referenceCellRange.endRow,
      columns,
      startColumn: type === CellRangeType2.DIMENSION || this.referenceCellRange.startColumn == null ? columns[0] : this.referenceCellRange.startColumn,
      type
    };
  }
  getAllColumnsFromRanges() {
    if (this.pivotChart) {
      return _24.convertToSet(this.chartColumnService.getAllDisplayedColumns());
    }
    const columns = this.dimensionCellRange || this.valueCellRange ? [] : this.referenceCellRange.columns;
    if (this.dimensionCellRange) {
      columns.push(...this.dimensionCellRange.columns);
    }
    if (this.valueCellRange) {
      columns.push(...this.valueCellRange.columns);
    }
    return _24.convertToSet(columns);
  }
  getRowIndexes() {
    let startRow = 0, endRow = 0;
    const { rangeService, valueCellRange, dimensionCellRange } = this;
    const cellRange = valueCellRange || dimensionCellRange;
    if (rangeService && cellRange) {
      startRow = rangeService.getRangeStartRow(cellRange).rowIndex;
      const endRowPosition = rangeService.getRangeEndRow(cellRange);
      endRow = endRowPosition.rowPinned === "bottom" ? -1 : endRowPosition.rowIndex;
    }
    return { startRow, endRow };
  }
  resetColumnState() {
    const { dimensionCols, valueCols } = this.chartColumnService.getChartColumns();
    const allCols = this.getAllColumnsFromRanges();
    const isInitialising = this.valueColState.length < 1;
    this.dimensionColState = [];
    this.valueColState = [];
    const supportsMultipleDimensions = isHierarchical(getSeriesType(this.chartType));
    let hasSelectedDimension = false;
    let order = 1;
    const aggFuncDimension = this.suppliedCellRange.columns[0];
    dimensionCols.forEach((column) => {
      const isAutoGroupCol = column.getColId() === "ag-Grid-AutoColumn";
      let selected = false;
      if (this.crossFiltering && this.aggFunc) {
        if (aggFuncDimension.getColId() === column.getColId()) {
          selected = true;
        }
      } else {
        selected = isAutoGroupCol ? true : (!hasSelectedDimension || supportsMultipleDimensions) && allCols.has(column);
      }
      this.dimensionColState.push({
        column,
        colId: column.getColId(),
        displayName: this.getColDisplayName(column),
        selected,
        order: order++
      });
      if (selected) {
        hasSelectedDimension = true;
      }
    });
    const defaultCategory = {
      colId: _ChartDataModel2.DEFAULT_CATEGORY,
      displayName: this.chartTranslationService.translate("defaultCategory"),
      selected: !hasSelectedDimension,
      // if no dimensions in range select the default
      order: 0
    };
    this.dimensionColState.unshift(defaultCategory);
    const valueColumnsFromReferenceRange = this.referenceCellRange.columns.filter((c) => valueCols.has(c));
    valueCols.forEach((column) => {
      if (isInitialising && _24.includes(this.referenceCellRange.columns, column)) {
        column = valueColumnsFromReferenceRange.shift();
      }
      this.valueColState.push({
        column,
        colId: column.getColId(),
        displayName: this.getColDisplayName(column),
        selected: allCols.has(column),
        order: order++
      });
    });
  }
  updateColumnState(updatedCol, resetOrder) {
    const idsMatch = (cs) => cs.colId === updatedCol.colId;
    const { dimensionColState, valueColState } = this;
    const matchedDimensionColState = dimensionColState.find(idsMatch);
    const matchedValueColState = valueColState.find(idsMatch);
    if (matchedDimensionColState) {
      const supportsMultipleDimensions = isHierarchical(getSeriesType(this.chartType));
      if (!supportsMultipleDimensions) {
        const selectedColumnState = updatedCol.selected ? matchedDimensionColState : dimensionColState.filter((cs) => cs !== matchedDimensionColState).find(({ selected }) => selected);
        dimensionColState.forEach((cs) => cs.selected = cs === selectedColumnState);
      } else {
        matchedDimensionColState.selected = updatedCol.selected;
      }
    } else if (matchedValueColState) {
      matchedValueColState.selected = updatedCol.selected;
    }
    const allColumns = [...dimensionColState, ...valueColState];
    const orderedColIds = [];
    if (!resetOrder) {
      allColumns.forEach((col, i) => {
        if (i === updatedCol.order) {
          orderedColIds.push(updatedCol.colId);
        }
        if (col.colId !== updatedCol.colId) {
          orderedColIds.push(col.colId);
        }
      });
      allColumns.forEach((col) => {
        const order = orderedColIds.indexOf(col.colId);
        col.order = order >= 0 ? orderedColIds.indexOf(col.colId) : allColumns.length - 1;
      });
    }
    this.reorderColState();
  }
  reorderColState() {
    const ascColStateOrder = (a, b) => a.order - b.order;
    this.dimensionColState.sort(ascColStateOrder);
    this.valueColState.sort(ascColStateOrder);
  }
  setDimensionCellRange(dimensionCols, colsInRange, updatedColState) {
    this.dimensionCellRange = void 0;
    const supportsMultipleDimensions = isHierarchical(getSeriesType(this.chartType));
    if (!updatedColState && !this.dimensionColState.length) {
      const selectedCols = new Array();
      dimensionCols.forEach((col) => {
        if (selectedCols.length > 0 && !supportsMultipleDimensions || !colsInRange.has(col)) {
          return;
        }
        selectedCols.push(col);
      });
      if (selectedCols.length > 0) {
        this.dimensionCellRange = this.createCellRange(CellRangeType2.DIMENSION, ...selectedCols);
      }
      return;
    }
    let selectedDimensionColStates = updatedColState ? [updatedColState] : [];
    if (this.crossFiltering && this.aggFunc) {
      const aggFuncDimension = this.suppliedCellRange.columns[0];
      selectedDimensionColStates = this.dimensionColState.filter((cs) => cs.colId === aggFuncDimension.getColId());
    } else if (supportsMultipleDimensions || selectedDimensionColStates.length === 0 || selectedDimensionColStates.some(({ column }) => !column || !dimensionCols.has(column))) {
      selectedDimensionColStates = this.dimensionColState.filter((cs) => cs.selected);
    }
    const isDefaultCategory = selectedDimensionColStates.length === 1 ? selectedDimensionColStates[0].colId === _ChartDataModel2.DEFAULT_CATEGORY : false;
    const selectedColumns = selectedDimensionColStates.map(({ column }) => column).filter((value) => value != null);
    if (selectedColumns.length > 0 && !isDefaultCategory) {
      this.dimensionCellRange = this.createCellRange(CellRangeType2.DIMENSION, ...selectedColumns);
    }
  }
  setValueCellRange(valueCols, colsInRange, setColsFromRange) {
    this.valueCellRange = void 0;
    const selectedValueCols = [];
    const maxSelection = getMaxNumSeries(this.chartType);
    let numSelected = 0;
    valueCols.forEach((col) => {
      if (setColsFromRange) {
        if ((maxSelection == null || numSelected < maxSelection) && colsInRange.has(col)) {
          selectedValueCols.push(col);
          numSelected++;
        }
      } else {
        if (this.valueColState.some((colState) => colState.selected && colState.colId === col.getColId())) {
          selectedValueCols.push(col);
        }
      }
    });
    if (selectedValueCols.length > 0) {
      let orderedColIds = [];
      if (this.valueColState.length > 0) {
        orderedColIds = this.valueColState.map((c) => c.colId);
      } else {
        colsInRange.forEach((c) => orderedColIds.push(c.getColId()));
      }
      selectedValueCols.sort((a, b) => orderedColIds.indexOf(a.getColId()) - orderedColIds.indexOf(b.getColId()));
      this.valueCellRange = this.createCellRange(CellRangeType2.VALUE, ...selectedValueCols);
    }
  }
  resetCellRanges(dimension, value) {
    if (!dimension && !value) {
      return;
    }
    const { dimensionCols, valueCols } = this.chartColumnService.getChartColumns();
    const allColsFromRanges = this.getAllColumnsFromRanges();
    if (dimension) {
      this.setDimensionCellRange(dimensionCols, allColsFromRanges);
    }
    if (value) {
      this.setValueCellRange(valueCols, allColsFromRanges);
    }
  }
  updateSelectedDimensions(columns) {
    const colIdSet = new Set(columns.map((column) => column.getColId()));
    const supportsMultipleDimensions = isHierarchical(getSeriesType(this.chartType));
    if (!supportsMultipleDimensions) {
      const foundColState = this.dimensionColState.find((colState) => colIdSet.has(colState.colId)) || this.dimensionColState[0];
      const selectedColumnId = foundColState.colId;
      this.dimensionColState = this.dimensionColState.map((colState) => __spreadProps3(__spreadValues3({}, colState), {
        selected: colState.colId === selectedColumnId
      }));
    } else {
      const foundColStates = this.dimensionColState.filter((colState) => colIdSet.has(colState.colId));
      const selectedColumnIds = new Set(foundColStates.map((colState) => colState.colId));
      this.dimensionColState = this.dimensionColState.map((colState) => __spreadProps3(__spreadValues3({}, colState), {
        selected: selectedColumnIds.has(colState.colId)
      }));
    }
  }
  syncDimensionCellRange() {
    const selectedDimensions = this.getSelectedDimensions();
    if (selectedDimensions.length === 0)
      return;
    const selectedCols = selectedDimensions.map(({ column }) => column).filter((value) => value != null);
    if (selectedCols.length > 0) {
      this.dimensionCellRange = this.createCellRange(CellRangeType2.DIMENSION, ...selectedCols);
    }
  }
  isComboChart(chartType) {
    return isComboChart(chartType != null ? chartType : this.chartType);
  }
};
_ChartDataModel.DEFAULT_CATEGORY = "AG-GRID-DEFAULT-CATEGORY";
__decorateClass4([
  Autowired34("rangeService")
], _ChartDataModel.prototype, "rangeService", 2);
__decorateClass4([
  Autowired34("chartTranslationService")
], _ChartDataModel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct32
], _ChartDataModel.prototype, "init", 1);
var ChartDataModel = _ChartDataModel;
var ALL_AXIS_TYPES = ["number", "category", "grouped-category", "log", "time"];
function getLegacyAxisType(chartType) {
  switch (chartType) {
    case "bar":
    case "stackedBar":
    case "normalizedBar":
      return ["number", "category"];
    case "groupedBar":
      return ["number", "grouped-category"];
    case "column":
    case "stackedColumn":
    case "normalizedColumn":
    case "line":
    case "area":
    case "stackedArea":
    case "normalizedArea":
    case "histogram":
      return ["category", "number"];
    case "groupedColumn":
      return ["grouped-category", "number"];
    case "scatter":
    case "bubble":
      return ["number", "number"];
    default:
      return void 0;
  }
}
function emptyTarget(value) {
  return Array.isArray(value) ? [] : {};
}
function cloneUnlessOtherwiseSpecified(value, options) {
  return options.clone !== false && options.isMergeableObject(value) ? deepMerge(emptyTarget(value), value, options) : value;
}
function defaultArrayMerge(target, source, options) {
  return target.concat(source).map(function(element) {
    return cloneUnlessOtherwiseSpecified(element, options);
  });
}
function getMergeFunction(key, options) {
  if (!options.customMerge) {
    return deepMerge;
  }
  const customMerge = options.customMerge(key);
  return typeof customMerge === "function" ? customMerge : deepMerge;
}
function getEnumerableOwnPropertySymbols(target) {
  return Object.getOwnPropertySymbols ? Object.getOwnPropertySymbols(target).filter(function(symbol) {
    return target.propertyIsEnumerable(symbol);
  }) : [];
}
function getKeys(target) {
  return Object.keys(target).concat(getEnumerableOwnPropertySymbols(target));
}
function propertyIsOnObject(object, property) {
  try {
    return property in object;
  } catch (_382) {
    return false;
  }
}
function propertyIsUnsafe(target, key) {
  return propertyIsOnObject(target, key) && !(Object.hasOwnProperty.call(target, key) && Object.propertyIsEnumerable.call(target, key));
}
function mergeObject(target = {}, source = {}, options) {
  const destination = {};
  if (options.isMergeableObject(target)) {
    getKeys(target).forEach(function(key) {
      destination[key] = cloneUnlessOtherwiseSpecified(target[key], options);
    });
  }
  getKeys(source).forEach(function(key) {
    if (propertyIsUnsafe(target, key)) {
      return;
    }
    if (propertyIsOnObject(target, key) && options.isMergeableObject(source[key])) {
      destination[key] = getMergeFunction(key, options)(target[key], source[key], options);
    } else {
      destination[key] = cloneUnlessOtherwiseSpecified(source[key], options);
    }
  });
  return destination;
}
function defaultIsMergeableObject(value) {
  return isNonNullObject(value) && !isSpecial(value);
}
function isNonNullObject(value) {
  return !!value && typeof value === "object";
}
function isSpecial(value) {
  const stringValue = Object.prototype.toString.call(value);
  return stringValue === "[object RegExp]" || stringValue === "[object Date]";
}
function deepMerge(target, source, options) {
  options = options || {};
  options.arrayMerge = options.arrayMerge || defaultArrayMerge;
  options.isMergeableObject = options.isMergeableObject || defaultIsMergeableObject;
  options.cloneUnlessOtherwiseSpecified = cloneUnlessOtherwiseSpecified;
  const sourceIsArray = Array.isArray(source);
  const targetIsArray = Array.isArray(target);
  const sourceAndTargetTypesMatch = sourceIsArray === targetIsArray;
  if (!sourceAndTargetTypesMatch) {
    return cloneUnlessOtherwiseSpecified(source, options);
  } else if (sourceIsArray) {
    return options.arrayMerge(target, source, options);
  } else {
    return mergeObject(target, source, options);
  }
}
function get(source, expression, defaultValue) {
  if (source == null) {
    return defaultValue;
  }
  const keys = expression.split(".");
  let objectToRead = source;
  while (keys.length > 1) {
    objectToRead = objectToRead[keys.shift()];
    if (objectToRead == null) {
      return defaultValue;
    }
  }
  const value = objectToRead[keys[0]];
  return value != null ? value : defaultValue;
}
function set(target, expression, value) {
  if (target == null) {
    return;
  }
  const keys = expression.split(".");
  let objectToUpdate = target;
  keys.forEach((key, i) => {
    if (!objectToUpdate[key]) {
      objectToUpdate[key] = {};
    }
    if (i < keys.length - 1) {
      objectToUpdate = objectToUpdate[key];
    }
  });
  objectToUpdate[keys[keys.length - 1]] = value;
}
function createAgChartTheme(chartProxyParams, proxy, isEnterprise, chartThemeDefaults, updatedOverrides) {
  var _a;
  const { chartOptionsToRestore, chartPaletteToRestore, chartThemeToRestore } = chartProxyParams;
  const themeName = getSelectedTheme(chartProxyParams);
  const stockTheme = isStockTheme(themeName);
  const rootTheme = stockTheme ? { baseTheme: themeName } : (_a = lookupCustomChartTheme(chartProxyParams, themeName)) != null ? _a : {};
  const gridOptionsThemeOverrides = chartProxyParams.getGridOptionsChartThemeOverrides();
  const apiThemeOverrides = chartProxyParams.apiChartThemeOverrides;
  const standaloneChartType = getSeriesType(chartProxyParams.chartType);
  const crossFilteringOverrides = chartProxyParams.crossFiltering ? createCrossFilterThemeOverrides(proxy, chartProxyParams, standaloneChartType) : void 0;
  const isTitleEnabled = () => {
    const isTitleEnabled2 = (obj) => {
      if (!obj) {
        return false;
      }
      return Object.keys(obj).some((key) => get(obj[key], "title.enabled", false));
    };
    return isTitleEnabled2(gridOptionsThemeOverrides) || isTitleEnabled2(apiThemeOverrides);
  };
  const overrides = [
    stockTheme ? inbuiltStockThemeOverrides(chartProxyParams, isEnterprise, isTitleEnabled()) : void 0,
    chartThemeDefaults,
    crossFilteringOverrides,
    gridOptionsThemeOverrides,
    apiThemeOverrides,
    __spreadValues3({}, chartOptionsToRestore != null ? chartOptionsToRestore : {}),
    updatedOverrides
  ];
  const theme = overrides.filter((v) => !!v).reduce(
    (r, n) => ({
      baseTheme: r,
      overrides: n
    }),
    rootTheme
  );
  if (chartPaletteToRestore && themeName === chartThemeToRestore) {
    const rootThemePalette = _Theme.getChartTheme(rootTheme).palette;
    if (!isIdenticalPalette(chartPaletteToRestore, rootThemePalette)) {
      theme.palette = chartPaletteToRestore;
    }
  }
  return theme;
}
function isIdenticalPalette(paletteA, paletteB) {
  const arrayCompare = (arrA, arrB) => {
    if (arrA.length !== arrB.length)
      return false;
    return arrA.every((v, i) => v === arrB[i]);
  };
  return arrayCompare(paletteA.fills, paletteB.fills) && arrayCompare(paletteA.strokes, paletteB.strokes);
}
function isStockTheme(themeName) {
  return _34.includes(Object.keys(_Theme.themes), themeName);
}
function createCrossFilterThemeOverrides(proxy, chartProxyParams, seriesType) {
  const legend = {
    listeners: {
      legendItemClick: (e) => {
        const chart = proxy.getChart();
        chart.series.forEach((s) => {
          s.toggleSeriesItem(e.itemId, e.enabled);
          s.toggleSeriesItem(`${e.itemId}-filtered-out`, e.enabled);
        });
      }
    }
  };
  return {
    [seriesType]: {
      tooltip: {
        delay: 500
      },
      legend,
      listeners: {
        click: (e) => chartProxyParams.crossFilterCallback(e, true)
      }
    }
  };
}
var STATIC_INBUILT_STOCK_THEME_AXES_OVERRIDES = ALL_AXIS_TYPES.reduce(
  (r, n) => __spreadProps3(__spreadValues3({}, r), { [n]: { title: { _enabledFromTheme: true } } }),
  {}
);
function inbuiltStockThemeOverrides(params, isEnterprise, titleEnabled) {
  const extraPadding = params.getExtraPaddingDirections();
  return {
    common: __spreadProps3(__spreadValues3({}, isEnterprise ? { animation: { duration: 500 } } : void 0), {
      axes: STATIC_INBUILT_STOCK_THEME_AXES_OVERRIDES,
      padding: {
        // don't add extra padding when a title is present!
        top: !titleEnabled && extraPadding.includes("top") ? 40 : 20,
        right: extraPadding.includes("right") ? 30 : 20,
        bottom: extraPadding.includes("bottom") ? 40 : 20,
        left: extraPadding.includes("left") ? 30 : 20
      }
    }),
    pie: {
      series: {
        title: { _enabledFromTheme: true },
        calloutLabel: { _enabledFromTheme: true },
        sectorLabel: {
          enabled: false,
          _enabledFromTheme: true
        }
      }
    },
    donut: {
      series: {
        title: { _enabledFromTheme: true },
        calloutLabel: { _enabledFromTheme: true },
        sectorLabel: {
          enabled: false,
          _enabledFromTheme: true
        }
      }
    }
  };
}
function getSelectedTheme(chartProxyParams) {
  let chartThemeName = chartProxyParams.getChartThemeName();
  const availableThemes = chartProxyParams.getChartThemes();
  if (!_34.includes(availableThemes, chartThemeName)) {
    chartThemeName = availableThemes[0];
  }
  return chartThemeName;
}
function lookupCustomChartTheme(chartProxyParams, name) {
  const { customChartThemes } = chartProxyParams;
  const customChartTheme = customChartThemes && customChartThemes[name];
  if (!customChartTheme) {
    console.warn(
      `AG Grid: no stock theme exists with the name '${name}' and no custom chart theme with that name was supplied to 'customChartThemes'`
    );
  }
  return customChartTheme;
}
var validateIfDefined = (validationFn) => {
  return (value) => {
    if (value == void 0)
      return true;
    return validationFn(value);
  };
};
var isString = (value) => typeof value === "string";
var isBoolean = (value) => typeof value === "boolean";
var isValidSeriesChartType = (value) => typeof value === "object";
var createWarnMessage = (property, expectedType) => (value) => `AG Grid - unable to update chart as invalid params supplied:  \`${property}: ${value}\`, expected ${expectedType}.`;
var createEnterpriseMessage = (feature) => {
  const url = "https://www.ag-grid.com/javascript-data-grid/integrated-charts-installation/";
  return `${feature} is not supported in AG Charts Community (either 'ag-grid-charts-enterprise' or '@ag-grid-enterprise/charts-enterprise' hasn't been loaded). See ${url} for more details.`;
};
var _ChartParamsValidator = class _ChartParamsValidator2 {
  static isEnterprise() {
    return _ModuleSupport.enterpriseModule.isEnterprise;
  }
  static isValidChartType(value) {
    return !!getSeriesTypeIfExists(value) || isComboChart(value);
  }
  static isLegacyChartType(value) {
    return _ChartParamsValidator2.legacyChartTypes.includes(value);
  }
  static validateUpdateParams(params) {
    let paramsToValidate = params;
    switch (paramsToValidate.type) {
      case "rangeChartUpdate":
        return _ChartParamsValidator2.validateUpdateRangeChartParams(params);
      case "pivotChartUpdate":
        return _ChartParamsValidator2.validateUpdatePivotChartParams(params);
      case "crossFilterChartUpdate":
        return _ChartParamsValidator2.validateUpdateCrossFilterChartParams(params);
      default:
        console.warn(`AG Grid - Invalid value supplied for 'type': ${params.type}. It must be either 'rangeChartUpdate', 'pivotChartUpdate', or 'crossFilterChartUpdate'.`);
        return false;
    }
  }
  static validateCreateParams(params) {
    return _ChartParamsValidator2.validateProperties(params, [
      _ChartParamsValidator2.enterpriseChartTypeValidation,
      _ChartParamsValidator2.switchCategorySeriesValidation
    ]);
  }
  static validateUpdateRangeChartParams(params) {
    const validations = [
      ..._ChartParamsValidator2.commonUpdateValidations,
      _ChartParamsValidator2.enterpriseChartTypeValidation,
      ..._ChartParamsValidator2.cellRangeValidations,
      {
        property: "seriesChartTypes",
        validationFn: (value) => value === void 0 || Array.isArray(value) && value.every(isValidSeriesChartType),
        warnMessage: createWarnMessage("seriesChartTypes", "Array of SeriesChartType")
      }
    ];
    return _ChartParamsValidator2.validateProperties(params, validations, [..._ChartParamsValidator2.baseUpdateChartParams, "cellRange", "suppressChartRanges", "switchCategorySeries", "aggFunc", "seriesChartTypes", "seriesGroupType"], "UpdateRangeChartParams");
  }
  static validateUpdatePivotChartParams(params) {
    const validations = [
      ..._ChartParamsValidator2.commonUpdateValidations
    ];
    return _ChartParamsValidator2.validateProperties(params, validations, [..._ChartParamsValidator2.baseUpdateChartParams], "UpdatePivotChartParams");
  }
  static validateUpdateCrossFilterChartParams(params) {
    const validations = [
      ..._ChartParamsValidator2.commonUpdateValidations,
      ..._ChartParamsValidator2.cellRangeValidations
    ];
    return _ChartParamsValidator2.validateProperties(params, validations, [..._ChartParamsValidator2.baseUpdateChartParams, "cellRange", "suppressChartRanges", "aggFunc"], "UpdateCrossFilterChartParams");
  }
  static validateProperties(params, validations, validPropertyNames, paramsType) {
    let validatedProperties = void 0;
    for (const validation of validations) {
      const { property, validationFn, warnMessage, warnIfFixed } = validation;
      if (property in params) {
        const value = params[property];
        const validationResult = validationFn(value);
        if (validationResult === true)
          continue;
        if (validationResult === false) {
          console.warn(warnMessage(value));
          return false;
        }
        validatedProperties = validatedProperties || __spreadValues3({}, params);
        validatedProperties[property] = validationResult;
        if (warnIfFixed) {
          console.warn(warnMessage(value));
        }
      }
    }
    if (validPropertyNames) {
      for (const property in params) {
        if (!validPropertyNames.includes(property)) {
          console.warn(`AG Grid - Unexpected property supplied. ${paramsType} does not contain: \`${property}\`.`);
          return false;
        }
      }
    }
    if (validatedProperties)
      return validatedProperties;
    return true;
  }
};
_ChartParamsValidator.legacyChartTypes = [
  "doughnut"
];
_ChartParamsValidator.baseUpdateChartParams = ["type", "chartId", "chartType", "chartThemeName", "chartThemeOverrides", "unlinkChart"];
_ChartParamsValidator.validateChartType = validateIfDefined((chartType) => {
  if (_ChartParamsValidator.isValidChartType(chartType))
    return true;
  if (_ChartParamsValidator.isLegacyChartType(chartType)) {
    const renamedChartType = getCanonicalChartType(chartType);
    _43.warnOnce(`The chart type '${chartType}' has been deprecated. Please use '${renamedChartType}' instead.`);
    return renamedChartType;
  }
  ;
  return false;
});
_ChartParamsValidator.validateAgChartThemeOverrides = validateIfDefined((themeOverrides) => {
  return typeof themeOverrides === "object";
});
_ChartParamsValidator.validateChartParamsCellRange = validateIfDefined((cellRange) => {
  return typeof cellRange === "object";
});
_ChartParamsValidator.validateAggFunc = validateIfDefined((aggFunc) => {
  return typeof aggFunc === "string" || typeof aggFunc === "function";
});
_ChartParamsValidator.enterpriseChartTypeValidation = {
  property: "chartType",
  validationFn: validateIfDefined((chartType) => _ChartParamsValidator.isEnterprise() || !chartType || !isEnterpriseChartType(chartType)),
  warnMessage: (chartType) => createEnterpriseMessage(`The '${chartType}' chart type`)
};
_ChartParamsValidator.switchCategorySeriesValidation = {
  property: "switchCategorySeries",
  validationFn: validateIfDefined((switchCategorySeries) => {
    if (!switchCategorySeries || _ChartParamsValidator.isEnterprise()) {
      return true;
    }
    return void 0;
  }),
  warnMessage: () => createEnterpriseMessage(`'switchCategorySeries' has been ignored as it`),
  warnIfFixed: true
};
_ChartParamsValidator.commonUpdateValidations = [
  { property: "chartId", validationFn: isString, warnMessage: createWarnMessage("chartId", "string") },
  {
    property: "chartType",
    validationFn: _ChartParamsValidator.validateChartType,
    warnMessage: createWarnMessage("chartType", "ChartType")
  },
  {
    property: "chartThemeName",
    validationFn: isString,
    warnMessage: createWarnMessage("chartThemeName", "string")
  },
  {
    property: "chartThemeOverrides",
    validationFn: _ChartParamsValidator.validateAgChartThemeOverrides,
    warnMessage: createWarnMessage("chartThemeOverrides", "AgChartThemeOverrides")
  },
  { property: "unlinkChart", validationFn: isBoolean, warnMessage: createWarnMessage("unlinkChart", "boolean") }
];
_ChartParamsValidator.cellRangeValidations = [
  {
    property: "cellRange",
    validationFn: _ChartParamsValidator.validateChartParamsCellRange,
    warnMessage: createWarnMessage("cellRange", "ChartParamsCellRange")
  },
  {
    property: "suppressChartRanges",
    validationFn: isBoolean,
    warnMessage: createWarnMessage("suppressChartRanges", "boolean")
  },
  {
    property: "aggFunc",
    validationFn: _ChartParamsValidator.validateAggFunc,
    warnMessage: createWarnMessage("aggFunc", "string or IAggFunc")
  },
  _ChartParamsValidator.switchCategorySeriesValidation
];
var ChartParamsValidator = _ChartParamsValidator;
var DEFAULT_THEMES = ["ag-default", "ag-material", "ag-sheets", "ag-polychroma", "ag-vivid"];
var _ChartController = class _ChartController2 extends BeanStub52 {
  constructor(model) {
    super();
    this.model = model;
    this.isEnterprise = () => _ModuleSupport2.enterpriseModule.isEnterprise;
  }
  init() {
    this.setChartRange();
    this.addManagedListener(this.eventService, Events23.EVENT_RANGE_SELECTION_CHANGED, (event) => {
      if (event.id && event.id === this.model.chartId) {
        this.updateForRangeChange();
      }
    });
    if (this.model.unlinked) {
      if (this.rangeService) {
        this.rangeService.setCellRanges([]);
      }
    }
    this.addManagedListener(this.eventService, Events23.EVENT_COLUMN_MOVED, this.updateForGridChange.bind(this));
    this.addManagedListener(this.eventService, Events23.EVENT_COLUMN_PINNED, this.updateForGridChange.bind(this));
    this.addManagedListener(this.eventService, Events23.EVENT_COLUMN_VISIBLE, this.updateForGridChange.bind(this));
    this.addManagedListener(this.eventService, Events23.EVENT_COLUMN_ROW_GROUP_CHANGED, this.updateForGridChange.bind(this));
    this.addManagedListener(this.eventService, Events23.EVENT_MODEL_UPDATED, this.updateForGridChange.bind(this));
    this.addManagedListener(this.eventService, Events23.EVENT_CELL_VALUE_CHANGED, this.updateForDataChange.bind(this));
  }
  update(params) {
    if (!this.validUpdateType(params))
      return false;
    const validationResult = ChartParamsValidator.validateUpdateParams(params);
    if (!validationResult)
      return false;
    const validParams = validationResult === true ? params : validationResult;
    this.applyValidatedChartParams(validParams);
    return true;
  }
  applyValidatedChartParams(params) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i;
    const { chartId, chartType, chartThemeName, unlinkChart } = params;
    const common = {
      chartId,
      pivotChart: this.model.pivotChart,
      chartType: chartType != null ? chartType : this.model.chartType,
      chartThemeName: chartThemeName != null ? chartThemeName : this.model.chartThemeName,
      unlinkChart: unlinkChart != null ? unlinkChart : this.model.unlinked,
      cellRange: this.model.suppliedCellRange,
      switchCategorySeries: this.model.switchCategorySeries,
      aggFunc: this.model.aggFunc,
      seriesChartTypes: void 0,
      suppressChartRanges: false,
      crossFiltering: false
    };
    let chartModelParams = __spreadValues3({}, common);
    switch (params.type) {
      case "rangeChartUpdate":
        chartModelParams.cellRange = (_a = this.createCellRange(params)) != null ? _a : this.model.suppliedCellRange;
        chartModelParams.switchCategorySeries = (_b = params.switchCategorySeries) != null ? _b : this.model.switchCategorySeries;
        chartModelParams.aggFunc = (_c = params.aggFunc) != null ? _c : this.model.aggFunc;
        chartModelParams.seriesChartTypes = params.seriesChartTypes;
        chartModelParams.suppressChartRanges = (_d = params.suppressChartRanges) != null ? _d : this.model.suppressChartRanges;
        chartModelParams.seriesGroupType = (_e = params.seriesGroupType) != null ? _e : this.model.seriesGroupType;
        break;
      case "crossFilterChartUpdate":
        chartModelParams.cellRange = (_f = this.createCellRange(params)) != null ? _f : this.model.suppliedCellRange;
        chartModelParams.switchCategorySeries = false;
        chartModelParams.aggFunc = (_g = params.aggFunc) != null ? _g : this.model.aggFunc;
        chartModelParams.crossFiltering = true;
        chartModelParams.suppressChartRanges = (_h = params.suppressChartRanges) != null ? _h : this.model.suppressChartRanges;
        break;
      case "pivotChartUpdate":
        chartModelParams.switchCategorySeries = false;
        break;
    }
    this.model.updateModel(chartModelParams);
    const removeChartCellRanges = chartModelParams.unlinkChart || chartModelParams.suppressChartRanges;
    removeChartCellRanges ? (_i = this.rangeService) == null ? void 0 : _i.setCellRanges([]) : this.setChartRange();
  }
  updateForGridChange(params) {
    if (this.model.unlinked) {
      return;
    }
    const { maintainColState, setColsFromRange } = params != null ? params : {};
    this.model.updateCellRanges({ maintainColState, setColsFromRange });
    this.model.updateData();
    this.setChartRange();
  }
  updateForDataChange() {
    if (this.model.unlinked) {
      return;
    }
    this.model.updateData();
    this.raiseChartModelUpdateEvent();
  }
  updateForRangeChange() {
    this.updateForGridChange({ setColsFromRange: true });
    this.raiseChartRangeSelectionChangedEvent();
  }
  updateForPanelChange(params) {
    this.model.updateCellRanges(params);
    this.model.updateData();
    if (params.skipAnimation) {
      this.getChartProxy().getChartRef().skipAnimations();
    }
    this.setChartRange();
    this.raiseChartRangeSelectionChangedEvent();
  }
  updateThemeOverrides(updatedOverrides) {
    this.chartProxy.updateThemeOverrides(updatedOverrides);
  }
  getChartUpdateParams(updatedOverrides) {
    const selectedCols = this.getSelectedValueColState();
    const fields = selectedCols.map((c) => ({ colId: c.colId, displayName: c.displayName }));
    const data = this.getChartData();
    const selectedDimensions = this.getSelectedDimensions();
    const params = {
      data,
      groupData: this.model.groupChartData,
      grouping: this.isGrouping(),
      categories: selectedDimensions.map((selectedDimension) => {
        var _a;
        return {
          id: selectedDimension.colId,
          name: selectedDimension.displayName,
          chartDataType: (_a = this.model.categoryAxisType) != null ? _a : this.model.getChartDataType(selectedDimension.colId)
        };
      }),
      fields,
      chartId: this.getChartId(),
      getCrossFilteringContext: () => ({ lastSelectedChartId: "xxx" }),
      //this.params.crossFilteringContext, //TODO
      seriesChartTypes: this.getSeriesChartTypes(),
      updatedOverrides,
      seriesGroupType: this.model.seriesGroupType
    };
    return this.isCategorySeriesSwitched() ? this.invertCategorySeriesParams(params) : params;
  }
  invertCategorySeriesParams(params) {
    const [category] = params.categories;
    const categories = [{ id: ChartDataModel.DEFAULT_CATEGORY, name: "" }];
    const fields = params.data.map((value, index) => {
      const categoryKey = `${category.id}:${index}`;
      const categoryValue = value[category.id];
      const seriesLabel = categoryValue == null ? "" : String(categoryValue);
      return { colId: categoryKey, displayName: seriesLabel };
    });
    const data = params.fields.map((field) => {
      const row = {
        [ChartDataModel.DEFAULT_CATEGORY]: field.displayName
      };
      for (const [index, value] of params.data.entries()) {
        const categoryKey = `${category.id}:${index}`;
        const seriesLabelValue = value[field.colId];
        row[categoryKey] = seriesLabelValue;
      }
      return row;
    });
    return __spreadProps3(__spreadValues3({}, params), {
      categories,
      fields,
      data
    });
  }
  getChartModel() {
    const modelType = this.model.pivotChart ? "pivot" : "range";
    const seriesChartTypes = this.isComboChart() ? this.model.comboChartModel.seriesChartTypes : void 0;
    return {
      modelType,
      chartId: this.model.chartId,
      chartType: this.model.chartType,
      chartThemeName: this.getChartThemeName(),
      chartOptions: this.chartProxy.getChartThemeOverrides(),
      chartPalette: this.chartProxy.getChartPalette(),
      cellRange: this.getCellRangeParams(),
      switchCategorySeries: this.model.switchCategorySeries,
      suppressChartRanges: this.model.suppressChartRanges,
      aggFunc: this.model.aggFunc,
      unlinkChart: this.model.unlinked,
      seriesChartTypes,
      seriesGroupType: this.model.seriesGroupType
    };
  }
  getChartId() {
    return this.model.chartId;
  }
  getChartData() {
    return this.model.chartData;
  }
  getChartType() {
    return this.model.chartType;
  }
  setChartType(chartType) {
    this.updateMultiSeriesAndCategory(this.model.chartType, chartType);
    this.model.chartType = chartType;
    this.model.comboChartModel.updateSeriesChartTypes();
    this.model.switchCategorySeries = false;
    this.model.categoryAxisType = void 0;
    this.model.seriesGroupType = void 0;
    this.raiseChartModelUpdateEvent();
    this.raiseChartOptionsChangedEvent();
  }
  isCategorySeriesSwitched() {
    return this.model.switchCategorySeries && !this.model.isGrouping();
  }
  switchCategorySeries(inverted) {
    if (!supportsInvertedCategorySeries(this.getChartType()))
      return;
    this.model.switchCategorySeries = inverted;
    this.raiseChartModelUpdateEvent();
  }
  getAggFunc() {
    return this.model.aggFunc;
  }
  setAggFunc(value, silent) {
    if (this.model.aggFunc === value)
      return;
    this.model.aggFunc = value;
    if (silent)
      return;
    this.model.updateData();
    this.raiseChartModelUpdateEvent();
  }
  updateMultiSeriesAndCategory(previousChartType, chartType) {
    var _a, _b;
    const updateForMax = (columns, maxNum) => {
      let numSelected = 0;
      for (const colState of columns) {
        if (!colState.selected)
          continue;
        if (numSelected >= maxNum) {
          colState.selected = false;
        } else {
          numSelected++;
        }
      }
      if (numSelected === 0) {
        columns[0].selected = true;
      }
    };
    const maxNumDimensions = getMaxNumCategories(chartType);
    const maxNumSeries = getMaxNumSeries(chartType);
    const updateDimensionColState = maxNumDimensions != null && ((_a = getMaxNumCategories(previousChartType)) != null ? _a : 100) > (maxNumDimensions != null ? maxNumDimensions : 100);
    const updateValueColState = maxNumSeries != null && ((_b = getMaxNumSeries(previousChartType)) != null ? _b : 100) > (maxNumSeries != null ? maxNumSeries : 100);
    if (updateDimensionColState) {
      updateForMax(this.model.dimensionColState, maxNumDimensions);
    }
    if (updateValueColState) {
      updateForMax(this.model.valueColState, maxNumSeries);
    }
    if (updateDimensionColState || updateValueColState) {
      this.model.resetCellRanges(updateDimensionColState, updateValueColState);
      this.setChartRange(true);
    }
  }
  setChartThemeName(chartThemeName, silent) {
    this.model.chartThemeName = chartThemeName;
    if (!silent) {
      this.raiseChartModelUpdateEvent();
      this.raiseChartOptionsChangedEvent();
    }
  }
  getChartThemeName() {
    return this.model.chartThemeName;
  }
  isPivotChart() {
    return this.model.pivotChart;
  }
  isPivotMode() {
    return this.model.isPivotMode();
  }
  isGrouping() {
    return this.model.isGrouping();
  }
  isCrossFilterChart() {
    return this.model.crossFiltering;
  }
  getThemeNames() {
    return this.gos.get("chartThemes") || DEFAULT_THEMES;
  }
  getThemes() {
    const themeNames = this.getThemeNames();
    return themeNames.map((themeName) => {
      const stockTheme = isStockTheme(themeName);
      const theme = stockTheme ? themeName : this.chartProxy.lookupCustomChartTheme(themeName);
      return _Theme2.getChartTheme(theme);
    });
  }
  getPalettes() {
    const themes = this.getThemes();
    return themes.map((theme) => {
      return theme.palette;
    });
  }
  getThemeTemplateParameters() {
    const themes = this.getThemes();
    return themes.map((theme) => {
      return theme.getTemplateParameters();
    });
  }
  getValueColState() {
    return this.model.valueColState.map(this.displayNameMapper.bind(this));
  }
  getSelectedValueColState() {
    return this.getValueColState().filter((cs) => cs.selected);
  }
  getSelectedDimensions() {
    return this.model.getSelectedDimensions();
  }
  displayNameMapper(col) {
    const columnNames = this.model.columnNames[col.colId];
    col.displayName = columnNames ? columnNames.join(" - ") : this.model.getColDisplayName(col.column);
    return col;
  }
  getColStateForMenu() {
    return { dimensionCols: this.model.dimensionColState, valueCols: this.getValueColState() };
  }
  setChartRange(silent = false) {
    if (this.rangeService && !this.model.suppressChartRanges && !this.model.unlinked) {
      this.rangeService.setCellRanges(this.getCellRanges());
    }
    if (!silent) {
      this.raiseChartModelUpdateEvent();
    }
  }
  detachChartRange() {
    this.model.unlinked = !this.model.unlinked;
    if (this.model.unlinked) {
      if (this.rangeService) {
        this.rangeService.setCellRanges([]);
      }
    } else {
      this.updateForGridChange();
    }
    this.dispatchEvent({ type: _ChartController2.EVENT_CHART_LINKED_CHANGED });
  }
  setChartProxy(chartProxy) {
    this.chartProxy = chartProxy;
  }
  getChartProxy() {
    return this.chartProxy;
  }
  isActiveXYChart() {
    return _52.includes(["scatter", "bubble"], this.getChartType());
  }
  isChartLinked() {
    return !this.model.unlinked;
  }
  customComboExists() {
    const savedCustomSeriesChartTypes = this.model.comboChartModel.savedCustomSeriesChartTypes;
    return savedCustomSeriesChartTypes && savedCustomSeriesChartTypes.length > 0;
  }
  getSeriesChartTypes() {
    return this.model.comboChartModel.seriesChartTypes;
  }
  isComboChart(chartType) {
    return this.model.isComboChart(chartType);
  }
  updateSeriesChartType(colId, chartType, secondaryAxis) {
    const seriesChartType = this.model.comboChartModel.seriesChartTypes.find((s) => s.colId === colId);
    if (seriesChartType) {
      const updateChartType = this.model.chartType !== "customCombo";
      if (updateChartType) {
        this.model.chartType = "customCombo";
      }
      const prevSeriesChartType = seriesChartType.chartType;
      if (chartType != null) {
        seriesChartType.chartType = chartType;
      }
      if (secondaryAxis != null) {
        seriesChartType.secondaryAxis = secondaryAxis;
      }
      this.model.comboChartModel.savedCustomSeriesChartTypes = this.model.comboChartModel.seriesChartTypes;
      this.model.comboChartModel.updateSeriesChartTypes();
      this.updateForDataChange();
      if (updateChartType) {
        this.dispatchEvent({
          type: _ChartController2.EVENT_CHART_TYPE_CHANGED
        });
      }
      if (prevSeriesChartType !== chartType) {
        this.dispatchEvent({
          type: _ChartController2.EVENT_CHART_SERIES_CHART_TYPE_CHANGED
        });
      }
      this.raiseChartOptionsChangedEvent();
    }
  }
  getActiveSeriesChartTypes() {
    const selectedColIds = this.getSelectedValueColState().map((c) => c.colId);
    return this.getSeriesChartTypes().filter((s) => selectedColIds.includes(s.colId));
  }
  getChartSeriesTypes(chartType) {
    const targetChartType = chartType != null ? chartType : this.getChartType();
    return this.isComboChart(targetChartType) ? ["line", "bar", "area"] : [getSeriesType(targetChartType)];
  }
  getChartSeriesType() {
    const seriesChartTypes = this.getSeriesChartTypes();
    if (seriesChartTypes.length === 0) {
      return "bar";
    }
    const ct = seriesChartTypes[0].chartType;
    if (ct === "columnLineCombo") {
      return "bar";
    }
    if (ct === "areaColumnCombo") {
      return "area";
    }
    return getSeriesType(ct);
  }
  getCellRanges() {
    return [this.model.dimensionCellRange, this.model.valueCellRange].filter((r) => r);
  }
  createCellRange(params) {
    var _a;
    return params.cellRange && ((_a = this.rangeService) == null ? void 0 : _a.createPartialCellRangeFromRangeParams(params.cellRange, true));
  }
  validUpdateType(params) {
    var _a;
    if (!params.type) {
      console.warn(`AG Grid - Unable to update chart as the 'type' is missing. It must be either 'rangeChartUpdate', 'pivotChartUpdate', or 'crossFilterChartUpdate'.`);
      return false;
    }
    const chartTypeMap = {
      "Range Chart": () => !this.isPivotChart() && !this.isCrossFilterChart(),
      "Pivot Chart": () => this.isPivotChart(),
      "Cross Filter Chart": () => this.isCrossFilterChart()
    };
    const currentChartType = (_a = Object.keys(chartTypeMap).find((type) => chartTypeMap[type]())) != null ? _a : "Range Chart";
    const valid = params.type === `${currentChartType[0].toLowerCase()}${currentChartType.slice(1).replace(/ /g, "")}Update`;
    if (!valid) {
      console.warn(`AG Grid - Unable to update chart as a '${params.type}' update type is not permitted on a ${currentChartType}.`);
    }
    return valid;
  }
  getCellRangeParams() {
    const cellRanges = this.getCellRanges();
    const firstCellRange = cellRanges[0];
    const startRow = firstCellRange && firstCellRange.startRow || null;
    const endRow = firstCellRange && firstCellRange.endRow || null;
    return {
      rowStartIndex: startRow && startRow.rowIndex,
      rowStartPinned: startRow && startRow.rowPinned,
      rowEndIndex: endRow && endRow.rowIndex,
      rowEndPinned: endRow && endRow.rowPinned,
      columns: cellRanges.reduce((columns, value) => columns.concat(value.columns.map((c) => c.getId())), [])
    };
  }
  setCategoryAxisType(categoryAxisType) {
    this.model.categoryAxisType = categoryAxisType;
    this.raiseChartModelUpdateEvent();
  }
  getSeriesGroupType() {
    var _a;
    return (_a = this.model.seriesGroupType) != null ? _a : this.chartProxy.getSeriesGroupType();
  }
  setSeriesGroupType(seriesGroupType) {
    this.model.seriesGroupType = seriesGroupType;
    this.raiseChartModelUpdateEvent();
  }
  raiseChartModelUpdateEvent() {
    const event = {
      type: _ChartController2.EVENT_CHART_MODEL_UPDATE
    };
    this.dispatchEvent(event);
  }
  raiseChartUpdatedEvent() {
    const event = {
      type: _ChartController2.EVENT_CHART_UPDATED
    };
    this.dispatchEvent(event);
  }
  raiseChartApiUpdateEvent() {
    const event = {
      type: _ChartController2.EVENT_CHART_API_UPDATE
    };
    this.dispatchEvent(event);
  }
  raiseChartOptionsChangedEvent() {
    const { chartId, chartType } = this.getChartModel();
    const event = {
      type: Events23.EVENT_CHART_OPTIONS_CHANGED,
      chartId,
      chartType,
      chartThemeName: this.getChartThemeName(),
      chartOptions: this.chartProxy.getChartThemeOverrides()
    };
    this.eventService.dispatchEvent(event);
  }
  raiseChartRangeSelectionChangedEvent() {
    const event = {
      type: Events23.EVENT_CHART_RANGE_SELECTION_CHANGED,
      id: this.model.chartId,
      chartId: this.model.chartId,
      cellRange: this.getCellRangeParams()
    };
    this.eventService.dispatchEvent(event);
  }
  destroy() {
    super.destroy();
    if (this.rangeService) {
      this.rangeService.setCellRanges([]);
    }
  }
};
_ChartController.EVENT_CHART_UPDATED = "chartUpdated";
_ChartController.EVENT_CHART_API_UPDATE = "chartApiUpdate";
_ChartController.EVENT_CHART_MODEL_UPDATE = "chartModelUpdate";
_ChartController.EVENT_CHART_TYPE_CHANGED = "chartTypeChanged";
_ChartController.EVENT_CHART_SERIES_CHART_TYPE_CHANGED = "chartSeriesChartTypeChanged";
_ChartController.EVENT_CHART_LINKED_CHANGED = "chartLinkedChanged";
__decorateClass4([
  Autowired42("rangeService")
], _ChartController.prototype, "rangeService", 2);
__decorateClass4([
  PostConstruct43
], _ChartController.prototype, "init", 1);
var ChartController = _ChartController;
var _AgPillSelect = class _AgPillSelect2 extends Component10 {
  constructor(config) {
    super(_AgPillSelect2.TEMPLATE);
    this.config = config != null ? config : {};
    const { selectedValueList, valueFormatter, valueList } = this.config;
    this.selectedValues = selectedValueList != null ? selectedValueList : [];
    this.valueList = valueList != null ? valueList : [];
    this.valueFormatter = valueFormatter != null ? valueFormatter : (value) => _62.escapeString(value);
  }
  init() {
    const { ariaLabel, onValuesChange, dragSourceId } = this.config;
    this.dropZonePanel = this.createManagedBean(new PillSelectDropZonePanel(
      {
        getValues: () => this.selectedValues,
        setValues: (values) => this.updateValues(values),
        isDraggable: () => this.selectedValues.length > 1
      },
      (value) => this.valueFormatter(value),
      ariaLabel,
      dragSourceId
    ));
    const eGui = this.getGui();
    eGui.appendChild(this.dropZonePanel.getGui());
    this.initSelect();
    if (onValuesChange != null) {
      this.onValuesChange = onValuesChange;
    }
  }
  setValues(valueList, selectedValues) {
    const { added, removed, updated } = this.getChanges(this.valueList, valueList);
    let refreshSelect = false;
    if (added.length || removed.length || updated.length) {
      refreshSelect = true;
    }
    this.valueList = valueList;
    this.updateValues(selectedValues, refreshSelect, true);
    return this;
  }
  setValueFormatter(valueFormatter) {
    this.valueFormatter = valueFormatter;
    return this;
  }
  initSelect() {
    const options = this.createSelectOptions();
    if (!options.length) {
      return false;
    }
    const { selectPlaceholder: placeholder } = this.config;
    this.eSelect = this.createBean(new AgSelect({
      options,
      placeholder,
      onValueChange: (value) => this.addValue(value),
      pickerIcon: "chartsMenuAdd"
    }));
    this.getGui().appendChild(this.eSelect.getGui());
    return true;
  }
  createSelectOptions() {
    let options = [];
    const { maxSelection } = this.config;
    if (maxSelection && this.selectedValues.length >= maxSelection) {
      return options;
    }
    this.valueList.forEach((value) => {
      if (!this.selectedValues.includes(value)) {
        options.push({ value, text: this.valueFormatter(value) });
      }
    });
    return options;
  }
  addValue(value) {
    this.dropZonePanel.addItem(value);
  }
  updateValues(values, forceRefreshSelect, silent) {
    var _a, _b, _c, _d, _e;
    const previousSelectedValues = this.selectedValues;
    this.selectedValues = values;
    const changes = this.getChanges(previousSelectedValues, values);
    const refreshSelect = forceRefreshSelect || changes.added.length || changes.removed.length;
    const activeElement = this.gos.getActiveDomElement();
    const selectHasFocus = (_a = this.eSelect) == null ? void 0 : _a.getGui().contains(activeElement);
    const dropZoneHasFocus = (_b = this.dropZonePanel) == null ? void 0 : _b.getGui().contains(activeElement);
    if (!silent) {
      (_c = this.onValuesChange) == null ? void 0 : _c.call(this, changes);
    }
    const emptyRefreshedSelect = refreshSelect ? !this.refreshSelect() : false;
    this.dropZonePanel.refreshGui();
    if (refreshSelect && selectHasFocus) {
      if (emptyRefreshedSelect) {
        this.dropZonePanel.focusList(true);
      } else {
        (_d = this.eSelect) == null ? void 0 : _d.getFocusableElement().focus();
      }
    }
    if (dropZoneHasFocus && !values.length) {
      (_e = this.eSelect) == null ? void 0 : _e.getFocusableElement().focus();
    }
  }
  getChanges(previousSelectedValues, newSelectedValues) {
    const added = newSelectedValues.filter((value) => !previousSelectedValues.includes(value));
    const removed = previousSelectedValues.filter((value) => !newSelectedValues.includes(value));
    const updated = newSelectedValues.filter((value, index) => previousSelectedValues[index] !== value);
    return { added, removed, updated, selected: newSelectedValues };
  }
  refreshSelect() {
    if (!this.eSelect) {
      return this.initSelect();
    }
    const options = this.createSelectOptions();
    if (!options.length) {
      _62.removeFromParent(this.eSelect.getGui());
      this.eSelect = this.destroyBean(this.eSelect);
      return false;
    }
    this.eSelect.clearOptions().addOptions(options).setValue(void 0, true);
    return true;
  }
  destroy() {
    this.destroyBean(this.eSelect);
    super.destroy();
  }
};
_AgPillSelect.TEMPLATE = /* html */
`<div class="ag-pill-select" role="presentation"></div>`;
__decorateClass4([
  PostConstruct52
], _AgPillSelect.prototype, "init", 1);
var AgPillSelect = _AgPillSelect;
var PillSelectDragComp = class extends PillDragComp {
  constructor(value, dragSourceDropTarget, ghost, valueFormatter, draggable, sourceId) {
    super(dragSourceDropTarget, ghost, false);
    this.value = value;
    this.valueFormatter = valueFormatter;
    this.draggable = draggable;
    this.sourceId = sourceId;
  }
  getItem() {
    return this.value;
  }
  getDisplayName() {
    return this.valueFormatter(this.value);
  }
  getAriaDisplayName() {
    return this.getDisplayName();
  }
  getTooltip() {
    return void 0;
  }
  createGetDragItem() {
    return () => ({
      value: this.value
    });
  }
  getDragSourceType() {
    return DragSourceType3.ChartPanel;
  }
  getDragSourceId() {
    return this.sourceId;
  }
  isDraggable() {
    return this.draggable;
  }
};
var PillSelectDropZonePanel = class extends PillDropZonePanel {
  constructor(model, valueFormatter, ariaLabel, sourceId) {
    super(false);
    this.model = model;
    this.valueFormatter = valueFormatter;
    this.ariaLabel = ariaLabel;
    this.sourceId = sourceId;
  }
  postConstruct() {
    super.init();
  }
  isItemDroppable(item, draggingEvent) {
    return this.isSourceEventFromTarget(draggingEvent) || this.sourceId != null && this.sourceId === draggingEvent.dragSource.sourceId;
  }
  updateItems(items) {
    this.model.setValues(items);
  }
  getExistingItems() {
    return this.model.getValues();
  }
  getIconName() {
    return this.isPotentialDndItems() ? DragAndDropService2.ICON_MOVE : DragAndDropService2.ICON_NOT_ALLOWED;
  }
  getAriaLabel() {
    return this.ariaLabel;
  }
  createPillComponent(item, dropTarget, ghost) {
    return new PillSelectDragComp(item, dropTarget, ghost, this.valueFormatter, this.model.isDraggable(), this.sourceId);
  }
  getItems(dragItem) {
    return [dragItem.value];
  }
  isInterestedIn(type) {
    return type === DragSourceType3.ChartPanel;
  }
};
__decorateClass4([
  PostConstruct52
], PillSelectDropZonePanel.prototype, "postConstruct", 1);
var DragDataPanel = class extends Component22 {
  constructor(chartController, autoScrollService, allowMultipleSelection, maxSelection, template) {
    super(template);
    this.chartController = chartController;
    this.autoScrollService = autoScrollService;
    this.allowMultipleSelection = allowMultipleSelection;
    this.maxSelection = maxSelection;
    this.columnComps = /* @__PURE__ */ new Map();
  }
  refreshColumnComps(cols) {
    if (!_72.areEqual(_72.keys(this.columnComps), cols.map(({ colId }) => colId))) {
      return false;
    }
    cols.forEach((col) => {
      this.columnComps.get(col.colId).setValue(col.selected, true);
    });
    return true;
  }
  createGroup(columns, valueFormatter, selectLabelKey, dragSourceId, skipAnimation) {
    if (this.allowMultipleSelection) {
      const selectedValueList = columns.filter((col) => col.selected);
      this.valuePillSelect = this.groupComp.createManagedBean(new AgPillSelect({
        valueList: columns,
        selectedValueList,
        valueFormatter,
        selectPlaceholder: this.chartTranslationService.translate(selectLabelKey),
        dragSourceId,
        onValuesChange: (params) => this.onValueChange(params),
        maxSelection: this.maxSelection
      }));
      this.groupComp.addItem(this.valuePillSelect);
    } else {
      const params = this.createValueSelectParams(columns);
      params.onValueChange = (updatedColState) => {
        columns.forEach((col) => {
          col.selected = false;
        });
        updatedColState.selected = true;
        if (updatedColState.colId === ChartDataModel.DEFAULT_CATEGORY) {
          this.chartController.setAggFunc(void 0, true);
        }
        this.chartController.updateForPanelChange({ updatedColState, skipAnimation: skipAnimation == null ? void 0 : skipAnimation() });
      };
      this.valueSelect = this.groupComp.createManagedBean(new AgSelect2(params));
      this.groupComp.addItem(this.valueSelect);
    }
  }
  refreshValueSelect(columns) {
    if (!this.valueSelect) {
      return;
    }
    const { options, value } = this.createValueSelectParams(columns);
    this.valueSelect.clearOptions().addOptions(options).setValue(value, true);
  }
  createValueSelectParams(columns) {
    let selectedValue;
    const options = columns.map((value) => {
      var _a;
      const text = (_a = value.displayName) != null ? _a : "";
      if (value.selected) {
        selectedValue = value;
      }
      return {
        value,
        text
      };
    });
    return {
      options,
      value: selectedValue
    };
  }
  onDragging(draggingEvent) {
    const itemHovered = this.checkHoveredItem(draggingEvent);
    if (!itemHovered) {
      return;
    }
    this.lastDraggedColumn = draggingEvent.dragItem.columns[0];
    const { comp, position } = itemHovered;
    const { comp: lastHoveredComp, position: lastHoveredPosition } = this.lastHoveredItem || {};
    if (comp === lastHoveredComp && position === lastHoveredPosition) {
      return;
    }
    this.autoScrollService.check(draggingEvent.event);
    this.clearHoveredItems();
    this.lastHoveredItem = { comp, position };
    const eGui = comp.getGui();
    eGui.classList.add("ag-list-item-hovered", `ag-item-highlight-${position}`);
  }
  checkHoveredItem(draggingEvent) {
    if (_72.missing(draggingEvent.vDirection)) {
      return null;
    }
    const mouseEvent = draggingEvent.event;
    for (const comp of this.columnComps.values()) {
      const eGui = comp.getGui();
      if (!eGui.querySelector(".ag-chart-data-column-drag-handle")) {
        continue;
      }
      const rect = eGui.getBoundingClientRect();
      const isOverComp = mouseEvent.clientY >= rect.top && mouseEvent.clientY <= rect.bottom;
      if (isOverComp) {
        const height = eGui.clientHeight;
        const position = mouseEvent.clientY > rect.top + height / 2 ? "bottom" : "top";
        return { comp, position };
      }
    }
    return null;
  }
  onDragLeave() {
    this.clearHoveredItems();
  }
  onDragStop() {
    if (this.lastHoveredItem) {
      const { dimensionCols, valueCols } = this.chartController.getColStateForMenu();
      const draggedColumnState = [...dimensionCols, ...valueCols].find((state) => state.column === this.lastDraggedColumn);
      if (draggedColumnState) {
        let targetIndex = Array.from(this.columnComps.values()).indexOf(this.lastHoveredItem.comp);
        if (this.lastHoveredItem.position === "bottom") {
          targetIndex++;
        }
        draggedColumnState.order = targetIndex;
        this.chartController.updateForPanelChange({ updatedColState: draggedColumnState });
      }
    }
    this.clearHoveredItems();
    this.lastDraggedColumn = void 0;
    this.autoScrollService.ensureCleared();
  }
  clearHoveredItems() {
    this.columnComps.forEach((columnComp) => {
      columnComp.getGui().classList.remove(
        "ag-list-item-hovered",
        "ag-item-highlight-top",
        "ag-item-highlight-bottom"
      );
    });
    this.lastHoveredItem = void 0;
  }
  addDragHandle(comp, col) {
    const eDragHandle = _72.createIconNoSpan("columnDrag", this.gos);
    eDragHandle.classList.add("ag-drag-handle", "ag-chart-data-column-drag-handle");
    comp.getGui().insertAdjacentElement("beforeend", eDragHandle);
    const dragSource = {
      type: DragSourceType22.ChartPanel,
      eElement: eDragHandle,
      dragItemName: col.displayName,
      getDragItem: () => ({ columns: [col.column] }),
      onDragStopped: () => this.onDragStop()
    };
    this.dragAndDropService.addDragSource(dragSource, true);
    this.addDestroyFunc(() => this.dragAndDropService.removeDragSource(dragSource));
  }
  addChangeListener(component, updatedColState) {
    this.addManagedListener(component, Events32.EVENT_FIELD_VALUE_CHANGED, () => {
      updatedColState.selected = component.getValue();
      this.chartController.updateForPanelChange({ updatedColState });
    });
  }
  isInterestedIn(type) {
    return type === DragSourceType22.ChartPanel;
  }
  onValueChange({ added, updated, removed, selected }) {
    let updatedColState;
    let resetOrder;
    const updateOrder = () => {
      selected.forEach((col, index) => {
        col.order = index;
      });
      resetOrder = true;
    };
    if (added.length) {
      updatedColState = added[0];
      updatedColState.selected = true;
      updateOrder();
    } else if (removed.length) {
      updatedColState = removed[0];
      updatedColState.selected = false;
    } else if (updated.length) {
      updateOrder();
      updatedColState = updated[0];
    }
    if (updatedColState) {
      this.chartController.updateForPanelChange({ updatedColState, resetOrder });
    }
  }
  destroy() {
    this.valuePillSelect = void 0;
    this.valueSelect = void 0;
    super.destroy();
  }
};
__decorateClass4([
  Autowired52("dragAndDropService")
], DragDataPanel.prototype, "dragAndDropService", 2);
__decorateClass4([
  Autowired52("chartTranslationService")
], DragDataPanel.prototype, "chartTranslationService", 2);
var DEFAULT_AGG_FUNC = "sum";
var _CategoriesDataPanel = class _CategoriesDataPanel2 extends DragDataPanel {
  constructor(chartController, autoScrollService, title, allowMultipleSelection, dimensionCols, isOpen) {
    const maxSelection = void 0;
    super(chartController, autoScrollService, allowMultipleSelection, maxSelection, _CategoriesDataPanel2.TEMPLATE);
    this.title = title;
    this.dimensionCols = dimensionCols;
    this.isOpen = isOpen;
  }
  init() {
    this.groupComp = this.createBean(new AgGroupComponent2({
      title: this.title,
      enabled: true,
      suppressEnabledCheckbox: true,
      suppressOpenCloseIcons: false,
      cssIdentifier: "charts-data",
      expanded: this.isOpen
    }));
    if (this.chartMenuService.isLegacyFormat()) {
      this.createLegacyCategoriesGroup(this.dimensionCols);
      this.clearAggFuncControls();
    } else {
      this.createCategoriesGroup(this.dimensionCols);
      this.createAggFuncControls(this.dimensionCols);
    }
    this.getGui().appendChild(this.groupComp.getGui());
  }
  refresh(dimensionCols) {
    var _a;
    if (this.chartMenuService.isLegacyFormat()) {
      if (!this.refreshColumnComps(dimensionCols)) {
        this.recreate(dimensionCols);
      }
    } else {
      (_a = this.valuePillSelect) == null ? void 0 : _a.setValues(dimensionCols, dimensionCols.filter((col) => col.selected));
      this.refreshValueSelect(dimensionCols);
      this.refreshAggFuncControls(dimensionCols, this.chartController.getAggFunc());
    }
  }
  recreate(dimensionCols) {
    this.isOpen = this.groupComp.isExpanded();
    _82.clearElement(this.getGui());
    this.destroyBean(this.groupComp);
    this.dimensionCols = dimensionCols;
    this.init();
  }
  createCategoriesGroup(columns) {
    this.createGroup(columns, (col) => {
      var _a;
      return (_a = col.displayName) != null ? _a : "";
    }, "categoryAdd", "categorySelect", () => !this.chartController.getAggFunc());
  }
  createLegacyCategoriesGroup(columns) {
    const inputName = `chartDimension${this.groupComp.getCompId()}`;
    const supportsMultipleCategoryColumns = this.allowMultipleSelection;
    columns.forEach((col) => {
      var _a;
      const params = {
        label: (_a = col.displayName) != null ? _a : "",
        value: col.selected,
        inputName
      };
      const comp = this.groupComp.createManagedBean(
        supportsMultipleCategoryColumns ? (() => {
          const checkboxComp = new AgCheckbox2(params);
          checkboxComp.addCssClass("ag-data-select-checkbox");
          return checkboxComp;
        })() : new AgRadioButton2(params)
      );
      this.addChangeListener(comp, col);
      this.groupComp.addItem(comp);
      this.columnComps.set(col.colId, comp);
      if (supportsMultipleCategoryColumns)
        this.addDragHandle(comp, col);
    });
    if (supportsMultipleCategoryColumns) {
      const categoriesGroupGui = this.groupComp.getGui();
      const dropTarget = {
        getIconName: () => DragAndDropService3.ICON_MOVE,
        getContainer: () => categoriesGroupGui,
        onDragging: (params) => this.onDragging(params),
        onDragLeave: () => this.onDragLeave(),
        isInterestedIn: this.isInterestedIn.bind(this),
        targetContainsSource: true
      };
      this.dragAndDropService.addDropTarget(dropTarget);
      this.addDestroyFunc(() => this.dragAndDropService.removeDropTarget(dropTarget));
    }
  }
  createAggFuncControls(dimensionCols) {
    const aggFunc = this.chartController.getAggFunc();
    this.groupComp.addItem(this.aggFuncToggle = this.createBean(new AgToggleButton({
      label: this.chartTranslationService.translate("aggregate"),
      labelAlignment: "left",
      labelWidth: "flex",
      inputWidth: "flex",
      value: aggFunc != void 0,
      onValueChange: (value) => {
        var _a, _b;
        const aggFunc2 = value ? DEFAULT_AGG_FUNC : void 0;
        this.chartController.setAggFunc(aggFunc2);
        (_a = this.aggFuncSelect) == null ? void 0 : _a.setValue(aggFunc2, true);
        (_b = this.aggFuncSelect) == null ? void 0 : _b.setDisplayed(aggFunc2 != void 0);
      }
    })));
    this.groupComp.addItem(this.aggFuncSelect = this.createBean(new AgSelect3({
      options: [
        { value: "sum", text: this.chartTranslationService.translate("sum") },
        { value: "first", text: this.chartTranslationService.translate("first") },
        { value: "last", text: this.chartTranslationService.translate("last") },
        { value: "min", text: this.chartTranslationService.translate("min") },
        { value: "max", text: this.chartTranslationService.translate("max") },
        { value: "count", text: this.chartTranslationService.translate("count") },
        { value: "avg", text: this.chartTranslationService.translate("avg") }
      ],
      value: typeof aggFunc === "string" ? aggFunc : void 0,
      onValueChange: (value) => {
        this.chartController.setAggFunc(value);
      }
    })));
    this.refreshAggFuncControls(dimensionCols, aggFunc);
  }
  refreshAggFuncControls(dimensionCols, aggFunc) {
    var _a, _b, _c, _d;
    const selectedDimensions = dimensionCols.filter((col) => col.selected);
    const supportsAggregation = selectedDimensions.some((col) => col.colId !== ChartDataModel.DEFAULT_CATEGORY);
    (_a = this.aggFuncToggle) == null ? void 0 : _a.setValue(aggFunc != void 0);
    (_b = this.aggFuncSelect) == null ? void 0 : _b.setValue(typeof aggFunc === "string" ? aggFunc : void 0, true);
    (_c = this.aggFuncToggle) == null ? void 0 : _c.setDisplayed(supportsAggregation);
    (_d = this.aggFuncSelect) == null ? void 0 : _d.setDisplayed(supportsAggregation && aggFunc != void 0);
  }
  clearAggFuncControls() {
    this.aggFuncToggle = this.aggFuncToggle && this.destroyBean(this.aggFuncToggle);
    this.aggFuncSelect = this.aggFuncSelect && this.destroyBean(this.aggFuncSelect);
  }
  destroy() {
    this.clearAggFuncControls();
    this.groupComp = this.destroyBean(this.groupComp);
    super.destroy();
  }
};
_CategoriesDataPanel.TEMPLATE = /* html */
`<div id="categoriesGroup"></div>`;
__decorateClass4([
  Autowired62("chartMenuService")
], _CategoriesDataPanel.prototype, "chartMenuService", 2);
__decorateClass4([
  PostConstruct62
], _CategoriesDataPanel.prototype, "init", 1);
var CategoriesDataPanel = _CategoriesDataPanel;
var _SeriesDataPanel = class _SeriesDataPanel2 extends DragDataPanel {
  constructor(chartController, autoScrollService, chartOptionsService, title, allowMultipleSelect, maxSelection, valueCols, isOpen) {
    super(chartController, autoScrollService, allowMultipleSelect, maxSelection, _SeriesDataPanel2.TEMPLATE);
    this.chartOptionsService = chartOptionsService;
    this.title = title;
    this.valueCols = valueCols;
    this.isOpen = isOpen;
  }
  init() {
    this.groupComp = this.createBean(new AgGroupComponent3({
      title: this.title,
      enabled: true,
      suppressEnabledCheckbox: true,
      suppressOpenCloseIcons: false,
      cssIdentifier: "charts-data",
      expanded: this.isOpen
    }));
    if (this.chartController.isActiveXYChart()) {
      const pairedModeToggle = this.groupComp.createManagedBean(new AgToggleButton2({
        label: this.chartTranslationService.translate("paired"),
        labelAlignment: "left",
        labelWidth: "flex",
        inputWidth: "flex",
        value: this.chartOptionsService.getPairedMode(),
        onValueChange: (newValue) => {
          this.chartOptionsService.setPairedMode(!!newValue);
          this.chartController.updateForGridChange({ maintainColState: true });
        }
      }));
      this.groupComp.addItem(pairedModeToggle);
    }
    if (this.chartMenuService.isLegacyFormat()) {
      this.createLegacySeriesGroup(this.valueCols);
    } else {
      this.createSeriesGroup(this.valueCols);
    }
    this.getGui().appendChild(this.groupComp.getGui());
  }
  refresh(valueCols) {
    var _a, _b;
    if (this.chartMenuService.isLegacyFormat()) {
      const canRefresh = this.refreshColumnComps(valueCols);
      if (canRefresh) {
        if (this.chartController.isActiveXYChart()) {
          const getSeriesLabel = this.generateGetSeriesLabel(valueCols);
          valueCols.forEach((col) => {
            this.columnComps.get(col.colId).setLabel(getSeriesLabel(col));
          });
        }
      } else {
        this.recreate(valueCols);
      }
    } else {
      (_a = this.valuePillSelect) == null ? void 0 : _a.setValueFormatter(this.generateGetSeriesLabel(valueCols));
      (_b = this.valuePillSelect) == null ? void 0 : _b.setValues(valueCols, valueCols.filter((col) => col.selected));
      this.refreshValueSelect(valueCols);
    }
  }
  recreate(valueCols) {
    this.isOpen = this.groupComp.isExpanded();
    _92.clearElement(this.getGui());
    this.destroyBean(this.groupComp);
    this.valueCols = valueCols;
    this.init();
  }
  createSeriesGroup(columns) {
    this.createGroup(columns, this.generateGetSeriesLabel(columns), "seriesAdd", "seriesSelect");
  }
  createLegacySeriesGroup(columns) {
    const getSeriesLabel = this.generateGetSeriesLabel(columns);
    columns.forEach((col) => {
      const label = getSeriesLabel(col);
      const comp = this.groupComp.createManagedBean(new AgCheckbox3({
        label,
        value: col.selected
      }));
      comp.addCssClass("ag-data-select-checkbox");
      this.addChangeListener(comp, col);
      this.groupComp.addItem(comp);
      this.columnComps.set(col.colId, comp);
      this.addDragHandle(comp, col);
    });
    const seriesGroupGui = this.groupComp.getGui();
    const dropTarget = {
      getIconName: () => DragAndDropService4.ICON_MOVE,
      getContainer: () => seriesGroupGui,
      onDragging: (params) => this.onDragging(params),
      onDragLeave: () => this.onDragLeave(),
      isInterestedIn: this.isInterestedIn.bind(this),
      targetContainsSource: true
    };
    this.dragAndDropService.addDropTarget(dropTarget);
    this.addDestroyFunc(() => this.dragAndDropService.removeDropTarget(dropTarget));
  }
  generateGetSeriesLabel(valueCols) {
    if (!this.chartController.isActiveXYChart()) {
      return (col) => {
        var _a;
        return (_a = col.displayName) != null ? _a : "";
      };
    }
    const selectedCols = valueCols.filter((col) => col.selected);
    const isBubble = this.chartController.getChartType() === "bubble";
    const isInPairedMode = this.chartOptionsService.getPairedMode();
    const indexToAxisLabel = /* @__PURE__ */ new Map();
    indexToAxisLabel.set(0, "X");
    indexToAxisLabel.set(1, "Y");
    indexToAxisLabel.set(2, "size");
    return (col) => {
      var _a;
      const escapedLabel = (_a = col.displayName) != null ? _a : "";
      if (!col.selected) {
        return escapedLabel;
      }
      const index = selectedCols.indexOf(col);
      if (index === -1) {
        return escapedLabel;
      }
      let axisLabel;
      if (isInPairedMode) {
        axisLabel = indexToAxisLabel.get(index % (isBubble ? 3 : 2));
      } else {
        if (index === 0) {
          axisLabel = "X";
        } else {
          axisLabel = isBubble && index % 2 === 0 ? "size" : "Y";
        }
      }
      return `${escapedLabel} (${axisLabel})`;
    };
  }
  destroy() {
    this.groupComp = this.destroyBean(this.groupComp);
    super.destroy();
  }
};
_SeriesDataPanel.TEMPLATE = /* html */
`<div id="seriesGroup"></div>`;
__decorateClass4([
  Autowired72("chartMenuService")
], _SeriesDataPanel.prototype, "chartMenuService", 2);
__decorateClass4([
  PostConstruct72
], _SeriesDataPanel.prototype, "init", 1);
var SeriesDataPanel = _SeriesDataPanel;
var _SeriesChartTypePanel = class _SeriesChartTypePanel2 extends Component33 {
  constructor(chartController, columns, isOpen) {
    super(_SeriesChartTypePanel2.TEMPLATE);
    this.chartController = chartController;
    this.columns = columns;
    this.isOpen = isOpen;
    this.selectedColIds = [];
    this.chartTypeComps = /* @__PURE__ */ new Map();
    this.secondaryAxisComps = /* @__PURE__ */ new Map();
  }
  init() {
    this.createSeriesChartTypeGroup(this.columns);
  }
  refresh(columns) {
    if (!_102.areEqual(this.getValidColIds(columns), this.selectedColIds)) {
      this.recreate(columns);
    } else {
      this.refreshComps();
    }
  }
  recreate(columns) {
    this.isOpen = this.seriesChartTypeGroupComp.isExpanded();
    _102.clearElement(this.getGui());
    this.destroyBean(this.seriesChartTypeGroupComp);
    this.columns = columns;
    this.selectedColIds = [];
    this.clearComps();
    this.init();
  }
  getValidColIds(columns) {
    const seriesChartTypes = this.chartController.getSeriesChartTypes();
    return columns.filter((col) => col.selected && !!seriesChartTypes.filter((s) => s.colId === col.colId)[0]).map(({ colId }) => colId);
  }
  createSeriesChartTypeGroup(columns) {
    this.seriesChartTypeGroupComp = this.createBean(new AgGroupComponent4({
      title: this.chartTranslationService.translate("seriesChartType"),
      enabled: true,
      suppressEnabledCheckbox: true,
      suppressOpenCloseIcons: false,
      cssIdentifier: "charts-data",
      expanded: this.isOpen
    }));
    const seriesChartTypes = this.chartController.getSeriesChartTypes();
    columns.forEach((col) => {
      if (!col.selected) {
        return;
      }
      const seriesChartType = seriesChartTypes.filter((s) => s.colId === col.colId)[0];
      if (!seriesChartType) {
        return;
      }
      this.selectedColIds.push(col.colId);
      const seriesItemGroup = this.seriesChartTypeGroupComp.createManagedBean(new AgGroupComponent4({
        title: col.displayName,
        enabled: true,
        suppressEnabledCheckbox: true,
        suppressOpenCloseIcons: true,
        cssIdentifier: "charts-format-sub-level"
      }));
      const isSecondaryAxisDisabled = (chartType) => ["groupedColumn", "stackedColumn", "stackedArea"].includes(chartType);
      const secondaryAxisComp = this.seriesChartTypeGroupComp.createManagedBean(new AgCheckbox4({
        label: this.chartTranslationService.translate("secondaryAxis"),
        labelWidth: "flex",
        disabled: isSecondaryAxisDisabled(seriesChartType.chartType),
        value: !!seriesChartType.secondaryAxis,
        onValueChange: (enabled) => this.chartController.updateSeriesChartType(col.colId, void 0, enabled)
      }));
      seriesItemGroup.addItem(secondaryAxisComp);
      const translate = (key) => {
        return this.chartTranslationService.translate(key);
      };
      const availableChartTypes = [
        { value: "line", text: translate("line") },
        { value: "area", text: translate("area") },
        { value: "stackedArea", text: translate("stackedArea") },
        { value: "groupedColumn", text: translate("groupedColumn") },
        { value: "stackedColumn", text: translate("stackedColumn") }
      ];
      const chartTypeComp = seriesItemGroup.createManagedBean(new AgSelect4({
        labelAlignment: "left",
        labelWidth: "flex",
        options: availableChartTypes,
        value: seriesChartType.chartType,
        onValueChange: (chartType) => this.chartController.updateSeriesChartType(col.colId, chartType)
      }));
      seriesItemGroup.addItem(chartTypeComp);
      this.seriesChartTypeGroupComp.addItem(seriesItemGroup);
      this.chartTypeComps.set(col.colId, chartTypeComp);
      this.secondaryAxisComps.set(col.colId, secondaryAxisComp);
    });
    this.getGui().appendChild(this.seriesChartTypeGroupComp.getGui());
  }
  refreshComps() {
    const seriesChartTypes = this.chartController.getSeriesChartTypes();
    this.selectedColIds.forEach((colId) => {
      const seriesChartType = seriesChartTypes.find((chartType) => chartType.colId === colId);
      if (!seriesChartType) {
        return;
      }
      const chartTypeComp = this.chartTypeComps.get(colId);
      const secondaryAxisComp = this.secondaryAxisComps.get(colId);
      chartTypeComp == null ? void 0 : chartTypeComp.setValue(seriesChartType.chartType);
      secondaryAxisComp == null ? void 0 : secondaryAxisComp.setValue(!!seriesChartType.secondaryAxis);
      secondaryAxisComp == null ? void 0 : secondaryAxisComp.setDisabled(this.isSecondaryAxisDisabled(seriesChartType.chartType));
    });
  }
  clearComps() {
    this.chartTypeComps.clear();
    this.secondaryAxisComps.clear();
  }
  isSecondaryAxisDisabled(chartType) {
    return ["groupedColumn", "stackedColumn", "stackedArea"].includes(chartType);
  }
  destroy() {
    this.clearComps();
    this.seriesChartTypeGroupComp = this.destroyBean(this.seriesChartTypeGroupComp);
    super.destroy();
  }
};
_SeriesChartTypePanel.TEMPLATE = /* html */
`<div id="seriesChartTypeGroup"></div>`;
__decorateClass4([
  Autowired82("chartTranslationService")
], _SeriesChartTypePanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct82
], _SeriesChartTypePanel.prototype, "init", 1);
var SeriesChartTypePanel = _SeriesChartTypePanel;
var _SwitchCategorySeriesDataPanel = class _SwitchCategorySeriesDataPanel2 extends Component42 {
  constructor(getValue, setValue) {
    super();
    this.getValue = getValue;
    this.setValue = setValue;
  }
  init() {
    this.switchCategorySeriesToggleButton = this.createManagedBean(new AgToggleButton3({
      label: this.chartTranslationService.translate("switchCategorySeries"),
      labelAlignment: "left",
      labelWidth: "flex",
      inputWidth: "flex",
      value: this.getValue(),
      onValueChange: (value) => {
        this.setValue(value);
      }
    }));
    const switchCategorySeriesGroupParams = {
      title: void 0,
      suppressEnabledCheckbox: true,
      suppressOpenCloseIcons: true,
      cssIdentifier: "charts-data",
      expanded: true,
      items: [this.switchCategorySeriesToggleButton]
    };
    this.setTemplate(_SwitchCategorySeriesDataPanel2.TEMPLATE, {
      switchCategorySeriesGroup: switchCategorySeriesGroupParams
    });
  }
  refresh() {
    var _a;
    (_a = this.switchCategorySeriesToggleButton) == null ? void 0 : _a.setValue(this.getValue(), true);
  }
};
_SwitchCategorySeriesDataPanel.TEMPLATE = /* html */
`<div>
        <ag-group-component ref="switchCategorySeriesGroup"></ag-group-component>
    </div>`;
__decorateClass4([
  Autowired92("chartTranslationService")
], _SwitchCategorySeriesDataPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct92
], _SwitchCategorySeriesDataPanel.prototype, "init", 1);
var SwitchCategorySeriesDataPanel = _SwitchCategorySeriesDataPanel;
var DefaultDataPanelDef = {
  groups: [
    { type: "categories", isOpen: true },
    { type: "series", isOpen: true },
    { type: "seriesChartType", isOpen: true }
  ]
};
var _ChartDataPanel = class _ChartDataPanel2 extends Component52 {
  constructor(chartController, chartOptionsService) {
    super(_ChartDataPanel2.TEMPLATE);
    this.chartController = chartController;
    this.chartOptionsService = chartOptionsService;
    this.isSwitchCategorySeriesToggled = false;
  }
  init() {
    this.switchCategorySeriesPanel = this.addComponent(this.createManagedBean(new SwitchCategorySeriesDataPanel(
      () => this.chartController.isCategorySeriesSwitched(),
      (value) => this.chartController.switchCategorySeries(value)
    )));
    this.isSwitchCategorySeriesToggled = this.chartController.isCategorySeriesSwitched();
    this.createAutoScrollService();
    this.updatePanels();
    this.addManagedListener(this.chartController, ChartController.EVENT_CHART_MODEL_UPDATE, this.updatePanels.bind(this));
    this.addManagedListener(this.chartController, ChartController.EVENT_CHART_API_UPDATE, this.updatePanels.bind(this));
  }
  destroy() {
    this.clearPanelComponents();
    super.destroy();
  }
  updatePanels() {
    var _a, _b, _c, _d, _e;
    const currentChartType = this.chartType;
    const isSwitchCategorySeriesToggledCurrent = this.isSwitchCategorySeriesToggled;
    const { dimensionCols, valueCols } = this.chartController.getColStateForMenu();
    this.chartType = this.chartController.getChartType();
    this.isSwitchCategorySeriesToggled = this.chartController.isCategorySeriesSwitched();
    const hasChangedSwitchCategorySeries = this.isSwitchCategorySeriesToggled !== isSwitchCategorySeriesToggledCurrent;
    if (this.canRefresh(currentChartType, this.chartType) && !hasChangedSwitchCategorySeries) {
      (_a = this.categoriesDataPanel) == null ? void 0 : _a.refresh(dimensionCols);
      (_b = this.seriesDataPanel) == null ? void 0 : _b.refresh(valueCols);
      (_c = this.seriesChartTypePanel) == null ? void 0 : _c.refresh(valueCols);
    } else {
      this.recreatePanels(dimensionCols, valueCols);
    }
    (_d = this.switchCategorySeriesPanel) == null ? void 0 : _d.setDisplayed(
      supportsInvertedCategorySeries(this.chartType) && !this.chartMenuService.isLegacyFormat() && !this.chartController.isGrouping()
    );
    if (hasChangedSwitchCategorySeries) {
      (_e = this.switchCategorySeriesPanel) == null ? void 0 : _e.refresh();
    }
  }
  canRefresh(oldChartType, newChartType) {
    if (oldChartType === void 0)
      return false;
    if (oldChartType === newChartType) {
      return true;
    }
    const isCombo = (chartType) => ["columnLineCombo", "areaColumnCombo", "customCombo"].includes(chartType);
    if (isCombo(oldChartType) && isCombo(newChartType)) {
      return true;
    }
    return false;
  }
  recreatePanels(dimensionCols, valueCols) {
    var _a;
    this.clearPanelComponents();
    const { chartType } = this;
    if (!chartType)
      return;
    const isCategorySeriesSwitched = this.chartController.isCategorySeriesSwitched();
    const panels = (_a = this.getDataPanelDef().groups) == null ? void 0 : _a.map(({ type, isOpen }) => {
      if (type === (isCategorySeriesSwitched ? "series" : "categories")) {
        return this.categoriesDataPanel = this.createBean(new CategoriesDataPanel(
          this.chartController,
          this.autoScrollService,
          this.getCategoryGroupTitle(isCategorySeriesSwitched),
          this.getCategoryGroupMultipleSelect(chartType, isCategorySeriesSwitched),
          dimensionCols,
          isOpen
        ));
      } else if (type === (isCategorySeriesSwitched ? "categories" : "series")) {
        return this.seriesDataPanel = this.createBean(new SeriesDataPanel(
          this.chartController,
          this.autoScrollService,
          this.chartOptionsService,
          this.getSeriesGroupTitle(isCategorySeriesSwitched),
          this.getSeriesGroupMultipleSelect(chartType, isCategorySeriesSwitched),
          this.getSeriesGroupMaxSelection(chartType, isCategorySeriesSwitched),
          valueCols,
          isOpen
        ));
      } else if (type === "seriesChartType") {
        if (this.chartController.isComboChart()) {
          return this.seriesChartTypePanel = this.createBean(new SeriesChartTypePanel(
            this.chartController,
            valueCols,
            isOpen
          ));
        }
        return null;
      } else {
        _112.warnOnce(`Invalid charts data panel group name supplied: '${type}'`);
        return null;
      }
    }).filter((value) => value != null);
    if (panels)
      this.addPanelComponents(panels);
  }
  addPanelComponents(panels) {
    var _a;
    const fragment = document.createDocumentFragment();
    for (const panel of panels) {
      this.registerComponent(panel);
      fragment.appendChild(panel.getGui());
    }
    const afterPanelElement = (_a = this.switchCategorySeriesPanel) == null ? void 0 : _a.getGui();
    this.getGui().insertBefore(fragment, afterPanelElement != null ? afterPanelElement : null);
    return panels;
  }
  clearPanelComponents() {
    const eGui = this.getGui();
    if (this.categoriesDataPanel)
      eGui.removeChild(this.categoriesDataPanel.getGui());
    if (this.seriesDataPanel)
      eGui.removeChild(this.seriesDataPanel.getGui());
    if (this.seriesChartTypePanel)
      eGui.removeChild(this.seriesChartTypePanel.getGui());
    this.categoriesDataPanel = this.destroyBean(this.categoriesDataPanel);
    this.seriesDataPanel = this.destroyBean(this.seriesDataPanel);
    this.seriesChartTypePanel = this.destroyBean(this.seriesChartTypePanel);
  }
  createAutoScrollService() {
    const eGui = this.getGui();
    this.autoScrollService = new AutoScrollService4({
      scrollContainer: eGui,
      scrollAxis: "y",
      getVerticalPosition: () => eGui.scrollTop,
      setVerticalPosition: (position) => eGui.scrollTop = position
    });
  }
  addComponent(component) {
    this.registerComponent(component);
    this.getGui().appendChild(component.getGui());
    return component;
  }
  registerComponent(component) {
    component.addCssClass("ag-chart-data-section");
  }
  getDataPanelDef() {
    var _a, _b;
    return (_b = (_a = this.gos.get("chartToolPanelsDef")) == null ? void 0 : _a.dataPanel) != null ? _b : DefaultDataPanelDef;
  }
  getCategoryGroupTitle(isCategorySeriesSwitched) {
    if (isCategorySeriesSwitched)
      return this.chartTranslationService.translate("seriesLabels");
    return this.chartTranslationService.translate(this.chartController.isActiveXYChart() ? "labels" : "categories");
  }
  getCategoryGroupMultipleSelect(chartType, isCategorySeriesSwitched) {
    if (isCategorySeriesSwitched)
      return false;
    return getMaxNumCategories(chartType) !== 1;
  }
  getSeriesGroupTitle(isCategorySeriesSwitched) {
    if (isCategorySeriesSwitched)
      return this.chartTranslationService.translate("categoryValues");
    return this.chartTranslationService.translate(this.chartController.isActiveXYChart() ? "xyValues" : "series");
  }
  getSeriesGroupMultipleSelect(chartType, isCategorySeriesSwitched) {
    return this.getSeriesGroupMaxSelection(chartType, isCategorySeriesSwitched) !== 1;
  }
  getSeriesGroupMaxSelection(chartType, isCategorySeriesSwitched) {
    if (isCategorySeriesSwitched)
      return void 0;
    return getMaxNumSeries(chartType);
  }
};
_ChartDataPanel.TEMPLATE = /* html */
`<div class="ag-chart-data-wrapper ag-scrollable-container"></div>`;
__decorateClass4([
  Autowired102("chartTranslationService")
], _ChartDataPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  Autowired102("chartMenuService")
], _ChartDataPanel.prototype, "chartMenuService", 2);
__decorateClass4([
  PostConstruct102
], _ChartDataPanel.prototype, "init", 1);
var ChartDataPanel = _ChartDataPanel;
var _FontPanel = class _FontPanel2 extends Component62 {
  constructor(params) {
    super();
    this.activeComps = [];
    this.params = params;
    this.chartOptions = params.chartMenuUtils.getChartOptions();
  }
  init() {
    const fontGroupParams = {
      cssIdentifier: "charts-format-sub-level",
      direction: "vertical",
      suppressOpenCloseIcons: true,
      title: this.params.name || this.chartTranslationService.translate("font"),
      enabled: this.params.enabled,
      suppressEnabledCheckbox: !!this.params.suppressEnabledCheckbox,
      onEnableChange: (enabled) => {
        if (this.params.onEnableChange) {
          this.params.onEnableChange(enabled);
        }
      }
    };
    this.setTemplate(_FontPanel2.TEMPLATE, {
      fontGroup: fontGroupParams,
      familySelect: this.getFamilySelectParams(),
      weightStyleSelect: this.getWeightStyleSelectParams(),
      sizeSelect: this.getSizeSelectParams(),
      colorPicker: this.params.chartMenuUtils.getDefaultColorPickerParams(this.params.keyMapper("color"))
    });
  }
  addCompToPanel(comp) {
    this.fontGroup.addItem(comp);
    this.activeComps.push(comp);
  }
  setEnabled(enabled) {
    this.fontGroup.setEnabled(enabled);
  }
  getFamilySelectParams() {
    const families = [
      "Arial, sans-serif",
      "Aria Black, sans-serif",
      "Book Antiqua,  serif",
      "Charcoal, sans-serif",
      "Comic Sans MS, cursive",
      "Courier, monospace",
      "Courier New, monospace",
      "Gadget, sans-serif",
      "Geneva, sans-serif",
      "Helvetica, sans-serif",
      "Impact, sans-serif",
      "Lucida Console, monospace",
      "Lucida Grande, sans-serif",
      "Lucida Sans Unicode,  sans-serif",
      "Monaco, monospace",
      "Palatino Linotype, serif",
      "Palatino, serif",
      "Times New Roman, serif",
      "Times, serif",
      "Verdana, sans-serif"
    ];
    const family = this.getInitialFontValue("fontFamily");
    let initialValue = families[0];
    if (family) {
      const lowerCaseValues = families.map((f) => f.toLowerCase());
      const valueIndex = lowerCaseValues.indexOf(family.toLowerCase());
      if (valueIndex >= 0) {
        initialValue = families[valueIndex];
      } else {
        const capitalisedFontValue = _122.capitalise(family);
        families.push(capitalisedFontValue);
        initialValue = capitalisedFontValue;
      }
    }
    const options = families.sort().map((value) => ({ value, text: value }));
    return {
      options,
      inputWidth: "flex",
      value: `${initialValue}`,
      onValueChange: (newValue) => this.setFont({ fontFamily: newValue })
    };
  }
  getSizeSelectParams() {
    const sizes = [8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36];
    const size = this.getInitialFontValue("fontSize");
    if (!_122.includes(sizes, size)) {
      sizes.push(size);
    }
    const options = sizes.sort((a, b) => a - b).map((value) => ({ value: `${value}`, text: `${value}` }));
    return {
      options,
      inputWidth: "flex",
      value: `${size}`,
      onValueChange: (newValue) => this.setFont({ fontSize: parseInt(newValue, 10) }),
      label: this.chartTranslationService.translate("size")
    };
  }
  getWeightStyleSelectParams() {
    var _a, _b;
    const weight = (_a = this.getInitialFontValue("fontWeight")) != null ? _a : "normal";
    const style = (_b = this.getInitialFontValue("fontStyle")) != null ? _b : "normal";
    const weightStyles = [
      { name: "normal", weight: "normal", style: "normal" },
      { name: "bold", weight: "bold", style: "normal" },
      { name: "italic", weight: "normal", style: "italic" },
      { name: "boldItalic", weight: "bold", style: "italic" }
    ];
    let selectedOption = weightStyles.find((x) => x.weight === weight && x.style === style);
    if (!selectedOption) {
      selectedOption = { name: "predefined", weight, style };
      weightStyles.unshift(selectedOption);
    }
    const options = weightStyles.map((ws) => ({
      value: ws.name,
      text: this.chartTranslationService.translate(ws.name)
    }));
    return {
      options,
      inputWidth: "flex",
      value: selectedOption.name,
      onValueChange: (newValue) => {
        const selectedWeightStyle = weightStyles.find((x) => x.name === newValue);
        this.setFont({ fontWeight: selectedWeightStyle.weight, fontStyle: selectedWeightStyle.style });
      }
    };
  }
  addItemToPanel(item) {
    this.fontGroup.addItem(item);
    this.activeComps.push(item);
  }
  destroyActiveComps() {
    this.activeComps.forEach((comp) => {
      _122.removeFromParent(comp.getGui());
      this.destroyBean(comp);
    });
  }
  destroy() {
    this.destroyActiveComps();
    super.destroy();
  }
  setFont(font) {
    const { keyMapper } = this.params;
    Object.entries(font).forEach(([fontKey, value]) => {
      if (value) {
        this.chartOptions.setValue(keyMapper(fontKey), value);
      }
    });
  }
  getInitialFontValue(fontKey) {
    const { keyMapper } = this.params;
    return this.chartOptions.getValue(keyMapper(fontKey));
  }
};
_FontPanel.TEMPLATE = /* html */
`<div class="ag-font-panel">
            <ag-group-component ref="fontGroup">
                <ag-select ref="familySelect"></ag-select>
                <ag-select ref="weightStyleSelect"></ag-select>
                <div class="ag-charts-font-size-color">
                    <ag-select ref="sizeSelect"></ag-select>
                    <ag-color-picker ref="colorPicker"></ag-color-picker>
                </div>
            </ag-group-component>
        </div>`;
__decorateClass4([
  RefSelector6("fontGroup")
], _FontPanel.prototype, "fontGroup", 2);
__decorateClass4([
  Autowired112("chartTranslationService")
], _FontPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct112
], _FontPanel.prototype, "init", 1);
var FontPanel = _FontPanel;
var _LegendPanel = class _LegendPanel2 extends Component72 {
  constructor({ chartMenuParamsFactory: chartMenuUtils, isExpandedOnInit = false }) {
    super();
    this.chartMenuUtils = chartMenuUtils;
    this.isExpandedOnInit = isExpandedOnInit;
  }
  init() {
    const legendGroupParams = this.chartMenuUtils.addEnableParams(
      "legend.enabled",
      {
        cssIdentifier: "charts-format-top-level",
        direction: "vertical",
        title: this.chartTranslationService.translate("legend"),
        suppressEnabledCheckbox: false,
        suppressToggleExpandOnEnableChange: true,
        expanded: this.isExpandedOnInit,
        items: [this.createLabelPanel()]
      }
    );
    this.setTemplate(_LegendPanel2.TEMPLATE, {
      legendGroup: legendGroupParams,
      legendPositionSelect: this.chartMenuUtils.getDefaultLegendParams("legend.position"),
      legendPaddingSlider: this.getSliderParams("spacing", "spacing", 200),
      markerSizeSlider: this.getSliderParams("item.marker.size", "markerSize", 40),
      markerStrokeSlider: this.getSliderParams("item.marker.strokeWidth", "markerStroke", 10),
      markerPaddingSlider: this.getSliderParams("item.marker.padding", "itemSpacing", 20),
      itemPaddingXSlider: this.getSliderParams("item.paddingX", "layoutHorizontalSpacing", 50),
      itemPaddingYSlider: this.getSliderParams("item.paddingY", "layoutVerticalSpacing", 50)
    });
  }
  getSliderParams(expression, labelKey, defaultMaxValue) {
    return this.chartMenuUtils.getDefaultSliderParams(`legend.${expression}`, labelKey, defaultMaxValue);
  }
  createLabelPanel() {
    const params = {
      enabled: true,
      suppressEnabledCheckbox: true,
      chartMenuUtils: this.chartMenuUtils,
      keyMapper: (key) => `legend.item.label.${key}`
    };
    return this.createManagedBean(new FontPanel(params));
  }
};
_LegendPanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="legendGroup">
                <ag-select ref="legendPositionSelect"></ag-select>
                <ag-slider ref="legendPaddingSlider"></ag-slider>
                <ag-slider ref="markerSizeSlider"></ag-slider>
                <ag-slider ref="markerStrokeSlider"></ag-slider>
                <ag-slider ref="markerPaddingSlider"></ag-slider>
                <ag-slider ref="itemPaddingXSlider"></ag-slider>
                <ag-slider ref="itemPaddingYSlider"></ag-slider>
            </ag-group-component>
        </div>`;
__decorateClass4([
  Autowired122("chartTranslationService")
], _LegendPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct122
], _LegendPanel.prototype, "init", 1);
var LegendPanel = _LegendPanel;
var _AxisTicksPanel = class _AxisTicksPanel2 extends Component82 {
  constructor(chartMenuUtils) {
    super();
    this.chartMenuUtils = chartMenuUtils;
  }
  init() {
    const axisTicksGroupParams = this.chartMenuUtils.addEnableParams(
      "tick.enabled",
      {
        cssIdentifier: "charts-format-sub-level",
        direction: "vertical",
        suppressOpenCloseIcons: true,
        title: this.chartTranslationService.translate("ticks"),
        suppressEnabledCheckbox: false
      }
    );
    const axisTicksColorPickerParams = this.chartMenuUtils.getDefaultColorPickerParams("tick.color");
    const axisTicksWidthSliderParams = this.chartMenuUtils.getDefaultSliderParams("tick.width", "width", 10);
    const axisTicksSizeSliderParams = this.chartMenuUtils.getDefaultSliderParams("tick.size", "length", 30);
    this.setTemplate(_AxisTicksPanel2.TEMPLATE, {
      axisTicksGroup: axisTicksGroupParams,
      axisTicksColorPicker: axisTicksColorPickerParams,
      axisTicksWidthSlider: axisTicksWidthSliderParams,
      axisTicksSizeSlider: axisTicksSizeSliderParams
    });
  }
};
_AxisTicksPanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="axisTicksGroup">
                <ag-color-picker ref="axisTicksColorPicker"></ag-color-picker>
                <ag-slider ref="axisTicksWidthSlider"></ag-slider>
                <ag-slider ref="axisTicksSizeSlider"></ag-slider>
            </ag-group-component>
        </div>`;
__decorateClass4([
  Autowired132("chartTranslationService")
], _AxisTicksPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct132
], _AxisTicksPanel.prototype, "init", 1);
var AxisTicksPanel = _AxisTicksPanel;
var _GridLinePanel = class _GridLinePanel2 extends Component92 {
  constructor(chartMenuUtils) {
    super();
    this.chartMenuUtils = chartMenuUtils;
    this.chartOptions = chartMenuUtils.getChartOptions();
  }
  init() {
    const gridLineGroupParams = this.chartMenuUtils.addEnableParams("gridLine.enabled", {
      cssIdentifier: "charts-format-sub-level",
      direction: "vertical",
      suppressOpenCloseIcons: true,
      title: this.chartTranslationService.translate("gridLines"),
      suppressEnabledCheckbox: false
    });
    const gridLineColorPickerParams = this.getGridLineColorPickerParams("color");
    const gridLineWidthSliderParams = this.getGridLineWidthSliderParams("thickness");
    const gridLineLineDashSliderParams = this.getGridLineDashSliderParams("lineDash");
    this.setTemplate(_GridLinePanel2.TEMPLATE, {
      gridLineGroup: gridLineGroupParams,
      gridLineColorPicker: gridLineColorPickerParams,
      gridLineWidthSlider: gridLineWidthSliderParams,
      gridLineLineDashSlider: gridLineLineDashSliderParams
    });
  }
  getGridLineColorPickerParams(labelKey) {
    return this.chartMenuUtils.getDefaultColorPickerParams(
      "gridLine.style",
      labelKey,
      {
        formatInputValue: (value) => {
          var _a;
          return (_a = value == null ? void 0 : value[0]) == null ? void 0 : _a.stroke;
        },
        parseInputValue: (value) => {
          var _a;
          const styles = (_a = this.chartOptions.getValue("gridLine.style")) != null ? _a : [];
          if (styles.length === 0)
            return [{ stroke: value, lineDash: [] }];
          return [__spreadProps3(__spreadValues3({}, styles[0]), { stroke: value })];
        }
      }
    );
  }
  getGridLineWidthSliderParams(labelKey) {
    return this.chartMenuUtils.getDefaultSliderParams("gridLine.width", labelKey, 10);
  }
  getGridLineDashSliderParams(labelKey) {
    var _a, _b;
    const initialStyles = this.chartOptions.getValue("gridLine.style");
    const initialValue = (_b = (_a = initialStyles == null ? void 0 : initialStyles[0]) == null ? void 0 : _a.lineDash) == null ? void 0 : _b[0];
    const params = this.chartMenuUtils.getDefaultSliderParamsWithoutValueParams(
      initialValue != null ? initialValue : 0,
      labelKey,
      30
    );
    params.onValueChange = (value) => {
      const stroke = this.chartOptions.getValue("gridLine.style.0.stroke");
      this.chartOptions.setValue(
        "gridLine.style",
        [{ lineDash: [value], stroke }]
      );
    };
    return params;
  }
};
_GridLinePanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="gridLineGroup">
                <ag-color-picker ref="gridLineColorPicker"></ag-color-picker>
                <ag-slider ref="gridLineWidthSlider"></ag-slider>
                <ag-slider ref="gridLineLineDashSlider"></ag-slider>
            </ag-group-component>
        </div>`;
__decorateClass4([
  Autowired14("chartTranslationService")
], _GridLinePanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct14
], _GridLinePanel.prototype, "init", 1);
var GridLinePanel = _GridLinePanel;
var _AgAngleSelect = class _AgAngleSelect2 extends AgAbstractLabel {
  constructor(config) {
    super(config, _AgAngleSelect2.TEMPLATE);
    this.radius = 0;
    this.offsetX = 0;
    this.offsetY = 0;
  }
  postConstruct() {
    super.postConstruct();
    const { value, onValueChange } = this.config;
    if (value != null) {
      this.setValue(value, void 0, true);
    }
    if (onValueChange != null) {
      this.onValueChange(onValueChange);
    }
    this.dragListener = {
      eElement: this.eParentCircle,
      dragStartPixels: 0,
      onDragStart: (e) => {
        this.parentCircleRect = this.eParentCircle.getBoundingClientRect();
      },
      onDragging: (e) => this.calculateAngleDrag(e),
      onDragStop: () => {
      }
    };
    this.dragService.addDragSource(this.dragListener);
    this.eAngleValue.setLabel("").setLabelWidth(5).setInputWidth(45).setMin(0).setMax(360).setValue(`${this.degrees}`).onValueChange((value2) => {
      if (value2 == null || value2 === "") {
        value2 = "0";
      }
      value2 = this.eAngleValue.normalizeValue(value2);
      let floatValue = parseFloat(value2);
      if (floatValue > 180) {
        floatValue = floatValue - 360;
      }
      this.setValue(floatValue);
    });
    this.updateNumberInput();
    if (_142.exists(this.getValue())) {
      this.eAngleValue.setValue(this.normalizeNegativeValue(this.getValue()).toString());
    }
    this.addManagedListener(this, Events42.EVENT_FIELD_VALUE_CHANGED, () => {
      if (this.eAngleValue.getInputElement().contains(this.gos.getActiveDomElement())) {
        return;
      }
      this.updateNumberInput();
    });
  }
  updateNumberInput() {
    const normalizedValue = this.normalizeNegativeValue(this.getValue());
    this.eAngleValue.setValue(normalizedValue.toString());
  }
  positionChildCircle(radians) {
    const rect = this.parentCircleRect || { width: 24, height: 24 };
    const eChildCircle = this.eChildCircle;
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    eChildCircle.style.left = `${centerX + Math.cos(radians) * 8}px`;
    eChildCircle.style.top = `${centerY + Math.sin(radians) * 8}px`;
  }
  calculatePolar() {
    const x = this.offsetX;
    const y = this.offsetY;
    const radians = Math.atan2(y, x);
    this.degrees = this.toDegrees(radians);
    this.radius = Math.sqrt(x * x + y * y);
    this.positionChildCircle(radians);
  }
  calculateCartesian() {
    const radians = this.toRadians(this.getValue());
    const radius = this.getRadius();
    this.setOffsetX(Math.cos(radians) * radius).setOffsetY(Math.sin(radians) * radius);
  }
  setOffsetX(offset) {
    if (this.offsetX !== offset) {
      this.offsetX = offset;
      this.calculatePolar();
    }
    return this;
  }
  setOffsetY(offset) {
    if (this.offsetY !== offset) {
      this.offsetY = offset;
      this.calculatePolar();
    }
    return this;
  }
  calculateAngleDrag(e) {
    const rect = this.parentCircleRect;
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const dx = x - centerX;
    const dy = y - centerY;
    const radians = Math.atan2(dy, dx);
    this.setValue(radians, true);
  }
  toDegrees(radians) {
    return radians / Math.PI * 180;
  }
  toRadians(degrees) {
    return degrees / 180 * Math.PI;
  }
  normalizeNegativeValue(degrees) {
    return degrees < 0 ? 360 + degrees : degrees;
  }
  normalizeAngle180(radians) {
    radians %= Math.PI * 2;
    if (radians < -Math.PI) {
      radians += Math.PI * 2;
    } else if (radians >= Math.PI) {
      radians -= Math.PI * 2;
    }
    return radians;
  }
  getRadius() {
    return this.radius;
  }
  setRadius(r) {
    if (this.radius === r) {
      return this;
    }
    this.radius = r;
    this.calculateCartesian();
    return this;
  }
  onValueChange(callbackFn) {
    this.addManagedListener(this, Events42.EVENT_FIELD_VALUE_CHANGED, () => {
      callbackFn(this.degrees);
    });
    return this;
  }
  getValue(radians) {
    return radians ? this.toRadians(this.degrees) : this.degrees;
  }
  setValue(degrees, radians, silent) {
    let radiansValue;
    if (!radians) {
      radiansValue = this.normalizeAngle180(this.toRadians(degrees));
    } else {
      radiansValue = degrees;
    }
    degrees = this.toDegrees(radiansValue);
    if (this.degrees !== degrees) {
      this.degrees = Math.floor(degrees);
      this.calculateCartesian();
      this.positionChildCircle(radiansValue);
      if (!silent) {
        this.dispatchEvent({ type: Events42.EVENT_FIELD_VALUE_CHANGED });
      }
    }
    return this;
  }
  setWidth(width) {
    _142.setFixedWidth(this.getGui(), width);
    return this;
  }
  setDisabled(disabled) {
    super.setDisabled(disabled);
    this.eAngleValue.setDisabled(disabled);
    return this;
  }
  destroy() {
    this.dragService.removeDragSource(this.dragListener);
    super.destroy();
  }
};
_AgAngleSelect.TEMPLATE = /* html */
`<div class="ag-angle-select">
            <div ref="eLabel"></div>
            <div class="ag-wrapper ag-angle-select-wrapper">
                <div ref="eAngleSelectField" class="ag-angle-select-field">
                    <div ref="eParentCircle" class="ag-angle-select-parent-circle">
                        <div ref="eChildCircle" class="ag-angle-select-child-circle"></div>
                    </div>
                </div>
                <ag-input-number-field ref="eAngleValue"></ag-input-number-field>
            </div>
        </div>`;
__decorateClass4([
  RefSelector22("eLabel")
], _AgAngleSelect.prototype, "eLabel", 2);
__decorateClass4([
  RefSelector22("eParentCircle")
], _AgAngleSelect.prototype, "eParentCircle", 2);
__decorateClass4([
  RefSelector22("eChildCircle")
], _AgAngleSelect.prototype, "eChildCircle", 2);
__decorateClass4([
  RefSelector22("eAngleValue")
], _AgAngleSelect.prototype, "eAngleValue", 2);
__decorateClass4([
  Autowired15("dragService")
], _AgAngleSelect.prototype, "dragService", 2);
var AgAngleSelect = _AgAngleSelect;
var ChartMenuParamsFactory = class extends BeanStub62 {
  constructor(chartOptionsProxy) {
    super();
    this.chartOptionsProxy = chartOptionsProxy;
  }
  getDefaultColorPickerParams(expression, labelKey, options) {
    return this.addValueParams(
      expression,
      {
        label: this.chartTranslationService.translate(labelKey != null ? labelKey : "color"),
        labelWidth: "flex",
        inputWidth: "flex"
      },
      options
    );
  }
  getDefaultNumberInputParams(expression, labelKey, options) {
    return this.addValueParams(
      expression,
      {
        label: this.chartTranslationService.translate(labelKey),
        labelWidth: "flex",
        inputWidth: "flex",
        precision: options == null ? void 0 : options.precision,
        step: options == null ? void 0 : options.step,
        min: options == null ? void 0 : options.min,
        max: options == null ? void 0 : options.max
      },
      {
        parseInputValue: (value) => {
          const numberValue = Number(value);
          return isNaN(numberValue) ? void 0 : numberValue;
        },
        formatInputValue: (value) => {
          return value == null ? "" : `${value}`;
        }
      }
    );
  }
  getDefaultSliderParams(expression, labelKey, defaultMaxValue, isArray) {
    var _a;
    let value = (_a = this.chartOptionsProxy.getValue(expression)) != null ? _a : 0;
    if (isArray && Array.isArray(value)) {
      value = value[0];
    }
    const params = this.getDefaultSliderParamsWithoutValueParams(value, labelKey, defaultMaxValue);
    params.onValueChange = (value2) => this.chartOptionsProxy.setValue(expression, isArray ? [value2] : value2);
    return params;
  }
  getDefaultSliderParamsWithoutValueParams(value, labelKey, defaultMaxValue) {
    return {
      label: this.chartTranslationService.translate(labelKey),
      minValue: 0,
      maxValue: Math.max(value, defaultMaxValue),
      textFieldWidth: 45,
      value: `${value}`
    };
  }
  getDefaultCheckboxParams(expression, labelKey, options) {
    const value = this.chartOptionsProxy.getValue(expression);
    const params = {
      label: this.chartTranslationService.translate(labelKey),
      value,
      readOnly: options == null ? void 0 : options.readOnly,
      passive: options == null ? void 0 : options.passive
    };
    params.onValueChange = (value2) => {
      this.chartOptionsProxy.setValue(expression, typeof value2 === "boolean" ? value2 : void 0);
    };
    return params;
  }
  getDefaultSelectParams(expression, labelKey, dropdownOptions, options) {
    const value = this.chartOptionsProxy.getValue(expression);
    const params = {
      label: this.chartTranslationService.translate(labelKey),
      value,
      options: dropdownOptions,
      pickerType: options == null ? void 0 : options.pickerType,
      pickerAriaLabelKey: options == null ? void 0 : options.pickerAriaLabelKey,
      pickerAriaLabelValue: options == null ? void 0 : options.pickerAriaLabelValue
    };
    params.onValueChange = (value2) => {
      this.chartOptionsProxy.setValue(expression, value2);
    };
    return params;
  }
  getDefaultLegendParams(expression) {
    return this.addValueParams(
      expression,
      {
        label: this.chartTranslationService.translate("position"),
        labelWidth: "flex",
        inputWidth: "flex",
        options: ["top", "right", "bottom", "left"].map((position) => ({
          value: position,
          text: this.chartTranslationService.translate(position)
        }))
      }
    );
  }
  getDefaultFontPanelParams(expression, labelKey) {
    const keyMapper = (key) => `${expression}.${key}`;
    return this.addEnableParams(
      keyMapper("enabled"),
      {
        name: this.chartTranslationService.translate(labelKey),
        suppressEnabledCheckbox: false,
        chartMenuUtils: this,
        keyMapper
      }
    );
  }
  addValueParams(expression, params, options) {
    const optionsValue = this.chartOptionsProxy.getValue(expression);
    params.value = (options == null ? void 0 : options.formatInputValue) ? options.formatInputValue(optionsValue) : optionsValue;
    params.onValueChange = (value) => {
      const optionsValue2 = (options == null ? void 0 : options.parseInputValue) ? options.parseInputValue(value) : value;
      this.chartOptionsProxy.setValue(expression, optionsValue2);
    };
    return params;
  }
  addEnableParams(expression, params) {
    var _a;
    params.enabled = (_a = this.chartOptionsProxy.getValue(expression)) != null ? _a : false;
    params.onEnableChange = (value) => this.chartOptionsProxy.setValue(expression, value);
    return params;
  }
  getChartOptions() {
    return this.chartOptionsProxy;
  }
};
__decorateClass4([
  Autowired16("chartTranslationService")
], ChartMenuParamsFactory.prototype, "chartTranslationService", 2);
var DEFAULT_TIME_AXIS_FORMAT = "%d %B %Y";
var _CartesianAxisPanel = class _CartesianAxisPanel2 extends Component102 {
  constructor(axisType, { chartController, chartOptionsService, isExpandedOnInit = false, seriesType }) {
    super();
    this.activePanels = [];
    this.axisLabelUpdateFuncs = [];
    this.axisType = axisType;
    this.chartController = chartController;
    this.chartAxisOptionsProxy = chartOptionsService.getCartesianAxisOptionsProxy(axisType);
    this.chartAxisThemeOverridesProxy = chartOptionsService.getCartesianAxisThemeOverridesProxy(axisType);
    this.chartAxisAppliedThemeOverridesProxy = chartOptionsService.getCartesianAxisAppliedThemeOverridesProxy(axisType);
    this.chartOptionsSeriesProxy = chartOptionsService.getSeriesOptionsProxy(() => seriesType != null ? seriesType : this.chartController.getChartSeriesType());
    this.chartOptionsService = chartOptionsService;
    this.isExpandedOnInit = isExpandedOnInit;
  }
  init() {
    var _a;
    const labelKey = this.axisType;
    const axisGroupParams = {
      cssIdentifier: "charts-format-top-level",
      direction: "vertical",
      title: this.translate(labelKey),
      expanded: this.isExpandedOnInit,
      suppressEnabledCheckbox: true
    };
    const chartAxisOptions = this.createManagedBean(new ChartMenuParamsFactory(this.chartAxisOptionsProxy));
    const chartAxisThemeOverrides = this.createManagedBean(new ChartMenuParamsFactory(this.chartAxisThemeOverridesProxy));
    const axisTypeSelectParams = this.getAxisTypeSelectParams(chartAxisOptions, this.chartAxisAppliedThemeOverridesProxy);
    const axisPositionSelectParams = this.getAxisPositionSelectParams(chartAxisOptions);
    const axisTimeFormatSelectParams = this.getAxisTimeFormatSelectParams(chartAxisOptions);
    const axisColorInputParams = this.getAxisColorInputParams(chartAxisThemeOverrides);
    const axisLineWidthSliderParams = this.getAxisLineWidthSliderParams(chartAxisThemeOverrides);
    this.setTemplate(_CartesianAxisPanel2.TEMPLATE, {
      axisGroup: axisGroupParams,
      axisTypeSelect: axisTypeSelectParams != null ? axisTypeSelectParams : void 0,
      axisPositionSelect: axisPositionSelectParams != null ? axisPositionSelectParams : void 0,
      axisTimeFormatSelect: axisTimeFormatSelectParams != null ? axisTimeFormatSelectParams : void 0,
      axisColorInput: axisColorInputParams,
      axisLineWidthSlider: axisLineWidthSliderParams
    });
    this.axisTypeSelect.setDisplayed(!!((_a = axisTypeSelectParams.options) == null ? void 0 : _a.length));
    if (!axisPositionSelectParams)
      this.removeTemplateComponent(this.axisPositionSelect);
    const updateTimeFormatVisibility = () => {
      const isTimeAxis = this.chartAxisOptionsProxy.getValue("type") === "time";
      _152.setDisplayed(this.axisTimeFormatSelect.getGui(), isTimeAxis);
    };
    if (!axisTimeFormatSelectParams) {
      this.removeTemplateComponent(this.axisTimeFormatSelect);
    } else {
      updateTimeFormatVisibility();
      this.addManagedListener(this.eventService, Events52.EVENT_CHART_OPTIONS_CHANGED, (e) => {
        updateTimeFormatVisibility();
      });
    }
    this.initGridLines(chartAxisThemeOverrides);
    this.initAxisTicks(chartAxisThemeOverrides);
    this.initAxisLabels(chartAxisThemeOverrides);
    const updateAxisLabelRotations = () => this.axisLabelUpdateFuncs.forEach((func) => func());
    this.addManagedListener(this.chartController, ChartController.EVENT_CHART_UPDATED, updateAxisLabelRotations);
    this.addManagedListener(this.chartController, ChartController.EVENT_CHART_MODEL_UPDATE, () => setTimeout(() => {
      this.refreshAxisTypeSelect(chartAxisOptions);
      updateTimeFormatVisibility();
    }));
  }
  getAxisTypeSelectParams(chartAxisOptions, chartAxisAppliedThemeOverrides) {
    const chartOptions = chartAxisOptions.getChartOptions();
    const axisTypeSelectOptions = this.getAxisTypeSelectOptions();
    const params = chartAxisOptions.getDefaultSelectParams(
      "type",
      "axisType",
      axisTypeSelectOptions
    );
    params.onValueChange = (value) => {
      var _a;
      const previousAxisType = chartOptions.getValue("type");
      if (value === previousAxisType)
        return;
      const previousAxisThemeOverrides = chartAxisAppliedThemeOverrides.getValue("*");
      const previousAxisIsTimeAxis = previousAxisType === "time";
      const updatedAxisIsTimeAxis = value === "time";
      const updatedLabelFormat = previousAxisIsTimeAxis !== updatedAxisIsTimeAxis ? updatedAxisIsTimeAxis ? DEFAULT_TIME_AXIS_FORMAT : void 0 : null;
      this.chartOptionsService.setCartesianCategoryAxisType(this.axisType, value);
      if (updatedLabelFormat !== null) {
        const existingLabel = (_a = chartOptions.getValue("label")) != null ? _a : {};
        chartOptions.setValue("label", __spreadProps3(__spreadValues3({}, existingLabel), { format: updatedLabelFormat }));
      }
      chartAxisAppliedThemeOverrides.setValue("*", previousAxisThemeOverrides);
    };
    return params;
  }
  refreshAxisTypeSelect(chartAxisOptions) {
    const options = this.getAxisTypeSelectOptions();
    const hasOptions = !!options.length;
    this.axisTypeSelect.setDisplayed(hasOptions);
    if (!hasOptions) {
      return;
    }
    this.axisTypeSelect.clearOptions().addOptions(options).setValue(chartAxisOptions.getChartOptions().getValue("type"));
  }
  getAxisTypeSelectOptions() {
    const chartType = this.chartController.getChartType();
    const supportsNumericalAxis = () => {
      const testDatum = this.chartController.getChartData()[0];
      if (!testDatum) {
        return false;
      }
      return this.chartController.getSelectedDimensions().every((col) => !isNaN(parseFloat(testDatum[col.colId])));
    };
    if ([
      "heatmap",
      "histogram",
      "boxPlot",
      "rangeBar",
      "scatter",
      "bubble"
    ].includes(chartType) || this.chartController.isGrouping() || !this.isCategoryAxis() || this.chartController.isCategorySeriesSwitched() || !supportsNumericalAxis()) {
      return [];
    }
    return ["category", "number", "time"].map((value) => ({
      value,
      text: this.translate(value)
    }));
  }
  isCategoryAxis() {
    const isHorizontal = this.chartOptionsSeriesProxy.getValue("direction") === "horizontal";
    return isHorizontal && this.axisType === "yAxis" || !isHorizontal && this.axisType === "xAxis";
  }
  getAxisPositionSelectParams(chartAxisOptions) {
    const axisPositionSelectOptions = ((chartType, axisType) => {
      switch (chartType) {
        case "heatmap":
          return null;
        default:
          switch (axisType) {
            case "xAxis":
              return [
                { value: "top", text: this.translate("top") },
                { value: "bottom", text: this.translate("bottom") }
              ];
            case "yAxis":
              return [
                { value: "left", text: this.translate("left") },
                { value: "right", text: this.translate("right") }
              ];
          }
      }
    })(this.chartController.getChartType(), this.axisType);
    if (!axisPositionSelectOptions)
      return null;
    return chartAxisOptions.getDefaultSelectParams(
      "position",
      "position",
      axisPositionSelectOptions
    );
  }
  getAxisTimeFormatSelectParams(chartAxisOptions) {
    if (!this.isCategoryAxis()) {
      return null;
    }
    const axisTimeFormatSelectOptions = [
      { value: "%d/%m/%Y", text: this.translate("timeFormatSlashesDDMMYYYY") },
      { value: "%m/%d/%Y", text: this.translate("timeFormatSlashesMMDDYYYY") },
      { value: "%d/%m/%y", text: this.translate("timeFormatSlashesDDMMYY") },
      { value: "%m/%d/%y", text: this.translate("timeFormatSlashesMMDDYY") },
      { value: "%d.%e.%y", text: this.translate("timeFormatDotsDDMYY") },
      { value: "%e.%d.%y", text: this.translate("timeFormatDotsMDDYY") },
      { value: "%Y-%m-%d", text: this.translate("timeFormatDashesYYYYMMDD") },
      { value: "%d %B %Y", text: this.translate("timeFormatSpacesDDMMMMYYYY") },
      { value: "%H:%M:%S", text: this.translate("timeFormatHHMMSS") },
      { value: "%I:%M:%S %p", text: this.translate("timeFormatHHMMSSAmPm") }
    ];
    return chartAxisOptions.getDefaultSelectParams(
      "label.format",
      "timeFormat",
      axisTimeFormatSelectOptions
    );
  }
  getAxisColorInputParams(chartAxisThemeOverrides) {
    return chartAxisThemeOverrides.getDefaultColorPickerParams("line.color");
  }
  getAxisLineWidthSliderParams(chartAxisThemeOverrides) {
    var _a;
    const chartOptions = chartAxisThemeOverrides.getChartOptions();
    const getAxisLineWidth = () => {
      const isAxisLineEnabled = chartOptions.getValue("line.enabled");
      if (!isAxisLineEnabled)
        return null;
      return chartOptions.getValue("line.width");
    };
    const setAxisLineWidth = (value) => {
      chartOptions.setValues([
        { expression: "line.enabled", value: value != null },
        { expression: "line.width", value: value != null ? value : 0 }
      ]);
    };
    const axisLineWidthSliderParams = chartAxisThemeOverrides.getDefaultSliderParamsWithoutValueParams(
      (_a = getAxisLineWidth()) != null ? _a : 0,
      "thickness",
      10
    );
    axisLineWidthSliderParams.onValueChange = (newValue) => {
      setAxisLineWidth(newValue === 0 ? null : newValue);
    };
    return axisLineWidthSliderParams;
  }
  initGridLines(chartAxisThemeOverrides) {
    const chartType = this.chartController.getChartType();
    switch (chartType) {
      case "heatmap":
        return;
      default:
        const gridLineComp = this.createBean(new GridLinePanel(chartAxisThemeOverrides));
        this.axisGroup.addItem(gridLineComp);
        this.activePanels.push(gridLineComp);
    }
  }
  initAxisTicks(chartAxisThemeOverrides) {
    if (!this.hasConfigurableAxisTicks())
      return;
    const axisTicksComp = this.createBean(new AxisTicksPanel(chartAxisThemeOverrides));
    this.axisGroup.addItem(axisTicksComp);
    this.activePanels.push(axisTicksComp);
  }
  hasConfigurableAxisTicks() {
    const chartType = this.chartController.getChartType();
    switch (chartType) {
      case "radarLine":
      case "radarArea":
      case "rangeBar":
      case "boxPlot":
      case "waterfall":
        return false;
      default:
        return true;
    }
  }
  initAxisLabels(chartAxisThemeOverrides) {
    const params = {
      name: this.translate("labels"),
      enabled: true,
      suppressEnabledCheckbox: true,
      chartMenuUtils: chartAxisThemeOverrides,
      keyMapper: (key) => `label.${key}`
    };
    const labelPanelComp = this.createBean(new FontPanel(params));
    this.axisGroup.addItem(labelPanelComp);
    this.activePanels.push(labelPanelComp);
    this.addAdditionalLabelComps(labelPanelComp, chartAxisThemeOverrides);
  }
  addAdditionalLabelComps(labelPanelComp, chartAxisThemeOverrides) {
    this.addLabelPadding(labelPanelComp, chartAxisThemeOverrides);
    const rotationComp = this.createRotationWidget("labelRotation", chartAxisThemeOverrides);
    const autoRotateCb = this.initLabelRotation(rotationComp, chartAxisThemeOverrides);
    labelPanelComp.addCompToPanel(autoRotateCb);
    labelPanelComp.addCompToPanel(rotationComp);
  }
  initLabelRotation(rotationComp, chartAxisThemeOverrides) {
    const chartOptions = chartAxisThemeOverrides.getChartOptions();
    const getLabelRotationValue = () => {
      return chartOptions.getValue("label.rotation");
    };
    const getLabelAutoRotateValue = () => {
      return chartOptions.getValue("label.autoRotate");
    };
    const updateAutoRotate = (autoRotate2) => {
      if (autoRotate2)
        this.prevRotation = getLabelRotationValue();
      chartOptions.setValues([
        { expression: "label.autoRotate", value: autoRotate2 },
        // Clear the rotation option when activating auto-rotate, reinstate the previous value when deactivating
        { expression: "label.rotation", value: autoRotate2 ? void 0 : this.prevRotation }
      ]);
      rotationComp.setDisabled(autoRotate2);
    };
    const rotation = getLabelRotationValue();
    const autoRotate = typeof rotation === "number" ? false : getLabelAutoRotateValue();
    const autoRotateCheckbox = this.createBean(new AgCheckbox5({
      label: this.translate("autoRotate"),
      value: autoRotate,
      onValueChange: updateAutoRotate
    }));
    rotationComp.setDisabled(autoRotate);
    return autoRotateCheckbox;
  }
  createRotationWidget(labelKey, chartAxisThemeOverrides) {
    var _a;
    const chartOptions = chartAxisThemeOverrides.getChartOptions();
    const getLabelRotationValue = () => {
      return chartOptions.getValue("label.rotation");
    };
    const setLabelRotationValue = (value) => {
      return chartOptions.setValue("label.rotation", value);
    };
    const degreesSymbol = String.fromCharCode(176);
    const label = `${this.chartTranslationService.translate(labelKey)} ${degreesSymbol}`;
    const angleSelect = new AgAngleSelect({
      label,
      labelWidth: "flex",
      value: (_a = getLabelRotationValue()) != null ? _a : 0,
      onValueChange: setLabelRotationValue
    });
    this.axisLabelUpdateFuncs.push(() => {
      var _a2;
      angleSelect.setValue((_a2 = getLabelRotationValue()) != null ? _a2 : 0);
    });
    return this.createBean(angleSelect);
  }
  addLabelPadding(labelPanelComp, chartAxisThemeOverrides) {
    const labelPaddingSlider = this.createBean(new AgSlider(chartAxisThemeOverrides.getDefaultSliderParams(
      "label.padding",
      "padding",
      30
    )));
    labelPanelComp.addCompToPanel(labelPaddingSlider);
  }
  translate(key) {
    return this.chartTranslationService.translate(key);
  }
  removeTemplateComponent(component) {
    _152.removeFromParent(component.getGui());
    this.destroyBean(component);
  }
  destroyActivePanels() {
    this.activePanels.forEach((panel) => {
      _152.removeFromParent(panel.getGui());
      this.destroyBean(panel);
    });
  }
  destroy() {
    this.destroyActivePanels();
    super.destroy();
  }
};
_CartesianAxisPanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="axisGroup">
                <ag-select ref="axisTypeSelect"></ag-select>
                <ag-select ref="axisTimeFormatSelect"></ag-select>
                <ag-select ref="axisPositionSelect"></ag-select>
                <ag-color-picker ref="axisColorInput"></ag-color-picker>
                <ag-slider ref="axisLineWidthSlider"></ag-slider>
            </ag-group-component>
        </div>`;
__decorateClass4([
  RefSelector33("axisGroup")
], _CartesianAxisPanel.prototype, "axisGroup", 2);
__decorateClass4([
  RefSelector33("axisTypeSelect")
], _CartesianAxisPanel.prototype, "axisTypeSelect", 2);
__decorateClass4([
  RefSelector33("axisPositionSelect")
], _CartesianAxisPanel.prototype, "axisPositionSelect", 2);
__decorateClass4([
  RefSelector33("axisTimeFormatSelect")
], _CartesianAxisPanel.prototype, "axisTimeFormatSelect", 2);
__decorateClass4([
  Autowired17("chartTranslationService")
], _CartesianAxisPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct15
], _CartesianAxisPanel.prototype, "init", 1);
var CartesianAxisPanel = _CartesianAxisPanel;
var _PolarAxisPanel = class _PolarAxisPanel2 extends Component11 {
  constructor({ chartController, chartAxisMenuParamsFactory: chartAxisMenuUtils, isExpandedOnInit = false }) {
    super();
    this.chartController = chartController;
    this.chartMenuUtils = chartAxisMenuUtils;
    this.isExpandedOnInit = isExpandedOnInit;
  }
  init() {
    const axisGroupParams = {
      cssIdentifier: "charts-format-top-level",
      direction: "vertical",
      title: this.translate("polarAxis"),
      expanded: this.isExpandedOnInit,
      suppressEnabledCheckbox: true
    };
    const axisColorInputParams = this.chartMenuUtils.getDefaultColorPickerParams("line.color");
    const axisLineWidthSliderParams = this.chartMenuUtils.getDefaultSliderParams("line.width", "thickness", 10);
    this.setTemplate(_PolarAxisPanel2.TEMPLATE, {
      axisGroup: axisGroupParams,
      axisColorInput: axisColorInputParams,
      axisLineWidthSlider: axisLineWidthSliderParams
    });
    this.initAxis();
    this.initAxisLabels();
    this.initRadiusAxis();
  }
  initAxis() {
    const chartType = this.chartController.getChartType();
    const hasConfigurableAxisShape = ["radarLine", "radarArea"].includes(chartType);
    if (hasConfigurableAxisShape) {
      const options = [
        { value: "circle", text: this.translate("circle") },
        { value: "polygon", text: this.translate("polygon") }
      ];
      this.axisGroup.addItem(this.createSelect({
        labelKey: "shape",
        options,
        property: "shape"
      }));
    }
    if (chartType !== "pie") {
      this.axisGroup.addItem(this.createSlider({
        labelKey: "innerRadius",
        defaultMaxValue: 1,
        property: "innerRadiusRatio"
      }));
    }
  }
  initAxisLabels() {
    const params = {
      name: this.translate("labels"),
      enabled: true,
      suppressEnabledCheckbox: true,
      chartMenuUtils: this.chartMenuUtils,
      keyMapper: (key) => `label.${key}`
    };
    const labelPanelComp = this.createManagedBean(new FontPanel(params));
    const labelOrientationComp = this.createOrientationWidget();
    labelPanelComp.addItemToPanel(labelOrientationComp);
    this.axisGroup.addItem(labelPanelComp);
  }
  createOrientationWidget() {
    const options = [
      { value: "fixed", text: this.translate("fixed") },
      { value: "parallel", text: this.translate("parallel") },
      { value: "perpendicular", text: this.translate("perpendicular") }
    ];
    return this.createSelect({
      labelKey: "orientation",
      options,
      property: "label.orientation"
    });
  }
  initRadiusAxis() {
    const chartSeriesType = getSeriesType(this.chartController.getChartType());
    if (!isRadial(chartSeriesType))
      return;
    const items = [
      this.createSlider({
        labelKey: "groupPadding",
        defaultMaxValue: 1,
        property: "paddingInner"
      }),
      this.createSlider({
        labelKey: "seriesPadding",
        defaultMaxValue: 1,
        property: "groupPaddingInner"
      })
    ];
    const paddingPanelComp = this.createManagedBean(new AgGroupComponent7({
      cssIdentifier: "charts-format-sub-level",
      direction: "vertical",
      suppressOpenCloseIcons: true,
      enabled: true,
      suppressEnabledCheckbox: true,
      title: this.translate("padding"),
      items
    })).hideEnabledCheckbox(true).hideOpenCloseIcons(true);
    this.axisGroup.addItem(paddingPanelComp);
  }
  createSlider(config) {
    const { labelKey, defaultMaxValue, step = 0.05, property } = config;
    const params = this.chartMenuUtils.getDefaultSliderParams(property, labelKey, defaultMaxValue);
    params.step = step;
    return this.createManagedBean(new AgSlider2(params));
  }
  createSelect(config) {
    const { labelKey: label, options, property } = config;
    return this.createManagedBean(new AgSelect6(this.chartMenuUtils.addValueParams(
      property,
      {
        label: this.chartTranslationService.translate(label),
        labelAlignment: "left",
        labelWidth: "flex",
        inputWidth: "flex",
        options
      }
    )));
  }
  translate(key) {
    return this.chartTranslationService.translate(key);
  }
};
_PolarAxisPanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="axisGroup">
                <ag-color-picker ref="axisColorInput"></ag-color-picker>
                <ag-slider ref="axisLineWidthSlider"></ag-slider>
            </ag-group-component>
        </div>`;
__decorateClass4([
  RefSelector42("axisGroup")
], _PolarAxisPanel.prototype, "axisGroup", 2);
__decorateClass4([
  Autowired18("chartTranslationService")
], _PolarAxisPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct16
], _PolarAxisPanel.prototype, "init", 1);
var PolarAxisPanel = _PolarAxisPanel;
var _PaddingPanel = class _PaddingPanel2 extends Component12 {
  constructor(chartMenuUtils, chartController) {
    super();
    this.chartMenuUtils = chartMenuUtils;
    this.chartController = chartController;
  }
  init() {
    const chartPaddingGroupParams = {
      cssIdentifier: "charts-format-sub-level",
      direction: "vertical",
      suppressOpenCloseIcons: true,
      title: this.chartTranslationService.translate("padding"),
      suppressEnabledCheckbox: true
    };
    const getSliderParams = (property) => this.chartMenuUtils.getDefaultSliderParams("padding." + property, property, 200);
    this.setTemplate(_PaddingPanel2.TEMPLATE, {
      chartPaddingGroup: chartPaddingGroupParams,
      paddingTopSlider: getSliderParams("top"),
      paddingRightSlider: getSliderParams("right"),
      paddingBottomSlider: getSliderParams("bottom"),
      paddingLeftSlider: getSliderParams("left")
    });
    this.addManagedListener(this.eventService, Events62.EVENT_CHART_OPTIONS_CHANGED, (e) => {
      this.updateTopPadding(e.chartOptions);
    });
  }
  updateTopPadding(chartOptions) {
    const topPadding = [...this.chartController.getChartSeriesTypes(), "common"].map((seriesType) => {
      var _a, _b;
      return (_b = (_a = chartOptions[seriesType]) == null ? void 0 : _a.padding) == null ? void 0 : _b.top;
    }).find((value) => value != null);
    if (topPadding != null) {
      this.paddingTopSlider.setValue(`${topPadding}`);
    }
  }
};
_PaddingPanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="chartPaddingGroup">
                <ag-slider ref="paddingTopSlider"></ag-slider>
                <ag-slider ref="paddingRightSlider"></ag-slider>
                <ag-slider ref="paddingBottomSlider"></ag-slider>
                <ag-slider ref="paddingLeftSlider"></ag-slider>
            </ag-group-component>
        <div>`;
__decorateClass4([
  RefSelector52("paddingTopSlider")
], _PaddingPanel.prototype, "paddingTopSlider", 2);
__decorateClass4([
  Autowired19("chartTranslationService")
], _PaddingPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct17
], _PaddingPanel.prototype, "init", 1);
var PaddingPanel = _PaddingPanel;
var _BackgroundPanel = class _BackgroundPanel2 extends Component13 {
  constructor(chartMenuUtils) {
    super();
    this.chartMenuUtils = chartMenuUtils;
  }
  init() {
    const chartBackgroundGroupParams = this.chartMenuUtils.addEnableParams(
      "background.visible",
      {
        cssIdentifier: "charts-format-sub-level",
        direction: "vertical",
        suppressOpenCloseIcons: true,
        title: this.chartTranslationService.translate("background"),
        suppressEnabledCheckbox: false
      }
    );
    const colorPickerParams = this.chartMenuUtils.getDefaultColorPickerParams("background.fill");
    this.setTemplate(_BackgroundPanel2.TEMPLATE, {
      chartBackgroundGroup: chartBackgroundGroupParams,
      colorPicker: colorPickerParams
    });
  }
};
_BackgroundPanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="chartBackgroundGroup">
                <ag-color-picker ref="colorPicker"></ag-color-picker>
            </ag-group-component>
        <div>`;
__decorateClass4([
  Autowired20("chartTranslationService")
], _BackgroundPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct18
], _BackgroundPanel.prototype, "init", 1);
var BackgroundPanel = _BackgroundPanel;
var _TitlePanel = class _TitlePanel2 extends Component14 {
  constructor(chartMenuUtils, chartController) {
    super(_TitlePanel2.TEMPLATE);
    this.chartMenuUtils = chartMenuUtils;
    this.chartController = chartController;
    this.activePanels = [];
    this.chartOptions = chartMenuUtils.getChartOptions();
  }
  init() {
    this.initFontPanel();
    this.titlePlaceholder = this.chartTranslationService.translate("titlePlaceholder");
  }
  hasTitle() {
    const title = this.chartOptions.getValue("title");
    return title && title.enabled && title.text && title.text.length > 0;
  }
  initFontPanel() {
    const hasTitle = this.hasTitle();
    const fontPanelParams = {
      name: this.chartTranslationService.translate("title"),
      enabled: hasTitle,
      suppressEnabledCheckbox: false,
      chartMenuUtils: this.chartMenuUtils,
      keyMapper: (key) => `title.${key}`,
      onEnableChange: (enabled) => {
        if (this.chartMenuService.doesChartToolbarExist(this.chartController)) {
          const topPadding = this.chartOptions.getValue("padding.top");
          this.chartOptions.setValue("padding.top", enabled ? topPadding - 20 : topPadding + 20);
        }
        this.chartOptions.setValue("title.enabled", enabled);
        const currentTitleText = this.chartOptions.getValue("title.text");
        const replaceableTitleText = currentTitleText === "Title" || (currentTitleText == null ? void 0 : currentTitleText.trim().length) === 0;
        if (enabled && replaceableTitleText) {
          this.chartOptions.setValue("title.text", this.titlePlaceholder);
        }
      }
    };
    const fontPanelComp = this.createBean(new FontPanel(fontPanelParams));
    fontPanelComp.addItemToPanel(this.createSpacingSlicer());
    this.getGui().appendChild(fontPanelComp.getGui());
    this.activePanels.push(fontPanelComp);
    this.addManagedListener(this.eventService, "chartTitleEdit", () => {
      fontPanelComp.setEnabled(this.hasTitle());
    });
  }
  createSpacingSlicer() {
    const params = this.chartMenuUtils.getDefaultSliderParams("title.spacing", "spacing", 100);
    params.value = "10";
    return this.createBean(new AgSlider4(params));
  }
  destroyActivePanels() {
    this.activePanels.forEach((panel) => {
      _17.removeFromParent(panel.getGui());
      this.destroyBean(panel);
    });
  }
  destroy() {
    this.destroyActivePanels();
    super.destroy();
  }
};
_TitlePanel.TEMPLATE = /* html */
`<div></div>`;
__decorateClass4([
  Autowired21("chartTranslationService")
], _TitlePanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  Autowired21("chartMenuService")
], _TitlePanel.prototype, "chartMenuService", 2);
__decorateClass4([
  PostConstruct19
], _TitlePanel.prototype, "init", 1);
var TitlePanel = _TitlePanel;
var _ChartPanel = class _ChartPanel2 extends Component15 {
  constructor({
    chartController,
    chartMenuParamsFactory,
    isExpandedOnInit = false,
    chartOptionsService,
    seriesType
  }) {
    super();
    this.chartController = chartController;
    this.chartMenuParamsFactory = chartMenuParamsFactory;
    this.chartOptionsSeriesProxy = chartOptionsService.getSeriesOptionsProxy(() => seriesType != null ? seriesType : this.chartController.getChartSeriesType());
    this.isExpandedOnInit = isExpandedOnInit;
  }
  init() {
    this.chartSeriesMenuParamsFactory = this.createManagedBean(new ChartMenuParamsFactory(this.chartOptionsSeriesProxy));
    const chartGroupParams = {
      cssIdentifier: "charts-format-top-level",
      direction: "vertical",
      title: this.chartTranslationService.translate("chart"),
      expanded: this.isExpandedOnInit,
      suppressEnabledCheckbox: true,
      items: [
        this.createManagedBean(new TitlePanel(this.chartMenuParamsFactory, this.chartController)),
        this.createManagedBean(new PaddingPanel(this.chartMenuParamsFactory, this.chartController)),
        this.createManagedBean(new BackgroundPanel(this.chartMenuParamsFactory)),
        ...this.createDirectionSelect()
      ]
    };
    this.setTemplate(_ChartPanel2.TEMPLATE, { chartGroup: chartGroupParams });
    this.addManagedListener(this.eventService, Events72.EVENT_CHART_OPTIONS_CHANGED, () => this.refresh());
  }
  refresh() {
    this.updateDirectionSelect();
  }
  createDirectionSelect() {
    const enabled = !this.chartMenuService.isLegacyFormat();
    if (!enabled) {
      return [];
    }
    const options = ["horizontal", "vertical"].map((value) => ({
      value,
      text: this.chartTranslationService.translate(value)
    }));
    const params = this.chartSeriesMenuParamsFactory.getDefaultSelectParams("direction", "direction", options);
    params.labelWidth = "flex";
    params.inputWidth = "flex";
    const onValueChange = params.onValueChange;
    params.onValueChange = (value) => {
      onValueChange(value);
      this.chartController.raiseChartModelUpdateEvent();
    };
    this.directionSelect = this.createManagedBean(new AgSelect7(params));
    this.updateDirectionSelect();
    return [this.directionSelect];
  }
  updateDirectionSelect() {
    var _a;
    (_a = this.directionSelect) == null ? void 0 : _a.setDisplayed(canSwitchDirection(this.chartController.getChartType()));
  }
};
_ChartPanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="chartGroup"></ag-group-component>
        </div>`;
__decorateClass4([
  Autowired222("chartTranslationService")
], _ChartPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  Autowired222("chartMenuService")
], _ChartPanel.prototype, "chartMenuService", 2);
__decorateClass4([
  PostConstruct20
], _ChartPanel.prototype, "init", 1);
var ChartPanel = _ChartPanel;
var _ShadowPanel = class _ShadowPanel2 extends Component16 {
  constructor(chartMenuUtils, propertyKey = "shadow") {
    super();
    this.chartMenuUtils = chartMenuUtils;
    this.propertyKey = propertyKey;
  }
  init() {
    const propertyNamespace = this.propertyKey;
    const shadowGroupParams = this.chartMenuUtils.addEnableParams(
      `${propertyNamespace}.enabled`,
      {
        cssIdentifier: "charts-format-sub-level",
        direction: "vertical",
        suppressOpenCloseIcons: true,
        title: this.chartTranslationService.translate("shadow"),
        suppressEnabledCheckbox: false
      }
    );
    const shadowColorPickerParams = this.chartMenuUtils.getDefaultColorPickerParams(`${propertyNamespace}.color`);
    this.setTemplate(_ShadowPanel2.TEMPLATE, {
      shadowGroup: shadowGroupParams,
      shadowColorPicker: shadowColorPickerParams,
      shadowBlurSlider: this.getSliderParams("blur", 0, 20),
      shadowXOffsetSlider: this.getSliderParams("xOffset", -10, 10),
      shadowYOffsetSlider: this.getSliderParams("yOffset", -10, 10)
    });
  }
  getSliderParams(property, minValue, defaultMaxValue) {
    const expression = `${this.propertyKey}.${property}`;
    const params = this.chartMenuUtils.getDefaultSliderParams(
      expression,
      property,
      defaultMaxValue
    );
    params.minValue = minValue;
    return params;
  }
};
_ShadowPanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="shadowGroup">
                <ag-color-picker ref="shadowColorPicker"></ag-color-picker>
                <ag-slider ref="shadowBlurSlider"></ag-slider>
                <ag-slider ref="shadowXOffsetSlider"></ag-slider>
                <ag-slider ref="shadowYOffsetSlider"></ag-slider>
            </ag-group-component>
        </div>`;
__decorateClass4([
  Autowired232("chartTranslationService")
], _ShadowPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct21
], _ShadowPanel.prototype, "init", 1);
var ShadowPanel = _ShadowPanel;
var _MarkersPanel = class _MarkersPanel2 extends Component17 {
  constructor(chartOptionsService, chartMenuUtils) {
    super();
    this.chartOptionsService = chartOptionsService;
    this.chartMenuUtils = chartMenuUtils;
  }
  init() {
    const chartType = this.chartOptionsService.getChartType();
    const shouldHideEnabledCheckbox = _18.includes(["scatter", "bubble"], chartType);
    const seriesMarkersGroupParams = this.chartMenuUtils.addEnableParams(
      "marker.enabled",
      {
        cssIdentifier: "charts-format-sub-level",
        direction: "vertical",
        title: this.chartTranslationService.translate("markers"),
        suppressEnabledCheckbox: shouldHideEnabledCheckbox,
        suppressOpenCloseIcons: true
      }
    );
    const isBubble = chartType === "bubble";
    let seriesMarkerMinSizeSliderParams;
    let seriesMarkerSizeSliderParams;
    if (isBubble) {
      seriesMarkerMinSizeSliderParams = this.getSliderParams("marker.maxSize", "maxSize", 60);
      seriesMarkerSizeSliderParams = this.getSliderParams("marker.size", "minSize", 60);
    } else {
      seriesMarkerMinSizeSliderParams = {};
      seriesMarkerSizeSliderParams = this.getSliderParams("marker.size", "size", 60);
    }
    this.setTemplate(_MarkersPanel2.TEMPLATE, {
      seriesMarkersGroup: seriesMarkersGroupParams,
      seriesMarkerShapeSelect: this.getMarkerShapeSelectParams(),
      seriesMarkerMinSizeSlider: seriesMarkerMinSizeSliderParams,
      seriesMarkerSizeSlider: seriesMarkerSizeSliderParams,
      seriesMarkerStrokeWidthSlider: this.getSliderParams("marker.strokeWidth", "strokeWidth", 10)
    });
    if (!isBubble) {
      this.seriesMarkerMinSizeSlider.setDisplayed(false);
    }
  }
  getMarkerShapeSelectParams() {
    const options = [
      {
        value: "square",
        text: "Square"
      },
      {
        value: "circle",
        text: "Circle"
      },
      {
        value: "cross",
        text: "Cross"
      },
      {
        value: "diamond",
        text: "Diamond"
      },
      {
        value: "plus",
        text: "Plus"
      },
      {
        value: "triangle",
        text: "Triangle"
      },
      {
        value: "heart",
        text: "Heart"
      }
    ];
    return this.chartMenuUtils.addValueParams(
      "marker.shape",
      {
        options,
        label: this.chartTranslationService.translate("shape")
      }
    );
  }
  getSliderParams(expression, labelKey, defaultMaxValue) {
    return this.chartMenuUtils.getDefaultSliderParams(
      expression,
      labelKey,
      defaultMaxValue
    );
  }
};
_MarkersPanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="seriesMarkersGroup">
                <ag-select ref="seriesMarkerShapeSelect"></ag-select>
                <ag-slider ref="seriesMarkerMinSizeSlider"></ag-slider>
                <ag-slider ref="seriesMarkerSizeSlider"></ag-slider>
                <ag-slider ref="seriesMarkerStrokeWidthSlider"></ag-slider>
            </ag-group-component>
        </div>`;
__decorateClass4([
  RefSelector62("seriesMarkerMinSizeSlider")
], _MarkersPanel.prototype, "seriesMarkerMinSizeSlider", 2);
__decorateClass4([
  Autowired242("chartTranslationService")
], _MarkersPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct222
], _MarkersPanel.prototype, "init", 1);
var MarkersPanel = _MarkersPanel;
var _AgColorPanel = class _AgColorPanel2 extends Component18 {
  constructor(config) {
    super(_AgColorPanel2.TEMPLATE);
    this.H = 1;
    this.S = 1;
    this.B = 1;
    this.A = 1;
    this.isSpectrumDragging = false;
    this.isSpectrumHueDragging = false;
    this.isSpectrumAlphaDragging = false;
    this.colorChanged = false;
    this.picker = config.picker;
  }
  postConstruct() {
    this.initTabIndex();
    this.initRecentColors();
    this.addGuiEventListener("focus", () => this.spectrumColor.focus());
    this.addGuiEventListener("keydown", (e) => {
      if (e.key === KeyCode5.ENTER && !e.defaultPrevented) {
        this.destroy();
      }
    });
    this.addManagedListener(this.spectrumColor, "keydown", (e) => this.moveDragger(e));
    this.addManagedListener(this.spectrumAlphaSlider, "keydown", (e) => this.moveAlphaSlider(e));
    this.addManagedListener(this.spectrumHueSlider, "keydown", (e) => this.moveHueSlider(e));
    this.addManagedListener(this.spectrumVal, "mousedown", this.onSpectrumDraggerDown.bind(this));
    this.addManagedListener(this.spectrumHue, "mousedown", this.onSpectrumHueDown.bind(this));
    this.addManagedListener(this.spectrumAlpha, "mousedown", this.onSpectrumAlphaDown.bind(this));
    this.addGuiEventListener("mousemove", (e) => {
      this.onSpectrumDraggerMove(e);
      this.onSpectrumHueMove(e);
      this.onSpectrumAlphaMove(e);
    });
    this.addManagedListener(document, "mouseup", this.onMouseUp.bind(this));
    this.addManagedListener(this.recentColors, "click", this.onRecentColorClick.bind(this));
    this.addManagedListener(this.recentColors, "keydown", (e) => {
      if (e.key === KeyCode5.ENTER || e.key === KeyCode5.SPACE) {
        e.preventDefault();
        this.onRecentColorClick(e);
      }
    });
  }
  initTabIndex() {
    const tabIndex = this.tabIndex = this.gos.get("tabIndex").toString();
    this.spectrumColor.setAttribute("tabindex", tabIndex);
    this.spectrumHueSlider.setAttribute("tabindex", tabIndex);
    this.spectrumAlphaSlider.setAttribute("tabindex", tabIndex);
  }
  refreshSpectrumRect() {
    return this.spectrumValRect = this.spectrumVal.getBoundingClientRect();
  }
  refreshHueRect() {
    return this.spectrumHueRect = this.spectrumHue.getBoundingClientRect();
  }
  refreshAlphaRect() {
    return this.spectrumAlphaRect = this.spectrumAlpha.getBoundingClientRect();
  }
  onSpectrumDraggerDown(e) {
    this.refreshSpectrumRect();
    this.isSpectrumDragging = true;
    this.moveDragger(e);
  }
  onSpectrumDraggerMove(e) {
    if (this.isSpectrumDragging) {
      this.moveDragger(e);
    }
  }
  onSpectrumHueDown(e) {
    this.refreshHueRect();
    this.isSpectrumHueDragging = true;
    this.moveHueSlider(e);
  }
  onSpectrumHueMove(e) {
    if (this.isSpectrumHueDragging) {
      this.moveHueSlider(e);
    }
  }
  onSpectrumAlphaDown(e) {
    this.refreshAlphaRect();
    this.isSpectrumAlphaDragging = true;
    this.moveAlphaSlider(e);
  }
  onSpectrumAlphaMove(e) {
    if (this.isSpectrumAlphaDragging) {
      this.moveAlphaSlider(e);
    }
  }
  onMouseUp() {
    this.isSpectrumDragging = false;
    this.isSpectrumHueDragging = false;
    this.isSpectrumAlphaDragging = false;
  }
  moveDragger(e) {
    const valRect = this.spectrumValRect;
    if (!valRect) {
      return;
    }
    let x;
    let y;
    if (e instanceof MouseEvent) {
      x = e.clientX - valRect.left;
      y = e.clientY - valRect.top;
    } else {
      const isLeft = e.key === KeyCode5.LEFT;
      const isRight = e.key === KeyCode5.RIGHT;
      const isUp = e.key === KeyCode5.UP;
      const isDown = e.key === KeyCode5.DOWN;
      const isVertical = isUp || isDown;
      const isHorizontal = isLeft || isRight;
      if (!isVertical && !isHorizontal) {
        return;
      }
      e.preventDefault();
      const { x: currentX, y: currentY } = this.getSpectrumValue();
      x = currentX + (isHorizontal ? isLeft ? -5 : 5 : 0);
      y = currentY + (isVertical ? isUp ? -5 : 5 : 0);
    }
    x = Math.max(x, 0);
    x = Math.min(x, valRect.width);
    y = Math.max(y, 0);
    y = Math.min(y, valRect.height);
    this.setSpectrumValue(x / valRect.width, 1 - y / valRect.height);
  }
  moveHueSlider(e) {
    const rect = this.spectrumHueRect;
    if (!rect) {
      return;
    }
    const x = this.moveSlider(this.spectrumHueSlider, e);
    if (x == null) {
      return;
    }
    this.H = 1 - x / rect.width;
    this.update();
  }
  moveAlphaSlider(e) {
    const rect = this.spectrumAlphaRect;
    if (!rect) {
      return;
    }
    const x = this.moveSlider(this.spectrumAlphaSlider, e);
    if (x == null) {
      return;
    }
    this.A = x / rect.width;
    this.update();
  }
  moveSlider(slider, e) {
    var _a;
    const sliderRect = slider.getBoundingClientRect();
    const parentRect = (_a = slider.parentElement) == null ? void 0 : _a.getBoundingClientRect();
    if (!slider || !parentRect) {
      return null;
    }
    let x;
    if (e instanceof MouseEvent) {
      x = e.clientX - parentRect.left;
    } else {
      const isLeft = e.key === KeyCode5.LEFT;
      const isRight = e.key === KeyCode5.RIGHT;
      if (!isLeft && !isRight) {
        return null;
      }
      e.preventDefault();
      const diff = isLeft ? -5 : 5;
      x = parseFloat(slider.style.left) - sliderRect.width / 2 + diff;
    }
    x = Math.max(x, 0);
    x = Math.min(x, parentRect.width);
    slider.style.left = x + sliderRect.width / 2 + "px";
    return x;
  }
  update() {
    const color = _Util.Color.fromHSB(this.H * 360, this.S, this.B, this.A);
    const spectrumColor = _Util.Color.fromHSB(this.H * 360, 1, 1);
    const rgbaColor = color.toRgbaString();
    const colorPicker = this.picker;
    const existingColor = _Util.Color.fromString(colorPicker.getValue());
    if (existingColor.toRgbaString() !== rgbaColor) {
      this.colorChanged = true;
    }
    colorPicker.setValue(rgbaColor);
    this.spectrumColor.style.backgroundColor = spectrumColor.toRgbaString();
    this.spectrumDragger.style.backgroundColor = rgbaColor;
  }
  /**
   * @param saturation In the [0, 1] interval.
   * @param brightness In the [0, 1] interval.
   */
  setSpectrumValue(saturation, brightness) {
    const valRect = this.spectrumValRect || this.refreshSpectrumRect();
    if (valRect == null) {
      return;
    }
    const dragger = this.spectrumDragger;
    const draggerRect = dragger.getBoundingClientRect();
    saturation = Math.max(0, saturation);
    saturation = Math.min(1, saturation);
    brightness = Math.max(0, brightness);
    brightness = Math.min(1, brightness);
    this.S = saturation;
    this.B = brightness;
    dragger.style.left = saturation * valRect.width - draggerRect.width / 2 + "px";
    dragger.style.top = (1 - brightness) * valRect.height - draggerRect.height / 2 + "px";
    this.update();
  }
  getSpectrumValue() {
    const dragger = this.spectrumDragger;
    const draggerRect = dragger.getBoundingClientRect();
    const x = parseFloat(dragger.style.left) + draggerRect.width / 2;
    const y = parseFloat(dragger.style.top) + draggerRect.height / 2;
    return { x, y };
  }
  initRecentColors() {
    const recentColors = _AgColorPanel2.recentColors;
    const innerHtml = recentColors.map((color, index) => {
      return (
        /* html */
        `<div class="ag-recent-color" id=${index} style="background-color: ${color}; width: 15px; height: 15px;" recent-color="${color}" tabIndex="${this.tabIndex}"></div>`
      );
    });
    this.recentColors.innerHTML = innerHtml.join("");
  }
  setValue(val) {
    const color = _Util.Color.fromString(val);
    const [h, s, b] = color.toHSB();
    this.H = (isNaN(h) ? 0 : h) / 360;
    this.A = color.a;
    const spectrumHueRect = this.spectrumHueRect || this.refreshHueRect();
    const spectrumAlphaRect = this.spectrumAlphaRect || this.refreshAlphaRect();
    this.spectrumHueSlider.style.left = `${(this.H - 1) * -spectrumHueRect.width}px`;
    this.spectrumAlphaSlider.style.left = `${this.A * spectrumAlphaRect.width}px`;
    this.setSpectrumValue(s, b);
  }
  onRecentColorClick(e) {
    const target = e.target;
    if (!_19.exists(target.id)) {
      return;
    }
    const id = parseInt(target.id, 10);
    this.setValue(_AgColorPanel2.recentColors[id]);
    this.destroy();
  }
  addRecentColor() {
    const color = _Util.Color.fromHSB(this.H * 360, this.S, this.B, this.A);
    const rgbaColor = color.toRgbaString();
    let recentColors = _AgColorPanel2.recentColors;
    if (!this.colorChanged || recentColors[0] === rgbaColor) {
      return;
    }
    recentColors = recentColors.filter((currentColor) => currentColor != rgbaColor);
    recentColors = [rgbaColor].concat(recentColors);
    if (recentColors.length > _AgColorPanel2.maxRecentColors) {
      recentColors = recentColors.slice(0, _AgColorPanel2.maxRecentColors);
    }
    _AgColorPanel2.recentColors = recentColors;
  }
  destroy() {
    this.addRecentColor();
    super.destroy();
  }
};
_AgColorPanel.maxRecentColors = 8;
_AgColorPanel.recentColors = [];
_AgColorPanel.TEMPLATE = /* html */
`<div class="ag-color-panel" tabindex="-1">
            <div ref="spectrumColor" class="ag-spectrum-color">
                <div class="ag-spectrum-sat ag-spectrum-fill">
                    <div ref="spectrumVal" class="ag-spectrum-val ag-spectrum-fill">
                        <div ref="spectrumDragger" class="ag-spectrum-dragger"></div>
                    </div>
                </div>
            </div>
            <div class="ag-spectrum-tools">
                <div ref="spectrumHue" class="ag-spectrum-hue ag-spectrum-tool">
                    <div class="ag-spectrum-hue-background"></div>
                    <div ref="spectrumHueSlider" class="ag-spectrum-slider"></div>
                </div>
                <div ref="spectrumAlpha" class="ag-spectrum-alpha ag-spectrum-tool">
                    <div class="ag-spectrum-alpha-background"></div>
                    <div ref="spectrumAlphaSlider" class="ag-spectrum-slider"></div>
                </div>
                <div ref="recentColors" class="ag-recent-colors"></div>
            </div>
        </div>`;
__decorateClass4([
  RefSelector7("spectrumColor")
], _AgColorPanel.prototype, "spectrumColor", 2);
__decorateClass4([
  RefSelector7("spectrumVal")
], _AgColorPanel.prototype, "spectrumVal", 2);
__decorateClass4([
  RefSelector7("spectrumDragger")
], _AgColorPanel.prototype, "spectrumDragger", 2);
__decorateClass4([
  RefSelector7("spectrumHue")
], _AgColorPanel.prototype, "spectrumHue", 2);
__decorateClass4([
  RefSelector7("spectrumHueSlider")
], _AgColorPanel.prototype, "spectrumHueSlider", 2);
__decorateClass4([
  RefSelector7("spectrumAlpha")
], _AgColorPanel.prototype, "spectrumAlpha", 2);
__decorateClass4([
  RefSelector7("spectrumAlphaSlider")
], _AgColorPanel.prototype, "spectrumAlphaSlider", 2);
__decorateClass4([
  RefSelector7("recentColors")
], _AgColorPanel.prototype, "recentColors", 2);
__decorateClass4([
  PostConstruct232
], _AgColorPanel.prototype, "postConstruct", 1);
var AgColorPanel = _AgColorPanel;
var AgColorPicker = class extends AgPickerField {
  constructor(config) {
    super(__spreadValues3({
      pickerAriaLabelKey: "ariaLabelColorPicker",
      pickerAriaLabelValue: "Color Picker",
      pickerType: "ag-list",
      className: "ag-color-picker",
      pickerIcon: "colorPicker"
    }, config));
  }
  postConstruct() {
    super.postConstruct();
    if (this.value) {
      this.setValue(this.value);
    }
  }
  createPickerComponent() {
    const eGuiRect = this.getGui().getBoundingClientRect();
    const colorDialog = this.createBean(new AgDialog3({
      closable: false,
      modal: true,
      hideTitleBar: true,
      minWidth: 190,
      width: 190,
      height: 250,
      x: eGuiRect.right - 190,
      y: eGuiRect.top - 250
    }));
    return colorDialog;
  }
  renderAndPositionPicker() {
    const pickerComponent = this.pickerComponent;
    const colorPanel = this.createBean(new AgColorPanel({ picker: this }));
    pickerComponent.addCssClass("ag-color-dialog");
    colorPanel.addDestroyFunc(() => {
      if (pickerComponent.isAlive()) {
        this.destroyBean(pickerComponent);
      }
    });
    pickerComponent.setParentComponent(this);
    pickerComponent.setBodyComponent(colorPanel);
    colorPanel.setValue(this.getValue());
    colorPanel.getGui().focus();
    pickerComponent.addDestroyFunc(() => {
      if (!this.isDestroyingPicker) {
        this.beforeHidePicker();
        this.isDestroyingPicker = true;
        if (colorPanel.isAlive()) {
          this.destroyBean(colorPanel);
        }
        if (this.isAlive()) {
          this.getFocusableElement().focus();
        }
      } else {
        this.isDestroyingPicker = false;
      }
    });
    return () => {
      var _a;
      return (_a = this.pickerComponent) == null ? void 0 : _a.close();
    };
  }
  setValue(color) {
    if (this.value === color) {
      return this;
    }
    this.eDisplayField.style.backgroundColor = color;
    return super.setValue(color);
  }
  getValue() {
    return this.value;
  }
};
var _CalloutPanel = class _CalloutPanel2 extends Component19 {
  constructor(chartMenuUtils) {
    super();
    this.chartMenuUtils = chartMenuUtils;
  }
  init() {
    const calloutGroupParams = {
      cssIdentifier: "charts-format-sub-level",
      direction: "vertical",
      title: this.chartTranslationService.translate("callout"),
      enabled: true,
      suppressOpenCloseIcons: true,
      suppressEnabledCheckbox: true
    };
    this.setTemplate(_CalloutPanel2.TEMPLATE, {
      calloutGroup: calloutGroupParams,
      calloutLengthSlider: this.chartMenuUtils.getDefaultSliderParams("calloutLine.length", "length", 40),
      calloutStrokeWidthSlider: this.chartMenuUtils.getDefaultSliderParams("calloutLine.strokeWidth", "strokeWidth", 10),
      labelOffsetSlider: this.chartMenuUtils.getDefaultSliderParams("calloutLabel.offset", "offset", 30)
    });
  }
};
_CalloutPanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="calloutGroup">
                <ag-slider ref="calloutLengthSlider"></ag-slider>
                <ag-slider ref="calloutStrokeWidthSlider"></ag-slider>
                <ag-slider ref="labelOffsetSlider"></ag-slider>
            </ag-group-component>
        </div>`;
__decorateClass4([
  Autowired25("chartTranslationService")
], _CalloutPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct242
], _CalloutPanel.prototype, "init", 1);
var CalloutPanel = _CalloutPanel;
var _CapsPanel = class _CapsPanel2 extends Component20 {
  constructor(chartMenuUtils) {
    super();
    this.chartMenuUtils = chartMenuUtils;
  }
  init() {
    const capsGroupParams = {
      cssIdentifier: "charts-format-sub-level",
      direction: "vertical",
      title: this.chartTranslationService.translate("cap"),
      enabled: true,
      suppressOpenCloseIcons: true,
      suppressEnabledCheckbox: true
    };
    const capLengthRatioSliderParams = this.chartMenuUtils.getDefaultSliderParams("cap.lengthRatio", "capLengthRatio", 1);
    capLengthRatioSliderParams.step = 0.05;
    this.setTemplate(_CapsPanel2.TEMPLATE, {
      capsGroup: capsGroupParams,
      capLengthRatioSlider: capLengthRatioSliderParams
    });
  }
};
_CapsPanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="capsGroup">
                <ag-slider ref="capLengthRatioSlider"></ag-slider>
            </ag-group-component>
        </div>`;
__decorateClass4([
  Autowired26("chartTranslationService")
], _CapsPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct25
], _CapsPanel.prototype, "init", 1);
var CapsPanel = _CapsPanel;
var _ConnectorLinePanel = class _ConnectorLinePanel2 extends Component21 {
  constructor(chartMenuUtils) {
    super();
    this.chartMenuUtils = chartMenuUtils;
  }
  init() {
    const lineGroupParams = {
      cssIdentifier: "charts-format-sub-level",
      direction: "vertical",
      title: this.chartTranslationService.translate("connectorLine"),
      enabled: true,
      suppressOpenCloseIcons: true,
      suppressEnabledCheckbox: true
    };
    this.setTemplate(_ConnectorLinePanel2.TEMPLATE, {
      lineGroup: lineGroupParams,
      lineColorPicker: this.chartMenuUtils.getDefaultColorPickerParams("line.stroke"),
      lineStrokeWidthSlider: this.getSliderParams("strokeWidth", 10, "line.strokeWidth"),
      lineDashSlider: this.getSliderParams("lineDash", 30, "line.lineDash", 1, true),
      lineOpacitySlider: this.getSliderParams("strokeOpacity", 1, "line.strokeOpacity", 0.05)
    });
  }
  getSliderParams(labelKey, maxValue, seriesOptionKey, step = 1, isArray = false) {
    const params = this.chartMenuUtils.getDefaultSliderParams(seriesOptionKey, labelKey, maxValue, isArray);
    params.step = step;
    return params;
  }
};
_ConnectorLinePanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="lineGroup">
                <ag-color-picker ref="lineColorPicker"></ag-color-picker>
                <ag-slider ref="lineStrokeWidthSlider"></ag-slider>
                <ag-slider ref="lineOpacitySlider"></ag-slider>
                <ag-slider ref="lineDashSlider"></ag-slider>                
            </ag-group-component>
        </div>`;
__decorateClass4([
  Autowired27("chartTranslationService")
], _ConnectorLinePanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct26
], _ConnectorLinePanel.prototype, "init", 1);
var ConnectorLinePanel = _ConnectorLinePanel;
var _WhiskersPanel = class _WhiskersPanel2 extends Component222 {
  constructor(chartMenuUtils) {
    super();
    this.chartMenuUtils = chartMenuUtils;
  }
  init() {
    const whiskersGroupParams = {
      cssIdentifier: "charts-format-sub-level",
      direction: "vertical",
      title: this.chartTranslationService.translate("whisker"),
      enabled: true,
      suppressOpenCloseIcons: true,
      suppressEnabledCheckbox: true
    };
    this.setTemplate(_WhiskersPanel2.TEMPLATE, {
      whiskersGroup: whiskersGroupParams,
      whiskerColorPicker: this.chartMenuUtils.getDefaultColorPickerParams("whisker.stroke"),
      whiskerThicknessSlider: this.chartMenuUtils.getDefaultSliderParams("whisker.strokeWidth", "strokeWidth", 10),
      whiskerOpacitySlider: this.chartMenuUtils.getDefaultSliderParams("whisker.strokeOpacity", "strokeOpacity", 1),
      whiskerLineDashSlider: this.chartMenuUtils.getDefaultSliderParams("whisker.lineDash", "lineDash", 30, true),
      whiskerLineDashOffsetSlider: this.chartMenuUtils.getDefaultSliderParams("whisker.lineDashOffset", "lineDashOffset", 30)
    });
  }
};
_WhiskersPanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="whiskersGroup">
                <ag-color-picker ref="whiskerColorPicker"></ag-color-picker>
                <ag-slider ref="whiskerThicknessSlider"></ag-slider>
                <ag-slider ref="whiskerOpacitySlider"></ag-slider>
                <ag-slider ref="whiskerLineDashSlider"></ag-slider>
                <ag-slider ref="whiskerLineDashOffsetSlider"></ag-slider>
            </ag-group-component>
        </div>`;
__decorateClass4([
  Autowired28("chartTranslationService")
], _WhiskersPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct27
], _WhiskersPanel.prototype, "init", 1);
var WhiskersPanel = _WhiskersPanel;
var _SeriesItemsPanel = class _SeriesItemsPanel2 extends Component23 {
  constructor(chartMenuUtils) {
    super();
    this.chartMenuUtils = chartMenuUtils;
    this.activePanels = [];
  }
  init() {
    const seriesItemsGroupParams = {
      cssIdentifier: "charts-format-sub-level",
      direction: "vertical",
      title: this.chartTranslationService.translate("seriesItems"),
      enabled: true,
      suppressOpenCloseIcons: true,
      suppressEnabledCheckbox: true
    };
    this.setTemplate(_SeriesItemsPanel2.TEMPLATE, {
      seriesItemsGroup: seriesItemsGroupParams,
      seriesItemSelect: this.getSeriesItemsParams()
    });
    this.initSeriesControls();
  }
  getSeriesItemsParams() {
    const options = [
      { value: "positive", text: this.chartTranslationService.translate("seriesItemPositive") },
      { value: "negative", text: this.chartTranslationService.translate("seriesItemNegative") }
    ];
    const seriesItemChangedCallback = (newValue) => {
      this.destroyActivePanels();
      this.initSeriesControls(newValue);
    };
    return {
      label: this.chartTranslationService.translate("seriesItemType"),
      labelAlignment: "left",
      labelWidth: "flex",
      inputWidth: "flex",
      options,
      value: "positive",
      onValueChange: seriesItemChangedCallback
    };
  }
  initSeriesControls(itemType = "positive") {
    this.initSlider("strokeWidth", 10, `item.${itemType}.strokeWidth`);
    this.initSlider("lineDash", 30, `item.${itemType}.lineDash`, 1, true);
    this.initSlider("strokeOpacity", 1, `item.${itemType}.strokeOpacity`, 0.05, false);
    this.initSlider("fillOpacity", 1, `item.${itemType}.fillOpacity`, 0.05, false);
    this.initItemLabels(itemType);
  }
  initSlider(labelKey, maxValue, seriesOptionKey, step = 1, isArray = false) {
    const params = this.chartMenuUtils.getDefaultSliderParams(
      seriesOptionKey,
      labelKey,
      maxValue,
      isArray
    );
    params.step = step;
    const itemSlider = this.seriesItemsGroup.createManagedBean(new AgSlider6(params));
    this.seriesItemsGroup.addItem(itemSlider);
    this.activePanels.push(itemSlider);
  }
  initItemLabels(itemType) {
    const sectorParams = this.chartMenuUtils.getDefaultFontPanelParams(`item.${itemType}.label`, "seriesItemLabels");
    const labelPanelComp = this.createBean(new FontPanel(sectorParams));
    this.seriesItemsGroup.addItem(labelPanelComp);
    this.activePanels.push(labelPanelComp);
  }
  destroyActivePanels() {
    this.activePanels.forEach((panel) => {
      _21.removeFromParent(panel.getGui());
      this.destroyBean(panel);
    });
  }
  destroy() {
    this.destroyActivePanels();
    super.destroy();
  }
};
_SeriesItemsPanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="seriesItemsGroup">
                <ag-select ref="seriesItemSelect"></ag-select>
            </ag-group-component>
        </div>`;
__decorateClass4([
  RefSelector8("seriesItemsGroup")
], _SeriesItemsPanel.prototype, "seriesItemsGroup", 2);
__decorateClass4([
  Autowired29("chartTranslationService")
], _SeriesItemsPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct28
], _SeriesItemsPanel.prototype, "init", 1);
var SeriesItemsPanel = _SeriesItemsPanel;
var _TileSpacingPanel = class _TileSpacingPanel2 extends Component24 {
  constructor(chartMenuUtils) {
    super();
    this.chartMenuUtils = chartMenuUtils;
  }
  init() {
    const groupParams = {
      cssIdentifier: "charts-format-sub-level",
      direction: "vertical",
      enabled: true,
      suppressOpenCloseIcons: true,
      suppressEnabledCheckbox: true
    };
    this.setTemplate(_TileSpacingPanel2.TEMPLATE, {
      groupSpacing: __spreadProps3(__spreadValues3({}, groupParams), { title: this.chartTranslationService.translate("group") }),
      tileSpacing: __spreadProps3(__spreadValues3({}, groupParams), { title: this.chartTranslationService.translate("tile") }),
      groupPaddingSlider: this.getSliderParams("padding", "group.padding"),
      groupSpacingSlider: this.getSliderParams("spacing", "group.gap"),
      tilePaddingSlider: this.getSliderParams("padding", "tile.padding"),
      tileSpacingSlider: this.getSliderParams("spacing", "tile.gap")
    });
  }
  getSliderParams(labelKey, key) {
    return this.chartMenuUtils.getDefaultSliderParams(key, labelKey, 10);
  }
};
_TileSpacingPanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="groupSpacing">
                <ag-slider ref="groupPaddingSlider"></ag-slider>
                <ag-slider ref="groupSpacingSlider"></ag-slider>
            </ag-group-component>
            <ag-group-component ref="tileSpacing">
                <ag-slider ref="tilePaddingSlider"></ag-slider>
                <ag-slider ref="tileSpacingSlider"></ag-slider>
            </ag-group-component>
        </div>`;
__decorateClass4([
  Autowired30("chartTranslationService")
], _TileSpacingPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct29
], _TileSpacingPanel.prototype, "init", 1);
var TileSpacingPanel = _TileSpacingPanel;
var _SeriesPanel = class _SeriesPanel2 extends Component25 {
  constructor({
    chartController,
    chartOptionsService,
    seriesType,
    isExpandedOnInit = false
  }) {
    super();
    this.activePanels = [];
    this.widgetFuncs = {
      lineWidth: () => this.initStrokeWidth("lineWidth"),
      strokeWidth: () => this.initStrokeWidth("strokeWidth"),
      lineColor: () => this.initLineColor(),
      lineDash: () => this.initLineDash(),
      lineOpacity: () => this.initLineOpacity(),
      fillOpacity: () => this.initFillOpacity(),
      markers: () => this.initMarkers(),
      labels: () => this.initLabels(),
      shadow: () => this.initShadow(),
      tooltips: () => this.initTooltips(),
      bins: () => this.initBins(),
      whiskers: () => this.initWhiskers(),
      caps: () => this.initCaps(),
      connectorLine: () => this.initConnectorLine(),
      seriesItems: () => this.initSeriesItemsPanel(),
      tileSpacing: () => this.initTileSpacingPanel(),
      groupType: () => this.initGroupType()
    };
    this.seriesWidgetMappings = {
      bar: ["tooltips", "strokeWidth", "lineDash", "lineOpacity", "fillOpacity", "labels", "shadow"],
      pie: ["tooltips", "strokeWidth", "lineOpacity", "fillOpacity", "labels", "shadow"],
      donut: ["tooltips", "strokeWidth", "lineOpacity", "fillOpacity", "labels", "shadow"],
      line: ["tooltips", "lineWidth", "lineDash", "lineOpacity", "markers", "labels"],
      scatter: ["tooltips", "markers", "labels"],
      bubble: ["tooltips", "markers", "labels"],
      area: ["tooltips", "lineWidth", "lineDash", "lineOpacity", "fillOpacity", "markers", "labels", "shadow"],
      histogram: ["tooltips", "bins", "strokeWidth", "lineDash", "lineOpacity", "fillOpacity", "labels", "shadow"],
      "radial-column": ["tooltips", "strokeWidth", "lineDash", "lineOpacity", "fillOpacity", "labels", "groupType"],
      "radial-bar": ["tooltips", "strokeWidth", "lineDash", "lineOpacity", "fillOpacity", "labels", "groupType"],
      "radar-line": ["tooltips", "strokeWidth", "lineDash", "lineOpacity", "markers", "labels"],
      "radar-area": ["tooltips", "strokeWidth", "lineDash", "lineOpacity", "fillOpacity", "markers", "labels"],
      nightingale: ["tooltips", "strokeWidth", "lineDash", "lineOpacity", "fillOpacity", "labels", "groupType"],
      "box-plot": ["tooltips", "strokeWidth", "lineDash", "lineOpacity", "fillOpacity", "whiskers", "caps"],
      "range-bar": ["tooltips", "strokeWidth", "lineDash", "lineOpacity", "fillOpacity", "labels"],
      "range-area": ["tooltips", "lineWidth", "lineDash", "lineOpacity", "fillOpacity", "markers", "labels", "shadow"],
      treemap: ["tooltips", "tileSpacing"],
      sunburst: ["tooltips"],
      heatmap: ["tooltips", "labels", "lineColor", "lineWidth", "lineOpacity"],
      waterfall: ["tooltips", "connectorLine", "seriesItems"]
    };
    this.chartController = chartController;
    this.chartOptionsService = chartOptionsService;
    this.seriesType = seriesType || this.chartController.getChartSeriesType();
    this.isExpandedOnInit = isExpandedOnInit;
  }
  init() {
    const seriesGroupParams = {
      cssIdentifier: "charts-format-top-level",
      direction: "vertical",
      title: this.translate("series"),
      expanded: this.isExpandedOnInit,
      suppressEnabledCheckbox: true
    };
    this.setTemplate(_SeriesPanel2.TEMPLATE, { seriesGroup: seriesGroupParams });
    this.chartMenuUtils = this.createManagedBean(new ChartMenuParamsFactory(
      this.chartOptionsService.getSeriesOptionsProxy(() => this.seriesType)
    ));
    this.chartOptions = this.chartMenuUtils.getChartOptions();
    this.addManagedListener(this.chartController, ChartController.EVENT_CHART_SERIES_CHART_TYPE_CHANGED, this.refreshWidgets.bind(this));
    this.refreshWidgets();
  }
  refreshWidgets() {
    this.destroyActivePanels();
    const chart = this.chartController.getChartProxy().getChart();
    chart.waitForUpdate().then(() => {
      var _a;
      const componentWasRemoved = !this.isAlive();
      if (componentWasRemoved) {
        return;
      }
      if (this.chartController.isComboChart()) {
        this.updateSeriesType();
        this.initSeriesSelect();
      }
      ((_a = this.seriesWidgetMappings[this.seriesType]) != null ? _a : []).forEach((w) => this.widgetFuncs[w]());
    }).catch((e) => console.error(`AG Grid - chart rendering failed`, e));
  }
  initSeriesSelect() {
    const seriesSelect = this.seriesGroup.createManagedBean(new AgSelect8({
      label: this.translate("seriesType"),
      labelAlignment: "left",
      labelWidth: "flex",
      inputWidth: "flex",
      options: this.getSeriesSelectOptions(),
      value: `${this.seriesType}`,
      onValueChange: (newValue) => {
        this.seriesType = newValue;
        this.refreshWidgets();
      }
    }));
    this.seriesGroup.addItem(seriesSelect);
    this.activePanels.push(seriesSelect);
  }
  initTooltips() {
    const seriesTooltipsToggle = this.createBean(new AgToggleButton4(this.chartMenuUtils.addValueParams(
      "tooltip.enabled",
      {
        label: this.translate("tooltips"),
        labelAlignment: "left",
        labelWidth: "flex",
        inputWidth: "flex"
      }
    )));
    this.addWidget(seriesTooltipsToggle);
  }
  initLineColor() {
    const seriesLineColorPicker = this.createBean(new AgColorPicker(this.chartMenuUtils.getDefaultColorPickerParams(
      "stroke",
      "strokeColor"
    )));
    this.addWidget(seriesLineColorPicker);
  }
  initStrokeWidth(labelKey) {
    const seriesStrokeWidthSlider = this.createBean(new AgSlider7(this.chartMenuUtils.getDefaultSliderParams(
      "strokeWidth",
      labelKey,
      10
    )));
    this.addWidget(seriesStrokeWidthSlider);
  }
  initLineDash() {
    const seriesLineDashSlider = this.createBean(new AgSlider7(this.chartMenuUtils.getDefaultSliderParams(
      "lineDash",
      "lineDash",
      30,
      true
    )));
    this.addWidget(seriesLineDashSlider);
  }
  initLineOpacity() {
    const params = this.chartMenuUtils.getDefaultSliderParams(
      "strokeOpacity",
      "strokeOpacity",
      1
    );
    params.step = 0.05;
    const seriesLineOpacitySlider = this.createBean(new AgSlider7(params));
    this.addWidget(seriesLineOpacitySlider);
  }
  initFillOpacity() {
    const params = this.chartMenuUtils.getDefaultSliderParams(
      "fillOpacity",
      "fillOpacity",
      1
    );
    params.step = 0.05;
    const seriesFillOpacitySlider = this.createBean(new AgSlider7(params));
    this.addWidget(seriesFillOpacitySlider);
  }
  initLabels() {
    const isPieChart = isPieChartSeries(this.seriesType);
    const seriesOptionLabelProperty = isPieChart ? "calloutLabel" : "label";
    const labelKey = isPieChart ? "calloutLabels" : "labels";
    const labelParams = this.chartMenuUtils.getDefaultFontPanelParams(seriesOptionLabelProperty, labelKey);
    const labelPanelComp = this.createBean(new FontPanel(labelParams));
    if (isPieChart) {
      const calloutPanelComp = this.createBean(new CalloutPanel(this.chartMenuUtils));
      labelPanelComp.addCompToPanel(calloutPanelComp);
      this.activePanels.push(calloutPanelComp);
    }
    this.addWidget(labelPanelComp);
    if (isPieChart) {
      const sectorParams = this.chartMenuUtils.getDefaultFontPanelParams("sectorLabel", "sectorLabels");
      const sectorPanelComp = this.createBean(new FontPanel(sectorParams));
      const positionRatioComp = this.getSectorLabelPositionRatio();
      sectorPanelComp.addCompToPanel(positionRatioComp);
      this.addWidget(sectorPanelComp);
    }
    if (this.seriesType === "range-bar") {
      const options = [
        { value: "inside", text: this.translate("inside") },
        { value: "outside", text: this.translate("outside") }
      ];
      const placementSelect = labelPanelComp.createManagedBean(new AgSelect8(this.chartMenuUtils.addValueParams(
        "label.placement",
        {
          label: this.translate("labelPlacement"),
          labelAlignment: "left",
          labelWidth: "flex",
          inputWidth: "flex",
          options
        }
      )));
      labelPanelComp.addCompToPanel(placementSelect);
      this.activePanels.push(placementSelect);
      const paddingSlider = labelPanelComp.createManagedBean(new AgSlider7(this.chartMenuUtils.getDefaultSliderParams(
        "label.padding",
        "padding",
        200
      )));
      labelPanelComp.addCompToPanel(paddingSlider);
      this.activePanels.push(paddingSlider);
    }
  }
  getSectorLabelPositionRatio() {
    const params = this.chartMenuUtils.getDefaultSliderParams(
      "sectorLabel.positionRatio",
      "positionRatio",
      1
    );
    params.step = 0.05;
    return this.createBean(new AgSlider7(params));
  }
  initShadow() {
    const shadowPanelComp = this.createBean(new ShadowPanel(this.chartMenuUtils));
    this.addWidget(shadowPanelComp);
  }
  initMarkers() {
    const markersPanelComp = this.createBean(new MarkersPanel(this.chartOptionsService, this.chartMenuUtils));
    this.addWidget(markersPanelComp);
  }
  initBins() {
    var _a;
    const params = this.chartMenuUtils.getDefaultSliderParams("binCount", "histogramBinCount", 20);
    const value = ((_a = this.chartOptions.getValue("bins")) != null ? _a : this.chartOptions.getValue("calculatedBins", true)).length;
    params.value = `${value}`;
    params.maxValue = Math.max(value, 20);
    const seriesBinCountSlider = this.createBean(new AgSlider7(params));
    this.addWidget(seriesBinCountSlider);
  }
  initWhiskers() {
    const whiskersPanelComp = this.createBean(new WhiskersPanel(this.chartMenuUtils));
    this.addWidget(whiskersPanelComp);
  }
  initCaps() {
    const capsPanelComp = this.createBean(new CapsPanel(this.chartMenuUtils));
    this.addWidget(capsPanelComp);
  }
  initConnectorLine() {
    const connectorLinePanelComp = this.createBean(new ConnectorLinePanel(this.chartMenuUtils));
    this.addWidget(connectorLinePanelComp);
  }
  initSeriesItemsPanel() {
    const seriesItemsPanelComp = this.createBean(new SeriesItemsPanel(this.chartMenuUtils));
    this.addWidget(seriesItemsPanelComp);
  }
  initTileSpacingPanel() {
    const tileSpacingPanelComp = this.createBean(new TileSpacingPanel(this.chartMenuUtils));
    this.addWidget(tileSpacingPanelComp);
  }
  initGroupType() {
    const groupTypeSelect = this.createBean(new AgSelect8({
      label: this.chartTranslationService.translate("seriesGroupType"),
      options: ChartMappings2.SERIES_GROUP_TYPES.map((value) => ({
        value,
        text: this.chartTranslationService.translate(`${value}SeriesGroupType`)
      })),
      value: this.chartController.getSeriesGroupType(),
      onValueChange: (value) => this.chartController.setSeriesGroupType(value)
    }));
    this.addWidget(groupTypeSelect);
  }
  addWidget(widget) {
    this.seriesGroup.addItem(widget);
    this.activePanels.push(widget);
  }
  getSeriesSelectOptions() {
    const activeSeriesTypes = this.getActiveSeriesTypes();
    return ["area", "bar", "line"].filter((seriesType) => activeSeriesTypes.includes(seriesType)).map((value) => ({ value, text: this.translate(value) }));
  }
  updateSeriesType() {
    const activeSeriesTypes = this.getActiveSeriesTypes();
    const invalidSeriesType = !activeSeriesTypes.includes(this.seriesType);
    if (invalidSeriesType && activeSeriesTypes.length > 0) {
      this.seriesType = activeSeriesTypes[0];
    }
  }
  getActiveSeriesTypes() {
    return this.chartController.getActiveSeriesChartTypes().map((s) => getSeriesType(s.chartType));
  }
  translate(key) {
    return this.chartTranslationService.translate(key);
  }
  destroyActivePanels() {
    this.activePanels.forEach((panel) => {
      _222.removeFromParent(panel.getGui());
      this.destroyBean(panel);
    });
  }
  destroy() {
    this.destroyActivePanels();
    super.destroy();
  }
};
_SeriesPanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="seriesGroup">
            </ag-group-component>
        </div>`;
__decorateClass4([
  RefSelector9("seriesGroup")
], _SeriesPanel.prototype, "seriesGroup", 2);
__decorateClass4([
  Autowired31("chartTranslationService")
], _SeriesPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct30
], _SeriesPanel.prototype, "init", 1);
var SeriesPanel = _SeriesPanel;
var _GradientLegendPanel = class _GradientLegendPanel2 extends Component26 {
  constructor({ chartMenuParamsFactory: chartMenuUtils, isExpandedOnInit = false }) {
    super();
    this.chartMenuUtils = chartMenuUtils;
    this.isExpandedOnInit = isExpandedOnInit;
  }
  init() {
    const legendGroupParams = this.chartMenuUtils.addEnableParams(
      "gradientLegend.enabled",
      {
        cssIdentifier: "charts-format-top-level",
        direction: "vertical",
        title: this.chartTranslationService.translate("legend"),
        suppressEnabledCheckbox: false,
        suppressToggleExpandOnEnableChange: true,
        expanded: this.isExpandedOnInit,
        items: [this.createLabelPanel()]
      }
    );
    this.setTemplate(_GradientLegendPanel2.TEMPLATE, {
      legendGroup: legendGroupParams,
      legendPositionSelect: this.chartMenuUtils.getDefaultLegendParams("gradientLegend.position"),
      gradientReverseCheckbox: this.getGradientReverseCheckboxParams(),
      gradientThicknessSlider: this.chartMenuUtils.getDefaultSliderParams("gradientLegend.gradient.thickness", "thickness", 40),
      gradientPreferredLengthSlider: this.chartMenuUtils.getDefaultSliderParams("gradientLegend.gradient.preferredLength", "preferredLength", 300),
      legendSpacingSlider: this.chartMenuUtils.getDefaultSliderParams("gradientLegend.spacing", "spacing", 200)
    });
  }
  getGradientReverseCheckboxParams() {
    return this.chartMenuUtils.addValueParams(
      "gradientLegend.reverseOrder",
      {
        label: this.chartTranslationService.translate("reverseDirection"),
        labelWidth: "flex"
      }
    );
  }
  createLabelPanel() {
    const params = {
      enabled: true,
      suppressEnabledCheckbox: true,
      chartMenuUtils: this.chartMenuUtils,
      keyMapper: (key) => `gradientLegend.scale.label.${key}`
    };
    return this.createManagedBean(new FontPanel(params));
  }
};
_GradientLegendPanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="legendGroup">
                <ag-select ref="legendPositionSelect"></ag-select>
                <ag-checkbox ref="gradientReverseCheckbox"></ag-checkbox>
                <ag-slider ref="gradientThicknessSlider"></ag-slider>
                <ag-slider ref="gradientPreferredLengthSlider"></ag-slider>
                <ag-slider ref="legendSpacingSlider"></ag-slider>
            </ag-group-component>
        </div>`;
__decorateClass4([
  Autowired322("chartTranslationService")
], _GradientLegendPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct31
], _GradientLegendPanel.prototype, "init", 1);
var GradientLegendPanel = _GradientLegendPanel;
var ChartPanelFeature = class extends BeanStub72 {
  constructor(chartController, eGui, cssClass, createPanels) {
    super();
    this.chartController = chartController;
    this.eGui = eGui;
    this.cssClass = cssClass;
    this.createPanels = createPanels;
    this.panels = [];
  }
  postConstruct() {
    this.addManagedListener(this.chartController, ChartController.EVENT_CHART_UPDATED, () => this.refreshPanels(true));
    this.addManagedListener(this.chartController, ChartController.EVENT_CHART_API_UPDATE, () => this.refreshPanels(false));
  }
  addComponent(component) {
    this.createBean(component);
    this.panels.push(component);
    component.addCssClass(this.cssClass);
    this.eGui.appendChild(component.getGui());
  }
  refreshPanels(reuse) {
    const chartType = this.chartController.getChartType();
    const isGrouping = this.chartController.isGrouping();
    const seriesType = getSeriesType(chartType);
    if (reuse && chartType === this.chartType && isGrouping === this.isGrouping) {
      return;
    }
    this.destroyPanels();
    this.createPanels(chartType, seriesType);
    this.chartType = chartType;
    this.isGrouping = isGrouping;
  }
  destroyPanels() {
    this.panels.forEach((panel) => {
      _242.removeFromParent(panel.getGui());
      this.destroyBean(panel);
    });
    this.panels = [];
  }
  destroy() {
    this.destroyPanels();
    super.destroy();
  }
};
__decorateClass4([
  PostConstruct322
], ChartPanelFeature.prototype, "postConstruct", 1);
var DefaultFormatPanelDef = {
  groups: [
    { type: "chart" },
    { type: "legend" },
    { type: "series" },
    { type: "axis" }
  ]
};
var _FormatPanel = class _FormatPanel2 extends Component28 {
  constructor(chartMenuContext) {
    super(_FormatPanel2.TEMPLATE);
    this.chartMenuContext = chartMenuContext;
  }
  init() {
    this.chartPanelFeature = this.createManagedBean(new ChartPanelFeature(
      this.chartMenuContext.chartController,
      this.getGui(),
      "ag-chart-format-section",
      (chartType, seriesType) => this.createPanels(chartType, seriesType)
    ));
    this.chartPanelFeature.refreshPanels();
  }
  createPanels(chartType, seriesType) {
    var _a;
    (_a = this.getFormatPanelDef().groups) == null ? void 0 : _a.forEach((groupDef) => {
      const group = groupDef.type;
      if (!this.isGroupPanelShownInSeries(group, seriesType)) {
        return;
      }
      const opts = __spreadProps3(__spreadValues3({}, this.chartMenuContext), {
        isExpandedOnInit: groupDef.isOpen,
        seriesType
      });
      switch (group) {
        case "chart":
          this.chartPanelFeature.addComponent(new ChartPanel(opts));
          break;
        case "legend":
          const panel = ["treemap", "sunburst", "heatmap"].includes(chartType) ? new GradientLegendPanel(opts) : new LegendPanel(opts);
          this.chartPanelFeature.addComponent(panel);
          break;
        case "axis":
          if (isPolar(seriesType)) {
            this.chartPanelFeature.addComponent(new PolarAxisPanel(opts));
          } else if (isCartesian(seriesType)) {
            this.chartPanelFeature.addComponent(new CartesianAxisPanel("xAxis", opts));
            this.chartPanelFeature.addComponent(new CartesianAxisPanel("yAxis", opts));
          }
          break;
        case "horizontalAxis":
          this.chartPanelFeature.addComponent(new CartesianAxisPanel("xAxis", opts));
          break;
        case "verticalAxis":
          this.chartPanelFeature.addComponent(new CartesianAxisPanel("yAxis", opts));
          break;
        case "series":
          this.chartPanelFeature.addComponent(new SeriesPanel(opts));
          break;
        case "navigator":
          _25.warnOnce(`'navigator' is now displayed in the charts advanced settings instead of the format panel, and this setting will be ignored.`);
        default:
          _25.warnOnce(`Invalid charts format panel group name supplied: '${groupDef.type}'`);
      }
    });
  }
  getFormatPanelDef() {
    var _a;
    const userProvidedFormatPanelDef = (_a = this.gos.get("chartToolPanelsDef")) == null ? void 0 : _a.formatPanel;
    return userProvidedFormatPanelDef ? userProvidedFormatPanelDef : DefaultFormatPanelDef;
  }
  isGroupPanelShownInSeries(group, seriesType) {
    return ["chart", "legend", "series"].includes(group) || isCartesian(seriesType) && ["axis", "horizontalAxis", "verticalAxis"].includes(group) || isPolar(seriesType) && group === "axis";
  }
};
_FormatPanel.TEMPLATE = /* html */
`<div class="ag-chart-format-wrapper"></div>`;
__decorateClass4([
  PostConstruct33
], _FormatPanel.prototype, "init", 1);
var FormatPanel = _FormatPanel;
var CANVAS_CLASS = "ag-chart-mini-thumbnail-canvas";
var ERROR_MESSAGE = "AG Grid - chart update failed";
var MiniChart = class extends Component29 {
  constructor(container, tooltipName) {
    super();
    this.tooltipName = tooltipName;
    this.size = 58;
    this.padding = 5;
    this.root = new _Scene.Group();
    const scene = new _Scene.Scene({
      width: this.size,
      height: this.size
    });
    scene.canvas.element.classList.add(CANVAS_CLASS);
    scene.setRoot(this.root);
    scene.setContainer(container);
    this.scene = scene;
  }
  init() {
    this.scene.canvas.element.title = this.chartTranslationService.translate(this.tooltipName);
    this.scene.render().catch((e) => {
      console.error(`${ERROR_MESSAGE}`, e);
    });
  }
};
__decorateClass4([
  Autowired332("chartTranslationService")
], MiniChart.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct34
], MiniChart.prototype, "init", 1);
var MiniChartWithAxes = class extends MiniChart {
  constructor(container, tooltipName) {
    super(container, tooltipName);
    this.stroke = "gray";
    this.axisOvershoot = 3;
  }
  addAxes() {
    const size = this.size;
    const padding = this.padding;
    const leftAxis = new _Scene2.Line();
    leftAxis.x1 = padding;
    leftAxis.y1 = padding;
    leftAxis.x2 = padding;
    leftAxis.y2 = size - padding + this.axisOvershoot;
    leftAxis.stroke = this.stroke;
    const bottomAxis = new _Scene2.Line();
    bottomAxis.x1 = padding - this.axisOvershoot + 1;
    bottomAxis.y1 = size - padding;
    bottomAxis.x2 = size - padding + 1;
    bottomAxis.y2 = size - padding;
    bottomAxis.stroke = this.stroke;
    const root = this.root;
    root.append(leftAxis);
    root.append(bottomAxis);
  }
};
__decorateClass4([
  PostConstruct35
], MiniChartWithAxes.prototype, "addAxes", 1);
function createColumnRects(params) {
  const { stacked, size, padding, xScalePadding, xScaleDomain, yScaleDomain } = params;
  const xScale = new _Scene3.BandScale();
  xScale.domain = xScaleDomain;
  xScale.range = [padding, size - padding];
  xScale.paddingInner = xScalePadding;
  xScale.paddingOuter = xScalePadding;
  const yScale = new _Scene3.LinearScale();
  yScale.domain = yScaleDomain;
  yScale.range = [size - padding, padding];
  const createBars = (series, xScale2, yScale2) => {
    return series.map((datum, i) => {
      const top = yScale2.convert(datum);
      const rect = new _Scene3.Rect();
      rect.x = xScale2.convert(i);
      rect.y = top;
      rect.width = xScale2.bandwidth;
      rect.height = yScale2.convert(0) - top;
      rect.strokeWidth = 0;
      rect.crisp = true;
      return rect;
    });
  };
  if (stacked) {
    return params.data.map((d) => createBars(d, xScale, yScale));
  }
  return createBars(params.data, xScale, yScale);
}
function createLinePaths(root, data, size, padding) {
  const xScale = new _Scene3.LinearScale();
  xScale.domain = [0, 4];
  xScale.range = [padding, size - padding];
  const yScale = new _Scene3.LinearScale();
  yScale.domain = [0, 10];
  yScale.range = [size - padding, padding];
  const lines = data.map((series) => {
    const line = new _Scene3.Path();
    line.strokeWidth = 3;
    line.lineCap = "round";
    line.fill = void 0;
    series.forEach((datum, i) => {
      line.path[i > 0 ? "lineTo" : "moveTo"](xScale.convert(i), yScale.convert(datum));
    });
    return line;
  });
  const linesGroup = new _Scene3.Group();
  linesGroup.setClipRectInGroupCoordinateSpace(
    new _Scene3.BBox(padding, padding, size - padding * 2, size - padding * 2)
  );
  linesGroup.append(lines);
  root.append(linesGroup);
  return lines;
}
function createPolarPaths(root, data, size, radius, innerRadius, markerSize = 0) {
  const angleScale = new _Scene3.LinearScale();
  angleScale.domain = [0, 7];
  angleScale.range = [-Math.PI, Math.PI].map((angle) => angle + Math.PI / 2);
  const radiusScale = new _Scene3.LinearScale();
  radiusScale.domain = [0, 10];
  radiusScale.range = [radius, innerRadius];
  const markers = [];
  const paths = data.map((series) => {
    const path = new _Scene3.Path();
    path.strokeWidth = 1;
    path.strokeOpacity = 0.5;
    path.lineCap = "round";
    path.fill = void 0;
    path.fillOpacity = 0.8;
    series.forEach((datum, i) => {
      const angle = angleScale.convert(i);
      const r = radius + innerRadius - radiusScale.convert(datum);
      const x = r * Math.cos(angle);
      const y = r * Math.sin(angle);
      path.path[i > 0 ? "lineTo" : "moveTo"](x, y);
      if (markerSize > 0) {
        const marker = new _Scene3.Circle();
        marker.x = x;
        marker.y = y;
        marker.size = markerSize;
        markers.push(marker);
      }
    });
    path.path.closePath();
    return path;
  });
  const group = new _Scene3.Group();
  const center = size / 2;
  group.translationX = center;
  group.translationY = center;
  group.append([...paths, ...markers]);
  root.append(group);
  return { paths, markers };
}
function accumulateData(data) {
  let [min, max] = [Infinity, -Infinity];
  const processedData = data.reduce((acc, curr, currIndex) => {
    var _a;
    const previous = currIndex > 0 ? acc[currIndex - 1] : void 0;
    (_a = acc[currIndex]) != null ? _a : acc[currIndex] = [];
    const current = acc[currIndex];
    curr.forEach((datum, datumIndex) => {
      if (previous) {
        datum += previous[datumIndex];
      }
      current[datumIndex] = datum;
      if (current[datumIndex] < min) {
        min = current[datumIndex];
      }
      if (current[datumIndex] > max) {
        max = current[datumIndex];
      }
    });
    return acc;
  }, []);
  return { processedData, min, max };
}
var MiniColumn = class extends MiniChartWithAxes {
  constructor(container, fills, strokes) {
    super(container, "groupedColumnTooltip");
    this.columnData = [2, 3, 4];
    const { root, columnData, size, padding } = this;
    this.columns = createColumnRects({
      stacked: false,
      root,
      data: columnData,
      size,
      padding,
      xScaleDomain: [0, 1, 2],
      yScaleDomain: [0, 4],
      xScalePadding: 0.3
    });
    root.append(this.columns);
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.columns.forEach((column, i) => {
      column.fill = fills[i];
      column.stroke = strokes[i];
    });
  }
};
MiniColumn.chartType = "groupedColumn";
var _MiniStackedColumn = class _MiniStackedColumn2 extends MiniChartWithAxes {
  constructor(container, fills, strokes, _themeTemplateParameters, _isCustomTheme, data = _MiniStackedColumn2.data, yScaleDomain = [0, 16], tooltipName = "stackedColumnTooltip") {
    super(container, tooltipName);
    const { root, size, padding } = this;
    this.stackedColumns = createColumnRects({
      stacked: true,
      root,
      data,
      size,
      padding,
      xScaleDomain: [0, 1, 2],
      yScaleDomain,
      xScalePadding: 0.3
    });
    root.append([].concat.apply([], this.stackedColumns));
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.stackedColumns.forEach(
      (series, i) => series.forEach((column) => {
        column.fill = fills[i];
        column.stroke = strokes[i];
      })
    );
  }
};
_MiniStackedColumn.chartType = "stackedColumn";
_MiniStackedColumn.data = [
  [8, 12, 16],
  [6, 9, 12],
  [2, 3, 4]
];
var MiniStackedColumn = _MiniStackedColumn;
var _MiniNormalizedColumn = class _MiniNormalizedColumn2 extends MiniStackedColumn {
  constructor(container, fills, strokes, themeTemplateParameters, isCustomTheme) {
    super(container, fills, strokes, themeTemplateParameters, isCustomTheme, _MiniNormalizedColumn2.data, [0, 10], "normalizedColumnTooltip");
  }
};
_MiniNormalizedColumn.chartType = "normalizedColumn";
_MiniNormalizedColumn.data = [
  [10, 10, 10],
  [6, 7, 8],
  [2, 4, 6]
];
var MiniNormalizedColumn = _MiniNormalizedColumn;
var MiniBar = class extends MiniChartWithAxes {
  constructor(container, fills, strokes) {
    super(container, "groupedBarTooltip");
    const padding = this.padding;
    const size = this.size;
    const data = [2, 3, 4];
    const yScale = new _Scene4.BandScale();
    yScale.domain = [0, 1, 2];
    yScale.range = [padding, size - padding];
    yScale.paddingInner = 0.3;
    yScale.paddingOuter = 0.3;
    const xScale = new _Scene4.LinearScale();
    xScale.domain = [0, 4];
    xScale.range = [size - padding, padding];
    const bottom = xScale.convert(0);
    const height = yScale.bandwidth;
    this.bars = data.map((datum, i) => {
      const rect = new _Scene4.Rect();
      rect.x = padding;
      rect.y = yScale.convert(i);
      rect.width = bottom - xScale.convert(datum);
      rect.height = height;
      rect.strokeWidth = 0;
      rect.crisp = true;
      return rect;
    });
    this.updateColors(fills, strokes);
    this.root.append(this.bars);
  }
  updateColors(fills, strokes) {
    this.bars.forEach((bar, i) => {
      bar.fill = fills[i];
      bar.stroke = strokes[i];
    });
  }
};
MiniBar.chartType = "groupedBar";
var _MiniStackedBar = class _MiniStackedBar2 extends MiniChartWithAxes {
  constructor(container, fills, strokes, _themeTemplateParameters, _isCustomTheme, data = _MiniStackedBar2.data, xScaleDomain = [0, 16], tooltipName = "stackedBarTooltip") {
    super(container, tooltipName);
    const size = this.size;
    const padding = this.padding;
    const yScale = new _Scene5.BandScale();
    yScale.domain = [0, 1, 2];
    yScale.range = [padding, size - padding];
    yScale.paddingInner = 0.3;
    yScale.paddingOuter = 0.3;
    const xScale = new _Scene5.LinearScale();
    xScale.domain = xScaleDomain;
    xScale.range = [size - padding, padding];
    const bottom = xScale.convert(0);
    const height = yScale.bandwidth;
    this.bars = data.map(
      (series) => series.map((datum, i) => {
        const rect = new _Scene5.Rect();
        rect.x = padding;
        rect.y = yScale.convert(i);
        rect.width = bottom - xScale.convert(datum);
        rect.height = height;
        rect.strokeWidth = 0;
        rect.crisp = true;
        return rect;
      })
    );
    this.updateColors(fills, strokes);
    this.root.append([].concat.apply([], this.bars));
  }
  updateColors(fills, strokes) {
    this.bars.forEach(
      (series, i) => series.forEach((bar) => {
        bar.fill = fills[i];
        bar.stroke = strokes[i];
      })
    );
  }
};
_MiniStackedBar.chartType = "stackedBar";
_MiniStackedBar.data = [
  [8, 12, 16],
  [6, 9, 12],
  [2, 3, 4]
];
var MiniStackedBar = _MiniStackedBar;
var _MiniNormalizedBar = class _MiniNormalizedBar2 extends MiniStackedBar {
  constructor(container, fills, strokes, themeTemplateParameters, isCustomTheme) {
    super(container, fills, strokes, themeTemplateParameters, isCustomTheme, _MiniNormalizedBar2.data, [0, 10], "normalizedBarTooltip");
  }
};
_MiniNormalizedBar.chartType = "normalizedBar";
_MiniNormalizedBar.data = [
  [10, 10, 10],
  [6, 7, 8],
  [2, 4, 6]
];
var MiniNormalizedBar = _MiniNormalizedBar;
var toRadians = _Scene6.toRadians;
var MiniDonut = class extends MiniChart {
  constructor(container, fills, strokes, _themeTemplateParameters, _isCustomTheme, centerRadiusScaler = 0.6, tooltipName = "donutTooltip") {
    super(container, tooltipName);
    const radius = (this.size - this.padding * 2) / 2;
    const center = radius + this.padding;
    const angles = [
      [toRadians(-90), toRadians(30)],
      [toRadians(30), toRadians(120)],
      [toRadians(120), toRadians(180)],
      [toRadians(180), toRadians(210)],
      [toRadians(210), toRadians(240)],
      [toRadians(240), toRadians(270)]
    ];
    this.sectors = angles.map(([startAngle, endAngle]) => {
      const sector = new _Scene6.Sector();
      sector.centerX = center;
      sector.centerY = center;
      sector.innerRadius = radius * centerRadiusScaler;
      sector.outerRadius = radius;
      sector.startAngle = startAngle;
      sector.endAngle = endAngle;
      sector.stroke = void 0;
      sector.strokeWidth = 0;
      sector.inset = 0.75;
      return sector;
    });
    this.updateColors(fills, strokes);
    this.root.append(this.sectors);
  }
  updateColors(fills, strokes) {
    this.sectors.forEach((sector, i) => {
      sector.fill = fills[i % fills.length];
      sector.stroke = strokes[i % strokes.length];
    });
  }
};
MiniDonut.chartType = "donut";
var MiniPie = class extends MiniDonut {
  constructor(container, fills, strokes, themeTemplateParameters, isCustomTheme) {
    super(container, fills, strokes, themeTemplateParameters, isCustomTheme, 0, "pieTooltip");
  }
};
MiniPie.chartType = "pie";
var MiniLine = class extends MiniChartWithAxes {
  constructor(container, fills, strokes) {
    super(container, "lineTooltip");
    this.data = [
      [9, 7, 8, 5, 6],
      [5, 6, 3, 4, 1],
      [1, 3, 4, 8, 7]
    ];
    this.lines = createLinePaths(this.root, this.data, this.size, this.padding);
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.lines.forEach((line, i) => {
      line.stroke = fills[i];
    });
  }
};
MiniLine.chartType = "line";
var MiniScatter = class extends MiniChartWithAxes {
  constructor(container, fills, strokes) {
    super(container, "scatterTooltip");
    const size = this.size;
    const padding = this.padding;
    const data = [
      [[0.3, 3], [1.1, 0.9], [2, 0.4], [3.4, 2.4]],
      [[0, 0.3], [1, 2], [2.4, 1.4], [3, 0]]
    ];
    const xScale = new _Scene7.LinearScale();
    xScale.domain = [-0.5, 4];
    xScale.range = [padding * 2, size - padding];
    const yScale = new _Scene7.LinearScale();
    yScale.domain = [-0.5, 3.5];
    yScale.range = [size - padding, padding];
    const points = [];
    data.forEach((series) => {
      series.forEach(([x, y]) => {
        const arc = new _Scene7.Arc();
        arc.strokeWidth = 0;
        arc.centerX = xScale.convert(x);
        arc.centerY = yScale.convert(y);
        arc.radius = 2.5;
        points.push(arc);
      });
    });
    this.points = points;
    this.updateColors(fills, strokes);
    const pointsGroup = new _Scene7.Group();
    pointsGroup.setClipRectInGroupCoordinateSpace(new _Scene7.BBox(padding, padding, size - padding * 2, size - padding * 2));
    pointsGroup.append(this.points);
    this.root.append(pointsGroup);
  }
  updateColors(fills, strokes) {
    this.points.forEach((line, i) => {
      line.stroke = strokes[i % strokes.length];
      line.fill = fills[i % fills.length];
    });
  }
};
MiniScatter.chartType = "scatter";
var MiniBubble = class extends MiniChartWithAxes {
  constructor(container, fills, strokes) {
    super(container, "bubbleTooltip");
    const size = this.size;
    const padding = this.padding;
    const data = [
      [[0.1, 0.3, 5], [0.5, 0.4, 7], [0.2, 0.8, 7]],
      [[0.8, 0.7, 5], [0.7, 0.3, 9]]
    ];
    const xScale = new _Scene8.LinearScale();
    xScale.domain = [0, 1];
    xScale.range = [padding * 2, size - padding];
    const yScale = new _Scene8.LinearScale();
    yScale.domain = [0, 1];
    yScale.range = [size - padding, padding];
    const points = [];
    data.forEach((series) => {
      series.forEach(([x, y, radius]) => {
        const arc = new _Scene8.Arc();
        arc.strokeWidth = 0;
        arc.centerX = xScale.convert(x);
        arc.centerY = yScale.convert(y);
        arc.radius = radius;
        arc.fillOpacity = 0.7;
        points.push(arc);
      });
    });
    this.points = points;
    this.updateColors(fills, strokes);
    const pointsGroup = new _Scene8.Group();
    pointsGroup.setClipRectInGroupCoordinateSpace(new _Scene8.BBox(padding, padding, size - padding * 2, size - padding * 2));
    pointsGroup.append(this.points);
    this.root.append(pointsGroup);
  }
  updateColors(fills, strokes) {
    this.points.forEach((line, i) => {
      line.stroke = strokes[i % strokes.length];
      line.fill = fills[i % fills.length];
    });
  }
};
MiniBubble.chartType = "bubble";
var _MiniArea = class _MiniArea2 extends MiniChartWithAxes {
  constructor(container, fills, strokes, _themeTemplateParameters, _isCustomTheme, data = _MiniArea2.data) {
    super(container, "groupedAreaTooltip");
    const size = this.size;
    const padding = this.padding;
    const xScale = new _Scene9.BandScale();
    xScale.domain = [0, 1, 2];
    xScale.paddingInner = 1;
    xScale.paddingOuter = 0;
    xScale.range = [padding + 0.5, size - padding - 0.5];
    const yScale = new _Scene9.LinearScale();
    yScale.domain = [0, 6];
    yScale.range = [size - padding + 0.5, padding];
    const xCount = data.length;
    const last = xCount * 2 - 1;
    const pathData = [];
    const bottomY = yScale.convert(0);
    data.forEach((datum, i) => {
      const x = xScale.convert(i);
      datum.forEach((yDatum, j) => {
        const y = yScale.convert(yDatum);
        const points = pathData[j] || (pathData[j] = []);
        points[i] = {
          x,
          y
        };
        points[last - i] = {
          x,
          y: bottomY
        };
      });
    });
    this.areas = pathData.reverse().map((points) => {
      const area = new _Scene9.Path();
      area.strokeWidth = 1;
      area.strokeOpacity = 0.75;
      area.fillOpacity = 0.7;
      const path = area.path;
      path.clear();
      points.forEach((point, i) => path[i > 0 ? "lineTo" : "moveTo"](point.x, point.y));
      path.closePath();
      return area;
    });
    this.updateColors(fills, strokes);
    this.root.append(this.areas);
  }
  updateColors(fills, strokes) {
    this.areas.forEach((area, i) => {
      area.fill = fills[i];
      area.stroke = strokes[i];
    });
  }
};
_MiniArea.chartType = "area";
_MiniArea.data = [
  [1, 3, 5],
  [2, 6, 4],
  [5, 3, 1]
];
var MiniArea = _MiniArea;
var _MiniStackedArea = class _MiniStackedArea2 extends MiniChartWithAxes {
  constructor(container, fills, strokes, _themeTemplateParameters, _isCustomTheme, data = _MiniStackedArea2.data, tooltipName = "stackedAreaTooltip") {
    super(container, tooltipName);
    const size = this.size;
    const padding = this.padding;
    const xScale = new _Scene10.BandScale();
    xScale.domain = [0, 1, 2];
    xScale.paddingInner = 1;
    xScale.paddingOuter = 0;
    xScale.range = [padding + 0.5, size - padding - 0.5];
    const yScale = new _Scene10.LinearScale();
    yScale.domain = [0, 16];
    yScale.range = [size - padding + 0.5, padding + 0.5];
    const xCount = data.length;
    const last = xCount * 2 - 1;
    const pathData = [];
    data.forEach((datum, i) => {
      const x = xScale.convert(i);
      let total = 0;
      datum.forEach((yDatum, j) => {
        const y = yScale.convert(total + yDatum);
        const points = pathData[j] || (pathData[j] = []);
        points[i] = {
          x,
          y
        };
        points[last - i] = {
          x,
          y: yScale.convert(total)
          // bottom y
        };
        total += yDatum;
      });
    });
    this.areas = pathData.map((points) => {
      const area = new _Scene10.Path();
      area.strokeWidth = 0;
      const path = area.path;
      path.clear();
      points.forEach((point, i) => path[i > 0 ? "lineTo" : "moveTo"](point.x, point.y));
      path.closePath();
      return area;
    });
    this.updateColors(fills, strokes);
    this.root.append(this.areas);
  }
  updateColors(fills, strokes) {
    this.areas.forEach((area, i) => {
      area.fill = fills[i];
      area.stroke = strokes[i];
    });
  }
};
_MiniStackedArea.chartType = "stackedArea";
_MiniStackedArea.data = [
  [2, 3, 2],
  [3, 6, 5],
  [6, 2, 2]
];
var MiniStackedArea = _MiniStackedArea;
var _MiniNormalizedArea = class _MiniNormalizedArea2 extends MiniStackedArea {
  constructor(container, fills, strokes, themeTemplateParameters, isCustomTheme, data = _MiniNormalizedArea2.data) {
    super(container, fills, strokes, themeTemplateParameters, isCustomTheme, data, "normalizedAreaTooltip");
  }
};
_MiniNormalizedArea.chartType = "normalizedArea";
_MiniNormalizedArea.data = MiniStackedArea.data.map((stack) => {
  const sum = stack.reduce((p, c) => p + c, 0);
  return stack.map((v) => v / sum * 16);
});
var MiniNormalizedArea = _MiniNormalizedArea;
var MiniHistogram = class extends MiniChartWithAxes {
  constructor(container, fills, strokes) {
    super(container, "histogramTooltip");
    const padding = this.padding;
    const size = this.size;
    const data = [2, 5, 11, 13, 10, 6, 1];
    const xScale = new _Scene11.LinearScale();
    xScale.domain = [0, data.length];
    xScale.range = [padding, size - padding];
    const yScale = new _Scene11.LinearScale();
    yScale.domain = [0, data.reduce((a, b) => Math.max(a, b), 0)];
    yScale.range = [size - padding, padding];
    const bottom = yScale.convert(0);
    this.bars = data.map((datum, i) => {
      const top = yScale.convert(datum);
      const left = xScale.convert(i);
      const right = xScale.convert(i + 1);
      const rect = new _Scene11.Rect();
      rect.x = left;
      rect.y = top;
      rect.width = right - left;
      rect.height = bottom - top;
      rect.strokeWidth = 1;
      rect.strokeOpacity = 0.75;
      rect.crisp = true;
      return rect;
    });
    this.updateColors(fills, strokes);
    this.root.append(this.bars);
  }
  updateColors([fill], [stroke]) {
    this.bars.forEach((bar) => {
      bar.fill = fill;
      bar.stroke = stroke;
    });
  }
};
MiniHistogram.chartType = "histogram";
var MiniChartWithPolarAxes = class extends MiniChart {
  constructor(container, tooltipName) {
    super(container, tooltipName);
    this.stroke = "gray";
    this.showRadiusAxisLine = true;
    this.showAngleAxisLines = true;
  }
  addAxes() {
    const size = this.size;
    const padding = this.padding;
    const combinedPadding = padding * 2;
    const axisLineRadius = (size - combinedPadding) / 2;
    const gridRadii = this.showAngleAxisLines ? [
      axisLineRadius,
      axisLineRadius * 0.8,
      axisLineRadius * 0.6,
      axisLineRadius * 0.4
    ] : [];
    const radiusAxisLine = new _Scene12.Line();
    radiusAxisLine.x1 = size / 2;
    radiusAxisLine.y1 = padding;
    radiusAxisLine.x2 = size / 2;
    radiusAxisLine.y2 = size - padding - axisLineRadius - gridRadii[gridRadii.length - 1];
    radiusAxisLine.stroke = this.stroke;
    radiusAxisLine.strokeOpacity = 0.5;
    radiusAxisLine.fill = void 0;
    radiusAxisLine.visible = this.showRadiusAxisLine;
    const x = padding + axisLineRadius;
    this.gridLines = gridRadii.map((radius, index) => {
      const gridLine = new _Scene12.Path();
      gridLine.path.arc(x, x, radius, 0, 2 * Math.PI);
      gridLine.strokeWidth = 1;
      gridLine.stroke = this.stroke;
      gridLine.strokeOpacity = index === 0 ? 0.5 : 0.2;
      gridLine.fill = void 0;
      return gridLine;
    });
    const root = this.root;
    root.append(radiusAxisLine);
    if (this.gridLines.length > 0)
      root.append(this.gridLines);
  }
};
__decorateClass4([
  PostConstruct36
], MiniChartWithPolarAxes.prototype, "addAxes", 1);
var MiniRadialColumn = class extends MiniChartWithPolarAxes {
  constructor(container, fills, strokes) {
    super(container, "radialColumnTooltip");
    this.data = [
      [6, 8, 10, 2, 6, 5],
      [4, 4, 3, 6, 4, 4],
      [5, 4, 2, 9, 8, 9]
    ];
    this.showRadiusAxisLine = false;
    const { padding, size, data } = this;
    const radius = (size - padding * 2) / 2;
    const innerRadiusRatio = 0.4;
    const axisInnerRadius = radius * innerRadiusRatio;
    const angleScale = new _Scene13.BandScale();
    angleScale.domain = data[0].map((_382, index) => index);
    angleScale.range = [0, 2 * Math.PI];
    angleScale.paddingInner = 0;
    angleScale.paddingOuter = 0;
    const bandwidth = angleScale.bandwidth * 0.7;
    const { processedData, max } = accumulateData(data);
    const radiusScale = new _Scene13.LinearScale();
    radiusScale.domain = [0, max];
    radiusScale.range = [axisInnerRadius, radius];
    const center = this.size / 2;
    this.series = processedData.map((series, seriesIndex) => {
      const firstSeries = seriesIndex === 0;
      const previousSeries = firstSeries ? void 0 : processedData[seriesIndex - 1];
      const seriesGroup = new _Scene13.Group({ zIndex: 1e6 });
      const seriesColumns = series.map((datum, i) => {
        const previousDatum = previousSeries == null ? void 0 : previousSeries[i];
        const outerRadius = radiusScale.convert(datum);
        const innerRadius = radiusScale.convert(previousDatum != null ? previousDatum : 0);
        const startAngle = angleScale.convert(i);
        const endAngle = startAngle + bandwidth;
        const columnWidth = _Scene13.getRadialColumnWidth(startAngle, endAngle, radius, 0.5, 0.5);
        const column = new _Scene13.RadialColumnShape();
        column.scalingCenterX = center;
        column.scalingCenterY = center;
        column.columnWidth = columnWidth;
        column.innerRadius = innerRadius;
        column.outerRadius = outerRadius;
        column.startAngle = startAngle;
        column.endAngle = endAngle;
        column.isBeveled = true;
        column.axisInnerRadius = axisInnerRadius;
        column.axisOuterRadius = radius;
        column.stroke = void 0;
        column.strokeWidth = 0;
        return column;
      });
      seriesGroup.append(seriesColumns);
      seriesGroup.translationX = center;
      seriesGroup.translationY = center;
      return seriesGroup;
    });
    this.root.append(this.series);
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.series.forEach((group, i) => {
      var _a;
      (_a = group.children) == null ? void 0 : _a.forEach((sector) => {
        sector.fill = fills[i % fills.length];
        sector.stroke = strokes[i % strokes.length];
      });
    });
  }
};
MiniRadialColumn.chartType = "radialColumn";
var MiniRadialBar = class extends MiniChartWithPolarAxes {
  constructor(container, fills, strokes) {
    super(container, "radialBarTooltip");
    this.data = [
      [6, 8, 10],
      [4, 4, 3],
      [5, 4, 2]
    ];
    this.showRadiusAxisLine = false;
    const radius = (this.size - this.padding) / 2;
    const innerRadiusRatio = 0.4;
    const innerRadius = radius * innerRadiusRatio;
    const totalRadius = radius + innerRadius;
    const radiusScale = new _Scene14.BandScale();
    radiusScale.domain = this.data[0].map((_382, index) => index);
    radiusScale.range = [radius, innerRadius];
    radiusScale.paddingInner = 0.5;
    radiusScale.paddingOuter = 0;
    const bandwidth = radiusScale.bandwidth;
    const { processedData, max } = accumulateData(this.data);
    const angleScale = new _Scene14.LinearScale();
    angleScale.domain = [0, Math.ceil(max * 1.5)];
    const start = 3 / 2 * Math.PI;
    const end = start + 2 * Math.PI;
    angleScale.range = [start, end];
    const center = this.size / 2;
    this.series = processedData.map((series, index) => {
      const previousSeries = index < 0 ? void 0 : processedData[index - 1];
      const seriesGroup = new _Scene14.Group({ zIndex: 1e6 });
      const seriesSectors = series.map((datum, i) => {
        var _a;
        const previousDatum = (_a = previousSeries == null ? void 0 : previousSeries[i]) != null ? _a : 0;
        const innerRadius2 = totalRadius - radiusScale.convert(i);
        const outerRadius = innerRadius2 + bandwidth;
        const startAngle = angleScale.convert(previousDatum);
        const endAngle = angleScale.convert(datum);
        const sector = new _Scene14.Sector();
        sector.centerX = center;
        sector.centerY = center;
        sector.innerRadius = innerRadius2;
        sector.outerRadius = outerRadius;
        sector.startAngle = startAngle;
        sector.endAngle = endAngle;
        sector.stroke = void 0;
        sector.strokeWidth = 0;
        return sector;
      });
      seriesGroup.append(seriesSectors);
      return seriesGroup;
    });
    this.root.append(this.series);
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.series.forEach((group, i) => {
      var _a;
      (_a = group.children) == null ? void 0 : _a.forEach((sector) => {
        sector.fill = fills[i % fills.length];
        sector.stroke = strokes[i % strokes.length];
      });
    });
  }
};
MiniRadialBar.chartType = "radialBar";
var MiniRadarLine = class extends MiniChartWithPolarAxes {
  constructor(container, fills, strokes) {
    super(container, "radarLineTooltip");
    this.markerSize = 4;
    this.data = [
      [8, 7, 8, 7, 8, 8, 7, 8],
      [6, 8, 5, 10, 6, 7, 4, 6],
      [0, 3, 3, 5, 4, 4, 2, 0]
    ];
    this.showRadiusAxisLine = false;
    const radius = (this.size - this.padding * 2) / 2;
    const innerRadius = 0;
    const { paths, markers } = createPolarPaths(this.root, this.data, this.size, radius, innerRadius, this.markerSize);
    this.lines = paths;
    this.markers = markers;
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.lines.forEach((line, i) => {
      const n = this.data[i].length;
      line.stroke = fills[i];
      const startIdx = i * n;
      const endIdx = startIdx + n;
      const markers = this.markers.slice(startIdx, endIdx);
      markers.forEach((marker) => {
        marker.stroke = strokes[i];
        marker.fill = fills[i];
      });
    });
  }
};
MiniRadarLine.chartType = "radarLine";
var MiniRadarArea = class extends MiniChartWithPolarAxes {
  constructor(container, fills, strokes) {
    super(container, "radarAreaTooltip");
    this.data = [
      [8, 10, 5, 7, 4, 1, 5, 8],
      [1, 1, 2, 7, 7, 8, 10, 1],
      [4, 5, 9, 9, 4, 2, 3, 4]
    ];
    this.showRadiusAxisLine = false;
    const radius = (this.size - this.padding * 2) / 2;
    const innerRadius = radius - this.size * 0.3;
    this.areas = createPolarPaths(this.root, this.data, this.size, radius, innerRadius).paths;
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.areas.forEach((area, i) => {
      area.fill = fills[i];
      area.stroke = strokes[i];
    });
  }
};
MiniRadarArea.chartType = "radarArea";
var MiniNightingale = class extends MiniChartWithPolarAxes {
  constructor(container, fills, strokes) {
    super(container, "nightingaleTooltip");
    this.data = [
      [6, 10, 9, 8, 7, 8],
      [4, 6, 5, 4, 5, 5],
      [3, 5, 4, 3, 4, 7]
    ];
    this.showRadiusAxisLine = false;
    const radius = (this.size - this.padding * 2) / 2;
    const angleScale = new _Scene15.BandScale();
    angleScale.domain = this.data[0].map((_382, index) => index);
    angleScale.range = [-Math.PI, Math.PI];
    angleScale.paddingInner = 0;
    angleScale.paddingOuter = 0;
    const bandwidth = angleScale.bandwidth * 0.7;
    const { processedData, max } = accumulateData(this.data);
    const radiusScale = new _Scene15.LinearScale();
    radiusScale.domain = [0, max];
    radiusScale.range = [0, radius];
    const center = this.size / 2;
    this.series = processedData.map((series, index) => {
      const previousSeries = index < 0 ? void 0 : processedData[index - 1];
      const seriesGroup = new _Scene15.Group({ zIndex: 1e6 });
      const seriesSectors = series.map((datum, i) => {
        const previousDatum = previousSeries == null ? void 0 : previousSeries[i];
        const outerRadius = radiusScale.convert(datum);
        const innerRadius = radiusScale.convert(previousDatum != null ? previousDatum : 0);
        const startAngle = angleScale.convert(i);
        const endAngle = startAngle + bandwidth;
        const sector = new _Scene15.Sector();
        sector.centerX = center;
        sector.centerY = center;
        sector.innerRadius = innerRadius;
        sector.outerRadius = outerRadius;
        sector.startAngle = startAngle;
        sector.endAngle = endAngle;
        sector.stroke = void 0;
        sector.strokeWidth = 0;
        return sector;
      });
      seriesGroup.append(seriesSectors);
      return seriesGroup;
    });
    this.root.append(this.series);
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.series.forEach((group, i) => {
      var _a;
      (_a = group.children) == null ? void 0 : _a.forEach((sector) => {
        sector.fill = fills[i % fills.length];
        sector.stroke = strokes[i % strokes.length];
      });
    });
  }
};
MiniNightingale.chartType = "nightingale";
var MiniRangeBar = class extends MiniChartWithAxes {
  constructor(container, fills, strokes) {
    super(container, "rangeBarTooltip");
    const data = [3, 3.5, 3];
    this.bars = this.createRangeBar(this.root, data, this.size, this.padding, "vertical");
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.bars.forEach((bar, i) => {
      bar.fill = fills[i];
      bar.stroke = strokes[i];
    });
  }
  createRangeBar(root, data, size, padding, direction) {
    const barAlongX = direction === "horizontal";
    const scalePadding = 2 * padding;
    const xScale = new _Scene16.BandScale();
    xScale.domain = data.map((_382, index) => index);
    xScale.range = [padding, size - padding];
    xScale.paddingInner = 0.3;
    xScale.paddingOuter = 0.3;
    const lowRatio = 0.7;
    const highRatio = 1.3;
    const yScale = new _Scene16.LinearScale();
    yScale.domain = [
      data.reduce((a, b) => Math.min(a, b), Infinity) * lowRatio,
      data.reduce((a, b) => Math.max(a, b), 0) * highRatio
    ];
    yScale.range = [scalePadding, size - scalePadding];
    const width = xScale.bandwidth;
    const bars = data.map((datum, i) => {
      const [low, high] = [datum * lowRatio, datum * highRatio];
      const x = xScale.convert(i);
      const y = yScale.convert(low);
      const height = yScale.convert(high) - y;
      const rect = new _Scene16.Rect();
      rect.x = barAlongX ? y : x;
      rect.y = barAlongX ? x : y;
      rect.width = barAlongX ? height : width;
      rect.height = barAlongX ? width : height;
      rect.strokeWidth = 0;
      rect.crisp = true;
      return rect;
    });
    root.append(bars);
    return bars;
  }
};
MiniRangeBar.chartType = "rangeBar";
var MiniRangeArea = class extends MiniChartWithAxes {
  constructor(container, fills, strokes) {
    super(container, "rangeAreaTooltip");
    const period = 4;
    const dataSeriesMidpoints = [
      zigzag({ offset: 0.375 * period, length: period, pattern: { low: 3, high: 5, period } }),
      zigzag({ offset: 0.375 * period, length: period, pattern: { low: 2.25, high: 4.25, period } }),
      zigzag({ offset: 0.75 * period, length: period, pattern: { low: 2.5, high: 4.5, period } })
    ];
    const dataSeriesWidth = 1.75;
    const data = dataSeriesMidpoints.map(
      (series) => series.map(([x, y]) => ({
        x,
        low: y - 0.5 * dataSeriesWidth,
        high: y + 0.5 * dataSeriesWidth
      }))
    );
    const { lines, areas } = this.createRangeArea(this.root, data, this.size, this.padding);
    this.lines = lines;
    this.areas = areas;
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    fills = swapArrayItems(fills, 1, 2);
    strokes = swapArrayItems(strokes, 1, 2);
    this.lines.forEach(([highLine, lowLine], i) => {
      highLine.fill = void 0;
      highLine.stroke = strokes[i];
      lowLine.fill = void 0;
      lowLine.stroke = strokes[i];
    });
    this.areas.forEach((area, i) => {
      area.fill = fills[i];
    });
  }
  createRangeArea(root, data, size, padding) {
    const xMin = data.reduce((acc, series) => series.reduce((acc2, { x }) => Math.min(acc2, x), acc), Infinity);
    const xMax = data.reduce((acc, series) => series.reduce((acc2, { x }) => Math.max(acc2, x), acc), -Infinity);
    const yMin = data.reduce((acc, series) => series.reduce((acc2, { low }) => Math.min(acc2, low), acc), Infinity);
    const yMax = data.reduce((acc, series) => series.reduce((acc2, { high }) => Math.max(acc2, high), acc), -Infinity);
    const xScale = new _Scene17.LinearScale();
    xScale.domain = [xMin, xMax];
    xScale.range = [padding, size - padding];
    const scalePadding = 2 * padding;
    const yScale = new _Scene17.LinearScale();
    yScale.domain = [yMin, yMax];
    yScale.range = [size - scalePadding, scalePadding];
    const lines = [];
    const areas = [];
    const lowPoints = data.map((series) => {
      const highLine = new _Scene17.Path();
      const lowLine = new _Scene17.Path();
      const area = new _Scene17.Path();
      lines.push([highLine, lowLine]);
      areas.push(area);
      highLine.strokeWidth = 0;
      lowLine.strokeWidth = 0;
      area.strokeWidth = 0;
      area.fillOpacity = 0.8;
      highLine.path.clear();
      lowLine.path.clear();
      area.path.clear();
      return series.map((datum, datumIndex) => {
        const { x, low, high } = datum;
        const scaledX = xScale.convert(x);
        const yLow = yScale.convert(low);
        const yHigh = yScale.convert(high);
        const command = datumIndex > 0 ? "lineTo" : "moveTo";
        highLine.path[command](scaledX, yHigh);
        lowLine.path[command](scaledX, yLow);
        area.path[command](scaledX, yHigh);
        return [scaledX, yLow];
      });
    });
    lowPoints.forEach((seriesLowPoints, seriesIndex) => {
      const n = seriesLowPoints.length - 1;
      const area = areas[seriesIndex];
      for (let datumIndex = n; datumIndex >= 0; datumIndex--) {
        const [x, y] = seriesLowPoints[datumIndex];
        area.path["lineTo"](x, y);
      }
    });
    root.append(areas.concat(...lines));
    return { lines, areas };
  }
};
MiniRangeArea.chartType = "rangeArea";
function zigzag(options) {
  const { offset, length, pattern } = options;
  const points = getZigzagInflectionPoints(offset, length, pattern);
  const xMin = 0;
  const xMax = length;
  if (points.length === 0 || points[0][0] !== xMin)
    points.unshift(getZigzagPoint(xMin, offset, pattern));
  if (points[points.length - 1][0] !== xMax)
    points.push(getZigzagPoint(xMax, offset, pattern));
  return points;
  function getZigzagInflectionPoints(offset2, length2, pattern2) {
    const { period } = pattern2;
    const scaledOffset = offset2 / period;
    const patternInflectionPoints = [0, 0.5];
    const inflectionPoints = patternInflectionPoints.map((x) => x - scaledOffset).map(getRemainderAbs).sort((a, b) => a - b);
    const repeatedPoints = Array.from(
      { length: Math.floor(inflectionPoints.length * (period / length2)) },
      (_382, i) => inflectionPoints[i % inflectionPoints.length] + Math.floor(i / inflectionPoints.length)
    );
    return repeatedPoints.map((x) => x * period).map((x) => getZigzagPoint(x, offset2, pattern2));
  }
  function getZigzagPoint(x, offset2, pattern2) {
    return [x, getZigzagValue(offset2 + x, pattern2)];
  }
  function getZigzagValue(x, pattern2) {
    const { low, high, period } = pattern2;
    const scaledX = getRemainderAbs(x / period);
    const y = scaledX > 0.5 ? 1 - 2 * (scaledX - 0.5) : 2 * scaledX;
    return low + (high - low) * y;
  }
}
function getRemainderAbs(value) {
  const remainder = value % 1;
  return remainder < 0 ? remainder + 1 : remainder;
}
function swapArrayItems(items, leftIndex, rightIndex) {
  const results = [...items];
  const temp = results[leftIndex];
  results[leftIndex] = results[rightIndex];
  results[rightIndex] = temp;
  return results;
}
var MiniBoxPlot = class extends MiniChartWithAxes {
  constructor(container, fills, strokes, themeTemplateParameters, isCustomTheme) {
    super(container, "boxPlotTooltip");
    const padding = this.padding;
    const size = this.size;
    const data = [11, 11.5, 10.5];
    const maxRatio = 1.2;
    const q3Ratio = 1.1;
    const q1Ratio = 0.9;
    const minRatio = 0.8;
    const yScale = new _Scene18.LinearScale();
    yScale.domain = [
      data.reduce((a, b) => Math.min(a, b), Infinity) * minRatio,
      data.reduce((a, b) => Math.max(a, b), 0) * maxRatio
    ];
    yScale.range = [size - 1.5 * padding, padding];
    const xScale = new _Scene18.BandScale();
    xScale.domain = data.map((_382, index) => index);
    xScale.range = [padding, size - padding];
    xScale.paddingInner = 0.4;
    xScale.paddingOuter = 0.2;
    const bandwidth = Math.round(xScale.bandwidth);
    const halfBandWidth = Math.round(xScale.bandwidth / 2);
    this.boxPlotGroups = data.map((datum, i) => {
      let [minValue, q1Value, q3Value, maxValue] = [
        datum * minRatio,
        datum * q1Ratio,
        datum * q3Ratio,
        datum * maxRatio
      ];
      const top = Math.round(yScale.convert(q3Value));
      const left = Math.round(xScale.convert(i));
      const right = Math.round(left + bandwidth);
      const bottom = Math.round(yScale.convert(q1Value));
      const min = Math.round(yScale.convert(minValue));
      const mid = Math.round(yScale.convert(datum));
      const max = Math.round(yScale.convert(maxValue));
      const whiskerX = left + halfBandWidth;
      const boxPlotGroup = new _Scene18.Group();
      const box = new _Scene18.Rect();
      const median = new _Scene18.Line();
      const topWhisker = new _Scene18.Line();
      const bottomWhisker = new _Scene18.Line();
      const topCap = new _Scene18.Line();
      const bottomCap = new _Scene18.Line();
      box.x = left;
      box.y = top;
      box.width = bandwidth;
      box.height = bottom - top;
      box.strokeWidth = 1;
      box.strokeOpacity = 0.75;
      box.crisp = true;
      this.setLineProperties(median, left, right, mid, mid);
      this.setLineProperties(topWhisker, whiskerX, whiskerX, max, top);
      this.setLineProperties(bottomWhisker, whiskerX, whiskerX, min, bottom);
      this.setLineProperties(topCap, left, right, max, max);
      this.setLineProperties(bottomCap, left, right, min, min);
      boxPlotGroup.append([box, median, topWhisker, bottomWhisker, topCap, bottomCap]);
      return boxPlotGroup;
    });
    this.updateColors(fills, strokes, themeTemplateParameters, isCustomTheme);
    this.root.append(this.boxPlotGroups);
  }
  updateColors(fills, strokes, themeTemplateParameters, isCustomTheme) {
    var _a;
    const themeBackgroundColor = themeTemplateParameters == null ? void 0 : themeTemplateParameters.properties.get(_Theme4.DEFAULT_BACKGROUND_COLOUR);
    const backgroundFill = (_a = Array.isArray(themeBackgroundColor) ? themeBackgroundColor[0] : themeBackgroundColor) != null ? _a : "white";
    this.boxPlotGroups.forEach((group, i) => {
      var _a2;
      (_a2 = group.children) == null ? void 0 : _a2.forEach((node) => {
        const fill = fills[i % fills.length];
        node.fill = isCustomTheme ? fill : _Util3.Color.interpolate(fill, backgroundFill)(0.7);
        node.stroke = strokes[i % strokes.length];
      });
    });
  }
  setLineProperties(line, x1, x2, y1, y2) {
    line.x1 = x1;
    line.x2 = x2;
    line.y1 = y1;
    line.y2 = y2;
    line.strokeOpacity = 0.75;
  }
};
MiniBoxPlot.chartType = "boxPlot";
var MiniTreemap = class extends MiniChart {
  constructor(container, fills, strokes, themeTemplate, isCustomTheme) {
    super(container, "treemapTooltip");
    const { size, padding } = this;
    const data = [
      [1, 1],
      [3, 2, 1]
    ];
    const treeSize = data.length;
    const treePadding = treeSize % 2 === 0 ? 0.3 : 0.2;
    const range = [padding, size - padding];
    const columns = data.length;
    const columnParts = columns * (columns + 1) / 2;
    const columnPadding = treePadding / (columns - 1);
    const availableRange = range[1] - range[0];
    const availableWidth = availableRange - treePadding;
    let previousX = range[0];
    this.rects = data.reduce((rects, d, columnIndex) => {
      rects != null ? rects : rects = [];
      const widthRatio = (columns - columnIndex) / columnParts;
      const width = availableWidth * widthRatio;
      const rows = d.length;
      const rowParts = d.reduce((parts, ratio) => parts += ratio, 0);
      const rowPadding = treePadding / (rows - 1 || 1);
      const availableHeight = rows > 1 ? availableRange - treePadding : availableRange;
      let previousY = range[0];
      const xRects = d.map((ratio) => {
        const rect = new _Scene19.Rect();
        const height = availableHeight * ratio / rowParts;
        rect.x = previousX;
        rect.y = previousY;
        rect.width = width;
        rect.height = height;
        rect.strokeWidth = 0.75;
        rect.crisp = true;
        previousY += height + rowPadding;
        return rect;
      });
      previousX += width + columnPadding;
      rects.push(...xRects);
      return rects;
    }, []);
    this.updateColors(fills, strokes, themeTemplate, isCustomTheme);
    const rectGroup = new _Scene19.Group();
    rectGroup.setClipRectInGroupCoordinateSpace(new _Scene19.BBox(padding, padding, size - padding, size - padding));
    rectGroup.append(this.rects);
    this.root.append(rectGroup);
  }
  updateColors(fills, strokes, themeTemplate, isCustomTheme) {
    var _a;
    const { properties } = themeTemplate != null ? themeTemplate : {};
    const defaultBackgroundColor = properties == null ? void 0 : properties.get(_Theme5.DEFAULT_BACKGROUND_COLOUR);
    const backgroundFill = (_a = Array.isArray(defaultBackgroundColor) ? defaultBackgroundColor[0] : defaultBackgroundColor) != null ? _a : "white";
    this.rects.forEach((rect, i) => {
      rect.fill = fills[i % strokes.length];
      rect.stroke = isCustomTheme ? strokes[i % strokes.length] : backgroundFill;
    });
  }
};
MiniTreemap.chartType = "treemap";
var MiniSunburst = class extends MiniChartWithPolarAxes {
  constructor(container, fills, strokes) {
    super(container, "sunburstTooltip");
    this.data = [
      [[], []],
      [[], []],
      [[], []]
    ];
    this.angleOffset = -Math.PI / 2;
    this.innerRadiusRatio = 0;
    this.showRadiusAxisLine = false;
    this.showAngleAxisLines = false;
    const { data, size, padding, angleOffset, innerRadiusRatio } = this;
    const radius = (size - padding * 2) / 2;
    const angleRange = [angleOffset + 0, angleOffset + 2 * Math.PI];
    const angleExtent = Math.abs(angleRange[1] - angleRange[0]);
    const radiusRange = [radius * innerRadiusRatio, radius];
    const radiusExtent = Math.abs(radiusRange[1] - radiusRange[0]);
    let maxDepth = 0;
    const findMaxDepth = (data2, parentDepth) => {
      data2.forEach((child) => {
        const depth = parentDepth + 1;
        maxDepth = Math.max(maxDepth, depth);
        findMaxDepth(child, depth);
      });
    };
    findMaxDepth(data, 0);
    const radiusRatio = radiusExtent / maxDepth;
    const center = this.size / 2;
    const startAngle = angleRange[0];
    this.series = [];
    const createSectors = (data2, depth, startAngle2, availableAngle, group) => {
      const isArray = Array.isArray(data2);
      if (!isArray) {
        return;
      }
      const childDepth = depth + 1;
      let previousAngle = startAngle2;
      data2.forEach((child, childIndex, children) => {
        let childGroup = group;
        if (!childGroup) {
          childGroup = new _Scene20.Group();
          this.series.push(childGroup);
        }
        const innerRadius = radiusRange[0] + depth * radiusRatio;
        const outerRadius = radiusRange[0] + childDepth * radiusRatio;
        const angleRatio = 1 / children.length;
        const start = previousAngle;
        const end = start + availableAngle * angleRatio;
        const sector = new _Scene20.Sector();
        sector.centerX = center;
        sector.centerY = center;
        sector.innerRadius = innerRadius;
        sector.outerRadius = outerRadius;
        sector.startAngle = start;
        sector.endAngle = end;
        sector.stroke = void 0;
        sector.strokeWidth = 0;
        sector.inset = 0.75;
        previousAngle = end;
        childGroup.append(sector);
        createSectors(child, childDepth, start, Math.abs(end - start), childGroup);
      });
    };
    createSectors(data, 0, startAngle, angleExtent);
    this.root.append(this.series);
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.series.forEach((group, i) => {
      var _a;
      (_a = group.children) == null ? void 0 : _a.forEach((sector) => {
        sector.fill = fills[i % fills.length];
        sector.stroke = strokes[i % strokes.length];
      });
    });
  }
};
MiniSunburst.chartType = "sunburst";
var MiniHeatmap = class extends MiniChart {
  constructor(container, fills, strokes, themeTemplate, isCustomTheme) {
    var _a, _b;
    super(container, "heatmapTooltip");
    const { size, padding } = this;
    const heatmapSize = 3;
    const data = Array.from(
      { length: heatmapSize },
      (_382, __) => Array.from({ length: heatmapSize }, (_392, yIndex) => yIndex)
    );
    const domain = data.map((_382, index) => index);
    const xScale = new _Scene21.BandScale();
    xScale.domain = domain;
    xScale.range = [padding, size - padding];
    xScale.paddingInner = 0.01;
    xScale.paddingOuter = 0.1;
    const yScale = new _Scene21.BandScale();
    yScale.domain = domain;
    yScale.range = [padding, size - padding];
    yScale.paddingInner = 0.01;
    yScale.paddingOuter = 0.1;
    const width = (_a = xScale.bandwidth) != null ? _a : 0;
    const height = (_b = yScale.bandwidth) != null ? _b : 0;
    this.rects = data.reduce((rects, d, index) => {
      rects != null ? rects : rects = [];
      const xRects = d.map((_382, yIndex) => {
        const rect = new _Scene21.Rect();
        rect.x = xScale.convert(index);
        rect.y = yScale.convert(yIndex);
        rect.width = width;
        rect.height = height;
        rect.strokeWidth = 0;
        rect.crisp = true;
        return rect;
      });
      rects.push(...xRects);
      return rects;
    }, []);
    this.updateColors(fills, strokes, themeTemplate, isCustomTheme);
    const rectGroup = new _Scene21.Group();
    rectGroup.setClipRectInGroupCoordinateSpace(new _Scene21.BBox(padding, padding, size - padding, size - padding));
    rectGroup.append(this.rects);
    this.root.append(rectGroup);
  }
  updateColors(fills, strokes, themeTemplate, isCustomTheme) {
    var _a;
    const { properties } = themeTemplate != null ? themeTemplate : {};
    const defaultColorRange = properties == null ? void 0 : properties.get(_Theme6.DEFAULT_DIVERGING_SERIES_COLOUR_RANGE);
    const defaultBackgroundColor = properties == null ? void 0 : properties.get(_Theme6.DEFAULT_BACKGROUND_COLOUR);
    const backgroundFill = (_a = Array.isArray(defaultBackgroundColor) ? defaultBackgroundColor[0] : defaultBackgroundColor) != null ? _a : "white";
    const colorRange = isCustomTheme ? [fills[0], fills[1]] : defaultColorRange;
    const stroke = isCustomTheme ? strokes[0] : backgroundFill;
    this.rects.forEach((rect, i) => {
      rect.fill = _Util5.Color.interpolate(colorRange[0], colorRange[1])(i * 0.2);
      rect.stroke = stroke;
    });
  }
};
MiniHeatmap.chartType = "heatmap";
var MiniWaterfall = class extends MiniChartWithAxes {
  constructor(container, fills, strokes, themeTemplate, isCustomTheme) {
    super(container, "waterfallTooltip");
    this.data = [4, 3, -3, 6, -3];
    this.bars = this.createWaterfall(this.root, this.data, this.size, this.padding, "vertical").bars;
    this.updateColors(fills, strokes, themeTemplate, isCustomTheme);
  }
  updateColors(fills, strokes, themeTemplate, isCustomTheme) {
    var _a, _b;
    const { data } = this;
    const { properties } = themeTemplate != null ? themeTemplate : {};
    const palettePositive = {
      fill: fills[0],
      stroke: strokes[0]
    };
    const paletteNegative = {
      fill: fills[1],
      stroke: strokes[1]
    };
    const positive = isCustomTheme ? palettePositive : (_a = properties == null ? void 0 : properties.get(_Theme7.DEFAULT_WATERFALL_SERIES_POSITIVE_COLOURS)) != null ? _a : palettePositive;
    const negative = isCustomTheme ? paletteNegative : (_b = properties == null ? void 0 : properties.get(_Theme7.DEFAULT_WATERFALL_SERIES_NEGATIVE_COLOURS)) != null ? _b : paletteNegative;
    this.bars.forEach((bar, i) => {
      const isPositive = data[i] >= 0;
      bar.fill = isPositive ? positive.fill : negative.fill;
      bar.stroke = isPositive ? positive.stroke : negative.stroke;
    });
  }
  createWaterfall(root, data, size, padding, direction) {
    const scalePadding = 2 * padding;
    const { processedData, min, max } = accumulateData(data.map((d) => [d]));
    const flatData = processedData.reduce((flat, d) => flat.concat(d), []);
    const yScale = new _Scene22.LinearScale();
    yScale.domain = [Math.min(min, 0), max];
    yScale.range = [size - scalePadding, scalePadding];
    const xScale = new _Scene22.BandScale();
    xScale.domain = data.map((_382, index) => index);
    xScale.range = [padding, size - padding];
    xScale.paddingInner = 0.2;
    xScale.paddingOuter = 0.3;
    const width = xScale.bandwidth;
    const connectorLine = new _Scene22.Path();
    connectorLine.stroke = "#575757";
    connectorLine.strokeWidth = 0;
    const pixelAlignmentOffset = Math.floor(connectorLine.strokeWidth) % 2 / 2;
    const connectorPath = connectorLine.path;
    connectorPath.clear();
    const barAlongX = direction === "horizontal";
    const bars = flatData.map((datum, i) => {
      const previousDatum = i > 0 ? flatData[i - 1] : 0;
      const rawValue = data[i];
      const isPositive = rawValue > 0;
      const currY = Math.round(yScale.convert(datum));
      const trailY = Math.round(yScale.convert(previousDatum));
      const y = (isPositive ? currY : trailY) - pixelAlignmentOffset;
      const bottomY = (isPositive ? trailY : currY) + pixelAlignmentOffset;
      const height = Math.abs(bottomY - y);
      const x = xScale.convert(i);
      const rect = new _Scene22.Rect();
      rect.x = barAlongX ? y : x;
      rect.y = barAlongX ? x : y;
      rect.width = barAlongX ? height : width;
      rect.height = barAlongX ? width : height;
      rect.strokeWidth = 0;
      rect.crisp = true;
      const moveTo = currY + pixelAlignmentOffset;
      const lineTo = trailY + pixelAlignmentOffset;
      if (i > 0) {
        const lineToX = barAlongX ? lineTo : rect.x;
        const lineToY = barAlongX ? rect.y : lineTo;
        connectorPath.lineTo(lineToX, lineToY);
      }
      const moveToX = barAlongX ? moveTo : rect.x;
      const moveToY = barAlongX ? rect.y : moveTo;
      connectorPath.moveTo(moveToX, moveToY);
      return rect;
    });
    root.append([connectorLine, ...bars]);
    return { bars };
  }
};
MiniWaterfall.chartType = "waterfall";
var MiniColumnLineCombo = class extends MiniChartWithAxes {
  constructor(container, fills, strokes) {
    super(container, "columnLineComboTooltip");
    this.columnData = [3, 4];
    this.lineData = [
      [5, 4, 6, 5, 4]
    ];
    const { root, columnData, lineData, size, padding } = this;
    this.columns = createColumnRects({
      stacked: false,
      root,
      data: columnData,
      size,
      padding,
      xScaleDomain: [0, 1],
      yScaleDomain: [0, 4],
      xScalePadding: 0.5
    });
    root.append(this.columns);
    this.lines = createLinePaths(root, lineData, size, padding);
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.columns.forEach((bar, i) => {
      bar.fill = fills[i];
      bar.stroke = strokes[i];
    });
    this.lines.forEach((line, i) => {
      line.stroke = fills[i + 2];
    });
  }
};
MiniColumnLineCombo.chartType = "columnLineCombo";
var MiniAreaColumnCombo = class extends MiniChartWithAxes {
  constructor(container, fills, strokes) {
    super(container, "areaColumnComboTooltip");
    this.columnData = [3, 4.5];
    this.areaData = [
      [5, 4, 6, 5, 4]
    ];
    const { root, columnData, areaData, size, padding } = this;
    this.columns = createColumnRects({
      stacked: false,
      root,
      data: columnData,
      size,
      padding,
      xScaleDomain: [0, 1],
      yScaleDomain: [0, 6],
      xScalePadding: 0.5
    });
    const xScale = new _Scene23.BandScale();
    xScale.range = [padding, size - padding];
    xScale.domain = [0, 1, 2, 3, 4];
    xScale.paddingInner = 1;
    xScale.paddingOuter = 0;
    const yScale = new _Scene23.LinearScale();
    yScale.range = [size - padding, padding];
    yScale.domain = [0, 6];
    const pathData = [];
    const yZero = yScale.convert(0);
    const firstX = xScale.convert(0);
    areaData.forEach((series, i) => {
      const points = pathData[i] || (pathData[i] = []);
      series.forEach((data, j) => {
        const yDatum = data;
        const xDatum = j;
        const x = xScale.convert(xDatum);
        const y = yScale.convert(yDatum);
        points[j] = { x, y };
      });
      const lastX = xScale.convert(series.length - 1);
      pathData[i].push({
        x: lastX,
        y: yZero
      }, {
        x: firstX,
        y: yZero
      });
    });
    this.areas = pathData.map((points) => {
      const area = new _Scene23.Path();
      area.strokeWidth = 0;
      area.fillOpacity = 0.8;
      const path = area.path;
      points.forEach((point, i) => path[i > 0 ? "lineTo" : "moveTo"](point.x, point.y));
      return area;
    });
    root.append(this.areas);
    root.append([].concat.apply([], this.columns));
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.areas.forEach((area, i) => {
      area.fill = fills[i];
      area.stroke = strokes[i];
    });
    this.columns.forEach((bar, i) => {
      bar.fill = fills[i + 1];
      bar.stroke = strokes[i + 1];
    });
  }
};
MiniAreaColumnCombo.chartType = "areaColumnCombo";
var MiniCustomCombo = class extends MiniChart {
  constructor(container, fills, strokes) {
    super(container, "customComboTooltip");
    this.columnData = [3, 4];
    this.lineData = [[5, 4, 6, 5, 4]];
    const { root, columnData, lineData, size, padding } = this;
    this.columns = createColumnRects({
      stacked: false,
      root,
      data: columnData,
      size,
      padding,
      xScaleDomain: [0, 1],
      yScaleDomain: [0, 4],
      xScalePadding: 0.5
    });
    root.append(this.columns);
    this.lines = createLinePaths(root, lineData, size, padding);
    const axisStroke = "grey";
    const axisOvershoot = 3;
    const leftAxis = new _Scene24.Line();
    leftAxis.x1 = padding;
    leftAxis.y1 = padding;
    leftAxis.x2 = padding;
    leftAxis.y2 = size - padding + axisOvershoot;
    leftAxis.stroke = axisStroke;
    const bottomAxis = new _Scene24.Line();
    bottomAxis.x1 = padding - axisOvershoot + 1;
    bottomAxis.y1 = size - padding;
    bottomAxis.x2 = size - padding + 1;
    bottomAxis.y2 = size - padding;
    bottomAxis.stroke = axisStroke;
    const penIcon = new _Scene24.Path();
    this.buildPenIconPath(penIcon);
    penIcon.fill = "whitesmoke";
    penIcon.stroke = "darkslategrey";
    penIcon.strokeWidth = 1;
    root.append([bottomAxis, leftAxis, penIcon]);
    this.updateColors(fills, strokes);
  }
  updateColors(fills, strokes) {
    this.columns.forEach((bar, i) => {
      bar.fill = fills[i];
      bar.stroke = strokes[i];
    });
    this.lines.forEach((line, i) => {
      line.stroke = fills[i + 2];
    });
  }
  buildPenIconPath(penIcon) {
    const { path } = penIcon;
    path.moveTo(25.76, 43.46);
    path.lineTo(31.27, 48.53);
    path.moveTo(49.86, 22);
    path.lineTo(49.86, 22);
    path.cubicCurveTo(49.01994659053345, 21.317514933510974, 47.89593834348529, 21.09645997825817, 46.86, 21.41);
    path.lineTo(46.86, 21.41);
    path.cubicCurveTo(45.55460035985361, 21.77260167850787, 44.38777081121966, 22.517979360321792, 43.51, 23.55);
    path.lineTo(25.51, 43.8);
    path.lineTo(25.43, 43.89);
    path.lineTo(23.01, 51.89);
    path.lineTo(22.83, 52.46);
    path.lineTo(31.02, 48.86);
    path.lineTo(49.02, 28.52);
    path.lineTo(49.02, 28.52);
    path.cubicCurveTo(49.940716461596224, 27.521914221246085, 50.54302631059587, 26.2720342455763, 50.75, 24.93);
    path.lineTo(50.75, 24.93);
    path.cubicCurveTo(50.95363374988308, 23.866379846512814, 50.62080640232334, 22.77066734274871, 49.86, 22);
    path.closePath();
    path.moveTo(41.76, 25.5);
    path.lineTo(47.34, 30.5);
    path.moveTo(40.74, 26.65);
    path.lineTo(46.25, 31.71);
  }
};
MiniCustomCombo.chartType = "customCombo";
var miniChartMapping = {
  columnGroup: {
    column: { range: true, pivot: true, enterprise: false, icon: MiniColumn },
    stackedColumn: { range: true, pivot: true, enterprise: false, icon: MiniStackedColumn },
    normalizedColumn: { range: true, pivot: true, enterprise: false, icon: MiniNormalizedColumn }
  },
  barGroup: {
    bar: { range: true, pivot: true, enterprise: false, icon: MiniBar },
    stackedBar: { range: true, pivot: true, enterprise: false, icon: MiniStackedBar },
    normalizedBar: { range: true, pivot: true, enterprise: false, icon: MiniNormalizedBar }
  },
  pieGroup: {
    pie: { range: true, pivot: true, enterprise: false, icon: MiniPie },
    donut: { range: true, pivot: true, enterprise: false, icon: MiniDonut },
    doughnut: { range: true, pivot: true, enterprise: false, icon: MiniDonut }
  },
  lineGroup: { line: { range: true, pivot: true, enterprise: false, icon: MiniLine } },
  scatterGroup: {
    scatter: { range: true, pivot: true, enterprise: false, icon: MiniScatter },
    bubble: { range: true, pivot: true, enterprise: false, icon: MiniBubble }
  },
  areaGroup: {
    area: { range: true, pivot: true, enterprise: false, icon: MiniArea },
    stackedArea: { range: true, pivot: true, enterprise: false, icon: MiniStackedArea },
    normalizedArea: { range: true, pivot: true, enterprise: false, icon: MiniNormalizedArea }
  },
  polarGroup: {
    radarLine: { range: true, pivot: false, enterprise: true, icon: MiniRadarLine },
    radarArea: { range: true, pivot: false, enterprise: true, icon: MiniRadarArea },
    nightingale: { range: true, pivot: false, enterprise: true, icon: MiniNightingale },
    radialColumn: { range: true, pivot: false, enterprise: true, icon: MiniRadialColumn },
    radialBar: { range: true, pivot: false, enterprise: true, icon: MiniRadialBar }
  },
  statisticalGroup: {
    boxPlot: { range: true, pivot: false, enterprise: true, icon: MiniBoxPlot },
    histogram: { range: true, pivot: false, enterprise: false, icon: MiniHistogram },
    rangeBar: { range: true, pivot: false, enterprise: true, icon: MiniRangeBar },
    rangeArea: { range: true, pivot: false, enterprise: true, icon: MiniRangeArea }
  },
  hierarchicalGroup: {
    treemap: { range: true, pivot: true, enterprise: true, icon: MiniTreemap },
    sunburst: { range: true, pivot: true, enterprise: true, icon: MiniSunburst }
  },
  specializedGroup: {
    heatmap: { range: true, pivot: false, enterprise: true, icon: MiniHeatmap },
    waterfall: { range: true, pivot: false, enterprise: true, icon: MiniWaterfall }
  },
  combinationGroup: {
    columnLineCombo: { range: true, pivot: true, enterprise: false, icon: MiniColumnLineCombo },
    areaColumnCombo: { range: true, pivot: true, enterprise: false, icon: MiniAreaColumnCombo },
    customCombo: { range: true, pivot: true, enterprise: false, icon: MiniCustomCombo }
  }
};
var DEFAULT_CHART_GROUPS = {
  columnGroup: [
    "column",
    "stackedColumn",
    "normalizedColumn"
  ],
  barGroup: [
    "bar",
    "stackedBar",
    "normalizedBar"
  ],
  pieGroup: [
    "pie",
    "donut"
  ],
  lineGroup: [
    "line"
  ],
  scatterGroup: [
    "scatter",
    "bubble"
  ],
  areaGroup: [
    "area",
    "stackedArea",
    "normalizedArea"
  ],
  polarGroup: [
    "radarLine",
    "radarArea",
    "nightingale",
    "radialColumn",
    "radialBar"
  ],
  statisticalGroup: [
    "boxPlot",
    "histogram",
    "rangeBar",
    "rangeArea"
  ],
  hierarchicalGroup: [
    "treemap",
    "sunburst"
  ],
  specializedGroup: [
    "heatmap",
    "waterfall"
  ],
  combinationGroup: [
    "columnLineCombo",
    "areaColumnCombo",
    "customCombo"
  ]
};
var _MiniChartsContainer = class _MiniChartsContainer2 extends Component30 {
  constructor(chartController, fills, strokes, themeTemplateParameters, isCustomTheme, chartGroups = DEFAULT_CHART_GROUPS) {
    super(_MiniChartsContainer2.TEMPLATE);
    this.wrappers = {};
    this.chartController = chartController;
    this.fills = fills;
    this.strokes = strokes;
    this.themeTemplateParameters = themeTemplateParameters;
    this.isCustomTheme = isCustomTheme;
    this.chartGroups = __spreadValues3({}, chartGroups);
  }
  init() {
    if (!this.chartController.customComboExists() && this.chartGroups.combinationGroup) {
      this.chartGroups.combinationGroup = this.chartGroups.combinationGroup.filter((chartType) => chartType !== "customCombo");
    }
    const eGui = this.getGui();
    const isEnterprise = this.chartController.isEnterprise();
    const isPivotChart = this.chartController.isPivotChart();
    const isRangeChart = !isPivotChart;
    const displayedMenuGroups = Object.keys(this.chartGroups).map((group) => {
      var _a;
      const menuGroup = group in miniChartMapping ? miniChartMapping[group] : void 0;
      if (!menuGroup) {
        _26.warnOnce(`invalid chartGroupsDef config '${group}'`);
        return null;
      }
      const chartGroupValues = (_a = this.chartGroups[group]) != null ? _a : [];
      const menuItems = chartGroupValues.map((chartType) => {
        const menuItem = chartType in menuGroup ? menuGroup[chartType] : void 0;
        if (!menuItem) {
          _26.warnOnce(`invalid chartGroupsDef config '${group}.${chartType}'`);
          return null;
        }
        if (!isEnterprise && menuItem.enterprise) {
          return null;
        }
        if (isRangeChart && menuItem.range)
          return menuItem;
        if (isPivotChart && menuItem.pivot)
          return menuItem;
        return null;
      }).filter((menuItem) => menuItem != null);
      if (menuItems.length === 0)
        return null;
      return {
        label: this.chartTranslationService.translate(group),
        items: menuItems
      };
    }).filter((menuGroup) => menuGroup != null);
    for (const { label, items } of displayedMenuGroups) {
      const groupComponent = this.createBean(
        new AgGroupComponent10({
          title: label,
          suppressEnabledCheckbox: true,
          enabled: true,
          suppressOpenCloseIcons: true,
          cssIdentifier: "charts-settings",
          direction: "horizontal"
        })
      );
      for (const menuItem of items) {
        const MiniClass = menuItem.icon;
        const miniWrapper = document.createElement("div");
        miniWrapper.classList.add("ag-chart-mini-thumbnail");
        const miniClassChartType = MiniClass.chartType;
        this.addManagedListener(miniWrapper, "click", () => {
          this.chartController.setChartType(miniClassChartType);
          this.updateSelectedMiniChart();
        });
        this.wrappers[miniClassChartType] = miniWrapper;
        this.createBean(new MiniClass(miniWrapper, this.fills, this.strokes, this.themeTemplateParameters, this.isCustomTheme));
        groupComponent.addItem(miniWrapper);
      }
      eGui.appendChild(groupComponent.getGui());
    }
    this.updateSelectedMiniChart();
  }
  updateSelectedMiniChart() {
    const selectedChartType = this.chartController.getChartType();
    for (const miniChartType in this.wrappers) {
      const miniChart = this.wrappers[miniChartType];
      const selected = miniChartType === selectedChartType;
      miniChart.classList.toggle("ag-selected", selected);
    }
  }
};
_MiniChartsContainer.TEMPLATE = /* html */
`<div class="ag-chart-settings-mini-wrapper"></div>`;
__decorateClass4([
  Autowired342("chartTranslationService")
], _MiniChartsContainer.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct37
], _MiniChartsContainer.prototype, "init", 1);
var MiniChartsContainer = _MiniChartsContainer;
var _ChartSettingsPanel = class _ChartSettingsPanel2 extends Component31 {
  constructor(chartController) {
    super(_ChartSettingsPanel2.TEMPLATE);
    this.chartController = chartController;
    this.miniChartsContainers = [];
    this.cardItems = [];
    this.activePaletteIndex = 0;
    this.palettes = [];
    this.themes = [];
  }
  postConstruct() {
    this.resetPalettes();
    this.ePrevBtn.insertAdjacentElement("afterbegin", _27.createIconNoSpan("previous", this.gos));
    this.eNextBtn.insertAdjacentElement("afterbegin", _27.createIconNoSpan("next", this.gos));
    this.addManagedListener(this.ePrevBtn, "click", () => this.setActivePalette(this.getPrev(), "left"));
    this.addManagedListener(this.eNextBtn, "click", () => this.setActivePalette(this.getNext(), "right"));
    this.addManagedListener(this.chartController, ChartController.EVENT_CHART_TYPE_CHANGED, () => this.resetPalettes(true));
    this.addManagedListener(this.chartController, ChartController.EVENT_CHART_API_UPDATE, () => this.resetPalettes(true));
    this.scrollSelectedIntoView();
  }
  scrollSelectedIntoView() {
    setTimeout(() => {
      const isMiniChartsContainerVisible = (miniChartsContainers) => {
        return !miniChartsContainers.getGui().classList.contains("ag-hidden");
      };
      const currentMiniChartContainer = this.miniChartsContainers.find(isMiniChartsContainerVisible);
      const currentChart = currentMiniChartContainer.getGui().querySelector(".ag-selected");
      if (currentChart) {
        const parent = currentChart.offsetParent;
        if (parent) {
          this.eMiniChartsContainer.scrollTo(0, parent.offsetTop);
        }
      }
    }, 250);
  }
  resetPalettes(forceReset) {
    var _a, _b;
    const palettes = this.chartController.getPalettes();
    const themeTemplateParameters = this.chartController.getThemeTemplateParameters();
    const chartGroups = (_b = (_a = this.gos.get("chartToolPanelsDef")) == null ? void 0 : _a.settingsPanel) == null ? void 0 : _b.chartGroupsDef;
    if (_27.shallowCompare(palettes, this.palettes) && !forceReset || this.isAnimating) {
      return;
    }
    this.palettes = palettes;
    this.themes = this.chartController.getThemeNames();
    this.activePaletteIndex = this.themes.findIndex((name) => name === this.chartController.getChartThemeName());
    this.cardItems = [];
    _27.clearElement(this.eCardSelector);
    this.destroyMiniCharts();
    const { themes } = this;
    this.palettes.forEach((palette, index) => {
      const isActivePalette = this.activePaletteIndex === index;
      const { fills, strokes } = palette;
      const themeName = themes[index];
      const isCustomTheme = !isStockTheme(themeName);
      const miniChartsContainer = this.createBean(
        new MiniChartsContainer(
          this.chartController,
          fills,
          strokes,
          themeTemplateParameters[index],
          isCustomTheme,
          chartGroups
        )
      );
      this.miniChartsContainers.push(miniChartsContainer);
      this.eMiniChartsContainer.appendChild(miniChartsContainer.getGui());
      this.addCardLink(index);
      if (isActivePalette) {
        miniChartsContainer.updateSelectedMiniChart();
      } else {
        miniChartsContainer.setDisplayed(false);
      }
    });
    _27.setDisplayed(this.eNavBar, this.palettes.length > 1);
    _27.radioCssClass(this.cardItems[this.activePaletteIndex], "ag-selected", "ag-not-selected");
  }
  addCardLink(index) {
    const link = document.createElement("div");
    link.classList.add("ag-chart-settings-card-item");
    this.addManagedListener(link, "click", () => {
      this.setActivePalette(index, index < this.activePaletteIndex ? "left" : "right");
    });
    this.eCardSelector.appendChild(link);
    this.cardItems.push(link);
  }
  getPrev() {
    let prev = this.activePaletteIndex - 1;
    if (prev < 0) {
      prev = this.palettes.length - 1;
    }
    return prev;
  }
  getNext() {
    let next = this.activePaletteIndex + 1;
    if (next >= this.palettes.length) {
      next = 0;
    }
    return next;
  }
  setActivePalette(index, animationDirection) {
    if (this.isAnimating || this.activePaletteIndex === index) {
      return;
    }
    _27.radioCssClass(this.cardItems[index], "ag-selected", "ag-not-selected");
    const currentPalette = this.miniChartsContainers[this.activePaletteIndex];
    const currentGui = currentPalette.getGui();
    const futurePalette = this.miniChartsContainers[index];
    const nextGui = futurePalette.getGui();
    currentPalette.updateSelectedMiniChart();
    futurePalette.updateSelectedMiniChart();
    const multiplier = animationDirection === "left" ? -1 : 1;
    const final = nextGui.style.left = `${_27.getAbsoluteWidth(this.getGui()) * multiplier}px`;
    this.activePaletteIndex = index;
    this.isAnimating = true;
    const animatingClass = "ag-animating";
    futurePalette.setDisplayed(true);
    currentPalette.addCssClass(animatingClass);
    futurePalette.addCssClass(animatingClass);
    this.chartController.setChartThemeName(this.themes[index]);
    window.setTimeout(() => {
      currentGui.style.left = `${-parseFloat(final)}px`;
      nextGui.style.left = "0px";
    }, 0);
    window.setTimeout(() => {
      this.isAnimating = false;
      currentPalette.removeCssClass(animatingClass);
      futurePalette.removeCssClass(animatingClass);
      currentPalette.setDisplayed(false);
    }, 300);
  }
  destroyMiniCharts() {
    _27.clearElement(this.eMiniChartsContainer);
    this.miniChartsContainers = this.destroyBeans(this.miniChartsContainers);
  }
  destroy() {
    this.destroyMiniCharts();
    super.destroy();
  }
};
_ChartSettingsPanel.TEMPLATE = /* html */
`<div class="ag-chart-settings-wrapper">
            <div ref="eMiniChartsContainer" class="ag-chart-settings-mini-charts-container ag-scrollable-container"></div>
            <div ref="eNavBar" class="ag-chart-settings-nav-bar">
                <div ref="ePrevBtn" class="ag-chart-settings-prev">
                    <button type="button" class="ag-button ag-chart-settings-prev-button"></button>
                </div>
                <div ref="eCardSelector" class="ag-chart-settings-card-selector"></div>
                <div ref="eNextBtn" class="ag-chart-settings-next">
                    <button type="button" class="ag-button ag-chart-settings-next-button"></button>
                </div>
            </div>
        </div>`;
__decorateClass4([
  RefSelector10("eMiniChartsContainer")
], _ChartSettingsPanel.prototype, "eMiniChartsContainer", 2);
__decorateClass4([
  RefSelector10("eNavBar")
], _ChartSettingsPanel.prototype, "eNavBar", 2);
__decorateClass4([
  RefSelector10("eCardSelector")
], _ChartSettingsPanel.prototype, "eCardSelector", 2);
__decorateClass4([
  RefSelector10("ePrevBtn")
], _ChartSettingsPanel.prototype, "ePrevBtn", 2);
__decorateClass4([
  RefSelector10("eNextBtn")
], _ChartSettingsPanel.prototype, "eNextBtn", 2);
__decorateClass4([
  PostConstruct38
], _ChartSettingsPanel.prototype, "postConstruct", 1);
var ChartSettingsPanel = _ChartSettingsPanel;
var _TabbedChartMenu = class _TabbedChartMenu2 extends Component32 {
  constructor(panels, chartMenuContext) {
    super();
    this.panels = panels;
    this.chartMenuContext = chartMenuContext;
    this.tabs = [];
  }
  init() {
    this.panels.forEach((panel) => {
      const panelType = panel.replace("chart", "").toLowerCase();
      const panelComp = this.createPanel(panelType);
      const tabItem = this.createTab(panel, panelType, panelComp);
      this.tabs.push(tabItem);
      this.addDestroyFunc(() => this.destroyBean(panelComp));
    });
    this.tabbedLayout = new TabbedLayout({
      items: this.tabs,
      cssClass: "ag-chart-tabbed-menu",
      keepScrollPosition: true,
      suppressFocusBodyOnOpen: true,
      suppressTrapFocus: true,
      enableCloseButton: !this.chartMenuService.isLegacyFormat(),
      closeButtonAriaLabel: this.chartTranslationService.translate("ariaChartMenuClose"),
      onCloseClicked: () => {
        var _a;
        (_a = this.eventSource) == null ? void 0 : _a.focus({ preventScroll: true });
        this.dispatchEvent({ type: _TabbedChartMenu2.EVENT_CLOSED });
      }
    });
    this.getContext().createBean(this.tabbedLayout);
  }
  createTab(name, title, panelComp) {
    const eWrapperDiv = document.createElement("div");
    eWrapperDiv.classList.add("ag-chart-tab", `ag-chart-${title}`);
    this.getContext().createBean(panelComp);
    eWrapperDiv.appendChild(panelComp.getGui());
    const titleEl = document.createElement("div");
    const translatedTitle = this.chartTranslationService.translate(title);
    titleEl.innerText = translatedTitle;
    return {
      title: titleEl,
      titleLabel: translatedTitle,
      bodyPromise: AgPromise.resolve(eWrapperDiv),
      getScrollableContainer: () => {
        const scrollableContainer = eWrapperDiv.querySelector(".ag-scrollable-container");
        return scrollableContainer || eWrapperDiv;
      },
      name
    };
  }
  showTab(tab) {
    const tabItem = this.tabs[tab];
    this.tabbedLayout.showItem(tabItem);
  }
  getGui() {
    return this.tabbedLayout && this.tabbedLayout.getGui();
  }
  showMenu(eventSource, suppressFocus) {
    var _a;
    this.eventSource = eventSource;
    if (!suppressFocus) {
      (_a = this.tabbedLayout) == null ? void 0 : _a.focusHeader(true);
    }
  }
  destroy() {
    if (this.parentComponent && this.parentComponent.isAlive()) {
      this.destroyBean(this.parentComponent);
    }
    super.destroy();
  }
  createPanel(panelType) {
    const { chartController, chartOptionsService } = this.chartMenuContext;
    switch (panelType) {
      case _TabbedChartMenu2.TAB_DATA:
        return new ChartDataPanel(chartController, chartOptionsService);
      case _TabbedChartMenu2.TAB_FORMAT:
        return new FormatPanel(this.chartMenuContext);
      default:
        return new ChartSettingsPanel(chartController);
    }
  }
};
_TabbedChartMenu.EVENT_CLOSED = "closed";
_TabbedChartMenu.TAB_DATA = "data";
_TabbedChartMenu.TAB_FORMAT = "format";
__decorateClass4([
  Autowired35("chartTranslationService")
], _TabbedChartMenu.prototype, "chartTranslationService", 2);
__decorateClass4([
  Autowired35("chartMenuService")
], _TabbedChartMenu.prototype, "chartMenuService", 2);
__decorateClass4([
  PostConstruct39
], _TabbedChartMenu.prototype, "init", 1);
var TabbedChartMenu = _TabbedChartMenu;
var ChartToolbar = class extends Component332 {
  constructor() {
    super(
      /* html */
      `<div class="ag-chart-menu" ref="eMenu"></div>`
    );
    this.buttonListenersDestroyFuncs = [];
  }
  updateParams(params) {
    const { buttons } = params;
    this.createButtons(buttons);
  }
  createButtons(buttons) {
    this.buttonListenersDestroyFuncs.forEach((func) => func == null ? void 0 : func());
    this.buttonListenersDestroyFuncs = [];
    const menuEl = this.eMenu;
    _28.clearElement(menuEl);
    buttons.forEach((buttonConfig) => {
      const { buttonName, iconName, callback } = buttonConfig;
      const buttonEl = this.createButton(iconName);
      const tooltipTitle = this.chartTranslationService.translate(buttonName + "ToolbarTooltip");
      if (tooltipTitle && buttonEl instanceof HTMLElement) {
        buttonEl.title = tooltipTitle;
      }
      this.buttonListenersDestroyFuncs.push(
        this.addManagedListener(buttonEl, "click", (event) => callback(event.target))
      );
      menuEl.appendChild(buttonEl);
    });
  }
  createButton(iconName) {
    let buttonEl = _28.createIconNoSpan(
      iconName,
      this.gos,
      void 0,
      true
    );
    buttonEl.classList.add("ag-chart-menu-icon");
    if (!this.chartMenuService.isLegacyFormat()) {
      buttonEl = this.wrapButton(buttonEl);
    }
    return buttonEl;
  }
  wrapButton(buttonEl) {
    const wrapperEl = this.gos.getDocument().createElement("button");
    wrapperEl.appendChild(buttonEl);
    wrapperEl.classList.add("ag-chart-menu-toolbar-button");
    return wrapperEl;
  }
  destroy() {
    this.buttonListenersDestroyFuncs = [];
    super.destroy();
  }
};
__decorateClass4([
  Autowired36("chartTranslationService")
], ChartToolbar.prototype, "chartTranslationService", 2);
__decorateClass4([
  Autowired36("chartMenuService")
], ChartToolbar.prototype, "chartMenuService", 2);
__decorateClass4([
  RefSelector11("eMenu")
], ChartToolbar.prototype, "eMenu", 2);
var _ChartMenu = class _ChartMenu2 extends Component34 {
  constructor(eChartContainer, eMenuPanelContainer, chartMenuContext) {
    super(_ChartMenu2.TEMPLATE);
    this.eChartContainer = eChartContainer;
    this.eMenuPanelContainer = eMenuPanelContainer;
    this.chartMenuContext = chartMenuContext;
    this.buttons = {
      chartSettings: { iconName: "menu", callback: () => this.showMenu({ panel: this.defaultPanel }) },
      chartData: { iconName: "menu", callback: () => this.showMenu({ panel: "chartData" }) },
      chartFormat: { iconName: "menu", callback: () => this.showMenu({ panel: "chartFormat" }) },
      chartLink: { iconName: "linked", callback: () => this.chartMenuService.toggleLinked(this.chartMenuContext) },
      chartUnlink: { iconName: "unlinked", callback: () => this.chartMenuService.toggleLinked(this.chartMenuContext) },
      chartDownload: { iconName: "save", callback: () => this.chartMenuService.downloadChart(this.chartMenuContext) },
      chartMenu: { iconName: "menuAlt", callback: (eventSource) => this.showMenuList(eventSource) }
    };
    this.panels = [];
    this.menuVisible = false;
    this.chartController = chartMenuContext.chartController;
  }
  postConstruct() {
    this.legacyFormat = this.chartMenuService.isLegacyFormat();
    this.chartToolbar = this.createManagedBean(new ChartToolbar());
    this.getGui().appendChild(this.chartToolbar.getGui());
    if (this.legacyFormat) {
      this.createLegacyToggleButton();
    }
    this.refreshToolbarAndPanels();
    this.addManagedListener(this.eventService, Events8.EVENT_CHART_CREATED, (e) => {
      var _a;
      if (e.chartId === this.chartController.getChartId()) {
        const showDefaultToolPanel = Boolean((_a = this.gos.get("chartToolPanelsDef")) == null ? void 0 : _a.defaultToolPanel);
        if (showDefaultToolPanel) {
          this.showMenu({ panel: this.defaultPanel, animate: false, suppressFocus: true });
        }
      }
    });
    this.addManagedListener(this.chartController, ChartController.EVENT_CHART_LINKED_CHANGED, this.refreshToolbarAndPanels.bind(this));
    this.refreshMenuClasses();
    if (this.legacyFormat && !this.gos.get("suppressChartToolPanelsButton") && this.panels.length > 0) {
      this.getGui().classList.add("ag-chart-tool-panel-button-enable");
      if (this.eHideButton) {
        this.addManagedListener(this.eHideButton, "click", this.toggleMenu.bind(this));
      }
    }
    if (!this.legacyFormat) {
      this.getGui().classList.add("ag-chart-menu-wrapper");
    }
    this.addManagedListener(this.chartController, ChartController.EVENT_CHART_API_UPDATE, this.refreshToolbarAndPanels.bind(this));
  }
  isVisible() {
    return this.menuVisible;
  }
  getExtraPaddingDirections() {
    const topItems = ["chartMenu", "chartLink", "chartUnlink", "chartDownload"];
    const rightItems = ["chartSettings", "chartData", "chartFormat"];
    const result = [];
    if (topItems.some((v) => this.chartToolbarOptions.includes(v))) {
      result.push("top");
    }
    if (rightItems.some((v) => this.chartToolbarOptions.includes(v))) {
      result.push(this.gos.get("enableRtl") ? "left" : "right");
    }
    return result;
  }
  createLegacyToggleButton() {
    const eDocument = this.gos.getDocument();
    this.eHideButton = eDocument.createElement("button");
    this.eHideButton.classList.add("ag-button", "ag-chart-menu-close");
    this.eHideButtonIcon = eDocument.createElement("span");
    this.eHideButtonIcon.classList.add("ag-icon", "ag-icon-contracted");
    this.eHideButton.appendChild(this.eHideButtonIcon);
    this.getGui().appendChild(this.eHideButton);
  }
  refreshToolbarAndPanels() {
    this.initToolbarOptionsAndPanels();
    this.updateToolbar();
  }
  initToolbarOptionsAndPanels() {
    const {
      panels,
      defaultPanel,
      chartToolbarOptions
    } = this.chartMenuService.getToolbarOptionsAndPanels(this.chartController);
    this.panels = panels;
    this.defaultPanel = defaultPanel;
    this.chartToolbarOptions = chartToolbarOptions;
  }
  updateToolbar() {
    const buttons = this.chartToolbarOptions.map((buttonName) => {
      const { iconName, callback } = this.buttons[buttonName];
      return {
        buttonName,
        iconName,
        callback
      };
    });
    this.chartToolbar.updateParams({ buttons });
  }
  createMenuPanel(defaultTab) {
    const width = this.environment.chartMenuPanelWidth();
    const menuPanel = this.menuPanel = this.createBean(new AgPanel({
      minWidth: width,
      width,
      height: "100%",
      closable: true,
      hideTitleBar: true,
      cssIdentifier: "chart-menu"
    }));
    menuPanel.setParentComponent(this);
    this.eMenuPanelContainer.appendChild(menuPanel.getGui());
    this.tabbedMenu = this.createBean(new TabbedChartMenu(
      this.panels,
      this.chartMenuContext
    ));
    this.addManagedListener(this.tabbedMenu, TabbedChartMenu.EVENT_CLOSED, () => {
      this.hideMenu(false);
    });
    this.addManagedListener(
      menuPanel,
      Component34.EVENT_DESTROYED,
      () => this.destroyBean(this.tabbedMenu)
    );
    return new AgPromise2((res) => {
      window.setTimeout(() => {
        menuPanel.setBodyComponent(this.tabbedMenu);
        this.tabbedMenu.showTab(defaultTab);
        res(menuPanel);
        if (this.legacyFormat) {
          this.addManagedListener(
            this.eChartContainer,
            "click",
            (event) => {
              if (this.getGui().contains(event.target)) {
                return;
              }
              if (this.menuVisible) {
                this.hideMenu();
              }
            }
          );
        }
      }, 100);
    });
  }
  showContainer(eventSource, suppressFocus) {
    if (!this.menuPanel) {
      return;
    }
    this.menuVisible = true;
    this.showParent(this.menuPanel.getWidth());
    this.refreshMenuClasses();
    this.tabbedMenu.showMenu(eventSource, suppressFocus);
  }
  toggleMenu() {
    this.menuVisible ? this.hideMenu(this.legacyFormat) : this.showMenu({ animate: this.legacyFormat });
  }
  showMenu(params) {
    const { panel, animate = true, eventSource, suppressFocus } = params;
    if (!animate) {
      this.eMenuPanelContainer.classList.add("ag-no-transition");
    }
    if (this.menuPanel && !panel) {
      this.showContainer(eventSource, suppressFocus);
    } else {
      const menuPanel = panel || this.defaultPanel;
      let tab = this.panels.indexOf(menuPanel);
      if (tab < 0) {
        console.warn(`AG Grid: '${panel}' is not a valid Chart Tool Panel name`);
        tab = this.panels.indexOf(this.defaultPanel);
      }
      if (this.menuPanel) {
        this.tabbedMenu.showTab(tab);
        this.showContainer(eventSource, suppressFocus);
      } else {
        this.createMenuPanel(tab).then(() => this.showContainer(eventSource, suppressFocus));
      }
    }
    if (!animate) {
      setTimeout(() => {
        if (!this.isAlive()) {
          return;
        }
        this.eMenuPanelContainer.classList.remove("ag-no-transition");
      }, 500);
    }
  }
  hideMenu(animate = true) {
    if (!animate) {
      this.eMenuPanelContainer.classList.add("ag-no-transition");
    }
    this.hideParent();
    window.setTimeout(() => {
      this.menuVisible = false;
      this.refreshMenuClasses();
      if (!animate) {
        this.eMenuPanelContainer.classList.remove("ag-no-transition");
      }
    }, 500);
  }
  refreshMenuClasses() {
    this.eChartContainer.classList.toggle("ag-chart-menu-visible", this.menuVisible);
    this.eChartContainer.classList.toggle("ag-chart-menu-hidden", !this.menuVisible);
    if (this.legacyFormat && !this.gos.get("suppressChartToolPanelsButton")) {
      this.eHideButtonIcon.classList.toggle("ag-icon-contracted", this.menuVisible);
      this.eHideButtonIcon.classList.toggle("ag-icon-expanded", !this.menuVisible);
    }
  }
  showParent(width) {
    this.eMenuPanelContainer.style.minWidth = `${width}px`;
  }
  hideParent() {
    this.eMenuPanelContainer.style.minWidth = "0";
  }
  showMenuList(eventSource) {
    this.chartMenuListFactory.showMenuList({
      eventSource,
      showMenu: () => this.showMenu({ animate: false, eventSource }),
      chartMenuContext: this.chartMenuContext
    });
  }
  destroy() {
    super.destroy();
    if (this.menuPanel && this.menuPanel.isAlive()) {
      this.destroyBean(this.menuPanel);
    }
    if (this.tabbedMenu && this.tabbedMenu.isAlive()) {
      this.destroyBean(this.tabbedMenu);
    }
  }
};
_ChartMenu.TEMPLATE = /* html */
`<div></div>`;
__decorateClass4([
  Autowired37("chartMenuService")
], _ChartMenu.prototype, "chartMenuService", 2);
__decorateClass4([
  Autowired37("chartMenuListFactory")
], _ChartMenu.prototype, "chartMenuListFactory", 2);
__decorateClass4([
  PostConstruct40
], _ChartMenu.prototype, "postConstruct", 1);
var ChartMenu = _ChartMenu;
var _TitleEdit = class _TitleEdit2 extends Component35 {
  constructor(chartMenu) {
    super(_TitleEdit2.TEMPLATE);
    this.chartMenu = chartMenu;
    this.destroyableChartListeners = [];
    this.editing = false;
  }
  init() {
    this.addManagedListener(this.getGui(), "keydown", (e) => {
      if (this.editing && e.key === "Enter" && !e.shiftKey) {
        this.handleEndEditing();
        e.preventDefault();
      }
    });
    this.addManagedListener(this.getGui(), "input", () => {
      if (this.editing) {
        this.updateHeight();
      }
    });
    this.addManagedListener(this.getGui(), "blur", () => this.endEditing());
  }
  /* should be called when the containing component changes to a new chart proxy */
  refreshTitle(chartMenuContext) {
    this.chartController = chartMenuContext.chartController;
    this.chartOptionsService = chartMenuContext.chartOptionsService;
    this.chartMenuUtils = chartMenuContext.chartMenuParamsFactory.getChartOptions();
    for (const destroyFn of this.destroyableChartListeners) {
      destroyFn();
    }
    this.destroyableChartListeners = [];
    const chartProxy = this.chartController.getChartProxy();
    const chart = chartProxy.getChart();
    const canvas = chart.canvasElement;
    const destroyDbleClickListener = this.addManagedListener(canvas, "dblclick", (event) => {
      const { title } = chart;
      if (title && title.node.containsPoint(event.offsetX, event.offsetY)) {
        const bbox = title.node.computeBBox();
        const xy = title.node.inverseTransformPoint(bbox.x, bbox.y);
        this.startEditing(__spreadValues3(__spreadValues3({}, bbox), xy), canvas.width);
      }
    });
    let wasInTitle = false;
    const destroyMouseMoveListener = this.addManagedListener(canvas, "mousemove", (event) => {
      const { title } = chart;
      const inTitle = !!(title && title.enabled && title.node.containsPoint(event.offsetX, event.offsetY));
      if (wasInTitle !== inTitle) {
        canvas.style.cursor = inTitle ? "pointer" : "";
      }
      wasInTitle = inTitle;
    });
    this.destroyableChartListeners = [
      destroyDbleClickListener,
      destroyMouseMoveListener
    ];
  }
  startEditing(titleBBox, canvasWidth) {
    if (this.chartMenuService.isLegacyFormat() && this.chartMenu && this.chartMenu.isVisible()) {
      return;
    }
    if (this.editing) {
      return;
    }
    this.editing = true;
    const minimumTargetInputWidth = 300;
    const inputWidth = Math.max(Math.min(titleBBox.width + 20, canvasWidth), minimumTargetInputWidth);
    const element = this.getGui();
    element.classList.add("currently-editing");
    const inputStyle = element.style;
    inputStyle.fontFamily = this.chartMenuUtils.getValue("title.fontFamily");
    inputStyle.fontWeight = this.chartMenuUtils.getValue("title.fontWeight");
    inputStyle.fontStyle = this.chartMenuUtils.getValue("title.fontStyle");
    inputStyle.fontSize = this.chartMenuUtils.getValue("title.fontSize") + "px";
    inputStyle.color = this.chartMenuUtils.getValue("title.color");
    const oldTitle = this.chartMenuUtils.getValue("title.text");
    const isTitlePlaceholder = oldTitle === this.chartTranslationService.translate("titlePlaceholder");
    element.value = isTitlePlaceholder ? "" : oldTitle;
    const oldTitleLines = oldTitle.split(/\r?\n/g).length;
    inputStyle.left = Math.round(titleBBox.x + titleBBox.width / 2 - inputWidth / 2 - 1) + "px";
    inputStyle.top = Math.round(titleBBox.y + titleBBox.height / 2 - oldTitleLines * this.getLineHeight() / 2 - 2) + "px";
    inputStyle.width = Math.round(inputWidth) + "px";
    inputStyle.lineHeight = this.getLineHeight() + "px";
    this.updateHeight();
    element.focus();
  }
  updateHeight() {
    const element = this.getGui();
    const oldTitleLines = this.chartMenuUtils.getValue("title.text").split(/\r?\n/g).length;
    const currentTitleLines = element.value.split(/\r?\n/g).length;
    element.style.height = Math.round(Math.max(oldTitleLines, currentTitleLines) * this.getLineHeight()) + 4 + "px";
  }
  getLineHeight() {
    const fixedLineHeight = this.chartMenuUtils.getValue("title.lineHeight");
    if (fixedLineHeight) {
      return parseInt(fixedLineHeight);
    }
    return Math.round(parseInt(this.chartMenuUtils.getValue("title.fontSize")) * 1.2);
  }
  handleEndEditing() {
    const titleColor = this.chartMenuUtils.getValue("title.color");
    const transparentColor = "rgba(0, 0, 0, 0)";
    this.chartMenuUtils.setValue("title.color", transparentColor);
    this.chartOptionsService.awaitChartOptionUpdate(() => this.endEditing());
    this.chartOptionsService.awaitChartOptionUpdate(() => {
      this.chartMenuUtils.setValue("title.color", titleColor);
    });
  }
  endEditing() {
    if (!this.editing) {
      return;
    }
    this.editing = false;
    const value = this.getGui().value;
    if (value && value.trim() !== "") {
      this.chartMenuUtils.setValue("title.text", value);
      this.chartMenuUtils.setValue("title.enabled", true);
    } else {
      this.chartMenuUtils.setValue("title.text", "");
      this.chartMenuUtils.setValue("title.enabled", false);
    }
    this.getGui().classList.remove("currently-editing");
    this.chartOptionsService.awaitChartOptionUpdate(() => {
      this.eventService.dispatchEvent({ type: "chartTitleEdit" });
    });
  }
};
_TitleEdit.TEMPLATE = /* html */
`<textarea
             class="ag-chart-title-edit"
             style="padding:0; border:none; border-radius: 0; min-height: 0; text-align: center; resize: none;" />
        `;
__decorateClass4([
  Autowired38("chartTranslationService")
], _TitleEdit.prototype, "chartTranslationService", 2);
__decorateClass4([
  Autowired38("chartMenuService")
], _TitleEdit.prototype, "chartMenuService", 2);
__decorateClass4([
  PostConstruct41
], _TitleEdit.prototype, "init", 1);
var TitleEdit = _TitleEdit;
function deproxy(chartOrProxy) {
  if (chartOrProxy.chart != null) {
    return chartOrProxy.chart;
  }
  return chartOrProxy;
}
var ChartProxy = class {
  constructor(chartProxyParams) {
    this.chartProxyParams = chartProxyParams;
    this.clearThemeOverrides = false;
    this.isEnterpriseCharts = _ModuleSupport4.enterpriseModule.isEnterprise;
    this.chart = chartProxyParams.chartInstance;
    this.chartType = chartProxyParams.chartType;
    this.crossFiltering = chartProxyParams.crossFiltering;
    this.crossFilterCallback = chartProxyParams.crossFilterCallback;
    this.standaloneChartType = getSeriesType(this.chartType);
    if (this.chart == null) {
      this.chart = AgCharts.create(this.getCommonChartOptions());
    } else {
      this.clearThemeOverrides = true;
    }
  }
  crossFilteringReset() {
  }
  update(params) {
    AgCharts.update(this.getChartRef(), this.getUpdateOptions(params, this.getCommonChartOptions(params.updatedOverrides)));
  }
  updateThemeOverrides(themeOverrides) {
    AgCharts.updateDelta(this.getChartRef(), { theme: { overrides: themeOverrides } });
  }
  getChart() {
    return deproxy(this.chart);
  }
  getChartRef() {
    return this.chart;
  }
  downloadChart(dimensions, fileName, fileFormat) {
    const { chart } = this;
    const rawChart = deproxy(chart);
    const imageFileName = fileName || (rawChart.title ? rawChart.title.text : "chart");
    const { width, height } = dimensions || {};
    AgCharts.download(chart, { width, height, fileName: imageFileName, fileFormat });
  }
  getChartImageDataURL(type) {
    return this.getChart().getCanvasDataURL(type);
  }
  getChartOptions() {
    return this.chart.getOptions();
  }
  getChartThemeOverrides() {
    var _a;
    const chartOptionsTheme = this.getChartOptions().theme;
    return (_a = chartOptionsTheme.overrides) != null ? _a : {};
  }
  getChartPalette() {
    return _Theme8.getChartTheme(this.getChartOptions().theme).palette;
  }
  setPaired(paired) {
    const seriesType = getSeriesType(this.chartProxyParams.chartType);
    AgCharts.updateDelta(this.chart, { theme: { overrides: { [seriesType]: { paired } } } });
  }
  isPaired() {
    const seriesType = getSeriesType(this.chartProxyParams.chartType);
    return get(this.getChartThemeOverrides(), `${seriesType}.paired`, true);
  }
  lookupCustomChartTheme(themeName) {
    return lookupCustomChartTheme(this.chartProxyParams, themeName);
  }
  getSeriesGroupType() {
    return void 0;
  }
  transformCategoryData(data, categoryKey) {
    return data.map((d, index) => {
      const value = d[categoryKey];
      const valueString = value && value.toString ? value.toString() : "";
      const datum = __spreadValues3({}, d);
      datum[categoryKey] = { id: index, value, toString: () => valueString };
      return datum;
    });
  }
  getCommonChartOptions(updatedOverrides) {
    var _a, _b;
    const existingOptions = this.clearThemeOverrides ? {} : (_b = (_a = this.chart) == null ? void 0 : _a.getOptions()) != null ? _b : {};
    const formattingPanelOverrides = this.chart != null ? this.getActiveFormattingPanelOverrides() : void 0;
    this.clearThemeOverrides = false;
    const theme = createAgChartTheme(
      this.chartProxyParams,
      this,
      this.isEnterpriseCharts,
      this.getChartThemeDefaults(),
      updatedOverrides != null ? updatedOverrides : formattingPanelOverrides
    );
    const newOptions = __spreadProps3(__spreadValues3({}, existingOptions), {
      mode: "integrated"
    });
    newOptions.theme = theme;
    newOptions.container = this.chartProxyParams.parentElement;
    return newOptions;
  }
  getChartThemeDefaults() {
    const seriesOverrides = this.getSeriesChartThemeDefaults();
    const seriesChartOptions = seriesOverrides ? {
      [this.standaloneChartType]: seriesOverrides
    } : {};
    const crosshair = {
      enabled: true,
      snap: true,
      label: {
        enabled: false
      }
    };
    return __spreadValues3({
      common: {
        navigator: {
          enabled: false
        },
        zoom: {
          enabled: true
        },
        animation: {
          enabled: true,
          duration: 500
        },
        axes: {
          number: { crosshair },
          category: { crosshair },
          log: { crosshair },
          time: { crosshair }
        }
      }
    }, seriesChartOptions);
  }
  getSeriesChartThemeDefaults() {
    return void 0;
  }
  getActiveFormattingPanelOverrides() {
    var _a, _b;
    if (this.clearThemeOverrides) {
      return {};
    }
    const inUseTheme = (_a = this.chart) == null ? void 0 : _a.getOptions().theme;
    return (_b = inUseTheme == null ? void 0 : inUseTheme.overrides) != null ? _b : {};
  }
  destroy({ keepChartInstance = false } = {}) {
    if (keepChartInstance) {
      this.chart.resetAnimations();
      return this.chart;
    }
    this.destroyChart();
  }
  destroyChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = void 0;
    }
  }
};
var CartesianChartProxy = class extends ChartProxy {
  constructor(params) {
    super(params);
    this.crossFilteringAllPoints = /* @__PURE__ */ new Set();
    this.crossFilteringSelectedPoints = [];
  }
  getUpdateOptions(params, commonChartOptions) {
    const axes = this.getAxes(params, commonChartOptions);
    return __spreadProps3(__spreadValues3({}, commonChartOptions), {
      data: this.getData(params, axes),
      axes,
      series: this.getSeries(params)
    });
  }
  getData(params, axes) {
    const supportsCrossFiltering = ["area", "line"].includes(this.standaloneChartType);
    return this.crossFiltering && supportsCrossFiltering ? this.getCrossFilterData(params) : this.getDataTransformedData(params, axes);
  }
  getDataTransformedData(params, axes) {
    const xAxisType = axes[0].type;
    const { categories, data } = params;
    const [category] = categories;
    switch (xAxisType) {
      case "category":
        return this.transformCategoryData(data, category.id);
      case "time":
        return this.transformTimeData(data, category.id);
      default:
        return data;
    }
  }
  getXAxisType(params) {
    if (params.grouping) {
      return "grouped-category";
    } else if (this.isXAxisOfType(params, "time", (value) => value instanceof Date)) {
      return "time";
    } else if (this.isXAxisOfType(params, "number")) {
      return "number";
    }
    return "category";
  }
  isXAxisOfType(params, type, isInstance) {
    const [category] = params.categories;
    if (category == null ? void 0 : category.chartDataType) {
      return category.chartDataType === type;
    }
    if (!isInstance) {
      return false;
    }
    const testDatum = params.data[0];
    if (!testDatum) {
      return false;
    }
    return isInstance(testDatum[category.id]);
  }
  transformTimeData(data, categoryKey) {
    var _a;
    const firstValue = (_a = data[0]) == null ? void 0 : _a[categoryKey];
    if (firstValue instanceof Date) {
      return data;
    }
    return data.map((datum) => {
      const value = datum[categoryKey];
      return typeof value === "string" ? __spreadProps3(__spreadValues3({}, datum), {
        [categoryKey]: new Date(value)
      }) : datum;
    });
  }
  crossFilteringReset() {
    this.crossFilteringSelectedPoints = [];
    this.crossFilteringAllPoints.clear();
  }
  crossFilteringPointSelected(point) {
    return this.crossFilteringSelectedPoints.length == 0 || this.crossFilteringSelectedPoints.includes(point);
  }
  crossFilteringDeselectedPoints() {
    return this.crossFilteringSelectedPoints.length > 0 && this.crossFilteringAllPoints.size !== this.crossFilteringSelectedPoints.length;
  }
  extractLineAreaCrossFilterSeries(series, params) {
    const [category] = params.categories;
    const getYKey = (yKey) => {
      if (this.standaloneChartType === "area") {
        const lastSelectedChartId = params.getCrossFilteringContext().lastSelectedChartId;
        return lastSelectedChartId === params.chartId ? yKey + "-total" : yKey;
      }
      return yKey + "-total";
    };
    return series.map((s) => {
      s.yKey = getYKey(s.yKey);
      s.listeners = {
        nodeClick: (e) => {
          const value = e.datum[s.xKey];
          const multiSelection = e.event.metaKey || e.event.ctrlKey;
          this.crossFilteringAddSelectedPoint(multiSelection, value);
          this.crossFilterCallback(e);
        }
      };
      s.marker = {
        formatter: (p) => {
          const value = p.datum[category.id];
          return {
            fill: p.highlighted ? "yellow" : p.fill,
            size: p.highlighted ? 14 : this.crossFilteringPointSelected(value) ? 8 : 0
          };
        }
      };
      if (this.standaloneChartType === "area") {
        s.fillOpacity = this.crossFilteringDeselectedPoints() ? 0.3 : 1;
      }
      if (this.standaloneChartType === "line") {
        s.strokeOpacity = this.crossFilteringDeselectedPoints() ? 0.3 : 1;
      }
      return s;
    });
  }
  getCrossFilterData(params) {
    this.crossFilteringAllPoints.clear();
    const [category] = params.categories;
    const colId = params.fields[0].colId;
    const filteredOutColId = `${colId}-filtered-out`;
    const lastSelectedChartId = params.getCrossFilteringContext().lastSelectedChartId;
    return params.data.map((d) => {
      const value = d[category.id];
      this.crossFilteringAllPoints.add(value);
      const pointSelected = this.crossFilteringPointSelected(value);
      if (this.standaloneChartType === "area" && lastSelectedChartId === params.chartId) {
        d[`${colId}-total`] = pointSelected ? d[colId] : d[colId] + d[filteredOutColId];
      }
      if (this.standaloneChartType === "line") {
        d[`${colId}-total`] = pointSelected ? d[colId] : d[colId] + d[filteredOutColId];
      }
      return d;
    });
  }
  crossFilteringAddSelectedPoint(multiSelection, value) {
    multiSelection ? this.crossFilteringSelectedPoints.push(value) : this.crossFilteringSelectedPoints = [value];
  }
  isHorizontal(commonChartOptions) {
    const seriesType = this.standaloneChartType;
    if (seriesType !== "waterfall" && seriesType !== "box-plot" && seriesType !== "range-bar") {
      return false;
    }
    const theme = commonChartOptions.theme;
    const isHorizontal = (theme2) => {
      var _a, _b, _c;
      const direction = (_c = (_b = (_a = theme2 == null ? void 0 : theme2.overrides) == null ? void 0 : _a[seriesType]) == null ? void 0 : _b.series) == null ? void 0 : _c.direction;
      if (direction != null) {
        return direction === "horizontal";
      }
      if (typeof (theme2 == null ? void 0 : theme2.baseTheme) === "object") {
        return isHorizontal(theme2.baseTheme);
      }
      return false;
    };
    return isHorizontal(theme);
  }
};
function hexToRGBA(hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgba(${r}, ${g}, ${b})`;
}
function changeOpacity(fills, alpha) {
  return fills.map((fill) => {
    const c = _Util6.Color.fromString(fill);
    return new _Util6.Color(c.r, c.g, c.b, alpha).toHexString();
  });
}
var HORIZONTAL_CHART_TYPES = /* @__PURE__ */ new Set(["bar", "groupedBar", "stackedBar", "normalizedBar"]);
var BarChartProxy = class extends CartesianChartProxy {
  constructor(params) {
    super(params);
  }
  getAxes(params) {
    const axes = [
      {
        type: this.getXAxisType(params),
        position: this.isHorizontal() ? "left" : "bottom"
      },
      {
        type: "number",
        position: this.isHorizontal() ? "bottom" : "left"
      }
    ];
    if (this.isNormalised()) {
      const numberAxis = axes[1];
      numberAxis.label = __spreadProps3(__spreadValues3({}, numberAxis.label), { formatter: (params2) => Math.round(params2.value) + "%" });
    }
    return axes;
  }
  getSeries(params) {
    const [category] = params.categories;
    const series = params.fields.map((f) => ({
      type: this.standaloneChartType,
      direction: this.isHorizontal() ? "horizontal" : "vertical",
      stacked: this.crossFiltering || isStacked(this.chartType),
      normalizedTo: this.isNormalised() ? 100 : void 0,
      xKey: category.id,
      xName: category.name,
      yKey: f.colId,
      yName: f.displayName
    }));
    return this.crossFiltering ? this.extractCrossFilterSeries(series) : series;
  }
  extractCrossFilterSeries(series) {
    const palette = this.getChartPalette();
    const updatePrimarySeries = (seriesOptions, index) => {
      return __spreadProps3(__spreadValues3({}, seriesOptions), {
        highlightStyle: { item: { fill: void 0 } },
        fill: palette == null ? void 0 : palette.fills[index],
        stroke: palette == null ? void 0 : palette.strokes[index],
        listeners: {
          nodeClick: this.crossFilterCallback
        }
      });
    };
    const updateFilteredOutSeries = (seriesOptions) => {
      const yKey = seriesOptions.yKey + "-filtered-out";
      return __spreadProps3(__spreadValues3({}, deepMerge({}, seriesOptions)), {
        yKey,
        fill: hexToRGBA(seriesOptions.fill, "0.3"),
        stroke: hexToRGBA(seriesOptions.stroke, "0.3"),
        showInLegend: false
      });
    };
    const allSeries = [];
    for (let i = 0; i < series.length; i++) {
      const primarySeries = updatePrimarySeries(series[i], i);
      allSeries.push(primarySeries);
      allSeries.push(updateFilteredOutSeries(primarySeries));
    }
    return allSeries;
  }
  isNormalised() {
    const normalisedCharts = ["normalizedColumn", "normalizedBar"];
    return !this.crossFiltering && _30.includes(normalisedCharts, this.chartType);
  }
  isHorizontal() {
    return HORIZONTAL_CHART_TYPES.has(this.chartType);
  }
};
var AreaChartProxy = class extends CartesianChartProxy {
  constructor(params) {
    super(params);
  }
  getAxes(params) {
    const axes = [
      {
        type: this.getXAxisType(params),
        position: "bottom"
      },
      {
        type: "number",
        position: "left"
      }
    ];
    if (this.isNormalised()) {
      const numberAxis = axes[1];
      numberAxis.label = __spreadProps3(__spreadValues3({}, numberAxis.label), { formatter: (params2) => Math.round(params2.value) + "%" });
    }
    return axes;
  }
  getSeries(params) {
    const [category] = params.categories;
    const series = params.fields.map((f) => ({
      type: this.standaloneChartType,
      xKey: category.id,
      xName: category.name,
      yKey: f.colId,
      yName: f.displayName,
      normalizedTo: this.chartType === "normalizedArea" ? 100 : void 0,
      stacked: ["normalizedArea", "stackedArea"].includes(this.chartType)
    }));
    return this.crossFiltering ? this.extractLineAreaCrossFilterSeries(series, params) : series;
  }
  isNormalised() {
    return !this.crossFiltering && this.chartType === "normalizedArea";
  }
};
var LineChartProxy = class extends CartesianChartProxy {
  constructor(params) {
    super(params);
  }
  getAxes(params) {
    return [
      {
        type: this.getXAxisType(params),
        position: "bottom"
      },
      {
        type: "number",
        position: "left"
      }
    ];
  }
  getSeries(params) {
    const [category] = params.categories;
    const series = params.fields.map((f) => ({
      type: this.standaloneChartType,
      xKey: category.id,
      xName: category.name,
      yKey: f.colId,
      yName: f.displayName
    }));
    return this.crossFiltering ? this.extractLineAreaCrossFilterSeries(series, params) : series;
  }
};
var PolarChartProxy = class extends ChartProxy {
  constructor(params) {
    super(params);
  }
  getAxes(_382) {
    const radialBar = this.standaloneChartType === "radial-bar";
    return [
      { type: radialBar ? "angle-number" : "angle-category" },
      { type: radialBar ? "radius-category" : "radius-number" }
    ];
  }
  getSeries(params) {
    const { fields, categories, seriesGroupType } = params;
    const [category] = categories;
    const radialBar = this.standaloneChartType === "radial-bar";
    const seriesGroupTypeOptions = this.getSeriesGroupTypeOptions(seriesGroupType);
    return fields.map((f) => {
      var _a, _b;
      return __spreadValues3({
        type: this.standaloneChartType,
        angleKey: radialBar ? f.colId : category.id,
        angleName: radialBar ? (_a = f.displayName) != null ? _a : void 0 : category.name,
        radiusKey: radialBar ? category.id : f.colId,
        radiusName: radialBar ? category.name : (_b = f.displayName) != null ? _b : void 0
      }, seriesGroupTypeOptions);
    });
  }
  getSeriesGroupType() {
    var _a, _b;
    const standaloneChartType = this.standaloneChartType;
    if (!["nightingale", "radial-bar", "radial-column"].includes(standaloneChartType)) {
      return void 0;
    }
    const firstSeriesProperties = (_b = (_a = this.getChart().series) == null ? void 0 : _a[0]) == null ? void 0 : _b.properties.toJson();
    const getStackedValue = () => firstSeriesProperties.normalizedTo ? "normalized" : "stacked";
    if (standaloneChartType === "nightingale") {
      return firstSeriesProperties.grouped ? "grouped" : getStackedValue();
    } else {
      return firstSeriesProperties.stacked ? getStackedValue() : "grouped";
    }
  }
  getUpdateOptions(params, commonChartOptions) {
    const axes = this.getAxes(params);
    return __spreadProps3(__spreadValues3({}, commonChartOptions), {
      data: this.getData(params, axes),
      axes,
      series: this.getSeries(params)
    });
  }
  getData(params, axes) {
    const isCategoryAxis = axes.some((axis) => axis.type === "angle-category" || axis.type === "radius-category");
    if (isCategoryAxis) {
      const [category] = params.categories;
      return this.transformCategoryData(params.data, category.id);
    } else {
      return params.data;
    }
  }
  getSeriesGroupTypeOptions(seriesGroupType) {
    if (!seriesGroupType) {
      return {};
    }
    return {
      grouped: seriesGroupType === "grouped" || void 0,
      stacked: seriesGroupType !== "grouped" || void 0,
      normalizedTo: seriesGroupType === "normalized" ? 100 : void 0
    };
  }
};
var PieChartProxy = class _PieChartProxy extends ChartProxy {
  constructor(params) {
    super(params);
  }
  getUpdateOptions(params, commonChartOptions) {
    return __spreadProps3(__spreadValues3({}, commonChartOptions), {
      data: this.crossFiltering ? this.getCrossFilterData(params) : params.data,
      series: this.getSeries(params)
    });
  }
  getSeries(params) {
    const [category] = params.categories;
    const numFields = params.fields.length;
    const offset = {
      currentOffset: 0,
      offsetAmount: numFields > 1 ? 20 : 40
    };
    const series = this.getFields(params).map((f) => {
      var _a;
      const options = {
        type: this.standaloneChartType,
        angleKey: f.colId,
        angleName: f.displayName,
        sectorLabelKey: f.colId,
        calloutLabelName: category.name,
        calloutLabelKey: category.id
      };
      if (this.chartType === "donut" || this.chartType === "doughnut") {
        const { outerRadiusOffset, innerRadiusOffset } = _PieChartProxy.calculateOffsets(offset);
        const title = f.displayName ? {
          title: { text: f.displayName, showInLegend: numFields > 1 }
        } : void 0;
        return __spreadProps3(__spreadValues3(__spreadProps3(__spreadValues3({}, options), {
          type: "donut",
          outerRadiusOffset,
          innerRadiusOffset
        }), title), {
          calloutLine: {
            colors: (_a = this.getChartPalette()) == null ? void 0 : _a.strokes
          }
        });
      }
      return options;
    });
    return this.crossFiltering ? this.extractCrossFilterSeries(series) : series;
  }
  getCrossFilterData(params) {
    const colId = params.fields[0].colId;
    const filteredOutColId = `${colId}-filtered-out`;
    return params.data.map((d) => {
      const total = d[colId] + d[filteredOutColId];
      d[`${colId}-total`] = total;
      d[filteredOutColId] = 1;
      d[colId] = d[colId] / total;
      return d;
    });
  }
  extractCrossFilterSeries(series) {
    const palette = this.getChartPalette();
    const primaryOptions = (seriesOptions) => {
      return __spreadProps3(__spreadValues3({}, seriesOptions), {
        legendItemKey: seriesOptions.calloutLabelKey,
        calloutLabel: { enabled: false },
        // hide labels on primary series
        highlightStyle: { item: { fill: void 0 } },
        radiusKey: seriesOptions.angleKey,
        angleKey: seriesOptions.angleKey + "-total",
        radiusMin: 0,
        radiusMax: 1,
        listeners: {
          nodeClick: this.crossFilterCallback
        }
      });
    };
    const filteredOutOptions = (seriesOptions, angleKey2) => {
      var _a, _b;
      return __spreadProps3(__spreadValues3({}, deepMerge({}, primaryOpts)), {
        radiusKey: angleKey2 + "-filtered-out",
        fills: changeOpacity((_a = seriesOptions.fills) != null ? _a : palette.fills, 0.3),
        strokes: changeOpacity((_b = seriesOptions.strokes) != null ? _b : palette.strokes, 0.3),
        showInLegend: false
      });
    };
    const primarySeries = series[0];
    const angleKey = primarySeries.angleKey;
    const primaryOpts = primaryOptions(primarySeries);
    return [
      filteredOutOptions(primarySeries, angleKey),
      primaryOpts
    ];
  }
  static calculateOffsets(offset) {
    const outerRadiusOffset = offset.currentOffset;
    offset.currentOffset -= offset.offsetAmount;
    const innerRadiusOffset = offset.currentOffset;
    offset.currentOffset -= offset.offsetAmount;
    return { outerRadiusOffset, innerRadiusOffset };
  }
  getFields(params) {
    return this.chartType === "pie" ? params.fields.slice(0, 1) : params.fields;
  }
};
var ScatterChartProxy = class extends CartesianChartProxy {
  constructor(params) {
    super(params);
  }
  getAxes(_params) {
    return [
      {
        type: "number",
        position: "bottom"
      },
      {
        type: "number",
        position: "left"
      }
    ];
  }
  getSeries(params) {
    const [category] = params.categories;
    const paired = this.isPaired();
    const seriesDefinitions = this.getSeriesDefinitions(params.fields, paired);
    const labelFieldDefinition = category.id === ChartDataModel.DEFAULT_CATEGORY ? void 0 : category;
    const series = seriesDefinitions.map((seriesDefinition) => {
      var _a, _b, _c, _d, _e;
      if (seriesDefinition == null ? void 0 : seriesDefinition.sizeField) {
        const opts2 = {
          type: "bubble",
          xKey: seriesDefinition.xField.colId,
          xName: (_a = seriesDefinition.xField.displayName) != null ? _a : void 0,
          yKey: seriesDefinition.yField.colId,
          yName: (_b = seriesDefinition.yField.displayName) != null ? _b : void 0,
          title: `${seriesDefinition.yField.displayName} vs ${seriesDefinition.xField.displayName}`,
          sizeKey: seriesDefinition.sizeField.colId,
          sizeName: (_c = seriesDefinition.sizeField.displayName) != null ? _c : "",
          labelKey: labelFieldDefinition ? labelFieldDefinition.id : seriesDefinition.yField.colId,
          labelName: labelFieldDefinition ? labelFieldDefinition.name : void 0
        };
        return opts2;
      }
      const opts = {
        type: "scatter",
        xKey: seriesDefinition.xField.colId,
        xName: (_d = seriesDefinition.xField.displayName) != null ? _d : void 0,
        yKey: seriesDefinition.yField.colId,
        yName: (_e = seriesDefinition.yField.displayName) != null ? _e : void 0,
        title: `${seriesDefinition.yField.displayName} vs ${seriesDefinition.xField.displayName}`,
        labelKey: labelFieldDefinition ? labelFieldDefinition.id : seriesDefinition.yField.colId,
        labelName: labelFieldDefinition ? labelFieldDefinition.name : void 0
      };
      return opts;
    });
    return this.crossFiltering ? this.extractCrossFilterSeries(series, params) : series;
  }
  extractCrossFilterSeries(series, params) {
    const { data } = params;
    const palette = this.getChartPalette();
    const filteredOutKey = (key) => `${key}-filtered-out`;
    const calcMarkerDomain = (data2, sizeKey) => {
      var _a;
      const markerDomain = [Infinity, -Infinity];
      if (sizeKey != null) {
        for (const datum of data2) {
          const value = (_a = datum[sizeKey]) != null ? _a : datum[filteredOutKey(sizeKey)];
          if (value < markerDomain[0]) {
            markerDomain[0] = value;
          }
          if (value > markerDomain[1]) {
            markerDomain[1] = value;
          }
        }
      }
      if (markerDomain[0] <= markerDomain[1]) {
        return markerDomain;
      }
      return void 0;
    };
    const updatePrimarySeries = (series2, idx) => {
      const fill = palette == null ? void 0 : palette.fills[idx];
      const stroke = palette == null ? void 0 : palette.strokes[idx];
      let markerDomain = void 0;
      if (series2.type === "bubble") {
        const { sizeKey } = series2;
        markerDomain = calcMarkerDomain(data, sizeKey);
      }
      const marker = __spreadProps3(__spreadValues3({}, series2.marker), {
        fill,
        stroke,
        domain: markerDomain
      });
      return __spreadProps3(__spreadValues3({}, series2), {
        marker,
        highlightStyle: { item: { fill: "yellow" } },
        listeners: __spreadProps3(__spreadValues3({}, series2.listeners), {
          nodeClick: this.crossFilterCallback
        })
      });
    };
    const updateFilteredOutSeries = (series2) => {
      let { yKey, xKey } = series2;
      let alteredSizeKey = {};
      if (series2.type === "bubble") {
        alteredSizeKey = { sizeKey: filteredOutKey(series2.sizeKey) };
      }
      return __spreadProps3(__spreadValues3(__spreadValues3({}, series2), alteredSizeKey), {
        yKey: filteredOutKey(yKey),
        xKey: filteredOutKey(xKey),
        marker: __spreadProps3(__spreadValues3({}, series2.marker), {
          fillOpacity: 0.3,
          strokeOpacity: 0.3
        }),
        showInLegend: false,
        listeners: __spreadProps3(__spreadValues3({}, series2.listeners), {
          nodeClick: (e) => {
            const value = e.datum[filteredOutKey(xKey)];
            const filterableEvent = __spreadProps3(__spreadValues3({}, e), {
              xKey,
              datum: __spreadProps3(__spreadValues3({}, e.datum), { [xKey]: value })
            });
            this.crossFilterCallback(filterableEvent);
          }
        })
      });
    };
    const updatedSeries = series.map(updatePrimarySeries);
    return [
      ...updatedSeries,
      ...updatedSeries.map(updateFilteredOutSeries)
    ];
  }
  getSeriesDefinitions(fields, paired) {
    if (fields.length < 2) {
      return [];
    }
    const isBubbleChart = this.chartType === "bubble";
    if (paired) {
      if (isBubbleChart) {
        return fields.map((currentXField, i) => i % 3 === 0 ? {
          xField: currentXField,
          yField: fields[i + 1],
          sizeField: fields[i + 2]
        } : null).filter((x) => x && x.yField && x.sizeField);
      }
      return fields.map((currentXField, i) => i % 2 === 0 ? {
        xField: currentXField,
        yField: fields[i + 1]
      } : null).filter((x) => x && x.yField);
    }
    const xField = fields[0];
    if (isBubbleChart) {
      return fields.map((yField, i) => i % 2 === 1 ? {
        xField,
        yField,
        sizeField: fields[i + 1]
      } : null).filter((x) => x && x.sizeField);
    }
    return fields.filter((value, i) => i > 0).map((yField) => ({ xField, yField }));
  }
};
var StatisticalChartProxy = class extends CartesianChartProxy {
  constructor(params) {
    super(params);
  }
  getAxes(params, commonChartOptions) {
    return [
      {
        type: this.getXAxisType(params),
        position: this.isHorizontal(commonChartOptions) ? "left" : "bottom"
      },
      {
        type: "number",
        position: this.isHorizontal(commonChartOptions) ? "bottom" : "left"
      }
    ];
  }
  computeSeriesStatistics(params, computeStatsFn) {
    const { data, fields } = params;
    const [category] = params.categories;
    const categoryKey = category.id || ChartDataModel.DEFAULT_CATEGORY;
    const groupedData = this.groupDataByCategory(categoryKey, data);
    return Array.from(groupedData).map(([categoryValue, categoryData]) => {
      const categoryResult = { [category.id]: categoryValue };
      fields.forEach((field, seriesIndex) => {
        const seriesValues = categoryData.map((datum) => datum[field.colId]).filter((value) => typeof value === "number" && !isNaN(value));
        Object.entries(computeStatsFn(seriesValues)).forEach(([statKey, value]) => {
          const propertyKey = `${statKey}:${seriesIndex}`;
          categoryResult[propertyKey] = seriesValues.length > 0 ? value : null;
        });
      });
      return categoryResult;
    });
  }
  groupDataByCategory(categoryKey, data) {
    const getCategory = (datum) => {
      if (categoryKey === ChartDataModel.DEFAULT_CATEGORY) {
        return 1;
      }
      const categoryValue = datum[categoryKey];
      if (categoryValue === null || categoryValue === void 0) {
        return "";
      }
      return categoryValue instanceof Date ? categoryValue.getTime() : categoryValue;
    };
    return data.reduce((acc, datum) => {
      let category = getCategory(datum);
      const existingCategoryData = acc.get(category);
      if (existingCategoryData) {
        existingCategoryData.push(datum);
      } else {
        acc.set(category, [datum]);
      }
      return acc;
    }, /* @__PURE__ */ new Map());
  }
};
var RangeChartProxy = class extends StatisticalChartProxy {
  constructor(params) {
    super(params);
  }
  getSeries(params) {
    const [category] = params.categories;
    return params.fields.map(
      (field, seriesIndex) => {
        var _a;
        return {
          type: this.standaloneChartType,
          // xKey/xName refer to category buckets
          xKey: category.id,
          xName: category.name,
          // yName is used to label the series
          yName: (_a = field.displayName) != null ? _a : void 0,
          // custom field labels shown in the tooltip
          yLowName: "Min",
          yHighName: "Max",
          // generated 'synthetic fields' from getData()
          yLowKey: `min:${seriesIndex}`,
          yHighKey: `max:${seriesIndex}`
        };
      }
    );
  }
  getData(params) {
    return this.computeSeriesStatistics(params, (seriesValues) => {
      return {
        min: Math.min(...seriesValues),
        max: Math.max(...seriesValues)
      };
    });
  }
};
var HistogramChartProxy = class extends CartesianChartProxy {
  constructor(params) {
    super(params);
  }
  getSeries(params) {
    const firstField = params.fields[0];
    return [
      {
        type: this.standaloneChartType,
        xKey: firstField.colId,
        xName: firstField.displayName,
        yName: this.chartProxyParams.translate("histogramFrequency"),
        areaPlot: false
        // only constant width is supported via integrated charts
      }
    ];
  }
  getAxes(_params) {
    return [
      {
        type: "number",
        position: "bottom"
      },
      {
        type: "number",
        position: "left"
      }
    ];
  }
};
var BoxPlotChartProxy = class extends StatisticalChartProxy {
  constructor(params) {
    super(params);
  }
  getSeries(params) {
    const [category] = params.categories;
    return params.fields.map(
      (field, seriesIndex) => {
        var _a;
        return {
          type: this.standaloneChartType,
          // xKey/xName refer to category buckets
          xKey: category.id,
          xName: category.name,
          // yName is used to label the series
          yName: (_a = field.displayName) != null ? _a : void 0,
          // custom field labels shown in the tooltip
          minName: "Min",
          q1Name: "Q1",
          medianName: "Median",
          q3Name: "Q3",
          maxName: "Max",
          // generated 'synthetic fields' from getData()
          minKey: `min:${seriesIndex}`,
          q1Key: `q1:${seriesIndex}`,
          medianKey: `median:${seriesIndex}`,
          q3Key: `q3:${seriesIndex}`,
          maxKey: `max:${seriesIndex}`
        };
      }
    );
  }
  getData(params) {
    return this.computeSeriesStatistics(params, (seriesValues) => {
      const sortedValues = seriesValues.sort((a, b) => a - b);
      return {
        min: sortedValues[0],
        q1: this.quantile(sortedValues, 0.25),
        median: this.quantile(sortedValues, 0.5),
        q3: this.quantile(sortedValues, 0.75),
        max: sortedValues[sortedValues.length - 1]
      };
    });
  }
  quantile(sortedValues, q) {
    const position = (sortedValues.length - 1) * q;
    const indexBelow = Math.floor(position);
    const aboveValue = position - indexBelow;
    if (sortedValues[indexBelow + 1] !== void 0) {
      return sortedValues[indexBelow] + aboveValue * (sortedValues[indexBelow + 1] - sortedValues[indexBelow]);
    }
    return sortedValues[indexBelow];
  }
};
function flatMap(items, iteratee) {
  return items.reduce((acc, item, index, array) => acc.concat(iteratee(item, index, array)), new Array());
}
var HEATMAP_CATEGORY_KEY = "AG-GRID-DEFAULT-HEATMAP-CATEGORY-KEY";
var HEATMAP_SERIES_KEY = "AG-GRID-DEFAULT-HEATMAP-SERIES-KEY";
var HEATMAP_VALUE_KEY = "AG-GRID-DEFAULT-HEATMAP-VALUE-KEY";
var HeatmapChartProxy = class extends ChartProxy {
  constructor(params) {
    super(params);
  }
  getUpdateOptions(params, commonChartOptions) {
    const xSeriesKey = HEATMAP_SERIES_KEY;
    const xValueKey = HEATMAP_VALUE_KEY;
    const yKey = HEATMAP_CATEGORY_KEY;
    return __spreadProps3(__spreadValues3({}, commonChartOptions), {
      series: this.getSeries(params, xSeriesKey, xValueKey, yKey),
      data: this.getData(params, xSeriesKey, xValueKey, yKey)
    });
  }
  getSeries(params, xSeriesKey, xValueKey, yKey) {
    const [category] = params.categories;
    return [
      {
        type: this.standaloneChartType,
        // The axis keys reference synthetic fields based on the category values and series column names
        yKey,
        xKey: xSeriesKey,
        // The color key references a synthetic field based on the series column value for a specific cell
        colorKey: xValueKey,
        yName: category.name,
        // We don't know how to label the 'x' series, as it is a synthetic series created from the set of all input columns
        // In future releases we may want to consider inferring the series label from column groupings etc
        xName: void 0,
        colorName: void 0
      }
    ];
  }
  getData(params, xSeriesKey, xValueKey, yKey) {
    const [category] = params.categories;
    return flatMap(
      params.data,
      (datum, index) => {
        const value = datum[category.id];
        const valueString = value == null ? "" : String(value);
        const yValue = { id: index, value, toString: () => valueString };
        return params.fields.map(({ colId, displayName }) => __spreadProps3(__spreadValues3({}, datum), {
          [xSeriesKey]: displayName,
          [xValueKey]: datum[colId],
          [yKey]: yValue
        }));
      }
    );
  }
  getSeriesChartThemeDefaults() {
    return {
      gradientLegend: {
        gradient: {
          preferredLength: 200
        }
      },
      series: {
        tooltip: {
          renderer: renderHeatmapTooltip
        }
      }
    };
  }
};
function renderHeatmapTooltip(params) {
  const { xKey, yKey, colorKey, yName, datum } = params;
  const table = [
    { label: yName, value: datum[yKey] },
    { label: datum[xKey], value: colorKey && datum[colorKey] }
  ];
  const html = table.map(({ label, value }) => `<b>${sanitizeHtml(String(label))}:</b> ${sanitizeHtml(String(value))}`).join("<br>");
  return {
    title: "",
    content: html
  };
}
function sanitizeHtml(input) {
  const ESCAPED_CHARS = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;"
  };
  const characterClass = `[${Object.keys(ESCAPED_CHARS).join("")}]`;
  const pattern = new RegExp(characterClass, "g");
  return input.replace(pattern, (char) => ESCAPED_CHARS[char]);
}
var WaterfallChartProxy = class extends CartesianChartProxy {
  constructor(params) {
    super(params);
  }
  getAxes(params, commonChartOptions) {
    return [
      {
        type: this.getXAxisType(params),
        position: this.isHorizontal(commonChartOptions) ? "left" : "bottom"
      },
      {
        type: "number",
        position: this.isHorizontal(commonChartOptions) ? "bottom" : "left"
      }
    ];
  }
  getSeries(params) {
    var _a;
    const [category] = params.categories;
    const [firstField] = params.fields;
    const firstSeries = {
      type: this.standaloneChartType,
      xKey: category.id,
      xName: category.name,
      yKey: firstField.colId,
      yName: (_a = firstField.displayName) != null ? _a : void 0
    };
    return [firstSeries];
  }
};
var CARTESIAN_AXIS_TYPES = ["number", "category", "time", "grouped-category"];
var POLAR_AXIS_TYPES = ["angle-category", "angle-number", "radius-category", "radius-number"];
var VALID_AXIS_TYPES = [...CARTESIAN_AXIS_TYPES, ...POLAR_AXIS_TYPES];
var ChartOptionsService = class _ChartOptionsService extends BeanStub8 {
  constructor(chartController) {
    super();
    this.chartController = chartController;
  }
  getChartThemeOverridesProxy() {
    return {
      getValue: (expression) => this.getChartOption(expression),
      setValue: (expression, value) => this.setChartThemeOverrides([{ expression, value }]),
      setValues: (properties) => this.setChartThemeOverrides(properties)
    };
  }
  getAxisThemeOverridesProxy() {
    return {
      getValue: (expression) => this.getAxisProperty(expression),
      setValue: (expression, value) => this.setAxisThemeOverrides([{ expression, value }]),
      setValues: (properties) => this.setAxisThemeOverrides(properties)
    };
  }
  getCartesianAxisOptionsProxy(axisType) {
    return {
      getValue: (expression) => this.getCartesianAxisProperty(axisType, expression),
      setValue: (expression, value) => this.setCartesianAxisOptions(axisType, [{ expression, value }]),
      setValues: (properties) => this.setCartesianAxisOptions(axisType, properties)
    };
  }
  getCartesianAxisThemeOverridesProxy(axisType) {
    return {
      getValue: (expression) => this.getCartesianAxisProperty(axisType, expression),
      setValue: (expression, value) => this.setCartesianAxisThemeOverrides(axisType, [{ expression, value }]),
      setValues: (properties) => this.setCartesianAxisThemeOverrides(axisType, properties)
    };
  }
  getCartesianAxisAppliedThemeOverridesProxy(axisType) {
    return {
      getValue: (expression) => this.getCartesianAxisThemeOverride(
        axisType,
        // Allow the caller to specify a wildcard expression to retrieve the whole set of overrides
        expression === "*" ? null : expression
      ),
      setValue: (expression, value) => this.setCartesianAxisThemeOverrides(
        axisType,
        // Allow the caller to specify a wildcard expression to set the whole set of overrides
        [{ expression: expression === "*" ? null : expression, value }]
      ),
      setValues: (properties) => this.setCartesianAxisThemeOverrides(axisType, properties)
    };
  }
  getSeriesOptionsProxy(getSelectedSeries) {
    return {
      getValue: (expression, calculated) => this.getSeriesOption(getSelectedSeries(), expression, calculated),
      setValue: (expression, value) => this.setSeriesOptions(getSelectedSeries(), [{ expression, value }]),
      setValues: (properties) => this.setSeriesOptions(getSelectedSeries(), properties)
    };
  }
  /**
   * Determine the set of theme overrides that should be retained when transitioning from one chart type to another.
   */
  getPersistedChartThemeOverrides(existingChartOptions, existingAxes, existingChartType, targetChartType) {
    const retainedThemeOverrideKeys = this.getRetainedChartThemeOverrideKeys(existingChartType, targetChartType);
    const retainedChartAxisThemeOverrideKeys = this.getRetainedChartAxisThemeOverrideKeys(null, existingChartType, targetChartType);
    const targetChartOptions = this.createChartOptions();
    for (const expression of retainedThemeOverrideKeys) {
      const value = this.retrieveChartOptionsThemeOverride(existingChartOptions, existingChartType, expression);
      if (value !== void 0) {
        this.assignChartOptionsThemeOverride(targetChartOptions, targetChartType, expression, value);
      }
    }
    if (existingAxes) {
      this.assignPersistedAxisOverrides({
        existingAxes,
        retainedChartAxisThemeOverrideKeys,
        existingChartOptions,
        targetChartOptions,
        existingChartType,
        targetChartType
      });
    }
    return targetChartOptions.theme.overrides;
  }
  assignPersistedAxisOverrides(params) {
    const { existingAxes, retainedChartAxisThemeOverrideKeys, existingChartOptions, targetChartOptions, existingChartType, targetChartType } = params;
    for (const { expression, targetAxisTypes } of retainedChartAxisThemeOverrideKeys) {
      for (const existingAxisType of existingAxes.map((axis) => axis.type)) {
        const value = this.retrieveChartOptionsThemeOverride(
          existingChartOptions,
          existingChartType,
          ["axes", existingAxisType, expression].join(".")
        );
        if (value !== void 0) {
          for (const targetAxisType of targetAxisTypes) {
            this.assignChartOptionsThemeOverride(
              targetChartOptions,
              targetChartType,
              ["axes", targetAxisType, expression].join("."),
              value
            );
          }
        }
      }
    }
  }
  getRetainedChartThemeOverrideKeys(existingChartType, targetChartType) {
    const UNIVERSAL_PERSISTED_THEME_OVERRIDES = ["animation"];
    const PERSISTED_CARTESIAN_CHART_THEME_OVERRIDES = ["zoom", "navigator"];
    const chartSpecificThemeOverrideKeys = ((previousChartType, updatedChartType) => {
      const expressions = new Array();
      if (isCartesian(getSeriesType(previousChartType)) && isCartesian(getSeriesType(updatedChartType))) {
        expressions.push(...PERSISTED_CARTESIAN_CHART_THEME_OVERRIDES);
      }
      return expressions;
    })(existingChartType, targetChartType);
    return [
      ...UNIVERSAL_PERSISTED_THEME_OVERRIDES,
      ...chartSpecificThemeOverrideKeys
    ];
  }
  getRetainedChartAxisThemeOverrideKeys(axisType, existingChartType, targetChartType) {
    if (isCartesian(getSeriesType(existingChartType)) && isCartesian(getSeriesType(targetChartType))) {
      const retainedKeys = this.getRetainedCartesianAxisThemeOverrideKeys(axisType);
      return retainedKeys.map((expression) => ({ expression, targetAxisTypes: CARTESIAN_AXIS_TYPES }));
    }
    return [];
  }
  getRetainedCartesianAxisThemeOverrideKeys(axisType) {
    const axisPositionSuffixes = axisType === "xAxis" ? ["", ".top", ".bottom"] : axisType === "yAxis" ? ["", ".left", ".right"] : ["", ".left", ".right", ".top", ".bottom"];
    const PERSISTED_CARTESIAN_AXIS_THEME_OVERRIDES = ["crosshair"];
    const expressions = new Array();
    for (const expression of PERSISTED_CARTESIAN_AXIS_THEME_OVERRIDES) {
      for (const axisPositionSuffix of axisPositionSuffixes) {
        expressions.push(`${expression}${axisPositionSuffix}`);
      }
    }
    return expressions;
  }
  getChartOption(expression) {
    return get(this.getChart(), expression, void 0);
  }
  setChartThemeOverrides(properties) {
    const chartType = this.getChartType();
    const chartOptions = this.createChartOptions();
    for (const { expression, value } of properties) {
      this.assignChartOptionsThemeOverride(chartOptions, chartType, expression, value);
    }
    this.applyChartOptions(chartOptions);
  }
  applyChartOptions(chartOptions, options) {
    if (Object.keys(chartOptions).length === 0)
      return;
    this.updateChart(chartOptions);
    const shouldRaiseEvent = !(options == null ? void 0 : options.silent);
    if (shouldRaiseEvent)
      this.raiseChartOptionsChangedEvent();
  }
  awaitChartOptionUpdate(func) {
    const chart = this.chartController.getChartProxy().getChart();
    chart.waitForUpdate().then(() => func()).catch((e) => console.error(`AG Grid - chart update failed`, e));
  }
  getAxisProperty(expression) {
    var _a;
    return get((_a = this.getChart().axes) == null ? void 0 : _a[0], expression, void 0);
  }
  setAxisThemeOverrides(properties) {
    var _a;
    const chart = this.getChart();
    const chartType = this.getChartType();
    let chartOptions = this.createChartOptions();
    for (const { expression, value } of properties) {
      const relevantAxes = (_a = chart.axes) == null ? void 0 : _a.filter((axis) => {
        const parts = expression.split(".");
        let current = axis;
        for (const part of parts) {
          if (!(part in current)) {
            return false;
          }
          current = current[part];
        }
        return true;
      });
      if (!relevantAxes)
        continue;
      for (const axis of relevantAxes) {
        if (!this.isValidAxisType(axis))
          continue;
        this.assignChartAxisThemeOverride(chartOptions, chartType, axis.type, null, expression, value);
      }
    }
    this.applyChartOptions(chartOptions);
  }
  getCartesianAxisProperty(axisType, expression) {
    const axes = this.getChartAxes();
    const axis = this.getCartesianAxis(axes, axisType);
    return get(axis, expression, void 0);
  }
  getCartesianAxisThemeOverride(axisType, expression) {
    const axes = this.getChartAxes();
    const chartAxis = this.getCartesianAxis(axes, axisType);
    if (!chartAxis || !this.isValidAxisType(chartAxis))
      return void 0;
    const chartType = this.getChartType();
    const chartOptions = this.getChart().getOptions();
    return this.retrieveChartAxisThemeOverride(
      chartOptions,
      chartType,
      chartAxis.type,
      axisType === "yAxis" ? ["left", "right"] : ["bottom", "top"],
      expression
    );
  }
  setCartesianAxisThemeOverrides(axisType, properties) {
    const axes = this.getChartAxes();
    const chartAxis = this.getCartesianAxis(axes, axisType);
    if (!chartAxis || !this.isValidAxisType(chartAxis))
      return;
    const chartType = this.getChartType();
    let chartOptions = this.createChartOptions();
    for (const { expression, value } of properties) {
      this.assignChartAxisThemeOverride(
        chartOptions,
        chartType,
        chartAxis.type,
        axisType === "yAxis" ? ["left", "right"] : ["bottom", "top"],
        expression,
        value
      );
    }
    this.applyChartOptions(chartOptions);
  }
  setCartesianAxisOptions(axisType, properties) {
    this.updateCartesianAxisOptions(axisType, (chartOptions, axes, chartAxis) => {
      const axisIndex = axes.indexOf(chartAxis);
      for (const { expression, value } of properties) {
        this.assignChartOption(chartOptions, `axes.${axisIndex}.${expression}`, value);
      }
    });
  }
  updateCartesianAxisOptions(axisType, updateFunc) {
    const existingChartOptions = this.getChart().getOptions();
    const axisOptions = "axes" in existingChartOptions ? existingChartOptions.axes : void 0;
    if (!existingChartOptions || !axisOptions)
      return;
    const axes = this.getChartAxes();
    const chartAxis = this.getCartesianAxis(axes, axisType);
    if (!chartAxis)
      return;
    let chartOptions = this.createChartOptions();
    chartOptions.axes = axisOptions;
    updateFunc(chartOptions, axes, chartAxis, existingChartOptions);
    this.applyChartOptions(chartOptions);
  }
  setCartesianCategoryAxisType(axisType, value) {
    this.updateCartesianAxisOptions(axisType, (chartOptions, _axes, chartAxis, existingChartOptions) => {
      const chartType = this.getChartType();
      this.assignPersistedAxisOverrides({
        existingAxes: [chartAxis],
        retainedChartAxisThemeOverrideKeys: this.getRetainedChartAxisThemeOverrideKeys(axisType, chartType, chartType),
        existingChartOptions,
        targetChartOptions: chartOptions,
        existingChartType: chartType,
        targetChartType: chartType
      });
      this.assignChartOption(chartOptions, `axes.0.type`, value);
      this.chartController.setCategoryAxisType(value);
    });
  }
  getCartesianAxis(axes, axisType) {
    if (axes.length < 2) {
      return void 0;
    }
    switch (axisType) {
      case "xAxis":
        return axes[0].direction === "x" ? axes[0] : axes[1];
      case "yAxis":
        return axes[1].direction === "y" ? axes[1] : axes[0];
    }
  }
  getSeriesOption(seriesType, expression, calculated) {
    const series = this.getChart().series.find((s) => _ChartOptionsService.isMatchingSeries(seriesType, s));
    return get(calculated ? series : series == null ? void 0 : series.properties.toJson(), expression, void 0);
  }
  setSeriesOptions(seriesType, properties) {
    let chartOptions = this.createChartOptions();
    for (const { expression, value } of properties) {
      this.assignChartOptionsSeriesThemeOverride(
        chartOptions,
        seriesType,
        `series.${expression}`,
        value
      );
    }
    this.applyChartOptions(chartOptions);
  }
  getPairedMode() {
    return this.chartController.getChartProxy().isPaired();
  }
  setPairedMode(paired) {
    this.chartController.getChartProxy().setPaired(paired);
  }
  getChartAxes() {
    var _a;
    const chart = this.getChart();
    return (_a = chart.axes) != null ? _a : [];
  }
  retrieveChartAxisThemeOverride(chartOptions, chartType, axisType, axisPositions, expression) {
    if (axisPositions) {
      for (const axisPosition of axisPositions) {
        const value = this.retrieveChartOptionsThemeOverride(
          chartOptions,
          chartType,
          ["axes", axisType, axisPosition, ...expression ? [expression] : []].join(".")
        );
        if (value === void 0)
          continue;
        return value;
      }
    } else {
      return this.retrieveChartOptionsThemeOverride(
        chartOptions,
        chartType,
        ["axes", axisType, ...expression ? [expression] : []].join(".")
      );
    }
  }
  assignChartAxisThemeOverride(chartOptions, chartType, axisType, axisPositions, expression, value) {
    if (axisPositions) {
      for (const axisPosition of axisPositions) {
        this.assignChartOptionsThemeOverride(
          chartOptions,
          chartType,
          ["axes", axisType, axisPosition, ...expression ? [expression] : []].join("."),
          value
        );
      }
    } else {
      this.assignChartOptionsThemeOverride(
        chartOptions,
        chartType,
        ["axes", axisType, ...expression ? [expression] : []].join("."),
        value
      );
    }
  }
  isValidAxisType(chartAxis) {
    return VALID_AXIS_TYPES.includes(chartAxis.type);
  }
  getChartType() {
    return this.chartController.getChartType();
  }
  getChart() {
    return this.chartController.getChartProxy().getChart();
  }
  updateChart(chartOptions) {
    const chartRef = this.chartController.getChartProxy().getChartRef();
    chartRef.skipAnimations();
    AgCharts2.updateDelta(chartRef, chartOptions);
  }
  createChartOptions() {
    const chartOptions = {
      theme: {
        overrides: {}
      }
    };
    return chartOptions;
  }
  retrieveChartOptionsThemeOverride(chartOptions, chartType, expression) {
    const chartSeriesTypes = this.getChartThemeOverridesSeriesTypeKeys(chartType);
    for (const seriesType of chartSeriesTypes) {
      const value = this.retrieveChartOptionsSeriesThemeOverride(chartOptions, seriesType, expression);
      if (value === void 0)
        continue;
      return value;
    }
    return void 0;
  }
  assignChartOptionsThemeOverride(chartOptions, chartType, expression, value) {
    const chartSeriesTypes = this.getChartThemeOverridesSeriesTypeKeys(chartType);
    for (const seriesType of chartSeriesTypes) {
      this.assignChartOptionsSeriesThemeOverride(chartOptions, seriesType, expression, value);
    }
  }
  retrieveChartOptionsSeriesThemeOverride(chartOptions, seriesType, expression) {
    return this.retrieveChartOption(
      chartOptions,
      ["theme", "overrides", seriesType, ...expression ? [expression] : []].join(".")
    );
  }
  assignChartOptionsSeriesThemeOverride(chartOptions, seriesType, expression, value) {
    this.assignChartOption(
      chartOptions,
      ["theme", "overrides", seriesType, ...expression ? [expression] : []].join("."),
      value
    );
  }
  getChartThemeOverridesSeriesTypeKeys(chartType) {
    const chartSeriesTypes = this.chartController.getChartSeriesTypes(chartType);
    if (this.chartController.isComboChart()) {
      chartSeriesTypes.push("common");
    }
    return chartSeriesTypes;
  }
  retrieveChartOption(chartOptions, expression) {
    return get(chartOptions, expression, void 0);
  }
  assignChartOption(chartOptions, expression, value) {
    set(chartOptions, expression, value);
  }
  raiseChartOptionsChangedEvent() {
    const chartModel = this.chartController.getChartModel();
    const event = {
      type: Events92.EVENT_CHART_OPTIONS_CHANGED,
      chartId: chartModel.chartId,
      chartType: chartModel.chartType,
      chartThemeName: this.chartController.getChartThemeName(),
      chartOptions: chartModel.chartOptions
    };
    this.eventService.dispatchEvent(event);
  }
  static isMatchingSeries(seriesType, series) {
    return isSeriesType(seriesType) && series.type === seriesType;
  }
  destroy() {
    super.destroy();
  }
};
var ComboChartProxy = class extends CartesianChartProxy {
  constructor(params) {
    super(params);
  }
  getAxes(params) {
    const fields = params ? params.fields : [];
    const fieldsMap = new Map(fields.map((f) => [f.colId, f]));
    const { primaryYKeys, secondaryYKeys } = this.getYKeys(fields, params.seriesChartTypes);
    const axes = [
      {
        type: this.getXAxisType(params),
        position: "bottom"
      }
    ];
    if (primaryYKeys.length > 0) {
      axes.push({
        type: "number",
        keys: primaryYKeys,
        position: "left"
      });
    }
    if (secondaryYKeys.length > 0) {
      secondaryYKeys.forEach((secondaryYKey) => {
        const field = fieldsMap.get(secondaryYKey);
        const secondaryAxisIsVisible = field && field.colId === secondaryYKey;
        if (!secondaryAxisIsVisible) {
          return;
        }
        const secondaryAxisOptions = {
          type: "number",
          keys: [secondaryYKey],
          position: "right"
        };
        axes.push(secondaryAxisOptions);
      });
    }
    return axes;
  }
  getSeries(params) {
    const { fields, seriesChartTypes } = params;
    const [category] = params.categories;
    return fields.map((field) => {
      const seriesChartType = seriesChartTypes.find((s) => s.colId === field.colId);
      if (seriesChartType) {
        const chartType = seriesChartType.chartType;
        const grouped = ["groupedColumn", "groupedBar"].includes(chartType);
        const groupedOpts = grouped ? { grouped: true } : {};
        return __spreadValues3({
          type: getSeriesType(chartType),
          xKey: category.id,
          yKey: field.colId,
          yName: field.displayName,
          stacked: ["stackedArea", "stackedColumn"].includes(chartType)
        }, groupedOpts);
      }
    });
  }
  getYKeys(fields, seriesChartTypes) {
    const primaryYKeys = [];
    const secondaryYKeys = [];
    fields.forEach((field) => {
      const colId = field.colId;
      const seriesChartType = seriesChartTypes.find((s) => s.colId === colId);
      if (seriesChartType) {
        seriesChartType.secondaryAxis ? secondaryYKeys.push(colId) : primaryYKeys.push(colId);
      }
    });
    return { primaryYKeys, secondaryYKeys };
  }
};
var CHART_TOOL_PANEL_ALLOW_LIST = [
  "chartSettings",
  "chartData",
  "chartFormat"
];
var CHART_TOOLBAR_ALLOW_LIST = [
  "chartUnlink",
  "chartLink",
  "chartDownload"
];
var CHART_TOOL_PANEL_MENU_OPTIONS = {
  settings: "chartSettings",
  data: "chartData",
  format: "chartFormat"
};
var ChartMenuService = class extends BeanStub9 {
  isLegacyFormat() {
    return !this.chartService.isEnterprise();
  }
  downloadChart(chartMenuContext, dimensions, fileName, fileFormat) {
    chartMenuContext.chartController.getChartProxy().downloadChart(dimensions, fileName, fileFormat);
  }
  toggleLinked(chartMenuContext) {
    chartMenuContext.chartController.detachChartRange();
  }
  openAdvancedSettings(chartMenuContext, eventSource) {
    this.advancedSettingsMenuFactory.showMenu(chartMenuContext, eventSource);
  }
  hideAdvancedSettings() {
    this.advancedSettingsMenuFactory.hideMenu();
  }
  getToolbarOptionsAndPanels(chartController) {
    var _a, _b, _c;
    const legacyFormat = this.isLegacyFormat();
    const useChartToolPanelCustomisation = Boolean(this.gos.get("chartToolPanelsDef")) || !legacyFormat;
    let panels;
    let defaultPanel;
    let chartToolbarOptions;
    if (useChartToolPanelCustomisation) {
      const defaultChartToolbarOptions = legacyFormat ? [
        chartController.isChartLinked() ? "chartLink" : "chartUnlink",
        "chartDownload"
      ] : [
        "chartMenu"
      ];
      const toolbarItemsFunc = this.gos.getCallback("getChartToolbarItems");
      const params = {
        defaultItems: defaultChartToolbarOptions
      };
      chartToolbarOptions = toolbarItemsFunc ? toolbarItemsFunc(params).filter((option) => {
        if (!(legacyFormat ? CHART_TOOLBAR_ALLOW_LIST : [...CHART_TOOLBAR_ALLOW_LIST, "chartMenu"]).includes(option)) {
          let msg;
          if (CHART_TOOL_PANEL_ALLOW_LIST.includes(option)) {
            msg = `'${option}' is a Chart Tool Panel option and will be ignored since 'chartToolPanelsDef' is used. Please use 'chartToolPanelsDef.panels' grid option instead`;
          } else if (option === "chartMenu") {
            msg = `'chartMenu' is only allowed as a Chart Toolbar Option when using AG Charts Enterprise`;
          } else {
            msg = `'${option}' is not a valid Chart Toolbar Option`;
          }
          _322.warnOnce(msg);
          return false;
        }
        return true;
      }) : defaultChartToolbarOptions;
      const panelsOverride = (_b = (_a = this.gos.get("chartToolPanelsDef")) == null ? void 0 : _a.panels) == null ? void 0 : _b.map((panel) => {
        const menuOption = CHART_TOOL_PANEL_MENU_OPTIONS[panel];
        if (!menuOption) {
          _322.warnOnce(`Invalid panel in chartToolPanelsDef.panels: '${panel}'`);
        }
        return menuOption;
      }).filter((panel) => Boolean(panel));
      panels = panelsOverride ? panelsOverride : Object.values(CHART_TOOL_PANEL_MENU_OPTIONS);
      if (chartController.isPivotChart()) {
        panels = panels.filter((panel) => panel !== "chartData");
      }
      const defaultToolPanel = (_c = this.gos.get("chartToolPanelsDef")) == null ? void 0 : _c.defaultToolPanel;
      defaultPanel = defaultToolPanel && CHART_TOOL_PANEL_MENU_OPTIONS[defaultToolPanel] || panels[0];
      if (legacyFormat) {
        chartToolbarOptions = panels.length > 0 ? [panels[0], ...chartToolbarOptions] : chartToolbarOptions;
      }
    } else {
      let tabOptions = [
        "chartSettings",
        "chartData",
        "chartFormat",
        chartController.isChartLinked() ? "chartLink" : "chartUnlink",
        "chartDownload"
      ];
      const toolbarItemsFunc = this.gos.getCallback("getChartToolbarItems");
      if (toolbarItemsFunc) {
        const isLegacyToolbar = this.gos.get("suppressChartToolPanelsButton");
        const params = {
          defaultItems: isLegacyToolbar ? tabOptions : CHART_TOOLBAR_ALLOW_LIST
        };
        tabOptions = toolbarItemsFunc(params).filter((option) => {
          if (!CHART_TOOL_PANEL_ALLOW_LIST.includes(option) && !CHART_TOOLBAR_ALLOW_LIST.includes(option)) {
            _322.warnOnce(`'${option}' is not a valid Chart Toolbar Option`);
            return false;
          } else if (!isLegacyToolbar && CHART_TOOL_PANEL_ALLOW_LIST.includes(option)) {
            const msg = `'${option}' is a Chart Tool Panel option and will be ignored. Please use 'chartToolPanelsDef.panels' grid option instead`;
            _322.warnOnce(msg);
            return false;
          }
          return true;
        });
        if (!isLegacyToolbar) {
          tabOptions = tabOptions.concat(CHART_TOOL_PANEL_ALLOW_LIST);
        }
      }
      if (chartController.isPivotChart()) {
        tabOptions = tabOptions.filter((option) => option !== "chartData");
      }
      const ignoreOptions = ["chartUnlink", "chartLink", "chartDownload"];
      panels = tabOptions.filter((option) => ignoreOptions.indexOf(option) === -1);
      defaultPanel = panels[0];
      chartToolbarOptions = tabOptions.filter(
        (value) => ignoreOptions.indexOf(value) !== -1 || panels.length && value === panels[0]
      );
    }
    return {
      panels,
      defaultPanel,
      chartToolbarOptions
    };
  }
  doesChartToolbarExist(chartController) {
    const { chartToolbarOptions } = this.getToolbarOptionsAndPanels(chartController);
    return ["chartMenu", ...CHART_TOOLBAR_ALLOW_LIST].some((option) => chartToolbarOptions.includes(option));
  }
  doChartToolPanelsExist(chartController) {
    const { panels } = this.getToolbarOptionsAndPanels(chartController);
    return panels.length > 0;
  }
};
__decorateClass4([
  Autowired39("chartService")
], ChartMenuService.prototype, "chartService", 2);
__decorateClass4([
  Autowired39("advancedSettingsMenuFactory")
], ChartMenuService.prototype, "advancedSettingsMenuFactory", 2);
ChartMenuService = __decorateClass4([
  Bean24("chartMenuService")
], ChartMenuService);
var CATEGORY_LABEL_KEY = "AG-GRID-DEFAULT-LABEL-KEY";
function createCategoryHierarchy(data, categoryKeys) {
  const hierarchy = buildNestedHierarchy(data, getItemDepth, getItemCategoryLabel);
  return formatCategoryHierarchy(hierarchy);
  function getItemDepth(item) {
    return categoryKeys.length;
  }
  function getItemCategoryLabel(item, categoryIndex) {
    const categoryKey = categoryKeys[categoryIndex];
    const categoryValue = item[categoryKey];
    return getCategoryLabel(categoryValue);
  }
  function getCategoryLabel(value) {
    if (value == null)
      return null;
    return String(value);
  }
}
function createAutoGroupHierarchy(data, getItemLabels) {
  const hierarchy = buildNestedHierarchy(data, getItemDepth, getItemGroupLabel);
  return formatCategoryHierarchy(hierarchy);
  function getItemDepth(item) {
    var _a, _b;
    return (_b = (_a = getItemLabels(item)) == null ? void 0 : _a.length) != null ? _b : 0;
  }
  function getItemGroupLabel(item, groupIndex) {
    const labels = getItemLabels(item);
    if (!labels)
      return null;
    const labelIndex = labels.length - 1 - groupIndex;
    return labels[labelIndex];
  }
}
function formatCategoryHierarchy(hierarchy, key = null, isChild) {
  const { depth, rootValues, value, children: inputChildren } = hierarchy;
  if (rootValues) {
    return rootValues.map((item) => __spreadValues3({ [CATEGORY_LABEL_KEY]: key }, item));
  } else if (depth === 0) {
    return [__spreadValues3({ [CATEGORY_LABEL_KEY]: key }, value)];
  }
  const children = [];
  for (const [childKey, childHierarchy] of inputChildren.entries()) {
    children.push(...formatCategoryHierarchy(childHierarchy, childKey, true));
  }
  return isChild ? [__spreadValues3({
    [CATEGORY_LABEL_KEY]: key,
    children
  }, value != null ? value : {})] : children;
}
function buildNestedHierarchy(data, getItemDepth, getItemGroupKey) {
  const hierarchy = { depth: 0, children: /* @__PURE__ */ new Map() };
  data.forEach((item) => {
    const itemDepth = getItemDepth(item);
    createNestedItemHierarchy(item, itemDepth, getItemGroupKey, 0, hierarchy);
  });
  return hierarchy;
  function createNestedItemHierarchy(item, itemDepth, getItemGroupKey2, currentDepth, hierarchy2) {
    if (currentDepth === itemDepth) {
      if (currentDepth === 0) {
        if (!hierarchy2.rootValues) {
          hierarchy2.rootValues = [];
        }
        hierarchy2.rootValues.push(item);
      } else {
        hierarchy2.value = item;
      }
      return hierarchy2;
    } else {
      const key = getItemGroupKey2(item, currentDepth);
      const existingChildHierarchy = hierarchy2.children.get(key);
      const childHierarchy = createNestedItemHierarchy(
        item,
        itemDepth,
        getItemGroupKey2,
        currentDepth + 1,
        existingChildHierarchy || { depth: 0, children: /* @__PURE__ */ new Map() }
      );
      hierarchy2.children.set(key, childHierarchy);
      hierarchy2.depth = Math.max(1 + childHierarchy.depth, hierarchy2.depth);
      return hierarchy2;
    }
  }
}
var HierarchicalChartProxy = class extends ChartProxy {
  constructor(chartProxyParams) {
    super(chartProxyParams);
  }
  getUpdateOptions(params, commonChartOptions) {
    const { fields } = params;
    const [sizeField, colorField] = fields;
    return __spreadProps3(__spreadValues3({}, commonChartOptions), {
      series: this.getSeries(sizeField, colorField),
      data: this.getData(params, sizeField, colorField)
    });
  }
  getSeriesChartThemeDefaults() {
    return {
      gradientLegend: {
        gradient: {
          preferredLength: 200
        }
      }
    };
  }
  getSeries(sizeField, colorField) {
    var _a, _b;
    return [
      {
        type: this.standaloneChartType,
        labelKey: CATEGORY_LABEL_KEY,
        // Size and color fields are inferred from the range data
        sizeKey: sizeField == null ? void 0 : sizeField.colId,
        sizeName: (_a = sizeField == null ? void 0 : sizeField.displayName) != null ? _a : void 0,
        colorKey: colorField == null ? void 0 : colorField.colId,
        colorName: (_b = colorField == null ? void 0 : colorField.displayName) != null ? _b : void 0
      }
    ];
  }
  getData(params, sizeField, colorField) {
    var _a;
    const { categories, data, groupData, grouping: isGrouped } = params;
    if (isGrouped) {
      const processedData = colorField ? data.concat(
        (_a = groupData == null ? void 0 : groupData.map((groupDatum) => {
          const newDatum = __spreadValues3({}, groupDatum);
          delete newDatum[sizeField.colId];
          return newDatum;
        })) != null ? _a : []
      ) : data;
      return createAutoGroupHierarchy(processedData, (item) => {
        var _a2, _b;
        return (_b = (_a2 = item[GROUP_AUTO_COLUMN_ID]) == null ? void 0 : _a2.labels) != null ? _b : null;
      });
    } else {
      const categoryKeys = categories.map(({ id }) => id);
      return createCategoryHierarchy(data, categoryKeys);
    }
  }
};
var _GridChartComp = class _GridChartComp2 extends Component36 {
  constructor(params) {
    super(_GridChartComp2.TEMPLATE);
    this.params = params;
  }
  init() {
    const modelParams = __spreadProps3(__spreadValues3({}, this.params), {
      chartType: getCanonicalChartType(this.params.chartType),
      chartThemeName: this.getThemeName()
    });
    const isRtl = this.gos.get("enableRtl");
    this.addCssClass(isRtl ? "ag-rtl" : "ag-ltr");
    const model = this.createBean(new ChartDataModel(modelParams));
    this.chartController = this.createManagedBean(new ChartController(model));
    this.chartOptionsService = this.createManagedBean(new ChartOptionsService(this.chartController));
    this.validateCustomThemes();
    this.createChart();
    if (this.params.insideDialog) {
      this.addDialog();
    }
    this.addMenu();
    this.addTitleEditComp();
    this.addManagedListener(this.getGui(), "focusin", this.setActiveChartCellRange.bind(this));
    this.addManagedListener(this.chartController, ChartController.EVENT_CHART_MODEL_UPDATE, this.update.bind(this));
    this.addManagedPropertyListeners(["chartThemeOverrides", "chartThemes"], this.reactivePropertyUpdate.bind(this));
    this.update();
    this.raiseChartCreatedEvent();
  }
  createChart() {
    var _a;
    let chartInstance = void 0;
    if (this.chartProxy) {
      chartInstance = this.chartProxy.destroy({ keepChartInstance: true });
    }
    const crossFilterCallback = (event, reset) => {
      const ctx = this.params.crossFilteringContext;
      ctx.lastSelectedChartId = reset ? "" : this.chartController.getChartId();
      if (reset) {
        this.params.crossFilteringResetCallback();
      }
      this.crossFilterService.filter(event, reset);
    };
    const chartType = this.chartController.getChartType();
    const chartProxyParams = {
      chartType,
      chartInstance,
      getChartThemeName: this.getChartThemeName.bind(this),
      getChartThemes: this.getChartThemes.bind(this),
      customChartThemes: this.gos.get("customChartThemes"),
      getGridOptionsChartThemeOverrides: () => this.getGridOptionsChartThemeOverrides(),
      getExtraPaddingDirections: () => {
        var _a2, _b;
        return (_b = (_a2 = this.chartMenu) == null ? void 0 : _a2.getExtraPaddingDirections()) != null ? _b : [];
      },
      apiChartThemeOverrides: this.params.chartThemeOverrides,
      crossFiltering: (_a = this.params.crossFiltering) != null ? _a : false,
      crossFilterCallback,
      parentElement: this.eChart,
      grouping: this.chartController.isGrouping(),
      chartThemeToRestore: this.params.chartThemeName,
      chartOptionsToRestore: this.params.chartOptionsToRestore,
      chartPaletteToRestore: this.params.chartPaletteToRestore,
      seriesChartTypes: this.chartController.getSeriesChartTypes(),
      translate: (toTranslate) => this.chartTranslationService.translate(toTranslate)
    };
    this.params.chartOptionsToRestore = void 0;
    this.chartType = chartType;
    this.chartProxy = _GridChartComp2.createChartProxy(chartProxyParams);
    if (!this.chartProxy) {
      console.warn("AG Grid: invalid chart type supplied: ", chartProxyParams.chartType);
      return;
    }
    const canvas = this.eChart.querySelector("canvas");
    if (canvas) {
      canvas.classList.add("ag-charts-canvas");
    }
    this.chartController.setChartProxy(this.chartProxy);
    this.createMenuContext();
    this.titleEdit && this.titleEdit.refreshTitle(this.chartMenuContext);
  }
  createMenuContext() {
    if (this.chartMenuContext) {
      return;
    }
    const chartMenuParamsFactory = this.createManagedBean(new ChartMenuParamsFactory(this.chartOptionsService.getChartThemeOverridesProxy()));
    const chartAxisMenuParamsFactory = this.createManagedBean(new ChartMenuParamsFactory(this.chartOptionsService.getAxisThemeOverridesProxy()));
    this.chartMenuContext = {
      chartController: this.chartController,
      chartOptionsService: this.chartOptionsService,
      chartMenuParamsFactory,
      chartAxisMenuParamsFactory
    };
  }
  getChartThemeName() {
    return this.chartController.getChartThemeName();
  }
  getChartThemes() {
    return this.chartController.getThemeNames();
  }
  getGridOptionsChartThemeOverrides() {
    return this.gos.get("chartThemeOverrides");
  }
  static createChartProxy(chartProxyParams) {
    switch (chartProxyParams.chartType) {
      case "column":
      case "bar":
      case "groupedColumn":
      case "stackedColumn":
      case "normalizedColumn":
      case "groupedBar":
      case "stackedBar":
      case "normalizedBar":
        return new BarChartProxy(chartProxyParams);
      case "pie":
      case "donut":
      case "doughnut":
        return new PieChartProxy(chartProxyParams);
      case "area":
      case "stackedArea":
      case "normalizedArea":
        return new AreaChartProxy(chartProxyParams);
      case "line":
        return new LineChartProxy(chartProxyParams);
      case "scatter":
      case "bubble":
        return new ScatterChartProxy(chartProxyParams);
      case "histogram":
        return new HistogramChartProxy(chartProxyParams);
      case "radarLine":
      case "radarArea":
      case "nightingale":
      case "radialColumn":
      case "radialBar":
        return new PolarChartProxy(chartProxyParams);
      case "rangeBar":
      case "rangeArea":
        return new RangeChartProxy(chartProxyParams);
      case "boxPlot":
        return new BoxPlotChartProxy(chartProxyParams);
      case "treemap":
      case "sunburst":
        return new HierarchicalChartProxy(chartProxyParams);
      case "heatmap":
        return new HeatmapChartProxy(chartProxyParams);
      case "waterfall":
        return new WaterfallChartProxy(chartProxyParams);
      case "columnLineCombo":
      case "areaColumnCombo":
      case "customCombo":
        return new ComboChartProxy(chartProxyParams);
      default:
        throw `AG Grid: Unable to create chart as an invalid chartType = '${chartProxyParams.chartType}' was supplied.`;
    }
  }
  addDialog() {
    const title = this.chartTranslationService.translate(this.params.pivotChart ? "pivotChartTitle" : "rangeChartTitle");
    const { width, height } = this.getBestDialogSize();
    this.chartDialog = new AgDialog2({
      resizable: true,
      movable: true,
      maximizable: true,
      title,
      width,
      height,
      component: this,
      centered: true,
      closable: true
    });
    this.getContext().createBean(this.chartDialog);
    this.chartDialog.addEventListener(AgDialog2.EVENT_DESTROYED, () => {
      this.destroy();
      this.chartMenuService.hideAdvancedSettings();
    });
  }
  getBestDialogSize() {
    const popupParent = this.popupService.getPopupParent();
    const maxWidth = _33.getAbsoluteWidth(popupParent) * 0.75;
    const maxHeight = _33.getAbsoluteHeight(popupParent) * 0.75;
    const ratio = 0.553;
    const chart = this.chartProxy.getChart();
    let width = this.params.insideDialog ? 850 : chart.width;
    let height = this.params.insideDialog ? 470 : chart.height;
    if (width > maxWidth || height > maxHeight) {
      width = Math.min(width, maxWidth);
      height = Math.round(width * ratio);
      if (height > maxHeight) {
        height = maxHeight;
        width = Math.min(width, Math.round(height / ratio));
      }
    }
    return { width, height };
  }
  addMenu() {
    if (!this.params.crossFiltering) {
      this.chartMenu = this.createBean(new ChartMenu(
        this.eChartContainer,
        this.eMenuContainer,
        this.chartMenuContext
      ));
      this.eChartContainer.appendChild(this.chartMenu.getGui());
    }
  }
  addTitleEditComp() {
    this.titleEdit = this.createBean(new TitleEdit(this.chartMenu));
    this.eTitleEditContainer.appendChild(this.titleEdit.getGui());
    if (this.chartProxy) {
      this.titleEdit.refreshTitle(this.chartMenuContext);
    }
  }
  update(params) {
    if (params == null ? void 0 : params.chartId) {
      const validUpdate = this.chartController.update(params);
      if (!validUpdate) {
        return;
      }
    }
    const updatedChartType = this.chartTypeChanged(params);
    const persistedThemeOverrides = updatedChartType || this.chartEmpty ? ((updatedChartType2) => {
      const currentChartType = this.chartType;
      const targetChartType = updatedChartType2;
      const existingChartInstance = this.chartProxy.getChart();
      const existingChartOptions = existingChartInstance == null ? void 0 : existingChartInstance.getOptions();
      const existingAxes = existingChartInstance == null ? void 0 : existingChartInstance.axes;
      return this.chartOptionsService.getPersistedChartThemeOverrides(
        existingChartOptions,
        existingAxes,
        currentChartType,
        targetChartType != null ? targetChartType : currentChartType
      );
    })(updatedChartType) : void 0;
    if (updatedChartType)
      this.createChart();
    const updatedThemeOverrides = persistedThemeOverrides && (params == null ? void 0 : params.chartThemeOverrides) ? deepMerge(persistedThemeOverrides, params.chartThemeOverrides) : persistedThemeOverrides || (params == null ? void 0 : params.chartThemeOverrides);
    this.updateChart(updatedThemeOverrides);
    if (params == null ? void 0 : params.chartId) {
      this.chartProxy.getChart().waitForUpdate().then(() => {
        this.chartController.raiseChartApiUpdateEvent();
      });
    }
  }
  updateChart(updatedOverrides) {
    const { chartProxy } = this;
    const selectedCols = this.chartController.getSelectedValueColState();
    const data = this.chartController.getChartData();
    const chartEmpty = this.handleEmptyChart(data, selectedCols.length);
    this.chartEmpty = chartEmpty;
    if (chartEmpty) {
      if (updatedOverrides)
        this.chartController.updateThemeOverrides(updatedOverrides);
      return;
    }
    let chartUpdateParams = this.chartController.getChartUpdateParams(updatedOverrides);
    chartProxy.update(chartUpdateParams);
    this.chartProxy.getChart().waitForUpdate().then(() => {
      this.chartController.raiseChartUpdatedEvent();
    });
    this.titleEdit.refreshTitle(this.chartMenuContext);
  }
  chartTypeChanged(updateParams) {
    const [currentType, updatedChartType] = [this.chartController.getChartType(), updateParams == null ? void 0 : updateParams.chartType];
    const targetChartType = updatedChartType ? getCanonicalChartType(updatedChartType) : void 0;
    if (this.chartType !== currentType)
      return targetChartType != null ? targetChartType : currentType;
    if (targetChartType && currentType !== targetChartType)
      return targetChartType;
    return null;
  }
  getChartModel() {
    return this.chartController.getChartModel();
  }
  getChartImageDataURL(fileFormat) {
    return this.chartProxy.getChartImageDataURL(fileFormat);
  }
  handleEmptyChart(data, numFields) {
    const pivotModeDisabled = this.chartController.isPivotChart() && !this.chartController.isPivotMode();
    const chartType = this.chartController.getChartType();
    let minFieldsRequired = 1;
    if (this.chartController.isActiveXYChart()) {
      minFieldsRequired = chartType === "bubble" ? 3 : 2;
    } else if (isHierarchical(getSeriesType(chartType))) {
      minFieldsRequired = 0;
    }
    const isEmptyChart = numFields < minFieldsRequired || data.length === 0;
    if (this.eChart) {
      const isEmpty = pivotModeDisabled || isEmptyChart;
      _33.setDisplayed(this.eChart, !isEmpty);
      _33.setDisplayed(this.eEmpty, isEmpty);
    }
    if (pivotModeDisabled) {
      this.eEmpty.innerText = this.chartTranslationService.translate("pivotChartRequiresPivotMode");
      return true;
    }
    if (isEmptyChart) {
      this.eEmpty.innerText = this.chartTranslationService.translate("noDataToChart");
      return true;
    }
    return false;
  }
  downloadChart(dimensions, fileName, fileFormat) {
    this.chartProxy.downloadChart(dimensions, fileName, fileFormat);
  }
  openChartToolPanel(panel) {
    const menuPanel = panel ? CHART_TOOL_PANEL_MENU_OPTIONS[panel] : panel;
    this.chartMenu.showMenu({ panel: menuPanel });
  }
  closeChartToolPanel() {
    this.chartMenu.hideMenu();
  }
  getChartId() {
    return this.chartController.getChartId();
  }
  getUnderlyingChart() {
    return this.chartProxy.getChartRef();
  }
  crossFilteringReset() {
    this.chartProxy.crossFilteringReset();
  }
  setActiveChartCellRange(focusEvent) {
    if (this.getGui().contains(focusEvent.relatedTarget)) {
      return;
    }
    this.chartController.setChartRange(true);
    this.focusService.clearFocusedCell();
  }
  getThemeName() {
    const availableChartThemes = this.gos.get("chartThemes") || DEFAULT_THEMES;
    if (availableChartThemes.length === 0) {
      throw new Error("Cannot create chart: no chart themes available.");
    }
    const { chartThemeName } = this.params;
    return _33.includes(availableChartThemes, chartThemeName) ? chartThemeName : availableChartThemes[0];
  }
  getAllKeysInObjects(objects) {
    const allValues = {};
    objects.filter((obj) => obj != null).forEach((obj) => {
      Object.keys(obj).forEach((key) => allValues[key] = null);
    });
    return Object.keys(allValues);
  }
  validateCustomThemes() {
    const suppliedThemes = this.getChartThemes();
    const customChartThemes = this.gos.get("customChartThemes");
    if (customChartThemes) {
      this.getAllKeysInObjects([customChartThemes]).forEach((customThemeName) => {
        if (!_33.includes(suppliedThemes, customThemeName)) {
          console.warn("AG Grid: a custom chart theme with the name '" + customThemeName + "' has been supplied but not added to the 'chartThemes' list");
        }
      });
    }
  }
  reactivePropertyUpdate() {
    this.chartController.setChartThemeName(this.getThemeName(), true);
    const chartId = this.getChartId();
    const modelType = this.chartController.isCrossFilterChart() ? "crossFilter" : this.getChartModel().modelType;
    const chartThemeOverrides = this.gos.get("chartThemeOverrides") || {};
    this.update({
      type: `${modelType}ChartUpdate`,
      chartId,
      chartThemeOverrides
    });
  }
  raiseChartCreatedEvent() {
    const event = {
      type: Events10.EVENT_CHART_CREATED,
      chartId: this.chartController.getChartId()
    };
    this.chartProxy.getChart().waitForUpdate().then(() => {
      this.eventService.dispatchEvent(event);
    });
  }
  raiseChartDestroyedEvent() {
    const event = {
      type: Events10.EVENT_CHART_DESTROYED,
      chartId: this.chartController.getChartId()
    };
    this.eventService.dispatchEvent(event);
  }
  destroy() {
    var _a;
    super.destroy();
    if (this.chartProxy) {
      this.chartProxy.destroy();
    }
    this.destroyBean(this.chartMenu);
    this.destroyBean(this.titleEdit);
    if (this.chartDialog && this.chartDialog.isAlive()) {
      this.destroyBean(this.chartDialog);
    }
    (_a = this.onDestroyColorSchemeChangeListener) == null ? void 0 : _a.call(this);
    const eGui = this.getGui();
    _33.clearElement(eGui);
    _33.removeFromParent(eGui);
    this.raiseChartDestroyedEvent();
  }
};
_GridChartComp.TEMPLATE = /* html */
`<div class="ag-chart" tabindex="-1">
            <div ref="eChartContainer" tabindex="-1" class="ag-chart-components-wrapper">
                <div ref="eChart" class="ag-chart-canvas-wrapper"></div>
                <div ref="eEmpty" class="ag-chart-empty-text ag-unselectable"></div>
            </div>
            <div ref="eTitleEditContainer"></div>
            <div ref="eMenuContainer" class="ag-chart-docked-container" style="min-width: 0px;"></div>
        </div>`;
__decorateClass4([
  RefSelector12("eChart")
], _GridChartComp.prototype, "eChart", 2);
__decorateClass4([
  RefSelector12("eChartContainer")
], _GridChartComp.prototype, "eChartContainer", 2);
__decorateClass4([
  RefSelector12("eMenuContainer")
], _GridChartComp.prototype, "eMenuContainer", 2);
__decorateClass4([
  RefSelector12("eEmpty")
], _GridChartComp.prototype, "eEmpty", 2);
__decorateClass4([
  RefSelector12("eTitleEditContainer")
], _GridChartComp.prototype, "eTitleEditContainer", 2);
__decorateClass4([
  Autowired40("chartCrossFilterService")
], _GridChartComp.prototype, "crossFilterService", 2);
__decorateClass4([
  Autowired40("chartTranslationService")
], _GridChartComp.prototype, "chartTranslationService", 2);
__decorateClass4([
  Autowired40("chartMenuService")
], _GridChartComp.prototype, "chartMenuService", 2);
__decorateClass4([
  Autowired40("focusService")
], _GridChartComp.prototype, "focusService", 2);
__decorateClass4([
  Autowired40("popupService")
], _GridChartComp.prototype, "popupService", 2);
__decorateClass4([
  PostConstruct42
], _GridChartComp.prototype, "init", 1);
var GridChartComp = _GridChartComp;
var VERSION4 = "31.3.2";
var DEBUG = false;
function upgradeChartModel(model) {
  const originalVersion = model.version;
  if (model.version == null) {
    model.version = heuristicVersionDetection(model);
  }
  model = migrateIfBefore("23.0.0", model, migrateV23);
  model = migrateIfBefore("24.0.0", model, migrateV24);
  model = migrateIfBefore("25.1.0", model, migrateV25_1);
  model = migrateIfBefore("26.0.0", model, migrateV26);
  model = migrateIfBefore("26.1.0", model, migrateV26_1);
  model = migrateIfBefore("26.2.0", model, migrateV26_2);
  model = migrateIfBefore("28.0.0", model, migrateV28);
  model = migrateIfBefore("28.2.0", model, migrateV28_2);
  model = migrateIfBefore("29.0.0", model, migrateV29);
  model = migrateIfBefore("29.1.0", model, migrateV29_1);
  model = migrateIfBefore("29.2.0", model, migrateV29_2);
  model = migrateIfBefore("30.0.0", model, migrateV30);
  model = migrateIfBefore("31.0.0", model, migrateV31);
  model = cleanup(model);
  model = migrateIfBefore(VERSION4, model, (m) => m);
  if (DEBUG && originalVersion !== model.version) {
    console.log("AG Grid: ChartModel migration complete", { model });
  }
  return model;
}
function migrateV23(model) {
  model = jsonRename("chartOptions.legend.item.marker.type", "shape", model);
  model = jsonRename("chartOptions.seriesDefaults.marker.type", "shape", model);
  model = jsonRename("chartOptions.legend.padding", "spacing", model);
  return model;
}
function migrateV24(model) {
  var _d;
  model = jsonDelete("chartOptions.seriesDefaults.marker.minSize", model);
  const _a = model, {
    chartType,
    chartPalette,
    chartOptions: _b
  } = _a, _c = _b, { xAxis, yAxis } = _c, chartOptions = __objRest(_c, ["xAxis", "yAxis"]), chartModel = __objRest(_a, [
    "chartType",
    "chartPalette",
    // Migrate.
    "chartOptions"
  ]);
  const axesTypes = getLegacyAxisType(chartType);
  const axes = axesTypes == null ? void 0 : axesTypes.map((type, i) => __spreadValues3({
    type
  }, i === 0 ? xAxis : yAxis));
  const LEGACY_PALETTES = {
    borneo: "ag-default",
    material: "ag-material",
    bright: "ag-vivid"
  };
  return __spreadValues3({
    chartType,
    chartThemeName: (_d = LEGACY_PALETTES[chartPalette]) != null ? _d : "ag-default",
    chartOptions: __spreadProps3(__spreadValues3({}, chartOptions), {
      axes,
      xAxis,
      yAxis
    })
  }, chartModel);
}
function migrateV25_1(model) {
  model = jsonRename("chartOptions.seriesDefaults.label.minRequiredAngle", "minAngle", model);
  return model;
}
function migrateV26(model) {
  const highlightOptUpdate = (_a) => {
    var _b = _a, { dimOpacity } = _b, opts = __objRest(_b, ["dimOpacity"]);
    return __spreadValues3(__spreadValues3({}, opts), dimOpacity != null ? { series: { dimOpacity } } : {});
  };
  model = jsonMutate("chartOptions.seriesDefaults.highlightStyle", model, highlightOptUpdate);
  model = jsonDelete("chart", model);
  model = jsonDelete("chartOptions.seriesDefaults.tooltipClass", model);
  model = jsonDelete("chartOptions.seriesDefaults.tooltipTracking", model);
  model = jsonDeleteDefault("chartOptions.axes[].label.rotation", 0, model);
  model = jsonDeleteDefault("chartOptions.axes[].label.rotation", 335, model);
  return model;
}
function migrateV26_1(model) {
  const highlightOptUpdate = (_a) => {
    var _b = _a, { item, series } = _b, opts = __objRest(_b, ["item", "series"]);
    return __spreadValues3({
      item: __spreadValues3(__spreadValues3({}, opts), item)
    }, series ? { series } : {});
  };
  model = jsonMutate("chartOptions.seriesDefaults.highlightStyle", model, highlightOptUpdate);
  model = jsonMutate("chartOptions.series[].highlightStyle", model, highlightOptUpdate);
  return model;
}
function migrateV26_2(model) {
  model = jsonMove("chartOptions.seriesDefaults.fill.opacity", "chartOptions.seriesDefaults.fillOpacity", model);
  model = jsonMove("chartOptions.seriesDefaults.stroke.opacity", "chartOptions.seriesDefaults.strokeOpacity", model);
  model = jsonMove("chartOptions.seriesDefaults.stroke.width", "chartOptions.seriesDefaults.strokeWidth", model);
  model = jsonDelete("chartOptions.seriesDefaults.fill", model);
  model = jsonDelete("chartOptions.seriesDefaults.stroke", model);
  model = jsonDelete("chartOptions.seriesDefaults.callout.colors", model);
  model = jsonDelete("chartOptions.xAxis", model);
  model = jsonDelete("chartOptions.yAxis", model);
  const _a = model, {
    chartType: providedChartType,
    chartOptions: _b
  } = _a, _c = _b, { axes, series, seriesDefaults } = _c, otherChartOptions = __objRest(_c, ["axes", "series", "seriesDefaults"]), otherModelProps = __objRest(_a, [
    "chartType",
    "chartOptions"
  ]);
  const chartType = getCanonicalChartType(providedChartType);
  const seriesType = getSeriesType(chartType);
  const seriesTypes = [seriesType];
  const chartTypeMixin = {};
  if (!isPieChartSeries(seriesType)) {
    const minimalAxis = { top: {}, bottom: {}, left: {}, right: {} };
    const updatedAxes = axes.map((_d) => {
      var _e = _d, { type } = _e, axisProps = __objRest(_e, ["type"]);
      return {
        [type]: __spreadValues3(__spreadValues3({}, minimalAxis), axisProps)
      };
    }).reduce(merge, {});
    ALL_AXIS_TYPES.filter((v) => updatedAxes[v] == null).forEach((v) => {
      updatedAxes[v] = __spreadValues3({}, minimalAxis);
    });
    chartTypeMixin.axes = updatedAxes;
  }
  const updatedChartOptions = seriesTypes.map((t) => ({
    [t]: __spreadValues3(__spreadProps3(__spreadValues3({}, chartTypeMixin), {
      series: seriesDefaults
    }), otherChartOptions)
  })).reduce(merge, {});
  model = __spreadProps3(__spreadValues3({}, otherModelProps), {
    chartType,
    chartOptions: updatedChartOptions
  });
  return model;
}
function migrateV28(model) {
  model = jsonDelete("chartOptions.*.title.padding", model);
  model = jsonDelete("chartOptions.*.subtitle.padding", model);
  model = jsonDelete("chartOptions.*.axes.*.title.padding", model);
  model = jsonBackfill("chartOptions.*.axes.*.title.enabled", false, model);
  return model;
}
function migrateV28_2(model) {
  model = jsonRename("chartOptions.pie.series.callout", "calloutLine", model);
  model = jsonRename("chartOptions.pie.series.label", "calloutLabel", model);
  model = jsonRename("chartOptions.pie.series.labelKey", "sectorLabelKey", model);
  model = jsonRename("chartOptions.pie.series.labelName", "sectorLabelName", model);
  model = jsonRename("chartOptions.donut.series.callout", "calloutLine", model);
  model = jsonRename("chartOptions.donut.series.label", "calloutLabel", model);
  model = jsonRename("chartOptions.donut.series.labelKey", "sectorLabelKey", model);
  model = jsonRename("chartOptions.donut.series.labelName", "sectorLabelName", model);
  return model;
}
function migrateV29(model) {
  model = jsonMoveIfMissing("chartOptions.scatter.series.fill", "chartOptions.scatter.series.marker.fill", model);
  model = jsonMoveIfMissing(
    "chartOptions.scatter.series.fillOpacity",
    "chartOptions.scatter.series.marker.fillOpacity",
    model
  );
  model = jsonMoveIfMissing("chartOptions.scatter.series.stroke", "chartOptions.scatter.series.marker.stroke", model);
  model = jsonMoveIfMissing(
    "chartOptions.scatter.series.strokeOpacity",
    "chartOptions.scatter.series.marker.strokeOpacity",
    model
  );
  model = jsonMoveIfMissing(
    "chartOptions.scatter.series.strokeWidth",
    "chartOptions.scatter.series.marker.strokeWidth",
    model
  );
  model = jsonMove("chartOptions.scatter.series.paired", "chartOptions.scatter.paired", model);
  return model;
}
function migrateV29_1(model) {
  model = jsonDelete("chartOptions.axes[].tick.count", model);
  return model;
}
function migrateV29_2(model) {
  const tooltipOptUpdate = (_a) => {
    var _b = _a, { tracking } = _b, opts = __objRest(_b, ["tracking"]);
    var _a2, _b2, _c, _d;
    const output = __spreadValues3({}, opts);
    if (tracking === false) {
      (_a2 = output.position) != null ? _a2 : output.position = { type: "pointer" };
      (_b2 = output.range) != null ? _b2 : output.range = "nearest";
    } else if (tracking === true) {
      (_c = output.position) != null ? _c : output.position = { type: "node" };
      (_d = output.range) != null ? _d : output.range = "nearest";
    }
    return output;
  };
  model = jsonMutate("chartOptions.*.tooltip", model, tooltipOptUpdate);
  return model;
}
function migrateV30(model) {
  model = jsonRename("chartOptions.pie.series.labelKey", "sectorLabelKey", model);
  model = jsonRename("chartOptions.pie.series.labelName", "sectorLabelName", model);
  model = migrateV29_1(model);
  model = migrateV29_2(model);
  model = jsonDelete("chartOptions.*.series.flipXY", model);
  model = jsonAdd("chartOptions.common.legend.enabled", true, model);
  model = jsonBackfill("chartOptions.common.legend.position", "right", model);
  return model;
}
function migrateV31(model) {
  const V30_LEGACY_PALETTES = {
    "ag-pastel": "ag-sheets",
    "ag-solar": "ag-polychroma"
  };
  const updatedModel = jsonRename("chartOptions.column", "bar", model);
  const chartThemeName = V30_LEGACY_PALETTES[updatedModel.chartThemeName] || updatedModel.chartThemeName;
  return __spreadProps3(__spreadValues3({}, updatedModel), {
    chartThemeName
  });
}
function cleanup(model) {
  model = jsonDelete("chartOptions.*.width", model);
  model = jsonDelete("chartOptions.*.height", model);
  model = jsonBackfill("chartOptions.*.axes.category.label.autoRotate", true, model);
  return model;
}
function heuristicVersionDetection(model) {
  var _a, _b;
  const modelAny = model;
  if (model.version != null) {
    return model.version;
  }
  const hasKey = (obj, ...keys) => {
    return Object.keys(obj || {}).some((k) => keys.includes(k));
  };
  const chartOptions = modelAny.chartOptions;
  const seriesOptions = hasKey(chartOptions, "seriesDefaults") ? chartOptions == null ? void 0 : chartOptions.seriesDefaults : chartOptions == null ? void 0 : chartOptions[Object.keys(chartOptions)[0]];
  const hints = {
    "27.0.0": hasKey(modelAny, "seriesChartTypes"),
    "26.2.0": !hasKey(chartOptions, "seriesDefaults"),
    "26.1.0": hasKey(seriesOptions == null ? void 0 : seriesOptions.highlightStyle, "item"),
    "26.0.0": hasKey(seriesOptions == null ? void 0 : seriesOptions.highlightStyle, "series"),
    // '26.0.0': modelAny.chart === undefined,
    "25.1.0": hasKey(seriesOptions == null ? void 0 : seriesOptions.label, "minAngle"),
    "25.0.0": hasKey(modelAny, "modelType", "aggFunc", "unlinkChart", "suppressChartRanges") || hasKey(seriesOptions, "lineDash", "lineDashOffset"),
    "24.0.0": hasKey(modelAny, "chartThemeName", "chart") || hasKey(chartOptions, "series"),
    "23.2.0": hasKey(chartOptions, "navigator"),
    "23.0.0": hasKey((_b = (_a = chartOptions == null ? void 0 : chartOptions.legend) == null ? void 0 : _a.item) == null ? void 0 : _b.marker, "shape"),
    "22.1.0": hasKey(modelAny, "chartPalette", "chartType")
  };
  const defaultVersion = "27.1.0";
  const matchingHints = Object.entries(hints).filter(([_382, match]) => match);
  if (DEBUG)
    console.log("AG Grid: ChartModel migration", { heuristicVersionCandidates: matchingHints });
  const [heuristicVersion = defaultVersion] = matchingHints[0];
  if (DEBUG)
    console.log("AG Grid: ChartModel migration", { heuristicVersion });
  return heuristicVersion;
}
function migrateIfBefore(maxVersion, model, migration) {
  if (versionNumber(maxVersion) > versionNumber(model.version)) {
    if (DEBUG)
      console.log("AG Grid: ChartModel migration", { migratingTo: maxVersion });
    const result = migration(model);
    result.version = maxVersion;
    if (DEBUG)
      console.log("AG Grid: ChartModel migration", { migratedTo: maxVersion, result });
    return result;
  }
  return model;
}
function versionParts(version) {
  const split = typeof version === "string" ? version.split(".").map((v) => Number(v)) : [];
  if (split.length !== 3 || split.some((v) => isNaN(v))) {
    throw new Error("AG Grid - Illegal version string: " + version);
  }
  return {
    major: split[0],
    minor: split[1],
    patch: split[2]
  };
}
function versionNumber(version) {
  const { major, minor, patch } = versionParts(version);
  return major * 1e4 + minor * 100 + patch;
}
function jsonDeleteDefault(path, defaultValue, json) {
  return jsonMutateProperty(path, true, json, (parent, prop) => {
    if (parent[prop] === defaultValue) {
      delete parent[prop];
    }
  });
}
function jsonBackfill(path, defaultValue, json) {
  return jsonMutateProperty(path, false, json, (parent, prop) => {
    if (parent[prop] == null) {
      parent[prop] = defaultValue;
    }
  });
}
function jsonAdd(path, value, json) {
  var _a;
  if (typeof path === "string") {
    path = path.split(".");
  }
  const nextPath = path[0];
  if (path.length > 1) {
    json[nextPath] = jsonAdd(path.slice(1), value, (_a = json[nextPath]) != null ? _a : {});
  }
  const hasProperty = Object.keys(json).includes(nextPath);
  if (!hasProperty) {
    json[nextPath] = value;
  }
  return json;
}
function jsonMove(from, to, json) {
  let valueToMove = void 0;
  let valueFound = false;
  json = jsonMutateProperty(from, true, json, (parent, prop) => {
    valueFound = true;
    valueToMove = parent[prop];
    delete parent[prop];
  });
  if (!valueFound) {
    return json;
  }
  return jsonMutateProperty(to, false, json, (parent, prop) => {
    parent[prop] = valueToMove;
  });
}
function jsonMoveIfMissing(from, to, json) {
  let valueToMove = void 0;
  let valueFound = false;
  json = jsonMutateProperty(from, true, json, (parent, prop) => {
    valueFound = true;
    valueToMove = parent[prop];
    delete parent[prop];
  });
  if (!valueFound) {
    return json;
  }
  return jsonMutateProperty(to, false, json, (parent, prop) => {
    if (parent[prop] === void 0) {
      parent[prop] = valueToMove;
    }
  });
}
function jsonRename(path, renameTo, json) {
  return jsonMutateProperty(path, true, json, (parent, prop) => {
    parent[renameTo] = parent[prop];
    delete parent[prop];
  });
}
function jsonDelete(path, json) {
  return jsonMutateProperty(path, true, json, (parent, prop) => delete parent[prop]);
}
function jsonMutateProperty(path, skipMissing, json, mutator) {
  const pathElements = path instanceof Array ? path : path.split(".");
  const parentPathElements = pathElements.slice(0, pathElements.length - 1);
  const targetName = pathElements[pathElements.length - 1];
  return jsonMutate(parentPathElements, json, (parent) => {
    const hasProperty = Object.keys(parent).includes(targetName);
    if (skipMissing && !hasProperty) {
      return parent;
    }
    const result = __spreadValues3({}, parent);
    mutator(result, targetName);
    return result;
  });
}
function jsonMutate(path, json, mutator) {
  const pathElements = path instanceof Array ? path : path.split(".");
  json = __spreadValues3({}, json);
  if (pathElements.length === 0) {
    return mutator(json);
  } else if (pathElements[0].startsWith("{")) {
    const pathOptions = pathElements[0].substring(1, pathElements[0].lastIndexOf("}")).split(",");
    for (const pathOption of pathOptions) {
      if (json[pathOption] != null) {
        json[pathOption] = jsonMutate(pathElements.slice(1), json[pathOption], mutator);
      }
    }
  } else if (pathElements[0].endsWith("[]")) {
    const arrayName = pathElements[0].substring(0, path[0].indexOf("["));
    if (json[arrayName] instanceof Array) {
      json[arrayName] = json[arrayName].map((v) => jsonMutate(pathElements.slice(1), v, mutator));
    }
  } else if (pathElements[0] === "*") {
    for (const jsonProp in json) {
      json[jsonProp] = jsonMutate(pathElements.slice(1), json[jsonProp], mutator);
    }
  } else if (json[pathElements[0]] != null) {
    json[pathElements[0]] = jsonMutate(pathElements.slice(1), json[pathElements[0]], mutator);
  }
  return json;
}
var merge = (r, n) => __spreadValues3(__spreadValues3({}, r), n);
var ChartService = class extends BeanStub10 {
  constructor() {
    super(...arguments);
    this.activeCharts = /* @__PURE__ */ new Set();
    this.activeChartComps = /* @__PURE__ */ new Set();
    this.crossFilteringContext = {
      lastSelectedChartId: ""
    };
    this.isEnterprise = () => _ModuleSupport5.enterpriseModule.isEnterprise;
  }
  updateChart(params) {
    if (this.activeChartComps.size === 0) {
      console.warn(`AG Grid - No active charts to update.`);
      return;
    }
    const chartComp = [...this.activeChartComps].find((chartComp2) => chartComp2.getChartId() === params.chartId);
    if (!chartComp) {
      console.warn(`AG Grid - Unable to update chart. No active chart found with ID: ${params.chartId}.`);
      return;
    }
    chartComp.update(params);
  }
  getChartModels() {
    const models = [];
    const versionedModel = (c) => {
      return __spreadProps3(__spreadValues3({}, c), { version: VERSION4 });
    };
    this.activeChartComps.forEach((c) => models.push(versionedModel(c.getChartModel())));
    return models;
  }
  getChartRef(chartId) {
    let chartRef;
    this.activeCharts.forEach((cr) => {
      if (cr.chartId === chartId) {
        chartRef = cr;
      }
    });
    return chartRef;
  }
  getChartComp(chartId) {
    let chartComp;
    this.activeChartComps.forEach((comp) => {
      if (comp.getChartId() === chartId) {
        chartComp = comp;
      }
    });
    return chartComp;
  }
  getChartImageDataURL(params) {
    let url;
    this.activeChartComps.forEach((c) => {
      if (c.getChartId() === params.chartId) {
        url = c.getChartImageDataURL(params.fileFormat);
      }
    });
    return url;
  }
  downloadChart(params) {
    const chartComp = Array.from(this.activeChartComps).find((c) => c.getChartId() === params.chartId);
    chartComp == null ? void 0 : chartComp.downloadChart(params.dimensions, params.fileName, params.fileFormat);
  }
  openChartToolPanel(params) {
    const chartComp = Array.from(this.activeChartComps).find((c) => c.getChartId() === params.chartId);
    chartComp == null ? void 0 : chartComp.openChartToolPanel(params.panel);
  }
  closeChartToolPanel(chartId) {
    const chartComp = Array.from(this.activeChartComps).find((c) => c.getChartId() === chartId);
    chartComp == null ? void 0 : chartComp.closeChartToolPanel();
  }
  createChartFromCurrentRange(chartType = "groupedColumn") {
    const cellRange = this.getSelectedRange();
    return this.createChart({ cellRange, chartType });
  }
  restoreChart(model, chartContainer) {
    if (!model) {
      console.warn("AG Grid - unable to restore chart as no chart model is provided");
      return;
    }
    if (model.version !== VERSION4) {
      model = upgradeChartModel(model);
    }
    let cellRange;
    let pivotChart;
    let suppressChartRanges;
    let chartPaletteToRestore;
    if (model.modelType === "pivot") {
      this.gos.updateGridOptions({ options: { pivotMode: true }, source: "pivotChart" });
      cellRange = this.createCellRange(void 0, true);
      pivotChart = true;
      suppressChartRanges = true;
    } else {
      cellRange = this.createCellRange(model.cellRange);
      chartPaletteToRestore = model.chartPalette;
    }
    if (!cellRange) {
      return;
    }
    return this.createChart(__spreadProps3(__spreadValues3({}, model), {
      cellRange,
      pivotChart,
      suppressChartRanges,
      chartContainer,
      chartOptionsToRestore: model.chartOptions,
      chartPaletteToRestore
    }));
  }
  createRangeChart(params) {
    const cellRange = this.createCellRange(params.cellRange);
    if (!cellRange) {
      return;
    }
    return this.createChart(__spreadProps3(__spreadValues3({}, params), {
      cellRange
    }));
  }
  createPivotChart(params) {
    this.gos.updateGridOptions({ options: { pivotMode: true }, source: "pivotChart" });
    const cellRange = this.createCellRange(void 0, true);
    if (!cellRange) {
      return;
    }
    return this.createChart(__spreadProps3(__spreadValues3({}, params), {
      cellRange,
      pivotChart: true,
      suppressChartRanges: true
    }));
  }
  createCrossFilterChart(params) {
    const cellRange = this.createCellRange(params.cellRange);
    if (!cellRange) {
      return;
    }
    const suppressChartRangesSupplied = typeof params.suppressChartRanges !== "undefined" && params.suppressChartRanges !== null;
    const suppressChartRanges = suppressChartRangesSupplied ? params.suppressChartRanges : true;
    return this.createChart(__spreadProps3(__spreadValues3({}, params), {
      cellRange,
      suppressChartRanges,
      crossFiltering: true
    }));
  }
  createChart(params) {
    const validationResult = ChartParamsValidator.validateCreateParams(params);
    if (!validationResult) {
      return void 0;
    }
    params = validationResult === true ? params : validationResult;
    const { chartType, chartContainer } = params;
    const createChartContainerFunc = this.gos.getCallback("createChartContainer");
    const gridChartParams = __spreadProps3(__spreadValues3({}, params), {
      chartId: this.generateId(),
      chartType: getCanonicalChartType(chartType),
      insideDialog: !(chartContainer || createChartContainerFunc),
      crossFilteringContext: this.crossFilteringContext,
      crossFilteringResetCallback: () => this.activeChartComps.forEach((c) => c.crossFilteringReset())
    });
    const chartComp = new GridChartComp(gridChartParams);
    this.context.createBean(chartComp);
    const chartRef = this.createChartRef(chartComp);
    if (chartContainer) {
      chartContainer.appendChild(chartComp.getGui());
      const theme = this.environment.getTheme();
      if (theme.el && !theme.el.contains(chartContainer)) {
        chartContainer.classList.add(theme.theme);
      }
    } else if (createChartContainerFunc) {
      createChartContainerFunc(chartRef);
    } else {
      chartComp.addEventListener(
        GridChartComp.EVENT_DESTROYED,
        () => {
          this.activeChartComps.delete(chartComp);
          this.activeCharts.delete(chartRef);
        }
      );
    }
    return chartRef;
  }
  createChartRef(chartComp) {
    const chartRef = {
      destroyChart: () => {
        if (this.activeCharts.has(chartRef)) {
          this.context.destroyBean(chartComp);
          this.activeChartComps.delete(chartComp);
          this.activeCharts.delete(chartRef);
        }
      },
      chartElement: chartComp.getGui(),
      chart: chartComp.getUnderlyingChart(),
      chartId: chartComp.getChartModel().chartId
    };
    this.activeCharts.add(chartRef);
    this.activeChartComps.add(chartComp);
    return chartRef;
  }
  getSelectedRange() {
    var _a, _b;
    const ranges = (_b = (_a = this.rangeService) == null ? void 0 : _a.getCellRanges()) != null ? _b : [];
    return ranges.length > 0 ? ranges[0] : { columns: [] };
  }
  generateId() {
    return `id-${Math.random().toString(36).substring(2, 18)}`;
  }
  createCellRange(cellRangeParams, allRange) {
    var _a;
    const rangeParams = allRange ? {
      rowStartIndex: null,
      rowStartPinned: void 0,
      rowEndIndex: null,
      rowEndPinned: void 0,
      columns: this.columnModel.getAllDisplayedColumns().map((col) => col.getColId())
    } : cellRangeParams;
    const cellRange = rangeParams && ((_a = this.rangeService) == null ? void 0 : _a.createPartialCellRangeFromRangeParams(rangeParams, true));
    if (!cellRange) {
      console.warn(`AG Grid - unable to create chart as ${allRange ? "there are no columns in the grid" : "no range is selected"}.`);
    }
    return cellRange;
  }
  destroyAllActiveCharts() {
    this.activeCharts.forEach((chart) => chart.destroyChart());
  }
};
ChartService.CHARTS_VERSION = CHARTS_VERSION;
__decorateClass4([
  Autowired41("columnModel")
], ChartService.prototype, "columnModel", 2);
__decorateClass4([
  Optional2("rangeService")
], ChartService.prototype, "rangeService", 2);
__decorateClass4([
  PreDestroy
], ChartService.prototype, "destroyAllActiveCharts", 1);
ChartService = __decorateClass4([
  Bean32("chartService")
], ChartService);
var ChartTranslationService = class extends BeanStub11 {
  translate(toTranslate) {
    const translate = this.localeService.getLocaleTextFunc();
    const defaultTranslation = ChartTranslationService.DEFAULT_TRANSLATIONS[toTranslate];
    return translate(toTranslate, defaultTranslation);
  }
};
ChartTranslationService.DEFAULT_TRANSLATIONS = {
  pivotChartTitle: "Pivot Chart",
  rangeChartTitle: "Range Chart",
  settings: "Chart",
  data: "Data",
  format: "Format",
  categories: "Categories",
  defaultCategory: "(None)",
  series: "Series",
  switchCategorySeries: "Switch Category / Series",
  categoryValues: "Category Values",
  seriesLabels: "Series Labels",
  aggregate: "Aggregate",
  xyValues: "X Y Values",
  paired: "Paired Mode",
  axis: "Axis",
  xAxis: "Horizontal Axis",
  yAxis: "Vertical Axis",
  polarAxis: "Polar Axis",
  radiusAxis: "Radius Axis",
  navigator: "Navigator",
  zoom: "Zoom",
  animation: "Animation",
  crosshair: "Crosshair",
  color: "Color",
  thickness: "Thickness",
  preferredLength: "Preferred Length",
  xType: "X Type",
  axisType: "Axis Type",
  automatic: "Automatic",
  category: "Category",
  number: "Number",
  time: "Time",
  timeFormat: "Time Format",
  autoRotate: "Auto Rotate",
  labelRotation: "Rotation",
  circle: "Circle",
  orientation: "Orientation",
  polygon: "Polygon",
  fixed: "Fixed",
  parallel: "Parallel",
  perpendicular: "Perpendicular",
  radiusAxisPosition: "Position",
  ticks: "Ticks",
  gridLines: "Grid Lines",
  width: "Width",
  height: "Height",
  length: "Length",
  padding: "Padding",
  spacing: "Spacing",
  chart: "Chart",
  title: "Title",
  titlePlaceholder: "Chart title - double click to edit",
  background: "Background",
  font: "Font",
  top: "Top",
  right: "Right",
  bottom: "Bottom",
  left: "Left",
  labels: "Labels",
  calloutLabels: "Callout Labels",
  sectorLabels: "Sector Labels",
  positionRatio: "Position Ratio",
  size: "Size",
  shape: "Shape",
  minSize: "Minimum Size",
  maxSize: "Maximum Size",
  legend: "Legend",
  position: "Position",
  markerSize: "Marker Size",
  markerStroke: "Marker Stroke",
  markerPadding: "Marker Padding",
  itemSpacing: "Item Spacing",
  itemPaddingX: "Item Padding X",
  itemPaddingY: "Item Padding Y",
  layoutHorizontalSpacing: "Horizontal Spacing",
  layoutVerticalSpacing: "Vertical Spacing",
  strokeWidth: "Stroke Width",
  offset: "Offset",
  offsets: "Offsets",
  tooltips: "Tooltips",
  callout: "Callout",
  markers: "Markers",
  shadow: "Shadow",
  blur: "Blur",
  xOffset: "X Offset",
  yOffset: "Y Offset",
  lineWidth: "Line Width",
  lineDash: "Line Dash",
  lineDashOffset: "Dash Offset",
  scrollingZoom: "Scrolling",
  scrollingStep: "Scrolling Step",
  selectingZoom: "Selecting",
  durationMillis: "Duration (ms)",
  crosshairLabel: "Label",
  crosshairSnap: "Snap to Node",
  normal: "Normal",
  bold: "Bold",
  italic: "Italic",
  boldItalic: "Bold Italic",
  predefined: "Predefined",
  fillOpacity: "Fill Opacity",
  strokeColor: "Line Color",
  strokeOpacity: "Line Opacity",
  miniChart: "Mini-Chart",
  histogramBinCount: "Bin count",
  connectorLine: "Connector Line",
  seriesItems: "Series Items",
  seriesItemType: "Item Type",
  seriesItemPositive: "Positive",
  seriesItemNegative: "Negative",
  seriesItemLabels: "Item Labels",
  columnGroup: "Column",
  barGroup: "Bar",
  pieGroup: "Pie",
  lineGroup: "Line",
  scatterGroup: "X Y (Scatter)",
  areaGroup: "Area",
  polarGroup: "Polar",
  statisticalGroup: "Statistical",
  hierarchicalGroup: "Hierarchical",
  specializedGroup: "Specialized",
  combinationGroup: "Combination",
  groupedColumnTooltip: "Grouped",
  stackedColumnTooltip: "Stacked",
  normalizedColumnTooltip: "100% Stacked",
  groupedBarTooltip: "Grouped",
  stackedBarTooltip: "Stacked",
  normalizedBarTooltip: "100% Stacked",
  pieTooltip: "Pie",
  donutTooltip: "Donut",
  lineTooltip: "Line",
  groupedAreaTooltip: "Area",
  stackedAreaTooltip: "Stacked",
  normalizedAreaTooltip: "100% Stacked",
  scatterTooltip: "Scatter",
  bubbleTooltip: "Bubble",
  histogramTooltip: "Histogram",
  radialColumnTooltip: "Radial Column",
  radialBarTooltip: "Radial Bar",
  radarLineTooltip: "Radar Line",
  radarAreaTooltip: "Radar Area",
  nightingaleTooltip: "Nightingale",
  rangeBarTooltip: "Range Bar",
  rangeAreaTooltip: "Range Area",
  boxPlotTooltip: "Box Plot",
  treemapTooltip: "Treemap",
  sunburstTooltip: "Sunburst",
  heatmapTooltip: "Heatmap",
  waterfallTooltip: "Waterfall",
  columnLineComboTooltip: "Column & Line",
  areaColumnComboTooltip: "Area & Column",
  customComboTooltip: "Custom Combination",
  innerRadius: "Inner Radius",
  startAngle: "Start Angle",
  endAngle: "End Angle",
  reverseDirection: "Reverse Direction",
  groupPadding: "Group Padding",
  seriesPadding: "Series Padding",
  group: "Group",
  tile: "Tile",
  whisker: "Whisker",
  cap: "Cap",
  capLengthRatio: "Length Ratio",
  labelPlacement: "Placement",
  inside: "Inside",
  outside: "Outside",
  noDataToChart: "No data available to be charted.",
  pivotChartRequiresPivotMode: "Pivot Chart requires Pivot Mode enabled.",
  chartSettingsToolbarTooltip: "Menu",
  chartLinkToolbarTooltip: "Linked to Grid",
  chartUnlinkToolbarTooltip: "Unlinked from Grid",
  chartDownloadToolbarTooltip: "Download Chart",
  chartMenuToolbarTooltip: "Menu",
  chartEdit: "Edit Chart",
  chartAdvancedSettings: "Advanced Settings",
  chartLink: "Link to Grid",
  chartUnlink: "Unlink from Grid",
  chartDownload: "Download Chart",
  histogramFrequency: "Frequency",
  seriesChartType: "Series Chart Type",
  seriesType: "Series Type",
  secondaryAxis: "Secondary Axis",
  seriesAdd: "Add a series",
  categoryAdd: "Add a category",
  area: "Area",
  bar: "Bar",
  column: "Column",
  line: "Line",
  scatter: "Scatter",
  histogram: "Histogram",
  radialColumn: "Radial Column",
  radialBar: "Radial Bar",
  radarLine: "Radar Line",
  radarArea: "Radar Area",
  nightingale: "Nightingale",
  rangeBar: "Range Bar",
  rangeArea: "Range Area",
  treemap: "Treemap",
  sunburst: "Sunburst",
  waterfall: "Waterfall",
  boxPlot: "Box Plot",
  pie: "Pie",
  donut: "Donut",
  stackedArea: "StackedArea",
  groupedColumn: "Grouped Column",
  stackedColumn: "Stacked Column",
  advancedSettings: "Advanced Settings",
  ariaChartMenuClose: "Close Chart Edit Menu",
  timeFormatSlashesDDMMYYYY: "DD/MM/YYYY",
  timeFormatSlashesMMDDYYYY: "MM/DD/YYYY",
  timeFormatSlashesDDMMYY: "DD/MM/YY",
  timeFormatSlashesMMDDYY: "MM/DD/YY",
  timeFormatDotsDDMYY: "DD.M.YY",
  timeFormatDotsMDDYY: "M.DD.YY",
  timeFormatDashesYYYYMMDD: "YYYY-MM-DD",
  timeFormatSpacesDDMMMMYYYY: "DD MMMM YYYY",
  timeFormatHHMMSS: "HH:MM:SS",
  timeFormatHHMMSSAmPm: "HH:MM:SS AM/PM",
  sum: "Sum",
  first: "First",
  last: "Last",
  min: "Min",
  max: "Max",
  count: "Count",
  avg: "Average",
  direction: "Direction",
  horizontal: "Horizontal",
  vertical: "Vertical",
  seriesGroupType: "Group Type",
  groupedSeriesGroupType: "Grouped",
  stackedSeriesGroupType: "Stacked",
  normalizedSeriesGroupType: "100% Stacked"
};
ChartTranslationService = __decorateClass4([
  Bean42("chartTranslationService")
], ChartTranslationService);
var ChartCrossFilterService = class extends BeanStub12 {
  filter(event, reset = false) {
    const filterModel = this.gridApi.getFilterModel();
    if (reset) {
      this.resetFilters(filterModel);
      return;
    }
    let colId = ChartCrossFilterService.extractFilterColId(event);
    if (this.isValidColumnFilter(colId)) {
      this.updateFilters(filterModel, event, colId);
    } else {
      console.warn("AG Grid: cross filtering requires a 'agSetColumnFilter' or 'agMultiColumnFilter' to be defined on the column with id: '" + colId + "'");
    }
  }
  resetFilters(filterModel) {
    const filtersExist = Object.keys(filterModel).length > 0;
    if (filtersExist) {
      this.gridApi.setFilterModel(null);
      this.gridApi.onFilterChanged();
    }
  }
  updateFilters(filterModel, event, colId) {
    let dataKey = ChartCrossFilterService.extractFilterColId(event);
    let rawValue = event.datum[dataKey];
    if (rawValue === void 0) {
      return;
    }
    let selectedValue = rawValue.toString();
    if (event.event.metaKey || event.event.ctrlKey) {
      const existingGridValues = this.getCurrentGridValuesForCategory(colId);
      const valueAlreadyExists = _35.includes(existingGridValues, selectedValue);
      let updatedValues;
      if (valueAlreadyExists) {
        updatedValues = existingGridValues.filter((v) => v !== selectedValue);
      } else {
        updatedValues = existingGridValues;
        updatedValues.push(selectedValue);
      }
      filterModel[colId] = this.getUpdatedFilterModel(colId, updatedValues);
    } else {
      const updatedValues = [selectedValue];
      filterModel = { [colId]: this.getUpdatedFilterModel(colId, updatedValues) };
    }
    this.gridApi.setFilterModel(filterModel);
  }
  getUpdatedFilterModel(colId, updatedValues) {
    let columnFilterType = this.getColumnFilterType(colId);
    if (columnFilterType === "agMultiColumnFilter") {
      return { filterType: "multi", filterModels: [null, { filterType: "set", values: updatedValues }] };
    }
    return { filterType: "set", values: updatedValues };
  }
  getCurrentGridValuesForCategory(colId) {
    let filteredValues = [];
    const column = this.getColumnById(colId);
    this.gridApi.forEachNodeAfterFilter((rowNode) => {
      if (column && !rowNode.group) {
        const value = this.valueService.getValue(column, rowNode) + "";
        if (!filteredValues.includes(value)) {
          filteredValues.push(value);
        }
      }
    });
    return filteredValues;
  }
  static extractFilterColId(event) {
    return event.xKey || event.calloutLabelKey;
  }
  isValidColumnFilter(colId) {
    if (colId.indexOf("-filtered-out")) {
      colId = colId.replace("-filtered-out", "");
    }
    let filterType = this.getColumnFilterType(colId);
    if (typeof filterType === "boolean") {
      return filterType;
    }
    return _35.includes(["agSetColumnFilter", "agMultiColumnFilter"], filterType);
  }
  getColumnFilterType(colId) {
    let gridColumn = this.getColumnById(colId);
    if (gridColumn) {
      const colDef = gridColumn.getColDef();
      return colDef.filter;
    }
  }
  getColumnById(colId) {
    return this.columnModel.getGridColumn(colId);
  }
};
__decorateClass4([
  Autowired422("gridApi")
], ChartCrossFilterService.prototype, "gridApi", 2);
__decorateClass4([
  Autowired422("columnModel")
], ChartCrossFilterService.prototype, "columnModel", 2);
__decorateClass4([
  Autowired422("valueService")
], ChartCrossFilterService.prototype, "valueService", 2);
ChartCrossFilterService = __decorateClass4([
  Bean5("chartCrossFilterService")
], ChartCrossFilterService);
var VERSION_CHECKING_FIRST_GRID_MAJOR_VERSION = 28;
var VERSION_CHECKING_FIRST_CHARTS_MAJOR_VERSION = 6;
function isValidVersion(version) {
  return version && version.match(/\d+\.\d+\.\d+/);
}
function isValidMajorVersion({ gridMajorVersion, chartsMajorVersion }) {
  const gridMajor = parseInt(gridMajorVersion, 10);
  const chartsMajor = parseInt(chartsMajorVersion, 10);
  const gridMajorDifference = gridMajor - VERSION_CHECKING_FIRST_GRID_MAJOR_VERSION;
  const chartsMajorDifference = chartsMajor - VERSION_CHECKING_FIRST_CHARTS_MAJOR_VERSION;
  const isFirstOrAfterVersion = gridMajorDifference >= 0;
  return gridMajorDifference === chartsMajorDifference && isFirstOrAfterVersion;
}
function gridChartVersion(gridVersion) {
  if (!gridVersion || !isValidVersion(gridVersion)) {
    return void 0;
  }
  const [gridMajor, gridMinor] = gridVersion.split(".") || [];
  const gridMajorMinor = `${gridMajor}.${gridMinor}.x`;
  const gridMajorNumber = parseInt(gridMajor, 10);
  const chartsMajor = gridMajorNumber - VERSION_CHECKING_FIRST_GRID_MAJOR_VERSION + VERSION_CHECKING_FIRST_CHARTS_MAJOR_VERSION;
  if (chartsMajor < 0) {
    return void 0;
  }
  const chartsMinor = gridMinor;
  const chartsMajorMinor = `${chartsMajor}.${chartsMinor}.x`;
  return {
    gridMajorMinor,
    chartsMajorMinor
  };
}
function validGridChartsVersionErrorMessage({ type, gridVersion, chartsVersion }) {
  const invalidMessage = "AG Grid: AG Grid version is incompatible. Please see https://www.ag-grid.com/javascript-data-grid/modules/ for more information.";
  if (!gridVersion) {
    return invalidMessage;
  }
  const version = gridChartVersion(gridVersion);
  if (!version) {
    return invalidMessage;
  }
  const { gridMajorMinor, chartsMajorMinor } = version;
  if (type === "incompatible") {
    return `AG Grid version ${gridVersion} and AG Charts version ${chartsVersion} is not supported. AG Grid version ${gridMajorMinor} should be used with AG Chart ${chartsMajorMinor}. Please see https://www.ag-grid.com/javascript-data-grid/modules/ for more information.`;
  } else if (type === "invalidCharts") {
    return `AG Grid version ${gridMajorMinor} should be used with AG Chart ${chartsMajorMinor}. Please see https://www.ag-grid.com/javascript-data-grid/modules/ for more information.`;
  }
  return invalidMessage;
}
function validGridChartsVersion({ gridVersion, chartsVersion }) {
  if (!isValidVersion(chartsVersion)) {
    return {
      isValid: false,
      message: validGridChartsVersionErrorMessage({ type: "invalidCharts", gridVersion, chartsVersion })
    };
  }
  if (!isValidVersion(gridVersion)) {
    return {
      isValid: false,
      message: validGridChartsVersionErrorMessage({ type: "invalidGrid", gridVersion, chartsVersion })
    };
  }
  const [gridMajor, gridMinor] = gridVersion.split(".") || [];
  const [chartsMajor, chartsMinor, chartsPatch] = chartsVersion.split(".") || [];
  const isValidMajor = isValidMajorVersion({
    gridMajorVersion: gridMajor,
    chartsMajorVersion: chartsMajor
  });
  if (isValidMajor && gridMinor === chartsMinor || chartsPatch.includes("beta")) {
    return {
      isValid: true
    };
  } else if (!isValidMajor || gridMinor !== chartsMinor) {
    return {
      isValid: false,
      message: validGridChartsVersionErrorMessage({ type: "incompatible", gridVersion, chartsVersion })
    };
  }
  return {
    isValid: false,
    message: validGridChartsVersionErrorMessage({ type: "invalid", gridVersion, chartsVersion })
  };
}
var ChartMenuListFactory = class extends BeanStub13 {
  showMenuList(params) {
    const { eventSource, showMenu, chartMenuContext } = params;
    const areChartToolPanelsEnabled = this.chartMenuService.doChartToolPanelsExist(chartMenuContext.chartController);
    const menuItems = this.mapWithStockItems(
      this.getMenuItems(chartMenuContext.chartController, areChartToolPanelsEnabled),
      chartMenuContext,
      showMenu,
      eventSource,
      areChartToolPanelsEnabled
    );
    if (!menuItems.length) {
      return;
    }
    const chartMenuList = this.createBean(new ChartMenuList(menuItems));
    this.activeChartMenuList = chartMenuList;
    let multiplier = -1;
    let alignSide = "left";
    if (this.gos.get("enableRtl")) {
      multiplier = 1;
      alignSide = "right";
    }
    const eGui = chartMenuList.getGui();
    this.popupService.addPopup({
      modal: true,
      eChild: eGui,
      closeOnEsc: true,
      closedCallback: () => {
        this.destroyBean(chartMenuList);
        this.activeChartMenuList = void 0;
        const eDocument = this.gos.getDocument();
        const activeEl = this.gos.getActiveDomElement();
        if (!activeEl || activeEl === eDocument.body) {
          eventSource.focus({ preventScroll: true });
        }
      },
      afterGuiAttached: (params2) => chartMenuList.afterGuiAttached(params2),
      positionCallback: () => {
        {
          this.popupService.positionPopupByComponent({
            type: "chartMenu",
            eventSource,
            ePopup: eGui,
            alignSide,
            nudgeX: 4 * multiplier,
            nudgeY: 4,
            position: "under",
            keepWithinBounds: true
          });
        }
      },
      ariaLabel: "Chart Menu"
    });
  }
  getMenuItems(chartController, areChartToolPanelsEnabled) {
    const defaultItems = [
      ...areChartToolPanelsEnabled ? ["chartEdit"] : [],
      ...chartController.isEnterprise() ? ["chartAdvancedSettings"] : [],
      chartController.isChartLinked() ? "chartUnlink" : "chartLink",
      "chartDownload"
    ];
    const chartMenuItems = this.gos.get("chartMenuItems");
    if (!chartMenuItems) {
      return defaultItems;
    } else if (Array.isArray(chartMenuItems)) {
      return chartMenuItems;
    } else {
      return chartMenuItems(this.gos.addGridCommonParams({
        defaultItems
      }));
    }
  }
  mapWithStockItems(originalList, chartMenuContext, showMenu, eventSource, areChartToolPanelsEnabled) {
    if (!originalList) {
      return [];
    }
    const resultList = [];
    originalList.forEach((menuItemOrString) => {
      let result;
      if (typeof menuItemOrString === "string") {
        result = this.getStockMenuItem(menuItemOrString, chartMenuContext, showMenu, eventSource, areChartToolPanelsEnabled);
      } else {
        result = __spreadValues3({}, menuItemOrString);
      }
      if (!result) {
        return;
      }
      const { subMenu } = result;
      if (Array.isArray(subMenu)) {
        result.subMenu = this.mapWithStockItems(subMenu, chartMenuContext, showMenu, eventSource, areChartToolPanelsEnabled);
      }
      resultList.push(result);
    });
    return resultList;
  }
  getStockMenuItem(key, chartMenuContext, showMenu, eventSource, areChartToolPanelsEnabled) {
    switch (key) {
      case "chartEdit":
        return areChartToolPanelsEnabled ? this.createMenuItem(this.chartTranslationService.translate("chartEdit"), "chartsMenuEdit", showMenu) : null;
      case "chartAdvancedSettings":
        return this.createMenuItem(
          this.chartTranslationService.translate("chartAdvancedSettings"),
          "chartsMenuAdvancedSettings",
          () => this.chartMenuService.openAdvancedSettings(chartMenuContext, eventSource)
        );
      case "chartUnlink":
        return chartMenuContext.chartController.isChartLinked() ? this.createMenuItem(
          this.chartTranslationService.translate("chartUnlink"),
          "unlinked",
          () => this.chartMenuService.toggleLinked(chartMenuContext)
        ) : null;
      case "chartLink":
        return !chartMenuContext.chartController.isChartLinked() ? this.createMenuItem(
          this.chartTranslationService.translate("chartLink"),
          "linked",
          () => this.chartMenuService.toggleLinked(chartMenuContext)
        ) : null;
      case "chartDownload":
        return this.createMenuItem(
          this.chartTranslationService.translate("chartDownload"),
          "save",
          () => this.chartMenuService.downloadChart(chartMenuContext)
        );
    }
    return null;
  }
  createMenuItem(name, iconName, action) {
    return {
      name,
      icon: _36.createIconNoSpan(iconName, this.gos, null),
      action
    };
  }
  destroy() {
    this.destroyBean(this.activeChartMenuList);
    super.destroy();
  }
};
__decorateClass4([
  Autowired43("popupService")
], ChartMenuListFactory.prototype, "popupService", 2);
__decorateClass4([
  Autowired43("chartMenuService")
], ChartMenuListFactory.prototype, "chartMenuService", 2);
__decorateClass4([
  Autowired43("chartTranslationService")
], ChartMenuListFactory.prototype, "chartTranslationService", 2);
ChartMenuListFactory = __decorateClass4([
  Bean6("chartMenuListFactory")
], ChartMenuListFactory);
var ChartMenuList = class extends Component37 {
  constructor(menuItems) {
    super(
      /* html */
      `
            <div ref="eChartsMenu" role="presentation" class="ag-menu ag-chart-menu-popup"></div>
        `
    );
    this.menuItems = menuItems;
  }
  init() {
    this.mainMenuList = this.createManagedBean(new AgMenuList(0));
    this.mainMenuList.addMenuItems(this.menuItems);
    this.mainMenuList.addEventListener(AgMenuItemComponent.EVENT_CLOSE_MENU, this.onHidePopup.bind(this));
    this.eChartsMenu.appendChild(this.mainMenuList.getGui());
  }
  onHidePopup() {
    var _a;
    (_a = this.hidePopupFunc) == null ? void 0 : _a.call(this);
  }
  afterGuiAttached({ hidePopup }) {
    if (hidePopup) {
      this.hidePopupFunc = hidePopup;
      this.addDestroyFunc(hidePopup);
    }
    this.focusService.focusInto(this.mainMenuList.getGui());
  }
};
__decorateClass4([
  Autowired43("focusService")
], ChartMenuList.prototype, "focusService", 2);
__decorateClass4([
  RefSelector13("eChartsMenu")
], ChartMenuList.prototype, "eChartsMenu", 2);
__decorateClass4([
  PostConstruct432
], ChartMenuList.prototype, "init", 1);
var _AnimationPanel = class _AnimationPanel2 extends Component38 {
  constructor(chartMenuParamsFactory) {
    super();
    this.chartMenuParamsFactory = chartMenuParamsFactory;
  }
  init() {
    const animationGroupParams = this.chartMenuParamsFactory.addEnableParams(
      "animation.enabled",
      {
        cssIdentifier: "charts-advanced-settings-top-level",
        direction: "vertical",
        suppressOpenCloseIcons: true,
        title: this.chartTranslationService.translate("animation"),
        suppressEnabledCheckbox: true,
        useToggle: true
      }
    );
    const animationHeightInputParams = this.chartMenuParamsFactory.getDefaultNumberInputParams("animation.duration", "durationMillis", {
      min: 0
    });
    this.setTemplate(_AnimationPanel2.TEMPLATE, {
      animationGroup: animationGroupParams,
      animationHeightInput: animationHeightInputParams
    });
  }
};
_AnimationPanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="animationGroup">
                <ag-input-number-field ref="animationHeightInput"></ag-input>
            </ag-group-component>
        </div>`;
__decorateClass4([
  Autowired44("chartTranslationService")
], _AnimationPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct44
], _AnimationPanel.prototype, "init", 1);
var AnimationPanel = _AnimationPanel;
var _CrosshairPanel = class _CrosshairPanel2 extends Component39 {
  constructor(chartMenuParamsFactory) {
    super();
    this.chartMenuParamsFactory = chartMenuParamsFactory;
  }
  init() {
    const crosshairGroupParams = this.chartMenuParamsFactory.addEnableParams("crosshair.enabled", {
      cssIdentifier: "charts-advanced-settings-top-level",
      direction: "vertical",
      suppressOpenCloseIcons: true,
      title: this.chartTranslationService.translate("crosshair"),
      suppressEnabledCheckbox: true,
      useToggle: true
    });
    const crosshairLabelCheckboxParams = this.chartMenuParamsFactory.getDefaultCheckboxParams(
      "crosshair.label.enabled",
      "crosshairLabel"
    );
    const crosshairSnapCheckboxParams = this.chartMenuParamsFactory.getDefaultCheckboxParams(
      "crosshair.snap",
      "crosshairSnap"
    );
    const crosshairStrokeColorPickerParams = this.chartMenuParamsFactory.getDefaultColorPickerParams(
      "crosshair.stroke",
      "color"
    );
    this.setTemplate(_CrosshairPanel2.TEMPLATE, {
      crosshairGroup: crosshairGroupParams,
      crosshairLabelCheckbox: crosshairLabelCheckboxParams,
      crosshairSnapCheckbox: crosshairSnapCheckboxParams,
      crosshairStrokeColorPicker: crosshairStrokeColorPickerParams
    });
  }
};
_CrosshairPanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="crosshairGroup">
                <ag-checkbox ref="crosshairLabelCheckbox"></ag-checkbox>
                <ag-checkbox ref="crosshairSnapCheckbox"></ag-checkbox>
                <ag-color-picker ref="crosshairStrokeColorPicker"></ag-color-picker>
            </ag-group-component>
        </div>`;
__decorateClass4([
  Autowired45("chartTranslationService")
], _CrosshairPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct45
], _CrosshairPanel.prototype, "init", 1);
var CrosshairPanel = _CrosshairPanel;
var _NavigatorPanel = class _NavigatorPanel2 extends Component40 {
  constructor(chartMenuParamsFactory) {
    super();
    this.chartMenuParamsFactory = chartMenuParamsFactory;
  }
  init() {
    const navigatorGroupParams = this.chartMenuParamsFactory.addEnableParams(
      "navigator.enabled",
      {
        cssIdentifier: "charts-advanced-settings-top-level",
        direction: "vertical",
        suppressOpenCloseIcons: true,
        title: this.chartTranslationService.translate("navigator"),
        suppressEnabledCheckbox: true,
        useToggle: true
      }
    );
    const navigatorHeightSliderParams = this.chartMenuParamsFactory.getDefaultSliderParams("navigator.height", "height", 60);
    navigatorHeightSliderParams.minValue = 10;
    const navigatorMiniChartCheckboxParams = this.chartMenuParamsFactory.getDefaultCheckboxParams("navigator.miniChart.enabled", "miniChart");
    this.setTemplate(_NavigatorPanel2.TEMPLATE, {
      navigatorGroup: navigatorGroupParams,
      navigatorHeightSlider: navigatorHeightSliderParams,
      navigatorMiniChartCheckbox: navigatorMiniChartCheckboxParams
    });
  }
};
_NavigatorPanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="navigatorGroup">
                <ag-slider ref="navigatorHeightSlider"></ag-slider>
                <ag-checkbox ref="navigatorMiniChartCheckbox"></ag-checkbox>
            </ag-group-component>
        </div>`;
__decorateClass4([
  Autowired46("chartTranslationService")
], _NavigatorPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  PostConstruct46
], _NavigatorPanel.prototype, "init", 1);
var NavigatorPanel = _NavigatorPanel;
var _ZoomPanel = class _ZoomPanel2 extends Component41 {
  constructor(chartMenuParamsFactory) {
    super();
    this.chartMenuParamsFactory = chartMenuParamsFactory;
  }
  init() {
    const zoomGroupParams = this.chartMenuParamsFactory.addEnableParams("zoom.enabled", {
      cssIdentifier: "charts-advanced-settings-top-level",
      direction: "vertical",
      suppressOpenCloseIcons: true,
      title: this.chartTranslationService.translate("zoom"),
      suppressEnabledCheckbox: true,
      useToggle: true
    });
    const zoomScrollingCheckboxParams = this.chartMenuParamsFactory.getDefaultCheckboxParams(
      "zoom.enableScrolling",
      "scrollingZoom"
    );
    const zoomScrollingStepSliderParams = this.chartMenuParamsFactory.getDefaultSliderParams(
      "zoom.scrollingStep",
      "scrollingStep",
      1
    );
    zoomScrollingStepSliderParams.step = 0.01;
    zoomScrollingStepSliderParams.minValue = zoomScrollingStepSliderParams.step;
    const zoomSelectingCheckboxParams = this.chartMenuParamsFactory.getDefaultCheckboxParams(
      "zoom.enableSelecting",
      "selectingZoom"
    );
    zoomScrollingCheckboxParams.onValueChange = /* @__PURE__ */ ((onValueChange) => (value) => {
      if (!onValueChange)
        return;
      onValueChange(value);
      this.zoomScrollingStepInput.setDisabled(!value);
    })(zoomScrollingCheckboxParams.onValueChange);
    this.setTemplate(_ZoomPanel2.TEMPLATE, {
      zoomGroup: zoomGroupParams,
      zoomScrollingCheckbox: zoomScrollingCheckboxParams,
      zoomScrollingStepInput: zoomScrollingStepSliderParams,
      zoomSelectingCheckbox: zoomSelectingCheckboxParams
    });
    this.zoomScrollingStepInput.setDisabled(!zoomScrollingCheckboxParams.value);
  }
};
_ZoomPanel.TEMPLATE = /* html */
`<div>
            <ag-group-component ref="zoomGroup">
                <ag-checkbox ref="zoomSelectingCheckbox"></ag-checkbox>
                <ag-checkbox ref="zoomScrollingCheckbox"></ag-checkbox>
                <ag-slider ref="zoomScrollingStepInput"></ag-slider>
            </ag-group-component>
        </div>`;
__decorateClass4([
  Autowired47("chartTranslationService")
], _ZoomPanel.prototype, "chartTranslationService", 2);
__decorateClass4([
  RefSelector14("zoomScrollingStepInput")
], _ZoomPanel.prototype, "zoomScrollingStepInput", 2);
__decorateClass4([
  PostConstruct47
], _ZoomPanel.prototype, "init", 1);
var ZoomPanel = _ZoomPanel;
var INTERACTIVITY_GROUPS = ["navigator", "zoom", "animation", "crosshair"];
var _AdvancedSettingsPanel = class _AdvancedSettingsPanel2 extends Component422 {
  constructor(chartMenuContext) {
    super(_AdvancedSettingsPanel2.TEMPLATE);
    this.chartMenuContext = chartMenuContext;
  }
  postConstruct() {
    this.chartPanelFeature = this.createManagedBean(new ChartPanelFeature(
      this.chartMenuContext.chartController,
      this.getGui(),
      "ag-chart-advanced-settings-section",
      (chartType, seriesType) => this.createPanels(chartType, seriesType)
    ));
    this.chartPanelFeature.refreshPanels();
  }
  createPanels(chartType, seriesType) {
    INTERACTIVITY_GROUPS.forEach((group) => {
      if (!this.isGroupPanelShownForSeries(group, seriesType)) {
        return;
      }
      const comp = this.createPanel(group);
      this.chartPanelFeature.addComponent(comp);
    });
  }
  isGroupPanelShownForSeries(group, seriesType) {
    return group === "animation" || isCartesian(seriesType);
  }
  createPanel(group) {
    const { chartMenuParamsFactory, chartAxisMenuParamsFactory } = this.chartMenuContext;
    switch (group) {
      case "navigator":
        return new NavigatorPanel(chartMenuParamsFactory);
      case "zoom":
        return new ZoomPanel(chartMenuParamsFactory);
      case "animation":
        return new AnimationPanel(chartMenuParamsFactory);
      case "crosshair":
        return new CrosshairPanel(chartAxisMenuParamsFactory);
    }
  }
};
_AdvancedSettingsPanel.TEMPLATE = /* html */
`<div class="ag-chart-advanced-settings-wrapper"></div>`;
__decorateClass4([
  PostConstruct48
], _AdvancedSettingsPanel.prototype, "postConstruct", 1);
var AdvancedSettingsPanel = _AdvancedSettingsPanel;
var AdvancedSettingsMenuFactory = class extends BeanStub14 {
  showMenu(chartMenuContext, eventSource) {
    this.hideMenu();
    const menu = this.createBean(new AdvancedSettingsMenu(chartMenuContext));
    this.activeDialog = this.createBean(new AgDialog32({
      title: this.chartTranslationService.translate("advancedSettings"),
      component: menu,
      width: 300,
      height: 400,
      resizable: true,
      movable: true,
      centered: true,
      closable: true,
      afterGuiAttached: () => {
        var _a;
        (_a = this.focusService.findFocusableElements(menu.getGui())[0]) == null ? void 0 : _a.focus();
      },
      closedCallback: () => {
        this.activeMenu = this.destroyBean(this.activeMenu);
        this.activeDialog = void 0;
        eventSource == null ? void 0 : eventSource.focus({ preventScroll: true });
      }
    }));
    this.activeMenu = menu;
  }
  hideMenu() {
    if (this.activeDialog) {
      this.destroyBean(this.activeDialog);
    }
  }
  destroy() {
    this.activeMenu = this.destroyBean(this.activeMenu);
    this.activeDialog = this.destroyBean(this.activeDialog);
    super.destroy();
  }
};
__decorateClass4([
  Autowired48("focusService")
], AdvancedSettingsMenuFactory.prototype, "focusService", 2);
__decorateClass4([
  Autowired48("chartTranslationService")
], AdvancedSettingsMenuFactory.prototype, "chartTranslationService", 2);
AdvancedSettingsMenuFactory = __decorateClass4([
  Bean7("advancedSettingsMenuFactory")
], AdvancedSettingsMenuFactory);
var _AdvancedSettingsMenu = class _AdvancedSettingsMenu2 extends TabGuardComp2 {
  constructor(chartMenuContext) {
    super(_AdvancedSettingsMenu2.TEMPLATE);
    this.chartMenuContext = chartMenuContext;
  }
  postConstruct() {
    this.advancedSettingsPanel = this.createManagedBean(new AdvancedSettingsPanel(this.chartMenuContext));
    this.getGui().appendChild(this.advancedSettingsPanel.getGui());
    this.initialiseTabGuard({
      onTabKeyDown: this.onTabKeyDown.bind(this),
      focusTrapActive: true
    });
  }
  onTabKeyDown(e) {
    if (e.defaultPrevented) {
      return;
    }
    e.preventDefault();
    const backwards = e.shiftKey;
    const panelGui = this.advancedSettingsPanel.getGui();
    const nextEl = this.focusService.findNextFocusableElement(panelGui, false, backwards);
    if (nextEl) {
      nextEl.focus();
    } else {
      const focusableElements = this.focusService.findFocusableElements(panelGui);
      if (focusableElements.length) {
        focusableElements[backwards ? focusableElements.length - 1 : 0].focus();
      }
    }
  }
};
_AdvancedSettingsMenu.TEMPLATE = /* html */
`<div class="ag-chart-advanced-settings"></div>`;
__decorateClass4([
  Autowired48("focusService")
], _AdvancedSettingsMenu.prototype, "focusService", 2);
__decorateClass4([
  PostConstruct49
], _AdvancedSettingsMenu.prototype, "postConstruct", 1);
var AdvancedSettingsMenu = _AdvancedSettingsMenu;
var GridChartsModule = {
  version: VERSION4,
  validate: () => {
    return validGridChartsVersion({
      gridVersion: VERSION4,
      chartsVersion: ChartService.CHARTS_VERSION
    });
  },
  moduleName: ModuleNames22.GridChartsModule,
  beans: [
    ChartService,
    ChartTranslationService,
    ChartCrossFilterService,
    ChartMenuListFactory,
    ChartMenuService,
    AdvancedSettingsMenuFactory
  ],
  agStackComponents: [
    { componentName: "AgColorPicker", componentClass: AgColorPicker },
    { componentName: "AgAngleSelect", componentClass: AgAngleSelect },
    { componentName: "AgPillSelect", componentClass: AgPillSelect }
  ],
  dependantModules: [
    RangeSelectionModule,
    EnterpriseCoreModule
  ]
};
var __FORCE_MODULE_DETECTION_AG_GRID_CORE_EXT = 0;
var agCharts = {
  time,
  AgChart
};

// enterprise-modules/clipboard/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames5 } from "ag-grid-community";
import { CsvExportModule } from "ag-grid-community";
import {
  _ as _16,
  Autowired as Autowired49,
  Bean as Bean8,
  BeanStub as BeanStub15,
  ChangedPath,
  Events as Events11,
  PostConstruct as PostConstruct50,
  Optional as Optional3
} from "ag-grid-community";
var __defProp5 = Object.defineProperty;
var __getOwnPropDesc5 = Object.getOwnPropertyDescriptor;
var __decorateClass5 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc5(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp5(target, key, result);
  return result;
};
var SOURCE_PASTE = "paste";
var EXPORT_TYPE_DRAG_COPY = "dragCopy";
var EXPORT_TYPE_CLIPBOARD = "clipboard";
var apiError = (method) => `AG Grid: Unable to use the Clipboard API (navigator.clipboard.${method}()). The reason why it could not be used has been logged in the previous line. For this reason the grid has defaulted to using a workaround which doesn't perform as well. Either fix why Clipboard API is blocked, OR stop this message from appearing by setting grid property suppressClipboardApi=true (which will default the grid to using the workaround rather than the API.`;
var ClipboardService = class extends BeanStub15 {
  constructor() {
    super(...arguments);
    this.lastPasteOperationTime = 0;
    this.navigatorApiFailed = false;
  }
  init() {
    this.logger = this.loggerFactory.create("ClipboardService");
    if (this.rowModel.getType() === "clientSide") {
      this.clientSideRowModel = this.rowModel;
    }
    this.ctrlsService.whenReady((p) => {
      this.gridCtrl = p.gridCtrl;
    });
  }
  pasteFromClipboard() {
    this.logger.log("pasteFromClipboard");
    const allowNavigator = !this.gos.get("suppressClipboardApi");
    if (allowNavigator && !this.navigatorApiFailed && navigator.clipboard && navigator.clipboard.readText) {
      navigator.clipboard.readText().then(this.processClipboardData.bind(this)).catch((e) => {
        _16.doOnce(() => {
          console.warn(e);
          console.warn(apiError("readText"));
        }, "clipboardApiError");
        this.navigatorApiFailed = true;
        this.pasteFromClipboardLegacy();
      });
    } else {
      this.pasteFromClipboardLegacy();
    }
  }
  pasteFromClipboardLegacy() {
    let defaultPrevented = false;
    const handlePasteEvent = (e) => {
      const currentPastOperationTime = (/* @__PURE__ */ new Date()).getTime();
      if (currentPastOperationTime - this.lastPasteOperationTime < 50) {
        defaultPrevented = true;
        e.preventDefault();
      }
      this.lastPasteOperationTime = currentPastOperationTime;
    };
    this.executeOnTempElement(
      (textArea) => {
        textArea.addEventListener("paste", handlePasteEvent);
        textArea.focus({ preventScroll: true });
      },
      (element) => {
        const data = element.value;
        if (!defaultPrevented) {
          this.processClipboardData(data);
        } else {
          this.refocusLastFocusedCell();
        }
        element.removeEventListener("paste", handlePasteEvent);
      }
    );
  }
  refocusLastFocusedCell() {
    const focusedCell = this.focusService.getFocusedCell();
    if (focusedCell) {
      this.focusService.setFocusedCell({
        rowIndex: focusedCell.rowIndex,
        column: focusedCell.column,
        rowPinned: focusedCell.rowPinned,
        forceBrowserFocus: true
      });
    }
  }
  getClipboardDelimiter() {
    const delimiter = this.gos.get("clipboardDelimiter");
    return _16.exists(delimiter) ? delimiter : "	";
  }
  processClipboardData(data) {
    if (data == null) {
      return;
    }
    let parsedData = ClipboardService.stringToArray(data, this.getClipboardDelimiter());
    const userFunc = this.gos.getCallback("processDataFromClipboard");
    if (userFunc) {
      parsedData = userFunc({ data: parsedData });
    }
    if (parsedData == null) {
      return;
    }
    if (this.gos.get("suppressLastEmptyLineOnPaste")) {
      this.removeLastLineIfBlank(parsedData);
    }
    const pasteOperation = (cellsToFlash, updatedRowNodes, focusedCell, changedPath) => {
      var _a;
      const rangeActive = (_a = this.rangeService) == null ? void 0 : _a.isMoreThanOneCell();
      const pasteIntoRange = rangeActive && !this.hasOnlyOneValueToPaste(parsedData);
      if (pasteIntoRange) {
        this.pasteIntoActiveRange(this.rangeService, parsedData, cellsToFlash, updatedRowNodes, changedPath);
      } else {
        this.pasteStartingFromFocusedCell(parsedData, cellsToFlash, updatedRowNodes, focusedCell, changedPath);
      }
    };
    this.doPasteOperation(pasteOperation);
  }
  // This will parse a delimited string into an array of arrays.
  static stringToArray(strData, delimiter = ",") {
    const data = [];
    const isNewline = (char) => char === "\r" || char === "\n";
    let insideQuotedField = false;
    if (strData === "") {
      return [[""]];
    }
    for (let row = 0, column = 0, position = 0; position < strData.length; position++) {
      const previousChar = strData[position - 1];
      const currentChar = strData[position];
      const nextChar = strData[position + 1];
      const ensureDataExists = () => {
        if (!data[row]) {
          data[row] = [];
        }
        if (!data[row][column]) {
          data[row][column] = "";
        }
      };
      ensureDataExists();
      if (currentChar === '"') {
        if (insideQuotedField) {
          if (nextChar === '"') {
            data[row][column] += '"';
            position++;
          } else {
            insideQuotedField = false;
          }
        } else if (previousChar === void 0 || previousChar === delimiter || isNewline(previousChar)) {
          insideQuotedField = true;
        }
      }
      if (!insideQuotedField && currentChar !== '"') {
        if (currentChar === delimiter) {
          column++;
          ensureDataExists();
          continue;
        } else if (isNewline(currentChar)) {
          column = 0;
          row++;
          ensureDataExists();
          if (currentChar === "\r" && nextChar === "\n") {
            position++;
          }
          continue;
        }
      }
      data[row][column] += currentChar;
    }
    return data;
  }
  // common code to paste operations, e.g. paste to cell, paste to range, and copy range down
  doPasteOperation(pasteOperationFunc) {
    const source = "clipboard";
    this.eventService.dispatchEvent({
      type: Events11.EVENT_PASTE_START,
      source
    });
    let changedPath;
    if (this.clientSideRowModel) {
      const onlyChangedColumns = this.gos.get("aggregateOnlyChangedColumns");
      changedPath = new ChangedPath(onlyChangedColumns, this.clientSideRowModel.getRootNode());
    }
    const cellsToFlash = {};
    const updatedRowNodes = [];
    const focusedCell = this.focusService.getFocusedCell();
    pasteOperationFunc(cellsToFlash, updatedRowNodes, focusedCell, changedPath);
    const nodesToRefresh = [...updatedRowNodes];
    if (changedPath) {
      this.clientSideRowModel.doAggregate(changedPath);
      changedPath.forEachChangedNodeDepthFirst((rowNode) => {
        nodesToRefresh.push(rowNode);
      });
    }
    this.rowRenderer.refreshCells({ rowNodes: nodesToRefresh });
    this.dispatchFlashCells(cellsToFlash);
    this.fireRowChanged(updatedRowNodes);
    this.refocusLastFocusedCell();
    const event = {
      type: Events11.EVENT_PASTE_END,
      source
    };
    this.eventService.dispatchEvent(event);
  }
  pasteIntoActiveRange(rangeService, clipboardData, cellsToFlash, updatedRowNodes, changedPath) {
    const abortRepeatingPasteIntoRows = this.getRangeSize(rangeService) % clipboardData.length != 0;
    let indexOffset = 0;
    let dataRowIndex = 0;
    const rowCallback = (currentRow, rowNode, columns, index) => {
      const atEndOfClipboardData = index - indexOffset >= clipboardData.length;
      if (atEndOfClipboardData) {
        if (abortRepeatingPasteIntoRows) {
          return;
        }
        indexOffset += dataRowIndex;
        dataRowIndex = 0;
      }
      const currentRowData = clipboardData[index - indexOffset];
      updatedRowNodes.push(rowNode);
      const processCellFromClipboardFunc = this.gos.getCallback("processCellFromClipboard");
      columns.forEach((column, idx) => {
        if (!column.isCellEditable(rowNode) || column.isSuppressPaste(rowNode)) {
          return;
        }
        if (idx >= currentRowData.length) {
          idx = idx % currentRowData.length;
        }
        const newValue = this.processCell(
          rowNode,
          column,
          currentRowData[idx],
          EXPORT_TYPE_DRAG_COPY,
          processCellFromClipboardFunc,
          true
        );
        rowNode.setDataValue(column, newValue, SOURCE_PASTE);
        if (changedPath) {
          changedPath.addParentNode(rowNode.parent, [column]);
        }
        const { rowIndex, rowPinned } = currentRow;
        const cellId = this.cellPositionUtils.createIdFromValues({ rowIndex, column, rowPinned });
        cellsToFlash[cellId] = true;
      });
      dataRowIndex++;
    };
    this.iterateActiveRanges(false, rowCallback);
  }
  getDisplayedColumnsStartingAt(column) {
    let currentColumn = column;
    const columns = [];
    while (currentColumn != null) {
      columns.push(currentColumn);
      currentColumn = this.columnModel.getDisplayedColAfter(currentColumn);
    }
    return columns;
  }
  pasteStartingFromFocusedCell(parsedData, cellsToFlash, updatedRowNodes, focusedCell, changedPath) {
    if (!focusedCell) {
      return;
    }
    const currentRow = { rowIndex: focusedCell.rowIndex, rowPinned: focusedCell.rowPinned };
    const columnsToPasteInto = this.getDisplayedColumnsStartingAt(focusedCell.column);
    if (this.isPasteSingleValueIntoRange(parsedData)) {
      this.pasteSingleValueIntoRange(parsedData, updatedRowNodes, cellsToFlash, changedPath);
    } else {
      this.pasteMultipleValues(
        parsedData,
        currentRow,
        updatedRowNodes,
        columnsToPasteInto,
        cellsToFlash,
        EXPORT_TYPE_CLIPBOARD,
        changedPath
      );
    }
  }
  // if range is active, and only one cell, then we paste this cell into all cells in the active range.
  isPasteSingleValueIntoRange(parsedData) {
    return this.hasOnlyOneValueToPaste(parsedData) && this.rangeService != null && !this.rangeService.isEmpty();
  }
  pasteSingleValueIntoRange(parsedData, updatedRowNodes, cellsToFlash, changedPath) {
    const value = parsedData[0][0];
    const rowCallback = (currentRow, rowNode, columns) => {
      updatedRowNodes.push(rowNode);
      columns.forEach((column) => this.updateCellValue(rowNode, column, value, cellsToFlash, EXPORT_TYPE_CLIPBOARD, changedPath));
    };
    this.iterateActiveRanges(false, rowCallback);
  }
  hasOnlyOneValueToPaste(parsedData) {
    return parsedData.length === 1 && parsedData[0].length === 1;
  }
  copyRangeDown() {
    if (!this.rangeService || this.rangeService.isEmpty()) {
      return;
    }
    const firstRowValues = [];
    const pasteOperation = (cellsToFlash, updatedRowNodes, focusedCell, changedPath) => {
      const processCellForClipboardFunc = this.gos.getCallback("processCellForClipboard");
      const processCellFromClipboardFunc = this.gos.getCallback("processCellFromClipboard");
      const rowCallback = (currentRow, rowNode, columns) => {
        if (!firstRowValues.length) {
          columns.forEach((column) => {
            const value = this.processCell(
              rowNode,
              column,
              this.valueService.getValue(column, rowNode),
              EXPORT_TYPE_DRAG_COPY,
              processCellForClipboardFunc,
              false,
              true
            );
            firstRowValues.push(value);
          });
        } else {
          updatedRowNodes.push(rowNode);
          columns.forEach((column, index) => {
            if (!column.isCellEditable(rowNode) || column.isSuppressPaste(rowNode)) {
              return;
            }
            const firstRowValue = this.processCell(
              rowNode,
              column,
              firstRowValues[index],
              EXPORT_TYPE_DRAG_COPY,
              processCellFromClipboardFunc,
              true
            );
            rowNode.setDataValue(column, firstRowValue, SOURCE_PASTE);
            if (changedPath) {
              changedPath.addParentNode(rowNode.parent, [column]);
            }
            const { rowIndex, rowPinned } = currentRow;
            const cellId = this.cellPositionUtils.createIdFromValues({ rowIndex, column, rowPinned });
            cellsToFlash[cellId] = true;
          });
        }
      };
      this.iterateActiveRanges(true, rowCallback);
    };
    this.doPasteOperation(pasteOperation);
  }
  removeLastLineIfBlank(parsedData) {
    const lastLine = _16.last(parsedData);
    const lastLineIsBlank = lastLine && lastLine.length === 1 && lastLine[0] === "";
    if (lastLineIsBlank) {
      if (parsedData.length === 1) {
        return;
      }
      _16.removeFromArray(parsedData, lastLine);
    }
  }
  fireRowChanged(rowNodes) {
    if (this.gos.get("editType") !== "fullRow") {
      return;
    }
    rowNodes.forEach((rowNode) => {
      const event = {
        type: Events11.EVENT_ROW_VALUE_CHANGED,
        node: rowNode,
        data: rowNode.data,
        rowIndex: rowNode.rowIndex,
        rowPinned: rowNode.rowPinned
      };
      this.eventService.dispatchEvent(event);
    });
  }
  pasteMultipleValues(clipboardGridData, currentRow, updatedRowNodes, columnsToPasteInto, cellsToFlash, type, changedPath) {
    let rowPointer = currentRow;
    const skipGroupRows = this.clientSideRowModel != null && !this.gos.get("enableGroupEdit") && !this.gos.get("treeData");
    const getNextGoodRowNode = () => {
      while (true) {
        if (!rowPointer) {
          return null;
        }
        const res = this.rowPositionUtils.getRowNode(rowPointer);
        rowPointer = this.cellNavigationService.getRowBelow({ rowPinned: rowPointer.rowPinned, rowIndex: rowPointer.rowIndex });
        if (res == null) {
          return null;
        }
        const skipRow = res.detail || res.footer || skipGroupRows && res.group;
        if (!skipRow) {
          return res;
        }
      }
    };
    clipboardGridData.forEach((clipboardRowData) => {
      const rowNode = getNextGoodRowNode();
      if (!rowNode) {
        return;
      }
      clipboardRowData.forEach((value, index) => this.updateCellValue(rowNode, columnsToPasteInto[index], value, cellsToFlash, type, changedPath));
      updatedRowNodes.push(rowNode);
    });
  }
  updateCellValue(rowNode, column, value, cellsToFlash, type, changedPath) {
    if (!rowNode || !column || !column.isCellEditable(rowNode) || column.isSuppressPaste(rowNode)) {
      return;
    }
    if (rowNode.group && column.isValueActive()) {
      return;
    }
    const processedValue = this.processCell(rowNode, column, value, type, this.gos.getCallback("processCellFromClipboard"), true);
    rowNode.setDataValue(column, processedValue, SOURCE_PASTE);
    const { rowIndex, rowPinned } = rowNode;
    const cellId = this.cellPositionUtils.createIdFromValues({ rowIndex, column, rowPinned });
    cellsToFlash[cellId] = true;
    if (changedPath) {
      changedPath.addParentNode(rowNode.parent, [column]);
    }
  }
  copyToClipboard(params = {}) {
    this.copyOrCutToClipboard(params);
  }
  cutToClipboard(params = {}, source = "api") {
    if (this.gos.get("suppressCutToClipboard")) {
      return;
    }
    const startEvent = {
      type: Events11.EVENT_CUT_START,
      source
    };
    this.eventService.dispatchEvent(startEvent);
    this.copyOrCutToClipboard(params, true);
    const endEvent = {
      type: Events11.EVENT_CUT_END,
      source
    };
    this.eventService.dispatchEvent(endEvent);
  }
  copyOrCutToClipboard(params, cut) {
    let { includeHeaders, includeGroupHeaders } = params;
    this.logger.log(`copyToClipboard: includeHeaders = ${includeHeaders}`);
    if (includeHeaders == null) {
      includeHeaders = this.gos.get("copyHeadersToClipboard");
    }
    if (includeGroupHeaders == null) {
      includeGroupHeaders = this.gos.get("copyGroupHeadersToClipboard");
    }
    const copyParams = { includeHeaders, includeGroupHeaders };
    const shouldCopyRows = !this.gos.get("suppressCopyRowsToClipboard");
    let cellClearType = null;
    if (this.rangeService && !this.rangeService.isEmpty() && !this.shouldSkipSingleCellRange(this.rangeService)) {
      this.copySelectedRangeToClipboard(copyParams);
      cellClearType = 0;
    } else if (shouldCopyRows && !this.selectionService.isEmpty()) {
      this.copySelectedRowsToClipboard(copyParams);
      cellClearType = 1;
    } else if (this.focusService.isAnyCellFocused()) {
      this.copyFocusedCellToClipboard(copyParams);
      cellClearType = 2;
    }
    if (cut && cellClearType !== null) {
      this.clearCellsAfterCopy(cellClearType);
    }
  }
  clearCellsAfterCopy(type) {
    this.eventService.dispatchEvent({ type: Events11.EVENT_KEY_SHORTCUT_CHANGED_CELL_START });
    if (type === 0) {
      this.rangeService.clearCellRangeCellValues({ cellEventSource: "clipboardService" });
    } else if (type === 1) {
      this.clearSelectedRows();
    } else {
      const focusedCell = this.focusService.getFocusedCell();
      if (focusedCell == null) {
        return;
      }
      const rowNode = this.rowPositionUtils.getRowNode(focusedCell);
      if (rowNode) {
        this.clearCellValue(rowNode, focusedCell.column);
      }
    }
    this.eventService.dispatchEvent({ type: Events11.EVENT_KEY_SHORTCUT_CHANGED_CELL_END });
  }
  clearSelectedRows() {
    const selected = this.selectionService.getSelectedNodes();
    const columns = this.columnModel.getAllDisplayedColumns();
    for (const row of selected) {
      for (const col of columns) {
        this.clearCellValue(row, col);
      }
    }
  }
  clearCellValue(rowNode, column) {
    var _a;
    if (!column.isCellEditable(rowNode)) {
      return;
    }
    const emptyValue = (_a = this.valueService.parseValue(column, rowNode, "", rowNode.getValueFromValueService(column))) != null ? _a : null;
    rowNode.setDataValue(column, emptyValue, "clipboardService");
  }
  shouldSkipSingleCellRange(rangeService) {
    return this.gos.get("suppressCopySingleCellRanges") && !rangeService.isMoreThanOneCell();
  }
  iterateActiveRanges(onlyFirst, rowCallback, columnCallback) {
    if (!this.rangeService || this.rangeService.isEmpty()) {
      return;
    }
    const cellRanges = this.rangeService.getCellRanges();
    if (onlyFirst) {
      this.iterateActiveRange(cellRanges[0], rowCallback, columnCallback, true);
    } else {
      cellRanges.forEach((range, idx) => this.iterateActiveRange(range, rowCallback, columnCallback, idx === cellRanges.length - 1));
    }
  }
  iterateActiveRange(range, rowCallback, columnCallback, isLastRange) {
    if (!this.rangeService) {
      return;
    }
    let currentRow = this.rangeService.getRangeStartRow(range);
    const lastRow = this.rangeService.getRangeEndRow(range);
    if (columnCallback && range.columns) {
      columnCallback(range.columns);
    }
    let rangeIndex = 0;
    let isLastRow = false;
    while (!isLastRow && currentRow != null) {
      const rowNode = this.rowPositionUtils.getRowNode(currentRow);
      isLastRow = this.rowPositionUtils.sameRow(currentRow, lastRow);
      rowCallback(currentRow, rowNode, range.columns, rangeIndex++, isLastRow && isLastRange);
      currentRow = this.cellNavigationService.getRowBelow(currentRow);
    }
  }
  copySelectedRangeToClipboard(params = {}) {
    if (!this.rangeService || this.rangeService.isEmpty()) {
      return;
    }
    const allRangesMerge = this.rangeService.areAllRangesAbleToMerge();
    const { data, cellsToFlash } = allRangesMerge ? this.buildDataFromMergedRanges(this.rangeService, params) : this.buildDataFromRanges(this.rangeService, params);
    this.copyDataToClipboard(data);
    this.dispatchFlashCells(cellsToFlash);
  }
  buildDataFromMergedRanges(rangeService, params) {
    const columnsSet = /* @__PURE__ */ new Set();
    const ranges = rangeService.getCellRanges();
    const rowPositionsMap = /* @__PURE__ */ new Map();
    const allRowPositions = [];
    const allCellsToFlash = {};
    ranges.forEach((range) => {
      range.columns.forEach((col) => columnsSet.add(col));
      const { rowPositions, cellsToFlash } = this.getRangeRowPositionsAndCellsToFlash(rangeService, range);
      rowPositions.forEach((rowPosition) => {
        const rowPositionAsString = `${rowPosition.rowIndex}-${rowPosition.rowPinned || "null"}`;
        if (!rowPositionsMap.get(rowPositionAsString)) {
          rowPositionsMap.set(rowPositionAsString, true);
          allRowPositions.push(rowPosition);
        }
      });
      Object.assign(allCellsToFlash, cellsToFlash);
    });
    const allColumns = this.columnModel.getAllDisplayedColumns();
    const exportedColumns = Array.from(columnsSet);
    exportedColumns.sort((a, b) => {
      const posA = allColumns.indexOf(a);
      const posB = allColumns.indexOf(b);
      return posA - posB;
    });
    const data = this.buildExportParams({
      columns: exportedColumns,
      rowPositions: allRowPositions,
      includeHeaders: params.includeHeaders,
      includeGroupHeaders: params.includeGroupHeaders
    });
    return { data, cellsToFlash: allCellsToFlash };
  }
  buildDataFromRanges(rangeService, params) {
    const ranges = rangeService.getCellRanges();
    const data = [];
    const allCellsToFlash = {};
    ranges.forEach((range) => {
      const { rowPositions, cellsToFlash } = this.getRangeRowPositionsAndCellsToFlash(rangeService, range);
      Object.assign(allCellsToFlash, cellsToFlash);
      data.push(this.buildExportParams({
        columns: range.columns,
        rowPositions,
        includeHeaders: params.includeHeaders,
        includeGroupHeaders: params.includeGroupHeaders
      }));
    });
    return { data: data.join("\n"), cellsToFlash: allCellsToFlash };
  }
  getRangeRowPositionsAndCellsToFlash(rangeService, range) {
    const rowPositions = [];
    const cellsToFlash = {};
    const startRow = rangeService.getRangeStartRow(range);
    const lastRow = rangeService.getRangeEndRow(range);
    let node = startRow;
    while (node) {
      rowPositions.push(node);
      range.columns.forEach((column) => {
        const { rowIndex, rowPinned } = node;
        const cellId = this.cellPositionUtils.createIdFromValues({ rowIndex, column, rowPinned });
        cellsToFlash[cellId] = true;
      });
      if (this.rowPositionUtils.sameRow(node, lastRow)) {
        break;
      }
      node = this.cellNavigationService.getRowBelow(node);
    }
    return { rowPositions, cellsToFlash };
  }
  getCellsToFlashFromRowNodes(rowNodes) {
    const allDisplayedColumns = this.columnModel.getAllDisplayedColumns();
    const cellsToFlash = {};
    for (let i = 0; i < rowNodes.length; i++) {
      const { rowIndex, rowPinned } = rowNodes[i];
      if (rowIndex == null) {
        continue;
      }
      for (let j = 0; j < allDisplayedColumns.length; j++) {
        const column = allDisplayedColumns[j];
        const cellId = this.cellPositionUtils.createIdFromValues({ rowIndex, column, rowPinned });
        cellsToFlash[cellId] = true;
      }
    }
    return cellsToFlash;
  }
  copyFocusedCellToClipboard(params = {}) {
    const focusedCell = this.focusService.getFocusedCell();
    if (focusedCell == null) {
      return;
    }
    const cellId = this.cellPositionUtils.createId(focusedCell);
    const currentRow = { rowPinned: focusedCell.rowPinned, rowIndex: focusedCell.rowIndex };
    const column = focusedCell.column;
    const data = this.buildExportParams({
      columns: [column],
      rowPositions: [currentRow],
      includeHeaders: params.includeHeaders,
      includeGroupHeaders: params.includeGroupHeaders
    });
    this.copyDataToClipboard(data);
    this.dispatchFlashCells({ [cellId]: true });
  }
  copySelectedRowsToClipboard(params = {}) {
    const { columnKeys, includeHeaders, includeGroupHeaders } = params;
    const data = this.buildExportParams({
      columns: columnKeys,
      includeHeaders,
      includeGroupHeaders
    });
    this.copyDataToClipboard(data);
    const rowNodes = this.selectionService.getSelectedNodes() || [];
    this.dispatchFlashCells(this.getCellsToFlashFromRowNodes(rowNodes));
  }
  buildExportParams(params) {
    const { columns, rowPositions, includeHeaders = false, includeGroupHeaders = false } = params;
    const exportParams = {
      columnKeys: columns,
      rowPositions,
      skipColumnHeaders: !includeHeaders,
      skipColumnGroupHeaders: !includeGroupHeaders,
      suppressQuotes: true,
      columnSeparator: this.getClipboardDelimiter(),
      onlySelected: !rowPositions,
      processCellCallback: this.gos.getCallback("processCellForClipboard"),
      processRowGroupCallback: (params2) => this.processRowGroupCallback(params2),
      processHeaderCallback: this.gos.getCallback("processHeaderForClipboard"),
      processGroupHeaderCallback: this.gos.getCallback("processGroupHeaderForClipboard")
    };
    return this.csvCreator.getDataAsCsv(exportParams, true);
  }
  processRowGroupCallback(params) {
    const { node, column } = params;
    const isTreeData = this.gos.get("treeData");
    const isSuppressGroupMaintainValueType = this.gos.get("suppressGroupMaintainValueType");
    const getValueFromNode = () => {
      var _a, _b;
      if (isTreeData || isSuppressGroupMaintainValueType || !column) {
        return node.key;
      }
      const value2 = (_a = node.groupData) == null ? void 0 : _a[column.getId()];
      if (!value2 || !node.rowGroupColumn || node.rowGroupColumn.getColDef().useValueFormatterForExport === false) {
        return value2;
      }
      return (_b = this.valueService.formatValue(node.rowGroupColumn, node, value2)) != null ? _b : value2;
    };
    let value = getValueFromNode();
    if (params.node.footer) {
      let suffix = "";
      if (value && value.length) {
        suffix = ` ${value}`;
      }
      value = `Total${suffix}`;
    }
    const processCellForClipboard = this.gos.getCallback("processCellForClipboard");
    if (processCellForClipboard) {
      let column2 = node.rowGroupColumn;
      if (!column2 && node.footer && node.level === -1) {
        column2 = this.columnModel.getRowGroupColumns()[0];
      }
      return processCellForClipboard({
        value,
        node,
        column: column2,
        type: "clipboard",
        formatValue: (valueToFormat) => {
          var _a;
          return (_a = this.valueService.formatValue(column2, node, valueToFormat)) != null ? _a : valueToFormat;
        },
        parseValue: (valueToParse) => this.valueService.parseValue(column2, node, valueToParse, this.valueService.getValue(column2, node))
      });
    }
    return value;
  }
  dispatchFlashCells(cellsToFlash) {
    window.setTimeout(() => {
      const event = {
        type: Events11.EVENT_FLASH_CELLS,
        cells: cellsToFlash
      };
      this.eventService.dispatchEvent(event);
    }, 0);
  }
  processCell(rowNode, column, value, type, func, canParse, canFormat) {
    var _a;
    if (func) {
      const params = {
        column,
        node: rowNode,
        value,
        type,
        formatValue: (valueToFormat) => {
          var _a2;
          return (_a2 = this.valueService.formatValue(column, rowNode != null ? rowNode : null, valueToFormat)) != null ? _a2 : valueToFormat;
        },
        parseValue: (valueToParse) => this.valueService.parseValue(column, rowNode != null ? rowNode : null, valueToParse, this.valueService.getValue(column, rowNode))
      };
      return func(params);
    }
    if (canParse && column.getColDef().useValueParserForImport !== false) {
      return this.valueService.parseValue(column, rowNode != null ? rowNode : null, value, this.valueService.getValue(column, rowNode));
    }
    if (canFormat && column.getColDef().useValueFormatterForExport !== false) {
      return (_a = this.valueService.formatValue(column, rowNode != null ? rowNode : null, value)) != null ? _a : value;
    }
    return value;
  }
  copyDataToClipboard(data) {
    const userProvidedFunc = this.gos.getCallback("sendToClipboard");
    if (userProvidedFunc) {
      userProvidedFunc({ data });
      return;
    }
    const allowNavigator = !this.gos.get("suppressClipboardApi");
    if (allowNavigator && navigator.clipboard) {
      navigator.clipboard.writeText(data).catch((e) => {
        _16.doOnce(() => {
          console.warn(e);
          console.warn(apiError("writeText"));
        }, "clipboardApiError");
        this.copyDataToClipboardLegacy(data);
      });
      return;
    }
    this.copyDataToClipboardLegacy(data);
  }
  copyDataToClipboardLegacy(data) {
    this.executeOnTempElement((element) => {
      const eDocument = this.gos.getDocument();
      const focusedElementBefore = this.gos.getActiveDomElement();
      element.value = data || " ";
      element.select();
      element.focus({ preventScroll: true });
      const result = eDocument.execCommand("copy");
      if (!result) {
        console.warn("AG Grid: Browser did not allow document.execCommand('copy'). Ensure api.copySelectedRowsToClipboard() is invoked via a user event, i.e. button click, otherwise the browser will prevent it for security reasons.");
      }
      if (focusedElementBefore != null && focusedElementBefore.focus != null) {
        focusedElementBefore.focus({ preventScroll: true });
      }
    });
  }
  executeOnTempElement(callbackNow, callbackAfter) {
    const eDoc = this.gos.getDocument();
    const eTempInput = eDoc.createElement("textarea");
    eTempInput.style.width = "1px";
    eTempInput.style.height = "1px";
    eTempInput.style.top = eDoc.documentElement.scrollTop + "px";
    eTempInput.style.left = eDoc.documentElement.scrollLeft + "px";
    eTempInput.style.position = "absolute";
    eTempInput.style.opacity = "0";
    const guiRoot = this.gridCtrl.getGui();
    guiRoot.appendChild(eTempInput);
    try {
      callbackNow(eTempInput);
    } catch (err) {
      console.warn("AG Grid: Browser does not support document.execCommand('copy') for clipboard operations");
    }
    if (callbackAfter) {
      window.setTimeout(() => {
        callbackAfter(eTempInput);
        guiRoot.removeChild(eTempInput);
      }, 100);
    } else {
      guiRoot.removeChild(eTempInput);
    }
  }
  getRangeSize(rangeService) {
    const ranges = rangeService.getCellRanges();
    let startRangeIndex = 0;
    let endRangeIndex = 0;
    if (ranges.length > 0) {
      startRangeIndex = rangeService.getRangeStartRow(ranges[0]).rowIndex;
      endRangeIndex = rangeService.getRangeEndRow(ranges[0]).rowIndex;
    }
    return startRangeIndex - endRangeIndex + 1;
  }
};
__decorateClass5([
  Autowired49("csvCreator")
], ClipboardService.prototype, "csvCreator", 2);
__decorateClass5([
  Autowired49("loggerFactory")
], ClipboardService.prototype, "loggerFactory", 2);
__decorateClass5([
  Autowired49("selectionService")
], ClipboardService.prototype, "selectionService", 2);
__decorateClass5([
  Autowired49("rowModel")
], ClipboardService.prototype, "rowModel", 2);
__decorateClass5([
  Autowired49("ctrlsService")
], ClipboardService.prototype, "ctrlsService", 2);
__decorateClass5([
  Autowired49("valueService")
], ClipboardService.prototype, "valueService", 2);
__decorateClass5([
  Autowired49("focusService")
], ClipboardService.prototype, "focusService", 2);
__decorateClass5([
  Autowired49("rowRenderer")
], ClipboardService.prototype, "rowRenderer", 2);
__decorateClass5([
  Autowired49("columnModel")
], ClipboardService.prototype, "columnModel", 2);
__decorateClass5([
  Autowired49("cellNavigationService")
], ClipboardService.prototype, "cellNavigationService", 2);
__decorateClass5([
  Autowired49("cellPositionUtils")
], ClipboardService.prototype, "cellPositionUtils", 2);
__decorateClass5([
  Autowired49("rowPositionUtils")
], ClipboardService.prototype, "rowPositionUtils", 2);
__decorateClass5([
  Optional3("rangeService")
], ClipboardService.prototype, "rangeService", 2);
__decorateClass5([
  PostConstruct50
], ClipboardService.prototype, "init", 1);
ClipboardService = __decorateClass5([
  Bean8("clipboardService")
], ClipboardService);
var VERSION5 = "31.3.2";
var ClipboardModule = {
  version: VERSION5,
  moduleName: ModuleNames5.ClipboardModule,
  beans: [ClipboardService],
  dependantModules: [
    EnterpriseCoreModule,
    CsvExportModule
  ]
};

// enterprise-modules/column-tool-panel/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames24 } from "ag-grid-community";
import {
  _ as _39,
  Autowired as Autowired55,
  Events as Events14,
  RefSelector as RefSelector17,
  KeyCode as KeyCode8,
  PostConstruct as PostConstruct56,
  Component as Component46
} from "ag-grid-community";
import {
  _ as _64,
  Autowired as Autowired64,
  Component as Component54,
  Events as Events54,
  ProvidedColumnGroup as ProvidedColumnGroup5,
  VirtualList as VirtualList2,
  PreDestroy as PreDestroy3
} from "ag-grid-community";
import {
  Autowired as Autowired412,
  BeanStub as BeanStub18,
  DragSourceType as DragSourceType24,
  Events as Events34,
  ProvidedColumnGroup as ProvidedColumnGroup4,
  PostConstruct as PostConstruct412,
  VirtualListDragFeature as VirtualListDragFeature2
} from "ag-grid-community";
import {
  _ as _310,
  Autowired as Autowired312,
  Column as Column3,
  Component as Component312,
  CssClassApplier,
  DragAndDropService as DragAndDropService6,
  DragSourceType as DragSourceType5,
  Events as Events26,
  KeyCode as KeyCode23,
  PostConstruct as PostConstruct312,
  RefSelector as RefSelector25,
  TouchListener
} from "ag-grid-community";
import {
  EventService
} from "ag-grid-community";
import {
  AgMenuItemComponent as AgMenuItemComponent2,
  AgMenuList as AgMenuList2,
  Autowired as Autowired212,
  Column as Column2,
  Component as Component211,
  PostConstruct as PostConstruct212,
  ProvidedColumnGroup as ProvidedColumnGroup2,
  _ as _211
} from "ag-grid-community";
import {
  _ as _54,
  Autowired as Autowired56,
  Column as Column5,
  Component as Component47,
  CssClassApplier as CssClassApplier2,
  DragAndDropService as DragAndDropService23,
  DragSourceType as DragSourceType32,
  Events as Events44,
  KeyCode as KeyCode32,
  PostConstruct as PostConstruct57,
  RefSelector as RefSelector35
} from "ag-grid-community";
import {
  _ as _73,
  Component as Component83,
  Events as Events74,
  ModuleNames as ModuleNames8,
  ModuleRegistry as ModuleRegistry3
} from "ag-grid-community";
import {
  Autowired as Autowired74,
  Component as Component63,
  Events as Events64,
  PreConstruct as PreConstruct2,
  RefSelector as RefSelector43
} from "ag-grid-community";

// enterprise-modules/row-grouping/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames6 } from "ag-grid-community";
import {
  Bean as Bean9,
  BeanStub as BeanStub16,
  Autowired as Autowired50,
  _ as _20
} from "ag-grid-community";
import {
  _ as _29,
  Autowired as Autowired210,
  Bean as Bean25,
  BeanStub as BeanStub25,
  RowNode as RowNode2
} from "ag-grid-community";
import {
  Autowired as Autowired310,
  Bean as Bean33,
  BeanStub as BeanStub33,
  PostConstruct as PostConstruct51,
  _ as _37
} from "ag-grid-community";
import {
  Autowired as Autowired410,
  Bean as Bean43,
  BeanStub as BeanStub43,
  Events as Events12,
  _ as _44
} from "ag-grid-community";
import {
  Bean as Bean52,
  BeanStub as BeanStub53,
  PostConstruct as PostConstruct210,
  _ as _53
} from "ag-grid-community";
import {
  Autowired as Autowired73,
  Component as Component27,
  Events as Events63,
  PostConstruct as PostConstruct53,
  _ as _103
} from "ag-grid-community";
import {
  _ as _83,
  DragAndDropService as DragAndDropService22,
  Events as Events43,
  PostConstruct as PostConstruct310
} from "ag-grid-community";
import {
  PillDropZonePanel as PillDropZonePanel2,
  Autowired as Autowired63,
  Events as Events33,
  DragSourceType as DragSourceType23
} from "ag-grid-community";
import {
  Component as Component43,
  Autowired as Autowired53,
  Events as Events24,
  Column as Column6,
  RefSelector as RefSelector15,
  Optional as Optional4,
  VirtualList,
  KeyCode as KeyCode6,
  _ as _63,
  PillDragComp as PillDragComp2,
  DragSourceType as DragSourceType4,
  DragAndDropService as DragAndDropService5
} from "ag-grid-community";
import {
  _ as _93,
  DragAndDropService as DragAndDropService32,
  Events as Events53,
  PostConstruct as PostConstruct410
} from "ag-grid-community";
import {
  Autowired as Autowired83,
  Bean as Bean62,
  BeanStub as BeanStub63
} from "ag-grid-community";
import {
  _ as _113,
  AgPromise as AgPromise3,
  AgSelect as AgSelect5,
  Autowired as Autowired93,
  Events as Events73,
  PostConstruct as PostConstruct63,
  RefSelector as RefSelector23,
  TabGuardComp as TabGuardComp3,
  FilterWrapperComp
} from "ag-grid-community";
import {
  _ as _123,
  AgInputTextField as AgInputTextField2,
  AgPromise as AgPromise22,
  Autowired as Autowired103,
  Column as Column11,
  Component as Component310,
  RefSelector as RefSelector34
} from "ag-grid-community";
import {
  _ as _132,
  DragAndDropService as DragAndDropService42,
  Events as Events82,
  PostConstruct as PostConstruct73
} from "ag-grid-community";
var __defProp6 = Object.defineProperty;
var __defProps4 = Object.defineProperties;
var __getOwnPropDesc6 = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs4 = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols4 = Object.getOwnPropertySymbols;
var __hasOwnProp5 = Object.prototype.hasOwnProperty;
var __propIsEnum4 = Object.prototype.propertyIsEnumerable;
var __defNormalProp4 = (obj, key, value) => key in obj ? __defProp6(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues4 = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp5.call(b, prop))
      __defNormalProp4(a, prop, b[prop]);
  if (__getOwnPropSymbols4)
    for (var prop of __getOwnPropSymbols4(b)) {
      if (__propIsEnum4.call(b, prop))
        __defNormalProp4(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps4 = (a, b) => __defProps4(a, __getOwnPropDescs4(b));
var __decorateClass6 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc6(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp6(target, key, result);
  return result;
};
var AggregationStage = class extends BeanStub16 {
  // it's possible to recompute the aggregate without doing the other parts
  // + api.refreshClientSideRowModel('aggregate')
  execute(params) {
    const noValueColumns = _20.missingOrEmpty(this.columnModel.getValueColumns());
    const noUserAgg = !this.gos.getCallback("getGroupRowAgg");
    const changedPathActive = params.changedPath && params.changedPath.isActive();
    if (noValueColumns && noUserAgg && changedPathActive) {
      return;
    }
    const aggDetails = this.createAggDetails(params);
    this.recursivelyCreateAggData(aggDetails);
  }
  createAggDetails(params) {
    const pivotActive = this.columnModel.isPivotActive();
    const measureColumns = this.columnModel.getValueColumns();
    const pivotColumns = pivotActive ? this.columnModel.getPivotColumns() : [];
    const aggDetails = {
      alwaysAggregateAtRootLevel: this.gos.get("alwaysAggregateAtRootLevel"),
      groupIncludeTotalFooter: !!this.gos.getGrandTotalRow(),
      changedPath: params.changedPath,
      valueColumns: measureColumns,
      pivotColumns,
      filteredOnly: !this.isSuppressAggFilteredOnly(),
      userAggFunc: this.gos.getCallback("getGroupRowAgg")
    };
    return aggDetails;
  }
  isSuppressAggFilteredOnly() {
    const isGroupAggFiltering = this.gos.getGroupAggFiltering() !== void 0;
    return isGroupAggFiltering || this.gos.get("suppressAggFilteredOnly");
  }
  recursivelyCreateAggData(aggDetails) {
    const callback = (rowNode) => {
      const hasNoChildren = !rowNode.hasChildren();
      if (hasNoChildren) {
        if (rowNode.aggData) {
          rowNode.setAggData(null);
        }
        return;
      }
      const isRootNode = rowNode.level === -1;
      if (isRootNode && !aggDetails.groupIncludeTotalFooter) {
        const notPivoting = !this.columnModel.isPivotMode();
        if (!aggDetails.alwaysAggregateAtRootLevel && notPivoting) {
          rowNode.setAggData(null);
          return;
        }
      }
      this.aggregateRowNode(rowNode, aggDetails);
    };
    aggDetails.changedPath.forEachChangedNodeDepthFirst(callback, true);
  }
  aggregateRowNode(rowNode, aggDetails) {
    const measureColumnsMissing = aggDetails.valueColumns.length === 0;
    const pivotColumnsMissing = aggDetails.pivotColumns.length === 0;
    let aggResult;
    if (aggDetails.userAggFunc) {
      aggResult = aggDetails.userAggFunc({ nodes: rowNode.childrenAfterFilter });
    } else if (measureColumnsMissing) {
      aggResult = null;
    } else if (pivotColumnsMissing) {
      aggResult = this.aggregateRowNodeUsingValuesOnly(rowNode, aggDetails);
    } else {
      aggResult = this.aggregateRowNodeUsingValuesAndPivot(rowNode);
    }
    rowNode.setAggData(aggResult);
    if (rowNode.sibling) {
      rowNode.sibling.setAggData(aggResult);
    }
  }
  aggregateRowNodeUsingValuesAndPivot(rowNode) {
    var _a, _b;
    const result = {};
    const secondaryColumns = (_a = this.columnModel.getSecondaryColumns()) != null ? _a : [];
    let canSkipTotalColumns = true;
    for (let i = 0; i < secondaryColumns.length; i++) {
      const secondaryCol = secondaryColumns[i];
      const colDef = secondaryCol.getColDef();
      if (colDef.pivotTotalColumnIds != null) {
        canSkipTotalColumns = false;
        continue;
      }
      const keys = (_b = colDef.pivotKeys) != null ? _b : [];
      let values;
      if (rowNode.leafGroup) {
        values = this.getValuesFromMappedSet(rowNode.childrenMapped, keys, colDef.pivotValueColumn);
      } else {
        values = this.getValuesPivotNonLeaf(rowNode, colDef.colId);
      }
      result[colDef.colId] = this.aggregateValues(values, colDef.pivotValueColumn.getAggFunc(), colDef.pivotValueColumn, rowNode, secondaryCol);
    }
    if (!canSkipTotalColumns) {
      for (let i = 0; i < secondaryColumns.length; i++) {
        const secondaryCol = secondaryColumns[i];
        const colDef = secondaryCol.getColDef();
        if (colDef.pivotTotalColumnIds == null || !colDef.pivotTotalColumnIds.length) {
          continue;
        }
        const aggResults = colDef.pivotTotalColumnIds.map((currentColId) => result[currentColId]);
        result[colDef.colId] = this.aggregateValues(aggResults, colDef.pivotValueColumn.getAggFunc(), colDef.pivotValueColumn, rowNode, secondaryCol);
      }
    }
    return result;
  }
  aggregateRowNodeUsingValuesOnly(rowNode, aggDetails) {
    const result = {};
    const changedValueColumns = aggDetails.changedPath.isActive() ? aggDetails.changedPath.getValueColumnsForNode(rowNode, aggDetails.valueColumns) : aggDetails.valueColumns;
    const notChangedValueColumns = aggDetails.changedPath.isActive() ? aggDetails.changedPath.getNotValueColumnsForNode(rowNode, aggDetails.valueColumns) : null;
    const values2d = this.getValuesNormal(rowNode, changedValueColumns, aggDetails.filteredOnly);
    const oldValues = rowNode.aggData;
    changedValueColumns.forEach((valueColumn, index) => {
      result[valueColumn.getId()] = this.aggregateValues(values2d[index], valueColumn.getAggFunc(), valueColumn, rowNode);
    });
    if (notChangedValueColumns && oldValues) {
      notChangedValueColumns.forEach((valueColumn) => {
        result[valueColumn.getId()] = oldValues[valueColumn.getId()];
      });
    }
    return result;
  }
  getValuesPivotNonLeaf(rowNode, colId) {
    return rowNode.childrenAfterFilter.map((childNode) => childNode.aggData[colId]);
  }
  getValuesFromMappedSet(mappedSet, keys, valueColumn) {
    let mapPointer = mappedSet;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      mapPointer = mapPointer ? mapPointer[key] : null;
    }
    if (!mapPointer) {
      return [];
    }
    return mapPointer.map((rowNode) => this.valueService.getValue(valueColumn, rowNode));
  }
  getValuesNormal(rowNode, valueColumns, filteredOnly) {
    const values = [];
    valueColumns.forEach(() => values.push([]));
    const valueColumnCount = valueColumns.length;
    const nodeList = filteredOnly ? rowNode.childrenAfterFilter : rowNode.childrenAfterGroup;
    const rowCount = nodeList.length;
    for (let i = 0; i < rowCount; i++) {
      const childNode = nodeList[i];
      for (let j = 0; j < valueColumnCount; j++) {
        const valueColumn = valueColumns[j];
        const value = this.valueService.getValue(valueColumn, childNode);
        values[j].push(value);
      }
    }
    return values;
  }
  aggregateValues(values, aggFuncOrString, column, rowNode, pivotResultColumn) {
    const aggFunc = typeof aggFuncOrString === "string" ? this.aggFuncService.getAggFunc(aggFuncOrString) : aggFuncOrString;
    if (typeof aggFunc !== "function") {
      console.error(`AG Grid: unrecognised aggregation function ${aggFuncOrString}`);
      return null;
    }
    const aggFuncAny = aggFunc;
    const params = this.gos.addGridCommonParams({
      values,
      column,
      colDef: column ? column.getColDef() : void 0,
      pivotResultColumn,
      rowNode,
      data: rowNode ? rowNode.data : void 0
    });
    return aggFuncAny(params);
  }
};
__decorateClass6([
  Autowired50("columnModel")
], AggregationStage.prototype, "columnModel", 2);
__decorateClass6([
  Autowired50("valueService")
], AggregationStage.prototype, "valueService", 2);
__decorateClass6([
  Autowired50("aggFuncService")
], AggregationStage.prototype, "aggFuncService", 2);
AggregationStage = __decorateClass6([
  Bean9("aggregationStage")
], AggregationStage);
var BatchRemover = class {
  constructor() {
    this.allSets = {};
    this.allParents = [];
  }
  removeFromChildrenAfterGroup(parent, child) {
    const set2 = this.getSet(parent);
    set2.removeFromChildrenAfterGroup[child.id] = true;
  }
  isRemoveFromAllLeafChildren(parent, child) {
    const set2 = this.getSet(parent);
    return !!set2.removeFromAllLeafChildren[child.id];
  }
  preventRemoveFromAllLeafChildren(parent, child) {
    const set2 = this.getSet(parent);
    delete set2.removeFromAllLeafChildren[child.id];
  }
  removeFromAllLeafChildren(parent, child) {
    const set2 = this.getSet(parent);
    set2.removeFromAllLeafChildren[child.id] = true;
  }
  getSet(parent) {
    if (!this.allSets[parent.id]) {
      this.allSets[parent.id] = {
        removeFromAllLeafChildren: {},
        removeFromChildrenAfterGroup: {}
      };
      this.allParents.push(parent);
    }
    return this.allSets[parent.id];
  }
  getAllParents() {
    return this.allParents;
  }
  flush() {
    this.allParents.forEach((parent) => {
      const nodeDetails = this.allSets[parent.id];
      parent.childrenAfterGroup = parent.childrenAfterGroup.filter(
        (child) => !nodeDetails.removeFromChildrenAfterGroup[child.id]
      );
      parent.allLeafChildren = parent.allLeafChildren.filter(
        (child) => !nodeDetails.removeFromAllLeafChildren[child.id]
      );
      parent.updateHasChildren();
      if (parent.sibling) {
        parent.sibling.childrenAfterGroup = parent.childrenAfterGroup;
        parent.sibling.allLeafChildren = parent.allLeafChildren;
      }
    });
    this.allSets = {};
    this.allParents.length = 0;
  }
};
var GroupStage = class extends BeanStub25 {
  execute(params) {
    const details = this.createGroupingDetails(params);
    if (details.transactions) {
      this.handleTransaction(details);
    } else {
      const afterColsChanged = params.afterColumnsChanged === true;
      this.shotgunResetEverything(details, afterColsChanged);
    }
    if (!details.usingTreeData) {
      this.positionLeafsAndGroups(params.changedPath);
      this.orderGroups(details);
    }
    this.selectableService.updateSelectableAfterGrouping();
  }
  positionLeafsAndGroups(changedPath) {
    changedPath.forEachChangedNodeDepthFirst((group) => {
      if (group.childrenAfterGroup) {
        const leafNodes = [];
        const groupNodes = [];
        let unbalancedNode;
        group.childrenAfterGroup.forEach((row) => {
          var _a;
          if (!((_a = row.childrenAfterGroup) == null ? void 0 : _a.length)) {
            leafNodes.push(row);
          } else {
            if (row.key === "" && !unbalancedNode) {
              unbalancedNode = row;
            } else {
              groupNodes.push(row);
            }
          }
        });
        if (unbalancedNode) {
          groupNodes.push(unbalancedNode);
        }
        group.childrenAfterGroup = [...leafNodes, ...groupNodes];
      }
    }, false);
  }
  createGroupingDetails(params) {
    var _a;
    const { rowNode, changedPath, rowNodeTransactions, rowNodeOrder } = params;
    const usingTreeData = this.gos.get("treeData");
    const groupedCols = usingTreeData ? null : this.columnModel.getRowGroupColumns();
    const details = {
      // someone complained that the parent attribute was causing some change detection
      // to break in an angular add-on.  Taking the parent out breaks a cyclic dependency, hence this flag got introduced.
      includeParents: !this.gos.get("suppressParentsInRowNodes"),
      expandByDefault: this.gos.get("groupDefaultExpanded"),
      groupedCols,
      rootNode: rowNode,
      pivotMode: this.columnModel.isPivotMode(),
      groupedColCount: usingTreeData || !groupedCols ? 0 : groupedCols.length,
      rowNodeOrder,
      transactions: rowNodeTransactions,
      // if no transaction, then it's shotgun, changed path would be 'not active' at this point anyway
      changedPath,
      groupAllowUnbalanced: this.gos.get("groupAllowUnbalanced"),
      isGroupOpenByDefault: this.gos.getCallback("isGroupOpenByDefault"),
      initialGroupOrderComparator: this.gos.getCallback("initialGroupOrderComparator"),
      usingTreeData,
      suppressGroupMaintainValueType: this.gos.get("suppressGroupMaintainValueType"),
      getDataPath: usingTreeData ? this.gos.get("getDataPath") : void 0,
      keyCreators: (_a = groupedCols == null ? void 0 : groupedCols.map((column) => column.getColDef().keyCreator)) != null ? _a : []
    };
    return details;
  }
  handleTransaction(details) {
    details.transactions.forEach((tran) => {
      const batchRemover = !details.usingTreeData ? new BatchRemover() : void 0;
      if (_29.existsAndNotEmpty(tran.remove)) {
        this.removeNodes(tran.remove, details, batchRemover);
      }
      if (_29.existsAndNotEmpty(tran.update)) {
        this.moveNodesInWrongPath(tran.update, details, batchRemover);
      }
      if (_29.existsAndNotEmpty(tran.add)) {
        this.insertNodes(tran.add, details, false);
      }
      if (batchRemover) {
        const parentsWithChildrenRemoved = batchRemover.getAllParents().slice();
        batchRemover.flush();
        this.removeEmptyGroups(parentsWithChildrenRemoved, details);
      }
    });
    if (details.rowNodeOrder) {
      this.sortChildren(details);
    }
  }
  // this is used when doing delta updates, eg Redux, keeps nodes in right order
  sortChildren(details) {
    details.changedPath.forEachChangedNodeDepthFirst((node) => {
      if (!node.childrenAfterGroup) {
        return;
      }
      const didSort = _29.sortRowNodesByOrder(node.childrenAfterGroup, details.rowNodeOrder);
      if (didSort) {
        details.changedPath.addParentNode(node);
      }
    }, false, true);
  }
  orderGroups(details) {
    const comparator = details.initialGroupOrderComparator;
    if (_29.exists(comparator)) {
      recursiveSort(details.rootNode);
    }
    function recursiveSort(rowNode) {
      const doSort = _29.exists(rowNode.childrenAfterGroup) && // we only want to sort groups, so we do not sort leafs (a leaf group has leafs as children)
      !rowNode.leafGroup;
      if (doSort) {
        rowNode.childrenAfterGroup.sort((nodeA, nodeB) => comparator({ nodeA, nodeB }));
        rowNode.childrenAfterGroup.forEach((childNode) => recursiveSort(childNode));
      }
    }
  }
  getExistingPathForNode(node, details) {
    const res = [];
    let pointer = details.usingTreeData ? node : node.parent;
    while (pointer && pointer !== details.rootNode) {
      res.push({
        key: pointer.key,
        rowGroupColumn: pointer.rowGroupColumn,
        field: pointer.field
      });
      pointer = pointer.parent;
    }
    res.reverse();
    return res;
  }
  moveNodesInWrongPath(childNodes, details, batchRemover) {
    childNodes.forEach((childNode) => {
      if (details.changedPath.isActive()) {
        details.changedPath.addParentNode(childNode.parent);
      }
      const infoToKeyMapper = (item) => item.key;
      const oldPath = this.getExistingPathForNode(childNode, details).map(infoToKeyMapper);
      const newPath = this.getGroupInfo(childNode, details).map(infoToKeyMapper);
      const nodeInCorrectPath = _29.areEqual(oldPath, newPath);
      if (!nodeInCorrectPath) {
        this.moveNode(childNode, details, batchRemover);
      }
    });
  }
  moveNode(childNode, details, batchRemover) {
    this.removeNodesInStages([childNode], details, batchRemover);
    this.insertOneNode(childNode, details, true, batchRemover);
    childNode.setData(childNode.data);
    if (details.changedPath.isActive()) {
      const newParent = childNode.parent;
      details.changedPath.addParentNode(newParent);
    }
  }
  removeNodes(leafRowNodes, details, batchRemover) {
    this.removeNodesInStages(leafRowNodes, details, batchRemover);
    if (details.changedPath.isActive()) {
      leafRowNodes.forEach((rowNode) => details.changedPath.addParentNode(rowNode.parent));
    }
  }
  removeNodesInStages(leafRowNodes, details, batchRemover) {
    this.removeNodesFromParents(leafRowNodes, details, batchRemover);
    if (details.usingTreeData) {
      this.postRemoveCreateFillerNodes(leafRowNodes, details);
      const nodeParents = leafRowNodes.map((n) => n.parent);
      this.removeEmptyGroups(nodeParents, details);
    }
  }
  forEachParentGroup(details, group, callback) {
    let pointer = group;
    while (pointer && pointer !== details.rootNode) {
      callback(pointer);
      pointer = pointer.parent;
    }
  }
  removeNodesFromParents(nodesToRemove, details, provided) {
    const batchRemoverIsLocal = provided == null;
    const batchRemoverToUse = provided ? provided : new BatchRemover();
    nodesToRemove.forEach((nodeToRemove) => {
      this.removeFromParent(nodeToRemove, batchRemoverToUse);
      this.forEachParentGroup(details, nodeToRemove.parent, (parentNode) => {
        batchRemoverToUse.removeFromAllLeafChildren(parentNode, nodeToRemove);
      });
    });
    if (batchRemoverIsLocal) {
      batchRemoverToUse.flush();
    }
  }
  postRemoveCreateFillerNodes(nodesToRemove, details) {
    nodesToRemove.forEach((nodeToRemove) => {
      const replaceWithGroup = nodeToRemove.hasChildren();
      if (replaceWithGroup) {
        const oldPath = this.getExistingPathForNode(nodeToRemove, details);
        const newGroupNode = this.findParentForNode(nodeToRemove, oldPath, details);
        newGroupNode.expanded = nodeToRemove.expanded;
        newGroupNode.allLeafChildren = nodeToRemove.allLeafChildren;
        newGroupNode.childrenAfterGroup = nodeToRemove.childrenAfterGroup;
        newGroupNode.childrenMapped = nodeToRemove.childrenMapped;
        newGroupNode.updateHasChildren();
        newGroupNode.childrenAfterGroup.forEach((rowNode) => rowNode.parent = newGroupNode);
      }
    });
  }
  removeEmptyGroups(possibleEmptyGroups, details) {
    let checkAgain = true;
    const groupShouldBeRemoved = (rowNode) => {
      const mapKey = this.getChildrenMappedKey(rowNode.key, rowNode.rowGroupColumn);
      const parentRowNode = rowNode.parent;
      const groupAlreadyRemoved = parentRowNode && parentRowNode.childrenMapped ? !parentRowNode.childrenMapped[mapKey] : true;
      if (groupAlreadyRemoved) {
        return false;
      }
      return !!rowNode.isEmptyRowGroupNode();
    };
    while (checkAgain) {
      checkAgain = false;
      const batchRemover = new BatchRemover();
      possibleEmptyGroups.forEach((possibleEmptyGroup) => {
        this.forEachParentGroup(details, possibleEmptyGroup, (rowNode) => {
          if (groupShouldBeRemoved(rowNode)) {
            checkAgain = true;
            this.removeFromParent(rowNode, batchRemover);
            rowNode.setSelectedParams({ newValue: false, source: "rowGroupChanged" });
          }
        });
      });
      batchRemover.flush();
    }
  }
  // removes the node from the parent by:
  // a) removing from childrenAfterGroup (using batchRemover if present, otherwise immediately)
  // b) removing from childrenMapped (immediately)
  // c) setRowTop(null) - as the rowRenderer uses this to know the RowNode is no longer needed
  // d) setRowIndex(null) - as the rowNode will no longer be displayed.
  removeFromParent(child, batchRemover) {
    if (child.parent) {
      if (batchRemover) {
        batchRemover.removeFromChildrenAfterGroup(child.parent, child);
      } else {
        _29.removeFromArray(child.parent.childrenAfterGroup, child);
        child.parent.updateHasChildren();
      }
    }
    const mapKey = this.getChildrenMappedKey(child.key, child.rowGroupColumn);
    if (child.parent && child.parent.childrenMapped) {
      child.parent.childrenMapped[mapKey] = void 0;
    }
    child.setRowTop(null);
    child.setRowIndex(null);
  }
  addToParent(child, parent) {
    const mapKey = this.getChildrenMappedKey(child.key, child.rowGroupColumn);
    if (parent) {
      const children = parent.childrenMapped != null;
      if (children) {
        parent.childrenMapped[mapKey] = child;
      }
      parent.childrenAfterGroup.push(child);
      parent.updateHasChildren();
    }
  }
  areGroupColsEqual(d1, d2) {
    if (d1 == null || d2 == null || d1.pivotMode !== d2.pivotMode) {
      return false;
    }
    return _29.areEqual(d1.groupedCols, d2.groupedCols) && _29.areEqual(d1.keyCreators, d2.keyCreators);
  }
  checkAllGroupDataAfterColsChanged(details) {
    const recurse = (rowNodes) => {
      if (!rowNodes) {
        return;
      }
      rowNodes.forEach((rowNode) => {
        const isLeafNode = !details.usingTreeData && !rowNode.group;
        if (isLeafNode) {
          return;
        }
        const groupInfo = {
          field: rowNode.field,
          key: rowNode.key,
          rowGroupColumn: rowNode.rowGroupColumn,
          leafNode: rowNode.allLeafChildren[0]
        };
        this.setGroupData(rowNode, groupInfo, details);
        recurse(rowNode.childrenAfterGroup);
      });
    };
    recurse(details.rootNode.childrenAfterGroup);
  }
  shotgunResetEverything(details, afterColumnsChanged) {
    if (this.noChangeInGroupingColumns(details, afterColumnsChanged)) {
      return;
    }
    this.selectionService.filterFromSelection((node) => node && !node.group);
    const { rootNode, groupedCols } = details;
    rootNode.leafGroup = details.usingTreeData ? false : groupedCols.length === 0;
    rootNode.childrenAfterGroup = [];
    rootNode.childrenMapped = {};
    rootNode.updateHasChildren();
    const sibling = rootNode.sibling;
    if (sibling) {
      sibling.childrenAfterGroup = rootNode.childrenAfterGroup;
      sibling.childrenMapped = rootNode.childrenMapped;
    }
    this.insertNodes(rootNode.allLeafChildren, details, false);
  }
  noChangeInGroupingColumns(details, afterColumnsChanged) {
    let noFurtherProcessingNeeded = false;
    const groupDisplayColumns = this.columnModel.getGroupDisplayColumns();
    const newGroupDisplayColIds = groupDisplayColumns ? groupDisplayColumns.map((c) => c.getId()).join("-") : "";
    if (afterColumnsChanged) {
      noFurtherProcessingNeeded = details.usingTreeData || this.areGroupColsEqual(details, this.oldGroupingDetails);
      if (this.oldGroupDisplayColIds !== newGroupDisplayColIds) {
        this.checkAllGroupDataAfterColsChanged(details);
      }
    }
    this.oldGroupingDetails = details;
    this.oldGroupDisplayColIds = newGroupDisplayColIds;
    return noFurtherProcessingNeeded;
  }
  insertNodes(newRowNodes, details, isMove) {
    newRowNodes.forEach((rowNode) => {
      this.insertOneNode(rowNode, details, isMove);
      if (details.changedPath.isActive()) {
        details.changedPath.addParentNode(rowNode.parent);
      }
    });
  }
  insertOneNode(childNode, details, isMove, batchRemover) {
    const path = this.getGroupInfo(childNode, details);
    const parentGroup = this.findParentForNode(childNode, path, details, batchRemover);
    if (!parentGroup.group) {
      console.warn(
        `AG Grid: duplicate group keys for row data, keys should be unique`,
        [parentGroup.data, childNode.data]
      );
    }
    if (details.usingTreeData) {
      this.swapGroupWithUserNode(parentGroup, childNode, isMove);
    } else {
      childNode.parent = parentGroup;
      childNode.level = path.length;
      parentGroup.childrenAfterGroup.push(childNode);
      parentGroup.updateHasChildren();
    }
  }
  findParentForNode(childNode, path, details, batchRemover) {
    let nextNode = details.rootNode;
    path.forEach((groupInfo, level) => {
      nextNode = this.getOrCreateNextNode(nextNode, groupInfo, level, details);
      if (!(batchRemover == null ? void 0 : batchRemover.isRemoveFromAllLeafChildren(nextNode, childNode))) {
        nextNode.allLeafChildren.push(childNode);
      } else {
        batchRemover == null ? void 0 : batchRemover.preventRemoveFromAllLeafChildren(nextNode, childNode);
      }
    });
    return nextNode;
  }
  swapGroupWithUserNode(fillerGroup, userGroup, isMove) {
    userGroup.parent = fillerGroup.parent;
    userGroup.key = fillerGroup.key;
    userGroup.field = fillerGroup.field;
    userGroup.groupData = fillerGroup.groupData;
    userGroup.level = fillerGroup.level;
    if (!isMove) {
      userGroup.expanded = fillerGroup.expanded;
    }
    userGroup.leafGroup = fillerGroup.leafGroup;
    userGroup.rowGroupIndex = fillerGroup.rowGroupIndex;
    userGroup.allLeafChildren = fillerGroup.allLeafChildren;
    userGroup.childrenAfterGroup = fillerGroup.childrenAfterGroup;
    userGroup.childrenMapped = fillerGroup.childrenMapped;
    userGroup.sibling = fillerGroup.sibling;
    userGroup.updateHasChildren();
    this.removeFromParent(fillerGroup);
    userGroup.childrenAfterGroup.forEach((rowNode) => rowNode.parent = userGroup);
    this.addToParent(userGroup, fillerGroup.parent);
  }
  getOrCreateNextNode(parentGroup, groupInfo, level, details) {
    const key = this.getChildrenMappedKey(groupInfo.key, groupInfo.rowGroupColumn);
    let nextNode = parentGroup.childrenMapped ? parentGroup.childrenMapped[key] : void 0;
    if (!nextNode) {
      nextNode = this.createGroup(groupInfo, parentGroup, level, details);
      this.addToParent(nextNode, parentGroup);
    }
    return nextNode;
  }
  createGroup(groupInfo, parent, level, details) {
    const groupNode = new RowNode2(this.beans);
    groupNode.group = true;
    groupNode.field = groupInfo.field;
    groupNode.rowGroupColumn = groupInfo.rowGroupColumn;
    this.setGroupData(groupNode, groupInfo, details);
    groupNode.key = groupInfo.key;
    groupNode.id = this.createGroupId(groupNode, parent, details.usingTreeData, level);
    groupNode.level = level;
    groupNode.leafGroup = details.usingTreeData ? false : level === details.groupedColCount - 1;
    groupNode.allLeafChildren = [];
    groupNode.setAllChildrenCount(0);
    groupNode.rowGroupIndex = details.usingTreeData ? null : level;
    groupNode.childrenAfterGroup = [];
    groupNode.childrenMapped = {};
    groupNode.updateHasChildren();
    groupNode.parent = details.includeParents ? parent : null;
    this.setExpandedInitialValue(details, groupNode);
    return groupNode;
  }
  createGroupId(node, parent, usingTreeData, level) {
    let createGroupId;
    if (usingTreeData) {
      createGroupId = (node2, parent2, level2) => {
        if (level2 < 0) {
          return null;
        }
        const parentId = parent2 ? createGroupId(parent2, parent2.parent, level2 - 1) : null;
        return `${parentId == null ? "" : parentId + "-"}${level2}-${node2.key}`;
      };
    } else {
      createGroupId = (node2, parent2) => {
        if (!node2.rowGroupColumn) {
          return null;
        }
        const parentId = parent2 ? createGroupId(parent2, parent2.parent, 0) : null;
        return `${parentId == null ? "" : parentId + "-"}${node2.rowGroupColumn.getColId()}-${node2.key}`;
      };
    }
    return RowNode2.ID_PREFIX_ROW_GROUP + createGroupId(node, parent, level);
  }
  setGroupData(groupNode, groupInfo, details) {
    groupNode.groupData = {};
    const groupDisplayCols = this.columnModel.getGroupDisplayColumns();
    groupDisplayCols.forEach((col) => {
      const isTreeData = details.usingTreeData;
      if (isTreeData) {
        groupNode.groupData[col.getColId()] = groupInfo.key;
        return;
      }
      const groupColumn = groupNode.rowGroupColumn;
      const isRowGroupDisplayed = groupColumn !== null && col.isRowGroupDisplayed(groupColumn.getId());
      if (isRowGroupDisplayed) {
        if (details.suppressGroupMaintainValueType) {
          groupNode.groupData[col.getColId()] = groupInfo.key;
        } else {
          groupNode.groupData[col.getColId()] = this.valueService.getValue(groupColumn, groupInfo.leafNode);
        }
      }
    });
  }
  getChildrenMappedKey(key, rowGroupColumn) {
    if (rowGroupColumn) {
      return rowGroupColumn.getId() + "-" + key;
    }
    return key;
  }
  setExpandedInitialValue(details, groupNode) {
    if (details.pivotMode && groupNode.leafGroup) {
      groupNode.expanded = false;
      return;
    }
    const userCallback = details.isGroupOpenByDefault;
    if (userCallback) {
      const params = {
        rowNode: groupNode,
        field: groupNode.field,
        key: groupNode.key,
        level: groupNode.level,
        rowGroupColumn: groupNode.rowGroupColumn
      };
      groupNode.expanded = userCallback(params) == true;
      return;
    }
    const { expandByDefault } = details;
    if (details.expandByDefault === -1) {
      groupNode.expanded = true;
      return;
    }
    groupNode.expanded = groupNode.level < expandByDefault;
  }
  getGroupInfo(rowNode, details) {
    if (details.usingTreeData) {
      return this.getGroupInfoFromCallback(rowNode, details);
    }
    return this.getGroupInfoFromGroupColumns(rowNode, details);
  }
  getGroupInfoFromCallback(rowNode, details) {
    const keys = details.getDataPath ? details.getDataPath(rowNode.data) : null;
    if (keys === null || keys === void 0 || keys.length === 0) {
      _29.warnOnce(`getDataPath() should not return an empty path for data ${rowNode.data}`);
    }
    const groupInfoMapper = (key) => ({ key, field: null, rowGroupColumn: null });
    return keys ? keys.map(groupInfoMapper) : [];
  }
  getGroupInfoFromGroupColumns(rowNode, details) {
    const res = [];
    details.groupedCols.forEach((groupCol) => {
      let key = this.valueService.getKeyForNode(groupCol, rowNode);
      let keyExists = key !== null && key !== void 0 && key !== "";
      const createGroupForEmpty = details.pivotMode || !details.groupAllowUnbalanced;
      if (createGroupForEmpty && !keyExists) {
        key = "";
        keyExists = true;
      }
      if (keyExists) {
        const item = {
          key,
          field: groupCol.getColDef().field,
          rowGroupColumn: groupCol,
          leafNode: rowNode
        };
        res.push(item);
      }
    });
    return res;
  }
};
__decorateClass6([
  Autowired210("columnModel")
], GroupStage.prototype, "columnModel", 2);
__decorateClass6([
  Autowired210("selectableService")
], GroupStage.prototype, "selectableService", 2);
__decorateClass6([
  Autowired210("valueService")
], GroupStage.prototype, "valueService", 2);
__decorateClass6([
  Autowired210("beans")
], GroupStage.prototype, "beans", 2);
__decorateClass6([
  Autowired210("selectionService")
], GroupStage.prototype, "selectionService", 2);
GroupStage = __decorateClass6([
  Bean25("groupStage")
], GroupStage);
var PivotColDefService = class extends BeanStub33 {
  init() {
    const getFieldSeparator = () => {
      var _a;
      return (_a = this.gos.get("serverSidePivotResultFieldSeparator")) != null ? _a : "_";
    };
    this.fieldSeparator = getFieldSeparator();
    this.addManagedPropertyListener("serverSidePivotResultFieldSeparator", () => {
      this.fieldSeparator = getFieldSeparator();
    });
    const getPivotDefaultExpanded = () => this.gos.get("pivotDefaultExpanded");
    this.pivotDefaultExpanded = getPivotDefaultExpanded();
    this.addManagedPropertyListener("pivotDefaultExpanded", () => {
      this.pivotDefaultExpanded = getPivotDefaultExpanded();
    });
  }
  createPivotColumnDefs(uniqueValues) {
    const pivotColumnGroupDefs = this.createPivotColumnsFromUniqueValues(uniqueValues);
    function extractColDefs(input, arr = []) {
      input.forEach((def) => {
        if (def.children !== void 0) {
          extractColDefs(def.children, arr);
        } else {
          arr.push(def);
        }
      });
      return arr;
    }
    const pivotColumnDefs = extractColDefs(pivotColumnGroupDefs);
    this.addRowGroupTotals(pivotColumnGroupDefs, pivotColumnDefs);
    this.addExpandablePivotGroups(pivotColumnGroupDefs, pivotColumnDefs);
    this.addPivotTotalsToGroups(pivotColumnGroupDefs, pivotColumnDefs);
    const pivotColumnDefsClone = pivotColumnDefs.map((colDef) => _37.cloneObject(colDef));
    return {
      pivotColumnGroupDefs,
      pivotColumnDefs: pivotColumnDefsClone
    };
  }
  createPivotColumnsFromUniqueValues(uniqueValues) {
    const pivotColumns = this.columnModel.getPivotColumns();
    const maxDepth = pivotColumns.length;
    const pivotColumnGroupDefs = this.recursivelyBuildGroup(0, uniqueValues, [], maxDepth, pivotColumns);
    return pivotColumnGroupDefs;
  }
  recursivelyBuildGroup(index, uniqueValue, pivotKeys, maxDepth, primaryPivotColumns) {
    const measureColumns = this.columnModel.getValueColumns();
    if (index >= maxDepth) {
      return this.buildMeasureCols(pivotKeys);
    }
    const primaryPivotColumnDefs = primaryPivotColumns[index].getColDef();
    const comparator = this.headerNameComparator.bind(this, primaryPivotColumnDefs.pivotComparator);
    if (measureColumns.length === 1 && this.gos.get("removePivotHeaderRowWhenSingleValueColumn") && index === maxDepth - 1) {
      const leafCols = [];
      _37.iterateObject(uniqueValue, (key) => {
        const newPivotKeys = [...pivotKeys, key];
        const colDef = this.createColDef(measureColumns[0], key, newPivotKeys);
        colDef.columnGroupShow = "open";
        leafCols.push(colDef);
      });
      leafCols.sort(comparator);
      return leafCols;
    }
    const groups = [];
    _37.iterateObject(uniqueValue, (key, value) => {
      const openByDefault = this.pivotDefaultExpanded === -1 || index < this.pivotDefaultExpanded;
      const newPivotKeys = [...pivotKeys, key];
      groups.push({
        children: this.recursivelyBuildGroup(index + 1, value, newPivotKeys, maxDepth, primaryPivotColumns),
        headerName: key,
        pivotKeys: newPivotKeys,
        columnGroupShow: "open",
        openByDefault,
        groupId: this.generateColumnGroupId(newPivotKeys)
      });
    });
    groups.sort(comparator);
    return groups;
  }
  buildMeasureCols(pivotKeys) {
    const measureColumns = this.columnModel.getValueColumns();
    if (measureColumns.length === 0) {
      return [this.createColDef(null, "-", pivotKeys)];
    }
    return measureColumns.map((measureCol) => {
      const columnName = this.columnModel.getDisplayNameForColumn(measureCol, "header");
      return __spreadProps4(__spreadValues4({}, this.createColDef(measureCol, columnName, pivotKeys)), {
        columnGroupShow: "open"
      });
    });
  }
  addExpandablePivotGroups(pivotColumnGroupDefs, pivotColumnDefs) {
    if (this.gos.get("suppressExpandablePivotGroups") || this.gos.get("pivotColumnGroupTotals")) {
      return;
    }
    const recursivelyAddSubTotals = (groupDef, currentPivotColumnDefs, acc) => {
      const group = groupDef;
      if (group.children) {
        const childAcc = /* @__PURE__ */ new Map();
        group.children.forEach((grp) => {
          recursivelyAddSubTotals(grp, currentPivotColumnDefs, childAcc);
        });
        const firstGroup = !group.children.some((child) => child.children);
        this.columnModel.getValueColumns().forEach((valueColumn) => {
          const columnName = this.columnModel.getDisplayNameForColumn(valueColumn, "header");
          const totalColDef = this.createColDef(valueColumn, columnName, groupDef.pivotKeys);
          totalColDef.pivotTotalColumnIds = childAcc.get(valueColumn.getColId());
          totalColDef.columnGroupShow = "closed";
          totalColDef.aggFunc = valueColumn.getAggFunc();
          if (!firstGroup) {
            const children = groupDef.children;
            children.push(totalColDef);
            currentPivotColumnDefs.push(totalColDef);
          }
        });
        this.merge(acc, childAcc);
      } else {
        const def = groupDef;
        if (!def.pivotValueColumn) {
          return;
        }
        const pivotValueColId = def.pivotValueColumn.getColId();
        const arr = acc.has(pivotValueColId) ? acc.get(pivotValueColId) : [];
        arr.push(def.colId);
        acc.set(pivotValueColId, arr);
      }
    };
    pivotColumnGroupDefs.forEach((groupDef) => {
      recursivelyAddSubTotals(groupDef, pivotColumnDefs, /* @__PURE__ */ new Map());
    });
  }
  addPivotTotalsToGroups(pivotColumnGroupDefs, pivotColumnDefs) {
    if (!this.gos.get("pivotColumnGroupTotals")) {
      return;
    }
    const insertAfter = this.gos.get("pivotColumnGroupTotals") === "after";
    const valueCols = this.columnModel.getValueColumns();
    const aggFuncs = valueCols.map((valueCol) => valueCol.getAggFunc());
    if (!aggFuncs || aggFuncs.length < 1 || !this.sameAggFuncs(aggFuncs)) {
      return;
    }
    const valueColumn = valueCols[0];
    pivotColumnGroupDefs.forEach((groupDef) => {
      this.recursivelyAddPivotTotal(groupDef, pivotColumnDefs, valueColumn, insertAfter);
    });
  }
  recursivelyAddPivotTotal(groupDef, pivotColumnDefs, valueColumn, insertAfter) {
    const group = groupDef;
    if (!group.children) {
      const def = groupDef;
      return def.colId ? [def.colId] : null;
    }
    let colIds = [];
    group.children.forEach((grp) => {
      const childColIds = this.recursivelyAddPivotTotal(grp, pivotColumnDefs, valueColumn, insertAfter);
      if (childColIds) {
        colIds = colIds.concat(childColIds);
      }
    });
    if (group.children.length > 1) {
      const localeTextFunc = this.localeService.getLocaleTextFunc();
      const headerName = localeTextFunc("pivotColumnGroupTotals", "Total");
      const totalColDef = this.createColDef(valueColumn, headerName, groupDef.pivotKeys, true);
      totalColDef.pivotTotalColumnIds = colIds;
      totalColDef.aggFunc = valueColumn.getAggFunc();
      const children = groupDef.children;
      insertAfter ? children.push(totalColDef) : children.unshift(totalColDef);
      pivotColumnDefs.push(totalColDef);
    }
    return colIds;
  }
  addRowGroupTotals(pivotColumnGroupDefs, pivotColumnDefs) {
    if (!this.gos.get("pivotRowTotals")) {
      return;
    }
    const insertAfter = this.gos.get("pivotRowTotals") === "after";
    const valueColumns = this.columnModel.getValueColumns();
    const valueCols = insertAfter ? valueColumns.slice() : valueColumns.slice().reverse();
    for (let i = 0; i < valueCols.length; i++) {
      const valueCol = valueCols[i];
      let colIds = [];
      pivotColumnGroupDefs.forEach((groupDef) => {
        colIds = colIds.concat(this.extractColIdsForValueColumn(groupDef, valueCol));
      });
      const withGroup = valueCols.length > 1 || !this.gos.get("removePivotHeaderRowWhenSingleValueColumn");
      this.createRowGroupTotal(pivotColumnGroupDefs, pivotColumnDefs, valueCol, colIds, insertAfter, withGroup);
    }
  }
  extractColIdsForValueColumn(groupDef, valueColumn) {
    const group = groupDef;
    if (!group.children) {
      const colDef = group;
      return colDef.pivotValueColumn === valueColumn && colDef.colId ? [colDef.colId] : [];
    }
    let colIds = [];
    group.children.forEach((grp) => {
      this.extractColIdsForValueColumn(grp, valueColumn);
      const childColIds = this.extractColIdsForValueColumn(grp, valueColumn);
      colIds = colIds.concat(childColIds);
    });
    return colIds;
  }
  createRowGroupTotal(parentChildren, pivotColumnDefs, valueColumn, colIds, insertAfter, addGroup) {
    const measureColumns = this.columnModel.getValueColumns();
    let colDef;
    if (measureColumns.length === 0) {
      colDef = this.createColDef(null, "-", []);
    } else {
      const columnName = this.columnModel.getDisplayNameForColumn(valueColumn, "header");
      colDef = this.createColDef(valueColumn, columnName, []);
      colDef.pivotTotalColumnIds = colIds;
    }
    colDef.colId = PivotColDefService.PIVOT_ROW_TOTAL_PREFIX + colDef.colId;
    pivotColumnDefs.push(colDef);
    const valueGroup = addGroup ? {
      children: [colDef],
      pivotKeys: [],
      groupId: `${PivotColDefService.PIVOT_ROW_TOTAL_PREFIX}_pivotGroup_${valueColumn.getColId()}`
    } : colDef;
    insertAfter ? parentChildren.push(valueGroup) : parentChildren.unshift(valueGroup);
  }
  createColDef(valueColumn, headerName, pivotKeys, totalColumn = false) {
    const colDef = {};
    if (valueColumn) {
      const colDefToCopy = valueColumn.getColDef();
      Object.assign(colDef, colDefToCopy);
      colDef.hide = false;
    }
    colDef.headerName = headerName;
    colDef.colId = this.generateColumnId(pivotKeys || [], valueColumn && !totalColumn ? valueColumn.getColId() : "");
    colDef.field = colDef.colId;
    colDef.valueGetter = (params) => {
      var _a;
      return (_a = params.data) == null ? void 0 : _a[params.colDef.field];
    };
    colDef.pivotKeys = pivotKeys;
    colDef.pivotValueColumn = valueColumn;
    if (colDef.filter === true) {
      colDef.filter = "agNumberColumnFilter";
    }
    return colDef;
  }
  sameAggFuncs(aggFuncs) {
    if (aggFuncs.length == 1) {
      return true;
    }
    for (let i = 1; i < aggFuncs.length; i++) {
      if (aggFuncs[i] !== aggFuncs[0]) {
        return false;
      }
    }
    return true;
  }
  headerNameComparator(userComparator, a, b) {
    if (userComparator) {
      return userComparator(a.headerName, b.headerName);
    } else {
      if (a.headerName && !b.headerName) {
        return 1;
      } else if (!a.headerName && b.headerName) {
        return -1;
      }
      if (!a.headerName || !b.headerName) {
        return 0;
      }
      if (a.headerName < b.headerName) {
        return -1;
      }
      if (a.headerName > b.headerName) {
        return 1;
      }
      return 0;
    }
  }
  merge(m1, m2) {
    m2.forEach((value, key, map) => {
      const existingList = m1.has(key) ? m1.get(key) : [];
      const updatedList = [...existingList, ...value];
      m1.set(key, updatedList);
    });
  }
  generateColumnGroupId(pivotKeys) {
    const pivotCols = this.columnModel.getPivotColumns().map((col) => col.getColId());
    return `pivotGroup_${pivotCols.join("-")}_${pivotKeys.join("-")}`;
  }
  generateColumnId(pivotKeys, measureColumnId) {
    const pivotCols = this.columnModel.getPivotColumns().map((col) => col.getColId());
    return `pivot_${pivotCols.join("-")}_${pivotKeys.join("-")}_${measureColumnId}`;
  }
  /**
   * Used by the SSRM to create secondary columns from provided fields
   * @param fields 
   */
  createColDefsFromFields(fields) {
    ;
    const uniqueValues = {};
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      const parts = field.split(this.fieldSeparator);
      let level = uniqueValues;
      for (let p = 0; p < parts.length; p++) {
        const part = parts[p];
        if (level[part] == null) {
          level[part] = {};
        }
        level = level[part];
      }
    }
    const uniqueValuesToGroups = (id, key, uniqueValues2, depth) => {
      var _a;
      const children = [];
      for (let key2 in uniqueValues2) {
        const item = uniqueValues2[key2];
        const child = uniqueValuesToGroups(`${id}${this.fieldSeparator}${key2}`, key2, item, depth + 1);
        children.push(child);
      }
      if (children.length === 0) {
        const potentialAggCol = this.columnModel.getPrimaryColumn(key);
        if (potentialAggCol) {
          const headerName = (_a = this.columnModel.getDisplayNameForColumn(potentialAggCol, "header")) != null ? _a : key;
          const colDef = this.createColDef(potentialAggCol, headerName, void 0, false);
          colDef.colId = id;
          colDef.aggFunc = potentialAggCol.getAggFunc();
          colDef.valueGetter = (params) => {
            var _a2;
            return (_a2 = params.data) == null ? void 0 : _a2[id];
          };
          return colDef;
        }
        const col = {
          colId: id,
          headerName: key,
          // this is to support using pinned rows, normally the data will be extracted from the aggData object using the colId
          // however pinned rows still access the data object by field, this prevents values with dots from being treated as complex objects
          valueGetter: (params) => {
            var _a2;
            return (_a2 = params.data) == null ? void 0 : _a2[id];
          }
        };
        return col;
      }
      const collapseSingleChildren = this.gos.get("removePivotHeaderRowWhenSingleValueColumn");
      if (collapseSingleChildren && children.length === 1 && "colId" in children[0]) {
        children[0].headerName = key;
        return children[0];
      }
      const group = {
        openByDefault: this.pivotDefaultExpanded === -1 || depth < this.pivotDefaultExpanded,
        groupId: id,
        headerName: key,
        children
      };
      return group;
    };
    const res = [];
    for (let key in uniqueValues) {
      const item = uniqueValues[key];
      const col = uniqueValuesToGroups(key, key, item, 0);
      res.push(col);
    }
    return res;
  }
};
PivotColDefService.PIVOT_ROW_TOTAL_PREFIX = "PivotRowTotal_";
__decorateClass6([
  Autowired310("columnModel")
], PivotColDefService.prototype, "columnModel", 2);
__decorateClass6([
  PostConstruct51
], PivotColDefService.prototype, "init", 1);
PivotColDefService = __decorateClass6([
  Bean33("pivotColDefService")
], PivotColDefService);
var PivotStage = class extends BeanStub43 {
  constructor() {
    super(...arguments);
    this.uniqueValues = {};
    this.lastTimeFailed = false;
    this.maxUniqueValues = -1;
    this.currentUniqueCount = 0;
  }
  execute(params) {
    const changedPath = params.changedPath;
    if (this.columnModel.isPivotActive()) {
      this.executePivotOn(changedPath);
    } else {
      this.executePivotOff(changedPath);
    }
  }
  executePivotOff(changedPath) {
    this.aggregationColumnsHashLastTime = null;
    this.uniqueValues = {};
    if (this.columnModel.isSecondaryColumnsPresent()) {
      this.columnModel.setSecondaryColumns(null, "rowModelUpdated");
      if (changedPath) {
        changedPath.setInactive();
      }
    }
  }
  executePivotOn(changedPath) {
    var _a;
    const numberOfAggregationColumns = (_a = this.columnModel.getValueColumns().length) != null ? _a : 1;
    const configuredMaxCols = this.gos.get("pivotMaxGeneratedColumns");
    this.maxUniqueValues = configuredMaxCols === -1 ? -1 : configuredMaxCols / numberOfAggregationColumns;
    let uniqueValues;
    try {
      uniqueValues = this.bucketUpRowNodes(changedPath);
    } catch (e) {
      if (e.message === PivotStage.EXCEEDED_MAX_UNIQUE_VALUES) {
        this.columnModel.setSecondaryColumns([], "rowModelUpdated");
        const event = {
          type: Events12.EVENT_PIVOT_MAX_COLUMNS_EXCEEDED,
          message: e.message
        };
        this.eventService.dispatchEvent(event);
        this.lastTimeFailed = true;
        return;
      }
      throw e;
    }
    const uniqueValuesChanged = this.setUniqueValues(uniqueValues);
    const aggregationColumns = this.columnModel.getValueColumns();
    const aggregationColumnsHash = aggregationColumns.map((column) => `${column.getId()}-${column.getColDef().headerName}`).join("#");
    const aggregationFuncsHash = aggregationColumns.map((column) => column.getAggFunc().toString()).join("#");
    const aggregationColumnsChanged = this.aggregationColumnsHashLastTime !== aggregationColumnsHash;
    const aggregationFuncsChanged = this.aggregationFuncsHashLastTime !== aggregationFuncsHash;
    this.aggregationColumnsHashLastTime = aggregationColumnsHash;
    this.aggregationFuncsHashLastTime = aggregationFuncsHash;
    const groupColumnsHash = this.columnModel.getRowGroupColumns().map((column) => column.getId()).join("#");
    const groupColumnsChanged = groupColumnsHash !== this.groupColumnsHashLastTime;
    this.groupColumnsHashLastTime = groupColumnsHash;
    const pivotRowTotals = this.gos.get("pivotRowTotals");
    const pivotColumnGroupTotals = this.gos.get("pivotColumnGroupTotals");
    const suppressExpandablePivotGroups = this.gos.get("suppressExpandablePivotGroups");
    const removePivotHeaderRowWhenSingleValueColumn = this.gos.get("removePivotHeaderRowWhenSingleValueColumn");
    const anyGridOptionsChanged = pivotRowTotals !== this.pivotRowTotalsLastTime || pivotColumnGroupTotals !== this.pivotColumnGroupTotalsLastTime || suppressExpandablePivotGroups !== this.suppressExpandablePivotGroupsLastTime || removePivotHeaderRowWhenSingleValueColumn !== this.removePivotHeaderRowWhenSingleValueColumnLastTime;
    this.pivotRowTotalsLastTime = pivotRowTotals;
    this.pivotColumnGroupTotalsLastTime = pivotColumnGroupTotals;
    this.suppressExpandablePivotGroupsLastTime = suppressExpandablePivotGroups;
    this.removePivotHeaderRowWhenSingleValueColumnLastTime = removePivotHeaderRowWhenSingleValueColumn;
    if (this.lastTimeFailed || uniqueValuesChanged || aggregationColumnsChanged || groupColumnsChanged || aggregationFuncsChanged || anyGridOptionsChanged) {
      const { pivotColumnGroupDefs, pivotColumnDefs } = this.pivotColDefService.createPivotColumnDefs(this.uniqueValues);
      this.pivotColumnDefs = pivotColumnDefs;
      this.columnModel.setSecondaryColumns(pivotColumnGroupDefs, "rowModelUpdated");
      if (changedPath) {
        changedPath.setInactive();
      }
    }
    this.lastTimeFailed = false;
  }
  setUniqueValues(newValues) {
    const json1 = JSON.stringify(newValues);
    const json2 = JSON.stringify(this.uniqueValues);
    const uniqueValuesChanged = json1 !== json2;
    if (uniqueValuesChanged) {
      this.uniqueValues = newValues;
      return true;
    } else {
      return false;
    }
  }
  bucketUpRowNodes(changedPath) {
    this.currentUniqueCount = 0;
    const uniqueValues = {};
    changedPath.forEachChangedNodeDepthFirst((node) => {
      if (node.leafGroup) {
        node.childrenMapped = null;
      }
    });
    const recursivelyBucketFilteredChildren = (node) => {
      var _a;
      if (node.leafGroup) {
        this.bucketRowNode(node, uniqueValues);
      } else {
        (_a = node.childrenAfterFilter) == null ? void 0 : _a.forEach(recursivelyBucketFilteredChildren);
      }
    };
    changedPath.executeFromRootNode(recursivelyBucketFilteredChildren);
    return uniqueValues;
  }
  bucketRowNode(rowNode, uniqueValues) {
    const pivotColumns = this.columnModel.getPivotColumns();
    if (pivotColumns.length === 0) {
      rowNode.childrenMapped = null;
    } else {
      rowNode.childrenMapped = this.bucketChildren(rowNode.childrenAfterFilter, pivotColumns, 0, uniqueValues);
    }
    if (rowNode.sibling) {
      rowNode.sibling.childrenMapped = rowNode.childrenMapped;
    }
  }
  bucketChildren(children, pivotColumns, pivotIndex, uniqueValues) {
    const mappedChildren = {};
    const pivotColumn = pivotColumns[pivotIndex];
    children.forEach((child) => {
      let key = this.valueService.getKeyForNode(pivotColumn, child);
      if (_44.missing(key)) {
        key = "";
      }
      if (!uniqueValues[key]) {
        this.currentUniqueCount += 1;
        uniqueValues[key] = {};
        const doesGeneratedColMaxExist = this.maxUniqueValues !== -1;
        const hasExceededColMax = this.currentUniqueCount > this.maxUniqueValues;
        if (doesGeneratedColMaxExist && hasExceededColMax) {
          throw Error(PivotStage.EXCEEDED_MAX_UNIQUE_VALUES);
        }
      }
      if (!mappedChildren[key]) {
        mappedChildren[key] = [];
      }
      mappedChildren[key].push(child);
    });
    if (pivotIndex === pivotColumns.length - 1) {
      return mappedChildren;
    } else {
      const result = {};
      _44.iterateObject(mappedChildren, (key, value) => {
        result[key] = this.bucketChildren(value, pivotColumns, pivotIndex + 1, uniqueValues[key]);
      });
      return result;
    }
  }
  getPivotColumnDefs() {
    return this.pivotColumnDefs;
  }
};
PivotStage.EXCEEDED_MAX_UNIQUE_VALUES = "Exceeded maximum allowed pivot column count.";
__decorateClass6([
  Autowired410("valueService")
], PivotStage.prototype, "valueService", 2);
__decorateClass6([
  Autowired410("columnModel")
], PivotStage.prototype, "columnModel", 2);
__decorateClass6([
  Autowired410("pivotColDefService")
], PivotStage.prototype, "pivotColDefService", 2);
PivotStage = __decorateClass6([
  Bean43("pivotStage")
], PivotStage);
var defaultAggFuncNames = {
  sum: "Sum",
  first: "First",
  last: "Last",
  min: "Min",
  max: "Max",
  count: "Count",
  avg: "Average"
};
var AggFuncService = class extends BeanStub53 {
  constructor() {
    super(...arguments);
    this.aggFuncsMap = {};
    this.initialised = false;
  }
  init() {
    if (this.initialised) {
      return;
    }
    this.initialiseWithDefaultAggregations();
    this.addAggFuncs(this.gos.get("aggFuncs"));
  }
  initialiseWithDefaultAggregations() {
    this.aggFuncsMap[AggFuncService.AGG_SUM] = aggSum;
    this.aggFuncsMap[AggFuncService.AGG_FIRST] = aggFirst;
    this.aggFuncsMap[AggFuncService.AGG_LAST] = aggLast;
    this.aggFuncsMap[AggFuncService.AGG_MIN] = aggMin;
    this.aggFuncsMap[AggFuncService.AGG_MAX] = aggMax;
    this.aggFuncsMap[AggFuncService.AGG_COUNT] = aggCount;
    this.aggFuncsMap[AggFuncService.AGG_AVG] = aggAvg;
    this.initialised = true;
  }
  isAggFuncPossible(column, func) {
    const allKeys = this.getFuncNames(column);
    const allowed = _53.includes(allKeys, func);
    const funcExists = _53.exists(this.aggFuncsMap[func]);
    return allowed && funcExists;
  }
  getDefaultFuncLabel(fctName) {
    var _a;
    return (_a = defaultAggFuncNames[fctName]) != null ? _a : fctName;
  }
  getDefaultAggFunc(column) {
    const defaultAgg = column.getColDef().defaultAggFunc;
    if (_53.exists(defaultAgg) && this.isAggFuncPossible(column, defaultAgg)) {
      return defaultAgg;
    }
    if (this.isAggFuncPossible(column, AggFuncService.AGG_SUM)) {
      return AggFuncService.AGG_SUM;
    }
    const allKeys = this.getFuncNames(column);
    return _53.existsAndNotEmpty(allKeys) ? allKeys[0] : null;
  }
  addAggFuncs(aggFuncs) {
    this.init();
    _53.iterateObject(aggFuncs, (key, aggFunc) => {
      this.aggFuncsMap[key] = aggFunc;
    });
  }
  getAggFunc(name) {
    this.init();
    return this.aggFuncsMap[name];
  }
  getFuncNames(column) {
    const userAllowedFuncs = column.getColDef().allowedAggFuncs;
    return userAllowedFuncs == null ? Object.keys(this.aggFuncsMap).sort() : userAllowedFuncs;
  }
  clear() {
    this.aggFuncsMap = {};
  }
};
AggFuncService.AGG_SUM = "sum";
AggFuncService.AGG_FIRST = "first";
AggFuncService.AGG_LAST = "last";
AggFuncService.AGG_MIN = "min";
AggFuncService.AGG_MAX = "max";
AggFuncService.AGG_COUNT = "count";
AggFuncService.AGG_AVG = "avg";
__decorateClass6([
  PostConstruct210
], AggFuncService.prototype, "init", 1);
AggFuncService = __decorateClass6([
  Bean52("aggFuncService")
], AggFuncService);
function aggSum(params) {
  const { values } = params;
  let result = null;
  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    if (typeof value === "number") {
      if (result === null) {
        result = value;
      } else {
        result += typeof result === "number" ? value : BigInt(value);
      }
    } else if (typeof value === "bigint") {
      if (result === null) {
        result = value;
      } else {
        result = (typeof result === "bigint" ? result : BigInt(result)) + value;
      }
    }
  }
  return result;
}
function aggFirst(params) {
  return params.values.length > 0 ? params.values[0] : null;
}
function aggLast(params) {
  return params.values.length > 0 ? _53.last(params.values) : null;
}
function aggMin(params) {
  const { values } = params;
  let result = null;
  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    if ((typeof value === "number" || typeof value === "bigint") && (result === null || result > value)) {
      result = value;
    }
  }
  return result;
}
function aggMax(params) {
  const { values } = params;
  let result = null;
  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    if ((typeof value === "number" || typeof value === "bigint") && (result === null || result < value)) {
      result = value;
    }
  }
  return result;
}
function aggCount(params) {
  var _a, _b;
  const { values } = params;
  let result = 0;
  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    result += value != null && typeof value.value === "number" ? value.value : 1;
  }
  const existingAggData = (_b = (_a = params.rowNode) == null ? void 0 : _a.aggData) == null ? void 0 : _b[params.column.getColId()];
  if (existingAggData && existingAggData.value === result) {
    return existingAggData;
  }
  return {
    value: result,
    toString: function() {
      return this.value.toString();
    },
    // used for sorting
    toNumber: function() {
      return this.value;
    }
  };
}
function aggAvg(params) {
  var _a, _b, _c;
  const { values } = params;
  let sum = 0;
  let count = 0;
  for (let i = 0; i < values.length; i++) {
    const currentValue = values[i];
    let valueToAdd = null;
    if (typeof currentValue === "number" || typeof currentValue === "bigint") {
      valueToAdd = currentValue;
      count++;
    } else if (currentValue != null && (typeof currentValue.value === "number" || typeof currentValue.value === "bigint") && typeof currentValue.count === "number") {
      valueToAdd = currentValue.value * (typeof currentValue.value === "number" ? currentValue.count : BigInt(currentValue.count));
      count += currentValue.count;
    }
    if (typeof valueToAdd === "number") {
      sum += typeof sum === "number" ? valueToAdd : BigInt(valueToAdd);
    } else if (typeof valueToAdd === "bigint") {
      sum = (typeof sum === "bigint" ? sum : BigInt(sum)) + valueToAdd;
    }
  }
  let value = null;
  if (count > 0) {
    value = sum / (typeof sum === "number" ? count : BigInt(count));
  }
  const existingAggData = (_c = (_a = params.rowNode) == null ? void 0 : _a.aggData) == null ? void 0 : _c[(_b = params.column) == null ? void 0 : _b.getColId()];
  if (existingAggData && existingAggData.count === count && existingAggData.value === value) {
    return existingAggData;
  }
  return {
    count,
    value,
    // the grid by default uses toString to render values for an object, so this
    // is a trick to get the default cellRenderer to display the avg value
    toString: function() {
      return typeof this.value === "number" || typeof this.value === "bigint" ? this.value.toString() : "";
    },
    // used for sorting
    toNumber: function() {
      return this.value;
    }
  };
}
var DropZoneColumnComp = class extends PillDragComp2 {
  constructor(column, dragSourceDropTarget, ghost, dropZonePurpose, horizontal) {
    super(
      dragSourceDropTarget,
      ghost,
      horizontal,
      /* html */
      `
                <span role="option">
                    <span ref="eDragHandle" class="ag-drag-handle ag-column-drop-cell-drag-handle" role="presentation"></span>
                    <span ref="eText" class="ag-column-drop-cell-text" aria-hidden="true"></span>
                    <ag-sort-indicator ref="eSortIndicator"></ag-sort-indicator>
                    <span ref="eButton" class="ag-column-drop-cell-button" role="presentation"></span>
                </span>
            `
    );
    this.column = column;
    this.dropZonePurpose = dropZonePurpose;
    this.popupShowing = false;
  }
  init() {
    this.displayName = this.columnModel.getDisplayNameForColumn(this.column, "columnDrop");
    super.init();
    this.setupSort();
    this.addManagedListener(this.eventService, Column6.EVENT_SORT_CHANGED, () => {
      this.setupAria();
    });
    if (this.isGroupingZone()) {
      this.addManagedPropertyListener("groupLockGroupColumns", () => {
        this.refreshRemove();
        this.refreshDraggable();
        this.setupAria();
      });
    }
  }
  getItem() {
    return this.column;
  }
  getDisplayName() {
    return this.displayName;
  }
  getTooltip() {
    return this.column.getColDef().headerTooltip;
  }
  addAdditionalAriaInstructions(ariaInstructions, translate) {
    const isSortSuppressed = this.gos.get("rowGroupPanelSuppressSort");
    const isFunctionsReadOnly = this.gos.get("functionsReadOnly");
    if (this.isAggregationZone() && !isFunctionsReadOnly) {
      const aggregationMenuAria = translate("ariaDropZoneColumnValueItemDescription", "Press ENTER to change the aggregation type");
      ariaInstructions.push(aggregationMenuAria);
    }
    if (this.isGroupingZone() && this.column.isSortable() && !isSortSuppressed) {
      const sortProgressAria = translate("ariaDropZoneColumnGroupItemDescription", "Press ENTER to sort");
      ariaInstructions.push(sortProgressAria);
    }
    super.addAdditionalAriaInstructions(ariaInstructions, translate);
  }
  isDraggable() {
    return this.isReadOnly();
  }
  isRemovable() {
    return this.isReadOnly();
  }
  isReadOnly() {
    return !this.isGroupingAndLocked() && !this.gos.get("functionsReadOnly");
  }
  getAriaDisplayName() {
    const translate = this.localeService.getLocaleTextFunc();
    const { name, aggFuncName } = this.getColumnAndAggFuncName();
    const aggSeparator = translate("ariaDropZoneColumnComponentAggFuncSeparator", " of ");
    const sortDirection = {
      asc: translate("ariaDropZoneColumnComponentSortAscending", "ascending"),
      desc: translate("ariaDropZoneColumnComponentSortDescending", "descending")
    };
    const columnSort = this.column.getSort();
    const isSortSuppressed = this.gos.get("rowGroupPanelSuppressSort");
    return [
      aggFuncName && `${aggFuncName}${aggSeparator}`,
      name,
      this.isGroupingZone() && !isSortSuppressed && columnSort && `, ${sortDirection[columnSort]}`
    ].filter((part) => !!part).join("");
  }
  getColumnAndAggFuncName() {
    const name = this.displayName;
    let aggFuncName = "";
    if (this.isAggregationZone()) {
      const aggFunc = this.column.getAggFunc();
      const aggFuncString = typeof aggFunc === "string" ? aggFunc : "agg";
      const localeTextFunc = this.localeService.getLocaleTextFunc();
      aggFuncName = localeTextFunc(aggFuncString, aggFuncString);
    }
    return { name, aggFuncName };
  }
  setupSort() {
    const canSort = this.column.isSortable();
    const isGroupingZone = this.isGroupingZone();
    if (!canSort || !isGroupingZone) {
      return;
    }
    if (!this.gos.get("rowGroupPanelSuppressSort")) {
      this.eSortIndicator.setupSort(this.column, true);
      const performSort = (event) => {
        event.preventDefault();
        const sortUsingCtrl = this.gos.get("multiSortKey") === "ctrl";
        const multiSort = sortUsingCtrl ? event.ctrlKey || event.metaKey : event.shiftKey;
        this.sortController.progressSort(this.column, multiSort, "uiColumnSorted");
      };
      this.addGuiEventListener("click", performSort);
      this.addGuiEventListener("keydown", (e) => {
        const isEnter = e.key === KeyCode6.ENTER;
        if (isEnter && this.isGroupingZone()) {
          performSort(e);
        }
      });
    }
  }
  getDefaultIconName() {
    return DragAndDropService5.ICON_HIDE;
  }
  createGetDragItem() {
    const { column } = this;
    return () => {
      const visibleState = {};
      visibleState[column.getId()] = column.isVisible();
      return {
        columns: [column],
        visibleState
      };
    };
  }
  setupComponents() {
    super.setupComponents();
    if (this.isAggregationZone() && !this.gos.get("functionsReadOnly")) {
      this.addGuiEventListener("click", this.onShowAggFuncSelection.bind(this));
    }
  }
  onKeyDown(e) {
    super.onKeyDown(e);
    const isEnter = e.key === KeyCode6.ENTER;
    if (isEnter && this.isAggregationZone() && !this.gos.get("functionsReadOnly")) {
      e.preventDefault();
      this.onShowAggFuncSelection();
    }
  }
  getDisplayValue() {
    const { name, aggFuncName } = this.getColumnAndAggFuncName();
    return this.isAggregationZone() ? `${aggFuncName}(${name})` : name;
  }
  onShowAggFuncSelection() {
    if (this.popupShowing) {
      return;
    }
    this.popupShowing = true;
    const virtualList = new VirtualList({ cssIdentifier: "select-agg-func" });
    const rows = this.aggFuncService.getFuncNames(this.column);
    const eGui = this.getGui();
    const virtualListGui = virtualList.getGui();
    virtualList.setModel({
      getRow: function(index) {
        return rows[index];
      },
      getRowCount: function() {
        return rows.length;
      }
    });
    this.getContext().createBean(virtualList);
    const ePopup = _63.loadTemplate(
      /* html*/
      `<div class="ag-select-agg-func-popup"></div>`
    );
    ePopup.style.top = "0px";
    ePopup.style.left = "0px";
    ePopup.appendChild(virtualListGui);
    ePopup.style.width = `${eGui.clientWidth}px`;
    const focusoutListener = this.addManagedListener(ePopup, "focusout", (e) => {
      if (!ePopup.contains(e.relatedTarget) && addPopupRes) {
        addPopupRes.hideFunc();
      }
    });
    const popupHiddenFunc = (callbackEvent) => {
      this.destroyBean(virtualList);
      this.popupShowing = false;
      if ((callbackEvent == null ? void 0 : callbackEvent.key) === "Escape") {
        eGui.focus();
      }
      if (focusoutListener) {
        focusoutListener();
      }
    };
    const translate = this.localeService.getLocaleTextFunc();
    const addPopupRes = this.popupService.addPopup({
      modal: true,
      eChild: ePopup,
      closeOnEsc: true,
      closedCallback: popupHiddenFunc,
      ariaLabel: translate("ariaLabelAggregationFunction", "Aggregation Function")
    });
    if (addPopupRes) {
      virtualList.setComponentCreator(
        this.createAggSelect.bind(this, addPopupRes.hideFunc)
      );
    }
    virtualList.addGuiEventListener("keydown", (e) => {
      if (e.key === KeyCode6.ENTER || e.key === KeyCode6.SPACE) {
        const row = virtualList.getLastFocusedRow();
        if (row == null) {
          return;
        }
        const comp = virtualList.getComponentAt(row);
        if (comp) {
          comp.selectItem();
        }
      }
    });
    this.popupService.positionPopupByComponent({
      type: "aggFuncSelect",
      eventSource: eGui,
      ePopup,
      keepWithinBounds: true,
      column: this.column,
      position: "under"
    });
    virtualList.refresh();
    let rowToFocus = rows.findIndex((r) => r === this.column.getAggFunc());
    if (rowToFocus === -1) {
      rowToFocus = 0;
    }
    virtualList.focusRow(rowToFocus);
  }
  createAggSelect(hidePopup, value) {
    const itemSelected = () => {
      hidePopup();
      if (this.gos.get("functionsPassive")) {
        const event = {
          type: Events24.EVENT_COLUMN_AGG_FUNC_CHANGE_REQUEST,
          columns: [this.column],
          aggFunc: value
        };
        this.eventService.dispatchEvent(event);
      } else {
        this.columnModel.setColumnAggFunc(this.column, value, "toolPanelDragAndDrop");
      }
    };
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const aggFuncString = value.toString();
    const aggFuncStringTranslated = localeTextFunc(aggFuncString, aggFuncString);
    const comp = new AggItemComp(itemSelected, aggFuncStringTranslated);
    return comp;
  }
  isGroupingAndLocked() {
    return this.isGroupingZone() && this.columnModel.isColumnGroupingLocked(this.column);
  }
  isAggregationZone() {
    return this.dropZonePurpose === "aggregation";
  }
  isGroupingZone() {
    return this.dropZonePurpose === "rowGroup";
  }
  getDragSourceType() {
    return DragSourceType4.ToolPanel;
  }
  destroy() {
    super.destroy();
    this.column = null;
  }
};
__decorateClass6([
  Autowired53("popupService")
], DropZoneColumnComp.prototype, "popupService", 2);
__decorateClass6([
  Autowired53("sortController")
], DropZoneColumnComp.prototype, "sortController", 2);
__decorateClass6([
  Autowired53("columnModel")
], DropZoneColumnComp.prototype, "columnModel", 2);
__decorateClass6([
  Optional4("aggFuncService")
], DropZoneColumnComp.prototype, "aggFuncService", 2);
__decorateClass6([
  RefSelector15("eSortIndicator")
], DropZoneColumnComp.prototype, "eSortIndicator", 2);
var AggItemComp = class extends Component43 {
  constructor(itemSelected, value) {
    super(
      /* html */
      `<div class="ag-select-agg-func-item"/>`
    );
    this.selectItem = itemSelected;
    this.getGui().innerText = value;
    this.addGuiEventListener("click", this.selectItem);
  }
};
var BaseDropZonePanel = class extends PillDropZonePanel2 {
  constructor(horizontal, dropZonePurpose) {
    super(horizontal);
    this.dropZonePurpose = dropZonePurpose;
  }
  init(params) {
    super.init(params);
    this.addManagedListener(this.eventService, Events33.EVENT_NEW_COLUMNS_LOADED, this.refreshGui.bind(this));
    this.addManagedPropertyListeners(["functionsReadOnly", "rowGroupPanelSuppressSort", "groupLockGroupColumns"], this.refreshGui.bind(this));
  }
  getItems(dragItem) {
    var _a;
    return (_a = dragItem.columns) != null ? _a : [];
  }
  isInterestedIn(type) {
    return type === DragSourceType23.HeaderCell || type === DragSourceType23.ToolPanel;
  }
  minimumAllowedNewInsertIndex() {
    const numberOfLockedCols = this.gos.get("groupLockGroupColumns");
    const numberOfGroupCols = this.columnModel.getRowGroupColumns().length;
    if (numberOfLockedCols === -1) {
      return numberOfGroupCols;
    }
    return Math.min(numberOfLockedCols, numberOfGroupCols);
  }
  showOrHideColumnOnExit(draggingEvent) {
    return this.isRowGroupPanel() && !this.gos.get("suppressRowGroupHidesColumns") && !draggingEvent.fromNudge;
  }
  handleDragEnterEnd(draggingEvent) {
    const hideColumnOnExit = this.showOrHideColumnOnExit(draggingEvent);
    if (hideColumnOnExit) {
      const dragItem = draggingEvent.dragSource.getDragItem();
      const columns = dragItem.columns;
      this.setColumnsVisible(columns, false, "uiColumnDragged");
    }
  }
  handleDragLeaveEnd(draggingEvent) {
    const showColumnOnExit = this.showOrHideColumnOnExit(draggingEvent);
    if (showColumnOnExit) {
      const dragItem = draggingEvent.dragSource.getDragItem();
      this.setColumnsVisible(dragItem.columns, true, "uiColumnDragged");
    }
  }
  setColumnsVisible(columns, visible, source) {
    if (columns) {
      const allowedCols = columns.filter((c) => !c.getColDef().lockVisible);
      this.columnModel.setColumnsVisible(allowedCols, visible, source);
    }
  }
  isRowGroupPanel() {
    return this.dropZonePurpose === "rowGroup";
  }
  refreshOnDragStop() {
    return !this.gos.get("functionsPassive");
  }
  createPillComponent(column, dropTarget, ghost, horizontal) {
    return new DropZoneColumnComp(column, dropTarget, ghost, this.dropZonePurpose, horizontal);
  }
};
__decorateClass6([
  Autowired63("columnModel")
], BaseDropZonePanel.prototype, "columnModel", 2);
var RowGroupDropZonePanel = class extends BaseDropZonePanel {
  constructor(horizontal) {
    super(horizontal, "rowGroup");
  }
  passBeansUp() {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const emptyMessage = localeTextFunc("rowGroupColumnsEmptyMessage", "Drag here to set row groups");
    const title = localeTextFunc("groups", "Row Groups");
    super.init({
      icon: _83.createIconNoSpan("rowGroupPanel", this.gos, null),
      emptyMessage,
      title
    });
    this.addManagedListener(this.eventService, Events43.EVENT_COLUMN_ROW_GROUP_CHANGED, this.refreshGui.bind(this));
  }
  getAriaLabel() {
    const translate = this.localeService.getLocaleTextFunc();
    const label = translate("ariaRowGroupDropZonePanelLabel", "Row Groups");
    return label;
  }
  getTooltipParams() {
    const res = super.getTooltipParams();
    res.location = "rowGroupColumnsList";
    return res;
  }
  isItemDroppable(column, draggingEvent) {
    if (this.gos.get("functionsReadOnly") || !column.isPrimary()) {
      return false;
    }
    return column.isAllowRowGroup() && (!column.isRowGroupActive() || this.isSourceEventFromTarget(draggingEvent));
  }
  updateItems(columns) {
    if (this.gos.get("functionsPassive")) {
      const event = {
        type: Events43.EVENT_COLUMN_ROW_GROUP_CHANGE_REQUEST,
        columns
      };
      this.eventService.dispatchEvent(event);
    } else {
      this.columnModel.setRowGroupColumns(columns, "toolPanelUi");
    }
  }
  getIconName() {
    return this.isPotentialDndItems() ? DragAndDropService22.ICON_GROUP : DragAndDropService22.ICON_NOT_ALLOWED;
  }
  getExistingItems() {
    return this.columnModel.getRowGroupColumns();
  }
};
__decorateClass6([
  PostConstruct310
], RowGroupDropZonePanel.prototype, "passBeansUp", 1);
var PivotDropZonePanel = class extends BaseDropZonePanel {
  constructor(horizontal) {
    super(horizontal, "pivot");
  }
  passBeansUp() {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const emptyMessage = localeTextFunc("pivotColumnsEmptyMessage", "Drag here to set column labels");
    const title = localeTextFunc("pivots", "Column Labels");
    super.init({
      icon: _93.createIconNoSpan("pivotPanel", this.gos, null),
      emptyMessage,
      title
    });
    this.addManagedListener(this.eventService, Events53.EVENT_NEW_COLUMNS_LOADED, this.refresh.bind(this));
    this.addManagedListener(this.eventService, Events53.EVENT_COLUMN_PIVOT_CHANGED, this.refresh.bind(this));
    this.addManagedListener(this.eventService, Events53.EVENT_COLUMN_PIVOT_MODE_CHANGED, this.checkVisibility.bind(this));
    this.refresh();
  }
  getAriaLabel() {
    const translate = this.localeService.getLocaleTextFunc();
    const label = translate("ariaPivotDropZonePanelLabel", "Column Labels");
    return label;
  }
  getTooltipParams() {
    const res = super.getTooltipParams();
    res.location = "pivotColumnsList";
    return res;
  }
  refresh() {
    this.checkVisibility();
    this.refreshGui();
  }
  checkVisibility() {
    const pivotMode = this.columnModel.isPivotMode();
    if (this.isHorizontal()) {
      switch (this.gos.get("pivotPanelShow")) {
        case "always":
          this.setDisplayed(pivotMode);
          break;
        case "onlyWhenPivoting":
          const pivotActive = this.columnModel.isPivotActive();
          this.setDisplayed(pivotMode && pivotActive);
          break;
        default:
          this.setDisplayed(false);
          break;
      }
    } else {
      this.setDisplayed(pivotMode);
    }
  }
  isItemDroppable(column, draggingEvent) {
    if (this.gos.get("functionsReadOnly") || !column.isPrimary()) {
      return false;
    }
    return column.isAllowPivot() && (!column.isPivotActive() || this.isSourceEventFromTarget(draggingEvent));
  }
  updateItems(columns) {
    if (this.gos.get("functionsPassive")) {
      const event = {
        type: Events53.EVENT_COLUMN_PIVOT_CHANGE_REQUEST,
        columns
      };
      this.eventService.dispatchEvent(event);
    } else {
      this.columnModel.setPivotColumns(columns, "toolPanelUi");
    }
  }
  getIconName() {
    return this.isPotentialDndItems() ? DragAndDropService32.ICON_PIVOT : DragAndDropService32.ICON_NOT_ALLOWED;
  }
  getExistingItems() {
    return this.columnModel.getPivotColumns();
  }
};
__decorateClass6([
  PostConstruct410
], PivotDropZonePanel.prototype, "passBeansUp", 1);
var GridHeaderDropZones = class extends Component27 {
  constructor() {
    super();
  }
  postConstruct() {
    this.setGui(this.createNorthPanel());
    this.addManagedListener(this.eventService, Events63.EVENT_COLUMN_ROW_GROUP_CHANGED, () => this.onRowGroupChanged());
    this.addManagedListener(this.eventService, Events63.EVENT_NEW_COLUMNS_LOADED, () => this.onRowGroupChanged());
    this.addManagedPropertyListener("rowGroupPanelShow", () => this.onRowGroupChanged());
    this.addManagedPropertyListener("pivotPanelShow", () => this.onPivotPanelShow());
    this.onRowGroupChanged();
  }
  createNorthPanel() {
    const topPanelGui = document.createElement("div");
    topPanelGui.classList.add("ag-column-drop-wrapper");
    _103.setAriaRole(topPanelGui, "presentation");
    this.rowGroupComp = new RowGroupDropZonePanel(true);
    this.createManagedBean(this.rowGroupComp);
    this.pivotComp = new PivotDropZonePanel(true);
    this.createManagedBean(this.pivotComp);
    topPanelGui.appendChild(this.rowGroupComp.getGui());
    topPanelGui.appendChild(this.pivotComp.getGui());
    this.addManagedListener(this.rowGroupComp, Component27.EVENT_DISPLAYED_CHANGED, () => this.onDropPanelVisible());
    this.addManagedListener(this.pivotComp, Component27.EVENT_DISPLAYED_CHANGED, () => this.onDropPanelVisible());
    this.onDropPanelVisible();
    return topPanelGui;
  }
  onDropPanelVisible() {
    const bothDisplayed = this.rowGroupComp.isDisplayed() && this.pivotComp.isDisplayed();
    this.rowGroupComp.addOrRemoveCssClass("ag-column-drop-horizontal-half-width", bothDisplayed);
    this.pivotComp.addOrRemoveCssClass("ag-column-drop-horizontal-half-width", bothDisplayed);
  }
  onRowGroupChanged() {
    if (!this.rowGroupComp) {
      return;
    }
    const rowGroupPanelShow = this.gos.get("rowGroupPanelShow");
    if (rowGroupPanelShow === "always") {
      this.rowGroupComp.setDisplayed(true);
    } else if (rowGroupPanelShow === "onlyWhenGrouping") {
      const grouping = !this.columnModel.isRowGroupEmpty();
      this.rowGroupComp.setDisplayed(grouping);
    } else {
      this.rowGroupComp.setDisplayed(false);
    }
  }
  onPivotPanelShow() {
    if (!this.pivotComp) {
      return;
    }
    const pivotPanelShow = this.gos.get("pivotPanelShow");
    if (pivotPanelShow === "always") {
      this.pivotComp.setDisplayed(true);
    } else if (pivotPanelShow === "onlyWhenPivoting") {
      const pivoting = this.columnModel.isPivotActive();
      this.pivotComp.setDisplayed(pivoting);
    } else {
      this.pivotComp.setDisplayed(false);
    }
  }
};
__decorateClass6([
  Autowired73("columnModel")
], GridHeaderDropZones.prototype, "columnModel", 2);
__decorateClass6([
  PostConstruct53
], GridHeaderDropZones.prototype, "postConstruct", 1);
var FilterAggregatesStage = class extends BeanStub63 {
  execute(params) {
    const isPivotMode = this.columnModel.isPivotMode();
    const isAggFilterActive = this.filterManager.isAggregateFilterPresent() || this.filterManager.isAggregateQuickFilterPresent();
    const defaultPrimaryColumnPredicate = (params2) => !params2.node.group;
    const defaultSecondaryColumnPredicate = (params2) => params2.node.leafGroup;
    const applyFilterToNode = this.gos.getGroupAggFiltering() || (isPivotMode ? defaultSecondaryColumnPredicate : defaultPrimaryColumnPredicate);
    const { changedPath } = params;
    const preserveChildren = (node, recursive = false) => {
      if (node.childrenAfterFilter) {
        node.childrenAfterAggFilter = node.childrenAfterFilter;
        if (recursive) {
          node.childrenAfterAggFilter.forEach((child) => preserveChildren(child, recursive));
        }
        this.setAllChildrenCount(node);
      }
      if (node.sibling) {
        node.sibling.childrenAfterAggFilter = node.childrenAfterAggFilter;
      }
    };
    const filterChildren = (node) => {
      var _a;
      node.childrenAfterAggFilter = ((_a = node.childrenAfterFilter) == null ? void 0 : _a.filter((child) => {
        var _a2;
        const shouldFilterRow = applyFilterToNode({ node: child });
        if (shouldFilterRow) {
          const doesNodePassFilter = this.filterManager.doesRowPassAggregateFilters({ rowNode: child });
          if (doesNodePassFilter) {
            preserveChildren(child, true);
            return true;
          }
        }
        const hasChildPassed = (_a2 = child.childrenAfterAggFilter) == null ? void 0 : _a2.length;
        return hasChildPassed;
      })) || null;
      this.setAllChildrenCount(node);
      if (node.sibling) {
        node.sibling.childrenAfterAggFilter = node.childrenAfterAggFilter;
      }
    };
    changedPath.forEachChangedNodeDepthFirst(
      isAggFilterActive ? filterChildren : preserveChildren,
      true
    );
  }
  setAllChildrenCountTreeData(rowNode) {
    let allChildrenCount = 0;
    rowNode.childrenAfterAggFilter.forEach((child) => {
      allChildrenCount++;
      allChildrenCount += child.allChildrenCount;
    });
    rowNode.setAllChildrenCount(allChildrenCount);
  }
  setAllChildrenCountGridGrouping(rowNode) {
    let allChildrenCount = 0;
    rowNode.childrenAfterAggFilter.forEach((child) => {
      if (child.group) {
        allChildrenCount += child.allChildrenCount;
      } else {
        allChildrenCount++;
      }
    });
    rowNode.setAllChildrenCount(allChildrenCount);
  }
  setAllChildrenCount(rowNode) {
    if (!rowNode.hasChildren()) {
      rowNode.setAllChildrenCount(null);
      return;
    }
    if (this.gos.get("treeData")) {
      this.setAllChildrenCountTreeData(rowNode);
    } else {
      this.setAllChildrenCountGridGrouping(rowNode);
    }
  }
};
__decorateClass6([
  Autowired83("filterManager")
], FilterAggregatesStage.prototype, "filterManager", 2);
__decorateClass6([
  Autowired83("columnModel")
], FilterAggregatesStage.prototype, "columnModel", 2);
FilterAggregatesStage = __decorateClass6([
  Bean62("filterAggregatesStage")
], FilterAggregatesStage);
var VERSION6 = "31.3.2";
var _GroupFilter = class _GroupFilter2 extends TabGuardComp3 {
  constructor() {
    super(
      /* html */
      `
            <div class="ag-group-filter">
                <div ref="eGroupField"></div>
                <div ref="eUnderlyingFilter"></div>
            </div>
        `
    );
  }
  postConstruct() {
    this.initialiseTabGuard({});
  }
  init(params) {
    this.params = params;
    this.validateParams();
    return this.updateGroups().then(() => {
      this.addManagedListener(this.eventService, Events73.EVENT_COLUMN_ROW_GROUP_CHANGED, () => this.onColumnRowGroupChanged());
    });
  }
  validateParams() {
    const { colDef } = this.params;
    if (colDef.field) {
      _113.warnOnce('Group Column Filter does not work with the colDef property "field". This property will be ignored.');
    }
    if (colDef.filterValueGetter) {
      _113.warnOnce('Group Column Filter does not work with the colDef property "filterValueGetter". This property will be ignored.');
    }
    if (colDef.filterParams) {
      _113.warnOnce('Group Column Filter does not work with the colDef property "filterParams". This property will be ignored.');
    }
  }
  updateGroups() {
    const sourceColumns = this.updateGroupField();
    return this.getUnderlyingFilters(sourceColumns);
  }
  getSourceColumns() {
    this.groupColumn = this.params.column;
    if (this.gos.get("treeData")) {
      _113.warnOnce("Group Column Filter does not work with Tree Data enabled. Please disable Tree Data, or use a different filter.");
      return [];
    }
    const sourceColumns = this.columnModel.getSourceColumnsForGroupColumn(this.groupColumn);
    if (!sourceColumns) {
      _113.warnOnce("Group Column Filter only works on group columns. Please use a different filter.");
      return [];
    }
    return sourceColumns;
  }
  updateGroupField() {
    _113.clearElement(this.eGroupField);
    if (this.eGroupFieldSelect) {
      this.destroyBean(this.eGroupFieldSelect);
    }
    const allSourceColumns = this.getSourceColumns();
    const sourceColumns = allSourceColumns.filter((sourceColumn) => sourceColumn.isFilterAllowed());
    if (!sourceColumns.length) {
      this.selectedColumn = void 0;
      _113.setDisplayed(this.eGroupField, false);
      return null;
    }
    if (allSourceColumns.length === 1) {
      this.selectedColumn = sourceColumns[0];
      _113.setDisplayed(this.eGroupField, false);
    } else {
      if (!this.selectedColumn || !sourceColumns.some((column) => column.getId() === this.selectedColumn.getId())) {
        this.selectedColumn = sourceColumns[0];
      }
      this.createGroupFieldSelectElement(sourceColumns);
      this.eGroupField.appendChild(this.eGroupFieldSelect.getGui());
      this.eGroupField.appendChild(_113.loadTemplate(
        /* html */
        `<div class="ag-filter-separator"></div>`
      ));
      _113.setDisplayed(this.eGroupField, true);
    }
    return sourceColumns;
  }
  createGroupFieldSelectElement(sourceColumns) {
    this.eGroupFieldSelect = this.createManagedBean(new AgSelect5());
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    this.eGroupFieldSelect.setLabel(localeTextFunc("groupFilterSelect", "Select field:"));
    this.eGroupFieldSelect.setLabelAlignment("top");
    this.eGroupFieldSelect.addOptions(sourceColumns.map((sourceColumn) => {
      var _a;
      return {
        value: sourceColumn.getId(),
        text: (_a = this.columnModel.getDisplayNameForColumn(sourceColumn, "groupFilter", false)) != null ? _a : void 0
      };
    }));
    this.eGroupFieldSelect.setValue(this.selectedColumn.getId());
    this.eGroupFieldSelect.onValueChange((newValue) => this.updateSelectedColumn(newValue));
    this.eGroupFieldSelect.addCssClass("ag-group-filter-field-select-wrapper");
    if (sourceColumns.length === 1) {
      this.eGroupFieldSelect.setDisabled(true);
    }
  }
  getUnderlyingFilters(sourceColumns) {
    if (!sourceColumns) {
      this.filterColumnPairs = void 0;
      this.selectedFilter = void 0;
      this.groupColumn.setFilterActive(false, "columnRowGroupChanged");
      return AgPromise3.resolve();
    }
    const filterPromises = [];
    const filterColumnPairs = [];
    sourceColumns.forEach((column) => {
      const filterWrapper = this.filterManager.getOrCreateFilterWrapper(column, "COLUMN_MENU");
      if (filterWrapper == null ? void 0 : filterWrapper.filterPromise) {
        filterPromises.push(filterWrapper.filterPromise.then((filter) => {
          if (filter) {
            filterColumnPairs.push({
              filter,
              column
            });
          }
          if (column.getId() === this.selectedColumn.getId()) {
            this.selectedFilter = filter != null ? filter : void 0;
          }
          return filter;
        }));
      }
    });
    return AgPromise3.all(filterPromises).then(() => {
      this.filterColumnPairs = filterColumnPairs;
      this.groupColumn.setFilterActive(this.isFilterActive(), "columnRowGroupChanged");
    });
  }
  addUnderlyingFilterElement() {
    var _a, _b;
    _113.clearElement(this.eUnderlyingFilter);
    if (!this.selectedColumn) {
      return AgPromise3.resolve();
    }
    const comp = this.createManagedBean(new FilterWrapperComp(this.selectedColumn, "COLUMN_MENU"));
    this.filterWrapperComp = comp;
    if (!comp.hasFilter()) {
      return AgPromise3.resolve();
    }
    this.eUnderlyingFilter.appendChild(comp.getGui());
    return (_b = (_a = comp.getFilter()) == null ? void 0 : _a.then(() => {
      var _a2, _b2;
      (_a2 = comp.afterGuiAttached) == null ? void 0 : _a2.call(comp, this.afterGuiAttachedParams);
      if (!((_b2 = this.afterGuiAttachedParams) == null ? void 0 : _b2.suppressFocus) && this.eGroupFieldSelect && !this.eGroupFieldSelect.isDisabled()) {
        this.eGroupFieldSelect.getFocusableElement().focus();
      }
    })) != null ? _b : AgPromise3.resolve();
  }
  updateSelectedColumn(columnId) {
    var _a;
    if (!columnId) {
      return;
    }
    (_a = this.filterWrapperComp) == null ? void 0 : _a.afterGuiDetached();
    this.destroyBean(this.filterWrapperComp);
    const selectedFilterColumnPair = this.getFilterColumnPair(columnId);
    this.selectedColumn = selectedFilterColumnPair == null ? void 0 : selectedFilterColumnPair.column;
    this.selectedFilter = selectedFilterColumnPair == null ? void 0 : selectedFilterColumnPair.filter;
    this.dispatchEvent({
      type: _GroupFilter2.EVENT_SELECTED_COLUMN_CHANGED
    });
    this.addUnderlyingFilterElement();
  }
  isFilterActive() {
    var _a;
    return !!((_a = this.filterColumnPairs) == null ? void 0 : _a.some(({ filter }) => filter.isFilterActive()));
  }
  doesFilterPass() {
    return true;
  }
  getModel() {
    return null;
  }
  setModel() {
    return AgPromise3.resolve();
  }
  afterGuiAttached(params) {
    this.afterGuiAttachedParams = params;
    this.addUnderlyingFilterElement();
  }
  afterGuiDetached() {
    var _a, _b;
    _113.clearElement(this.eUnderlyingFilter);
    (_b = (_a = this.selectedFilter) == null ? void 0 : _a.afterGuiDetached) == null ? void 0 : _b.call(_a);
  }
  onColumnRowGroupChanged() {
    this.updateGroups().then(() => {
      this.dispatchEvent({
        type: _GroupFilter2.EVENT_COLUMN_ROW_GROUP_CHANGED
      });
      this.eventService.dispatchEvent({
        type: "filterAllowedUpdated"
      });
    });
  }
  getFilterColumnPair(columnId) {
    var _a;
    if (!columnId) {
      return void 0;
    }
    return (_a = this.filterColumnPairs) == null ? void 0 : _a.find(({ column }) => column.getId() === columnId);
  }
  getSelectedFilter() {
    return this.selectedFilter;
  }
  getSelectedColumn() {
    return this.selectedColumn;
  }
  isFilterAllowed() {
    return !!this.selectedColumn;
  }
  destroy() {
    super.destroy();
  }
};
_GroupFilter.EVENT_COLUMN_ROW_GROUP_CHANGED = "columnRowGroupChanged";
_GroupFilter.EVENT_SELECTED_COLUMN_CHANGED = "selectedColumnChanged";
__decorateClass6([
  Autowired93("filterManager")
], _GroupFilter.prototype, "filterManager", 2);
__decorateClass6([
  Autowired93("columnModel")
], _GroupFilter.prototype, "columnModel", 2);
__decorateClass6([
  RefSelector23("eGroupField")
], _GroupFilter.prototype, "eGroupField", 2);
__decorateClass6([
  RefSelector23("eUnderlyingFilter")
], _GroupFilter.prototype, "eUnderlyingFilter", 2);
__decorateClass6([
  PostConstruct63
], _GroupFilter.prototype, "postConstruct", 1);
var GroupFilter = _GroupFilter;
var GroupFloatingFilterComp = class extends Component310 {
  constructor() {
    super(
      /* html */
      `
            <div ref="eFloatingFilter" class="ag-group-floating-filter ag-floating-filter-input" role="presentation"></div>
        `
    );
    this.haveAddedColumnListeners = false;
  }
  init(params) {
    this.params = params;
    const canShowUnderlyingFloatingFilter = this.gos.get("groupDisplayType") === "multipleColumns";
    return new AgPromise22((resolve) => {
      this.params.parentFilterInstance((parentFilterInstance) => {
        this.parentFilterInstance = parentFilterInstance;
        if (canShowUnderlyingFloatingFilter) {
          this.setupUnderlyingFloatingFilterElement().then(() => resolve());
        } else {
          this.setupReadOnlyFloatingFilterElement();
          resolve();
        }
      });
    }).then(() => {
      this.addManagedListener(this.parentFilterInstance, GroupFilter.EVENT_SELECTED_COLUMN_CHANGED, () => this.onSelectedColumnChanged());
      this.addManagedListener(this.parentFilterInstance, GroupFilter.EVENT_COLUMN_ROW_GROUP_CHANGED, () => this.onColumnRowGroupChanged());
    });
  }
  onParamsUpdated(params) {
    this.refresh(params);
  }
  refresh(params) {
    this.params = params;
    this.setParams();
  }
  setParams() {
    var _a;
    const displayName = this.columnModel.getDisplayNameForColumn(this.params.column, "header", true);
    const translate = this.localeService.getLocaleTextFunc();
    (_a = this.eFloatingFilterText) == null ? void 0 : _a.setInputAriaLabel(`${displayName} ${translate("ariaFilterInput", "Filter Input")}`);
  }
  setupReadOnlyFloatingFilterElement() {
    if (!this.eFloatingFilterText) {
      this.eFloatingFilterText = this.createManagedBean(new AgInputTextField2());
      this.eFloatingFilterText.setDisabled(true).addGuiEventListener("click", () => this.params.showParentFilter());
      this.setParams();
    }
    this.updateDisplayedValue();
    this.eFloatingFilter.appendChild(this.eFloatingFilterText.getGui());
  }
  setupUnderlyingFloatingFilterElement() {
    this.showingUnderlyingFloatingFilter = false;
    this.underlyingFloatingFilter = void 0;
    _123.clearElement(this.eFloatingFilter);
    const column = this.parentFilterInstance.getSelectedColumn();
    if (column && !column.isVisible()) {
      const compDetails = this.filterManager.getFloatingFilterCompDetails(column, this.params.showParentFilter);
      if (compDetails) {
        this.compDetails = compDetails;
        if (!this.haveAddedColumnListeners) {
          this.haveAddedColumnListeners = true;
          this.addManagedListener(column, Column11.EVENT_VISIBLE_CHANGED, this.onColumnVisibleChanged.bind(this));
          this.addManagedListener(column, Column11.EVENT_COL_DEF_CHANGED, this.onColDefChanged.bind(this));
        }
        return compDetails.newAgStackInstance().then((floatingFilter) => {
          var _a, _b;
          this.underlyingFloatingFilter = floatingFilter;
          (_b = this.underlyingFloatingFilter) == null ? void 0 : _b.onParentModelChanged((_a = this.parentFilterInstance.getSelectedFilter()) == null ? void 0 : _a.getModel());
          this.appendChild(floatingFilter.getGui());
          this.showingUnderlyingFloatingFilter = true;
        });
      }
    }
    this.setupReadOnlyFloatingFilterElement();
    return AgPromise22.resolve();
  }
  onColumnVisibleChanged() {
    this.setupUnderlyingFloatingFilterElement();
  }
  onColDefChanged(event) {
    var _a, _b, _c;
    if (!event.column) {
      return;
    }
    const compDetails = this.filterManager.getFloatingFilterCompDetails(event.column, this.params.showParentFilter);
    if (compDetails) {
      if ((_a = this.underlyingFloatingFilter) == null ? void 0 : _a.refresh) {
        this.underlyingFloatingFilter.refresh(compDetails.params);
      } else {
        (_c = (_b = this.underlyingFloatingFilter) == null ? void 0 : _b.onParamsUpdated) == null ? void 0 : _c.call(_b, compDetails.params);
      }
    }
  }
  onParentModelChanged(_model, event) {
    var _a, _b;
    if (this.showingUnderlyingFloatingFilter) {
      (_b = this.underlyingFloatingFilter) == null ? void 0 : _b.onParentModelChanged((_a = this.parentFilterInstance.getSelectedFilter()) == null ? void 0 : _a.getModel(), event);
    } else {
      this.updateDisplayedValue();
    }
  }
  updateDisplayedValue() {
    if (!this.parentFilterInstance || !this.eFloatingFilterText) {
      return;
    }
    const selectedFilter = this.parentFilterInstance.getSelectedFilter();
    if (!selectedFilter) {
      this.eFloatingFilterText.setValue("");
      this.eFloatingFilterText.setDisplayed(false);
      return;
    }
    this.eFloatingFilterText.setDisplayed(true);
    if (selectedFilter.getModelAsString) {
      const filterModel = selectedFilter.getModel();
      this.eFloatingFilterText.setValue(filterModel == null ? "" : selectedFilter.getModelAsString(filterModel));
    } else {
      this.eFloatingFilterText.setValue("");
    }
  }
  onSelectedColumnChanged() {
    if (!this.showingUnderlyingFloatingFilter) {
      this.updateDisplayedValue();
    }
  }
  onColumnRowGroupChanged() {
    if (!this.showingUnderlyingFloatingFilter) {
      this.updateDisplayedValue();
    }
  }
  destroy() {
    super.destroy();
  }
};
__decorateClass6([
  Autowired103("columnModel")
], GroupFloatingFilterComp.prototype, "columnModel", 2);
__decorateClass6([
  Autowired103("filterManager")
], GroupFloatingFilterComp.prototype, "filterManager", 2);
__decorateClass6([
  RefSelector34("eFloatingFilter")
], GroupFloatingFilterComp.prototype, "eFloatingFilter", 2);
var RowGroupingModule = {
  version: VERSION6,
  moduleName: ModuleNames6.RowGroupingModule,
  beans: [AggregationStage, FilterAggregatesStage, GroupStage, PivotColDefService, PivotStage, AggFuncService],
  agStackComponents: [
    { componentName: "AgGridHeaderDropZones", componentClass: GridHeaderDropZones }
  ],
  userComponents: [
    { componentName: "agGroupColumnFilter", componentClass: GroupFilter },
    { componentName: "agGroupColumnFloatingFilter", componentClass: GroupFloatingFilterComp }
  ],
  dependantModules: [
    EnterpriseCoreModule
  ]
};
var ValuesDropZonePanel = class extends BaseDropZonePanel {
  constructor(horizontal) {
    super(horizontal, "aggregation");
  }
  passBeansUp() {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const emptyMessage = localeTextFunc("valueColumnsEmptyMessage", "Drag here to aggregate");
    const title = localeTextFunc("values", "Values");
    super.init({
      icon: _132.createIconNoSpan("valuePanel", this.gos, null),
      emptyMessage,
      title
    });
    this.addManagedListener(this.eventService, Events82.EVENT_COLUMN_VALUE_CHANGED, this.refreshGui.bind(this));
  }
  getAriaLabel() {
    const translate = this.localeService.getLocaleTextFunc();
    const label = translate("ariaValuesDropZonePanelLabel", "Values");
    return label;
  }
  getTooltipParams() {
    const res = super.getTooltipParams();
    res.location = "valueColumnsList";
    return res;
  }
  getIconName() {
    return this.isPotentialDndItems() ? DragAndDropService42.ICON_AGGREGATE : DragAndDropService42.ICON_NOT_ALLOWED;
  }
  isItemDroppable(column, draggingEvent) {
    if (this.gos.get("functionsReadOnly") || !column.isPrimary()) {
      return false;
    }
    return column.isAllowValue() && (!column.isValueActive() || this.isSourceEventFromTarget(draggingEvent));
  }
  updateItems(columns) {
    if (this.gos.get("functionsPassive")) {
      const event = {
        type: Events82.EVENT_COLUMN_VALUE_CHANGE_REQUEST,
        columns
      };
      this.eventService.dispatchEvent(event);
    } else {
      this.columnModel.setValueColumns(columns, "toolPanelUi");
    }
  }
  getExistingItems() {
    return this.columnModel.getValueColumns();
  }
};
__decorateClass6([
  PostConstruct73
], ValuesDropZonePanel.prototype, "passBeansUp", 1);

// enterprise-modules/column-tool-panel/dist/package/main.esm.mjs
import {
  Component as Component73,
  RefSelector as RefSelector53,
  PositionableFeature
} from "ag-grid-community";

// enterprise-modules/side-bar/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames23 } from "ag-grid-community";
import {
  Autowired as Autowired51,
  Component as Component44,
  PostConstruct as PostConstruct54,
  Events as Events13
} from "ag-grid-community";
import {
  _ as _38,
  Component as Component53,
  Events as Events25,
  ModuleNames as ModuleNames7,
  ModuleRegistry as ModuleRegistry2,
  PostConstruct as PostConstruct55,
  RefSelector as RefSelector24,
  Autowired as Autowired411,
  ManagedFocusFeature,
  KeyCode as KeyCode22
} from "ag-grid-community";
import {
  Autowired as Autowired211,
  Component as Component311,
  PostConstruct as PostConstruct311,
  PreDestroy as PreDestroy2,
  _ as _210,
  KeyCode as KeyCode7
} from "ag-grid-community";
import {
  Component as Component210,
  PostConstruct as PostConstruct211,
  RefSelector as RefSelector16,
  _ as _31
} from "ag-grid-community";
import {
  Autowired as Autowired311,
  Component as Component45,
  PostConstruct as PostConstruct411
} from "ag-grid-community";
import {
  _ as _45,
  Autowired as Autowired54,
  Bean as Bean10,
  BeanStub as BeanStub17,
  ProvidedColumnGroup
} from "ag-grid-community";
import { Bean as Bean26, BeanStub as BeanStub26 } from "ag-grid-community";
var __defProp7 = Object.defineProperty;
var __defProps5 = Object.defineProperties;
var __getOwnPropDesc7 = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs5 = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols5 = Object.getOwnPropertySymbols;
var __hasOwnProp6 = Object.prototype.hasOwnProperty;
var __propIsEnum5 = Object.prototype.propertyIsEnumerable;
var __defNormalProp5 = (obj, key, value) => key in obj ? __defProp7(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues5 = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp6.call(b, prop))
      __defNormalProp5(a, prop, b[prop]);
  if (__getOwnPropSymbols5)
    for (var prop of __getOwnPropSymbols5(b)) {
      if (__propIsEnum5.call(b, prop))
        __defNormalProp5(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps5 = (a, b) => __defProps5(a, __getOwnPropDescs5(b));
var __decorateClass7 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc7(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp7(target, key, result);
  return result;
};
var HorizontalResizeComp = class extends Component44 {
  constructor() {
    super(
      /* html */
      `<div class="ag-tool-panel-horizontal-resize"></div>`
    );
    this.minWidth = 100;
    this.maxWidth = null;
  }
  setElementToResize(elementToResize) {
    this.elementToResize = elementToResize;
  }
  postConstruct() {
    const finishedWithResizeFunc = this.horizontalResizeService.addResizeBar({
      eResizeBar: this.getGui(),
      dragStartPixels: 1,
      onResizeStart: this.onResizeStart.bind(this),
      onResizing: this.onResizing.bind(this),
      onResizeEnd: this.onResizeEnd.bind(this)
    });
    this.addDestroyFunc(finishedWithResizeFunc);
    this.setInverted(this.gos.get("enableRtl"));
  }
  dispatchResizeEvent(start, end, width) {
    const event = {
      type: Events13.EVENT_TOOL_PANEL_SIZE_CHANGED,
      width,
      started: start,
      ended: end
    };
    this.eventService.dispatchEvent(event);
  }
  onResizeStart() {
    this.startingWidth = this.elementToResize.offsetWidth;
    this.dispatchResizeEvent(true, false, this.startingWidth);
  }
  onResizeEnd(delta) {
    return this.onResizing(delta, true);
  }
  onResizing(delta, isEnd = false) {
    const direction = this.inverted ? -1 : 1;
    let newWidth = Math.max(this.minWidth, Math.floor(this.startingWidth - delta * direction));
    if (this.maxWidth != null) {
      newWidth = Math.min(this.maxWidth, newWidth);
    }
    this.elementToResize.style.width = `${newWidth}px`;
    this.dispatchResizeEvent(false, isEnd, newWidth);
  }
  setInverted(inverted) {
    this.inverted = inverted;
  }
  setMaxWidth(value) {
    this.maxWidth = value;
  }
  setMinWidth(value) {
    if (value != null) {
      this.minWidth = value;
    } else {
      this.minWidth = 100;
    }
  }
};
__decorateClass7([
  Autowired51("horizontalResizeService")
], HorizontalResizeComp.prototype, "horizontalResizeService", 2);
__decorateClass7([
  PostConstruct54
], HorizontalResizeComp.prototype, "postConstruct", 1);
var _SideBarButtonComp = class _SideBarButtonComp2 extends Component210 {
  constructor(toolPanelDef) {
    super();
    this.toolPanelDef = toolPanelDef;
  }
  getToolPanelId() {
    return this.toolPanelDef.id;
  }
  postConstruct() {
    const template = this.createTemplate();
    this.setTemplate(template);
    this.setLabel();
    this.setIcon();
    this.addManagedListener(this.eToggleButton, "click", this.onButtonPressed.bind(this));
    this.eToggleButton.setAttribute("id", `ag-${this.getCompId()}-button`);
  }
  createTemplate() {
    const res = (
      /* html */
      `<div class="ag-side-button" role="presentation">
                <button type="button" ref="eToggleButton" tabindex="-1" role="tab" aria-expanded="false" class="ag-button ag-side-button-button">
                    <div ref="eIconWrapper" class="ag-side-button-icon-wrapper" aria-hidden="true"></div>
                    <span ref ="eLabel" class="ag-side-button-label"></span>
                </button>
            </div>`
    );
    return res;
  }
  setLabel() {
    const translate = this.localeService.getLocaleTextFunc();
    const def = this.toolPanelDef;
    const label = translate(def.labelKey, def.labelDefault);
    this.eLabel.innerText = label;
  }
  setIcon() {
    this.eIconWrapper.insertAdjacentElement("afterbegin", _31.createIconNoSpan(this.toolPanelDef.iconKey, this.gos));
  }
  onButtonPressed() {
    this.dispatchEvent({ type: _SideBarButtonComp2.EVENT_TOGGLE_BUTTON_CLICKED });
  }
  setSelected(selected) {
    this.addOrRemoveCssClass("ag-selected", selected);
    _31.setAriaExpanded(this.eToggleButton, selected);
  }
  getButtonElement() {
    return this.eToggleButton;
  }
};
_SideBarButtonComp.EVENT_TOGGLE_BUTTON_CLICKED = "toggleButtonClicked";
__decorateClass7([
  RefSelector16("eToggleButton")
], _SideBarButtonComp.prototype, "eToggleButton", 2);
__decorateClass7([
  RefSelector16("eIconWrapper")
], _SideBarButtonComp.prototype, "eIconWrapper", 2);
__decorateClass7([
  RefSelector16("eLabel")
], _SideBarButtonComp.prototype, "eLabel", 2);
__decorateClass7([
  PostConstruct211
], _SideBarButtonComp.prototype, "postConstruct", 1);
var SideBarButtonComp = _SideBarButtonComp;
var _SideBarButtonsComp = class _SideBarButtonsComp2 extends Component311 {
  constructor() {
    super(_SideBarButtonsComp2.TEMPLATE);
    this.buttonComps = [];
  }
  postConstruct() {
    this.addManagedListener(this.getFocusableElement(), "keydown", this.handleKeyDown.bind(this));
  }
  handleKeyDown(e) {
    if (e.key !== KeyCode7.TAB || !e.shiftKey) {
      return;
    }
    const lastColumn = _210.last(this.columnModel.getAllDisplayedColumns());
    if (this.focusService.focusGridView(lastColumn, true)) {
      e.preventDefault();
    }
  }
  setActiveButton(id) {
    this.buttonComps.forEach((comp) => {
      comp.setSelected(id === comp.getToolPanelId());
    });
  }
  addButtonComp(def) {
    const buttonComp = this.createBean(new SideBarButtonComp(def));
    this.buttonComps.push(buttonComp);
    this.appendChild(buttonComp);
    buttonComp.addEventListener(SideBarButtonComp.EVENT_TOGGLE_BUTTON_CLICKED, () => {
      this.dispatchEvent({
        type: _SideBarButtonsComp2.EVENT_SIDE_BAR_BUTTON_CLICKED,
        toolPanelId: def.id
      });
    });
    return buttonComp;
  }
  clearButtons() {
    this.buttonComps = this.destroyBeans(this.buttonComps);
    _210.clearElement(this.getGui());
  }
};
_SideBarButtonsComp.EVENT_SIDE_BAR_BUTTON_CLICKED = "sideBarButtonClicked";
_SideBarButtonsComp.TEMPLATE = /* html */
`<div class="ag-side-buttons" role="tablist"></div>`;
__decorateClass7([
  Autowired211("focusService")
], _SideBarButtonsComp.prototype, "focusService", 2);
__decorateClass7([
  Autowired211("columnModel")
], _SideBarButtonsComp.prototype, "columnModel", 2);
__decorateClass7([
  PostConstruct311
], _SideBarButtonsComp.prototype, "postConstruct", 1);
__decorateClass7([
  PreDestroy2
], _SideBarButtonsComp.prototype, "clearButtons", 1);
var SideBarButtonsComp = _SideBarButtonsComp;
var _SideBarDefParser = class _SideBarDefParser2 {
  static parse(toParse) {
    if (!toParse) {
      return void 0;
    }
    if (toParse === true) {
      return {
        toolPanels: [
          _SideBarDefParser2.DEFAULT_COLUMN_COMP,
          _SideBarDefParser2.DEFAULT_FILTER_COMP
        ],
        defaultToolPanel: "columns"
      };
    }
    if (typeof toParse === "string") {
      return _SideBarDefParser2.parse([toParse]);
    }
    if (Array.isArray(toParse)) {
      const comps = [];
      toParse.forEach((key) => {
        const lookupResult = _SideBarDefParser2.DEFAULT_BY_KEY[key];
        if (!lookupResult) {
          console.warn(`AG Grid: the key ${key} is not a valid key for specifying a tool panel, valid keys are: ${Object.keys(_SideBarDefParser2.DEFAULT_BY_KEY).join(",")}`);
          return;
        }
        comps.push(lookupResult);
      });
      if (comps.length === 0) {
        return void 0;
      }
      return {
        toolPanels: comps,
        defaultToolPanel: comps[0].id
      };
    }
    const result = {
      toolPanels: _SideBarDefParser2.parseComponents(toParse.toolPanels),
      defaultToolPanel: toParse.defaultToolPanel,
      hiddenByDefault: toParse.hiddenByDefault,
      position: toParse.position
    };
    return result;
  }
  static parseComponents(from) {
    const result = [];
    if (!from) {
      return result;
    }
    from.forEach((it) => {
      let toAdd = null;
      if (typeof it === "string") {
        const lookupResult = _SideBarDefParser2.DEFAULT_BY_KEY[it];
        if (!lookupResult) {
          console.warn(`AG Grid: the key ${it} is not a valid key for specifying a tool panel, valid keys are: ${Object.keys(_SideBarDefParser2.DEFAULT_BY_KEY).join(",")}`);
          return;
        }
        toAdd = lookupResult;
      } else {
        toAdd = it;
      }
      result.push(toAdd);
    });
    return result;
  }
};
_SideBarDefParser.DEFAULT_COLUMN_COMP = {
  id: "columns",
  labelDefault: "Columns",
  labelKey: "columns",
  iconKey: "columns",
  toolPanel: "agColumnsToolPanel"
};
_SideBarDefParser.DEFAULT_FILTER_COMP = {
  id: "filters",
  labelDefault: "Filters",
  labelKey: "filters",
  iconKey: "filter",
  toolPanel: "agFiltersToolPanel"
};
_SideBarDefParser.DEFAULT_BY_KEY = {
  columns: _SideBarDefParser.DEFAULT_COLUMN_COMP,
  filters: _SideBarDefParser.DEFAULT_FILTER_COMP
};
var SideBarDefParser = _SideBarDefParser;
var _ToolPanelWrapper = class _ToolPanelWrapper2 extends Component45 {
  constructor() {
    super(_ToolPanelWrapper2.TEMPLATE);
  }
  setupResize() {
    const eGui = this.getGui();
    const resizeBar = this.resizeBar = this.createManagedBean(new HorizontalResizeComp());
    eGui.setAttribute("id", `ag-${this.getCompId()}`);
    resizeBar.setElementToResize(eGui);
    this.appendChild(resizeBar);
  }
  getToolPanelId() {
    return this.toolPanelId;
  }
  setToolPanelDef(toolPanelDef, params) {
    const { id, minWidth, maxWidth, width } = toolPanelDef;
    this.toolPanelId = id;
    this.width = width;
    const compDetails = this.userComponentFactory.getToolPanelCompDetails(toolPanelDef, params);
    const componentPromise = compDetails.newAgStackInstance();
    this.params = compDetails.params;
    if (componentPromise == null) {
      console.warn(`AG Grid: error processing tool panel component ${id}. You need to specify 'toolPanel'`);
      return;
    }
    componentPromise.then(this.setToolPanelComponent.bind(this));
    if (minWidth != null) {
      this.resizeBar.setMinWidth(minWidth);
    }
    if (maxWidth != null) {
      this.resizeBar.setMaxWidth(maxWidth);
    }
  }
  setToolPanelComponent(compInstance) {
    this.toolPanelCompInstance = compInstance;
    this.appendChild(compInstance.getGui());
    this.addDestroyFunc(() => {
      this.destroyBean(compInstance);
    });
    if (this.width) {
      this.getGui().style.width = `${this.width}px`;
    }
  }
  getToolPanelInstance() {
    return this.toolPanelCompInstance;
  }
  setResizerSizerSide(side) {
    const isRtl = this.gos.get("enableRtl");
    const isLeft = side === "left";
    const inverted = isRtl ? isLeft : !isLeft;
    this.resizeBar.setInverted(inverted);
  }
  refresh() {
    this.toolPanelCompInstance.refresh(this.params);
  }
};
_ToolPanelWrapper.TEMPLATE = /* html */
`<div class="ag-tool-panel-wrapper" role="tabpanel"/>`;
__decorateClass7([
  Autowired311("userComponentFactory")
], _ToolPanelWrapper.prototype, "userComponentFactory", 2);
__decorateClass7([
  PostConstruct411
], _ToolPanelWrapper.prototype, "setupResize", 1);
var ToolPanelWrapper = _ToolPanelWrapper;
var _SideBarComp = class _SideBarComp2 extends Component53 {
  constructor() {
    super(_SideBarComp2.TEMPLATE);
    this.toolPanelWrappers = [];
  }
  postConstruct() {
    var _a;
    this.sideBarButtonsComp.addEventListener(SideBarButtonsComp.EVENT_SIDE_BAR_BUTTON_CLICKED, this.onToolPanelButtonClicked.bind(this));
    const { sideBar: sideBarState } = (_a = this.gos.get("initialState")) != null ? _a : {};
    this.setSideBarDef({
      sideBarDef: SideBarDefParser.parse(this.gos.get("sideBar")),
      sideBarState
    });
    this.addManagedPropertyListener("sideBar", this.onSideBarUpdated.bind(this));
    this.sideBarService.registerSideBarComp(this);
    this.createManagedBean(new ManagedFocusFeature(
      this.getFocusableElement(),
      {
        onTabKeyDown: this.onTabKeyDown.bind(this),
        handleKeyDown: this.handleKeyDown.bind(this)
      }
    ));
  }
  onTabKeyDown(e) {
    if (e.defaultPrevented) {
      return;
    }
    const { focusService, sideBarButtonsComp } = this;
    const eGui = this.getGui();
    const sideBarGui = sideBarButtonsComp.getGui();
    const activeElement = this.gos.getActiveDomElement();
    const openPanel = eGui.querySelector(".ag-tool-panel-wrapper:not(.ag-hidden)");
    const target = e.target;
    if (!openPanel) {
      return;
    }
    if (sideBarGui.contains(activeElement)) {
      if (focusService.focusInto(openPanel, e.shiftKey)) {
        e.preventDefault();
      }
      return;
    }
    if (!e.shiftKey) {
      return;
    }
    let nextEl = null;
    if (openPanel.contains(activeElement)) {
      nextEl = this.focusService.findNextFocusableElement(openPanel, void 0, true);
    } else if (focusService.isTargetUnderManagedComponent(openPanel, target) && e.shiftKey) {
      nextEl = this.focusService.findFocusableElementBeforeTabGuard(openPanel, target);
    }
    if (!nextEl) {
      nextEl = sideBarGui.querySelector(".ag-selected button");
    }
    if (nextEl && nextEl !== e.target) {
      e.preventDefault();
      nextEl.focus();
    }
  }
  handleKeyDown(e) {
    const currentButton = this.gos.getActiveDomElement();
    if (!this.sideBarButtonsComp.getGui().contains(currentButton)) {
      return;
    }
    const sideBarGui = this.sideBarButtonsComp.getGui();
    const buttons = Array.prototype.slice.call(sideBarGui.querySelectorAll(".ag-side-button"));
    const currentPos = buttons.findIndex((button) => button.contains(currentButton));
    let nextPos = null;
    switch (e.key) {
      case KeyCode22.LEFT:
      case KeyCode22.UP:
        nextPos = Math.max(0, currentPos - 1);
        break;
      case KeyCode22.RIGHT:
      case KeyCode22.DOWN:
        nextPos = Math.min(currentPos + 1, buttons.length - 1);
        break;
    }
    if (nextPos === null) {
      return;
    }
    const innerButton = buttons[nextPos].querySelector("button");
    if (innerButton) {
      innerButton.focus();
      e.preventDefault();
    }
  }
  onToolPanelButtonClicked(event) {
    const id = event.toolPanelId;
    const openedItem = this.openedItem();
    if (openedItem === id) {
      this.openToolPanel(void 0, "sideBarButtonClicked");
    } else {
      this.openToolPanel(id, "sideBarButtonClicked");
    }
  }
  clearDownUi() {
    this.sideBarButtonsComp.clearButtons();
    this.destroyToolPanelWrappers();
  }
  setSideBarDef({
    sideBarDef,
    sideBarState,
    existingToolPanelWrappers
  }) {
    this.setDisplayed(false);
    this.sideBar = sideBarDef;
    if (!!this.sideBar && !!this.sideBar.toolPanels) {
      const toolPanelDefs = this.sideBar.toolPanels;
      this.createToolPanelsAndSideButtons(toolPanelDefs, sideBarState, existingToolPanelWrappers);
      if (!this.toolPanelWrappers.length) {
        return;
      }
      const shouldDisplaySideBar = sideBarState ? sideBarState.visible : !this.sideBar.hiddenByDefault;
      this.setDisplayed(shouldDisplaySideBar);
      this.setSideBarPosition(sideBarState ? sideBarState.position : this.sideBar.position);
      if (shouldDisplaySideBar) {
        if (sideBarState) {
          const { openToolPanel } = sideBarState;
          if (openToolPanel) {
            this.openToolPanel(openToolPanel, "sideBarInitializing");
          }
        } else {
          this.openToolPanel(this.sideBar.defaultToolPanel, "sideBarInitializing");
        }
      }
    }
  }
  getDef() {
    return this.sideBar;
  }
  setSideBarPosition(position) {
    if (!position) {
      position = "right";
    }
    this.position = position;
    const isLeft = position === "left";
    const resizerSide = isLeft ? "right" : "left";
    this.addOrRemoveCssClass("ag-side-bar-left", isLeft);
    this.addOrRemoveCssClass("ag-side-bar-right", !isLeft);
    this.toolPanelWrappers.forEach((wrapper) => {
      wrapper.setResizerSizerSide(resizerSide);
    });
    this.eventService.dispatchEvent({ type: Events25.EVENT_SIDE_BAR_UPDATED });
    return this;
  }
  setDisplayed(displayed, options) {
    super.setDisplayed(displayed, options);
    this.eventService.dispatchEvent({ type: Events25.EVENT_SIDE_BAR_UPDATED });
  }
  getState() {
    const toolPanels = {};
    this.toolPanelWrappers.forEach((wrapper) => {
      var _a, _b;
      toolPanels[wrapper.getToolPanelId()] = (_b = (_a = wrapper.getToolPanelInstance()) == null ? void 0 : _a.getState) == null ? void 0 : _b.call(_a);
    });
    return {
      visible: this.isDisplayed(),
      position: this.position,
      openToolPanel: this.openedItem(),
      toolPanels
    };
  }
  createToolPanelsAndSideButtons(defs, sideBarState, existingToolPanelWrappers) {
    var _a;
    for (const def of defs) {
      this.createToolPanelAndSideButton(def, (_a = sideBarState == null ? void 0 : sideBarState.toolPanels) == null ? void 0 : _a[def.id], existingToolPanelWrappers == null ? void 0 : existingToolPanelWrappers[def.id]);
    }
  }
  validateDef(def) {
    if (def.id == null) {
      console.warn(`AG Grid: please review all your toolPanel components, it seems like at least one of them doesn't have an id`);
      return false;
    }
    if (def.toolPanel === "agColumnsToolPanel") {
      const moduleMissing = !ModuleRegistry2.__assertRegistered(ModuleNames7.ColumnsToolPanelModule, "Column Tool Panel", this.context.getGridId());
      if (moduleMissing) {
        return false;
      }
    }
    if (def.toolPanel === "agFiltersToolPanel") {
      const moduleMissing = !ModuleRegistry2.__assertRegistered(ModuleNames7.FiltersToolPanelModule, "Filters Tool Panel", this.context.getGridId());
      if (moduleMissing) {
        return false;
      }
      if (this.filterManager.isAdvancedFilterEnabled()) {
        _38.warnOnce("Advanced Filter does not work with Filters Tool Panel. Filters Tool Panel has been disabled.");
        return false;
      }
    }
    return true;
  }
  createToolPanelAndSideButton(def, initialState, existingToolPanelWrapper) {
    if (!this.validateDef(def)) {
      return;
    }
    const button = this.sideBarButtonsComp.addButtonComp(def);
    let wrapper;
    if (existingToolPanelWrapper) {
      wrapper = existingToolPanelWrapper;
    } else {
      wrapper = this.getContext().createBean(new ToolPanelWrapper());
      wrapper.setToolPanelDef(def, {
        initialState,
        onStateUpdated: () => this.eventService.dispatchEvent({ type: Events25.EVENT_SIDE_BAR_UPDATED })
      });
    }
    wrapper.setDisplayed(false);
    const wrapperGui = wrapper.getGui();
    this.appendChild(wrapperGui);
    this.toolPanelWrappers.push(wrapper);
    _38.setAriaControls(button.getButtonElement(), wrapperGui);
  }
  refresh() {
    this.toolPanelWrappers.forEach((wrapper) => wrapper.refresh());
  }
  openToolPanel(key, source = "api") {
    const currentlyOpenedKey = this.openedItem();
    if (currentlyOpenedKey === key) {
      return;
    }
    this.toolPanelWrappers.forEach((wrapper) => {
      const show = key === wrapper.getToolPanelId();
      wrapper.setDisplayed(show);
    });
    const newlyOpenedKey = this.openedItem();
    const openToolPanelChanged = currentlyOpenedKey !== newlyOpenedKey;
    if (openToolPanelChanged) {
      this.sideBarButtonsComp.setActiveButton(key);
      this.raiseToolPanelVisibleEvent(key, currentlyOpenedKey != null ? currentlyOpenedKey : void 0, source);
    }
  }
  getToolPanelInstance(key) {
    const toolPanelWrapper = this.toolPanelWrappers.filter((toolPanel) => toolPanel.getToolPanelId() === key)[0];
    if (!toolPanelWrapper) {
      console.warn(`AG Grid: unable to lookup Tool Panel as invalid key supplied: ${key}`);
      return;
    }
    return toolPanelWrapper.getToolPanelInstance();
  }
  raiseToolPanelVisibleEvent(key, previousKey, source) {
    const switchingToolPanel = !!key && !!previousKey;
    if (previousKey) {
      const event = {
        type: Events25.EVENT_TOOL_PANEL_VISIBLE_CHANGED,
        source,
        key: previousKey,
        visible: false,
        switchingToolPanel
      };
      this.eventService.dispatchEvent(event);
    }
    if (key) {
      const event = {
        type: Events25.EVENT_TOOL_PANEL_VISIBLE_CHANGED,
        source,
        key,
        visible: true,
        switchingToolPanel
      };
      this.eventService.dispatchEvent(event);
    }
  }
  close(source = "api") {
    this.openToolPanel(void 0, source);
  }
  isToolPanelShowing() {
    return !!this.openedItem();
  }
  openedItem() {
    let activeToolPanel = null;
    this.toolPanelWrappers.forEach((wrapper) => {
      if (wrapper.isDisplayed()) {
        activeToolPanel = wrapper.getToolPanelId();
      }
    });
    return activeToolPanel;
  }
  onSideBarUpdated() {
    var _a;
    const sideBarDef = SideBarDefParser.parse(this.gos.get("sideBar"));
    let existingToolPanelWrappers = {};
    if (sideBarDef && this.sideBar) {
      (_a = sideBarDef.toolPanels) == null ? void 0 : _a.forEach((toolPanelDef) => {
        var _a2, _b;
        const { id } = toolPanelDef;
        if (!id) {
          return;
        }
        const existingToolPanelDef = (_a2 = this.sideBar.toolPanels) == null ? void 0 : _a2.find(
          (toolPanelDefToCheck) => toolPanelDefToCheck.id === id
        );
        if (!existingToolPanelDef || toolPanelDef.toolPanel !== existingToolPanelDef.toolPanel) {
          return;
        }
        const toolPanelWrapper = this.toolPanelWrappers.find((toolPanel) => toolPanel.getToolPanelId() === id);
        if (!toolPanelWrapper) {
          return;
        }
        const params = this.gos.addGridCommonParams(__spreadProps5(__spreadValues5({}, (_b = toolPanelDef.toolPanelParams) != null ? _b : {}), {
          onStateUpdated: () => this.eventService.dispatchEvent({ type: Events25.EVENT_SIDE_BAR_UPDATED })
        }));
        const hasRefreshed = toolPanelWrapper.getToolPanelInstance().refresh(params);
        if (hasRefreshed !== true) {
          return;
        }
        this.toolPanelWrappers = this.toolPanelWrappers.filter((toolPanel) => toolPanel !== toolPanelWrapper);
        _38.removeFromParent(toolPanelWrapper.getGui());
        existingToolPanelWrappers[id] = toolPanelWrapper;
      });
    }
    this.clearDownUi();
    this.setSideBarDef({ sideBarDef, existingToolPanelWrappers });
  }
  destroyToolPanelWrappers() {
    this.toolPanelWrappers.forEach((wrapper) => {
      _38.removeFromParent(wrapper.getGui());
      this.destroyBean(wrapper);
    });
    this.toolPanelWrappers.length = 0;
  }
  destroy() {
    this.destroyToolPanelWrappers();
    super.destroy();
  }
};
_SideBarComp.TEMPLATE = /* html */
`<div class="ag-side-bar ag-unselectable">
            <ag-side-bar-buttons ref="sideBarButtons"></ag-side-bar-buttons>
        </div>`;
__decorateClass7([
  Autowired411("focusService")
], _SideBarComp.prototype, "focusService", 2);
__decorateClass7([
  Autowired411("filterManager")
], _SideBarComp.prototype, "filterManager", 2);
__decorateClass7([
  Autowired411("sideBarService")
], _SideBarComp.prototype, "sideBarService", 2);
__decorateClass7([
  RefSelector24("sideBarButtons")
], _SideBarComp.prototype, "sideBarButtonsComp", 2);
__decorateClass7([
  PostConstruct55
], _SideBarComp.prototype, "postConstruct", 1);
var SideBarComp = _SideBarComp;
var ToolPanelColDefService = class extends BeanStub17 {
  constructor() {
    super(...arguments);
    this.isColGroupDef = (colDef) => colDef && typeof colDef.children !== "undefined";
    this.getId = (colDef) => {
      return this.isColGroupDef(colDef) ? colDef.groupId : colDef.colId;
    };
  }
  createColumnTree(colDefs) {
    const invalidColIds = [];
    const createDummyColGroup = (abstractColDef, depth) => {
      if (this.isColGroupDef(abstractColDef)) {
        const groupDef = abstractColDef;
        const groupId = typeof groupDef.groupId !== "undefined" ? groupDef.groupId : groupDef.headerName;
        const group = new ProvidedColumnGroup(groupDef, groupId, false, depth);
        const children = [];
        groupDef.children.forEach((def) => {
          const child = createDummyColGroup(def, depth + 1);
          if (child) {
            children.push(child);
          }
        });
        group.setChildren(children);
        return group;
      } else {
        const colDef = abstractColDef;
        const key = colDef.colId ? colDef.colId : colDef.field;
        const column = this.columnModel.getPrimaryColumn(key);
        if (!column) {
          invalidColIds.push(colDef);
        }
        return column;
      }
    };
    const mappedResults = [];
    colDefs.forEach((colDef) => {
      const result = createDummyColGroup(colDef, 0);
      if (result) {
        mappedResults.push(result);
      }
    });
    if (invalidColIds.length > 0) {
      console.warn("AG Grid: unable to find grid columns for the supplied colDef(s):", invalidColIds);
    }
    return mappedResults;
  }
  syncLayoutWithGrid(syncLayoutCallback) {
    const leafPathTrees = this.getLeafPathTrees();
    const mergedColumnTrees = this.mergeLeafPathTrees(leafPathTrees);
    syncLayoutCallback(mergedColumnTrees);
  }
  getLeafPathTrees() {
    const getLeafPathTree = (node, childDef) => {
      let leafPathTree;
      if (node instanceof ProvidedColumnGroup) {
        if (node.isPadding()) {
          leafPathTree = childDef;
        } else {
          const groupDef = Object.assign({}, node.getColGroupDef());
          groupDef.groupId = node.getGroupId();
          groupDef.children = [childDef];
          leafPathTree = groupDef;
        }
      } else {
        const colDef = Object.assign({}, node.getColDef());
        colDef.colId = node.getColId();
        leafPathTree = colDef;
      }
      const parent = node.getOriginalParent();
      if (parent) {
        return getLeafPathTree(parent, leafPathTree);
      } else {
        return leafPathTree;
      }
    };
    const allGridColumns = this.columnModel.getAllGridColumns();
    const allPrimaryGridColumns = allGridColumns.filter((column) => {
      const colDef = column.getColDef();
      return column.isPrimary() && !colDef.showRowGroup;
    });
    return allPrimaryGridColumns.map((col) => getLeafPathTree(col, col.getColDef()));
  }
  mergeLeafPathTrees(leafPathTrees) {
    const matchingRootGroupIds = (pathA, pathB) => {
      const bothPathsAreGroups = this.isColGroupDef(pathA) && this.isColGroupDef(pathB);
      return bothPathsAreGroups && this.getId(pathA) === this.getId(pathB);
    };
    const mergeTrees = (treeA, treeB) => {
      if (!this.isColGroupDef(treeB)) {
        return treeA;
      }
      const mergeResult = treeA;
      const groupToMerge = treeB;
      if (groupToMerge.children && groupToMerge.groupId) {
        const added = this.addChildrenToGroup(mergeResult, groupToMerge.groupId, groupToMerge.children[0]);
        if (added) {
          return mergeResult;
        }
      }
      groupToMerge.children.forEach((child) => mergeTrees(mergeResult, child));
      return mergeResult;
    };
    const mergeColDefs = [];
    for (let i = 1; i <= leafPathTrees.length; i++) {
      const first = leafPathTrees[i - 1];
      const second = leafPathTrees[i];
      if (matchingRootGroupIds(first, second)) {
        leafPathTrees[i] = mergeTrees(first, second);
      } else {
        mergeColDefs.push(first);
      }
    }
    return mergeColDefs;
  }
  addChildrenToGroup(tree, groupId, colDef) {
    const subGroupIsSplit = (currentSubGroup, currentSubGroupToAdd) => {
      const existingChildIds = currentSubGroup.children.map(this.getId);
      const childGroupAlreadyExists = _45.includes(existingChildIds, this.getId(currentSubGroupToAdd));
      const lastChild = _45.last(currentSubGroup.children);
      const lastChildIsDifferent = lastChild && this.getId(lastChild) !== this.getId(currentSubGroupToAdd);
      return childGroupAlreadyExists && lastChildIsDifferent;
    };
    if (!this.isColGroupDef(tree)) {
      return true;
    }
    const currentGroup = tree;
    const groupToAdd = colDef;
    if (subGroupIsSplit(currentGroup, groupToAdd)) {
      currentGroup.children.push(groupToAdd);
      return true;
    }
    if (currentGroup.groupId === groupId) {
      const existingChildIds = currentGroup.children.map(this.getId);
      const colDefAlreadyPresent = _45.includes(existingChildIds, this.getId(groupToAdd));
      if (!colDefAlreadyPresent) {
        currentGroup.children.push(groupToAdd);
        return true;
      }
    }
    currentGroup.children.forEach((subGroup) => this.addChildrenToGroup(subGroup, groupId, colDef));
    return false;
  }
};
__decorateClass7([
  Autowired54("columnModel")
], ToolPanelColDefService.prototype, "columnModel", 2);
ToolPanelColDefService = __decorateClass7([
  Bean10("toolPanelColDefService")
], ToolPanelColDefService);
var VERSION7 = "31.3.2";
var SideBarService = class extends BeanStub26 {
  registerSideBarComp(sideBarComp) {
    this.sideBarComp = sideBarComp;
  }
  getSideBarComp() {
    return this.sideBarComp;
  }
};
SideBarService = __decorateClass7([
  Bean26("sideBarService")
], SideBarService);
var SideBarModule = {
  version: VERSION7,
  moduleName: ModuleNames23.SideBarModule,
  beans: [ToolPanelColDefService, SideBarService],
  agStackComponents: [
    { componentName: "AgHorizontalResize", componentClass: HorizontalResizeComp },
    { componentName: "AgSideBar", componentClass: SideBarComp },
    { componentName: "AgSideBarButtons", componentClass: SideBarButtonsComp }
  ],
  dependantModules: [
    EnterpriseCoreModule
  ]
};

// enterprise-modules/column-tool-panel/dist/package/main.esm.mjs
import {
  Events as Events83,
  Bean as Bean11,
  Autowired as Autowired84,
  _ as _84
} from "ag-grid-community";
var __defProp8 = Object.defineProperty;
var __getOwnPropDesc8 = Object.getOwnPropertyDescriptor;
var __getOwnPropSymbols6 = Object.getOwnPropertySymbols;
var __hasOwnProp7 = Object.prototype.hasOwnProperty;
var __propIsEnum6 = Object.prototype.propertyIsEnumerable;
var __defNormalProp6 = (obj, key, value) => key in obj ? __defProp8(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues6 = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp7.call(b, prop))
      __defNormalProp6(a, prop, b[prop]);
  if (__getOwnPropSymbols6)
    for (var prop of __getOwnPropSymbols6(b)) {
      if (__propIsEnum6.call(b, prop))
        __defNormalProp6(a, prop, b[prop]);
    }
  return a;
};
var __decorateClass8 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc8(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp8(target, key, result);
  return result;
};
var _PrimaryColsHeaderPanel = class _PrimaryColsHeaderPanel2 extends Component46 {
  constructor() {
    super(_PrimaryColsHeaderPanel2.TEMPLATE);
  }
  postConstruct() {
    this.createExpandIcons();
    this.addManagedListener(this.eExpand, "click", this.onExpandClicked.bind(this));
    this.addManagedListener(this.eExpand, "keydown", (e) => {
      if (e.key === KeyCode8.SPACE) {
        e.preventDefault();
        this.onExpandClicked();
      }
    });
    this.addManagedListener(this.eSelect.getInputElement(), "click", this.onSelectClicked.bind(this));
    this.addManagedPropertyListener("functionsReadOnly", () => this.onFunctionsReadOnlyPropChanged());
    this.eFilterTextField.setAutoComplete(false).onValueChange(() => this.onFilterTextChanged());
    this.addManagedListener(
      this.eFilterTextField.getInputElement(),
      "keydown",
      this.onMiniFilterKeyDown.bind(this)
    );
    this.addManagedListener(this.eventService, Events14.EVENT_NEW_COLUMNS_LOADED, this.showOrHideOptions.bind(this));
    const translate = this.localeService.getLocaleTextFunc();
    this.eSelect.setInputAriaLabel(translate("ariaColumnSelectAll", "Toggle Select All Columns"));
    this.eFilterTextField.setInputAriaLabel(translate("ariaFilterColumnsInput", "Filter Columns Input"));
    this.activateTabIndex([this.eExpand]);
  }
  onFunctionsReadOnlyPropChanged() {
    const readOnly = this.gos.get("functionsReadOnly");
    this.eSelect.setReadOnly(readOnly);
    this.eSelect.addOrRemoveCssClass("ag-column-select-column-readonly", readOnly);
  }
  init(params) {
    this.params = params;
    const readOnly = this.gos.get("functionsReadOnly");
    this.eSelect.setReadOnly(readOnly);
    this.eSelect.addOrRemoveCssClass("ag-column-select-column-readonly", readOnly);
    if (this.columnModel.isReady()) {
      this.showOrHideOptions();
    }
  }
  createExpandIcons() {
    this.eExpand.appendChild(this.eExpandChecked = _39.createIconNoSpan("columnSelectOpen", this.gos));
    this.eExpand.appendChild(this.eExpandUnchecked = _39.createIconNoSpan("columnSelectClosed", this.gos));
    this.eExpand.appendChild(this.eExpandIndeterminate = _39.createIconNoSpan("columnSelectIndeterminate", this.gos));
    this.setExpandState(
      0
      /* EXPANDED */
    );
  }
  // we only show expand / collapse if we are showing columns
  showOrHideOptions() {
    const showFilter = !this.params.suppressColumnFilter;
    const showSelect = !this.params.suppressColumnSelectAll;
    const showExpand = !this.params.suppressColumnExpandAll;
    const groupsPresent = this.columnModel.isPrimaryColumnGroupsPresent();
    const translate = this.localeService.getLocaleTextFunc();
    this.eFilterTextField.setInputPlaceholder(translate("searchOoo", "Search..."));
    _39.setDisplayed(this.eFilterTextField.getGui(), showFilter);
    _39.setDisplayed(this.eSelect.getGui(), showSelect);
    _39.setDisplayed(this.eExpand, showExpand && groupsPresent);
  }
  onFilterTextChanged() {
    if (!this.onFilterTextChangedDebounced) {
      this.onFilterTextChangedDebounced = _39.debounce(() => {
        const filterText = this.eFilterTextField.getValue();
        this.dispatchEvent({ type: "filterChanged", filterText });
      }, _PrimaryColsHeaderPanel2.DEBOUNCE_DELAY);
    }
    this.onFilterTextChangedDebounced();
  }
  onMiniFilterKeyDown(e) {
    if (e.key === KeyCode8.ENTER) {
      setTimeout(() => this.onSelectClicked(), _PrimaryColsHeaderPanel2.DEBOUNCE_DELAY);
    }
  }
  onSelectClicked() {
    this.dispatchEvent({ type: this.selectState ? "unselectAll" : "selectAll" });
  }
  onExpandClicked() {
    this.dispatchEvent({ type: this.expandState === 0 ? "collapseAll" : "expandAll" });
  }
  setExpandState(state) {
    this.expandState = state;
    _39.setDisplayed(
      this.eExpandChecked,
      this.expandState === 0
      /* EXPANDED */
    );
    _39.setDisplayed(
      this.eExpandUnchecked,
      this.expandState === 1
      /* COLLAPSED */
    );
    _39.setDisplayed(
      this.eExpandIndeterminate,
      this.expandState === 2
      /* INDETERMINATE */
    );
  }
  setSelectionState(state) {
    this.selectState = state;
    this.eSelect.setValue(this.selectState);
  }
};
_PrimaryColsHeaderPanel.DEBOUNCE_DELAY = 300;
_PrimaryColsHeaderPanel.TEMPLATE = /* html */
`<div class="ag-column-select-header" role="presentation">
            <div ref="eExpand" class="ag-column-select-header-icon"></div>
            <ag-checkbox ref="eSelect" class="ag-column-select-header-checkbox"></ag-checkbox>
            <ag-input-text-field class="ag-column-select-header-filter-wrapper" ref="eFilterTextField"></ag-input-text-field>
        </div>`;
__decorateClass8([
  Autowired55("columnModel")
], _PrimaryColsHeaderPanel.prototype, "columnModel", 2);
__decorateClass8([
  RefSelector17("eExpand")
], _PrimaryColsHeaderPanel.prototype, "eExpand", 2);
__decorateClass8([
  RefSelector17("eSelect")
], _PrimaryColsHeaderPanel.prototype, "eSelect", 2);
__decorateClass8([
  RefSelector17("eFilterTextField")
], _PrimaryColsHeaderPanel.prototype, "eFilterTextField", 2);
__decorateClass8([
  PostConstruct56
], _PrimaryColsHeaderPanel.prototype, "postConstruct", 1);
var PrimaryColsHeaderPanel = _PrimaryColsHeaderPanel;
var _ColumnModelItem = class _ColumnModelItem2 {
  constructor(displayName, columnOrGroup, dept, group = false, expanded) {
    this.eventService = new EventService();
    this.displayName = displayName;
    this.dept = dept;
    this.group = group;
    if (group) {
      this.columnGroup = columnOrGroup;
      this.expanded = expanded;
      this.children = [];
    } else {
      this.column = columnOrGroup;
    }
  }
  isGroup() {
    return this.group;
  }
  getDisplayName() {
    return this.displayName;
  }
  getColumnGroup() {
    return this.columnGroup;
  }
  getColumn() {
    return this.column;
  }
  getDept() {
    return this.dept;
  }
  isExpanded() {
    return !!this.expanded;
  }
  getChildren() {
    return this.children;
  }
  isPassesFilter() {
    return this.passesFilter;
  }
  setExpanded(expanded) {
    if (expanded === this.expanded) {
      return;
    }
    this.expanded = expanded;
    this.eventService.dispatchEvent({ type: _ColumnModelItem2.EVENT_EXPANDED_CHANGED });
  }
  setPassesFilter(passesFilter) {
    this.passesFilter = passesFilter;
  }
  addEventListener(eventType, listener) {
    this.eventService.addEventListener(eventType, listener);
  }
  removeEventListener(eventType, listener) {
    this.eventService.removeEventListener(eventType, listener);
  }
};
_ColumnModelItem.EVENT_EXPANDED_CHANGED = "expandedChanged";
var ColumnModelItem = _ColumnModelItem;
var ToolPanelContextMenu = class extends Component211 {
  constructor(column, mouseEvent, parentEl) {
    super(
      /* html */
      `<div class="ag-menu"></div>`
    );
    this.column = column;
    this.mouseEvent = mouseEvent;
    this.parentEl = parentEl;
    this.displayName = null;
  }
  postConstruct() {
    this.initializeProperties(this.column);
    this.buildMenuItemMap();
    if (this.column instanceof Column2) {
      this.displayName = this.columnModel.getDisplayNameForColumn(this.column, "columnToolPanel");
    } else {
      this.displayName = this.columnModel.getDisplayNameForProvidedColumnGroup(null, this.column, "columnToolPanel");
    }
    if (this.isActive()) {
      this.mouseEvent.preventDefault();
      const menuItemsMapped = this.getMappedMenuItems();
      if (menuItemsMapped.length === 0) {
        return;
      }
      this.displayContextMenu(menuItemsMapped);
    }
  }
  initializeProperties(column) {
    if (column instanceof ProvidedColumnGroup2) {
      this.columns = column.getLeafColumns();
    } else {
      this.columns = [column];
    }
    this.allowGrouping = this.columns.some((col) => col.isPrimary() && col.isAllowRowGroup());
    this.allowValues = this.columns.some((col) => col.isPrimary() && col.isAllowValue());
    this.allowPivoting = this.columnModel.isPivotMode() && this.columns.some((col) => col.isPrimary() && col.isAllowPivot());
  }
  buildMenuItemMap() {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    this.menuItemMap = /* @__PURE__ */ new Map();
    this.menuItemMap.set("rowGroup", {
      allowedFunction: (col) => col.isPrimary() && col.isAllowRowGroup() && !this.columnModel.isColumnGroupingLocked(col),
      activeFunction: (col) => col.isRowGroupActive(),
      activateLabel: () => `${localeTextFunc("groupBy", "Group by")} ${this.displayName}`,
      deactivateLabel: () => `${localeTextFunc("ungroupBy", "Un-Group by")} ${this.displayName}`,
      activateFunction: () => {
        const groupedColumns = this.columnModel.getRowGroupColumns();
        this.columnModel.setRowGroupColumns(this.addColumnsToList(groupedColumns), "toolPanelUi");
      },
      deActivateFunction: () => {
        const groupedColumns = this.columnModel.getRowGroupColumns();
        this.columnModel.setRowGroupColumns(this.removeColumnsFromList(groupedColumns), "toolPanelUi");
      },
      addIcon: "menuAddRowGroup",
      removeIcon: "menuRemoveRowGroup"
    });
    this.menuItemMap.set("value", {
      allowedFunction: (col) => col.isPrimary() && col.isAllowValue(),
      activeFunction: (col) => col.isValueActive(),
      activateLabel: () => localeTextFunc("addToValues", `Add ${this.displayName} to values`, [this.displayName]),
      deactivateLabel: () => localeTextFunc("removeFromValues", `Remove ${this.displayName} from values`, [this.displayName]),
      activateFunction: () => {
        const valueColumns = this.columnModel.getValueColumns();
        this.columnModel.setValueColumns(this.addColumnsToList(valueColumns), "toolPanelUi");
      },
      deActivateFunction: () => {
        const valueColumns = this.columnModel.getValueColumns();
        this.columnModel.setValueColumns(this.removeColumnsFromList(valueColumns), "toolPanelUi");
      },
      addIcon: "valuePanel",
      removeIcon: "valuePanel"
    });
    this.menuItemMap.set("pivot", {
      allowedFunction: (col) => this.columnModel.isPivotMode() && col.isPrimary() && col.isAllowPivot(),
      activeFunction: (col) => col.isPivotActive(),
      activateLabel: () => localeTextFunc("addToLabels", `Add ${this.displayName} to labels`, [this.displayName]),
      deactivateLabel: () => localeTextFunc("removeFromLabels", `Remove ${this.displayName} from labels`, [this.displayName]),
      activateFunction: () => {
        const pivotColumns = this.columnModel.getPivotColumns();
        this.columnModel.setPivotColumns(this.addColumnsToList(pivotColumns), "toolPanelUi");
      },
      deActivateFunction: () => {
        const pivotColumns = this.columnModel.getPivotColumns();
        this.columnModel.setPivotColumns(this.removeColumnsFromList(pivotColumns), "toolPanelUi");
      },
      addIcon: "pivotPanel",
      removeIcon: "pivotPanel"
    });
  }
  addColumnsToList(columnList) {
    return [...columnList].concat(this.columns.filter((col) => columnList.indexOf(col) === -1));
  }
  removeColumnsFromList(columnList) {
    return columnList.filter((col) => this.columns.indexOf(col) === -1);
  }
  displayContextMenu(menuItemsMapped) {
    const eGui = this.getGui();
    const menuList = this.createBean(new AgMenuList2());
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    let hideFunc = () => {
    };
    eGui.appendChild(menuList.getGui());
    menuList.addMenuItems(menuItemsMapped);
    menuList.addManagedListener(menuList, AgMenuItemComponent2.EVENT_CLOSE_MENU, () => {
      this.parentEl.focus();
      hideFunc();
    });
    const addPopupRes = this.popupService.addPopup({
      modal: true,
      eChild: eGui,
      closeOnEsc: true,
      afterGuiAttached: () => this.focusService.focusInto(menuList.getGui()),
      ariaLabel: localeTextFunc("ariaLabelContextMenu", "Context Menu"),
      closedCallback: (e) => {
        if (e instanceof KeyboardEvent) {
          this.parentEl.focus();
        }
        this.destroyBean(menuList);
      }
    });
    if (addPopupRes) {
      hideFunc = addPopupRes.hideFunc;
    }
    this.popupService.positionPopupUnderMouseEvent({
      type: "columnContextMenu",
      mouseEvent: this.mouseEvent,
      ePopup: eGui
    });
  }
  isActive() {
    return this.allowGrouping || this.allowValues || this.allowPivoting;
  }
  getMappedMenuItems() {
    const ret = [];
    for (const val of this.menuItemMap.values()) {
      const isInactive = this.columns.some((col) => val.allowedFunction(col) && !val.activeFunction(col));
      const isActive = this.columns.some((col) => val.allowedFunction(col) && val.activeFunction(col));
      if (isInactive) {
        ret.push({
          name: val.activateLabel(this.displayName),
          icon: _211.createIconNoSpan(val.addIcon, this.gos, null),
          action: () => val.activateFunction()
        });
      }
      if (isActive) {
        ret.push({
          name: val.deactivateLabel(this.displayName),
          icon: _211.createIconNoSpan(val.removeIcon, this.gos, null),
          action: () => val.deActivateFunction()
        });
      }
    }
    return ret;
  }
};
__decorateClass8([
  Autowired212("columnModel")
], ToolPanelContextMenu.prototype, "columnModel", 2);
__decorateClass8([
  Autowired212("popupService")
], ToolPanelContextMenu.prototype, "popupService", 2);
__decorateClass8([
  Autowired212("focusService")
], ToolPanelContextMenu.prototype, "focusService", 2);
__decorateClass8([
  PostConstruct212
], ToolPanelContextMenu.prototype, "postConstruct", 1);
var _ToolPanelColumnGroupComp = class _ToolPanelColumnGroupComp2 extends Component312 {
  constructor(modelItem, allowDragging, eventType, focusWrapper) {
    super();
    this.modelItem = modelItem;
    this.allowDragging = allowDragging;
    this.eventType = eventType;
    this.focusWrapper = focusWrapper;
    this.processingColumnStateChange = false;
    this.modelItem = modelItem;
    this.columnGroup = modelItem.getColumnGroup();
    this.columnDept = modelItem.getDept();
    this.displayName = modelItem.getDisplayName();
    this.allowDragging = allowDragging;
  }
  init() {
    this.setTemplate(_ToolPanelColumnGroupComp2.TEMPLATE);
    this.eDragHandle = _310.createIconNoSpan("columnDrag", this.gos);
    this.eDragHandle.classList.add("ag-drag-handle", "ag-column-select-column-group-drag-handle");
    const checkboxGui = this.cbSelect.getGui();
    const checkboxInput = this.cbSelect.getInputElement();
    checkboxGui.insertAdjacentElement("afterend", this.eDragHandle);
    checkboxInput.setAttribute("tabindex", "-1");
    this.eLabel.innerHTML = this.displayName ? this.displayName : "";
    this.setupExpandContract();
    this.addCssClass("ag-column-select-indent-" + this.columnDept);
    this.addManagedListener(this.eventService, Events26.EVENT_COLUMN_PIVOT_MODE_CHANGED, this.onColumnStateChanged.bind(this));
    this.addManagedListener(this.eLabel, "click", this.onLabelClicked.bind(this));
    this.addManagedListener(this.cbSelect, Events26.EVENT_FIELD_VALUE_CHANGED, this.onCheckboxChanged.bind(this));
    this.addManagedListener(this.modelItem, ColumnModelItem.EVENT_EXPANDED_CHANGED, this.onExpandChanged.bind(this));
    this.addManagedListener(this.focusWrapper, "keydown", this.handleKeyDown.bind(this));
    this.addManagedListener(this.focusWrapper, "contextmenu", this.onContextMenu.bind(this));
    this.setOpenClosedIcons();
    this.setupDragging();
    this.onColumnStateChanged();
    this.addVisibilityListenersToAllChildren();
    this.refreshAriaExpanded();
    this.refreshAriaLabel();
    this.setupTooltip();
    const classes = CssClassApplier.getToolPanelClassesFromColDef(this.columnGroup.getColGroupDef(), this.gos, null, this.columnGroup);
    classes.forEach((c) => this.addOrRemoveCssClass(c, true));
  }
  getColumns() {
    return this.columnGroup.getLeafColumns();
  }
  setupTooltip() {
    const colGroupDef = this.columnGroup.getColGroupDef();
    if (!colGroupDef) {
      return;
    }
    const isTooltipWhenTruncated = this.gos.get("tooltipShowMode") === "whenTruncated";
    let shouldDisplayTooltip;
    if (isTooltipWhenTruncated) {
      shouldDisplayTooltip = () => this.eLabel.scrollWidth > this.eLabel.clientWidth;
    }
    const refresh = () => {
      const newTooltipText = colGroupDef.headerTooltip;
      this.setTooltip({ newTooltipText, location: "columnToolPanelColumnGroup", shouldDisplayTooltip });
    };
    refresh();
    this.addManagedListener(this.eventService, Events26.EVENT_NEW_COLUMNS_LOADED, refresh);
  }
  getTooltipParams() {
    const res = super.getTooltipParams();
    res.location = "columnToolPanelColumnGroup";
    return res;
  }
  handleKeyDown(e) {
    switch (e.key) {
      case KeyCode23.LEFT:
        e.preventDefault();
        this.modelItem.setExpanded(false);
        break;
      case KeyCode23.RIGHT:
        e.preventDefault();
        this.modelItem.setExpanded(true);
        break;
      case KeyCode23.SPACE:
        e.preventDefault();
        if (this.isSelectable()) {
          this.onSelectAllChanged(!this.isSelected());
        }
        break;
    }
  }
  onContextMenu(e) {
    const { columnGroup, gos } = this;
    if (gos.get("functionsReadOnly")) {
      return;
    }
    const contextMenu = this.createBean(new ToolPanelContextMenu(columnGroup, e, this.focusWrapper));
    this.addDestroyFunc(() => {
      if (contextMenu.isAlive()) {
        this.destroyBean(contextMenu);
      }
    });
  }
  addVisibilityListenersToAllChildren() {
    this.columnGroup.getLeafColumns().forEach((column) => {
      this.addManagedListener(column, Column3.EVENT_VISIBLE_CHANGED, this.onColumnStateChanged.bind(this));
      this.addManagedListener(column, Column3.EVENT_VALUE_CHANGED, this.onColumnStateChanged.bind(this));
      this.addManagedListener(column, Column3.EVENT_PIVOT_CHANGED, this.onColumnStateChanged.bind(this));
      this.addManagedListener(column, Column3.EVENT_ROW_GROUP_CHANGED, this.onColumnStateChanged.bind(this));
    });
  }
  setupDragging() {
    if (!this.allowDragging) {
      _310.setDisplayed(this.eDragHandle, false);
      return;
    }
    let hideColumnOnExit = !this.gos.get("suppressDragLeaveHidesColumns");
    const dragSource = {
      type: DragSourceType5.ToolPanel,
      eElement: this.eDragHandle,
      dragItemName: this.displayName,
      getDefaultIconName: () => hideColumnOnExit ? DragAndDropService6.ICON_HIDE : DragAndDropService6.ICON_NOT_ALLOWED,
      getDragItem: () => this.createDragItem(),
      onDragStarted: () => {
        hideColumnOnExit = !this.gos.get("suppressDragLeaveHidesColumns");
        const event = {
          type: Events26.EVENT_COLUMN_PANEL_ITEM_DRAG_START,
          column: this.columnGroup
        };
        this.eventService.dispatchEvent(event);
      },
      onDragStopped: () => {
        const event = {
          type: Events26.EVENT_COLUMN_PANEL_ITEM_DRAG_END
        };
        this.eventService.dispatchEvent(event);
      },
      onGridEnter: (dragItem) => {
        if (hideColumnOnExit) {
          this.modelItemUtils.updateColumns({
            columns: this.columnGroup.getLeafColumns(),
            visibleState: dragItem == null ? void 0 : dragItem.visibleState,
            pivotState: dragItem == null ? void 0 : dragItem.pivotState,
            eventType: this.eventType
          });
        }
      },
      onGridExit: () => {
        if (hideColumnOnExit) {
          this.onChangeCommon(false);
        }
      }
    };
    this.dragAndDropService.addDragSource(dragSource, true);
    this.addDestroyFunc(() => this.dragAndDropService.removeDragSource(dragSource));
  }
  createDragItem() {
    const columns = this.columnGroup.getLeafColumns();
    const visibleState = {};
    const pivotState = {};
    columns.forEach((col) => {
      const colId = col.getId();
      visibleState[colId] = col.isVisible();
      pivotState[colId] = this.modelItemUtils.createPivotState(col);
    });
    return {
      columns,
      visibleState,
      pivotState
    };
  }
  setupExpandContract() {
    this.eGroupClosedIcon.appendChild(_310.createIcon("columnSelectClosed", this.gos, null));
    this.eGroupOpenedIcon.appendChild(_310.createIcon("columnSelectOpen", this.gos, null));
    this.addManagedListener(this.eGroupClosedIcon, "click", this.onExpandOrContractClicked.bind(this));
    this.addManagedListener(this.eGroupOpenedIcon, "click", this.onExpandOrContractClicked.bind(this));
    const touchListener = new TouchListener(this.eColumnGroupIcons, true);
    this.addManagedListener(touchListener, TouchListener.EVENT_TAP, this.onExpandOrContractClicked.bind(this));
    this.addDestroyFunc(touchListener.destroy.bind(touchListener));
  }
  onLabelClicked() {
    const nextState = !this.cbSelect.getValue();
    this.onChangeCommon(nextState);
  }
  onCheckboxChanged(event) {
    this.onChangeCommon(event.selected);
  }
  getVisibleLeafColumns() {
    const childColumns = [];
    const extractCols = (children) => {
      children.forEach((child) => {
        if (!child.isPassesFilter()) {
          return;
        }
        if (child.isGroup()) {
          extractCols(child.getChildren());
        } else {
          childColumns.push(child.getColumn());
        }
      });
    };
    extractCols(this.modelItem.getChildren());
    return childColumns;
  }
  onChangeCommon(nextState) {
    this.refreshAriaLabel();
    if (this.processingColumnStateChange) {
      return;
    }
    this.modelItemUtils.selectAllChildren(this.modelItem.getChildren(), nextState, this.eventType);
  }
  refreshAriaLabel() {
    const translate = this.localeService.getLocaleTextFunc();
    const columnLabel = translate("ariaColumnGroup", "Column Group");
    const checkboxValue = this.cbSelect.getValue();
    const state = checkboxValue === void 0 ? translate("ariaIndeterminate", "indeterminate") : checkboxValue ? translate("ariaVisible", "visible") : translate("ariaHidden", "hidden");
    const visibilityLabel = translate("ariaToggleVisibility", "Press SPACE to toggle visibility");
    _310.setAriaLabel(this.focusWrapper, `${this.displayName} ${columnLabel}`);
    this.cbSelect.setInputAriaLabel(`${visibilityLabel} (${state})`);
    _310.setAriaDescribedBy(this.focusWrapper, this.cbSelect.getInputElement().id);
  }
  onColumnStateChanged() {
    const selectedValue = this.workOutSelectedValue();
    const readOnlyValue = this.workOutReadOnlyValue();
    this.processingColumnStateChange = true;
    this.cbSelect.setValue(selectedValue);
    this.cbSelect.setReadOnly(readOnlyValue);
    this.addOrRemoveCssClass("ag-column-select-column-group-readonly", readOnlyValue);
    this.processingColumnStateChange = false;
  }
  workOutSelectedValue() {
    const pivotMode = this.columnModel.isPivotMode();
    const visibleLeafColumns = this.getVisibleLeafColumns();
    let checkedCount = 0;
    let uncheckedCount = 0;
    visibleLeafColumns.forEach((column) => {
      if (!pivotMode && column.getColDef().lockVisible) {
        return;
      }
      if (this.isColumnChecked(column, pivotMode)) {
        checkedCount++;
      } else {
        uncheckedCount++;
      }
    });
    if (checkedCount > 0 && uncheckedCount > 0) {
      return void 0;
    }
    return checkedCount > 0;
  }
  workOutReadOnlyValue() {
    const pivotMode = this.columnModel.isPivotMode();
    let colsThatCanAction = 0;
    this.columnGroup.getLeafColumns().forEach((col) => {
      if (pivotMode) {
        if (col.isAnyFunctionAllowed()) {
          colsThatCanAction++;
        }
      } else {
        if (!col.getColDef().lockVisible) {
          colsThatCanAction++;
        }
      }
    });
    return colsThatCanAction === 0;
  }
  isColumnChecked(column, pivotMode) {
    if (pivotMode) {
      const pivoted = column.isPivotActive();
      const grouped = column.isRowGroupActive();
      const aggregated = column.isValueActive();
      return pivoted || grouped || aggregated;
    }
    return column.isVisible();
  }
  onExpandOrContractClicked() {
    const oldState = this.modelItem.isExpanded();
    this.modelItem.setExpanded(!oldState);
  }
  onExpandChanged() {
    this.setOpenClosedIcons();
    this.refreshAriaExpanded();
  }
  setOpenClosedIcons() {
    const folderOpen = this.modelItem.isExpanded();
    _310.setDisplayed(this.eGroupClosedIcon, !folderOpen);
    _310.setDisplayed(this.eGroupOpenedIcon, folderOpen);
  }
  refreshAriaExpanded() {
    _310.setAriaExpanded(this.focusWrapper, this.modelItem.isExpanded());
  }
  getDisplayName() {
    return this.displayName;
  }
  onSelectAllChanged(value) {
    const cbValue = this.cbSelect.getValue();
    const readOnly = this.cbSelect.isReadOnly();
    if (!readOnly && (value && !cbValue || !value && cbValue)) {
      this.cbSelect.toggle();
    }
  }
  isSelected() {
    return this.cbSelect.getValue();
  }
  isSelectable() {
    return !this.cbSelect.isReadOnly();
  }
  setSelected(selected) {
    this.cbSelect.setValue(selected, true);
  }
};
_ToolPanelColumnGroupComp.TEMPLATE = /* html */
`<div class="ag-column-select-column-group" aria-hidden="true">
            <span class="ag-column-group-icons" ref="eColumnGroupIcons" >
                <span class="ag-column-group-closed-icon" ref="eGroupClosedIcon"></span>
                <span class="ag-column-group-opened-icon" ref="eGroupOpenedIcon"></span>
            </span>
            <ag-checkbox ref="cbSelect" class="ag-column-select-checkbox"></ag-checkbox>
            <span class="ag-column-select-column-label" ref="eLabel"></span>
        </div>`;
__decorateClass8([
  Autowired312("columnModel")
], _ToolPanelColumnGroupComp.prototype, "columnModel", 2);
__decorateClass8([
  Autowired312("dragAndDropService")
], _ToolPanelColumnGroupComp.prototype, "dragAndDropService", 2);
__decorateClass8([
  Autowired312("modelItemUtils")
], _ToolPanelColumnGroupComp.prototype, "modelItemUtils", 2);
__decorateClass8([
  RefSelector25("cbSelect")
], _ToolPanelColumnGroupComp.prototype, "cbSelect", 2);
__decorateClass8([
  RefSelector25("eLabel")
], _ToolPanelColumnGroupComp.prototype, "eLabel", 2);
__decorateClass8([
  RefSelector25("eGroupOpenedIcon")
], _ToolPanelColumnGroupComp.prototype, "eGroupOpenedIcon", 2);
__decorateClass8([
  RefSelector25("eGroupClosedIcon")
], _ToolPanelColumnGroupComp.prototype, "eGroupClosedIcon", 2);
__decorateClass8([
  RefSelector25("eColumnGroupIcons")
], _ToolPanelColumnGroupComp.prototype, "eColumnGroupIcons", 2);
__decorateClass8([
  PostConstruct312
], _ToolPanelColumnGroupComp.prototype, "init", 1);
var ToolPanelColumnGroupComp = _ToolPanelColumnGroupComp;
var PrimaryColsListPanelItemDragFeature = class extends BeanStub18 {
  constructor(comp, virtualList) {
    super();
    this.comp = comp;
    this.virtualList = virtualList;
  }
  postConstruct() {
    this.createManagedBean(new VirtualListDragFeature2(
      this.comp,
      this.virtualList,
      {
        dragSourceType: DragSourceType24.ToolPanel,
        listItemDragStartEvent: Events34.EVENT_COLUMN_PANEL_ITEM_DRAG_START,
        listItemDragEndEvent: Events34.EVENT_COLUMN_PANEL_ITEM_DRAG_END,
        eventSource: this.eventService,
        getCurrentDragValue: (listItemDragStartEvent) => this.getCurrentDragValue(listItemDragStartEvent),
        isMoveBlocked: (currentDragValue) => this.isMoveBlocked(currentDragValue),
        getNumRows: (comp) => comp.getDisplayedColsList().length,
        moveItem: (currentDragValue, lastHoveredListItem) => this.moveItem(currentDragValue, lastHoveredListItem)
      }
    ));
  }
  getCurrentDragValue(listItemDragStartEvent) {
    return listItemDragStartEvent.column;
  }
  isMoveBlocked(currentDragValue) {
    const preventMoving = this.gos.get("suppressMovableColumns");
    if (preventMoving) {
      return true;
    }
    const currentColumns = this.getCurrentColumns(currentDragValue);
    const hasNotMovable = currentColumns.find((col) => {
      const colDef = col.getColDef();
      return !!colDef.suppressMovable || !!colDef.lockPosition;
    });
    return !!hasNotMovable;
  }
  moveItem(currentDragValue, lastHoveredListItem) {
    const targetIndex = this.getTargetIndex(currentDragValue, lastHoveredListItem);
    const columnsToMove = this.getCurrentColumns(currentDragValue);
    if (targetIndex != null) {
      this.columnModel.moveColumns(columnsToMove, targetIndex, "toolPanelUi");
    }
  }
  getMoveDiff(currentDragValue, end) {
    const allColumns = this.columnModel.getAllGridColumns();
    const currentColumns = this.getCurrentColumns(currentDragValue);
    const currentColumn = currentColumns[0];
    const span = currentColumns.length;
    const currentIndex = allColumns.indexOf(currentColumn);
    if (currentIndex < end) {
      return span;
    }
    return 0;
  }
  getCurrentColumns(currentDragValue) {
    if (currentDragValue instanceof ProvidedColumnGroup4) {
      return currentDragValue.getLeafColumns();
    }
    return [currentDragValue];
  }
  getTargetIndex(currentDragValue, lastHoveredListItem) {
    if (!lastHoveredListItem) {
      return null;
    }
    const columnItemComponent = lastHoveredListItem.component;
    let isBefore = lastHoveredListItem.position === "top";
    let targetColumn;
    if (columnItemComponent instanceof ToolPanelColumnGroupComp) {
      const columns = columnItemComponent.getColumns();
      targetColumn = columns[0];
      isBefore = true;
    } else {
      targetColumn = columnItemComponent.getColumn();
    }
    const movingCols = this.getCurrentColumns(currentDragValue);
    if (movingCols.indexOf(targetColumn) !== -1) {
      return null;
    }
    const targetColumnIndex = this.columnModel.getAllGridColumns().indexOf(targetColumn);
    const adjustedTarget = isBefore ? targetColumnIndex : targetColumnIndex + 1;
    const diff = this.getMoveDiff(currentDragValue, adjustedTarget);
    return adjustedTarget - diff;
  }
};
__decorateClass8([
  Autowired412("columnModel")
], PrimaryColsListPanelItemDragFeature.prototype, "columnModel", 2);
__decorateClass8([
  PostConstruct412
], PrimaryColsListPanelItemDragFeature.prototype, "postConstruct", 1);
var _ToolPanelColumnComp = class _ToolPanelColumnComp2 extends Component47 {
  constructor(modelItem, allowDragging, groupsExist, focusWrapper) {
    super();
    this.allowDragging = allowDragging;
    this.groupsExist = groupsExist;
    this.focusWrapper = focusWrapper;
    this.processingColumnStateChange = false;
    this.column = modelItem.getColumn();
    this.columnDept = modelItem.getDept();
    this.displayName = modelItem.getDisplayName();
  }
  init() {
    this.setTemplate(_ToolPanelColumnComp2.TEMPLATE);
    this.eDragHandle = _54.createIconNoSpan("columnDrag", this.gos);
    this.eDragHandle.classList.add("ag-drag-handle", "ag-column-select-column-drag-handle");
    const checkboxGui = this.cbSelect.getGui();
    const checkboxInput = this.cbSelect.getInputElement();
    checkboxGui.insertAdjacentElement("afterend", this.eDragHandle);
    checkboxInput.setAttribute("tabindex", "-1");
    const displayNameSanitised = _54.escapeString(this.displayName);
    this.eLabel.innerHTML = displayNameSanitised;
    const indent = this.columnDept;
    if (this.groupsExist) {
      this.addCssClass("ag-column-select-add-group-indent");
    }
    this.addCssClass(`ag-column-select-indent-${indent}`);
    this.setupDragging();
    this.addManagedListener(this.eventService, Events44.EVENT_COLUMN_PIVOT_MODE_CHANGED, this.onColumnStateChanged.bind(this));
    this.addManagedListener(this.column, Column5.EVENT_VALUE_CHANGED, this.onColumnStateChanged.bind(this));
    this.addManagedListener(this.column, Column5.EVENT_PIVOT_CHANGED, this.onColumnStateChanged.bind(this));
    this.addManagedListener(this.column, Column5.EVENT_ROW_GROUP_CHANGED, this.onColumnStateChanged.bind(this));
    this.addManagedListener(this.column, Column5.EVENT_VISIBLE_CHANGED, this.onColumnStateChanged.bind(this));
    this.addManagedListener(this.focusWrapper, "keydown", this.handleKeyDown.bind(this));
    this.addManagedListener(this.focusWrapper, "contextmenu", this.onContextMenu.bind(this));
    this.addManagedPropertyListener("functionsReadOnly", this.onColumnStateChanged.bind(this));
    this.addManagedListener(this.cbSelect, Events44.EVENT_FIELD_VALUE_CHANGED, this.onCheckboxChanged.bind(this));
    this.addManagedListener(this.eLabel, "click", this.onLabelClicked.bind(this));
    this.onColumnStateChanged();
    this.refreshAriaLabel();
    this.setupTooltip();
    const classes = CssClassApplier2.getToolPanelClassesFromColDef(this.column.getColDef(), this.gos, this.column, null);
    classes.forEach((c) => this.addOrRemoveCssClass(c, true));
  }
  getColumn() {
    return this.column;
  }
  setupTooltip() {
    const isTooltipWhenTruncated = this.gos.get("tooltipShowMode") === "whenTruncated";
    let shouldDisplayTooltip;
    if (isTooltipWhenTruncated) {
      shouldDisplayTooltip = () => this.eLabel.scrollWidth > this.eLabel.clientWidth;
    }
    const refresh = () => {
      const newTooltipText = this.column.getColDef().headerTooltip;
      this.setTooltip({ newTooltipText, location: "columnToolPanelColumn", shouldDisplayTooltip });
    };
    refresh();
    this.addManagedListener(this.eventService, Events44.EVENT_NEW_COLUMNS_LOADED, refresh);
  }
  getTooltipParams() {
    const res = super.getTooltipParams();
    res.location = "columnToolPanelColumn";
    res.colDef = this.column.getColDef();
    return res;
  }
  onContextMenu(e) {
    const { column, gos } = this;
    if (gos.get("functionsReadOnly")) {
      return;
    }
    const contextMenu = this.createBean(new ToolPanelContextMenu(column, e, this.focusWrapper));
    this.addDestroyFunc(() => {
      if (contextMenu.isAlive()) {
        this.destroyBean(contextMenu);
      }
    });
  }
  handleKeyDown(e) {
    if (e.key === KeyCode32.SPACE) {
      e.preventDefault();
      if (this.isSelectable()) {
        this.onSelectAllChanged(!this.isSelected());
      }
    }
  }
  onLabelClicked() {
    if (this.gos.get("functionsReadOnly")) {
      return;
    }
    const nextState = !this.cbSelect.getValue();
    this.onChangeCommon(nextState);
  }
  onCheckboxChanged(event) {
    this.onChangeCommon(event.selected);
  }
  onChangeCommon(nextState) {
    if (this.cbSelect.isReadOnly()) {
      return;
    }
    this.refreshAriaLabel();
    if (this.processingColumnStateChange) {
      return;
    }
    this.modelItemUtils.setColumn(this.column, nextState, "toolPanelUi");
  }
  refreshAriaLabel() {
    const translate = this.localeService.getLocaleTextFunc();
    const columnLabel = translate("ariaColumn", "Column");
    const state = this.cbSelect.getValue() ? translate("ariaVisible", "visible") : translate("ariaHidden", "hidden");
    const visibilityLabel = translate("ariaToggleVisibility", "Press SPACE to toggle visibility");
    _54.setAriaLabel(this.focusWrapper, `${this.displayName} ${columnLabel}`);
    this.cbSelect.setInputAriaLabel(`${visibilityLabel} (${state})`);
    _54.setAriaDescribedBy(this.focusWrapper, this.cbSelect.getInputElement().id);
  }
  setupDragging() {
    if (!this.allowDragging) {
      _54.setDisplayed(this.eDragHandle, false);
      return;
    }
    let hideColumnOnExit = !this.gos.get("suppressDragLeaveHidesColumns");
    const dragSource = {
      type: DragSourceType32.ToolPanel,
      eElement: this.eDragHandle,
      dragItemName: this.displayName,
      getDefaultIconName: () => hideColumnOnExit ? DragAndDropService23.ICON_HIDE : DragAndDropService23.ICON_NOT_ALLOWED,
      getDragItem: () => this.createDragItem(),
      onDragStarted: () => {
        hideColumnOnExit = !this.gos.get("suppressDragLeaveHidesColumns");
        const event = {
          type: Events44.EVENT_COLUMN_PANEL_ITEM_DRAG_START,
          column: this.column
        };
        this.eventService.dispatchEvent(event);
      },
      onDragStopped: () => {
        const event = {
          type: Events44.EVENT_COLUMN_PANEL_ITEM_DRAG_END
        };
        this.eventService.dispatchEvent(event);
      },
      onGridEnter: (dragItem) => {
        if (hideColumnOnExit) {
          this.modelItemUtils.updateColumns({
            columns: [this.column],
            visibleState: dragItem == null ? void 0 : dragItem.visibleState,
            pivotState: dragItem == null ? void 0 : dragItem.pivotState,
            eventType: "toolPanelUi"
          });
        }
      },
      onGridExit: () => {
        if (hideColumnOnExit) {
          this.onChangeCommon(false);
        }
      }
    };
    this.dragAndDropService.addDragSource(dragSource, true);
    this.addDestroyFunc(() => this.dragAndDropService.removeDragSource(dragSource));
  }
  createDragItem() {
    const colId = this.column.getColId();
    const visibleState = { [colId]: this.column.isVisible() };
    const pivotState = { [colId]: this.modelItemUtils.createPivotState(this.column) };
    return {
      columns: [this.column],
      visibleState,
      pivotState
    };
  }
  onColumnStateChanged() {
    this.processingColumnStateChange = true;
    const isPivotMode = this.columnModel.isPivotMode();
    if (isPivotMode) {
      const anyFunctionActive = this.column.isAnyFunctionActive();
      this.cbSelect.setValue(anyFunctionActive);
    } else {
      this.cbSelect.setValue(this.column.isVisible());
    }
    let canBeToggled = true;
    let canBeDragged = true;
    if (isPivotMode) {
      const functionsReadOnly = this.gos.get("functionsReadOnly");
      const noFunctionsAllowed = !this.column.isAnyFunctionAllowed();
      canBeToggled = !functionsReadOnly && !noFunctionsAllowed;
      canBeDragged = canBeToggled;
    } else {
      const { enableRowGroup, enableValue, lockPosition, suppressMovable, lockVisible } = this.column.getColDef();
      const forceDraggable = !!enableRowGroup || !!enableValue;
      const disableDraggable = !!lockPosition || !!suppressMovable;
      canBeToggled = !lockVisible;
      canBeDragged = forceDraggable || !disableDraggable;
    }
    this.cbSelect.setReadOnly(!canBeToggled);
    this.eDragHandle.classList.toggle("ag-column-select-column-readonly", !canBeDragged);
    this.addOrRemoveCssClass("ag-column-select-column-readonly", !canBeDragged && !canBeToggled);
    const checkboxPassive = isPivotMode && this.gos.get("functionsPassive");
    this.cbSelect.setPassive(checkboxPassive);
    this.processingColumnStateChange = false;
  }
  getDisplayName() {
    return this.displayName;
  }
  onSelectAllChanged(value) {
    if (value !== this.cbSelect.getValue()) {
      if (!this.cbSelect.isReadOnly()) {
        this.cbSelect.toggle();
      }
    }
  }
  isSelected() {
    return this.cbSelect.getValue();
  }
  isSelectable() {
    return !this.cbSelect.isReadOnly();
  }
  isExpandable() {
    return false;
  }
  setExpanded(value) {
    console.warn("AG Grid: can not expand a column item that does not represent a column group header");
  }
};
_ToolPanelColumnComp.TEMPLATE = /* html */
`<div class="ag-column-select-column" aria-hidden="true">
            <ag-checkbox ref="cbSelect" class="ag-column-select-checkbox"></ag-checkbox>
            <span class="ag-column-select-column-label" ref="eLabel"></span>
        </div>`;
__decorateClass8([
  Autowired56("columnModel")
], _ToolPanelColumnComp.prototype, "columnModel", 2);
__decorateClass8([
  Autowired56("dragAndDropService")
], _ToolPanelColumnComp.prototype, "dragAndDropService", 2);
__decorateClass8([
  Autowired56("modelItemUtils")
], _ToolPanelColumnComp.prototype, "modelItemUtils", 2);
__decorateClass8([
  RefSelector35("eLabel")
], _ToolPanelColumnComp.prototype, "eLabel", 2);
__decorateClass8([
  RefSelector35("cbSelect")
], _ToolPanelColumnComp.prototype, "cbSelect", 2);
__decorateClass8([
  PostConstruct57
], _ToolPanelColumnComp.prototype, "init", 1);
var ToolPanelColumnComp = _ToolPanelColumnComp;
var UIColumnModel = class {
  constructor(items) {
    this.items = items;
  }
  getRowCount() {
    return this.items.length;
  }
  getRow(index) {
    return this.items[index];
  }
};
var PRIMARY_COLS_LIST_PANEL_CLASS = "ag-column-select-list";
var _PrimaryColsListPanel = class _PrimaryColsListPanel2 extends Component54 {
  constructor() {
    super(_PrimaryColsListPanel2.TEMPLATE);
    this.destroyColumnItemFuncs = [];
    this.hasLoadedInitialState = false;
    this.isInitialState = false;
  }
  destroyColumnTree() {
    this.allColsTree = [];
    this.destroyColumnItemFuncs.forEach((f) => f());
    this.destroyColumnItemFuncs = [];
  }
  init(params, allowDragging, eventType) {
    this.params = params;
    this.allowDragging = allowDragging;
    this.eventType = eventType;
    if (!this.params.suppressSyncLayoutWithGrid) {
      this.addManagedListener(this.eventService, Events54.EVENT_COLUMN_MOVED, this.onColumnsChanged.bind(this));
    }
    this.addManagedListener(this.eventService, Events54.EVENT_NEW_COLUMNS_LOADED, this.onColumnsChanged.bind(this));
    const eventsImpactingCheckedState = [
      Events54.EVENT_COLUMN_PIVOT_CHANGED,
      Events54.EVENT_COLUMN_PIVOT_MODE_CHANGED,
      Events54.EVENT_COLUMN_ROW_GROUP_CHANGED,
      Events54.EVENT_COLUMN_VALUE_CHANGED,
      Events54.EVENT_COLUMN_VISIBLE,
      Events54.EVENT_NEW_COLUMNS_LOADED
    ];
    eventsImpactingCheckedState.forEach((event) => {
      this.addManagedListener(this.eventService, event, this.fireSelectionChangedEvent.bind(this));
    });
    this.expandGroupsByDefault = !this.params.contractColumnSelection;
    this.virtualList = this.createManagedBean(new VirtualList2({
      cssIdentifier: "column-select",
      ariaRole: "tree"
    }));
    this.appendChild(this.virtualList.getGui());
    this.virtualList.setComponentCreator(
      (item, listItemElement) => {
        _64.setAriaLevel(listItemElement, item.getDept() + 1);
        return this.createComponentFromItem(item, listItemElement);
      }
    );
    if (this.columnModel.isReady()) {
      this.onColumnsChanged();
    }
    if (this.params.suppressColumnMove) {
      return;
    }
    this.createManagedBean(
      new PrimaryColsListPanelItemDragFeature(this, this.virtualList)
    );
  }
  createComponentFromItem(item, listItemElement) {
    if (item.isGroup()) {
      const renderedGroup = new ToolPanelColumnGroupComp(item, this.allowDragging, this.eventType, listItemElement);
      this.getContext().createBean(renderedGroup);
      return renderedGroup;
    }
    const columnComp = new ToolPanelColumnComp(item, this.allowDragging, this.groupsExist, listItemElement);
    this.getContext().createBean(columnComp);
    return columnComp;
  }
  onColumnsChanged() {
    if (!this.hasLoadedInitialState) {
      this.hasLoadedInitialState = true;
      this.isInitialState = !!this.params.initialState;
    }
    const expandedStates = this.getExpandedStates();
    const pivotModeActive = this.columnModel.isPivotMode();
    const shouldSyncColumnLayoutWithGrid = !this.params.suppressSyncLayoutWithGrid && !pivotModeActive;
    if (shouldSyncColumnLayoutWithGrid) {
      this.buildTreeFromWhatGridIsDisplaying();
    } else {
      this.buildTreeFromProvidedColumnDefs();
    }
    this.setExpandedStates(expandedStates);
    this.markFilteredColumns();
    this.flattenAndFilterModel();
    this.isInitialState = false;
  }
  getDisplayedColsList() {
    return this.displayedColsList;
  }
  getExpandedStates() {
    const res = {};
    if (this.isInitialState) {
      const { expandedGroupIds } = this.params.initialState;
      expandedGroupIds.forEach((id) => {
        res[id] = true;
      });
      return res;
    }
    if (!this.allColsTree) {
      return {};
    }
    this.forEachItem((item) => {
      if (!item.isGroup()) {
        return;
      }
      const colGroup = item.getColumnGroup();
      if (colGroup) {
        res[colGroup.getId()] = item.isExpanded();
      }
    });
    return res;
  }
  setExpandedStates(states) {
    if (!this.allColsTree) {
      return;
    }
    const { isInitialState } = this;
    this.forEachItem((item) => {
      if (!item.isGroup()) {
        return;
      }
      const colGroup = item.getColumnGroup();
      if (colGroup) {
        const expanded = states[colGroup.getId()];
        const groupExistedLastTime = expanded != null;
        if (groupExistedLastTime || isInitialState) {
          item.setExpanded(!!expanded);
        }
      }
    });
  }
  buildTreeFromWhatGridIsDisplaying() {
    this.colDefService.syncLayoutWithGrid(this.setColumnLayout.bind(this));
  }
  setColumnLayout(colDefs) {
    const columnTree = this.colDefService.createColumnTree(colDefs);
    this.buildListModel(columnTree);
    this.groupsExist = colDefs.some((colDef) => {
      return colDef && typeof colDef.children !== "undefined";
    });
    this.markFilteredColumns();
    this.flattenAndFilterModel();
  }
  buildTreeFromProvidedColumnDefs() {
    this.buildListModel(this.columnModel.getPrimaryColumnTree());
    this.groupsExist = this.columnModel.isPrimaryColumnGroupsPresent();
  }
  buildListModel(columnTree) {
    const columnExpandedListener = this.onColumnExpanded.bind(this);
    const addListeners = (item) => {
      item.addEventListener(ColumnModelItem.EVENT_EXPANDED_CHANGED, columnExpandedListener);
      const removeFunc = item.removeEventListener.bind(item, ColumnModelItem.EVENT_EXPANDED_CHANGED, columnExpandedListener);
      this.destroyColumnItemFuncs.push(removeFunc);
    };
    const recursivelyBuild = (tree, dept, parentList) => {
      tree.forEach((child) => {
        if (child instanceof ProvidedColumnGroup5) {
          createGroupItem(child, dept, parentList);
        } else {
          createColumnItem(child, dept, parentList);
        }
      });
    };
    const createGroupItem = (columnGroup, dept, parentList) => {
      const columnGroupDef = columnGroup.getColGroupDef();
      const skipThisGroup = columnGroupDef && columnGroupDef.suppressColumnsToolPanel;
      if (skipThisGroup) {
        return;
      }
      if (columnGroup.isPadding()) {
        recursivelyBuild(columnGroup.getChildren(), dept, parentList);
        return;
      }
      const displayName = this.columnModel.getDisplayNameForProvidedColumnGroup(null, columnGroup, "columnToolPanel");
      const item = new ColumnModelItem(displayName, columnGroup, dept, true, this.expandGroupsByDefault);
      parentList.push(item);
      addListeners(item);
      recursivelyBuild(columnGroup.getChildren(), dept + 1, item.getChildren());
    };
    const createColumnItem = (column, dept, parentList) => {
      const skipThisColumn = column.getColDef() && column.getColDef().suppressColumnsToolPanel;
      if (skipThisColumn) {
        return;
      }
      const displayName = this.columnModel.getDisplayNameForColumn(column, "columnToolPanel");
      parentList.push(new ColumnModelItem(displayName, column, dept));
    };
    this.destroyColumnTree();
    recursivelyBuild(columnTree, 0, this.allColsTree);
  }
  onColumnExpanded() {
    this.flattenAndFilterModel();
  }
  flattenAndFilterModel() {
    this.displayedColsList = [];
    const recursiveFunc = (item) => {
      if (!item.isPassesFilter()) {
        return;
      }
      this.displayedColsList.push(item);
      if (item.isGroup() && item.isExpanded()) {
        item.getChildren().forEach(recursiveFunc);
      }
    };
    this.allColsTree.forEach(recursiveFunc);
    this.virtualList.setModel(new UIColumnModel(this.displayedColsList));
    const focusedRow = this.virtualList.getLastFocusedRow();
    this.virtualList.refresh();
    if (focusedRow != null) {
      this.focusRowIfAlive(focusedRow);
    }
    this.notifyListeners();
    this.refreshAriaLabel();
  }
  refreshAriaLabel() {
    const translate = this.localeService.getLocaleTextFunc();
    const columnListName = translate("ariaColumnPanelList", "Column List");
    const localeColumns = translate("columns", "Columns");
    const items = this.displayedColsList.length;
    _64.setAriaLabel(this.virtualList.getAriaElement(), `${columnListName} ${items} ${localeColumns}`);
  }
  focusRowIfAlive(rowIndex) {
    window.setTimeout(() => {
      if (this.isAlive()) {
        this.virtualList.focusRow(rowIndex);
      }
    }, 0);
  }
  forEachItem(callback) {
    const recursiveFunc = (items) => {
      items.forEach((item) => {
        callback(item);
        if (item.isGroup()) {
          recursiveFunc(item.getChildren());
        }
      });
    };
    if (!this.allColsTree) {
      return;
    }
    recursiveFunc(this.allColsTree);
  }
  doSetExpandedAll(value) {
    this.forEachItem((item) => {
      if (item.isGroup()) {
        item.setExpanded(value);
      }
    });
  }
  setGroupsExpanded(expand, groupIds) {
    if (!groupIds) {
      this.doSetExpandedAll(expand);
      return;
    }
    const expandedGroupIds = [];
    this.forEachItem((item) => {
      if (!item.isGroup()) {
        return;
      }
      const groupId = item.getColumnGroup().getId();
      if (groupIds.indexOf(groupId) >= 0) {
        item.setExpanded(expand);
        expandedGroupIds.push(groupId);
      }
    });
    const unrecognisedGroupIds = groupIds.filter((groupId) => !_64.includes(expandedGroupIds, groupId));
    if (unrecognisedGroupIds.length > 0) {
      console.warn("AG Grid: unable to find group(s) for supplied groupIds:", unrecognisedGroupIds);
    }
  }
  getExpandState() {
    let expandedCount = 0;
    let notExpandedCount = 0;
    this.forEachItem((item) => {
      if (!item.isGroup()) {
        return;
      }
      if (item.isExpanded()) {
        expandedCount++;
      } else {
        notExpandedCount++;
      }
    });
    if (expandedCount > 0 && notExpandedCount > 0) {
      return 2;
    }
    if (notExpandedCount > 0) {
      return 1;
    }
    return 0;
  }
  doSetSelectedAll(selectAllChecked) {
    this.modelItemUtils.selectAllChildren(this.allColsTree, selectAllChecked, this.eventType);
  }
  getSelectionState() {
    let checkedCount = 0;
    let uncheckedCount = 0;
    const pivotMode = this.columnModel.isPivotMode();
    this.forEachItem((item) => {
      if (item.isGroup()) {
        return;
      }
      if (!item.isPassesFilter()) {
        return;
      }
      const column = item.getColumn();
      const colDef = column.getColDef();
      let checked;
      if (pivotMode) {
        const noPivotModeOptionsAllowed = !column.isAllowPivot() && !column.isAllowRowGroup() && !column.isAllowValue();
        if (noPivotModeOptionsAllowed) {
          return;
        }
        checked = column.isValueActive() || column.isPivotActive() || column.isRowGroupActive();
      } else {
        if (colDef.lockVisible) {
          return;
        }
        checked = column.isVisible();
      }
      checked ? checkedCount++ : uncheckedCount++;
    });
    if (checkedCount > 0 && uncheckedCount > 0) {
      return void 0;
    }
    return !(checkedCount === 0 || uncheckedCount > 0);
  }
  setFilterText(filterText) {
    this.filterText = _64.exists(filterText) ? filterText.toLowerCase() : null;
    this.markFilteredColumns();
    this.flattenAndFilterModel();
  }
  markFilteredColumns() {
    const passesFilter = (item) => {
      if (!_64.exists(this.filterText)) {
        return true;
      }
      const displayName = item.getDisplayName();
      return displayName == null || displayName.toLowerCase().indexOf(this.filterText) !== -1;
    };
    const recursivelyCheckFilter = (item, parentPasses) => {
      let atLeastOneChildPassed = false;
      if (item.isGroup()) {
        const groupPasses = passesFilter(item);
        item.getChildren().forEach((child) => {
          const childPasses = recursivelyCheckFilter(child, groupPasses || parentPasses);
          if (childPasses) {
            atLeastOneChildPassed = childPasses;
          }
        });
      }
      const filterPasses = parentPasses || atLeastOneChildPassed ? true : passesFilter(item);
      item.setPassesFilter(filterPasses);
      return filterPasses;
    };
    this.allColsTree.forEach((item) => recursivelyCheckFilter(item, false));
  }
  notifyListeners() {
    this.fireGroupExpandedEvent();
    this.fireSelectionChangedEvent();
  }
  fireGroupExpandedEvent() {
    const expandState = this.getExpandState();
    this.dispatchEvent({ type: "groupExpanded", state: expandState });
  }
  fireSelectionChangedEvent() {
    if (!this.allColsTree) {
      return;
    }
    const selectionState = this.getSelectionState();
    this.dispatchEvent({ type: "selectionChanged", state: selectionState });
  }
  getExpandedGroups() {
    const expandedGroupIds = [];
    if (!this.allColsTree) {
      return expandedGroupIds;
    }
    this.forEachItem((item) => {
      if (item.isGroup() && item.isExpanded()) {
        expandedGroupIds.push(item.getColumnGroup().getId());
      }
    });
    return expandedGroupIds;
  }
};
_PrimaryColsListPanel.TEMPLATE = /* html */
`<div class="${PRIMARY_COLS_LIST_PANEL_CLASS}" role="presentation"></div>`;
__decorateClass8([
  Autowired64("columnModel")
], _PrimaryColsListPanel.prototype, "columnModel", 2);
__decorateClass8([
  Autowired64("toolPanelColDefService")
], _PrimaryColsListPanel.prototype, "colDefService", 2);
__decorateClass8([
  Autowired64("modelItemUtils")
], _PrimaryColsListPanel.prototype, "modelItemUtils", 2);
__decorateClass8([
  PreDestroy3
], _PrimaryColsListPanel.prototype, "destroyColumnTree", 1);
var PrimaryColsListPanel = _PrimaryColsListPanel;
var PivotModePanel = class extends Component63 {
  createTemplate() {
    return (
      /* html */
      `<div class="ag-pivot-mode-panel">
                <ag-toggle-button ref="cbPivotMode" class="ag-pivot-mode-select"></ag-toggle-button>
            </div>`
    );
  }
  init() {
    this.setTemplate(this.createTemplate());
    this.cbPivotMode.setValue(this.columnModel.isPivotMode());
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    this.cbPivotMode.setLabel(localeTextFunc("pivotMode", "Pivot Mode"));
    this.addManagedListener(this.cbPivotMode, Events64.EVENT_FIELD_VALUE_CHANGED, this.onBtPivotMode.bind(this));
    this.addManagedListener(this.eventService, Events64.EVENT_NEW_COLUMNS_LOADED, this.onPivotModeChanged.bind(this));
    this.addManagedListener(this.eventService, Events64.EVENT_COLUMN_PIVOT_MODE_CHANGED, this.onPivotModeChanged.bind(this));
  }
  onBtPivotMode() {
    const newValue = !!this.cbPivotMode.getValue();
    if (newValue !== this.columnModel.isPivotMode()) {
      this.gos.updateGridOptions({ options: { pivotMode: newValue }, source: "toolPanelUi" });
      const { api } = this;
      if (api) {
        api.refreshHeader();
      }
    }
  }
  onPivotModeChanged() {
    const pivotModeActive = this.columnModel.isPivotMode();
    this.cbPivotMode.setValue(pivotModeActive);
  }
};
__decorateClass8([
  Autowired74("columnModel")
], PivotModePanel.prototype, "columnModel", 2);
__decorateClass8([
  Autowired74("gridApi")
], PivotModePanel.prototype, "api", 2);
__decorateClass8([
  RefSelector43("cbPivotMode")
], PivotModePanel.prototype, "cbPivotMode", 2);
__decorateClass8([
  PreConstruct2
], PivotModePanel.prototype, "init", 1);
var _PrimaryColsPanel = class _PrimaryColsPanel2 extends Component73 {
  constructor() {
    super(_PrimaryColsPanel2.TEMPLATE);
  }
  // we allow dragging in the toolPanel, but not when this component appears in the column menu
  init(allowDragging, params, eventType) {
    this.allowDragging = allowDragging;
    this.params = params;
    this.eventType = eventType;
    this.primaryColsHeaderPanel.init(this.params);
    const hideFilter = this.params.suppressColumnFilter;
    const hideSelect = this.params.suppressColumnSelectAll;
    const hideExpand = this.params.suppressColumnExpandAll;
    if (hideExpand && hideFilter && hideSelect) {
      this.primaryColsHeaderPanel.setDisplayed(false);
    }
    this.addManagedListener(this.primaryColsListPanel, "groupExpanded", this.onGroupExpanded.bind(this));
    this.addManagedListener(this.primaryColsListPanel, "selectionChanged", this.onSelectionChange.bind(this));
    this.primaryColsListPanel.init(this.params, this.allowDragging, this.eventType);
    this.addManagedListener(this.primaryColsHeaderPanel, "expandAll", this.onExpandAll.bind(this));
    this.addManagedListener(this.primaryColsHeaderPanel, "collapseAll", this.onCollapseAll.bind(this));
    this.addManagedListener(this.primaryColsHeaderPanel, "selectAll", this.onSelectAll.bind(this));
    this.addManagedListener(this.primaryColsHeaderPanel, "unselectAll", this.onUnselectAll.bind(this));
    this.addManagedListener(this.primaryColsHeaderPanel, "filterChanged", this.onFilterChanged.bind(this));
    this.positionableFeature = new PositionableFeature(this.getGui(), { minHeight: 100 });
    this.createManagedBean(this.positionableFeature);
  }
  toggleResizable(resizable) {
    this.positionableFeature.setResizable(resizable ? { bottom: true } : false);
  }
  onExpandAll() {
    this.primaryColsListPanel.doSetExpandedAll(true);
  }
  onCollapseAll() {
    this.primaryColsListPanel.doSetExpandedAll(false);
  }
  expandGroups(groupIds) {
    this.primaryColsListPanel.setGroupsExpanded(true, groupIds);
  }
  collapseGroups(groupIds) {
    this.primaryColsListPanel.setGroupsExpanded(false, groupIds);
  }
  setColumnLayout(colDefs) {
    this.primaryColsListPanel.setColumnLayout(colDefs);
  }
  onFilterChanged(event) {
    this.primaryColsListPanel.setFilterText(event.filterText);
  }
  syncLayoutWithGrid() {
    this.primaryColsListPanel.onColumnsChanged();
  }
  onSelectAll() {
    this.primaryColsListPanel.doSetSelectedAll(true);
  }
  onUnselectAll() {
    this.primaryColsListPanel.doSetSelectedAll(false);
  }
  onGroupExpanded(event) {
    this.primaryColsHeaderPanel.setExpandState(event.state);
    this.params.onStateUpdated();
  }
  onSelectionChange(event) {
    this.primaryColsHeaderPanel.setSelectionState(event.state);
  }
  getExpandedGroups() {
    return this.primaryColsListPanel.getExpandedGroups();
  }
};
_PrimaryColsPanel.TEMPLATE = /* html */
`<div class="ag-column-select">
            <ag-primary-cols-header ref="primaryColsHeaderPanel"></ag-primary-cols-header>
            <ag-primary-cols-list ref="primaryColsListPanel"></ag-primary-cols-list>
        </div>`;
__decorateClass8([
  RefSelector53("primaryColsHeaderPanel")
], _PrimaryColsPanel.prototype, "primaryColsHeaderPanel", 2);
__decorateClass8([
  RefSelector53("primaryColsListPanel")
], _PrimaryColsPanel.prototype, "primaryColsListPanel", 2);
var PrimaryColsPanel = _PrimaryColsPanel;
var _ColumnToolPanel = class _ColumnToolPanel2 extends Component83 {
  constructor() {
    super(_ColumnToolPanel2.TEMPLATE);
    this.initialised = false;
    this.childDestroyFuncs = [];
  }
  // lazy initialise the panel
  setVisible(visible) {
    super.setDisplayed(visible);
    if (visible && !this.initialised) {
      this.init(this.params);
    }
  }
  init(params) {
    const defaultParams = this.gos.addGridCommonParams({
      suppressColumnMove: false,
      suppressColumnSelectAll: false,
      suppressColumnFilter: false,
      suppressColumnExpandAll: false,
      contractColumnSelection: false,
      suppressPivotMode: false,
      suppressRowGroups: false,
      suppressValues: false,
      suppressPivots: false,
      suppressSyncLayoutWithGrid: false
    });
    this.params = __spreadValues6(__spreadValues6({}, defaultParams), params);
    if (this.isRowGroupingModuleLoaded() && !this.params.suppressPivotMode) {
      this.pivotModePanel = this.createBean(new PivotModePanel());
      this.childDestroyFuncs.push(() => this.destroyBean(this.pivotModePanel));
      this.appendChild(this.pivotModePanel);
    }
    this.primaryColsPanel = this.createBean(new PrimaryColsPanel());
    this.childDestroyFuncs.push(() => this.destroyBean(this.primaryColsPanel));
    this.primaryColsPanel.init(true, this.params, "toolPanelUi");
    this.primaryColsPanel.addCssClass("ag-column-panel-column-select");
    this.appendChild(this.primaryColsPanel);
    if (this.isRowGroupingModuleLoaded()) {
      if (!this.params.suppressRowGroups) {
        this.rowGroupDropZonePanel = this.createBean(new RowGroupDropZonePanel(false));
        this.childDestroyFuncs.push(() => this.destroyBean(this.rowGroupDropZonePanel));
        this.appendChild(this.rowGroupDropZonePanel);
      }
      if (!this.params.suppressValues) {
        this.valuesDropZonePanel = this.createBean(new ValuesDropZonePanel(false));
        this.childDestroyFuncs.push(() => this.destroyBean(this.valuesDropZonePanel));
        this.appendChild(this.valuesDropZonePanel);
      }
      if (!this.params.suppressPivots) {
        this.pivotDropZonePanel = this.createBean(new PivotDropZonePanel(false));
        this.childDestroyFuncs.push(() => this.destroyBean(this.pivotDropZonePanel));
        this.appendChild(this.pivotDropZonePanel);
      }
      this.setLastVisible();
      const pivotModeListener = this.addManagedListener(this.eventService, Events74.EVENT_COLUMN_PIVOT_MODE_CHANGED, () => {
        this.resetChildrenHeight();
        this.setLastVisible();
      });
      this.childDestroyFuncs.push(() => pivotModeListener());
    }
    this.initialised = true;
  }
  setPivotModeSectionVisible(visible) {
    if (!this.isRowGroupingModuleLoaded()) {
      return;
    }
    if (this.pivotModePanel) {
      this.pivotModePanel.setDisplayed(visible);
    } else if (visible) {
      this.pivotModePanel = this.createBean(new PivotModePanel());
      this.getGui().insertBefore(this.pivotModePanel.getGui(), this.getGui().firstChild);
      this.childDestroyFuncs.push(() => this.destroyBean(this.pivotModePanel));
    }
    this.setLastVisible();
  }
  setRowGroupsSectionVisible(visible) {
    if (!this.isRowGroupingModuleLoaded()) {
      return;
    }
    if (this.rowGroupDropZonePanel) {
      this.rowGroupDropZonePanel.setDisplayed(visible);
    } else if (visible) {
      this.rowGroupDropZonePanel = this.createManagedBean(new RowGroupDropZonePanel(false));
      this.appendChild(this.rowGroupDropZonePanel);
    }
    this.setLastVisible();
  }
  setValuesSectionVisible(visible) {
    if (!this.isRowGroupingModuleLoaded()) {
      return;
    }
    if (this.valuesDropZonePanel) {
      this.valuesDropZonePanel.setDisplayed(visible);
    } else if (visible) {
      this.valuesDropZonePanel = this.createManagedBean(new ValuesDropZonePanel(false));
      this.appendChild(this.valuesDropZonePanel);
    }
    this.setLastVisible();
  }
  setPivotSectionVisible(visible) {
    if (!this.isRowGroupingModuleLoaded()) {
      return;
    }
    if (this.pivotDropZonePanel) {
      this.pivotDropZonePanel.setDisplayed(visible);
    } else if (visible) {
      this.pivotDropZonePanel = this.createManagedBean(new PivotDropZonePanel(false));
      this.appendChild(this.pivotDropZonePanel);
      this.pivotDropZonePanel.setDisplayed(visible);
    }
    this.setLastVisible();
  }
  setResizers() {
    [
      this.primaryColsPanel,
      this.rowGroupDropZonePanel,
      this.valuesDropZonePanel,
      this.pivotDropZonePanel
    ].forEach((panel) => {
      if (!panel) {
        return;
      }
      const eGui = panel.getGui();
      panel.toggleResizable(!eGui.classList.contains("ag-last-column-drop") && !eGui.classList.contains("ag-hidden"));
    });
  }
  setLastVisible() {
    const eGui = this.getGui();
    const columnDrops = Array.prototype.slice.call(eGui.querySelectorAll(".ag-column-drop"));
    columnDrops.forEach((columnDrop) => columnDrop.classList.remove("ag-last-column-drop"));
    const columnDropEls = eGui.querySelectorAll(".ag-column-drop:not(.ag-hidden)");
    const lastVisible = _73.last(columnDropEls);
    if (lastVisible) {
      lastVisible.classList.add("ag-last-column-drop");
    }
    this.setResizers();
  }
  resetChildrenHeight() {
    const eGui = this.getGui();
    const children = eGui.children;
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      child.style.removeProperty("height");
      child.style.removeProperty("flex");
    }
  }
  isRowGroupingModuleLoaded() {
    return ModuleRegistry3.__assertRegistered(ModuleNames8.RowGroupingModule, "Row Grouping", this.context.getGridId());
  }
  expandColumnGroups(groupIds) {
    this.primaryColsPanel.expandGroups(groupIds);
  }
  collapseColumnGroups(groupIds) {
    this.primaryColsPanel.collapseGroups(groupIds);
  }
  setColumnLayout(colDefs) {
    this.primaryColsPanel.setColumnLayout(colDefs);
  }
  syncLayoutWithGrid() {
    this.primaryColsPanel.syncLayoutWithGrid();
  }
  destroyChildren() {
    this.childDestroyFuncs.forEach((func) => func());
    this.childDestroyFuncs.length = 0;
    _73.clearElement(this.getGui());
  }
  refresh(params) {
    this.destroyChildren();
    this.init(params);
    return true;
  }
  getState() {
    return {
      expandedGroupIds: this.primaryColsPanel.getExpandedGroups()
    };
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so this must be public.
  destroy() {
    this.destroyChildren();
    super.destroy();
  }
};
_ColumnToolPanel.TEMPLATE = `<div class="ag-column-panel"></div>`;
var ColumnToolPanel = _ColumnToolPanel;
var ModelItemUtils = class {
  selectAllChildren(colTree, selectAllChecked, eventType) {
    const cols = this.extractAllLeafColumns(colTree);
    this.setAllColumns(cols, selectAllChecked, eventType);
  }
  setColumn(col, selectAllChecked, eventType) {
    this.setAllColumns([col], selectAllChecked, eventType);
  }
  setAllColumns(cols, selectAllChecked, eventType) {
    if (this.columnModel.isPivotMode()) {
      this.setAllPivot(cols, selectAllChecked, eventType);
    } else {
      this.setAllVisible(cols, selectAllChecked, eventType);
    }
  }
  extractAllLeafColumns(allItems) {
    const res = [];
    const recursiveFunc = (items) => {
      items.forEach((item) => {
        if (!item.isPassesFilter()) {
          return;
        }
        if (item.isGroup()) {
          recursiveFunc(item.getChildren());
        } else {
          res.push(item.getColumn());
        }
      });
    };
    recursiveFunc(allItems);
    return res;
  }
  setAllVisible(columns, visible, eventType) {
    const colStateItems = [];
    columns.forEach((col) => {
      if (col.getColDef().lockVisible) {
        return;
      }
      if (col.isVisible() != visible) {
        colStateItems.push({
          colId: col.getId(),
          hide: !visible
        });
      }
    });
    if (colStateItems.length > 0) {
      this.columnModel.applyColumnState({ state: colStateItems }, eventType);
    }
  }
  setAllPivot(columns, value, eventType) {
    if (this.gos.get("functionsPassive")) {
      this.setAllPivotPassive(columns, value);
    } else {
      this.setAllPivotActive(columns, value, eventType);
    }
  }
  setAllPivotPassive(columns, value) {
    const copyOfPivotColumns = this.columnModel.getPivotColumns().slice();
    const copyOfValueColumns = this.columnModel.getValueColumns().slice();
    const copyOfRowGroupColumns = this.columnModel.getRowGroupColumns().slice();
    let pivotChanged = false;
    let valueChanged = false;
    let rowGroupChanged = false;
    const turnOnAction = (col) => {
      if (col.isAnyFunctionActive()) {
        return;
      }
      if (col.isAllowValue()) {
        copyOfValueColumns.push(col);
        valueChanged = true;
      } else if (col.isAllowRowGroup()) {
        copyOfRowGroupColumns.push(col);
        pivotChanged = true;
      } else if (col.isAllowPivot()) {
        copyOfPivotColumns.push(col);
        rowGroupChanged = true;
      }
    };
    const turnOffAction = (col) => {
      if (!col.isAnyFunctionActive()) {
        return;
      }
      if (copyOfPivotColumns.indexOf(col) >= 0) {
        _84.removeFromArray(copyOfPivotColumns, col);
        pivotChanged = true;
      }
      if (copyOfValueColumns.indexOf(col) >= 0) {
        _84.removeFromArray(copyOfValueColumns, col);
        valueChanged = true;
      }
      if (copyOfRowGroupColumns.indexOf(col) >= 0) {
        _84.removeFromArray(copyOfRowGroupColumns, col);
        rowGroupChanged = true;
      }
    };
    const action = value ? turnOnAction : turnOffAction;
    columns.forEach(action);
    if (pivotChanged) {
      const event = {
        type: Events83.EVENT_COLUMN_PIVOT_CHANGE_REQUEST,
        columns: copyOfPivotColumns
      };
      this.eventService.dispatchEvent(event);
    }
    if (rowGroupChanged) {
      const event = {
        type: Events83.EVENT_COLUMN_ROW_GROUP_CHANGE_REQUEST,
        columns: copyOfRowGroupColumns
      };
      this.eventService.dispatchEvent(event);
    }
    if (valueChanged) {
      const event = {
        type: Events83.EVENT_COLUMN_VALUE_CHANGE_REQUEST,
        columns: copyOfRowGroupColumns
      };
      this.eventService.dispatchEvent(event);
    }
  }
  setAllPivotActive(columns, value, eventType) {
    const colStateItems = [];
    const turnOnAction = (col) => {
      if (col.isAnyFunctionActive()) {
        return;
      }
      if (col.isAllowValue()) {
        const aggFunc = typeof col.getAggFunc() === "string" ? col.getAggFunc() : this.aggFuncService.getDefaultAggFunc(col);
        colStateItems.push({
          colId: col.getId(),
          aggFunc
        });
      } else if (col.isAllowRowGroup()) {
        colStateItems.push({
          colId: col.getId(),
          rowGroup: true
        });
      } else if (col.isAllowPivot()) {
        colStateItems.push({
          colId: col.getId(),
          pivot: true
        });
      }
    };
    const turnOffAction = (col) => {
      const isActive = col.isPivotActive() || col.isRowGroupActive() || col.isValueActive();
      if (isActive) {
        colStateItems.push({
          colId: col.getId(),
          pivot: false,
          rowGroup: false,
          aggFunc: null
        });
      }
    };
    const action = value ? turnOnAction : turnOffAction;
    columns.forEach(action);
    if (colStateItems.length > 0) {
      this.columnModel.applyColumnState({ state: colStateItems }, eventType);
    }
  }
  updateColumns(params) {
    const { columns, visibleState, pivotState, eventType } = params;
    const state = columns.map((column) => {
      const colId = column.getColId();
      if (this.columnModel.isPivotMode()) {
        const pivotStateForColumn = pivotState == null ? void 0 : pivotState[colId];
        return {
          colId,
          pivot: pivotStateForColumn == null ? void 0 : pivotStateForColumn.pivot,
          rowGroup: pivotStateForColumn == null ? void 0 : pivotStateForColumn.rowGroup,
          aggFunc: pivotStateForColumn == null ? void 0 : pivotStateForColumn.aggFunc
        };
      } else {
        return {
          colId,
          hide: !(visibleState == null ? void 0 : visibleState[colId])
        };
      }
    });
    this.columnModel.applyColumnState({ state }, eventType);
  }
  createPivotState(column) {
    return {
      pivot: column.isPivotActive(),
      rowGroup: column.isRowGroupActive(),
      aggFunc: column.isValueActive() ? column.getAggFunc() : void 0
    };
  }
};
__decorateClass8([
  Autowired84("aggFuncService")
], ModelItemUtils.prototype, "aggFuncService", 2);
__decorateClass8([
  Autowired84("columnModel")
], ModelItemUtils.prototype, "columnModel", 2);
__decorateClass8([
  Autowired84("gridOptionsService")
], ModelItemUtils.prototype, "gos", 2);
__decorateClass8([
  Autowired84("eventService")
], ModelItemUtils.prototype, "eventService", 2);
ModelItemUtils = __decorateClass8([
  Bean11("modelItemUtils")
], ModelItemUtils);
var VERSION8 = "31.3.2";
var ColumnsToolPanelModule = {
  version: VERSION8,
  moduleName: ModuleNames24.ColumnsToolPanelModule,
  beans: [ModelItemUtils],
  agStackComponents: [
    { componentName: "AgPrimaryColsHeader", componentClass: PrimaryColsHeaderPanel },
    { componentName: "AgPrimaryColsList", componentClass: PrimaryColsListPanel },
    { componentName: "AgPrimaryCols", componentClass: PrimaryColsPanel }
  ],
  userComponents: [
    { componentName: "agColumnsToolPanel", componentClass: ColumnToolPanel }
  ],
  dependantModules: [
    EnterpriseCoreModule,
    RowGroupingModule,
    SideBarModule
  ]
};

// enterprise-modules/excel-export/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames9 } from "ag-grid-community";
import {
  Autowired as Autowired57,
  Bean as Bean12,
  PostConstruct as PostConstruct58,
  CssClassApplier as CssClassApplier3
} from "ag-grid-community";
import {
  ExcelFactoryMode,
  _ as _55
} from "ag-grid-community";
import { XmlFactory } from "ag-grid-community";
import { _ as _40 } from "ag-grid-community";
import { _ as _212 } from "ag-grid-community";
import {
  _ as _46
} from "ag-grid-community";
import { _ as _311 } from "ag-grid-community";
import { BaseCreator, Downloader, RowType as RowType2, ZipContainer } from "ag-grid-community";
import {
  _ as _65
} from "ag-grid-community";
import {
  BaseGridSerializingSession,
  RowType
} from "ag-grid-community";
import { CsvCreator, GridSerializer as GridSerializer2 } from "ag-grid-community";
import { CsvExportModule as CsvExportModule2 } from "ag-grid-community";
var __defProp9 = Object.defineProperty;
var __defProps6 = Object.defineProperties;
var __getOwnPropDesc9 = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs6 = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols7 = Object.getOwnPropertySymbols;
var __hasOwnProp8 = Object.prototype.hasOwnProperty;
var __propIsEnum7 = Object.prototype.propertyIsEnumerable;
var __defNormalProp7 = (obj, key, value) => key in obj ? __defProp9(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues7 = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp8.call(b, prop))
      __defNormalProp7(a, prop, b[prop]);
  if (__getOwnPropSymbols7)
    for (var prop of __getOwnPropSymbols7(b)) {
      if (__propIsEnum7.call(b, prop))
        __defNormalProp7(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps6 = (a, b) => __defProps6(a, __getOwnPropDescs6(b));
var __decorateClass9 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc9(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp9(target, key, result);
  return result;
};
var coreFactory = {
  getTemplate(author) {
    const dt = /* @__PURE__ */ new Date();
    const jsonDate = dt.toJSON();
    return {
      name: "cp:coreProperties",
      properties: {
        prefixedAttributes: [{
          prefix: "xmlns:",
          map: {
            cp: "http://schemas.openxmlformats.org/package/2006/metadata/core-properties",
            dc: "http://purl.org/dc/elements/1.1/",
            dcterms: "http://purl.org/dc/terms/",
            dcmitype: "http://purl.org/dc/dcmitype/",
            xsi: "http://www.w3.org/2001/XMLSchema-instance"
          }
        }]
      },
      children: [{
        name: "dc:creator",
        textNode: author
      }, {
        name: "dc:title",
        textNode: "Workbook"
      }, {
        name: "dcterms:created",
        properties: {
          rawMap: {
            "xsi:type": "dcterms:W3CDTF"
          }
        },
        textNode: jsonDate
      }, {
        name: "dcterms:modified",
        properties: {
          rawMap: {
            "xsi:type": "dcterms:W3CDTF"
          }
        },
        textNode: jsonDate
      }]
    };
  }
};
var core_default = coreFactory;
var contentTypeFactory = {
  getTemplate(config) {
    const { name, ContentType, Extension, PartName } = config;
    return {
      name,
      properties: {
        rawMap: {
          Extension,
          PartName,
          ContentType
        }
      }
    };
  }
};
var contentType_default = contentTypeFactory;
var contentTypesFactory = {
  getTemplate(sheetLen) {
    const worksheets = new Array(sheetLen).fill(void 0).map((v, i) => ({
      name: "Override",
      ContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.worksheet+xml",
      PartName: `/xl/worksheets/sheet${i + 1}.xml`
    }));
    const sheetsWithImages = ExcelXlsxFactory.worksheetImages.size;
    const headerFooterImages = ExcelXlsxFactory.worksheetHeaderFooterImages.size;
    const sheetsWithTables = ExcelXlsxFactory.worksheetDataTables.size;
    const imageTypesObject = {};
    ExcelXlsxFactory.workbookImageIds.forEach((v) => {
      const type = v.type === "jpg" ? "jpeg" : v.type;
      imageTypesObject[type] = true;
    });
    const imageDocs = new Array(sheetsWithImages).fill(void 0).map((v, i) => ({
      name: "Override",
      ContentType: "application/vnd.openxmlformats-officedocument.drawing+xml",
      PartName: `/xl/drawings/drawing${i + 1}.xml`
    }));
    const tableDocs = new Array(sheetsWithTables).fill(void 0).map((v, i) => ({
      name: "Override",
      ContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.table+xml",
      PartName: `/xl/tables/${ExcelXlsxFactory.getTableNameFromIndex(i)}.xml`
    }));
    const imageTypes = Object.keys(imageTypesObject).map((ext) => ({
      name: "Default",
      ContentType: `image/${ext}`,
      Extension: ext
    }));
    if (headerFooterImages) {
      imageTypes.push({
        name: "Default",
        Extension: "vml",
        ContentType: "application/vnd.openxmlformats-officedocument.vmlDrawing"
      });
    }
    const children = [
      ...imageTypes,
      {
        name: "Default",
        Extension: "rels",
        ContentType: "application/vnd.openxmlformats-package.relationships+xml"
      },
      {
        name: "Default",
        ContentType: "application/xml",
        Extension: "xml"
      },
      {
        name: "Override",
        ContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml",
        PartName: "/xl/workbook.xml"
      },
      ...worksheets,
      {
        name: "Override",
        ContentType: "application/vnd.openxmlformats-officedocument.theme+xml",
        PartName: "/xl/theme/theme1.xml"
      },
      {
        name: "Override",
        ContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.styles+xml",
        PartName: "/xl/styles.xml"
      },
      {
        name: "Override",
        ContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sharedStrings+xml",
        PartName: "/xl/sharedStrings.xml"
      },
      ...imageDocs,
      ...tableDocs,
      {
        name: "Override",
        ContentType: "application/vnd.openxmlformats-package.core-properties+xml",
        PartName: "/docProps/core.xml"
      }
    ].map((contentType) => contentType_default.getTemplate(contentType));
    return {
      name: "Types",
      properties: {
        rawMap: {
          xmlns: "http://schemas.openxmlformats.org/package/2006/content-types"
        }
      },
      children
    };
  }
};
var contentTypes_default = contentTypesFactory;
var INCH_TO_EMU = 9525;
var numberFormatMap = {
  "0": 1,
  "0.00": 2,
  "#,##0": 3,
  "#,##0.00": 4,
  "0%": 9,
  "0.00%": 10,
  "0.00E+00": 11,
  "# ?/?": 12,
  "# ??/??": 13,
  "mm-dd-yy": 14,
  "d-mmm-yy": 15,
  "d-mmm": 16,
  "mmm-yy": 17,
  "h:mm AM/PM": 18,
  "h:mm:ss AM/PM": 19,
  "h:mm": 20,
  "h:mm:ss": 21,
  "m/d/yy h:mm": 22,
  "#,##0 ;(#,##0)": 37,
  "#,##0 ;[Red](#,##0)": 38,
  "#,##0.00;(#,##0.00)": 39,
  "#,##0.00;[Red](#,##0.00)": 40,
  "mm:ss": 45,
  "[h]:mm:ss": 46,
  "mmss.0": 47,
  "##0.0E+0": 48,
  "@": 49
};
var pixelsToPoint = (pixels) => {
  return Math.round(pixels * 72 / 96);
};
var pointsToPixel = (points) => {
  return Math.round(points * 96 / 72);
};
var pixelsToEMU = (value) => {
  return Math.ceil(value * INCH_TO_EMU);
};
var getFontFamilyId = (name) => {
  if (name === void 0) {
    return;
  }
  const families = ["Automatic", "Roman", "Swiss", "Modern", "Script", "Decorative"];
  const pos = families.indexOf(name || "Automatic");
  return Math.max(pos, 0);
};
var getHeightFromProperty = (rowIndex, height) => {
  if (!height) {
    return;
  }
  let finalHeight;
  if (typeof height === "number") {
    finalHeight = height;
  } else {
    const heightFunc = height;
    finalHeight = heightFunc({ rowIndex });
  }
  return pixelsToPoint(finalHeight);
};
var setExcelImageTotalWidth = (image, columnsToExport) => {
  const { colSpan, column } = image.position;
  if (!image.width) {
    return;
  }
  if (colSpan) {
    const columnsInSpan = columnsToExport.slice(column - 1, column + colSpan - 1);
    let totalWidth = 0;
    for (let i = 0; i < columnsInSpan.length; i++) {
      const colWidth = columnsInSpan[i].getActualWidth();
      if (image.width < totalWidth + colWidth) {
        image.position.colSpan = i + 1;
        image.totalWidth = image.width;
        image.width = image.totalWidth - totalWidth;
        break;
      }
      totalWidth += colWidth;
    }
  } else {
    image.totalWidth = image.width;
  }
};
var setExcelImageTotalHeight = (image, rowHeight) => {
  const { rowSpan, row } = image.position;
  if (!image.height) {
    return;
  }
  if (rowSpan) {
    let totalHeight = 0;
    let counter = 0;
    for (let i = row; i < row + rowSpan; i++) {
      const nextRowHeight = pointsToPixel(getHeightFromProperty(i, rowHeight) || 20);
      if (image.height < totalHeight + nextRowHeight) {
        image.position.rowSpan = counter + 1;
        image.totalHeight = image.height;
        image.height = image.totalHeight - totalHeight;
        break;
      }
      totalHeight += nextRowHeight;
      counter++;
    }
  } else {
    image.totalHeight = image.height;
  }
};
var createXmlPart = (body, skipHeader) => {
  const header = XmlFactory.createHeader({
    encoding: "UTF-8",
    standalone: "yes"
  });
  const xmlBody = XmlFactory.createXml(body);
  if (skipHeader) {
    return xmlBody;
  }
  return `${header}${xmlBody}`;
};
var getExcelColumnName = (colIdx) => {
  const startCode = 65;
  const tableWidth = 26;
  const fromCharCode = String.fromCharCode;
  const pos = Math.floor(colIdx / tableWidth);
  const tableIdx = colIdx % tableWidth;
  if (!pos || colIdx === tableWidth) {
    return fromCharCode(startCode + colIdx - 1);
  }
  if (!tableIdx) {
    return getExcelColumnName(pos - 1) + "Z";
  }
  if (pos < tableWidth) {
    return fromCharCode(startCode + pos - 1) + fromCharCode(startCode + tableIdx - 1);
  }
  return getExcelColumnName(pos) + fromCharCode(startCode + tableIdx - 1);
};
var getAnchor = (name, imageAnchor) => ({
  name: `xdr:${name}`,
  children: [{
    name: "xdr:col",
    textNode: imageAnchor.col.toString()
  }, {
    name: "xdr:colOff",
    textNode: imageAnchor.offsetX.toString()
  }, {
    name: "xdr:row",
    textNode: imageAnchor.row.toString()
  }, {
    name: "xdr:rowOff",
    textNode: imageAnchor.offsetY.toString()
  }]
});
var getExt = (image) => {
  const children = [{
    name: "a:ext",
    properties: {
      rawMap: {
        uri: "{FF2B5EF4-FFF2-40B4-BE49-F238E27FC236}"
      }
    },
    children: [{
      name: "a16:creationId",
      properties: {
        rawMap: {
          "id": "{822E6D20-D7BC-2841-A643-D49A6EF008A2}",
          "xmlns:a16": "http://schemas.microsoft.com/office/drawing/2014/main"
        }
      }
    }]
  }];
  const recolor = image.recolor && image.recolor.toLowerCase();
  switch (recolor) {
    case "grayscale":
    case "sepia":
    case "washout":
      children.push({
        name: "a:ext",
        properties: {
          rawMap: {
            uri: "{C183D7F6-B498-43B3-948B-1728B52AA6E4}"
          }
        },
        children: [{
          name: "adec:decorative",
          properties: {
            rawMap: {
              "val": "0",
              "xmlns:adec": "http://schemas.microsoft.com/office/drawing/2017/decorative"
            }
          }
        }]
      });
  }
  return {
    name: "a:extLst",
    children
  };
};
var getNvPicPr = (image, index) => ({
  name: "xdr:nvPicPr",
  children: [{
    name: "xdr:cNvPr",
    properties: {
      rawMap: {
        id: index,
        name: image.id,
        descr: image.altText != null ? image.altText : void 0
      }
    },
    children: [getExt(image)]
  }, {
    name: "xdr:cNvPicPr",
    properties: {
      rawMap: {
        preferRelativeResize: "0"
      }
    },
    children: [{
      name: "a:picLocks"
    }]
  }]
});
var getColorDetails = (color) => {
  if (!color.saturation && !color.tint) {
    return;
  }
  const ret = [];
  if (color.saturation) {
    ret.push({
      name: "a:satMod",
      properties: {
        rawMap: {
          val: color.saturation * 1e3
        }
      }
    });
  }
  if (color.tint) {
    ret.push({
      name: "a:tint",
      properties: {
        rawMap: {
          val: color.tint * 1e3
        }
      }
    });
  }
  return ret;
};
var getDuoTone = (primaryColor, secondaryColor) => {
  return {
    name: "a:duotone",
    children: [{
      name: "a:prstClr",
      properties: {
        rawMap: {
          val: primaryColor.color
        }
      },
      children: getColorDetails(primaryColor)
    }, {
      name: "a:srgbClr",
      properties: {
        rawMap: {
          val: secondaryColor.color
        }
      },
      children: getColorDetails(secondaryColor)
    }]
  };
};
var getBlipFill = (image, index) => {
  let blipChildren;
  if (image.transparency) {
    const transparency = Math.min(Math.max(image.transparency, 0), 100);
    blipChildren = [{
      name: "a:alphaModFix",
      properties: {
        rawMap: {
          amt: 1e5 - Math.round(transparency * 1e3)
        }
      }
    }];
  }
  if (image.recolor) {
    if (!blipChildren) {
      blipChildren = [];
    }
    switch (image.recolor.toLocaleLowerCase()) {
      case "grayscale":
        blipChildren.push({ name: "a:grayscl" });
        break;
      case "sepia":
        blipChildren.push(getDuoTone({ color: "black" }, { color: "D9C3A5", tint: 50, saturation: 180 }));
        break;
      case "washout":
        blipChildren.push({
          name: "a:lum",
          properties: {
            rawMap: {
              bright: "70000",
              contrast: "-70000"
            }
          }
        });
        break;
      default:
    }
  }
  return {
    name: "xdr:blipFill",
    children: [{
      name: "a:blip",
      properties: {
        rawMap: {
          "cstate": "print",
          "r:embed": `rId${index}`,
          "xmlns:r": "http://schemas.openxmlformats.org/officeDocument/2006/relationships"
        }
      },
      children: blipChildren
    }, {
      name: "a:stretch",
      children: [{
        name: "a:fillRect"
      }]
    }]
  };
};
var getSpPr = (image, imageBoxSize) => {
  const xfrm = {
    name: "a:xfrm",
    children: [{
      name: "a:off",
      properties: {
        rawMap: {
          x: 0,
          y: 0
        }
      }
    }, {
      name: "a:ext",
      properties: {
        rawMap: {
          cx: imageBoxSize.width,
          cy: imageBoxSize.height
        }
      }
    }]
  };
  if (image.rotation) {
    const rotation = image.rotation;
    xfrm.properties = {
      rawMap: {
        rot: Math.min(Math.max(rotation, 0), 360) * 6e4
      }
    };
  }
  const prstGeom = {
    name: "a:prstGeom",
    properties: {
      rawMap: {
        prst: "rect"
      }
    },
    children: [{ name: "a:avLst" }]
  };
  const ret = {
    name: "xdr:spPr",
    children: [xfrm, prstGeom]
  };
  return ret;
};
var getImageBoxSize = (image) => {
  image.fitCell = !!image.fitCell || (!image.width || !image.height);
  const { position = {}, fitCell, width = 0, height = 0, totalHeight, totalWidth } = image;
  const { offsetX = 0, offsetY = 0, row = 1, rowSpan = 1, column = 1, colSpan = 1 } = position;
  return {
    from: {
      row: row - 1,
      col: column - 1,
      offsetX: pixelsToEMU(offsetX),
      offsetY: pixelsToEMU(offsetY)
    },
    to: {
      row: row - 1 + (fitCell ? 1 : rowSpan - 1),
      col: column - 1 + (fitCell ? 1 : colSpan - 1),
      offsetX: pixelsToEMU(width + offsetX),
      offsetY: pixelsToEMU(height + offsetY)
    },
    height: pixelsToEMU(totalHeight || height),
    width: pixelsToEMU(totalWidth || width)
  };
};
var getPicture = (image, currentIndex, worksheetImageIndex, imageBoxSize) => {
  return {
    name: "xdr:pic",
    children: [
      getNvPicPr(image, currentIndex + 1),
      getBlipFill(image, worksheetImageIndex + 1),
      getSpPr(image, imageBoxSize)
    ]
  };
};
var drawingFactory = {
  getTemplate(config) {
    const { sheetIndex } = config;
    const sheetImages = ExcelXlsxFactory.worksheetImages.get(sheetIndex);
    const sheetImageIds = ExcelXlsxFactory.worksheetImageIds.get(sheetIndex);
    const children = sheetImages.map((image, idx) => {
      const boxSize = getImageBoxSize(image);
      return {
        name: "xdr:twoCellAnchor",
        properties: {
          rawMap: {
            editAs: "absolute"
          }
        },
        children: [
          getAnchor("from", boxSize.from),
          getAnchor("to", boxSize.to),
          getPicture(image, idx, sheetImageIds.get(image.id).index, boxSize),
          { name: "xdr:clientData" }
        ]
      };
    });
    return {
      name: "xdr:wsDr",
      properties: {
        rawMap: {
          "xmlns:a": "http://schemas.openxmlformats.org/drawingml/2006/main",
          "xmlns:xdr": "http://schemas.openxmlformats.org/drawingml/2006/spreadsheetDrawing"
        }
      },
      children
    };
  }
};
var drawing_default = drawingFactory;
var tableFactory = {
  getTemplate(dataTable, idx) {
    const {
      name,
      columns,
      rowCount,
      displayName,
      headerRowIndex,
      showRowStripes,
      showColumnStripes,
      showFilterButtons,
      highlightFirstColumn,
      highlightLastColumn
    } = dataTable || {};
    if (!dataTable || !name || !Array.isArray(columns) || !columns.length || !rowCount) {
      return { name: "table" };
    }
    const filterColumns = columns.map((col, idx2) => ({
      name: "filterColumn",
      properties: {
        rawMap: {
          colId: idx2.toString(),
          // For filters, this should start with 0
          hiddenButton: showFilterButtons[idx2] ? 0 : 1
        }
      }
    }));
    const firstRow = headerRowIndex + 1;
    const id = (idx + 1).toString();
    const firstCell = `A${firstRow}`;
    const lastCell = `${String.fromCharCode(64 + columns.length)}${firstRow + rowCount}`;
    const ref = `${firstCell}:${lastCell}`;
    const displayNameToUse = idx ? `${displayName}_${idx + 1}` : displayName;
    return {
      name: "table",
      properties: {
        rawMap: {
          "xmlns": "http://schemas.openxmlformats.org/spreadsheetml/2006/main",
          "xmlns:mc": "http://schemas.openxmlformats.org/markup-compatibility/2006",
          "mc:Ignorable": "xr xr3",
          "xmlns:xr": "http://schemas.microsoft.com/office/spreadsheetml/2014/revision",
          "xmlns:xr3": "http://schemas.microsoft.com/office/spreadsheetml/2016/revision3",
          "name": name,
          "displayName": displayNameToUse,
          "ref": ref,
          "totalsRowShown": 0,
          "id": id
        }
      },
      children: [
        {
          name: "autoFilter",
          properties: {
            rawMap: {
              ref
            }
          },
          children: filterColumns
        },
        {
          name: "tableColumns",
          properties: {
            rawMap: {
              count: columns.length
            }
          },
          children: columns.map((col, idx2) => ({
            name: "tableColumn",
            properties: {
              rawMap: {
                id: (idx2 + 1).toString(),
                name: col,
                dataCellStyle: "Normal"
              }
            }
          }))
        },
        {
          name: "tableStyleInfo",
          properties: {
            rawMap: {
              name: "TableStyleLight1",
              showFirstColumn: highlightFirstColumn ? 1 : 0,
              showLastColumn: highlightLastColumn ? 1 : 0,
              showRowStripes: showRowStripes ? 1 : 0,
              showColumnStripes: showColumnStripes ? 1 : 0
            }
          }
        }
      ]
    };
  }
};
var table_default = tableFactory;
var getColorChildren = (props) => {
  const [type, innerType, val, lastClr] = props;
  return {
    name: `a:${type}`,
    children: [{
      name: `a:${innerType}`,
      properties: {
        rawMap: {
          val,
          lastClr
        }
      }
    }]
  };
};
var colorScheme = {
  getTemplate() {
    return {
      name: "a:clrScheme",
      properties: {
        rawMap: {
          name: "Office"
        }
      },
      children: [
        getColorChildren(["dk1", "sysClr", "windowText", "000000"]),
        getColorChildren(["lt1", "sysClr", "window", "FFFFFF"]),
        getColorChildren(["dk2", "srgbClr", "44546A"]),
        getColorChildren(["lt2", "srgbClr", "E7E6E6"]),
        getColorChildren(["accent1", "srgbClr", "4472C4"]),
        getColorChildren(["accent2", "srgbClr", "ED7D31"]),
        getColorChildren(["accent3", "srgbClr", "A5A5A5"]),
        getColorChildren(["accent4", "srgbClr", "FFC000"]),
        getColorChildren(["accent5", "srgbClr", "5B9BD5"]),
        getColorChildren(["accent6", "srgbClr", "70AD47"]),
        getColorChildren(["hlink", "srgbClr", "0563C1"]),
        getColorChildren(["folHlink", "srgbClr", "954F72"])
      ]
    };
  }
};
var colorScheme_default = colorScheme;
var getFont = (props) => {
  const [type, typeface, script, panose] = props;
  return {
    name: `a:${type}`,
    properties: {
      rawMap: {
        script,
        typeface,
        panose
      }
    }
  };
};
var fontScheme = {
  getTemplate() {
    return {
      name: "a:fontScheme",
      properties: {
        rawMap: {
          name: "Office"
        }
      },
      children: [{
        name: "a:majorFont",
        children: [
          getFont(["latin", "Calibri Light", void 0, "020F0302020204030204"]),
          getFont(["ea", ""]),
          getFont(["cs", ""]),
          getFont(["font", "\u6E38\u30B4\u30B7\u30C3\u30AF Light", "Jpan"]),
          getFont(["font", "\uB9D1\uC740 \uACE0\uB515", "Hang"]),
          getFont(["font", "\u7B49\u7EBF Light", "Hans"]),
          getFont(["font", "\u65B0\u7D30\u660E\u9AD4", "Hant"]),
          getFont(["font", "Times New Roman", "Arab"]),
          getFont(["font", "Times New Roman", "Hebr"]),
          getFont(["font", "Tahoma", "Thai"]),
          getFont(["font", "Nyala", "Ethi"]),
          getFont(["font", "Vrinda", "Beng"]),
          getFont(["font", "Shruti", "Gujr"]),
          getFont(["font", "MoolBoran", "Khmr"]),
          getFont(["font", "Tunga", "Knda"]),
          getFont(["font", "Raavi", "Guru"]),
          getFont(["font", "Euphemia", "Cans"]),
          getFont(["font", "Plantagenet Cherokee", "Cher"]),
          getFont(["font", "Microsoft Yi Baiti", "Yiii"]),
          getFont(["font", "Microsoft Himalaya", "Tibt"]),
          getFont(["font", "MV Boli", "Thaa"]),
          getFont(["font", "Mangal", "Deva"]),
          getFont(["font", "Gautami", "Telu"]),
          getFont(["font", "Latha", "Taml"]),
          getFont(["font", "Estrangelo Edessa", "Syrc"]),
          getFont(["font", "Kalinga", "Orya"]),
          getFont(["font", "Kartika", "Mlym"]),
          getFont(["font", "DokChampa", "Laoo"]),
          getFont(["font", "Iskoola Pota", "Sinh"]),
          getFont(["font", "Mongolian Baiti", "Mong"]),
          getFont(["font", "Times New Roman", "Viet"]),
          getFont(["font", "Microsoft Uighur", "Uigh"]),
          getFont(["font", "Sylfaen", "Geor"]),
          getFont(["font", "Arial", "Armn"]),
          getFont(["font", "Leelawadee UI", "Bugi"]),
          getFont(["font", "Microsoft JhengHei", "Bopo"]),
          getFont(["font", "Javanese Text", "Java"]),
          getFont(["font", "Segoe UI", "Lisu"]),
          getFont(["font", "Myanmar Text", "Mymr"]),
          getFont(["font", "Ebrima", "Nkoo"]),
          getFont(["font", "Nirmala UI", "Olck"]),
          getFont(["font", "Ebrima", "Osma"]),
          getFont(["font", "Phagspa", "Phag"]),
          getFont(["font", "Estrangelo Edessa", "Syrn"]),
          getFont(["font", "Estrangelo Edessa", "Syrj"]),
          getFont(["font", "Estrangelo Edessa", "Syre"]),
          getFont(["font", "Nirmala UI", "Sora"]),
          getFont(["font", "Microsoft Tai Le", "Tale"]),
          getFont(["font", "Microsoft New Tai Lue", "Talu"]),
          getFont(["font", "Ebrima", "Tfng"])
        ]
      }, {
        name: "a:minorFont",
        children: [
          getFont(["latin", "Calibri", void 0, "020F0502020204030204"]),
          getFont(["ea", ""]),
          getFont(["cs", ""]),
          getFont(["font", "\u6E38\u30B4\u30B7\u30C3\u30AF", "Jpan"]),
          getFont(["font", "\uB9D1\uC740 \uACE0\uB515", "Hang"]),
          getFont(["font", "\u7B49\u7EBF", "Hans"]),
          getFont(["font", "\u65B0\u7D30\u660E\u9AD4", "Hant"]),
          getFont(["font", "Arial", "Arab"]),
          getFont(["font", "Arial", "Hebr"]),
          getFont(["font", "Tahoma", "Thai"]),
          getFont(["font", "Nyala", "Ethi"]),
          getFont(["font", "Vrinda", "Beng"]),
          getFont(["font", "Shruti", "Gujr"]),
          getFont(["font", "DaunPenh", "Khmr"]),
          getFont(["font", "Tunga", "Knda"]),
          getFont(["font", "Raavi", "Guru"]),
          getFont(["font", "Euphemia", "Cans"]),
          getFont(["font", "Plantagenet Cherokee", "Cher"]),
          getFont(["font", "Microsoft Yi Baiti", "Yiii"]),
          getFont(["font", "Microsoft Himalaya", "Tibt"]),
          getFont(["font", "MV Boli", "Thaa"]),
          getFont(["font", "Mangal", "Deva"]),
          getFont(["font", "Gautami", "Telu"]),
          getFont(["font", "Latha", "Taml"]),
          getFont(["font", "Estrangelo Edessa", "Syrc"]),
          getFont(["font", "Kalinga", "Orya"]),
          getFont(["font", "Kartika", "Mlym"]),
          getFont(["font", "DokChampa", "Laoo"]),
          getFont(["font", "Iskoola Pota", "Sinh"]),
          getFont(["font", "Mongolian Baiti", "Mong"]),
          getFont(["font", "Arial", "Viet"]),
          getFont(["font", "Microsoft Uighur", "Uigh"]),
          getFont(["font", "Sylfaen", "Geor"]),
          getFont(["font", "Arial", "Armn"]),
          getFont(["font", "Leelawadee UI", "Bugi"]),
          getFont(["font", "Microsoft JhengHei", "Bopo"]),
          getFont(["font", "Javanese Text", "Java"]),
          getFont(["font", "Segoe UI", "Lisu"]),
          getFont(["font", "Myanmar Text", "Mymr"]),
          getFont(["font", "Ebrima", "Nkoo"]),
          getFont(["font", "Nirmala UI", "Olck"]),
          getFont(["font", "Ebrima", "Osma"]),
          getFont(["font", "Phagspa", "Phag"]),
          getFont(["font", "Estrangelo Edessa", "Syrn"]),
          getFont(["font", "Estrangelo Edessa", "Syrj"]),
          getFont(["font", "Estrangelo Edessa", "Syre"]),
          getFont(["font", "Nirmala UI", "Sora"]),
          getFont(["font", "Microsoft Tai Le", "Tale"]),
          getFont(["font", "Microsoft New Tai Lue", "Talu"]),
          getFont(["font", "Ebrima", "Tfng"])
        ]
      }]
    };
  }
};
var fontScheme_default = fontScheme;
var getPropertyVal = (name, val, children) => ({
  name: `a:${name}`,
  properties: {
    rawMap: {
      val
    }
  },
  children
});
var getGs = (props) => {
  const [pos, schemeColor, satMod, lumMod, tint, shade] = props;
  const children = [];
  children.push(getPropertyVal("satMod", satMod));
  if (lumMod) {
    children.push(getPropertyVal("lumMod", lumMod));
  }
  if (tint) {
    children.push(getPropertyVal("tint", tint));
  }
  if (shade) {
    children.push(getPropertyVal("shade", shade));
  }
  return {
    name: "a:gs",
    properties: {
      rawMap: {
        pos
      }
    },
    children: [{
      name: "a:schemeClr",
      properties: {
        rawMap: {
          val: schemeColor
        }
      },
      children
    }]
  };
};
var getSolidFill = (val, children) => ({
  name: "a:solidFill",
  children: [getPropertyVal("schemeClr", val, children)]
});
var getGradFill = (props) => {
  const [rotWithShape, gs1, gs2, gs3, lin] = props;
  const [ang, scaled] = lin;
  return {
    name: "a:gradFill",
    properties: {
      rawMap: {
        rotWithShape
      }
    },
    children: [{
      name: "a:gsLst",
      children: [
        getGs(gs1),
        getGs(gs2),
        getGs(gs3)
      ]
    }, {
      name: "a:lin",
      properties: {
        rawMap: {
          ang,
          scaled
        }
      }
    }]
  };
};
var getLine = (props) => {
  const [w, cap, cmpd, algn] = props;
  return {
    name: "a:ln",
    properties: {
      rawMap: { w, cap, cmpd, algn }
    },
    children: [
      getSolidFill("phClr"),
      getPropertyVal("prstDash", "solid"),
      {
        name: "a:miter",
        properties: {
          rawMap: {
            lim: "800000"
          }
        }
      }
    ]
  };
};
var getEffectStyle = (shadow) => {
  const children = [];
  if (shadow) {
    const [blurRad, dist, dir, algn, rotWithShape] = shadow;
    children.push({
      name: "a:outerShdw",
      properties: {
        rawMap: { blurRad, dist, dir, algn, rotWithShape }
      },
      children: [
        getPropertyVal("srgbClr", "000000", [getPropertyVal("alpha", "63000")])
      ]
    });
  }
  return {
    name: "a:effectStyle",
    children: [Object.assign({}, {
      name: "a:effectLst"
    }, children.length ? { children } : {})]
  };
};
var getFillStyleList = () => ({
  name: "a:fillStyleLst",
  children: [
    getSolidFill("phClr"),
    getGradFill([
      "1",
      ["0", "phClr", "105000", "110000", "67000"],
      ["50000", "phClr", "103000", "105000", "73000"],
      ["100000", "phClr", "109000", "105000", "81000"],
      ["5400000", "0"]
    ]),
    getGradFill([
      "1",
      ["0", "phClr", "103000", "102000", "94000"],
      ["50000", "phClr", "110000", "100000", void 0, "100000"],
      ["100000", "phClr", "120000", "99000", void 0, "78000"],
      ["5400000", "0"]
    ])
  ]
});
var getLineStyleList = () => ({
  name: "a:lnStyleLst",
  children: [
    getLine(["6350", "flat", "sng", "ctr"]),
    getLine(["12700", "flat", "sng", "ctr"]),
    getLine(["19050", "flat", "sng", "ctr"])
  ]
});
var getEffectStyleList = () => ({
  name: "a:effectStyleLst",
  children: [
    getEffectStyle(),
    getEffectStyle(),
    getEffectStyle(["57150", "19050", "5400000", "ctr", "0"])
  ]
});
var getBgFillStyleList = () => ({
  name: "a:bgFillStyleLst",
  children: [
    getSolidFill("phClr"),
    getSolidFill("phClr", [
      getPropertyVal("tint", "95000"),
      getPropertyVal("satMod", "170000")
    ]),
    getGradFill([
      "1",
      ["0", "phClr", "150000", "102000", "93000", "98000"],
      ["50000", "phClr", "130000", "103000", "98000", "90000"],
      ["100000", "phClr", "120000", void 0, void 0, "63000"],
      ["5400000", "0"]
    ])
  ]
});
var formatScheme = {
  getTemplate() {
    return {
      name: "a:fmtScheme",
      properties: {
        rawMap: {
          name: "Office"
        }
      },
      children: [
        getFillStyleList(),
        getLineStyleList(),
        getEffectStyleList(),
        getBgFillStyleList()
      ]
    };
  }
};
var formatScheme_default = formatScheme;
var themeElements = {
  getTemplate() {
    return {
      name: "a:themeElements",
      children: [
        colorScheme_default.getTemplate(),
        fontScheme_default.getTemplate(),
        formatScheme_default.getTemplate()
      ]
    };
  }
};
var themeElements_default = themeElements;
var officeTheme = {
  getTemplate() {
    return {
      name: "a:theme",
      properties: {
        prefixedAttributes: [{
          prefix: "xmlns:",
          map: {
            a: "http://schemas.openxmlformats.org/drawingml/2006/main"
          }
        }],
        rawMap: {
          name: "Office Theme"
        }
      },
      children: [
        themeElements_default.getTemplate(),
        {
          name: "a:objectDefaults"
        },
        {
          name: "a:extraClrSchemeLst"
        }
      ]
    };
  }
};
var office_default = officeTheme;
var buildSharedString = (strMap) => {
  const ret = [];
  for (const key of strMap.keys()) {
    const textNode = key.toString();
    const child = {
      name: "t",
      textNode: _40.escapeString(textNode)
    };
    const preserveSpaces = textNode.trim().length !== textNode.length;
    if (preserveSpaces) {
      child.properties = {
        rawMap: {
          "xml:space": "preserve"
        }
      };
    }
    ret.push({
      name: "si",
      children: [child]
    });
  }
  return ret;
};
var sharedStrings = {
  getTemplate(strings) {
    return {
      name: "sst",
      properties: {
        rawMap: {
          xmlns: "http://schemas.openxmlformats.org/spreadsheetml/2006/main",
          count: strings.size,
          uniqueCount: strings.size
        }
      },
      children: buildSharedString(strings)
    };
  }
};
var sharedStrings_default = sharedStrings;
function prepareString(str) {
  const split = str.split(/(\[[^\]]*\])/);
  for (let i = 0; i < split.length; i++) {
    let currentString = split[i];
    if (!currentString.length) {
      continue;
    }
    if (!currentString.startsWith("[")) {
      currentString = currentString.replace(/\$/g, '"$"');
    }
    split[i] = _212.escapeString(currentString);
  }
  return split.join("");
}
var numberFormatFactory = {
  getTemplate(numberFormat) {
    let { formatCode, numFmtId } = numberFormat;
    if (formatCode.length) {
      formatCode = prepareString(formatCode);
    }
    return {
      name: "numFmt",
      properties: {
        rawMap: {
          formatCode,
          numFmtId
        }
      }
    };
  }
};
var numberFormat_default = numberFormatFactory;
var numberFormatsFactory = {
  getTemplate(numberFormats) {
    return {
      name: "numFmts",
      properties: {
        rawMap: {
          count: numberFormats.length
        }
      },
      children: numberFormats.map((numberFormat) => numberFormat_default.getTemplate(numberFormat))
    };
  }
};
var numberFormats_default = numberFormatsFactory;
var fontFactory = {
  getTemplate(font) {
    const {
      size,
      colorTheme,
      color = "FF000000",
      fontName = "Calibri",
      family,
      scheme,
      italic,
      bold,
      strikeThrough,
      outline,
      shadow,
      underline,
      verticalAlign
    } = font;
    const children = [
      { name: "sz", properties: { rawMap: { val: size } } },
      { name: "color", properties: { rawMap: { theme: colorTheme, rgb: color } } },
      { name: "name", properties: { rawMap: { val: fontName } } }
    ];
    if (family) {
      children.push({ name: "family", properties: { rawMap: { val: family } } });
    }
    if (scheme) {
      children.push({ name: "scheme", properties: { rawMap: { val: scheme } } });
    }
    if (italic) {
      children.push({ name: "i" });
    }
    if (bold) {
      children.push({ name: "b" });
    }
    if (strikeThrough) {
      children.push({ name: "strike" });
    }
    if (outline) {
      children.push({ name: "outline" });
    }
    if (shadow) {
      children.push({ name: "shadow" });
    }
    if (underline) {
      children.push({ name: "u", properties: { rawMap: { val: underline } } });
    }
    if (verticalAlign) {
      children.push({ name: "vertAlign", properties: { rawMap: { val: verticalAlign } } });
    }
    return { name: "font", children };
  }
};
var font_default = fontFactory;
var fontsFactory = {
  getTemplate(fonts) {
    return {
      name: "fonts",
      properties: {
        rawMap: {
          count: fonts.length
        }
      },
      children: fonts.map((font) => font_default.getTemplate(font))
    };
  }
};
var fonts_default = fontsFactory;
var fillFactory = {
  getTemplate(fill) {
    const { patternType, fgTheme, fgTint, fgRgb, bgRgb, bgIndexed } = fill;
    const pf = {
      name: "patternFill",
      properties: {
        rawMap: {
          patternType
        }
      }
    };
    if (fgTheme || fgTint || fgRgb) {
      pf.children = [{
        name: "fgColor",
        properties: {
          rawMap: {
            theme: fgTheme,
            tint: fgTint,
            rgb: fgRgb
          }
        }
      }];
    }
    if (bgIndexed || bgRgb) {
      if (!pf.children) {
        pf.children = [];
      }
      pf.children.push({
        name: "bgColor",
        properties: {
          rawMap: {
            indexed: bgIndexed,
            rgb: bgRgb
          }
        }
      });
    }
    return {
      name: "fill",
      children: [pf]
    };
  }
};
var fill_default = fillFactory;
var fillsFactory = {
  getTemplate(fills) {
    return {
      name: "fills",
      properties: {
        rawMap: {
          count: fills.length
        }
      },
      children: fills.map((fill) => fill_default.getTemplate(fill))
    };
  }
};
var fills_default = fillsFactory;
var getWeightName = (value) => {
  switch (value) {
    case 1:
      return "thin";
    case 2:
      return "medium";
    case 3:
      return "thick";
    default:
      return "hair";
  }
};
var mappedBorderNames = {
  None: "None",
  Dot: "Dotted",
  Dash: "Dashed",
  Double: "Double",
  DashDot: "DashDot",
  DashDotDot: "DashDotDot",
  SlantDashDot: "SlantDashDot"
};
var mediumBorders = ["Dashed", "DashDot", "DashDotDot"];
var colorMap = {
  None: "none",
  Solid: "solid",
  Gray50: "mediumGray",
  Gray75: "darkGray",
  Gray25: "lightGray",
  HorzStripe: "darkHorizontal",
  VertStripe: "darkVertical",
  ReverseDiagStripe: "darkDown",
  DiagStripe: "darkUp",
  DiagCross: "darkGrid",
  ThickDiagCross: "darkTrellis",
  ThinHorzStripe: "lightHorizontal",
  ThinVertStripe: "lightVertical",
  ThinReverseDiagStripe: "lightDown",
  ThinDiagStripe: "lightUp",
  ThinHorzCross: "lightGrid",
  ThinDiagCross: "lightTrellis",
  Gray125: "gray125",
  Gray0625: "gray0625"
};
var horizontalAlignmentMap = {
  Automatic: "general",
  Left: "left",
  Center: "center",
  Right: "right",
  Fill: "fill",
  Justify: "justify",
  CenterAcrossSelection: "centerContinuous",
  Distributed: "distributed",
  JustifyDistributed: "justify"
};
var verticalAlignmentMap = {
  Automatic: void 0,
  Top: "top",
  Bottom: "bottom",
  Center: "center",
  Justify: "justify",
  Distributed: "distributed",
  JustifyDistributed: "justify"
};
var convertLegacyPattern = (name) => {
  if (!name) {
    return "none";
  }
  return colorMap[name] || name;
};
var convertLegacyColor = (color) => {
  if (color == void 0) {
    return color;
  }
  if (color.charAt(0) === "#") {
    color = color.substring(1);
  }
  return color.length === 6 ? "FF" + color : color;
};
var convertLegacyBorder = (type, weight) => {
  if (!type) {
    return "thin";
  }
  const namedWeight = getWeightName(weight);
  const mappedName = mappedBorderNames[type];
  if (type === "Continuous") {
    return namedWeight;
  }
  if (namedWeight === "medium" && mediumBorders.indexOf(mappedName) !== -1) {
    return `medium${mappedName}`;
  }
  return mappedName.charAt(0).toLowerCase() + mappedName.substring(1);
};
var convertLegacyHorizontalAlignment = (alignment) => {
  return horizontalAlignmentMap[alignment] || "general";
};
var convertLegacyVerticalAlignment = (alignment) => {
  return verticalAlignmentMap[alignment] || void 0;
};
var getBorderColor = (color) => {
  return {
    name: "color",
    properties: {
      rawMap: {
        rgb: convertLegacyColor(color || "#000000")
      }
    }
  };
};
var borderFactory = {
  getTemplate(border) {
    const { left, right, top, bottom, diagonal } = border;
    const leftChildren = left ? [getBorderColor(left.color)] : void 0;
    const rightChildren = right ? [getBorderColor(right.color)] : void 0;
    const topChildren = top ? [getBorderColor(top.color)] : void 0;
    const bottomChildren = bottom ? [getBorderColor(bottom.color)] : void 0;
    const diagonalChildren = diagonal ? [getBorderColor(diagonal.color)] : void 0;
    return {
      name: "border",
      children: [{
        name: "left",
        properties: { rawMap: { style: left && left.style } },
        children: leftChildren
      }, {
        name: "right",
        properties: { rawMap: { style: right && right.style } },
        children: rightChildren
      }, {
        name: "top",
        properties: { rawMap: { style: top && top.style } },
        children: topChildren
      }, {
        name: "bottom",
        properties: { rawMap: { style: bottom && bottom.style } },
        children: bottomChildren
      }, {
        name: "diagonal",
        properties: { rawMap: { style: diagonal && diagonal.style } },
        children: diagonalChildren
      }]
    };
  }
};
var border_default = borderFactory;
var bordersFactory = {
  getTemplate(borders) {
    return {
      name: "borders",
      properties: {
        rawMap: {
          count: borders.length
        }
      },
      children: borders.map((border) => border_default.getTemplate(border))
    };
  }
};
var borders_default = bordersFactory;
var getReadingOrderId = (readingOrder) => {
  const order = ["Context", "LeftToRight", "RightToLeft"];
  const pos = order.indexOf(readingOrder);
  return Math.max(pos, 0);
};
var alignmentFactory = {
  getTemplate(alignment) {
    const { horizontal, indent, readingOrder, rotate, shrinkToFit, vertical, wrapText } = alignment;
    return {
      name: "alignment",
      properties: {
        rawMap: {
          horizontal: horizontal && convertLegacyHorizontalAlignment(horizontal),
          indent,
          readingOrder: readingOrder && getReadingOrderId(readingOrder),
          textRotation: rotate,
          shrinkToFit,
          vertical: vertical && convertLegacyVerticalAlignment(vertical),
          wrapText
        }
      }
    };
  }
};
var alignment_default = alignmentFactory;
var protectionFactory = {
  getTemplate(protection) {
    const locked = protection.protected === false ? 0 : 1;
    const hidden = protection.hideFormula === true ? 1 : 0;
    return {
      name: "protection",
      properties: {
        rawMap: {
          hidden,
          locked
        }
      }
    };
  }
};
var protection_default = protectionFactory;
var xfFactory = {
  getTemplate(xf) {
    const { alignment, borderId, fillId, fontId, numFmtId, protection, quotePrefix, xfId } = xf;
    const children = [];
    if (alignment) {
      children.push(alignment_default.getTemplate(alignment));
    }
    if (protection) {
      children.push(protection_default.getTemplate(protection));
    }
    return {
      name: "xf",
      properties: {
        rawMap: {
          applyAlignment: alignment ? 1 : void 0,
          applyProtection: protection ? 1 : void 0,
          applyBorder: borderId ? 1 : void 0,
          applyFill: fillId ? 1 : void 0,
          borderId,
          fillId,
          applyFont: fontId ? 1 : void 0,
          fontId,
          applyNumberFormat: numFmtId ? 1 : void 0,
          numFmtId,
          quotePrefix: quotePrefix ? 1 : void 0,
          xfId
        }
      },
      children: children.length ? children : void 0
    };
  }
};
var xf_default = xfFactory;
var cellStylesXfsFactory = {
  getTemplate(xfs) {
    return {
      name: "cellStyleXfs",
      properties: {
        rawMap: {
          count: xfs.length
        }
      },
      children: xfs.map((xf) => xf_default.getTemplate(xf))
    };
  }
};
var cellStyleXfs_default = cellStylesXfsFactory;
var cellXfsFactory = {
  getTemplate(xfs) {
    return {
      name: "cellXfs",
      properties: {
        rawMap: {
          count: xfs.length
        }
      },
      children: xfs.map((xf) => xf_default.getTemplate(xf))
    };
  }
};
var cellXfs_default = cellXfsFactory;
var borderFactory2 = {
  getTemplate(cellStyle) {
    const { builtinId, name, xfId } = cellStyle;
    return {
      name: "cellStyle",
      properties: {
        rawMap: {
          builtinId,
          name,
          xfId
        }
      }
    };
  }
};
var cellStyle_default = borderFactory2;
var cellStylesFactory = {
  getTemplate(cellStyles) {
    return {
      name: "cellStyles",
      properties: {
        rawMap: {
          count: cellStyles.length
        }
      },
      children: cellStyles.map((cellStyle) => cellStyle_default.getTemplate(cellStyle))
    };
  }
};
var cellStyles_default = cellStylesFactory;
var stylesMap;
var registeredNumberFmts;
var registeredFonts;
var registeredFills;
var registeredBorders;
var registeredCellStyleXfs;
var registeredCellXfs;
var registeredCellStyles;
var currentSheet;
var getStyleName = (name, currentSheet2) => {
  if (name.indexOf("mixedStyle") !== -1 && currentSheet2 > 1) {
    name += `_${currentSheet2}`;
  }
  return name;
};
var resetStylesheetValues = () => {
  stylesMap = { base: 0 };
  registeredNumberFmts = [];
  registeredFonts = [{ fontName: "Calibri", colorTheme: "1", family: "2", scheme: "minor" }];
  registeredFills = [{ patternType: "none" }, { patternType: "gray125" }];
  registeredBorders = [{ left: void 0, right: void 0, top: void 0, bottom: void 0, diagonal: void 0 }];
  registeredCellStyleXfs = [{ borderId: 0, fillId: 0, fontId: 0, numFmtId: 0 }];
  registeredCellXfs = [{ borderId: 0, fillId: 0, fontId: 0, numFmtId: 0, xfId: 0 }];
  registeredCellStyles = [{ builtinId: 0, name: "Normal", xfId: 0 }];
};
var registerFill = (fill) => {
  const convertedPattern = convertLegacyPattern(fill.pattern);
  const convertedFillColor = convertLegacyColor(fill.color);
  const convertedPatternColor = convertLegacyColor(fill.patternColor);
  let pos = registeredFills.findIndex((currentFill) => {
    const { patternType, fgRgb, bgRgb } = currentFill;
    if (patternType != convertedPattern || fgRgb != convertedFillColor || bgRgb != convertedPatternColor) {
      return false;
    }
    return true;
  });
  if (pos === -1) {
    pos = registeredFills.length;
    registeredFills.push({ patternType: convertedPattern, fgRgb: convertedFillColor, bgRgb: convertedPatternColor });
  }
  return pos;
};
var registerNumberFmt = (format) => {
  if (numberFormatMap[format]) {
    return numberFormatMap[format];
  }
  let pos = registeredNumberFmts.findIndex((currentFormat) => currentFormat.formatCode === format);
  if (pos === -1) {
    pos = registeredNumberFmts.length + 164;
    registeredNumberFmts.push({ formatCode: format, numFmtId: pos });
  } else {
    pos = registeredNumberFmts[pos].numFmtId;
  }
  return pos;
};
var registerBorders = (borders) => {
  const { borderBottom, borderTop, borderLeft, borderRight } = borders;
  let bottomStyle;
  let topStyle;
  let leftStyle;
  let rightStyle;
  let bottomColor;
  let topColor;
  let leftColor;
  let rightColor;
  if (borderLeft) {
    leftStyle = convertLegacyBorder(borderLeft.lineStyle, borderLeft.weight);
    leftColor = convertLegacyColor(borderLeft.color);
  }
  if (borderRight) {
    rightStyle = convertLegacyBorder(borderRight.lineStyle, borderRight.weight);
    rightColor = convertLegacyColor(borderRight.color);
  }
  if (borderBottom) {
    bottomStyle = convertLegacyBorder(borderBottom.lineStyle, borderBottom.weight);
    bottomColor = convertLegacyColor(borderBottom.color);
  }
  if (borderTop) {
    topStyle = convertLegacyBorder(borderTop.lineStyle, borderTop.weight);
    topColor = convertLegacyColor(borderTop.color);
  }
  let pos = registeredBorders.findIndex((currentBorder) => {
    const { left, right, top, bottom } = currentBorder;
    if (!left && (leftStyle || leftColor)) {
      return false;
    }
    if (!right && (rightStyle || rightColor)) {
      return false;
    }
    if (!top && (topStyle || topColor)) {
      return false;
    }
    if (!bottom && (bottomStyle || bottomColor)) {
      return false;
    }
    const { style: clS, color: clC } = left || {};
    const { style: crS, color: crC } = right || {};
    const { style: ctS, color: ctC } = top || {};
    const { style: cbS, color: cbC } = bottom || {};
    if (clS != leftStyle || clC != leftColor) {
      return false;
    }
    if (crS != rightStyle || crC != rightColor) {
      return false;
    }
    if (ctS != topStyle || ctC != topColor) {
      return false;
    }
    if (cbS != bottomStyle || cbC != bottomColor) {
      return false;
    }
    return true;
  });
  if (pos === -1) {
    pos = registeredBorders.length;
    registeredBorders.push({
      left: {
        style: leftStyle,
        color: leftColor
      },
      right: {
        style: rightStyle,
        color: rightColor
      },
      top: {
        style: topStyle,
        color: topColor
      },
      bottom: {
        style: bottomStyle,
        color: bottomColor
      },
      diagonal: {
        style: void 0,
        color: void 0
      }
    });
  }
  return pos;
};
var registerFont = (font) => {
  const { fontName: name = "Calibri", color, size, bold, italic, outline, shadow, strikeThrough, underline, family, verticalAlign } = font;
  const convertedColor = convertLegacyColor(color);
  const familyId = getFontFamilyId(family);
  const convertedUnderline = underline ? underline.toLocaleLowerCase() : void 0;
  const convertedVerticalAlign = verticalAlign ? verticalAlign.toLocaleLowerCase() : void 0;
  let pos = registeredFonts.findIndex((currentFont) => {
    if (currentFont.fontName != name || currentFont.color != convertedColor || currentFont.size != size || currentFont.bold != bold || currentFont.italic != italic || currentFont.outline != outline || currentFont.shadow != shadow || currentFont.strikeThrough != strikeThrough || currentFont.underline != convertedUnderline || currentFont.verticalAlign != convertedVerticalAlign || // @ts-ignore
    currentFont.family != familyId) {
      return false;
    }
    return true;
  });
  if (pos === -1) {
    pos = registeredFonts.length;
    registeredFonts.push({
      fontName: name,
      color: convertedColor,
      size,
      bold,
      italic,
      outline,
      shadow,
      strikeThrough,
      underline: convertedUnderline,
      verticalAlign: convertedVerticalAlign,
      family: familyId != null ? familyId.toString() : void 0
    });
  }
  return pos;
};
var registerStyle = (config) => {
  const { alignment, borders, font, interior, numberFormat, protection, quotePrefix } = config;
  let { id } = config;
  let currentFill = 0;
  let currentBorder = 0;
  let currentFont = 0;
  let currentNumberFmt = 0;
  if (!id) {
    return;
  }
  id = getStyleName(id, currentSheet);
  if (stylesMap[id] != void 0) {
    return;
  }
  if (interior) {
    currentFill = registerFill(interior);
  }
  if (borders) {
    currentBorder = registerBorders(borders);
  }
  if (font) {
    currentFont = registerFont(font);
  }
  if (numberFormat) {
    currentNumberFmt = registerNumberFmt(numberFormat.format);
  }
  stylesMap[id] = registeredCellXfs.length;
  registeredCellXfs.push({
    alignment,
    borderId: currentBorder || 0,
    fillId: currentFill || 0,
    fontId: currentFont || 0,
    numFmtId: currentNumberFmt || 0,
    protection,
    quotePrefix,
    xfId: 0
  });
};
var stylesheetFactory = {
  getTemplate(defaultFontSize) {
    const numberFormats = numberFormats_default.getTemplate(registeredNumberFmts);
    const fonts = fonts_default.getTemplate(registeredFonts.map((font) => __spreadProps6(__spreadValues7({}, font), { size: font.size != null ? font.size : defaultFontSize })));
    const fills = fills_default.getTemplate(registeredFills);
    const borders = borders_default.getTemplate(registeredBorders);
    const cellStylesXfs = cellStyleXfs_default.getTemplate(registeredCellStyleXfs);
    const cellXfs = cellXfs_default.getTemplate(registeredCellXfs);
    const cellStyles = cellStyles_default.getTemplate(registeredCellStyles);
    resetStylesheetValues();
    return {
      name: "styleSheet",
      properties: {
        rawMap: {
          "mc:Ignorable": "x14ac x16r2 xr",
          "xmlns": "http://schemas.openxmlformats.org/spreadsheetml/2006/main",
          "xmlns:mc": "http://schemas.openxmlformats.org/markup-compatibility/2006",
          "xmlns:x14ac": "http://schemas.microsoft.com/office/spreadsheetml/2009/9/ac",
          "xmlns:x16r2": "http://schemas.microsoft.com/office/spreadsheetml/2015/02/main",
          "xmlns:xr": "http://schemas.microsoft.com/office/spreadsheetml/2014/revision"
        }
      },
      children: [
        numberFormats,
        fonts,
        fills,
        borders,
        cellStylesXfs,
        cellXfs,
        cellStyles,
        {
          name: "tableStyles",
          properties: {
            rawMap: {
              count: 0,
              defaultPivotStyle: "PivotStyleLight16",
              defaultTableStyle: "TableStyleMedium2"
            }
          }
        }
      ]
    };
  }
};
var getStyleId = (name, currentSheet2) => {
  return stylesMap[getStyleName(name, currentSheet2)] || 0;
};
var registerStyles = (styles, _currentSheet) => {
  currentSheet = _currentSheet;
  if (currentSheet === 1) {
    resetStylesheetValues();
  }
  styles.forEach(registerStyle);
};
var stylesheet_default = stylesheetFactory;
var sheetFactory = {
  getTemplate(name, idx) {
    const sheetId = (idx + 1).toString();
    return {
      name: "sheet",
      properties: {
        rawMap: {
          "name": name,
          "sheetId": sheetId,
          "r:id": `rId${sheetId}`
        }
      }
    };
  }
};
var sheet_default = sheetFactory;
var sheetsFactory = {
  getTemplate(names) {
    return {
      name: "sheets",
      children: names.map((sheet, idx) => sheet_default.getTemplate(sheet, idx))
    };
  }
};
var sheets_default = sheetsFactory;
var workbookFactory = {
  getTemplate(names) {
    return {
      name: "workbook",
      properties: {
        prefixedAttributes: [{
          prefix: "xmlns:",
          map: {
            r: "http://schemas.openxmlformats.org/officeDocument/2006/relationships"
          }
        }],
        rawMap: {
          xmlns: "http://schemas.openxmlformats.org/spreadsheetml/2006/main"
        }
      },
      children: [sheets_default.getTemplate(names)]
    };
  }
};
var workbook_default = workbookFactory;
var getExcelCellWidth = (width) => Math.ceil((width - 12) / 7 + 1);
var columnFactory = {
  getTemplate(config) {
    const { min, max, outlineLevel, s, width, hidden, bestFit } = config;
    let excelWidth = 1;
    let customWidth = "0";
    if (width > 1) {
      excelWidth = getExcelCellWidth(width);
      customWidth = "1";
    }
    return {
      name: "col",
      properties: {
        rawMap: {
          min,
          max,
          outlineLevel: outlineLevel != null ? outlineLevel : void 0,
          width: excelWidth,
          style: s,
          hidden: hidden ? "1" : "0",
          bestFit: bestFit ? "1" : "0",
          customWidth
        }
      }
    };
  }
};
var column_default = columnFactory;
var convertLegacyType = (type) => {
  const t = type.charAt(0).toLowerCase();
  return t === "s" ? "inlineStr" : t;
};
var cellFactory = {
  getTemplate(config, idx, currentSheet2) {
    const { ref, data, styleId } = config;
    const { type, value } = data || { type: "empty", value: null };
    let convertedType = type;
    if (type === "f") {
      convertedType = "str";
    } else if (type.charAt(0) === type.charAt(0).toUpperCase()) {
      convertedType = convertLegacyType(type);
    }
    const obj = {
      name: "c",
      properties: {
        rawMap: {
          r: ref,
          t: convertedType === "empty" ? void 0 : convertedType,
          s: styleId ? getStyleId(styleId, currentSheet2) : void 0
        }
      }
    };
    if (convertedType === "empty") {
      return obj;
    }
    let children;
    if (convertedType === "str" && type === "f") {
      children = [{
        name: "f",
        textNode: _311.escapeString(value)
      }];
    } else if (convertedType === "inlineStr") {
      children = [{
        name: "is",
        children: [{
          name: "t",
          textNode: _311.escapeString(value)
        }]
      }];
    } else {
      children = [{
        name: "v",
        textNode: value
      }];
    }
    return Object.assign({}, obj, { children });
  }
};
var cell_default = cellFactory;
var addEmptyCells = (cells, rowIdx) => {
  const mergeMap = [];
  let posCounter = 0;
  for (let i = 0; i < cells.length; i++) {
    const cell = cells[i];
    if (cell.mergeAcross) {
      mergeMap.push({
        pos: i,
        excelPos: posCounter
      });
      posCounter += cell.mergeAcross;
    }
    posCounter++;
  }
  if (mergeMap.length) {
    for (let i = mergeMap.length - 1; i >= 0; i--) {
      const mergedCells = [];
      const cell = cells[mergeMap[i].pos];
      for (let j = 1; j <= cell.mergeAcross; j++) {
        mergedCells.push({
          ref: `${getExcelColumnName(mergeMap[i].excelPos + 1 + j)}${rowIdx + 1}`,
          styleId: cell.styleId,
          data: { type: "empty", value: null }
        });
      }
      if (mergedCells.length) {
        cells.splice(mergeMap[i].pos + 1, 0, ...mergedCells);
      }
    }
  }
};
var shouldDisplayCell = (cell) => {
  var _a;
  return ((_a = cell.data) == null ? void 0 : _a.value) !== "" || cell.styleId !== void 0;
};
var rowFactory = {
  getTemplate(config, idx, currentSheet2) {
    const { collapsed, hidden, height, outlineLevel, cells = [] } = config;
    addEmptyCells(cells, idx);
    const children = cells.filter(shouldDisplayCell).map((cell, idx2) => cell_default.getTemplate(cell, idx2, currentSheet2));
    return {
      name: "row",
      properties: {
        rawMap: {
          r: idx + 1,
          collapsed: collapsed ? "1" : "0",
          hidden: hidden ? "1" : "0",
          ht: height,
          customHeight: height != null ? "1" : "0",
          spans: "1:1",
          outlineLevel: outlineLevel || void 0
        }
      },
      children
    };
  }
};
var row_default = rowFactory;
var mergeCellFactory = {
  getTemplate(ref) {
    return {
      name: "mergeCell",
      properties: {
        rawMap: {
          ref
        }
      }
    };
  }
};
var mergeCell_default = mergeCellFactory;
var getMergedCellsAndAddColumnGroups = (rows, cols, suppressColumnOutline) => {
  const mergedCells = [];
  const cellsWithCollapsibleGroups = [];
  rows.forEach((currentRow, rowIdx) => {
    const cells = currentRow.cells;
    let merges = 0;
    let lastCol;
    cells.forEach((currentCell, cellIdx) => {
      const min = cellIdx + merges + 1;
      const start = getExcelColumnName(min);
      const outputRow = rowIdx + 1;
      if (currentCell.mergeAcross) {
        merges += currentCell.mergeAcross;
        const end = getExcelColumnName(cellIdx + merges + 1);
        mergedCells.push(`${start}${outputRow}:${end}${outputRow}`);
      }
      if (!cols[min - 1]) {
        cols[min - 1] = {};
      }
      const { collapsibleRanges } = currentCell;
      if (collapsibleRanges) {
        collapsibleRanges.forEach((range) => {
          cellsWithCollapsibleGroups.push([min + range[0], min + range[1]]);
        });
      }
      lastCol = cols[min - 1];
      lastCol.min = min;
      lastCol.max = min;
      currentCell.ref = `${start}${outputRow}`;
    });
  });
  cellsWithCollapsibleGroups.sort((a, b) => {
    if (a[0] !== b[0]) {
      return a[0] - b[0];
    }
    return b[1] - a[1];
  });
  const rangeMap = /* @__PURE__ */ new Map();
  const outlineLevel = /* @__PURE__ */ new Map();
  cellsWithCollapsibleGroups.filter((currentRange) => {
    const rangeString = currentRange.toString();
    const inMap = rangeMap.get(rangeString);
    if (inMap) {
      return false;
    }
    rangeMap.set(rangeString, true);
    return true;
  }).forEach((range) => {
    const refCol = cols.find((col) => col.min == range[0] && col.max == range[1]);
    const currentOutlineLevel = outlineLevel.get(range[0]);
    cols.push({
      min: range[0],
      max: range[1],
      outlineLevel: suppressColumnOutline ? void 0 : currentOutlineLevel || 1,
      width: (refCol || { width: 100 }).width
    });
    outlineLevel.set(range[0], (currentOutlineLevel || 0) + 1);
  });
  return mergedCells;
};
var getPageOrientation = (orientation) => {
  if (!orientation || orientation !== "Portrait" && orientation !== "Landscape") {
    return "portrait";
  }
  return orientation.toLocaleLowerCase();
};
var getPageSize = (pageSize) => {
  if (pageSize == null) {
    return 1;
  }
  const positions = ["Letter", "Letter Small", "Tabloid", "Ledger", "Legal", "Statement", "Executive", "A3", "A4", "A4 Small", "A5", "A6", "B4", "B5", "Folio", "Envelope", "Envelope DL", "Envelope C5", "Envelope B5", "Envelope C3", "Envelope C4", "Envelope C6", "Envelope Monarch", "Japanese Postcard", "Japanese Double Postcard"];
  const pos = positions.indexOf(pageSize);
  return pos === -1 ? 1 : pos + 1;
};
var addColumns = (columns) => {
  return (params) => {
    if (columns.length) {
      params.children.push({
        name: "cols",
        children: columns.map((column) => column_default.getTemplate(column))
      });
    }
    return params;
  };
};
var addSheetData = (rows, sheetNumber) => {
  return (params) => {
    if (rows.length) {
      params.children.push({
        name: "sheetData",
        children: rows.map((row, idx) => row_default.getTemplate(row, idx, sheetNumber))
      });
    }
    return params;
  };
};
var addMergeCells = (mergeCells) => {
  return (params) => {
    if (mergeCells.length) {
      params.children.push({
        name: "mergeCells",
        properties: {
          rawMap: {
            count: mergeCells.length
          }
        },
        children: mergeCells.map((mergedCell) => mergeCell_default.getTemplate(mergedCell))
      });
    }
    return params;
  };
};
var addPageMargins = (margins) => {
  return (params) => {
    const { top = 0.75, right = 0.7, bottom = 0.75, left = 0.7, header = 0.3, footer = 0.3 } = margins;
    params.children.push({
      name: "pageMargins",
      properties: {
        rawMap: { bottom, footer, header, left, right, top }
      }
    });
    return params;
  };
};
var addPageSetup = (pageSetup) => {
  return (params) => {
    if (pageSetup) {
      params.children.push({
        name: "pageSetup",
        properties: {
          rawMap: {
            horizontalDpi: 0,
            verticalDpi: 0,
            orientation: getPageOrientation(pageSetup.orientation),
            paperSize: getPageSize(pageSetup.pageSize)
          }
        }
      });
    }
    return params;
  };
};
var replaceHeaderFooterTokens = (value) => {
  const map = {
    "&[Page]": "&P",
    "&[Pages]": "&N",
    "&[Date]": "&D",
    "&[Time]": "&T",
    "&[Tab]": "&A",
    "&[Path]": "&Z",
    "&[File]": "&F",
    "&[Picture]": "&G"
  };
  _46.iterateObject(map, (key, val) => {
    value = value.replace(key, val);
  });
  return value;
};
var getHeaderPosition = (position) => {
  if (position === "Center") {
    return "C";
  }
  if (position === "Right") {
    return "R";
  }
  return "L";
};
var applyHeaderFontStyle = (headerString, font) => {
  if (!font) {
    return headerString;
  }
  headerString += "&amp;&quot;";
  headerString += font.fontName || "Calibri";
  if (font.bold !== font.italic) {
    headerString += font.bold ? ",Bold" : ",Italic";
  } else if (font.bold) {
    headerString += ",Bold Italic";
  } else {
    headerString += ",Regular";
  }
  headerString += "&quot;";
  if (font.size) {
    headerString += `&amp;${font.size}`;
  }
  if (font.strikeThrough) {
    headerString += "&amp;S";
  }
  if (font.underline) {
    headerString += `&amp;${font.underline === "Double" ? "E" : "U"}`;
  }
  if (font.color) {
    headerString += `&amp;K${font.color.replace("#", "").toUpperCase()}`;
  }
  return headerString;
};
var processHeaderFooterContent = (content, location, rule) => content.reduce((prev, curr, idx) => {
  const pos = getHeaderPosition(curr.position);
  const output = applyHeaderFontStyle(`${prev}&amp;${pos}`, curr.font);
  const PositionMap = ["Left", "Center", "Right"];
  if (!curr.position) {
    curr.position = PositionMap[idx];
  }
  const { image } = curr;
  if (curr.value === "&[Picture]" && image) {
    const imagePosition = `${pos}${location}${rule}`;
    ExcelXlsxFactory.addHeaderFooterImageToMap(image, imagePosition);
  }
  return `${output}${_46.escapeString(replaceHeaderFooterTokens(curr.value))}`;
}, "");
var buildHeaderFooter = (headerFooterConfig) => {
  const rules = ["all", "first", "even"];
  const headersAndFooters = [];
  rules.forEach((rule) => {
    const headerFooter = headerFooterConfig[rule];
    const namePrefix = rule === "all" ? "odd" : rule;
    if (!headerFooter) {
      return;
    }
    for (const [key, value] of Object.entries(headerFooter)) {
      const nameSuffix = `${key.charAt(0).toUpperCase()}${key.slice(1)}`;
      const location = key[0].toUpperCase();
      if (value) {
        const normalizedRule = rule === "all" ? "" : rule.toUpperCase();
        headersAndFooters.push({
          name: `${namePrefix}${nameSuffix}`,
          properties: {
            rawMap: { "xml:space": "preserve" }
          },
          textNode: processHeaderFooterContent(value, location, normalizedRule)
        });
      }
    }
  });
  return headersAndFooters;
};
var addHeaderFooter = (headerFooterConfig) => {
  return (params) => {
    if (!headerFooterConfig) {
      return params;
    }
    const differentFirst = headerFooterConfig.first != null ? 1 : 0;
    const differentOddEven = headerFooterConfig.even != null ? 1 : 0;
    params.children.push({
      name: "headerFooter",
      properties: {
        rawMap: {
          differentFirst,
          differentOddEven
        }
      },
      children: buildHeaderFooter(headerFooterConfig)
    });
    return params;
  };
};
var addExcelTableRel = (excelTable) => {
  return (params) => {
    if (excelTable) {
      params.children.push({
        name: "tableParts",
        properties: {
          rawMap: {
            count: "1"
          }
        },
        children: [{
          name: "tablePart",
          properties: {
            rawMap: {
              "r:id": `rId${++params.rIdCounter}`
            }
          }
        }]
      });
    }
    return params;
  };
};
var addDrawingRel = (currentSheet2) => {
  return (params) => {
    const worksheetImages = ExcelXlsxFactory.worksheetImages.get(currentSheet2);
    if (worksheetImages == null ? void 0 : worksheetImages.length) {
      params.children.push({
        name: "drawing",
        properties: {
          rawMap: {
            "r:id": `rId${++params.rIdCounter}`
          }
        }
      });
    }
    return params;
  };
};
var addVmlDrawingRel = (currentSheet2) => {
  return (params) => {
    if (ExcelXlsxFactory.worksheetHeaderFooterImages.get(currentSheet2)) {
      params.children.push({
        name: "legacyDrawingHF",
        properties: {
          rawMap: {
            "r:id": `rId${++params.rIdCounter}`
          }
        }
      });
    }
    return params;
  };
};
var addSheetPr = () => {
  return (params) => {
    params.children.push({
      name: "sheetPr",
      children: [{
        name: "outlinePr",
        properties: {
          rawMap: {
            summaryBelow: 0
          }
        }
      }]
    });
    return params;
  };
};
var addSheetFormatPr = (rows) => {
  return (params) => {
    const maxOutline = rows.reduce((prev, row) => {
      if (row.outlineLevel && row.outlineLevel > prev) {
        return row.outlineLevel;
      }
      return prev;
    }, 0);
    params.children.push({
      name: "sheetFormatPr",
      properties: {
        rawMap: {
          baseColWidth: 10,
          defaultRowHeight: 16,
          outlineLevelRow: maxOutline ? maxOutline : void 0
        }
      }
    });
    return params;
  };
};
var worksheetFactory = {
  getTemplate(params) {
    const { worksheet, currentSheet: currentSheet2, config } = params;
    const { margins = {}, pageSetup, headerFooterConfig, suppressColumnOutline } = config;
    const { table } = worksheet;
    const { rows, columns } = table;
    const mergedCells = columns && columns.length ? getMergedCellsAndAddColumnGroups(rows, columns, !!suppressColumnOutline) : [];
    const { worksheetDataTables } = ExcelXlsxFactory;
    const worksheetExcelTables = worksheetDataTables.get(currentSheet2);
    const createWorksheetChildren = _46.compose(
      addSheetPr(),
      addSheetFormatPr(rows),
      addColumns(columns),
      addSheetData(rows, currentSheet2 + 1),
      addMergeCells(mergedCells),
      addPageMargins(margins),
      addPageSetup(pageSetup),
      addHeaderFooter(headerFooterConfig),
      addDrawingRel(currentSheet2),
      addVmlDrawingRel(currentSheet2),
      addExcelTableRel(worksheetExcelTables)
    );
    const { children } = createWorksheetChildren({ children: [], rIdCounter: 0 });
    return {
      name: "worksheet",
      properties: {
        prefixedAttributes: [{
          prefix: "xmlns:",
          map: {
            r: "http://schemas.openxmlformats.org/officeDocument/2006/relationships"
          }
        }],
        rawMap: {
          xmlns: "http://schemas.openxmlformats.org/spreadsheetml/2006/main"
        }
      },
      children
    };
  }
};
var worksheet_default = worksheetFactory;
var relationshipFactory = {
  getTemplate(config) {
    const { Id, Type, Target } = config;
    return {
      name: "Relationship",
      properties: {
        rawMap: {
          Id,
          Type,
          Target
        }
      }
    };
  }
};
var relationship_default = relationshipFactory;
var relationshipsFactory = {
  getTemplate(c) {
    const children = c.map((relationship) => relationship_default.getTemplate(relationship));
    return {
      name: "Relationships",
      properties: {
        rawMap: {
          xmlns: "http://schemas.openxmlformats.org/package/2006/relationships"
        }
      },
      children
    };
  }
};
var relationships_default = relationshipsFactory;
var getShapeLayout = () => ({
  name: "o:shapelayout",
  properties: {
    prefixedAttributes: [{
      prefix: "v:",
      map: {
        ext: "edit"
      }
    }]
  },
  children: [
    {
      name: "o:idmap",
      properties: {
        prefixedAttributes: [{
          prefix: "v:",
          map: {
            ext: "edit"
          }
        }],
        rawMap: {
          data: "1"
        }
      }
    }
  ]
});
var getStroke = () => ({
  name: "v:stroke",
  properties: {
    rawMap: {
      joinstyle: "miter"
    }
  }
});
var getFormulas = (formulas) => ({
  name: "v:formulas",
  children: formulas.map((formula) => ({
    name: "v:f",
    properties: {
      rawMap: {
        eqn: formula
      }
    }
  }))
});
var getPath = () => ({
  name: "v:path",
  properties: {
    prefixedAttributes: [{
      prefix: "o:",
      map: {
        connecttype: "rect",
        extrusionok: "f"
      }
    }],
    rawMap: {
      gradientshapeok: "t"
    }
  }
});
var getLock = (params) => {
  const { aspectratio, rotation } = params || {};
  const rawMap = {};
  if (aspectratio) {
    rawMap.aspectratio = "t";
  }
  if (rotation) {
    rawMap.rotation = "t";
  }
  return {
    name: "o:lock",
    properties: {
      prefixedAttributes: [{
        prefix: "v:",
        map: {
          ext: "edit"
        }
      }],
      rawMap
    }
  };
};
function mapNumber(value, startSource, endSource, startTarget, endTarget) {
  return (value - startSource) / (endSource - startSource) * (endTarget - startTarget) + startTarget;
}
var gainMap = {
  0: "0",
  5: "6554f",
  10: "13107f",
  15: "19661f",
  20: "26214f",
  25: ".5",
  30: "39322f",
  35: "45875f",
  40: "52429f",
  45: "58982f",
  50: "1",
  55: "72818f",
  60: "1.25",
  65: "93623f",
  70: "109227f",
  75: "2",
  80: "2.5",
  85: "3.4",
  90: "5",
  95: "10",
  96: "12.5",
  97: "1092267f",
  98: "25",
  99: "50",
  100: "2147483647f"
};
var getImageData = (image, idx) => {
  var _a;
  let rawMap;
  const { recolor, brightness, contrast, id } = image;
  if (recolor) {
    rawMap = {};
    if (recolor === "Washout" || recolor === "Grayscale") {
      rawMap.gain = "19661f";
      rawMap.blacklevel = "22938f";
    }
    if (recolor === "Black & White" || recolor === "Grayscale") {
      rawMap.grayscale = "t";
      if (recolor === "Black & White") {
        rawMap.bilevel = "t";
      }
    }
  }
  if (!recolor || recolor === "Grayscale") {
    if (!rawMap) {
      rawMap = {};
    }
    if (contrast != null && contrast !== 50) {
      rawMap.gain = (_a = gainMap[contrast]) != null ? _a : "1";
    }
    if (brightness != null && brightness !== 50) {
      rawMap.blacklevel = mapNumber(brightness, 0, 100, -0.5, 0.5).toString();
    }
  }
  return {
    name: "v:imagedata",
    properties: {
      prefixedAttributes: [{
        prefix: "o:",
        map: {
          relid: `rId${idx}`,
          title: id
        }
      }],
      rawMap
    }
  };
};
var getShapeType = () => {
  const formulas = [
    "if lineDrawn pixelLineWidth 0",
    "sum @0 1 0",
    "sum 0 0 @1",
    "prod @2 1 2",
    "prod @3 21600 pixelWidth",
    "prod @3 21600 pixelHeight",
    "sum @0 0 1",
    "prod @6 1 2",
    "prod @7 21600 pixelWidth",
    "sum @8 21600 0",
    "prod @7 21600 pixelHeight",
    "sum @10 21600 0"
  ];
  return {
    name: "v:shapetype",
    properties: {
      prefixedAttributes: [{
        prefix: "o:",
        map: {
          spt: "75",
          preferrelative: "t"
        }
      }],
      rawMap: {
        coordsize: "21600,21600",
        filled: "f",
        id: "_x0000_t75",
        path: "m@4@5l@4@11@9@11@9@5xe",
        stroked: "f"
      }
    },
    children: [
      getStroke(),
      getFormulas(formulas),
      getPath(),
      getLock({ aspectratio: true })
    ]
  };
};
var pixelToPoint = (value) => Math.floor((value != null ? value : 0) * 0.74999943307122);
var getShape = (image, idx) => {
  const { width = 0, height = 0, altText } = image;
  const imageWidth = pixelToPoint(width);
  const imageHeight = pixelToPoint(height);
  return {
    name: "v:shape",
    properties: {
      rawMap: {
        id: image.headerFooterPosition,
        "o:spid": "_x0000_s1025",
        style: `position: absolute; margin-left: 0; margin-top: 10in; margin-bottom: 0; margin-right: 0; width: ${imageWidth}pt; height: ${imageHeight}pt; z-index: ${idx + 1}`,
        type: "#_x0000_t75",
        alt: altText
      }
    },
    children: [
      getImageData(image, idx + 1),
      getLock({ rotation: true })
    ]
  };
};
var vmlDrawingFactory = {
  getTemplate(params) {
    const headerFooterImages = ExcelXlsxFactory.worksheetHeaderFooterImages.get(params.sheetIndex) || [];
    const children = [getShapeLayout(), getShapeType(), ...headerFooterImages.map((img, idx) => getShape(img, idx))];
    return {
      name: "xml",
      properties: {
        prefixedAttributes: [{
          prefix: "xmlns:",
          map: {
            v: "urn:schemas-microsoft-com:vml",
            o: "urn:schemas-microsoft-com:office:office",
            x: "urn:schemas-microsoft-com:office:excel"
          }
        }]
      },
      children
    };
  }
};
var vmlDrawing_default = vmlDrawingFactory;
var _ExcelXlsxFactory = class _ExcelXlsxFactory2 {
  static createExcel(styles, worksheet, config) {
    this.addSheetName(worksheet);
    registerStyles(styles, this.sheetNames.length);
    let newConfig = Object.assign({}, config);
    if (config.exportAsExcelTable) {
      if (config.columnModel.isPivotActive()) {
        this.showExcelTableNonCompatibleFeaturesWarning("pivot mode");
        newConfig.exportAsExcelTable = false;
      }
      if (config.gos.get("masterDetail")) {
        this.showExcelTableNonCompatibleFeaturesWarning("master/detail");
        newConfig.exportAsExcelTable = false;
      }
    }
    this.processTableConfig(worksheet, newConfig);
    return this.createWorksheet(worksheet, newConfig);
  }
  static showExcelTableNonCompatibleFeaturesWarning(featureName) {
    console.warn(
      `AG Grid: Excel table export does not work with ${featureName}. The exported Excel file will not contain any Excel tables.
Please turn off ${featureName} to enable Excel table exports.`
    );
  }
  static getTableNameFromIndex(idx) {
    return `table${idx + 1}`;
  }
  static getSanitizedTableName(name) {
    return name.replace(/^[^a-zA-Z_]+/, "_").replace(/\s/g, "_").replace(/[^a-zA-Z0-9_]/g, "_");
  }
  static addTableToSheet(sheetIndex, table) {
    if (this.worksheetDataTables.has(sheetIndex)) {
      console.warn("Unable to add data table to Excel sheet: A table already exists.");
      return;
    }
    this.worksheetDataTables.set(sheetIndex, table);
  }
  static processTableConfig(worksheet, config) {
    var _a;
    if (!config.exportAsExcelTable) {
      return;
    }
    const tableConfig = typeof config.exportAsExcelTable === "boolean" ? {} : config.exportAsExcelTable;
    const {
      name: nameFromConfig,
      showColumnStripes,
      showRowStripes,
      showFilterButton,
      highlightFirstColumn,
      highlightLastColumn
    } = tableConfig;
    const tableName = this.getSanitizedTableName(
      nameFromConfig || _ExcelXlsxFactory2.defaultTableDisplayName
    );
    const sheetIndex = this.sheetNames.length - 1;
    const { table } = worksheet;
    const { rows, columns } = table;
    const headerRowCount = config.columnModel.getHeaderRowCount();
    const tableHeaderRowIndex = headerRowCount - 1;
    const tableRowCount = rows.length;
    const tableColCount = columns.length;
    const tableColumns = [];
    const showFilterButtons = [];
    for (let i = 0; i < tableColCount; i++) {
      const col = columns[i];
      tableColumns.push(col.displayName || "");
      showFilterButtons.push(
        showFilterButton === "match" || showFilterButton === void 0 ? (_a = col.filterAllowed) != null ? _a : false : showFilterButton
      );
    }
    if (!tableColumns || !tableColumns.length || !tableRowCount || !tableName) {
      console.warn("Unable to add data table to Excel sheet: Missing required parameters.");
      return;
    }
    this.addTableToSheet(sheetIndex, {
      name: this.getTableNameFromIndex(sheetIndex),
      displayName: tableName,
      columns: tableColumns,
      showFilterButtons,
      headerRowIndex: tableHeaderRowIndex,
      rowCount: tableRowCount - headerRowCount,
      showRowStripes: showRowStripes != null ? showRowStripes : true,
      showColumnStripes: showColumnStripes != null ? showColumnStripes : false,
      highlightFirstColumn: highlightFirstColumn != null ? highlightFirstColumn : false,
      highlightLastColumn: highlightLastColumn != null ? highlightLastColumn : false
    });
  }
  static addHeaderFooterImageToMap(image, position) {
    const sheetIndex = this.sheetNames.length - 1;
    const headerFooterImage = image;
    headerFooterImage.headerFooterPosition = position;
    this.buildImageMap({ imageToAdd: headerFooterImage, idx: sheetIndex });
    let headerFooterImagesForSheet = this.worksheetHeaderFooterImages.get(sheetIndex);
    if (!headerFooterImagesForSheet) {
      headerFooterImagesForSheet = [];
      this.worksheetHeaderFooterImages.set(sheetIndex, headerFooterImagesForSheet);
    }
    if (!headerFooterImagesForSheet.find((img) => img.id === image.id)) {
      headerFooterImagesForSheet.push(image);
    }
  }
  static addBodyImageToMap(image, rowIndex, col, columnsToExport, rowHeight) {
    let sheetIndex = this.sheetNames.length;
    const { row, column } = image.position || {};
    const calculatedImage = image;
    if (columnsToExport) {
      if (rowIndex != null && col != null && (!row || !column)) {
        if (!image.position) {
          image.position = {};
        }
        image.position = Object.assign({}, image.position, {
          row: rowIndex,
          column: columnsToExport.indexOf(col) + 1
        });
      }
      setExcelImageTotalWidth(calculatedImage, columnsToExport);
      setExcelImageTotalHeight(calculatedImage, rowHeight);
    }
    this.buildImageMap({ imageToAdd: calculatedImage, idx: sheetIndex });
    let worksheetImageIdMap = this.worksheetImageIds.get(sheetIndex);
    if (!worksheetImageIdMap) {
      worksheetImageIdMap = /* @__PURE__ */ new Map();
      this.worksheetImageIds.set(sheetIndex, worksheetImageIdMap);
    }
    const sheetImages = this.worksheetImages.get(sheetIndex);
    if (!sheetImages) {
      this.worksheetImages.set(sheetIndex, [calculatedImage]);
    } else {
      sheetImages.push(calculatedImage);
    }
    if (!worksheetImageIdMap.get(image.id)) {
      worksheetImageIdMap.set(image.id, { index: worksheetImageIdMap.size, type: image.imageType });
    }
  }
  static buildImageMap(params) {
    const { imageToAdd, idx } = params;
    const mappedImagesToSheet = this.images.get(imageToAdd.id);
    if (mappedImagesToSheet) {
      const currentSheetImages = mappedImagesToSheet.find((currentImage) => currentImage.sheetId === idx);
      if (currentSheetImages) {
        currentSheetImages.image.push(imageToAdd);
      } else {
        mappedImagesToSheet.push({
          sheetId: idx,
          image: [imageToAdd]
        });
      }
    } else {
      this.images.set(imageToAdd.id, [{ sheetId: idx, image: [imageToAdd] }]);
      this.workbookImageIds.set(imageToAdd.id, { type: imageToAdd.imageType, index: this.workbookImageIds.size });
    }
  }
  static addSheetName(worksheet) {
    const name = _55.escapeString(worksheet.name) || "";
    let append = "";
    while (this.sheetNames.indexOf(`${name}${append}`) !== -1) {
      if (append === "") {
        append = "_1";
      } else {
        const curr = parseInt(append.slice(1), 10);
        append = `_${curr + 1}`;
      }
    }
    worksheet.name = `${name}${append}`;
    this.sheetNames.push(worksheet.name);
  }
  static getStringPosition(str) {
    if (this.sharedStrings.has(str)) {
      return this.sharedStrings.get(str);
    }
    this.sharedStrings.set(str, this.sharedStrings.size);
    return this.sharedStrings.size - 1;
  }
  static resetFactory() {
    this.sharedStrings = /* @__PURE__ */ new Map();
    this.images = /* @__PURE__ */ new Map();
    this.worksheetImages = /* @__PURE__ */ new Map();
    this.worksheetHeaderFooterImages = /* @__PURE__ */ new Map();
    this.workbookImageIds = /* @__PURE__ */ new Map();
    this.worksheetImageIds = /* @__PURE__ */ new Map();
    this.worksheetDataTables = /* @__PURE__ */ new Map();
    this.sheetNames = [];
    this.factoryMode = ExcelFactoryMode.SINGLE_SHEET;
  }
  static createWorkbook() {
    return createXmlPart(workbook_default.getTemplate(this.sheetNames));
  }
  static createStylesheet(defaultFontSize) {
    return createXmlPart(stylesheet_default.getTemplate(defaultFontSize));
  }
  static createSharedStrings() {
    return createXmlPart(sharedStrings_default.getTemplate(this.sharedStrings));
  }
  static createCore(author) {
    return createXmlPart(core_default.getTemplate(author));
  }
  static createContentTypes(sheetLen) {
    return createXmlPart(contentTypes_default.getTemplate(sheetLen));
  }
  static createRels() {
    const rs = relationships_default.getTemplate([{
      Id: "rId1",
      Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/officeDocument",
      Target: "xl/workbook.xml"
    }, {
      Id: "rId2",
      Type: "http://schemas.openxmlformats.org/package/2006/relationships/metadata/core-properties",
      Target: "docProps/core.xml"
    }]);
    return createXmlPart(rs);
  }
  static createTheme() {
    return createXmlPart(office_default.getTemplate());
  }
  static createTable(dataTable, index) {
    return createXmlPart(table_default.getTemplate(dataTable, index));
  }
  static createWorkbookRels(sheetLen) {
    const worksheets = new Array(sheetLen).fill(void 0).map((v, i) => ({
      Id: `rId${i + 1}`,
      Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/worksheet",
      Target: `worksheets/sheet${i + 1}.xml`
    }));
    const rs = relationships_default.getTemplate([
      ...worksheets,
      {
        Id: `rId${sheetLen + 1}`,
        Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/theme",
        Target: "theme/theme1.xml"
      },
      {
        Id: `rId${sheetLen + 2}`,
        Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/styles",
        Target: "styles.xml"
      },
      {
        Id: `rId${sheetLen + 3}`,
        Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/sharedStrings",
        Target: "sharedStrings.xml"
      }
    ]);
    return createXmlPart(rs);
  }
  static createDrawing(sheetIndex) {
    return createXmlPart(drawing_default.getTemplate({ sheetIndex }));
  }
  static createDrawingRel(sheetIndex) {
    const worksheetImageIds = this.worksheetImageIds.get(sheetIndex) || [];
    const XMLArr = [];
    for (const [key, value] of worksheetImageIds) {
      XMLArr.push({
        Id: `rId${value.index + 1}`,
        Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/image",
        Target: `../media/image${this.workbookImageIds.get(key).index + 1}.${value.type}`
      });
    }
    return createXmlPart(relationships_default.getTemplate(XMLArr));
  }
  static createVmlDrawing(sheetIndex) {
    return createXmlPart(vmlDrawing_default.getTemplate({ sheetIndex }), true);
  }
  static createVmlDrawingRel(sheetIndex) {
    const worksheetHeaderFooterImages = this.worksheetHeaderFooterImages.get(sheetIndex) || [];
    const XMLArr = [];
    for (let i = 0; i < worksheetHeaderFooterImages.length; i++) {
      const headerFooterImage = worksheetHeaderFooterImages[i];
      const workbookImage = this.workbookImageIds.get(headerFooterImage.id);
      if (!workbookImage) {
        continue;
      }
      const { index, type } = workbookImage;
      const imageType = type === "jpg" ? "jpeg" : type;
      XMLArr.push({
        Id: `rId${i + 1}`,
        Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/image",
        Target: `../media/image${index + 1}.${imageType}`
      });
    }
    return createXmlPart(relationships_default.getTemplate(XMLArr));
  }
  static createRelationships({
    drawingIndex,
    vmlDrawingIndex,
    tableIndex
  } = {}) {
    if (drawingIndex === void 0 && vmlDrawingIndex === void 0 && tableIndex === void 0) {
      return "";
    }
    const config = [];
    if (drawingIndex != null) {
      config.push({
        Id: `rId${config.length + 1}`,
        Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/drawing",
        Target: `../drawings/drawing${drawingIndex + 1}.xml`
      });
    }
    if (vmlDrawingIndex != null) {
      config.push({
        Id: `rId${config.length + 1}`,
        Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/vmlDrawing",
        Target: `../drawings/vmlDrawing${vmlDrawingIndex + 1}.vml`
      });
    }
    if (tableIndex != null) {
      config.push({
        Id: `rId${config.length + 1}`,
        Type: "http://schemas.openxmlformats.org/officeDocument/2006/relationships/table",
        Target: `../tables/${this.getTableNameFromIndex(tableIndex)}.xml`
      });
    }
    const rs = relationships_default.getTemplate(config);
    return createXmlPart(rs);
  }
  static createWorksheet(worksheet, config) {
    return createXmlPart(worksheet_default.getTemplate({
      worksheet,
      currentSheet: this.sheetNames.length - 1,
      config
    }));
  }
};
_ExcelXlsxFactory.sharedStrings = /* @__PURE__ */ new Map();
_ExcelXlsxFactory.sheetNames = [];
_ExcelXlsxFactory.images = /* @__PURE__ */ new Map();
_ExcelXlsxFactory.worksheetImages = /* @__PURE__ */ new Map();
_ExcelXlsxFactory.worksheetHeaderFooterImages = /* @__PURE__ */ new Map();
_ExcelXlsxFactory.workbookImageIds = /* @__PURE__ */ new Map();
_ExcelXlsxFactory.worksheetImageIds = /* @__PURE__ */ new Map();
_ExcelXlsxFactory.worksheetDataTables = /* @__PURE__ */ new Map();
_ExcelXlsxFactory.defaultTableDisplayName = "AG-GRID-TABLE";
_ExcelXlsxFactory.factoryMode = ExcelFactoryMode.SINGLE_SHEET;
var ExcelXlsxFactory = _ExcelXlsxFactory;
var ExcelSerializingSession = class extends BaseGridSerializingSession {
  constructor(config) {
    super(config);
    this.mixedStyles = {};
    this.mixedStyleCounter = 0;
    this.rows = [];
    this.config = Object.assign({}, config);
    this.stylesByIds = {};
    this.config.baseExcelStyles.forEach((style) => {
      this.stylesByIds[style.id] = style;
    });
    this.excelStyles = [...this.config.baseExcelStyles, { id: "_quotePrefix", quotePrefix: 1 }];
  }
  addCustomContent(customContent) {
    customContent.forEach((row) => {
      const rowLen = this.rows.length + 1;
      let outlineLevel;
      if (!this.config.suppressRowOutline && row.outlineLevel != null) {
        outlineLevel = row.outlineLevel;
      }
      const rowObj = {
        height: getHeightFromProperty(rowLen, row.height || this.config.rowHeight),
        cells: (row.cells || []).map((cell, idx) => {
          var _a, _b, _c;
          const image = this.addImage(rowLen, this.columnsToExport[idx], (_a = cell.data) == null ? void 0 : _a.value);
          let excelStyles = null;
          if (cell.styleId) {
            excelStyles = typeof cell.styleId === "string" ? [cell.styleId] : cell.styleId;
          }
          const excelStyleId = this.getStyleId(excelStyles);
          if (image) {
            return this.createCell(excelStyleId, this.getDataTypeForValue(image.value), image.value == null ? "" : image.value);
          }
          const value = (_c = (_b = cell.data) == null ? void 0 : _b.value) != null ? _c : "";
          const type = this.getDataTypeForValue(value);
          if (cell.mergeAcross) {
            return this.createMergedCell(excelStyleId, type, value, cell.mergeAcross);
          }
          return this.createCell(excelStyleId, type, value);
        }),
        outlineLevel
      };
      if (row.collapsed != null) {
        rowObj.collapsed = row.collapsed;
      }
      if (row.hidden != null) {
        rowObj.hidden = row.hidden;
      }
      this.rows.push(rowObj);
    });
  }
  onNewHeaderGroupingRow() {
    const currentCells = [];
    this.rows.push({
      cells: currentCells,
      height: getHeightFromProperty(this.rows.length + 1, this.config.headerRowHeight)
    });
    return {
      onColumn: (columnGroup, header, index, span, collapsibleRanges) => {
        const styleIds = this.config.styleLinker({ rowType: RowType.HEADER_GROUPING, rowIndex: 1, value: `grouping-${header}`, columnGroup });
        currentCells.push(__spreadProps6(__spreadValues7({}, this.createMergedCell(this.getStyleId(styleIds), this.getDataTypeForValue("string"), header, span)), {
          collapsibleRanges
        }));
      }
    };
  }
  onNewHeaderRow() {
    return this.onNewRow(this.onNewHeaderColumn, this.config.headerRowHeight);
  }
  onNewBodyRow(node) {
    const rowAccumulator = this.onNewRow(this.onNewBodyColumn, this.config.rowHeight);
    if (node) {
      this.addRowOutlineIfNecessary(node);
    }
    return rowAccumulator;
  }
  prepare(columnsToExport) {
    super.prepare(columnsToExport);
    this.columnsToExport = [...columnsToExport];
    this.cols = columnsToExport.map((col, i) => this.convertColumnToExcel(col, i));
  }
  parse() {
    const longestRow = this.rows.reduce((a, b) => Math.max(a, b.cells.length), 0);
    while (this.cols.length < longestRow) {
      this.cols.push(this.convertColumnToExcel(null, this.cols.length + 1));
    }
    const data = {
      name: this.config.sheetName,
      table: {
        columns: this.cols,
        rows: this.rows
      }
    };
    return this.createExcel(data);
  }
  addRowOutlineIfNecessary(node) {
    const { gos, suppressRowOutline, rowGroupExpandState = "expanded" } = this.config;
    const isGroupHideOpenParents = gos.get("groupHideOpenParents");
    if (isGroupHideOpenParents || suppressRowOutline || node.level == null) {
      return;
    }
    const padding = node.footer ? 1 : 0;
    const currentRow = _65.last(this.rows);
    currentRow.outlineLevel = node.level + padding;
    if (rowGroupExpandState === "expanded") {
      return;
    }
    const collapseAll = rowGroupExpandState === "collapsed";
    if (node.isExpandable()) {
      const isExpanded = !collapseAll && node.expanded;
      currentRow.collapsed = !isExpanded;
    }
    currentRow.hidden = // always show the node if there is no parent to be expanded
    !!node.parent && // or if it is a child of the root node
    node.parent.level !== -1 && (collapseAll || this.isAnyParentCollapsed(node.parent));
  }
  isAnyParentCollapsed(node) {
    while (node && node.level !== -1) {
      if (!node.expanded) {
        return true;
      }
      node = node.parent;
    }
    return false;
  }
  convertColumnToExcel(column, index) {
    const columnWidth = this.config.columnWidth;
    const headerValue = column ? this.extractHeaderValue(column) : void 0;
    const displayName = headerValue != null ? headerValue : "";
    const filterAllowed = column ? column.isFilterAllowed() : false;
    if (columnWidth) {
      if (typeof columnWidth === "number") {
        return { width: columnWidth, displayName, filterAllowed };
      }
      return { width: columnWidth({ column, index }), displayName, filterAllowed };
    }
    if (column) {
      const smallestUsefulWidth = 75;
      return { width: Math.max(column.getActualWidth(), smallestUsefulWidth), displayName, filterAllowed };
    }
    return {
      displayName,
      filterAllowed
    };
  }
  onNewHeaderColumn(rowIndex, currentCells) {
    return (column) => {
      const nameForCol = this.extractHeaderValue(column);
      const styleIds = this.config.styleLinker({ rowType: RowType.HEADER, rowIndex, value: nameForCol, column });
      currentCells.push(this.createCell(this.getStyleId(styleIds), this.getDataTypeForValue("string"), nameForCol));
    };
  }
  onNewBodyColumn(rowIndex, currentCells) {
    let skipCols = 0;
    return (column, index, node) => {
      if (skipCols > 0) {
        skipCols -= 1;
        return;
      }
      const { value: valueForCell, valueFormatted } = this.extractRowCellValue(column, index, rowIndex, "excel", node);
      const styleIds = this.config.styleLinker({ rowType: RowType.BODY, rowIndex, value: valueForCell, column, node });
      const excelStyleId = this.getStyleId(styleIds);
      const colSpan = column.getColSpan(node);
      const addedImage = this.addImage(rowIndex, column, valueForCell);
      if (addedImage) {
        currentCells.push(this.createCell(excelStyleId, this.getDataTypeForValue(addedImage.value), addedImage.value == null ? "" : addedImage.value));
      } else if (colSpan > 1) {
        skipCols = colSpan - 1;
        currentCells.push(this.createMergedCell(excelStyleId, this.getDataTypeForValue(valueForCell), valueForCell, colSpan - 1));
      } else {
        currentCells.push(this.createCell(excelStyleId, this.getDataTypeForValue(valueForCell), valueForCell, valueFormatted));
      }
    };
  }
  onNewRow(onNewColumnAccumulator, height) {
    const currentCells = [];
    this.rows.push({
      cells: currentCells,
      height: getHeightFromProperty(this.rows.length + 1, height)
    });
    return {
      onColumn: onNewColumnAccumulator.bind(this, this.rows.length, currentCells)()
    };
  }
  createExcel(data) {
    const { excelStyles, config } = this;
    return ExcelXlsxFactory.createExcel(
      excelStyles,
      data,
      config
    );
  }
  getDataTypeForValue(valueForCell) {
    if (valueForCell === void 0) {
      return "empty";
    }
    return this.isNumerical(valueForCell) ? "n" : "s";
  }
  getTypeFromStyle(style, value) {
    if (this.isFormula(value)) {
      return "f";
    }
    if (style && style.dataType) {
      switch (style.dataType.toLocaleLowerCase()) {
        case "formula":
          return "f";
        case "string":
          return "s";
        case "number":
          return "n";
        case "datetime":
          return "d";
        case "error":
          return "e";
        case "boolean":
          return "b";
        default:
          console.warn(`AG Grid: Unrecognized data type for excel export [${style.id}.dataType=${style.dataType}]`);
      }
    }
    return null;
  }
  addImage(rowIndex, column, value) {
    if (!this.config.addImageToCell) {
      return;
    }
    const addedImage = this.config.addImageToCell(rowIndex, column, value);
    if (!addedImage) {
      return;
    }
    ExcelXlsxFactory.addBodyImageToMap(addedImage.image, rowIndex, column, this.columnsToExport, this.config.rowHeight);
    return addedImage;
  }
  createCell(styleId, type, value, valueFormatted) {
    const actualStyle = this.getStyleById(styleId);
    if (!(actualStyle == null ? void 0 : actualStyle.dataType) && type === "s" && valueFormatted) {
      value = valueFormatted;
    }
    const processedType = this.getTypeFromStyle(actualStyle, value) || type;
    const { value: processedValue, escaped } = this.getCellValue(processedType, value);
    const styles = [];
    if (actualStyle) {
      styles.push(styleId);
    }
    if (escaped) {
      styles.push("_quotePrefix");
    }
    styleId = this.getStyleId(styles) || void 0;
    return {
      styleId,
      data: {
        type: processedType,
        value: processedValue
      }
    };
  }
  createMergedCell(styleId, type, value, numOfCells) {
    const valueToUse = value == null ? "" : value;
    return {
      styleId: !!this.getStyleById(styleId) ? styleId : void 0,
      data: {
        type,
        value: type === "s" ? ExcelXlsxFactory.getStringPosition(valueToUse).toString() : value
      },
      mergeAcross: numOfCells
    };
  }
  getCellValue(type, value) {
    let escaped = false;
    if (value == null) {
      type = "s";
      value = "";
    }
    if (type === "s") {
      if (value && value[0] === "'") {
        escaped = true;
        value = value.slice(1);
      }
      value = ExcelXlsxFactory.getStringPosition(value).toString();
    } else if (type === "f") {
      value = value.slice(1);
    } else if (type === "n") {
      const numberValue = Number(value);
      if (isNaN(numberValue)) {
        value = "";
      } else if (value !== "") {
        value = numberValue.toString();
      }
    }
    return { value, escaped };
  }
  getStyleId(styleIds) {
    if (!styleIds || !styleIds.length) {
      return null;
    }
    if (styleIds.length === 1) {
      return styleIds[0];
    }
    const key = styleIds.join("-");
    if (!this.mixedStyles[key]) {
      this.addNewMixedStyle(styleIds);
    }
    return this.mixedStyles[key].excelID;
  }
  deepCloneObject(object) {
    return JSON.parse(JSON.stringify(object));
  }
  addNewMixedStyle(styleIds) {
    this.mixedStyleCounter += 1;
    const excelId = `mixedStyle${this.mixedStyleCounter}`;
    const resultantStyle = {};
    for (const styleId of styleIds) {
      for (const excelStyle of this.excelStyles) {
        if (excelStyle.id === styleId) {
          _65.mergeDeep(resultantStyle, this.deepCloneObject(excelStyle));
        }
      }
    }
    resultantStyle.id = excelId;
    resultantStyle.name = excelId;
    const key = styleIds.join("-");
    this.mixedStyles[key] = {
      excelID: excelId,
      key,
      result: resultantStyle
    };
    this.excelStyles.push(resultantStyle);
    this.stylesByIds[excelId] = resultantStyle;
  }
  isFormula(value) {
    if (value == null) {
      return false;
    }
    return this.config.autoConvertFormulas && value.toString().startsWith("=");
  }
  isNumerical(value) {
    if (typeof value === "bigint") {
      return true;
    }
    return isFinite(value) && value !== "" && !isNaN(parseFloat(value));
  }
  getStyleById(styleId) {
    if (styleId == null) {
      return null;
    }
    return this.stylesByIds[styleId] || null;
  }
};
var createExcelXMLCoreFolderStructure = () => {
  ZipContainer.addFolders([
    "_rels/",
    "docProps/",
    "xl/",
    "xl/theme/",
    "xl/_rels/",
    "xl/worksheets/"
  ]);
  const { images } = ExcelXlsxFactory;
  if (!images.size) {
    return;
  }
  ZipContainer.addFolders([
    "xl/worksheets/_rels",
    "xl/drawings/",
    "xl/drawings/_rels",
    "xl/media/"
  ]);
  let imgCounter = 0;
  images.forEach((value) => {
    const firstImage = value[0].image[0];
    const { base64, imageType } = firstImage;
    const ext = imageType === "jpg" ? "jpeg" : imageType;
    ZipContainer.addFile(`xl/media/image${++imgCounter}.${ext}`, base64, true);
  });
};
var createExcelXmlWorksheets = (data) => {
  let imageRelationCounter = 0;
  let headerFooterImageCounter = 0;
  let tableRelationCounter = 0;
  const { images, worksheetDataTables, worksheetImages, worksheetHeaderFooterImages } = ExcelXlsxFactory;
  for (let i = 0; i < data.length; i++) {
    const value = data[i];
    ZipContainer.addFile(`xl/worksheets/sheet${i + 1}.xml`, value, false);
    const hasImages = images.size > 0 && worksheetImages.has(i);
    const hasTables = worksheetDataTables.size > 0 && worksheetDataTables.has(i);
    const hasHeaderFooterImages = images.size && worksheetHeaderFooterImages.has(i);
    if (!hasImages && !hasTables && !hasHeaderFooterImages) {
      continue;
    }
    let tableIndex;
    let drawingIndex;
    let vmlDrawingIndex;
    if (hasImages) {
      createExcelXmlDrawings(i, imageRelationCounter);
      drawingIndex = imageRelationCounter;
      imageRelationCounter++;
    }
    if (hasHeaderFooterImages) {
      createExcelVmlDrawings(i, headerFooterImageCounter);
      vmlDrawingIndex = headerFooterImageCounter;
      headerFooterImageCounter++;
    }
    if (hasTables) {
      tableIndex = tableRelationCounter++;
    }
    const worksheetRelFile = `xl/worksheets/_rels/sheet${i + 1}.xml.rels`;
    ZipContainer.addFile(
      worksheetRelFile,
      ExcelXlsxFactory.createRelationships({
        tableIndex,
        drawingIndex,
        vmlDrawingIndex
      })
    );
  }
};
var createExcelXmlDrawings = (sheetIndex, drawingIndex) => {
  const drawingFolder = "xl/drawings";
  const drawingFileName = `${drawingFolder}/drawing${drawingIndex + 1}.xml`;
  const relFileName = `${drawingFolder}/_rels/drawing${drawingIndex + 1}.xml.rels`;
  ZipContainer.addFile(relFileName, ExcelXlsxFactory.createDrawingRel(sheetIndex));
  ZipContainer.addFile(drawingFileName, ExcelXlsxFactory.createDrawing(sheetIndex));
};
var createExcelVmlDrawings = (sheetIndex, drawingIndex) => {
  const drawingFolder = "xl/drawings";
  const drawingFileName = `${drawingFolder}/vmlDrawing${drawingIndex + 1}.vml`;
  const relFileName = `${drawingFolder}/_rels/vmlDrawing${drawingIndex + 1}.vml.rels`;
  ZipContainer.addFile(drawingFileName, ExcelXlsxFactory.createVmlDrawing(sheetIndex));
  ZipContainer.addFile(relFileName, ExcelXlsxFactory.createVmlDrawingRel(sheetIndex));
};
var createExcelXmlTables = () => {
  const { worksheetDataTables } = ExcelXlsxFactory;
  const tablesDataByWorksheet = worksheetDataTables;
  const worksheetKeys = Array.from(tablesDataByWorksheet.keys());
  for (let i = 0; i < worksheetKeys.length; i++) {
    const sheetIndex = worksheetKeys[i];
    const dataTable = tablesDataByWorksheet.get(sheetIndex);
    if (!dataTable) {
      continue;
    }
    ZipContainer.addFile(
      `xl/tables/${dataTable.name}.xml`,
      ExcelXlsxFactory.createTable(dataTable, i)
    );
  }
};
var createExcelXmlCoreSheets = (fontSize, author, sheetLen) => {
  ZipContainer.addFile("xl/workbook.xml", ExcelXlsxFactory.createWorkbook());
  ZipContainer.addFile("xl/styles.xml", ExcelXlsxFactory.createStylesheet(fontSize));
  ZipContainer.addFile("xl/sharedStrings.xml", ExcelXlsxFactory.createSharedStrings());
  ZipContainer.addFile("xl/theme/theme1.xml", ExcelXlsxFactory.createTheme());
  ZipContainer.addFile("xl/_rels/workbook.xml.rels", ExcelXlsxFactory.createWorkbookRels(sheetLen));
  ZipContainer.addFile("docProps/core.xml", ExcelXlsxFactory.createCore(author));
  ZipContainer.addFile("[Content_Types].xml", ExcelXlsxFactory.createContentTypes(sheetLen));
  ZipContainer.addFile("_rels/.rels", ExcelXlsxFactory.createRels());
};
var createExcelFileForExcel = (data, options = {}) => {
  if (!data || data.length === 0) {
    console.warn("AG Grid: Invalid params supplied to createExcelFileForExcel() - `ExcelExportParams.data` is empty.");
    ExcelXlsxFactory.resetFactory();
    return false;
  }
  const {
    fontSize = 11,
    author = "AG Grid"
  } = options;
  createExcelXMLCoreFolderStructure();
  createExcelXmlTables();
  createExcelXmlWorksheets(data);
  createExcelXmlCoreSheets(fontSize, author, data.length);
  ExcelXlsxFactory.resetFactory();
  return true;
};
var getMultipleSheetsAsExcelCompressed = (params) => {
  const { data, fontSize, author } = params;
  const mimeType = params.mimeType || "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  if (!createExcelFileForExcel(data, {
    author,
    fontSize
  })) {
    return Promise.resolve(void 0);
  }
  return ZipContainer.getZipFile(mimeType);
};
var getMultipleSheetsAsExcel = (params) => {
  const { data, fontSize, author } = params;
  const mimeType = params.mimeType || "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  if (!createExcelFileForExcel(data, {
    author,
    fontSize
  })) {
    return;
  }
  return ZipContainer.getUncompressedZipFile(mimeType);
};
var exportMultipleSheetsAsExcel = (params) => {
  const { fileName = "export.xlsx" } = params;
  getMultipleSheetsAsExcelCompressed(params).then((contents) => {
    if (contents) {
      const downloadFileName = typeof fileName === "function" ? fileName() : fileName;
      Downloader.download(downloadFileName, contents);
    }
  });
};
var ExcelCreator = class extends BaseCreator {
  postConstruct() {
    this.setBeans({
      gridSerializer: this.gridSerializer,
      gos: this.gos
    });
  }
  getMergedParams(params) {
    const baseParams = this.gos.get("defaultExcelExportParams");
    return Object.assign({}, baseParams, params);
  }
  export(userParams) {
    if (this.isExportSuppressed()) {
      console.warn(`AG Grid: Export cancelled. Export is not allowed as per your configuration.`);
      return;
    }
    const mergedParams = this.getMergedParams(userParams);
    const data = this.getData(mergedParams);
    const exportParams = {
      data: [data],
      fontSize: mergedParams.fontSize,
      author: mergedParams.author,
      mimeType: mergedParams.mimeType
    };
    this.packageCompressedFile(exportParams).then((packageFile) => {
      if (packageFile) {
        const { fileName } = mergedParams;
        const providedFileName = typeof fileName === "function" ? fileName(this.gos.getGridCommonParams()) : fileName;
        Downloader.download(this.getFileName(providedFileName), packageFile);
      }
    });
  }
  exportDataAsExcel(params) {
    this.export(params);
  }
  getDataAsExcel(params) {
    const mergedParams = this.getMergedParams(params);
    const data = this.getData(mergedParams);
    const exportParams = {
      data: [data],
      fontSize: mergedParams.fontSize,
      author: mergedParams.author,
      mimeType: mergedParams.mimeType
    };
    return this.packageFile(exportParams);
  }
  setFactoryMode(factoryMode) {
    ExcelXlsxFactory.factoryMode = factoryMode;
  }
  getFactoryMode() {
    return ExcelXlsxFactory.factoryMode;
  }
  getSheetDataForExcel(params) {
    const mergedParams = this.getMergedParams(params);
    return this.getData(mergedParams);
  }
  getMultipleSheetsAsExcel(params) {
    return getMultipleSheetsAsExcel(params);
  }
  exportMultipleSheetsAsExcel(params) {
    exportMultipleSheetsAsExcel(params);
  }
  getDefaultFileExtension() {
    return "xlsx";
  }
  createSerializingSession(params) {
    const { columnModel, valueService, gos } = this;
    let sheetName;
    if (params.sheetName != null) {
      const { sheetName: sheetNameParam } = params;
      const sheetNameValue = typeof sheetNameParam === "function" ? sheetNameParam(this.gos.getGridCommonParams()) : sheetNameParam;
      sheetName = String(sheetNameValue).substring(0, 31);
    } else {
      sheetName = "ag-grid";
    }
    const config = __spreadProps6(__spreadValues7({}, params), {
      sheetName,
      columnModel,
      valueService,
      gos,
      suppressRowOutline: params.suppressRowOutline || params.skipRowGroups,
      headerRowHeight: params.headerRowHeight || params.rowHeight,
      baseExcelStyles: this.gos.get("excelStyles") || [],
      styleLinker: this.styleLinker.bind(this)
    });
    return new ExcelSerializingSession(config);
  }
  styleLinker(params) {
    const { rowType, rowIndex, value, column, columnGroup, node } = params;
    const isHeader = rowType === RowType2.HEADER;
    const isGroupHeader = rowType === RowType2.HEADER_GROUPING;
    const col = isHeader ? column : columnGroup;
    let headerClasses = [];
    if (isHeader || isGroupHeader) {
      headerClasses.push("header");
      if (isGroupHeader) {
        headerClasses.push("headerGroup");
      }
      if (col) {
        headerClasses = headerClasses.concat(CssClassApplier3.getHeaderClassesFromColDef(
          col.getDefinition(),
          this.gos,
          column || null,
          columnGroup || null
        ));
      }
      return headerClasses;
    }
    const styles = this.gos.get("excelStyles");
    const applicableStyles = ["cell"];
    if (!styles || !styles.length) {
      return applicableStyles;
    }
    const styleIds = styles.map((it) => {
      return it.id;
    });
    this.stylingService.processAllCellClasses(
      column.getDefinition(),
      this.gos.addGridCommonParams({
        value,
        data: node.data,
        node,
        colDef: column.getDefinition(),
        column,
        rowIndex
      }),
      (className) => {
        if (styleIds.indexOf(className) > -1) {
          applicableStyles.push(className);
        }
      }
    );
    return applicableStyles.sort((left, right) => {
      return styleIds.indexOf(left) < styleIds.indexOf(right) ? -1 : 1;
    });
  }
  isExportSuppressed() {
    return this.gos.get("suppressExcelExport");
  }
  packageCompressedFile(params) {
    return getMultipleSheetsAsExcelCompressed(params);
  }
  packageFile(params) {
    return getMultipleSheetsAsExcel(params);
  }
};
__decorateClass9([
  Autowired57("columnModel")
], ExcelCreator.prototype, "columnModel", 2);
__decorateClass9([
  Autowired57("valueService")
], ExcelCreator.prototype, "valueService", 2);
__decorateClass9([
  Autowired57("stylingService")
], ExcelCreator.prototype, "stylingService", 2);
__decorateClass9([
  Autowired57("gridSerializer")
], ExcelCreator.prototype, "gridSerializer", 2);
__decorateClass9([
  Autowired57("gridOptionsService")
], ExcelCreator.prototype, "gos", 2);
__decorateClass9([
  PostConstruct58
], ExcelCreator.prototype, "postConstruct", 1);
ExcelCreator = __decorateClass9([
  Bean12("excelCreator")
], ExcelCreator);
var VERSION9 = "31.3.2";
var ExcelExportModule = {
  version: VERSION9,
  moduleName: ModuleNames9.ExcelExportModule,
  beans: [
    // beans in this module
    ExcelCreator,
    // these beans are part of CSV Export module
    GridSerializer2,
    CsvCreator
  ],
  dependantModules: [
    CsvExportModule2,
    EnterpriseCoreModule
  ]
};

// enterprise-modules/filter-tool-panel/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames10 } from "ag-grid-community";
import {
  _ as _41,
  Autowired as Autowired58,
  Component as Component48,
  Events as Events15,
  PostConstruct as PostConstruct59,
  PreConstruct as PreConstruct3,
  RefSelector as RefSelector18
} from "ag-grid-community";
import {
  _ as _47,
  Autowired as Autowired413,
  Component as Component49,
  Events as Events45,
  ProvidedColumnGroup as ProvidedColumnGroup22
} from "ag-grid-community";
import {
  _ as _213,
  Autowired as Autowired213,
  Column as Column22,
  Component as Component212,
  Events as Events27,
  KeyCode as KeyCode9,
  PostConstruct as PostConstruct213,
  RefSelector as RefSelector26,
  FilterWrapperComp as FilterWrapperComp2
} from "ag-grid-community";
import {
  _ as _312,
  AgGroupComponent,
  Autowired as Autowired313,
  Column as Column32,
  Component as Component313,
  Events as Events35,
  ProvidedColumnGroup as ProvidedColumnGroup3,
  PostConstruct as PostConstruct313,
  PreConstruct as PreConstruct22,
  RefSelector as RefSelector36
} from "ag-grid-community";
import {
  Component as Component55,
  RefSelector as RefSelector44
} from "ag-grid-community";
var __defProp10 = Object.defineProperty;
var __getOwnPropDesc10 = Object.getOwnPropertyDescriptor;
var __getOwnPropSymbols8 = Object.getOwnPropertySymbols;
var __hasOwnProp9 = Object.prototype.hasOwnProperty;
var __propIsEnum8 = Object.prototype.propertyIsEnumerable;
var __defNormalProp8 = (obj, key, value) => key in obj ? __defProp10(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues8 = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp9.call(b, prop))
      __defNormalProp8(a, prop, b[prop]);
  if (__getOwnPropSymbols8)
    for (var prop of __getOwnPropSymbols8(b)) {
      if (__propIsEnum8.call(b, prop))
        __defNormalProp8(a, prop, b[prop]);
    }
  return a;
};
var __decorateClass10 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc10(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp10(target, key, result);
  return result;
};
var FiltersToolPanelHeaderPanel = class extends Component48 {
  preConstruct() {
    this.setTemplate(
      /* html */
      `<div class="ag-filter-toolpanel-search" role="presentation">
                <div ref="eExpand" class="ag-filter-toolpanel-expand"></div>
                <ag-input-text-field ref="eFilterTextField" class="ag-filter-toolpanel-search-input"></ag-input-text-field>
            </div>`
    );
  }
  postConstruct() {
    const translate = this.localeService.getLocaleTextFunc();
    this.eFilterTextField.setAutoComplete(false).setInputAriaLabel(translate("ariaFilterColumnsInput", "Filter Columns Input")).onValueChange(this.onSearchTextChanged.bind(this));
    this.createExpandIcons();
    this.setExpandState(
      0
      /* EXPANDED */
    );
    this.addManagedListener(this.eExpand, "click", this.onExpandClicked.bind(this));
    this.addManagedListener(this.eventService, Events15.EVENT_NEW_COLUMNS_LOADED, this.showOrHideOptions.bind(this));
  }
  init(params) {
    this.params = params;
    if (this.columnModel.isReady()) {
      this.showOrHideOptions();
    }
  }
  createExpandIcons() {
    this.eExpand.appendChild(this.eExpandChecked = _41.createIconNoSpan("columnSelectOpen", this.gos));
    this.eExpand.appendChild(this.eExpandUnchecked = _41.createIconNoSpan("columnSelectClosed", this.gos));
    this.eExpand.appendChild(this.eExpandIndeterminate = _41.createIconNoSpan("columnSelectIndeterminate", this.gos));
  }
  // we only show expand / collapse if we are showing filters
  showOrHideOptions() {
    const showFilterSearch = !this.params.suppressFilterSearch;
    const showExpand = !this.params.suppressExpandAll;
    const translate = this.localeService.getLocaleTextFunc();
    this.eFilterTextField.setInputPlaceholder(translate("searchOoo", "Search..."));
    const isFilterGroupPresent = (col) => col.getOriginalParent() && col.isFilterAllowed();
    const filterGroupsPresent = this.columnModel.getAllGridColumns().some(isFilterGroupPresent);
    _41.setDisplayed(this.eFilterTextField.getGui(), showFilterSearch);
    _41.setDisplayed(this.eExpand, showExpand && filterGroupsPresent);
  }
  onSearchTextChanged() {
    if (!this.onSearchTextChangedDebounced) {
      this.onSearchTextChangedDebounced = _41.debounce(() => {
        this.dispatchEvent({ type: "searchChanged", searchText: this.eFilterTextField.getValue() });
      }, 300);
    }
    this.onSearchTextChangedDebounced();
  }
  onExpandClicked() {
    const event = this.currentExpandState === 0 ? { type: "collapseAll" } : { type: "expandAll" };
    this.dispatchEvent(event);
  }
  setExpandState(state) {
    this.currentExpandState = state;
    _41.setDisplayed(
      this.eExpandChecked,
      this.currentExpandState === 0
      /* EXPANDED */
    );
    _41.setDisplayed(
      this.eExpandUnchecked,
      this.currentExpandState === 1
      /* COLLAPSED */
    );
    _41.setDisplayed(
      this.eExpandIndeterminate,
      this.currentExpandState === 2
      /* INDETERMINATE */
    );
  }
};
__decorateClass10([
  Autowired58("columnModel")
], FiltersToolPanelHeaderPanel.prototype, "columnModel", 2);
__decorateClass10([
  RefSelector18("eExpand")
], FiltersToolPanelHeaderPanel.prototype, "eExpand", 2);
__decorateClass10([
  RefSelector18("eFilterTextField")
], FiltersToolPanelHeaderPanel.prototype, "eFilterTextField", 2);
__decorateClass10([
  PreConstruct3
], FiltersToolPanelHeaderPanel.prototype, "preConstruct", 1);
__decorateClass10([
  PostConstruct59
], FiltersToolPanelHeaderPanel.prototype, "postConstruct", 1);
var _ToolPanelFilterComp = class _ToolPanelFilterComp2 extends Component212 {
  constructor(hideHeader, expandedCallback) {
    super(_ToolPanelFilterComp2.TEMPLATE);
    this.expandedCallback = expandedCallback;
    this.expanded = false;
    this.hideHeader = hideHeader;
  }
  postConstruct() {
    this.eExpandChecked = _213.createIconNoSpan("columnSelectOpen", this.gos);
    this.eExpandUnchecked = _213.createIconNoSpan("columnSelectClosed", this.gos);
    this.eExpand.appendChild(this.eExpandChecked);
    this.eExpand.appendChild(this.eExpandUnchecked);
  }
  setColumn(column) {
    this.column = column;
    this.eFilterName.innerText = this.columnModel.getDisplayNameForColumn(this.column, "filterToolPanel", false) || "";
    this.addManagedListener(this.eFilterToolPanelHeader, "click", this.toggleExpanded.bind(this));
    this.addManagedListener(this.eFilterToolPanelHeader, "keydown", this.onKeyDown.bind(this));
    this.addManagedListener(this.eventService, Events27.EVENT_FILTER_OPENED, this.onFilterOpened.bind(this));
    this.addInIcon("filter", this.eFilterIcon, this.column);
    _213.setDisplayed(this.eFilterIcon, this.isFilterActive(), { skipAriaHidden: true });
    _213.setDisplayed(this.eExpandChecked, false);
    if (this.hideHeader) {
      _213.setDisplayed(this.eFilterToolPanelHeader, false);
      this.eFilterToolPanelHeader.removeAttribute("tabindex");
    } else {
      this.eFilterToolPanelHeader.setAttribute("tabindex", "0");
    }
    this.addManagedListener(this.column, Column22.EVENT_FILTER_CHANGED, this.onFilterChanged.bind(this));
  }
  onKeyDown(e) {
    const { key } = e;
    const { ENTER, SPACE, LEFT, RIGHT } = KeyCode9;
    if (key !== ENTER && key !== SPACE && key !== LEFT && key !== RIGHT) {
      return;
    }
    e.preventDefault();
    if (key === ENTER || key === SPACE) {
      this.toggleExpanded();
    } else if (key === KeyCode9.LEFT) {
      this.collapse();
    } else {
      this.expand();
    }
  }
  getColumn() {
    return this.column;
  }
  getColumnFilterName() {
    return this.columnModel.getDisplayNameForColumn(this.column, "filterToolPanel", false);
  }
  addCssClassToTitleBar(cssClass) {
    this.eFilterToolPanelHeader.classList.add(cssClass);
  }
  addInIcon(iconName, eParent, column) {
    if (eParent == null) {
      return;
    }
    const eIcon = _213.createIconNoSpan(iconName, this.gos, column);
    eParent.appendChild(eIcon);
  }
  isFilterActive() {
    return this.filterManager.isFilterActive(this.column);
  }
  onFilterChanged() {
    _213.setDisplayed(this.eFilterIcon, this.isFilterActive(), { skipAriaHidden: true });
    this.dispatchEvent({ type: Column22.EVENT_FILTER_CHANGED });
  }
  toggleExpanded() {
    this.expanded ? this.collapse() : this.expand();
  }
  expand() {
    if (this.expanded) {
      return;
    }
    this.expanded = true;
    _213.setAriaExpanded(this.eFilterToolPanelHeader, true);
    _213.setDisplayed(this.eExpandChecked, true);
    _213.setDisplayed(this.eExpandUnchecked, false);
    this.addFilterElement();
    this.expandedCallback();
  }
  addFilterElement(suppressFocus) {
    var _a;
    const filterPanelWrapper = _213.loadTemplate(
      /* html */
      `<div class="ag-filter-toolpanel-instance-filter"></div>`
    );
    const comp = this.createManagedBean(new FilterWrapperComp2(this.column, "TOOLBAR"));
    this.filterWrapperComp = comp;
    if (!comp.hasFilter()) {
      return;
    }
    (_a = comp.getFilter()) == null ? void 0 : _a.then((filter) => {
      this.underlyingFilter = filter;
      if (!filter) {
        return;
      }
      filterPanelWrapper.appendChild(comp.getGui());
      this.agFilterToolPanelBody.appendChild(filterPanelWrapper);
      comp.afterGuiAttached({ container: "toolPanel", suppressFocus });
    });
  }
  collapse() {
    var _a;
    if (!this.expanded) {
      return;
    }
    this.expanded = false;
    _213.setAriaExpanded(this.eFilterToolPanelHeader, false);
    this.removeFilterElement();
    _213.setDisplayed(this.eExpandChecked, false);
    _213.setDisplayed(this.eExpandUnchecked, true);
    (_a = this.filterWrapperComp) == null ? void 0 : _a.afterGuiDetached();
    this.destroyBean(this.filterWrapperComp);
    this.expandedCallback();
  }
  removeFilterElement() {
    _213.clearElement(this.agFilterToolPanelBody);
  }
  isExpanded() {
    return this.expanded;
  }
  refreshFilter(isDisplayed) {
    var _a;
    if (!this.expanded) {
      return;
    }
    const filter = this.underlyingFilter;
    if (!filter) {
      return;
    }
    if (isDisplayed) {
      if (typeof filter.refreshVirtualList === "function") {
        filter.refreshVirtualList();
      }
    } else {
      (_a = filter.afterGuiDetached) == null ? void 0 : _a.call(filter);
    }
  }
  onFilterOpened(event) {
    if (event.source !== "COLUMN_MENU") {
      return;
    }
    if (event.column !== this.column) {
      return;
    }
    if (!this.expanded) {
      return;
    }
    this.collapse();
  }
};
_ToolPanelFilterComp.TEMPLATE = /* html */
`
        <div class="ag-filter-toolpanel-instance">
            <div class="ag-filter-toolpanel-header ag-filter-toolpanel-instance-header" ref="eFilterToolPanelHeader" role="button" aria-expanded="false">
                <div ref="eExpand" class="ag-filter-toolpanel-expand"></div>
                <span ref="eFilterName" class="ag-header-cell-text"></span>
                <span ref="eFilterIcon" class="ag-header-icon ag-filter-icon ag-filter-toolpanel-instance-header-icon" aria-hidden="true"></span>
            </div>
            <div class="ag-filter-toolpanel-instance-body ag-filter" ref="agFilterToolPanelBody"></div>
        </div>`;
__decorateClass10([
  RefSelector26("eFilterToolPanelHeader")
], _ToolPanelFilterComp.prototype, "eFilterToolPanelHeader", 2);
__decorateClass10([
  RefSelector26("eFilterName")
], _ToolPanelFilterComp.prototype, "eFilterName", 2);
__decorateClass10([
  RefSelector26("agFilterToolPanelBody")
], _ToolPanelFilterComp.prototype, "agFilterToolPanelBody", 2);
__decorateClass10([
  RefSelector26("eFilterIcon")
], _ToolPanelFilterComp.prototype, "eFilterIcon", 2);
__decorateClass10([
  RefSelector26("eExpand")
], _ToolPanelFilterComp.prototype, "eExpand", 2);
__decorateClass10([
  Autowired213("filterManager")
], _ToolPanelFilterComp.prototype, "filterManager", 2);
__decorateClass10([
  Autowired213("columnModel")
], _ToolPanelFilterComp.prototype, "columnModel", 2);
__decorateClass10([
  PostConstruct213
], _ToolPanelFilterComp.prototype, "postConstruct", 1);
var ToolPanelFilterComp = _ToolPanelFilterComp;
var _ToolPanelFilterGroupComp = class _ToolPanelFilterGroupComp2 extends Component313 {
  constructor(columnGroup, childFilterComps, expandedCallback, depth, showingColumn) {
    super();
    this.columnGroup = columnGroup;
    this.childFilterComps = childFilterComps;
    this.depth = depth;
    this.expandedCallback = expandedCallback;
    this.showingColumn = showingColumn;
  }
  preConstruct() {
    const groupParams = {
      cssIdentifier: "filter-toolpanel",
      direction: "vertical"
    };
    this.setTemplate(_ToolPanelFilterGroupComp2.TEMPLATE, { filterGroupComp: groupParams });
  }
  init() {
    this.setGroupTitle();
    this.filterGroupComp.setAlignItems("stretch");
    this.filterGroupComp.addCssClass(`ag-filter-toolpanel-group-level-${this.depth}`);
    this.filterGroupComp.addCssClassToTitleBar(`ag-filter-toolpanel-group-level-${this.depth}-header`);
    this.childFilterComps.forEach((filterComp) => {
      this.filterGroupComp.addItem(filterComp);
      filterComp.addCssClassToTitleBar(`ag-filter-toolpanel-group-level-${this.depth + 1}-header`);
    });
    this.refreshFilterClass();
    this.addExpandCollapseListeners();
    this.addFilterChangedListeners();
    this.setupTooltip();
    this.addInIcon("filter");
  }
  setupTooltip() {
    if (!this.showingColumn) {
      return;
    }
    const isTooltipWhenTruncated = this.gos.get("tooltipShowMode") === "whenTruncated";
    let shouldDisplayTooltip;
    if (isTooltipWhenTruncated) {
      shouldDisplayTooltip = () => {
        const eGui = this.filterGroupComp.getGui();
        const eTitle = eGui.querySelector(".ag-group-title");
        if (!eTitle) {
          return true;
        }
        return eTitle.scrollWidth > eTitle.clientWidth;
      };
    }
    const refresh = () => {
      const newTooltipText = this.columnGroup.getColDef().headerTooltip;
      this.setTooltip({ newTooltipText, location: "filterToolPanelColumnGroup", shouldDisplayTooltip });
    };
    refresh();
    this.addManagedListener(this.eventService, Events35.EVENT_NEW_COLUMNS_LOADED, refresh);
  }
  getTooltipParams() {
    const res = super.getTooltipParams();
    res.location = "filterToolPanelColumnGroup";
    return res;
  }
  addCssClassToTitleBar(cssClass) {
    this.filterGroupComp.addCssClassToTitleBar(cssClass);
  }
  refreshFilters(isDisplayed) {
    this.childFilterComps.forEach((filterComp) => {
      if (filterComp instanceof _ToolPanelFilterGroupComp2) {
        filterComp.refreshFilters(isDisplayed);
      } else {
        filterComp.refreshFilter(isDisplayed);
      }
    });
  }
  isColumnGroup() {
    return this.columnGroup instanceof ProvidedColumnGroup3;
  }
  isExpanded() {
    return this.filterGroupComp.isExpanded();
  }
  getChildren() {
    return this.childFilterComps;
  }
  getFilterGroupName() {
    return this.filterGroupName ? this.filterGroupName : "";
  }
  getFilterGroupId() {
    return this.columnGroup.getId();
  }
  hideGroupItem(hide, index) {
    this.filterGroupComp.hideItem(hide, index);
  }
  hideGroup(hide) {
    this.setDisplayed(!hide);
  }
  addInIcon(iconName) {
    const eIcon = _312.createIconNoSpan(iconName, this.gos);
    if (eIcon) {
      eIcon.classList.add("ag-filter-toolpanel-group-instance-header-icon");
    }
    this.filterGroupComp.addTitleBarWidget(eIcon);
  }
  forEachToolPanelFilterChild(action) {
    this.childFilterComps.forEach((filterComp) => {
      if (filterComp instanceof ToolPanelFilterComp) {
        action(filterComp);
      }
    });
  }
  addExpandCollapseListeners() {
    const expandListener = this.isColumnGroup() ? () => this.expandedCallback() : () => this.forEachToolPanelFilterChild((filterComp) => filterComp.expand());
    const collapseListener = this.isColumnGroup() ? () => this.expandedCallback() : () => this.forEachToolPanelFilterChild((filterComp) => filterComp.collapse());
    this.addManagedListener(this.filterGroupComp, AgGroupComponent.EVENT_EXPANDED, expandListener);
    this.addManagedListener(this.filterGroupComp, AgGroupComponent.EVENT_COLLAPSED, collapseListener);
  }
  getColumns() {
    if (this.columnGroup instanceof ProvidedColumnGroup3) {
      return this.columnGroup.getLeafColumns();
    }
    return [this.columnGroup];
  }
  addFilterChangedListeners() {
    this.getColumns().forEach((column) => {
      this.addManagedListener(column, Column32.EVENT_FILTER_CHANGED, () => this.refreshFilterClass());
    });
    if (!(this.columnGroup instanceof ProvidedColumnGroup3)) {
      this.addManagedListener(this.eventService, Events35.EVENT_FILTER_OPENED, this.onFilterOpened.bind(this));
    }
  }
  refreshFilterClass() {
    const columns = this.getColumns();
    const anyChildFiltersActive = () => columns.some((col) => col.isFilterActive());
    this.filterGroupComp.addOrRemoveCssClass("ag-has-filter", anyChildFiltersActive());
  }
  onFilterOpened(event) {
    if (event.source !== "COLUMN_MENU") {
      return;
    }
    if (event.column !== this.columnGroup) {
      return;
    }
    if (!this.isExpanded()) {
      return;
    }
    this.collapse();
  }
  expand() {
    this.filterGroupComp.toggleGroupExpand(true);
  }
  collapse() {
    this.filterGroupComp.toggleGroupExpand(false);
  }
  setGroupTitle() {
    this.filterGroupName = this.columnGroup instanceof ProvidedColumnGroup3 ? this.getColumnGroupName(this.columnGroup) : this.getColumnName(this.columnGroup);
    this.filterGroupComp.setTitle(this.filterGroupName || "");
  }
  getColumnGroupName(columnGroup) {
    return this.columnModel.getDisplayNameForProvidedColumnGroup(null, columnGroup, "filterToolPanel");
  }
  getColumnName(column) {
    return this.columnModel.getDisplayNameForColumn(column, "filterToolPanel", false);
  }
  destroyFilters() {
    this.childFilterComps = this.destroyBeans(this.childFilterComps);
    _312.clearElement(this.getGui());
  }
  destroy() {
    this.destroyFilters();
    super.destroy();
  }
};
_ToolPanelFilterGroupComp.TEMPLATE = /* html */
`<div class="ag-filter-toolpanel-group-wrapper">
            <ag-group-component ref="filterGroupComp"></ag-group-component>
        </div>`;
__decorateClass10([
  RefSelector36("filterGroupComp")
], _ToolPanelFilterGroupComp.prototype, "filterGroupComp", 2);
__decorateClass10([
  Autowired313("columnModel")
], _ToolPanelFilterGroupComp.prototype, "columnModel", 2);
__decorateClass10([
  PreConstruct22
], _ToolPanelFilterGroupComp.prototype, "preConstruct", 1);
__decorateClass10([
  PostConstruct313
], _ToolPanelFilterGroupComp.prototype, "init", 1);
var ToolPanelFilterGroupComp = _ToolPanelFilterGroupComp;
var _FiltersToolPanelListPanel = class _FiltersToolPanelListPanel2 extends Component49 {
  constructor() {
    super(_FiltersToolPanelListPanel2.TEMPLATE);
    this.initialised = false;
    this.hasLoadedInitialState = false;
    this.isInitialState = false;
    this.filterGroupComps = [];
    this.suppressOnColumnsChanged = false;
    this.onColumnsChangedPending = false;
  }
  init(params) {
    this.initialised = true;
    const defaultParams = this.gos.addGridCommonParams({
      suppressExpandAll: false,
      suppressFilterSearch: false,
      suppressSyncLayoutWithGrid: false
    });
    _47.mergeDeep(defaultParams, params);
    this.params = defaultParams;
    if (!this.params.suppressSyncLayoutWithGrid) {
      this.addManagedListener(this.eventService, Events45.EVENT_COLUMN_MOVED, () => this.onColumnsChanged());
    }
    this.addManagedListener(this.eventService, Events45.EVENT_NEW_COLUMNS_LOADED, () => this.onColumnsChanged());
    this.addManagedListener(this.eventService, Events45.EVENT_TOOL_PANEL_VISIBLE_CHANGED, (event) => {
      if (event.key === "filters") {
        this.refreshFilters(event.visible);
      }
    });
    this.addManagedListener(this.eventService, Events45.EVENT_DRAG_STARTED, () => {
      this.suppressOnColumnsChanged = true;
    });
    this.addManagedListener(this.eventService, Events45.EVENT_DRAG_STOPPED, () => {
      this.suppressOnColumnsChanged = false;
      if (this.onColumnsChangedPending) {
        this.onColumnsChangedPending = false;
        this.onColumnsChanged();
      }
    });
    if (this.columnModel.isReady()) {
      this.onColumnsChanged();
    }
  }
  onColumnsChanged() {
    if (this.suppressOnColumnsChanged) {
      this.onColumnsChangedPending = true;
      return;
    }
    const pivotModeActive = this.columnModel.isPivotMode();
    const shouldSyncColumnLayoutWithGrid = !this.params.suppressSyncLayoutWithGrid && !pivotModeActive;
    shouldSyncColumnLayoutWithGrid ? this.syncFilterLayout() : this.buildTreeFromProvidedColumnDefs();
    this.refreshAriaLabel();
  }
  syncFilterLayout() {
    this.toolPanelColDefService.syncLayoutWithGrid(this.setFiltersLayout.bind(this));
    this.refreshAriaLabel();
  }
  buildTreeFromProvidedColumnDefs() {
    const columnTree = this.columnModel.getPrimaryColumnTree();
    this.recreateFilters(columnTree);
  }
  setFiltersLayout(colDefs) {
    const columnTree = this.toolPanelColDefService.createColumnTree(colDefs);
    this.recreateFilters(columnTree);
  }
  recreateFilters(columnTree) {
    const activeElement = this.gos.getActiveDomElement();
    if (!this.hasLoadedInitialState) {
      this.hasLoadedInitialState = true;
      this.isInitialState = !!this.params.initialState;
    }
    const expansionState = this.getExpansionState();
    this.destroyFilters();
    this.filterGroupComps = this.recursivelyAddComps(columnTree, 0, expansionState);
    const len = this.filterGroupComps.length;
    if (len) {
      this.filterGroupComps.forEach((comp) => this.appendChild(comp));
      this.setFirstAndLastVisible(0, len - 1);
    }
    if (_47.exists(this.searchFilterText)) {
      this.searchFilters(this.searchFilterText);
    }
    this.fireExpandedEvent();
    if (this.getGui().contains(activeElement)) {
      activeElement.focus();
    }
    this.isInitialState = false;
    this.refreshAriaLabel();
  }
  recursivelyAddComps(tree, depth, expansionState) {
    return _47.flatten(tree.map((child) => {
      if (child instanceof ProvidedColumnGroup22) {
        return _47.flatten(this.recursivelyAddFilterGroupComps(child, depth, expansionState));
      }
      const column = child;
      if (!this.shouldDisplayFilter(column)) {
        return [];
      }
      const hideFilterCompHeader = depth === 0;
      const filterComp = new ToolPanelFilterComp(hideFilterCompHeader, () => this.onFilterExpanded());
      this.createBean(filterComp);
      filterComp.setColumn(column);
      if (expansionState.get(column.getId())) {
        filterComp.expand();
      }
      if (depth > 0) {
        return filterComp;
      }
      const filterGroupComp = this.createBean(new ToolPanelFilterGroupComp(column, [filterComp], this.onGroupExpanded.bind(this), depth, true));
      filterGroupComp.addCssClassToTitleBar("ag-filter-toolpanel-header");
      if (!expansionState.get(filterGroupComp.getFilterGroupId())) {
        filterGroupComp.collapse();
      }
      return filterGroupComp;
    }));
  }
  refreshAriaLabel() {
    const translate = this.localeService.getLocaleTextFunc();
    const filterListName = translate("ariaFilterPanelList", "Filter List");
    const localeFilters = translate("filters", "Filters");
    const eGui = this.getGui();
    const groupSelector = ".ag-filter-toolpanel-group-wrapper";
    const itemSelector = ".ag-filter-toolpanel-group-item";
    const hiddenSelector = ".ag-hidden";
    const visibleItems = eGui.querySelectorAll(`${itemSelector}:not(${groupSelector}, ${hiddenSelector})`);
    const totalVisibleItems = visibleItems.length;
    _47.setAriaLabel(this.getAriaElement(), `${filterListName} ${totalVisibleItems} ${localeFilters}`);
  }
  recursivelyAddFilterGroupComps(columnGroup, depth, expansionState) {
    if (!this.filtersExistInChildren(columnGroup.getChildren())) {
      return;
    }
    const colGroupDef = columnGroup.getColGroupDef();
    if (colGroupDef && colGroupDef.suppressFiltersToolPanel) {
      return [];
    }
    const newDepth = columnGroup.isPadding() ? depth : depth + 1;
    const childFilterComps = _47.flatten(this.recursivelyAddComps(columnGroup.getChildren(), newDepth, expansionState));
    if (columnGroup.isPadding()) {
      return childFilterComps;
    }
    const filterGroupComp = new ToolPanelFilterGroupComp(columnGroup, childFilterComps, this.onGroupExpanded.bind(this), depth, false);
    this.createBean(filterGroupComp);
    filterGroupComp.addCssClassToTitleBar("ag-filter-toolpanel-header");
    const expansionStateValue = expansionState.get(filterGroupComp.getFilterGroupId());
    if (this.isInitialState && !expansionStateValue || expansionStateValue === false) {
      filterGroupComp.collapse();
    }
    return [filterGroupComp];
  }
  filtersExistInChildren(tree) {
    return tree.some((child) => {
      if (child instanceof ProvidedColumnGroup22) {
        return this.filtersExistInChildren(child.getChildren());
      }
      return this.shouldDisplayFilter(child);
    });
  }
  shouldDisplayFilter(column) {
    const suppressFiltersToolPanel = column.getColDef() && column.getColDef().suppressFiltersToolPanel;
    return column.isFilterAllowed() && !suppressFiltersToolPanel;
  }
  getExpansionState() {
    const expansionState = /* @__PURE__ */ new Map();
    if (this.isInitialState) {
      const { expandedColIds, expandedGroupIds } = this.params.initialState;
      expandedColIds.forEach((id) => expansionState.set(id, true));
      expandedGroupIds.forEach((id) => expansionState.set(id, true));
      return expansionState;
    }
    const recursiveGetExpansionState = (filterGroupComp) => {
      expansionState.set(filterGroupComp.getFilterGroupId(), filterGroupComp.isExpanded());
      filterGroupComp.getChildren().forEach((child) => {
        if (child instanceof ToolPanelFilterGroupComp) {
          recursiveGetExpansionState(child);
        } else {
          expansionState.set(child.getColumn().getId(), child.isExpanded());
        }
      });
    };
    this.filterGroupComps.forEach(recursiveGetExpansionState);
    return expansionState;
  }
  // we don't support refreshing, but must implement because it's on the tool panel interface
  refresh() {
  }
  // lazy initialise the panel
  setVisible(visible) {
    super.setDisplayed(visible);
    if (visible && !this.initialised) {
      this.init(this.params);
    }
  }
  expandFilterGroups(expand, groupIds) {
    const updatedGroupIds = [];
    const updateGroupExpandState = (filterGroup) => {
      const groupId = filterGroup.getFilterGroupId();
      const shouldExpandOrCollapse = !groupIds || _47.includes(groupIds, groupId);
      if (shouldExpandOrCollapse) {
        if (expand && filterGroup.isColumnGroup()) {
          filterGroup.expand();
        } else {
          filterGroup.collapse();
        }
        updatedGroupIds.push(groupId);
      }
      filterGroup.getChildren().forEach((child) => {
        if (child instanceof ToolPanelFilterGroupComp) {
          updateGroupExpandState(child);
        }
      });
    };
    this.filterGroupComps.forEach(updateGroupExpandState);
    this.onGroupExpanded();
    if (groupIds) {
      const unrecognisedGroupIds = groupIds.filter((groupId) => updatedGroupIds.indexOf(groupId) < 0);
      if (unrecognisedGroupIds.length > 0) {
        console.warn("AG Grid: unable to find groups for these supplied groupIds:", unrecognisedGroupIds);
      }
    }
  }
  expandFilters(expand, colIds) {
    const updatedColIds = [];
    const updateGroupExpandState = (filterComp) => {
      if (filterComp instanceof ToolPanelFilterGroupComp) {
        let anyChildrenChanged = false;
        filterComp.getChildren().forEach((child) => {
          const childUpdated = updateGroupExpandState(child);
          if (childUpdated) {
            if (expand) {
              filterComp.expand();
              anyChildrenChanged = true;
            } else if (!filterComp.isColumnGroup()) {
              filterComp.collapse();
            }
          }
        });
        return anyChildrenChanged;
      }
      const colId = filterComp.getColumn().getColId();
      const updateFilterExpandState = !colIds || _47.includes(colIds, colId);
      if (updateFilterExpandState) {
        expand ? filterComp.expand() : filterComp.collapse();
        updatedColIds.push(colId);
      }
      return updateFilterExpandState;
    };
    this.filterGroupComps.forEach(updateGroupExpandState);
    this.onGroupExpanded();
    if (colIds) {
      const unrecognisedColIds = colIds.filter((colId) => updatedColIds.indexOf(colId) < 0);
      if (unrecognisedColIds.length > 0) {
        console.warn("AG Grid: unable to find columns for these supplied colIds:", unrecognisedColIds);
      }
    }
  }
  onGroupExpanded() {
    this.fireExpandedEvent();
  }
  onFilterExpanded() {
    this.dispatchEvent({ type: "filterExpanded" });
  }
  fireExpandedEvent() {
    let expandedCount = 0;
    let notExpandedCount = 0;
    const updateExpandCounts = (filterGroup) => {
      if (!filterGroup.isColumnGroup()) {
        return;
      }
      filterGroup.isExpanded() ? expandedCount++ : notExpandedCount++;
      filterGroup.getChildren().forEach((child) => {
        if (child instanceof ToolPanelFilterGroupComp) {
          updateExpandCounts(child);
        }
      });
    };
    this.filterGroupComps.forEach(updateExpandCounts);
    let state;
    if (expandedCount > 0 && notExpandedCount > 0) {
      state = 2;
    } else if (notExpandedCount > 0) {
      state = 1;
    } else {
      state = 0;
    }
    this.dispatchEvent({ type: "groupExpanded", state });
  }
  performFilterSearch(searchText) {
    this.searchFilterText = _47.exists(searchText) ? searchText.toLowerCase() : null;
    this.searchFilters(this.searchFilterText);
  }
  searchFilters(searchFilter) {
    const passesFilter = (groupName) => {
      return !_47.exists(searchFilter) || groupName.toLowerCase().indexOf(searchFilter) !== -1;
    };
    const recursivelySearch = (filterItem, parentPasses) => {
      if (!(filterItem instanceof ToolPanelFilterGroupComp)) {
        return passesFilter(filterItem.getColumnFilterName() || "");
      }
      const children = filterItem.getChildren();
      const groupNamePasses = passesFilter(filterItem.getFilterGroupName());
      const alreadyPassed = parentPasses || groupNamePasses;
      if (alreadyPassed) {
        filterItem.hideGroup(false);
        for (let i = 0; i < children.length; i++) {
          recursivelySearch(children[i], alreadyPassed);
          filterItem.hideGroupItem(false, i);
        }
        return true;
      }
      let anyChildPasses = false;
      children.forEach((child, index) => {
        const childPasses = recursivelySearch(child, parentPasses);
        filterItem.hideGroupItem(!childPasses, index);
        if (childPasses) {
          anyChildPasses = true;
        }
      });
      filterItem.hideGroup(!anyChildPasses);
      return anyChildPasses;
    };
    let firstVisible;
    let lastVisible;
    this.filterGroupComps.forEach((filterGroup, idx) => {
      recursivelySearch(filterGroup, false);
      if (firstVisible === void 0) {
        if (!filterGroup.containsCssClass("ag-hidden")) {
          firstVisible = idx;
          lastVisible = idx;
        }
      } else if (!filterGroup.containsCssClass("ag-hidden") && lastVisible !== idx) {
        lastVisible = idx;
      }
    });
    this.setFirstAndLastVisible(firstVisible, lastVisible);
    this.refreshAriaLabel();
  }
  setFirstAndLastVisible(firstIdx, lastIdx) {
    this.filterGroupComps.forEach((filterGroup, idx) => {
      filterGroup.removeCssClass("ag-first-group-visible");
      filterGroup.removeCssClass("ag-last-group-visible");
      if (idx === firstIdx) {
        filterGroup.addCssClass("ag-first-group-visible");
      }
      if (idx === lastIdx) {
        filterGroup.addCssClass("ag-last-group-visible");
      }
    });
  }
  refreshFilters(isDisplayed) {
    this.filterGroupComps.forEach((filterGroupComp) => filterGroupComp.refreshFilters(isDisplayed));
  }
  getExpandedFiltersAndGroups() {
    const expandedGroupIds = [];
    const expandedColIds = /* @__PURE__ */ new Set();
    const getExpandedFiltersAndGroups = (filterComp) => {
      if (filterComp instanceof ToolPanelFilterGroupComp) {
        filterComp.getChildren().forEach((child) => getExpandedFiltersAndGroups(child));
        const groupId = filterComp.getFilterGroupId();
        if (filterComp.isExpanded() && !expandedColIds.has(groupId)) {
          expandedGroupIds.push(groupId);
        }
      } else {
        if (filterComp.isExpanded()) {
          expandedColIds.add(filterComp.getColumn().getColId());
        }
      }
    };
    this.filterGroupComps.forEach(getExpandedFiltersAndGroups);
    return { expandedGroupIds, expandedColIds: Array.from(expandedColIds) };
  }
  destroyFilters() {
    this.filterGroupComps = this.destroyBeans(this.filterGroupComps);
    _47.clearElement(this.getGui());
  }
  destroy() {
    this.destroyFilters();
    super.destroy();
  }
};
_FiltersToolPanelListPanel.TEMPLATE = /* html */
`<div class="ag-filter-list-panel"></div>`;
__decorateClass10([
  Autowired413("toolPanelColDefService")
], _FiltersToolPanelListPanel.prototype, "toolPanelColDefService", 2);
__decorateClass10([
  Autowired413("columnModel")
], _FiltersToolPanelListPanel.prototype, "columnModel", 2);
var FiltersToolPanelListPanel = _FiltersToolPanelListPanel;
var _FiltersToolPanel = class _FiltersToolPanel2 extends Component55 {
  constructor() {
    super(_FiltersToolPanel2.TEMPLATE);
    this.initialised = false;
    this.listenerDestroyFuncs = [];
  }
  init(params) {
    if (this.initialised) {
      this.listenerDestroyFuncs.forEach((func) => func());
      this.listenerDestroyFuncs = [];
    }
    this.initialised = true;
    const defaultParams = this.gos.addGridCommonParams({
      suppressExpandAll: false,
      suppressFilterSearch: false,
      suppressSyncLayoutWithGrid: false
    });
    this.params = __spreadValues8(__spreadValues8({}, defaultParams), params);
    this.filtersToolPanelHeaderPanel.init(this.params);
    this.filtersToolPanelListPanel.init(this.params);
    const hideExpand = this.params.suppressExpandAll;
    const hideSearch = this.params.suppressFilterSearch;
    if (hideExpand && hideSearch) {
      this.filtersToolPanelHeaderPanel.setDisplayed(false);
    }
    this.listenerDestroyFuncs.push(
      this.addManagedListener(this.filtersToolPanelHeaderPanel, "expandAll", this.onExpandAll.bind(this)),
      this.addManagedListener(this.filtersToolPanelHeaderPanel, "collapseAll", this.onCollapseAll.bind(this)),
      this.addManagedListener(this.filtersToolPanelHeaderPanel, "searchChanged", this.onSearchChanged.bind(this)),
      this.addManagedListener(this.filtersToolPanelListPanel, "filterExpanded", this.onFilterExpanded.bind(this)),
      this.addManagedListener(this.filtersToolPanelListPanel, "groupExpanded", this.onGroupExpanded.bind(this))
    );
  }
  // lazy initialise the panel
  setVisible(visible) {
    super.setDisplayed(visible);
    if (visible && !this.initialised) {
      this.init(this.params);
    }
  }
  onExpandAll() {
    this.filtersToolPanelListPanel.expandFilterGroups(true);
  }
  onCollapseAll() {
    this.filtersToolPanelListPanel.expandFilterGroups(false);
  }
  onSearchChanged(event) {
    this.filtersToolPanelListPanel.performFilterSearch(event.searchText);
  }
  setFilterLayout(colDefs) {
    this.filtersToolPanelListPanel.setFiltersLayout(colDefs);
  }
  onFilterExpanded() {
    this.params.onStateUpdated();
  }
  onGroupExpanded(event) {
    this.filtersToolPanelHeaderPanel.setExpandState(event.state);
    this.params.onStateUpdated();
  }
  expandFilterGroups(groupIds) {
    this.filtersToolPanelListPanel.expandFilterGroups(true, groupIds);
  }
  collapseFilterGroups(groupIds) {
    this.filtersToolPanelListPanel.expandFilterGroups(false, groupIds);
  }
  expandFilters(colIds) {
    this.filtersToolPanelListPanel.expandFilters(true, colIds);
  }
  collapseFilters(colIds) {
    this.filtersToolPanelListPanel.expandFilters(false, colIds);
  }
  syncLayoutWithGrid() {
    this.filtersToolPanelListPanel.syncFilterLayout();
  }
  refresh(params) {
    this.init(params);
    return true;
  }
  getState() {
    return this.filtersToolPanelListPanel.getExpandedFiltersAndGroups();
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
};
_FiltersToolPanel.TEMPLATE = /* html */
`<div class="ag-filter-toolpanel">
            <ag-filters-tool-panel-header ref="filtersToolPanelHeaderPanel"></ag-filters-tool-panel-header>
            <ag-filters-tool-panel-list ref="filtersToolPanelListPanel"></ag-filters-tool-panel-list>
         </div>`;
__decorateClass10([
  RefSelector44("filtersToolPanelHeaderPanel")
], _FiltersToolPanel.prototype, "filtersToolPanelHeaderPanel", 2);
__decorateClass10([
  RefSelector44("filtersToolPanelListPanel")
], _FiltersToolPanel.prototype, "filtersToolPanelListPanel", 2);
var FiltersToolPanel = _FiltersToolPanel;
var VERSION10 = "31.3.2";
var FiltersToolPanelModule = {
  version: VERSION10,
  moduleName: ModuleNames10.FiltersToolPanelModule,
  beans: [],
  agStackComponents: [
    { componentName: "AgFiltersToolPanelHeader", componentClass: FiltersToolPanelHeaderPanel },
    { componentName: "AgFiltersToolPanelList", componentClass: FiltersToolPanelListPanel }
  ],
  userComponents: [
    { componentName: "agFiltersToolPanel", componentClass: FiltersToolPanel }
  ],
  dependantModules: [
    SideBarModule,
    EnterpriseCoreModule
  ]
};

// enterprise-modules/master-detail/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames11 } from "ag-grid-community";
import { Component as Component50, RefSelector as RefSelector19, _ as _214, ModuleRegistry as ModuleRegistry4, createGrid, ColumnApi as ColumnApi2 } from "ag-grid-community";
import {
  Autowired as Autowired59,
  BeanStub as BeanStub19,
  Events as Events16,
  _ as _48
} from "ag-grid-community";
var __defProp11 = Object.defineProperty;
var __getOwnPropDesc11 = Object.getOwnPropertyDescriptor;
var __getOwnPropSymbols9 = Object.getOwnPropertySymbols;
var __hasOwnProp10 = Object.prototype.hasOwnProperty;
var __propIsEnum9 = Object.prototype.propertyIsEnumerable;
var __defNormalProp9 = (obj, key, value) => key in obj ? __defProp11(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues9 = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp10.call(b, prop))
      __defNormalProp9(a, prop, b[prop]);
  if (__getOwnPropSymbols9)
    for (var prop of __getOwnPropSymbols9(b)) {
      if (__propIsEnum9.call(b, prop))
        __defNormalProp9(a, prop, b[prop]);
    }
  return a;
};
var __decorateClass11 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc11(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp11(target, key, result);
  return result;
};
var DetailCellRendererCtrl = class extends BeanStub19 {
  constructor() {
    super(...arguments);
    this.loadRowDataVersion = 0;
  }
  init(comp, params) {
    this.params = params;
    this.comp = comp;
    const doNothingBecauseInsidePinnedSection = params.pinned != null;
    if (doNothingBecauseInsidePinnedSection) {
      return;
    }
    this.setAutoHeightClasses();
    this.setupRefreshStrategy();
    this.addThemeToDetailGrid();
    this.createDetailGrid();
    this.loadRowData();
    this.addManagedListener(this.eventService, Events16.EVENT_FULL_WIDTH_ROW_FOCUSED, this.onFullWidthRowFocused.bind(this));
  }
  onFullWidthRowFocused(e) {
    const params = this.params;
    const row = { rowIndex: params.node.rowIndex, rowPinned: params.node.rowPinned };
    const eventRow = { rowIndex: e.rowIndex, rowPinned: e.rowPinned };
    const isSameRow = this.rowPositionUtils.sameRow(row, eventRow);
    if (!isSameRow) {
      return;
    }
    this.focusService.focusInto(this.comp.getGui(), e.fromBelow);
  }
  setAutoHeightClasses() {
    const autoHeight = this.gos.get("detailRowAutoHeight");
    const parentClass = autoHeight ? "ag-details-row-auto-height" : "ag-details-row-fixed-height";
    const detailClass = autoHeight ? "ag-details-grid-auto-height" : "ag-details-grid-fixed-height";
    this.comp.addOrRemoveCssClass(parentClass, true);
    this.comp.addOrRemoveDetailGridCssClass(detailClass, true);
  }
  setupRefreshStrategy() {
    const providedStrategy = this.params.refreshStrategy;
    const validSelection = providedStrategy == "everything" || providedStrategy == "nothing" || providedStrategy == "rows";
    if (validSelection) {
      this.refreshStrategy = providedStrategy;
      return;
    }
    if (providedStrategy != null) {
      console.warn("AG Grid: invalid cellRendererParams.refreshStrategy = '" + providedStrategy + "' supplied, defaulting to refreshStrategy = 'rows'.");
    }
    this.refreshStrategy = "rows";
  }
  addThemeToDetailGrid() {
    const { theme } = this.environment.getTheme();
    if (theme) {
      this.comp.addOrRemoveDetailGridCssClass(theme, true);
    }
  }
  createDetailGrid() {
    if (_48.missing(this.params.detailGridOptions)) {
      console.warn("AG Grid: could not find detail grid options for master detail, please set gridOptions.detailCellRendererParams.detailGridOptions");
      return;
    }
    const autoHeight = this.gos.get("detailRowAutoHeight");
    const gridOptions = __spreadValues9({}, this.params.detailGridOptions);
    if (autoHeight) {
      gridOptions.domLayout = "autoHeight";
    }
    this.comp.setDetailGrid(gridOptions);
  }
  registerDetailWithMaster(api, columnApi) {
    const rowId = this.params.node.id;
    const masterGridApi = this.params.api;
    const gridInfo = {
      id: rowId,
      api,
      columnApi
    };
    const rowNode = this.params.node;
    if (masterGridApi.isDestroyed()) {
      return;
    }
    masterGridApi.addDetailGridInfo(rowId, gridInfo);
    rowNode.detailGridInfo = gridInfo;
    this.addDestroyFunc(() => {
      if (rowNode.detailGridInfo !== gridInfo) {
        return;
      }
      if (!masterGridApi.isDestroyed()) {
        masterGridApi.removeDetailGridInfo(rowId);
      }
      rowNode.detailGridInfo = null;
    });
  }
  loadRowData() {
    var _a, _b, _c;
    this.loadRowDataVersion++;
    const versionThisCall = this.loadRowDataVersion;
    if (((_a = this.params.detailGridOptions) == null ? void 0 : _a.rowModelType) === "serverSide") {
      const node = this.params.node;
      (_c = (_b = node.detailGridInfo) == null ? void 0 : _b.api) == null ? void 0 : _c.refreshServerSide({ purge: true });
      return;
    }
    const userFunc = this.params.getDetailRowData;
    if (!userFunc) {
      console.warn("AG Grid: could not find getDetailRowData for master / detail, please set gridOptions.detailCellRendererParams.getDetailRowData");
      return;
    }
    const successCallback = (rowData) => {
      const mostRecentCall = this.loadRowDataVersion === versionThisCall;
      if (mostRecentCall) {
        this.comp.setRowData(rowData);
      }
    };
    const funcParams = {
      node: this.params.node,
      // we take data from node, rather than params.data
      // as the data could have been updated with new instance
      data: this.params.node.data,
      successCallback,
      context: this.gos.getGridCommonParams().context
    };
    userFunc(funcParams);
  }
  refresh() {
    const GET_GRID_TO_REFRESH = false;
    const GET_GRID_TO_DO_NOTHING = true;
    switch (this.refreshStrategy) {
      case "nothing":
        return GET_GRID_TO_DO_NOTHING;
      case "everything":
        return GET_GRID_TO_REFRESH;
    }
    this.loadRowData();
    return GET_GRID_TO_DO_NOTHING;
  }
};
__decorateClass11([
  Autowired59("rowPositionUtils")
], DetailCellRendererCtrl.prototype, "rowPositionUtils", 2);
__decorateClass11([
  Autowired59("focusService")
], DetailCellRendererCtrl.prototype, "focusService", 2);
var _DetailCellRenderer = class _DetailCellRenderer2 extends Component50 {
  init(params) {
    this.params = params;
    this.selectAndSetTemplate();
    const compProxy = {
      addOrRemoveCssClass: (cssClassName, on) => this.addOrRemoveCssClass(cssClassName, on),
      addOrRemoveDetailGridCssClass: (cssClassName, on) => this.eDetailGrid.classList.toggle(cssClassName, on),
      setDetailGrid: (gridOptions) => this.setDetailGrid(gridOptions),
      setRowData: (rowData) => this.setRowData(rowData),
      getGui: () => this.eDetailGrid
    };
    this.ctrl = this.createManagedBean(new DetailCellRendererCtrl());
    this.ctrl.init(compProxy, params);
  }
  refresh() {
    return this.ctrl && this.ctrl.refresh();
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
  selectAndSetTemplate() {
    if (this.params.pinned) {
      this.setTemplate('<div class="ag-details-row"></div>');
      return;
    }
    const setDefaultTemplate = () => {
      this.setTemplate(_DetailCellRenderer2.TEMPLATE);
    };
    if (_214.missing(this.params.template)) {
      setDefaultTemplate();
    } else {
      if (typeof this.params.template === "string") {
        this.setTemplate(this.params.template);
      } else if (typeof this.params.template === "function") {
        const templateFunc = this.params.template;
        const template = templateFunc(this.params);
        this.setTemplate(template);
      } else {
        console.warn("AG Grid: detailCellRendererParams.template should be function or string");
        setDefaultTemplate();
      }
    }
    if (this.eDetailGrid == null) {
      console.warn('AG Grid: reference to eDetailGrid was missing from the details template. Please add ref="eDetailGrid" to the template.');
    }
  }
  setDetailGrid(gridOptions) {
    if (!this.eDetailGrid) {
      return;
    }
    const agGridReact = this.context.getBean("agGridReact");
    const agGridReactCloned = agGridReact ? _214.cloneObject(agGridReact) : void 0;
    const frameworkComponentWrapper = this.context.getBean("frameworkComponentWrapper");
    const frameworkOverrides = this.getFrameworkOverrides();
    const api = createGrid(this.eDetailGrid, gridOptions, {
      frameworkOverrides,
      providedBeanInstances: {
        agGridReact: agGridReactCloned,
        frameworkComponentWrapper
      },
      modules: ModuleRegistry4.__getGridRegisteredModules(this.params.api.getGridId())
    });
    this.detailApi = api;
    this.ctrl.registerDetailWithMaster(api, new ColumnApi2(api));
    this.addDestroyFunc(() => {
      api == null ? void 0 : api.destroy();
    });
  }
  setRowData(rowData) {
    this.detailApi && this.detailApi.setGridOption("rowData", rowData);
  }
};
_DetailCellRenderer.TEMPLATE = /* html */
`<div class="ag-details-row" role="gridcell">
            <div ref="eDetailGrid" class="ag-details-grid" role="presentation"></div>
        </div>`;
__decorateClass11([
  RefSelector19("eDetailGrid")
], _DetailCellRenderer.prototype, "eDetailGrid", 2);
var DetailCellRenderer = _DetailCellRenderer;
var VERSION11 = "31.3.2";
var MasterDetailModule = {
  version: VERSION11,
  moduleName: ModuleNames11.MasterDetailModule,
  beans: [],
  userComponents: [
    { componentName: "agDetailCellRenderer", componentClass: DetailCellRenderer }
  ],
  controllers: [
    { controllerName: "detailCellRenderer", controllerClass: DetailCellRendererCtrl }
  ],
  dependantModules: [
    EnterpriseCoreModule
  ]
};

// enterprise-modules/menu/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames52 } from "ag-grid-community";
import {
  _ as _49,
  Autowired as Autowired60,
  Bean as Bean13,
  BeanStub as BeanStub20,
  ModuleNames as ModuleNames12,
  ModuleRegistry as ModuleRegistry5,
  PostConstruct as PostConstruct60,
  RefSelector as RefSelector20,
  AgPromise as AgPromise4,
  TabbedLayout as TabbedLayout2,
  AgMenuItemComponent as AgMenuItemComponent3,
  Component as Component51,
  Events as Events17,
  FilterWrapperComp as FilterWrapperComp3
} from "ag-grid-community";
import {
  _ as _215,
  AgMenuItemComponent as AgMenuItemComponent22,
  AgMenuList as AgMenuList22,
  Autowired as Autowired214,
  Bean as Bean27,
  BeanStub as BeanStub27,
  Component as Component213,
  ModuleNames as ModuleNames25,
  ModuleRegistry as ModuleRegistry22,
  Optional as Optional5,
  PostConstruct as PostConstruct214,
  Events as Events28
} from "ag-grid-community";
import {
  _ as _313,
  Autowired as Autowired314,
  Bean as Bean34,
  BeanStub as BeanStub34,
  ModuleNames as ModuleNames32,
  ModuleRegistry as ModuleRegistry32,
  Optional as Optional22
} from "ag-grid-community";
import { Bean as Bean44, BeanStub as BeanStub44, ModuleNames as ModuleNames42, ModuleRegistry as ModuleRegistry42, Optional as Optional32, _ as _410 } from "ag-grid-community";
import {
  AgDialog as AgDialog4,
  Autowired as Autowired414,
  Bean as Bean53,
  BeanStub as BeanStub54,
  Events as Events36
} from "ag-grid-community";
import {
  AgMenuList as AgMenuList3,
  Autowired as Autowired510,
  Bean as Bean63,
  BeanStub as BeanStub64,
  _ as _56
} from "ag-grid-community";
import {
  Autowired as Autowired65,
  Bean as Bean72,
  BeanStub as BeanStub73,
  _ as _66
} from "ag-grid-community";
var __defProp12 = Object.defineProperty;
var __defProps7 = Object.defineProperties;
var __getOwnPropDesc12 = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs7 = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols10 = Object.getOwnPropertySymbols;
var __hasOwnProp11 = Object.prototype.hasOwnProperty;
var __propIsEnum10 = Object.prototype.propertyIsEnumerable;
var __defNormalProp10 = (obj, key, value) => key in obj ? __defProp12(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues10 = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp11.call(b, prop))
      __defNormalProp10(a, prop, b[prop]);
  if (__getOwnPropSymbols10)
    for (var prop of __getOwnPropSymbols10(b)) {
      if (__propIsEnum10.call(b, prop))
        __defNormalProp10(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps7 = (a, b) => __defProps7(a, __getOwnPropDescs7(b));
var __decorateClass12 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc12(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp12(target, key, result);
  return result;
};
var EnterpriseMenuFactory = class extends BeanStub20 {
  hideActiveMenu() {
    this.destroyBean(this.activeMenu);
  }
  showMenuAfterMouseEvent(column, mouseEvent, containerType, filtersOnly) {
    const defaultTab = filtersOnly ? "filterMenuTab" : void 0;
    this.showMenu(column, (menu) => {
      var _a;
      const ePopup = menu.getGui();
      this.popupService.positionPopupUnderMouseEvent({
        type: containerType,
        column,
        mouseEvent,
        ePopup
      });
      if (defaultTab) {
        (_a = menu.showTab) == null ? void 0 : _a.call(menu, defaultTab);
      }
      this.dispatchVisibleChangedEvent(true, false, column, defaultTab);
    }, containerType, defaultTab, void 0, mouseEvent.target);
  }
  showMenuAfterButtonClick(column, eventSource, containerType, filtersOnly) {
    let multiplier = -1;
    let alignSide = "left";
    if (this.gos.get("enableRtl")) {
      multiplier = 1;
      alignSide = "right";
    }
    const defaultTab = filtersOnly ? "filterMenuTab" : void 0;
    const restrictToTabs = defaultTab ? [defaultTab] : void 0;
    const isLegacyMenuEnabled = this.menuService.isLegacyMenuEnabled();
    let nudgeX = (isLegacyMenuEnabled ? 9 : 4) * multiplier;
    let nudgeY = isLegacyMenuEnabled ? -23 : 4;
    this.showMenu(column, (menu) => {
      var _a;
      const ePopup = menu.getGui();
      this.popupService.positionPopupByComponent({
        type: containerType,
        column,
        eventSource,
        ePopup,
        alignSide,
        nudgeX,
        nudgeY,
        position: "under",
        keepWithinBounds: true
      });
      if (defaultTab) {
        (_a = menu.showTab) == null ? void 0 : _a.call(menu, defaultTab);
      }
      this.dispatchVisibleChangedEvent(true, false, column, defaultTab);
    }, containerType, defaultTab, restrictToTabs, eventSource);
  }
  showMenu(column, positionCallback, containerType, defaultTab, restrictToTabs, eventSource) {
    var _a;
    const { menu, eMenuGui, anchorToElement, restoreFocusParams } = this.getMenuParams(column, restrictToTabs, eventSource);
    const closedFuncs = [];
    closedFuncs.push(
      (e) => {
        const eComp = menu.getGui();
        this.destroyBean(menu);
        if (column) {
          column.setMenuVisible(false, "contextMenu");
          this.menuUtils.restoreFocusOnClose(restoreFocusParams, eComp, e);
        }
      }
    );
    const translate = this.localeService.getLocaleTextFunc();
    this.popupService.addPopup({
      modal: true,
      eChild: eMenuGui,
      closeOnEsc: true,
      closedCallback: (e) => {
        closedFuncs.forEach((f) => f(e));
        this.dispatchVisibleChangedEvent(false, false, column, defaultTab);
      },
      afterGuiAttached: (params) => menu.afterGuiAttached(Object.assign({}, { container: containerType }, params)),
      // if defaultTab is not present, positionCallback will be called
      // after `showTabBasedOnPreviousSelection` is called.
      positionCallback: !!defaultTab ? () => positionCallback(menu) : void 0,
      ariaLabel: translate("ariaLabelColumnMenu", "Column Menu")
    });
    if (!defaultTab) {
      (_a = menu.showTabBasedOnPreviousSelection) == null ? void 0 : _a.call(menu);
      positionCallback(menu);
    }
    if (this.menuService.isColumnMenuAnchoringEnabled()) {
      const stopAnchoringPromise = this.popupService.setPopupPositionRelatedToElement(eMenuGui, anchorToElement);
      if (stopAnchoringPromise && column) {
        this.addStopAnchoring(stopAnchoringPromise, column, closedFuncs);
      }
    }
    menu.addEventListener(TabbedColumnMenu.EVENT_TAB_SELECTED, (event) => {
      this.dispatchVisibleChangedEvent(false, true, column);
      this.lastSelectedTab = event.key;
      this.dispatchVisibleChangedEvent(true, true, column);
    });
    column == null ? void 0 : column.setMenuVisible(true, "contextMenu");
    this.activeMenu = menu;
    menu.addEventListener(BeanStub20.EVENT_DESTROYED, () => {
      if (this.activeMenu === menu) {
        this.activeMenu = null;
      }
    });
  }
  addStopAnchoring(stopAnchoringPromise, column, closedFuncsArr) {
    stopAnchoringPromise.then((stopAnchoringFunc) => {
      column.addEventListener("leftChanged", stopAnchoringFunc);
      column.addEventListener("visibleChanged", stopAnchoringFunc);
      closedFuncsArr.push(() => {
        column.removeEventListener("leftChanged", stopAnchoringFunc);
        column.removeEventListener("visibleChanged", stopAnchoringFunc);
      });
    });
  }
  getMenuParams(column, restrictToTabs, eventSource) {
    const restoreFocusParams = {
      column,
      headerPosition: this.focusService.getFocusedHeader(),
      columnIndex: this.columnModel.getAllDisplayedColumns().indexOf(column),
      eventSource
    };
    const menu = this.createMenu(column, restoreFocusParams, restrictToTabs, eventSource);
    return {
      menu,
      eMenuGui: menu.getGui(),
      anchorToElement: eventSource || this.ctrlsService.getGridBodyCtrl().getGui(),
      restoreFocusParams
    };
  }
  createMenu(column, restoreFocusParams, restrictToTabs, eventSource) {
    if (this.menuService.isLegacyMenuEnabled()) {
      return this.createBean(new TabbedColumnMenu(column, restoreFocusParams, this.lastSelectedTab, restrictToTabs, eventSource));
    } else {
      return this.createBean(new ColumnContextMenu(column, restoreFocusParams, eventSource));
    }
  }
  dispatchVisibleChangedEvent(visible, switchingTab, column, defaultTab) {
    var _a, _b;
    const event = {
      type: Events17.EVENT_COLUMN_MENU_VISIBLE_CHANGED,
      visible,
      switchingTab,
      key: (_b = (_a = this.lastSelectedTab) != null ? _a : defaultTab) != null ? _b : this.menuService.isLegacyMenuEnabled() ? TabbedColumnMenu.TAB_GENERAL : "columnMenu",
      column: column != null ? column : null
    };
    this.eventService.dispatchEvent(event);
  }
  isMenuEnabled(column) {
    var _a;
    if (!this.menuService.isLegacyMenuEnabled()) {
      return true;
    }
    const isFilterDisabled = !this.filterManager.isFilterAllowed(column);
    const tabs = (_a = column.getColDef().menuTabs) != null ? _a : TabbedColumnMenu.TABS_DEFAULT;
    const numActiveTabs = isFilterDisabled && tabs.includes(TabbedColumnMenu.TAB_FILTER) ? tabs.length - 1 : tabs.length;
    return numActiveTabs > 0;
  }
  showMenuAfterContextMenuEvent(column, mouseEvent, touchEvent) {
    this.menuUtils.onContextMenu(mouseEvent, touchEvent, (eventOrTouch) => {
      this.showMenuAfterMouseEvent(column, eventOrTouch, "columnMenu");
      return true;
    });
  }
};
__decorateClass12([
  Autowired60("popupService")
], EnterpriseMenuFactory.prototype, "popupService", 2);
__decorateClass12([
  Autowired60("focusService")
], EnterpriseMenuFactory.prototype, "focusService", 2);
__decorateClass12([
  Autowired60("ctrlsService")
], EnterpriseMenuFactory.prototype, "ctrlsService", 2);
__decorateClass12([
  Autowired60("columnModel")
], EnterpriseMenuFactory.prototype, "columnModel", 2);
__decorateClass12([
  Autowired60("filterManager")
], EnterpriseMenuFactory.prototype, "filterManager", 2);
__decorateClass12([
  Autowired60("menuUtils")
], EnterpriseMenuFactory.prototype, "menuUtils", 2);
__decorateClass12([
  Autowired60("menuService")
], EnterpriseMenuFactory.prototype, "menuService", 2);
EnterpriseMenuFactory = __decorateClass12([
  Bean13("enterpriseMenuFactory")
], EnterpriseMenuFactory);
var _TabbedColumnMenu = class _TabbedColumnMenu2 extends BeanStub20 {
  constructor(column, restoreFocusParams, initialSelection, restrictTo, sourceElement) {
    super();
    this.column = column;
    this.restoreFocusParams = restoreFocusParams;
    this.initialSelection = initialSelection;
    this.restrictTo = restrictTo;
    this.sourceElement = sourceElement;
    this.tabFactories = {};
    this.includeChecks = {};
    this.tabFactories[_TabbedColumnMenu2.TAB_GENERAL] = this.createMainPanel.bind(this);
    this.tabFactories[_TabbedColumnMenu2.TAB_FILTER] = this.createFilterPanel.bind(this);
    this.tabFactories[_TabbedColumnMenu2.TAB_COLUMNS] = this.createColumnsPanel.bind(this);
    this.includeChecks[_TabbedColumnMenu2.TAB_GENERAL] = () => true;
    this.includeChecks[_TabbedColumnMenu2.TAB_FILTER] = () => column ? this.filterManager.isFilterAllowed(column) : false;
    this.includeChecks[_TabbedColumnMenu2.TAB_COLUMNS] = () => true;
  }
  init() {
    const tabs = this.getTabsToCreate().map((name) => this.createTab(name));
    this.tabbedLayout = new TabbedLayout2({
      items: tabs,
      cssClass: "ag-menu",
      onActiveItemClicked: this.onHidePopup.bind(this),
      onItemClicked: this.onTabItemClicked.bind(this)
    });
    this.createBean(this.tabbedLayout);
    if (this.mainMenuList) {
      this.mainMenuList.setParentComponent(this.tabbedLayout);
    }
    this.addDestroyFunc(() => this.destroyBean(this.tabbedLayout));
  }
  getTabsToCreate() {
    var _a, _b;
    if (this.restrictTo) {
      return this.restrictTo;
    }
    return ((_b = (_a = this.column) == null ? void 0 : _a.getColDef().menuTabs) != null ? _b : _TabbedColumnMenu2.TABS_DEFAULT).filter((tabName) => this.isValidMenuTabItem(tabName)).filter((tabName) => this.isNotSuppressed(tabName)).filter((tabName) => this.isModuleLoaded(tabName));
  }
  isModuleLoaded(menuTabName) {
    if (menuTabName === _TabbedColumnMenu2.TAB_COLUMNS) {
      return ModuleRegistry5.__isRegistered(ModuleNames12.ColumnsToolPanelModule, this.context.getGridId());
    }
    return true;
  }
  isValidMenuTabItem(menuTabName) {
    let isValid = true;
    let itemsToConsider = _TabbedColumnMenu2.TABS_DEFAULT;
    if (this.restrictTo != null) {
      isValid = this.restrictTo.indexOf(menuTabName) > -1;
      itemsToConsider = this.restrictTo;
    }
    isValid = isValid && _TabbedColumnMenu2.TABS_DEFAULT.indexOf(menuTabName) > -1;
    if (!isValid) {
      console.warn(`AG Grid: Trying to render an invalid menu item '${menuTabName}'. Check that your 'menuTabs' contains one of [${itemsToConsider}]`);
    }
    return isValid;
  }
  isNotSuppressed(menuTabName) {
    return this.includeChecks[menuTabName]();
  }
  createTab(name) {
    return this.tabFactories[name]();
  }
  showTabBasedOnPreviousSelection() {
    this.showTab(this.initialSelection);
  }
  showTab(toShow) {
    if (this.tabItemColumns && toShow === _TabbedColumnMenu2.TAB_COLUMNS) {
      this.tabbedLayout.showItem(this.tabItemColumns);
    } else if (this.tabItemFilter && toShow === _TabbedColumnMenu2.TAB_FILTER) {
      this.tabbedLayout.showItem(this.tabItemFilter);
    } else if (this.tabItemGeneral && toShow === _TabbedColumnMenu2.TAB_GENERAL) {
      this.tabbedLayout.showItem(this.tabItemGeneral);
    } else {
      this.tabbedLayout.showFirstItem();
    }
  }
  onTabItemClicked(event) {
    let key = null;
    switch (event.item) {
      case this.tabItemColumns:
        key = _TabbedColumnMenu2.TAB_COLUMNS;
        break;
      case this.tabItemFilter:
        key = _TabbedColumnMenu2.TAB_FILTER;
        break;
      case this.tabItemGeneral:
        key = _TabbedColumnMenu2.TAB_GENERAL;
        break;
    }
    if (key) {
      this.activateTab(key);
    }
  }
  activateTab(tab) {
    const ev = {
      type: _TabbedColumnMenu2.EVENT_TAB_SELECTED,
      key: tab
    };
    this.dispatchEvent(ev);
  }
  createMainPanel() {
    this.mainMenuList = this.columnMenuFactory.createMenu(this, this.column, () => {
      var _a;
      return (_a = this.sourceElement) != null ? _a : this.getGui();
    });
    this.mainMenuList.addEventListener(AgMenuItemComponent3.EVENT_CLOSE_MENU, this.onHidePopup.bind(this));
    this.tabItemGeneral = {
      title: _49.createIconNoSpan("menu", this.gos, this.column),
      titleLabel: _TabbedColumnMenu2.TAB_GENERAL.replace("MenuTab", ""),
      bodyPromise: AgPromise4.resolve(this.mainMenuList.getGui()),
      name: _TabbedColumnMenu2.TAB_GENERAL
    };
    return this.tabItemGeneral;
  }
  onHidePopup(event) {
    this.menuUtils.closePopupAndRestoreFocusOnSelect(this.hidePopupFunc, this.restoreFocusParams, event);
  }
  createFilterPanel() {
    const comp = this.column ? this.createManagedBean(new FilterWrapperComp3(this.column, "COLUMN_MENU")) : null;
    if (!(comp == null ? void 0 : comp.hasFilter())) {
      throw new Error("AG Grid - Unable to instantiate filter");
    }
    const afterAttachedCallback = (params) => comp.afterGuiAttached(params);
    const afterDetachedCallback = () => comp.afterGuiDetached();
    this.tabItemFilter = {
      title: _49.createIconNoSpan("filter", this.gos, this.column),
      titleLabel: _TabbedColumnMenu2.TAB_FILTER.replace("MenuTab", ""),
      bodyPromise: AgPromise4.resolve(comp == null ? void 0 : comp.getGui()),
      afterAttachedCallback,
      afterDetachedCallback,
      name: _TabbedColumnMenu2.TAB_FILTER
    };
    return this.tabItemFilter;
  }
  createColumnsPanel() {
    const eWrapperDiv = document.createElement("div");
    eWrapperDiv.classList.add("ag-menu-column-select-wrapper");
    const columnSelectPanel = this.columnChooserFactory.createColumnSelectPanel(this, this.column);
    const columnSelectPanelGui = columnSelectPanel.getGui();
    columnSelectPanelGui.classList.add("ag-menu-column-select");
    eWrapperDiv.appendChild(columnSelectPanelGui);
    this.tabItemColumns = {
      title: _49.createIconNoSpan("columns", this.gos, this.column),
      //createColumnsIcon(),
      titleLabel: _TabbedColumnMenu2.TAB_COLUMNS.replace("MenuTab", ""),
      bodyPromise: AgPromise4.resolve(eWrapperDiv),
      name: _TabbedColumnMenu2.TAB_COLUMNS
    };
    return this.tabItemColumns;
  }
  afterGuiAttached(params) {
    const { container, hidePopup } = params;
    this.tabbedLayout.setAfterAttachedParams({ container, hidePopup });
    if (hidePopup) {
      this.hidePopupFunc = hidePopup;
      this.addDestroyFunc(hidePopup);
    }
  }
  getGui() {
    return this.tabbedLayout.getGui();
  }
};
_TabbedColumnMenu.EVENT_TAB_SELECTED = "tabSelected";
_TabbedColumnMenu.TAB_FILTER = "filterMenuTab";
_TabbedColumnMenu.TAB_GENERAL = "generalMenuTab";
_TabbedColumnMenu.TAB_COLUMNS = "columnsMenuTab";
_TabbedColumnMenu.TABS_DEFAULT = [_TabbedColumnMenu.TAB_GENERAL, _TabbedColumnMenu.TAB_FILTER, _TabbedColumnMenu.TAB_COLUMNS];
__decorateClass12([
  Autowired60("filterManager")
], _TabbedColumnMenu.prototype, "filterManager", 2);
__decorateClass12([
  Autowired60("columnChooserFactory")
], _TabbedColumnMenu.prototype, "columnChooserFactory", 2);
__decorateClass12([
  Autowired60("columnMenuFactory")
], _TabbedColumnMenu.prototype, "columnMenuFactory", 2);
__decorateClass12([
  Autowired60("menuUtils")
], _TabbedColumnMenu.prototype, "menuUtils", 2);
__decorateClass12([
  PostConstruct60
], _TabbedColumnMenu.prototype, "init", 1);
var TabbedColumnMenu = _TabbedColumnMenu;
var ColumnContextMenu = class extends Component51 {
  constructor(column, restoreFocusParams, sourceElement) {
    super(
      /* html */
      `
            <div ref="eColumnMenu" role="presentation" class="ag-menu ag-column-menu"></div>
        `
    );
    this.column = column;
    this.restoreFocusParams = restoreFocusParams;
    this.sourceElement = sourceElement;
  }
  init() {
    this.mainMenuList = this.columnMenuFactory.createMenu(this, this.column, () => {
      var _a;
      return (_a = this.sourceElement) != null ? _a : this.getGui();
    });
    this.mainMenuList.addEventListener(AgMenuItemComponent3.EVENT_CLOSE_MENU, this.onHidePopup.bind(this));
    this.eColumnMenu.appendChild(this.mainMenuList.getGui());
  }
  onHidePopup(event) {
    this.menuUtils.closePopupAndRestoreFocusOnSelect(this.hidePopupFunc, this.restoreFocusParams, event);
  }
  afterGuiAttached({ hidePopup }) {
    if (hidePopup) {
      this.hidePopupFunc = hidePopup;
      this.addDestroyFunc(hidePopup);
    }
    this.focusService.focusInto(this.mainMenuList.getGui());
  }
};
__decorateClass12([
  Autowired60("columnMenuFactory")
], ColumnContextMenu.prototype, "columnMenuFactory", 2);
__decorateClass12([
  Autowired60("menuUtils")
], ColumnContextMenu.prototype, "menuUtils", 2);
__decorateClass12([
  Autowired60("focusService")
], ColumnContextMenu.prototype, "focusService", 2);
__decorateClass12([
  RefSelector20("eColumnMenu")
], ColumnContextMenu.prototype, "eColumnMenu", 2);
__decorateClass12([
  PostConstruct60
], ColumnContextMenu.prototype, "init", 1);
var CSS_MENU = "ag-menu";
var CSS_CONTEXT_MENU_OPEN = "ag-context-menu-open";
var ContextMenuFactory = class extends BeanStub27 {
  hideActiveMenu() {
    this.destroyBean(this.activeMenu);
  }
  getMenuItems(node, column, value) {
    const defaultMenuOptions = [];
    if (_215.exists(node) && ModuleRegistry22.__isRegistered(ModuleNames25.ClipboardModule, this.context.getGridId())) {
      if (column) {
        if (!this.gos.get("suppressCutToClipboard")) {
          defaultMenuOptions.push("cut");
        }
        defaultMenuOptions.push("copy", "copyWithHeaders", "copyWithGroupHeaders", "paste", "separator");
      }
    }
    if (this.gos.get("enableCharts") && ModuleRegistry22.__isRegistered(ModuleNames25.GridChartsModule, this.context.getGridId())) {
      if (this.columnModel.isPivotMode()) {
        defaultMenuOptions.push("pivotChart");
      }
      if (this.rangeService && !this.rangeService.isEmpty()) {
        defaultMenuOptions.push("chartRange");
      }
    }
    if (_215.exists(node)) {
      const csvModuleMissing = !ModuleRegistry22.__isRegistered(ModuleNames25.CsvExportModule, this.context.getGridId());
      const excelModuleMissing = !ModuleRegistry22.__isRegistered(ModuleNames25.ExcelExportModule, this.context.getGridId());
      const suppressExcel = this.gos.get("suppressExcelExport") || excelModuleMissing;
      const suppressCsv = this.gos.get("suppressCsvExport") || csvModuleMissing;
      const onIPad = _215.isIOSUserAgent();
      const anyExport = !onIPad && (!suppressExcel || !suppressCsv);
      if (anyExport) {
        defaultMenuOptions.push("export");
      }
    }
    const defaultItems = defaultMenuOptions.length ? defaultMenuOptions : void 0;
    const columnContextMenuItems = column == null ? void 0 : column.getColDef().contextMenuItems;
    if (Array.isArray(columnContextMenuItems)) {
      return columnContextMenuItems;
    }
    if (typeof columnContextMenuItems === "function") {
      return columnContextMenuItems(this.gos.addGridCommonParams({
        column,
        node,
        value,
        defaultItems
      }));
    }
    const userFunc = this.gos.getCallback("getContextMenuItems");
    if (userFunc) {
      return userFunc({ column, node, value, defaultItems });
    }
    return defaultMenuOptions;
  }
  onContextMenu(mouseEvent, touchEvent, rowNode, column, value, anchorToElement) {
    this.menuUtils.onContextMenu(mouseEvent, touchEvent, (eventOrTouch) => this.showMenu(rowNode, column, value, eventOrTouch, anchorToElement));
  }
  showMenu(node, column, value, mouseEvent, anchorToElement) {
    const menuItems = this.getMenuItems(node, column, value);
    const eGridBodyGui = this.ctrlsService.getGridBodyCtrl().getGui();
    if (menuItems === void 0 || _215.missingOrEmpty(menuItems)) {
      return false;
    }
    const menu = new ContextMenu(menuItems, column, node, value);
    this.createBean(menu);
    const eMenuGui = menu.getGui();
    const positionParams = {
      column,
      rowNode: node,
      type: "contextMenu",
      mouseEvent,
      ePopup: eMenuGui,
      // move one pixel away so that accidentally double clicking
      // won't show the browser's contextmenu
      nudgeY: 1
    };
    const translate = this.localeService.getLocaleTextFunc();
    const addPopupRes = this.popupService.addPopup({
      modal: true,
      eChild: eMenuGui,
      closeOnEsc: true,
      closedCallback: (e) => {
        eGridBodyGui.classList.remove(CSS_CONTEXT_MENU_OPEN);
        this.destroyBean(menu);
        this.dispatchVisibleChangedEvent(false, e === void 0 ? "api" : "ui");
      },
      click: mouseEvent,
      positionCallback: () => {
        const isRtl = this.gos.get("enableRtl");
        this.popupService.positionPopupUnderMouseEvent(__spreadProps7(__spreadValues10({}, positionParams), {
          nudgeX: isRtl ? (eMenuGui.offsetWidth + 1) * -1 : 1
        }));
      },
      // so when browser is scrolled down, or grid is scrolled, context menu stays with cell
      anchorToElement,
      ariaLabel: translate("ariaLabelContextMenu", "Context Menu")
    });
    if (addPopupRes) {
      eGridBodyGui.classList.add(CSS_CONTEXT_MENU_OPEN);
      menu.afterGuiAttached({ container: "contextMenu", hidePopup: addPopupRes.hideFunc });
    }
    if (this.activeMenu) {
      this.hideActiveMenu();
    }
    this.activeMenu = menu;
    menu.addEventListener(BeanStub27.EVENT_DESTROYED, () => {
      if (this.activeMenu === menu) {
        this.activeMenu = null;
      }
    });
    if (addPopupRes) {
      menu.addEventListener(AgMenuItemComponent22.EVENT_CLOSE_MENU, (e) => {
        var _a, _b;
        return addPopupRes.hideFunc({
          mouseEvent: (_a = e.mouseEvent) != null ? _a : void 0,
          keyboardEvent: (_b = e.keyboardEvent) != null ? _b : void 0,
          forceHide: true
        });
      });
    }
    const isApi = mouseEvent && mouseEvent instanceof MouseEvent && mouseEvent.type === "mousedown";
    this.dispatchVisibleChangedEvent(true, isApi ? "api" : "ui");
    return true;
  }
  dispatchVisibleChangedEvent(visible, source = "ui") {
    const displayedEvent = {
      type: Events28.EVENT_CONTEXT_MENU_VISIBLE_CHANGED,
      visible,
      source
    };
    this.eventService.dispatchEvent(displayedEvent);
  }
};
__decorateClass12([
  Autowired214("popupService")
], ContextMenuFactory.prototype, "popupService", 2);
__decorateClass12([
  Autowired214("ctrlsService")
], ContextMenuFactory.prototype, "ctrlsService", 2);
__decorateClass12([
  Autowired214("columnModel")
], ContextMenuFactory.prototype, "columnModel", 2);
__decorateClass12([
  Autowired214("menuUtils")
], ContextMenuFactory.prototype, "menuUtils", 2);
__decorateClass12([
  Optional5("rangeService")
], ContextMenuFactory.prototype, "rangeService", 2);
ContextMenuFactory = __decorateClass12([
  Bean27("contextMenuFactory")
], ContextMenuFactory);
var ContextMenu = class extends Component213 {
  constructor(menuItems, column, node, value) {
    super(
      /* html */
      `<div class="${CSS_MENU}" role="presentation"></div>`
    );
    this.menuItems = menuItems;
    this.column = column;
    this.node = node;
    this.value = value;
    this.menuList = null;
    this.focusedCell = null;
  }
  addMenuItems() {
    const menuList = this.createManagedBean(new AgMenuList22(0, {
      column: this.column,
      node: this.node,
      value: this.value
    }));
    const menuItemsMapped = this.menuItemMapper.mapWithStockItems(this.menuItems, null, () => this.getGui());
    menuList.addMenuItems(menuItemsMapped);
    this.appendChild(menuList);
    this.menuList = menuList;
    menuList.addEventListener(AgMenuItemComponent22.EVENT_CLOSE_MENU, (e) => this.dispatchEvent(e));
  }
  afterGuiAttached(params) {
    if (params.hidePopup) {
      this.addDestroyFunc(params.hidePopup);
    }
    this.focusedCell = this.focusService.getFocusedCell();
    if (this.menuList) {
      this.focusService.focusInto(this.menuList.getGui());
    }
  }
  restoreFocusedCell() {
    const currentFocusedCell = this.focusService.getFocusedCell();
    if (currentFocusedCell && this.focusedCell && this.cellPositionUtils.equals(currentFocusedCell, this.focusedCell)) {
      const { rowIndex, rowPinned, column } = this.focusedCell;
      const doc = this.gos.getDocument();
      const activeEl = this.gos.getActiveDomElement();
      if (!activeEl || activeEl === doc.body) {
        this.focusService.setFocusedCell({
          rowIndex,
          column,
          rowPinned,
          forceBrowserFocus: true,
          preventScrollOnBrowserFocus: !this.focusService.isKeyboardMode()
        });
      }
    }
  }
  destroy() {
    this.restoreFocusedCell();
    super.destroy();
  }
};
__decorateClass12([
  Autowired214("menuItemMapper")
], ContextMenu.prototype, "menuItemMapper", 2);
__decorateClass12([
  Autowired214("focusService")
], ContextMenu.prototype, "focusService", 2);
__decorateClass12([
  Autowired214("cellPositionUtils")
], ContextMenu.prototype, "cellPositionUtils", 2);
__decorateClass12([
  PostConstruct214
], ContextMenu.prototype, "addMenuItems", 1);
var MenuItemMapper = class extends BeanStub34 {
  mapWithStockItems(originalList, column, sourceElement) {
    if (!originalList) {
      return [];
    }
    const resultList = [];
    originalList.forEach((menuItemOrString) => {
      let result;
      if (typeof menuItemOrString === "string") {
        result = this.getStockMenuItem(menuItemOrString, column, sourceElement);
      } else {
        result = __spreadValues10({}, menuItemOrString);
      }
      if (!result) {
        return;
      }
      const resultDef = result;
      const { subMenu } = resultDef;
      if (subMenu && subMenu instanceof Array) {
        resultDef.subMenu = this.mapWithStockItems(subMenu, column, sourceElement);
      }
      if (result != null) {
        resultList.push(result);
      }
    });
    return resultList;
  }
  getStockMenuItem(key, column, sourceElement) {
    var _a;
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const skipHeaderOnAutoSize = this.gos.get("skipHeaderOnAutoSize");
    switch (key) {
      case "pinSubMenu":
        return {
          name: localeTextFunc("pinColumn", "Pin Column"),
          icon: _313.createIconNoSpan("menuPin", this.gos, null),
          subMenu: ["clearPinned", "pinLeft", "pinRight"]
        };
      case "pinLeft":
        return {
          name: localeTextFunc("pinLeft", "Pin Left"),
          action: () => this.columnModel.setColumnsPinned([column], "left", "contextMenu"),
          checked: !!column && column.isPinnedLeft()
        };
      case "pinRight":
        return {
          name: localeTextFunc("pinRight", "Pin Right"),
          action: () => this.columnModel.setColumnsPinned([column], "right", "contextMenu"),
          checked: !!column && column.isPinnedRight()
        };
      case "clearPinned":
        return {
          name: localeTextFunc("noPin", "No Pin"),
          action: () => this.columnModel.setColumnsPinned([column], null, "contextMenu"),
          checked: !!column && !column.isPinned()
        };
      case "valueAggSubMenu":
        if (ModuleRegistry32.__assertRegistered(ModuleNames32.RowGroupingModule, "Aggregation from Menu", this.context.getGridId())) {
          if (!(column == null ? void 0 : column.isPrimary()) && !(column == null ? void 0 : column.getColDef().pivotValueColumn)) {
            return null;
          }
          return {
            name: localeTextFunc("valueAggregation", "Value Aggregation"),
            icon: _313.createIconNoSpan("menuValue", this.gos, null),
            subMenu: this.createAggregationSubMenu(column, this.aggFuncService)
          };
        } else {
          return null;
        }
      case "autoSizeThis":
        return {
          name: localeTextFunc("autosizeThiscolumn", "Autosize This Column"),
          action: () => this.columnModel.autoSizeColumn(column, "contextMenu", skipHeaderOnAutoSize)
        };
      case "autoSizeAll":
        return {
          name: localeTextFunc("autosizeAllColumns", "Autosize All Columns"),
          action: () => this.columnModel.autoSizeAllColumns("contextMenu", skipHeaderOnAutoSize)
        };
      case "rowGroup":
        return {
          name: localeTextFunc("groupBy", "Group by") + " " + _313.escapeString(this.columnModel.getDisplayNameForColumn(column, "header")),
          disabled: (column == null ? void 0 : column.isRowGroupActive()) || !(column == null ? void 0 : column.getColDef().enableRowGroup),
          action: () => this.columnModel.addRowGroupColumns([column], "contextMenu"),
          icon: _313.createIconNoSpan("menuAddRowGroup", this.gos, null)
        };
      case "rowUnGroup":
        const icon = _313.createIconNoSpan("menuRemoveRowGroup", this.gos, null);
        const showRowGroup = column == null ? void 0 : column.getColDef().showRowGroup;
        const lockedGroups = this.gos.get("groupLockGroupColumns");
        if (showRowGroup === true) {
          return {
            name: localeTextFunc("ungroupAll", "Un-Group All"),
            disabled: lockedGroups === -1 || lockedGroups >= this.columnModel.getRowGroupColumns().length,
            action: () => this.columnModel.setRowGroupColumns(this.columnModel.getRowGroupColumns().slice(0, lockedGroups), "contextMenu"),
            icon
          };
        }
        if (typeof showRowGroup === "string") {
          const underlyingColumn = this.columnModel.getPrimaryColumn(showRowGroup);
          const ungroupByName = underlyingColumn != null ? _313.escapeString(this.columnModel.getDisplayNameForColumn(underlyingColumn, "header")) : showRowGroup;
          return {
            name: localeTextFunc("ungroupBy", "Un-Group by") + " " + ungroupByName,
            disabled: underlyingColumn != null && this.columnModel.isColumnGroupingLocked(underlyingColumn),
            action: () => this.columnModel.removeRowGroupColumns([showRowGroup], "contextMenu"),
            icon
          };
        }
        return {
          name: localeTextFunc("ungroupBy", "Un-Group by") + " " + _313.escapeString(this.columnModel.getDisplayNameForColumn(column, "header")),
          disabled: !(column == null ? void 0 : column.isRowGroupActive()) || !(column == null ? void 0 : column.getColDef().enableRowGroup) || this.columnModel.isColumnGroupingLocked(column),
          action: () => this.columnModel.removeRowGroupColumns([column], "contextMenu"),
          icon
        };
      case "resetColumns":
        return {
          name: localeTextFunc("resetColumns", "Reset Columns"),
          action: () => this.columnModel.resetColumnState("contextMenu")
        };
      case "expandAll":
        return {
          name: localeTextFunc("expandAll", "Expand All Row Groups"),
          action: () => this.gridApi.expandAll()
        };
      case "contractAll":
        return {
          name: localeTextFunc("collapseAll", "Collapse All Row Groups"),
          action: () => this.gridApi.collapseAll()
        };
      case "copy":
        if (ModuleRegistry32.__assertRegistered(ModuleNames32.ClipboardModule, "Copy from Menu", this.context.getGridId())) {
          return {
            name: localeTextFunc("copy", "Copy"),
            shortcut: localeTextFunc("ctrlC", "Ctrl+C"),
            icon: _313.createIconNoSpan("clipboardCopy", this.gos, null),
            action: () => this.clipboardService.copyToClipboard()
          };
        } else {
          return null;
        }
      case "copyWithHeaders":
        if (ModuleRegistry32.__assertRegistered(ModuleNames32.ClipboardModule, "Copy with Headers from Menu", this.context.getGridId())) {
          return {
            name: localeTextFunc("copyWithHeaders", "Copy with Headers"),
            // shortcut: localeTextFunc('ctrlC','Ctrl+C'),
            icon: _313.createIconNoSpan("clipboardCopy", this.gos, null),
            action: () => this.clipboardService.copyToClipboard({ includeHeaders: true })
          };
        } else {
          return null;
        }
      case "copyWithGroupHeaders":
        if (ModuleRegistry32.__assertRegistered(ModuleNames32.ClipboardModule, "Copy with Group Headers from Menu", this.context.getGridId())) {
          return {
            name: localeTextFunc("copyWithGroupHeaders", "Copy with Group Headers"),
            // shortcut: localeTextFunc('ctrlC','Ctrl+C'),
            icon: _313.createIconNoSpan("clipboardCopy", this.gos, null),
            action: () => this.clipboardService.copyToClipboard({ includeHeaders: true, includeGroupHeaders: true })
          };
        } else {
          return null;
        }
      case "cut":
        if (ModuleRegistry32.__assertRegistered(ModuleNames32.ClipboardModule, "Cut from Menu", this.context.getGridId())) {
          const focusedCell = this.focusService.getFocusedCell();
          const rowNode = focusedCell ? this.rowPositionUtils.getRowNode(focusedCell) : null;
          const isEditable = rowNode ? focusedCell == null ? void 0 : focusedCell.column.isCellEditable(rowNode) : false;
          return {
            name: localeTextFunc("cut", "Cut"),
            shortcut: localeTextFunc("ctrlX", "Ctrl+X"),
            icon: _313.createIconNoSpan("clipboardCut", this.gos, null),
            disabled: !isEditable || this.gos.get("suppressCutToClipboard"),
            action: () => this.clipboardService.cutToClipboard(void 0, "contextMenu")
          };
        } else {
          return null;
        }
      case "paste":
        if (ModuleRegistry32.__assertRegistered(ModuleNames32.ClipboardModule, "Paste from Clipboard", this.context.getGridId())) {
          return {
            name: localeTextFunc("paste", "Paste"),
            shortcut: localeTextFunc("ctrlV", "Ctrl+V"),
            disabled: true,
            icon: _313.createIconNoSpan("clipboardPaste", this.gos, null),
            action: () => this.clipboardService.pasteFromClipboard()
          };
        } else {
          return null;
        }
      case "export":
        const exportSubMenuItems = [];
        const csvModuleLoaded = ModuleRegistry32.__isRegistered(ModuleNames32.CsvExportModule, this.context.getGridId());
        const excelModuleLoaded = ModuleRegistry32.__isRegistered(ModuleNames32.ExcelExportModule, this.context.getGridId());
        if (!this.gos.get("suppressCsvExport") && csvModuleLoaded) {
          exportSubMenuItems.push("csvExport");
        }
        if (!this.gos.get("suppressExcelExport") && excelModuleLoaded) {
          exportSubMenuItems.push("excelExport");
        }
        return {
          name: localeTextFunc("export", "Export"),
          subMenu: exportSubMenuItems,
          icon: _313.createIconNoSpan("save", this.gos, null)
        };
      case "csvExport":
        return {
          name: localeTextFunc("csvExport", "CSV Export"),
          icon: _313.createIconNoSpan("csvExport", this.gos, null),
          action: () => this.gridApi.exportDataAsCsv({})
        };
      case "excelExport":
        return {
          name: localeTextFunc("excelExport", "Excel Export"),
          icon: _313.createIconNoSpan("excelExport", this.gos, null),
          action: () => this.gridApi.exportDataAsExcel()
        };
      case "separator":
        return "separator";
      case "pivotChart":
      case "chartRange":
        return (_a = this.chartMenuItemMapper.getChartItems(key)) != null ? _a : null;
      case "columnFilter":
        if (column) {
          return {
            name: localeTextFunc("columnFilter", "Column Filter"),
            icon: _313.createIconNoSpan("filter", this.gos, null),
            action: () => this.menuService.showFilterMenu({
              column,
              buttonElement: sourceElement(),
              containerType: "columnFilter",
              positionBy: "button"
            })
          };
        } else {
          return null;
        }
      case "columnChooser":
        if (ModuleRegistry32.__isRegistered(ModuleNames32.ColumnsToolPanelModule, this.context.getGridId())) {
          return {
            name: localeTextFunc("columnChooser", "Choose Columns"),
            icon: _313.createIconNoSpan("columns", this.gos, null),
            action: () => this.menuService.showColumnChooser({ column, eventSource: sourceElement() })
          };
        } else {
          return null;
        }
      case "sortAscending":
        return {
          name: localeTextFunc("sortAscending", "Sort Ascending"),
          icon: _313.createIconNoSpan("sortAscending", this.gos, null),
          action: () => this.sortController.setSortForColumn(column, "asc", false, "columnMenu")
        };
      case "sortDescending":
        return {
          name: localeTextFunc("sortDescending", "Sort Descending"),
          icon: _313.createIconNoSpan("sortDescending", this.gos, null),
          action: () => this.sortController.setSortForColumn(column, "desc", false, "columnMenu")
        };
      case "sortUnSort":
        return {
          name: localeTextFunc("sortUnSort", "Clear Sort"),
          icon: _313.createIconNoSpan("sortUnSort", this.gos, null),
          action: () => this.sortController.setSortForColumn(column, null, false, "columnMenu")
        };
      default: {
        console.warn(`AG Grid: unknown menu item type ${key}`);
        return null;
      }
    }
  }
  createAggregationSubMenu(column, aggFuncService) {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    let columnToUse;
    if (column.isPrimary()) {
      columnToUse = column;
    } else {
      const pivotValueColumn = column.getColDef().pivotValueColumn;
      columnToUse = _313.exists(pivotValueColumn) ? pivotValueColumn : void 0;
    }
    const result = [];
    if (columnToUse) {
      const columnIsAlreadyAggValue = columnToUse.isValueActive();
      const funcNames = aggFuncService.getFuncNames(columnToUse);
      result.push({
        name: localeTextFunc("noAggregation", "None"),
        action: () => {
          this.columnModel.removeValueColumns([columnToUse], "contextMenu");
          this.columnModel.setColumnAggFunc(columnToUse, void 0, "contextMenu");
        },
        checked: !columnIsAlreadyAggValue
      });
      funcNames.forEach((funcName) => {
        result.push({
          name: localeTextFunc(funcName, aggFuncService.getDefaultFuncLabel(funcName)),
          action: () => {
            this.columnModel.setColumnAggFunc(columnToUse, funcName, "contextMenu");
            this.columnModel.addValueColumns([columnToUse], "contextMenu");
          },
          checked: columnIsAlreadyAggValue && columnToUse.getAggFunc() === funcName
        });
      });
    }
    return result;
  }
};
__decorateClass12([
  Autowired314("columnModel")
], MenuItemMapper.prototype, "columnModel", 2);
__decorateClass12([
  Autowired314("gridApi")
], MenuItemMapper.prototype, "gridApi", 2);
__decorateClass12([
  Autowired314("focusService")
], MenuItemMapper.prototype, "focusService", 2);
__decorateClass12([
  Autowired314("rowPositionUtils")
], MenuItemMapper.prototype, "rowPositionUtils", 2);
__decorateClass12([
  Autowired314("chartMenuItemMapper")
], MenuItemMapper.prototype, "chartMenuItemMapper", 2);
__decorateClass12([
  Autowired314("menuService")
], MenuItemMapper.prototype, "menuService", 2);
__decorateClass12([
  Autowired314("sortController")
], MenuItemMapper.prototype, "sortController", 2);
__decorateClass12([
  Optional22("clipboardService")
], MenuItemMapper.prototype, "clipboardService", 2);
__decorateClass12([
  Optional22("aggFuncService")
], MenuItemMapper.prototype, "aggFuncService", 2);
MenuItemMapper = __decorateClass12([
  Bean34("menuItemMapper")
], MenuItemMapper);
var VERSION12 = "31.3.2";
var ChartMenuItemMapper = class extends BeanStub44 {
  getChartItems(key) {
    var _a, _b;
    if (!this.chartService) {
      ModuleRegistry42.__assertRegistered(ModuleNames42.GridChartsModule, `the Context Menu key "${key}"`, this.context.getGridId());
      return void 0;
    }
    const builder = key === "pivotChart" ? new PivotMenuItemMapper(this.gos, this.chartService, this.localeService) : new RangeMenuItemMapper(this.gos, this.chartService, this.localeService);
    const isEnterprise = this.chartService.isEnterprise();
    let topLevelMenuItem = builder.getMenuItem();
    if (topLevelMenuItem && topLevelMenuItem.subMenu && !isEnterprise) {
      const filterEnterpriseItems = (m) => {
        var _a2;
        return __spreadProps7(__spreadValues10({}, m), {
          subMenu: (_a2 = m.subMenu) == null ? void 0 : _a2.filter((menu) => !menu._enterprise).map((menu) => filterEnterpriseItems(menu))
        });
      };
      topLevelMenuItem = filterEnterpriseItems(topLevelMenuItem);
    }
    const chartGroupsDef = (_b = (_a = this.gos.get("chartToolPanelsDef")) == null ? void 0 : _a.settingsPanel) == null ? void 0 : _b.chartGroupsDef;
    if (chartGroupsDef) {
      topLevelMenuItem = ChartMenuItemMapper.filterAndOrderChartMenu(topLevelMenuItem, chartGroupsDef, builder.getConfigLookup());
    }
    return this.cleanInternals(topLevelMenuItem);
  }
  // Remove our internal _key and _enterprise properties so this does not leak out of the class on the menu items.
  cleanInternals(menuItem) {
    if (!menuItem) {
      return menuItem;
    }
    const removeKeys = (m) => {
      var _a;
      m == null ? true : delete m._key;
      m == null ? true : delete m._enterprise;
      (_a = m == null ? void 0 : m.subMenu) == null ? void 0 : _a.forEach((s) => removeKeys(s));
      return m;
    };
    return removeKeys(menuItem);
  }
  static buildLookup(menuItem) {
    let itemLookup = {};
    const addItem = (item) => {
      itemLookup[item._key] = item;
      if (item.subMenu) {
        item.subMenu.forEach((s) => addItem(s));
      }
    };
    addItem(menuItem);
    return itemLookup;
  }
  /**
   * Make the MenuItem match the charts provided and their ordering on the ChartGroupsDef config object as provided by the user.
   */
  static filterAndOrderChartMenu(topLevelMenuItem, chartGroupsDef, configLookup) {
    var _a;
    const menuItemLookup = this.buildLookup(topLevelMenuItem);
    let orderedAndFiltered = __spreadProps7(__spreadValues10({}, topLevelMenuItem), { subMenu: [] });
    Object.entries(chartGroupsDef).forEach(([group, chartTypes]) => {
      var _a2, _b;
      const chartConfigGroup = configLookup[group];
      if (chartConfigGroup === null)
        return;
      if (chartConfigGroup == void 0) {
        _410.warnOnce(`invalid chartGroupsDef config '${group}'`);
        return void 0;
      }
      const menuItem = menuItemLookup[chartConfigGroup._key];
      if (menuItem) {
        if (menuItem.subMenu) {
          const subMenus = chartTypes.map((chartType) => {
            const itemKey = chartConfigGroup[chartType];
            if (itemKey == void 0) {
              _410.warnOnce(`invalid chartGroupsDef config '${group}.${chartType}'`);
              return void 0;
            }
            return menuItemLookup[itemKey];
          }).filter((s) => s !== void 0);
          if (subMenus.length > 0) {
            menuItem.subMenu = subMenus;
            (_a2 = orderedAndFiltered.subMenu) == null ? void 0 : _a2.push(menuItem);
          }
        } else {
          (_b = orderedAndFiltered.subMenu) == null ? void 0 : _b.push(menuItem);
        }
      }
    });
    if (((_a = orderedAndFiltered.subMenu) == null ? void 0 : _a.length) == 0) {
      return void 0;
    }
    return orderedAndFiltered;
  }
};
__decorateClass12([
  Optional32("chartService")
], ChartMenuItemMapper.prototype, "chartService", 2);
ChartMenuItemMapper = __decorateClass12([
  Bean44("chartMenuItemMapper")
], ChartMenuItemMapper);
var PivotMenuItemMapper = class {
  constructor(gos, chartService, localeService) {
    this.gos = gos;
    this.chartService = chartService;
    this.localeService = localeService;
  }
  getMenuItem() {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const getMenuItem = (localeKey, defaultText, chartType, key, enterprise = false) => {
      return {
        name: localeTextFunc(localeKey, defaultText),
        action: () => this.chartService.createPivotChart({ chartType }),
        _key: key,
        _enterprise: enterprise
      };
    };
    return {
      name: localeTextFunc("pivotChart", "Pivot Chart"),
      _key: "pivotChart",
      subMenu: [
        {
          _key: "pivotColumnChart",
          name: localeTextFunc("columnChart", "Column"),
          subMenu: [
            getMenuItem("groupedColumn", "Grouped&lrm;", "groupedColumn", "pivotGroupedColumn"),
            getMenuItem("stackedColumn", "Stacked&lrm;", "stackedColumn", "pivotStackedColumn"),
            getMenuItem("normalizedColumn", "100% Stacked&lrm;", "normalizedColumn", "pivotNormalizedColumn")
          ]
        },
        {
          _key: "pivotBarChart",
          name: localeTextFunc("barChart", "Bar"),
          subMenu: [
            getMenuItem("groupedBar", "Grouped&lrm;", "groupedBar", "pivotGroupedBar"),
            getMenuItem("stackedBar", "Stacked&lrm;", "stackedBar", "pivotStackedBar"),
            getMenuItem("normalizedBar", "100% Stacked&lrm;", "normalizedBar", "pivotNormalizedBar")
          ]
        },
        {
          _key: "pivotPieChart",
          name: localeTextFunc("pieChart", "Pie"),
          subMenu: [
            getMenuItem("pie", "Pie&lrm;", "pie", "pivotPie"),
            getMenuItem("donut", "Donut&lrm;", "donut", "pivotDonut")
          ]
        },
        getMenuItem("line", "Line&lrm;", "line", "pivotLineChart"),
        {
          _key: "pivotXYChart",
          name: localeTextFunc("xyChart", "X Y (Scatter)"),
          subMenu: [
            getMenuItem("scatter", "Scatter&lrm;", "scatter", "pivotScatter"),
            getMenuItem("bubble", "Bubble&lrm;", "bubble", "pivotBubble")
          ]
        },
        {
          _key: "pivotAreaChart",
          name: localeTextFunc("areaChart", "Area"),
          subMenu: [
            getMenuItem("area", "Area&lrm;", "area", "pivotArea"),
            getMenuItem("stackedArea", "Stacked&lrm;", "stackedArea", "pivotStackedArea"),
            getMenuItem("normalizedArea", "100% Stacked&lrm;", "normalizedArea", "pivotNormalizedArea")
          ]
        },
        {
          _key: "pivotStatisticalChart",
          _enterprise: false,
          // histogram chart is available in both community and enterprise distributions
          name: localeTextFunc("statisticalChart", "Statistical"),
          subMenu: [
            getMenuItem("histogramChart", "Histogram&lrm;", "histogram", "pivotHistogram", false)
          ]
        },
        {
          _key: "pivotHierarchicalChart",
          _enterprise: true,
          name: localeTextFunc("hierarchicalChart", "Hierarchical"),
          subMenu: [
            getMenuItem("treemapChart", "Treemap&lrm;", "treemap", "pivotTreemap", true),
            getMenuItem("sunburstChart", "Sunburst&lrm;", "sunburst", "pivotSunburst", true)
          ]
        },
        {
          _key: "pivotCombinationChart",
          name: localeTextFunc("combinationChart", "Combination"),
          subMenu: [
            getMenuItem("columnLineCombo", "Column & Line&lrm;", "columnLineCombo", "pivotColumnLineCombo"),
            getMenuItem("AreaColumnCombo", "Area & Column&lrm;", "areaColumnCombo", "pivotAreaColumnCombo")
          ]
        }
      ],
      icon: _410.createIconNoSpan("chart", this.gos, void 0)
    };
  }
  getConfigLookup() {
    return {
      columnGroup: {
        _key: "pivotColumnChart",
        column: "pivotGroupedColumn",
        stackedColumn: "pivotStackedColumn",
        normalizedColumn: "pivotNormalizedColumn"
      },
      barGroup: {
        _key: "pivotBarChart",
        bar: "pivotGroupedBar",
        stackedBar: "pivotStackedBar",
        normalizedBar: "pivotNormalizedBar"
      },
      pieGroup: {
        _key: "pivotPieChart",
        pie: "pivotPie",
        donut: "pivotDonut",
        doughnut: "pivotDonut"
      },
      lineGroup: {
        _key: "pivotLineChart",
        line: "pivotLineChart"
      },
      scatterGroup: {
        _key: "pivotXYChart",
        bubble: "pivotBubble",
        scatter: "pivotScatter"
      },
      areaGroup: {
        _key: "pivotAreaChart",
        area: "pivotArea",
        stackedArea: "pivotStackedArea",
        normalizedArea: "pivotNormalizedArea"
      },
      combinationGroup: {
        _key: "pivotCombinationChart",
        columnLineCombo: "pivotColumnLineCombo",
        areaColumnCombo: "pivotAreaColumnCombo",
        customCombo: null
        // Not currently supported
      },
      hierarchicalGroup: {
        _key: "pivotHierarchicalChart",
        treemap: "pivotTreemap",
        sunburst: "pivotSunburst"
      },
      statisticalGroup: {
        _key: "pivotStatisticalChart",
        histogram: "pivotHistogram",
        // Some statistical charts do not currently support pivot mode
        rangeBar: null,
        rangeArea: null,
        boxPlot: null
      },
      // Polar charts do not support pivot mode
      polarGroup: null,
      // Specialized charts do not currently support pivot mode
      specializedGroup: null
    };
  }
};
var RangeMenuItemMapper = class {
  constructor(gos, chartService, localeService) {
    this.gos = gos;
    this.chartService = chartService;
    this.localeService = localeService;
  }
  getMenuItem() {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const getMenuItem = (localeKey, defaultText, chartType, key, enterprise = false) => {
      return {
        name: localeTextFunc(localeKey, defaultText),
        action: () => this.chartService.createChartFromCurrentRange(chartType),
        _key: key,
        _enterprise: enterprise
      };
    };
    return {
      name: localeTextFunc("chartRange", "Chart Range"),
      _key: "chartRange",
      subMenu: [
        {
          name: localeTextFunc("columnChart", "Column"),
          subMenu: [
            getMenuItem("groupedColumn", "Grouped&lrm;", "groupedColumn", "rangeGroupedColumn"),
            getMenuItem("stackedColumn", "Stacked&lrm;", "stackedColumn", "rangeStackedColumn"),
            getMenuItem("normalizedColumn", "100% Stacked&lrm;", "normalizedColumn", "rangeNormalizedColumn")
          ],
          _key: "rangeColumnChart"
        },
        {
          name: localeTextFunc("barChart", "Bar"),
          subMenu: [
            getMenuItem("groupedBar", "Grouped&lrm;", "groupedBar", "rangeGroupedBar"),
            getMenuItem("stackedBar", "Stacked&lrm;", "stackedBar", "rangeStackedBar"),
            getMenuItem("normalizedBar", "100% Stacked&lrm;", "normalizedBar", "rangeNormalizedBar")
          ],
          _key: "rangeBarChart"
        },
        {
          name: localeTextFunc("pieChart", "Pie"),
          subMenu: [
            getMenuItem("pie", "Pie&lrm;", "pie", "rangePie"),
            getMenuItem("donut", "Donut&lrm;", "donut", "rangeDonut")
          ],
          _key: "rangePieChart"
        },
        getMenuItem("line", "Line&lrm;", "line", "rangeLineChart"),
        {
          name: localeTextFunc("xyChart", "X Y (Scatter)"),
          subMenu: [
            getMenuItem("scatter", "Scatter&lrm;", "scatter", "rangeScatter"),
            getMenuItem("bubble", "Bubble&lrm;", "bubble", "rangeBubble")
          ],
          _key: "rangeXYChart"
        },
        {
          name: localeTextFunc("areaChart", "Area"),
          subMenu: [
            getMenuItem("area", "Area&lrm;", "area", "rangeArea"),
            getMenuItem("stackedArea", "Stacked&lrm;", "stackedArea", "rangeStackedArea"),
            getMenuItem("normalizedArea", "100% Stacked&lrm;", "normalizedArea", "rangeNormalizedArea")
          ],
          _key: "rangeAreaChart"
        },
        {
          name: localeTextFunc("polarChart", "Polar"),
          subMenu: [
            getMenuItem("radarLine", "Radar Line&lrm;", "radarLine", "rangeRadarLine"),
            getMenuItem("radarArea", "Radar Area&lrm;", "radarArea", "rangeRadarArea"),
            getMenuItem("nightingale", "Nightingale&lrm;", "nightingale", "rangeNightingale"),
            getMenuItem("radialColumn", "Radial Column&lrm;", "radialColumn", "rangeRadialColumn"),
            getMenuItem("radialBar", "Radial Bar&lrm;", "radialBar", "rangeRadialBar")
          ],
          _key: "rangePolarChart",
          _enterprise: true
        },
        {
          name: localeTextFunc("statisticalChart", "Statistical"),
          subMenu: [
            getMenuItem("boxPlot", "Box Plot&lrm;", "boxPlot", "rangeBoxPlot", true),
            getMenuItem("histogramChart", "Histogram&lrm;", "histogram", "rangeHistogram", false),
            getMenuItem("rangeBar", "Range Bar&lrm;", "rangeBar", "rangeRangeBar", true),
            getMenuItem("rangeArea", "Range Area&lrm;", "rangeArea", "rangeRangeArea", true)
          ],
          _key: "rangeStatisticalChart",
          _enterprise: false
          // histogram chart is available in both community and enterprise distributions
        },
        {
          name: localeTextFunc("hierarchicalChart", "Hierarchical"),
          subMenu: [
            getMenuItem("treemap", "Treemap&lrm;", "treemap", "rangeTreemap"),
            getMenuItem("sunburst", "Sunburst&lrm;", "sunburst", "rangeSunburst")
          ],
          _key: "rangeHierarchicalChart",
          _enterprise: true
        },
        {
          name: localeTextFunc("specializedChart", "Specialized"),
          subMenu: [
            getMenuItem("heatmap", "Heatmap&lrm;", "heatmap", "rangeHeatmap"),
            getMenuItem("waterfall", "Waterfall&lrm;", "waterfall", "rangeWaterfall")
          ],
          _key: "rangeSpecializedChart",
          _enterprise: true
        },
        {
          name: localeTextFunc("combinationChart", "Combination"),
          subMenu: [
            getMenuItem("columnLineCombo", "Column & Line&lrm;", "columnLineCombo", "rangeColumnLineCombo"),
            getMenuItem("AreaColumnCombo", "Area & Column&lrm;", "areaColumnCombo", "rangeAreaColumnCombo")
          ],
          _key: "rangeCombinationChart"
        }
      ],
      icon: _410.createIconNoSpan("chart", this.gos, void 0)
    };
  }
  getConfigLookup() {
    return {
      columnGroup: {
        _key: "rangeColumnChart",
        column: "rangeGroupedColumn",
        stackedColumn: "rangeStackedColumn",
        normalizedColumn: "rangeNormalizedColumn"
      },
      barGroup: {
        _key: "rangeBarChart",
        bar: "rangeGroupedBar",
        stackedBar: "rangeStackedBar",
        normalizedBar: "rangeNormalizedBar"
      },
      pieGroup: {
        _key: "rangePieChart",
        pie: "rangePie",
        donut: "rangeDonut",
        doughnut: "rangeDonut"
      },
      lineGroup: {
        _key: "rangeLineChart",
        line: "rangeLineChart"
      },
      scatterGroup: {
        _key: "rangeXYChart",
        bubble: "rangeBubble",
        scatter: "rangeScatter"
      },
      areaGroup: {
        _key: "rangeAreaChart",
        area: "rangeArea",
        stackedArea: "rangeStackedArea",
        normalizedArea: "rangeNormalizedArea"
      },
      polarGroup: {
        _key: "rangePolarChart",
        radarLine: "rangeRadarLine",
        radarArea: "rangeRadarArea",
        nightingale: "rangeNightingale",
        radialColumn: "rangeRadialColumn",
        radialBar: "rangeRadialBar"
      },
      statisticalGroup: {
        _key: "rangeStatisticalChart",
        boxPlot: "rangeBoxPlot",
        histogram: "rangeHistogram",
        rangeBar: "rangeRangeBar",
        rangeArea: "rangeRangeArea"
      },
      hierarchicalGroup: {
        _key: "rangeHierarchicalChart",
        treemap: "rangeTreemap",
        sunburst: "rangeSunburst"
      },
      specializedGroup: {
        _key: "rangeSpecializedChart",
        heatmap: "rangeHeatmap",
        waterfall: "rangeWaterfall"
      },
      combinationGroup: {
        _key: "rangeCombinationChart",
        columnLineCombo: "rangeColumnLineCombo",
        areaColumnCombo: "rangeAreaColumnCombo",
        customCombo: null
        // Not currently supported
      }
    };
  }
};
var ColumnChooserFactory = class extends BeanStub54 {
  createColumnSelectPanel(parent, column, draggable, params) {
    var _a, _b;
    const columnSelectPanel = parent.createManagedBean(new PrimaryColsPanel());
    const columnChooserParams = (_b = (_a = params != null ? params : column == null ? void 0 : column.getColDef().columnChooserParams) != null ? _a : column == null ? void 0 : column.getColDef().columnsMenuParams) != null ? _b : {};
    const {
      contractColumnSelection,
      suppressColumnExpandAll,
      suppressColumnFilter,
      suppressColumnSelectAll,
      suppressSyncLayoutWithGrid,
      columnLayout
    } = columnChooserParams;
    columnSelectPanel.init(!!draggable, this.gos.addGridCommonParams({
      suppressColumnMove: false,
      suppressValues: false,
      suppressPivots: false,
      suppressRowGroups: false,
      suppressPivotMode: false,
      contractColumnSelection: !!contractColumnSelection,
      suppressColumnExpandAll: !!suppressColumnExpandAll,
      suppressColumnFilter: !!suppressColumnFilter,
      suppressColumnSelectAll: !!suppressColumnSelectAll,
      suppressSyncLayoutWithGrid: !!columnLayout || !!suppressSyncLayoutWithGrid,
      onStateUpdated: () => {
      }
    }), "columnMenu");
    if (columnLayout) {
      columnSelectPanel.setColumnLayout(columnLayout);
    }
    return columnSelectPanel;
  }
  showColumnChooser({ column, chooserParams, eventSource }) {
    this.hideActiveColumnChooser();
    const columnSelectPanel = this.createColumnSelectPanel(this, column, true, chooserParams);
    const translate = this.localeService.getLocaleTextFunc();
    const columnIndex = this.columnModel.getAllDisplayedColumns().indexOf(column);
    const headerPosition = column ? this.focusService.getFocusedHeader() : null;
    this.activeColumnChooserDialog = this.createBean(new AgDialog4({
      title: translate("chooseColumns", "Choose Columns"),
      component: columnSelectPanel,
      width: 300,
      height: 300,
      resizable: true,
      movable: true,
      centered: true,
      closable: true,
      afterGuiAttached: () => {
        var _a;
        (_a = this.focusService.findNextFocusableElement(columnSelectPanel.getGui())) == null ? void 0 : _a.focus();
        this.dispatchVisibleChangedEvent(true, column);
      },
      closedCallback: (event) => {
        const eComp = this.activeColumnChooser.getGui();
        this.destroyBean(this.activeColumnChooser);
        this.activeColumnChooser = void 0;
        this.activeColumnChooserDialog = void 0;
        this.dispatchVisibleChangedEvent(false, column);
        if (column) {
          this.menuUtils.restoreFocusOnClose({ column, headerPosition, columnIndex, eventSource }, eComp, event, true);
        }
      }
    }));
    this.activeColumnChooser = columnSelectPanel;
  }
  hideActiveColumnChooser() {
    if (this.activeColumnChooserDialog) {
      this.destroyBean(this.activeColumnChooserDialog);
    }
  }
  dispatchVisibleChangedEvent(visible, column) {
    const event = {
      type: Events36.EVENT_COLUMN_MENU_VISIBLE_CHANGED,
      visible,
      switchingTab: false,
      key: "columnChooser",
      column: column != null ? column : null
    };
    this.eventService.dispatchEvent(event);
  }
};
__decorateClass12([
  Autowired414("focusService")
], ColumnChooserFactory.prototype, "focusService", 2);
__decorateClass12([
  Autowired414("menuUtils")
], ColumnChooserFactory.prototype, "menuUtils", 2);
__decorateClass12([
  Autowired414("columnModel")
], ColumnChooserFactory.prototype, "columnModel", 2);
ColumnChooserFactory = __decorateClass12([
  Bean53("columnChooserFactory")
], ColumnChooserFactory);
var ColumnMenuFactory = class extends BeanStub64 {
  createMenu(parent, column, sourceElement) {
    const menuList = parent.createManagedBean(new AgMenuList3(0, {
      column: column != null ? column : null,
      node: null,
      value: null
    }));
    const menuItems = this.getMenuItems(column);
    const menuItemsMapped = this.menuItemMapper.mapWithStockItems(menuItems, column != null ? column : null, sourceElement);
    menuList.addMenuItems(menuItemsMapped);
    return menuList;
  }
  getMenuItems(column) {
    const defaultItems = this.getDefaultMenuOptions(column);
    let result;
    const columnMainMenuItems = column == null ? void 0 : column.getColDef().mainMenuItems;
    if (Array.isArray(columnMainMenuItems)) {
      result = columnMainMenuItems;
    } else if (typeof columnMainMenuItems === "function") {
      result = columnMainMenuItems(this.gos.addGridCommonParams({
        column,
        defaultItems
      }));
    } else {
      const userFunc = this.gos.getCallback("getMainMenuItems");
      if (userFunc && column) {
        result = userFunc({
          column,
          defaultItems
        });
      } else {
        result = defaultItems;
      }
    }
    _56.removeRepeatsFromArray(result, ColumnMenuFactory.MENU_ITEM_SEPARATOR);
    return result;
  }
  getDefaultMenuOptions(column) {
    const result = [];
    const isLegacyMenuEnabled = this.menuService.isLegacyMenuEnabled();
    if (!column) {
      if (!isLegacyMenuEnabled) {
        result.push("columnChooser");
      }
      result.push("resetColumns");
      return result;
    }
    const allowPinning = !column.getColDef().lockPinned;
    const rowGroupCount = this.columnModel.getRowGroupColumns().length;
    const doingGrouping = rowGroupCount > 0;
    const allowValue = column.isAllowValue();
    const allowRowGroup = column.isAllowRowGroup();
    const isPrimary = column.isPrimary();
    const pivotModeOn = this.columnModel.isPivotMode();
    const isInMemoryRowModel = this.rowModel.getType() === "clientSide";
    const usingTreeData = this.gos.get("treeData");
    const allowValueAgg = (
      // if primary, then only allow aggValue if grouping and it's a value columns
      isPrimary && doingGrouping && allowValue || !isPrimary
    );
    if (!isLegacyMenuEnabled && column.isSortable()) {
      const sort = column.getSort();
      if (sort !== "asc") {
        result.push("sortAscending");
      }
      if (sort !== "desc") {
        result.push("sortDescending");
      }
      if (sort) {
        result.push("sortUnSort");
      }
      result.push(ColumnMenuFactory.MENU_ITEM_SEPARATOR);
    }
    if (this.menuService.isFilterMenuItemEnabled(column)) {
      result.push("columnFilter");
      result.push(ColumnMenuFactory.MENU_ITEM_SEPARATOR);
    }
    if (allowPinning) {
      result.push("pinSubMenu");
    }
    if (allowValueAgg) {
      result.push("valueAggSubMenu");
    }
    if (allowPinning || allowValueAgg) {
      result.push(ColumnMenuFactory.MENU_ITEM_SEPARATOR);
    }
    result.push("autoSizeThis");
    result.push("autoSizeAll");
    result.push(ColumnMenuFactory.MENU_ITEM_SEPARATOR);
    const showRowGroup = column.getColDef().showRowGroup;
    if (showRowGroup) {
      result.push("rowUnGroup");
    } else if (allowRowGroup && column.isPrimary()) {
      if (column.isRowGroupActive()) {
        const groupLocked = this.columnModel.isColumnGroupingLocked(column);
        if (!groupLocked) {
          result.push("rowUnGroup");
        }
      } else {
        result.push("rowGroup");
      }
    }
    result.push(ColumnMenuFactory.MENU_ITEM_SEPARATOR);
    if (!isLegacyMenuEnabled) {
      result.push("columnChooser");
    }
    result.push("resetColumns");
    const allowExpandAndContract = isInMemoryRowModel && (usingTreeData || rowGroupCount > (pivotModeOn ? 1 : 0));
    if (allowExpandAndContract) {
      result.push("expandAll");
      result.push("contractAll");
    }
    return result;
  }
};
ColumnMenuFactory.MENU_ITEM_SEPARATOR = "separator";
__decorateClass12([
  Autowired510("menuItemMapper")
], ColumnMenuFactory.prototype, "menuItemMapper", 2);
__decorateClass12([
  Autowired510("columnModel")
], ColumnMenuFactory.prototype, "columnModel", 2);
__decorateClass12([
  Autowired510("rowModel")
], ColumnMenuFactory.prototype, "rowModel", 2);
__decorateClass12([
  Autowired510("filterManager")
], ColumnMenuFactory.prototype, "filterManager", 2);
__decorateClass12([
  Autowired510("menuService")
], ColumnMenuFactory.prototype, "menuService", 2);
ColumnMenuFactory = __decorateClass12([
  Bean63("columnMenuFactory")
], ColumnMenuFactory);
var MenuUtils = class extends BeanStub73 {
  restoreFocusOnClose(restoreFocusParams, eComp, e, restoreIfMouseEvent) {
    const { eventSource } = restoreFocusParams;
    const isKeyboardEvent = e instanceof KeyboardEvent;
    if (!restoreIfMouseEvent && !isKeyboardEvent || !eventSource) {
      return;
    }
    const eDocument = this.gos.getDocument();
    const activeEl = this.gos.getActiveDomElement();
    if (!eComp.contains(activeEl) && activeEl !== eDocument.body) {
      return;
    }
    this.focusHeaderCell(restoreFocusParams);
  }
  closePopupAndRestoreFocusOnSelect(hidePopupFunc, restoreFocusParams, event) {
    let keyboardEvent;
    if (event && event.keyboardEvent) {
      keyboardEvent = event.keyboardEvent;
    }
    hidePopupFunc(keyboardEvent && { keyboardEvent });
    const focusedCell = this.focusService.getFocusedCell();
    const eDocument = this.gos.getDocument();
    const activeEl = this.gos.getActiveDomElement();
    if (!activeEl || activeEl === eDocument.body) {
      if (focusedCell) {
        const { rowIndex, rowPinned, column } = focusedCell;
        this.focusService.setFocusedCell({ rowIndex, column, rowPinned, forceBrowserFocus: true, preventScrollOnBrowserFocus: true });
      } else {
        this.focusHeaderCell(restoreFocusParams);
      }
    }
  }
  onContextMenu(mouseEvent, touchEvent, showMenuCallback) {
    if (!this.gos.get("allowContextMenuWithControlKey")) {
      if (mouseEvent && (mouseEvent.ctrlKey || mouseEvent.metaKey)) {
        return;
      }
    }
    if (mouseEvent) {
      this.blockMiddleClickScrollsIfNeeded(mouseEvent);
    }
    if (this.gos.get("suppressContextMenu")) {
      return;
    }
    const eventOrTouch = mouseEvent != null ? mouseEvent : touchEvent.touches[0];
    if (showMenuCallback(eventOrTouch)) {
      const event = mouseEvent != null ? mouseEvent : touchEvent;
      if (event && event.cancelable) {
        event.preventDefault();
      }
    }
  }
  focusHeaderCell(restoreFocusParams) {
    const { column, columnIndex, headerPosition, eventSource } = restoreFocusParams;
    const isColumnStillVisible = this.columnModel.getAllDisplayedColumns().some((col) => col === column);
    if (isColumnStillVisible && eventSource && _66.isVisible(eventSource)) {
      const focusableEl = this.focusService.findTabbableParent(eventSource);
      if (focusableEl) {
        if (column) {
          this.headerNavigationService.scrollToColumn(column);
        }
        focusableEl.focus();
      }
    } else if (headerPosition && columnIndex !== -1) {
      const allColumns = this.columnModel.getAllDisplayedColumns();
      const columnToFocus = allColumns[columnIndex] || _66.last(allColumns);
      if (columnToFocus) {
        this.focusService.focusHeaderPosition({
          headerPosition: {
            headerRowIndex: headerPosition.headerRowIndex,
            column: columnToFocus
          }
        });
      }
    }
  }
  blockMiddleClickScrollsIfNeeded(mouseEvent) {
    if (this.gos.get("suppressMiddleClickScrolls") && mouseEvent.which === 2) {
      mouseEvent.preventDefault();
    }
  }
};
__decorateClass12([
  Autowired65("focusService")
], MenuUtils.prototype, "focusService", 2);
__decorateClass12([
  Autowired65("headerNavigationService")
], MenuUtils.prototype, "headerNavigationService", 2);
__decorateClass12([
  Autowired65("columnModel")
], MenuUtils.prototype, "columnModel", 2);
MenuUtils = __decorateClass12([
  Bean72("menuUtils")
], MenuUtils);
var MenuModule = {
  version: VERSION12,
  moduleName: ModuleNames52.MenuModule,
  beans: [EnterpriseMenuFactory, ContextMenuFactory, MenuItemMapper, ChartMenuItemMapper, ColumnChooserFactory, ColumnMenuFactory, MenuUtils],
  dependantModules: [
    EnterpriseCoreModule
  ]
};

// enterprise-modules/multi-filter/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames13 } from "ag-grid-community";
import {
  ProvidedFilter,
  AgPromise as AgPromise5,
  Autowired as Autowired61,
  AgGroupComponent as AgGroupComponent5,
  TabGuardComp as TabGuardComp4,
  AgMenuItemComponent as AgMenuItemComponent4,
  AgMenuItemRenderer,
  PostConstruct as PostConstruct61,
  _ as _50,
  KeyCode as KeyCode10
} from "ag-grid-community";
import {
  Component as Component56,
  _ as _216,
  Autowired as Autowired215,
  AgPromise as AgPromise23
} from "ag-grid-community";
var __defProp13 = Object.defineProperty;
var __defProps8 = Object.defineProperties;
var __getOwnPropDesc13 = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs8 = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols11 = Object.getOwnPropertySymbols;
var __hasOwnProp12 = Object.prototype.hasOwnProperty;
var __propIsEnum11 = Object.prototype.propertyIsEnumerable;
var __defNormalProp11 = (obj, key, value) => key in obj ? __defProp13(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues11 = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp12.call(b, prop))
      __defNormalProp11(a, prop, b[prop]);
  if (__getOwnPropSymbols11)
    for (var prop of __getOwnPropSymbols11(b)) {
      if (__propIsEnum11.call(b, prop))
        __defNormalProp11(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps8 = (a, b) => __defProps8(a, __getOwnPropDescs8(b));
var __decorateClass13 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc13(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp13(target, key, result);
  return result;
};
var _MultiFilter = class _MultiFilter2 extends TabGuardComp4 {
  constructor() {
    super(
      /* html */
      `<div class="ag-multi-filter ag-menu-list-compact"></div>`
    );
    this.filterDefs = [];
    this.filters = [];
    this.guiDestroyFuncs = [];
    this.filterGuis = [];
    this.activeFilterIndices = [];
    this.lastActivatedMenuItem = null;
    this.afterFiltersReadyFuncs = [];
  }
  postConstruct() {
    this.initialiseTabGuard({
      onFocusIn: (e) => this.onFocusIn(e)
    });
  }
  static getFilterDefs(params) {
    const { filters } = params;
    return filters && filters.length > 0 ? filters : [{ filter: "agTextColumnFilter" }, { filter: "agSetColumnFilter" }];
  }
  init(params) {
    this.params = params;
    this.filterDefs = _MultiFilter2.getFilterDefs(params);
    const { column, filterChangedCallback } = params;
    this.column = column;
    this.filterChangedCallback = filterChangedCallback;
    const filterPromises = [];
    this.filterDefs.forEach((filterDef, index) => {
      const filterPromise = this.createFilter(filterDef, index);
      if (filterPromise != null) {
        filterPromises.push(filterPromise);
      }
    });
    return new AgPromise5((resolve) => {
      AgPromise5.all(filterPromises).then((filters) => {
        this.filters = filters;
        this.refreshGui("columnMenu").then(() => {
          resolve();
        });
      });
    }).then(() => {
      this.afterFiltersReadyFuncs.forEach((f) => f());
      this.afterFiltersReadyFuncs.length = 0;
    });
  }
  refreshGui(container) {
    if (container === this.lastOpenedInContainer) {
      return AgPromise5.resolve();
    }
    this.removeAllChildrenExceptTabGuards();
    this.destroyChildren();
    return AgPromise5.all(this.filters.map((filter, index) => {
      const filterDef = this.filterDefs[index];
      const filterTitle = this.getFilterTitle(filter, filterDef);
      let filterGuiPromise;
      if (filterDef.display === "subMenu" && container !== "toolPanel") {
        filterGuiPromise = this.insertFilterMenu(filter, filterTitle).then((menuItem) => menuItem.getGui());
      } else if (filterDef.display === "subMenu" || filterDef.display === "accordion") {
        const group = this.insertFilterGroup(filter, filterTitle);
        filterGuiPromise = AgPromise5.resolve(group.getGui());
      } else {
        filterGuiPromise = AgPromise5.resolve(filter.getGui());
      }
      return filterGuiPromise;
    })).then((filterGuis) => {
      filterGuis.forEach((filterGui, index) => {
        if (index > 0) {
          this.appendChild(_50.loadTemplate(
            /* html */
            `<div class="ag-filter-separator"></div>`
          ));
        }
        this.appendChild(filterGui);
      });
      this.filterGuis = filterGuis;
      this.lastOpenedInContainer = container;
    });
  }
  getFilterTitle(filter, filterDef) {
    if (filterDef.title != null) {
      return filterDef.title;
    }
    return filter instanceof ProvidedFilter ? filter.getFilterTitle() : "Filter";
  }
  destroyChildren() {
    this.guiDestroyFuncs.forEach((func) => func());
    this.guiDestroyFuncs.length = 0;
    this.filterGuis.length = 0;
  }
  insertFilterMenu(filter, name) {
    const menuItem = this.createBean(new AgMenuItemComponent4());
    return menuItem.init({
      menuItemDef: {
        name,
        subMenu: [],
        cssClasses: ["ag-multi-filter-menu-item"],
        menuItem: AgMenuItemRenderer,
        menuItemParams: {
          cssClassPrefix: "ag-compact-menu-option",
          isCompact: true
        }
      },
      level: 0,
      isAnotherSubMenuOpen: () => false,
      childComponent: filter,
      contextParams: {
        column: null,
        node: null,
        value: null
      }
    }).then(() => {
      menuItem.setParentComponent(this);
      this.guiDestroyFuncs.push(() => this.destroyBean(menuItem));
      this.addManagedListener(menuItem, AgMenuItemComponent4.EVENT_MENU_ITEM_ACTIVATED, (event) => {
        if (this.lastActivatedMenuItem && this.lastActivatedMenuItem !== event.menuItem) {
          this.lastActivatedMenuItem.deactivate();
        }
        this.lastActivatedMenuItem = event.menuItem;
      });
      const menuItemGui = menuItem.getGui();
      menuItem.addManagedListener(menuItemGui, "keydown", (e) => {
        const { key } = e;
        switch (key) {
          case KeyCode10.UP:
          case KeyCode10.RIGHT:
          case KeyCode10.DOWN:
          case KeyCode10.LEFT:
            e.preventDefault();
            if (key === KeyCode10.RIGHT) {
              menuItem.openSubMenu(true);
            }
            break;
        }
      });
      menuItem.addManagedListener(menuItemGui, "focusin", () => menuItem.activate());
      menuItem.addManagedListener(menuItemGui, "focusout", () => {
        if (!menuItem.isSubMenuOpen() && !menuItem.isSubMenuOpening()) {
          menuItem.deactivate();
        }
      });
      return menuItem;
    });
  }
  insertFilterGroup(filter, title) {
    const group = this.createBean(new AgGroupComponent5({
      title,
      cssIdentifier: "multi-filter"
    }));
    this.guiDestroyFuncs.push(() => this.destroyBean(group));
    group.addItem(filter.getGui());
    group.toggleGroupExpand(false);
    if (filter.afterGuiAttached) {
      group.addManagedListener(group, AgGroupComponent5.EVENT_EXPANDED, () => filter.afterGuiAttached({
        container: this.lastOpenedInContainer,
        suppressFocus: true,
        hidePopup: this.hidePopup
      }));
    }
    return group;
  }
  isFilterActive() {
    return this.filters.some((filter) => filter.isFilterActive());
  }
  getLastActiveFilterIndex() {
    return this.activeFilterIndices.length > 0 ? this.activeFilterIndices[this.activeFilterIndices.length - 1] : null;
  }
  doesFilterPass(params, filterToSkip) {
    let rowPasses = true;
    this.filters.forEach((filter) => {
      if (!rowPasses || filter === filterToSkip || !filter.isFilterActive()) {
        return;
      }
      rowPasses = filter.doesFilterPass(params);
    });
    return rowPasses;
  }
  getFilterType() {
    return "multi";
  }
  getModelFromUi() {
    const model = {
      filterType: this.getFilterType(),
      filterModels: this.filters.map((filter) => {
        const providedFilter = filter;
        if (typeof providedFilter.getModelFromUi === "function") {
          return providedFilter.getModelFromUi();
        }
        return null;
      })
    };
    return model;
  }
  getModel() {
    if (!this.isFilterActive()) {
      return null;
    }
    const model = {
      filterType: this.getFilterType(),
      filterModels: this.filters.map((filter) => {
        if (filter.isFilterActive()) {
          return filter.getModel();
        }
        return null;
      })
    };
    return model;
  }
  setModel(model) {
    const setFilterModel = (filter, filterModel) => {
      return new AgPromise5((resolve) => {
        const promise = filter.setModel(filterModel);
        promise ? promise.then(() => resolve()) : resolve();
      });
    };
    let promises = [];
    if (model == null) {
      promises = this.filters.map((filter, index) => {
        const res = setFilterModel(filter, null).then(() => {
          this.updateActiveList(index);
        });
        return res;
      });
    } else {
      this.filters.forEach((filter, index) => {
        const filterModel = model.filterModels.length > index ? model.filterModels[index] : null;
        const res = setFilterModel(filter, filterModel).then(() => {
          this.updateActiveList(index);
        });
        promises.push(res);
      });
    }
    return AgPromise5.all(promises).then(() => {
    });
  }
  applyModel(source = "api") {
    let result = false;
    this.filters.forEach((filter) => {
      if (filter instanceof ProvidedFilter) {
        result = filter.applyModel(source) || result;
      }
    });
    return result;
  }
  getChildFilterInstance(index) {
    return this.filters[index];
  }
  afterGuiAttached(params) {
    let refreshPromise;
    if (params) {
      this.hidePopup = params.hidePopup;
      refreshPromise = this.refreshGui(params.container);
    } else {
      this.hidePopup = void 0;
      refreshPromise = AgPromise5.resolve();
    }
    refreshPromise.then(() => {
      const { filterDefs } = this;
      let hasFocused = false;
      if (filterDefs) {
        _50.forEachReverse(filterDefs, (filterDef, index) => {
          var _a;
          const isFirst = index === 0;
          const suppressFocus = !isFirst || filterDef.display !== "inline";
          const afterGuiAttachedParams = __spreadProps8(__spreadValues11({}, params != null ? params : {}), { suppressFocus });
          const filter = (_a = this.filters) == null ? void 0 : _a[index];
          if (filter) {
            this.executeFunctionIfExistsOnFilter(filter, "afterGuiAttached", afterGuiAttachedParams);
            if (isFirst) {
              hasFocused = true;
            }
          }
          if (isFirst && suppressFocus) {
            const filterGui = this.filterGuis[index];
            if (filterGui) {
              filterGui.focus();
              hasFocused = true;
            }
          }
        });
      }
      const eDocument = this.gos.getDocument();
      const activeEl = this.gos.getActiveDomElement();
      if (!hasFocused && (!activeEl || activeEl === eDocument.body || this.getGui().contains(activeEl))) {
        this.forceFocusOutOfContainer(true);
      }
    });
  }
  afterGuiDetached() {
    this.executeFunctionIfExists("afterGuiDetached");
  }
  onAnyFilterChanged() {
    this.executeFunctionIfExists("onAnyFilterChanged");
  }
  onNewRowsLoaded() {
    this.executeFunctionIfExists("onNewRowsLoaded");
  }
  destroy() {
    this.filters.forEach((filter) => this.destroyBean(filter));
    this.filters.length = 0;
    this.destroyChildren();
    this.hidePopup = void 0;
    super.destroy();
  }
  executeFunctionIfExists(name, ...params) {
    _50.forEachReverse(this.filters, (filter) => {
      this.executeFunctionIfExistsOnFilter(filter, name, params);
    });
  }
  executeFunctionIfExistsOnFilter(filter, name, ...params) {
    const func = filter[name];
    if (typeof func === "function") {
      func.apply(filter, params);
    }
  }
  createFilter(filterDef, index) {
    const { filterModifiedCallback, doesRowPassOtherFilter } = this.params;
    let filterInstance;
    const filterParams = __spreadProps8(__spreadValues11({}, this.filterManager.createFilterParams(this.column, this.column.getColDef())), {
      filterModifiedCallback,
      filterChangedCallback: (additionalEventAttributes) => {
        this.executeWhenAllFiltersReady(() => this.filterChanged(index, additionalEventAttributes));
      },
      doesRowPassOtherFilter: (node) => doesRowPassOtherFilter(node) && this.doesFilterPass({ node, data: node.data }, filterInstance)
    });
    const compDetails = this.userComponentFactory.getFilterDetails(filterDef, filterParams, "agTextColumnFilter");
    if (!compDetails) {
      return null;
    }
    const filterPromise = compDetails.newAgStackInstance();
    if (filterPromise) {
      filterPromise.then((filter) => filterInstance = filter);
    }
    return filterPromise;
  }
  executeWhenAllFiltersReady(action) {
    if (this.filters && this.filters.length > 0) {
      action();
    } else {
      this.afterFiltersReadyFuncs.push(action);
    }
  }
  updateActiveList(index) {
    const changedFilter = this.filters[index];
    _50.removeFromArray(this.activeFilterIndices, index);
    if (changedFilter.isFilterActive()) {
      this.activeFilterIndices.push(index);
    }
  }
  filterChanged(index, additionalEventAttributes) {
    this.updateActiveList(index);
    this.filterChangedCallback(additionalEventAttributes);
    const changedFilter = this.filters[index];
    this.filters.forEach((filter) => {
      if (filter === changedFilter) {
        return;
      }
      if (typeof filter.onAnyFilterChanged === "function") {
        filter.onAnyFilterChanged();
      }
    });
  }
  onFocusIn(e) {
    if (this.lastActivatedMenuItem != null && !this.lastActivatedMenuItem.getGui().contains(e.target)) {
      this.lastActivatedMenuItem.deactivate();
      this.lastActivatedMenuItem = null;
    }
  }
  getModelAsString(model) {
    var _a, _b, _c, _d;
    if (!this.filters || !((_a = model == null ? void 0 : model.filterModels) == null ? void 0 : _a.length)) {
      return "";
    }
    const lastActiveIndex = (_b = this.getLastActiveFilterIndex()) != null ? _b : 0;
    const activeFilter = this.filters[lastActiveIndex];
    return (_d = (_c = activeFilter.getModelAsString) == null ? void 0 : _c.call(activeFilter, model.filterModels[lastActiveIndex])) != null ? _d : "";
  }
};
__decorateClass13([
  Autowired61("filterManager")
], _MultiFilter.prototype, "filterManager", 2);
__decorateClass13([
  Autowired61("userComponentFactory")
], _MultiFilter.prototype, "userComponentFactory", 2);
__decorateClass13([
  PostConstruct61
], _MultiFilter.prototype, "postConstruct", 1);
var MultiFilter = _MultiFilter;
var MultiFloatingFilterComp = class extends Component56 {
  constructor() {
    super(
      /* html */
      `<div class="ag-multi-floating-filter ag-floating-filter-input"></div>`
    );
    this.floatingFilters = [];
    this.compDetailsList = [];
  }
  init(params) {
    this.params = params;
    const { compDetailsList } = this.getCompDetailsList(params);
    return this.setParams(compDetailsList);
  }
  setParams(compDetailsList) {
    const floatingFilterPromises = [];
    compDetailsList.forEach((compDetails) => {
      const floatingFilterPromise = compDetails == null ? void 0 : compDetails.newAgStackInstance();
      if (floatingFilterPromise != null) {
        this.compDetailsList.push(compDetails);
        floatingFilterPromises.push(floatingFilterPromise);
      }
    });
    return AgPromise23.all(floatingFilterPromises).then((floatingFilters) => {
      floatingFilters.forEach((floatingFilter, index) => {
        this.floatingFilters.push(floatingFilter);
        const gui = floatingFilter.getGui();
        this.appendChild(gui);
        if (index > 0) {
          _216.setDisplayed(gui, false);
        }
      });
    });
  }
  onParamsUpdated(params) {
    this.refresh(params);
  }
  refresh(params) {
    this.params = params;
    const { compDetailsList: newCompDetailsList, floatingFilterParamsList } = this.getCompDetailsList(params);
    const allFloatingFilterCompsUnchanged = newCompDetailsList.length === this.compDetailsList.length && newCompDetailsList.every((newCompDetails, index) => !this.filterManager.areFilterCompsDifferent(this.compDetailsList[index], newCompDetails));
    if (allFloatingFilterCompsUnchanged) {
      floatingFilterParamsList.forEach((floatingFilterParams, index) => {
        var _a;
        const floatingFilter = this.floatingFilters[index];
        let hasRefreshed = false;
        if (floatingFilter.refresh) {
          const result = floatingFilter.refresh(floatingFilterParams);
          if (result !== null) {
            hasRefreshed = true;
          }
        }
        if (!hasRefreshed) {
          (_a = floatingFilter.onParamsUpdated) == null ? void 0 : _a.call(floatingFilter, floatingFilterParams);
        }
      });
    } else {
      _216.clearElement(this.getGui());
      this.destroyBeans(this.floatingFilters);
      this.floatingFilters = [];
      this.compDetailsList = [];
      this.setParams(newCompDetailsList);
    }
  }
  getCompDetailsList(params) {
    const compDetailsList = [];
    const floatingFilterParamsList = [];
    const filterParams = params.filterParams;
    MultiFilter.getFilterDefs(filterParams).forEach((filterDef, index) => {
      const floatingFilterParams = __spreadProps8(__spreadValues11({}, params), {
        // set the parent filter instance for each floating filter to the relevant child filter instance
        parentFilterInstance: (callback) => {
          this.parentMultiFilterInstance((parent) => {
            const child = parent.getChildFilterInstance(index);
            if (child == null) {
              return;
            }
            callback(child);
          });
        }
      });
      _216.mergeDeep(floatingFilterParams.filterParams, filterDef.filterParams);
      const compDetails = this.getCompDetails(filterDef, floatingFilterParams);
      if (compDetails) {
        compDetailsList.push(compDetails);
        floatingFilterParamsList.push(floatingFilterParams);
      }
    });
    return { compDetailsList, floatingFilterParamsList };
  }
  onParentModelChanged(model, event) {
    if (event && event.afterFloatingFilter) {
      return;
    }
    this.parentMultiFilterInstance((parent) => {
      if (model == null) {
        this.floatingFilters.forEach((filter, i) => {
          filter.onParentModelChanged(null, event);
          _216.setDisplayed(filter.getGui(), i === 0);
        });
      } else {
        const lastActiveFloatingFilterIndex = parent.getLastActiveFilterIndex();
        this.floatingFilters.forEach((filter, i) => {
          const filterModel = model.filterModels.length > i ? model.filterModels[i] : null;
          filter.onParentModelChanged(filterModel, event);
          const shouldShow = lastActiveFloatingFilterIndex == null ? i === 0 : i === lastActiveFloatingFilterIndex;
          _216.setDisplayed(filter.getGui(), shouldShow);
        });
      }
    });
  }
  destroy() {
    this.destroyBeans(this.floatingFilters);
    this.floatingFilters.length = 0;
    super.destroy();
  }
  getCompDetails(filterDef, params) {
    var _a;
    let defaultComponentName = (_a = this.userComponentFactory.getDefaultFloatingFilterType(
      filterDef,
      () => this.filterManager.getDefaultFloatingFilter(this.params.column)
    )) != null ? _a : "agReadOnlyFloatingFilter";
    return this.userComponentFactory.getFloatingFilterCompDetails(filterDef, params, defaultComponentName);
  }
  parentMultiFilterInstance(cb) {
    this.params.parentFilterInstance((parent) => {
      if (!(parent instanceof MultiFilter)) {
        throw new Error("AG Grid - MultiFloatingFilterComp expects MultiFilter as its parent");
      }
      cb(parent);
    });
  }
};
__decorateClass13([
  Autowired215("userComponentFactory")
], MultiFloatingFilterComp.prototype, "userComponentFactory", 2);
__decorateClass13([
  Autowired215("filterManager")
], MultiFloatingFilterComp.prototype, "filterManager", 2);
var VERSION13 = "31.3.2";
var MultiFilterModule = {
  version: VERSION13,
  moduleName: ModuleNames13.MultiFilterModule,
  beans: [],
  userComponents: [
    { componentName: "agMultiColumnFilter", componentClass: MultiFilter },
    { componentName: "agMultiColumnFloatingFilter", componentClass: MultiFloatingFilterComp }
  ],
  dependantModules: [
    EnterpriseCoreModule
  ]
};

// enterprise-modules/rich-select/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames14 } from "ag-grid-community";
import {
  AgRichSelect as AgRichSelect3,
  Events as Events18,
  PopupComponent,
  _ as _51
} from "ag-grid-community";
var RichSelectCellEditor = class extends PopupComponent {
  constructor() {
    super(
      /* html */
      `<div class="ag-cell-edit-wrapper"></div>`
    );
  }
  init(params) {
    this.params = params;
    const { cellStartedEdit, cellHeight, values } = params;
    if (_51.missing(values)) {
      console.warn("AG Grid: agRichSelectCellEditor requires cellEditorParams.values to be set");
    }
    const { params: richSelectParams, valuesPromise } = this.buildRichSelectParams();
    this.richSelect = this.createManagedBean(new AgRichSelect3(richSelectParams));
    this.richSelect.addCssClass("ag-cell-editor");
    this.appendChild(this.richSelect);
    if (valuesPromise) {
      valuesPromise.then((values2) => {
        this.richSelect.setValueList({ valueList: values2, refresh: true });
        const searchStringCallback = this.getSearchStringCallback(values2);
        if (searchStringCallback) {
          this.richSelect.setSearchStringCreator(searchStringCallback);
        }
      });
    }
    this.addManagedListener(this.richSelect, Events18.EVENT_FIELD_PICKER_VALUE_SELECTED, this.onEditorPickerValueSelected.bind(this));
    this.addManagedListener(this.richSelect.getGui(), "focusout", this.onEditorFocusOut.bind(this));
    this.focusAfterAttached = cellStartedEdit;
    if (_51.exists(cellHeight)) {
      this.richSelect.setRowHeight(cellHeight);
    }
  }
  onEditorPickerValueSelected(e) {
    this.params.stopEditing(!e.fromEnterKey);
  }
  onEditorFocusOut(e) {
    if (this.richSelect.getGui().contains(e.relatedTarget)) {
      return;
    }
    this.params.stopEditing(true);
  }
  buildRichSelectParams() {
    const {
      cellRenderer,
      value,
      values,
      formatValue,
      searchDebounceDelay,
      valueListGap,
      valueListMaxHeight,
      valueListMaxWidth,
      allowTyping,
      filterList,
      searchType,
      highlightMatch,
      valuePlaceholder,
      eventKey
    } = this.params;
    const ret = {
      value,
      cellRenderer,
      searchDebounceDelay,
      valueFormatter: formatValue,
      pickerAriaLabelKey: "ariaLabelRichSelectField",
      pickerAriaLabelValue: "Rich Select Field",
      pickerType: "virtual-list",
      pickerGap: valueListGap,
      allowTyping,
      filterList,
      searchType,
      highlightMatch,
      maxPickerHeight: valueListMaxHeight,
      maxPickerWidth: valueListMaxWidth,
      placeholder: valuePlaceholder,
      initialInputValue: (eventKey == null ? void 0 : eventKey.length) === 1 ? eventKey : void 0
    };
    let valuesResult;
    let valuesPromise;
    if (typeof values === "function") {
      valuesResult = values(this.params);
    } else {
      valuesResult = values != null ? values : [];
    }
    if (Array.isArray(valuesResult)) {
      ret.valueList = valuesResult;
      ret.searchStringCreator = this.getSearchStringCallback(valuesResult);
    } else {
      valuesPromise = valuesResult;
    }
    return { params: ret, valuesPromise };
  }
  getSearchStringCallback(values) {
    const { colDef } = this.params;
    if (typeof values[0] !== "object" || !colDef.keyCreator) {
      return;
    }
    return (values2) => values2.map((value) => {
      const keyParams = this.gos.addGridCommonParams({
        value,
        colDef: this.params.colDef,
        column: this.params.column,
        node: this.params.node,
        data: this.params.data
      });
      return colDef.keyCreator(keyParams);
    });
  }
  // we need to have the gui attached before we can draw the virtual rows, as the
  // virtual row logic needs info about the gui state
  afterGuiAttached() {
    const { focusAfterAttached, params } = this;
    setTimeout(() => {
      if (!this.isAlive()) {
        return;
      }
      if (focusAfterAttached) {
        const focusableEl = this.richSelect.getFocusableElement();
        focusableEl.focus();
        const { allowTyping, eventKey: eventKey2 } = this.params;
        if (allowTyping && (!eventKey2 || eventKey2.length !== 1)) {
          focusableEl.select();
        }
      }
      this.richSelect.showPicker();
      const { eventKey } = params;
      if (eventKey) {
        if ((eventKey == null ? void 0 : eventKey.length) === 1) {
          this.richSelect.searchTextFromString(eventKey);
        }
      }
    });
  }
  getValue() {
    return this.richSelect.getValue();
  }
  isPopup() {
    return false;
  }
};
var VERSION14 = "31.3.2";
var RichSelectModule = {
  version: VERSION14,
  moduleName: ModuleNames14.RichSelectModule,
  beans: [],
  userComponents: [
    { componentName: "agRichSelect", componentClass: RichSelectCellEditor },
    { componentName: "agRichSelectCellEditor", componentClass: RichSelectCellEditor }
  ],
  dependantModules: [
    EnterpriseCoreModule
  ]
};

// enterprise-modules/server-side-row-model/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames26 } from "ag-grid-community";
import {
  _ as _314,
  Autowired as Autowired511,
  Bean as Bean28,
  BeanStub as BeanStub45,
  Events as Events37,
  NumberSequence as NumberSequence4,
  PostConstruct as PostConstruct510,
  PreDestroy as PreDestroy4,
  RowNode as RowNode5,
  Optional as Optional6,
  ModuleRegistry as ModuleRegistry6,
  ModuleNames as ModuleNames15
} from "ag-grid-community";
import {
  _ as _57,
  Autowired as Autowired66,
  Events as Events19,
  NumberSequence,
  PostConstruct as PostConstruct64,
  PreDestroy as PreDestroy5,
  RowNodeBlock,
  ServerSideTransactionResultStatus
} from "ag-grid-community";
import {
  _ as _217,
  Autowired as Autowired415,
  BeanStub as BeanStub35,
  Events as Events29,
  NumberSequence as NumberSequence3,
  PostConstruct as PostConstruct413,
  PreDestroy as PreDestroy32,
  ServerSideTransactionResultStatus as ServerSideTransactionResultStatus2
} from "ag-grid-community";
import { Autowired as Autowired315, BeanStub as BeanStub28, PostConstruct as PostConstruct314, PreDestroy as PreDestroy22 } from "ag-grid-community";
import { Autowired as Autowired216, Bean as Bean14, BeanStub as BeanStub21, PostConstruct as PostConstruct215, RowNodeBlockLoader as RowNodeBlockLoader2 } from "ag-grid-community";
import {
  _ as _411,
  Autowired as Autowired67,
  Bean as Bean35,
  BeanStub as BeanStub55
} from "ag-grid-community";
import {
  _ as _58,
  Autowired as Autowired75,
  Bean as Bean45,
  BeanStub as BeanStub65,
  RowNode as RowNode7
} from "ag-grid-community";
import { Bean as Bean54, PreDestroy as PreDestroy52 } from "ag-grid-community";
import {
  Autowired as Autowired85,
  Bean as Bean64,
  BeanStub as BeanStub74,
  Events as Events46,
  PostConstruct as PostConstruct74,
  ServerSideTransactionResultStatus as ServerSideTransactionResultStatus3
} from "ag-grid-community";
import {
  _ as _67,
  Autowired as Autowired94,
  BeanStub as BeanStub82,
  Events as Events55,
  PostConstruct as PostConstruct83,
  RowNode as RowNode10,
  Bean as Bean73
} from "ag-grid-community";
import {
  Autowired as Autowired104,
  Bean as Bean82,
  BeanStub as BeanStub92,
  Events as Events65,
  PostConstruct as PostConstruct93
} from "ag-grid-community";
import {
  Autowired as Autowired113,
  Bean as Bean92,
  BeanStub as BeanStub102,
  Events as Events75,
  PostConstruct as PostConstruct103
} from "ag-grid-community";
import {
  _ as _85,
  Autowired as Autowired123,
  Bean as Bean102
} from "ag-grid-community";
import { Autowired as Autowired133, Bean as Bean112 } from "ag-grid-community";
import {
  Autowired as Autowired162,
  Bean as Bean122,
  BeanStub as BeanStub132,
  Events as Events102,
  PostConstruct as PostConstruct133
} from "ag-grid-community";
import { Autowired as Autowired142, BeanStub as BeanStub112, Events as Events84, PostConstruct as PostConstruct113 } from "ag-grid-community";
import { Autowired as Autowired152, BeanStub as BeanStub122, PostConstruct as PostConstruct123, Events as Events93 } from "ag-grid-community";
import {
  Autowired as Autowired172,
  Bean as Bean132,
  Events as Events112,
  ExpansionService
} from "ag-grid-community";
var __defProp14 = Object.defineProperty;
var __defProps9 = Object.defineProperties;
var __getOwnPropDesc14 = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs9 = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols12 = Object.getOwnPropertySymbols;
var __hasOwnProp13 = Object.prototype.hasOwnProperty;
var __propIsEnum12 = Object.prototype.propertyIsEnumerable;
var __defNormalProp12 = (obj, key, value) => key in obj ? __defProp14(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues12 = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp13.call(b, prop))
      __defNormalProp12(a, prop, b[prop]);
  if (__getOwnPropSymbols12)
    for (var prop of __getOwnPropSymbols12(b)) {
      if (__propIsEnum12.call(b, prop))
        __defNormalProp12(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps9 = (a, b) => __defProps9(a, __getOwnPropDescs9(b));
var __objRest2 = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp13.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols12)
    for (var prop of __getOwnPropSymbols12(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum12.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __decorateClass14 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc14(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp14(target, key, result);
  return result;
};
var FullStore = class extends RowNodeBlock {
  constructor(ssrmParams, storeParams, parentRowNode) {
    super(0);
    this.nodeIdSequence = new NumberSequence();
    this.info = {};
    this.ssrmParams = ssrmParams;
    this.parentRowNode = parentRowNode;
    this.level = parentRowNode.level + 1;
    this.groupLevel = ssrmParams.rowGroupCols ? this.level < ssrmParams.rowGroupCols.length : void 0;
    this.leafGroup = ssrmParams.rowGroupCols ? this.level === ssrmParams.rowGroupCols.length - 1 : false;
  }
  postConstruct() {
    this.usingTreeData = this.gos.get("treeData");
    this.nodeIdPrefix = this.blockUtils.createNodeIdPrefix(this.parentRowNode);
    if (!this.usingTreeData && this.groupLevel) {
      const groupColVo = this.ssrmParams.rowGroupCols[this.level];
      this.groupField = groupColVo.field;
      this.rowGroupColumn = this.columnModel.getRowGroupColumns()[this.level];
    }
    let initialRowCount = 1;
    const isRootStore = this.parentRowNode.level === -1;
    const userInitialRowCount = this.storeUtils.getServerSideInitialRowCount();
    if (isRootStore && userInitialRowCount != null) {
      initialRowCount = userInitialRowCount;
    }
    this.initialiseRowNodes(initialRowCount);
    this.rowNodeBlockLoader.addBlock(this);
    this.addDestroyFunc(() => this.rowNodeBlockLoader.removeBlock(this));
    this.postSortFunc = this.gos.getCallback("postSortRows");
    if (userInitialRowCount != null) {
      this.eventService.dispatchEventOnce({
        type: Events19.EVENT_ROW_COUNT_READY
      });
    }
  }
  destroyRowNodes() {
    this.blockUtils.destroyRowNodes(this.allRowNodes);
    this.allRowNodes = [];
    this.nodesAfterSort = [];
    this.nodesAfterFilter = [];
    this.allNodesMap = {};
  }
  initialiseRowNodes(loadingRowsCount, failedLoad = false) {
    this.destroyRowNodes();
    for (let i = 0; i < loadingRowsCount; i++) {
      const loadingRowNode = this.blockUtils.createRowNode(
        {
          field: this.groupField,
          group: this.groupLevel,
          leafGroup: this.leafGroup,
          level: this.level,
          parent: this.parentRowNode,
          rowGroupColumn: this.rowGroupColumn
        }
      );
      if (failedLoad) {
        loadingRowNode.failedLoad = true;
      }
      this.allRowNodes.push(loadingRowNode);
      this.nodesAfterFilter.push(loadingRowNode);
      this.nodesAfterSort.push(loadingRowNode);
    }
  }
  getBlockStateJson() {
    return {
      id: this.nodeIdPrefix ? this.nodeIdPrefix : "",
      state: this.getState()
    };
  }
  loadFromDatasource() {
    this.storeUtils.loadFromDatasource({
      startRow: void 0,
      endRow: void 0,
      parentBlock: this,
      parentNode: this.parentRowNode,
      storeParams: this.ssrmParams,
      success: this.success.bind(this, this.getVersion()),
      fail: this.pageLoadFailed.bind(this, this.getVersion())
    });
  }
  getStartRow() {
    return 0;
  }
  getEndRow() {
    return this.nodesAfterSort.length;
  }
  createDataNode(data, index) {
    const rowNode = this.blockUtils.createRowNode(
      {
        field: this.groupField,
        group: this.groupLevel,
        leafGroup: this.leafGroup,
        level: this.level,
        parent: this.parentRowNode,
        rowGroupColumn: this.rowGroupColumn
      }
    );
    if (index != null) {
      _57.insertIntoArray(this.allRowNodes, rowNode, index);
    } else {
      this.allRowNodes.push(rowNode);
    }
    const defaultId = this.prefixId(this.nodeIdSequence.next());
    this.blockUtils.setDataIntoRowNode(rowNode, data, defaultId, void 0);
    this.nodeManager.addRowNode(rowNode);
    this.blockUtils.checkOpenByDefault(rowNode);
    this.allNodesMap[rowNode.id] = rowNode;
    return rowNode;
  }
  prefixId(id) {
    if (this.nodeIdPrefix) {
      return this.nodeIdPrefix + "-" + id;
    } else {
      return id.toString();
    }
  }
  processServerFail() {
    this.initialiseRowNodes(1, true);
    this.fireStoreUpdatedEvent();
    this.flushAsyncTransactions();
  }
  processServerResult(params) {
    if (!this.isAlive()) {
      return;
    }
    const info = params.groupLevelInfo;
    if (info) {
      Object.assign(this.info, info);
    }
    if (params.pivotResultFields) {
      this.serverSideRowModel.generateSecondaryColumns(params.pivotResultFields);
    }
    const nodesToRecycle = this.allRowNodes.length > 0 ? this.allNodesMap : void 0;
    this.allRowNodes = [];
    this.nodesAfterSort = [];
    this.nodesAfterFilter = [];
    this.allNodesMap = {};
    if (!params.rowData) {
      _57.warnOnce('"params.data" is missing from Server-Side Row Model success() callback. Please use the "data" attribute. If no data is returned, set an empty list.');
    }
    this.createOrRecycleNodes(nodesToRecycle, params.rowData);
    if (nodesToRecycle) {
      this.blockUtils.destroyRowNodes(_57.getAllValuesInObject(nodesToRecycle));
    }
    if (this.level === 0) {
      this.eventService.dispatchEventOnce({
        type: Events19.EVENT_ROW_COUNT_READY
      });
    }
    this.filterAndSortNodes();
    this.fireStoreUpdatedEvent();
    this.flushAsyncTransactions();
  }
  createOrRecycleNodes(nodesToRecycle, rowData) {
    if (!rowData) {
      return;
    }
    const lookupNodeToRecycle = (data) => {
      if (!nodesToRecycle) {
        return void 0;
      }
      const getRowIdFunc = this.gos.getCallback("getRowId");
      if (!getRowIdFunc) {
        return void 0;
      }
      const parentKeys = this.parentRowNode.getGroupKeys();
      const level = this.level;
      const id = getRowIdFunc({
        data,
        parentKeys: parentKeys.length > 0 ? parentKeys : void 0,
        level
      });
      const foundNode = nodesToRecycle[id];
      if (!foundNode) {
        return void 0;
      }
      delete nodesToRecycle[id];
      return foundNode;
    };
    const recycleNode = (rowNode, dataItem) => {
      this.allNodesMap[rowNode.id] = rowNode;
      this.blockUtils.updateDataIntoRowNode(rowNode, dataItem);
      this.allRowNodes.push(rowNode);
    };
    rowData.forEach((dataItem) => {
      const nodeToRecycle = lookupNodeToRecycle(dataItem);
      if (nodeToRecycle) {
        recycleNode(nodeToRecycle, dataItem);
      } else {
        this.createDataNode(dataItem);
      }
    });
  }
  flushAsyncTransactions() {
    window.setTimeout(() => this.transactionManager.flushAsyncTransactions(), 0);
  }
  filterAndSortNodes() {
    this.filterRowNodes();
    this.sortRowNodes();
  }
  sortRowNodes() {
    const serverIsSorting = this.storeUtils.isServerSideSortAllLevels() || this.storeUtils.isServerSideSortOnServer();
    const sortOptions = this.sortController.getSortOptions();
    const noSortApplied = !sortOptions || sortOptions.length == 0;
    if (serverIsSorting || noSortApplied) {
      this.nodesAfterSort = this.nodesAfterFilter;
      return;
    }
    this.nodesAfterSort = this.rowNodeSorter.doFullSort(this.nodesAfterFilter, sortOptions);
    if (this.postSortFunc) {
      const params = { nodes: this.nodesAfterSort };
      this.postSortFunc(params);
    }
  }
  filterRowNodes() {
    const serverIsFiltering = !this.storeUtils.isServerSideOnlyRefreshFilteredGroups() || this.storeUtils.isServerSideFilterOnServer();
    const groupLevel = this.groupLevel;
    if (serverIsFiltering || groupLevel) {
      this.nodesAfterFilter = this.allRowNodes;
      return;
    }
    this.nodesAfterFilter = this.allRowNodes.filter(
      (rowNode) => this.filterManager.doesRowPassFilter({ rowNode })
    );
  }
  clearDisplayIndexes() {
    this.displayIndexStart = void 0;
    this.displayIndexEnd = void 0;
    this.allRowNodes.forEach((rowNode) => this.blockUtils.clearDisplayIndex(rowNode));
  }
  getDisplayIndexEnd() {
    return this.displayIndexEnd;
  }
  isDisplayIndexInStore(displayIndex) {
    if (this.getRowCount() === 0) {
      return false;
    }
    return displayIndex >= this.displayIndexStart && displayIndex < this.displayIndexEnd;
  }
  setDisplayIndexes(displayIndexSeq, nextRowTop) {
    this.displayIndexStart = displayIndexSeq.peek();
    this.topPx = nextRowTop.value;
    const visibleNodeIds = {};
    this.nodesAfterSort.forEach((rowNode) => {
      this.blockUtils.setDisplayIndex(rowNode, displayIndexSeq, nextRowTop);
      visibleNodeIds[rowNode.id] = true;
    });
    this.allRowNodes.forEach((rowNode) => {
      if (!visibleNodeIds[rowNode.id]) {
        this.blockUtils.clearDisplayIndex(rowNode);
      }
    });
    this.displayIndexEnd = displayIndexSeq.peek();
    this.heightPx = nextRowTop.value - this.topPx;
  }
  forEachStoreDeep(callback, sequence = new NumberSequence()) {
    callback(this, sequence.next());
    this.allRowNodes.forEach((rowNode) => {
      const childCache = rowNode.childStore;
      if (childCache) {
        childCache.forEachStoreDeep(callback, sequence);
      }
    });
  }
  forEachNodeDeep(callback, sequence = new NumberSequence()) {
    this.allRowNodes.forEach((rowNode) => {
      callback(rowNode, sequence.next());
      const childCache = rowNode.childStore;
      if (childCache) {
        childCache.forEachNodeDeep(callback, sequence);
      }
    });
  }
  forEachNodeDeepAfterFilterAndSort(callback, sequence = new NumberSequence(), includeFooterNodes = false) {
    this.nodesAfterSort.forEach((rowNode) => {
      callback(rowNode, sequence.next());
      const childCache = rowNode.childStore;
      if (childCache) {
        childCache.forEachNodeDeepAfterFilterAndSort(callback, sequence, includeFooterNodes);
      }
    });
    if (includeFooterNodes && this.parentRowNode.sibling) {
      callback(this.parentRowNode.sibling, sequence.next());
    }
  }
  getRowUsingDisplayIndex(displayRowIndex) {
    if (!this.isDisplayIndexInStore(displayRowIndex)) {
      return void 0;
    }
    const res = this.blockUtils.binarySearchForDisplayIndex(displayRowIndex, this.nodesAfterSort);
    return res;
  }
  getRowBounds(index) {
    for (let i = 0; i < this.nodesAfterSort.length; i++) {
      const rowNode = this.nodesAfterSort[i];
      const res = this.blockUtils.extractRowBounds(rowNode, index);
      if (res) {
        return res;
      }
    }
    return null;
  }
  isPixelInRange(pixel) {
    return pixel >= this.topPx && pixel < this.topPx + this.heightPx;
  }
  getRowIndexAtPixel(pixel) {
    const pixelBeforeThisStore = pixel <= this.topPx;
    if (pixelBeforeThisStore) {
      const firstNode = this.nodesAfterSort[0];
      return firstNode.rowIndex;
    }
    const pixelAfterThisStore = pixel >= this.topPx + this.heightPx;
    if (pixelAfterThisStore) {
      const lastRowNode = this.nodesAfterSort[this.nodesAfterSort.length - 1];
      const lastRowNodeBottomPx = lastRowNode.rowTop + lastRowNode.rowHeight;
      if (pixel >= lastRowNodeBottomPx && lastRowNode.expanded) {
        if (lastRowNode.childStore && lastRowNode.childStore.getRowCount() > 0) {
          return lastRowNode.childStore.getRowIndexAtPixel(pixel);
        }
        if (lastRowNode.detailNode) {
          return lastRowNode.detailNode.rowIndex;
        }
      }
      return lastRowNode.rowIndex;
    }
    let res = null;
    this.nodesAfterSort.forEach((rowNode) => {
      const res2 = this.blockUtils.getIndexAtPixel(rowNode, pixel);
      if (res2 != null) {
        res = res2;
      }
    });
    const pixelIsPastLastRow = res == null;
    if (pixelIsPastLastRow) {
      return this.displayIndexEnd - 1;
    }
    return res;
  }
  getChildStore(keys) {
    return this.storeUtils.getChildStore(keys, this, (key) => {
      const rowNode = this.allRowNodes.find((currentRowNode) => {
        return currentRowNode.key == key;
      });
      return rowNode;
    });
  }
  forEachChildStoreShallow(callback) {
    this.allRowNodes.forEach((rowNode) => {
      const childStore = rowNode.childStore;
      if (childStore) {
        callback(childStore);
      }
    });
  }
  refreshAfterFilter(params) {
    const serverIsFiltering = this.storeUtils.isServerSideFilterOnServer();
    const storeIsImpacted = this.storeUtils.isServerRefreshNeeded(this.parentRowNode, this.ssrmParams.rowGroupCols, params);
    const serverIsFilteringAllLevels = !this.storeUtils.isServerSideOnlyRefreshFilteredGroups();
    if (serverIsFilteringAllLevels || serverIsFiltering && storeIsImpacted) {
      this.refreshStore(true);
      this.sortRowNodes();
      return;
    }
    this.filterRowNodes();
    this.sortRowNodes();
    this.forEachChildStoreShallow((store) => store.refreshAfterFilter(params));
  }
  refreshAfterSort(params) {
    const serverIsSorting = this.storeUtils.isServerSideSortOnServer();
    const storeIsImpacted = this.storeUtils.isServerRefreshNeeded(this.parentRowNode, this.ssrmParams.rowGroupCols, params);
    const serverIsSortingAllLevels = this.storeUtils.isServerSideSortAllLevels();
    if (serverIsSortingAllLevels || serverIsSorting && storeIsImpacted) {
      this.refreshStore(true);
      this.filterRowNodes();
      return;
    }
    this.filterRowNodes();
    this.sortRowNodes();
    this.forEachChildStoreShallow((store) => store.refreshAfterSort(params));
  }
  applyTransaction(transaction) {
    switch (this.getState()) {
      case RowNodeBlock.STATE_FAILED:
        return { status: ServerSideTransactionResultStatus.StoreLoadingFailed };
      case RowNodeBlock.STATE_LOADING:
        return { status: ServerSideTransactionResultStatus.StoreLoading };
      case RowNodeBlock.STATE_WAITING_TO_LOAD:
        return { status: ServerSideTransactionResultStatus.StoreWaitingToLoad };
    }
    const applyCallback = this.gos.getCallback("isApplyServerSideTransaction");
    if (applyCallback) {
      const params = {
        transaction,
        parentNode: this.parentRowNode,
        groupLevelInfo: this.info
      };
      const apply = applyCallback(params);
      if (!apply) {
        return { status: ServerSideTransactionResultStatus.Cancelled };
      }
    }
    const res = {
      status: ServerSideTransactionResultStatus.Applied,
      remove: [],
      update: [],
      add: []
    };
    const nodesToUnselect = [];
    this.executeAdd(transaction, res);
    this.executeRemove(transaction, res, nodesToUnselect);
    this.executeUpdate(transaction, res, nodesToUnselect);
    this.filterAndSortNodes();
    this.updateSelection(nodesToUnselect);
    return res;
  }
  updateSelection(nodesToUnselect) {
    const selectionChanged = nodesToUnselect.length > 0;
    if (selectionChanged) {
      this.selectionService.setNodesSelected({
        newValue: false,
        nodes: nodesToUnselect,
        suppressFinishActions: true,
        clearSelection: false,
        source: "rowDataChanged"
      });
      const event = {
        type: Events19.EVENT_SELECTION_CHANGED,
        source: "rowDataChanged"
      };
      this.eventService.dispatchEvent(event);
    }
  }
  executeAdd(rowDataTran, rowNodeTransaction) {
    const { add, addIndex } = rowDataTran;
    if (_57.missingOrEmpty(add)) {
      return;
    }
    const useIndex = typeof addIndex === "number" && addIndex >= 0;
    if (useIndex) {
      add.reverse().forEach((item) => {
        const newRowNode = this.createDataNode(item, addIndex);
        rowNodeTransaction.add.push(newRowNode);
      });
    } else {
      add.forEach((item) => {
        const newRowNode = this.createDataNode(item);
        rowNodeTransaction.add.push(newRowNode);
      });
    }
  }
  executeRemove(rowDataTran, rowNodeTransaction, nodesToUnselect) {
    const { remove } = rowDataTran;
    if (remove == null) {
      return;
    }
    const rowIdsRemoved = {};
    remove.forEach((item) => {
      const rowNode = this.lookupRowNode(item);
      if (!rowNode) {
        return;
      }
      if (rowNode.isSelected()) {
        nodesToUnselect.push(rowNode);
      }
      rowNode.clearRowTopAndRowIndex();
      rowIdsRemoved[rowNode.id] = true;
      delete this.allNodesMap[rowNode.id];
      rowNodeTransaction.remove.push(rowNode);
      this.nodeManager.removeNode(rowNode);
    });
    this.allRowNodes = this.allRowNodes.filter((rowNode) => !rowIdsRemoved[rowNode.id]);
  }
  executeUpdate(rowDataTran, rowNodeTransaction, nodesToUnselect) {
    const { update } = rowDataTran;
    if (update == null) {
      return;
    }
    update.forEach((item) => {
      const rowNode = this.lookupRowNode(item);
      if (!rowNode) {
        return;
      }
      this.blockUtils.updateDataIntoRowNode(rowNode, item);
      if (!rowNode.selectable && rowNode.isSelected()) {
        nodesToUnselect.push(rowNode);
      }
      rowNodeTransaction.update.push(rowNode);
    });
  }
  lookupRowNode(data) {
    const getRowIdFunc = this.gos.getCallback("getRowId");
    let rowNode;
    if (getRowIdFunc != null) {
      const level = this.level;
      const parentKeys = this.parentRowNode.getGroupKeys();
      const id = getRowIdFunc({
        data,
        parentKeys: parentKeys.length > 0 ? parentKeys : void 0,
        level
      });
      rowNode = this.allNodesMap[id];
      if (!rowNode) {
        console.error(`AG Grid: could not find row id=${id}, data item was not found for this id`);
        return null;
      }
    } else {
      rowNode = this.allRowNodes.find((currentRowNode) => currentRowNode.data === data);
      if (!rowNode) {
        console.error(`AG Grid: could not find data item as object was not found`, data);
        return null;
      }
    }
    return rowNode;
  }
  addStoreStates(result) {
    result.push({
      suppressInfiniteScroll: true,
      route: this.parentRowNode.getGroupKeys(),
      rowCount: this.allRowNodes.length,
      info: this.info
    });
    this.forEachChildStoreShallow((childStore) => childStore.addStoreStates(result));
  }
  refreshStore(purge) {
    if (purge) {
      const loadingRowsToShow = this.nodesAfterSort ? this.nodesAfterSort.length : 1;
      this.initialiseRowNodes(loadingRowsToShow);
    }
    this.scheduleLoad();
    this.fireStoreUpdatedEvent();
  }
  retryLoads() {
    if (this.getState() === RowNodeBlock.STATE_FAILED) {
      this.initialiseRowNodes(1);
      this.scheduleLoad();
    }
    this.forEachChildStoreShallow((store) => store.retryLoads());
  }
  scheduleLoad() {
    this.setStateWaitingToLoad();
    this.rowNodeBlockLoader.checkBlockToLoad();
  }
  // gets called 1) row count changed 2) cache purged 3) items inserted
  fireStoreUpdatedEvent() {
    const event = {
      type: Events19.EVENT_STORE_UPDATED
    };
    this.eventService.dispatchEvent(event);
  }
  getRowCount() {
    return this.nodesAfterSort.length;
  }
  getTopLevelRowDisplayedIndex(topLevelIndex) {
    const rowNode = this.nodesAfterSort[topLevelIndex];
    return rowNode.rowIndex;
  }
  isLastRowIndexKnown() {
    return this.getState() == RowNodeBlock.STATE_LOADED;
  }
  getRowNodesInRange(firstInRange, lastInRange) {
    const result = [];
    let inActiveRange = false;
    if (_57.missing(firstInRange)) {
      inActiveRange = true;
    }
    this.nodesAfterSort.forEach((rowNode) => {
      const hitFirstOrLast = rowNode === firstInRange || rowNode === lastInRange;
      if (inActiveRange || hitFirstOrLast) {
        result.push(rowNode);
      }
      if (hitFirstOrLast) {
        inActiveRange = !inActiveRange;
      }
    });
    const invalidRange = inActiveRange;
    return invalidRange ? [] : result;
  }
  getStoreBounds() {
    return {
      topPx: this.topPx,
      heightPx: this.heightPx
    };
  }
};
__decorateClass14([
  Autowired66("ssrmStoreUtils")
], FullStore.prototype, "storeUtils", 2);
__decorateClass14([
  Autowired66("ssrmBlockUtils")
], FullStore.prototype, "blockUtils", 2);
__decorateClass14([
  Autowired66("columnModel")
], FullStore.prototype, "columnModel", 2);
__decorateClass14([
  Autowired66("rowNodeBlockLoader")
], FullStore.prototype, "rowNodeBlockLoader", 2);
__decorateClass14([
  Autowired66("rowNodeSorter")
], FullStore.prototype, "rowNodeSorter", 2);
__decorateClass14([
  Autowired66("sortController")
], FullStore.prototype, "sortController", 2);
__decorateClass14([
  Autowired66("selectionService")
], FullStore.prototype, "selectionService", 2);
__decorateClass14([
  Autowired66("ssrmNodeManager")
], FullStore.prototype, "nodeManager", 2);
__decorateClass14([
  Autowired66("filterManager")
], FullStore.prototype, "filterManager", 2);
__decorateClass14([
  Autowired66("ssrmTransactionManager")
], FullStore.prototype, "transactionManager", 2);
__decorateClass14([
  Autowired66("rowModel")
], FullStore.prototype, "serverSideRowModel", 2);
__decorateClass14([
  PostConstruct64
], FullStore.prototype, "postConstruct", 1);
__decorateClass14([
  PreDestroy5
], FullStore.prototype, "destroyRowNodes", 1);
var MultiIndexMap = class {
  constructor(...indexes) {
    if (indexes.length < 1) {
      throw new Error("AG Grid: At least one index must be provided.");
    }
    this.indexes = indexes;
    this.maps = new Map(
      this.indexes.map((index) => [index, /* @__PURE__ */ new Map()])
    );
  }
  getSize() {
    return this.maps.get(this.indexes[0]).size;
  }
  getBy(index, key) {
    const map = this.maps.get(index);
    if (!map) {
      throw new Error(`AG Grid: ${String(index)} not found`);
    }
    return map.get(key);
  }
  set(item) {
    this.indexes.forEach((index) => {
      const map = this.maps.get(index);
      if (!map) {
        throw new Error(`AG Grid: ${String(index)} not found`);
      }
      map.set(item[index], item);
    });
  }
  delete(item) {
    this.indexes.forEach((index) => {
      const map = this.maps.get(index);
      if (!map) {
        throw new Error(`AG Grid: ${String(index)} not found`);
      }
      map.delete(item[index]);
    });
  }
  clear() {
    this.maps.forEach((map) => map.clear());
  }
  getIterator(index) {
    const map = this.maps.get(index);
    if (!map) {
      throw new Error(`AG Grid: ${String(index)} not found`);
    }
    return map.values();
  }
  forEach(callback) {
    const iterator = this.getIterator(this.indexes[0]);
    let pointer;
    while (pointer = iterator.next()) {
      if (pointer.done)
        break;
      callback(pointer.value);
    }
  }
  find(callback) {
    const iterator = this.getIterator(this.indexes[0]);
    let pointer;
    while (pointer = iterator.next()) {
      if (pointer.done)
        break;
      if (callback(pointer.value)) {
        return pointer.value;
      }
    }
  }
  filter(predicate) {
    const iterator = this.getIterator(this.indexes[0]);
    let pointer;
    const result = [];
    while (pointer = iterator.next()) {
      if (pointer.done)
        break;
      if (predicate(pointer.value)) {
        result.push(pointer.value);
      }
    }
    return result;
  }
};
var LazyBlockLoadingService = class extends BeanStub21 {
  constructor() {
    super(...arguments);
    this.cacheLoadingNodesMap = /* @__PURE__ */ new Map();
    this.isCheckQueued = false;
    this.nextBlockToLoad = void 0;
  }
  init() {
    this.addManagedListener(this.rowNodeBlockLoader, RowNodeBlockLoader2.BLOCK_LOADED_EVENT, () => this.queueLoadCheck());
  }
  subscribe(cache) {
    this.cacheLoadingNodesMap.set(cache, /* @__PURE__ */ new Set());
  }
  unsubscribe(cache) {
    this.cacheLoadingNodesMap.delete(cache);
  }
  /**
   * Queues a microtask to check if any blocks need to be loaded.
   */
  queueLoadCheck() {
    if (this.isCheckQueued) {
      return;
    }
    this.isCheckQueued = true;
    window.queueMicrotask(() => {
      this.queueLoadAction();
      this.isCheckQueued = false;
    });
  }
  queueLoadAction() {
    const nextBlockToLoad = this.getBlockToLoad();
    if (!nextBlockToLoad) {
      return;
    }
    const isSameBlock = this.nextBlockToLoad && this.nextBlockToLoad.cache === nextBlockToLoad.cache && this.nextBlockToLoad.index === nextBlockToLoad.index;
    if (isSameBlock) {
      return;
    }
    if (!this.nextBlockToLoad || !isSameBlock) {
      this.nextBlockToLoad = nextBlockToLoad;
      window.clearTimeout(this.loaderTimeout);
      const startRow = Number(this.nextBlockToLoad.index);
      const cache = this.nextBlockToLoad.cache;
      const endRow = nextBlockToLoad.index + nextBlockToLoad.cache.getBlockSize();
      this.loaderTimeout = window.setTimeout(() => {
        if (!cache.isAlive()) {
          return;
        }
        this.loaderTimeout = void 0;
        this.attemptLoad(cache, startRow, endRow);
        this.nextBlockToLoad = void 0;
      }, this.gos.get("blockLoadDebounceMillis"));
    }
  }
  attemptLoad(cache, start, end) {
    const availableLoadingCount = this.rowNodeBlockLoader.getAvailableLoadingCount();
    if (availableLoadingCount != null && availableLoadingCount === 0) {
      return;
    }
    ;
    this.rowNodeBlockLoader.registerLoads(1);
    this.executeLoad(cache, start, end);
    this.queueLoadCheck();
  }
  executeLoad(cache, startRow, endRow) {
    var _a;
    const ssrmParams = cache.getSsrmParams();
    const request = {
      startRow,
      endRow,
      rowGroupCols: ssrmParams.rowGroupCols,
      valueCols: ssrmParams.valueCols,
      pivotCols: ssrmParams.pivotCols,
      pivotMode: ssrmParams.pivotMode,
      groupKeys: cache.store.getParentNode().getGroupKeys(),
      filterModel: ssrmParams.filterModel,
      sortModel: ssrmParams.sortModel
    };
    const loadingNodes = this.cacheLoadingNodesMap.get(cache);
    const removeNodesFromLoadingMap = () => {
      for (let i = 0; i < endRow - startRow; i++) {
        loadingNodes.delete(startRow + i);
      }
    };
    const addNodesToLoadingMap = () => {
      for (let i = 0; i < endRow - startRow; i++) {
        loadingNodes.add(startRow + i);
      }
    };
    const success = (params2) => {
      this.rowNodeBlockLoader.loadComplete();
      cache.onLoadSuccess(startRow, endRow - startRow, params2);
      removeNodesFromLoadingMap();
    };
    const fail = () => {
      this.rowNodeBlockLoader.loadComplete();
      cache.onLoadFailed(startRow, endRow - startRow);
      removeNodesFromLoadingMap();
    };
    const params = this.gos.addGridCommonParams({
      request,
      success,
      fail,
      parentNode: cache.store.getParentNode()
    });
    addNodesToLoadingMap();
    (_a = cache.getSsrmParams().datasource) == null ? void 0 : _a.getRows(params);
  }
  getBlockToLoad() {
    var _a;
    const firstRowInViewport = this.rowRenderer.getFirstVirtualRenderedRow();
    const lastRowInViewport = this.rowRenderer.getLastVirtualRenderedRow();
    for (let i = firstRowInViewport; i <= lastRowInViewport; i++) {
      const row = this.rowModel.getRow(i);
      if (!row) {
        continue;
      }
      const store = row.parent && row.parent.childStore;
      if (!store || !(store instanceof LazyStore)) {
        continue;
      }
      const cache = store.getCache();
      const lazyNode = cache.getNodes().getBy("node", row);
      if (!lazyNode) {
        continue;
      }
      const loadingNodes = this.cacheLoadingNodesMap.get(cache);
      if (loadingNodes == null ? void 0 : loadingNodes.has(lazyNode.index)) {
        continue;
      }
      if (row.__needsRefreshWhenVisible || row.stub && !row.failedLoad) {
        return {
          cache,
          index: cache.getBlockStartIndex(lazyNode.index)
        };
      }
    }
    let cacheToRefresh = null;
    let nodeToRefresh = null;
    let nodeToRefreshDist = Number.MAX_SAFE_INTEGER;
    for (const cache of this.cacheLoadingNodesMap.keys()) {
      const nodesToRefresh = cache.getNodesToRefresh();
      nodesToRefresh.forEach((node) => {
        if (node.rowIndex == null) {
          nodeToRefresh = node;
          cacheToRefresh = cache;
          return;
        }
        const lazyNode = cache.getNodes().getBy("node", node);
        if (!lazyNode) {
          return;
        }
        const loadingNodes = this.cacheLoadingNodesMap.get(cache);
        if (loadingNodes == null ? void 0 : loadingNodes.has(lazyNode.index)) {
          return;
        }
        const distToViewportTop = Math.abs(firstRowInViewport - node.rowIndex);
        const distToViewportBottom = Math.abs(node.rowIndex - lastRowInViewport);
        if (distToViewportTop < nodeToRefreshDist) {
          nodeToRefresh = node;
          nodeToRefreshDist = distToViewportTop;
          cacheToRefresh = cache;
        }
        if (distToViewportBottom < nodeToRefreshDist) {
          nodeToRefresh = node;
          nodeToRefreshDist = distToViewportBottom;
          cacheToRefresh = cache;
        }
      });
    }
    if (!cacheToRefresh) {
      return void 0;
    }
    const lazyCache = cacheToRefresh;
    const lazyIndex = (_a = lazyCache.getNodes().getBy("node", nodeToRefresh)) == null ? void 0 : _a.index;
    return lazyIndex == null ? void 0 : {
      cache: lazyCache,
      index: lazyCache.getBlockStartIndex(lazyIndex)
    };
  }
  isRowLoading(cache, index) {
    var _a, _b;
    return (_b = (_a = this.cacheLoadingNodesMap.get(cache)) == null ? void 0 : _a.has(index)) != null ? _b : false;
  }
};
LazyBlockLoadingService.DEFAULT_BLOCK_SIZE = 100;
__decorateClass14([
  Autowired216("rowNodeBlockLoader")
], LazyBlockLoadingService.prototype, "rowNodeBlockLoader", 2);
__decorateClass14([
  Autowired216("rowRenderer")
], LazyBlockLoadingService.prototype, "rowRenderer", 2);
__decorateClass14([
  Autowired216("rowModel")
], LazyBlockLoadingService.prototype, "rowModel", 2);
__decorateClass14([
  PostConstruct215
], LazyBlockLoadingService.prototype, "init", 1);
LazyBlockLoadingService = __decorateClass14([
  Bean14("lazyBlockLoadingService")
], LazyBlockLoadingService);
var LazyCache = class extends BeanStub28 {
  constructor(store, numberOfRows, storeParams) {
    super();
    this.live = true;
    this.removedNodeCache = /* @__PURE__ */ new Map();
    this.store = store;
    this.numberOfRows = numberOfRows;
    this.isLastRowKnown = false;
    this.storeParams = storeParams;
  }
  init() {
    this.lazyBlockLoadingService.subscribe(this);
    this.nodeMap = new MultiIndexMap("index", "id", "node");
    this.nodeDisplayIndexMap = /* @__PURE__ */ new Map();
    this.nodesToRefresh = /* @__PURE__ */ new Set();
    this.defaultNodeIdPrefix = this.blockUtils.createNodeIdPrefix(this.store.getParentNode());
    this.getRowIdFunc = this.gos.getCallback("getRowId");
    this.isMasterDetail = this.gos.get("masterDetail");
  }
  destroyRowNodes() {
    this.lazyBlockLoadingService.unsubscribe(this);
    this.numberOfRows = 0;
    this.nodeMap.forEach((node) => this.blockUtils.destroyRowNode(node.node));
    this.nodeMap.clear();
    this.nodeDisplayIndexMap.clear();
    this.nodesToRefresh.clear();
    this.live = false;
  }
  /**
   * Get the row node for a specific display index from this store
   * @param displayIndex the display index of the node to find
   * @returns undefined if the node is not in the store bounds, otherwise will always return a node
   */
  getRowByDisplayIndex(displayIndex) {
    var _a, _b, _c, _d;
    if (!this.store.isDisplayIndexInStore(displayIndex)) {
      return void 0;
    }
    const node = this.nodeDisplayIndexMap.get(displayIndex);
    if (node) {
      if (node.stub || node.__needsRefreshWhenVisible) {
        this.lazyBlockLoadingService.queueLoadCheck();
      }
      return node;
    }
    if (displayIndex === this.store.getDisplayIndexStart()) {
      return this.createStubNode(0, displayIndex);
    }
    const contiguouslyPreviousNode = this.nodeDisplayIndexMap.get(displayIndex - 1);
    if (contiguouslyPreviousNode) {
      if (this.isMasterDetail && contiguouslyPreviousNode.master && contiguouslyPreviousNode.expanded) {
        return contiguouslyPreviousNode.detailNode;
      }
      if (contiguouslyPreviousNode.expanded && ((_a = contiguouslyPreviousNode.childStore) == null ? void 0 : _a.isDisplayIndexInStore(displayIndex))) {
        return (_b = contiguouslyPreviousNode.childStore) == null ? void 0 : _b.getRowUsingDisplayIndex(displayIndex);
      }
      const lazyCacheNode = this.nodeMap.getBy("node", contiguouslyPreviousNode);
      return this.createStubNode(lazyCacheNode.index + 1, displayIndex);
    }
    const adjacentNodes = this.getSurroundingNodesByDisplayIndex(displayIndex);
    if (adjacentNodes == null) {
      const storeIndexFromEndIndex2 = this.store.getRowCount() - (this.store.getDisplayIndexEnd() - displayIndex);
      return this.createStubNode(storeIndexFromEndIndex2, displayIndex);
    }
    const { previousNode, nextNode } = adjacentNodes;
    if (previousNode && previousNode.node.expanded && ((_c = previousNode.node.childStore) == null ? void 0 : _c.isDisplayIndexInStore(displayIndex))) {
      return (_d = previousNode.node.childStore) == null ? void 0 : _d.getRowUsingDisplayIndex(displayIndex);
    }
    if (nextNode) {
      const displayIndexDiff = nextNode.node.rowIndex - displayIndex;
      const newStoreIndex = nextNode.index - displayIndexDiff;
      return this.createStubNode(newStoreIndex, displayIndex);
    }
    const storeIndexFromEndIndex = this.store.getRowCount() - (this.store.getDisplayIndexEnd() - displayIndex);
    return this.createStubNode(storeIndexFromEndIndex, displayIndex);
  }
  /**
   * Used for creating and positioning a stub node without firing a store updated event
   */
  createStubNode(storeIndex, displayIndex) {
    const rowBounds = this.store.getRowBounds(displayIndex);
    const newNode = this.createRowAtIndex(storeIndex, null, (node) => {
      node.setRowIndex(displayIndex);
      node.setRowTop(rowBounds.rowTop);
      this.nodeDisplayIndexMap.set(displayIndex, node);
    });
    this.lazyBlockLoadingService.queueLoadCheck();
    return newNode;
  }
  /**
   * @param index The row index relative to this store
   * @returns A rowNode at the given store index
   */
  getRowByStoreIndex(index) {
    var _a;
    return (_a = this.nodeMap.getBy("index", index)) == null ? void 0 : _a.node;
  }
  /**
   * Given a number of rows, skips through the given sequence & row top reference (using default row height)
   * @param numberOfRowsToSkip number of rows to skip over in the given sequence
   * @param displayIndexSeq the sequence in which to skip
   * @param nextRowTop the row top reference in which to skip
   */
  skipDisplayIndexes(numberOfRowsToSkip, displayIndexSeq, nextRowTop) {
    if (numberOfRowsToSkip === 0) {
      return;
    }
    const defaultRowHeight = this.gos.getRowHeightAsNumber();
    displayIndexSeq.skip(numberOfRowsToSkip);
    nextRowTop.value += numberOfRowsToSkip * defaultRowHeight;
  }
  /**
   * @param displayIndexSeq the number sequence for generating the display index of each row
   * @param nextRowTop an object containing the next row top value intended to be modified by ref per row
   */
  setDisplayIndexes(displayIndexSeq, nextRowTop) {
    this.nodeDisplayIndexMap.clear();
    const orderedMap = {};
    this.nodeMap.forEach((lazyNode) => {
      orderedMap[lazyNode.index] = lazyNode.node;
    });
    let lastIndex = -1;
    for (const stringIndex in orderedMap) {
      const node = orderedMap[stringIndex];
      const numericIndex = Number(stringIndex);
      const numberOfRowsToSkip2 = numericIndex - 1 - lastIndex;
      this.skipDisplayIndexes(numberOfRowsToSkip2, displayIndexSeq, nextRowTop);
      this.blockUtils.setDisplayIndex(node, displayIndexSeq, nextRowTop);
      this.nodeDisplayIndexMap.set(node.rowIndex, node);
      lastIndex = numericIndex;
    }
    const numberOfRowsToSkip = this.numberOfRows - 1 - lastIndex;
    this.skipDisplayIndexes(numberOfRowsToSkip, displayIndexSeq, nextRowTop);
    this.purgeExcessRows();
  }
  getRowCount() {
    return this.numberOfRows;
  }
  setRowCount(rowCount, isLastRowIndexKnown) {
    if (rowCount < 0) {
      throw new Error("AG Grid: setRowCount can only accept a positive row count.");
    }
    this.numberOfRows = rowCount;
    if (isLastRowIndexKnown != null) {
      this.isLastRowKnown = isLastRowIndexKnown;
      if (isLastRowIndexKnown === false) {
        this.numberOfRows += 1;
      }
    }
    this.fireStoreUpdatedEvent();
  }
  getNodes() {
    return this.nodeMap;
  }
  getNodeCachedByDisplayIndex(displayIndex) {
    var _a;
    return (_a = this.nodeDisplayIndexMap.get(displayIndex)) != null ? _a : null;
  }
  getNodesToRefresh() {
    return this.nodesToRefresh;
  }
  /**
   * @returns the previous and next loaded row nodes surrounding the given display index
   */
  getSurroundingNodesByDisplayIndex(displayIndex) {
    let nextNode;
    let previousNode;
    this.nodeMap.forEach((lazyNode) => {
      if (displayIndex > lazyNode.node.rowIndex) {
        if (previousNode == null || previousNode.node.rowIndex < lazyNode.node.rowIndex) {
          previousNode = lazyNode;
        }
        return;
      }
      if (nextNode == null || nextNode.node.rowIndex > lazyNode.node.rowIndex) {
        nextNode = lazyNode;
        return;
      }
    });
    if (!previousNode && !nextNode)
      return null;
    return { previousNode, nextNode };
  }
  /**
   * Get or calculate the display index for a given store index
   * @param storeIndex the rows index within this store
   * @returns the rows visible display index relative to the grid
   */
  getDisplayIndexFromStoreIndex(storeIndex) {
    var _a, _b;
    const nodeAtIndex = this.nodeMap.getBy("index", storeIndex);
    if (nodeAtIndex) {
      return nodeAtIndex.node.rowIndex;
    }
    let nextNode;
    let previousNode;
    this.nodeMap.forEach((lazyNode) => {
      if (storeIndex > lazyNode.index) {
        if (previousNode == null || previousNode.index < lazyNode.index) {
          previousNode = lazyNode;
        }
        return;
      }
      if (nextNode == null || nextNode.index > lazyNode.index) {
        nextNode = lazyNode;
        return;
      }
    });
    if (!nextNode) {
      return this.store.getDisplayIndexEnd() - (this.numberOfRows - storeIndex);
    }
    if (!previousNode) {
      return this.store.getDisplayIndexStart() + storeIndex;
    }
    const storeIndexDiff = storeIndex - previousNode.index;
    const previousDisplayIndex = (_b = (_a = previousNode.node.childStore) == null ? void 0 : _a.getDisplayIndexEnd()) != null ? _b : previousNode.node.rowIndex;
    return previousDisplayIndex + storeIndexDiff;
  }
  /**
   * Creates a new row and inserts it at the given index
   * @param atStoreIndex the node index relative to this store
   * @param data the data object to populate the node with 
   * @returns the new row node
   */
  createRowAtIndex(atStoreIndex, data, createNodeCallback) {
    var _a, _b;
    const lazyNode = this.nodeMap.getBy("index", atStoreIndex);
    if (lazyNode) {
      const { node } = lazyNode;
      node.__needsRefreshWhenVisible = false;
      if (this.doesNodeMatch(data, node)) {
        this.blockUtils.updateDataIntoRowNode(node, data);
        this.nodesToRefresh.delete(node);
        return node;
      }
      if (this.getRowIdFunc == null && node.hasChildren() && node.expanded) {
        this.nodesToRefresh.delete(node);
        return node;
      }
      this.destroyRowAtIndex(atStoreIndex);
    }
    if (data && this.getRowIdFunc != null) {
      const id = this.getRowId(data);
      const deletedNode = id && ((_a = this.removedNodeCache) == null ? void 0 : _a.get(id));
      if (deletedNode) {
        (_b = this.removedNodeCache) == null ? void 0 : _b.delete(id);
        this.blockUtils.updateDataIntoRowNode(deletedNode, data);
        this.nodeMap.set({
          id: deletedNode.id,
          node: deletedNode,
          index: atStoreIndex
        });
        return deletedNode;
      }
      const lazyNode2 = this.nodeMap.getBy("id", id);
      if (lazyNode2) {
        this.nodeMap.delete(lazyNode2);
        const { node, index } = lazyNode2;
        this.blockUtils.updateDataIntoRowNode(node, data);
        this.nodeMap.set({
          id: node.id,
          node,
          index: atStoreIndex
        });
        this.nodesToRefresh.delete(node);
        if (this.getBlockStartIndex(index) === this.getBlockStartIndex(atStoreIndex)) {
          return node;
        }
        this.markBlockForVerify(index);
        return node;
      }
    }
    const newNode = this.blockUtils.createRowNode(this.store.getRowDetails());
    if (data != null) {
      const defaultId = this.getPrefixedId(this.store.getIdSequence().next());
      this.blockUtils.setDataIntoRowNode(newNode, data, defaultId, void 0);
      this.serverSideRowModel.setPaused(true);
      this.blockUtils.checkOpenByDefault(newNode);
      this.serverSideRowModel.setPaused(false);
      this.nodeManager.addRowNode(newNode);
    }
    this.nodeMap.set({
      id: newNode.id,
      node: newNode,
      index: atStoreIndex
    });
    if (createNodeCallback) {
      createNodeCallback(newNode);
    }
    return newNode;
  }
  getBlockStates() {
    const blockCounts = {};
    const blockStates = {};
    this.nodeMap.forEach(({ node, index }) => {
      var _a;
      const blockStart = this.getBlockStartIndex(index);
      if (!node.stub && !node.failedLoad) {
        blockCounts[blockStart] = ((_a = blockCounts[blockStart]) != null ? _a : 0) + 1;
      }
      let rowState = "loaded";
      if (node.failedLoad) {
        rowState = "failed";
      } else if (this.lazyBlockLoadingService.isRowLoading(this, blockStart)) {
        rowState = "loading";
      } else if (this.nodesToRefresh.has(node) || node.stub) {
        rowState = "needsLoading";
      }
      if (!blockStates[blockStart]) {
        blockStates[blockStart] = /* @__PURE__ */ new Set();
      }
      blockStates[blockStart].add(rowState);
    });
    const statePriorityMap = {
      loading: 4,
      failed: 3,
      needsLoading: 2,
      loaded: 1
    };
    const blockPrefix = this.blockUtils.createNodeIdPrefix(this.store.getParentNode());
    const results = {};
    Object.entries(blockStates).forEach(([blockStart, uniqueStates]) => {
      var _a;
      const sortedStates = [...uniqueStates].sort((a, b) => {
        var _a2, _b;
        return ((_a2 = statePriorityMap[a]) != null ? _a2 : 0) - ((_b = statePriorityMap[b]) != null ? _b : 0);
      });
      const priorityState = sortedStates[0];
      const blockNumber = Number(blockStart) / this.getBlockSize();
      const blockId = blockPrefix ? `${blockPrefix}-${blockNumber}` : String(blockNumber);
      results[blockId] = {
        blockNumber,
        startRow: Number(blockStart),
        endRow: Number(blockStart) + this.getBlockSize(),
        pageStatus: priorityState,
        loadedRowCount: (_a = blockCounts[blockStart]) != null ? _a : 0
      };
    });
    return results;
  }
  destroyRowAtIndex(atStoreIndex) {
    const lazyNode = this.nodeMap.getBy("index", atStoreIndex);
    if (!lazyNode) {
      return;
    }
    this.nodeMap.delete(lazyNode);
    this.nodeDisplayIndexMap.delete(lazyNode.node.rowIndex);
    if (this.nodesToRefresh.size > 0) {
      this.removedNodeCache.set(lazyNode.node.id, lazyNode.node);
    } else {
      this.blockUtils.destroyRowNode(lazyNode.node);
    }
    this.nodesToRefresh.delete(lazyNode.node);
  }
  getSsrmParams() {
    return this.store.getSsrmParams();
  }
  /**
   * @param id the base id to be prefixed
   * @returns a node id with prefix if required
   */
  getPrefixedId(id) {
    if (this.defaultNodeIdPrefix) {
      return this.defaultNodeIdPrefix + "-" + id;
    } else {
      return id.toString();
    }
  }
  markBlockForVerify(rowIndex) {
    const [start, end] = this.getBlockBounds(rowIndex);
    const lazyNodesInRange = this.nodeMap.filter((lazyNode) => lazyNode.index >= start && lazyNode.index < end);
    lazyNodesInRange.forEach(({ node }) => {
      node.__needsRefreshWhenVisible = true;
    });
  }
  doesNodeMatch(data, node) {
    if (node.stub) {
      return false;
    }
    if (this.getRowIdFunc != null) {
      const id = this.getRowId(data);
      return node.id === id;
    }
    return node.data === data;
  }
  /**
   * Deletes any stub nodes not within the given range
   */
  purgeStubsOutsideOfViewport() {
    const firstRow = this.api.getFirstDisplayedRowIndex();
    const lastRow = this.api.getLastDisplayedRowIndex();
    const firstRowBlockStart = this.getBlockStartIndex(firstRow);
    const [_94, lastRowBlockEnd] = this.getBlockBounds(lastRow);
    this.nodeMap.forEach((lazyNode) => {
      if (this.lazyBlockLoadingService.isRowLoading(this, lazyNode.index) || lazyNode.node.failedLoad) {
        return;
      }
      if (lazyNode.node.stub && (lazyNode.index < firstRowBlockStart || lazyNode.index > lastRowBlockEnd)) {
        this.destroyRowAtIndex(lazyNode.index);
      }
    });
  }
  getBlocksDistanceFromRow(nodes, otherDisplayIndex) {
    const blockDistanceToMiddle = {};
    nodes.forEach(({ node, index }) => {
      const [blockStart, blockEnd] = this.getBlockBounds(index);
      if (blockStart in blockDistanceToMiddle) {
        return;
      }
      const distStart = Math.abs(node.rowIndex - otherDisplayIndex);
      let distEnd;
      const lastLazyNode = this.nodeMap.getBy("index", [blockEnd - 1]);
      if (lastLazyNode)
        distEnd = Math.abs(lastLazyNode.node.rowIndex - otherDisplayIndex);
      const farthest = distEnd == null || distStart < distEnd ? distStart : distEnd;
      blockDistanceToMiddle[blockStart] = farthest;
    });
    return Object.entries(blockDistanceToMiddle);
  }
  purgeExcessRows() {
    var _a;
    this.purgeStubsOutsideOfViewport();
    if (this.store.getDisplayIndexEnd() == null || this.storeParams.maxBlocksInCache == null) {
      return;
    }
    const firstRowInViewport = this.api.getFirstDisplayedRowIndex();
    const lastRowInViewport = this.api.getLastDisplayedRowIndex();
    const allLoadedBlocks = /* @__PURE__ */ new Set();
    const blocksInViewport = /* @__PURE__ */ new Set();
    this.nodeMap.forEach(({ index, node }) => {
      const blockStart = this.getBlockStartIndex(index);
      allLoadedBlocks.add(blockStart);
      const isInViewport = node.rowIndex >= firstRowInViewport && node.rowIndex <= lastRowInViewport;
      if (isInViewport) {
        blocksInViewport.add(blockStart);
      }
    });
    const numberOfBlocksToRetain = Math.max(blocksInViewport.size, (_a = this.storeParams.maxBlocksInCache) != null ? _a : 0);
    const loadedBlockCount = allLoadedBlocks.size;
    const blocksToRemove = loadedBlockCount - numberOfBlocksToRetain;
    if (blocksToRemove <= 0) {
      return;
    }
    let firstRowBlockStart = Number.MAX_SAFE_INTEGER;
    let lastRowBlockStart = Number.MIN_SAFE_INTEGER;
    blocksInViewport.forEach((blockStart) => {
      if (firstRowBlockStart > blockStart) {
        firstRowBlockStart = blockStart;
      }
      if (lastRowBlockStart < blockStart) {
        lastRowBlockStart = blockStart;
      }
    });
    const disposableNodes = this.nodeMap.filter(({ node, index }) => {
      const rowBlockStart = this.getBlockStartIndex(index);
      const rowBlockInViewport = rowBlockStart >= firstRowBlockStart && rowBlockStart <= lastRowBlockStart;
      return !rowBlockInViewport && !this.isNodeCached(node);
    });
    if (disposableNodes.length === 0) {
      return;
    }
    const midViewportRow = firstRowInViewport + (lastRowInViewport - firstRowInViewport) / 2;
    const blockDistanceArray = this.getBlocksDistanceFromRow(disposableNodes, midViewportRow);
    const blockSize = this.getBlockSize();
    blockDistanceArray.sort((a, b) => Math.sign(b[1] - a[1]));
    for (let i = 0; i < Math.min(blocksToRemove, blockDistanceArray.length); i++) {
      const blockStart = Number(blockDistanceArray[i][0]);
      for (let x = blockStart; x < blockStart + blockSize; x++) {
        const lazyNode = this.nodeMap.getBy("index", x);
        if (!lazyNode || this.isNodeCached(lazyNode.node)) {
          continue;
        }
        this.destroyRowAtIndex(x);
      }
    }
  }
  isNodeFocused(node) {
    const focusedCell = this.focusService.getFocusCellToUseAfterRefresh();
    if (!focusedCell) {
      return false;
    }
    if (focusedCell.rowPinned != null) {
      return false;
    }
    const hasFocus = focusedCell.rowIndex === node.rowIndex;
    return hasFocus;
  }
  isNodeCached(node) {
    return node.isExpandable() && node.expanded || this.isNodeFocused(node);
  }
  extractDuplicateIds(rows) {
    if (this.getRowIdFunc == null) {
      return [];
    }
    const newIds = /* @__PURE__ */ new Set();
    const duplicates = /* @__PURE__ */ new Set();
    rows.forEach((data) => {
      const id = this.getRowId(data);
      if (newIds.has(id)) {
        duplicates.add(id);
        return;
      }
      newIds.add(id);
    });
    return [...duplicates];
  }
  onLoadSuccess(firstRowIndex, numberOfRowsExpected, response) {
    if (!this.live)
      return;
    const info = response.groupLevelInfo;
    this.store.setStoreInfo(info);
    if (this.getRowIdFunc != null) {
      const duplicates = this.extractDuplicateIds(response.rowData);
      if (duplicates.length > 0) {
        const duplicateIdText = duplicates.join(", ");
        console.warn(`AG Grid: Unable to display rows as duplicate row ids (${duplicateIdText}) were returned by the getRowId callback. Please modify the getRowId callback to provide unique ids.`);
        this.onLoadFailed(firstRowIndex, numberOfRowsExpected);
        return;
      }
    }
    if (response.pivotResultFields) {
      this.serverSideRowModel.generateSecondaryColumns(response.pivotResultFields);
    }
    const wasRefreshing = this.nodesToRefresh.size > 0;
    response.rowData.forEach((data, responseRowIndex) => {
      var _a;
      const rowIndex = firstRowIndex + responseRowIndex;
      const nodeFromCache = this.nodeMap.getBy("index", rowIndex);
      if ((_a = nodeFromCache == null ? void 0 : nodeFromCache.node) == null ? void 0 : _a.stub) {
        this.createRowAtIndex(rowIndex, data);
        return;
      }
      if (nodeFromCache && this.doesNodeMatch(data, nodeFromCache.node)) {
        this.blockUtils.updateDataIntoRowNode(nodeFromCache.node, data);
        this.nodesToRefresh.delete(nodeFromCache.node);
        nodeFromCache.node.__needsRefreshWhenVisible = false;
        return;
      }
      this.createRowAtIndex(rowIndex, data);
    });
    if (response.rowCount != void 0 && response.rowCount !== -1) {
      this.numberOfRows = response.rowCount;
      this.isLastRowKnown = true;
    } else if (numberOfRowsExpected > response.rowData.length) {
      this.numberOfRows = firstRowIndex + response.rowData.length;
      this.isLastRowKnown = true;
    } else if (!this.isLastRowKnown) {
      const lastInferredRow = firstRowIndex + response.rowData.length + 1;
      if (lastInferredRow > this.numberOfRows) {
        this.numberOfRows = lastInferredRow;
      }
    }
    if (this.isLastRowKnown) {
      const lazyNodesAfterStoreEnd = this.nodeMap.filter((lazyNode) => lazyNode.index >= this.numberOfRows);
      lazyNodesAfterStoreEnd.forEach((lazyNode) => this.destroyRowAtIndex(lazyNode.index));
    }
    this.fireStoreUpdatedEvent();
    const finishedRefreshing = this.nodesToRefresh.size === 0;
    if (wasRefreshing && finishedRefreshing) {
      this.fireRefreshFinishedEvent();
    }
  }
  fireRefreshFinishedEvent() {
    const finishedRefreshing = this.nodesToRefresh.size === 0;
    if (!finishedRefreshing) {
      return;
    }
    this.removedNodeCache.forEach((node) => {
      this.blockUtils.destroyRowNode(node);
    });
    this.removedNodeCache = /* @__PURE__ */ new Map();
    this.store.fireRefreshFinishedEvent();
  }
  /**
   * @returns true if all rows are loaded
   */
  isStoreFullyLoaded() {
    const knowsSize = this.isLastRowKnown;
    const hasCorrectRowCount = this.nodeMap.getSize() === this.numberOfRows;
    if (!knowsSize || !hasCorrectRowCount) {
      return;
    }
    if (this.nodesToRefresh.size > 0) {
      return;
    }
    let index = -1;
    const firstOutOfPlaceNode = this.nodeMap.find((lazyNode) => {
      index += 1;
      if (lazyNode.index !== index) {
        return true;
      }
      if (lazyNode.node.__needsRefreshWhenVisible) {
        return true;
      }
      if (lazyNode.node.stub) {
        return true;
      }
      return false;
    });
    return firstOutOfPlaceNode == null;
  }
  isLastRowIndexKnown() {
    return this.isLastRowKnown;
  }
  onLoadFailed(firstRowIndex, numberOfRowsExpected) {
    var _a;
    if (!this.live)
      return;
    const wasRefreshing = this.nodesToRefresh.size > 0;
    for (let i = firstRowIndex; i < firstRowIndex + numberOfRowsExpected && i < this.getRowCount(); i++) {
      let { node } = (_a = this.nodeMap.getBy("index", i)) != null ? _a : {};
      if (node) {
        this.nodesToRefresh.delete(node);
      }
      if (!node || !node.stub) {
        if (node && !node.stub) {
          this.destroyRowAtIndex(i);
        }
        node = this.createRowAtIndex(i);
      }
      node.__needsRefreshWhenVisible = false;
      node.failedLoad = true;
    }
    const finishedRefreshing = this.nodesToRefresh.size === 0;
    if (wasRefreshing && finishedRefreshing) {
      this.fireRefreshFinishedEvent();
    }
    this.fireStoreUpdatedEvent();
  }
  markNodesForRefresh() {
    this.nodeMap.forEach((lazyNode) => {
      if (lazyNode.node.stub && !lazyNode.node.failedLoad) {
        return;
      }
      this.nodesToRefresh.add(lazyNode.node);
    });
    this.lazyBlockLoadingService.queueLoadCheck();
    if (this.isLastRowKnown && this.numberOfRows === 0) {
      this.numberOfRows = 1;
      this.isLastRowKnown = false;
      this.fireStoreUpdatedEvent();
    }
  }
  isNodeInCache(id) {
    return !!this.nodeMap.getBy("id", id);
  }
  // gets called 1) row count changed 2) cache purged 3) items inserted
  fireStoreUpdatedEvent() {
    if (!this.live) {
      return;
    }
    this.store.fireStoreUpdatedEvent();
  }
  getRowId(data) {
    if (this.getRowIdFunc == null) {
      return null;
    }
    const { level } = this.store.getRowDetails();
    const parentKeys = this.store.getParentNode().getGroupKeys();
    const id = this.getRowIdFunc({
      data,
      parentKeys: parentKeys.length > 0 ? parentKeys : void 0,
      level
    });
    return String(id);
  }
  getOrderedNodeMap() {
    const obj = {};
    this.nodeMap.forEach((node) => obj[node.index] = node);
    return obj;
  }
  clearDisplayIndexes() {
    this.nodeDisplayIndexMap.clear();
  }
  /**
   * Client side sorting
   */
  clientSideSortRows() {
    const sortOptions = this.sortController.getSortOptions();
    const isAnySort = sortOptions.some((opt) => opt.sort != null);
    if (!isAnySort) {
      return;
    }
    const allNodes = new Array(this.nodeMap.getSize());
    this.nodeMap.forEach((lazyNode) => allNodes[lazyNode.index] = lazyNode.node);
    this.nodeMap.clear();
    const sortedNodes = this.rowNodeSorter.doFullSort(allNodes, sortOptions);
    sortedNodes.forEach((node, index) => {
      this.nodeMap.set({
        id: node.id,
        node,
        index
      });
    });
  }
  /**
   * Transaction Support here
   */
  updateRowNodes(updates) {
    if (this.getRowIdFunc == null) {
      throw new Error("AG Grid: Transactions can only be applied when row ids are supplied.");
    }
    const updatedNodes = [];
    updates.forEach((data) => {
      const id = this.getRowId(data);
      const lazyNode = this.nodeMap.getBy("id", id);
      if (lazyNode) {
        this.blockUtils.updateDataIntoRowNode(lazyNode.node, data);
        updatedNodes.push(lazyNode.node);
      }
    });
    return updatedNodes;
  }
  insertRowNodes(inserts, indexToAdd) {
    const realRowCount = this.store.getRowCount() - (this.store.getParentNode().sibling ? 1 : 0);
    const addIndex = indexToAdd == null && this.isLastRowKnown ? realRowCount : indexToAdd;
    if (addIndex == null || realRowCount < addIndex) {
      return [];
    }
    if (this.getRowIdFunc == null) {
      throw new Error("AG Grid: Transactions can only be applied when row ids are supplied.");
    }
    const uniqueInsertsMap = {};
    inserts.forEach((data) => {
      const dataId = this.getRowId(data);
      if (dataId && this.isNodeInCache(dataId)) {
        return;
      }
      uniqueInsertsMap[dataId] = data;
    });
    const uniqueInserts = Object.values(uniqueInsertsMap);
    let numberOfInserts = uniqueInserts.length;
    if (numberOfInserts === 0) {
      return [];
    }
    const nodesToMove = this.nodeMap.filter((node) => node.index >= addIndex);
    nodesToMove.forEach((lazyNode) => this.nodeMap.delete(lazyNode));
    nodesToMove.forEach((lazyNode) => {
      this.nodeMap.set({
        node: lazyNode.node,
        index: lazyNode.index + numberOfInserts,
        id: lazyNode.id
      });
    });
    this.numberOfRows += numberOfInserts;
    return uniqueInserts.map((data, uniqueInsertOffset) => this.createRowAtIndex(addIndex + uniqueInsertOffset, data));
  }
  removeRowNodes(idsToRemove) {
    if (this.getRowIdFunc == null) {
      throw new Error("AG Grid: Transactions can only be applied when row ids are supplied.");
    }
    const removedNodes = [];
    const nodesToVerify = [];
    let deletedNodeCount = 0;
    const remainingIdsToRemove = [...idsToRemove];
    const allNodes = this.getOrderedNodeMap();
    let contiguousIndex = -1;
    for (let stringIndex in allNodes) {
      contiguousIndex += 1;
      const node = allNodes[stringIndex];
      const matchIndex = remainingIdsToRemove.findIndex((idToRemove) => idToRemove === node.id);
      if (matchIndex !== -1) {
        remainingIdsToRemove.splice(matchIndex, 1);
        this.destroyRowAtIndex(Number(stringIndex));
        removedNodes.push(node.node);
        deletedNodeCount += 1;
        continue;
      }
      if (deletedNodeCount === 0) {
        continue;
      }
      const numericStoreIndex = Number(stringIndex);
      if (contiguousIndex !== numericStoreIndex) {
        nodesToVerify.push(node.node);
      }
      this.nodeMap.delete(allNodes[stringIndex]);
      this.nodeMap.set({
        id: node.id,
        node: node.node,
        index: numericStoreIndex - deletedNodeCount
      });
    }
    this.numberOfRows -= this.isLastRowIndexKnown() ? idsToRemove.length : deletedNodeCount;
    if (remainingIdsToRemove.length > 0 && nodesToVerify.length > 0) {
      nodesToVerify.forEach((node) => node.__needsRefreshWhenVisible = true);
      this.lazyBlockLoadingService.queueLoadCheck();
    }
    return removedNodes;
  }
  /**
   * Return the block size configured for this cache
   */
  getBlockSize() {
    return this.storeParams.cacheBlockSize || LazyBlockLoadingService.DEFAULT_BLOCK_SIZE;
  }
  /**
   * Get the start index of the loading block for a given index
   */
  getBlockStartIndex(storeIndex) {
    const blockSize = this.getBlockSize();
    return storeIndex - storeIndex % blockSize;
  }
  /**
   * Get the start and end index of a block, given a row store index
   */
  getBlockBounds(storeIndex) {
    const startOfBlock = this.getBlockStartIndex(storeIndex);
    const blockSize = this.getBlockSize();
    return [startOfBlock, startOfBlock + blockSize];
  }
};
__decorateClass14([
  Autowired315("gridApi")
], LazyCache.prototype, "api", 2);
__decorateClass14([
  Autowired315("ssrmBlockUtils")
], LazyCache.prototype, "blockUtils", 2);
__decorateClass14([
  Autowired315("focusService")
], LazyCache.prototype, "focusService", 2);
__decorateClass14([
  Autowired315("ssrmNodeManager")
], LazyCache.prototype, "nodeManager", 2);
__decorateClass14([
  Autowired315("rowModel")
], LazyCache.prototype, "serverSideRowModel", 2);
__decorateClass14([
  Autowired315("rowNodeSorter")
], LazyCache.prototype, "rowNodeSorter", 2);
__decorateClass14([
  Autowired315("sortController")
], LazyCache.prototype, "sortController", 2);
__decorateClass14([
  Autowired315("lazyBlockLoadingService")
], LazyCache.prototype, "lazyBlockLoadingService", 2);
__decorateClass14([
  PostConstruct314
], LazyCache.prototype, "init", 1);
__decorateClass14([
  PreDestroy22
], LazyCache.prototype, "destroyRowNodes", 1);
var LazyStore = class extends BeanStub35 {
  constructor(ssrmParams, storeParams, parentRowNode) {
    super();
    this.idSequence = new NumberSequence3();
    this.ssrmParams = ssrmParams;
    this.parentRowNode = parentRowNode;
    this.storeParams = storeParams;
    this.level = parentRowNode.level + 1;
    this.group = ssrmParams.rowGroupCols ? this.level < ssrmParams.rowGroupCols.length : false;
    this.leafGroup = ssrmParams.rowGroupCols ? this.level === ssrmParams.rowGroupCols.length - 1 : false;
    this.info = {};
  }
  init() {
    var _a;
    let numberOfRows = 1;
    if (this.level === 0) {
      numberOfRows = (_a = this.storeUtils.getServerSideInitialRowCount()) != null ? _a : 1;
      this.eventService.dispatchEventOnce({
        type: Events29.EVENT_ROW_COUNT_READY
      });
    }
    this.cache = this.createManagedBean(new LazyCache(this, numberOfRows, this.storeParams));
    const usingTreeData = this.gos.get("treeData");
    if (!usingTreeData && this.group) {
      const groupColVo = this.ssrmParams.rowGroupCols[this.level];
      this.groupField = groupColVo.field;
      this.rowGroupColumn = this.columnModel.getRowGroupColumns()[this.level];
    }
  }
  destroyRowNodes() {
    this.displayIndexStart = void 0;
    this.displayIndexEnd = void 0;
    this.destroyBean(this.cache);
  }
  /**
   * Given a server response, ingest the rows outside of the data source lifecycle.
   * 
   * @param rowDataParams the server response containing the rows to ingest
   * @param startRow the index to start ingesting rows
   * @param expectedRows the expected number of rows in the response (used to determine if the last row index is known)
   */
  applyRowData(rowDataParams, startRow, expectedRows) {
    this.cache.onLoadSuccess(startRow, expectedRows, rowDataParams);
  }
  /**
   * Applies a given transaction to the data set within this store
   * 
   * @param transaction an object containing delta instructions determining the changes to apply to this store
   * @returns an object determining the status of this transaction and effected nodes
   */
  applyTransaction(transaction) {
    var _a, _b, _c;
    const idFunc = this.gos.getCallback("getRowId");
    if (!idFunc) {
      console.warn("AG Grid: getRowId callback must be implemented for transactions to work. Transaction was ignored.");
      return {
        status: ServerSideTransactionResultStatus2.Cancelled
      };
    }
    const applyCallback = this.gos.getCallback("isApplyServerSideTransaction");
    if (applyCallback) {
      const params = {
        transaction,
        parentNode: this.parentRowNode,
        groupLevelInfo: this.info
      };
      const apply = applyCallback(params);
      if (!apply) {
        return { status: ServerSideTransactionResultStatus2.Cancelled };
      }
    }
    const allRowsLoaded = this.cache.isStoreFullyLoaded();
    let updatedNodes = void 0;
    if ((_a = transaction.update) == null ? void 0 : _a.length) {
      updatedNodes = this.cache.updateRowNodes(transaction.update);
    }
    let insertedNodes = void 0;
    if ((_b = transaction.add) == null ? void 0 : _b.length) {
      let addIndex = transaction.addIndex;
      if (addIndex != null && addIndex < 0) {
        addIndex = void 0;
      }
      insertedNodes = this.cache.insertRowNodes(transaction.add, addIndex);
    }
    let removedNodes = void 0;
    if ((_c = transaction.remove) == null ? void 0 : _c.length) {
      const allIdsToRemove = transaction.remove.map((data) => idFunc({ level: this.level, parentKeys: this.parentRowNode.getGroupKeys(), data }));
      const allUniqueIdsToRemove = [...new Set(allIdsToRemove)];
      removedNodes = this.cache.removeRowNodes(allUniqueIdsToRemove);
    }
    const isClientSideSortingEnabled = this.gos.get("serverSideEnableClientSideSort");
    const isUpdateOrAdd = (updatedNodes == null ? void 0 : updatedNodes.length) || (insertedNodes == null ? void 0 : insertedNodes.length);
    const isClientSideSort = allRowsLoaded && isClientSideSortingEnabled;
    if (isClientSideSort && isUpdateOrAdd) {
      this.cache.clientSideSortRows();
    }
    this.updateSelectionAfterTransaction(updatedNodes, removedNodes);
    return {
      status: ServerSideTransactionResultStatus2.Applied,
      update: updatedNodes,
      add: insertedNodes,
      remove: removedNodes
    };
  }
  updateSelectionAfterTransaction(updatedNodes, removedNodes) {
    const nodesToDeselect = [];
    updatedNodes == null ? void 0 : updatedNodes.forEach((node) => {
      if (node.isSelected() && !node.selectable) {
        nodesToDeselect.push(node);
      }
    });
    removedNodes == null ? void 0 : removedNodes.forEach((node) => {
      if (node.isSelected()) {
        nodesToDeselect.push(node);
      }
    });
    if (nodesToDeselect.length) {
      this.selectionService.setNodesSelected({
        newValue: false,
        clearSelection: false,
        nodes: nodesToDeselect,
        source: "rowDataChanged"
      });
    }
  }
  /**
   * Clear the display indexes, used for fading rows out when stores are not being destroyed
   */
  clearDisplayIndexes() {
    this.displayIndexStart = void 0;
    this.displayIndexEnd = void 0;
    this.cache.getNodes().forEach((lazyNode) => this.blockUtils.clearDisplayIndex(lazyNode.node));
    if (this.parentRowNode.sibling) {
      this.blockUtils.clearDisplayIndex(this.parentRowNode.sibling);
    }
    this.cache.clearDisplayIndexes();
  }
  /**
   * @returns an index representing the last sequentially displayed row in the grid for this store
   */
  getDisplayIndexStart() {
    return this.displayIndexStart;
  }
  /**
   * @returns the index representing one after the last sequentially displayed row in the grid for this store
   */
  getDisplayIndexEnd() {
    return this.displayIndexEnd;
  }
  /** 
   * @returns the virtual size of this store
   */
  getRowCount() {
    if (this.parentRowNode.sibling) {
      return this.cache.getRowCount() + 1;
    }
    return this.cache.getRowCount();
  }
  /** 
   * Sets the current row count of the store, and whether the last row index is known
   */
  setRowCount(rowCount, isLastRowIndexKnown) {
    this.cache.setRowCount(rowCount, isLastRowIndexKnown);
  }
  /**
   * Given a display index, returns whether that row is within this store or a child store of this store
   * 
   * @param displayIndex the visible index of a row
   * @returns whether or not the row exists within this store
   */
  isDisplayIndexInStore(displayIndex) {
    if (this.cache.getRowCount() === 0)
      return false;
    return this.displayIndexStart <= displayIndex && displayIndex < this.getDisplayIndexEnd();
  }
  /**
   * Recursively sets up the display indexes and top position of every node belonging to this store.
   * 
   * Called after a row height changes, or a store updated event.
   * 
   * @param displayIndexSeq the number sequence for generating the display index of each row
   * @param nextRowTop an object containing the next row top value intended to be modified by ref per row
   */
  setDisplayIndexes(displayIndexSeq, nextRowTop) {
    this.displayIndexStart = displayIndexSeq.peek();
    this.topPx = nextRowTop.value;
    const footerNode = this.parentRowNode.level > -1 && this.gos.getGroupTotalRowCallback()({ node: this.parentRowNode });
    if (!footerNode) {
      this.parentRowNode.destroyFooter();
    }
    if (footerNode === "top") {
      this.parentRowNode.createFooter();
      this.blockUtils.setDisplayIndex(this.parentRowNode.sibling, displayIndexSeq, nextRowTop);
    }
    this.cache.setDisplayIndexes(displayIndexSeq, nextRowTop);
    if (footerNode === "bottom") {
      this.parentRowNode.createFooter();
      this.blockUtils.setDisplayIndex(this.parentRowNode.sibling, displayIndexSeq, nextRowTop);
    }
    this.displayIndexEnd = displayIndexSeq.peek();
    this.heightPx = nextRowTop.value - this.topPx;
  }
  /**
   * Recursively applies a provided function to every node
   * 
   * For the purpose of exclusively server side filtered stores, this is the same as getNodes().forEachDeepAfterFilterAndSort
   */
  forEachStoreDeep(callback, sequence = new NumberSequence3()) {
    callback(this, sequence.next());
    this.cache.getNodes().forEach((lazyNode) => {
      const childCache = lazyNode.node.childStore;
      if (childCache) {
        childCache.forEachStoreDeep(callback, sequence);
      }
    });
  }
  /**
   * Recursively applies a provided function to every node
   * 
   * For the purpose of exclusively server side filtered stores, this is the same as getNodes().forEachDeepAfterFilterAndSort
   */
  forEachNodeDeep(callback, sequence = new NumberSequence3()) {
    this.cache.getNodes().forEach((lazyNode) => {
      callback(lazyNode.node, sequence.next());
      const childCache = lazyNode.node.childStore;
      if (childCache) {
        childCache.forEachNodeDeep(callback, sequence);
      }
    });
  }
  /**
   * Recursively applies a provided function to every node
   * 
   * For the purpose of exclusively server side filtered stores, this is the same as getNodes().forEachDeep
   */
  forEachNodeDeepAfterFilterAndSort(callback, sequence = new NumberSequence3(), includeFooterNodes = false) {
    const footerNode = this.parentRowNode.level > -1 && this.gos.getGroupTotalRowCallback()({ node: this.parentRowNode });
    if (footerNode === "top") {
      callback(this.parentRowNode.sibling, sequence.next());
    }
    const orderedNodes = this.cache.getOrderedNodeMap();
    for (let key in orderedNodes) {
      const lazyNode = orderedNodes[key];
      callback(lazyNode.node, sequence.next());
      const childCache = lazyNode.node.childStore;
      if (childCache) {
        childCache.forEachNodeDeepAfterFilterAndSort(callback, sequence, includeFooterNodes);
      }
    }
    if (footerNode === "bottom") {
      callback(this.parentRowNode.sibling, sequence.next());
    }
  }
  /**
   * Removes the failed status from all nodes, and marks them as stub to encourage reloading
   */
  retryLoads() {
    this.cache.getNodes().forEach(({ node }) => {
      if (node.failedLoad) {
        node.failedLoad = false;
        node.__needsRefreshWhenVisible = true;
        node.stub = true;
      }
    });
    this.forEachChildStoreShallow((store) => store.retryLoads());
    this.fireStoreUpdatedEvent();
  }
  /**
   * Given a display index, returns the row at that location.
   * 
   * @param displayRowIndex the displayed index within the grid to search for
   * @returns the row node if the display index falls within the store, if it didn't exist this will create a new stub to return
   */
  getRowUsingDisplayIndex(displayRowIndex) {
    if (this.parentRowNode.sibling && displayRowIndex === this.parentRowNode.sibling.rowIndex) {
      return this.parentRowNode.sibling;
    }
    return this.cache.getRowByDisplayIndex(displayRowIndex);
  }
  /**
   * Given a display index, returns the row top and height for the row at that index.
   * 
   * @param displayIndex the display index of the node
   * @returns an object containing the rowTop and rowHeight of the node at the given displayIndex
   */
  getRowBounds(displayIndex) {
    var _a;
    if (!this.isDisplayIndexInStore(displayIndex)) {
      return null;
    }
    const thisNode = this.cache.getNodeCachedByDisplayIndex(displayIndex);
    if (thisNode) {
      const boundsFromRow = this.blockUtils.extractRowBounds(thisNode, displayIndex);
      if (boundsFromRow) {
        return boundsFromRow;
      }
    }
    const { previousNode, nextNode } = (_a = this.cache.getSurroundingNodesByDisplayIndex(displayIndex)) != null ? _a : {};
    if (previousNode) {
      const boundsFromRow = this.blockUtils.extractRowBounds(previousNode.node, displayIndex);
      if (boundsFromRow != null) {
        return boundsFromRow;
      }
    }
    const defaultRowHeight = this.gos.getRowHeightAsNumber();
    if (nextNode) {
      const numberOfRowDiff2 = (nextNode.node.rowIndex - displayIndex) * defaultRowHeight;
      return {
        rowTop: nextNode.node.rowTop - numberOfRowDiff2,
        rowHeight: defaultRowHeight
      };
    }
    const lastTop = this.topPx + this.heightPx;
    const numberOfRowDiff = (this.getDisplayIndexEnd() - displayIndex) * defaultRowHeight;
    return {
      rowTop: lastTop - numberOfRowDiff,
      rowHeight: defaultRowHeight
    };
  }
  /**
   * Given a vertical pixel, determines whether this store contains a row at that pixel
   * 
   * @param pixel a vertical pixel position from the grid
   * @returns whether that pixel points to a virtual space belonging to this store
   */
  isPixelInRange(pixel) {
    return pixel >= this.topPx && pixel < this.topPx + this.heightPx;
  }
  /**
   * Given a vertical pixel, returns the row existing at that pixel location
   * 
   * @param pixel a vertical pixel position from the grid
   * @returns the display index at the given pixel location
   */
  getRowIndexAtPixel(pixel) {
    if (pixel < this.topPx) {
      return this.getDisplayIndexStart();
    }
    if (pixel >= this.topPx + this.heightPx) {
      return this.getDisplayIndexEnd() - 1;
    }
    if (this.parentRowNode.sibling && pixel > this.parentRowNode.sibling.rowTop && pixel < this.parentRowNode.sibling.rowTop + this.parentRowNode.sibling.rowHeight) {
      return this.parentRowNode.sibling.rowIndex;
    }
    let distToPreviousNodeTop = Number.MAX_SAFE_INTEGER;
    let previousNode = null;
    let distToNextNodeTop = Number.MAX_SAFE_INTEGER;
    let nextNode = null;
    this.cache.getNodes().forEach(({ node }) => {
      const distBetween = Math.abs(pixel - node.rowTop);
      if (node.rowTop < pixel) {
        if (distBetween < distToPreviousNodeTop) {
          distToPreviousNodeTop = distBetween;
          previousNode = node;
        }
        return;
      }
      if (distBetween < distToNextNodeTop) {
        distToNextNodeTop = distBetween;
        nextNode = node;
      }
    });
    previousNode = previousNode;
    nextNode = nextNode;
    if (previousNode) {
      const indexOfRow = this.blockUtils.getIndexAtPixel(previousNode, pixel);
      if (indexOfRow != null) {
        return indexOfRow;
      }
    }
    const defaultRowHeight = this.gos.getRowHeightAsNumber();
    if (nextNode) {
      const nextTop2 = nextNode.rowTop;
      const numberOfRowDiff2 = Math.ceil((nextTop2 - pixel) / defaultRowHeight);
      return nextNode.rowIndex - numberOfRowDiff2;
    }
    const nextTop = this.topPx + this.heightPx;
    const numberOfRowDiff = Math.floor((nextTop - pixel) / defaultRowHeight);
    return this.getDisplayIndexEnd() - numberOfRowDiff;
  }
  /**
   * Given a path of group keys, returns the child store for that group.
   * 
   * @param keys the grouping path to the desired store
   * @returns the child store for the given keys, or null if not found
   */
  getChildStore(keys) {
    return this.storeUtils.getChildStore(keys, this, (key) => {
      const lazyNode = this.cache.getNodes().find((lazyNode2) => lazyNode2.node.key == key);
      if (!lazyNode) {
        return null;
      }
      return lazyNode.node;
    });
  }
  /**
   * Executes a provided callback on each child store belonging to this store
   * 
   * @param cb the callback to execute
   */
  forEachChildStoreShallow(cb) {
    this.cache.getNodes().forEach(({ node }) => {
      if (node.childStore) {
        cb(node.childStore);
      }
    });
  }
  /**
   * Executes after a change to sorting, determines recursively whether this store or a child requires refreshed.
   * 
   * If a purge refresh occurs, the row count is preserved.
   * 
   * @param params a set of properties pertaining to the sort changes
   */
  refreshAfterSort(params) {
    const serverSortsAllLevels = this.storeUtils.isServerSideSortAllLevels();
    if (serverSortsAllLevels || this.storeUtils.isServerRefreshNeeded(this.parentRowNode, this.ssrmParams.rowGroupCols, params)) {
      const allRowsLoaded = this.cache.isStoreFullyLoaded();
      const isClientSideSortingEnabled = this.gos.get("serverSideEnableClientSideSort");
      const isClientSideSort = allRowsLoaded && isClientSideSortingEnabled;
      if (!isClientSideSort) {
        const oldCount = this.cache.getRowCount();
        this.destroyBean(this.cache);
        this.cache = this.createManagedBean(new LazyCache(this, oldCount, this.storeParams));
        return;
      }
      this.cache.clientSideSortRows();
    }
    this.forEachChildStoreShallow((store) => store.refreshAfterSort(params));
  }
  /**
   * Executes after a change to filtering, determines recursively whether this store or a child requires refreshed.
   * 
   * If a refresh occurs, the row count is reset.
   * 
   * @param params a set of properties pertaining to the filter changes
   */
  refreshAfterFilter(params) {
    const serverFiltersAllLevels = !this.storeUtils.isServerSideOnlyRefreshFilteredGroups();
    if (serverFiltersAllLevels || this.storeUtils.isServerRefreshNeeded(this.parentRowNode, this.ssrmParams.rowGroupCols, params)) {
      this.refreshStore(true);
      return;
    }
    this.forEachChildStoreShallow((store) => store.refreshAfterFilter(params));
  }
  /**
   * Marks all existing nodes as requiring reloaded, and triggers a load check
   *
   * @param purge whether to remove all nodes and data in favour of stub nodes
   */
  refreshStore(purge) {
    if (purge) {
      this.destroyBean(this.cache);
      this.cache = this.createManagedBean(new LazyCache(this, 1, this.storeParams));
      this.fireStoreUpdatedEvent();
      return;
    }
    this.cache.markNodesForRefresh();
  }
  /**
   * Used for pagination, given a local/store index, returns the display index of that row
   * 
   * @param topLevelIndex the store index of a row
   * @returns the display index for the given store index
   */
  getTopLevelRowDisplayedIndex(topLevelIndex) {
    const displayIndex = this.cache.getDisplayIndexFromStoreIndex(topLevelIndex);
    return displayIndex != null ? displayIndex : topLevelIndex;
  }
  /**
   * Used for pagination to determine if the last page is known, and for aria to determine if the last grid row is known
   * 
   * @returns whether the last index of this store is known, or if lazy loading still required
   */
  isLastRowIndexKnown() {
    return this.cache.isLastRowIndexKnown();
  }
  /**
   * Used by the selection service to select a range of nodes
   * 
   * @param firstInRange the first node in the range to find
   * @param lastInRange the last node in the range to find
   * @returns a range of nodes between firstInRange and lastInRange inclusive
   */
  getRowNodesInRange(firstInRange, lastInRange) {
    const result = [];
    let inActiveRange = false;
    if (_217.missing(firstInRange)) {
      inActiveRange = true;
    }
    return this.cache.getNodes().filter(({ node }) => {
      return node.rowIndex >= firstInRange.rowIndex && node.rowIndex <= lastInRange.rowIndex;
    }).map(({ node }) => node);
  }
  /**
   * Mutates a given array to add this stores state, and recursively add all the children store states.
   * 
   * @param result a mutable results array
   */
  addStoreStates(result) {
    result.push({
      suppressInfiniteScroll: false,
      route: this.parentRowNode.getGroupKeys(),
      rowCount: this.getRowCount(),
      lastRowIndexKnown: this.isLastRowIndexKnown(),
      info: this.info,
      maxBlocksInCache: this.storeParams.maxBlocksInCache,
      cacheBlockSize: this.storeParams.cacheBlockSize
    });
    this.forEachChildStoreShallow((childStore) => childStore.addStoreStates(result));
  }
  getIdSequence() {
    return this.idSequence;
  }
  getParentNode() {
    return this.parentRowNode;
  }
  getRowDetails() {
    return {
      field: this.groupField,
      group: this.group,
      leafGroup: this.leafGroup,
      level: this.level,
      parent: this.parentRowNode,
      rowGroupColumn: this.rowGroupColumn
    };
  }
  getSsrmParams() {
    return this.ssrmParams;
  }
  setStoreInfo(info) {
    if (info) {
      Object.assign(this.info, info);
    }
  }
  // gets called 1) row count changed 2) cache purged
  fireStoreUpdatedEvent() {
    const event = {
      type: Events29.EVENT_STORE_UPDATED
    };
    this.eventService.dispatchEvent(event);
  }
  // gets called when row data updated, and no more refreshing needed
  fireRefreshFinishedEvent() {
    const event = {
      type: Events29.EVENT_STORE_REFRESHED,
      route: this.parentRowNode.getRoute()
    };
    this.eventService.dispatchEvent(event);
  }
  getBlockStates() {
    return this.cache.getBlockStates();
  }
  getStoreBounds() {
    return {
      topPx: this.topPx,
      heightPx: this.heightPx
    };
  }
  getCache() {
    return this.cache;
  }
};
__decorateClass14([
  Autowired415("ssrmBlockUtils")
], LazyStore.prototype, "blockUtils", 2);
__decorateClass14([
  Autowired415("ssrmStoreUtils")
], LazyStore.prototype, "storeUtils", 2);
__decorateClass14([
  Autowired415("columnModel")
], LazyStore.prototype, "columnModel", 2);
__decorateClass14([
  Autowired415("selectionService")
], LazyStore.prototype, "selectionService", 2);
__decorateClass14([
  PostConstruct413
], LazyStore.prototype, "init", 1);
__decorateClass14([
  PreDestroy32
], LazyStore.prototype, "destroyRowNodes", 1);
var ServerSideRowModel = class extends BeanStub45 {
  constructor() {
    super(...arguments);
    this.onRowHeightChanged_debounced = _314.debounce(this.onRowHeightChanged.bind(this), 100);
    this.pauseStoreUpdateListening = false;
    this.started = false;
    this.managingPivotResultColumns = false;
  }
  // we don't implement as lazy row heights is not supported in this row model
  ensureRowHeightsValid() {
    return false;
  }
  start() {
    this.started = true;
    this.updateDatasource();
  }
  destroyDatasource() {
    if (!this.datasource) {
      return;
    }
    if (this.datasource.destroy) {
      this.datasource.destroy();
    }
    this.rowRenderer.datasourceChanged();
    this.datasource = void 0;
  }
  addEventListeners() {
    this.addManagedListener(this.eventService, Events37.EVENT_NEW_COLUMNS_LOADED, this.onColumnEverything.bind(this));
    this.addManagedListener(this.eventService, Events37.EVENT_STORE_UPDATED, this.onStoreUpdated.bind(this));
    const resetListener = this.resetRootStore.bind(this);
    this.addManagedListener(this.eventService, Events37.EVENT_COLUMN_VALUE_CHANGED, resetListener);
    this.addManagedListener(this.eventService, Events37.EVENT_COLUMN_PIVOT_CHANGED, resetListener);
    this.addManagedListener(this.eventService, Events37.EVENT_COLUMN_ROW_GROUP_CHANGED, resetListener);
    this.addManagedListener(this.eventService, Events37.EVENT_COLUMN_PIVOT_MODE_CHANGED, resetListener);
    this.addManagedPropertyListeners([
      /**
       * Following properties omitted as they are likely to come with undesired  side effects.
       * 'getRowId', 'isRowMaster', 'getRowHeight', 'isServerSideGroup', 'getServerSideGroupKey',
       * */
      "masterDetail",
      "treeData",
      "removePivotHeaderRowWhenSingleValueColumn",
      "suppressServerSideInfiniteScroll",
      "cacheBlockSize"
    ], resetListener);
    this.addManagedPropertyListener("rowHeight", () => this.resetRowHeights());
    this.verifyProps();
    this.addManagedPropertyListener("serverSideDatasource", () => this.updateDatasource());
  }
  updateDatasource() {
    const datasource = this.gos.get("serverSideDatasource");
    if (datasource) {
      this.setDatasource(datasource);
    }
  }
  verifyProps() {
    if (this.gos.exists("initialGroupOrderComparator")) {
      _314.warnOnce(`initialGroupOrderComparator cannot be used with Server Side Row Model.`);
    }
    if (this.gos.isRowSelection() && !this.gos.exists("getRowId")) {
      _314.warnOnce(`getRowId callback must be provided for Server Side Row Model selection to work correctly.`);
    }
  }
  setDatasource(datasource) {
    if (!this.started) {
      return;
    }
    this.destroyDatasource();
    this.datasource = datasource;
    this.resetRootStore();
  }
  applyRowData(rowDataParams, startRow, route) {
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return;
    }
    const storeToExecuteOn = rootStore.getChildStore(route);
    if (!storeToExecuteOn) {
      return;
    }
    ;
    if (storeToExecuteOn instanceof LazyStore) {
      storeToExecuteOn.applyRowData(rowDataParams, startRow, rowDataParams.rowData.length);
    } else if (storeToExecuteOn instanceof FullStore) {
      storeToExecuteOn.processServerResult(rowDataParams);
    }
  }
  isLastRowIndexKnown() {
    const cache = this.getRootStore();
    if (!cache) {
      return false;
    }
    return cache.isLastRowIndexKnown();
  }
  onColumnEverything() {
    if (!this.storeParams) {
      this.resetRootStore();
      return;
    }
    const rowGroupColumnVos = this.columnsToValueObjects(this.columnModel.getRowGroupColumns());
    const valueColumnVos = this.columnsToValueObjects(this.columnModel.getValueColumns());
    const pivotColumnVos = this.columnsToValueObjects(this.columnModel.getPivotColumns());
    const areColsSame = (params) => {
      const oldColsMap = {};
      params.oldCols.forEach((col) => oldColsMap[col.id] = col);
      const allColsUnchanged = params.newCols.every((col) => {
        const equivalentCol = oldColsMap[col.id];
        if (equivalentCol) {
          delete oldColsMap[col.id];
        }
        return equivalentCol && equivalentCol.field === col.field && equivalentCol.aggFunc === col.aggFunc;
      });
      const missingCols = !params.allowRemovedColumns && !!Object.values(oldColsMap).length;
      return allColsUnchanged && !missingCols;
    };
    const sortModelDifferent = !_314.jsonEquals(this.storeParams.sortModel, this.sortController.getSortModel());
    const rowGroupDifferent = !areColsSame({
      oldCols: this.storeParams.rowGroupCols,
      newCols: rowGroupColumnVos
    });
    const pivotDifferent = !areColsSame({
      oldCols: this.storeParams.pivotCols,
      newCols: pivotColumnVos
    });
    const valuesDifferent = !!(rowGroupColumnVos == null ? void 0 : rowGroupColumnVos.length) && !areColsSame({
      oldCols: this.storeParams.valueCols,
      newCols: valueColumnVos,
      allowRemovedColumns: true
    });
    const resetRequired = sortModelDifferent || rowGroupDifferent || pivotDifferent || valuesDifferent;
    if (resetRequired) {
      this.resetRootStore();
    } else {
      const newParams = this.createStoreParams();
      this.storeParams.rowGroupCols = newParams.rowGroupCols;
      this.storeParams.pivotCols = newParams.pivotCols;
      this.storeParams.valueCols = newParams.valueCols;
    }
  }
  destroyRootStore() {
    if (!this.rootNode || !this.rootNode.childStore) {
      return;
    }
    this.rootNode.childStore = this.destroyBean(this.rootNode.childStore);
    this.nodeManager.clear();
  }
  refreshAfterSort(newSortModel, params) {
    if (this.storeParams) {
      this.storeParams.sortModel = newSortModel;
    }
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return;
    }
    rootStore.refreshAfterSort(params);
    this.onStoreUpdated();
  }
  generateSecondaryColumns(pivotFields) {
    if (!this.pivotColDefService) {
      ModuleRegistry6.__assertRegistered(ModuleNames15.RowGroupingModule, "pivotResultFields", this.context.getGridId());
      return;
    }
    const pivotColumnGroupDefs = this.pivotColDefService.createColDefsFromFields(pivotFields);
    this.managingPivotResultColumns = true;
    this.columnModel.setSecondaryColumns(pivotColumnGroupDefs, "rowModelUpdated");
  }
  resetRowHeights() {
    const atLeastOne = this.resetRowHeightsForAllRowNodes();
    const rootNodeHeight = this.gos.getRowHeightForNode(this.rootNode);
    this.rootNode.setRowHeight(rootNodeHeight.height, rootNodeHeight.estimated);
    if (this.rootNode.sibling) {
      const rootNodeSibling = this.gos.getRowHeightForNode(this.rootNode.sibling);
      this.rootNode.sibling.setRowHeight(rootNodeSibling.height, rootNodeSibling.estimated);
    }
    if (atLeastOne) {
      this.onRowHeightChanged();
    }
  }
  resetRowHeightsForAllRowNodes() {
    let atLeastOne = false;
    this.forEachNode((rowNode) => {
      const rowHeightForNode = this.gos.getRowHeightForNode(rowNode);
      rowNode.setRowHeight(rowHeightForNode.height, rowHeightForNode.estimated);
      const detailNode = rowNode.detailNode;
      if (detailNode) {
        const detailRowHeight = this.gos.getRowHeightForNode(detailNode);
        detailNode.setRowHeight(detailRowHeight.height, detailRowHeight.estimated);
      }
      if (rowNode.sibling) {
        const siblingRowHeight = this.gos.getRowHeightForNode(rowNode.sibling);
        detailNode.setRowHeight(siblingRowHeight.height, siblingRowHeight.estimated);
      }
      atLeastOne = true;
    });
    return atLeastOne;
  }
  resetRootStore() {
    this.destroyRootStore();
    this.rootNode = new RowNode5(this.beans);
    this.rootNode.group = true;
    this.rootNode.level = -1;
    if (this.datasource) {
      this.storeParams = this.createStoreParams();
      this.rootNode.childStore = this.createBean(this.storeFactory.createStore(this.storeParams, this.rootNode));
      this.updateRowIndexesAndBounds();
    }
    if (this.managingPivotResultColumns) {
      this.columnModel.setSecondaryColumns(null, "api");
      this.managingPivotResultColumns = false;
    }
    this.dispatchModelUpdated(true);
  }
  columnsToValueObjects(columns) {
    return columns.map((col) => ({
      id: col.getId(),
      aggFunc: col.getAggFunc(),
      displayName: this.columnModel.getDisplayNameForColumn(col, "model"),
      field: col.getColDef().field
    }));
  }
  createStoreParams() {
    const rowGroupColumnVos = this.columnsToValueObjects(this.columnModel.getRowGroupColumns());
    const valueColumnVos = this.columnsToValueObjects(this.columnModel.getValueColumns());
    const pivotColumnVos = this.columnsToValueObjects(this.columnModel.getPivotColumns());
    const dynamicRowHeight = this.gos.isGetRowHeightFunction();
    const params = {
      // the columns the user has grouped and aggregated by
      valueCols: valueColumnVos,
      rowGroupCols: rowGroupColumnVos,
      pivotCols: pivotColumnVos,
      pivotMode: this.columnModel.isPivotMode(),
      // sort and filter model
      filterModel: this.filterManager.isAdvancedFilterEnabled() ? this.filterManager.getAdvancedFilterModel() : this.filterManager.getFilterModel(),
      sortModel: this.sortController.getSortModel(),
      datasource: this.datasource,
      lastAccessedSequence: new NumberSequence4(),
      // blockSize: blockSize == null ? 100 : blockSize,
      dynamicRowHeight
    };
    return params;
  }
  getParams() {
    return this.storeParams;
  }
  dispatchModelUpdated(reset = false) {
    const modelUpdatedEvent = {
      type: Events37.EVENT_MODEL_UPDATED,
      animate: !reset,
      keepRenderedRows: !reset,
      newPage: false,
      newData: false
    };
    this.eventService.dispatchEvent(modelUpdatedEvent);
  }
  onStoreUpdated() {
    if (this.pauseStoreUpdateListening) {
      return;
    }
    this.updateRowIndexesAndBounds();
    this.dispatchModelUpdated();
  }
  /** This method is debounced. It is used for row auto-height. If we don't debounce,
   * then the Row Models will end up recalculating each row position
   * for each row height change and result in the Row Renderer laying out rows.
   * This is particularly bad if using print layout, and showing eg 1,000 rows,
   * each row will change it's height, causing Row Model to update 1,000 times.
   */
  onRowHeightChangedDebounced() {
    this.onRowHeightChanged_debounced();
  }
  onRowHeightChanged() {
    this.updateRowIndexesAndBounds();
    this.dispatchModelUpdated();
  }
  updateRowIndexesAndBounds() {
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return;
    }
    rootStore.setDisplayIndexes(new NumberSequence4(), { value: 0 });
  }
  retryLoads() {
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return;
    }
    rootStore.retryLoads();
    this.onStoreUpdated();
  }
  getRow(index) {
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return void 0;
    }
    return rootStore.getRowUsingDisplayIndex(index);
  }
  /**
   * Pauses the store, to prevent it updating the UI. This is used when doing batch updates to the store.
   */
  setPaused(paused) {
    this.pauseStoreUpdateListening = paused;
  }
  expandAll(value) {
    this.pauseStoreUpdateListening = true;
    this.forEachNode((node) => {
      if (node.stub) {
        return;
      }
      if (node.hasChildren()) {
        node.setExpanded(value);
      }
    });
    this.pauseStoreUpdateListening = false;
    this.onStoreUpdated();
  }
  refreshAfterFilter(newFilterModel, params) {
    if (this.storeParams) {
      this.storeParams.filterModel = newFilterModel;
    }
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return;
    }
    rootStore.refreshAfterFilter(params);
    this.onStoreUpdated();
  }
  getRootStore() {
    if (this.rootNode && this.rootNode.childStore) {
      return this.rootNode.childStore;
    }
  }
  getRowCount() {
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return 0;
    }
    return rootStore.getDisplayIndexEnd();
  }
  getTopLevelRowCount() {
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return 1;
    }
    return rootStore.getRowCount();
  }
  getTopLevelRowDisplayedIndex(topLevelIndex) {
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return topLevelIndex;
    }
    return rootStore.getTopLevelRowDisplayedIndex(topLevelIndex);
  }
  getRowBounds(index) {
    const rootStore = this.getRootStore();
    if (!rootStore) {
      const rowHeight = this.gos.getRowHeightAsNumber();
      return {
        rowTop: 0,
        rowHeight
      };
    }
    return rootStore.getRowBounds(index);
  }
  getBlockStates() {
    const root = this.getRootStore();
    if (!root) {
      return void 0;
    }
    const states = {};
    root.forEachStoreDeep((store) => {
      if (store instanceof FullStore) {
        const { id, state } = store.getBlockStateJson();
        states[id] = state;
      } else if (store instanceof LazyStore) {
        Object.entries(store.getBlockStates()).forEach(([block, state]) => {
          states[block] = state;
        });
      } else {
        throw new Error("AG Grid: Unsupported store type");
      }
    });
    return states;
  }
  getRowIndexAtPixel(pixel) {
    const rootStore = this.getRootStore();
    if (pixel <= 0 || !rootStore) {
      return 0;
    }
    return rootStore.getRowIndexAtPixel(pixel);
  }
  isEmpty() {
    return false;
  }
  isRowsToRender() {
    return this.getRootStore() != null && this.getRowCount() > 0;
  }
  getType() {
    return "serverSide";
  }
  forEachNode(callback) {
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return;
    }
    rootStore.forEachNodeDeep(callback);
  }
  forEachNodeAfterFilterAndSort(callback, includeFooterNodes = false) {
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return;
    }
    rootStore.forEachNodeDeepAfterFilterAndSort(callback, void 0, includeFooterNodes);
  }
  /** @return false if store hasn't started */
  executeOnStore(route, callback) {
    if (!this.started) {
      return false;
    }
    const rootStore = this.getRootStore();
    if (!rootStore) {
      return true;
    }
    const storeToExecuteOn = rootStore.getChildStore(route);
    if (storeToExecuteOn) {
      callback(storeToExecuteOn);
    }
    return true;
  }
  refreshStore(params = {}) {
    const route = params.route ? params.route : [];
    this.executeOnStore(route, (store) => store.refreshStore(params.purge == true));
  }
  getStoreState() {
    const res = [];
    const rootStore = this.getRootStore();
    if (rootStore) {
      rootStore.addStoreStates(res);
    }
    return res;
  }
  getNodesInRangeForSelection(firstInRange, lastInRange) {
    if (!_314.exists(firstInRange)) {
      return [];
    }
    if (!lastInRange) {
      return [firstInRange];
    }
    const startIndex = firstInRange.rowIndex;
    const endIndex = lastInRange.rowIndex;
    if (startIndex === null || endIndex === null) {
      return [firstInRange];
    }
    const nodeRange = [];
    const [firstIndex, lastIndex] = [startIndex, endIndex].sort((a, b) => a - b);
    this.forEachNode((node) => {
      const thisRowIndex = node.rowIndex;
      if (thisRowIndex == null || node.stub) {
        return;
      }
      if (thisRowIndex >= firstIndex && thisRowIndex <= lastIndex) {
        nodeRange.push(node);
      }
    });
    if (nodeRange.length !== lastIndex - firstIndex + 1) {
      return [firstInRange];
    }
    return nodeRange;
  }
  getRowNode(id) {
    let result;
    this.forEachNode((rowNode) => {
      if (rowNode.id === id) {
        result = rowNode;
      }
      if (rowNode.detailNode && rowNode.detailNode.id === id) {
        result = rowNode.detailNode;
      }
    });
    return result;
  }
  isRowPresent(rowNode) {
    const foundRowNode = this.getRowNode(rowNode.id);
    return !!foundRowNode;
  }
  setRowCount(rowCount, lastRowIndexKnown) {
    const rootStore = this.getRootStore();
    if (rootStore) {
      if (rootStore instanceof LazyStore) {
        rootStore.setRowCount(rowCount, lastRowIndexKnown);
        return;
      }
      console.error("AG Grid: Infinite scrolling must be enabled in order to set the row count.");
    }
  }
};
__decorateClass14([
  Autowired511("columnModel")
], ServerSideRowModel.prototype, "columnModel", 2);
__decorateClass14([
  Autowired511("filterManager")
], ServerSideRowModel.prototype, "filterManager", 2);
__decorateClass14([
  Autowired511("sortController")
], ServerSideRowModel.prototype, "sortController", 2);
__decorateClass14([
  Autowired511("rowRenderer")
], ServerSideRowModel.prototype, "rowRenderer", 2);
__decorateClass14([
  Autowired511("ssrmNodeManager")
], ServerSideRowModel.prototype, "nodeManager", 2);
__decorateClass14([
  Autowired511("ssrmStoreFactory")
], ServerSideRowModel.prototype, "storeFactory", 2);
__decorateClass14([
  Autowired511("beans")
], ServerSideRowModel.prototype, "beans", 2);
__decorateClass14([
  Optional6("pivotColDefService")
], ServerSideRowModel.prototype, "pivotColDefService", 2);
__decorateClass14([
  PreDestroy4
], ServerSideRowModel.prototype, "destroyDatasource", 1);
__decorateClass14([
  PostConstruct510
], ServerSideRowModel.prototype, "addEventListeners", 1);
__decorateClass14([
  PreDestroy4
], ServerSideRowModel.prototype, "destroyRootStore", 1);
ServerSideRowModel = __decorateClass14([
  Bean28("rowModel")
], ServerSideRowModel);
var StoreUtils = class extends BeanStub55 {
  loadFromDatasource(p) {
    const { storeParams, parentBlock, parentNode } = p;
    const groupKeys = parentNode.getGroupKeys();
    if (!storeParams.datasource) {
      return;
    }
    const request = {
      startRow: p.startRow,
      endRow: p.endRow,
      rowGroupCols: storeParams.rowGroupCols,
      valueCols: storeParams.valueCols,
      pivotCols: storeParams.pivotCols,
      pivotMode: storeParams.pivotMode,
      groupKeys,
      filterModel: storeParams.filterModel,
      sortModel: storeParams.sortModel
    };
    const getRowsParams = this.gos.addGridCommonParams({
      success: p.success,
      fail: p.fail,
      request,
      parentNode: p.parentNode
    });
    window.setTimeout(() => {
      if (!storeParams.datasource || !parentBlock.isAlive()) {
        p.fail();
        return;
      }
      storeParams.datasource.getRows(getRowsParams);
    }, 0);
  }
  getChildStore(keys, currentCache, findNodeFunc) {
    if (_411.missingOrEmpty(keys)) {
      return currentCache;
    }
    const nextKey = keys[0];
    const nextNode = findNodeFunc(nextKey);
    if (nextNode) {
      if (keys.length === 1 && !nextNode.childStore) {
        const storeParams = this.serverSideRowModel.getParams();
        nextNode.childStore = this.createBean(this.storeFactory.createStore(storeParams, nextNode));
      }
      const keyListForNextLevel = keys.slice(1, keys.length);
      const nextStore = nextNode.childStore;
      return nextStore ? nextStore.getChildStore(keyListForNextLevel) : null;
    }
    return null;
  }
  isServerRefreshNeeded(parentRowNode, rowGroupCols, params) {
    if (params.valueColChanged || params.secondaryColChanged) {
      return true;
    }
    const level = parentRowNode.level + 1;
    const grouping = level < rowGroupCols.length;
    const leafNodes = !grouping;
    if (leafNodes) {
      return true;
    }
    const colIdThisGroup = rowGroupCols[level].id;
    const actionOnThisGroup = params.changedColumns.indexOf(colIdThisGroup) > -1;
    if (actionOnThisGroup) {
      return true;
    }
    const allCols = this.columnModel.getAllGridColumns();
    const affectedGroupCols = allCols.filter((col) => col.getColDef().showRowGroup && params.changedColumns.includes(col.getId())).map((col) => col.getColDef().showRowGroup).some((group) => group === true || group === colIdThisGroup);
    return affectedGroupCols;
  }
  getServerSideInitialRowCount() {
    return this.gos.get("serverSideInitialRowCount");
  }
  assertRowModelIsServerSide(key) {
    if (!this.gos.isRowModelType("serverSide")) {
      _411.warnOnce(`The '${key}' property can only be used with the Server Side Row Model.`);
      return false;
    }
    return true;
  }
  assertNotTreeData(key) {
    if (this.gos.get("treeData")) {
      _411.warnOnce(`The '${key}' property cannot be used while using tree data.`);
      return false;
    }
    return true;
  }
  isServerSideSortAllLevels() {
    return this.gos.get("serverSideSortAllLevels") && this.assertRowModelIsServerSide("serverSideSortAllLevels");
  }
  isServerSideOnlyRefreshFilteredGroups() {
    return this.gos.get("serverSideOnlyRefreshFilteredGroups") && this.assertRowModelIsServerSide("serverSideOnlyRefreshFilteredGroups");
  }
  isServerSideSortOnServer() {
    return this.gos.get("serverSideSortOnServer") && this.assertRowModelIsServerSide("serverSideSortOnServer") && this.assertNotTreeData("serverSideSortOnServer");
  }
  isServerSideFilterOnServer() {
    return this.gos.get("serverSideFilterOnServer") && this.assertRowModelIsServerSide("serverSideFilterOnServer") && this.assertNotTreeData("serverSideFilterOnServer");
  }
};
__decorateClass14([
  Autowired67("columnModel")
], StoreUtils.prototype, "columnModel", 2);
__decorateClass14([
  Autowired67("rowModel")
], StoreUtils.prototype, "serverSideRowModel", 2);
__decorateClass14([
  Autowired67("ssrmStoreFactory")
], StoreUtils.prototype, "storeFactory", 2);
StoreUtils = __decorateClass14([
  Bean35("ssrmStoreUtils")
], StoreUtils);
var GROUP_MISSING_KEY_ID = "ag-Grid-MissingKey";
var BlockUtils = class extends BeanStub65 {
  createRowNode(params) {
    const rowNode = new RowNode7(this.beans);
    const rowHeight = params.rowHeight != null ? params.rowHeight : this.gos.getRowHeightAsNumber();
    rowNode.setRowHeight(rowHeight);
    rowNode.group = params.group;
    rowNode.leafGroup = params.leafGroup;
    rowNode.level = params.level;
    rowNode.uiLevel = params.level;
    rowNode.parent = params.parent;
    rowNode.stub = true;
    rowNode.__needsRefreshWhenVisible = false;
    if (rowNode.group) {
      rowNode.expanded = false;
      rowNode.field = params.field;
      rowNode.rowGroupColumn = params.rowGroupColumn;
    }
    return rowNode;
  }
  destroyRowNodes(rowNodes) {
    if (rowNodes) {
      rowNodes.forEach((row) => this.destroyRowNode(row));
    }
  }
  destroyRowNode(rowNode, preserveStore = false) {
    if (rowNode.childStore && !preserveStore) {
      this.destroyBean(rowNode.childStore);
      rowNode.childStore = null;
    }
    if (rowNode.sibling && !rowNode.footer) {
      this.destroyRowNode(rowNode.sibling, false);
    }
    rowNode.clearRowTopAndRowIndex();
    if (rowNode.id != null) {
      this.nodeManager.removeNode(rowNode);
    }
  }
  setTreeGroupInfo(rowNode) {
    rowNode.updateHasChildren();
    const getKeyFunc = this.gos.get("getServerSideGroupKey");
    if (rowNode.hasChildren() && getKeyFunc != null) {
      rowNode.key = getKeyFunc(rowNode.data);
    }
    if (!rowNode.hasChildren() && rowNode.childStore != null) {
      this.destroyBean(rowNode.childStore);
      rowNode.childStore = null;
      rowNode.expanded = false;
    }
  }
  setRowGroupInfo(rowNode) {
    rowNode.key = this.valueService.getValue(rowNode.rowGroupColumn, rowNode);
    if (rowNode.key === null || rowNode.key === void 0) {
      _58.doOnce(() => {
        console.warn(`AG Grid: null and undefined values are not allowed for server side row model keys`);
        if (rowNode.rowGroupColumn) {
          console.warn(`column = ${rowNode.rowGroupColumn.getId()}`);
        }
        console.warn(`data is `, rowNode.data);
      }, "ServerSideBlock-CannotHaveNullOrUndefinedForKey");
    }
    const getGroupIncludeFooter = this.beans.gos.getGroupTotalRowCallback();
    const doesRowShowFooter = getGroupIncludeFooter({ node: rowNode });
    if (doesRowShowFooter) {
      rowNode.createFooter();
      if (rowNode.sibling) {
        rowNode.sibling.uiLevel = rowNode.uiLevel + 1;
      }
    }
  }
  setMasterDetailInfo(rowNode) {
    const isMasterFunc = this.gos.get("isRowMaster");
    if (isMasterFunc != null) {
      rowNode.master = isMasterFunc(rowNode.data);
    } else {
      rowNode.master = true;
    }
  }
  updateDataIntoRowNode(rowNode, data) {
    rowNode.updateData(data);
    if (this.gos.get("treeData")) {
      this.setTreeGroupInfo(rowNode);
      this.setChildCountIntoRowNode(rowNode);
    } else if (rowNode.group) {
      this.setChildCountIntoRowNode(rowNode);
      if (!rowNode.footer) {
        const getGroupIncludeFooter = this.beans.gos.getGroupTotalRowCallback();
        const doesRowShowFooter = getGroupIncludeFooter({ node: rowNode });
        if (doesRowShowFooter) {
          if (rowNode.sibling) {
            rowNode.sibling.updateData(data);
          } else {
            rowNode.createFooter();
          }
        } else if (rowNode.sibling) {
          rowNode.destroyFooter();
        }
      }
    } else if (this.gos.get("masterDetail")) {
    }
  }
  setDataIntoRowNode(rowNode, data, defaultId, cachedRowHeight) {
    var _a;
    rowNode.stub = false;
    const treeData = this.gos.get("treeData");
    if (_58.exists(data)) {
      rowNode.setDataAndId(data, defaultId);
      if (treeData) {
        this.setTreeGroupInfo(rowNode);
      } else if (rowNode.group) {
        this.setRowGroupInfo(rowNode);
      } else if (this.gos.get("masterDetail")) {
        this.setMasterDetailInfo(rowNode);
      }
    } else {
      rowNode.setDataAndId(void 0, void 0);
      rowNode.key = null;
    }
    if (treeData || rowNode.group) {
      this.setGroupDataIntoRowNode(rowNode);
      this.setChildCountIntoRowNode(rowNode);
    }
    if (_58.exists(data)) {
      rowNode.setRowHeight(this.gos.getRowHeightForNode(rowNode, false, cachedRowHeight).height);
      (_a = rowNode.sibling) == null ? void 0 : _a.setRowHeight(this.gos.getRowHeightForNode(rowNode.sibling, false, cachedRowHeight).height);
    }
  }
  setChildCountIntoRowNode(rowNode) {
    const getChildCount = this.gos.get("getChildCount");
    if (getChildCount) {
      rowNode.setAllChildrenCount(getChildCount(rowNode.data));
    }
  }
  setGroupDataIntoRowNode(rowNode) {
    const groupDisplayCols = this.columnModel.getGroupDisplayColumns();
    const usingTreeData = this.gos.get("treeData");
    groupDisplayCols.forEach((col) => {
      if (rowNode.groupData == null) {
        rowNode.groupData = {};
      }
      if (usingTreeData) {
        rowNode.groupData[col.getColId()] = rowNode.key;
      } else if (col.isRowGroupDisplayed(rowNode.rowGroupColumn.getId())) {
        const groupValue = this.valueService.getValue(rowNode.rowGroupColumn, rowNode);
        rowNode.groupData[col.getColId()] = groupValue;
      }
    });
  }
  clearDisplayIndex(rowNode) {
    rowNode.clearRowTopAndRowIndex();
    const hasChildStore = rowNode.hasChildren() && _58.exists(rowNode.childStore);
    if (hasChildStore) {
      const childStore = rowNode.childStore;
      childStore.clearDisplayIndexes();
    }
    const hasDetailNode = rowNode.master && rowNode.detailNode;
    if (hasDetailNode) {
      rowNode.detailNode.clearRowTopAndRowIndex();
    }
  }
  setDisplayIndex(rowNode, displayIndexSeq, nextRowTop) {
    rowNode.setRowIndex(displayIndexSeq.next());
    rowNode.setRowTop(nextRowTop.value);
    nextRowTop.value += rowNode.rowHeight;
    if (rowNode.footer) {
      return;
    }
    const hasDetailRow = rowNode.master;
    if (hasDetailRow) {
      if (rowNode.expanded && rowNode.detailNode) {
        rowNode.detailNode.setRowIndex(displayIndexSeq.next());
        rowNode.detailNode.setRowTop(nextRowTop.value);
        nextRowTop.value += rowNode.detailNode.rowHeight;
      } else if (rowNode.detailNode) {
        rowNode.detailNode.clearRowTopAndRowIndex();
      }
    }
    const hasChildStore = rowNode.hasChildren() && _58.exists(rowNode.childStore);
    if (hasChildStore) {
      const childStore = rowNode.childStore;
      if (rowNode.expanded) {
        childStore.setDisplayIndexes(displayIndexSeq, nextRowTop);
      } else {
        childStore.clearDisplayIndexes();
      }
    }
  }
  binarySearchForDisplayIndex(displayRowIndex, rowNodes) {
    let bottomPointer = 0;
    let topPointer = rowNodes.length - 1;
    if (_58.missing(topPointer) || _58.missing(bottomPointer)) {
      console.warn(`AG Grid: error: topPointer = ${topPointer}, bottomPointer = ${bottomPointer}`);
      return void 0;
    }
    while (true) {
      const midPointer = Math.floor((bottomPointer + topPointer) / 2);
      const currentRowNode = rowNodes[midPointer];
      if (currentRowNode.rowIndex === displayRowIndex) {
        return currentRowNode;
      }
      const expandedMasterRow = currentRowNode.master && currentRowNode.expanded;
      const detailNode = currentRowNode.detailNode;
      if (expandedMasterRow && detailNode && detailNode.rowIndex === displayRowIndex) {
        return currentRowNode.detailNode;
      }
      const childStore = currentRowNode.childStore;
      if (currentRowNode.expanded && childStore && childStore.isDisplayIndexInStore(displayRowIndex)) {
        return childStore.getRowUsingDisplayIndex(displayRowIndex);
      }
      if (currentRowNode.rowIndex < displayRowIndex) {
        bottomPointer = midPointer + 1;
      } else if (currentRowNode.rowIndex > displayRowIndex) {
        topPointer = midPointer - 1;
      } else {
        console.warn(`AG Grid: error: unable to locate rowIndex = ${displayRowIndex} in cache`);
        return void 0;
      }
    }
  }
  extractRowBounds(rowNode, index) {
    const extractRowBounds = (currentRowNode) => ({
      rowHeight: currentRowNode.rowHeight,
      rowTop: currentRowNode.rowTop
    });
    if (rowNode.rowIndex === index) {
      return extractRowBounds(rowNode);
    }
    if (rowNode.hasChildren() && rowNode.expanded && _58.exists(rowNode.childStore)) {
      const childStore = rowNode.childStore;
      if (childStore.isDisplayIndexInStore(index)) {
        return childStore.getRowBounds(index);
      }
    } else if (rowNode.master && rowNode.expanded && _58.exists(rowNode.detailNode)) {
      if (rowNode.detailNode.rowIndex === index) {
        return extractRowBounds(rowNode.detailNode);
      }
    }
  }
  getIndexAtPixel(rowNode, pixel) {
    if (rowNode.isPixelInRange(pixel)) {
      return rowNode.rowIndex;
    }
    const expandedMasterRow = rowNode.master && rowNode.expanded;
    const detailNode = rowNode.detailNode;
    if (expandedMasterRow && detailNode && detailNode.isPixelInRange(pixel)) {
      return rowNode.detailNode.rowIndex;
    }
    if (rowNode.hasChildren() && rowNode.expanded && _58.exists(rowNode.childStore)) {
      const childStore = rowNode.childStore;
      if (childStore.isPixelInRange(pixel)) {
        return childStore.getRowIndexAtPixel(pixel);
      }
    }
    return null;
  }
  createNodeIdPrefix(parentRowNode) {
    const parts = [];
    let rowNode = parentRowNode;
    while (rowNode && rowNode.level >= 0) {
      if (rowNode.key === "") {
        parts.push(GROUP_MISSING_KEY_ID);
      } else {
        parts.push(rowNode.key);
      }
      rowNode = rowNode.parent;
    }
    if (parts.length > 0) {
      return parts.reverse().join("-");
    }
    return void 0;
  }
  checkOpenByDefault(rowNode) {
    return this.expansionService.checkOpenByDefault(rowNode);
  }
};
__decorateClass14([
  Autowired75("valueService")
], BlockUtils.prototype, "valueService", 2);
__decorateClass14([
  Autowired75("columnModel")
], BlockUtils.prototype, "columnModel", 2);
__decorateClass14([
  Autowired75("ssrmNodeManager")
], BlockUtils.prototype, "nodeManager", 2);
__decorateClass14([
  Autowired75("beans")
], BlockUtils.prototype, "beans", 2);
__decorateClass14([
  Autowired75("expansionService")
], BlockUtils.prototype, "expansionService", 2);
BlockUtils = __decorateClass14([
  Bean45("ssrmBlockUtils")
], BlockUtils);
var NodeManager = class {
  constructor() {
    this.rowNodes = {};
  }
  addRowNode(rowNode) {
    const id = rowNode.id;
    if (this.rowNodes[id]) {
      console.warn(`AG Grid: Duplicate node id ${rowNode.id}. Row ID's are provided via the getRowId() callback. Please modify the getRowId() callback code to provide unique row id values.`);
      console.warn("first instance", this.rowNodes[id].data);
      console.warn("second instance", rowNode.data);
    }
    this.rowNodes[id] = rowNode;
  }
  removeNode(rowNode) {
    const id = rowNode.id;
    if (this.rowNodes[id]) {
      this.rowNodes[id] = void 0;
    }
  }
  clear() {
    this.rowNodes = {};
  }
};
__decorateClass14([
  PreDestroy52
], NodeManager.prototype, "clear", 1);
NodeManager = __decorateClass14([
  Bean54("ssrmNodeManager")
], NodeManager);
var TransactionManager = class extends BeanStub74 {
  constructor() {
    super(...arguments);
    this.asyncTransactions = [];
  }
  postConstruct() {
    if (!this.gos.isRowModelType("serverSide")) {
      return;
    }
  }
  applyTransactionAsync(transaction, callback) {
    if (this.asyncTransactionsTimeout == null) {
      this.scheduleExecuteAsync();
    }
    this.asyncTransactions.push({ transaction, callback });
  }
  scheduleExecuteAsync() {
    const waitMillis = this.gos.getAsyncTransactionWaitMillis();
    this.asyncTransactionsTimeout = window.setTimeout(() => {
      this.executeAsyncTransactions();
    }, waitMillis);
  }
  executeAsyncTransactions() {
    if (!this.asyncTransactions) {
      return;
    }
    const resultFuncs = [];
    const resultsForEvent = [];
    const transactionsToRetry = [];
    let atLeastOneTransactionApplied = false;
    this.asyncTransactions.forEach((txWrapper) => {
      let result;
      const hasStarted = this.serverSideRowModel.executeOnStore(txWrapper.transaction.route, (cache) => {
        result = cache.applyTransaction(txWrapper.transaction);
      });
      if (!hasStarted) {
        result = { status: ServerSideTransactionResultStatus3.StoreNotStarted };
      } else if (result == void 0) {
        result = { status: ServerSideTransactionResultStatus3.StoreNotFound };
      }
      resultsForEvent.push(result);
      const retryTransaction = result.status == ServerSideTransactionResultStatus3.StoreLoading;
      if (retryTransaction) {
        transactionsToRetry.push(txWrapper);
        return;
      }
      if (txWrapper.callback) {
        resultFuncs.push(() => txWrapper.callback(result));
      }
      if (result.status === ServerSideTransactionResultStatus3.Applied) {
        atLeastOneTransactionApplied = true;
      }
    });
    if (resultFuncs.length > 0) {
      window.setTimeout(() => {
        resultFuncs.forEach((func) => func());
      }, 0);
    }
    this.asyncTransactionsTimeout = void 0;
    this.asyncTransactions = transactionsToRetry;
    if (atLeastOneTransactionApplied) {
      this.valueCache.onDataChanged();
      this.eventService.dispatchEvent({ type: Events46.EVENT_STORE_UPDATED });
    }
    if (resultsForEvent.length > 0) {
      const event = {
        type: Events46.EVENT_ASYNC_TRANSACTIONS_FLUSHED,
        results: resultsForEvent
      };
      this.eventService.dispatchEvent(event);
    }
  }
  flushAsyncTransactions() {
    if (this.asyncTransactionsTimeout != null) {
      clearTimeout(this.asyncTransactionsTimeout);
    }
    this.executeAsyncTransactions();
  }
  applyTransaction(transaction) {
    let res;
    const hasStarted = this.serverSideRowModel.executeOnStore(transaction.route, (store) => {
      res = store.applyTransaction(transaction);
    });
    if (!hasStarted) {
      return { status: ServerSideTransactionResultStatus3.StoreNotStarted };
    } else if (res) {
      this.valueCache.onDataChanged();
      if (res.remove) {
        const removedRowIds = res.remove.map((row) => row.id);
        this.selectionService.deleteSelectionStateFromParent(transaction.route || [], removedRowIds);
      }
      this.eventService.dispatchEvent({ type: Events46.EVENT_STORE_UPDATED });
      return res;
    } else {
      return { status: ServerSideTransactionResultStatus3.StoreNotFound };
    }
  }
};
__decorateClass14([
  Autowired85("rowNodeBlockLoader")
], TransactionManager.prototype, "rowNodeBlockLoader", 2);
__decorateClass14([
  Autowired85("valueCache")
], TransactionManager.prototype, "valueCache", 2);
__decorateClass14([
  Autowired85("rowModel")
], TransactionManager.prototype, "serverSideRowModel", 2);
__decorateClass14([
  Autowired85("rowRenderer")
], TransactionManager.prototype, "rowRenderer", 2);
__decorateClass14([
  Autowired85("selectionService")
], TransactionManager.prototype, "selectionService", 2);
__decorateClass14([
  PostConstruct74
], TransactionManager.prototype, "postConstruct", 1);
TransactionManager = __decorateClass14([
  Bean64("ssrmTransactionManager")
], TransactionManager);
var ExpandListener = class extends BeanStub82 {
  postConstruct() {
    if (!this.gos.isRowModelType("serverSide")) {
      return;
    }
    this.addManagedListener(this.eventService, Events55.EVENT_ROW_GROUP_OPENED, this.onRowGroupOpened.bind(this));
  }
  onRowGroupOpened(event) {
    const rowNode = event.node;
    if (rowNode.expanded) {
      if (rowNode.master) {
        this.createDetailNode(rowNode);
      } else if (_67.missing(rowNode.childStore)) {
        const storeParams = this.serverSideRowModel.getParams();
        rowNode.childStore = this.createBean(this.storeFactory.createStore(storeParams, rowNode));
      }
    } else if (this.gos.get("purgeClosedRowNodes") && _67.exists(rowNode.childStore)) {
      rowNode.childStore = this.destroyBean(rowNode.childStore);
    }
    const storeUpdatedEvent = { type: Events55.EVENT_STORE_UPDATED };
    this.eventService.dispatchEvent(storeUpdatedEvent);
  }
  createDetailNode(masterNode) {
    if (_67.exists(masterNode.detailNode)) {
      return masterNode.detailNode;
    }
    const detailNode = new RowNode10(this.beans);
    detailNode.detail = true;
    detailNode.selectable = false;
    detailNode.parent = masterNode;
    if (_67.exists(masterNode.id)) {
      detailNode.id = "detail_" + masterNode.id;
    }
    detailNode.data = masterNode.data;
    detailNode.level = masterNode.level + 1;
    const defaultDetailRowHeight = 200;
    const rowHeight = this.gos.getRowHeightForNode(detailNode).height;
    detailNode.rowHeight = rowHeight ? rowHeight : defaultDetailRowHeight;
    masterNode.detailNode = detailNode;
    return detailNode;
  }
};
__decorateClass14([
  Autowired94("rowModel")
], ExpandListener.prototype, "serverSideRowModel", 2);
__decorateClass14([
  Autowired94("ssrmStoreFactory")
], ExpandListener.prototype, "storeFactory", 2);
__decorateClass14([
  Autowired94("beans")
], ExpandListener.prototype, "beans", 2);
__decorateClass14([
  PostConstruct83
], ExpandListener.prototype, "postConstruct", 1);
ExpandListener = __decorateClass14([
  Bean73("ssrmExpandListener")
], ExpandListener);
var SortListener = class extends BeanStub92 {
  postConstruct() {
    if (!this.gos.isRowModelType("serverSide")) {
      return;
    }
    this.addManagedListener(this.eventService, Events65.EVENT_SORT_CHANGED, this.onSortChanged.bind(this));
  }
  onSortChanged() {
    const storeParams = this.serverSideRowModel.getParams();
    if (!storeParams) {
      return;
    }
    const newSortModel = this.sortController.getSortModel();
    const oldSortModel = storeParams.sortModel;
    const changedColumns = this.findChangedColumnsInSort(newSortModel, oldSortModel);
    const valueColChanged = this.listenerUtils.isSortingWithValueColumn(changedColumns);
    const secondaryColChanged = this.listenerUtils.isSortingWithSecondaryColumn(changedColumns);
    const params = {
      valueColChanged,
      secondaryColChanged,
      changedColumns
    };
    this.serverSideRowModel.refreshAfterSort(newSortModel, params);
  }
  // returns back all the cols that were effected by the sorting. eg if we were sorting by col A,
  // and now we are sorting by col B, the list of impacted cols should be A and B. so if a cache
  // is impacted by sorting on A or B then it needs to be refreshed. this includes where the cache
  // was previously sorted by A and then the A sort now needs to be cleared.
  findChangedColumnsInSort(newSortModel, oldSortModel) {
    let allColsInBothSorts = [];
    [newSortModel, oldSortModel].forEach((sortModel) => {
      if (sortModel) {
        const ids = sortModel.map((sm) => sm.colId);
        allColsInBothSorts = allColsInBothSorts.concat(ids);
      }
    });
    const differentSorts = (oldSortItem, newSortItem) => {
      const oldSort = oldSortItem ? oldSortItem.sort : null;
      const newSort = newSortItem ? newSortItem.sort : null;
      return oldSort !== newSort;
    };
    const differentIndexes = (oldSortItem, newSortItem) => {
      const oldIndex = oldSortItem ? oldSortModel.indexOf(oldSortItem) : -1;
      const newIndex = newSortItem ? newSortModel.indexOf(newSortItem) : -1;
      return oldIndex !== newIndex;
    };
    return allColsInBothSorts.filter((colId) => {
      const oldSortItem = oldSortModel.find((sm) => sm.colId === colId);
      const newSortItem = newSortModel.find((sm) => sm.colId === colId);
      return differentSorts(oldSortItem, newSortItem) || differentIndexes(oldSortItem, newSortItem);
    });
  }
};
__decorateClass14([
  Autowired104("sortController")
], SortListener.prototype, "sortController", 2);
__decorateClass14([
  Autowired104("rowModel")
], SortListener.prototype, "serverSideRowModel", 2);
__decorateClass14([
  Autowired104("ssrmListenerUtils")
], SortListener.prototype, "listenerUtils", 2);
__decorateClass14([
  PostConstruct93
], SortListener.prototype, "postConstruct", 1);
SortListener = __decorateClass14([
  Bean82("ssrmSortService")
], SortListener);
var FilterListener = class extends BeanStub102 {
  postConstruct() {
    if (!this.gos.isRowModelType("serverSide")) {
      return;
    }
    this.addManagedListener(this.eventService, Events75.EVENT_ADVANCED_FILTER_ENABLED_CHANGED, () => this.onFilterChanged(true));
    this.addManagedListener(this.eventService, Events75.EVENT_FILTER_CHANGED, () => this.onFilterChanged());
  }
  onFilterChanged(advancedFilterEnabledChanged) {
    const storeParams = this.serverSideRowModel.getParams();
    if (!storeParams) {
      return;
    }
    const oldModel = storeParams.filterModel;
    let newModel;
    let changedColumns;
    if (this.filterManager.isAdvancedFilterEnabled()) {
      newModel = this.filterManager.getAdvancedFilterModel();
      const oldColumns = advancedFilterEnabledChanged ? Object.keys(oldModel != null ? oldModel : {}) : this.getAdvancedFilterColumns(oldModel);
      const newColumns = this.getAdvancedFilterColumns(newModel);
      oldColumns.forEach((column) => newColumns.add(column));
      changedColumns = Array.from(newColumns);
    } else {
      newModel = this.filterManager.getFilterModel();
      if (advancedFilterEnabledChanged) {
        const oldColumns = this.getAdvancedFilterColumns(oldModel);
        Object.keys(newModel).forEach((column) => oldColumns.add(column));
        changedColumns = Array.from(oldColumns);
      } else {
        changedColumns = this.findChangedColumns(oldModel, newModel);
      }
    }
    const valueColChanged = this.listenerUtils.isSortingWithValueColumn(changedColumns);
    const secondaryColChanged = this.listenerUtils.isSortingWithSecondaryColumn(changedColumns);
    const params = {
      valueColChanged,
      secondaryColChanged,
      changedColumns
    };
    this.serverSideRowModel.refreshAfterFilter(newModel, params);
  }
  findChangedColumns(oldModel, newModel) {
    const allColKeysMap = {};
    Object.keys(oldModel).forEach((key) => allColKeysMap[key] = true);
    Object.keys(newModel).forEach((key) => allColKeysMap[key] = true);
    const res = [];
    Object.keys(allColKeysMap).forEach((key) => {
      const oldJson = JSON.stringify(oldModel[key]);
      const newJson = JSON.stringify(newModel[key]);
      const filterChanged = oldJson != newJson;
      if (filterChanged) {
        res.push(key);
      }
    });
    return res;
  }
  getAdvancedFilterColumns(model) {
    const columns = /* @__PURE__ */ new Set();
    if (!model) {
      return columns;
    }
    const processAdvancedFilterModel = (filterModel) => {
      if (filterModel.filterType === "join") {
        filterModel.conditions.forEach((condition) => processAdvancedFilterModel(condition));
      } else {
        columns.add(filterModel.colId);
      }
    };
    processAdvancedFilterModel(model);
    return columns;
  }
};
__decorateClass14([
  Autowired113("rowModel")
], FilterListener.prototype, "serverSideRowModel", 2);
__decorateClass14([
  Autowired113("filterManager")
], FilterListener.prototype, "filterManager", 2);
__decorateClass14([
  Autowired113("ssrmListenerUtils")
], FilterListener.prototype, "listenerUtils", 2);
__decorateClass14([
  PostConstruct103
], FilterListener.prototype, "postConstruct", 1);
FilterListener = __decorateClass14([
  Bean92("ssrmFilterListener")
], FilterListener);
var StoreFactory = class {
  createStore(ssrmParams, parentNode) {
    const storeParams = this.getStoreParams(ssrmParams, parentNode);
    const CacheClass = storeParams.suppressInfiniteScroll ? FullStore : LazyStore;
    return new CacheClass(ssrmParams, storeParams, parentNode);
  }
  getStoreParams(ssrmParams, parentNode) {
    const userStoreParams = this.getLevelSpecificParams(parentNode);
    const infiniteScroll = this.isInfiniteScroll(userStoreParams);
    const cacheBlockSize = this.getBlockSize(infiniteScroll, userStoreParams);
    const maxBlocksInCache = this.getMaxBlocksInCache(infiniteScroll, ssrmParams, userStoreParams);
    const storeParams = {
      suppressInfiniteScroll: !infiniteScroll,
      cacheBlockSize,
      maxBlocksInCache
    };
    return storeParams;
  }
  getMaxBlocksInCache(infiniteScroll, ssrmParams, userStoreParams) {
    if (!infiniteScroll) {
      return void 0;
    }
    const maxBlocksInCache = userStoreParams && userStoreParams.maxBlocksInCache != null ? userStoreParams.maxBlocksInCache : this.gos.get("maxBlocksInCache");
    const maxBlocksActive = maxBlocksInCache != null && maxBlocksInCache >= 0;
    if (!maxBlocksActive) {
      return void 0;
    }
    if (ssrmParams.dynamicRowHeight) {
      const message = "Server Side Row Model does not support Dynamic Row Height and Cache Purging. Either a) remove getRowHeight() callback or b) remove maxBlocksInCache property. Purging has been disabled.";
      _85.warnOnce(message);
      return void 0;
    }
    if (this.columnModel.isAutoRowHeightActive()) {
      const message = "Server Side Row Model does not support Auto Row Height and Cache Purging. Either a) remove colDef.autoHeight or b) remove maxBlocksInCache property. Purging has been disabled.";
      _85.warnOnce(message);
      return void 0;
    }
    return maxBlocksInCache;
  }
  getBlockSize(infiniteScroll, userStoreParams) {
    if (!infiniteScroll) {
      return void 0;
    }
    const blockSize = userStoreParams && userStoreParams.cacheBlockSize != null ? userStoreParams.cacheBlockSize : this.gos.get("cacheBlockSize");
    if (blockSize != null && blockSize > 0) {
      return blockSize;
    } else {
      return 100;
    }
  }
  getLevelSpecificParams(parentNode) {
    const callback = this.gos.getCallback("getServerSideGroupLevelParams");
    if (!callback) {
      return void 0;
    }
    const params = {
      level: parentNode.level + 1,
      parentRowNode: parentNode.level >= 0 ? parentNode : void 0,
      rowGroupColumns: this.columnModel.getRowGroupColumns(),
      pivotColumns: this.columnModel.getPivotColumns(),
      pivotMode: this.columnModel.isPivotMode()
    };
    const res = callback(params);
    return res;
  }
  isInfiniteScroll(storeParams) {
    const res = storeParams && storeParams.suppressInfiniteScroll != null ? storeParams.suppressInfiniteScroll : this.isSuppressServerSideInfiniteScroll();
    return !res;
  }
  isSuppressServerSideInfiniteScroll() {
    return this.gos.get("suppressServerSideInfiniteScroll");
  }
};
__decorateClass14([
  Autowired123("gridOptionsService")
], StoreFactory.prototype, "gos", 2);
__decorateClass14([
  Autowired123("columnModel")
], StoreFactory.prototype, "columnModel", 2);
StoreFactory = __decorateClass14([
  Bean102("ssrmStoreFactory")
], StoreFactory);
var ListenerUtils = class {
  isSortingWithValueColumn(changedColumnsInSort) {
    const valueColIds = this.columnModel.getValueColumns().map((col) => col.getColId());
    for (let i = 0; i < changedColumnsInSort.length; i++) {
      if (valueColIds.indexOf(changedColumnsInSort[i]) > -1) {
        return true;
      }
    }
    return false;
  }
  isSortingWithSecondaryColumn(changedColumnsInSort) {
    if (!this.columnModel.getSecondaryColumns()) {
      return false;
    }
    const secondaryColIds = this.columnModel.getSecondaryColumns().map((col) => col.getColId());
    for (let i = 0; i < changedColumnsInSort.length; i++) {
      if (secondaryColIds.indexOf(changedColumnsInSort[i]) > -1) {
        return true;
      }
    }
    return false;
  }
};
__decorateClass14([
  Autowired133("columnModel")
], ListenerUtils.prototype, "columnModel", 2);
ListenerUtils = __decorateClass14([
  Bean112("ssrmListenerUtils")
], ListenerUtils);
var DefaultStrategy = class extends BeanStub112 {
  constructor() {
    super(...arguments);
    this.selectedState = { selectAll: false, toggledNodes: /* @__PURE__ */ new Set() };
    this.lastSelected = null;
    this.selectAllUsed = false;
    this.selectedNodes = {};
  }
  init() {
    this.rowSelection = this.gos.get("rowSelection");
    this.addManagedPropertyListener("rowSelection", (propChange) => {
      this.rowSelection = propChange.currentValue;
    });
  }
  getSelectedState() {
    return {
      selectAll: this.selectedState.selectAll,
      toggledNodes: [...this.selectedState.toggledNodes]
    };
  }
  setSelectedState(state) {
    const newState = {
      selectAll: false,
      toggledNodes: /* @__PURE__ */ new Set()
    };
    if (typeof state !== "object") {
      console.error("AG Grid: The provided selection state should be an object.");
      return;
    }
    if ("selectAll" in state && typeof state.selectAll === "boolean") {
      newState.selectAll = state.selectAll;
    } else {
      console.error("AG Grid: Select all status should be of boolean type.");
      return;
    }
    if ("toggledNodes" in state && Array.isArray(state.toggledNodes)) {
      state.toggledNodes.forEach((key) => {
        if (typeof key === "string") {
          newState.toggledNodes.add(key);
        } else {
          console.warn(`AG Grid: Provided ids must be of string type. Invalid id provided: ${key}`);
        }
      });
    } else {
      console.error("AG Grid: `toggledNodes` must be an array of string ids.");
      return;
    }
    this.selectedState = newState;
  }
  deleteSelectionStateFromParent(parentPath, removedNodeIds) {
    if (this.selectedState.toggledNodes.size === 0) {
      return false;
    }
    let anyNodesToggled = false;
    removedNodeIds.forEach((id) => {
      if (this.selectedState.toggledNodes.delete(id)) {
        anyNodesToggled = true;
      }
    });
    return anyNodesToggled;
  }
  setNodesSelected(params) {
    if (params.nodes.length === 0)
      return 0;
    const onlyThisNode = params.clearSelection && params.newValue && !params.rangeSelect;
    if (this.rowSelection !== "multiple" || onlyThisNode) {
      if (params.nodes.length > 1) {
        throw new Error("AG Grid: cannot select multiple rows when rowSelection is set to 'single'");
      }
      const node = params.nodes[0];
      if (params.newValue) {
        this.selectedNodes = { [node.id]: node };
        this.selectedState = {
          selectAll: false,
          toggledNodes: /* @__PURE__ */ new Set([node.id])
        };
      } else {
        this.selectedNodes = {};
        this.selectedState = {
          selectAll: false,
          toggledNodes: /* @__PURE__ */ new Set()
        };
      }
      this.lastSelected = node.id;
      return 1;
    }
    const updateNodeState = (node) => {
      if (params.newValue) {
        this.selectedNodes[node.id] = node;
      } else {
        delete this.selectedNodes[node.id];
      }
      const isNodeSelectable = node.selectable;
      const doesNodeConform = params.newValue === this.selectedState.selectAll;
      if (doesNodeConform || !isNodeSelectable) {
        this.selectedState.toggledNodes.delete(node.id);
        return;
      }
      this.selectedState.toggledNodes.add(node.id);
    };
    if (params.rangeSelect && this.lastSelected) {
      if (params.nodes.length > 1) {
        throw new Error("AG Grid: cannot select multiple rows when using rangeSelect");
      }
      const node = params.nodes[0];
      const lastSelectedNode = this.rowModel.getRowNode(this.lastSelected);
      this.rowModel.getNodesInRangeForSelection(node, lastSelectedNode != null ? lastSelectedNode : null).forEach(updateNodeState);
      this.lastSelected = node.id;
      return 1;
    }
    params.nodes.forEach(updateNodeState);
    this.lastSelected = params.nodes[params.nodes.length - 1].id;
    return 1;
  }
  processNewRow(node) {
    if (this.selectedNodes[node.id]) {
      this.selectedNodes[node.id] = node;
    }
  }
  isNodeSelected(node) {
    const isToggled = this.selectedState.toggledNodes.has(node.id);
    return this.selectedState.selectAll ? !isToggled : isToggled;
  }
  getSelectedNodes() {
    if (this.selectAllUsed) {
      console.warn(
        `AG Grid: getSelectedNodes and getSelectedRows functions cannot be used with select all functionality with the server-side row model.
                Use \`api.getServerSideSelectionState()\` instead.`
      );
    }
    return Object.values(this.selectedNodes);
  }
  getSelectedRows() {
    return this.getSelectedNodes().map((node) => node.data);
  }
  getSelectionCount() {
    if (this.selectedState.selectAll) {
      return -1;
    }
    return this.selectedState.toggledNodes.size;
  }
  clearOtherNodes(rowNodeToKeepSelected, source) {
    const clearedRows = this.selectedState.selectAll ? 1 : this.selectedState.toggledNodes.size - 1;
    this.selectedState = {
      selectAll: false,
      toggledNodes: /* @__PURE__ */ new Set([rowNodeToKeepSelected.id])
    };
    this.rowModel.forEachNode((node) => {
      if (node !== rowNodeToKeepSelected) {
        node.selectThisNode(false, void 0, source);
      }
    });
    const event = {
      type: Events84.EVENT_SELECTION_CHANGED,
      source
    };
    this.eventService.dispatchEvent(event);
    return clearedRows;
  }
  isEmpty() {
    var _a;
    return !this.selectedState.selectAll && !((_a = this.selectedState.toggledNodes) == null ? void 0 : _a.size);
  }
  selectAllRowNodes(params) {
    this.selectedState = { selectAll: true, toggledNodes: /* @__PURE__ */ new Set() };
    this.selectedNodes = {};
    this.selectAllUsed = true;
  }
  deselectAllRowNodes(params) {
    this.selectedState = { selectAll: false, toggledNodes: /* @__PURE__ */ new Set() };
    this.selectedNodes = {};
  }
  getSelectAllState(justFiltered, justCurrentPage) {
    if (this.selectedState.selectAll) {
      if (this.selectedState.toggledNodes.size > 0) {
        return null;
      }
      return true;
    }
    if (this.selectedState.toggledNodes.size > 0) {
      return null;
    }
    return false;
  }
};
__decorateClass14([
  Autowired142("rowModel")
], DefaultStrategy.prototype, "rowModel", 2);
__decorateClass14([
  PostConstruct113
], DefaultStrategy.prototype, "init", 1);
var GroupSelectsChildrenStrategy = class extends BeanStub122 {
  constructor() {
    super(...arguments);
    this.selectedState = { selectAllChildren: false, toggledNodes: /* @__PURE__ */ new Map() };
    this.lastSelected = null;
  }
  init() {
    this.addManagedListener(this.eventService, Events93.EVENT_MODEL_UPDATED, () => this.removeRedundantState());
    this.addManagedListener(this.eventService, Events93.EVENT_COLUMN_ROW_GROUP_CHANGED, () => this.selectionService.reset("rowGroupChanged"));
  }
  getSelectedState() {
    const treeData = this.gos.get("treeData");
    const recursivelySerializeState = (state, level, nodeId) => {
      const normalisedState = {
        nodeId
      };
      if (treeData || level <= this.columnModel.getRowGroupColumns().length) {
        normalisedState.selectAllChildren = state.selectAllChildren;
      }
      if (state.toggledNodes.size) {
        const toggledNodes = [];
        state.toggledNodes.forEach((value, key) => {
          const newState = recursivelySerializeState(value, level + 1, key);
          toggledNodes.push(newState);
        });
        normalisedState.toggledNodes = toggledNodes;
      }
      return normalisedState;
    };
    return recursivelySerializeState(this.selectedState, 0);
  }
  setSelectedState(state) {
    const recursivelyDeserializeState = (normalisedState, parentSelected) => {
      var _a, _b;
      if (typeof normalisedState !== "object") {
        throw new Error("AG Grid: Each provided state object must be an object.");
      }
      if ("selectAllChildren" in normalisedState && typeof normalisedState.selectAllChildren !== "boolean") {
        throw new Error("AG Grid: `selectAllChildren` must be a boolean value or undefined.");
      }
      if ("toggledNodes" in normalisedState) {
        if (!Array.isArray(normalisedState.toggledNodes)) {
          throw new Error("AG Grid: `toggledNodes` must be an array.");
        }
        const allHaveIds = normalisedState.toggledNodes.every((innerState) => typeof innerState === "object" && "nodeId" in innerState && typeof innerState.nodeId === "string");
        if (!allHaveIds) {
          throw new Error("AG Grid: Every `toggledNode` requires an associated string id.");
        }
      }
      const isThisNodeSelected = (_a = normalisedState.selectAllChildren) != null ? _a : !parentSelected;
      const convertedChildren = (_b = normalisedState.toggledNodes) == null ? void 0 : _b.map((innerState) => [innerState.nodeId, recursivelyDeserializeState(innerState, isThisNodeSelected)]);
      const doesRedundantStateExist = convertedChildren == null ? void 0 : convertedChildren.some(([_94, innerState]) => isThisNodeSelected === innerState.selectAllChildren && innerState.toggledNodes.size === 0);
      if (doesRedundantStateExist) {
        throw new Error(`
                    AG Grid: AG Grid: Row selection state could not be parsed due to invalid data. Ensure all child state has toggledNodes or does not conform with the parent rule.
                    Please rebuild the selection state and reapply it.
                `);
      }
      return {
        selectAllChildren: isThisNodeSelected,
        toggledNodes: new Map(convertedChildren)
      };
    };
    try {
      this.selectedState = recursivelyDeserializeState(state, !!state.selectAllChildren);
    } catch (e) {
      console.error(e.message);
    }
  }
  deleteSelectionStateFromParent(parentRoute, removedNodeIds) {
    let parentState = this.selectedState;
    const remainingRoute = [...parentRoute];
    while (parentState && remainingRoute.length) {
      parentState = parentState.toggledNodes.get(remainingRoute.pop());
    }
    if (!parentState) {
      return false;
    }
    let anyStateChanged = false;
    removedNodeIds.forEach((id) => {
      if (parentState == null ? void 0 : parentState.toggledNodes.delete(id)) {
        anyStateChanged = true;
      }
    });
    if (anyStateChanged) {
      this.removeRedundantState();
    }
    return anyStateChanged;
  }
  setNodesSelected(params) {
    const _a = params, { nodes } = _a, other = __objRest2(_a, ["nodes"]);
    if (nodes.length === 0)
      return 0;
    if (params.rangeSelect) {
      if (nodes.length > 1) {
        throw new Error("AG Grid: cannot select multiple rows when using rangeSelect");
      }
      const node = nodes[0];
      const rangeOfNodes = this.rowModel.getNodesInRangeForSelection(node, this.lastSelected);
      const routes = rangeOfNodes.map(this.getRouteToNode).sort((a, b) => b.length - a.length);
      const completedRoutes = /* @__PURE__ */ new Set();
      routes.forEach((route) => {
        if (completedRoutes.has(route[route.length - 1])) {
          return;
        }
        route.forEach((part) => completedRoutes.add(part));
        this.recursivelySelectNode(route, this.selectedState, __spreadValues12({ node }, other));
      });
      this.removeRedundantState();
      this.lastSelected = node;
      return 1;
    }
    params.nodes.forEach((node) => {
      const idPathToNode = this.getRouteToNode(node);
      this.recursivelySelectNode(idPathToNode, this.selectedState, __spreadProps9(__spreadValues12({}, other), { node }));
    });
    this.removeRedundantState();
    this.lastSelected = params.nodes[params.nodes.length - 1];
    return 1;
  }
  isNodeSelected(node) {
    const path = this.getRouteToNode(node);
    return this.isNodePathSelected(path, this.selectedState);
  }
  isNodePathSelected([nextNode, ...nodes], state) {
    if (nodes.length === 0) {
      const isToggled = state.toggledNodes.has(nextNode.id);
      if (nextNode.hasChildren()) {
        const groupState = state.toggledNodes.get(nextNode.id);
        if (groupState && groupState.toggledNodes.size) {
          return void 0;
        }
      }
      return state.selectAllChildren ? !isToggled : isToggled;
    }
    if (state.toggledNodes.has(nextNode.id)) {
      const nextState = state.toggledNodes.get(nextNode.id);
      if (nextState) {
        return this.isNodePathSelected(nodes, nextState);
      }
    }
    return !!state.selectAllChildren;
  }
  getRouteToNode(node) {
    const pathToNode = [];
    let tempNode = node;
    while (tempNode.parent) {
      pathToNode.push(tempNode);
      tempNode = tempNode.parent;
    }
    return pathToNode.reverse();
  }
  removeRedundantState() {
    if (this.filterManager.isAnyFilterPresent()) {
      return;
    }
    const forEachNodeStateDepthFirst = (state = this.selectedState, thisKey, parentState) => {
      state.toggledNodes.forEach((value, key) => {
        forEachNodeStateDepthFirst(value, key, state);
      });
      if (thisKey) {
        const thisRow = this.rowModel.getRowNode(thisKey);
        const thisRowStore = thisRow == null ? void 0 : thisRow.childStore;
        const isStoreSizeKnown = thisRowStore == null ? void 0 : thisRowStore.isLastRowIndexKnown();
        if (isStoreSizeKnown) {
          const possibleAllNodesToggled = state.toggledNodes.size >= thisRowStore.getRowCount();
          if (possibleAllNodesToggled) {
            for (const childState of state.toggledNodes.entries()) {
              const [key, value] = childState;
              if (value.toggledNodes.size > 0) {
                return;
              }
              const rowDoesNotExist = !this.rowModel.getRowNode(key);
              if (rowDoesNotExist) {
                return;
              }
            }
            state.selectAllChildren = !state.selectAllChildren;
            state.toggledNodes.clear();
          }
        }
      }
      const hasNoToggledRows = state.toggledNodes.size === 0;
      const isIdenticalToParent = (parentState == null ? void 0 : parentState.selectAllChildren) === state.selectAllChildren;
      if (hasNoToggledRows && isIdenticalToParent) {
        parentState == null ? void 0 : parentState.toggledNodes.delete(thisKey);
      }
    };
    forEachNodeStateDepthFirst();
  }
  recursivelySelectNode([nextNode, ...nodes], selectedState, params) {
    if (!nextNode) {
      return;
    }
    const isLastNode = !nodes.length;
    if (isLastNode) {
      const isNodeSelectable = nextNode.selectable;
      const doesNodeConform = selectedState.selectAllChildren === params.newValue;
      if (doesNodeConform || !isNodeSelectable) {
        selectedState.toggledNodes.delete(nextNode.id);
        return;
      }
      const newState = {
        selectAllChildren: params.newValue,
        toggledNodes: /* @__PURE__ */ new Map()
      };
      selectedState.toggledNodes.set(nextNode.id, newState);
      return;
    }
    const doesStateAlreadyExist = selectedState.toggledNodes.has(nextNode.id);
    const childState = doesStateAlreadyExist ? selectedState.toggledNodes.get(nextNode.id) : {
      selectAllChildren: selectedState.selectAllChildren,
      toggledNodes: /* @__PURE__ */ new Map()
    };
    if (!doesStateAlreadyExist) {
      selectedState.toggledNodes.set(nextNode.id, childState);
    }
    this.recursivelySelectNode(nodes, childState, params);
    if (selectedState.selectAllChildren === childState.selectAllChildren && childState.toggledNodes.size === 0) {
      selectedState.toggledNodes.delete(nextNode.id);
    }
  }
  getSelectedNodes() {
    console.warn(
      `AG Grid: \`getSelectedNodes\` and \`getSelectedRows\` functions cannot be used with \`groupSelectsChildren\` and the server-side row model.
            Use \`api.getServerSideSelectionState()\` instead.`
    );
    const selectedNodes = [];
    this.rowModel.forEachNode((node) => {
      if (node.isSelected()) {
        selectedNodes.push(node);
      }
    });
    return selectedNodes;
  }
  processNewRow(node) {
  }
  getSelectedRows() {
    return this.getSelectedNodes().map((node) => node.data);
  }
  getSelectionCount() {
    return -1;
  }
  isEmpty() {
    var _a;
    return !this.selectedState.selectAllChildren && !((_a = this.selectedState.toggledNodes) == null ? void 0 : _a.size);
  }
  selectAllRowNodes(params) {
    this.selectedState = { selectAllChildren: true, toggledNodes: /* @__PURE__ */ new Map() };
  }
  deselectAllRowNodes(params) {
    this.selectedState = { selectAllChildren: false, toggledNodes: /* @__PURE__ */ new Map() };
  }
  getSelectAllState(justFiltered, justCurrentPage) {
    if (this.selectedState.selectAllChildren) {
      if (this.selectedState.toggledNodes.size > 0) {
        return null;
      }
      return true;
    }
    if (this.selectedState.toggledNodes.size > 0) {
      return null;
    }
    return false;
  }
};
__decorateClass14([
  Autowired152("rowModel")
], GroupSelectsChildrenStrategy.prototype, "rowModel", 2);
__decorateClass14([
  Autowired152("columnModel")
], GroupSelectsChildrenStrategy.prototype, "columnModel", 2);
__decorateClass14([
  Autowired152("filterManager")
], GroupSelectsChildrenStrategy.prototype, "filterManager", 2);
__decorateClass14([
  Autowired152("rowModel")
], GroupSelectsChildrenStrategy.prototype, "serverSideRowModel", 2);
__decorateClass14([
  Autowired152("selectionService")
], GroupSelectsChildrenStrategy.prototype, "selectionService", 2);
__decorateClass14([
  PostConstruct123
], GroupSelectsChildrenStrategy.prototype, "init", 1);
var ServerSideSelectionService = class extends BeanStub132 {
  init() {
    const groupSelectsChildren = this.gos.get("groupSelectsChildren");
    this.addManagedPropertyListener("groupSelectsChildren", (propChange) => {
      this.destroyBean(this.selectionStrategy);
      const StrategyClazz2 = !propChange.currentValue ? DefaultStrategy : GroupSelectsChildrenStrategy;
      this.selectionStrategy = this.createManagedBean(new StrategyClazz2());
      this.shotgunResetNodeSelectionState();
      const event = {
        type: Events102.EVENT_SELECTION_CHANGED,
        source: "api"
      };
      this.eventService.dispatchEvent(event);
    });
    this.addManagedPropertyListener("rowSelection", () => this.deselectAllRowNodes({ source: "api" }));
    const StrategyClazz = !groupSelectsChildren ? DefaultStrategy : GroupSelectsChildrenStrategy;
    this.selectionStrategy = this.createManagedBean(new StrategyClazz());
  }
  getSelectionState() {
    return this.selectionStrategy.getSelectedState();
  }
  setSelectionState(state, source) {
    if (Array.isArray(state)) {
      return;
    }
    this.selectionStrategy.setSelectedState(state);
    this.shotgunResetNodeSelectionState();
    const event = {
      type: Events102.EVENT_SELECTION_CHANGED,
      source
    };
    this.eventService.dispatchEvent(event);
  }
  setNodesSelected(params) {
    const _a = params, { nodes } = _a, otherParams = __objRest2(_a, ["nodes"]);
    const rowSelection = this.gos.get("rowSelection");
    if (nodes.length > 1 && rowSelection !== "multiple") {
      console.warn(`AG Grid: cannot multi select while rowSelection='single'`);
      return 0;
    }
    if (nodes.length > 1 && params.rangeSelect) {
      console.warn(`AG Grid: cannot use range selection when multi selecting rows`);
      return 0;
    }
    const adjustedParams = __spreadValues12({
      nodes: nodes.filter((node) => node.selectable)
    }, otherParams);
    if (!adjustedParams.nodes.length) {
      return 0;
    }
    const changedNodes = this.selectionStrategy.setNodesSelected(adjustedParams);
    this.shotgunResetNodeSelectionState(adjustedParams.source);
    const event = {
      type: Events102.EVENT_SELECTION_CHANGED,
      source: adjustedParams.source
    };
    this.eventService.dispatchEvent(event);
    return changedNodes;
  }
  /**
   * Deletes the selection state for a set of nodes, for use after deleting nodes via
   * transaction. As this is designed for transactions, all nodes should belong to the same group.
   */
  deleteSelectionStateFromParent(storeRoute, removedNodeIds) {
    const stateChanged = this.selectionStrategy.deleteSelectionStateFromParent(storeRoute, removedNodeIds);
    if (!stateChanged) {
      return;
    }
    this.shotgunResetNodeSelectionState();
    const event = {
      type: Events102.EVENT_SELECTION_CHANGED,
      source: "api"
    };
    this.eventService.dispatchEvent(event);
  }
  shotgunResetNodeSelectionState(source) {
    this.rowModel.forEachNode((node) => {
      if (node.stub) {
        return;
      }
      const isNodeSelected = this.selectionStrategy.isNodeSelected(node);
      if (isNodeSelected !== node.isSelected()) {
        node.selectThisNode(isNodeSelected, void 0, source);
      }
    });
  }
  getSelectedNodes() {
    return this.selectionStrategy.getSelectedNodes();
  }
  getSelectedRows() {
    return this.selectionStrategy.getSelectedRows();
  }
  getSelectionCount() {
    return this.selectionStrategy.getSelectionCount();
  }
  syncInRowNode(rowNode, oldNode) {
    this.selectionStrategy.processNewRow(rowNode);
    const isNodeSelected = this.selectionStrategy.isNodeSelected(rowNode);
    if (isNodeSelected != false && !rowNode.selectable) {
      this.selectionStrategy.setNodesSelected({
        nodes: [rowNode],
        newValue: false,
        source: "api"
      });
      this.shotgunResetNodeSelectionState();
      const event = {
        type: Events102.EVENT_SELECTION_CHANGED,
        source: "api"
      };
      this.eventService.dispatchEvent(event);
      return;
    }
    rowNode.setSelectedInitialValue(isNodeSelected);
  }
  reset() {
    this.selectionStrategy.deselectAllRowNodes({ source: "api" });
  }
  isEmpty() {
    return this.selectionStrategy.isEmpty();
  }
  hasNodesToSelect(justFiltered = false, justCurrentPage = false) {
    return true;
  }
  selectAllRowNodes(params) {
    if (params.justCurrentPage || params.justFiltered) {
      console.warn("AG Grid: selecting just filtered only works when gridOptions.rowModelType='clientSide'");
    }
    this.selectionStrategy.selectAllRowNodes(params);
    this.rowModel.forEachNode((node) => {
      if (node.stub) {
        return;
      }
      node.selectThisNode(true, void 0, params.source);
    });
    const event = {
      type: Events102.EVENT_SELECTION_CHANGED,
      source: params.source
    };
    this.eventService.dispatchEvent(event);
  }
  deselectAllRowNodes(params) {
    if (params.justCurrentPage || params.justFiltered) {
      console.warn("AG Grid: selecting just filtered only works when gridOptions.rowModelType='clientSide'");
    }
    this.selectionStrategy.deselectAllRowNodes(params);
    this.rowModel.forEachNode((node) => {
      if (node.stub) {
        return;
      }
      node.selectThisNode(false, void 0, params.source);
    });
    const event = {
      type: Events102.EVENT_SELECTION_CHANGED,
      source: params.source
    };
    this.eventService.dispatchEvent(event);
  }
  getSelectAllState(justFiltered, justCurrentPage) {
    return this.selectionStrategy.getSelectAllState(justFiltered, justCurrentPage);
  }
  // used by CSRM
  updateGroupsFromChildrenSelections(source, changedPath) {
    return false;
  }
  // used by CSRM
  getBestCostNodeSelection() {
    console.warn("AG Grid: calling gridApi.getBestCostNodeSelection() is only possible when using rowModelType=`clientSide`.");
    return void 0;
  }
  // used by CSRM
  filterFromSelection() {
    return;
  }
};
__decorateClass14([
  Autowired162("rowModel")
], ServerSideSelectionService.prototype, "rowModel", 2);
__decorateClass14([
  PostConstruct133
], ServerSideSelectionService.prototype, "init", 1);
ServerSideSelectionService = __decorateClass14([
  Bean122("selectionService")
], ServerSideSelectionService);
var VERSION15 = "31.3.2";
var ServerSideExpansionService = class extends ExpansionService {
  constructor() {
    super(...arguments);
    this.queuedRowIds = /* @__PURE__ */ new Set();
  }
  postConstruct() {
    super.postConstruct();
    this.addManagedListener(this.eventService, Events112.EVENT_COLUMN_ROW_GROUP_CHANGED, () => {
      this.queuedRowIds.clear();
    });
  }
  checkOpenByDefault(rowNode) {
    if (!rowNode.isExpandable()) {
      return;
    }
    if (this.queuedRowIds.has(rowNode.id)) {
      this.queuedRowIds.delete(rowNode.id);
      rowNode.setExpanded(true);
      return;
    }
    const userFunc = this.gos.getCallback("isServerSideGroupOpenByDefault");
    if (!userFunc) {
      return;
    }
    const params = {
      data: rowNode.data,
      rowNode
    };
    const userFuncRes = userFunc(params);
    if (userFuncRes) {
      rowNode.setExpanded(true);
    }
  }
  expandRows(rowIds) {
    rowIds.forEach((rowId) => {
      const rowNode = this.serverSideRowModel.getRowNode(rowId);
      if (rowNode) {
        rowNode.setExpanded(true);
      } else {
        this.queuedRowIds.add(rowId);
      }
    });
  }
  expandAll(value) {
    this.serverSideRowModel.expandAll(value);
  }
  onGroupExpandedOrCollapsed() {
  }
};
__decorateClass14([
  Autowired172("rowModel")
], ServerSideExpansionService.prototype, "serverSideRowModel", 2);
ServerSideExpansionService = __decorateClass14([
  Bean132("expansionService")
], ServerSideExpansionService);
var ServerSideRowModelModule = {
  version: VERSION15,
  moduleName: ModuleNames26.ServerSideRowModelModule,
  rowModel: "serverSide",
  beans: [
    ServerSideRowModel,
    ExpandListener,
    SortListener,
    StoreUtils,
    BlockUtils,
    NodeManager,
    TransactionManager,
    FilterListener,
    StoreFactory,
    ListenerUtils,
    ServerSideSelectionService,
    ServerSideExpansionService,
    LazyBlockLoadingService
  ],
  dependantModules: [
    EnterpriseCoreModule
  ]
};

// enterprise-modules/set-filter/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames16 } from "ag-grid-community";
import {
  Autowired as Autowired217,
  Events as Events210,
  ProvidedFilter as ProvidedFilter2,
  RefSelector as RefSelector27,
  VirtualList as VirtualList4,
  AgPromise as AgPromise32,
  KeyCode as KeyCode11,
  _ as _59,
  GROUP_AUTO_COLUMN_ID as GROUP_AUTO_COLUMN_ID2
} from "ag-grid-community";
import {
  _ as _315,
  AgPromise as AgPromise24,
  TextFilter,
  EventService as EventService2
} from "ag-grid-community";
import {
  AgPromise as AgPromise6,
  Events as Events20,
  _ as _60
} from "ag-grid-community";
import { _ as _218 } from "ag-grid-community";
import {
  _ as _412,
  Autowired as Autowired68,
  Component as Component57,
  PostConstruct as PostConstruct65,
  RefSelector as RefSelector21
} from "ag-grid-community";
import {
  Autowired as Autowired316,
  Component as Component214,
  RefSelector as RefSelector37
} from "ag-grid-community";
var __defProp15 = Object.defineProperty;
var __getOwnPropDesc15 = Object.getOwnPropertyDescriptor;
var __decorateClass15 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc15(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp15(target, key, result);
  return result;
};
var ClientSideValuesExtractor = class {
  constructor(rowModel, filterParams, createKey, caseFormat, columnModel, valueService, treeDataOrGrouping, treeData, getDataPath, groupAllowUnbalanced, addManagedListener) {
    this.rowModel = rowModel;
    this.filterParams = filterParams;
    this.createKey = createKey;
    this.caseFormat = caseFormat;
    this.columnModel = columnModel;
    this.valueService = valueService;
    this.treeDataOrGrouping = treeDataOrGrouping;
    this.treeData = treeData;
    this.getDataPath = getDataPath;
    this.groupAllowUnbalanced = groupAllowUnbalanced;
    this.addManagedListener = addManagedListener;
  }
  extractUniqueValuesAsync(predicate, existingValues) {
    return new AgPromise6((resolve) => {
      if (this.rowModel.isRowDataLoaded()) {
        resolve(this.extractUniqueValues(predicate, existingValues));
      } else {
        const destroyFunc = this.addManagedListener(Events20.EVENT_ROW_COUNT_READY, () => {
          destroyFunc == null ? void 0 : destroyFunc();
          resolve(this.extractUniqueValues(predicate, existingValues));
        });
      }
    });
  }
  extractUniqueValues(predicate, existingValues) {
    const values = /* @__PURE__ */ new Map();
    const existingFormattedKeys = this.extractExistingFormattedKeys(existingValues);
    const formattedKeys = /* @__PURE__ */ new Set();
    const treeData = this.treeData && !!this.getDataPath;
    const groupedCols = this.columnModel.getRowGroupColumns();
    const addValue = (unformattedKey, value) => {
      const formattedKey = this.caseFormat(unformattedKey);
      if (!formattedKeys.has(formattedKey)) {
        formattedKeys.add(formattedKey);
        let keyToAdd = unformattedKey;
        let valueToAdd = _60.makeNull(value);
        const existingUnformattedKey = existingFormattedKeys == null ? void 0 : existingFormattedKeys.get(formattedKey);
        if (existingUnformattedKey != null) {
          keyToAdd = existingUnformattedKey;
          valueToAdd = existingValues.get(existingUnformattedKey);
        }
        values.set(keyToAdd, valueToAdd);
      }
    };
    this.rowModel.forEachLeafNode((node) => {
      if (!node.data || !predicate(node)) {
        return;
      }
      if (this.treeDataOrGrouping) {
        this.addValueForTreeDataOrGrouping(node, treeData, groupedCols, addValue);
        return;
      }
      let value = this.getValue(node);
      if (this.filterParams.convertValuesToStrings) {
        this.addValueForConvertValuesToString(node, value, addValue);
        return;
      }
      if (value != null && Array.isArray(value)) {
        value.forEach((x) => {
          addValue(this.createKey(x, node), x);
        });
        if (value.length === 0) {
          addValue(null, null);
        }
      } else {
        addValue(this.createKey(value, node), value);
      }
    });
    return values;
  }
  addValueForConvertValuesToString(node, value, addValue) {
    const key = this.createKey(value, node);
    if (key != null && Array.isArray(key)) {
      key.forEach((part) => {
        const processedPart = _60.toStringOrNull(_60.makeNull(part));
        addValue(processedPart, processedPart);
      });
      if (key.length === 0) {
        addValue(null, null);
      }
    } else {
      addValue(key, key);
    }
  }
  addValueForTreeDataOrGrouping(node, treeData, groupedCols, addValue) {
    var _a;
    let dataPath;
    if (treeData) {
      if ((_a = node.childrenAfterGroup) == null ? void 0 : _a.length) {
        return;
      }
      dataPath = this.getDataPath(node.data);
    } else {
      dataPath = groupedCols.map((groupCol) => this.valueService.getKeyForNode(groupCol, node));
      dataPath.push(this.getValue(node));
    }
    if (dataPath) {
      dataPath = dataPath.map((treeKey) => _60.toStringOrNull(_60.makeNull(treeKey)));
    }
    if (!treeData && this.groupAllowUnbalanced && (dataPath == null ? void 0 : dataPath.some((treeKey) => treeKey == null))) {
      dataPath = dataPath.filter((treeKey) => treeKey != null);
    }
    addValue(this.createKey(dataPath), dataPath);
  }
  getValue(node) {
    return this.filterParams.getValue(node);
  }
  extractExistingFormattedKeys(existingValues) {
    if (!existingValues) {
      return null;
    }
    const existingFormattedKeys = /* @__PURE__ */ new Map();
    existingValues.forEach((_value, key) => {
      existingFormattedKeys.set(this.caseFormat(key), key);
    });
    return existingFormattedKeys;
  }
};
var SetFilterDisplayValue = class {
};
SetFilterDisplayValue.SELECT_ALL = "__AG_SELECT_ALL__";
SetFilterDisplayValue.ADD_SELECTION_TO_FILTER = "__AG_ADD_SELECTION_TO_FILTER__";
var FlatSetDisplayValueModel = class {
  constructor(valueService, valueFormatter, formatter, column) {
    this.valueService = valueService;
    this.valueFormatter = valueFormatter;
    this.formatter = formatter;
    this.column = column;
    this.displayedKeys = [];
  }
  updateDisplayedValuesToAllAvailable(_getValue, _allKeys, availableKeys) {
    this.displayedKeys = Array.from(availableKeys);
  }
  updateDisplayedValuesToMatchMiniFilter(getValue, _allKeys, availableKeys, matchesFilter, nullMatchesFilter) {
    this.displayedKeys = [];
    for (let key of availableKeys) {
      if (key == null) {
        if (nullMatchesFilter) {
          this.displayedKeys.push(key);
        }
      } else {
        const value = getValue(key);
        const valueFormatterValue = this.valueService.formatValue(
          this.column,
          null,
          value,
          this.valueFormatter,
          false
        );
        const textFormatterValue = this.formatter(valueFormatterValue);
        if (matchesFilter(textFormatterValue)) {
          this.displayedKeys.push(key);
        }
      }
    }
  }
  getDisplayedValueCount() {
    return this.displayedKeys.length;
  }
  getDisplayedItem(index) {
    return this.displayedKeys[index];
  }
  getSelectAllItem() {
    return SetFilterDisplayValue.SELECT_ALL;
  }
  getAddSelectionToFilterItem() {
    return SetFilterDisplayValue.ADD_SELECTION_TO_FILTER;
  }
  getDisplayedKeys() {
    return this.displayedKeys;
  }
  forEachDisplayedKey(func) {
    this.displayedKeys.forEach(func);
  }
  someDisplayedKey(func) {
    return this.displayedKeys.some(func);
  }
  hasGroups() {
    return false;
  }
  refresh() {
  }
};
var _TreeSetDisplayValueModel = class _TreeSetDisplayValueModel2 {
  constructor(formatter, treeListPathGetter, treeListFormatter, treeDataOrGrouping) {
    this.formatter = formatter;
    this.treeListPathGetter = treeListPathGetter;
    this.treeListFormatter = treeListFormatter;
    this.treeDataOrGrouping = treeDataOrGrouping;
    this.allDisplayedItemsTree = [];
    this.activeDisplayedItemsFlat = [];
    this.selectAllItem = {
      depth: 0,
      filterPasses: true,
      available: true,
      treeKey: SetFilterDisplayValue.SELECT_ALL,
      children: this.allDisplayedItemsTree,
      expanded: true,
      key: SetFilterDisplayValue.SELECT_ALL,
      parentTreeKeys: []
    };
    this.addSelectionToFilterItem = {
      depth: 0,
      filterPasses: true,
      available: true,
      treeKey: SetFilterDisplayValue.ADD_SELECTION_TO_FILTER,
      expanded: true,
      key: SetFilterDisplayValue.ADD_SELECTION_TO_FILTER,
      parentTreeKeys: []
    };
  }
  updateDisplayedValuesToAllAvailable(getValue, allKeys, availableKeys, source) {
    if (source === "reload") {
      this.generateItemTree(getValue, allKeys, availableKeys);
    } else if (source === "otherFilter") {
      this.updateAvailable(availableKeys);
      this.updateExpandAll();
    } else if (source === "miniFilter") {
      this.resetFilter();
      this.updateExpandAll();
    }
    this.flattenItems();
  }
  updateDisplayedValuesToMatchMiniFilter(getValue, allKeys, availableKeys, matchesFilter, nullMatchesFilter, source) {
    if (source === "reload") {
      this.generateItemTree(getValue, allKeys, availableKeys);
    } else if (source === "otherFilter") {
      this.updateAvailable(availableKeys);
    }
    this.updateFilter(matchesFilter, nullMatchesFilter);
    this.updateExpandAll();
    this.flattenItems();
  }
  generateItemTree(getValue, allKeys, availableKeys) {
    var _a;
    this.allDisplayedItemsTree = [];
    this.groupsExist = false;
    const treeListPathGetter = this.getTreeListPathGetter(getValue, availableKeys);
    for (let key of allKeys) {
      const value = getValue(key);
      const dataPath = (_a = treeListPathGetter(value)) != null ? _a : [null];
      if (dataPath.length > 1) {
        this.groupsExist = true;
      }
      const available = availableKeys.has(key);
      let children = this.allDisplayedItemsTree;
      let item;
      let parentTreeKeys = [];
      dataPath.forEach((treeKey, depth) => {
        if (!children) {
          children = [];
          item.children = children;
        }
        item = children.find((child) => {
          var _a2;
          return ((_a2 = child.treeKey) == null ? void 0 : _a2.toUpperCase()) === (treeKey == null ? void 0 : treeKey.toUpperCase());
        });
        if (!item) {
          item = { treeKey, depth, filterPasses: true, expanded: false, available, parentTreeKeys };
          if (depth === dataPath.length - 1) {
            item.key = key;
          }
          children.push(item);
        }
        children = item.children;
        parentTreeKeys = [...parentTreeKeys, treeKey];
      });
    }
    this.updateAvailable(availableKeys);
    this.selectAllItem.children = this.allDisplayedItemsTree;
    this.selectAllItem.expanded = false;
  }
  getTreeListPathGetter(getValue, availableKeys) {
    if (this.treeListPathGetter) {
      return this.treeListPathGetter;
    }
    if (this.treeDataOrGrouping) {
      return (value) => value;
    }
    let isDate2 = false;
    for (const availableKey of availableKeys) {
      const value = getValue(availableKey);
      if (value instanceof Date) {
        isDate2 = true;
        break;
      } else if (value != null) {
        break;
      }
    }
    if (isDate2) {
      return _TreeSetDisplayValueModel2.DATE_TREE_LIST_PATH_GETTER;
    }
    _218.warnOnce("property treeList=true for Set Filter params, but you did not provide a treeListPathGetter or values of type Date.");
    return (value) => [String(value)];
  }
  flattenItems() {
    this.activeDisplayedItemsFlat = [];
    const recursivelyFlattenDisplayedItems = (items) => {
      items.forEach((item) => {
        if (!item.filterPasses || !item.available) {
          return;
        }
        this.activeDisplayedItemsFlat.push(item);
        if (item.children && item.expanded) {
          recursivelyFlattenDisplayedItems(item.children);
        }
      });
    };
    recursivelyFlattenDisplayedItems(this.allDisplayedItemsTree);
  }
  resetFilter() {
    const recursiveFilterReset = (item) => {
      if (item.children) {
        item.children.forEach((child) => {
          recursiveFilterReset(child);
        });
      }
      item.filterPasses = true;
    };
    this.allDisplayedItemsTree.forEach((item) => recursiveFilterReset(item));
  }
  updateFilter(matchesFilter, nullMatchesFilter) {
    const passesFilter = (item) => {
      if (!item.available) {
        return false;
      }
      if (item.treeKey == null) {
        return nullMatchesFilter;
      }
      return matchesFilter(
        this.formatter(this.treeListFormatter ? this.treeListFormatter(item.treeKey, item.depth, item.parentTreeKeys) : item.treeKey)
      );
    };
    this.allDisplayedItemsTree.forEach((item) => this.recursiveItemCheck(item, false, passesFilter, "filterPasses"));
  }
  getDisplayedValueCount() {
    return this.activeDisplayedItemsFlat.length;
  }
  getDisplayedItem(index) {
    return this.activeDisplayedItemsFlat[index];
  }
  getSelectAllItem() {
    return this.selectAllItem;
  }
  getAddSelectionToFilterItem() {
    return this.addSelectionToFilterItem;
  }
  getDisplayedKeys() {
    const displayedKeys = [];
    this.forEachDisplayedKey((key) => displayedKeys.push(key));
    return displayedKeys;
  }
  forEachDisplayedKey(func) {
    const recursiveForEachItem = (item, topParentExpanded) => {
      if (item.children) {
        if (!item.expanded || !topParentExpanded) {
          item.children.forEach((child) => {
            if (child.filterPasses) {
              recursiveForEachItem(child, false);
            }
          });
        }
      } else {
        func(item.key);
      }
    };
    this.activeDisplayedItemsFlat.forEach((item) => recursiveForEachItem(item, true));
  }
  someDisplayedKey(func) {
    const recursiveSomeItem = (item, topParentExpanded) => {
      if (item.children) {
        if (!item.expanded || !topParentExpanded) {
          return item.children.some((child) => {
            if (child.filterPasses) {
              return recursiveSomeItem(child, false);
            }
            return false;
          });
        }
      } else {
        return func(item.key);
      }
      return false;
    };
    return this.activeDisplayedItemsFlat.some((item) => recursiveSomeItem(item, true));
  }
  hasGroups() {
    return this.groupsExist;
  }
  refresh() {
    this.updateExpandAll();
    this.flattenItems();
  }
  updateExpandAll() {
    const recursiveExpansionCheck = (items, someTrue, someFalse) => {
      for (const item2 of items) {
        if (!item2.filterPasses || !item2.available || !item2.children) {
          continue;
        }
        someTrue = someTrue || !!item2.expanded;
        someFalse = someFalse || !item2.expanded;
        if (someTrue && someFalse) {
          return void 0;
        }
        const childExpanded = recursiveExpansionCheck(item2.children, someTrue, someFalse);
        if (childExpanded === void 0) {
          return void 0;
        } else if (childExpanded) {
          someTrue = true;
        } else {
          someFalse = true;
        }
      }
      return someTrue && someFalse ? void 0 : someTrue;
    };
    const item = this.getSelectAllItem();
    item.expanded = recursiveExpansionCheck(item.children, false, false);
  }
  recursiveItemCheck(item, parentPasses, checkFunction, itemProp) {
    let atLeastOneChildPassed = false;
    if (item.children) {
      item.children.forEach((child) => {
        const childPasses = this.recursiveItemCheck(child, parentPasses || checkFunction(item), checkFunction, itemProp);
        atLeastOneChildPassed = atLeastOneChildPassed || childPasses;
      });
    }
    const itemPasses = parentPasses || atLeastOneChildPassed || checkFunction(item);
    item[itemProp] = itemPasses;
    return itemPasses;
  }
  updateAvailable(availableKeys) {
    const isAvailable = (item) => availableKeys.has(item.key);
    this.allDisplayedItemsTree.forEach((item) => this.recursiveItemCheck(item, false, isAvailable, "available"));
  }
};
_TreeSetDisplayValueModel.DATE_TREE_LIST_PATH_GETTER = (date) => date ? [String(date.getFullYear()), String(date.getMonth() + 1), String(date.getDate())] : null;
var TreeSetDisplayValueModel = _TreeSetDisplayValueModel;
var SetValueModelFilteringKeys = class {
  constructor({ caseFormat }) {
    this.filteringKeys = null;
    this.filteringKeysCaseFormatted = null;
    this.hasNoAppliedFilteringKeys = false;
    this.caseFormat = caseFormat;
  }
  allFilteringKeys() {
    return this.filteringKeys;
  }
  allFilteringKeysCaseFormatted() {
    return this.filteringKeysCaseFormatted;
  }
  noAppliedFilteringKeys() {
    return this.hasNoAppliedFilteringKeys;
  }
  setFilteringKeys(filteringKeys) {
    this.filteringKeys = new Set(filteringKeys);
    this.hasNoAppliedFilteringKeys = !this.filteringKeys || this.filteringKeys.size === 0;
    this.filteringKeysCaseFormatted = /* @__PURE__ */ new Set();
    this.filteringKeys.forEach(
      (key) => this.filteringKeysCaseFormatted.add(this.caseFormat(key))
    );
  }
  addFilteringKey(key) {
    if (this.filteringKeys == null) {
      this.filteringKeys = /* @__PURE__ */ new Set();
      this.filteringKeysCaseFormatted = /* @__PURE__ */ new Set();
    }
    this.filteringKeys.add(key);
    this.filteringKeysCaseFormatted.add(this.caseFormat(key));
    if (this.hasNoAppliedFilteringKeys) {
      this.hasNoAppliedFilteringKeys = false;
    }
  }
  hasCaseFormattedFilteringKey(key) {
    return this.filteringKeysCaseFormatted.has(this.caseFormat(key));
  }
  hasFilteringKey(key) {
    return this.filteringKeys.has(key);
  }
  reset() {
    this.filteringKeys = null;
    this.filteringKeysCaseFormatted = null;
    this.hasNoAppliedFilteringKeys = false;
  }
};
var _SetValueModel = class _SetValueModel2 {
  constructor(params) {
    this.localEventService = new EventService2();
    this.miniFilterText = null;
    this.addCurrentSelectionToFilter = false;
    this.providedValues = null;
    this.allValues = /* @__PURE__ */ new Map();
    this.availableKeys = /* @__PURE__ */ new Set();
    this.selectedKeys = /* @__PURE__ */ new Set();
    this.initialised = false;
    const {
      usingComplexObjects,
      columnModel,
      valueService,
      treeDataTreeList,
      groupingTreeList,
      filterParams,
      gos,
      valueFormatter,
      addManagedListener
    } = params;
    const {
      column,
      colDef,
      textFormatter,
      doesRowPassOtherFilter,
      suppressSorting,
      comparator,
      rowModel,
      values,
      caseSensitive,
      convertValuesToStrings,
      treeList,
      treeListPathGetter,
      treeListFormatter
    } = filterParams;
    this.filterParams = filterParams;
    this.gos = gos;
    this.setIsLoading = params.setIsLoading;
    this.translate = params.translate;
    this.caseFormat = params.caseFormat;
    this.createKey = params.createKey;
    this.usingComplexObjects = !!params.usingComplexObjects;
    this.formatter = textFormatter || TextFilter.DEFAULT_FORMATTER;
    this.doesRowPassOtherFilters = doesRowPassOtherFilter;
    this.suppressSorting = suppressSorting || false;
    this.convertValuesToStrings = !!convertValuesToStrings;
    this.filteringKeys = new SetValueModelFilteringKeys({ caseFormat: this.caseFormat });
    const keyComparator = comparator != null ? comparator : colDef.comparator;
    const treeDataOrGrouping = !!treeDataTreeList || !!groupingTreeList;
    this.compareByValue = !!(usingComplexObjects && keyComparator || treeDataOrGrouping || treeList && !treeListPathGetter);
    if (treeDataOrGrouping && !keyComparator) {
      this.entryComparator = this.createTreeDataOrGroupingComparator();
    } else if (treeList && !treeListPathGetter && !keyComparator) {
      this.entryComparator = ([_aKey, aValue], [_bKey, bValue]) => _315.defaultComparator(aValue, bValue);
    } else {
      this.entryComparator = ([_aKey, aValue], [_bKey, bValue]) => keyComparator(aValue, bValue);
    }
    this.keyComparator = keyComparator != null ? keyComparator : _315.defaultComparator;
    this.caseSensitive = !!caseSensitive;
    const getDataPath = gos.get("getDataPath");
    const groupAllowUnbalanced = gos.get("groupAllowUnbalanced");
    if (rowModel.getType() === "clientSide") {
      this.clientSideValuesExtractor = new ClientSideValuesExtractor(
        rowModel,
        this.filterParams,
        this.createKey,
        this.caseFormat,
        columnModel,
        valueService,
        treeDataOrGrouping,
        !!treeDataTreeList,
        getDataPath,
        groupAllowUnbalanced,
        addManagedListener
      );
    }
    if (values == null) {
      this.valuesType = 2;
    } else {
      this.valuesType = Array.isArray(values) ? 0 : 1;
      this.providedValues = values;
    }
    this.displayValueModel = treeList ? new TreeSetDisplayValueModel(
      this.formatter,
      treeListPathGetter,
      treeListFormatter,
      treeDataTreeList || groupingTreeList
    ) : new FlatSetDisplayValueModel(
      valueService,
      valueFormatter,
      this.formatter,
      column
    );
    this.updateAllValues().then((updatedKeys) => this.resetSelectionState(updatedKeys || []));
  }
  addEventListener(eventType, listener, async) {
    this.localEventService.addEventListener(eventType, listener, async);
  }
  removeEventListener(eventType, listener, async) {
    this.localEventService.removeEventListener(eventType, listener, async);
  }
  updateOnParamsChange(filterParams) {
    return new AgPromise24((resolve) => {
      const {
        values,
        textFormatter,
        suppressSorting
      } = filterParams;
      const currentProvidedValues = this.providedValues;
      const currentSuppressSorting = this.suppressSorting;
      this.filterParams = filterParams;
      this.formatter = textFormatter || TextFilter.DEFAULT_FORMATTER;
      this.suppressSorting = suppressSorting || false;
      this.providedValues = values != null ? values : null;
      if (this.providedValues !== currentProvidedValues || this.suppressSorting !== currentSuppressSorting) {
        if (!values || values.length === 0) {
          this.valuesType = 2;
          this.providedValues = null;
        } else {
          this.valuesType = Array.isArray(values) ? 0 : 1;
        }
        const currentModel = this.getModel();
        this.updateAllValues().then((updatedKeys) => {
          this.setModel(currentModel).then(() => resolve());
        });
      } else {
        resolve();
      }
    });
  }
  /**
   * Re-fetches the values used in the filter from the value source.
   * If keepSelection is false, the filter selection will be reset to everything selected,
   * otherwise the current selection will be preserved.
   */
  refreshValues() {
    return new AgPromise24((resolve) => {
      this.allValuesPromise.then(() => {
        const currentModel = this.getModel();
        this.updateAllValues();
        this.setModel(currentModel).then(() => resolve());
      });
    });
  }
  /**
   * Overrides the current values being used for the set filter.
   * If keepSelection is false, the filter selection will be reset to everything selected,
   * otherwise the current selection will be preserved.
   */
  overrideValues(valuesToUse) {
    return new AgPromise24((resolve) => {
      this.allValuesPromise.then(() => {
        this.valuesType = 0;
        this.providedValues = valuesToUse;
        this.refreshValues().then(() => resolve());
      });
    });
  }
  /** @return has anything been updated */
  refreshAfterAnyFilterChanged() {
    if (this.showAvailableOnly()) {
      return this.allValuesPromise.then((keys) => {
        this.updateAvailableKeys(keys != null ? keys : [], "otherFilter");
        return true;
      });
    }
    return AgPromise24.resolve(false);
  }
  isInitialised() {
    return this.initialised;
  }
  updateAllValues() {
    this.allValuesPromise = new AgPromise24((resolve) => {
      switch (this.valuesType) {
        case 2:
          this.getValuesFromRowsAsync(false).then((values) => resolve(this.processAllValues(values)));
          break;
        case 0: {
          resolve(this.processAllValues(this.uniqueValues(this.validateProvidedValues(this.providedValues))));
          break;
        }
        case 1: {
          this.setIsLoading(true);
          const callback = this.providedValues;
          const { column, colDef } = this.filterParams;
          const params = this.gos.addGridCommonParams({
            success: (values) => {
              this.setIsLoading(false);
              resolve(this.processAllValues(this.uniqueValues(this.validateProvidedValues(values))));
            },
            colDef,
            column
          });
          window.setTimeout(() => callback(params), 0);
          break;
        }
        default:
          throw new Error("Unrecognised valuesType");
      }
    });
    this.allValuesPromise.then((values) => this.updateAvailableKeys(values || [], "reload")).then(() => this.initialised = true);
    return this.allValuesPromise;
  }
  processAllValues(values) {
    const sortedKeys = this.sortKeys(values);
    this.allValues = values != null ? values : /* @__PURE__ */ new Map();
    return sortedKeys;
  }
  validateProvidedValues(values) {
    if (this.usingComplexObjects && (values == null ? void 0 : values.length)) {
      const firstValue = values[0];
      if (firstValue && typeof firstValue !== "object" && typeof firstValue !== "function") {
        const firstKey = this.createKey(firstValue);
        if (firstKey == null) {
          _315.warnOnce("Set Filter Key Creator is returning null for provided values and provided values are primitives. Please provide complex objects or set convertValuesToStrings=true in the filterParams. See https://www.ag-grid.com/javascript-data-grid/filter-set-filter-list/#filter-value-types");
        } else {
          _315.warnOnce("Set Filter has a Key Creator, but provided values are primitives. Did you mean to provide complex objects or enable convertValuesToStrings?");
        }
      }
    }
    return values;
  }
  setValuesType(value) {
    this.valuesType = value;
  }
  getValuesType() {
    return this.valuesType;
  }
  isKeyAvailable(key) {
    return this.availableKeys.has(key);
  }
  showAvailableOnly() {
    return this.valuesType === 2;
  }
  updateAvailableKeys(allKeys, source) {
    const availableKeys = this.showAvailableOnly() ? this.sortKeys(this.getValuesFromRows(true)) : allKeys;
    this.availableKeys = new Set(availableKeys);
    this.localEventService.dispatchEvent({ type: _SetValueModel2.EVENT_AVAILABLE_VALUES_CHANGED });
    this.updateDisplayedValues(source, allKeys);
  }
  sortKeys(nullableValues) {
    const values = nullableValues != null ? nullableValues : /* @__PURE__ */ new Map();
    if (this.suppressSorting) {
      return Array.from(values.keys());
    }
    let sortedKeys;
    if (this.compareByValue) {
      sortedKeys = Array.from(values.entries()).sort(this.entryComparator).map(([key]) => key);
    } else {
      sortedKeys = Array.from(values.keys()).sort(this.keyComparator);
    }
    if (this.filterParams.excelMode && values.has(null)) {
      sortedKeys = sortedKeys.filter((v) => v != null);
      sortedKeys.push(null);
    }
    return sortedKeys;
  }
  getParamsForValuesFromRows(removeUnavailableValues = false) {
    if (!this.clientSideValuesExtractor) {
      _315.doOnce(() => {
        console.error("AG Grid: Set Filter cannot initialise because you are using a row model that does not contain all rows in the browser. Either use a different filter type, or configure Set Filter such that you provide it with values");
      }, "setFilterValueNotCSRM");
      return null;
    }
    const predicate = (node) => !removeUnavailableValues || this.doesRowPassOtherFilters(node);
    const existingValues = removeUnavailableValues && !this.caseSensitive ? this.allValues : void 0;
    return { predicate, existingValues };
  }
  getValuesFromRows(removeUnavailableValues = false) {
    const params = this.getParamsForValuesFromRows(removeUnavailableValues);
    if (!params) {
      return null;
    }
    return this.clientSideValuesExtractor.extractUniqueValues(params.predicate, params.existingValues);
  }
  getValuesFromRowsAsync(removeUnavailableValues = false) {
    const params = this.getParamsForValuesFromRows(removeUnavailableValues);
    if (!params) {
      return AgPromise24.resolve(null);
    }
    return this.clientSideValuesExtractor.extractUniqueValuesAsync(params.predicate, params.existingValues);
  }
  /** Sets mini filter value. Returns true if it changed from last value, otherwise false. */
  setMiniFilter(value) {
    value = _315.makeNull(value);
    if (this.miniFilterText === value) {
      return false;
    }
    if (value === null) {
      this.setAddCurrentSelectionToFilter(false);
    }
    this.miniFilterText = value;
    this.updateDisplayedValues("miniFilter");
    return true;
  }
  getMiniFilter() {
    return this.miniFilterText;
  }
  updateDisplayedValues(source, allKeys) {
    if (source === "expansion") {
      this.displayValueModel.refresh();
      return;
    }
    if (this.miniFilterText == null) {
      this.displayValueModel.updateDisplayedValuesToAllAvailable(
        (key) => this.getValue(key),
        allKeys,
        this.availableKeys,
        source
      );
      return;
    }
    const formattedFilterText = this.caseFormat(this.formatter(this.miniFilterText) || "");
    const matchesFilter = (valueToCheck) => valueToCheck != null && this.caseFormat(valueToCheck).indexOf(formattedFilterText) >= 0;
    const nullMatchesFilter = !!this.filterParams.excelMode && matchesFilter(this.translate("blanks"));
    this.displayValueModel.updateDisplayedValuesToMatchMiniFilter(
      (key) => this.getValue(key),
      allKeys,
      this.availableKeys,
      matchesFilter,
      nullMatchesFilter,
      source
    );
  }
  getDisplayedValueCount() {
    return this.displayValueModel.getDisplayedValueCount();
  }
  getDisplayedItem(index) {
    return this.displayValueModel.getDisplayedItem(index);
  }
  getSelectAllItem() {
    return this.displayValueModel.getSelectAllItem();
  }
  getAddSelectionToFilterItem() {
    return this.displayValueModel.getAddSelectionToFilterItem();
  }
  hasSelections() {
    return this.filterParams.defaultToNothingSelected ? this.selectedKeys.size > 0 : this.allValues.size !== this.selectedKeys.size;
  }
  getKeys() {
    return Array.from(this.allValues.keys());
  }
  getValues() {
    return Array.from(this.allValues.values());
  }
  getValue(key) {
    return this.allValues.get(key);
  }
  setAddCurrentSelectionToFilter(value) {
    this.addCurrentSelectionToFilter = value;
  }
  isInWindowsExcelMode() {
    return this.filterParams.excelMode === "windows";
  }
  isAddCurrentSelectionToFilterChecked() {
    return this.isInWindowsExcelMode() && this.addCurrentSelectionToFilter;
  }
  showAddCurrentSelectionToFilter() {
    return this.isInWindowsExcelMode() && _315.exists(this.miniFilterText) && this.miniFilterText.length > 0;
  }
  selectAllMatchingMiniFilter(clearExistingSelection = false) {
    if (this.miniFilterText == null) {
      this.selectedKeys = new Set(this.allValues.keys());
    } else {
      if (clearExistingSelection) {
        this.selectedKeys.clear();
      }
      this.displayValueModel.forEachDisplayedKey((key) => this.selectedKeys.add(key));
    }
  }
  deselectAllMatchingMiniFilter() {
    if (this.miniFilterText == null) {
      this.selectedKeys.clear();
    } else {
      this.displayValueModel.forEachDisplayedKey((key) => this.selectedKeys.delete(key));
    }
  }
  selectKey(key) {
    this.selectedKeys.add(key);
  }
  deselectKey(key) {
    if (this.filterParams.excelMode && this.isEverythingVisibleSelected()) {
      this.resetSelectionState(this.displayValueModel.getDisplayedKeys());
    }
    this.selectedKeys.delete(key);
  }
  isKeySelected(key) {
    return this.selectedKeys.has(key);
  }
  isEverythingVisibleSelected() {
    return !this.displayValueModel.someDisplayedKey((it) => !this.isKeySelected(it));
  }
  isNothingVisibleSelected() {
    return !this.displayValueModel.someDisplayedKey((it) => this.isKeySelected(it));
  }
  getModel() {
    if (!this.hasSelections()) {
      return null;
    }
    const filteringKeys = this.isAddCurrentSelectionToFilterChecked() ? this.filteringKeys.allFilteringKeys() : null;
    if (filteringKeys && filteringKeys.size > 0) {
      if (this.selectedKeys) {
        const modelKeys = /* @__PURE__ */ new Set([
          ...Array.from(filteringKeys),
          ...Array.from(this.selectedKeys).filter((key) => !filteringKeys.has(key))
        ]);
        return Array.from(modelKeys);
      }
      return Array.from(filteringKeys);
    }
    return Array.from(this.selectedKeys);
  }
  setModel(model) {
    return this.allValuesPromise.then((keys) => {
      if (model == null) {
        this.resetSelectionState(keys != null ? keys : []);
      } else {
        this.selectedKeys.clear();
        const existingFormattedKeys = /* @__PURE__ */ new Map();
        this.allValues.forEach((_value, key) => {
          existingFormattedKeys.set(this.caseFormat(key), key);
        });
        model.forEach((unformattedKey) => {
          const formattedKey = this.caseFormat(_315.makeNull(unformattedKey));
          const existingUnformattedKey = existingFormattedKeys.get(formattedKey);
          if (existingUnformattedKey !== void 0) {
            this.selectKey(existingUnformattedKey);
          }
        });
      }
    });
  }
  uniqueValues(values) {
    const uniqueValues = /* @__PURE__ */ new Map();
    const formattedKeys = /* @__PURE__ */ new Set();
    (values != null ? values : []).forEach((value) => {
      const valueToUse = _315.makeNull(value);
      const unformattedKey = this.convertAndGetKey(valueToUse);
      const formattedKey = this.caseFormat(unformattedKey);
      if (!formattedKeys.has(formattedKey)) {
        formattedKeys.add(formattedKey);
        uniqueValues.set(unformattedKey, valueToUse);
      }
    });
    return uniqueValues;
  }
  convertAndGetKey(value) {
    return this.convertValuesToStrings ? value : this.createKey(value);
  }
  resetSelectionState(keys) {
    if (this.filterParams.defaultToNothingSelected) {
      this.selectedKeys.clear();
    } else {
      this.selectedKeys = new Set(keys);
    }
  }
  hasGroups() {
    return this.displayValueModel.hasGroups();
  }
  createTreeDataOrGroupingComparator() {
    return ([_aKey, aValue], [_bKey, bValue]) => {
      if (aValue == null) {
        return bValue == null ? 0 : -1;
      } else if (bValue == null) {
        return 1;
      }
      for (let i = 0; i < aValue.length; i++) {
        if (i >= bValue.length) {
          return 1;
        }
        const diff = _315.defaultComparator(aValue[i], bValue[i]);
        if (diff !== 0) {
          return diff;
        }
      }
      return 0;
    };
  }
  setAppliedModelKeys(appliedModelKeys) {
    this.filteringKeys.setFilteringKeys(appliedModelKeys);
  }
  addToAppliedModelKeys(appliedModelKey) {
    this.filteringKeys.addFilteringKey(appliedModelKey);
  }
  getAppliedModelKeys() {
    return this.filteringKeys.allFilteringKeys();
  }
  getCaseFormattedAppliedModelKeys() {
    return this.filteringKeys.allFilteringKeysCaseFormatted();
  }
  hasAppliedModelKey(appliedModelKey) {
    return this.filteringKeys.hasCaseFormattedFilteringKey(appliedModelKey);
  }
  hasAnyAppliedModelKey() {
    return !this.filteringKeys.noAppliedFilteringKeys();
  }
};
_SetValueModel.EVENT_AVAILABLE_VALUES_CHANGED = "availableValuesChanged";
var SetValueModel = _SetValueModel;
var _SetFilterListItem = class _SetFilterListItem2 extends Component57 {
  constructor(params) {
    var _a;
    super(params.isGroup ? _SetFilterListItem2.GROUP_TEMPLATE : _SetFilterListItem2.TEMPLATE);
    this.focusWrapper = params.focusWrapper;
    this.value = params.value;
    this.params = params.params;
    this.translate = params.translate;
    this.valueFormatter = params.valueFormatter;
    this.item = params.item;
    this.isSelected = params.isSelected;
    this.isTree = params.isTree;
    this.depth = (_a = params.depth) != null ? _a : 0;
    this.isGroup = params.isGroup;
    this.groupsExist = params.groupsExist;
    this.isExpanded = params.isExpanded;
    this.hasIndeterminateExpandState = params.hasIndeterminateExpandState;
  }
  init() {
    this.addDestroyFunc(() => {
      var _a;
      return (_a = this.destroyCellRendererComponent) == null ? void 0 : _a.call(this);
    });
    this.render();
    this.eCheckbox.setLabelEllipsis(true).setValue(this.isSelected, true).setDisabled(!!this.params.readOnly).getInputElement().setAttribute("tabindex", "-1");
    this.refreshVariableAriaLabels();
    if (this.isTree) {
      if (this.depth > 0) {
        this.addCssClass("ag-set-filter-indent-" + this.depth);
      }
      if (this.isGroup) {
        this.setupExpansion();
      } else {
        if (this.groupsExist) {
          this.addCssClass("ag-set-filter-add-group-indent");
        }
      }
      _412.setAriaLevel(this.getAriaElement(), this.depth + 1);
    }
    this.refreshAriaChecked();
    if (!!this.params.readOnly) {
      return;
    }
    this.eCheckbox.onValueChange((value) => this.onCheckboxChanged(!!value));
  }
  getFocusableElement() {
    return this.focusWrapper;
  }
  setupExpansion() {
    this.eGroupClosedIcon.appendChild(_412.createIcon("setFilterGroupClosed", this.gos, null));
    this.eGroupOpenedIcon.appendChild(_412.createIcon("setFilterGroupOpen", this.gos, null));
    this.addManagedListener(this.eGroupClosedIcon, "click", this.onExpandOrContractClicked.bind(this));
    this.addManagedListener(this.eGroupOpenedIcon, "click", this.onExpandOrContractClicked.bind(this));
    if (this.hasIndeterminateExpandState) {
      this.eGroupIndeterminateIcon.appendChild(_412.createIcon("setFilterGroupIndeterminate", this.gos, null));
      this.addManagedListener(this.eGroupIndeterminateIcon, "click", this.onExpandOrContractClicked.bind(this));
    }
    this.setExpandedIcons();
    this.refreshAriaExpanded();
  }
  onExpandOrContractClicked() {
    this.setExpanded(!this.isExpanded);
  }
  setExpanded(isExpanded, silent) {
    if (this.isGroup && isExpanded !== this.isExpanded) {
      this.isExpanded = isExpanded;
      const event = {
        type: _SetFilterListItem2.EVENT_EXPANDED_CHANGED,
        isExpanded: !!isExpanded,
        item: this.item
      };
      if (!silent) {
        this.dispatchEvent(event);
      }
      this.setExpandedIcons();
      this.refreshAriaExpanded();
    }
  }
  setExpandedIcons() {
    _412.setDisplayed(this.eGroupClosedIcon, this.hasIndeterminateExpandState ? this.isExpanded === false : !this.isExpanded);
    _412.setDisplayed(this.eGroupOpenedIcon, this.isExpanded === true);
    if (this.hasIndeterminateExpandState) {
      _412.setDisplayed(this.eGroupIndeterminateIcon, this.isExpanded === void 0);
    }
  }
  onCheckboxChanged(isSelected) {
    this.isSelected = isSelected;
    const event = {
      type: _SetFilterListItem2.EVENT_SELECTION_CHANGED,
      isSelected,
      item: this.item
    };
    this.dispatchEvent(event);
    this.refreshVariableAriaLabels();
    this.refreshAriaChecked();
  }
  toggleSelected() {
    if (!!this.params.readOnly) {
      return;
    }
    this.setSelected(!this.isSelected);
  }
  setSelected(isSelected, silent) {
    this.isSelected = isSelected;
    this.eCheckbox.setValue(isSelected, silent);
    this.refreshAriaChecked();
  }
  refreshVariableAriaLabels() {
    if (!this.isTree) {
      return;
    }
    const translate = this.localeService.getLocaleTextFunc();
    const checkboxValue = this.eCheckbox.getValue();
    const state = checkboxValue === void 0 ? translate("ariaIndeterminate", "indeterminate") : checkboxValue ? translate("ariaVisible", "visible") : translate("ariaHidden", "hidden");
    const visibilityLabel = translate("ariaToggleVisibility", "Press SPACE to toggle visibility");
    _412.setAriaLabelledBy(this.eCheckbox.getInputElement(), void 0);
    this.eCheckbox.setInputAriaLabel(`${visibilityLabel} (${state})`);
  }
  setupFixedAriaLabels(value) {
    if (!this.isTree) {
      return;
    }
    const translate = this.localeService.getLocaleTextFunc();
    const itemLabel = translate("ariaFilterValue", "Filter Value");
    const ariaEl = this.getAriaElement();
    _412.setAriaLabel(ariaEl, `${value} ${itemLabel}`);
    _412.setAriaDescribedBy(ariaEl, this.eCheckbox.getInputElement().id);
  }
  refreshAriaChecked() {
    const ariaEl = this.getAriaElement();
    _412.setAriaChecked(ariaEl, this.eCheckbox.getValue());
  }
  refreshAriaExpanded() {
    _412.setAriaExpanded(this.getAriaElement(), !!this.isExpanded);
  }
  refresh(item, isSelected, isExpanded) {
    var _a, _b;
    this.item = item;
    if (isSelected !== this.isSelected) {
      this.setSelected(isSelected, true);
    }
    this.setExpanded(isExpanded, true);
    if (this.valueFunction) {
      const value = this.valueFunction();
      this.setTooltipAndCellRendererParams(value, value);
      if (!this.cellRendererComponent) {
        this.renderCellWithoutCellRenderer();
      }
    }
    if (this.cellRendererComponent) {
      const success = (_b = (_a = this.cellRendererComponent).refresh) == null ? void 0 : _b.call(_a, this.cellRendererParams);
      if (!success) {
        const oldComponent = this.cellRendererComponent;
        this.renderCell();
        this.destroyBean(oldComponent);
      }
    }
  }
  render() {
    const { params: { column } } = this;
    let { value } = this;
    let formattedValue = null;
    if (typeof value === "function") {
      this.valueFunction = value;
      formattedValue = this.valueFunction();
      value = formattedValue;
    } else if (this.isTree) {
      formattedValue = _412.toStringOrNull(value);
    } else {
      formattedValue = this.getFormattedValue(column, value);
    }
    this.setTooltipAndCellRendererParams(value, formattedValue);
    this.renderCell();
  }
  setTooltipAndCellRendererParams(value, formattedValue) {
    const isTooltipWhenTruncated = this.gos.get("tooltipShowMode") === "whenTruncated";
    if (this.params.showTooltips && (!isTooltipWhenTruncated || !this.params.cellRenderer)) {
      const newTooltipText = formattedValue != null ? formattedValue : _412.toStringOrNull(value);
      let shouldDisplayTooltip;
      if (isTooltipWhenTruncated) {
        shouldDisplayTooltip = () => {
          const el = this.eCheckbox.getGui().querySelector(".ag-label");
          if (!el) {
            return true;
          }
          return el.scrollWidth > el.clientWidth;
        };
      }
      this.setTooltip({
        newTooltipText,
        location: "setFilterValue",
        getColDef: () => this.params.colDef,
        getColumn: () => this.params.column,
        shouldDisplayTooltip
      });
    }
    this.cellRendererParams = this.gos.addGridCommonParams({
      value,
      valueFormatted: formattedValue,
      colDef: this.params.colDef,
      column: this.params.column,
      setTooltip: (value2, shouldDisplayTooltip) => {
        this.setTooltip({
          newTooltipText: value2,
          getColDef: () => this.params.colDef,
          getColumn: () => this.params.column,
          location: "setFilterValue",
          shouldDisplayTooltip
        });
      }
    });
  }
  getTooltipParams() {
    const res = super.getTooltipParams();
    res.location = "setFilterValue";
    res.colDef = this.getComponentHolder();
    if (this.isTree) {
      res.level = this.depth;
    }
    return res;
  }
  getFormattedValue(column, value) {
    return this.valueService.formatValue(column, null, value, this.valueFormatter, false);
  }
  renderCell() {
    const compDetails = this.userComponentFactory.getSetFilterCellRendererDetails(this.params, this.cellRendererParams);
    const cellRendererPromise = compDetails ? compDetails.newAgStackInstance() : void 0;
    if (cellRendererPromise == null) {
      this.renderCellWithoutCellRenderer();
      return;
    }
    cellRendererPromise.then((component) => {
      if (component) {
        this.cellRendererComponent = component;
        this.eCheckbox.setLabel(component.getGui());
        this.destroyCellRendererComponent = () => this.destroyBean(component);
      }
    });
  }
  renderCellWithoutCellRenderer() {
    var _a;
    let valueToRender = (_a = this.cellRendererParams.valueFormatted == null ? this.cellRendererParams.value : this.cellRendererParams.valueFormatted) != null ? _a : this.translate("blanks");
    if (typeof valueToRender !== "string") {
      _412.warnOnce(`Set Filter Value Formatter must return string values. Please ensure the Set Filter Value Formatter returns string values for complex objects, or set convertValuesToStrings=true in the filterParams. See ${this.getFrameworkOverrides().getDocLink("filter-set-filter-list/#filter-value-types")}`);
      valueToRender = "";
    }
    this.eCheckbox.setLabel(valueToRender);
    this.setupFixedAriaLabels(valueToRender);
  }
  getComponentHolder() {
    return this.params.column.getColDef();
  }
};
_SetFilterListItem.EVENT_SELECTION_CHANGED = "selectionChanged";
_SetFilterListItem.EVENT_EXPANDED_CHANGED = "expandedChanged";
_SetFilterListItem.GROUP_TEMPLATE = /* html */
`
        <div class="ag-set-filter-item" aria-hidden="true">
            <span class="ag-set-filter-group-icons">
                <span class="ag-set-filter-group-closed-icon" ref="eGroupClosedIcon"></span>
                <span class="ag-set-filter-group-opened-icon" ref="eGroupOpenedIcon"></span>
                <span class="ag-set-filter-group-indeterminate-icon" ref="eGroupIndeterminateIcon"></span>
            </span>
            <ag-checkbox ref="eCheckbox" class="ag-set-filter-item-checkbox"></ag-checkbox>
        </div>`;
_SetFilterListItem.TEMPLATE = /* html */
`
        <div class="ag-set-filter-item">
            <ag-checkbox ref="eCheckbox" class="ag-set-filter-item-checkbox"></ag-checkbox>
        </div>`;
__decorateClass15([
  Autowired68("valueService")
], _SetFilterListItem.prototype, "valueService", 2);
__decorateClass15([
  Autowired68("userComponentFactory")
], _SetFilterListItem.prototype, "userComponentFactory", 2);
__decorateClass15([
  RefSelector21("eCheckbox")
], _SetFilterListItem.prototype, "eCheckbox", 2);
__decorateClass15([
  RefSelector21("eGroupOpenedIcon")
], _SetFilterListItem.prototype, "eGroupOpenedIcon", 2);
__decorateClass15([
  RefSelector21("eGroupClosedIcon")
], _SetFilterListItem.prototype, "eGroupClosedIcon", 2);
__decorateClass15([
  RefSelector21("eGroupIndeterminateIcon")
], _SetFilterListItem.prototype, "eGroupIndeterminateIcon", 2);
__decorateClass15([
  PostConstruct65
], _SetFilterListItem.prototype, "init", 1);
var SetFilterListItem = _SetFilterListItem;
var DEFAULT_LOCALE_TEXT = {
  loadingOoo: "Loading...",
  blanks: "(Blanks)",
  searchOoo: "Search...",
  selectAll: "(Select All)",
  selectAllSearchResults: "(Select All Search Results)",
  addCurrentSelectionToFilter: "Add current selection to filter",
  noMatches: "No matches."
};
var SetFilterModelFormatter = class {
  getModelAsString(model, setFilter) {
    const { values } = model || setFilter.getModel() || {};
    const valueModel = setFilter.getValueModel();
    if (values == null || valueModel == null) {
      return "";
    }
    const availableKeys = values.filter((v) => valueModel.isKeyAvailable(v));
    const numValues = availableKeys.length;
    const formattedValues = availableKeys.slice(0, 10).map((key) => setFilter.getFormattedValue(key));
    return `(${numValues}) ${formattedValues.join(",")}${numValues > 10 ? ",..." : ""}`;
  }
};
var SetFilter = class extends ProvidedFilter2 {
  constructor() {
    super("setFilter");
    this.valueModel = null;
    this.setFilterParams = null;
    this.virtualList = null;
    this.caseSensitive = false;
    this.convertValuesToStrings = false;
    this.treeDataTreeList = false;
    this.groupingTreeList = false;
    this.hardRefreshVirtualList = false;
    this.noValueFormatterSupplied = false;
    this.filterModelFormatter = new SetFilterModelFormatter();
    this.updateSetFilterOnParamsChange = (newParams) => {
      var _a;
      this.setFilterParams = newParams;
      this.convertValuesToStrings = !!newParams.convertValuesToStrings;
      this.caseSensitive = !!newParams.caseSensitive;
      const keyCreator = (_a = newParams.keyCreator) != null ? _a : newParams.colDef.keyCreator;
      this.setValueFormatter(newParams.valueFormatter, keyCreator, this.convertValuesToStrings, !!newParams.treeList, !!newParams.colDef.refData);
      const isGroupCol = newParams.column.getId().startsWith(GROUP_AUTO_COLUMN_ID2);
      this.treeDataTreeList = this.gos.get("treeData") && !!newParams.treeList && isGroupCol;
      this.getDataPath = this.gos.get("getDataPath");
      this.groupingTreeList = !!this.columnModel.getRowGroupColumns().length && !!newParams.treeList && isGroupCol;
      this.createKey = this.generateCreateKey(keyCreator, this.convertValuesToStrings, this.treeDataTreeList || this.groupingTreeList);
    };
  }
  postConstruct() {
    super.postConstruct();
  }
  // unlike the simple filters, nothing in the set filter UI shows/hides.
  // maybe this method belongs in abstractSimpleFilter???
  updateUiVisibility() {
  }
  createBodyTemplate() {
    return (
      /* html */
      `
            <div class="ag-set-filter">
                <div ref="eFilterLoading" class="ag-filter-loading ag-hidden">${this.translateForSetFilter("loadingOoo")}</div>
                <ag-input-text-field class="ag-mini-filter" ref="eMiniFilter"></ag-input-text-field>
                <div ref="eFilterNoMatches" class="ag-filter-no-matches ag-hidden">${this.translateForSetFilter("noMatches")}</div>
                <div ref="eSetFilterList" class="ag-set-filter-list" role="presentation"></div>
            </div>`
    );
  }
  handleKeyDown(e) {
    super.handleKeyDown(e);
    if (e.defaultPrevented) {
      return;
    }
    switch (e.key) {
      case KeyCode11.SPACE:
        this.handleKeySpace(e);
        break;
      case KeyCode11.ENTER:
        this.handleKeyEnter(e);
        break;
      case KeyCode11.LEFT:
        this.handleKeyLeft(e);
        break;
      case KeyCode11.RIGHT:
        this.handleKeyRight(e);
        break;
    }
  }
  handleKeySpace(e) {
    var _a;
    (_a = this.getComponentForKeyEvent(e)) == null ? void 0 : _a.toggleSelected();
  }
  handleKeyEnter(e) {
    if (!this.setFilterParams) {
      return;
    }
    const { excelMode, readOnly } = this.setFilterParams || {};
    if (!excelMode || !!readOnly) {
      return;
    }
    e.preventDefault();
    this.onBtApply(false, false, e);
    if (this.setFilterParams.excelMode === "mac") {
      this.eMiniFilter.getInputElement().select();
    }
  }
  handleKeyLeft(e) {
    var _a;
    (_a = this.getComponentForKeyEvent(e)) == null ? void 0 : _a.setExpanded(false);
  }
  handleKeyRight(e) {
    var _a;
    (_a = this.getComponentForKeyEvent(e)) == null ? void 0 : _a.setExpanded(true);
  }
  getComponentForKeyEvent(e) {
    var _a;
    if (!this.eSetFilterList.contains(this.gos.getActiveDomElement()) || !this.virtualList) {
      return;
    }
    const currentItem = this.virtualList.getLastFocusedRow();
    if (currentItem == null) {
      return;
    }
    const component = this.virtualList.getComponentAt(currentItem);
    if (component == null) {
      return;
    }
    e.preventDefault();
    const { readOnly } = (_a = this.setFilterParams) != null ? _a : {};
    if (!!readOnly) {
      return;
    }
    return component;
  }
  getCssIdentifier() {
    return "set-filter";
  }
  setModel(model) {
    var _a;
    if (model == null && ((_a = this.valueModel) == null ? void 0 : _a.getModel()) == null) {
      this.setMiniFilter(null);
      return AgPromise32.resolve();
    }
    return super.setModel(model);
  }
  refresh(params) {
    var _a, _b, _c;
    if (!super.refresh(params)) {
      return false;
    }
    const paramsThatForceReload = [
      "treeList",
      "treeListFormatter",
      "treeListPathGetter",
      "keyCreator",
      "convertValuesToStrings",
      "caseSensitive",
      "comparator",
      "suppressSelectAll",
      "excelMode"
    ];
    if (paramsThatForceReload.some((param) => {
      var _a2;
      return params[param] !== ((_a2 = this.setFilterParams) == null ? void 0 : _a2[param]);
    })) {
      return false;
    }
    if (this.haveColDefParamsChanged(params.colDef)) {
      return false;
    }
    super.updateParams(params);
    this.updateSetFilterOnParamsChange(params);
    this.updateMiniFilter();
    if (params.cellRenderer !== ((_a = this.setFilterParams) == null ? void 0 : _a.cellRenderer) || params.valueFormatter !== ((_b = this.setFilterParams) == null ? void 0 : _b.valueFormatter)) {
      this.checkAndRefreshVirtualList();
    }
    (_c = this.valueModel) == null ? void 0 : _c.updateOnParamsChange(params).then(() => {
      var _a2;
      if ((_a2 = this.valueModel) == null ? void 0 : _a2.hasSelections()) {
        this.refreshFilterValues();
      }
    });
    return true;
  }
  haveColDefParamsChanged(colDef) {
    var _a;
    const paramsThatForceReload = [
      "keyCreator",
      "filterValueGetter"
    ];
    const existingColDef = (_a = this.setFilterParams) == null ? void 0 : _a.colDef;
    return paramsThatForceReload.some((param) => colDef[param] !== (existingColDef == null ? void 0 : existingColDef[param]));
  }
  setModelAndRefresh(values) {
    return this.valueModel ? this.valueModel.setModel(values).then(() => this.checkAndRefreshVirtualList()) : AgPromise32.resolve();
  }
  resetUiToDefaults() {
    this.setMiniFilter(null);
    return this.setModelAndRefresh(null);
  }
  setModelIntoUi(model) {
    this.setMiniFilter(null);
    const values = model == null ? null : model.values;
    return this.setModelAndRefresh(values);
  }
  getModelFromUi() {
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    const values = this.valueModel.getModel();
    if (!values) {
      return null;
    }
    return { values, filterType: this.getFilterType() };
  }
  getFilterType() {
    return "set";
  }
  getValueModel() {
    return this.valueModel;
  }
  areModelsEqual(a, b) {
    if (a == null && b == null) {
      return true;
    }
    return a != null && b != null && _59.areEqual(a.values, b.values);
  }
  setParams(params) {
    var _a;
    this.applyExcelModeOptions(params);
    super.setParams(params);
    this.updateSetFilterOnParamsChange(params);
    const keyCreator = (_a = params.keyCreator) != null ? _a : params.colDef.keyCreator;
    this.valueModel = new SetValueModel({
      filterParams: params,
      setIsLoading: (loading) => this.setIsLoading(loading),
      translate: (key) => this.translateForSetFilter(key),
      caseFormat: (v) => this.caseFormat(v),
      createKey: this.createKey,
      valueFormatter: this.valueFormatter,
      usingComplexObjects: !!keyCreator,
      gos: this.gos,
      columnModel: this.columnModel,
      valueService: this.valueService,
      treeDataTreeList: this.treeDataTreeList,
      groupingTreeList: this.groupingTreeList,
      addManagedListener: (event, listener) => this.addManagedListener(this.eventService, event, listener)
    });
    this.initialiseFilterBodyUi();
    this.addEventListenersForDataChanges();
  }
  onAddCurrentSelectionToFilterChange(newValue) {
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    this.valueModel.setAddCurrentSelectionToFilter(newValue);
  }
  setValueFormatter(providedValueFormatter, keyCreator, convertValuesToStrings, treeList, isRefData) {
    let valueFormatter = providedValueFormatter;
    if (!valueFormatter) {
      if (keyCreator && !convertValuesToStrings && !treeList) {
        throw new Error("AG Grid: Must supply a Value Formatter in Set Filter params when using a Key Creator unless convertValuesToStrings is enabled");
      }
      this.noValueFormatterSupplied = true;
      if (!isRefData) {
        valueFormatter = (params) => _59.toStringOrNull(params.value);
      }
    }
    this.valueFormatter = valueFormatter;
  }
  generateCreateKey(keyCreator, convertValuesToStrings, treeDataOrGrouping) {
    if (treeDataOrGrouping && !keyCreator) {
      throw new Error("AG Grid: Must supply a Key Creator in Set Filter params when `treeList = true` on a group column, and Tree Data or Row Grouping is enabled.");
    }
    if (keyCreator) {
      return (value, node = null) => {
        const params = this.getKeyCreatorParams(value, node);
        return _59.makeNull(keyCreator(params));
      };
    }
    if (convertValuesToStrings) {
      return (value) => Array.isArray(value) ? value : _59.makeNull(_59.toStringOrNull(value));
    } else {
      return (value) => _59.makeNull(_59.toStringOrNull(value));
    }
  }
  getFormattedValue(key) {
    var _a;
    let value = this.valueModel.getValue(key);
    if (this.noValueFormatterSupplied && (this.treeDataTreeList || this.groupingTreeList) && Array.isArray(value)) {
      value = _59.last(value);
    }
    const formattedValue = this.valueService.formatValue(
      this.setFilterParams.column,
      null,
      value,
      this.valueFormatter,
      false
    );
    return (_a = formattedValue == null ? _59.toStringOrNull(value) : formattedValue) != null ? _a : this.translateForSetFilter("blanks");
  }
  applyExcelModeOptions(params) {
    if (params.excelMode === "windows") {
      if (!params.buttons) {
        params.buttons = ["apply", "cancel"];
      }
      if (params.closeOnApply == null) {
        params.closeOnApply = true;
      }
    } else if (params.excelMode === "mac") {
      if (!params.buttons) {
        params.buttons = ["reset"];
      }
      if (params.applyMiniFilterWhileTyping == null) {
        params.applyMiniFilterWhileTyping = true;
      }
      if (params.debounceMs == null) {
        params.debounceMs = 500;
      }
    }
    if (params.excelMode && params.defaultToNothingSelected) {
      params.defaultToNothingSelected = false;
      _59.warnOnce('The Set Filter Parameter "defaultToNothingSelected" value was ignored because it does not work when "excelMode" is used.');
    }
  }
  addEventListenersForDataChanges() {
    if (!this.isValuesTakenFromGrid()) {
      return;
    }
    this.addManagedListener(
      this.eventService,
      Events210.EVENT_CELL_VALUE_CHANGED,
      (event) => {
        if (this.setFilterParams && event.column === this.setFilterParams.column) {
          this.syncAfterDataChange();
        }
      }
    );
    this.addManagedPropertyListeners(["treeData", "getDataPath", "groupAllowUnbalanced"], () => {
      this.syncAfterDataChange();
    });
  }
  syncAfterDataChange() {
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    let promise = this.valueModel.refreshValues();
    return promise.then(() => {
      this.checkAndRefreshVirtualList();
      this.onBtApply(false, true);
    });
  }
  setIsLoading(isLoading) {
    _59.setDisplayed(this.eFilterLoading, isLoading);
    if (!isLoading) {
      this.hardRefreshVirtualList = true;
    }
  }
  initialiseFilterBodyUi() {
    this.initVirtualList();
    this.initMiniFilter();
  }
  initVirtualList() {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    const translate = this.localeService.getLocaleTextFunc();
    const filterListName = translate("ariaFilterList", "Filter List");
    const isTree = !!this.setFilterParams.treeList;
    const virtualList = this.virtualList = this.createBean(new VirtualList4({
      cssIdentifier: "filter",
      ariaRole: isTree ? "tree" : "listbox",
      listName: filterListName
    }));
    const eSetFilterList = this.getRefElement("eSetFilterList");
    if (isTree) {
      eSetFilterList.classList.add("ag-set-filter-tree-list");
    }
    if (eSetFilterList) {
      eSetFilterList.appendChild(virtualList.getGui());
    }
    const { cellHeight } = this.setFilterParams;
    if (cellHeight != null) {
      virtualList.setRowHeight(cellHeight);
    }
    const componentCreator = (item, listItemElement) => this.createSetListItem(item, isTree, listItemElement);
    virtualList.setComponentCreator(componentCreator);
    const componentUpdater = (item, component) => this.updateSetListItem(item, component);
    virtualList.setComponentUpdater(componentUpdater);
    let model;
    if (this.setFilterParams.suppressSelectAll) {
      model = new ModelWrapper(this.valueModel);
    } else {
      model = new ModelWrapperWithSelectAll(this.valueModel, () => this.isSelectAllSelected());
    }
    if (isTree) {
      model = new TreeModelWrapper(model);
    }
    virtualList.setModel(model);
  }
  getSelectAllLabel() {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    const key = this.valueModel.getMiniFilter() == null || !this.setFilterParams.excelMode ? "selectAll" : "selectAllSearchResults";
    return this.translateForSetFilter(key);
  }
  getAddSelectionToFilterLabel() {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    return this.translateForSetFilter("addCurrentSelectionToFilter");
  }
  createSetListItem(item, isTree, focusWrapper) {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    const groupsExist = this.valueModel.hasGroups();
    const { isSelected, isExpanded } = this.isSelectedExpanded(item);
    const {
      value,
      depth,
      isGroup,
      hasIndeterminateExpandState,
      selectedListener,
      expandedListener
    } = this.newSetListItemAttributes(item, isTree);
    const itemParams = {
      focusWrapper,
      value,
      params: this.setFilterParams,
      translate: (translateKey) => this.translateForSetFilter(translateKey),
      valueFormatter: this.valueFormatter,
      item,
      isSelected,
      isTree,
      depth,
      groupsExist,
      isGroup,
      isExpanded,
      hasIndeterminateExpandState
    };
    const listItem = this.createBean(new SetFilterListItem(itemParams));
    listItem.addEventListener(SetFilterListItem.EVENT_SELECTION_CHANGED, selectedListener);
    if (expandedListener) {
      listItem.addEventListener(SetFilterListItem.EVENT_EXPANDED_CHANGED, expandedListener);
    }
    return listItem;
  }
  newSetTreeItemAttributes(item, isTree) {
    var _a, _b, _c, _d, _e, _f;
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    const groupsExist = this.valueModel.hasGroups();
    if (item.key === SetFilterDisplayValue.SELECT_ALL) {
      return {
        value: () => this.getSelectAllLabel(),
        isGroup: groupsExist,
        depth: item.depth,
        hasIndeterminateExpandState: true,
        selectedListener: (e) => this.onSelectAll(e.isSelected),
        expandedListener: (e) => this.onExpandAll(e.item, e.isExpanded)
      };
    }
    if (item.key === SetFilterDisplayValue.ADD_SELECTION_TO_FILTER) {
      return {
        value: () => this.getAddSelectionToFilterLabel(),
        depth: item.depth,
        isGroup: false,
        hasIndeterminateExpandState: false,
        selectedListener: (e) => {
          this.onAddCurrentSelectionToFilterChange(e.isSelected);
        }
      };
    }
    if (item.children) {
      return {
        value: (_c = (_b = (_a = this.setFilterParams).treeListFormatter) == null ? void 0 : _b.call(_a, item.treeKey, item.depth, item.parentTreeKeys)) != null ? _c : item.treeKey,
        depth: item.depth,
        isGroup: true,
        selectedListener: (e) => this.onGroupItemSelected(e.item, e.isSelected),
        expandedListener: (e) => this.onExpandedChanged(e.item, e.isExpanded)
      };
    }
    return {
      value: (_f = (_e = (_d = this.setFilterParams).treeListFormatter) == null ? void 0 : _e.call(_d, item.treeKey, item.depth, item.parentTreeKeys)) != null ? _f : item.treeKey,
      depth: item.depth,
      selectedListener: (e) => this.onItemSelected(e.item.key, e.isSelected)
    };
  }
  newSetListItemAttributes(item, isTree) {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    if (this.isSetFilterModelTreeItem(item)) {
      return this.newSetTreeItemAttributes(item, isTree);
    }
    if (item === SetFilterDisplayValue.SELECT_ALL) {
      return {
        value: () => this.getSelectAllLabel(),
        selectedListener: (e) => this.onSelectAll(e.isSelected)
      };
    }
    if (item === SetFilterDisplayValue.ADD_SELECTION_TO_FILTER) {
      return {
        value: () => this.getAddSelectionToFilterLabel(),
        selectedListener: (e) => {
          this.onAddCurrentSelectionToFilterChange(e.isSelected);
        }
      };
    }
    return {
      value: this.valueModel.getValue(item),
      selectedListener: (e) => this.onItemSelected(e.item, e.isSelected)
    };
  }
  updateSetListItem(item, component) {
    const { isSelected, isExpanded } = this.isSelectedExpanded(item);
    component.refresh(item, isSelected, isExpanded);
  }
  isSelectedExpanded(item) {
    let isSelected;
    let isExpanded;
    if (this.isSetFilterModelTreeItem(item)) {
      isExpanded = item.expanded;
      if (item.key === SetFilterDisplayValue.SELECT_ALL) {
        isSelected = this.isSelectAllSelected();
      } else if (item.key === SetFilterDisplayValue.ADD_SELECTION_TO_FILTER) {
        isSelected = this.valueModel.isAddCurrentSelectionToFilterChecked();
      } else if (item.children) {
        isSelected = this.areAllChildrenSelected(item);
      } else {
        isSelected = this.valueModel.isKeySelected(item.key);
      }
    } else {
      if (item === SetFilterDisplayValue.SELECT_ALL) {
        isSelected = this.isSelectAllSelected();
      } else if (item === SetFilterDisplayValue.ADD_SELECTION_TO_FILTER) {
        isSelected = this.valueModel.isAddCurrentSelectionToFilterChecked();
      } else {
        isSelected = this.valueModel.isKeySelected(item);
      }
    }
    return { isSelected, isExpanded };
  }
  isSetFilterModelTreeItem(item) {
    return (item == null ? void 0 : item.treeKey) !== void 0;
  }
  initMiniFilter() {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    const { eMiniFilter, localeService } = this;
    const translate = localeService.getLocaleTextFunc();
    eMiniFilter.setDisplayed(!this.setFilterParams.suppressMiniFilter);
    eMiniFilter.setValue(this.valueModel.getMiniFilter());
    eMiniFilter.onValueChange(() => this.onMiniFilterInput());
    eMiniFilter.setInputAriaLabel(translate("ariaSearchFilterValues", "Search filter values"));
    this.addManagedListener(eMiniFilter.getInputElement(), "keydown", (e) => this.onMiniFilterKeyDown(e));
  }
  updateMiniFilter() {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    const { eMiniFilter } = this;
    if (eMiniFilter.isDisplayed() !== !this.setFilterParams.suppressMiniFilter) {
      eMiniFilter.setDisplayed(!this.setFilterParams.suppressMiniFilter);
    }
    const miniFilterValue = this.valueModel.getMiniFilter();
    if (eMiniFilter.getValue() !== miniFilterValue) {
      eMiniFilter.setValue(miniFilterValue);
    }
  }
  // we need to have the GUI attached before we can draw the virtual rows, as the
  // virtual row logic needs info about the GUI state
  afterGuiAttached(params) {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    super.afterGuiAttached(params);
    this.resetExpansion();
    this.refreshVirtualList();
    const { eMiniFilter } = this;
    eMiniFilter.setInputPlaceholder(this.translateForSetFilter("searchOoo"));
    if (!params || !params.suppressFocus) {
      eMiniFilter.getFocusableElement().focus();
    }
  }
  afterGuiDetached() {
    var _a, _b;
    super.afterGuiDetached();
    if ((_a = this.setFilterParams) == null ? void 0 : _a.excelMode) {
      this.resetMiniFilter();
    }
    const appliedModel = this.getModel();
    if (((_b = this.setFilterParams) == null ? void 0 : _b.excelMode) || !this.areModelsEqual(appliedModel, this.getModelFromUi())) {
      this.resetUiToActiveModel(appliedModel);
      this.showOrHideResults();
    }
  }
  applyModel(source = "api") {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    if (this.setFilterParams.excelMode && source !== "rowDataUpdated" && this.valueModel.isEverythingVisibleSelected()) {
      this.valueModel.selectAllMatchingMiniFilter();
    }
    const shouldKeepCurrentSelection = this.valueModel.showAddCurrentSelectionToFilter() && this.valueModel.isAddCurrentSelectionToFilterChecked();
    if (shouldKeepCurrentSelection && !this.getModel()) {
      return false;
    }
    const result = super.applyModel(source);
    const appliedModel = this.getModel();
    if (appliedModel) {
      if (!shouldKeepCurrentSelection) {
        this.valueModel.setAppliedModelKeys(/* @__PURE__ */ new Set());
      }
      appliedModel.values.forEach((key) => {
        this.valueModel.addToAppliedModelKeys(key);
      });
    } else {
      if (!shouldKeepCurrentSelection) {
        this.valueModel.setAppliedModelKeys(null);
      }
    }
    return result;
  }
  isModelValid(model) {
    return this.setFilterParams && this.setFilterParams.excelMode ? model == null || model.values.length > 0 : true;
  }
  doesFilterPass(params) {
    if (!this.setFilterParams || !this.valueModel || !this.valueModel.getCaseFormattedAppliedModelKeys()) {
      return true;
    }
    if (!this.valueModel.hasAnyAppliedModelKey()) {
      return false;
    }
    const { node, data } = params;
    if (this.treeDataTreeList) {
      return this.doesFilterPassForTreeData(node, data);
    }
    if (this.groupingTreeList) {
      return this.doesFilterPassForGrouping(node);
    }
    let value = this.getValueFromNode(node);
    if (this.convertValuesToStrings) {
      return this.doesFilterPassForConvertValuesToString(node, value);
    }
    if (value != null && Array.isArray(value)) {
      if (value.length === 0) {
        return this.valueModel.hasAppliedModelKey(null);
      }
      return value.some((v) => this.isInAppliedModel(this.createKey(v, node)));
    }
    return this.isInAppliedModel(this.createKey(value, node));
  }
  doesFilterPassForConvertValuesToString(node, value) {
    const key = this.createKey(value, node);
    if (key != null && Array.isArray(key)) {
      if (key.length === 0) {
        return this.valueModel.hasAppliedModelKey(null);
      }
      return key.some((v) => this.isInAppliedModel(v));
    }
    return this.isInAppliedModel(key);
  }
  doesFilterPassForTreeData(node, data) {
    var _a;
    if ((_a = node.childrenAfterGroup) == null ? void 0 : _a.length) {
      return false;
    }
    return this.isInAppliedModel(this.createKey(this.checkMakeNullDataPath(this.getDataPath(data))));
  }
  doesFilterPassForGrouping(node) {
    const dataPath = this.columnModel.getRowGroupColumns().map((groupCol) => this.valueService.getKeyForNode(groupCol, node));
    dataPath.push(this.getValueFromNode(node));
    return this.isInAppliedModel(this.createKey(this.checkMakeNullDataPath(dataPath)));
  }
  checkMakeNullDataPath(dataPath) {
    if (dataPath) {
      dataPath = dataPath.map((treeKey) => _59.toStringOrNull(_59.makeNull(treeKey)));
    }
    if (dataPath == null ? void 0 : dataPath.some((treeKey) => treeKey == null)) {
      return null;
    }
    return dataPath;
  }
  isInAppliedModel(key) {
    return this.valueModel.hasAppliedModelKey(key);
  }
  getValueFromNode(node) {
    return this.setFilterParams.getValue(node);
  }
  getKeyCreatorParams(value, node = null) {
    return {
      value,
      colDef: this.setFilterParams.colDef,
      column: this.setFilterParams.column,
      node,
      data: node == null ? void 0 : node.data,
      api: this.setFilterParams.api,
      columnApi: this.setFilterParams.columnApi,
      context: this.setFilterParams.context
    };
  }
  onNewRowsLoaded() {
    if (!this.isValuesTakenFromGrid()) {
      return;
    }
    this.syncAfterDataChange();
  }
  isValuesTakenFromGrid() {
    if (!this.valueModel) {
      return false;
    }
    const valuesType = this.valueModel.getValuesType();
    return valuesType === 2;
  }
  //noinspection JSUnusedGlobalSymbols
  /**
   * Public method provided so the user can change the value of the filter once
   * the filter has been already started
   * @param values The values to use.
   */
  setFilterValues(values) {
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    this.valueModel.overrideValues(values).then(() => {
      this.checkAndRefreshVirtualList();
      this.onUiChanged();
    });
  }
  //noinspection JSUnusedGlobalSymbols
  /**
   * Public method provided so the user can reset the values of the filter once that it has started.
   */
  resetFilterValues() {
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    this.valueModel.setValuesType(
      2
      /* TAKEN_FROM_GRID_VALUES */
    );
    this.syncAfterDataChange();
  }
  refreshFilterValues() {
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    if (!this.valueModel.isInitialised()) {
      return;
    }
    this.valueModel.refreshValues().then(() => {
      this.checkAndRefreshVirtualList();
      this.onUiChanged();
    });
  }
  onAnyFilterChanged() {
    setTimeout(() => {
      if (!this.isAlive()) {
        return;
      }
      if (!this.valueModel) {
        throw new Error("Value model has not been created.");
      }
      this.valueModel.refreshAfterAnyFilterChanged().then((refresh) => {
        if (refresh) {
          this.checkAndRefreshVirtualList();
          this.showOrHideResults();
        }
      });
    }, 0);
  }
  onMiniFilterInput() {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    if (!this.valueModel.setMiniFilter(this.eMiniFilter.getValue())) {
      return;
    }
    const { applyMiniFilterWhileTyping, readOnly } = this.setFilterParams || {};
    if (!readOnly && applyMiniFilterWhileTyping) {
      this.filterOnAllVisibleValues(false);
    } else {
      this.updateUiAfterMiniFilterChange();
    }
  }
  updateUiAfterMiniFilterChange() {
    if (!this.setFilterParams) {
      throw new Error("Set filter params have not been provided.");
    }
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    const { excelMode, readOnly } = this.setFilterParams || {};
    if (excelMode == null || !!readOnly) {
      this.checkAndRefreshVirtualList();
    } else if (this.valueModel.getMiniFilter() == null) {
      this.resetUiToActiveModel(this.getModel());
    } else {
      this.valueModel.selectAllMatchingMiniFilter(true);
      this.checkAndRefreshVirtualList();
      this.onUiChanged();
    }
    this.showOrHideResults();
  }
  showOrHideResults() {
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    const hideResults = this.valueModel.getMiniFilter() != null && this.valueModel.getDisplayedValueCount() < 1;
    _59.setDisplayed(this.eNoMatches, hideResults);
    _59.setDisplayed(this.eSetFilterList, !hideResults);
  }
  resetMiniFilter() {
    var _a;
    this.eMiniFilter.setValue(null, true);
    (_a = this.valueModel) == null ? void 0 : _a.setMiniFilter(null);
  }
  resetUiToActiveModel(currentModel, afterUiUpdatedFunc) {
    this.setModelAndRefresh(currentModel == null ? null : currentModel.values).then(() => {
      this.onUiChanged(false, "prevent");
      afterUiUpdatedFunc == null ? void 0 : afterUiUpdatedFunc();
    });
  }
  handleCancelEnd(e) {
    this.setMiniFilter(null);
    super.handleCancelEnd(e);
  }
  onMiniFilterKeyDown(e) {
    const { excelMode, readOnly } = this.setFilterParams || {};
    if (e.key === KeyCode11.ENTER && !excelMode && !readOnly) {
      this.filterOnAllVisibleValues();
    }
  }
  filterOnAllVisibleValues(applyImmediately = true) {
    const { readOnly } = this.setFilterParams || {};
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    if (!!readOnly) {
      throw new Error("Unable to filter in readOnly mode.");
    }
    this.valueModel.selectAllMatchingMiniFilter(true);
    this.checkAndRefreshVirtualList();
    this.onUiChanged(false, applyImmediately ? "immediately" : "debounce");
    this.showOrHideResults();
  }
  focusRowIfAlive(rowIndex) {
    if (rowIndex == null) {
      return;
    }
    window.setTimeout(() => {
      if (!this.virtualList) {
        throw new Error("Virtual list has not been created.");
      }
      if (this.isAlive()) {
        this.virtualList.focusRow(rowIndex);
      }
    }, 0);
  }
  onSelectAll(isSelected) {
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    if (!this.virtualList) {
      throw new Error("Virtual list has not been created.");
    }
    if (isSelected) {
      this.valueModel.selectAllMatchingMiniFilter();
    } else {
      this.valueModel.deselectAllMatchingMiniFilter();
    }
    this.refreshAfterSelection();
  }
  onGroupItemSelected(item, isSelected) {
    const recursiveGroupSelection = (i) => {
      if (i.children) {
        i.children.forEach((childItem) => recursiveGroupSelection(childItem));
      } else {
        this.selectItem(i.key, isSelected);
      }
    };
    recursiveGroupSelection(item);
    this.refreshAfterSelection();
  }
  onItemSelected(key, isSelected) {
    if (!this.valueModel) {
      throw new Error("Value model has not been created.");
    }
    if (!this.virtualList) {
      throw new Error("Virtual list has not been created.");
    }
    this.selectItem(key, isSelected);
    this.refreshAfterSelection();
  }
  selectItem(key, isSelected) {
    if (isSelected) {
      this.valueModel.selectKey(key);
    } else {
      this.valueModel.deselectKey(key);
    }
  }
  onExpandAll(item, isExpanded) {
    const recursiveExpansion = (i) => {
      if (i.filterPasses && i.available && i.children) {
        i.children.forEach((childItem) => recursiveExpansion(childItem));
        i.expanded = isExpanded;
      }
    };
    recursiveExpansion(item);
    this.refreshAfterExpansion();
  }
  onExpandedChanged(item, isExpanded) {
    item.expanded = isExpanded;
    this.refreshAfterExpansion();
  }
  refreshAfterExpansion() {
    const focusedRow = this.virtualList.getLastFocusedRow();
    this.valueModel.updateDisplayedValues("expansion");
    this.checkAndRefreshVirtualList();
    this.focusRowIfAlive(focusedRow);
  }
  refreshAfterSelection() {
    const focusedRow = this.virtualList.getLastFocusedRow();
    this.checkAndRefreshVirtualList();
    this.onUiChanged();
    this.focusRowIfAlive(focusedRow);
  }
  setMiniFilter(newMiniFilter) {
    this.eMiniFilter.setValue(newMiniFilter);
    this.onMiniFilterInput();
  }
  getMiniFilter() {
    return this.valueModel ? this.valueModel.getMiniFilter() : null;
  }
  checkAndRefreshVirtualList() {
    if (!this.virtualList) {
      throw new Error("Virtual list has not been created.");
    }
    this.virtualList.refresh(!this.hardRefreshVirtualList);
    if (this.hardRefreshVirtualList) {
      this.hardRefreshVirtualList = false;
    }
  }
  getFilterKeys() {
    return this.valueModel ? this.valueModel.getKeys() : [];
  }
  getFilterValues() {
    return this.valueModel ? this.valueModel.getValues() : [];
  }
  getValues() {
    return this.getFilterKeys();
  }
  refreshVirtualList() {
    if (this.setFilterParams && this.setFilterParams.refreshValuesOnOpen) {
      this.refreshFilterValues();
    } else {
      this.checkAndRefreshVirtualList();
    }
  }
  translateForSetFilter(key) {
    const translate = this.localeService.getLocaleTextFunc();
    return translate(key, DEFAULT_LOCALE_TEXT[key]);
  }
  isSelectAllSelected() {
    if (!this.setFilterParams || !this.valueModel) {
      return false;
    }
    if (!this.setFilterParams.defaultToNothingSelected) {
      if (this.valueModel.hasSelections() && this.valueModel.isNothingVisibleSelected()) {
        return false;
      }
      if (this.valueModel.isEverythingVisibleSelected()) {
        return true;
      }
    } else {
      if (this.valueModel.hasSelections() && this.valueModel.isEverythingVisibleSelected()) {
        return true;
      }
      if (this.valueModel.isNothingVisibleSelected()) {
        return false;
      }
    }
    return void 0;
  }
  areAllChildrenSelected(item) {
    const recursiveChildSelectionCheck = (i) => {
      if (i.children) {
        let someTrue = false;
        let someFalse = false;
        const mixed = i.children.some((child) => {
          if (!child.filterPasses || !child.available) {
            return false;
          }
          const childSelected = recursiveChildSelectionCheck(child);
          if (childSelected === void 0) {
            return true;
          }
          if (childSelected) {
            someTrue = true;
          } else {
            someFalse = true;
          }
          return someTrue && someFalse;
        });
        return mixed ? void 0 : someTrue;
      } else {
        return this.valueModel.isKeySelected(i.key);
      }
    };
    if (!this.setFilterParams.defaultToNothingSelected) {
      return recursiveChildSelectionCheck(item);
    } else {
      return this.valueModel.hasSelections() && recursiveChildSelectionCheck(item);
    }
  }
  destroy() {
    if (this.virtualList != null) {
      this.virtualList.destroy();
      this.virtualList = null;
    }
    super.destroy();
  }
  caseFormat(valueToFormat) {
    if (valueToFormat == null || typeof valueToFormat !== "string") {
      return valueToFormat;
    }
    return this.caseSensitive ? valueToFormat : valueToFormat.toUpperCase();
  }
  resetExpansion() {
    var _a, _b;
    if (!((_a = this.setFilterParams) == null ? void 0 : _a.treeList)) {
      return;
    }
    const selectAllItem = (_b = this.valueModel) == null ? void 0 : _b.getSelectAllItem();
    if (this.isSetFilterModelTreeItem(selectAllItem)) {
      const recursiveCollapse = (i) => {
        if (i.children) {
          i.children.forEach((childItem) => recursiveCollapse(childItem));
          i.expanded = false;
        }
      };
      recursiveCollapse(selectAllItem);
      this.valueModel.updateDisplayedValues("expansion");
    }
  }
  getModelAsString(model) {
    return this.filterModelFormatter.getModelAsString(model, this);
  }
  getPositionableElement() {
    return this.eSetFilterList;
  }
};
__decorateClass15([
  RefSelector27("eMiniFilter")
], SetFilter.prototype, "eMiniFilter", 2);
__decorateClass15([
  RefSelector27("eFilterLoading")
], SetFilter.prototype, "eFilterLoading", 2);
__decorateClass15([
  RefSelector27("eSetFilterList")
], SetFilter.prototype, "eSetFilterList", 2);
__decorateClass15([
  RefSelector27("eFilterNoMatches")
], SetFilter.prototype, "eNoMatches", 2);
__decorateClass15([
  Autowired217("columnModel")
], SetFilter.prototype, "columnModel", 2);
__decorateClass15([
  Autowired217("valueService")
], SetFilter.prototype, "valueService", 2);
var ModelWrapper = class {
  constructor(model) {
    this.model = model;
  }
  getRowCount() {
    return this.model.getDisplayedValueCount();
  }
  getRow(index) {
    return this.model.getDisplayedItem(index);
  }
  areRowsEqual(oldRow, newRow) {
    return oldRow === newRow;
  }
};
var ModelWrapperWithSelectAll = class {
  constructor(model, isSelectAllSelected) {
    this.model = model;
    this.isSelectAllSelected = isSelectAllSelected;
  }
  getRowCount() {
    const showAddCurrentSelectionToFilter = this.model.showAddCurrentSelectionToFilter();
    const outboundItems = showAddCurrentSelectionToFilter ? 2 : 1;
    return this.model.getDisplayedValueCount() + outboundItems;
  }
  getRow(index) {
    if (index === 0) {
      return this.model.getSelectAllItem();
    }
    const showAddCurrentSelectionToFilter = this.model.showAddCurrentSelectionToFilter();
    const outboundItems = showAddCurrentSelectionToFilter ? 2 : 1;
    if (index === 1 && showAddCurrentSelectionToFilter) {
      return this.model.getAddSelectionToFilterItem();
    }
    return this.model.getDisplayedItem(index - outboundItems);
  }
  areRowsEqual(oldRow, newRow) {
    return oldRow === newRow;
  }
};
var TreeModelWrapper = class {
  constructor(model) {
    this.model = model;
  }
  getRowCount() {
    return this.model.getRowCount();
  }
  getRow(index) {
    return this.model.getRow(index);
  }
  areRowsEqual(oldRow, newRow) {
    if (oldRow == null && newRow == null) {
      return true;
    }
    return oldRow != null && newRow != null && oldRow.treeKey === newRow.treeKey && oldRow.depth === newRow.depth;
  }
};
var SetFloatingFilterComp = class extends Component214 {
  constructor() {
    super(
      /* html */
      `
            <div class="ag-floating-filter-input ag-set-floating-filter-input" role="presentation">
                <ag-input-text-field ref="eFloatingFilterText"></ag-input-text-field>
            </div>`
    );
    this.availableValuesListenerAdded = false;
    this.filterModelFormatter = new SetFilterModelFormatter();
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
  init(params) {
    this.params = params;
    this.eFloatingFilterText.setDisabled(true).addGuiEventListener("click", () => this.params.showParentFilter());
    this.setParams(params);
  }
  setParams(params) {
    const displayName = this.columnModel.getDisplayNameForColumn(params.column, "header", true);
    const translate = this.localeService.getLocaleTextFunc();
    this.eFloatingFilterText.setInputAriaLabel(`${displayName} ${translate("ariaFilterInput", "Filter Input")}`);
  }
  onParamsUpdated(params) {
    this.refresh(params);
  }
  refresh(params) {
    this.params = params;
    this.setParams(params);
  }
  onParentModelChanged(parentModel) {
    this.updateFloatingFilterText(parentModel);
  }
  parentSetFilterInstance(cb) {
    this.params.parentFilterInstance((filter) => {
      if (!(filter instanceof SetFilter)) {
        throw new Error("AG Grid - SetFloatingFilter expects SetFilter as its parent");
      }
      cb(filter);
    });
  }
  addAvailableValuesListener() {
    this.parentSetFilterInstance((setFilter) => {
      const setValueModel = setFilter.getValueModel();
      if (!setValueModel) {
        return;
      }
      this.addManagedListener(
        setValueModel,
        SetValueModel.EVENT_AVAILABLE_VALUES_CHANGED,
        () => this.updateFloatingFilterText()
      );
    });
    this.availableValuesListenerAdded = true;
  }
  updateFloatingFilterText(parentModel) {
    if (!this.availableValuesListenerAdded) {
      this.addAvailableValuesListener();
    }
    this.parentSetFilterInstance((setFilter) => {
      this.eFloatingFilterText.setValue(this.filterModelFormatter.getModelAsString(parentModel, setFilter));
    });
  }
};
__decorateClass15([
  RefSelector37("eFloatingFilterText")
], SetFloatingFilterComp.prototype, "eFloatingFilterText", 2);
__decorateClass15([
  Autowired316("columnModel")
], SetFloatingFilterComp.prototype, "columnModel", 2);
var VERSION16 = "31.3.2";
var SetFilterModule = {
  version: VERSION16,
  moduleName: ModuleNames16.SetFilterModule,
  beans: [],
  userComponents: [
    { componentName: "agSetColumnFilter", componentClass: SetFilter },
    { componentName: "agSetColumnFloatingFilter", componentClass: SetFloatingFilterComp }
  ],
  dependantModules: [
    EnterpriseCoreModule
  ]
};

// enterprise-modules/sparklines/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames17 } from "ag-grid-community";
import {
  Autowired as Autowired69,
  Component as Component58,
  RefSelector as RefSelector28
} from "ag-grid-community";
import { _Scale as _Scale2, _Scene as _Scene32, _Util as _Util2 } from "ag-charts-community";
import { _Scale, _Scene as _Scene25, _Util as _Util4 } from "ag-charts-community";
import { _Scene as _Scene26 } from "ag-charts-community";
import { _Scale as _Scale3, _Scene as _Scene42, _Util as _Util32 } from "ag-charts-community";
import { _Scale as _Scale4, _Scene as _Scene62, _Util as _Util52 } from "ag-charts-community";
import { _Scene as _Scene52, _Util as _Util42 } from "ag-charts-community";
import { _Scale as _Scale5, _Scene as _Scene72, _Util as _Util62 } from "ag-charts-community";
import { _Util as _Util7 } from "ag-charts-community";
import { Bean as Bean15, BeanStub as BeanStub29, PostConstruct as PostConstruct66, PreDestroy as PreDestroy6 } from "ag-grid-community";
var __defProp16 = Object.defineProperty;
var __getOwnPropDesc16 = Object.getOwnPropertyDescriptor;
var __getOwnPropSymbols13 = Object.getOwnPropertySymbols;
var __hasOwnProp14 = Object.prototype.hasOwnProperty;
var __propIsEnum13 = Object.prototype.propertyIsEnumerable;
var __defNormalProp13 = (obj, key, value) => key in obj ? __defProp16(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues13 = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp14.call(b, prop))
      __defNormalProp13(a, prop, b[prop]);
  if (__getOwnPropSymbols13)
    for (var prop of __getOwnPropSymbols13(b)) {
      if (__propIsEnum13.call(b, prop))
        __defNormalProp13(a, prop, b[prop]);
    }
  return a;
};
var __decorateClass16 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc16(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp16(target, key, result);
  return result;
};
var defaultTooltipCss = `
.ag-sparkline-tooltip-wrapper {
    position: absolute;
    user-select: none;
    pointer-events: none;
}

.ag-sparkline-tooltip {
    position: relative;
    font: 12px arial,sans-serif;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 20%), 0 1px 1px rgb(0 0 0 / 14%);
    line-height: 1.7em;
    overflow: hidden;
    white-space: nowrap;
    z-index: 99999;
    background-color: rgb(255, 255, 255);
    color: rgba(0,0,0, 0.67);
}

.ag-sparkline-tooltip-content {
    padding: 0 7px;
    opacity: 1;
}

.ag-sparkline-tooltip-title {
    padding-left: 7px;
    opacity: 1;
}

.ag-sparkline-tooltip-wrapper-hidden {
    top: -10000px !important;
}

.ag-sparkline-wrapper {
    box-sizing: border-box;
    overflow: hidden;
}
`;
var { extent, isNumber, isString: isString2, isStringObject, isDate, createId, Padding } = _Util4;
var { LinearScale, BandScale, TimeScale } = _Scale;
var SparklineAxis = class {
  constructor() {
    this.type = "category";
    this.stroke = "rgb(204, 214, 235)";
    this.strokeWidth = 1;
  }
};
var _Sparkline = class _Sparkline2 {
  constructor() {
    this.id = createId(this);
    this.seriesRect = {
      x: 0,
      y: 0,
      width: 0,
      height: 0
    };
    this._context = void 0;
    this._container = void 0;
    this._data = void 0;
    this.padding = new Padding(3);
    this.xKey = "x";
    this.yKey = "y";
    this.dataType = void 0;
    this.xData = [];
    this.yData = [];
    this.min = void 0;
    this.max = void 0;
    this.yScale = new LinearScale();
    this.axis = new SparklineAxis();
    this.highlightStyle = {
      size: 6,
      fill: "yellow",
      stroke: "silver",
      strokeWidth: 1
    };
    this._width = 100;
    this._height = 100;
    this.smallestInterval = void 0;
    this.layoutId = 0;
    this.defaultDateFormatter = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false
    });
    this._onMouseMove = this.onMouseMove.bind(this);
    this._onMouseOut = this.onMouseOut.bind(this);
    const root = new _Scene25.Group();
    this.rootGroup = root;
    const element = document.createElement("div");
    element.setAttribute("class", "ag-sparkline-wrapper");
    const scene = new _Scene25.Scene({});
    this.scene = scene;
    this.canvasElement = scene.canvas.element;
    scene.setRoot(root);
    scene.setContainer(element);
    this.resizeAndSetDimensions(this.width, this.height);
    if (!_Sparkline2.tooltipDocuments.includes(document)) {
      this.initialiseTooltipStyles();
    }
    this.setupDomEventListeners(this.canvasElement);
  }
  set context(value) {
    if (this._context !== value) {
      this._context = value;
    }
  }
  get context() {
    return this._context;
  }
  set container(value) {
    if (this._container !== value) {
      const { parentNode } = this.canvasElement;
      if (parentNode != null) {
        parentNode.removeChild(this.canvasElement);
      }
      if (value) {
        value.appendChild(this.canvasElement);
      }
      this._container = value;
    }
  }
  get container() {
    return this._container;
  }
  set data(value) {
    if (this._data !== value) {
      this._data = value;
      this.processData();
      if (this.mouseMoveEvent && this.highlightedDatum) {
        this.updateHitPoint(this.mouseMoveEvent);
      }
    }
  }
  get data() {
    return this._data;
  }
  resizeAndSetDimensions(width, height) {
    this.scene.resize(width, height);
    this.seriesRect.width = width;
    this.seriesRect.height = height;
  }
  initialiseTooltipStyles() {
    const styleElement = document.createElement("style");
    styleElement.innerHTML = defaultTooltipCss;
    document.head.insertBefore(styleElement, document.head.querySelector("style"));
    _Sparkline2.tooltipDocuments.push(document);
  }
  set width(value) {
    if (this._width !== value) {
      this._width = value;
      this.scene.resize(value, this.height);
      this.scheduleLayout();
    }
  }
  get width() {
    return this._width;
  }
  set height(value) {
    if (this._height !== value) {
      this._height = value;
      this.scene.resize(this.width, value);
      this.scheduleLayout();
    }
  }
  get height() {
    return this._height;
  }
  /**
   * Generate node data from processed data.
   * Produce data joins.
   * Update selection's nodes using node data.
   */
  update() {
  }
  // Update y scale based on processed data.
  updateYScale() {
    this.updateYScaleRange();
    this.updateYScaleDomain();
  }
  // Update y scale domain based on processed data.
  updateYScaleDomain() {
  }
  // Update y scale range based on height and padding (seriesRect).
  updateYScaleRange() {
    const { yScale, seriesRect } = this;
    yScale.range = [seriesRect.height, 0];
  }
  // Update x scale based on processed data.
  updateXScale() {
    const { type } = this.axis;
    this.xScale = this.getXScale(type);
    this.updateXScaleRange();
    this.updateXScaleDomain();
  }
  // Update x scale range based on width and padding (seriesRect).
  updateXScaleRange() {
    this.xScale.range = [0, this.seriesRect.width];
  }
  // Update x scale domain based on processed data and type of scale.
  updateXScaleDomain() {
    const { xData, xScale } = this;
    let xMinMax;
    if (xScale instanceof LinearScale || xScale instanceof TimeScale) {
      xMinMax = extent(xData);
    }
    this.xScale.domain = xMinMax ? xMinMax.slice() : xData;
  }
  /**
   * Return xScale instance based on the provided type or return a `BandScale` by default.
   * The default type is `category`.
   * @param type
   */
  getXScale(type = "category") {
    switch (type) {
      case "number":
        return new LinearScale();
      case "time":
        return new TimeScale();
      case "category":
      default:
        return new BandScale();
    }
  }
  // Update axis line.
  updateAxisLine() {
  }
  // Update X and Y scales and the axis line.
  updateAxes() {
    this.updateYScale();
    this.updateXScale();
    this.updateAxisLine();
  }
  // Update horizontal and vertical crosshair lines.
  updateCrosshairs() {
    this.updateXCrosshairLine();
    this.updateYCrosshairLine();
  }
  // Using processed data, generate data that backs visible nodes.
  generateNodeData() {
    return [];
  }
  // Returns persisted node data associated with the sparkline's data.
  getNodeData() {
    return [];
  }
  // Update the selection's nodes.
  updateNodes() {
  }
  // Update the vertical crosshair line.
  updateXCrosshairLine() {
  }
  // Update the horizontal crosshair line.
  updateYCrosshairLine() {
  }
  highlightDatum(closestDatum) {
    this.updateNodes();
  }
  dehighlightDatum() {
    this.highlightedDatum = void 0;
    this.updateNodes();
    this.updateCrosshairs();
  }
  /**
   * Highlight closest datum and display tooltip if enabled.
   * Only update if necessary, i.e. only update if the highlighted datum is different from previously highlighted datum,
   * or if there is no previously highlighted datum.
   * @param event
   */
  onMouseMove(event) {
    this.mouseMoveEvent = event;
    this.updateHitPoint(event);
  }
  updateHitPoint(event) {
    var _a, _b, _c;
    const closestDatum = this.pickClosestSeriesNodeDatum(event.offsetX, event.offsetY);
    if (!closestDatum) {
      return;
    }
    const oldHighlightedDatum = this.highlightedDatum;
    this.highlightedDatum = closestDatum;
    if (this.highlightedDatum && !oldHighlightedDatum || this.highlightedDatum && oldHighlightedDatum && this.highlightedDatum !== oldHighlightedDatum) {
      this.highlightDatum(closestDatum);
      this.updateCrosshairs();
      this.scene.render().catch((e) => console.error(`AG Grid - chart rendering failed`, e));
    }
    const tooltipEnabled = (_c = (_b = (_a = this.processedOptions) == null ? void 0 : _a.tooltip) == null ? void 0 : _b.enabled) != null ? _c : true;
    if (tooltipEnabled) {
      this.handleTooltip(event, closestDatum);
    }
  }
  /**
   * Dehighlight all nodes and remove tooltip.
   * @param event
   */
  onMouseOut(event) {
    this.dehighlightDatum();
    this.tooltip.toggle(false);
    this.scene.render().catch((e) => console.error(`AG Grid - chart rendering failed`, e));
  }
  // Fetch required values from the data object and process them.
  processData() {
    const { data, yData, xData } = this;
    if (!data || this.invalidData(this.data)) {
      return;
    }
    yData.length = 0;
    xData.length = 0;
    const n = data.length;
    const dataType = this.getDataType(data);
    this.dataType = dataType;
    const { type: xValueType } = this.axis;
    const xType = xValueType !== "number" && xValueType !== "time" ? "category" : xValueType;
    const isContinuousX = xType === "number" || xType === "time";
    const setSmallestXInterval = (curr, prev) => {
      if (this.smallestInterval == void 0) {
        this.smallestInterval = { x: Infinity, y: Infinity };
      }
      const { x } = this.smallestInterval;
      const interval = Math.abs(curr - prev);
      if (interval > 0 && interval < x) {
        this.smallestInterval.x = interval;
      }
    };
    let prevX;
    if (dataType === "number") {
      for (let i = 0; i < n; i++) {
        const xDatum = i;
        const yDatum = data[i];
        const x = this.getDatum(xDatum, xType);
        const y = this.getDatum(yDatum, "number");
        if (isContinuousX) {
          setSmallestXInterval(x, prevX);
        }
        xData.push(x);
        yData.push(y);
        prevX = x;
      }
    } else if (dataType === "array") {
      for (let i = 0; i < n; i++) {
        const datum = data[i];
        if (Array.isArray(datum)) {
          const xDatum = datum[0];
          const yDatum = datum[1];
          const x = this.getDatum(xDatum, xType);
          const y = this.getDatum(yDatum, "number");
          if (x == void 0) {
            continue;
          }
          if (isContinuousX) {
            setSmallestXInterval(x, prevX);
          }
          xData.push(x);
          yData.push(y);
          prevX = x;
        }
      }
    } else if (dataType === "object") {
      const { yKey, xKey } = this;
      for (let i = 0; i < n; i++) {
        const datum = data[i];
        if (typeof datum === "object" && !Array.isArray(datum)) {
          const xDatum = datum[xKey];
          const yDatum = datum[yKey];
          const x = this.getDatum(xDatum, xType);
          const y = this.getDatum(yDatum, "number");
          if (x == void 0) {
            continue;
          }
          if (isContinuousX) {
            setSmallestXInterval(x, prevX);
          }
          xData.push(x);
          yData.push(y);
          prevX = x;
        }
      }
    }
    this.updateAxes();
    this.immediateLayout();
  }
  /**
   * Return the type of data provided to the sparkline based on the first truthy value in the data array.
   * If the value is not a number, array or object, return `undefined`.
   * @param data
   */
  getDataType(data) {
    for (const datum of data) {
      if (datum != void 0) {
        if (isNumber(datum)) {
          return "number";
        } else if (Array.isArray(datum)) {
          return "array";
        } else if (typeof datum === "object") {
          return "object";
        }
      }
    }
  }
  /**
   * Return the given value depending on the type of axis.
   * Return `undefined` if the value is invalid for the given axis type.
   * @param value
   */
  getDatum(value, type) {
    if (type === "number" && isNumber(value) || type === "time" && (isNumber(value) || isDate(value))) {
      return value;
    } else if (type === "category") {
      if (isString2(value) || isDate(value) || isNumber(value)) {
        return { toString: () => String(value) };
      } else if (isStringObject(value)) {
        return value;
      }
    }
  }
  /**
   * Only `true` while we are waiting for the layout to start.
   * This will be `false` if the layout has already started and is ongoing.
   */
  get layoutScheduled() {
    return !!this.layoutId;
  }
  /**
   * Execute update method on the next available screen repaint to make changes to the canvas.
   * If we are waiting for a layout to start and a new layout is requested,
   * cancel the previous layout using the non 0 integer (this.layoutId) returned from requestAnimationFrame.
   */
  scheduleLayout() {
    if (this.layoutId) {
      cancelAnimationFrame(this.layoutId);
    }
    this.layoutId = requestAnimationFrame(() => {
      this.immediateLayout();
      this.layoutId = 0;
    });
  }
  immediateLayout() {
    this.setSparklineDimensions();
    if (this.invalidData(this.data)) {
      return;
    }
    this.updateXScaleRange();
    this.updateYScaleRange();
    this.updateAxisLine();
    this.update();
    this.scene.render().catch((e) => console.error(`AG Grid - chart rendering failed`, e));
  }
  setSparklineDimensions() {
    const { width, height, padding, seriesRect, rootGroup } = this;
    const shrunkWidth = width - padding.left - padding.right;
    const shrunkHeight = height - padding.top - padding.bottom;
    seriesRect.width = shrunkWidth;
    seriesRect.height = shrunkHeight;
    seriesRect.x = padding.left;
    seriesRect.y = padding.top;
    rootGroup.translationX = seriesRect.x;
    rootGroup.translationY = seriesRect.y;
  }
  /**
   * Return the closest data point to x/y canvas coordinates.
   * @param x
   * @param y
   */
  pickClosestSeriesNodeDatum(x, y) {
    let minDistance = Infinity;
    let closestDatum;
    const hitPoint = this.rootGroup.transformPoint(x, y);
    const nodeData = this.getNodeData();
    for (let i = 0; i < nodeData.length; i++) {
      const datum = nodeData[i];
      if (!datum.point) {
        return;
      }
      const distance = this.getDistance(hitPoint, datum.point);
      if (distance <= minDistance) {
        minDistance = distance;
        closestDatum = datum;
      }
    }
    return closestDatum;
  }
  /**
   * Return the relevant distance between two points.
   * The distance will be calculated based on the x value of the points for all sparklines except bar sparkline, where the distance is based on the y values.
   * @param x
   * @param y
   */
  getDistance(p1, p2) {
    return Math.abs(p1.x - p2.x);
  }
  /**
   * calculate x/y coordinates for tooltip based on coordinates of highlighted datum, position of canvas and page offset.
   * @param datum
   */
  handleTooltip(event, datum) {
    var _a, _b;
    const { seriesDatum } = datum;
    const { canvasElement } = this;
    const { clientX, clientY } = event;
    const tooltipOptions = (_a = this.processedOptions) == null ? void 0 : _a.tooltip;
    const meta = {
      pageX: clientX,
      pageY: clientY,
      position: {
        xOffset: tooltipOptions == null ? void 0 : tooltipOptions.xOffset,
        yOffset: tooltipOptions == null ? void 0 : tooltipOptions.yOffset
      },
      container: tooltipOptions == null ? void 0 : tooltipOptions.container
    };
    if (meta.container == void 0) {
      meta.container = canvasElement;
    }
    const yValue = seriesDatum.y;
    const xValue = seriesDatum.x;
    let enabled = (_b = tooltipOptions == null ? void 0 : tooltipOptions.enabled) != null ? _b : true;
    const tooltipRenderer = tooltipOptions == null ? void 0 : tooltipOptions.renderer;
    if (tooltipRenderer) {
      const tooltipRendererResult = tooltipRenderer({
        context: this.context,
        datum: seriesDatum,
        yValue,
        xValue
      });
      enabled = typeof tooltipRendererResult !== "string" && tooltipRendererResult.enabled !== void 0 ? tooltipRendererResult.enabled : enabled;
    }
    const html = enabled && seriesDatum.y !== void 0 && this.getTooltipHtml(datum);
    if (html) {
      this.tooltip.show(meta, html);
    }
  }
  formatNumericDatum(datum) {
    return String(Math.round(datum * 10) / 10);
  }
  // locale.format('%m/%d/%y, %H:%M:%S');
  formatDatum(datum) {
    const type = this.axis.type || "category";
    if (type === "number" && typeof datum === "number") {
      return this.formatNumericDatum(datum);
    } else if (type === "time" && (datum instanceof Date || isNumber(datum))) {
      return this.defaultDateFormatter.format(datum);
    } else {
      return String(datum);
    }
  }
  setupDomEventListeners(chartElement) {
    chartElement.addEventListener("mousemove", this._onMouseMove);
    chartElement.addEventListener("mouseout", this._onMouseOut);
  }
  cleanupDomEventListeners(chartElement) {
    chartElement.removeEventListener("mousemove", this._onMouseMove);
    chartElement.removeEventListener("mouseout", this._onMouseOut);
  }
  invalidData(data) {
    return !data || !Array.isArray(data);
  }
  /**
   * Cleanup and remove canvas element from the DOM.
   */
  destroy() {
    this.cleanupDomEventListeners(this.canvasElement);
    this.scene.destroy();
    this.container = void 0;
  }
};
_Sparkline.tooltipDocuments = [];
var Sparkline = _Sparkline;
function toTooltipHtml(input, defaults) {
  var _a, _b, _c;
  if (typeof input === "string") {
    return input;
  }
  defaults = defaults != null ? defaults : {};
  const {
    content = (_a = defaults.content) != null ? _a : "",
    title = (_b = defaults.title) != null ? _b : void 0,
    color = defaults.color,
    backgroundColor = defaults.backgroundColor,
    opacity = (_c = defaults.opacity) != null ? _c : 1
  } = input;
  let titleHtml;
  let contentHtml;
  if (color) {
    titleHtml = title ? `<span class="${SparklineTooltip.class}-title"; style="color: ${color}">${title}</span>` : "";
    contentHtml = `<span class="${SparklineTooltip.class}-content" style="color: ${color}">${content}</span>`;
  } else {
    titleHtml = title ? `<span class="${SparklineTooltip.class}-title">${title}</span>` : "";
    contentHtml = `<span class="${SparklineTooltip.class}-content">${content}</span>`;
  }
  let style = `opacity: ${opacity}`;
  if (backgroundColor) {
    style += `; background-color: ${backgroundColor.toLowerCase()}`;
  }
  return `<div class="${SparklineTooltip.class}" style="${style}">
                ${titleHtml}
                ${contentHtml}
            </div>`;
}
var _SparklineTooltip = class _SparklineTooltip2 {
  constructor() {
    this.element = document.createElement("div");
    const tooltipRoot = document.body;
    tooltipRoot.appendChild(this.element);
  }
  isVisible() {
    const { element } = this;
    if (element.classList) {
      return !element.classList.contains(`${_SparklineTooltip2.class}-wrapper-hidden`);
    }
    const classes = element.getAttribute("class");
    if (classes) {
      return classes.split(" ").indexOf(`${_SparklineTooltip2.class}-wrapper-hidden`) < 0;
    }
    return false;
  }
  updateClass(visible) {
    const classList = [`${_SparklineTooltip2.class}-wrapper`];
    if (visible !== true) {
      classList.push(`${_SparklineTooltip2.class}-wrapper-hidden`);
    }
    this.element.setAttribute("class", classList.join(" "));
  }
  show(meta, html) {
    var _a, _b, _c, _d;
    this.toggle(false);
    const { element } = this;
    if (html !== void 0) {
      element.innerHTML = html;
    } else if (!element.innerHTML) {
      return;
    }
    const xOffset = (_b = (_a = meta.position) == null ? void 0 : _a.xOffset) != null ? _b : 10;
    const yOffset = (_d = (_c = meta.position) == null ? void 0 : _c.yOffset) != null ? _d : 0;
    let left = meta.pageX + xOffset;
    let top = meta.pageY + yOffset;
    const tooltipRect = element.getBoundingClientRect();
    let maxLeft = window.innerWidth - tooltipRect.width;
    if (meta.container) {
      const containerRect = meta.container.getBoundingClientRect();
      maxLeft = containerRect.left + (containerRect.width - tooltipRect.width);
    }
    if (left > maxLeft) {
      left = meta.pageX - element.clientWidth - xOffset;
    }
    if (typeof scrollX !== "undefined") {
      left += scrollX;
    }
    if (typeof scrollY !== "undefined") {
      top += scrollY;
    }
    element.style.left = `${Math.round(left)}px`;
    element.style.top = `${Math.round(top)}px`;
    this.toggle(true);
  }
  toggle(visible) {
    this.updateClass(visible);
  }
  destroy() {
    const { parentNode } = this.element;
    if (parentNode) {
      parentNode.removeChild(this.element);
    }
  }
};
_SparklineTooltip.class = "ag-sparkline-tooltip";
var SparklineTooltip = _SparklineTooltip;
function getMarker(shape) {
  switch (shape) {
    case "circle":
      return _Scene26.Circle;
    case "square":
      return _Scene26.Square;
    case "diamond":
      return _Scene26.Diamond;
    default:
      return _Scene26.Circle;
  }
}
function getLineDash(lineCap, lineDash = "solid") {
  const buttOrNull = {
    solid: [],
    dash: [4, 3],
    dot: [1, 3],
    dashDot: [4, 3, 1, 3],
    dashDotDot: [4, 3, 1, 3, 1, 3],
    shortDot: [1, 1],
    shortDash: [3, 1],
    shortDashDot: [3, 1, 1, 1],
    shortDashDotDot: [3, 1, 1, 1, 1, 1],
    longDash: [8, 3],
    longDashDot: [8, 3, 1, 3],
    longDashDotDot: [8, 3, 1, 3, 1, 3]
  };
  const roundOrSquare = {
    solid: [],
    dash: [3, 3],
    dot: [0, 3],
    dashDot: [3, 3, 0, 3],
    dashDotDot: [3, 3, 0, 3, 0, 3],
    shortDot: [0, 2],
    shortDash: [2, 2],
    shortDashDot: [2, 2, 0, 2],
    shortDashDotDot: [2, 2, 0, 2, 0, 2],
    longDash: [7, 3],
    longDashDot: [7, 3, 0, 3],
    longDashDotDot: [7, 3, 0, 3, 0, 3]
  };
  if (lineCap === "round" || lineCap === "square") {
    if (roundOrSquare[lineDash] == void 0) {
      console.warn(`'${lineDash}' is not a valid 'lineDash' option.`);
      return roundOrSquare.solid;
    }
    return roundOrSquare[lineDash];
  }
  if (buttOrNull[lineDash] == void 0) {
    console.warn(`'${lineDash}' is not a valid 'lineDash' option.`);
    return buttOrNull.solid;
  }
  return buttOrNull[lineDash];
}
var { extent: extent2 } = _Util2;
var { BandScale: BandScale2 } = _Scale2;
var SparklineMarker = class {
  constructor() {
    this.enabled = true;
    this.shape = "circle";
    this.size = 0;
    this.fill = "rgb(124, 181, 236)";
    this.stroke = "rgb(124, 181, 236)";
    this.strokeWidth = 1;
    this.formatter = void 0;
  }
};
var SparklineLine = class {
  constructor() {
    this.stroke = "rgb(124, 181, 236)";
    this.strokeWidth = 1;
  }
};
var SparklineCrosshairs = class {
  constructor() {
    this.xLine = {
      enabled: true,
      stroke: "rgba(0,0,0, 0.54)",
      strokeWidth: 1,
      lineDash: "solid",
      lineCap: void 0
    };
    this.yLine = {
      enabled: false,
      stroke: "rgba(0,0,0, 0.54)",
      strokeWidth: 1,
      lineDash: "solid",
      lineCap: void 0
    };
  }
};
var AreaSparkline = class extends Sparkline {
  constructor() {
    super();
    this.fill = "rgba(124, 181, 236, 0.25)";
    this.strokePath = new _Scene32.Path();
    this.fillPath = new _Scene32.Path();
    this.xCrosshairLine = new _Scene32.Line();
    this.yCrosshairLine = new _Scene32.Line();
    this.areaSparklineGroup = new _Scene32.Group();
    this.xAxisLine = new _Scene32.Line();
    this.markers = new _Scene32.Group();
    this.markerSelection = _Scene32.Selection.select(
      this.markers,
      () => this.markerFactory()
    );
    this.markerSelectionData = [];
    this.marker = new SparklineMarker();
    this.line = new SparklineLine();
    this.crosshairs = new SparklineCrosshairs();
    this.rootGroup.append(this.areaSparklineGroup);
    this.xAxisLine.zIndex = 500;
    this.fillPath.zIndex = 50;
    this.strokePath.zIndex = 1e3;
    this.xCrosshairLine.zIndex = 2e3;
    this.yCrosshairLine.zIndex = 2e3;
    this.markers.zIndex = 2500;
    this.areaSparklineGroup.append([
      this.fillPath,
      this.xAxisLine,
      this.strokePath,
      this.xCrosshairLine,
      this.yCrosshairLine,
      this.markers
    ]);
  }
  markerFactory() {
    const { shape } = this.marker;
    const MarkerShape = getMarker(shape);
    return new MarkerShape();
  }
  getNodeData() {
    return this.markerSelectionData;
  }
  update() {
    const data = this.generateNodeData();
    if (!data) {
      return;
    }
    const { nodeData, fillData, strokeData } = data;
    this.markerSelectionData = nodeData;
    this.updateSelection(nodeData);
    this.updateNodes();
    this.updateStroke(strokeData);
    this.updateFill(fillData);
  }
  updateYScaleDomain() {
    const { yData, yScale } = this;
    const yMinMax = extent2(yData);
    let yMin = 0;
    let yMax = 1;
    if (yMinMax !== void 0) {
      yMin = this.min = yMinMax[0];
      yMax = this.max = yMinMax[1];
    }
    yMin = yMin < 0 ? yMin : 0;
    yMax = yMax < 0 ? 0 : yMax;
    yScale.domain = [yMin, yMax];
  }
  generateNodeData() {
    const { data, yData, xData, xScale, yScale } = this;
    if (!data) {
      return;
    }
    const continuous = !(xScale instanceof BandScale2);
    const offsetX = !continuous ? xScale.bandwidth / 2 : 0;
    const n = yData.length;
    const nodeData = [];
    const fillData = [];
    const strokeData = [];
    let firstValidX;
    let lastValidX;
    let previousX;
    let nextX;
    const yZero = yScale.convert(0);
    for (let i = 0; i < n; i++) {
      const yDatum = yData[i];
      const xDatum = xData[i];
      const x = xScale.convert(continuous ? xScale.toDomain(xDatum) : xDatum) + offsetX;
      const y = yDatum === void 0 ? NaN : yScale.convert(yDatum);
      if (i + 1 < n) {
        nextX = xScale.convert(continuous ? xScale.toDomain(xData[i + 1]) : xData[i + 1]) + offsetX;
      }
      strokeData.push({
        seriesDatum: { x: xDatum, y: yDatum },
        point: { x, y }
      });
      if (yDatum === void 0 && previousX !== void 0) {
        fillData.push({ seriesDatum: void 0, point: { x: previousX, y: yZero } });
        if (nextX !== void 0) {
          fillData.push({ seriesDatum: void 0, point: { x: nextX, y: yZero } });
        }
      } else if (yDatum !== void 0) {
        fillData.push({
          seriesDatum: { x: xDatum, y: yDatum },
          point: { x, y }
        });
        nodeData.push({
          seriesDatum: { x: xDatum, y: yDatum },
          point: { x, y }
        });
        firstValidX = firstValidX !== void 0 ? firstValidX : x;
        lastValidX = x;
      }
      previousX = x;
    }
    fillData.push(
      { seriesDatum: void 0, point: { x: lastValidX, y: yZero } },
      { seriesDatum: void 0, point: { x: firstValidX, y: yZero } }
    );
    return { nodeData, fillData, strokeData };
  }
  updateAxisLine() {
    const { xScale, yScale, axis, xAxisLine } = this;
    xAxisLine.x1 = xScale.range[0];
    xAxisLine.x2 = xScale.range[1];
    xAxisLine.y1 = xAxisLine.y2 = 0;
    xAxisLine.stroke = axis.stroke;
    xAxisLine.strokeWidth = axis.strokeWidth;
    const yZero = yScale.convert(0);
    xAxisLine.translationY = yZero;
  }
  updateSelection(selectionData) {
    this.markerSelection.update(selectionData);
  }
  updateNodes() {
    const { highlightedDatum, highlightStyle, marker } = this;
    const {
      size: highlightSize,
      fill: highlightFill,
      stroke: highlightStroke,
      strokeWidth: highlightStrokeWidth
    } = highlightStyle;
    const markerFormatter = marker.formatter;
    this.markerSelection.each((node, datum, index) => {
      const { point, seriesDatum } = datum;
      if (!point) {
        return;
      }
      const highlighted = datum === highlightedDatum;
      const markerFill = highlighted && highlightFill !== void 0 ? highlightFill : marker.fill;
      const markerStroke = highlighted && highlightStroke !== void 0 ? highlightStroke : marker.stroke;
      const markerStrokeWidth = highlighted && highlightStrokeWidth !== void 0 ? highlightStrokeWidth : marker.strokeWidth;
      const markerSize = highlighted && highlightSize !== void 0 ? highlightSize : marker.size;
      let markerFormat;
      if (markerFormatter) {
        const first = index === 0;
        const last = index === this.markerSelectionData.length - 1;
        const min = seriesDatum.y === this.min;
        const max = seriesDatum.y === this.max;
        markerFormat = markerFormatter({
          datum,
          xValue: seriesDatum.x,
          yValue: seriesDatum.y,
          min,
          max,
          first,
          last,
          fill: markerFill,
          stroke: markerStroke,
          strokeWidth: markerStrokeWidth,
          size: markerSize,
          highlighted
        });
      }
      node.size = markerFormat && markerFormat.size != void 0 ? markerFormat.size : markerSize;
      node.fill = markerFormat && markerFormat.fill != void 0 ? markerFormat.fill : markerFill;
      node.stroke = markerFormat && markerFormat.stroke != void 0 ? markerFormat.stroke : markerStroke;
      node.strokeWidth = markerFormat && markerFormat.strokeWidth != void 0 ? markerFormat.strokeWidth : markerStrokeWidth;
      node.translationX = point.x;
      node.translationY = point.y;
      node.visible = markerFormat && markerFormat.enabled != void 0 ? markerFormat.enabled : marker.enabled && node.size > 0;
    });
  }
  updateStroke(strokeData) {
    const { strokePath, yData, line } = this;
    const path = strokePath.path;
    path.clear();
    if (yData.length < 2) {
      return;
    }
    const n = strokeData.length;
    let moveTo = true;
    for (let i = 0; i < n; i++) {
      const { point, seriesDatum } = strokeData[i];
      const x = point.x;
      const y = point.y;
      if (seriesDatum.y == void 0) {
        moveTo = true;
      } else {
        if (moveTo) {
          path.moveTo(x, y);
          moveTo = false;
        } else {
          path.lineTo(x, y);
        }
      }
    }
    strokePath.lineJoin = strokePath.lineCap = "round";
    strokePath.fill = void 0;
    strokePath.stroke = line.stroke;
    strokePath.strokeWidth = line.strokeWidth;
  }
  updateFill(areaData) {
    const { fillPath, yData, fill } = this;
    const path = fillPath.path;
    const n = areaData.length;
    path.clear();
    if (yData.length < 2) {
      return;
    }
    for (let i = 0; i < n; i++) {
      const { point } = areaData[i];
      const x = point.x;
      const y = point.y;
      if (i > 0) {
        path.lineTo(x, y);
      } else {
        path.moveTo(x, y);
      }
    }
    path.closePath();
    fillPath.lineJoin = "round";
    fillPath.stroke = void 0;
    fillPath.fill = fill;
  }
  updateXCrosshairLine() {
    var _a;
    const {
      yScale,
      xCrosshairLine,
      highlightedDatum,
      crosshairs: { xLine }
    } = this;
    if (!xLine.enabled || highlightedDatum == void 0) {
      xCrosshairLine.strokeWidth = 0;
      return;
    }
    xCrosshairLine.y1 = yScale.range[0];
    xCrosshairLine.y2 = yScale.range[1];
    xCrosshairLine.x1 = xCrosshairLine.x2 = 0;
    xCrosshairLine.stroke = xLine.stroke;
    xCrosshairLine.strokeWidth = (_a = xLine.strokeWidth) != null ? _a : 1;
    xCrosshairLine.lineCap = xLine.lineCap === "round" || xLine.lineCap === "square" ? xLine.lineCap : void 0;
    const { lineDash } = xLine;
    xCrosshairLine.lineDash = Array.isArray(lineDash) ? lineDash : getLineDash(xCrosshairLine.lineCap, xLine.lineDash);
    xCrosshairLine.translationX = highlightedDatum.point.x;
  }
  updateYCrosshairLine() {
    var _a;
    const {
      xScale,
      yCrosshairLine,
      highlightedDatum,
      crosshairs: { yLine }
    } = this;
    if (!yLine.enabled || highlightedDatum == void 0) {
      yCrosshairLine.strokeWidth = 0;
      return;
    }
    yCrosshairLine.x1 = xScale.range[0];
    yCrosshairLine.x2 = xScale.range[1];
    yCrosshairLine.y1 = yCrosshairLine.y2 = 0;
    yCrosshairLine.stroke = yLine.stroke;
    yCrosshairLine.strokeWidth = (_a = yLine.strokeWidth) != null ? _a : 1;
    yCrosshairLine.lineCap = yLine.lineCap === "round" || yLine.lineCap === "square" ? yLine.lineCap : void 0;
    const { lineDash } = yLine;
    yCrosshairLine.lineDash = Array.isArray(lineDash) ? lineDash : getLineDash(yCrosshairLine.lineCap, yLine.lineDash);
    yCrosshairLine.translationY = highlightedDatum.point.y;
  }
  getTooltipHtml(datum) {
    var _a, _b;
    const { dataType } = this;
    const { seriesDatum } = datum;
    const yValue = seriesDatum.y;
    const xValue = seriesDatum.x;
    const content = this.formatNumericDatum(yValue);
    const title = dataType === "array" || dataType === "object" ? this.formatDatum(xValue) : void 0;
    const defaults = {
      content,
      title
    };
    const tooltipRenderer = (_b = (_a = this.processedOptions) == null ? void 0 : _a.tooltip) == null ? void 0 : _b.renderer;
    if (tooltipRenderer) {
      return toTooltipHtml(
        tooltipRenderer({
          context: this.context,
          datum: seriesDatum,
          yValue,
          xValue
        }),
        defaults
      );
    }
    return toTooltipHtml(defaults);
  }
};
AreaSparkline.className = "AreaSparkline";
var { extent: extent3 } = _Util32;
var { BandScale: BandScale3 } = _Scale3;
var SparklineMarker2 = class {
  constructor() {
    this.enabled = true;
    this.shape = "circle";
    this.size = 0;
    this.fill = "rgb(124, 181, 236)";
    this.stroke = "rgb(124, 181, 236)";
    this.strokeWidth = 1;
    this.formatter = void 0;
  }
};
var SparklineLine2 = class {
  constructor() {
    this.stroke = "rgb(124, 181, 236)";
    this.strokeWidth = 1;
  }
};
var SparklineCrosshairs2 = class {
  constructor() {
    this.xLine = {
      enabled: true,
      stroke: "rgba(0,0,0, 0.54)",
      strokeWidth: 1,
      lineDash: "solid",
      lineCap: void 0
    };
    this.yLine = {
      enabled: false,
      stroke: "rgba(0,0,0, 0.54)",
      strokeWidth: 1,
      lineDash: "solid",
      lineCap: void 0
    };
  }
};
var LineSparkline = class extends Sparkline {
  constructor() {
    super();
    this.linePath = new _Scene42.Path();
    this.xCrosshairLine = new _Scene42.Line();
    this.yCrosshairLine = new _Scene42.Line();
    this.lineSparklineGroup = new _Scene42.Group();
    this.markers = new _Scene42.Group();
    this.markerSelection = _Scene42.Selection.select(
      this.markers,
      () => this.markerFactory()
    );
    this.markerSelectionData = [];
    this.marker = new SparklineMarker2();
    this.line = new SparklineLine2();
    this.crosshairs = new SparklineCrosshairs2();
    this.rootGroup.append(this.lineSparklineGroup);
    this.linePath.zIndex = 1e3;
    this.xCrosshairLine.zIndex = 2e3;
    this.yCrosshairLine.zIndex = 2e3;
    this.markers.zIndex = 2500;
    this.lineSparklineGroup.append([this.linePath, this.xCrosshairLine, this.yCrosshairLine, this.markers]);
  }
  getNodeData() {
    return this.markerSelectionData;
  }
  markerFactory() {
    const { shape } = this.marker;
    const MarkerShape = getMarker(shape);
    return new MarkerShape();
  }
  /**
   * If marker shape is changed, this method should be called to remove the previous marker nodes selection.
   */
  onMarkerShapeChange() {
    this.markerSelection = this.markerSelection.clear();
    this.scheduleLayout();
  }
  update() {
    const nodeData = this.generateNodeData();
    if (!nodeData) {
      return;
    }
    this.markerSelectionData = nodeData;
    this.updateSelection(nodeData);
    this.updateNodes();
    this.updateLine();
  }
  updateYScaleDomain() {
    const { yData, yScale } = this;
    const yMinMax = extent3(yData);
    let yMin = 0;
    let yMax = 1;
    if (yMinMax !== void 0) {
      yMin = this.min = yMinMax[0];
      yMax = this.max = yMinMax[1];
    }
    if (yMin === yMax) {
      const padding = Math.abs(yMin * 0.01);
      yMin -= padding;
      yMax += padding;
    }
    yScale.domain = [yMin, yMax];
  }
  generateNodeData() {
    const { data, yData, xData, xScale, yScale } = this;
    if (!data) {
      return;
    }
    const continuous = !(xScale instanceof BandScale3);
    const offsetX = !continuous ? xScale.bandwidth / 2 : 0;
    const nodeData = [];
    for (let i = 0; i < yData.length; i++) {
      const yDatum = yData[i];
      const xDatum = xData[i];
      if (yDatum == void 0) {
        continue;
      }
      const x = xScale.convert(continuous ? xScale.toDomain(xDatum) : xDatum) + offsetX;
      const y = yDatum === void 0 ? NaN : yScale.convert(yDatum);
      nodeData.push({
        seriesDatum: { x: xDatum, y: yDatum },
        point: { x, y }
      });
    }
    return nodeData;
  }
  updateSelection(selectionData) {
    this.markerSelection.update(selectionData);
  }
  updateNodes() {
    const { highlightedDatum, highlightStyle, marker } = this;
    const {
      size: highlightSize,
      fill: highlightFill,
      stroke: highlightStroke,
      strokeWidth: highlightStrokeWidth
    } = highlightStyle;
    const markerFormatter = marker.formatter;
    this.markerSelection.each((node, datum, index) => {
      const highlighted = datum === highlightedDatum;
      const markerFill = highlighted && highlightFill !== void 0 ? highlightFill : marker.fill;
      const markerStroke = highlighted && highlightStroke !== void 0 ? highlightStroke : marker.stroke;
      const markerStrokeWidth = highlighted && highlightStrokeWidth !== void 0 ? highlightStrokeWidth : marker.strokeWidth;
      const markerSize = highlighted && highlightSize !== void 0 ? highlightSize : marker.size;
      let markerFormat;
      const { seriesDatum, point } = datum;
      if (markerFormatter) {
        const first = index === 0;
        const last = index === this.markerSelectionData.length - 1;
        const min = seriesDatum.y === this.min;
        const max = seriesDatum.y === this.max;
        markerFormat = markerFormatter({
          datum,
          xValue: seriesDatum.x,
          yValue: seriesDatum.y,
          min,
          max,
          first,
          last,
          fill: markerFill,
          stroke: markerStroke,
          strokeWidth: markerStrokeWidth,
          size: markerSize,
          highlighted
        });
      }
      node.size = markerFormat && markerFormat.size != void 0 ? markerFormat.size : markerSize;
      node.fill = markerFormat && markerFormat.fill != void 0 ? markerFormat.fill : markerFill;
      node.stroke = markerFormat && markerFormat.stroke != void 0 ? markerFormat.stroke : markerStroke;
      node.strokeWidth = markerFormat && markerFormat.strokeWidth != void 0 ? markerFormat.strokeWidth : markerStrokeWidth;
      node.translationX = point.x;
      node.translationY = point.y;
      node.visible = markerFormat && markerFormat.enabled != void 0 ? markerFormat.enabled : marker.enabled && node.size > 0;
    });
  }
  updateLine() {
    const { linePath, yData, xData, xScale, yScale, line } = this;
    const path = linePath.path;
    path.clear();
    if (yData.length < 2) {
      return;
    }
    const continuous = !(xScale instanceof BandScale3);
    const n = yData.length;
    const offsetX = !continuous ? xScale.bandwidth / 2 : 0;
    let moveTo = true;
    for (let i = 0; i < n; i++) {
      const xDatum = xData[i];
      const yDatum = yData[i];
      const x = xScale.convert(continuous ? xScale.toDomain(xDatum) : xDatum) + offsetX;
      const y = yDatum === void 0 ? NaN : yScale.convert(yDatum);
      if (yDatum == void 0) {
        moveTo = true;
      } else {
        if (moveTo) {
          path.moveTo(x, y);
          moveTo = false;
        } else {
          path.lineTo(x, y);
        }
      }
    }
    linePath.fill = void 0;
    linePath.stroke = line.stroke;
    linePath.strokeWidth = line.strokeWidth;
  }
  updateXCrosshairLine() {
    var _a;
    const {
      yScale,
      xCrosshairLine,
      highlightedDatum,
      crosshairs: { xLine }
    } = this;
    if (!xLine.enabled || highlightedDatum == void 0) {
      xCrosshairLine.strokeWidth = 0;
      return;
    }
    xCrosshairLine.y1 = yScale.range[0];
    xCrosshairLine.y2 = yScale.range[1];
    xCrosshairLine.x1 = xCrosshairLine.x2 = 0;
    xCrosshairLine.stroke = xLine.stroke;
    xCrosshairLine.strokeWidth = (_a = xLine.strokeWidth) != null ? _a : 1;
    xCrosshairLine.lineCap = xLine.lineCap === "round" || xLine.lineCap === "square" ? xLine.lineCap : void 0;
    const { lineDash } = xLine;
    xCrosshairLine.lineDash = Array.isArray(lineDash) ? lineDash : getLineDash(xCrosshairLine.lineCap, xLine.lineDash);
    xCrosshairLine.translationX = highlightedDatum.point.x;
  }
  updateYCrosshairLine() {
    var _a;
    const {
      xScale,
      yCrosshairLine,
      highlightedDatum,
      crosshairs: { yLine }
    } = this;
    if (!yLine.enabled || highlightedDatum == void 0) {
      yCrosshairLine.strokeWidth = 0;
      return;
    }
    yCrosshairLine.x1 = xScale.range[0];
    yCrosshairLine.x2 = xScale.range[1];
    yCrosshairLine.y1 = yCrosshairLine.y2 = 0;
    yCrosshairLine.stroke = yLine.stroke;
    yCrosshairLine.strokeWidth = (_a = yLine.strokeWidth) != null ? _a : 1;
    yCrosshairLine.lineCap = yLine.lineCap === "round" || yLine.lineCap === "square" ? yLine.lineCap : void 0;
    const { lineDash } = yLine;
    yCrosshairLine.lineDash = Array.isArray(lineDash) ? lineDash : getLineDash(yCrosshairLine.lineCap, yLine.lineDash);
    yCrosshairLine.translationY = highlightedDatum.point.y;
  }
  getTooltipHtml(datum) {
    var _a, _b;
    const { dataType } = this;
    const { seriesDatum } = datum;
    const yValue = seriesDatum.y;
    const xValue = seriesDatum.x;
    const content = this.formatNumericDatum(yValue);
    const title = dataType === "array" || dataType === "object" ? this.formatDatum(xValue) : void 0;
    const defaults = {
      content,
      title
    };
    const tooltipRenderer = (_b = (_a = this.processedOptions) == null ? void 0 : _a.tooltip) == null ? void 0 : _b.renderer;
    if (tooltipRenderer) {
      return toTooltipHtml(
        tooltipRenderer({
          context: this.context,
          datum: seriesDatum,
          yValue,
          xValue
        }),
        defaults
      );
    }
    return toTooltipHtml(defaults);
  }
};
LineSparkline.className = "LineSparkline";
var Label = class {
  constructor() {
    this.enabled = true;
    this.fontSize = 8;
    this.fontFamily = "Verdana, sans-serif";
    this.fontStyle = void 0;
    this.fontWeight = void 0;
    this.color = "rgba(70, 70, 70, 1)";
  }
};
var { extent: extent4 } = _Util42;
var BarColumnLabel = class extends Label {
  constructor() {
    super(...arguments);
    this.formatter = void 0;
    this.placement = "insideEnd";
  }
};
var BarColumnSparkline = class extends Sparkline {
  constructor() {
    super();
    this.fill = "rgb(124, 181, 236)";
    this.stroke = "silver";
    this.strokeWidth = 0;
    this.paddingInner = 0.1;
    this.paddingOuter = 0.2;
    this.valueAxisDomain = void 0;
    this.formatter = void 0;
    this.axisLine = new _Scene52.Line();
    this.bandWidth = 0;
    this.sparklineGroup = new _Scene52.Group();
    this.rectGroup = new _Scene52.Group();
    this.labelGroup = new _Scene52.Group();
    this.rectSelection = _Scene52.Selection.select(
      this.rectGroup,
      _Scene52.Rect
    );
    this.labelSelection = _Scene52.Selection.select(
      this.labelGroup,
      _Scene52.Text
    );
    this.nodeSelectionData = [];
    this.label = new BarColumnLabel();
    this.rootGroup.append(this.sparklineGroup);
    this.rectGroup.zIndex = 50;
    this.axisLine.zIndex = 500;
    this.labelGroup.zIndex = 1500;
    this.sparklineGroup.append([this.rectGroup, this.axisLine, this.labelGroup]);
    this.axisLine.lineCap = "round";
    this.label.enabled = false;
  }
  getNodeData() {
    return this.nodeSelectionData;
  }
  update() {
    this.updateSelections();
    this.updateNodes();
  }
  updateSelections() {
    const nodeData = this.generateNodeData();
    if (!nodeData) {
      return;
    }
    this.nodeSelectionData = nodeData;
    this.updateRectSelection(nodeData);
    this.updateLabelSelection(nodeData);
  }
  updateNodes() {
    this.updateRectNodes();
    this.updateLabelNodes();
  }
  calculateStep(range) {
    var _a;
    const { xScale, paddingInner, paddingOuter, smallestInterval } = this;
    let domainLength = xScale.domain[1] - xScale.domain[0];
    let intervals = domainLength / ((_a = smallestInterval == null ? void 0 : smallestInterval.x) != null ? _a : 1) + 1;
    const maxBands = 50;
    const bands = Math.min(intervals, maxBands);
    const gaps = bands - 1;
    const step = range / Math.max(1, 2 * paddingOuter + gaps * paddingInner + bands);
    return step;
  }
  updateYScaleDomain() {
    const { yScale, yData, valueAxisDomain } = this;
    const yMinMax = extent4(yData);
    let yMin = 0;
    let yMax = 1;
    if (yMinMax !== void 0) {
      yMin = this.min = yMinMax[0];
      yMax = this.max = yMinMax[1];
    }
    yMin = yMin < 0 ? yMin : 0;
    yMax = yMax < 0 ? 0 : yMax;
    if (valueAxisDomain) {
      if (valueAxisDomain[1] < yMax) {
        valueAxisDomain[1] = yMax;
      }
      if (valueAxisDomain[0] > yMin) {
        valueAxisDomain[0] = yMin;
      }
    }
    yScale.domain = valueAxisDomain ? valueAxisDomain : [yMin, yMax];
  }
  updateRectSelection(selectionData) {
    this.rectSelection.update(selectionData);
  }
  updateRectNodes() {
    const { highlightedDatum, formatter: nodeFormatter, fill, stroke, strokeWidth } = this;
    const { fill: highlightFill, stroke: highlightStroke, strokeWidth: highlightStrokeWidth } = this.highlightStyle;
    this.rectSelection.each((node, datum, index) => {
      const highlighted = datum === highlightedDatum;
      const nodeFill = highlighted && highlightFill !== void 0 ? highlightFill : fill;
      const nodeStroke = highlighted && highlightStroke !== void 0 ? highlightStroke : stroke;
      const nodeStrokeWidth = highlighted && highlightStrokeWidth !== void 0 ? highlightStrokeWidth : strokeWidth;
      let nodeFormat;
      const { x, y, width, height, seriesDatum } = datum;
      if (nodeFormatter) {
        const first = index === 0;
        const last = index === this.nodeSelectionData.length - 1;
        const min = seriesDatum.y === this.min;
        const max = seriesDatum.y === this.max;
        nodeFormat = nodeFormatter({
          datum,
          xValue: seriesDatum.x,
          yValue: seriesDatum.y,
          width,
          height,
          min,
          max,
          first,
          last,
          fill: nodeFill,
          stroke: nodeStroke,
          strokeWidth: nodeStrokeWidth,
          highlighted
        });
      }
      node.fill = nodeFormat && nodeFormat.fill || nodeFill;
      node.stroke = nodeFormat && nodeFormat.stroke || nodeStroke;
      node.strokeWidth = nodeFormat && nodeFormat.strokeWidth || nodeStrokeWidth;
      node.x = node.y = 0;
      node.width = width;
      node.height = height;
      node.visible = node.height > 0;
      node.translationX = x;
      node.translationY = y;
    });
  }
  updateLabelSelection(selectionData) {
    this.labelSelection.update(selectionData, (text) => {
      text.tag = 1;
      text.pointerEvents = _Scene52.PointerEvents.None;
    });
  }
  updateLabelNodes() {
    const {
      label: { enabled: labelEnabled, fontStyle, fontWeight, fontSize, fontFamily, color }
    } = this;
    this.labelSelection.each((text, datum) => {
      const label = datum.label;
      if (label && labelEnabled) {
        text.fontStyle = fontStyle;
        text.fontWeight = fontWeight;
        text.fontSize = fontSize;
        text.fontFamily = fontFamily;
        text.textAlign = label.textAlign;
        text.textBaseline = label.textBaseline;
        text.text = label.text;
        text.x = label.x;
        text.y = label.y;
        text.fill = color;
        text.visible = true;
      } else {
        text.visible = false;
      }
    });
  }
  getTooltipHtml(datum) {
    var _a, _b;
    const { dataType } = this;
    const { seriesDatum } = datum;
    const yValue = seriesDatum.y;
    const xValue = seriesDatum.x;
    const content = this.formatNumericDatum(yValue);
    const title = dataType === "array" || dataType === "object" ? this.formatDatum(xValue) : void 0;
    const defaults = {
      content,
      title
    };
    const tooltipRenderer = (_b = (_a = this.processedOptions) == null ? void 0 : _a.tooltip) == null ? void 0 : _b.renderer;
    if (tooltipRenderer) {
      return toTooltipHtml(
        tooltipRenderer({
          context: this.context,
          datum: seriesDatum,
          yValue,
          xValue
        }),
        defaults
      );
    }
    return toTooltipHtml(defaults);
  }
  formatLabelValue(value) {
    return value % 1 !== 0 ? value.toFixed(1) : value.toFixed(0);
  }
};
var { isNumber: isNumber2 } = _Util52;
var { BandScale: BandScale4 } = _Scale4;
var BarSparkline = class extends BarColumnSparkline {
  updateYScaleRange() {
    const { seriesRect, yScale } = this;
    yScale.range = [0, seriesRect.width];
  }
  updateXScaleRange() {
    const { xScale, seriesRect, paddingOuter, paddingInner } = this;
    if (xScale instanceof BandScale4) {
      xScale.range = [0, seriesRect.height];
      xScale.paddingInner = paddingInner;
      xScale.paddingOuter = paddingOuter;
    } else {
      const step = this.calculateStep(seriesRect.height);
      const padding = step * paddingOuter;
      this.bandWidth = step * (1 - paddingInner);
      xScale.range = [padding, seriesRect.height - padding - this.bandWidth];
    }
  }
  updateAxisLine() {
    const { yScale, axis, axisLine, seriesRect } = this;
    const { strokeWidth } = axis;
    axisLine.x1 = 0;
    axisLine.x2 = 0;
    axisLine.y1 = 0;
    axisLine.y2 = seriesRect.height;
    axisLine.stroke = axis.stroke;
    axisLine.strokeWidth = strokeWidth + (strokeWidth % 2 === 1 ? 1 : 0);
    const yZero = yScale.convert(0);
    axisLine.translationX = yZero;
  }
  generateNodeData() {
    const { data, yData, xData, xScale, yScale, fill, stroke, strokeWidth, label } = this;
    if (!data) {
      return;
    }
    const {
      fontStyle: labelFontStyle,
      fontWeight: labelFontWeight,
      fontSize: labelFontSize,
      fontFamily: labelFontFamily,
      color: labelColor,
      formatter: labelFormatter,
      placement: labelPlacement
    } = label;
    const nodeData = [];
    const yZero = yScale.convert(0);
    const continuous = !(xScale instanceof BandScale4);
    for (let i = 0, n = yData.length; i < n; i++) {
      let yDatum = yData[i];
      const xDatum = xData[i];
      const invalidDatum = yDatum === void 0;
      if (invalidDatum) {
        yDatum = 0;
      }
      const y = xScale.convert(continuous ? xScale.toDomain(xDatum) : xDatum);
      const x = Math.min(yDatum === void 0 ? NaN : yScale.convert(yDatum), yZero);
      const bottom = Math.max(yDatum === void 0 ? NaN : yScale.convert(yDatum), yZero);
      const height = !continuous ? xScale.bandwidth : this.bandWidth;
      const width = bottom - x;
      const midPoint = {
        x: yZero,
        y
      };
      let labelText;
      if (labelFormatter) {
        labelText = labelFormatter({ value: yDatum });
      } else {
        labelText = yDatum !== void 0 && isNumber2(yDatum) ? this.formatLabelValue(yDatum) : "";
      }
      const labelY = y + height / 2;
      let labelX;
      const labelTextBaseline = "middle";
      let labelTextAlign;
      const isPositiveY = yDatum !== void 0 && yDatum >= 0;
      const labelPadding = 4;
      if (labelPlacement === "center") {
        labelX = x + width / 2;
        labelTextAlign = "center";
      } else if (labelPlacement === "outsideEnd") {
        labelX = x + (isPositiveY ? width + labelPadding : -labelPadding);
        labelTextAlign = isPositiveY ? "start" : "end";
      } else if (labelPlacement === "insideEnd") {
        labelX = x + (isPositiveY ? width - labelPadding : labelPadding);
        labelTextAlign = isPositiveY ? "end" : "start";
        const textSize = _Scene62.Text.getTextSize(labelText, labelFontFamily);
        const textWidth = textSize.width || 20;
        const positiveBoundary = yZero + textWidth;
        const negativeBoundary = yZero - textWidth;
        const exceedsBoundaries = isPositiveY && labelX < positiveBoundary || !isPositiveY && labelX > negativeBoundary;
        if (exceedsBoundaries) {
          labelX = yZero + labelPadding * (isPositiveY ? 1 : -1);
          labelTextAlign = isPositiveY ? "start" : "end";
        }
      } else {
        labelX = yZero + labelPadding * (isPositiveY ? 1 : -1);
        labelTextAlign = isPositiveY ? "start" : "end";
      }
      nodeData.push({
        x,
        y,
        width,
        height,
        fill,
        stroke,
        strokeWidth,
        seriesDatum: { x: xDatum, y: invalidDatum ? void 0 : yDatum },
        point: midPoint,
        label: {
          x: labelX,
          y: labelY,
          text: labelText,
          fontStyle: labelFontStyle,
          fontWeight: labelFontWeight,
          fontSize: labelFontSize,
          fontFamily: labelFontFamily,
          textAlign: labelTextAlign,
          textBaseline: labelTextBaseline,
          fill: labelColor
        }
      });
    }
    return nodeData;
  }
  getDistance(p1, p2) {
    return Math.abs(p1.y - p2.y);
  }
};
BarSparkline.className = "BarSparkline";
var { isNumber: isNumber3 } = _Util62;
var { BandScale: BandScale5 } = _Scale5;
var ColumnSparkline = class extends BarColumnSparkline {
  updateYScaleRange() {
    const { seriesRect, yScale } = this;
    yScale.range = [seriesRect.height, 0];
  }
  updateXScaleRange() {
    const { xScale, seriesRect, paddingOuter, paddingInner } = this;
    if (xScale instanceof BandScale5) {
      xScale.range = [0, seriesRect.width];
      xScale.paddingInner = paddingInner;
      xScale.paddingOuter = paddingOuter;
    } else {
      const step = this.calculateStep(seriesRect.width);
      const padding = step * paddingOuter;
      this.bandWidth = step * (1 - paddingInner);
      xScale.range = [padding, seriesRect.width - padding - this.bandWidth];
    }
  }
  updateAxisLine() {
    const { yScale, axis, axisLine, seriesRect } = this;
    const { strokeWidth } = axis;
    axisLine.x1 = 0;
    axisLine.x2 = seriesRect.width;
    axisLine.y1 = 0;
    axisLine.y2 = 0;
    axisLine.stroke = axis.stroke;
    axisLine.strokeWidth = strokeWidth + (strokeWidth % 2 === 1 ? 1 : 0);
    const yZero = yScale.convert(0);
    axisLine.translationY = yZero;
  }
  generateNodeData() {
    const { data, yData, xData, xScale, yScale, fill, stroke, strokeWidth, label } = this;
    if (!data) {
      return;
    }
    const {
      fontStyle: labelFontStyle,
      fontWeight: labelFontWeight,
      fontSize: labelFontSize,
      fontFamily: labelFontFamily,
      color: labelColor,
      formatter: labelFormatter,
      placement: labelPlacement
    } = label;
    const nodeData = [];
    const yZero = yScale.convert(0);
    const continuous = !(xScale instanceof BandScale5);
    for (let i = 0, n = yData.length; i < n; i++) {
      let yDatum = yData[i];
      const xDatum = xData[i];
      const invalidDatum = yDatum === void 0;
      if (invalidDatum) {
        yDatum = 0;
      }
      const y = Math.min(yDatum === void 0 ? NaN : yScale.convert(yDatum), yZero);
      const x = xScale.convert(continuous ? xScale.toDomain(xDatum) : xDatum);
      const bottom = Math.max(yDatum === void 0 ? NaN : yScale.convert(yDatum), yZero);
      const width = !continuous ? xScale.bandwidth : this.bandWidth;
      const height = bottom - y;
      const midPoint = {
        x: x + width / 2,
        y: yZero
      };
      let labelText;
      if (labelFormatter) {
        labelText = labelFormatter({ value: yDatum });
      } else {
        labelText = yDatum !== void 0 && isNumber3(yDatum) ? this.formatLabelValue(yDatum) : "";
      }
      const labelX = x + width / 2;
      let labelY;
      const labelTextAlign = "center";
      let labelTextBaseline;
      const isPositiveY = yDatum !== void 0 && yDatum >= 0;
      const labelPadding = 2;
      if (labelPlacement === "center") {
        labelY = y + height / 2;
        labelTextBaseline = "middle";
      } else if (labelPlacement === "outsideEnd") {
        labelY = y + (isPositiveY ? -labelPadding : height + labelPadding);
        labelTextBaseline = isPositiveY ? "bottom" : "top";
      } else if (labelPlacement === "insideEnd") {
        labelY = y + (isPositiveY ? labelPadding : height - labelPadding);
        labelTextBaseline = isPositiveY ? "top" : "bottom";
        const textSize = _Scene72.Text.getTextSize(labelText, labelFontFamily);
        const textHeight = textSize.height || 10;
        const positiveBoundary = yZero - textHeight;
        const negativeBoundary = yZero + textHeight;
        const exceedsBoundaries = isPositiveY && labelY > positiveBoundary || !isPositiveY && labelY < negativeBoundary;
        if (exceedsBoundaries) {
          labelY = yZero + labelPadding * (isPositiveY ? -1 : 1);
          labelTextBaseline = isPositiveY ? "bottom" : "top";
        }
      } else {
        labelY = yZero + labelPadding * (isPositiveY ? -1 : 1);
        labelTextBaseline = isPositiveY ? "bottom" : "top";
      }
      nodeData.push({
        x,
        y,
        width,
        height,
        fill,
        stroke,
        strokeWidth,
        seriesDatum: { x: xDatum, y: invalidDatum ? void 0 : yDatum },
        point: midPoint,
        label: {
          x: labelX,
          y: labelY,
          text: labelText,
          fontStyle: labelFontStyle,
          fontWeight: labelFontWeight,
          fontSize: labelFontSize,
          fontFamily: labelFontFamily,
          textAlign: labelTextAlign,
          textBaseline: labelTextBaseline,
          fill: labelColor
        }
      });
    }
    return nodeData;
  }
};
ColumnSparkline.className = "ColumnSparkline";
var { isNumber: isNumber4 } = _Util7;
var AgSparkline = class {
  static create(options, tooltip) {
    options = _Util7.deepClone(options);
    const sparkline = getSparklineInstance(options.type);
    if (tooltip) {
      sparkline.tooltip = tooltip;
    }
    initSparkline(sparkline, options);
    initSparklineByType(sparkline, options);
    if (options.data) {
      sparkline.data = options.data;
    }
    sparkline.processedOptions = options;
    return sparkline;
  }
};
function getSparklineInstance(type = "line") {
  switch (type) {
    case "column":
      return new ColumnSparkline();
    case "bar":
      return new BarSparkline();
    case "area":
      return new AreaSparkline();
    case "line":
    default:
      return new LineSparkline();
  }
}
function initSparklineByType(sparkline, options) {
  switch (options.type) {
    case "bar":
      initBarColumnSparkline(sparkline, options);
      break;
    case "column":
      initBarColumnSparkline(sparkline, options);
      break;
    case "area":
      initAreaSparkline(sparkline, options);
      break;
    case "line":
    default:
      initLineSparkline(sparkline, options);
      break;
  }
}
function initSparkline(sparkline, options) {
  setValueIfPropertyExists(sparkline, "context", options.context, options);
  setValueIfPropertyExists(sparkline, "width", options.width, options);
  setValueIfPropertyExists(sparkline, "height", options.height, options);
  setValueIfPropertyExists(sparkline, "container", options.container, options);
  setValueIfPropertyExists(sparkline, "xKey", options.xKey, options);
  setValueIfPropertyExists(sparkline, "yKey", options.yKey, options);
  if (options.padding) {
    initPaddingOptions(sparkline.padding, options.padding);
  }
  if (options.axis) {
    initAxisOptions(sparkline.axis, options.axis);
  }
  if (options.highlightStyle) {
    initHighlightStyleOptions(sparkline.highlightStyle, options.highlightStyle);
  }
}
function initLineSparkline(sparkline, options) {
  if (options.marker) {
    initMarkerOptions(sparkline.marker, options.marker);
  }
  if (options.line) {
    initLineOptions(sparkline.line, options.line);
  }
  if (options.crosshairs) {
    initCrosshairsOptions(sparkline.crosshairs, options.crosshairs);
  }
}
function initAreaSparkline(sparkline, options) {
  setValueIfPropertyExists(sparkline, "fill", options.fill, options);
  if (options.marker) {
    initMarkerOptions(sparkline.marker, options.marker);
  }
  if (options.line) {
    initLineOptions(sparkline.line, options.line);
  }
  if (options.crosshairs) {
    initCrosshairsOptions(sparkline.crosshairs, options.crosshairs);
  }
}
function initBarColumnSparkline(sparkline, options) {
  setValueIfPropertyExists(sparkline, "valueAxisDomain", options.valueAxisDomain, options);
  setValueIfPropertyExists(sparkline, "fill", options.fill, options);
  setValueIfPropertyExists(sparkline, "stroke", options.stroke, options);
  setValueIfPropertyExists(sparkline, "strokeWidth", options.strokeWidth, options);
  setValueIfPropertyExists(sparkline, "paddingInner", options.paddingInner, options);
  setValueIfPropertyExists(sparkline, "paddingOuter", options.paddingOuter, options);
  setValueIfPropertyExists(sparkline, "formatter", options.formatter, options);
  if (options.label) {
    initLabelOptions(sparkline.label, options.label);
  }
}
function initPaddingOptions(target, options) {
  setValueIfPropertyExists(target, "top", options.top, options);
  setValueIfPropertyExists(target, "right", options.right, options);
  setValueIfPropertyExists(target, "bottom", options.bottom, options);
  setValueIfPropertyExists(target, "left", options.left, options);
}
function initMarkerOptions(target, options) {
  setValueIfPropertyExists(target, "enabled", options.enabled, options);
  setValueIfPropertyExists(target, "size", options.size, options);
  setValueIfPropertyExists(target, "shape", options.shape, options);
  setValueIfPropertyExists(target, "fill", options.fill, options);
  setValueIfPropertyExists(target, "stroke", options.stroke, options);
  setValueIfPropertyExists(target, "strokeWidth", options.strokeWidth, options);
  setValueIfPropertyExists(target, "formatter", options.formatter, options);
}
function initLabelOptions(target, options) {
  setValueIfPropertyExists(target, "enabled", options.enabled, options);
  setValueIfPropertyExists(target, "fontStyle", options.fontStyle, options);
  setValueIfPropertyExists(target, "fontWeight", options.fontWeight, options);
  setValueIfPropertyExists(target, "fontSize", options.fontSize, options);
  setValueIfPropertyExists(target, "fontFamily", options.fontFamily, options);
  setValueIfPropertyExists(target, "textAlign", options.textAlign, options);
  setValueIfPropertyExists(target, "textBaseline", options.textBaseline, options);
  setValueIfPropertyExists(target, "color", options.color, options);
  setValueIfPropertyExists(target, "formatter", options.formatter, options);
  setValueIfPropertyExists(target, "placement", options.placement, options);
}
function initLineOptions(target, options) {
  setValueIfPropertyExists(target, "stroke", options.stroke, options);
  setValueIfPropertyExists(target, "strokeWidth", options.strokeWidth, options);
}
function initAxisOptions(target, options) {
  setValueIfPropertyExists(target, "type", options.type, options);
  setValueIfPropertyExists(target, "stroke", options.stroke, options);
  setValueIfPropertyExists(target, "strokeWidth", options.strokeWidth, options);
}
function initHighlightStyleOptions(target, options) {
  setValueIfPropertyExists(target, "fill", options.fill, options);
  setValueIfPropertyExists(target, "size", options.size, options);
  setValueIfPropertyExists(target, "stroke", options.stroke, options);
  setValueIfPropertyExists(target, "strokeWidth", options.strokeWidth, options);
}
function initCrosshairsOptions(target, options) {
  if (target.xLine && options.xLine) {
    initCrosshairLineOptions(target.xLine, options.xLine);
  }
  if (target.yLine && options.yLine) {
    initCrosshairLineOptions(target.yLine, options.yLine);
  }
}
function initCrosshairLineOptions(target, options) {
  setValueIfPropertyExists(target, "enabled", options.enabled, options);
  setValueIfPropertyExists(target, "stroke", options.stroke, options);
  setValueIfPropertyExists(target, "strokeWidth", options.strokeWidth, options);
  setValueIfPropertyExists(target, "lineDash", options.lineDash, options);
  setValueIfPropertyExists(target, "lineCap", options.lineCap, options);
}
var doOnceFlags = {};
function doOnce(func, key) {
  if (doOnceFlags[key]) {
    return;
  }
  func();
  doOnceFlags[key] = true;
}
var offsetValidator = (property, value, defaultOffset) => {
  if (isNumber4(value)) {
    return true;
  }
  const message = `AG Charts: ${property} must be a number, the value you provided is not a valid number. Using the default of ${defaultOffset}px.`;
  doOnce(() => console.warn(message), `${property} not a number`);
  return false;
};
var validators = {
  xOffset: offsetValidator,
  yOffset: offsetValidator
};
function setValueIfPropertyExists(target, property, value, options) {
  if (property in options) {
    if (property in target) {
      const validator = validators[property];
      const isValid = validator ? validator(property, value, target[property]) : true;
      if (isValid && target[property] !== value) {
        target[property] = value;
      }
    } else {
      console.warn(`Property ${property} does not exist on the target object.`);
    }
  }
}
var _SparklineCellRenderer = class _SparklineCellRenderer2 extends Component58 {
  constructor() {
    super(_SparklineCellRenderer2.TEMPLATE);
  }
  init(params) {
    let firstTimeIn = true;
    const updateSparkline = () => {
      const { clientWidth, clientHeight } = this.getGui();
      if (clientWidth === 0 || clientHeight === 0) {
        return;
      }
      if (firstTimeIn) {
        const options = __spreadValues13({
          data: params.value,
          width: clientWidth,
          height: clientHeight,
          context: {
            data: params.data
          }
        }, params.sparklineOptions);
        this.sparkline = AgSparkline.create(options, this.sparklineTooltipSingleton.getSparklineTooltip());
        this.eSparkline.appendChild(this.sparkline.canvasElement);
        firstTimeIn = false;
      } else {
        this.sparkline.width = clientWidth;
        this.sparkline.height = clientHeight;
      }
    };
    const unsubscribeFromResize = this.resizeObserverService.observeResize(this.getGui(), updateSparkline);
    this.addDestroyFunc(() => unsubscribeFromResize());
  }
  refresh(params) {
    if (this.sparkline) {
      this.sparkline.data = params.value;
      return true;
    }
    return false;
  }
  destroy() {
    if (this.sparkline) {
      this.sparkline.destroy();
    }
    super.destroy();
  }
};
_SparklineCellRenderer.TEMPLATE = `<div class="ag-sparkline-wrapper">
            <span ref="eSparkline"></span>
        </div>`;
__decorateClass16([
  RefSelector28("eSparkline")
], _SparklineCellRenderer.prototype, "eSparkline", 2);
__decorateClass16([
  Autowired69("resizeObserverService")
], _SparklineCellRenderer.prototype, "resizeObserverService", 2);
__decorateClass16([
  Autowired69("sparklineTooltipSingleton")
], _SparklineCellRenderer.prototype, "sparklineTooltipSingleton", 2);
var SparklineCellRenderer = _SparklineCellRenderer;
var SparklineTooltipSingleton = class extends BeanStub29 {
  postConstruct() {
    this.tooltip = new SparklineTooltip();
  }
  getSparklineTooltip() {
    return this.tooltip;
  }
  destroyTooltip() {
    if (this.tooltip) {
      this.tooltip.destroy();
    }
  }
};
__decorateClass16([
  PostConstruct66
], SparklineTooltipSingleton.prototype, "postConstruct", 1);
__decorateClass16([
  PreDestroy6
], SparklineTooltipSingleton.prototype, "destroyTooltip", 1);
SparklineTooltipSingleton = __decorateClass16([
  Bean15("sparklineTooltipSingleton")
], SparklineTooltipSingleton);
var VERSION17 = "31.3.2";
var SparklinesModule = {
  version: VERSION17,
  moduleName: ModuleNames17.SparklinesModule,
  beans: [SparklineTooltipSingleton],
  userComponents: [{ componentName: "agSparklineCellRenderer", componentClass: SparklineCellRenderer }],
  dependantModules: [EnterpriseCoreModule]
};

// enterprise-modules/status-bar/src/statusBarModule.ts
import { ModuleNames as ModuleNames18 } from "ag-grid-community";

// enterprise-modules/status-bar/src/statusBar/statusBarService.ts
import { Bean as Bean16, BeanStub as BeanStub30 } from "ag-grid-community";
var StatusBarService = class extends BeanStub30 {
  // tslint:disable-next-line
  constructor() {
    super();
    this.allComponents = /* @__PURE__ */ new Map();
  }
  registerStatusPanel(key, component) {
    this.allComponents.set(key, component);
  }
  unregisterStatusPanel(key) {
    this.allComponents.delete(key);
  }
  unregisterAllComponents() {
    this.allComponents.clear();
  }
  getStatusPanel(key) {
    return this.allComponents.get(key);
  }
  destroy() {
    this.unregisterAllComponents();
    super.destroy();
  }
};
StatusBarService = __decorateClass([
  Bean16("statusBarService")
], StatusBarService);

// enterprise-modules/status-bar/src/statusBar/statusBar.ts
import {
  Autowired as Autowired70,
  Component as Component59,
  PostConstruct as PostConstruct67,
  PreDestroy as PreDestroy7,
  AgPromise as AgPromise7,
  RefSelector as RefSelector29,
  _ as _61
} from "ag-grid-community";
var _StatusBar = class _StatusBar extends Component59 {
  constructor() {
    super(_StatusBar.TEMPLATE);
    this.compDestroyFunctions = {};
  }
  postConstruct() {
    this.processStatusPanels(/* @__PURE__ */ new Map());
    this.addManagedPropertyListeners(["statusBar"], this.handleStatusBarChanged.bind(this));
  }
  processStatusPanels(existingStatusPanelsToReuse) {
    var _a;
    const statusPanels = (_a = this.gos.get("statusBar")) == null ? void 0 : _a.statusPanels;
    if (statusPanels) {
      const leftStatusPanelComponents = statusPanels.filter((componentConfig) => componentConfig.align === "left");
      this.createAndRenderComponents(leftStatusPanelComponents, this.eStatusBarLeft, existingStatusPanelsToReuse);
      const centerStatusPanelComponents = statusPanels.filter((componentConfig) => componentConfig.align === "center");
      this.createAndRenderComponents(centerStatusPanelComponents, this.eStatusBarCenter, existingStatusPanelsToReuse);
      const rightStatusPanelComponents = statusPanels.filter((componentConfig) => !componentConfig.align || componentConfig.align === "right");
      this.createAndRenderComponents(rightStatusPanelComponents, this.eStatusBarRight, existingStatusPanelsToReuse);
    } else {
      this.setDisplayed(false);
    }
  }
  handleStatusBarChanged() {
    var _a;
    const statusPanels = (_a = this.gos.get("statusBar")) == null ? void 0 : _a.statusPanels;
    const validStatusBarPanelsProvided = Array.isArray(statusPanels) && statusPanels.length > 0;
    this.setDisplayed(validStatusBarPanelsProvided);
    const existingStatusPanelsToReuse = /* @__PURE__ */ new Map();
    if (validStatusBarPanelsProvided) {
      statusPanels.forEach((statusPanelConfig) => {
        var _a2, _b;
        const key = (_a2 = statusPanelConfig.key) != null ? _a2 : statusPanelConfig.statusPanel;
        const existingStatusPanel = this.statusBarService.getStatusPanel(key);
        if (existingStatusPanel == null ? void 0 : existingStatusPanel.refresh) {
          const newParams = this.gos.addGridCommonParams((_b = statusPanelConfig.statusPanelParams) != null ? _b : {});
          const hasRefreshed = existingStatusPanel.refresh(newParams);
          if (hasRefreshed) {
            existingStatusPanelsToReuse.set(key, existingStatusPanel);
            delete this.compDestroyFunctions[key];
            _61.removeFromParent(existingStatusPanel.getGui());
          }
        }
      });
    }
    this.resetStatusBar();
    if (validStatusBarPanelsProvided) {
      this.processStatusPanels(existingStatusPanelsToReuse);
    }
  }
  resetStatusBar() {
    this.eStatusBarLeft.innerHTML = "";
    this.eStatusBarCenter.innerHTML = "";
    this.eStatusBarRight.innerHTML = "";
    this.destroyComponents();
    this.statusBarService.unregisterAllComponents();
  }
  destroyComponents() {
    Object.values(this.compDestroyFunctions).forEach((func) => func());
    this.compDestroyFunctions = {};
  }
  createAndRenderComponents(statusBarComponents, ePanelComponent, existingStatusPanelsToReuse) {
    const componentDetails = [];
    statusBarComponents.forEach((componentConfig) => {
      const key = componentConfig.key || componentConfig.statusPanel;
      const existingStatusPanel = existingStatusPanelsToReuse.get(key);
      let promise;
      if (existingStatusPanel) {
        promise = AgPromise7.resolve(existingStatusPanel);
      } else {
        const params = {};
        const compDetails = this.userComponentFactory.getStatusPanelCompDetails(componentConfig, params);
        promise = compDetails.newAgStackInstance();
        if (!promise) {
          return;
        }
      }
      componentDetails.push({
        key,
        promise
      });
    });
    AgPromise7.all(componentDetails.map((details) => details.promise)).then(() => {
      componentDetails.forEach((componentDetail) => {
        componentDetail.promise.then((component) => {
          const destroyFunc = () => {
            this.getContext().destroyBean(component);
          };
          if (this.isAlive()) {
            this.statusBarService.registerStatusPanel(componentDetail.key, component);
            ePanelComponent.appendChild(component.getGui());
            this.compDestroyFunctions[componentDetail.key] = destroyFunc;
          } else {
            destroyFunc();
          }
        });
      });
    });
  }
};
_StatusBar.TEMPLATE = /* html */
`<div class="ag-status-bar">
            <div ref="eStatusBarLeft" class="ag-status-bar-left" role="status"></div>
            <div ref="eStatusBarCenter" class="ag-status-bar-center" role="status"></div>
            <div ref="eStatusBarRight" class="ag-status-bar-right" role="status"></div>
        </div>`;
__decorateClass([
  Autowired70("userComponentFactory")
], _StatusBar.prototype, "userComponentFactory", 2);
__decorateClass([
  Autowired70("statusBarService")
], _StatusBar.prototype, "statusBarService", 2);
__decorateClass([
  RefSelector29("eStatusBarLeft")
], _StatusBar.prototype, "eStatusBarLeft", 2);
__decorateClass([
  RefSelector29("eStatusBarCenter")
], _StatusBar.prototype, "eStatusBarCenter", 2);
__decorateClass([
  RefSelector29("eStatusBarRight")
], _StatusBar.prototype, "eStatusBarRight", 2);
__decorateClass([
  PostConstruct67
], _StatusBar.prototype, "postConstruct", 1);
__decorateClass([
  PreDestroy7
], _StatusBar.prototype, "destroyComponents", 1);
var StatusBar = _StatusBar;

// enterprise-modules/status-bar/src/statusBar/providedPanels/nameValueComp.ts
import { Component as Component60, RefSelector as RefSelector30 } from "ag-grid-community";
var _NameValueComp = class _NameValueComp extends Component60 {
  constructor() {
    super(_NameValueComp.TEMPLATE);
  }
  setLabel(key, defaultValue) {
    this.setDisplayed(false);
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    this.eLabel.innerHTML = localeTextFunc(key, defaultValue);
  }
  setValue(value) {
    this.eValue.innerHTML = value;
  }
};
_NameValueComp.TEMPLATE = /* html */
`<div class="ag-status-name-value">
            <span ref="eLabel"></span>:&nbsp;
            <span ref="eValue" class="ag-status-name-value-value"></span>
        </div>`;
__decorateClass([
  RefSelector30("eLabel")
], _NameValueComp.prototype, "eLabel", 2);
__decorateClass([
  RefSelector30("eValue")
], _NameValueComp.prototype, "eValue", 2);
var NameValueComp = _NameValueComp;

// enterprise-modules/status-bar/src/statusBar/providedPanels/totalAndFilteredRowsComp.ts
import {
  Autowired as Autowired71,
  Events as Events21,
  PostConstruct as PostConstruct68,
  _ as _68
} from "ag-grid-community";
var TotalAndFilteredRowsComp = class extends NameValueComp {
  postConstruct() {
    if (this.rowModel.getType() !== "clientSide") {
      _68.warnOnce(`agTotalAndFilteredRowCountComponent should only be used with the client side row model.`);
      return;
    }
    this.setLabel("totalAndFilteredRows", "Rows");
    this.addCssClass("ag-status-panel");
    this.addCssClass("ag-status-panel-total-and-filtered-row-count");
    this.setDisplayed(true);
    this.addManagedListener(this.eventService, Events21.EVENT_MODEL_UPDATED, this.onDataChanged.bind(this));
    this.onDataChanged();
  }
  onDataChanged() {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const thousandSeparator = localeTextFunc("thousandSeparator", ",");
    const decimalSeparator = localeTextFunc("decimalSeparator", ".");
    const rowCount = _68.formatNumberCommas(this.getFilteredRowCountValue(), thousandSeparator, decimalSeparator);
    const totalRowCount = _68.formatNumberCommas(this.getTotalRowCount(), thousandSeparator, decimalSeparator);
    if (rowCount === totalRowCount) {
      this.setValue(rowCount);
    } else {
      const localeTextFunc2 = this.localeService.getLocaleTextFunc();
      this.setValue(`${rowCount} ${localeTextFunc2("of", "of")} ${totalRowCount}`);
    }
  }
  getFilteredRowCountValue() {
    let filteredRowCount = 0;
    this.rowModel.forEachNodeAfterFilter((node) => {
      if (!node.group) {
        filteredRowCount++;
      }
    });
    return filteredRowCount;
  }
  getTotalRowCount() {
    let totalRowCount = 0;
    this.rowModel.forEachNode((node) => {
      if (!node.group) {
        totalRowCount++;
      }
    });
    return totalRowCount;
  }
  init() {
  }
  refresh() {
    return true;
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
};
__decorateClass([
  Autowired71("rowModel")
], TotalAndFilteredRowsComp.prototype, "rowModel", 2);
__decorateClass([
  PostConstruct68
], TotalAndFilteredRowsComp.prototype, "postConstruct", 1);

// enterprise-modules/status-bar/src/statusBar/providedPanels/filteredRowsComp.ts
import {
  Autowired as Autowired76,
  Events as Events30,
  PostConstruct as PostConstruct69,
  _ as _69
} from "ag-grid-community";
var FilteredRowsComp = class extends NameValueComp {
  postConstruct() {
    this.setLabel("filteredRows", "Filtered");
    if (this.rowModel.getType() !== "clientSide") {
      _69.warnOnce(`agFilteredRowCountComponent should only be used with the client side row model.`);
      return;
    }
    this.addCssClass("ag-status-panel");
    this.addCssClass("ag-status-panel-filtered-row-count");
    this.setDisplayed(true);
    const listener = this.onDataChanged.bind(this);
    this.addManagedListener(this.eventService, Events30.EVENT_MODEL_UPDATED, listener);
    listener();
  }
  onDataChanged() {
    const totalRowCountValue = this.getTotalRowCountValue();
    const filteredRowCountValue = this.getFilteredRowCountValue();
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const thousandSeparator = localeTextFunc("thousandSeparator", ",");
    const decimalSeparator = localeTextFunc("decimalSeparator", ".");
    this.setValue(_69.formatNumberCommas(filteredRowCountValue, thousandSeparator, decimalSeparator));
    this.setDisplayed(totalRowCountValue !== filteredRowCountValue);
  }
  getTotalRowCountValue() {
    let totalRowCount = 0;
    this.rowModel.forEachNode((node) => totalRowCount += 1);
    return totalRowCount;
  }
  getFilteredRowCountValue() {
    let filteredRowCount = 0;
    this.rowModel.forEachNodeAfterFilter((node) => {
      if (!node.group) {
        filteredRowCount += 1;
      }
    });
    return filteredRowCount;
  }
  init() {
  }
  refresh() {
    return true;
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
};
__decorateClass([
  Autowired76("rowModel")
], FilteredRowsComp.prototype, "rowModel", 2);
__decorateClass([
  PostConstruct69
], FilteredRowsComp.prototype, "postConstruct", 1);

// enterprise-modules/status-bar/src/statusBar/providedPanels/totalRowsComp.ts
import { Autowired as Autowired77, Events as Events31, PostConstruct as PostConstruct70, _ as _70 } from "ag-grid-community";
var TotalRowsComp = class extends NameValueComp {
  postConstruct() {
    this.setLabel("totalRows", "Total Rows");
    if (this.rowModel.getType() !== "clientSide") {
      _70.warnOnce("agTotalRowCountComponent should only be used with the client side row model.");
      return;
    }
    this.addCssClass("ag-status-panel");
    this.addCssClass("ag-status-panel-total-row-count");
    this.setDisplayed(true);
    this.addManagedListener(this.eventService, Events31.EVENT_MODEL_UPDATED, this.onDataChanged.bind(this));
    this.onDataChanged();
  }
  onDataChanged() {
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const thousandSeparator = localeTextFunc("thousandSeparator", ",");
    const decimalSeparator = localeTextFunc("decimalSeparator", ".");
    this.setValue(_70.formatNumberCommas(this.getRowCountValue(), thousandSeparator, decimalSeparator));
  }
  getRowCountValue() {
    let totalRowCount = 0;
    this.rowModel.forEachLeafNode((node) => totalRowCount += 1);
    return totalRowCount;
  }
  init() {
  }
  refresh() {
    return true;
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
};
__decorateClass([
  Autowired77("rowModel")
], TotalRowsComp.prototype, "rowModel", 2);
__decorateClass([
  PostConstruct70
], TotalRowsComp.prototype, "postConstruct", 1);

// enterprise-modules/status-bar/src/statusBar/providedPanels/selectedRowsComp.ts
import { Autowired as Autowired78, Events as Events38, PostConstruct as PostConstruct71, _ as _71 } from "ag-grid-community";
var SelectedRowsComp = class extends NameValueComp {
  postConstruct() {
    if (!this.isValidRowModel()) {
      console.warn(`AG Grid: agSelectedRowCountComponent should only be used with the client and server side row model.`);
      return;
    }
    this.setLabel("selectedRows", "Selected");
    this.addCssClass("ag-status-panel");
    this.addCssClass("ag-status-panel-selected-row-count");
    this.onRowSelectionChanged();
    const eventListener = this.onRowSelectionChanged.bind(this);
    this.addManagedListener(this.eventService, Events38.EVENT_MODEL_UPDATED, eventListener);
    this.addManagedListener(this.eventService, Events38.EVENT_SELECTION_CHANGED, eventListener);
  }
  isValidRowModel() {
    const rowModelType = this.rowModel.getType();
    return rowModelType === "clientSide" || rowModelType === "serverSide";
  }
  onRowSelectionChanged() {
    const selectedRowCount = this.selectionService.getSelectionCount();
    if (selectedRowCount < 0) {
      this.setValue("?");
      this.setDisplayed(true);
      return;
    }
    const localeTextFunc = this.localeService.getLocaleTextFunc();
    const thousandSeparator = localeTextFunc("thousandSeparator", ",");
    const decimalSeparator = localeTextFunc("decimalSeparator", ".");
    this.setValue(_71.formatNumberCommas(selectedRowCount, thousandSeparator, decimalSeparator));
    this.setDisplayed(selectedRowCount > 0);
  }
  init() {
  }
  refresh() {
    return true;
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
};
__decorateClass([
  Autowired78("rowModel")
], SelectedRowsComp.prototype, "rowModel", 2);
__decorateClass([
  Autowired78("selectionService")
], SelectedRowsComp.prototype, "selectionService", 2);
__decorateClass([
  PostConstruct71
], SelectedRowsComp.prototype, "postConstruct", 1);

// enterprise-modules/status-bar/src/statusBar/providedPanels/aggregationComp.ts
import {
  Autowired as Autowired79,
  Component as Component61,
  Events as Events39,
  PostConstruct as PostConstruct75,
  RefSelector as RefSelector31,
  _ as _74,
  Optional as Optional7
} from "ag-grid-community";
var _AggregationComp = class _AggregationComp extends Component61 {
  constructor() {
    super(_AggregationComp.TEMPLATE);
  }
  // this is a user component, and IComponent has "public destroy()" as part of the interface.
  // so we need to override destroy() just to make the method public.
  destroy() {
    super.destroy();
  }
  postConstruct() {
    if (!this.isValidRowModel()) {
      console.warn(`AG Grid: agAggregationComponent should only be used with the client and server side row model.`);
      return;
    }
    this.avgAggregationComp.setLabel("avg", "Average");
    this.countAggregationComp.setLabel("count", "Count");
    this.minAggregationComp.setLabel("min", "Min");
    this.maxAggregationComp.setLabel("max", "Max");
    this.sumAggregationComp.setLabel("sum", "Sum");
    this.addManagedListener(this.eventService, Events39.EVENT_RANGE_SELECTION_CHANGED, this.onRangeSelectionChanged.bind(this));
    this.addManagedListener(this.eventService, Events39.EVENT_MODEL_UPDATED, this.onRangeSelectionChanged.bind(this));
  }
  isValidRowModel() {
    const rowModelType = this.rowModel.getType();
    return rowModelType === "clientSide" || rowModelType === "serverSide";
  }
  init(params) {
    this.params = params;
  }
  refresh(params) {
    this.params = params;
    this.onRangeSelectionChanged();
    return true;
  }
  setAggregationComponentValue(aggFuncName, value, visible) {
    var _a;
    const statusBarValueComponent = this.getAllowedAggregationValueComponent(aggFuncName);
    if (_74.exists(statusBarValueComponent) && statusBarValueComponent) {
      const localeTextFunc = this.localeService.getLocaleTextFunc();
      const thousandSeparator = localeTextFunc("thousandSeparator", ",");
      const decimalSeparator = localeTextFunc("decimalSeparator", ".");
      statusBarValueComponent.setValue(_74.formatNumberTwoDecimalPlacesAndCommas(value, thousandSeparator, decimalSeparator));
      statusBarValueComponent.setDisplayed(visible);
    } else {
      (_a = this.getAggregationValueComponent(aggFuncName)) == null ? void 0 : _a.setDisplayed(false);
    }
  }
  getAllowedAggregationValueComponent(aggFuncName) {
    const { aggFuncs } = this.params;
    if (!aggFuncs || aggFuncs.includes(aggFuncName)) {
      return this.getAggregationValueComponent(aggFuncName);
    }
    return null;
  }
  getAggregationValueComponent(aggFuncName) {
    const refComponentName = `${aggFuncName}AggregationComp`;
    return this[refComponentName];
  }
  onRangeSelectionChanged() {
    var _a;
    const cellRanges = (_a = this.rangeService) == null ? void 0 : _a.getCellRanges();
    let sum = 0;
    let count = 0;
    let numberCount = 0;
    let min = null;
    let max = null;
    const cellsSoFar = {};
    if (cellRanges && !_74.missingOrEmpty(cellRanges) && this.rangeService) {
      for (let i = 0; i < cellRanges.length; i++) {
        const cellRange = cellRanges[i];
        let currentRow = this.rangeService.getRangeStartRow(cellRange);
        const lastRow = this.rangeService.getRangeEndRow(cellRange);
        while (true) {
          const finishedAllRows = _74.missing(currentRow) || !currentRow || this.rowPositionUtils.before(lastRow, currentRow);
          if (finishedAllRows || !currentRow || !cellRange.columns) {
            break;
          }
          cellRange.columns.forEach((col) => {
            if (currentRow === null) {
              return;
            }
            const cellId = this.cellPositionUtils.createId({
              rowPinned: currentRow.rowPinned,
              column: col,
              rowIndex: currentRow.rowIndex
            });
            if (cellsSoFar[cellId]) {
              return;
            }
            cellsSoFar[cellId] = true;
            const rowNode = this.rowPositionUtils.getRowNode(currentRow);
            if (_74.missing(rowNode)) {
              return;
            }
            let value = this.valueService.getValue(col, rowNode);
            if (_74.missing(value) || value === "") {
              return;
            }
            count++;
            if (typeof value === "object" && "value" in value) {
              value = value.value;
              if (value === "") {
                return;
              }
            }
            if (typeof value === "string") {
              value = Number(value);
            }
            if (typeof value === "number" && !isNaN(value)) {
              sum += value;
              if (max === null || value > max) {
                max = value;
              }
              if (min === null || value < min) {
                min = value;
              }
              numberCount++;
            }
          });
          currentRow = this.cellNavigationService.getRowBelow(currentRow);
        }
      }
      ;
    }
    const gotResult = count > 1;
    const gotNumberResult = numberCount > 1;
    this.setAggregationComponentValue("count", count, gotResult);
    this.setAggregationComponentValue("sum", sum, gotNumberResult);
    this.setAggregationComponentValue("min", min, gotNumberResult);
    this.setAggregationComponentValue("max", max, gotNumberResult);
    this.setAggregationComponentValue("avg", sum / numberCount, gotNumberResult);
  }
};
_AggregationComp.TEMPLATE = /* html */
`<div class="ag-status-panel ag-status-panel-aggregations">
            <ag-name-value ref="avgAggregationComp"></ag-name-value>
            <ag-name-value ref="countAggregationComp"></ag-name-value>
            <ag-name-value ref="minAggregationComp"></ag-name-value>
            <ag-name-value ref="maxAggregationComp"></ag-name-value>
            <ag-name-value ref="sumAggregationComp"></ag-name-value>
        </div>`;
__decorateClass([
  Optional7("rangeService")
], _AggregationComp.prototype, "rangeService", 2);
__decorateClass([
  Autowired79("valueService")
], _AggregationComp.prototype, "valueService", 2);
__decorateClass([
  Autowired79("cellNavigationService")
], _AggregationComp.prototype, "cellNavigationService", 2);
__decorateClass([
  Autowired79("rowModel")
], _AggregationComp.prototype, "rowModel", 2);
__decorateClass([
  Autowired79("cellPositionUtils")
], _AggregationComp.prototype, "cellPositionUtils", 2);
__decorateClass([
  Autowired79("rowPositionUtils")
], _AggregationComp.prototype, "rowPositionUtils", 2);
__decorateClass([
  RefSelector31("sumAggregationComp")
], _AggregationComp.prototype, "sumAggregationComp", 2);
__decorateClass([
  RefSelector31("countAggregationComp")
], _AggregationComp.prototype, "countAggregationComp", 2);
__decorateClass([
  RefSelector31("minAggregationComp")
], _AggregationComp.prototype, "minAggregationComp", 2);
__decorateClass([
  RefSelector31("maxAggregationComp")
], _AggregationComp.prototype, "maxAggregationComp", 2);
__decorateClass([
  RefSelector31("avgAggregationComp")
], _AggregationComp.prototype, "avgAggregationComp", 2);
__decorateClass([
  PostConstruct75
], _AggregationComp.prototype, "postConstruct", 1);
var AggregationComp = _AggregationComp;

// enterprise-modules/status-bar/src/version.ts
var VERSION18 = "31.3.2";

// enterprise-modules/status-bar/src/statusBarModule.ts
var StatusBarModule = {
  version: VERSION18,
  moduleName: ModuleNames18.StatusBarModule,
  beans: [StatusBarService],
  agStackComponents: [
    { componentName: "AgStatusBar", componentClass: StatusBar },
    { componentName: "AgNameValue", componentClass: NameValueComp }
  ],
  userComponents: [
    { componentName: "agAggregationComponent", componentClass: AggregationComp },
    { componentName: "agSelectedRowCountComponent", componentClass: SelectedRowsComp },
    { componentName: "agTotalRowCountComponent", componentClass: TotalRowsComp },
    { componentName: "agFilteredRowCountComponent", componentClass: FilteredRowsComp },
    { componentName: "agTotalAndFilteredRowCountComponent", componentClass: TotalAndFilteredRowsComp }
  ],
  dependantModules: [
    EnterpriseCoreModule
  ]
};

// enterprise-modules/viewport-row-model/dist/package/main.esm.mjs
import { ModuleNames as ModuleNames19 } from "ag-grid-community";
import {
  _ as _75,
  Autowired as Autowired80,
  Bean as Bean17,
  BeanStub as BeanStub31,
  Events as Events40,
  PostConstruct as PostConstruct76,
  PreDestroy as PreDestroy8,
  RowNode
} from "ag-grid-community";
var __defProp17 = Object.defineProperty;
var __getOwnPropDesc17 = Object.getOwnPropertyDescriptor;
var __decorateClass17 = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc17(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp17(target, key, result);
  return result;
};
var VERSION19 = "31.3.2";
var ViewportRowModel = class extends BeanStub31 {
  constructor() {
    super(...arguments);
    this.firstRow = -1;
    this.lastRow = -1;
    this.rowCount = -1;
    this.rowNodesByIndex = {};
  }
  // we don't implement as lazy row heights is not supported in this row model
  ensureRowHeightsValid(startPixel, endPixel, startLimitIndex, endLimitIndex) {
    return false;
  }
  init() {
    this.rowHeight = this.gos.getRowHeightAsNumber();
    this.addManagedListener(this.eventService, Events40.EVENT_VIEWPORT_CHANGED, this.onViewportChanged.bind(this));
    this.addManagedPropertyListener("viewportDatasource", () => this.updateDatasource());
    this.addManagedPropertyListener("rowHeight", () => {
      this.rowHeight = this.gos.getRowHeightAsNumber();
      this.updateRowHeights();
    });
  }
  start() {
    this.updateDatasource();
  }
  isLastRowIndexKnown() {
    return true;
  }
  destroyDatasource() {
    if (!this.viewportDatasource) {
      return;
    }
    if (this.viewportDatasource.destroy) {
      this.viewportDatasource.destroy();
    }
    this.rowRenderer.datasourceChanged();
    this.firstRow = -1;
    this.lastRow = -1;
  }
  updateDatasource() {
    const datasource = this.gos.get("viewportDatasource");
    if (datasource) {
      this.setViewportDatasource(datasource);
    }
  }
  getViewportRowModelPageSize() {
    return this.gos.get("viewportRowModelPageSize");
  }
  getViewportRowModelBufferSize() {
    return this.gos.get("viewportRowModelBufferSize");
  }
  calculateFirstRow(firstRenderedRow) {
    const bufferSize = this.getViewportRowModelBufferSize();
    const pageSize = this.getViewportRowModelPageSize();
    const afterBuffer = firstRenderedRow - bufferSize;
    if (afterBuffer < 0) {
      return 0;
    }
    return Math.floor(afterBuffer / pageSize) * pageSize;
  }
  calculateLastRow(lastRenderedRow) {
    if (lastRenderedRow === -1) {
      return lastRenderedRow;
    }
    const bufferSize = this.getViewportRowModelBufferSize();
    const pageSize = this.getViewportRowModelPageSize();
    const afterBuffer = lastRenderedRow + bufferSize;
    const result = Math.ceil(afterBuffer / pageSize) * pageSize;
    const lastRowIndex = this.rowCount - 1;
    return Math.min(result, lastRowIndex);
  }
  onViewportChanged(event) {
    const newFirst = this.calculateFirstRow(event.firstRow);
    const newLast = this.calculateLastRow(event.lastRow);
    if (this.firstRow !== newFirst || this.lastRow !== newLast) {
      this.firstRow = newFirst;
      this.lastRow = newLast;
      this.purgeRowsNotInViewport();
      if (this.viewportDatasource) {
        this.viewportDatasource.setViewportRange(this.firstRow, this.lastRow);
      }
    }
  }
  purgeRowsNotInViewport() {
    Object.keys(this.rowNodesByIndex).forEach((indexStr) => {
      const index = parseInt(indexStr, 10);
      if (index < this.firstRow || index > this.lastRow) {
        if (this.isRowFocused(index)) {
          return;
        }
        delete this.rowNodesByIndex[index];
      }
    });
  }
  isRowFocused(rowIndex) {
    const focusedCell = this.focusService.getFocusCellToUseAfterRefresh();
    if (!focusedCell) {
      return false;
    }
    if (focusedCell.rowPinned != null) {
      return false;
    }
    const hasFocus = focusedCell.rowIndex === rowIndex;
    return hasFocus;
  }
  setViewportDatasource(viewportDatasource) {
    this.destroyDatasource();
    this.viewportDatasource = viewportDatasource;
    this.rowCount = -1;
    if (!viewportDatasource.init) {
      console.warn("AG Grid: viewport is missing init method.");
    } else {
      viewportDatasource.init({
        setRowCount: this.setRowCount.bind(this),
        setRowData: this.setRowData.bind(this),
        getRow: this.getRow.bind(this)
      });
    }
  }
  getType() {
    return "viewport";
  }
  getRow(rowIndex) {
    if (!this.rowNodesByIndex[rowIndex]) {
      this.rowNodesByIndex[rowIndex] = this.createBlankRowNode(rowIndex);
    }
    return this.rowNodesByIndex[rowIndex];
  }
  getRowNode(id) {
    let result;
    this.forEachNode((rowNode) => {
      if (rowNode.id === id) {
        result = rowNode;
      }
    });
    return result;
  }
  getRowCount() {
    return this.rowCount === -1 ? 0 : this.rowCount;
  }
  getRowIndexAtPixel(pixel) {
    if (this.rowHeight !== 0) {
      return Math.floor(pixel / this.rowHeight);
    }
    return 0;
  }
  getRowBounds(index) {
    return {
      rowHeight: this.rowHeight,
      rowTop: this.rowHeight * index
    };
  }
  updateRowHeights() {
    this.forEachNode((node) => {
      node.setRowHeight(this.rowHeight);
      node.setRowTop(this.rowHeight * node.rowIndex);
    });
    const event = {
      type: Events40.EVENT_MODEL_UPDATED,
      newData: false,
      newPage: false,
      keepRenderedRows: true,
      animate: false
    };
    this.eventService.dispatchEvent(event);
  }
  getTopLevelRowCount() {
    return this.getRowCount();
  }
  getTopLevelRowDisplayedIndex(topLevelIndex) {
    return topLevelIndex;
  }
  isEmpty() {
    return this.rowCount > 0;
  }
  isRowsToRender() {
    return this.rowCount > 0;
  }
  getNodesInRangeForSelection(firstInRange, lastInRange) {
    const firstIndex = _75.missing(firstInRange) ? 0 : firstInRange.rowIndex;
    const lastIndex = lastInRange.rowIndex;
    const firstNodeOutOfRange = firstIndex < this.firstRow || firstIndex > this.lastRow;
    const lastNodeOutOfRange = lastIndex < this.firstRow || lastIndex > this.lastRow;
    if (firstNodeOutOfRange || lastNodeOutOfRange) {
      return [];
    }
    const result = [];
    const startIndex = firstIndex <= lastIndex ? firstIndex : lastIndex;
    const endIndex = firstIndex <= lastIndex ? lastIndex : firstIndex;
    for (let i = startIndex; i <= endIndex; i++) {
      result.push(this.rowNodesByIndex[i]);
    }
    return result;
  }
  forEachNode(callback) {
    let callbackCount = 0;
    Object.keys(this.rowNodesByIndex).forEach((indexStr) => {
      const index = parseInt(indexStr, 10);
      const rowNode = this.rowNodesByIndex[index];
      callback(rowNode, callbackCount);
      callbackCount++;
    });
  }
  setRowData(rowData) {
    _75.iterateObject(rowData, (indexStr, dataItem) => {
      const index = parseInt(indexStr, 10);
      if (index >= this.firstRow && index <= this.lastRow) {
        let rowNode = this.rowNodesByIndex[index];
        if (_75.missing(rowNode)) {
          rowNode = this.createBlankRowNode(index);
          this.rowNodesByIndex[index] = rowNode;
        }
        rowNode.setDataAndId(dataItem, index.toString());
      }
    });
  }
  createBlankRowNode(rowIndex) {
    const rowNode = new RowNode(this.beans);
    rowNode.setRowHeight(this.rowHeight);
    rowNode.setRowTop(this.rowHeight * rowIndex);
    rowNode.setRowIndex(rowIndex);
    return rowNode;
  }
  setRowCount(rowCount, keepRenderedRows = false) {
    if (rowCount === this.rowCount) {
      return;
    }
    this.rowCount = rowCount;
    this.eventService.dispatchEventOnce({
      type: Events40.EVENT_ROW_COUNT_READY
    });
    const event = {
      type: Events40.EVENT_MODEL_UPDATED,
      newData: false,
      newPage: false,
      keepRenderedRows,
      animate: false
    };
    this.eventService.dispatchEvent(event);
  }
  isRowPresent(rowNode) {
    const foundRowNode = this.getRowNode(rowNode.id);
    return !!foundRowNode;
  }
};
__decorateClass17([
  Autowired80("rowRenderer")
], ViewportRowModel.prototype, "rowRenderer", 2);
__decorateClass17([
  Autowired80("focusService")
], ViewportRowModel.prototype, "focusService", 2);
__decorateClass17([
  Autowired80("beans")
], ViewportRowModel.prototype, "beans", 2);
__decorateClass17([
  PostConstruct76
], ViewportRowModel.prototype, "init", 1);
__decorateClass17([
  PreDestroy8
], ViewportRowModel.prototype, "destroyDatasource", 1);
ViewportRowModel = __decorateClass17([
  Bean17("rowModel")
], ViewportRowModel);
var ViewportRowModelModule = {
  version: VERSION19,
  moduleName: ModuleNames19.ViewportRowModelModule,
  rowModel: "viewport",
  beans: [ViewportRowModel],
  dependantModules: [
    EnterpriseCoreModule
  ]
};

// packages/ag-grid-enterprise/src/main.ts
__reExport(main_exports, main_esm_exports);
import { ALWAYS_SYNC_GLOBAL_EVENTS } from "ag-grid-community";
import { AbstractHeaderCellCtrl } from "ag-grid-community";
import { AgAbstractField } from "ag-grid-community";
import { AgAbstractLabel as AgAbstractLabel2 } from "ag-grid-community";
import { AgAutocomplete as AgAutocomplete2 } from "ag-grid-community";
import { AgCheckbox } from "ag-grid-community";
import { AgDialog as AgDialog5 } from "ag-grid-community";
import { AgGroupComponent as AgGroupComponent6 } from "ag-grid-community";
import { AgInputDateField as AgInputDateField2 } from "ag-grid-community";
import { AgInputNumberField as AgInputNumberField2 } from "ag-grid-community";
import { AgInputRange } from "ag-grid-community";
import { AgInputTextArea } from "ag-grid-community";
import { AgInputTextField as AgInputTextField3 } from "ag-grid-community";
import { AgMenuItemComponent as AgMenuItemComponent5 } from "ag-grid-community";
import { AgMenuItemRenderer as AgMenuItemRenderer2 } from "ag-grid-community";
import { AgMenuList as AgMenuList4 } from "ag-grid-community";
import { AgMenuPanel } from "ag-grid-community";
import { AgPanel as AgPanel2 } from "ag-grid-community";
import { AgPickerField as AgPickerField2 } from "ag-grid-community";
import { AgPromise as AgPromise8 } from "ag-grid-community";
import { AgPromiseStatus } from "ag-grid-community";
import { AgRadioButton } from "ag-grid-community";
import { AgRichSelect as AgRichSelect4 } from "ag-grid-community";
import { AgSelect as AgSelect9 } from "ag-grid-community";
import { AgSlider as AgSlider3 } from "ag-grid-community";
import { AgStackComponentsRegistry } from "ag-grid-community";
import { AgToggleButton as AgToggleButton5 } from "ag-grid-community";
import { AlignedGridsService } from "ag-grid-community";
import { AnimateShowChangeCellRenderer } from "ag-grid-community";
import { AnimateSlideCellRenderer } from "ag-grid-community";
import { AnimationFrameService } from "ag-grid-community";
import { AutoScrollService as AutoScrollService2 } from "ag-grid-community";
import { AutoWidthCalculator } from "ag-grid-community";
import { Autowired as Autowired81 } from "ag-grid-community";
import { BarColumnLabelPlacement } from "ag-grid-community";
import { BaseComponentWrapper } from "ag-grid-community";
import { Bean as Bean18 } from "ag-grid-community";
import { BeanStub as BeanStub36 } from "ag-grid-community";
import { Beans } from "ag-grid-community";
import { BodyDropPivotTarget } from "ag-grid-community";
import { BodyDropTarget } from "ag-grid-community";
import { CellComp } from "ag-grid-community";
import { CellCtrl as CellCtrl2 } from "ag-grid-community";
import { CellNavigationService as CellNavigationService2 } from "ag-grid-community";
import { CellPositionUtils as CellPositionUtils2 } from "ag-grid-community";
import { CellRangeType as CellRangeType3 } from "ag-grid-community";
import { ChangedPath as ChangedPath2 } from "ag-grid-community";
import { ChartMappings as ChartMappings3 } from "ag-grid-community";
import { CheckboxCellEditor } from "ag-grid-community";
import { CheckboxCellRenderer } from "ag-grid-community";
import { CheckboxSelectionComponent } from "ag-grid-community";
import { ClientSideRowModelSteps } from "ag-grid-community";
import { Column } from "ag-grid-community";
import { ColumnApi } from "ag-grid-community";
import { ColumnFactory } from "ag-grid-community";
import { ColumnGroup } from "ag-grid-community";
import { ColumnKeyCreator } from "ag-grid-community";
import { ColumnModel } from "ag-grid-community";
import { Component as Component64 } from "ag-grid-community";
import { ComponentUtil } from "ag-grid-community";
import { Context } from "ag-grid-community";
import { CssClassApplier as CssClassApplier4 } from "ag-grid-community";
import { CssClassManager } from "ag-grid-community";
import { CtrlsService } from "ag-grid-community";
import { DataTypeService } from "ag-grid-community";
import { DateCellEditor } from "ag-grid-community";
import { DateFilter } from "ag-grid-community";
import { DateStringCellEditor } from "ag-grid-community";
import { DisplayedGroupCreator } from "ag-grid-community";
import { DragAndDropService as DragAndDropService7 } from "ag-grid-community";
import { DragService } from "ag-grid-community";
import { DragSourceType as DragSourceType6 } from "ag-grid-community";
import { Environment } from "ag-grid-community";
import { EventService as EventService3 } from "ag-grid-community";
import { Events as Events41 } from "ag-grid-community";
import { ExcelFactoryMode as ExcelFactoryMode2 } from "ag-grid-community";
import { ExpansionService as ExpansionService2 } from "ag-grid-community";
import { ExpressionService } from "ag-grid-community";
import { FilterManager } from "ag-grid-community";
import { FilterWrapperComp as FilterWrapperComp4 } from "ag-grid-community";
import { FloatingFilterMapper } from "ag-grid-community";
import { FocusService } from "ag-grid-community";
import { GROUP_AUTO_COLUMN_ID as GROUP_AUTO_COLUMN_ID3 } from "ag-grid-community";
import { Grid } from "ag-grid-community";
import { GridApi as GridApi2 } from "ag-grid-community";
import { GridBodyComp } from "ag-grid-community";
import { GridBodyCtrl } from "ag-grid-community";
import { GridComp } from "ag-grid-community";
import { GridCoreCreator } from "ag-grid-community";
import { GridCtrl } from "ag-grid-community";
import { GridHeaderComp } from "ag-grid-community";
import { GridHeaderCtrl } from "ag-grid-community";
import { GridOptionsService } from "ag-grid-community";
import { GroupCellRenderer } from "ag-grid-community";
import { GroupCellRendererCtrl } from "ag-grid-community";
import { GroupInstanceIdCreator } from "ag-grid-community";
import { HeaderCellCtrl } from "ag-grid-community";
import { HeaderFilterCellComp } from "ag-grid-community";
import { HeaderFilterCellCtrl } from "ag-grid-community";
import { HeaderGroupCellCtrl } from "ag-grid-community";
import { HeaderNavigationDirection } from "ag-grid-community";
import { HeaderNavigationService } from "ag-grid-community";
import { HeaderPositionUtils } from "ag-grid-community";
import { HeaderRowComp } from "ag-grid-community";
import { HeaderRowContainerComp } from "ag-grid-community";
import { HeaderRowContainerCtrl } from "ag-grid-community";
import { HeaderRowCtrl } from "ag-grid-community";
import { HeaderRowType } from "ag-grid-community";
import { HorizontalDirection } from "ag-grid-community";
import { HorizontalResizeService } from "ag-grid-community";
import { KeyCode as KeyCode12 } from "ag-grid-community";
import { LargeTextCellEditor } from "ag-grid-community";
import { LayoutCssClasses } from "ag-grid-community";
import { LocaleService } from "ag-grid-community";
import { Logger } from "ag-grid-community";
import { LoggerFactory } from "ag-grid-community";
import { ManagedFocusFeature as ManagedFocusFeature2 } from "ag-grid-community";
import { MenuService } from "ag-grid-community";
import { ModuleNames as ModuleNames20 } from "ag-grid-community";
import { ModuleRegistry as ModuleRegistry8 } from "ag-grid-community";
import { MouseEventService } from "ag-grid-community";
import { MoveColumnFeature } from "ag-grid-community";
import { NavigationService } from "ag-grid-community";
import { NumberCellEditor } from "ag-grid-community";
import { NumberFilter } from "ag-grid-community";
import { NumberSequence as NumberSequence2 } from "ag-grid-community";
import { Optional as Optional8 } from "ag-grid-community";
import { PaginationProxy } from "ag-grid-community";
import { PillDragComp as PillDragComp3 } from "ag-grid-community";
import { PillDropZonePanel as PillDropZonePanel3 } from "ag-grid-community";
import { PinnedRowModel } from "ag-grid-community";
import { PopupComponent as PopupComponent2 } from "ag-grid-community";
import { PopupEditorWrapper } from "ag-grid-community";
import { PopupService } from "ag-grid-community";
import { PositionableFeature as PositionableFeature2 } from "ag-grid-community";
import { PostConstruct as PostConstruct77 } from "ag-grid-community";
import { PreConstruct as PreConstruct4 } from "ag-grid-community";
import { PreDestroy as PreDestroy9 } from "ag-grid-community";
import { PropertyKeys } from "ag-grid-community";
import { ProvidedColumnGroup as ProvidedColumnGroup6 } from "ag-grid-community";
import { ProvidedFilter as ProvidedFilter3 } from "ag-grid-community";
import { Qualifier } from "ag-grid-community";
import { QuerySelector } from "ag-grid-community";
import { RefSelector as RefSelector38 } from "ag-grid-community";
import { ResizeObserverService } from "ag-grid-community";
import { RowAnimationCssClasses } from "ag-grid-community";
import { RowContainerComp } from "ag-grid-community";
import { RowContainerCtrl } from "ag-grid-community";
import { RowContainerName } from "ag-grid-community";
import { RowContainerType } from "ag-grid-community";
import { RowCtrl } from "ag-grid-community";
import { RowHighlightPosition } from "ag-grid-community";
import { RowNode as RowNode3 } from "ag-grid-community";
import { RowNodeBlock as RowNodeBlock2 } from "ag-grid-community";
import { RowNodeBlockLoader } from "ag-grid-community";
import { RowNodeSorter } from "ag-grid-community";
import { RowPositionUtils as RowPositionUtils2 } from "ag-grid-community";
import { RowRenderer } from "ag-grid-community";
import { ScalarFilter } from "ag-grid-community";
import { ScrollVisibleService } from "ag-grid-community";
import { SelectCellEditor } from "ag-grid-community";
import { SelectableService } from "ag-grid-community";
import { SelectionHandleType as SelectionHandleType5 } from "ag-grid-community";
import { ServerSideTransactionResultStatus as ServerSideTransactionResultStatus4 } from "ag-grid-community";
import { SetLeftFeature } from "ag-grid-community";
import { SimpleFilter } from "ag-grid-community";
import { SortController } from "ag-grid-community";
import { SortIndicatorComp } from "ag-grid-community";
import { StandardMenuFactory } from "ag-grid-community";
import { StylingService } from "ag-grid-community";
import { TabGuardClassNames } from "ag-grid-community";
import { TabGuardComp as TabGuardComp5 } from "ag-grid-community";
import { TabGuardCtrl } from "ag-grid-community";
import { TabbedLayout as TabbedLayout3 } from "ag-grid-community";
import { TextCellEditor } from "ag-grid-community";
import { TextFilter as TextFilter2 } from "ag-grid-community";
import { TextFloatingFilter } from "ag-grid-community";
import { Timer } from "ag-grid-community";
import { TooltipFeature as TooltipFeature4 } from "ag-grid-community";
import { TooltipStateManager } from "ag-grid-community";
import { TouchListener as TouchListener2 } from "ag-grid-community";
import { UserComponentFactory as UserComponentFactory2 } from "ag-grid-community";
import { UserComponentRegistry } from "ag-grid-community";
import { ValueCache } from "ag-grid-community";
import { ValueService as ValueService2 } from "ag-grid-community";
import { VanillaFrameworkOverrides } from "ag-grid-community";
import { VerticalDirection } from "ag-grid-community";
import { VirtualList as VirtualList5 } from "ag-grid-community";
import { VirtualListDragFeature as VirtualListDragFeature3 } from "ag-grid-community";
import { _ as _76 } from "ag-grid-community";
import { createGrid as createGrid2 } from "ag-grid-community";
import { getRowContainerTypeForName } from "ag-grid-community";
import { provideGlobalGridOptions } from "ag-grid-community";
ModuleRegistry7.__registerModules([
  ClientSideRowModelModule,
  InfiniteRowModelModule,
  CsvExportModule3,
  AdvancedFilterModule,
  GridChartsModule,
  ClipboardModule,
  ColumnsToolPanelModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  MasterDetailModule,
  MenuModule,
  MultiFilterModule,
  RangeSelectionModule,
  RichSelectModule,
  RowGroupingModule,
  ServerSideRowModelModule,
  SetFilterModule,
  SideBarModule,
  SparklinesModule,
  StatusBarModule,
  ViewportRowModelModule
], false, void 0);
export {
  ALWAYS_SYNC_GLOBAL_EVENTS,
  AbstractHeaderCellCtrl,
  AdvancedFilterModule,
  AgAbstractField,
  AgAbstractLabel2 as AgAbstractLabel,
  AgAutocomplete2 as AgAutocomplete,
  AgCheckbox,
  AgDialog5 as AgDialog,
  AgGroupComponent6 as AgGroupComponent,
  AgInputDateField2 as AgInputDateField,
  AgInputNumberField2 as AgInputNumberField,
  AgInputRange,
  AgInputTextArea,
  AgInputTextField3 as AgInputTextField,
  AgMenuItemComponent5 as AgMenuItemComponent,
  AgMenuItemRenderer2 as AgMenuItemRenderer,
  AgMenuList4 as AgMenuList,
  AgMenuPanel,
  AgPanel2 as AgPanel,
  AgPickerField2 as AgPickerField,
  AgPromise8 as AgPromise,
  AgPromiseStatus,
  AgRadioButton,
  AgRichSelect4 as AgRichSelect,
  AgSelect9 as AgSelect,
  AgSlider3 as AgSlider,
  AgStackComponentsRegistry,
  AgToggleButton5 as AgToggleButton,
  AlignedGridsService,
  AnimateShowChangeCellRenderer,
  AnimateSlideCellRenderer,
  AnimationFrameService,
  AutoScrollService2 as AutoScrollService,
  AutoWidthCalculator,
  Autowired81 as Autowired,
  BarColumnLabelPlacement,
  BaseComponentWrapper,
  Bean18 as Bean,
  BeanStub36 as BeanStub,
  Beans,
  BodyDropPivotTarget,
  BodyDropTarget,
  CellComp,
  CellCtrl2 as CellCtrl,
  CellNavigationService2 as CellNavigationService,
  CellPositionUtils2 as CellPositionUtils,
  CellRangeType3 as CellRangeType,
  ChangedPath2 as ChangedPath,
  ChartMappings3 as ChartMappings,
  CheckboxCellEditor,
  CheckboxCellRenderer,
  CheckboxSelectionComponent,
  ClientSideRowModelSteps,
  ClipboardModule,
  Column,
  ColumnApi,
  ColumnFactory,
  ColumnGroup,
  ColumnKeyCreator,
  ColumnModel,
  ColumnsToolPanelModule,
  Component64 as Component,
  ComponentUtil,
  Context,
  CssClassApplier4 as CssClassApplier,
  CssClassManager,
  CtrlsService,
  DataTypeService,
  DateCellEditor,
  DateFilter,
  DateStringCellEditor,
  DisplayedGroupCreator,
  DragAndDropService7 as DragAndDropService,
  DragService,
  DragSourceType6 as DragSourceType,
  EnterpriseCoreModule,
  Environment,
  EventService3 as EventService,
  Events41 as Events,
  ExcelExportModule,
  ExcelFactoryMode2 as ExcelFactoryMode,
  ExpansionService2 as ExpansionService,
  ExpressionService,
  FilterManager,
  FilterWrapperComp4 as FilterWrapperComp,
  FiltersToolPanelModule,
  FloatingFilterMapper,
  FocusService,
  GROUP_AUTO_COLUMN_ID3 as GROUP_AUTO_COLUMN_ID,
  Grid,
  GridApi2 as GridApi,
  GridBodyComp,
  GridBodyCtrl,
  GridChartsModule,
  GridComp,
  GridCoreCreator,
  GridCtrl,
  GridHeaderComp,
  GridHeaderCtrl,
  GridOptionsService,
  GroupCellRenderer,
  GroupCellRendererCtrl,
  GroupInstanceIdCreator,
  HeaderCellCtrl,
  HeaderFilterCellComp,
  HeaderFilterCellCtrl,
  HeaderGroupCellCtrl,
  HeaderNavigationDirection,
  HeaderNavigationService,
  HeaderPositionUtils,
  HeaderRowComp,
  HeaderRowContainerComp,
  HeaderRowContainerCtrl,
  HeaderRowCtrl,
  HeaderRowType,
  HorizontalDirection,
  HorizontalResizeService,
  KeyCode12 as KeyCode,
  LargeTextCellEditor,
  LayoutCssClasses,
  GridLicenseManager as LicenseManager,
  LocaleService,
  Logger,
  LoggerFactory,
  ManagedFocusFeature2 as ManagedFocusFeature,
  MasterDetailModule,
  MenuModule,
  MenuService,
  ModuleNames20 as ModuleNames,
  ModuleRegistry8 as ModuleRegistry,
  MouseEventService,
  MoveColumnFeature,
  MultiFilter,
  MultiFilterModule,
  NavigationService,
  NumberCellEditor,
  NumberFilter,
  NumberSequence2 as NumberSequence,
  Optional8 as Optional,
  PaginationProxy,
  PillDragComp3 as PillDragComp,
  PillDropZonePanel3 as PillDropZonePanel,
  PinnedRowModel,
  PivotDropZonePanel,
  PopupComponent2 as PopupComponent,
  PopupEditorWrapper,
  PopupService,
  PositionableFeature2 as PositionableFeature,
  PostConstruct77 as PostConstruct,
  PreConstruct4 as PreConstruct,
  PreDestroy9 as PreDestroy,
  PrimaryColsPanel,
  PropertyKeys,
  ProvidedColumnGroup6 as ProvidedColumnGroup,
  ProvidedFilter3 as ProvidedFilter,
  Qualifier,
  QuerySelector,
  RangeSelectionModule,
  RefSelector38 as RefSelector,
  ResizeObserverService,
  RichSelectModule,
  RowAnimationCssClasses,
  RowContainerComp,
  RowContainerCtrl,
  RowContainerName,
  RowContainerType,
  RowCtrl,
  RowGroupDropZonePanel,
  RowGroupingModule,
  RowHighlightPosition,
  RowNode3 as RowNode,
  RowNodeBlock2 as RowNodeBlock,
  RowNodeBlockLoader,
  RowNodeSorter,
  RowPositionUtils2 as RowPositionUtils,
  RowRenderer,
  ScalarFilter,
  ScrollVisibleService,
  SelectCellEditor,
  SelectableService,
  SelectionHandleType5 as SelectionHandleType,
  ServerSideRowModelModule,
  ServerSideTransactionResultStatus4 as ServerSideTransactionResultStatus,
  SetFilter,
  SetFilterModule,
  SetLeftFeature,
  SideBarModule,
  SimpleFilter,
  SortController,
  SortIndicatorComp,
  SparklinesModule,
  StandardMenuFactory,
  StatusBarModule,
  StylingService,
  TabGuardClassNames,
  TabGuardComp5 as TabGuardComp,
  TabGuardCtrl,
  TabbedLayout3 as TabbedLayout,
  TextCellEditor,
  TextFilter2 as TextFilter,
  TextFloatingFilter,
  Timer,
  ToolPanelColDefService,
  TooltipFeature4 as TooltipFeature,
  TooltipStateManager,
  TouchListener2 as TouchListener,
  UserComponentFactory2 as UserComponentFactory,
  UserComponentRegistry,
  ValueCache,
  ValueService2 as ValueService,
  ValuesDropZonePanel,
  VanillaFrameworkOverrides,
  VerticalDirection,
  ViewportRowModelModule,
  VirtualList5 as VirtualList,
  VirtualListDragFeature3 as VirtualListDragFeature,
  _76 as _,
  __FORCE_MODULE_DETECTION_AG_GRID_CORE_EXT,
  agCharts,
  createGrid2 as createGrid,
  exportMultipleSheetsAsExcel,
  getMultipleSheetsAsExcel,
  getRowContainerTypeForName,
  provideGlobalGridOptions
};
