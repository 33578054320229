import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IconResolver, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import 'ag-grid-enterprise';

import { NavbarComponent, Tabs } from '@shared/navbar/navbar.component';
import { BrandingBarComponent } from '@shared/branding-bar/branding-bar.component';
import { LoaderComponent } from '@shared/loader/loader.component';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        RouterOutlet,
        BrandingBarComponent,
        HttpClientModule,
        NavbarComponent,
        LoaderComponent,
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
    activeTabId: Tabs = Tabs.DOCUMENT_AND_DISCREPANCIES;
    tabs: typeof Tabs = Tabs;
    loading = true;

    constructor(
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        private router: Router,
    ) {
        const resolver: IconResolver = (name) =>
            sanitizer.bypassSecurityTrustResourceUrl(
                `/assets/icons/${name}.svg`,
            );
        iconRegistry.addSvgIconResolver(resolver);
    }

    ngOnInit() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.loading = false;
            }
        });
    }

    updateActiveTab(tabId: Tabs) {
        this.activeTabId = tabId;
    }
}
