import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';

import DATA_QA from '@automation/data-qa.json';
import { WorkflowService } from '@services/api/workflow.service';
import { UserService } from '@services/api/user/user.service';
import { TableComponent } from '@grid-table/table.component';
import { ToggleComponent } from '@shared/toggle/toggle.component';
import {
    SelectComponent,
    SelectionChangeEvent,
    SelectOption,
} from '@shared/select/select.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { ChangeTableViewMenuComponent } from './change-table-view-menu/change-table-view-menu.component';
import { DiscrepencyViewComponent } from './discrepancy-view/discrepancy-view.component';
import { DocumentViewComponent } from './document-view/document-view.component';
import { StatusFilterComponent } from './status-filter/status-filter.component';
import {
    SubscriptionListType,
    SubscriptionList,
} from 'app/utils/helpers/subscription';
import { DocumentQueryParam, StatusChipItem } from 'app/models/workflow.model';
import { TableView } from './documents-and-discrepancies.utils';
import { RepositoryService } from '@services/api/repository/repository.service';

@Component({
    selector: 'app-documents-discrepancies',
    standalone: true,
    templateUrl: './documents-and-discrepancies.component.html',
    styleUrl: './documents-and-discrepancies.component.scss',
    imports: [
        CommonModule,
        AgGridAngular,
        ChangeTableViewMenuComponent,
        TextButtonComponent,
        DiscrepencyViewComponent,
        DocumentViewComponent,
        TableComponent,
        SelectComponent,
        StatusFilterComponent,
        ToggleComponent,
        LoaderComponent,
    ],
})
export class DocumentAndDiscrepenciesComponent implements OnInit, OnDestroy {
    @ViewChild(DiscrepencyViewComponent)
    discrepancyViewComponent: DiscrepencyViewComponent | undefined;
    @ViewChild(DocumentViewComponent)
    documentViewComponent: DocumentViewComponent | undefined;

    readonly TableView: typeof TableView = TableView;
    readonly dataQa = DATA_QA;

    loading = false;
    selectedTableView: TableView = TableView.Document;
    workflowInstanceOptions: SelectOption[] = [];
    repositoryOptions: SelectOption[] = [];
    statusChipsData: StatusChipItem[] = [];
    tableReady = true;
    selectedWorkflow: number | undefined;
    selectedRepository: any;
    selectedStatuses: number[] = [];
    showMyItems: boolean = false;
    showDocuments = false;
    isAdmin = false;

    private _workflowAndRepo: DocumentQueryParam | undefined;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    get workflowAndRepo(): DocumentQueryParam | undefined {
        return this._workflowAndRepo;
    }

    set workflowAndRepo(value: DocumentQueryParam | undefined) {
        this._workflowAndRepo = value;
        this.getStatusChips();
        this.showDocuments = true;
    }

    constructor(
        private repositoryService: RepositoryService,
        private workflowService: WorkflowService,
        private userService: UserService,
        private router: Router,
        private formBuilder: FormBuilder,
    ) {
        this.handleTableRefresh();
    }

    ngOnInit() {
        this.userService.isAdminUser().subscribe((isAdmin) => {
            this.isAdmin = isAdmin;

            if (isAdmin) {
                this.loading = true;
                this._subscriptions['get-workflows'] = this.workflowService
                    .getWorkflows()
                    .subscribe({
                        next: (data) => {
                            this.workflowInstanceOptions = data.map((item) => ({
                                value: item.id,
                                viewValue: item.name,
                            }));
                        },
                        complete: () => {
                            this.loading = false;
                        },
                    });
            } else {
                this.workflowAndRepo = undefined;
            }
        });
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribeAllSafe();
    }

    onTableViewChanged(tableView: TableView) {
        this.showMyItems = false;
        this.selectedTableView = tableView;
    }

    onWorkflowInstanceChange(event: SelectionChangeEvent) {
        this.selectedWorkflow = event.value;
        this.repositoryOptions = [];
        this.repositoryService
            .getRepositories(event.value)
            .subscribe((data) => {
                this.repositoryOptions = data.map((item) => ({
                    value: item.id,
                    viewValue: item.name,
                }));

                if (event.isDefaultValue) {
                    this.setPreSelectedRepositoryItems(event.value);
                }
            });
    }

    onRepositoryChange(event: SelectionChangeEvent) {
        this.selectedRepository = event.value;
        this.workflowAndRepo = new DocumentQueryParam(
            this.selectedWorkflow!,
            this.selectedRepository,
        );
        this.refreshTable();
    }

    onStatusFilterChange(filters: number[]) {
        this.selectedStatuses = [...filters];
        this.refreshTable();
    }

    onToggleMyItems(toggle: boolean) {
        this.showMyItems = toggle;
        this.refreshTable();
    }

    handleTableRefresh() {
        this.router.events.subscribe((val) => {
            if (
                val instanceof NavigationEnd &&
                val.urlAfterRedirects === '/documents-and-discrepancies'
            ) {
                if (
                    this.router.getCurrentNavigation()?.extras.state?.[
                        'refresh'
                    ]
                ) {
                    this.refreshTable();
                }
            }
        });
    }

    resetFilters() {
        if (
            this.discrepancyViewComponent &&
            this.selectedTableView == TableView.Discrepancy
        ) {
            this.discrepancyViewComponent.resetFilters();
        }
        if (
            this.documentViewComponent &&
            this.selectedTableView == TableView.Document
        ) {
            this.documentViewComponent.resetFilters();
        }
    }

    isResetFiltersDisabled(): boolean {
        if (
            this.discrepancyViewComponent &&
            this.selectedTableView == TableView.Discrepancy
        ) {
            return !this.discrepancyViewComponent.filterApplied;
        }
        if (
            this.documentViewComponent &&
            this.selectedTableView == TableView.Document
        ) {
            return !this.documentViewComponent.filterApplied;
        }
        return true;
    }

    private refreshTable() {
        if (
            this.discrepancyViewComponent &&
            this.selectedTableView == TableView.Discrepancy
        ) {
            this.discrepancyViewComponent.refreshData();
        }

        if (
            this.documentViewComponent &&
            this.selectedTableView == TableView.Document
        ) {
            this.documentViewComponent.refreshData();
        }
    }

    private getStatusChips() {
        this.selectedStatuses = [];
        this.workflowService
            .getDiscrepanciesStatusChips(this._workflowAndRepo)
            .subscribe((data) => (this.statusChipsData = data));
    }

    private setPreSelectedRepositoryItems(workflow: number) {
        this.selectedRepository = this.repositoryOptions.map(
            (option) => option.value,
        );
        this.selectedWorkflow = workflow;
        this.workflowAndRepo = new DocumentQueryParam(
            this.selectedWorkflow,
            this.selectedRepository,
        );
    }
}
