import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IRowNode } from 'ag-grid-community';

interface CustomParams {
    onClick?: (node: IRowNode) => void;
}

@Component({
    standalone: true,
    imports: [CommonModule],
    template: `
        <span
            tabindex="0"
            [ngClass]="{ clickable: params.onClick ?? false }"
            (click)="params.onClick && params.onClick(params.node)"
            (keyup.enter)="params.onClick && params.onClick(params.node)">
            {{ params.value }}
        </span>
    `,
    styleUrl: './custom-cell.component.scss',
})
export class CustomCellComponent implements ICellRendererAngularComp {
    public params!: ICellRendererParams & CustomParams;

    agInit(params: ICellRendererParams & CustomParams): void {
        this.params = params;
    }

    refresh() {
        return false;
    }
}
