import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { DocumentRevisionStatus } from 'app/models/document/revision';

interface CustomParams {
    relationKind: DocumentRevisionStatus[];
}

@Component({
    standalone: true,
    imports: [CommonModule, MatIconModule],
    template: `<div
        *ngIf="!params?.data.isHeader"
        class="flex gap-5 justify-space-evenly">
        <div
            *ngFor="let item of params.data.relationKind"
            style="width: 24px;"
            class="flex items-center justify-center">
            @if (item !== null) {
                <mat-icon [svgIcon]="getIconType(item)" />
            } @else {
                -
            }
        </div>
    </div>`,
    styles: ``,
})
export class DiscrepancyCellComponent implements ICellRendererAngularComp {
    public params!: ICellRendererParams & CustomParams;

    agInit(params: ICellRendererParams & CustomParams): void {
        this.params = params;
    }

    refresh() {
        return false;
    }

    getIconType(kind: DocumentRevisionStatus): string {
        const kindToIconMap = {
            [DocumentRevisionStatus.ORIGIN]: 'flag-circle',
            [DocumentRevisionStatus.NO_ACTION]: 'upload-circle',
            [DocumentRevisionStatus.REJECTED]: 'cancel-circle',
            [DocumentRevisionStatus.ACCEPTED]: 'check-circle',
            [DocumentRevisionStatus.ACCEPTED_AND_APPLIED]: 'done-all',
        };
        return kindToIconMap[kind];
    }
}
