<div class="p-5">
    <p class="text-lg mb-3">Cancel Discrepancy {{ data.id }}?</p>
    <div class="p-3 text-sm bg-status-red1">
        This action will mark the discrepancy as cancelled and moved to Recently
        Completed state. Once cancelled a discrepancy cannot be re-opened.
    </div>
    <div class="flex justify-end gap-8 mt-5">
        <app-flat-button mat-dialog-close>Return to Page</app-flat-button>
        <app-flat-button variant="tertiary" (clicked)="cancel()">
            Cancel Discrepancy {{ data.id }}
        </app-flat-button>
    </div>
    <div *ngIf="error" class="flex justify-end gap-8">
        <small class="color-status-red4"> Something went wrong </small>
    </div>
</div>

@if (loading) {
    <app-loader type="overlay" position="absolute" />
}
