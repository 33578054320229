import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

import { DropdownItem, IdDisplayPair } from 'app/models/workflow.model';
import { Formats } from 'app/utils/constants';

@Injectable({
    providedIn: 'root',
})
export class SharedMapper {
    constructor(private datePipe: DatePipe) {}

    toDropdownItem = (item: DropdownItem) =>
        new DropdownItem(item?.id, item?.name || '-');

    toDate = (date: string): string => {
        return this.datePipe.transform(date, Formats.DATE) ?? '-';
    };

    toIdDisplayPair = (item: IdDisplayPair): IdDisplayPair =>
        new IdDisplayPair(item.backingField, item?.displayName || '-');
}
