<form class="p-5" [formGroup]="form">
    <p class="text-lg">Duplicate Documents</p>
    <p class="text-sm mt-4 mb-4">
        A correction named “{{ data.documentName }}”
        <span class="text-semi">already exists</span> in documents’ versions.
        Please change the name of the new correction you are uploading.
    </p>
    <div style="min-height: 70px">
        <app-input
            label="CORRECTION NAME"
            formControlName="updatedDocumentName"
            [control]="updatedDocumentName"
            [required]="true" />
        <p
            *ngIf="updatedDocumentName?.hasError('duplicateDocName')"
            class="text-xs color-status-red4">
            This name already exists in versions. Please enter a different name.
        </p>
    </div>
    <div class="flex justify-end gap-8 mt-5">
        <app-flat-button (clicked)="onCloseClick()">Cancel</app-flat-button>
        <app-flat-button variant="cta" (clicked)="onUploadClick()">
            Upload
        </app-flat-button>
    </div>
</form>
