<div class="flex flex-column ml-n4">
    <button
        mat-button
        color="primary"
        class="color-primary activity-log-toggle"
        (click)="toggleActivityLog()">
        <span class="text-s">{{ toggleText }}</span>
        <mat-icon
            svgIcon="arrow-down"
            iconPositionEnd
            style="width: 12px"
            [attr.toggled]="toggled"
            class="activity-log-toggle-icon" />
    </button>
    <div *ngIf="toggled" class="activity-log__table p-3">
        <p class="activity-log__title pl-2 mb-2">Activity Log</p>
        <app-table
            variant="secondary"
            [suppressMenu]="true"
            [suppressRowHoverHighlight]="true"
            [suppressCellFocus]="true"
            [pagination]="false"
            [floatingFilter]="false"
            [columnDefs]="columnDefs"
            [pageSize]="50"
            noRowsText="No activity logs  to show."
            (gridReady)="onGridReady($event)" />
    </div>
</div>
<div #activityLogEnd></div>
