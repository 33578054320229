import { HttpResponse } from '@angular/common/http';

export class PDFDocument {
    constructor(
        public blob: Blob,
        public name: string,
    ) {}
}

export enum FileType {
    PDF = 'application/pdf',
    PNG = 'image/png',
    JPEG = 'image/jpeg',
}

export const fileTypesMap = {
    [FileType.PDF]: '.pdf',
    [FileType.PNG]: '.png',
    [FileType.JPEG]: '.jpg,.jpeg',
};

export const createPDFDocument = (
    response: HttpResponse<Blob>,
): PDFDocument | null => {
    if (response.body) {
        let name = '';
        const contentDisposition =
            response.headers.get('Content-Disposition') ?? '';

        if (contentDisposition) {
            name = contentDisposition
                .split(';')[1]
                .split('=')[1]
                .replace(/"/g, '');
        }
        return new PDFDocument(createBlobPDF(response.body), name);
    }

    return null;
};

export const createBlobPDF = (blobPart: BlobPart) => {
    return new Blob([blobPart], {
        type: 'application/pdf',
    });
};

export const convertToFileList = (files: File[]): FileList => {
    const dataTransfer = new DataTransfer();

    files.forEach((file) => {
        dataTransfer.items.add(file);
    });

    return dataTransfer.files;
};

export const bytesToMB = (bytes: number) => {
    return bytes / (1024 * 1024);
};

export const bytesToGB = (bytes: number) => {
    return bytes / (1024 * 1024 * 1024);
};
