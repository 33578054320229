import { createAction, props } from '@ngrx/store';

import { DocumentRevisionItem } from 'app/models/document';

export const updateSelectedRevision = createAction(
    '[Document] Update Selected revision',
    props<{ selectedRevision: DocumentRevisionItem | null }>(),
);

export const saveRevisionList = createAction(
    '[Document] Save Revision List',
    props<{ revisionList: string[] }>(),
);

export const updateDiscrepancyCount = createAction(
    '[Document] Update Discrepancy Count',
    props<{ discrepancyCount: { filtered: number; total: number } }>(),
);

export const saveOriginalDocumentId = createAction(
    '[Document] Original DocumentId',
    props<{ originalDocumentId: number | null }>(),
);
