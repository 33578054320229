import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { TooltipComponent } from '@shared/tooltip/tooltip.component';

@Component({
    selector: 'app-add-discrepancy-button',
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        FlatButtonComponent,
        TooltipComponent,
    ],
    template: ` @if (discrepancyCount >= maxDiscCount) {
            <app-tooltip class="max-disc-tooltip pos-abs">
                <app-flat-button trigger variant="tertiary" [disabled]="true">
                    <span class="flex items-center text-sm">
                        <mat-icon svgIcon="add" />New
                    </span>
                </app-flat-button>
                <div content>
                    You can create only
                    <strong>{{ maxDiscCount }}</strong>
                    discrepancies.
                </div>
            </app-tooltip>
        } @else {
            <app-flat-button
                class="pos-abs"
                style="top: -7px; right: 3px"
                variant="tertiary"
                (clicked)="onAddClick()">
                <span class="flex items-center text-sm">
                    <mat-icon svgIcon="add" />New</span
                >
            </app-flat-button>
        }`,
    styles: `
        .max-disc-tooltip {
            top: -7px;
            right: 3px;
        }
    `,
})
export class AddDiscrepancyButtonComponent {
    @Input({ required: true }) discrepancyCount!: number;
    @Input({ required: true }) maxDiscCount!: number;
    @Output() addClicked = new EventEmitter();

    onAddClick() {
        this.addClicked.emit();
    }
}
