import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';

@Component({
    standalone: true,
    selector: 'app-chip',
    imports: [CommonModule, MatIconModule, MatRippleModule],
    templateUrl: './chip.component.html',
    styleUrl: './chip.component.scss',
})
export class ChipComponent implements OnInit {
    @Input() disabled = false;
    @Input() clickable = true;
    @Input() defaultSelected = false;
    @Output() handleClick = new EventEmitter();

    selected = false;

    constructor() {}

    ngOnInit(): void {
        this.selected = this.defaultSelected;
    }

    onChipClick() {
        if (this.clickable) {
            this.selected = !this.selected;
            this.handleClick.emit();
        }
    }
}
