import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogClose,
} from '@angular/material/dialog';

import { DocumentService } from '@services/api/document/document.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { DropzoneComponent } from '@shared/dropzone/dropzone.component';
import { CheckboxComponent } from '@shared/checkbox/checkbox.component';
import { FileType } from 'app/utils/constants';
import { Discrepancy } from 'app/models/workflow.model';

@Component({
    standalone: true,
    templateUrl: 'upload-correction-dialog.component.html',
    imports: [
        CommonModule,
        FlatButtonComponent,
        MatDialogClose,
        LoaderComponent,
        DropzoneComponent,
        CheckboxComponent,
    ],
})
export class UploadCorrectionDialogComponent {
    acceptedFiles: FileType[] = [FileType.PDF];
    files: FileList | null = null;
    selectedDiscrepancies: number[] = [];
    progress = 0;
    error: boolean = false;

    constructor(
        private documentService: DocumentService,
        public dialogRef: MatDialogRef<UploadCorrectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { id: number; discrepancies: Discrepancy[] },
    ) {}

    upload() {
        if (this.files && this.files[0]) {
            this.documentService
                .uploadDocument(
                    this.data.id,
                    this.prepareFormdata(this.files[0]),
                )
                .subscribe({
                    next: (response) => {
                        this.progress = response.progress;

                        if (response.status === 'success') {
                            this.dialogRef.close(true);
                        }

                        if (response.status === 'error') {
                            this.error = true;
                        }
                    },
                });
        }
    }

    onFileReady(files: FileList) {
        this.files = files;
    }

    onDiscrepancySelected(checked: any, discrepancyId: number) {
        if (checked) {
            this.selectedDiscrepancies = [
                ...this.selectedDiscrepancies,
                discrepancyId,
            ];
        } else {
            this.selectedDiscrepancies = [
                ...this.selectedDiscrepancies.filter(
                    (disc) => disc !== discrepancyId,
                ),
            ];
        }
    }
    private prepareFormdata = (file: File) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        this.selectedDiscrepancies.forEach((item) =>
            formData.append('discrepancy', item.toString()),
        );

        return formData;
    };
}
