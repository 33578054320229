import {
    Component,
    ViewChild,
    OnInit,
    ElementRef,
    AfterViewInit,
    Input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';

import { environment } from '@environments/environment';

@Component({
    selector: 'app-web-viewer',
    standalone: true,
    imports: [MatIconModule],
    templateUrl: './webviewer.component.html',
    styleUrls: ['./webviewer.component.scss'],
})
export class WebViewerComponent implements OnInit, AfterViewInit {
    @ViewChild('viewer', { static: true }) viewer!: ElementRef;
    @Input() pdfDocument: any;
    @Input() name: string = '';

    wvInstance!: WebViewerInstance;

    ngOnInit() {
        this.wvDocumentLoadedHandler = this.wvDocumentLoadedHandler.bind(this);
    }

    wvDocumentLoadedHandler(): void {
        const { annotationManager, Annotations } = this.wvInstance.Core;
        const rectangle = new Annotations.RectangleAnnotation();
        rectangle.PageNumber = 1;
        rectangle.X = 100;
        rectangle.Y = 100;
        rectangle.Width = 250;
        rectangle.Height = 250;
        rectangle.StrokeThickness = 5;
        rectangle.Author = annotationManager.getCurrentUser();
    }

    ngAfterViewInit(): void {
        WebViewer(
            {
                path: 'assets/lib',
                licenseKey: environment.apryseLicenseKey,
            },
            this.viewer.nativeElement,
        ).then((instance: WebViewerInstance) => {
            this.wvInstance = instance;
            instance.UI.loadDocument(this.pdfDocument, {
                filename: this.name,
            });
            instance.UI.disableElements(['toolsHeader']);
            instance.UI.setTheme(instance.UI.Theme.DARK);

            // or listen to events from the viewer element
            this.viewer.nativeElement.addEventListener(
                'pageChanged',
                (e: any) => {
                    const [pageNumber] = e.detail;
                    console.log(`Current page is ${pageNumber}`);
                },
            );

            // or from the docViewer instance
            instance.Core.documentViewer.addEventListener(
                'annotationsLoaded',
                () => {
                    // console.log('annotations loaded');
                },
            );

            instance.Core.documentViewer.addEventListener(
                'documentLoaded',
                this.wvDocumentLoadedHandler,
            );
        });
    }
}
