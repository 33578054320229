import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

export interface ICustomHeaderParams {
    relationKind: number[];
}

@Component({
    standalone: true,
    imports: [CommonModule],
    template: `
        <div class="flex flex-column">
            <p class="text-center">DISCREPANCY</p>
            <div class="flex gap-5 justify-space-evenly">
                <span
                    class="text-center"
                    *ngFor="let item of params.relationKind">
                    {{ item }}
                </span>
            </div>
        </div>
    `,
})
export class DiscrepancyHeaderComponent implements IHeaderAngularComp {
    public params!: IHeaderParams & ICustomHeaderParams;

    agInit(params: IHeaderParams & ICustomHeaderParams): void {
        this.params = params;
    }

    refresh(_params: IHeaderParams) {
        return false;
    }
}
