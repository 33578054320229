@if (loading) {
    <app-loader class="ml-4" />
} @else {
    <app-content-field
        class="metadata"
        *ngFor="let data of metadata"
        [required]="data.isRequired"
        [label]="data.fieldLabel.toUpperCase()">
        {{ data.fieldValue }}
    </app-content-field>
    @if (metadata.length > columnCount && metadata.length % columnCount > 0) {
        <div class="metadata" *ngFor="let cell of placeholderCells()"></div>
    }
    @if (metadata.length === 0) {
        <div class="ml-5">No metadata found.</div>
    }
}
