import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IFloatingFilterAngularComp } from 'ag-grid-angular';
import { IFloatingFilterParams, ISimpleFilter } from 'ag-grid-community';

import {
    SelectComponent,
    SelectionChangeEvent,
} from '../../../select/select.component';
import { AutocompleteComponent } from '../../../autocomplete/autocomplete.component';
import { isNil } from 'app/utils/helpers';
import { PrioritySelectComponent } from '@shared/select/priority-select/priority-select.component';
import { SelectOption } from 'app/models/shared.model';

export interface CustomParams {
    selectList: SelectOption[];
    autocomplete: boolean;
    priority: boolean;
}

@Component({
    standalone: true,
    templateUrl: './select-filter.component.html',
    styleUrl: './select-filter.component.scss',
    imports: [
        FormsModule,
        CommonModule,
        SelectComponent,
        AutocompleteComponent,
        PrioritySelectComponent,
    ],
})
export class SelectFilterComponent implements IFloatingFilterAngularComp {
    params!: IFloatingFilterParams<ISimpleFilter> & CustomParams;
    currentValue: null | string = null;
    list: SelectOption[] = [];
    autocomplete: boolean = false;
    priority: boolean = false;

    agInit(params: IFloatingFilterParams<ISimpleFilter> & CustomParams): void {
        this.params = params;
        this.list = params.selectList ?? [];
        this.autocomplete = params.autocomplete ?? false;
        this.priority = params.priority ?? false;
    }

    onParentModelChanged(parentModel: any) {
        if (!parentModel) {
            this.currentValue = null;
        } else {
            this.currentValue = parentModel.filter;
        }
    }

    onValueChange(value: SelectionChangeEvent): void {
        this.currentValue = value.value;

        if (isNil(this.currentValue)) {
            this.params.parentFilterInstance((instance) => {
                instance.onFloatingFilterChanged(null, null);
            });
            return;
        }

        this.params.parentFilterInstance((parentInstance) => {
            parentInstance.onFloatingFilterChanged('equals', this.currentValue);
        });
    }

    refresh(): boolean {
        return false;
    }
}
