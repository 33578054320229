<div
    [ngClass]="{
        overlay: type === 'overlay',
    }">
    <span
        [ngClass]="{
            loader: true,
            'pos-abs': position === 'absolute',
            'loader--small': size === 'small',
            'loader--medium': size === 'medium',
            'loader--large': size === 'large',
            'loader--xlarge': size === 'xlarge',
        }">
    </span>
</div>
