<app-table
    [columnDefs]="columnDefs"
    (gridReady)="onGridReady($event)"
    noRowsText="No workflow items to show." />

<div *ngIf="showSummaryPanel" style="height: 45vh"></div>
<app-documents-summary-panel
    *ngIf="showSummaryPanel && expandedRow"
    [documentId]="expandedRow.documentId"
    [headerTitle]="expandedRow.documentName"
    [canEditDocument]="canEditDocument"
    (panelClose)="closePanel()"
    [selectedDiscrepancy]="selectedDiscrepancy" />
