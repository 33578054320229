import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IFloatingFilterAngularComp } from 'ag-grid-angular';
import { IFloatingFilterParams, ISimpleFilter } from 'ag-grid-community';
import { debounceTime, distinctUntilChanged } from 'rxjs';

export interface CustomParams {
    class: string;
}

@Component({
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './text-filter.component.html',
    styleUrl: './text-filter.component.scss',
})
export class TextFilterComponent
    implements IFloatingFilterAngularComp, AfterViewInit
{
    @ViewChild('input') input: any;

    params!: IFloatingFilterParams<ISimpleFilter> & CustomParams;
    currentValue: null | string = null;

    agInit(params: IFloatingFilterParams<ISimpleFilter> & CustomParams): void {
        this.params = params;
    }

    ngAfterViewInit() {
        this.input.valueChanges
            .pipe(debounceTime(500))
            .pipe(distinctUntilChanged())
            .subscribe(() => this.onValueChange());
    }

    onParentModelChanged(parentModel: any) {
        if (!parentModel) {
            this.currentValue = null;
        } else {
            this.currentValue = parentModel.filter;
        }
    }

    onValueChange() {
        if (!this.currentValue) {
            this.params.parentFilterInstance((instance) => {
                instance.onFloatingFilterChanged(null, null);
            });
            return;
        }

        this.params.parentFilterInstance((instance: any) => {
            instance.onFloatingFilterChanged('equals', this.currentValue);
        });
    }
}
