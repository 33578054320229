import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';

import { RevisionService } from '@services/api/revision/revision.service';
import { SnackbarService } from '@services/snackbar.service';
import { WorkflowService } from '@services/api/workflow.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { TabsComponent } from '@shared/tabs/tabs.component';
import { WebViewerComponent } from '@shared/webviewer/webviewer.component';
import { ContentFieldComponent } from '@shared/content-field/content-field.component';
import { ActivityLogComponent } from '../activity-log/activity-log.component';
import { PriorityColorDirective } from '@directives/priority-color.directive';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';
import { RevisionDetails } from 'app/models/revision/revision-detail';
import { DiscrepancyDetail } from 'app/models/discrepancy';
import { createPDFDocument, PDFDocument } from 'app/utils/helpers/file';
import { selectOriginalDocumentId } from 'app/store/selectors/document.selectors';

@Component({
    standalone: true,
    templateUrl: 'accept-correction-dialog.component.html',
    styleUrls: [
        'accept-correction-dialog.component.scss',
        '../discrepancies-tab/discrepancies-tab.component.scss',
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatDialogModule,
        FlatButtonComponent,
        LoaderComponent,
        TabsComponent,
        ContentFieldComponent,
        PriorityColorDirective,
        WebViewerComponent,
        ActivityLogComponent,
    ],
})
export class AcceptCorrectionDialogComponent implements OnInit {
    error: boolean = false;
    loading: boolean = false;
    tabsData: { label: string }[] = [];
    selectedTabIndex: number = 0;
    discDetail = DiscrepancyDetail.initial();
    discDetailLoading: boolean = false;
    revisionDocument: PDFDocument | null = null;
    originalDocument: PDFDocument | null = null;
    originalDocumentId: number | null = null;
    documentLoading: boolean = false;
    readonly elementsToBeDisabled = ['ribbons', 'viewControlsButton'];

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { revisionDetails: RevisionDetails },
        public dialogRef: MatDialogRef<AcceptCorrectionDialogComponent>,
        private revisionService: RevisionService,
        private snackbarService: SnackbarService,
        private workflowService: WorkflowService,
        private store: Store,
    ) {}

    ngOnInit(): void {
        this.getOriginalDocumentId();
        this.setsTabData();
        this.onTabChange();
        this.getDocuments();
    }

    get discrepancies() {
        return this.data.revisionDetails.discrepancies
            .map((item) => `Disc. ${item.discrepancyNo}`)
            .join(', ');
    }

    accept() {
        this.error = false;
        this.loading = true;

        this.revisionService
            .acceptRevision(this.data.revisionDetails.id)
            .subscribe({
                next: (success) => {
                    if (success) {
                        this.snackbarService.success({
                            header: `Correction is accepted for ${this.discrepancies}! `,
                        });
                        this.dialogRef.close();
                        this.store.dispatch(refreshActionRelatedData());
                    } else {
                        this.error = true;
                    }
                },
                complete: () => (this.loading = false),
            });
    }

    cancel() {
        this.dialogRef.close();
    }

    onTabChange(index: number | null = null) {
        this.discDetailLoading = true;
        const discrepancyId =
            this.data.revisionDetails.discrepancies[
                index ?? this.selectedTabIndex
            ].id;

        this.workflowService.getDiscrepancyDetails(discrepancyId).subscribe({
            next: (response) => (this.discDetail = response),
            complete: () => (this.discDetailLoading = false),
        });
    }

    private getOriginalDocumentId() {
        const originalDocumentId$ = this.store.select(selectOriginalDocumentId);
        originalDocumentId$.subscribe({
            next: (id) => (this.originalDocumentId = id),
        });
    }

    private setsTabData() {
        this.tabsData = [
            ...this.data.revisionDetails.discrepancies.map((item) => ({
                label: `${item.discrepancyNo}`,
            })),
        ];
    }

    private getDocuments() {
        this.documentLoading = true;

        forkJoin([
            this.getRevision(this.data.revisionDetails.id),
            this.getOriginal(),
        ]).subscribe({
            next: ([revisionDoc, originalDoc]) => {
                this.revisionDocument = revisionDoc
                    ? createPDFDocument(revisionDoc)
                    : null;
                this.originalDocument = originalDoc
                    ? createPDFDocument(originalDoc)
                    : null;
            },
            complete: () => (this.documentLoading = false),
        });
    }

    private getRevision(id: number) {
        return this.revisionService.getRevisionFile(id);
    }

    private getOriginal() {
        if (this.originalDocumentId)
            return this.getRevision(this.originalDocumentId);

        return of(null);
    }
}
