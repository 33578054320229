import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { ILoadingOverlayParams } from 'ag-grid-community';

import { LoaderComponent } from 'app/components/shared/loader/loader.component';

type CustomLoadingOverlayParams = ILoadingOverlayParams & {
    loadingMessage: string;
};

@Component({
    standalone: true,
    imports: [LoaderComponent],
    template: ` <app-loader /> `,
})
export class LoadingOverlayComponent implements ILoadingOverlayAngularComp {
    public params!: CustomLoadingOverlayParams;

    agInit(params: CustomLoadingOverlayParams): void {
        this.refresh(params);
    }

    refresh(params: CustomLoadingOverlayParams): void {
        this.params = params;
    }
}
