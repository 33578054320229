import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { RevisionService } from '@services/api/revision/revision.service';
import { SnackbarService } from '@services/snackbar.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { TextareaComponent } from '@shared/textarea/textarea.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { RejectRevisionRequest } from 'app/models/revision/reject-revision-request';
import { RevisionDetails } from 'app/models/revision/revision-detail';
import { refreshActionRelatedData } from 'app/store/actions/discrepancy.actions';

@Component({
    standalone: true,
    templateUrl: 'reject-correction-dialog.component.html',
    styleUrl: 'reject-correction-dialog.component.scss',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlatButtonComponent,
        TextareaComponent,
        LoaderComponent,
    ],
})
export class RejectCorrectionDialogComponent {
    form!: FormGroup;
    error: boolean = false;
    loading: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { revisionDetails: RevisionDetails },
        public dialogRef: MatDialogRef<RejectCorrectionDialogComponent>,
        private revisionService: RevisionService,
        private snackbarService: SnackbarService,
        private formBuilder: FormBuilder,
        private store: Store,
    ) {
        this.initForm();
    }

    get textPlaceholder() {
        return 'I.e. Image quality between 15 - 19 pages is bad. Re-scan is needed.';
    }

    get discrepancies() {
        return this.data.revisionDetails.discrepancies
            .map((item) => `Disc. ${item.discrepancyNo}`)
            .join(', ');
    }

    get reason() {
        return this.form.get('reason') as FormControl;
    }

    reject() {
        this.error = false;
        this.loading = true;

        this.revisionService
            .rejectRevision(
                this.data.revisionDetails.id,
                new RejectRevisionRequest(this.reason.value),
            )
            .subscribe({
                next: (success) => {
                    if (success) {
                        this.snackbarService.success({
                            header: `Correction is rejected for ${this.discrepancies}! `,
                        });
                        this.dialogRef.close();
                        this.store.dispatch(refreshActionRelatedData());
                    } else {
                        this.error = true;
                    }
                },
                complete: () => (this.loading = false),
            });
    }

    cancel() {
        this.dialogRef.close();
    }

    private initForm() {
        this.form = this.formBuilder.group({
            reason: new FormControl(''),
        });
    }
}
