<div class="p-5">
    <p class="text-lg mb-3">Assign Discrepancy {{ data.id }}</p>
    <p class="text-sm mb-2">Select a person to assign to:</p>
    <div style="height: 30px">
        <app-autocomplete
            [options]="data.options"
            [inputControl]="analyst"
            variant="secondary"
            placeholder="Search by name or e-mail" />
    </div>
    <div class="flex justify-end gap-8 mt-5">
        <app-flat-button mat-dialog-close>Cancel</app-flat-button>
        <app-flat-button
            variant="cta"
            (clicked)="assign()"
            [disabled]="!analyst.value">
            Assign
        </app-flat-button>
    </div>
</div>

@if (loading) {
    <app-loader type="overlay" position="absolute" />
}
