import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
    GridApi,
    GridReadyEvent,
    IServerSideDatasource,
} from 'ag-grid-community';

import { TableComponent } from '@grid-table/table.component';
import { WorkflowService } from '@services/api/workflow.service';

@Component({
    selector: 'app-activity-log',
    standalone: true,
    templateUrl: 'activity-log.component.html',
    styleUrl: 'activity-log.component.scss',
    imports: [CommonModule, TableComponent, MatIconModule, MatButtonModule],
})
export class ActivityLogComponent {
    @Input({ required: true }) discrepancyId!: number;

    @ViewChild('activityLogEnd') activityLogEnd!: ElementRef;
    toggled = false;
    columnDefs = [
        { field: 'user' },
        { field: 'logMessage', headerName: 'activity', flex: 2 },
        { field: 'createdAt', headerName: 'date', resizable: false },
    ];
    private _gridApi!: GridApi;

    constructor(private workflowService: WorkflowService) {}

    get toggleText() {
        return this.toggled ? 'Hide Activity Log' : 'Show Activity Log';
    }

    onGridReady(event: GridReadyEvent) {
        this._gridApi = event.api;

        const datasource = this.getActivityLog();
        this._gridApi.setGridOption('serverSideDatasource', datasource);
    }

    toggleActivityLog() {
        this.toggled = !this.toggled;
    }

    private scrollToArea() {
        this.activityLogEnd.nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
        });
    }

    private getActivityLog(): IServerSideDatasource {
        return {
            getRows: (params) => {
                this.workflowService
                    .getDiscrepancyActivityLogs(this.discrepancyId)
                    .subscribe((data) => {
                        if (data) {
                            params.success({
                                rowData: data,
                                rowCount: data.length,
                            });
                            if (data.length === 0) {
                                params.api.showNoRowsOverlay();
                            }
                            this.scrollToArea();
                        } else {
                            params.fail();
                        }
                    });
            },
        };
    }
}
