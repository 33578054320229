import { Priority } from '../shared.model';

export class WorkflowDocument {
    constructor(
        public id: number,
        public name: string,
        public documentType: string,
        public repository: string,
        public priority: Priority,
        public createdAt: string,
        public station: string,
        public documentServiceId: string | null,
        public maximumDiscrepancyCount: number | null,
        public canAddDiscrepancy: boolean,
    ) {}
}
