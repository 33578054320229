<div style="margin-top: -30px" class="pos-rel color-gray9 w-fit">
    <span>Rows per page</span>
    <button #tableViewToggle [matMenuTriggerFor]="pageSizeMenu" mat-button>
        <span class="color-primary">{{ pageSize }}</span>
        <mat-icon
            svgIcon="chevron-down"
            iconPositionEnd
            class="color-gray9"
            style="width: 12px" />
    </button>
    <mat-menu #pageSizeMenu="matMenu">
        <div
            tabindex="0"
            *ngFor="let size of paginationPageSizeSelector"
            class="mat-mdc-option"
            (click)="onPageChange(size)"
            (keyup.enter)="onPageChange(size)">
            <span [class.color-primary]="pageSize === size">{{ size }}</span>
        </div>
    </mat-menu>
</div>
