<div>
    <label>
        <span class="flex items-start mb-1">
            <span
                [ngClass]="{
                    'text-xxs color-gray14': true,
                    'color-status-red4':
                        (control && control.invalid) || (required && !value),
                }">
                {{ getLabel() }}
            </span>
            <ng-content />
        </span>
        <textarea
            [value]="value"
            (input)="onInput($event)"
            class="input block w-full"
            [attr.placeholder]="placeholder"
            [attr.maxlength]="max">
        </textarea>
        <div *ngIf="max" class="text-xs text-end mt-1 color-gray14">
            {{ value.length }}/{{ max }}
        </div>
    </label>
</div>
