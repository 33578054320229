import {
    Component,
    ViewChild,
    OnInit,
    ElementRef,
    AfterViewInit,
    Input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';

import { environment } from '@environments/environment';
import { PDFDocument } from 'app/utils/helpers/file';

@Component({
    selector: 'app-web-viewer',
    standalone: true,
    imports: [MatIconModule],
    templateUrl: './webviewer.component.html',
    styleUrls: ['./webviewer.component.scss'],
})
export class WebViewerComponent implements OnInit, AfterViewInit {
    @Input({ required: true }) document!: PDFDocument;
    @Input() documentToCompare: PDFDocument | undefined;
    @Input() elementsToBeDisabled: string[] = [];
    @ViewChild('viewer', { static: true }) viewer!: ElementRef;

    instance!: WebViewerInstance;

    ngOnInit() {
        this.onDocumentLoaded = this.onDocumentLoaded.bind(this);
        this.onAnnotationsLoaded = this.onAnnotationsLoaded.bind(this);
    }

    ngAfterViewInit(): void {
        WebViewer(
            {
                path: 'assets/lib',
                licenseKey: environment.apryseLicenseKey,
            },
            this.viewer.nativeElement,
        ).then((instance: WebViewerInstance) => {
            this.instance = instance;
            this.loadDocument();
            this.setDisabledElements();
            this.setOpenElements();
            this.setTheme();
            this.setListeners();
        });
    }

    private loadDocument() {
        const { Core, UI } = this.instance;
        const { enterMultiViewerMode } = UI;

        if (this.documentToCompare) {
            UI.enableFeatures([UI.Feature.MultiViewerMode]);
            enterMultiViewerMode();
            this.addFullScreenButtonToHeader();
            this.hideCloseDocumentButton();

            UI.addEventListener(UI.Events.MULTI_VIEWER_READY, () => {
                const [documentViewerOne, documentViewerTwo] =
                    Core.getDocumentViewers();
                documentViewerOne.loadDocument(this.document.blob, {
                    filename: this.document.name,
                });
                documentViewerTwo.loadDocument(this.documentToCompare!.blob, {
                    filename: this.documentToCompare!.name,
                });
            });
        } else {
            UI.loadDocument(this.document.blob, {
                filename: this.document.name,
            });
        }
    }

    private onDocumentLoaded(): void {
        const { annotationManager, Annotations } = this.instance.Core;
        const rectangle = new Annotations.RectangleAnnotation();
        rectangle.PageNumber = 1;
        rectangle.X = 100;
        rectangle.Y = 100;
        rectangle.Width = 250;
        rectangle.Height = 250;
        rectangle.StrokeThickness = 5;
        rectangle.Author = annotationManager.getCurrentUser();
    }

    private onAnnotationsLoaded(): void {
        console.log('annotations loaded');
    }

    private setDisabledElements() {
        this.instance.UI.disableElements([
            'toolsHeader',
            'panToolButton',
            'selectToolButton',
            'toggleNotesButton',
            'searchButton',
            ...this.elementsToBeDisabled,
        ]);
    }

    private setOpenElements() {
        this.instance.UI.openElements(['leftPanel']);
    }

    private setTheme() {
        this.instance.UI.setTheme(this.instance.UI.Theme.DARK);
    }

    private addFullScreenButtonToHeader() {
        this.instance.UI.setHeaderItems((header) => {
            header.push({
                type: 'actionButton',
                img: 'icon-header-full-screen',
                onClick: () => this.instance.UI.toggleFullScreen(),
                dataElement: 'alertButton',
                hidden: ['mobile'],
            });
        });
    }

    private hideCloseDocumentButton() {
        const matches =
            this.instance.UI.iframeWindow.document.querySelectorAll(
                '.control-buttons',
            );

        matches.forEach((match) => {
            const buttons = match.querySelectorAll('button');
            buttons[1].style.display = 'none';
        });
    }

    private setListeners() {
        const { documentViewer } = this.instance.Core;

        documentViewer.addEventListener(
            'annotationsLoaded',
            this.onAnnotationsLoaded,
        );
        documentViewer.addEventListener(
            'documentLoaded',
            this.onDocumentLoaded,
        );
    }
}
