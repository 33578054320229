import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import endpoints from '@endpoints';
import { interpolate } from 'app/utils/helpers';
import { ApiService } from './api.service';
import { PagedResponse, SimpleResponse } from '../../models/api.model';
import {
    Discrepancy,
    DiscrepancyDetail,
    StatusChipItem,
    DropdownItem,
    DiscrepancyFilterOptions,
    ActivityLogItem,
    DiscrepancyEditOptionsResponse,
    DiscrepancyEditOptions,
    DiscrepancyResponse,
    UpdateDiscrepancyRequest,
} from '../../models/workflow.model';
import { WorkflowMapper } from './workflow.mapper';
import { SharedMapper } from '@services/api/shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class WorkflowService {
    private readonly _path = 'workflow/v1';

    constructor(
        private api: ApiService,
        private mapper: WorkflowMapper,
        private sharedMapper: SharedMapper,
    ) {}

    getDiscrepanciesByDocumentId(
        id: number,
        params = {},
    ): Observable<Discrepancy[]> {
        const url =
            this._path + interpolate(endpoints.documentsDicrepancies, { id });

        return this.api.get<Discrepancy[]>(url, params).pipe(
            map((response: SimpleResponse<DiscrepancyResponse[]>) => {
                return response.data.map(this.mapper.toDiscrepancy) ?? [];
            }),
            catchError(() => of([])),
        );
    }

    getAllDiscrepancies(params = {}) {
        return this.api
            .get<
                Discrepancy[]
            >(this._path + endpoints.discrepancies, params, true)
            .pipe(
                map((response) => {
                    const pagedResponse =
                        response as PagedResponse<DiscrepancyResponse>;
                    return new PagedResponse<Discrepancy>(
                        pagedResponse.pageIndex,
                        pagedResponse.pageSize,
                        pagedResponse.totalItems,
                        pagedResponse.items.map(this.mapper.toDiscrepancy),
                    );
                }),
            );
    }

    getWorkflows(): Observable<DropdownItem[]> {
        return this.api
            .get<DropdownItem[]>(this._path + endpoints.workflows)
            .pipe(
                map((response: SimpleResponse<DropdownItem[]>) =>
                    response.data.map(this.sharedMapper.toDropdownItem),
                ),
                catchError(() => of([])),
            );
    }

    getDiscrepancyDetails(id: number): Observable<DiscrepancyDetail> {
        return this.api
            .get(this._path + interpolate(endpoints.discrepancyDetails, { id }))
            .pipe(
                map((response: SimpleResponse<DiscrepancyDetail>) =>
                    this.mapper.toDiscrepancyDetail(response.data),
                ),
                catchError(() => of(DiscrepancyDetail.initial())),
            );
    }

    getDiscrepanciesFilterOptions(
        params:
            | {
                  workflow: number;
                  repository: number[];
              }
            | undefined,
    ) {
        return this.api
            .get(this._path + endpoints.discrepanciesFilterOptions, params)
            .pipe(
                map((response: SimpleResponse<DiscrepancyFilterOptions>) =>
                    this.mapper.toDiscrepancyFilterOptions(response.data),
                ),
                catchError(() => of(DiscrepancyFilterOptions.initial())),
            );
    }

    getDiscrepancyEditOptions(id: number): Observable<DiscrepancyEditOptions> {
        return this.api
            .get(
                this._path +
                    interpolate(endpoints.discrepancyEditOptions, { id }),
            )
            .pipe(
                map(
                    (
                        response: SimpleResponse<DiscrepancyEditOptionsResponse>,
                    ) => this.mapper.toDiscrepancyEditOptions(response.data),
                ),
                catchError(() => of(DiscrepancyEditOptions.initial())),
            );
    }

    getDiscrepanciesStatusChips(
        params: { workflow: number; repository: any } | undefined,
    ): Observable<StatusChipItem[]> {
        return this.api
            .get(this._path + endpoints.discrepancyStatusChips, params)
            .pipe(
                map((response: SimpleResponse<StatusChipItem[]>) =>
                    (response.data ?? []).map(this.mapper.toStatusChipItem),
                ),
                catchError(() => of([])),
            );
    }

    getDiscrepancyActivityLogs(id: number): Observable<ActivityLogItem[]> {
        return this.api
            .get(
                this._path +
                    interpolate(endpoints.discrepancyActivityLogs, { id }),
            )
            .pipe(
                map((response: SimpleResponse<ActivityLogItem[]>) =>
                    (response.data ?? []).map(this.mapper.toActivityLogItem),
                ),
                catchError(() => of([])),
            );
    }

    takeDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(this._path + interpolate(endpoints.takeDiscrepancy, { id }))
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    putbackDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(
                this._path + interpolate(endpoints.putbackDiscrepancy, { id }),
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    assignDiscrepancy(
        id: number,
        data: { userId: number },
    ): Observable<boolean> {
        return this.api
            .post(
                this._path + interpolate(endpoints.assignDiscrepancy, { id }),
                data,
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    unassignDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(
                this._path + interpolate(endpoints.unassignDiscrepancy, { id }),
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    editDiscrepancy(
        id: number,
        data: UpdateDiscrepancyRequest,
    ): Observable<boolean> {
        return this.api
            .put(
                this._path + interpolate(endpoints.discrepancyById, { id }),
                data,
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    notifyDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(this._path + interpolate(endpoints.notifyDiscrepancy, { id }))
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    renotifyDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(
                this._path + interpolate(endpoints.renotifyDiscrepancy, { id }),
            )
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    addDiscrepancyToDocument(
        id: number,
        data: UpdateDiscrepancyRequest,
    ): Observable<DiscrepancyDetail | null> {
        return this.api
            .post(
                this._path +
                    interpolate(endpoints.documentsDicrepancies, { id }),
                data,
            )

            .pipe(
                map((response: SimpleResponse<DiscrepancyDetail>) =>
                    this.mapper.toDiscrepancyDetail(response.data),
                ),
                catchError(() => of(null)),
            );
    }

    cancelDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(this._path + interpolate(endpoints.cancelDiscrepancy, { id }))
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }

    recallDiscrepancy(id: number): Observable<boolean> {
        return this.api
            .post(this._path + interpolate(endpoints.recallDiscrepancy, { id }))
            .pipe(
                map(() => true),
                catchError(() => of(false)),
            );
    }
}
