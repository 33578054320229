import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-document-status-legend',
    standalone: true,
    imports: [CommonModule, MatIconModule],
    template: `
        <div
            class="status-legend flex items-center gap-10 ml-n4 pl-4 flex-wrap p-3 text-xxs">
            <span class="flex items-center gap-2">
                <mat-icon
                    svgIcon="done-all"
                    class="color-yellow-green7" />Accepted and Applied
            </span>
            <span class="flex items-center gap-2">
                <mat-icon svgIcon="check-circle" />Accepted
            </span>
            <span class="flex items-center gap-2">
                <mat-icon svgIcon="cancel-circle" />Rejected
            </span>
            <span class="flex items-center gap-2">
                <mat-icon svgIcon="upload-circle" />Uploaded - No action taken
            </span>
            <span class="flex items-center gap-2">
                <mat-icon svgIcon="flag-circle" />Discrepancy Created
            </span>
        </div>
    `,
    styles: `
        .status-legend {
            border-top: 1px solid #c5d1d8;
        }
    `,
})
export class DocStatusLegendComponent {}
