import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import {
    ColDef,
    GridApi,
    GridReadyEvent,
    IRowNode,
    IServerSideDatasource,
    IServerSideGetRowsRequest,
} from 'ag-grid-community';

import { WorkflowService } from '@services/api/workflow.service';
import { TextButtonComponent } from '@shared/button/text-button/text-button.component';
import { TextFilterComponent } from '@grid-table/filter/text-filter/text-filter.component';
import { PriorityCellComponent } from '@grid-table/cell/priority-cell/priority-cell.component';
import { TableComponent } from '@grid-table/table.component';
import { CustomCellComponent } from '@grid-table/cell/custom-cell.component.ts/custom-cell.compoent';
import { SelectFilterComponent } from '@grid-table/filter/select-filter/select-filter.component';
import { PanelComponent } from '@shared/panel/panel.component';
import { DateFilterComponent } from '@shared/table/filter/date-filter/date-filter.component';
import { SummaryPanelComponent } from '../summary-panel/summary-panel.component';
import { DocumentService } from '@services/api/document/document.service';
import {
    isFilterApplied,
    setFilterQuery,
    setPageIndex,
    setSortQuery,
} from '../documents-and-discrepancies.utils';
import { DocumentQueryParam } from 'app/models/workflow.model';
import { DiscrepancyFilterOptions } from 'app/models/discrepancy/filter-options';
import { Discrepancy } from 'app/models/discrepancy/item';

@Component({
    selector: 'app-discrepancy-view',
    standalone: true,
    templateUrl: './discrepancy-view.component.html',
    imports: [
        CommonModule,
        AgGridAngular,
        TextButtonComponent,
        TableComponent,
        PanelComponent,
        SummaryPanelComponent,
    ],
})
export class DiscrepencyViewComponent implements OnInit {
    @Input() workflowAndRepo: DocumentQueryParam | undefined;
    @Input() statuses: number[] = [];
    @Input() showMyItems: boolean = false;

    showSummaryPanel: boolean = false;
    expandedRow: Discrepancy | null = null;
    selectedDiscrepancy: number | null = null;
    canEditDocument: boolean = false;
    filterApplied: boolean = false;

    columnDefs: ColDef[] = [];
    private _gridApi!: GridApi;

    constructor(
        private workflowService: WorkflowService,
        private documentService: DocumentService,
    ) {}

    ngOnInit(): void {
        this.getFilterOptions();
    }

    onGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const datasource = this.getAllDiscrepancies();
        this._gridApi.setGridOption('serverSideDatasource', datasource);
    }

    resetFilters() {
        this._gridApi.refreshHeader();
        this._gridApi.setFilterModel(null);
    }

    refreshData() {
        this._gridApi.refreshServerSide({ purge: true });
    }

    isFilterActive() {
        return Object.keys(this._gridApi?.getFilterModel() ?? {})?.length > 0;
    }

    closePanel() {
        this.selectedDiscrepancy = null;
        this.showSummaryPanel = false;
    }

    private isDocumentEditable(documentId: number) {
        this.documentService.canEditDocument(documentId).subscribe({
            next: (canEdit) => (this.canEditDocument = canEdit),
        });
    }

    private getFilterOptions() {
        this.workflowService
            .getDiscrepanciesFilterOptions(this.workflowAndRepo)
            .subscribe({
                next: (response: DiscrepancyFilterOptions) => {
                    this.columnDefs = this.getColumnDefs(response);
                },
            });
    }

    private getAllDiscrepancies(): IServerSideDatasource {
        return {
            getRows: (params) => {
                params.api.hideOverlay();
                this.filterApplied = isFilterApplied(params.request);

                this.workflowService
                    .getAllDiscrepancies(this.getQueryParams(params.request))
                    .subscribe((data) => {
                        if (data) {
                            params.success({
                                rowData: data.items,
                                rowCount: data.totalItems ?? -1,
                            });
                            if (data.items.length === 0) {
                                params.api.showNoRowsOverlay();
                            }
                        } else {
                            params.fail();
                        }
                    });
            },
        };
    }

    private getQueryParams(request: IServerSideGetRowsRequest) {
        const pageSize = this._gridApi.paginationGetPageSize();
        const pageIndex = setPageIndex(request, pageSize);

        // setFilterQuery should be after workflowAndRepo to update repository query of dropdown

        return {
            ...this.workflowAndRepo,
            ...{ status: this.statuses },
            ...{ mine: this.showMyItems },
            ...setFilterQuery(request),
            ...setSortQuery(request),
            pageIndex,
            pageSize,
        };
    }

    private getColumnDefs(filterOptions: DiscrepancyFilterOptions) {
        return [
            {
                field: DiscrepancyColumnType.ID,
                headerName: 'Discrepancy ID',
                filter: 'agTextColumnFilter',
                cellRenderer: CustomCellComponent,
                cellRendererParams: {
                    onClick: (node: IRowNode) => {
                        this.selectedDiscrepancy = node.data.id;
                        this.isDocumentEditable(node.data.documentId);
                        this.expandedRow = node.data;
                        this.showSummaryPanel = true;
                    },
                },
                floatingFilterComponent: TextFilterComponent,
                floatingFilterComponentParams: {
                    class: 'pl-9',
                },
                checkboxSelection: true,
                headerCheckboxSelection: true,
            },
            {
                field: DiscrepancyColumnType.DOC_NAME,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: TextFilterComponent,
            },
            {
                field: DiscrepancyColumnType.REASON,
                filter: 'agTextColumnFilter',
                minWidth: 130,
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.reason,
                },
            },
            {
                field: DiscrepancyColumnType.PRIORITY,
                cellRenderer: PriorityCellComponent,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.priority,
                    priority: true,
                },
            },
            {
                field: DiscrepancyColumnType.STATUS,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.status,
                },
            },
            {
                field: DiscrepancyColumnType.WF_ENTER_DATE,
                headerName: 'WF Enter Date',
                filter: 'agTextColumnFilter',
                floatingFilterComponent: DateFilterComponent,
            },
            {
                field: DiscrepancyColumnType.ANALYST,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: TextFilterComponent,
            },
            {
                field: DiscrepancyColumnType.STATION,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.station,
                    autocomplete: true,
                },
            },
            {
                field: DiscrepancyColumnType.RESPONSIBLE_FIXER,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.responsibleFixer,
                    autocomplete: true,
                },
                resizable: false,
            },
        ];
    }
}

enum DiscrepancyColumnType {
    ID = 'discrepancyNo',
    DOC_NAME = 'documentName',
    REASON = 'reason',
    PRIORITY = 'priority',
    STATUS = 'status',
    WF_ENTER_DATE = 'createdAt',
    ANALYST = 'analyst',
    STATION = 'station',
    RESPONSIBLE_FIXER = 'responsibleFixer',
}
