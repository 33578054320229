<div [formGroup]="form">
    <div>
        @switch (question.fieldType) {
            @case ('TextBox') {
                @if (question.readonly) {
                    <app-content-field [label]="question.label">
                        {{ question.value }}
                    </app-content-field>
                } @else {
                    @if (question.dataType === 'DateTime') {
                        <app-datepicker
                            [formControlName]="question.fieldName"
                            [required]="question.required"
                            [label]="question.label.toLocaleUpperCase()" />
                    } @else {
                        <app-input
                            [formControlName]="question.fieldName"
                            [id]="question.fieldName"
                            [type]="question.dataType"
                            [required]="question.required"
                            [max]="question.maxLength"
                            [label]="question.label.toLocaleUpperCase()" />
                    }
                }
            }
            @case ('ListBox') {
                <app-select
                    [formControlName]="question.fieldName"
                    [id]="question.fieldName"
                    [label]="question.label"
                    [options]="question.options"
                    [value]="question.value" />
            }
        }
    </div>
</div>
