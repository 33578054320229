export class QuestionBase<T> {
    value: T | undefined;
    fieldName: string;
    label: string;
    required: boolean;
    order: number;
    fieldType: QuestionFieldType;
    dataType: QuestionDataType;
    options: { value: string; viewValue: string }[];
    maxLength: number;
    readonly: boolean;
    section: string;
    size: QuestionSize;

    constructor(
        options: {
            value?: T;
            fieldName?: string;
            label?: string;
            required?: boolean;
            order?: number;
            fieldType?: QuestionFieldType;
            dataType?: QuestionDataType;
            options?: { value: string; viewValue: string }[];
            maxLength?: number;
            readonly?: boolean;
            section?: string;
            size?: QuestionSize;
        } = {},
    ) {
        this.value = options.value;
        this.fieldName = options.fieldName || '';
        this.label = options.label || '';
        this.required = !!options.required;
        this.order = options.order === undefined ? 1 : options.order;
        this.fieldType = options.fieldType || 'TextBox';
        this.dataType = options.dataType || 'VarChar';
        this.options = options.options || [];
        this.maxLength =
            options.maxLength === undefined ? 100 : options.maxLength;
        this.readonly = !!options.readonly;
        this.section = options.section || '';
        this.size = options.size || 1;
    }
}

export type QuestionFieldType = 'TextBox' | 'ListBox';

export type QuestionDataType = 'VarChar' | 'DateTime';

export type QuestionSize = 1 | 2;
