<button
    mat-button
    [ngClass]="{
        'color-gray10 text-sm': true,
        'btn--default': variant === 'default',
        'btn--primary': variant === 'primary',
        disabled: disabled,
    }"
    [disabled]="disabled"
    [attr.data-qa]="dataQa"
    (click)="onClick()"
    (keyup.enter)="onKeyup()">
    <ng-content />
</button>
