import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { map } from 'rxjs/operators';

import { RouterService } from '@services/router.service';
import { DocumentService } from '@services/api/document/document.service';

export const DocDetailGuard: CanActivateFn = (route) => {
    const documentService = inject(DocumentService);
    const routerService = inject(RouterService);

    return documentService.canEditDocument(route.params['id']).pipe(
        map((canEdit) => {
            if (canEdit) return true;
            else {
                routerService.goHome();
                return false;
            }
        }),
    );
};
