import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogClose,
} from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

import { WorkflowService } from '@services/api/workflow.service';
import { SnackbarService } from '@services/snackbar.service';
import { SelectComponent, SelectOption } from '@shared/select/select.component';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { AutocompleteComponent } from '@shared/autocomplete/autocomplete.component';
import { LoaderComponent } from '@shared/loader/loader.component';

@Component({
    standalone: true,
    templateUrl: 'assign-discrepancy-dialog.component.html',
    imports: [
        SelectComponent,
        FlatButtonComponent,
        AutocompleteComponent,
        MatDialogClose,
        LoaderComponent,
    ],
})
export class AssignDiscrepancyDialogComponent implements OnInit {
    analyst!: FormControl;
    loading = false;

    constructor(
        public dialogRef: MatDialogRef<AssignDiscrepancyDialogComponent>,
        private workflowService: WorkflowService,
        private snackbarService: SnackbarService,
        @Inject(MAT_DIALOG_DATA)
        public data: { id: number; options: SelectOption[] },
    ) {}

    ngOnInit(): void {
        this.analyst = new FormControl(null);
    }

    assign() {
        const userId = this.analyst.value.value;
        const discId = this.data.id;
        this.loading = true;

        this.workflowService.assignDiscrepancy(discId, { userId }).subscribe({
            next: (success) => {
                if (success) {
                    const assignee = this.getAssignee(userId);

                    this.snackbarService.success({
                        variant: 'success',
                        header: `Discrepancy ${discId} Assigned to ${assignee}`,
                    });
                    this.dialogRef.close({ success: true });
                }
                this.loading = false;
                this.dialogRef.close({ success: false });
            },
        });
    }

    private getAssignee(userId: number) {
        return this.data.options.filter((option) => option.value === userId)[0]
            .viewValue;
    }
}
