import { Injectable, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { AuthService } from '@services/api/auth/auth.service';
import { RouterService } from '@services/router.service';

@Injectable({
    providedIn: 'root',
})
class PermissionsService {
    constructor(
        private authService: AuthService,
        private routerService: RouterService,
    ) {}

    canActivate(): boolean {
        if (this.authService.isLoggedIn()) {
            return true;
        } else {
            this.routerService.goLogin();
            return false;
        }
    }
}

export const AuthGuard: CanActivateFn = (): boolean => {
    return inject(PermissionsService).canActivate();
};
