export class IdDisplayPair {
    constructor(
        public backingField: number,
        public displayName: string,
    ) {}
}

export class DropdownItem {
    constructor(
        public id: number,
        public name: string,
    ) {}
}

export interface SelectOption {
    value: number | string;
    viewValue: string;
}

export enum Priority {
    Low = '0',
    Normal = '1',
    High = '2',
    Critical = '3',
}
