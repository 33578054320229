import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
    standalone: true,
    selector: 'app-text-button',
    imports: [CommonModule, MatButtonModule],
    templateUrl: './text-button.component.html',
    styleUrl: './text-button.component.scss',
})
export class TextButtonComponent {
    @Input() dataQa = '';
    @Input() variant: 'default' | 'primary' | 'tertiary' = 'default';
    @Input() disabled = false;
    @Output() clicked = new EventEmitter();

    onClick() {
        this.clicked.emit();
    }

    onKeyup() {
        this.clicked.emit();
    }
}
