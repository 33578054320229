import { CommonModule } from '@angular/common';
import { Component, Input, NgZone, OnInit } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
    ColDef,
    GetDetailRowDataParams,
    GridApi,
    GridOptions,
    GridReadyEvent,
    IRowNode,
    IServerSideDatasource,
    IServerSideGetRowsRequest,
    RowGroupOpenedEvent,
} from 'ag-grid-community';

import { WorkflowService } from '@services/api/workflow.service';
import { TableComponent } from '@grid-table/table.component';
import { TextFilterComponent } from '@grid-table/filter/text-filter/text-filter.component';
import { PriorityCellComponent } from '@grid-table/cell/priority-cell/priority-cell.component';
import { SelectFilterComponent } from '@grid-table/filter/select-filter/select-filter.component';
import { NoRowsOverlayComponent } from '@grid-table/overlay/no-rows-overlay/no-rows-overlay.component';
import { CustomCellComponent } from '@grid-table/cell/custom-cell.component.ts/custom-cell.compoent';
import { TextButtonComponent } from 'app/components/shared/button/text-button/text-button.component';
import {
    isFilterApplied,
    setFilterQuery,
    setPageIndex,
    setSortQuery,
    toSelectOption,
} from '../documents-and-discrepancies.utils';
import { PanelComponent } from 'app/components/shared/panel/panel.component';
import {
    DocumentFilterOptions,
    DocumentQueryParam,
    WorkflowDocument,
} from 'app/models/workflow.model';
import { SummaryPanelComponent } from '../summary-panel/summary-panel.component';
import { DocumentService } from '@services/api/document/document.service';

@Component({
    selector: 'app-document-view',
    standalone: true,
    templateUrl: './document-view.component.html',
    imports: [
        CommonModule,
        AgGridAngular,
        TextButtonComponent,
        TableComponent,
        PanelComponent,
        SummaryPanelComponent,
    ],
})
export class DocumentViewComponent implements OnInit {
    @Input() workflowAndRepo: DocumentQueryParam | undefined;
    @Input() statuses: number[] = [];
    @Input() showMyItems: boolean = false;

    error = false;
    discrepancies: number[] = [];
    expandedRow: WorkflowDocument | null = null;
    showSummaryPanel: boolean = false;
    selectedDiscrepancy: number | null = null;
    canEditDocument: boolean = false;
    filterApplied: boolean = false;

    columnDefs: ColDef[] = [];
    detailGridOptions: GridOptions = this.getDetailGridOptions();
    detailCellRendererParams = this.getDetailCellRendererParams();
    private _gridApi!: GridApi;

    constructor(
        private documentService: DocumentService,
        private workflowService: WorkflowService,
        private ngZone: NgZone,
    ) {}

    ngOnInit(): void {
        this.getFilterOptions();
    }

    onGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const datasource = this.getDocuments();
        this._gridApi.setGridOption('serverSideDatasource', datasource);
    }

    onRowGroupOpened(params: RowGroupOpenedEvent) {
        if (params.node.expanded) {
            this._gridApi.forEachNode((node) => {
                if (node.rowIndex !== params.node.rowIndex) {
                    this._gridApi.setRowNodeExpanded(node, false);
                }
            });
        }
    }

    resetFilters() {
        this._gridApi.refreshHeader();
        this._gridApi.setFilterModel(null);
    }

    refreshData() {
        this._gridApi.refreshServerSide({ purge: true });
    }

    closePanel() {
        this.selectedDiscrepancy = null;
        this.showSummaryPanel = false;
    }

    private isDocumentEditable(documentId: number) {
        this.documentService.canEditDocument(documentId).subscribe({
            next: (canEdit) => (this.canEditDocument = canEdit),
        });
    }

    private getFilterOptions() {
        this.documentService
            .getDocumentsFilterOptions(this.workflowAndRepo)
            .subscribe({
                next: (response: DocumentFilterOptions) => {
                    this.columnDefs = this.getColumnDefs(response);
                },
            });
    }

    private getDiscrepancies(params: GetDetailRowDataParams) {
        this.workflowService
            .getDiscrepanciesByDocumentId(params.data.id, {
                mine: this.showMyItems,
            })
            .subscribe({
                next: (response) => {
                    this.ngZone.run(() => {
                        this.expandedRow = params.data;
                        this.discrepancies = [
                            ...response.map((item) => item.id),
                        ];
                        params.successCallback(response);
                    });
                },
            });
    }

    private getDocuments(): IServerSideDatasource {
        return {
            getRows: (params) => {
                params.api.hideOverlay();
                params.api.showLoadingOverlay();
                this.filterApplied = isFilterApplied(params.request);

                this.documentService
                    .getDocuments(this.getQueryParams(params.request))
                    .subscribe({
                        next: (data) => {
                            params.api.hideOverlay();
                            if (data) {
                                params.success({
                                    rowData: data.items,
                                    rowCount: data.totalItems ?? -1,
                                });
                                if (data.items.length === 0) {
                                    params.api.showNoRowsOverlay();
                                }
                            } else {
                                params.fail();
                            }
                        },
                        error: () =>
                            this.ngZone.run(() => {
                                this.error = true;
                            }),
                    });
            },
        };
    }

    private expandRow(index: any) {
        this._gridApi.setRowNodeExpanded(index, true);
    }

    private getQueryParams(request: IServerSideGetRowsRequest) {
        const pageSize = this._gridApi.paginationGetPageSize();
        const pageIndex = setPageIndex(request, pageSize);

        // setFilterQuery should be after workflowAndRepo to update repository query of dropdown

        return {
            ...this.workflowAndRepo,
            ...{ status: this.statuses },
            ...{ mine: this.showMyItems },
            ...setFilterQuery(request),
            ...setSortQuery(request),
            pageIndex,
            pageSize,
        };
    }

    private getColumnDefs(filterOptions: DocumentFilterOptions) {
        return [
            {
                field: DocumentColumnType.NAME,
                headerName: 'Document Name',
                showRowGroup: true,
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                    suppressCount: true,
                    suppressDoubleClickExpand: true,
                    innerRenderer: CustomCellComponent,
                    innerRendererParams: {
                        onClick: (node: IRowNode) => {
                            this.expandedRow = node.data;
                            this.isDocumentEditable(node.data.id);
                            this.showSummaryPanel = true;
                            this.expandRow(node);
                            this._gridApi.ensureIndexVisible(
                                node.rowIndex ?? 0,
                                'top',
                            );
                        },
                    },
                },
                filter: 'agTextColumnFilter',
                floatingFilterComponent: TextFilterComponent,
                floatingFilterComponentParams: {
                    class: 'pl-9',
                },
                checkboxSelection: true,
                headerCheckboxSelection: true,
                minWidth: 150,
            },
            {
                field: DocumentColumnType.DOC_TYPE,
                maxWidth: 250,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.documentType.map(toSelectOption),
                    autocomplete: true,
                },
            },
            {
                field: DocumentColumnType.PRIORITY,
                cellRenderer: PriorityCellComponent,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.priority.map(toSelectOption),
                },
            },
            {
                field: DocumentColumnType.REPOSITORY,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.repository.map(toSelectOption),
                },
            },
            {
                field: DocumentColumnType.WF_ENTER_DATE,
                headerName: 'WF Enter Date',
                filter: 'agDateColumnFilter',
            },
            {
                field: DocumentColumnType.STATION,
                resizable: false,
                filter: 'agTextColumnFilter',
                floatingFilterComponent: SelectFilterComponent,
                floatingFilterComponentParams: {
                    suppressFloatingFilterButton: true,
                    selectList: filterOptions.station.map(toSelectOption),
                    autocomplete: true,
                },
            },
        ];
    }

    private getDetailGridOptions() {
        return {
            noRowsOverlayComponent: NoRowsOverlayComponent,
            noRowsOverlayComponentParams: {
                text: 'No discrepancies found',
                isDetail: true,
            },
            defaultColDef: { flex: 1 },
            columnDefs: [
                {
                    field: NestedColumnType.ID,
                    headerName: 'Discrepancy ID',
                    cellRenderer: CustomCellComponent,
                    cellRendererParams: {
                        onClick: (node: IRowNode) => {
                            this.selectedDiscrepancy = node.data.id;
                            this.isDocumentEditable(node.data.documentId);
                            this.showSummaryPanel = true;
                        },
                    },
                },
                { field: NestedColumnType.REASON },
                {
                    field: NestedColumnType.PRIORITY,
                    cellRenderer: PriorityCellComponent,
                },
                { field: NestedColumnType.STATUS },
                { field: NestedColumnType.TAKEN },
                { field: NestedColumnType.ANALYST },
                { field: NestedColumnType.STATION },
                { field: NestedColumnType.RESPONSIBLE_FIXER, resizable: false },
            ],
        };
    }

    private getDetailCellRendererParams() {
        return {
            detailGridOptions: this.detailGridOptions,
            getDetailRowData: (params: GetDetailRowDataParams) =>
                this.getDiscrepancies(params),
        };
    }
}

enum DocumentColumnType {
    NAME = 'name',
    DOC_TYPE = 'documentType',
    PRIORITY = 'priority',
    REPOSITORY = 'repository',
    WF_ENTER_DATE = 'createdAt',
    STATION = 'station',
}

enum NestedColumnType {
    ID = 'discrepancyNo',
    REASON = 'reason',
    PRIORITY = 'priority',
    STATUS = 'status',
    TAKEN = 'taken',
    ANALYST = 'analyst',
    STATION = 'station',
    RESPONSIBLE_FIXER = 'responsibleFixer',
}
