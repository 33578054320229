import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    SnackbarComponent,
    SnackbarComponentProps,
} from 'app/components/shared/snackbar/snackbar.component';

@Injectable({
    providedIn: 'root',
})
export class SnackbarService {
    constructor(private snackBar: MatSnackBar) {}

    error(data: SnackbarComponentProps) {
        return this.snackBar.openFromComponent(SnackbarComponent, {
            data: { ...data, variant: 'error' },
            horizontalPosition: 'end',
            verticalPosition: 'top',
        });
    }

    success(data: SnackbarComponentProps) {
        return this.snackBar.openFromComponent(SnackbarComponent, {
            data: { ...data, variant: 'success' },
            horizontalPosition: 'end',
            verticalPosition: 'top',
        });
    }

    progress(data: SnackbarComponentProps) {
        return this.snackBar.openFromComponent(SnackbarComponent, {
            data: { ...data, variant: 'progress' },
            horizontalPosition: 'end',
            verticalPosition: 'top',
        });
    }
}
