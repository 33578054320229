import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { RouterOutlet } from '@angular/router';

import { WorkflowInboxComponent } from '../../components/workflow-inbox/workflow-inbox.component';
import { DocumentAndDiscrepenciesComponent } from '../../components/documents-and-discrepancies/documents-and-discrepancies.component';

@Component({
    standalone: true,
    templateUrl: './documents-and-discrepancies.html',
    styleUrls: ['./documents-and-discrepancies.scss'],
    imports: [
        RouterOutlet,
        MatButtonModule,
        MatCardModule,
        CommonModule,
        WorkflowInboxComponent,
        DocumentAndDiscrepenciesComponent,
    ],
})
export class DocumentsAndDiscrepanciesComponent {
    constructor() {}
}
