<div class="tabs">
    <div class="tab-labels flex text-md" [ngClass]="labelClass">
        <div
            tabindex="0"
            *ngFor="let tab of tabs; let i = index"
            (click)="selectTab(i)"
            (keyup.enter)="selectTab(i)"
            class="tab"
            [class.active]="i === selectedTabIndex">
            {{ tab.label }}
        </div>
    </div>
    <div class="tab-content" [ngClass]="tabContentClass">
        <ng-content />
    </div>
</div>
