import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import endpoints from '@endpoints';
import { ApiService } from '../api.service';
import { interpolate } from 'app/utils/helpers';
import { SimpleResponse } from 'app/models/api.model';
import { IdDisplayPair } from 'app/models/workflow.model';
import { toSelectOption } from 'app/components/documents-and-discrepancies/documents-and-discrepancies.utils';
import { SelectOption } from '@shared/select/select.component';

@Injectable({
    providedIn: 'root',
})
export class StationService {
    private readonly _path = 'workflow/v1';

    constructor(private api: ApiService) {}

    getStationFixers(id: number): Observable<SelectOption[]> {
        return this.api
            .get(this._path + interpolate(endpoints.stationFixers, { id }))
            .pipe(
                map((response: SimpleResponse<IdDisplayPair[]>) =>
                    (response?.data ?? []).map(toSelectOption),
                ),
                catchError(() => of([])),
            );
    }
}
