import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { InvalidPageRangeErrorComponent } from './invalid-page-range.component';

@Component({
    selector: 'app-validation-error',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InvalidPageRangeErrorComponent,
    ],
    templateUrl: './validation-error.component.html',
})
export class ValidationErrorComponent {
    @Input() control!: FormControl;

    constructor() {}
}
