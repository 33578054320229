import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';

import { selectDiscrepancyCount } from 'app/store/selectors/document.selectors';
import { CommonModule } from '@angular/common';

@Component({
    standalone: true,
    imports: [CommonModule, MatIconModule],
    template: `
        <div *ngIf="count" class="flex items-center text-xxs whitespace-pre">
            Showing <span class="text-semi">{{ count.filtered }}</span> out of
            <span class="text-semi">{{ count.total }}</span>
            discrepancies
        </div>
    `,
})
export class CountStatusBarComponent implements IStatusPanelAngularComp {
    count: { filtered: number; total: number } | null = null;
    count$: Observable<{ filtered: number; total: number }> | undefined;

    constructor(private store: Store) {}

    agInit(_params: IStatusPanelParams): void {
        this.count$ = this.store.select(selectDiscrepancyCount);
        this.count$.subscribe({
            next: (value) => (this.count = value),
        });
    }
}
