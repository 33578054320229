@if (files) {
    <div class="file">
        <div style="align-self: flex-start" class="flex items-center ml-4">
            <div class="text-sm">
                <span class="color-primary mr-1"> {{ files[0].name }}</span>
                <span *ngIf="progress">(%{{ progress }})</span>
            </div>
            <button
                mat-icon-button
                aria-label="Close"
                (click)="onCloseClick()"
                class="ml-auto">
                <mat-icon svgIcon="close" />
            </button>
        </div>
        <div *ngIf="progress && progress > 0 && progress <= 100">
            <mat-progress-bar mode="determinate" [value]="progress" />
        </div>
    </div>
} @else {
    <div
        class="dropzone"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        (drop)="onDrop($event)"
        [class.dragging]="isDragging"
        (click)="onClick()"
        (keypress)="onClick()"
        tabindex="0">
        <p class="text-md flex">
            <span class="color-primary cursor-pointer flex mr-1">
                <mat-icon svgIcon="upload"></mat-icon>Upload
            </span>
            Or Drag & Drop here
        </p>
        <p class="text-xs">(.PDF format)</p>
        <div *ngIf="errorMessage" class="error-message">
            {{ errorMessage }}
        </div>
    </div>
    <input
        type="file"
        #fileInput
        (change)="onFileSelected($event)"
        style="display: none"
        multiple
        [accept]="acceptContentTypes" />
}
