import { CommonModule } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

import { RouterService } from '@services/router.service';
import { TabsComponent } from 'app/components/shared/tabs/tabs.component';
import { DiscrepanciesTabComponent } from './discrepancies-tab/discrepancies-tab.component';
import { DocumentMetadataTabComponent } from './document-metadata-tab/document-metadata-tab.component';
import { InternalsTabComponent } from './internals-tab/internals-tab.component';
import { ResizableDirective } from 'app/directives/resizable.directive';
import { PanelComponent } from 'app/components/shared/panel/panel.component';

@Component({
    selector: 'app-documents-summary-panel',
    standalone: true,
    templateUrl: './summary-panel.component.html',
    styleUrl: './summary-panel.component.scss',
    imports: [
        CommonModule,
        TabsComponent,
        DiscrepanciesTabComponent,
        DocumentMetadataTabComponent,
        InternalsTabComponent,
        ResizableDirective,
        PanelComponent,
    ],
})
export class SummaryPanelComponent implements OnInit, OnChanges {
    @Input({ required: true }) documentId!: number;
    @Input() selectedDiscrepancy: number | null = null;
    @Input() canEditDocument = false;
    @Input() headerTitle = '';
    @Output() panelClose = new EventEmitter();

    readonly tabs: typeof DocumentSummaryTab = DocumentSummaryTab;
    activeTab = DocumentSummaryTab.METADATA;
    initialSelectedTabIndex: number | null = null;
    tabsData = [
        { id: DocumentSummaryTab.METADATA, label: 'Document Metadata' },
        { id: DocumentSummaryTab.DISCREPANCIES, label: 'Discrepancies' },
        { id: DocumentSummaryTab.WF_INTERNALS, label: 'WF Internals' },
    ];

    constructor(private routerService: RouterService) {}

    ngOnInit() {
        if (this.selectedDiscrepancy) {
            this.openDiscrepancyTab();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const selectedDiscrepancyChange = changes['selectedDiscrepancy'];
        if (
            selectedDiscrepancyChange &&
            !selectedDiscrepancyChange.firstChange
        ) {
            this.openDiscrepancyTab();
        }
    }

    goToDetails() {
        this.routerService.goDocumentDetails(this.documentId);
    }

    closePanel() {
        this.panelClose.emit();
    }

    private openDiscrepancyTab() {
        this.activeTab = DocumentSummaryTab.DISCREPANCIES;
        this.initialSelectedTabIndex = this.tabsData.findIndex(
            (item) => item.id === this.activeTab,
        );
    }
}

enum DocumentSummaryTab {
    METADATA,
    DISCREPANCIES,
    WF_INTERNALS,
}
