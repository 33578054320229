import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    standalone: true,
    selector: 'app-tabs',
    imports: [CommonModule],
    templateUrl: './tabs.component.html',
    styleUrl: './tabs.component.scss',
})
export class TabsComponent implements OnInit, OnChanges {
    @Input({ required: true }) tabs: { label: string }[] = [];
    @Input() alignment: 'start' | 'center' | 'end' = 'start';
    @Input() initialSelectedTabIndex: number | null = null;
    @Input() tabLabelClass = '';
    @Input() tabContentClass = '';
    @Output() tabSelected = new EventEmitter<number>();

    selectedTabIndex: number = 0;

    get labelClass() {
        return {
            'justify-start': this.alignment === 'start',
            'justify-end': this.alignment === 'end',
            'justify-center': this.alignment === 'center',
            [this.tabLabelClass]: this.tabLabelClass,
        };
    }

    constructor() {}

    ngOnInit(): void {
        if (this.initialSelectedTabIndex) {
            this.selectedTabIndex = this.initialSelectedTabIndex;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        const initialSelectedTabIndexChange =
            changes['initialSelectedTabIndex'];

        if (
            initialSelectedTabIndexChange &&
            !initialSelectedTabIndexChange.firstChange
        ) {
            this.selectedTabIndex = initialSelectedTabIndexChange.currentValue;
        }
    }

    selectTab(index: number): void {
        this.selectedTabIndex = index;
        this.tabSelected.emit(index);
    }
}
