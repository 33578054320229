export class UserResponse {
    constructor(
        public id: number,
        public fullName: string,
        public userName: string,
        public email: string,
        public rmsUserId: number,
        public roles: Roles[],
    ) {}
}

export enum Roles {
    Admin,
    Analyst,
    Fixer,
}
