import { CommonModule } from '@angular/common';
import {
    Component,
    ComponentRef,
    Directive,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewContainerRef,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { ErrorMessages } from 'app/utils/constants/error-messages';

@Directive({
    selector: '[wfeDragDrop]',
    standalone: true,
})
export class DragDropDirective implements OnInit {
    @Input() overlayMessage: string = '';
    @Input() maxFileCount: number = 1;
    @Input() externalError: boolean = false;
    @Output() fileDropped = new EventEmitter<File>();

    private _hasError = false;
    private _overlayElement: ComponentRef<DragDropOverlayComponent> | null =
        null;

    constructor(
        private el: ElementRef,
        private viewContainerRef: ViewContainerRef,
    ) {}

    ngOnInit(): void {
        this._hasError = this.externalError;
    }

    @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.showOverlay(true, event);
    }

    @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        const target = event.relatedTarget as HTMLElement;
        if (!this.el.nativeElement.contains(target)) {
            this.showOverlay(false);
        }
    }

    @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.showOverlay(false);

        if (!this._hasError && event.dataTransfer?.files.length) {
            const file = event.dataTransfer.files[0];
            this.fileDropped.emit(file);
        }
    }

    private checkFileCountError(event: DragEvent): boolean {
        const fileCountExceeded =
            this.maxFileCount < (event.dataTransfer?.items.length ?? 0);

        if (fileCountExceeded) {
            this.showErrorComponent(
                this.maxFileCount === 1
                    ? ErrorMessages.ONE_FILE_ALLOWED
                    : `Maximum file count is: ${this.maxFileCount}`,
            );
        }

        return fileCountExceeded;
    }

    private showOverlay(isVisible: boolean, event: DragEvent | null = null) {
        if (isVisible) {
            if (this.externalError) {
                this.showErrorComponent();
            } else {
                const fileCountExceeded = event
                    ? this.checkFileCountError(event)
                    : false;

                this._hasError = fileCountExceeded;

                if (!fileCountExceeded) {
                    this.showDefaultOverlay();
                }
            }
        } else {
            this.hideOverlay();
        }
    }

    private showErrorComponent(message: string | null = null) {
        if (!this._overlayElement) {
            this._overlayElement = this.viewContainerRef.createComponent(
                DragDropOverlayComponent,
            );
            this.el.nativeElement.appendChild(
                this._overlayElement.location.nativeElement,
            );
            this._overlayElement.instance.message =
                message || this.overlayMessage;
            this._overlayElement.instance.type = 'error';
        }
    }

    private hideOverlay() {
        if (this._overlayElement) {
            this._overlayElement.destroy();
            this._overlayElement = null;
        }
    }

    private showDefaultOverlay() {
        if (!this._overlayElement) {
            this._overlayElement = this.viewContainerRef.createComponent(
                DragDropOverlayComponent,
            );
            this.el.nativeElement.appendChild(
                this._overlayElement.location.nativeElement,
            );
            this._overlayElement.instance.message = this.overlayMessage;
        }
    }
}

@Component({
    standalone: true,
    imports: [CommonModule, MatIconModule],
    template: `
        <mat-icon
            *ngIf="type === 'error'"
            svgIcon="status-cancel"
            class="color-status-red4"
            style="height: 44px; width: 44px;" />
        <p>{{ message }}</p>
    `,
    styles: [
        `
            :host {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                color: black;
                z-index: 1000;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-wrap: wrap;
                justify-content: center;
                text-align: center;
                padding: 30px;
            }
        `,
    ],
})
class DragDropOverlayComponent {
    @Input() message: string = '';
    @Input() type: 'default' | 'error' = 'default';

    @HostBinding('style.background') get backgroundColor() {
        return this.type == 'error' ? '#f5f0f0cc' : '#ffffffcc';
    }
    @HostBinding('style.border') get border() {
        return this.type == 'error'
            ? '1px solid #632323'
            : '1px dashed #4C6472';
    }
}
