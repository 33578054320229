import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';

import { AuthService } from '@services/api/auth/auth.service';

@Component({
    selector: 'app-login',
    standalone: true,
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
    imports: [CommonModule, MatButtonModule, MatDividerModule, MatCardModule],
})
export class LoginComponent {
    constructor(private authService: AuthService) {}

    login() {
        this.authService.login();
    }
}
