@if (error) {
    <p>Documents can't be loaded</p>
} @else {
    <app-table
        [columnDefs]="columnDefs"
        [masterDetail]="true"
        [detailCellRendererParams]="detailCellRendererParams"
        noRowsText="No workflow documents to show."
        (rowGroupOpened)="onRowGroupOpened($event)"
        (gridReady)="onGridReady($event)">
    </app-table>
    <div *ngIf="showSummaryPanel" style="height: 45vh"></div>
    <app-documents-summary-panel
        *ngIf="showSummaryPanel && expandedRow"
        [headerTitle]="expandedRow.name"
        [canEditDocument]="canEditDocument"
        (panelClose)="closePanel()"
        [documentId]="expandedRow.id"
        [selectedDiscrepancy]="selectedDiscrepancy" />
}
