import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '@environments/environment';
import { Cookies } from 'app/utils/constants';
import endpoints from '@endpoints';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private apiUrl = environment.apiUrl;

    constructor(
        private cookieService: CookieService,
        @Inject(DOCUMENT) private window: Window,
    ) {}

    login() {
        this.window.location.href = this.apiUrl + endpoints.login;
    }

    isLoggedIn(): boolean {
        return this.cookieService.check(Cookies.UAA_ACCESS_TOKEN);
    }

    getToken(): string {
        return this.cookieService.get(Cookies.UAA_ACCESS_TOKEN);
    }

    logout() {
        const domain = this.window.location.host;
        this.cookieService.delete(Cookies.UAA_ACCESS_TOKEN, '/', domain);
        this.cookieService.delete(Cookies.OIDC_ACCESS_TOKEN, '/', domain);
        this.cookieService.deleteAll();
        this.window.location.href = this.apiUrl + endpoints.logout;
    }
}
