import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IRowNode } from 'ag-grid-community';

import { DocumentRevisionItem } from 'app/models/document/revision';

interface CustomParams {
    data: DocumentRevisionItem;
    onClick: (node: IRowNode) => void;
}

@Component({
    standalone: true,
    imports: [CommonModule, MatIconModule],
    template: `
        @if (params.data.isHeader) {
            {{ params.data.revisionName }}
        } @else {
            <span
                *ngIf="!params.data.isHeader"
                tabindex="0"
                (click)="params.onClick(params.node)"
                (keyup.enter)="params.onClick(params.node)"
                class="color-primary flex items-center gap-4 cursor-pointer">
                <mat-icon
                    *ngIf="params.data.sentForConnection"
                    svgIcon="send-circle"
                    style="minWidth: 24px" />
                {{ params.data.revisionName }}
            </span>
        }
    `,
    styles: ``,
})
export class VersionNameCellComponent implements ICellRendererAngularComp {
    public params!: ICellRendererParams & CustomParams;

    agInit(params: ICellRendererParams & CustomParams): void {
        this.params = params;
    }

    refresh() {
        return false;
    }
}
