<div *ngIf="isLoggedIn" class="navbar fs-14">
    <div class="tabs">
        <div
            tabindex="0"
            *ngFor="let tab of tabs"
            class="tab"
            [attr.data-id]="tab.id"
            [class.active]="tab.id === activeTabId"
            (click)="setActiveTab(tab)"
            (keyup.enter)="setActiveTab(tab)">
            <mat-icon class="tab-icon" [svgIcon]="tab.icon" />
            {{ tab.label }}
            <mat-icon
                *ngIf="tab.isMenu"
                svgIcon="chevron-down"
                style="width: 12px; display: none" />
        </div>
    </div>
    <span class="spacer"></span>
    <button
        mat-icon-button
        aria-label="User"
        [matMenuTriggerFor]="userMenu"
        class="mr-2">
        <mat-icon class="color-gray16" svgIcon="user" />
    </button>
</div>

<mat-menu #userMenu="matMenu">
    <div style="width: 150px">
        <button mat-menu-item style="min-height: 30px">Account Settings</button>
        <button mat-menu-item style="min-height: 30px" (click)="logout()">
            Logout
        </button>
    </div>
</mat-menu>
