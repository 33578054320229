import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    Renderer2,
} from '@angular/core';

@Directive({
    selector: '[wfeStatusBox]',
    standalone: true,
})
export class StatusBoxDirective implements OnChanges {
    @Input() wfeStatusBox!: '';

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngOnChanges(): void {
        this.renderer.setStyle(
            this.el.nativeElement,
            'border',
            '1px solid #d8e0e5',
        );
        this.renderer.setStyle(
            this.el.nativeElement,
            'background-color',
            '#ebeff2',
        );
        this.renderer.setStyle(this.el.nativeElement, 'padding', '1px 6px');
        this.renderer.setStyle(this.el.nativeElement, 'display', 'inline-flex');
        this.renderer.setStyle(this.el.nativeElement, 'align-items', 'center');
        this.renderer.setStyle(this.el.nativeElement, 'height', '24px');
        this.renderer.setStyle(this.el.nativeElement, 'white-space', 'nowrap');
    }
}
