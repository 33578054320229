<div class="p-5">
    <p class="text-lg mb-3">Upload Correction</p>
    <p class="text-sm mb-3">Correction upload...</p>
    <app-dropzone
        [acceptedFiles]="acceptedFiles"
        (fileReady)="onFileReady($event)"
        [progress]="progress" />
    <div class="text-sm mt-5">
        <p class="mb-2">
            Which discrepanc(ies) are you uploading this correction for? *
        </p>
        <div class="grid gap-8">
            <app-checkbox
                *ngFor="let item of data.discrepancies"
                label="Discrepancy {{ item.discrepancyNo }}"
                (checkedChange)="onDiscrepancySelected($event, item.id)" />
        </div>
    </div>
    <div class="flex justify-end gap-8 mt-5">
        <small *ngIf="error" class="color-status-red4">
            Something went wrong
        </small>
        <app-flat-button mat-dialog-close>Cancel</app-flat-button>
        <app-flat-button
            variant="tertiary"
            (clicked)="upload()"
            [disabled]="selectedDiscrepancies.length === 0 || !files">
            Upload
        </app-flat-button>
    </div>
</div>
