<div class="dialog-content">
    <div class="half pt-5 pr-5 pl-5 overflow-hidden">
        <div class="flex items-start justify-space-between mb-3">
            <p class="text-lg">Accept Correction</p>
            <div class="flex gap-10">
                @if (error) {
                    <small class="color-status-red4">
                        Something went wrong
                    </small>
                }
                <app-flat-button (clicked)="cancel()">Cancel</app-flat-button>
                <app-flat-button (clicked)="accept()" variant="success">
                    Accept
                </app-flat-button>
            </div>
        </div>
        <div class="p-3 text-sm mb-4 summary">
            You will be accepting this correction for:
            <span class="text-semi">{{ discrepancies }}</span>
        </div>
        <app-tabs
            class="h-full"
            [tabs]="tabsData"
            (tabSelected)="onTabChange($event)"
            tabLabelClass="text-s">
            @if (discDetailLoading) {
                <app-loader />
            } @else {
                <div class="info-section bordered">
                    <app-content-field label="ANALYST">
                        {{ discDetail.analyst?.displayName ?? '-' }}
                    </app-content-field>
                    <app-content-field label="STATUS">
                        <span wfeStatusBox>
                            {{ discDetail.status }}
                        </span>
                    </app-content-field>
                    <app-content-field label="PRIORITY">
                        <span [wfePriorityField]="discDetail.priority"></span>
                    </app-content-field>
                    <app-content-field label="CREATE DATE">
                        {{ discDetail.createdAt }}
                    </app-content-field>
                </div>
                <div class="info-section">
                    <app-content-field label="REASON">
                        {{ discDetail.reason?.displayName }}
                    </app-content-field>
                    <app-content-field label="PAGE RANGE">
                        {{ discDetail.pageRange || '-' }}
                    </app-content-field>
                </div>
                <div class="info-section">
                    <app-content-field label="RESPONSIBLE STATION">
                        {{ discDetail.station?.displayName }}
                    </app-content-field>
                    <app-content-field label="RESPONSIBLE FIXER">
                        {{ discDetail.responsibleFixer?.displayName ?? '-' }}
                    </app-content-field>
                </div>
                <div class="info-section">
                    <app-content-field label="DIRECTIONS">
                        {{ discDetail.directions }}
                    </app-content-field>
                </div>
                <app-activity-log [discrepancyId]="discDetail.id" />
            }
        </app-tabs>
    </div>
    <div class="half bg-gray18 overflow-auto">
        @if (documentLoading) {
            <div class="h-full flex items-center justify-center">
                <app-loader />
            </div>
        } @else {
            @if (revisionDocument && originalDocument) {
                <app-web-viewer
                    [document]="revisionDocument"
                    [documentToCompare]="originalDocument"
                    [elementsToBeDisabled]="elementsToBeDisabled" />
            }
        }
    </div>
</div>

@if (loading) {
    <app-loader type="overlay" />
}
