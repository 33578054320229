<div class="inline-flex items-center w-full">
    <input
        matInput
        [ngClass]="{ secondary: variant === 'secondary' }"
        class="w-full h-full"
        [placeholder]="placeholder"
        [formControl]="inputControl"
        #trigger="matAutocompleteTrigger"
        [matAutocomplete]="auto" />
</div>
<mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="onSelectionChange($event.option)">
    @for (option of filteredOptions | async; track option) {
        <mat-option [value]="option">{{ option.viewValue }}</mat-option>
    }
</mat-autocomplete>
