<div class="flex flex-wrap gap-7">
    <app-tooltip *ngFor="let chip of statusChips">
        <app-chip
            trigger
            (clicked)="handleFilterSelect(chip.backingField)"
            [defaultSelected]="selectedFilterItems.includes(chip.backingField)">
            {{ chip.name }}({{ chip.assigned }}/{{ chip.unassigned }}/{{
                chip.total
            }})
        </app-chip>
        <div content>
            <strong>{{ chip.assigned }}</strong> assigned to me /
            <strong>{{ chip.unassigned }}</strong> unassigned /
            <strong>{{ chip.total }}</strong> total
        </div>
    </app-tooltip>
</div>
