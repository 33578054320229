import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogClose,
} from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { SnackbarService } from '@services/snackbar.service';
import { SelectComponent } from '@shared/select/select.component';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { AutocompleteComponent } from '@shared/autocomplete/autocomplete.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { SelectOption } from 'app/models/shared.model';
import { DiscrepancyIdNo } from 'app/models/discrepancy/item';

@Component({
    standalone: true,
    templateUrl: 'assign-discrepancy-dialog.component.html',
    imports: [
        SelectComponent,
        FlatButtonComponent,
        AutocompleteComponent,
        MatDialogClose,
        LoaderComponent,
    ],
})
export class AssignDiscrepancyDialogComponent implements OnInit {
    analyst!: FormControl;
    loading = false;

    constructor(
        public dialogRef: MatDialogRef<AssignDiscrepancyDialogComponent>,
        public discrepancyService: DiscrepancyService,
        private snackbarService: SnackbarService,
        @Inject(MAT_DIALOG_DATA)
        public data: { options: SelectOption[] } & DiscrepancyIdNo,
    ) {}

    ngOnInit(): void {
        this.analyst = new FormControl(null);
    }

    assign() {
        const userId = this.analyst.value.value;
        const discId = this.data.id;
        this.loading = true;

        this.discrepancyService
            .assignDiscrepancy(discId, { userId })
            .subscribe({
                next: (success) => {
                    if (success) {
                        const assignee = this.getAssignee(userId);

                        this.snackbarService.success({
                            variant: 'success',
                            header: `Discrepancy ${this.data.discrepancyNo} Assigned to ${assignee}`,
                        });
                        this.dialogRef.close({ success: true });
                    }
                    this.loading = false;
                    this.dialogRef.close({ success: false });
                },
            });
    }

    private getAssignee(userId: number) {
        return this.data.options.filter(
            (option: SelectOption) => option.value === userId,
        )[0].viewValue;
    }
}
