import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Patterns } from 'app/utils/constants/patterns';

export function pageRangeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value as string;
        const pageRangePattern = new RegExp(Patterns.PAGE_RANGE);

        if (!value || pageRangePattern.test(value)) {
            return null;
        }

        return { pageRange: true };
    };
}

export function duplicateDocNameValidator(docName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const inputValue = control.value;

        return inputValue === docName
            ? { duplicateDocName: { value: inputValue } }
            : null;
    };
}
