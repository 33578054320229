import { createReducer, on } from '@ngrx/store';

import { DocumentState } from '../state/document.state';
import {
    saveOriginalDocumentId,
    saveRevisionList,
    updateDiscrepancyCount,
    updateSelectedRevision,
} from '../actions/document.actions';

export const initialState: DocumentState = {
    id: null,
    selectedRevision: null,
    revisionList: [],
    discrepancyCount: {
        filtered: 0,
        total: 0,
    },
    originalDocumentId: null,
};

export const documentReducer = createReducer(
    initialState,
    on(
        updateSelectedRevision,
        (state, { selectedRevision }): DocumentState => ({
            ...state,
            selectedRevision,
        }),
    ),
    on(
        saveRevisionList,
        (state, { revisionList }): DocumentState => ({
            ...state,
            revisionList,
        }),
    ),
    on(
        updateDiscrepancyCount,
        (state, { discrepancyCount }): DocumentState => ({
            ...state,
            discrepancyCount,
        }),
    ),
    on(
        saveOriginalDocumentId,
        (state, { originalDocumentId }): DocumentState => ({
            ...state,
            originalDocumentId,
        }),
    ),
);
