<div>
    <div class="header text-sm flex items-center ml-n4 pl-4 bg-gray0">
        Documents
    </div>
    <div class="ml-n4 pl-4 pr-3 pt-3 bg-white">
        <div class="flex justify-space-between items-center mb-4">
            <span class="text-sm text-med">
                <b>{{ documentDetails.name }} Versions ({{ versionCount }})</b>
            </span>
            <app-flat-button
                variant="tertiary"
                (clicked)="onUploadClick()"
                class="upload-button">
                <mat-icon svgIcon="upload"></mat-icon>
                Upload Correction
            </app-flat-button>
        </div>
        <app-table
            variant="secondary"
            class="flex mb-4"
            [columnDefs]="columnDefs"
            [suppressMenu]="true"
            [suppressMovableColumns]="true"
            [suppressRowHoverHighlight]="true"
            [suppressCellFocus]="true"
            [pagination]="false"
            [pageSize]="10"
            [getRowStyle]="getRowStyle"
            [gridOptions]="gridOptions"
            (gridReady)="onGridReady($event)" />
        <app-document-status-legend />
    </div>
</div>
