import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { SnackbarService } from '@services/snackbar.service';
import { DocumentService } from '@services/api/document/document.service';
import { DynamicFormQuestionComponent } from '@shared/question/question.component';
import { QuestionBase } from '@shared/question/question-base';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { QuestionControlService } from '@shared/question/question-control.service';
import { groupBy } from 'app/utils/helpers';
import { ContentFieldComponent } from '@shared/content-field/content-field.component';
import {
    UpdateMetadataRequest,
    UpdateWorkflowDocumentMetadataRequest,
} from 'app/models/update-document-metadata';
import {
    SubscriptionList,
    SubscriptionListType,
} from 'app/utils/helpers/subscription';
import { moment } from 'app/utils/moment';

@Component({
    selector: 'app-document-metadata-edit',
    standalone: true,
    templateUrl: 'document-metadata-edit.component.html',
    styleUrl: 'document-metadata-edit.component.scss',
    providers: [QuestionControlService],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DynamicFormQuestionComponent,
        FlatButtonComponent,
        LoaderComponent,
        ContentFieldComponent,
    ],
})
export class DocumentMetadataEditComponent implements OnInit {
    @Input() documentId!: number;

    form!: FormGroup;
    initialFormValues: any;
    formChanged: boolean = false;
    questions: QuestionBase<any>[] = [];
    grouped: Record<string, QuestionBase<string>[]> = {};
    loading: boolean = false;
    error: boolean = false;
    private _subscriptions = new SubscriptionList() as SubscriptionListType;

    constructor(
        private documentService: DocumentService,
        private qcs: QuestionControlService,
        private snackbarService: SnackbarService,
    ) {}

    ngOnInit(): void {
        this.getMetadataOptions();
    }

    onSave(): void {
        this.loading = true;
        this.error = false;
        this.documentService
            .updateMetadata(this.documentId, this.preparePostData())
            .subscribe({
                next: (success) => {
                    if (success) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `${this.getChangedFields().join(', ')} has been updated.`,
                        });
                        this.formChanged = false;
                        this._subscriptions.unsubscribeAllSafe();
                        this.getMetadataOptions();
                    } else {
                        this.error = true;
                    }
                    this.loading = false;
                },
            });
    }

    private getMetadataOptions() {
        this.loading = true;
        this.documentService.getMetadataOptions(this.documentId).subscribe({
            next: (data) => {
                this.questions = data;
                this.form = this.qcs.toFormGroup(
                    this.questions as QuestionBase<string>[],
                );
                this.initialFormValues = this.form.value;
                this.handleFormChange();
                this.grouped = groupBy(data, (item) => item.section);
            },
            complete: () => (this.loading = false),
        });
    }

    private handleFormChange() {
        const initialValue = this.form.value;
        this._subscriptions['form-value-change'] =
            this.form.valueChanges.subscribe(() => {
                this.formChanged = Object.keys(initialValue).some(
                    (key) => this.form.value[key] != initialValue[key],
                );
            });
    }

    private getChangedFields(): string[] {
        const changedFiels: string[] = [];
        Object.keys(this.form.value).forEach((key) => {
            const control = this.form.get(key);

            if (control && control.value !== this.initialFormValues[key]) {
                changedFiels.push(
                    this.questions.filter(
                        (question) => question.fieldName === key,
                    )[0].label,
                );
            }
        });

        return changedFiels;
    }

    private preparePostData(): UpdateWorkflowDocumentMetadataRequest {
        const data: UpdateMetadataRequest[] = Object.keys(this.form.value).map(
            (key) => ({
                fieldName: key,
                fieldValue:
                    this.form.value[key] instanceof moment
                        ? moment(this.form.value[key]).toDate()
                        : this.form.value[key],
            }),
        );

        return { updateMetadataRequests: data };
    }
}
