import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Patterns } from '../constants';

export function pageRangeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value as string;
        const pageRangePattern = new RegExp(Patterns.PAGE_RANGE);

        if (!value || pageRangePattern.test(value)) {
            return null;
        }

        return { pageRange: true };
    };
}
