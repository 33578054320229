import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    standalone: true,
    selector: 'app-loader',
    imports: [CommonModule],
    templateUrl: './loader.component.html',
    styleUrl: './loader.component.scss',
})
export class LoaderComponent {
    @Input() size: 'small' | 'medium' | 'large' | 'xlarge' = 'medium';
    @Input() type: 'overlay' | 'stationary' = 'stationary';
    @Input() position: 'absolute' | 'fixed' | 'unset' = 'unset';
}
