import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogClose,
    MatDialogRef,
} from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';

import { WorkflowService } from '@services/api/workflow.service';
import { SnackbarService } from '@services/snackbar.service';
import { StationService } from '@services/api/station/station.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { SelectComponent, SelectOption } from '@shared/select/select.component';
import { InputComponent } from '@shared/input/input.component';
import { pageRangeValidator } from 'app/utils/helpers/validators';
import { DocDiscrepancyCreateOptions } from 'app/models/workflow.model';
import { DocumentService } from '@services/api/document/document.service';

@Component({
    standalone: true,
    templateUrl: 'add-discrepancy-dialog.component.html',
    styleUrl: 'add-discrepancy-dialog.component.scss',
    providers: [StationService],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SelectComponent,
        FlatButtonComponent,
        MatDialogClose,
        LoaderComponent,
        InputComponent,
    ],
})
export class AddDiscrepancyDialogComponent implements OnInit {
    form!: FormGroup;
    formChanged = false;
    loading = false;
    error = false;
    options: DocDiscrepancyCreateOptions =
        DocDiscrepancyCreateOptions.initial();
    fixers: SelectOption[] = [];
    readonly maxDirectionsLength = 300;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            documentId: number;
            station?: number;
            fixer?: number;
        },
        public dialogRef: MatDialogRef<AddDiscrepancyDialogComponent>,
        private formBuilder: FormBuilder,
        private workflowService: WorkflowService,
        private documentService: DocumentService,
        private stationService: StationService,
        private snackbarService: SnackbarService,
    ) {
        this.form = this.formBuilder.group({
            reasonId: new FormControl(null),
            stationId: new FormControl(data.station),
            responsibleFixerId: new FormControl(data.fixer),
            pageRange: new FormControl(null, [pageRangeValidator()]),
            directions: new FormControl(
                '',
                Validators.maxLength(this.maxDirectionsLength),
            ),
        });

        if (data.station) {
            this.getFixers(data.station);
        }
    }

    ngOnInit(): void {
        this.documentService
            .getDocDiscrepancyCreateOptions(this.data.documentId)
            .subscribe({
                next: (data) => (this.options = data),
            });

        const initialValue = this.form.value;
        this.form.valueChanges.subscribe(() => {
            this.formChanged = Object.keys(initialValue).some(
                (key) => this.form.value[key] != initialValue[key],
            );
        });

        this.form
            .get('station')
            ?.valueChanges.subscribe((newStation: number) => {
                this.getFixers(newStation);
            });
    }

    create() {
        this.loading = true;
        this.workflowService
            .addDiscrepancyToDocument(this.data.documentId, this.form.value)
            .subscribe({
                next: (newDiscrepancy) => {
                    this.loading = false;
                    if (newDiscrepancy) {
                        this.snackbarService.success({
                            variant: 'success',
                            header: `Discrepancy Is Created`,
                        });
                        this.dialogRef.close({
                            success: true,
                            discId: newDiscrepancy.id,
                        });
                    } else {
                        this.error = true;
                    }
                },
            });
    }

    onCloseClick() {
        this.dialogRef.close({ success: false });
    }

    private getFixers(stationId: number) {
        this.stationService.getStationFixers(stationId).subscribe({
            next: (fixers) => (this.fixers = fixers),
        });
    }
}
