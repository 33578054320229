@if (loading) {
    <app-loader />
} @else {
    @if (discrepancies.length <= 0) {
        No discrepancy found.
    } @else {
        <app-tabs
            [tabs]="tabsData"
            tabLabelClass="text-s flex-wrap"
            tabContentClass="ml-n4 pl-4"
            (tabSelected)="handleTabChange($event)"
            [initialSelectedTabIndex]="selectedTabIndex"
            (newClicked)="openAddDiscModal()">
            @if (detailsLoading) {
                <app-loader />
            } @else {
                @if (discDetail.id !== -1) {
                    <form class="pr-2" [formGroup]="form">
                        <div class="info-section bordered" *ngIf="discDetail">
                            <app-content-field label="ANALYST">
                                {{ discDetail.analyst?.displayName ?? '-' }}
                            </app-content-field>
                            <app-content-field label="STATUS">
                                <span wfeStatusBox>
                                    {{ discDetail.status }}
                                </span>
                            </app-content-field>
                            @if (readOnly || !isAdmin) {
                                <app-content-field label="PRIORITY">
                                    <span
                                        [wfePriorityField]="
                                            discDetail.priority
                                        "></span>
                                </app-content-field>
                            } @else {
                                <app-priority-select
                                    label="PRIORITY"
                                    variant="secondary"
                                    style="min-width: 100px"
                                    formControlName="priority"
                                    [value]="discDetail.priority"
                                    [options]="editOptions.priority" />
                            }
                            <app-content-field label="CREATE DATE">
                                {{ discDetail.createdAt }}
                            </app-content-field>
                        </div>
                        <mat-divider />
                        <div class="info-section">
                            @if (readOnly) {
                                <app-content-field label="REASON">
                                    {{ discDetail.reason?.displayName }}
                                </app-content-field>
                            } @else {
                                <app-select
                                    label="REASON"
                                    [required]="true"
                                    [options]="editOptions.reason"
                                    formControlName="reason"
                                    [value]="discDetail.reason?.backingField" />
                            }
                            @if (readOnly) {
                                <app-content-field label="PAGE RANGE">
                                    {{ discDetail.pageRange || '-' }}
                                </app-content-field>
                            } @else {
                                <div>
                                    <app-input
                                        label="PAGE RANGE"
                                        [required]="true"
                                        formControlName="pageRange"
                                        [control]="pageRange">
                                        <app-checkbox
                                            class="ml-auto"
                                            size="small"
                                            label="Send only selected pages"
                                            formControlName="notifyWithOnlySelectedPages"
                                            [disabled]="docSizeExceedMaxLimit()"
                                            [checked]="
                                                discDetail.notifyWithOnlySelectedPages
                                            " />
                                    </app-input>
                                    <app-page-range-error
                                        *ngIf="docSizeExceedMaxLimit()"
                                        [discDetail]="discDetail" />
                                </div>
                            }
                        </div>
                        <div class="info-section">
                            @if (readOnly) {
                                <app-content-field label="RESPONSIBLE STATION">
                                    {{ discDetail.station?.displayName }}
                                </app-content-field>
                            } @else {
                                <app-select
                                    label="RESPONSIBLE STATION"
                                    [required]="true"
                                    formControlName="station"
                                    [value]="discDetail.station?.backingField"
                                    [options]="editOptions.station" />
                            }
                            @if (readOnly) {
                                <app-content-field label="RESPONSIBLE FIXER">
                                    {{
                                        discDetail.responsibleFixer
                                            ?.displayName ?? '-'
                                    }}
                                </app-content-field>
                            } @else {
                                <app-select
                                    label="RESPONSIBLE FIXER"
                                    formControlName="responsibleFixer"
                                    [value]="
                                        discDetail.responsibleFixer
                                            ?.backingField
                                    "
                                    [options]="fixerOptions" />
                            }
                        </div>
                        <div class="info-section">
                            @if (readOnly) {
                                <app-content-field label="DIRECTIONS">
                                    {{ discDetail.directions }}
                                </app-content-field>
                            } @else {
                                <app-input
                                    label="DIRECTIONS"
                                    [max]="maxDirectionsLength"
                                    formControlName="directions" />
                            }
                        </div>
                        @if (mode === 'detail' && !readOnly) {
                            <div class="info-section">
                                <app-select
                                    label="SELECT A VERSION TO SEND FOR CORRECTION:"
                                    formControlName="revision"
                                    [options]="editOptions.revision" />
                            </div>
                        }
                    </form>
                    <app-activity-log [discrepancyId]="discDetail.id" />
                    <ng-container *ngIf="mode === 'detail'">
                        <div class="actions ml-n4 pl-2 pr-4">
                            <app-footer-assign-actions
                                *ngIf="discDetail"
                                [discDetail]="discDetail"
                                [editOptions]="editOptions"
                                [isAdmin]="isAdmin"
                                (assigneeChanged)="
                                    getDiscrepancyDetails($event)
                                " />
                            <app-footer-state-actions
                                *ngIf="discDetail"
                                [discDetail]="discDetail"
                                [documentId]="documentId"
                                [canSave]="canSave"
                                [editForm]="form"
                                [editOptions]="editOptions"
                                (getDiscrepancies)="getDiscrepancies($event)"
                                (getDiscrepancyDetails)="
                                    getDiscrepancyDetails($event)
                                " />
                        </div>
                    </ng-container>
                } @else {
                    Something went wrong.
                }
            }
            @if (mode === 'detail' && documentDetails) {
                <app-document-upload
                    [discrepancies]="discrepancies"
                    [documentDetails]="documentDetails" />
            }
        </app-tabs>
        @if (mode === 'detail' && maxDiscrepancyCount) {
            <app-add-discrepancy-button
                [discrepancyCount]="discrepancies.length"
                [maxDiscCount]="maxDiscrepancyCount"
                (addClicked)="openAddDiscModal()" />
        }
    }
}
