import { CommonModule } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';

import DATA_QA from '@automation/data-qa.json';
import { TableView } from '../documents-and-discrepancies.utils';
import { FlatButtonComponent } from '../../shared/button/flat-button/flat-button.component';

@Component({
    standalone: true,
    selector: 'app-change-view-menu',
    templateUrl: './change-table-view-menu.component.html',
    styleUrl: './change-table-view-menu.component.scss',
    imports: [
        CommonModule,
        MatMenuModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        FlatButtonComponent,
    ],
})
export class ChangeTableViewMenuComponent {
    @Input() selected: TableView = TableView.Document;
    @Output() tableViewChanged = new EventEmitter<TableView>();
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;

    constructor() {}

    get dataQa() {
        return DATA_QA;
    }

    get description() {
        return this.selected === TableView.Document
            ? `Document view shows both document’s and discrepancy information in
            the same table by grouping discrepancies under documents.`
            : 'Discrepancy view lists each discrepancy separately in the table.';
    }

    changeTableView(tableView: TableView) {
        this.tableViewChanged.emit(tableView);
    }

    closeMenu() {
        if (this.trigger) this.trigger.closeMenu();
    }
}
