<div class="p-5">
    <p class="text-lg mb-3">
        Recall Discrepancy {{ data.id }} to “Initial Review”
    </p>
    Recalling this discrepancy to “Inital Review”...
    <div class="flex justify-end gap-8 mt-5">
        <app-flat-button mat-dialog-close>Cancel</app-flat-button>
        <app-flat-button variant="tertiary" (clicked)="recall()">
            Recall
        </app-flat-button>
    </div>
    <div *ngIf="error" class="flex justify-end gap-8">
        <small class="color-status-red4"> Something went wrong </small>
    </div>
</div>

@if (loading) {
    <app-loader type="overlay" position="absolute" />
}
