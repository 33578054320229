import { Injectable } from '@angular/core';

import {
    DocDiscrepancyCreateOptions,
    DocDiscrepancyCreateOptionsResponse,
    DocumentFilterOptions,
    DocumentInternals,
    MetadataItem,
    MetadataOption,
    WorkflowDocument,
} from 'app/models/workflow.model';
import { SharedMapper } from '../shared.mapper';
import { toSelectOption } from 'app/components/documents-and-discrepancies/documents-and-discrepancies.utils';
import { QuestionBase, QuestionDataType } from '@shared/question/question-base';
import { DropdownQuestion } from '@shared/question/question-dropdown';
import { TextboxQuestion } from '@shared/question/question-textbox';

@Injectable({
    providedIn: 'root',
})
export class DocumentMapper {
    constructor(private sharedMapper: SharedMapper) {}

    toWorkflowDocument = (item: WorkflowDocument) =>
        new WorkflowDocument(
            item?.id ?? '-',
            item?.name || '-',
            item?.documentType || '-',
            item?.repository || '-',
            item.priority,
            this.sharedMapper.toDate(item.createdAt),
            item.station || '-',
            item.documentServiceId || null,
        );

    toDocumentFilterOptions = (
        item: DocumentFilterOptions,
    ): DocumentFilterOptions =>
        new DocumentFilterOptions(
            (item?.documentType || [])?.map(
                this.sharedMapper.toIdDisplayPair,
            ) ?? [],
            (item?.priority || [])?.map(this.sharedMapper.toIdDisplayPair) ??
                [],
            (item?.repository || [])?.map(this.sharedMapper.toIdDisplayPair) ??
                [],
            (item?.station || [])?.map(this.sharedMapper.toIdDisplayPair) ?? [],
        );

    toMetadata = (item: MetadataItem) =>
        new MetadataItem(
            item.fieldLabel,
            item.fieldValue || '-',
            item.ndx,
            item.isRequired ?? false,
        );

    toMetadataQuestion = (item: MetadataOption): QuestionBase<any> | null => {
        try {
            if (item.fieldType === 'ListBox') {
                return new DropdownQuestion({
                    fieldName: item.fieldName,
                    label: item.label,
                    value: item.value,
                    options: (item.options ?? []).map((option: string) => ({
                        value: option,
                        viewValue: option,
                    })),
                    order: item.order,
                    section: item.section,
                });
            }
            if (item.fieldType === 'TextBox') {
                return new TextboxQuestion({
                    fieldName: item.fieldName,
                    label: item.label,
                    value: item.value,
                    required: item.isRequired,
                    order: item.order,
                    section: item.section,
                    dataType: item.dataType as QuestionDataType,
                    maxLength: item.maxLength,
                });
            }
        } catch (e) {
            console.error(e);
            return null;
        }
        return null;
    };

    toInternals = (item: DocumentInternals): DocumentInternals =>
        new DocumentInternals(
            item?.tenantId ?? '',
            item?.repositoryId ?? '',
            item?.workflowId ?? '',
            item?.workflowDocumentId ?? '',
            item?.createdAt ?? '',
        );

    toDocDiscrepancyCreateOptions = (
        item: DocDiscrepancyCreateOptionsResponse,
    ): DocDiscrepancyCreateOptions =>
        new DocDiscrepancyCreateOptions(
            (item?.reason || [])?.map(toSelectOption) ?? [],
            (item?.station || [])?.map(toSelectOption) ?? [],
            (item?.fixer || [])?.map(toSelectOption) ?? [],
        );
}
