import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';

import endpoints from '@endpoints';
import { ApiService } from '../api.service';
import { SimpleResponse } from '../../../models/api.model';
import { Roles, UserResponse } from 'app/models/user.model';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private readonly _path = 'workflow/v1';
    private userSubject = new BehaviorSubject<any>(null);
    public user$: Observable<any> = this.userSubject.asObservable();

    constructor(private api: ApiService) {}

    getUserId() {
        return this.getUser().pipe(map((user) => user.id));
    }

    isAdminUser() {
        return this.getUser().pipe(
            map((user) => user.roles.includes(Roles.Admin)),
        );
    }

    getUser(): Observable<UserResponse> {
        if (this.userSubject.value === null) {
            return this.fetchUser();
        } else {
            return this.user$;
        }
    }

    private fetchUser() {
        return this.api.get(this._path + endpoints.userInfo).pipe(
            tap((response: SimpleResponse<UserResponse>) =>
                this.userSubject.next(response.data),
            ),
            map((response: SimpleResponse<UserResponse>) => response.data),
        );
    }
}
