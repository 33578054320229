<div class="container ml-n4 mt-n4">
    @if (questions.length > 0) {
        <div class="content">
            <form [formGroup]="form" class="dynamic-form">
                <div *ngFor="let group of grouped | keyvalue" class="section">
                    <p class="mb-2 text-md hidden">{{ group.key }}</p>
                    <div class="section__items">
                        <div
                            [ngClass]="{ 'full-width': question.size === 2 }"
                            *ngFor="let question of group.value">
                            @if (question.readonly) {
                                <app-content-field
                                    [required]="question.required"
                                    [label]="
                                        question.label.toLocaleUpperCase()
                                    ">
                                    {{ question.value }}
                                </app-content-field>
                            } @else {
                                <app-question
                                    [question]="question"
                                    [form]="form" />
                            }
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="footer" *ngIf="form">
            <small *ngIf="error" class="mr-4 color-status-red4">
                Metadata could not be updated.
            </small>
            <app-flat-button
                variant="tertiary"
                (clicked)="onSave()"
                [disabled]="!form.valid || !formChanged || loading">
                Save
            </app-flat-button>
        </div>
    }
</div>

@if (loading) {
    <app-loader type="overlay" />
}
