import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';

import { TooltipComponent } from '@shared/tooltip/tooltip.component';
import { WebViewerComponent } from '@shared/webviewer/webviewer.component';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { RejectCorrectionDialogComponent } from '../../reject-correction-dialog/reject-correction-dialog.component';
import { AcceptCorrectionDialogComponent } from '../../accept-correction-dialog/accept-correction-dialog.component';
import { PDFDocument } from 'app/utils/helpers/file';
import { DocumentRevisionStatus } from 'app/models/document';
import { RevisionDetails } from 'app/models/revision/revision-detail';
import { DiscrepancyActionCode } from 'app/models/discrepancy';

@Component({
    selector: 'app-document-preview',
    standalone: true,
    templateUrl: 'document-preview.component.html',
    styleUrl: 'document-preview.component.scss',
    imports: [
        CommonModule,
        MatIconModule,
        WebViewerComponent,
        FlatButtonComponent,
        TooltipComponent,
    ],
})
export class DocumentPreviewComponent implements OnInit, OnChanges {
    @Input() document!: PDFDocument;
    @Input() revisionDetails: RevisionDetails | null = null;

    statuses: Record<string, ToolipContentItem> | undefined;

    readonly shownStatuses = [
        DocumentRevisionStatus.ACCEPTED,
        DocumentRevisionStatus.REJECTED,
        DocumentRevisionStatus.NO_ACTION,
    ];

    constructor(public dialog: MatDialog) {}

    ngOnInit(): void {
        this.setStatuses();
    }

    ngOnChanges(changes: SimpleChanges) {
        const revisionDetailsChange = changes['revisionDetails'];

        if (revisionDetailsChange && !revisionDetailsChange.firstChange) {
            this.setStatuses();
        }
    }
    canReject() {
        return this.revisionDetails
            ? this.revisionDetails.actions.some(
                  (item) => item.action === DiscrepancyActionCode.REJECT,
              )
            : false;
    }

    canAccept() {
        return this.revisionDetails
            ? this.revisionDetails.actions.some(
                  (item) => item.action === DiscrepancyActionCode.ACCEPT,
              )
            : false;
    }

    onRejectClick() {
        this.dialog.open(RejectCorrectionDialogComponent, {
            data: { revisionDetails: this.revisionDetails },
            maxWidth: '1000px',
            minWidth: '200px',
            width: '1000px',
        });
    }

    onAcceptClick() {
        this.dialog.open(AcceptCorrectionDialogComponent, {
            data: { revisionDetails: this.revisionDetails },
            maxWidth: '100%',
            minWidth: '200px',
            width: '100%',
            height: '100%',
            panelClass: 'p-4',
        });
    }

    getKeys(obj: any) {
        return Object.keys(obj);
    }

    private setStatuses() {
        const discrepancies = this.getDiscrepanciesByRelation();

        if (discrepancies && this.getKeys(discrepancies).length > 0) {
            const { ACCEPTED, REJECTED, NO_ACTION } = DocumentRevisionStatus;
            const groups = {
                [ACCEPTED]: {
                    show: discrepancies[ACCEPTED],
                    textClass: 'color-yellow-green7',
                    icon: 'check-circle',
                    text: `Discrepancy ${discrepancies[ACCEPTED]?.join(', ')} accepted`,
                },
                [REJECTED]: {
                    show: discrepancies[REJECTED],
                    textClass: 'color-status-red4',
                    icon: 'cancel-circle',
                    text: `Discrepancy ${discrepancies[REJECTED]?.join(', ')} rejected`,
                },
                [NO_ACTION]: {
                    show: discrepancies[NO_ACTION],
                    textClass: '',
                    icon: 'upload-circle',
                    text: `Discrepancy ${discrepancies[NO_ACTION]?.join(', ')} pending action`,
                },
            };

            this.statuses = groups;
        }
    }

    private getDiscrepanciesByRelation = () => {
        const filtered = this.revisionDetails?.discrepancies.filter((item) =>
            this.shownStatuses.includes(item.relation),
        );
        const grouped = filtered?.reduce(
            (acc: Record<number, number[]>, item) => {
                if (!acc[item.relation]) {
                    acc[item.relation] = [];
                }
                acc[item.relation].push(item.discrepancyNo);
                return acc;
            },
            {},
        );

        return grouped;
    };
}

interface ToolipContentItem {
    show: number[] | undefined;
    textClass: string;
    icon: string;
    text: string;
}
