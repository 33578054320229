import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { DatePipe } from '@angular/common';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

import { WorkflowService } from '@services/api/workflow.service';
import { authInterceptor } from './interceptors/auth.interceptor';
import { routes } from './app.routes';
import { RepositoryService } from '@services/api/repository/repository.service';
import { DocumentService } from '@services/api/document/document.service';

export const appConfig: ApplicationConfig = {
    providers: [
        WorkflowService,
        DocumentService,
        RepositoryService,
        DatePipe,
        provideRouter(routes),
        provideHttpClient(withInterceptors([authInterceptor])),
        provideAnimationsAsync(),
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: { duration: 3500 },
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: { panelClass: 'm-4', autoFocus: false },
        },
    ],
};
