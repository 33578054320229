import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChipComponent } from '@shared/chip/chip.component';
import { TooltipComponent } from '@shared/tooltip/tooltip.component';
import { StatusChipItem } from 'app/models/discrepancy/status-chip';

@Component({
    selector: 'app-status-filter',
    standalone: true,
    templateUrl: './status-filter.component.html',
    imports: [CommonModule, ChipComponent, TooltipComponent],
})
export class StatusFilterComponent implements OnInit {
    @Input() statusChips: StatusChipItem[] = [];
    @Input() selectedStatuses: number[] = [];
    @Output() filterChange = new EventEmitter<number[]>();

    selectedFilterItems: number[] = [];

    ngOnInit() {
        this.selectedFilterItems = [...this.selectedStatuses];
    }

    handleFilterSelect(field: number) {
        this.selectedFilterItems = this.toggleFilterItems(
            this.selectedFilterItems,
            field,
        );
        this.filterChange.emit(this.selectedFilterItems);
    }

    private toggleFilterItems<T>(array: T[], value: T): T[] {
        const index = array.indexOf(value);
        if (index === -1) {
            return [...array, value];
        }

        return array.slice(0, index).concat(array.slice(index + 1));
    }
}
