@if (autocomplete) {
    <app-autocomplete
        [options]="list"
        (selectionChange)="onValueChange($event)" />
} @else {
    <app-select
        [options]="list"
        (selectionChange)="onValueChange($event)"
        placeholder="Filter" />
}
