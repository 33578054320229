import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import {
    ColDef,
    GridApi,
    GridOptions,
    GridReadyEvent,
    IServerSideDatasource,
    IServerSideGetRowsRequest,
} from 'ag-grid-community';
import { IRowNode } from 'ag-grid-enterprise';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SnackbarService } from '@services/snackbar.service';
import { SharedMapper } from '@services/api/shared.mapper';
import { DocumentService } from '@services/api/document/document.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { TableComponent } from '@shared/table/table.component';
import { TextFilterComponent } from '@shared/table/filter/text-filter/text-filter.component';
import { DateFilterComponent } from '@shared/table/filter/date-filter/date-filter.component';
import { UploadCorrectionDialogComponent } from '../../upload-correction-dialog/upload-correction-dialog.component';
import { DiscrepancyHeaderComponent } from './discrepancy-header.component';
import { DocStatusLegendComponent } from './status-legend.component';
import { VersionNameCellComponent } from './version-name-cell.component';
import { DiscrepancyCellComponent } from './discrepancy-cell.component';
import { DragDropDirective } from '@directives/drag-drop.directive';
import {
    setFilterQuery,
    setSortQuery,
} from '../../documents-and-discrepancies.utils';
import { ErrorMessages } from 'app/utils/constants/error-messages';
import {
    DocumentRevisionsResponse,
    DocumentRevisionItem,
    DocumentRevisionVersion,
} from 'app/models/document/revision';
import { Discrepancy } from 'app/models/discrepancy/item';
import { WorkflowDocument } from 'app/models/document/item';
import { selectSelectedRevision } from 'app/store/selectors/document.selectors';
import {
    saveOriginalDocumentId,
    saveRevisionList,
    updateSelectedRevision,
} from 'app/store/actions/document.actions';

@Component({
    standalone: true,
    selector: 'app-document-upload',
    imports: [
        CommonModule,
        MatIconModule,
        MatDividerModule,
        FlatButtonComponent,
        TableComponent,
        DocStatusLegendComponent,
        DragDropDirective,
    ],
    templateUrl: 'document-upload.component.html',
    styleUrl: 'document-upload.component.scss',
})
export class DocumentUploadComponent implements OnInit {
    @Input({ required: true }) documentDetails!: WorkflowDocument;
    @Input() discrepancies: Discrepancy[] = [];

    documentRevision$: Observable<DocumentRevisionItem | null> | undefined;
    documentServiceId: string | undefined;
    columnDefs: ColDef[] = [];
    gridOptions: GridOptions = {
        autoSizeStrategy: {
            type: 'fitCellContents',
        },
    };
    data: DocumentRevisionsResponse | undefined;
    private _gridApi!: GridApi;

    constructor(
        private store: Store,
        public dialog: MatDialog,
        private sharedMapper: SharedMapper,
        private documentService: DocumentService,
        private snackbarService: SnackbarService,
    ) {}

    ngOnInit(): void {
        this.documentRevision$ = this.store.select(selectSelectedRevision);
        this.documentRevision$.subscribe({
            next: (revision) =>
                (this.documentServiceId = revision?.documentServiceId),
        });
    }

    get versionCount() {
        return this.data?.versions.length ?? 0;
    }

    get pickableDiscrepancies() {
        return this.data?.pickableDiscrepancies ?? [];
    }

    get canUpload() {
        return this.pickableDiscrepancies.length > 0;
    }

    onGridReady(event: GridReadyEvent) {
        this._gridApi = event.api;

        const datasource = this.getDocumentVersions();
        this._gridApi.setGridOption('serverSideDatasource', datasource);
    }

    onUploadClick(file: File | null = null) {
        const dialogRef = this.dialog.open(UploadCorrectionDialogComponent, {
            data: {
                id: this.documentDetails.id,
                discrepancies: this.pickableDiscrepancies,
                file,
            },
            maxWidth: '600px',
            minWidth: '200px',
            width: '600px',
        });

        dialogRef.afterClosed().subscribe({
            next: (response) => {
                if (response?.success) {
                    this.snackbarService.success({
                        header: `${response.fileName} uploaded.`,
                    });

                    this.refreshData();
                }
            },
        });
    }

    onFileDrop(file: File) {
        this.onUploadClick(file);
    }

    getOverlayMessage(): string {
        if (!this.canUpload) {
            return ErrorMessages.NO_OPEN_DISC_TO_UPLOAD;
        }
        return 'Drop here to upload correction';
    }

    getRowStyle = (params: { data: DocumentRevisionItem }) => {
        if (params?.data?.isHeader) {
            return { backgroundColor: '#f2f5f7' };
        }

        if (params?.data?.documentServiceId === this.documentServiceId) {
            return { backgroundColor: '#e6f5ff' };
        }
        return undefined;
    };

    refreshData() {
        this._gridApi.refreshServerSide({ purge: true });
    }

    private getDocumentVersions(): IServerSideDatasource {
        return {
            getRows: (params) => {
                params.api.hideOverlay();
                const queryParams = this.getQueryParams(params.request);

                this.documentService
                    .getDocumentRevisions(this.documentDetails.id, queryParams)
                    .subscribe((data) => {
                        if (data) {
                            if (data.versions.length === 0) {
                                params.api.showNoRowsOverlay();
                            }
                            this.data = data;
                            this.saveRevisionList(data.versions);
                            this.saveOriginalDocumentId(
                                data.originalRevision?.id,
                            );

                            const rowData = this.getDocRevisionTableData(data);
                            params.success({
                                rowData: rowData,
                                rowCount: rowData.length,
                            });
                            this.setColumnDefs();
                        } else {
                            params.fail();
                        }
                    });
            },
        };
    }

    private getDocRevisionTableData(
        data: DocumentRevisionsResponse,
    ): DocumentRevisionItem[] {
        const tableData: DocumentRevisionItem[] = [];

        if (data.versions.length > 0) {
            tableData.push(
                new DocumentRevisionItem(
                    0,
                    true,
                    false,
                    `Interaction ${data.interactionsStation}`,
                ),
            );
            data.versions.forEach((item, index) => {
                tableData.push(
                    new DocumentRevisionItem(
                        index + 1,
                        false,
                        item.isOriginal,
                        item.revisionName,
                        item.relationKind,
                        item.createdBy,
                        item.createdAt
                            ? this.sharedMapper.toDate(item.createdAt)
                            : '-',
                        item.documentServiceId,
                        item.isOriginal ? true : false,
                        item.id,
                    ),
                );
            });
            const { originalRevision } = data;
            if (originalRevision) {
                tableData.push(
                    ...[
                        new DocumentRevisionItem(
                            data.versions.length + 1,
                            true,
                            false,
                            'Original Document',
                        ),
                        new DocumentRevisionItem(
                            data.versions.length + 2,
                            false,
                            true,
                            originalRevision.revisionName,
                            originalRevision.relationKind,
                            originalRevision.createdBy,
                            originalRevision.createdAt
                                ? this.sharedMapper.toDate(
                                      originalRevision.createdAt,
                                  )
                                : '-',
                            originalRevision.documentServiceId,
                            false,
                            originalRevision.id,
                        ),
                    ],
                );
            }
        }

        return tableData;
    }

    private setColumnDefs() {
        if (this._gridApi) {
            this._gridApi.setGridOption(
                'columnDefs',
                this.getColumnDefs(this.data!.discrepancyNos),
            );
            this._gridApi.redrawRows();
            this.autoSizeDiscrepancyColumn();
        }
    }

    private getColumnDefs(discrepancyNos: number[] = []) {
        return [
            {
                field: 'revisionName',
                headerName: 'Version Name',
                filter: 'agTextColumnFilter',
                flex: 1,
                floatingFilterComponent: TextFilterComponent,
                cellRenderer: VersionNameCellComponent,
                cellRendererParams: {
                    onClick: (node: IRowNode) => {
                        this.store.dispatch(
                            updateSelectedRevision({
                                selectedRevision: node.data,
                            }),
                        );
                        this.setColumnDefs();
                    },
                },
                cellStyle: (params: { data: DocumentRevisionItem }) => {
                    if (params?.data?.isHeader) {
                        return { overflow: 'visible' };
                    }
                    return undefined;
                },
            },
            {
                field: 'relationKind',
                floatingFilter: false,
                flex: 2,
                headerComponent: DiscrepancyHeaderComponent,
                headerComponentParams: {
                    relationKind: discrepancyNos,
                },
                cellRenderer: DiscrepancyCellComponent,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            {
                field: 'createdBy',
                headerName: 'By',
                filter: 'agTextColumnFilter',
                floatingFilterComponent: TextFilterComponent,
            },
            {
                field: 'createdAt',
                headerName: 'Date',
                filter: 'agTextColumnFilter',
                floatingFilterComponent: DateFilterComponent,
            },
        ];
    }

    private autoSizeDiscrepancyColumn() {
        this._gridApi!.autoSizeColumns(['relationKind'], false);
    }

    private getQueryParams(request: IServerSideGetRowsRequest) {
        return {
            ...setFilterQuery(request),
            ...setSortQuery(request),
        };
    }

    private saveRevisionList(versions: DocumentRevisionVersion[]) {
        this.store.dispatch(
            saveRevisionList({
                revisionList: versions.map((item) => item.revisionName),
            }),
        );
    }

    private saveOriginalDocumentId(originalDocumentId: number | null = null) {
        this.store.dispatch(
            saveOriginalDocumentId({
                originalDocumentId,
            }),
        );
    }
}
