import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { QuestionBase } from './question-base';
import { InputComponent } from '@shared/input/input.component';
import { SelectComponent } from '@shared/select/select.component';
import { DatepickerComponent } from '@shared/datepicker/datepicker.component';
import { ContentFieldComponent } from '@shared/content-field/content-field.component';

@Component({
    standalone: true,
    selector: 'app-question',
    templateUrl: 'question.component.html',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        InputComponent,
        SelectComponent,
        DatepickerComponent,
        ContentFieldComponent,
    ],
})
export class DynamicFormQuestionComponent {
    @Input() question!: QuestionBase<string>;
    @Input() form!: FormGroup;

    get isValid() {
        return this.form.controls[this.question.fieldName].valid;
    }
}
