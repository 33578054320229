<ag-grid-angular
    [getRowId]="getRowId"
    [class]="class"
    domLayout="autoHeight"
    [pagination]="pagination"
    [paginationPageSize]="pageSize"
    [paginationPageSizeSelector]="false"
    [cacheBlockSize]="pageSize"
    [columnDefs]="columnDefs"
    rowModelType="serverSide"
    rowSelection="multiple"
    [getRowStyle]="getRowStyle"
    [suppressAggFuncInHeader]="true"
    [suppressRowClickSelection]="true"
    [suppressDragLeaveHidesColumns]="true"
    [suppressRowHoverHighlight]="suppressRowHoverHighlight"
    [suppressCellFocus]="suppressCellFocus"
    [suppressMovableColumns]="suppressMovableColumns"
    [enableRangeSelection]="false"
    [rowMultiSelectWithClick]="true"
    [masterDetail]="masterDetail"
    [headerHeight]="headerHeight"
    [rowHeight]="rowHeight"
    [floatingFiltersHeight]="30"
    [detailCellRendererParams]="detailCellRendererParams"
    [detailRowAutoHeight]="detailRowAutoHeight"
    [defaultColDef]="defaultColDef"
    [noRowsOverlayComponent]="noRowsOverlayComponent"
    [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
    [loadingOverlayComponent]="loadingOverlayComponent"
    [suppressServerSideFullWidthLoadingRow]="true"
    (rowGroupOpened)="onRowGroupOpened($event)"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)">
</ag-grid-angular>
<app-pagination
    *ngIf="pagination"
    [pageSize]="pageSize"
    (pageSizeChanged)="pageSize = $event" />
