import { Injectable } from '@angular/core';
import { toSelectOption } from 'app/components/documents-and-discrepancies/documents-and-discrepancies.utils';
import {
    DiscrepancyResponse,
    Discrepancy,
    DiscrepancyDetail,
    DiscrepancyFilterOptions,
    DiscrepancyEditOptionsResponse,
    DiscrepancyEditOptions,
    StatusChipItem,
    ActivityLogItem,
} from 'app/models/workflow.model';
import { SharedMapper } from './shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class WorkflowMapper {
    constructor(private sharedMapper: SharedMapper) {}

    toDiscrepancy = (item: DiscrepancyResponse) => {
        return new Discrepancy(
            item.id,
            item.reason?.displayName ?? '-',
            item.priority,
            item.status,
            this.sharedMapper.toDate(item.createdAt),
            this.sharedMapper.toDate(item.updatedAt),
            this.sharedMapper.toDate(item.taken),
            item.analyst?.displayName ?? '-',
            item.station?.displayName ?? '-',
            item.responsibleFixer?.displayName ?? '-',
            item.documentName ?? '-',
            item.documentId,
            item.discrepancyNo,
        );
    };

    toDiscrepancyDetail = (item: DiscrepancyDetail): DiscrepancyDetail =>
        new DiscrepancyDetail(
            item.id,
            item?.pageRange || '',
            item?.directions || '-',
            this.sharedMapper.toDate(item.createdAt),
            item.reason ? this.sharedMapper.toIdDisplayPair(item.reason) : null,
            item?.priority ?? '-',
            item?.status || '-',
            item.analyst
                ? this.sharedMapper.toIdDisplayPair(item.analyst)
                : null,
            item.station
                ? this.sharedMapper.toIdDisplayPair(item.station)
                : null,
            item.responsibleFixer
                ? this.sharedMapper.toIdDisplayPair(item.responsibleFixer)
                : null,
            item.documentServiceId,
            item?.canCancel ?? false,
            item?.actions ?? [],
            item.revision
                ? this.sharedMapper.toIdDisplayPair(item.revision)
                : null,
        );

    toDiscrepancyFilterOptions = (
        item: DiscrepancyFilterOptions,
    ): DiscrepancyFilterOptions =>
        new DiscrepancyFilterOptions(
            (item?.priority || [])?.map(this.sharedMapper.toIdDisplayPair) ??
                [],
            (item?.reason || [])?.map(this.sharedMapper.toIdDisplayPair) ?? [],
            (item?.status || [])?.map(this.sharedMapper.toIdDisplayPair) ?? [],
            (item?.station || [])?.map(this.sharedMapper.toIdDisplayPair) ?? [],
            (item?.responsibleFixer || [])?.map(
                this.sharedMapper.toIdDisplayPair,
            ) ?? [],
        );

    toDiscrepancyEditOptions = (item: DiscrepancyEditOptionsResponse) =>
        new DiscrepancyEditOptions(
            item.priority.map(toSelectOption),
            item.reason.map(toSelectOption),
            item.station.map(toSelectOption),
            item.fixer.map(toSelectOption),
            item.canEdit,
            item.analyst?.map(toSelectOption) ?? [],
            item.revision.map(toSelectOption) ?? [],
        );

    toStatusChipItem = (item: StatusChipItem): StatusChipItem =>
        new StatusChipItem(
            item?.assigned ?? 0,
            item?.backingField ?? -1,
            item?.name ?? '',
            item?.total ?? 0,
            item?.unassigned ?? 0,
        );

    toActivityLogItem = (item: ActivityLogItem): ActivityLogItem =>
        new ActivityLogItem(
            item?.id,
            item?.user || '-',
            item?.logMessage || '-',
            item?.createdAt || '-',
        );
}
