import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

import { AuthService } from '@services/api/auth/auth.service';

@Component({
    standalone: true,
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrl: './navbar.component.scss',
    imports: [CommonModule, MatIconModule, MatButtonModule, MatMenuModule],
})
export class NavbarComponent {
    tabs: Tab[] = [];
    activeTabId: Tabs;
    isLoggedIn = false;
    @Output() tabChanged = new EventEmitter<Tabs>();

    constructor(private authService: AuthService) {
        this.isLoggedIn = this.authService.isLoggedIn();

        this.tabs = [
            new Tab(Tabs.WORKFLOW_INBOX, 'Workflow Inbox', 'inbox', true),
            new Tab(
                Tabs.DOCUMENT_AND_DISCREPANCIES,
                'Documents and Discrepancies',
                'document',
            ),
        ];
        this.activeTabId = this.tabs[1].id;
    }

    setActiveTab(tab: Tab): void {
        if (!tab.isMenu) {
            this.activeTabId = tab.id;
            this.onTabSelected(tab.id);
        }
    }

    onTabSelected(tabId: Tabs) {
        this.tabChanged.emit(tabId);
    }

    logout() {
        this.authService.logout();
    }
}

export class Tab {
    constructor(
        public id: Tabs,
        public label: string,
        public icon: string,
        public isMenu: boolean = false,
    ) {}
}

export enum Tabs {
    WORKFLOW_INBOX,
    DOCUMENT_AND_DISCREPANCIES,
    TEST,
}
