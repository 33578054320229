@if (loading) {
    <div
        class="w-full h-full flex items-center justify-center"
        style="background-color: #13161a">
        <app-loader size="medium" />
    </div>
} @else {
    <header>
        <app-branding-bar />
        <app-navbar (updateActiveTab)="updateActiveTab($event)" />
    </header>
    <router-outlet />
}
