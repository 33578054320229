import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    Renderer2,
} from '@angular/core';

@Directive({
    selector: '[wfeResizable]',
    standalone: true,
})
export class ResizableDirective implements OnChanges {
    @Input() wfeResizable!: '';

    private _startX = 0;
    private _startWitdh = 0;
    private _minWidth = 100;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngOnChanges(): void {
        this.renderer.setStyle(this.el.nativeElement, 'cursor', 'ew-resize');
        this.renderer.setStyle(
            this.el.nativeElement,
            'background-color',
            '#d8e0e5',
        );
        this.renderer.setStyle(this.el.nativeElement, 'min-width', '10px');
        this.renderer.setStyle(this.el.nativeElement, 'height', '100%');
        this.renderer.setStyle(this.el.nativeElement, 'z-index', '1');
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {
        const leftElement = this.el.nativeElement.previousElementSibling;
        const rightElement = this.el.nativeElement.nextElementSibling;
        this._startX = event.clientX;
        this._startWitdh = leftElement.getBoundingClientRect().width;

        this.renderer.setStyle(rightElement, 'pointer-events', 'none');

        document.body.style.cursor = 'ew-resize';
        document.body.style.userSelect = 'none';
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);
    }

    onMouseMove = (event: MouseEvent) => {
        const dx = event.clientX - this._startX;
        const newWidth = this._startWitdh + dx;
        const leftElement = this.el.nativeElement.previousElementSibling;

        if (
            newWidth >= this._minWidth &&
            newWidth <= window.innerWidth - this._minWidth
        ) {
            this.renderer.setStyle(leftElement, 'flex', 'none');
            this.renderer.setStyle(
                this.el.nativeElement.previousElementSibling,
                'width',
                `${newWidth}px`,
            );
        }
    };

    onMouseUp = () => {
        document.body.style.cursor = 'default';
        document.body.style.userSelect = 'auto';

        const rightElement = this.el.nativeElement.nextElementSibling;
        this.renderer.setStyle(rightElement, 'pointer-events', 'auto');

        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
    };
}
