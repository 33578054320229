import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogClose,
} from '@angular/material/dialog';

import { DiscrepancyService } from '@services/api/discrepancy/discrepancy.service';
import { SnackbarService } from '@services/snackbar.service';
import { FlatButtonComponent } from '@shared/button/flat-button/flat-button.component';
import { LoaderComponent } from '@shared/loader/loader.component';
import { DiscrepancyIdNo } from 'app/models/discrepancy/item';

@Component({
    standalone: true,
    templateUrl: 'cancel-discrepancy-dialog.component.html',
    imports: [
        CommonModule,
        FlatButtonComponent,
        MatDialogClose,
        LoaderComponent,
    ],
})
export class CancelDiscrepancyDialogComponent {
    loading = false;
    error = false;

    constructor(
        public dialogRef: MatDialogRef<CancelDiscrepancyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DiscrepancyIdNo,
        private snackbarService: SnackbarService,
        private discrepancyService: DiscrepancyService,
    ) {}

    cancel() {
        this.loading = true;
        this.error = false;

        this.discrepancyService.cancelDiscrepancy(this.data.id).subscribe({
            next: (success) => {
                this.loading = false;
                if (success) {
                    this.snackbarService.success({
                        variant: 'success',
                        header: `Discrepancy ${this.data.discrepancyNo} cancelled and moved to “Recently Completed”!`,
                    });
                    this.dialogRef.close(true);
                } else {
                    this.error = true;
                }
            },
        });
    }
}
