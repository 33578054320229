<div class="detail-dialog">
    <div class="detail-dialog__header">
        <app-branding-bar />
        <div class="detail-dialog__name flex items-center color-gray18">
            <button mat-icon-button aria-label="Close" (click)="onCloseClick()">
                <mat-icon svgIcon="close" />
            </button>
            <span class="text-lg">{{ documentDetails?.name }}</span>
        </div>
    </div>
    <div class="detail-dialog__body">
        <app-tabs
            [tabs]="tabsData"
            alignment="center"
            class="flex mt-4 h-full"
            (tabSelected)="activeTab = $event">
            <div class="flex h-full mt-n3 bg-gray0" style="overflow-y: hidden">
                <div class="detail-dialog__document-edit pl-4 mt-4">
                    <app-internals-tab
                        *ngIf="activeTab === tabs.WF_INTERNALS"
                        [documentId]="data.id" />
                    <app-discrepancies-tab
                        *ngIf="activeTab === tabs.DISCREPANCIES"
                        [documentId]="data.id"
                        [documentDetails]="documentDetails"
                        mode="detail" />
                    <app-document-metadata-edit
                        *ngIf="activeTab === tabs.METADATA"
                        [documentId]="data.id" />
                </div>
                <div class="detail-dialog__drag-handle" appResizable></div>
                <div class="detail-dialog__pdf-render" style="flex: 1">
                    @if (pdfLoading) {
                        <app-loader
                            class="disp-block h-full flex items-center justify-center" />
                    }
                    @if (pdfDocument) {
                        <app-web-viewer
                            [pdfDocument]="pdfDocument.blob"
                            [name]="pdfDocument.name" />
                    }
                </div>
            </div>
        </app-tabs>
    </div>
</div>
