import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { INoRowsOverlayParams } from 'ag-grid-community';

type NoRowsOverlayParams = INoRowsOverlayParams & {
    text: string;
    isDetail: boolean;
};

@Component({
    standalone: true,
    templateUrl: './no-rows-overlay.component.html',
    styleUrl: './no-rows-overlay.component.scss',
})
export class NoRowsOverlayComponent implements INoRowsOverlayAngularComp {
    public params!: NoRowsOverlayParams;

    agInit(params: NoRowsOverlayParams): void {
        this.refresh(params);
    }

    refresh(params: NoRowsOverlayParams): void {
        this.params = params;
    }
}
